<template>
  <div id="lesson-photos-good" class="lesson-screen">
    <div class="wrapper">
      <div class="content__drawer content__drawer-filled">
        <div class="content lesson-screen--container">
          <div class="lesson-screen--content">
            <div class="lesson-screen--header">
              <div class="photos-remark--container">
                <div class="photos-remark--content">
                  <i class="material-icons good-photo">thumb_up</i>
                  <span>{{ $t('good_photos') }}</span>
                </div>
              </div>
              <div class="examples-text--container">{{ $t('examples') }}</div>
            </div>
            <photo-samples :photos="GOOD_PHOTOS" />
          </div>
        </div>

        <navigator
          :current="currentPage"
          no-prev
          :next="$t('messages.globals.continue')"
          :next-class="'action-btn__black'"
          :total="totalPages"
          :step-class="'--purple'"
          @next.prevent="gotoNextLessonScreen"
        />
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/onboarding-lesson/translations/photos.json"></i18n>

<script>
import PhotoSamples from '@/features/onboarding-lesson/components/PhotoSamples.vue'
import navigator from '@components/v2/utilities/navigator.vue'
import { GOOD_PHOTOS } from '@/features/onboarding-lesson/constants'
import { OnboardingLessons } from '@/mixins/onboarding'

export default {
  components: {
    PhotoSamples,
    navigator
  },
  mixins: [
    OnboardingLessons
  ],
  data () {
    return {
      GOOD_PHOTOS
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/features/onboarding-lesson/sass/styles";
</style>
