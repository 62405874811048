<template>
  <div v-if="hasSelected" :id="component_id" class="country-picker" :class="{ 'focused': focused }">
    <div @click.prevent="show">
      <span class="label active">{{ $t('country') }}</span>
      <div class="selected" data-test="selected-country">
        <img v-cloak :src="selected.flag" class="selected-flag">
        <span class="selected-dial-code">+{{ selected.callingCode }}</span>
      </div>
      <span v-if="showCaret" class="material-icons">expand_more</span>
    </div>
    <div class="focus-item-wrapper">
      <autocomplete
        ref="countrypicker"
        type="dropdown"
        :class="{'autocomplete--registration': onRegistration}"
        value-key="alpha2Code"
        search-key="name"
        :show-on-empty="true"
        :label="$t('country')"
        :options="countries"
        :options-callback="countrySearch"
        :is-shown="isShown"
        @focus="onPickerFocus"
        @blur="onPickerBlur"
        @exitfullscreen="exitFullScreen"
        @select="onSelect"
        @close="close"
      >
        <template slot-scope="option">
          <div class="suggestion-item" data-test="dropdown-item">
            <img :src="option.value.flag" loading="lazy" class="inline-block-middle" style="width: 24px; margin-right: 8px;">
            <span class="inline-block-middle">
              {{ $i18n.locale === 'de' && 'deName' in option.value ? option.value.deName : option.value.name }}
              (+{{ option.value.callingCode }})
            </span>
          </div>
        </template>
      </autocomplete>
    </div>
  </div>
</template>

<i18n src="../translations/countryPicker.json"></i18n>

<script>
import { isEmpty, find, random } from 'lodash'

import Autocomplete from './Autocomplete.vue'
import Countries from '@/../../public/countries-copy.json'
import CountriesPhotoFallback from '@/../../public/countries-photo-fallback.json'

export default {
  components: {
    Autocomplete
  },
  props: {
    value: {
      type: String,
      default: null
    },
    caret: {
      type: Boolean,
      default: false
    },
    onRegistration: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      component_id: null,
      selected: {},
      countries: [],
      focused: false,
      isShown: false
    }
  },
  computed: {
    label () {
      return this.$t('country')
    },
    showCaret () {
      return !!(this.caret)
    },
    hasSelected () {
      return !(isEmpty(this.selected))
    },
    PHOTO_FALLBACK_A2_2720 () {
      return this.$store.getters['featureflag/isEnabled']('PHOTO_FALLBACK_A2_2720')
    }
  },
  watch: {
    value (value, old) {
      if (value && value !== old) {
        this.setSelected(value)
      }
    }
  },
  mounted () {
    var vm = this

    while (this.component_id === null || document.getElementById(this.component_id) !== null) {
      this.component_id = 'country-picker-' + random(0, 999999)
    }

    vm.setCountries(Countries)
    vm.setSelected(vm.value)
    vm.$emit('loaded', Countries)

    if (this.PHOTO_FALLBACK_A2_2720) {
      vm.setCountries(CountriesPhotoFallback)
      vm.setSelected(vm.value)
      vm.$emit('loaded', CountriesPhotoFallback)
    }
  },
  methods: {
    setCountries (countries) {
      this.countries = countries
    },
    setSelected (country) {
      this.selected = find(this.countries, { alpha2Code: country })
    },
    onPickerFocus () {
      this.focused = true
    },
    onPickerBlur () {
      if (this.$refs.countrypicker.fullscreen) {
        return false
      }

      this.focused = false
    },
    exitFullScreen () {
      this.focused = false
    },
    onSelect (alpha2Code) {
      this.setSelected(alpha2Code)
      this.$emit('select', this.selected)
    },
    countrySearch (query) {
      const vm = this

      let escapedQuery = query

      escapedQuery = query.includes('+') ? query.replace(/\+/i, '\\+') : query
      return new Promise(function (resolve, reject) {
        const results = _.filter(vm.countries, function (o) {
          return vm.testMatch('+' + o.callingCode, escapedQuery, 'start') ||
                  vm.testMatch(
                    vm.$i18n.locale === 'de' && 'deName' in o
                      ? o.deName
                      : o.name,
                    escapedQuery
                  ) ||
                  vm.testMatch(o.callingCode, escapedQuery)
        })
        if (results !== null) {
          resolve(results)
        } else {
          reject(results)
        }
      })
    },
    testMatch: function (str1, str2, position) {
      let regex

      switch (position) {
        case 'start':
          regex = '^' + str2 + '.*$'
          break
        case 'end':
          regex = '^.*' + str2 + '$'
          break
        case 'any':
        default:
          regex = '^.*' + str2 + '.*$'
          break
      }

      var testObj = new RegExp(regex, 'i')

      return testObj.test(str1)
    },
    show () {
      this.isShown = true
      this.$emit('shown')
    },
    close () {
      this.isShown = false
      this.$emit('closed')
    }
  }
}
</script>

<style lang="scss" scoped>
.country-picker {
  display: block;
  width: 100%;
  white-space: nowrap;
  position: relative;
  height: 56px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 8px 16px;
  transition: all 0.3s;

  span.caret {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 10px;
      margin: 0 auto;
      font-size: 10px;
      line-height: 10px;
      color: initial;
  }
  span.material-icons {
    position: absolute;
    top: 22px;
    right: 8px;
    color: #78838F;
  }
  span.label {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      color: #8a8f95;
      transform: translate(16px, 16px) scale(1);
      transition: transform .2s ease-out, -webkit-transform .2s ease-out;
  }
  span.label.active {
      color: #52575B;
      transform: translate(12px, 0px) scale(0.9);
  }

  &.country-picker--APV_REG_REDESIGN {
    top: 0;
    border: 1px solid #2f3740;
    border-radius: 12px;
    height: 68px;
    color: #2f3740;
    background: #fff;
    margin-bottom: 20px;
    padding-top: 5px;
    width: calc(98% - 8px);

    span.label.active {
      font-size: 12px;
      top: -10px;
      background: #FFFFFF;
      height: 16px;
      padding-left: 5px;
      padding-right: 5px;
      font-family: 'Proxima Nova Medium';
    }
  }

  div.selected {
    line-height: 56px;
    font-size: 16px;

    .selected-flag,
    .selected-dial-code {
        display: inline-block;
        vertical-align: middle;
    }
    .selected-flag {
        width: 24px;
        margin-right: 4px;
    }
  }
  .focus-item-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FFFFFF;

    .focus-item {
      display: block;
      width: 100%;
      height: 100%;

      .input-field {
          & {
              margin-top: 0;
          }
          input[type="text"] {
              margin-bottom: 0;
          }
      }
    }
  }

  &.focused {
    border-bottom: solid 1px #C51162;
    box-shadow: 0 1px 0 0 #C51162;

    span.label {
        color: #C51162;
    }
    .focus-item-wrapper {
        top: 100%;
        margin-top: 2px;
        width: 300%;
        opacity: 1;
    }
  }

  .suggestion-item {
    img, span {
      vertical-align: middle;
    }

    span {
      width: 85%;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      overflow: hidden;
      display: inline-block;
    }
  }
}
</style>

<style lang="scss">
#verify {
  .mobile-field-container {
    .input-field--APV_REG_REDESIGN.input-field label {
      top: 12px;
    }
  }
}
</style>
