var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer-button" }, [
    _c("ul", [
      _c("li", [
        _c(
          "button",
          {
            staticClass: "cancel",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.onCancel($event)
              }
            }
          },
          [_vm._v("\n        " + _vm._s(_vm.cancel) + "\n      ")]
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "button",
          {
            staticClass: "save",
            attrs: {
              "data-test": "footer-cta-confirm",
              disabled: _vm.loading || _vm.disabled
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.onConfirm($event)
              }
            }
          },
          [_vm._v("\n        " + _vm._s(_vm.confirm) + "\n      ")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }