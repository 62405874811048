<template>
  <div id="registration-review-modal" class="modal">
    <div class="modal-content center-align">
      <h4 class="modal-title margin-bottom-none" style="font-size: 20px;" v-html="$translate('app.registration_review_popup.title')" />
      <i class="mdi mdi-lock-open-outline krypton-green-text" style="font-size: 72px;" />
      <p class="margin-bottom-md margin-top-none" style="margin-top: -8px;"><strong>{{ $translate('app.registration_review_popup.subtitle') }}</strong></p>
      <p class="center grey-text margin-bottom-none margin-top-none text-darken-1" style="font-size: 14px;">
        {{ $translate('app.registration_review_popup.explanation_text_1') }}
      </p>
      <div class="support-number margin-bottom-md margin-top-lg"><strong>01573582190</strong></div>
      <p class="center grey-text margin-bottom-none margin-top-none text-darken-1" style="font-size: 14px;">
        {{ $translate('app.registration_review_popup.explanation_text_2') }}
      </p>
    </div>
    <div class="modal-footer" style="text-align: center; border-top: 1px solid #E1E6EB; text-align: center;">
      <a href="#" class="btn-flat waves-effect waves-dark krypton-pink-text" style="font-size: 16px;" @click.prevent="confirm">
        <strong>{{ $translate('app.ok_understand') }}</strong>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    confirm ($e) {
      this.$emit('confirm', $e)
    }
  }
}
</script>

<style lang="scss" scoped>
#registration-review-modal {
  .support-number {
    padding: 19px 79px;
    border: 1px solid #CAD1D9;
    border-radius: 4px;
  }
}
</style>
