<template>
  <div id="registration-complete-mobile" class="wrapper">
    <template>
      <div class="content__area">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <h2
          class="content__title content__title--desktop"
          v-html="$translate('register.new_complete.title')"
        />
      </div>

      <div class="content__area drawer max-height-mobile">
        <div class="content center-align">
          <h2
            class="content__title content__title--mobile"
            v-html="$translate('register.complete.title_2')"
          />

          <div class="drawer-icon">
            <img src="/img/registration/success/apv-preview-new.png" style="max-width: 205px;" alt="Registration Success">
          </div>

          <p class="text">{{ $translate('register.new_complete.content') }}</p>

          <div class="actions flex-grid space-between">
            <!-- registration success CTA here -->
            <button
              :disabled="isDownloadCTAClicked"
              class="btn btn-primary btn-verify"
              data-id="registration-complete-cta"
              @click="doGetTheApp">
              {{ $translate('register.new_complete.get_the_app') }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const AuthProvider = $h.getProvider('AuthProvider')

export default {
  data: () => ({
    isDownloadCTAClicked: false
  }),
  mounted () {
    if (this.isAndroidChrome) {
      this.$store.commit('pwaAnimation/setForceShowAnimation', false)
      this.$store.commit('setShowPWADownloadScreen', true)
    }
  },
  computed: {
    ...mapGetters({
      beforeInstallPromptEvt: 'pwaAnimation/beforeInstallPromptEvt'
    }),
    isAndroidChrome () {
      return navigator.userAgent.search(/Chrome/i) >= 0 &&
         navigator.userAgent.search(/Android/i) >= 0 &&
         navigator.userAgent.search(/SamsungBrowser/i) === -1
    },
    isIPhoneNonSafari () {
      // iPhone or iPad with Chrome Browser
      return navigator.userAgent.search(/iPhone|iPad/i) >= 0 && navigator.userAgent.search(/CriOS/i) >= 0
    },
    isIPhoneSafari () {
      // iPhone or iPad that is NOT Chrome Browser
      return navigator.userAgent.search(/CriOS/i) === -1 && navigator.userAgent.search(/iPhone/i) >= 0
    },
    isPWAMode () {
      return window.matchMedia('(display-mode: standalone)').matches ||
         window.matchMedia('(display-mode: fullscreen)').matches ||
         window.navigator.standalone === true ||
         window.location.search.indexOf('utm_source=homescreen') >= 0
    }
  },
  methods: {
    async triggerDefferedInstallPrompt () {
      this.isDownloadCTAClicked = true
      this.beforeInstallPromptEvt?.prompt()

      const { outcome } = await this.beforeInstallPromptEvt.userChoice

      if (outcome === 'accepted') {
        this.$store.commit('pwaAnimation/setForceShowAnimation', true)
        this.$store.commit('setShowPWADownloadSuccessScreen', true)
        this.isDownloadCTAClicked = false
        this.doLogout()
      } else {
        console.log('Installation prompt dismissed')
        this.isDownloadCTAClicked = false
      }
    },
    doGetTheApp () {
      // If detected on PWA mode and clicked the "download app" then redirect to dashboard
      if (this.isPWAMode) {
        // Set register progress to 0 to get out of registration process
        this.$store.commit('setProgress', 0)
        // Redirect to dashboard
        this.$router.push({ name: 'dashboard' })
        return false
      }

      if (this.beforeInstallPromptEvt && this.beforeInstallPromptEvt.prompt) {
        this.triggerDefferedInstallPrompt()
      } else if (this.isIPhoneNonSafari) {
        this.$store.commit('pwaAnimation/setForceShowAnimation', true)
        this.$store.commit('pwaAnimation/setForceShowSafariBanner', true)
        this.doLogout()
      } else if (this.isIPhoneSafari) {
        // Considering that the provider is still logged-in
        // Redirect to dashboard without ?utm_source=homescreen
        // Set the registration progress to 0
        // So it doesn't continue with the registration process
        // This will show the IOS download screen
        this.$store.commit('setProgress', 0)
        this.$router.push({ name: 'dashboard' })
        return false
      } else {
        this.$store.commit('pwaAnimation/setForceShowAnimation', true)
        const InstallChromeBanner = require('@/install-chrome-banner.js')

        InstallChromeBanner.init({
          instructionText: this.$translate('app.install_chrome_screen.content'),
          linkText: this.$translate('app.install_chrome_screen.button')
        })
        this.doLogout()
      }
    },
    doLogout () {
      AuthProvider.logout(() => {
        AuthProvider.setAuthorizationHeader()
        this.$store.commit('reset')
        this.$store.commit('billing/reset')
        this.$store.commit('events/RESET')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './mobile.style';

  #register-new .wrapper .content__area:first-of-type {
    background: linear-gradient(123deg, #06D6A0, #FFD600);

    @media screen and (max-width: 480px) {
      height: 30vh;
    }

    @media screen and (max-width: 375px) {
      height: 14vh;
    }
  }
</style>
