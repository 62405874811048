<template>
  <div class="tips-and-infos" data-test="tips-and-infos">
    <div class="section-label">
      <img src="/img/dashboard/dashboard-tips-and-infos-icon.svg" alt="tips and infos icon">
      <h2>{{ $translate('onboarding.tips_and_infos') }}</h2>
    </div>

    <onboarding-verification-banner />

    <onboarding-bottom-sheet-dashboard-intro />

    <onboarding-active-lesson />

    <onboarding-bottom-sheet-quick-start />
  </div>
</template>

<script>
export default {

}
</script>

<style style="scss" scoped>
.tips-and-infos {
  margin-bottom: 72px;
}
</style>
