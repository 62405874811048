<template>
  <div v-if="isViewReady" id="event-checkin">
    <md-navbar-event
      icon="arrow_back"
      :push="{ name: 'events' }"
      :show-currency="false"
      :brand="$translate('events.nav_title')"
    />
    <div id="content">
      <div class="container">
        <div class="timer">
          <div class="countdown-icon">
            <img class="responsive-img" src="/img/events/ic-countdown.svg" alt="countdown">
          </div>
          <h2 class="countdown-timer">{{ countdown }}</h2>
          <div class="details">
            <div class="hours">
              <i class="material-icons">schedule</i>
              {{ meetingDuration }}
            </div>
            <div class="rate">
              <i class="icon-payment white-icon" />
              {{ amountPayable }}
            </div>
          </div>
          <div v-if="!isAddExtension && canAddMoreExtension" class="add-extension">
            <a
              href="#"
              class="white-text"
              @click.prevent="isAddExtension = true"
            >{{ $translate('events.checkin.add_extension_btn') }}</a>
          </div>
        </div>
      </div>
      <div v-if="isAddExtension" class="time-extension">
        <div class="time-extension-controls">
          <button
            id="event-checkin-decrement-ext"
            class="btn subtract-time btn-krypton-pink waves-effect waves-light"
            @click="decrementExtension"
          >&minus;</button>
          <div class="added-time">+{{ hrExtension }}</div>
          <button
            id="event-checkin-increment-ext"
            class="btn add-time btn-krypton-pink waves-effect waves-light"
            @click="incrementExtension"
          >&plus;</button>
        </div>
        <div class="time-extension-details">
          <div class="hours">
            <i class="material-icons">schedule</i>
            {{ computedHrExtension }}
          </div>
          <div class="rate">
            <i class="icon-payment" />
            {{ computedFee }}
          </div>
        </div>
        <p>{{ $translate('events.checkin.extension_note', { meeting_duration: meetingDuration }) }}</p>
        <div class="controls">
          <button
            class="btn btn-large btn-secondary btn-full"
            @click.prevent="isAddExtension = false"
          >{{ $translate('events.checkin.cancel') }}</button>
          <button
            class="btn btn-large btn-full btn-krypton-pink waves-effect waves-light"
            @click.prevent="extendNow"
          >
            <span v-if="!isSending">{{ $translate('events.checkin.extend_now') }}</span>
            <div v-else class="preloader-wrapper small active">
              <div class="spinner-layer spinner-green-only">
                <div class="circle-clipper left">
                  <div class="circle" />
                </div>
                <div class="gap-patch">
                  <div class="circle" />
                </div>
                <div class="circle-clipper right">
                  <div class="circle" />
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div v-if="!isAddExtension" class="container">
        <md-slide-to-unlock
          v-model="pulleeVal"
          :min="0"
          :max="100"
          :label="$translate('events.checkin.slide_check_out')"
          :disabled="false"
          :is-reversed="true"
          :is-sending="isSending"
        />
        <a
          href="#"
          class="get-support-link grey-text"
          @click.prevent="onClickSupport"
        >{{ $translate('events.checkin.get_support') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import NullSelectCheck from './mixins/nullSelectCheck.js'
import { DURATION_FEES } from '../../../stores/events.js'
import { MAX_CHECKIN_HR_EXTENSION } from '../../../constants/global'

import { mapGetters, mapActions } from 'vuex'
import { getCurrencyByCountry } from '@/helpers/UnitHelper/index.js'

import { getTierData } from '@/services/TierService'

export default {
  name: 'EventCheckin',
  mixins: [NullSelectCheck],
  data () {
    return {
      isAddExtension: false,
      isSending: false,

      pulleeVal: 100,
      timeoutID: null,
      hrExtension: 1,
      tierData: {}
    }
  },
  computed: {
    ...mapGetters({
      activeEventTimeElapsed: 'events/getActiveEventTimeElapsed',
      totalHrExtension: 'events/getActiveEventTotalExtension',
      activeEvent: 'events/getActiveEvent',
      user: 'getUser'
    }),
    currency () {
      return this.user?._currency ?? '€'
    },
    countdown () {
      return moment()
        .hour(0)
        .minutes(0)
        .second(Math.abs(this.activeEventTimeElapsed))
        .format('HH:mm:ss')
    },
    meetingDurationAmountPayable () {
      if (this.selectedEvent) {
        return this.selectedEvent.amount_payable
      }
      return 0
    },
    meetingDuration () {
      let duration = 0

      if (this.selectedEvent) {
        duration = this.selectedEvent.meeting_duration
      }
      if (this.totalHrExtension) {
        return `${duration} + ${this.$translate('events.labels.meeting_hours', {
          hours: this.totalHrExtension
        })}`
      }

      return this.$translate('events.labels.meeting_hours', {
        hours: duration
      })
    },
    amountPayable () {
      if (this.selectedEvent) {
        if (this.totalHrExtension) {
          const totalHours = this.selectedEvent.meeting_duration + this.totalHrExtension

          const tierRate = this.tierData[`rate_${totalHours}hr`]
          let extensionAmount = 0
          const tierRateDurationBased = this.tierData[`rate_${this.selectedEvent.meeting_duration}hr`]
          extensionAmount = Math.abs(tierRate - tierRateDurationBased)

          return `${this.currency}${this.tierData[`rate_${this.selectedEvent.meeting_duration}hr`]} + ${extensionAmount}`
        }
      }

      return `${this.currency}${this.meetingDurationAmountPayable}`
    },
    computedFee () {
      const totalExtension = this.totalHrExtension
        ? this.totalHrExtension + this.hrExtension
        : this.hrExtension
      const totalHours = this.selectedEvent.meeting_duration + totalExtension

      const tierRate = this.tierData[`rate_${totalHours}hr`]

      return `${this.currency}${this.meetingDurationAmountPayable} + ${Math.abs(
        tierRate - this.selectedEvent.amount_payable
      )}`
    },
    computedHrExtension () {
      const extension = this.$translate('events.labels.meeting_hours', {
        hours: this.totalHrExtension + this.hrExtension
      })

      return `${this.selectedEvent.meeting_duration} + ${extension}`
    },
    canAddMoreExtension () {
      return this.totalHrExtension < MAX_CHECKIN_HR_EXTENSION && !this.isVideoDateMeeting
    },
    tier () {
      if (this.user) {
        return this.user.settings.tier
      }
      return 1
    },
    isVideoDateMeeting () {
      return this.selectedEvent.from_video_form === 1
    }
  },
  watch: {
    pulleeVal (newVal, oldVal) {
      if (newVal <= oldVal) {
        if (this.timeoutID) {
          clearTimeout(this.timeoutID)
        }
        if (newVal === 0) {
          this.slideEnd()
          this.timeoutID = null
          return
        }

        this.timeoutID = setTimeout(() => {
          while (this.pulleeVal !== 100) {
            this.pulleeVal += 1
          }
          this.pulleeVal = 100
          this.timeoutID = null
          clearTimeout(this.timeoutID)
        }, 100)
      }
    },
    activeEvent (val) {
      if (val === null) {
        this.$router.replace({ name: 'events' })
      }
    }
  },
  async mounted () {
    try {
      const tierData = await getTierData()
      this.tierData = tierData.data
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    ...mapActions([
      'getUserDetails'
    ]),
    slideEnd () {
      if (this.isSending) return

      this.isSending = true
      this.$store
        .dispatch('events/checkout', this.selectedEvent)
        .then(() => {
          this.$notifications.toast(this.$translate('events.checkin.checkout_toast'), 5000)
          this.$store.dispatch('events/syncEvents')

          if (this.user.availability_enabled) {
            this.$store.dispatch('availabilities/refreshAvailabilities')
          }

          this.getUserDetails()

          this.$router.replace({ name: 'event-rate' })
        })
        .finally(() => {
          this.isSending = false
        })
    },
    incrementExtension () {
      if (this.hrExtension + this.totalHrExtension < MAX_CHECKIN_HR_EXTENSION) {
        this.hrExtension += 1
      }
    },
    decrementExtension () {
      if (this.hrExtension > 1) {
        this.hrExtension -= 1
      }
    },
    extendNow () {
      if (this.isSending) return

      this.isSending = true
      this.$store
        .dispatch('events/addExtension', {
          id: this.selectedEvent.id,
          extension: this.hrExtension
        })
        .then(() => {
          this.$store.commit('events/ADD_EXTENSION', this.hrExtension)
        })
        .finally(() => {
          this.isSending = false
          // Reset selection
          this.hrExtension = 1
          this.isAddExtension = false
        })
    },
    onClickSupport () {
      if (this.selectedEvent._support === null) {
        const payload = {
          id: (this.selectedEvent._support === null) ? 'new' : this.selectedEvent._support.id,
          ticketID: this.selectedEvent.id
        }
        this.$router.push({
          name: 'support-v2-request',
          params: payload
        })
      } else {
        this.$store.commit('events/RESET_SELECTED_EVENT_SUPPORT_UNREAD')
        this.$router.push({
          name: 'support-v2-request',
          query: {
            id: this.selectedEvent._support.id
          }
        })
      }
    }
  }
}
</script>
