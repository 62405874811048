<template>
  <div id="inbox-list">
    <inbox-filter
      :value="filter"
      @click="onClick"
    />
    <button
      v-if="isReviewFilter"
      type="button"
      class="feedback-cta-btn btn btn-secondary btn-large display-block waves-effect waves-dark margin-bottom-md capitalize"
      @click.prevent="$router.replace({ name: 'feedback' })">{{ $translate('inbox.cta_buttons.see_performance') }}</button>
    <inbox-list-item
      v-for="(message, idx) in messages"
      :key="idx"
      v-bind="message"
      :has-notification="message.hasNotification"
      :updated-at="message.lastReceivedAt ? message.lastReceivedAt : message.updatedAt"
    />
    <button v-if="showLoadMore" class="load-more" @click="loadMore">
      <span>LOAD MORE</span>
    </button>
    <infinite-loading
      ref="infiniteLoader"
      direction="top"
      spinner="spiral"
      :identifier="filter"
      @infinite="infiniteHandler">
      <div slot="no-more" />
      <div slot="no-results" />
    </infinite-loading>
    <button
      v-if="isEmpty && !isFetching && !isReviewFilter"
      type="button"
      class="btn btn-secondary btn-large display-block waves-effect waves-dark btn-inbox-connect-faq"
      @click.prevent="$router.replace({ name: 'connect-faq' })">
      <i class="mdi mdi-information valign-middle" />&nbsp;&nbsp;<strong class="valign-middle">{{ $translate('inbox.cta_buttons.goto_faq') }}</strong>
    </button>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'

import { INBOX_FILTER } from '@/features/inbox/constants'

import ControllerMixin from './InboxList.controller'

export default {
  components: {
    InfiniteLoading
  },
  mixins: [ControllerMixin],
  props: {
    filter: {
      type: String,
      default: INBOX_FILTER.ALL
    }
  }
}
</script>

<style lang="scss" scoped>
@import "InboxList.style";
</style>
