<template>
  <div
    id="bottom-sheet-menu"
    :class="{
      'bottom-sheet-menu-backdrop': newDesign,
      'bottom-sheet-menu-backdrop_pre_existing': !newDesign,
      '--desktop-split': isDesktopDisplayModeSplit
    }">
    <div :class="{
      'menu-container': newDesign,
      'menu-container_pre_existing': !newDesign
    }">
      <div class="close-cta" @click.prevent="onClose"><img src="/img/registration/bottom-sheet-close.svg" alt=""></div>
      <div ref="header" class="title">{{ title }}</div>
      <div ref="content" class="option-menu">
        <div
          v-for="(option, i) in options"
          :key="i"
          class="option-item-container"
          :class="{'disabled': option.disabled}"
        >
          <div class="option-item" @click.prevent="select(option)">{{ option.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const DESKTOP_DISPLAY_MODES = Object.freeze({
  SPLIT: 'split',
  MIDDLE: 'middle'
})

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: null
    },
    desktopDisplayMode: {
      type: String,
      default: 'middle'
    },
    newDesign: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDesktopDisplayModeSplit () {
      return this.desktopDisplayMode === DESKTOP_DISPLAY_MODES.SPLIT
    }
  },
  methods: {
    onClose () {
      this.$emit('onClose')
    },
    select (option) {
      if (option.disabled) return false
      this.$emit('onSelect', option.val)
    }
  }
}
</script>

<style lang="scss" scoped>

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-up) {
  0% { transform: translateY(90%); }
  100% { transform: translateY(0); }
}

@include keyframes(slide-down) {
  0% { transform: translateY(0); }
  100% { transform: translateY(90%); }
}

#bottom-sheet-menu {

  & {
    display:none;
  }

  &.bottom-sheet-menu-backdrop {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 600px;
    height: 100%;
    background: rgba(#000000, 0.7);
    z-index: 199;
    justify-content: center;
    align-items: flex-end;
    box-sizing: content-box;

    @media (min-width: 992px) {
      &.--desktop-split {
        justify-content: flex-end;

        .menu-container {
          max-width: none;
          width: 50%;
          align-content: flex-end;
          padding: 0;
          border-radius: 0;
          max-height: none;
          height: 100%;
        }

        .close-cta {
          margin-left: 48px;
          margin-top: 24px;
          cursor: pointer;
        }

        .title {
          max-width: 464px;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
        }

        .option-menu {
          max-width: 464px;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
        }
      }
    }
  }

  &.bottom-sheet-menu-backdrop_pre_existing {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000000, 0.7);
    z-index: 998;
    justify-content: center;
    align-items: flex-end;
    box-sizing: content-box;

    @media (min-width: 992px) {
      &.--desktop-split {
        justify-content: flex-end;

        .menu-container_pre_existing {
          max-width: none;
          width: 50%;
          align-content: flex-end;
          padding: 0;
          border-radius: 0;
          max-height: none;
          height: 100%;
        }

        .close-cta {
          margin-left: 48px;
          margin-top: 24px;
          cursor: pointer;
        }

        .title {
          max-width: 464px;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
        }

        .option-menu {
          max-width: 464px;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
        }
      }
    }
  }

  .menu-container {
    position: relative;
    margin: 0;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    font-family: 'Proxima Nova Semibold';
    border-radius: 12px 12px 0 0;
    padding-left: 24px;
    padding-right: 24px;
    max-height: 90%;
    height: 90%;
    display: none;
    flex-direction: column;

    .close-cta {
      margin-top: 7px;
      margin-bottom: 8px;
      margin-left: -16px;
      width: 48px;
    }

    .title {
      font-family: 'Proxima Nova Semibold';
      font-size: 22px;
      text-align: left;
      color: #2F3740;
      margin-bottom: 12px;
    }

    .option-menu {
      max-height: 37em;
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      flex: 1 1 auto;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      @media (min-width: 992px) {
        height: 100%;
      }

      .option-item-container {
        .option-item {
          border-bottom: 1px solid #CED6E0;
          padding-top: 20px;
          padding-bottom: 20px;
          font-family: 'Proxima Nova Medium';
          color: #2F3740;
          font-size: 18px;
        }

        &.disabled > .option-item {
          color: rgba(0, 0, 0, 0.3);
        }

        &:last-child > .option-item {
          border-bottom: none;
        }
      }
    }
  }

  .menu-container_pre_existing {
    position: relative;
    margin: 0;
    width: 100%;
    max-width: 375px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    font-family: 'Proxima Nova Semibold';
    border-radius: 12px 12px 0 0;
    padding-left: 24px;
    padding-right: 24px;
    max-height: 90%;
    height: 90%;
    display: none;
    flex-direction: column;

    .close-cta {
      margin-top: 7px;
      margin-bottom: 8px;
      margin-left: -16px;
      width: 48px;
    }

    .title {
      font-family: 'Proxima Nova Semibold';
      font-size: 22px;
      text-align: left;
      color: #2F3740;
      margin-bottom: 12px;
    }

    .option-menu {
      max-height: 37em;
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      flex: 1 1 auto;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      @media (min-width: 992px) {
        height: 100%;
      }

      .option-item-container {
        .option-item {
          border-bottom: 1px solid #CED6E0;
          padding-top: 20px;
          padding-bottom: 20px;
          font-family: 'Proxima Nova Medium';
          color: #2F3740;
          font-size: 18px;
        }

        &.disabled > .option-item {
          color: rgba(0, 0, 0, 0.3);
        }

        &:last-child > .option-item {
          border-bottom: none;
        }
      }
    }
  }

  &.element--visible {
    display: flex;

    .menu-container, .menu-container_pre_existing {
      display: flex;
      @include animation('slide-up 0.3s ease');
    }
  }
}
</style>
