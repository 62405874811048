import LocalizationService, { ENGLISH_LOCALE } from '@/services/LocalizationService.js'

const routesWithENSegment = [
  'register-start',
  'login',
  'forgot-password',
  'forgot-password-success',
  'reset-password',
  'referral',
  'request-magic-link',
  'request-magic-link-success'
]

export default function localeCheck (to, from, next) {
  attemptLocaleCheck(to)
  next()
}

export async function attemptLocaleCheck (to) {
  let appLocale = await LocalizationService.getSavedLocale()
  let routeLocale

  const getRouteLocale = () => {
    let routeLocale = LocalizationService.defaultLocale()

    if (to.params?.lang !== undefined && to.params?.lang === ENGLISH_LOCALE) {
      routeLocale = ENGLISH_LOCALE
    }

    return routeLocale
  }

  if (appLocale === null) {
    appLocale = LocalizationService.defaultLocale()
  }

  if (routesWithENSegment.includes(to.name)) {
    routeLocale = getRouteLocale()

    if (appLocale !== routeLocale) {
      appLocale = routeLocale
      LocalizationService.saveLocale(appLocale)
    }
  }

  LocalizationService.setLocale(appLocale)
}
