import { detectIncognito } from 'detectincognitojs'

/** */
/**
 * Start detecting incognito mode
 * @param {number} interval - The interval in milliseconds. Set to less than 1 to instant call without interval
 * @param {function} callback - The callback function. Accepts two parameters: browserName: string and isPrivate: boolean. Will receive nothing if error occurs
 * @returns {function} - The function to stop detecting
 */
const start = (interval, callback) => {
  const incognitoHandler = async (callback) => {
    try {
      const result = await detectIncognito()
      callback(result.browserName, result.isPrivate)
    } catch (error) {
      console.error('incognito service error.', error)
    }
  }

  incognitoHandler(callback)

  const intervalRequirement = 0
  if (interval > intervalRequirement) {
    const intervalId = setInterval(() => incognitoHandler(callback), interval)
    return () => clearInterval(intervalId)
  } else {
    // terminate nothing
    return () => ({})
  }
}

export default { start }
