var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.photoTrigger === _vm.PHOTO_TRIGGER_VALUES.OTHER_PHOTO
        ? _c(
            "a",
            {
              staticClass: "add-more center-align",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.showPhotoTipsForFirstTimeUser($event)
                }
              }
            },
            [_c("div", { staticClass: "add-btn" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.photoTrigger === _vm.PHOTO_TRIGGER_VALUES.PHOTO_TIPS_CTA
        ? _c(
            "button",
            {
              staticClass: "btn btn-black btn-photo-tip-upload",
              attrs: { "data-test": "upload-cta-navigator" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.showFilePicker($event)
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  src:
                    "/img/registration/tips/photo-tips/cta-upload-photo-icon.svg",
                  alt: ""
                }
              }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$translate("photos.add_photo2")))])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.photoTrigger === _vm.PHOTO_TRIGGER_VALUES.OTHER_PHOTO_PILL
        ? _c(
            "div",
            {
              staticClass: "upload-pill",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.showPhotoTipsForFirstTimeUser($event)
                }
              }
            },
            [
              _c("i", { staticClass: "material-icons" }, [
                _vm._v("arrow_upward")
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$translate("verify.upload.cta_button")))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("vform", { ref: "uploadForm", on: { submit: _vm.uploadPhoto } }, [
        _c("input", {
          ref: "uploadFormFile",
          staticClass: "hide",
          attrs: { type: "file", accept: "image/*" },
          on: { change: _vm.uploadFormSubmit }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }