<template>
  <div id="pending-event-modal" class="modal left-align" style="max-height: 80%;">
    <div class="modal-content" style="padding: 32px 12px 2px;">
      <h4 class="modal-title center" style="font-size: 17px;">
        {{ $translate('events.pending_event_popup.title') }}
      </h4>

      <div class="apv-card">
        <div class="card-header">
          <div class="card-header__flex">
            <pre class="date uppercase">{{ date }}</pre>
          </div>
          <div class="grey-text card-header__flex card-header__flex--mid">
            <em class="schedule">
              <span class="schedule__countdown lilac lilac--red" style="font-size: 12px; color: #757575;">
                <strong style="color: #2F3740; font-size: 16px;">{{ meetingStart }}</strong> {{ $translate('events.labels.for') }} {{ evt.meeting_duration }}h
              </span>
            </em>
            <div class="location">
              <p v-if="isVideoDateMeeting" class="margin-none krypton-purple-text">{{ $translate('events.video_date.title') }}</p>
              <div v-else-if="isPrivatePlaceOrMultiLocationMeeting" class="event-details-hotel-name margin-none">
                <i v-if="isPrivatePlaceMeeting" data-test="event-details-pp-icon" class="material-icons">person_pin_circle</i>
                <multi-location-event-icon v-if="isMultiLocationMeeting" />
                <span v-if="isMultiLocationMeeting">{{ evt._city_name }}</span>
                <span v-else>{{ evt._hotel_name }}</span>
              </div>
              <p v-else class="margin-none">{{ evt._hotel_name }}</p>
            </div>
          </div>
          <div class="card-header__flex">
            <strong class="amount">
              <currency />{{ evt.amount_payable }}
              <div v-if="isMultiLocationMeeting">+ <currency />{{ evt.travel_cost }}</div>
            </strong>
          </div>
        </div>
        <multi-location-event-address v-if="isMultiLocationMeeting" :event="evt" />
        <div class="card-content">
          <div class="row controls">
            <strong class="expiration-time">
              <span>{{ $translate('events.labels.expires') }} {{ acceptCountdown }}</span>
            </strong>
            <label style="font-size: 12px">
              {{ $translate('events.labels.acceptance_note') }}
            </label>
          </div>
        </div>
      </div>

      <p class="grey-text text-darken-1 center" style="font-size: 14px;" />
    </div>
    <div class="modal-footer">
      <button class="btn-flat waves-effect waves-dark modal-close" @click="closeModal">
        {{ $translate('events.buttons.stay_here') }}
      </button>
      <button class="btn-flat waves-effect waves-dark krypton-pink-text" @click="confirm">
        {{ $translate('events.buttons.respond_now') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    evtID: { type: Number, required: true },
    evt: { type: Object, required: true },
    vm: { type: Object, required: true }
  },
  computed: {
    isPrivatePlaceMeeting () {
      return this.pendingEvt?.is_private_place === 1
    },
    isMultiLocationMeeting () {
      return this.pendingEvt?.is_multi_location === 1
    },
    isPrivatePlaceOrMultiLocationMeeting () {
      return this.isPrivatePlaceMeeting || this.isMultiLocationMeeting
    },
    pendingEvt () {
      const idx = this.vm.events.findIndex(e => e.id === this.evtID)

      if (idx >= 0) {
        return this.vm.events[idx]
      }
      return null
    },
    meetingStart () {
      if (this.pendingEvt) {
        return moment(this.pendingEvt.meeting_start_at).format('HH:mm')
      }

      return ''
    },
    date () {
      if (this.isToday) {
        return this.$translate('apv-card.today')
      }
      if (this.pendingEvt) {
        const meetingStart = moment(this.pendingEvt.meeting_start_at)

        return `${meetingStart.format('dd')}\n${meetingStart.format('DD')}`
      }

      return ''
    },
    isToday () {
      const today = moment()

      if (this.pendingEvt) {
        return moment(this.pendingEvt.meeting_start_at).format('YYYY-MM-DD') === today.format('YYYY-MM-DD')
      }

      return false
    },
    acceptCountdown () {
      if (this.pendingEvt) {
        if (this.pendingEvt._expiration_in_seconds !== null &&
                this.pendingEvt._expiration_in_seconds > 0) {
          return moment().hour(0)
            .minutes(0)
            .second(this.pendingEvt._expiration_in_seconds)
            .format('HH[h] : mm[m] : ss[s]')
        }
      }
      return '00h : 00m : 00s'
    },
    isVideoDateMeeting () {
      if (this.pendingEvt) {
        return this.pendingEvt.from_video_form === 1
      }
      return false
    }
  },
  watch: {
    evt: {
      handler (newVal, oldVal) {
        // Actively check if pending popup needs to be shown when there's a cancellation on
        // the current event.
        if (this.pendingEvt &&
              (this.pendingEvt.status === TICKET_STATUS.CANCELLED ||
               this.pendingEvt._event_status === EVENT_STATUS.EXPIRED)) {
          this.$emit('confirm')
        }
      },
      deep: true
    }
  },
  methods: {
    gotoEventDetail () {
      if (this.pendingEvt._expiration_in_seconds > 0) {
        this.vm.$store.commit('events/SET_SELECTED_BY_ID', this.pendingEvt.id)
        this.vm.$store.commit('events/PENDING_EVENT_SEEN', this.pendingEvt.id)
        this.vm.$router.push({ name: 'event-details' })
      } else {
        this.$notifications.toast('Event has expired.', 5000)
      }
    },
    confirm ($e) {
      this.$emit('confirm', $e)
      this.gotoEventDetail()
    },
    closeModal () {
      this.vm.$store.commit('events/PENDING_EVENT_SEEN', this.evt.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.alarm-icon .material-icons {
    font-size: 72px;
    color: #BFB38F;
}
.modal-content {
    padding-bottom: 0;
}
.modal-footer {
    text-align: center;
}
.event-details-hotel-name {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2E353D;
  margin-left: -8px;

  .material-icons {
    font-size: 20px;
    margin-right: 4px;
  }
}
</style>
