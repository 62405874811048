var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-page" }, [
    _c(
      "div",
      {
        staticClass: "interview-list clickable",
        class: { "add-moded": _vm.isAddMode },
        on: {
          click: function($event) {
            $event.preventDefault()
            return (function() {
              return _vm.$emit("item-clicked", _vm.selectedItem)
            })($event)
          }
        }
      },
      [
        _c("p", { staticClass: "margin-none" }, [
          _c("small", { staticClass: "question-counter" }, [
            _vm._v(_vm._s(_vm.questionLabel))
          ])
        ]),
        _vm._v(" "),
        _vm.isAddMode
          ? _c("div", { staticClass: "add-text" }, [
              _c("div", { staticClass: "left-align" }, [
                _c(
                  "p",
                  { staticClass: "question margin-bottom margin-top-none" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$translate("interview.select_a_question")) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "answer left-align" }, [
                _c("p", { staticClass: "margin-top-none" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$translate("interview.add_new_answer_cta")) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("i", { staticClass: "k-green material-icons" }, [
                _vm._v("add_circle")
              ])
            ])
          : _c("div", [
              _c("div", { staticClass: "left-align" }, [
                _c(
                  "p",
                  { staticClass: "question margin-bottom margin-top-none" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$store.getters.getDropdownInterviews(
                            _vm.interview.title
                          )
                        ) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "answer left-align" }, [
                _c("p", { staticClass: "margin-top-none" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.interview.answer) + "\n        "
                  )
                ])
              ])
            ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }