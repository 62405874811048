var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "navbar-fixed navbar navbar-event",
          class: _vm.statusBarClass
        },
        [
          _c("nav", { staticClass: "z-depth-0 center-align pre" }, [
            _c("div", { staticClass: "nav-wrapper container" }, [
              _c(
                "a",
                {
                  staticClass: "brand-logo",
                  class: { "grey-text": _vm.disabled },
                  attrs: { id: "navbar-event-back-btn", href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.redirect($event)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v(_vm._s(_vm.icon))
                  ]),
                  _vm._v(" "),
                  _c("strong", [_vm._v(_vm._s(_vm.brand))])
                ]
              ),
              _vm._v(" "),
              _vm.isReady && !_vm.isCheckIn
                ? _c("strong", { staticClass: "center-countdown countdown" }, [
                    _vm._v(_vm._s(_vm.meetingCountdown))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isReactivated && !_vm.isCheckIn
                ? _c("strong", { staticClass: "center-countdown countdown" }, [
                    _vm._v(_vm._s(_vm.confirmationCountdown))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("ul", { staticClass: "right margin-right-md" }, [
                _vm.isPending && !_vm.showHelp
                  ? _c("li", { staticClass: "countdown-holder" }, [
                      _c("strong", { staticClass: "countdown" }, [
                        _vm._v(
                          _vm._s(_vm.$translate("events.labels.expires")) +
                            " " +
                            _vm._s(_vm.confirmationCountdown)
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isPending && !_vm.showHelp && _vm.showCurrency
                  ? _c(
                      "li",
                      { staticClass: "padding-right-md amount-payable" },
                      [
                        _c(
                          "strong",
                          [
                            _c("currency"),
                            _vm._v(_vm._s(_vm.selectedEvent.amount_payable))
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showHelp
                  ? _c("li", { staticClass: "padding-x-sm" }, [
                      _c("div", {
                        staticClass: "white-icon",
                        attrs: { id: "robot-icon" }
                      })
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("md-sidenav")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }