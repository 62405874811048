<template>
  <div id="account-setting" class="wrapper">
    <md-new-navbar route-name="setting" />
    <div id="content" class="container account-overview">
      <h1 class="account-overview__title">{{ $translate('setting.contact_details') }}</h1>
      <div class="account-overview__item">
        <div class="account-overview__item-field">
          <div class="account-overview__item-label">{{ $translate('setting.mobile_phone_label') }}</div>
          <div class="account-overview__item-value">{{ maskedPhone }}</div>
        </div>
        <a
          href="#"
          class="account-overview__item-cta"
          @click.prevent="() => isMobileSettingShown = true"
        ><strong>{{ $translate('app.edit') }}</strong></a>
      </div>
      <div class="account-overview__item">
        <div class="account-overview__item-field">
          <div class="account-overview__item-label">{{ $translate('setting.email_label') }}</div>
          <div class="account-overview__item-value">{{ user.email }}</div>
        </div>
        <a
          href="#"
          class="account-overview__item-cta"
          @click.prevent="() => isEmailSettingShown = true"
        ><strong>{{ $translate('app.edit') }}</strong></a>
      </div>
      <div class="account-overview__item">
        <div class="account-overview__item-field">
          <div class="account-overview__item-label">{{ $translate('setting.password_label') }}</div>
          <div class="account-overview__item-value">{{ password }}</div>
        </div>
        <a
          href="#"
          class="account-overview__item-cta"
          @click.prevent="() => isPasswordSettingShown = true"
        ><strong>{{ $translate('app.edit') }}</strong></a>
      </div>
    </div>

    <mobile-setting
      :is-shown="isMobileSettingShown"
      @close="() => isMobileSettingShown = false"
    />

    <email-setting
      :is-shown="isEmailSettingShown"
      @close="() => isEmailSettingShown = false"
      @success-request="(email) => {
        isEmailSettingModalShown = true
        sendEmailTo = email
      }"
    />

    <password-setting
      :is-shown="isPasswordSettingShown"
      @close="() => isPasswordSettingShown = false"
    />

    <email-setting-modal
      :is-shown="isEmailSettingModalShown"
      :email="sendEmailTo"
      @confirm="() => isEmailSettingModalShown = false"
    />
  </div>
</template>

<script>
import mobileSetting from './mobile-setting.vue'
import emailSetting from './email-setting.vue'
import emailSettingModal from './email-setting-modal.vue'
import passwordSetting from './password-setting.vue'

export default {
  name: 'AccountSetting',
  components: { mobileSetting, emailSetting, emailSettingModal, passwordSetting },
  data () {
    return {
      password: '**********',
      isMobileSettingShown: false,
      isEmailSettingShown: false,
      isEmailSettingModalShown: false,
      isPasswordSettingShown: false,
      sendEmailTo: ''
    }
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    maskedPhone () {
      if (!this.user.phone) {
        return ''
      }

      const condition = /(.+)(.{3})/gi
      const exp = new RegExp(condition)
      const result = exp.exec(this.user.phone)

      if (result) {
        return result[1].replace(/\S/g, '*') + result[2]
      }
      return ''
    }
  },
  methods: {
    gotoPage (name) {
      this.$router.push({ name: name })
    }
  }
}
</script>

<style lang="scss" scoped>
#account-setting {
  background: white;

  > .container#content.account-overview {
    background-color: white;
    padding: 0 24px 24px;
  }

  .account-overview__title {
    font-size: 26px;
    color: #2F3740;
    margin-top: 5px;
    margin-bottom: 32px;
  }

  .account-overview__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 24px 0 16px;

    &:first-of-type {
      padding: 0 0 16px;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid #CED6E0;
    }
  }

  .account-overview__item-field {
    text-align: left;

    .account-overview__item-label  {
      color: #2F3740;
      font-size: 18px;
      margin-bottom: 9px;
    }

    .account-overview__item-value {
      color: #737B85;
      font-size: 16px;
    }
  }

  .account-overview__item-cta {
    color: #E30074;
    text-decoration: underline;
    text-transform: uppercase;
  }
}
</style>
