/**
 * A singleton class that manages the modal to be show in the app by queue.
 *
 * @class ModalManager
*/
export class ModalManager {
  /**
   * @constructor
   *
   * @returns {ModalManager}
  */
  constructor () {
    // Only allow one instance of the class.
    if (ModalManager.instance) {
      return ModalManager.instance
    }

    ModalManager.instance = this

    /**
     * @property {Number} counter - Counter value for tracking the number of modals passed.
    */
    this.counter = 0

    /**
     * @property {Modal[]>} queue - The list of modals to show.
    */
    this.queue = []

    return this
  }

  /// ///////////////
  // Class getters
  /// ///////////////

  get hasActiveModal () {
    return this.getActiveModal() !== null
  }

  /// ///////////////
  // Class methods
  /// ///////////////

  /**
   * Add modal to queue.
   *
   * @param {Modal} modal - The Modal model
   * @param {Boolean} [preventDuplicate = true] - Prevent duplicate modal in the queue by checking the `id`.
  */
  addToQueue (modal, preventDuplicate = true) {
    // Check only for duplicate if the modal has value for `id`.
    if (preventDuplicate && (modal.id || typeof modal.id === 'number')) {
      const r = this.queue.find(m => m.id === modal.id)

      if (!r) {
        this.queue.push(modal)
        this.counter++
      }
    } else {
      this.queue.push(modal)
      this.counter++
    }

    // Sort queue by priority level - descending order
    this.queue = this.queue.sort((a, b) => b.priority - a.priority)
  }

  /**
   * Show next modal in the queue.
  */
  showNextModal () {
    // No need to go through the code if the queue is empty.
    if (this.queue.length === 0) return

    // Only allow one modal to show at a time.
    if (this.hasActiveModal) return

    // Use FIFO (First-in, First-out) algorithm.
    const modal = this.queue.shift()

    this.showModal(modal)
  }

  /**
   * Show modal in the UI.
   *
   * @param {Modal} modal
  */
  showModal (modal) {
    const curRoute = document.location.pathname

    // Check if the modal has restrictions in showing based on the app route.
    if (ModalManager.cannotShowModal(curRoute, modal.excludedRoutes)) return

    // Create new instance of the component
    const vInstance = Vue.component(modal.name)
    const ExModal = Vue.extend(vInstance)

    const newModalConfig = {
      propsData: modal.propsData
    }

    newModalConfig.store = require('@/stores').default

    newModalConfig.i18n = window.i18n

    const newModal = new ExModal(newModalConfig)

    newModal.$mount()

    // Mount created component to the UI.

    var flyModal = $(newModal.$el).appendTo('#app')

    flyModal.modal({
      dismissible: modal.opts.dismissible,

      complete: () => {
        flyModal.remove()
      }
    })

    newModal.$on('confirm', ($e) => {
      if (modal.callback) {
        modal.callback($e)
      }

      flyModal.modal('close')
    })

    flyModal.modal('open')

    modal.opts.onOpen()
  }

  /**
   * Get current modal being shown in the app.
   *
   * @returns {HTMLDivElement | Null}
  */
  getActiveModal () {
    // We'll assume all modals will have `modal` class.
    const results = document.getElementsByClassName('modal').length

    if (results > 0) {
      return results[0]
    }

    return null
  }

  /**
   * Clear list.
  */
  clear () {
    this.queue = []
    this.counter = 0
  }

  /**
   * Check if route is excluded to be shown.
  */
  static cannotShowModal (page, excludedRoutes) {
    return excludedRoutes.some(er => {
      const r = new RegExp(er)

      return r.test(page)
    })
  }
}

export default new ModalManager()
