import ClientRating from './ClientRating.vue'
import PerformanceCard from './PerformanceCard.vue'
import PerformanceOverview from './PerformanceOverview.vue'
import PerformanceRating from './PerformanceRating.vue'
import ReliabilityRating from './ReliabilityRating.vue'

Vue.component('client-rating', ClientRating)
Vue.component('performance-card', PerformanceCard)
Vue.component('performance-overview', PerformanceOverview)
Vue.component('performance-rating', PerformanceRating)
Vue.component('reliability-rating', ReliabilityRating)
