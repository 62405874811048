import NotificationProvider from '@/providers/NotificationProvider'

/**
 * Vuex State
*/
const state = {
  inbox_count: 0,
  has_new_feedback: 0
}

/**
 * State getters
*/
const getters = {
  GET_INBOX_COUNT (state) {
    return state.inbox_count
  },
  HAS_INBOX_NOTIFICATION (state) {
    return state.inbox_count > 0
  },
  GET_HAS_NEW_FEEDBACK (state) {
    return state.has_new_feedback !== 0
  }
}

/**
 * State mutations
*/
const mutations = {
  SET_INBOX_COUNT (state, newCount = 0) {
    state.inbox_count = newCount
  },
  SEEN_INBOX (state) {
    state.inbox_count = 0
  },
  SEEN_ALL (state) {
    for (const key in state) {
      state[key] = 0
    }
  },
  INCREMENT_INBOX_COUNT (state) {
    state.inbox_count++
  },
  SET_HAS_NEW_FEEDBACK (state, value) {
    state.has_new_feedback = value
  }
}

/**
 * State actions
*/
const actions = {
  FETCH ({ commit }) {
    NotificationProvider.get()
      .then((res) => {
        commit('SET_INBOX_COUNT', res.data.inbox_count)
        commit('SET_HAS_NEW_FEEDBACK', res.data.has_new_feedback)
      })
  },
  SEEN_FEEDBACK_NOTIFICATION ({ commit }) {
    NotificationProvider.seen('has_new_feedback')
      .then((res) => {
        commit('SET_HAS_NEW_FEEDBACK', 0)
      })
  }
}

/// ////////////////////////
// Nothing to do here...
/// ////////////////////////

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
