var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "super-provider-card super-provider-card__req margin-bottom-md",
      class: _vm.cardStatusClass,
      attrs: { id: "prime-time-availability" }
    },
    [
      _c("h2", {
        staticClass: "super-provider-card__title margin-bottom-lg",
        attrs: { "data-test": "sp-pta-title" },
        domProps: { textContent: _vm._s(_vm.title) }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "super-provider-card__value margin-bottom-md" },
        [
          _c(
            "i",
            {
              staticClass: "material-icons margin-right-md",
              attrs: { "data-test": "sp-pta-icon" }
            },
            [_vm._v("assignment_turned_in")]
          ),
          _vm._v(" "),
          _c("span", { attrs: { "data-test": "sp-pta-value" } }, [
            _vm._v(_vm._s(_vm.actualPrimeTimeAvailabilityHours))
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "super-provider-card__tooltip-trigger",
          attrs: { "data-test": "sp-pta-tooltip" },
          on: { click: _vm.showTooltip }
        },
        [
          _c("span", {
            staticClass: "super-provider-card__tooltip-trigger-text",
            domProps: { textContent: _vm._s(_vm.tooltipText) }
          }),
          _vm._v(" "),
          _c("i", { staticClass: "material-icons" }, [_vm._v("help_outline")])
        ]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "text-content" }, [
        _vm._v(
          _vm._s(
            _vm.$t("statusChecks.primeTimeAvailability.missingHours", {
              hours: _vm.remainingPTAHours
            })
          )
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "prime-time margin-top-md",
          attrs: { "data-test": "sp-pta-primetime-info" }
        },
        [
          _c("i", { staticClass: "material-icons" }, [_vm._v("flash_on")]),
          _vm._v(" "),
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("statusChecks.primeTimeAvailability.primetime")
              )
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "super-provider-card__box-content",
          attrs: { "data-test": "sp-pta-exception-info" }
        },
        [
          _c("div", [
            _vm._v(
              _vm._s(
                _vm.$t("statusChecks.primeTimeAvailability.boxContent", {
                  successful_request_exception:
                    _vm.minReqSuccessfulRequestsException
                })
              ) + "\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "b",
            { attrs: { "data-test": "sp-pta-number-of-meetings-info" } },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "statusChecks.primeTimeAvailability.currentNumberOfMeetings",
                    {
                      num_of_meetings: _vm.actualSuccessfulRequests
                    }
                  )
                )
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "super-provider-card__req-status",
          attrs: { "data-test": "sp-pta-status" }
        },
        [
          _c("span", { domProps: { textContent: _vm._s(_vm.cardStatusText) } }),
          _vm._v(" "),
          _c("i", { staticClass: "material-icons" }, [
            _vm._v(_vm._s(_vm.cardStatusIcon))
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }