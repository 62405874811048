var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "super-provider-popup__title sp-popup__success" },
      [
        _c("img", {
          staticClass: "sp-popup-decor",
          attrs: { src: "/img/super-provider/popup-decor-4699.svg", alt: "" }
        }),
        _vm._v(" "),
        _c("h2", {
          staticClass: "sp-greetings",
          attrs: { "data-test": "sp-popup-title" },
          domProps: { textContent: _vm._s(_vm.$t("super_provider.greetings")) }
        }),
        _vm._v(" "),
        _c("p", {
          staticClass: "sp-subtitle",
          attrs: { "data-test": "sp-popup-subtitle" },
          domProps: { textContent: _vm._s(_vm.$t("super_provider.subtitle")) }
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "sp-popup-diamond-icon",
          attrs: {
            src: "/img/super-provider/sp-popup-diamond-success.svg",
            alt: ""
          }
        }),
        _vm._v(" "),
        _c("h2", {
          staticClass: "sp-title",
          attrs: { "data-test": "sp-popup-title" },
          domProps: { textContent: _vm._s(_vm.$t("super_provider.title")) }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }