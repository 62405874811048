<template>
  <div v-if="isViewReady" id="event-whereis">
    <md-navbar :push="{ name: 'event-details', params: { event: {} } }"
               :brand="$translate('events.nav_title')"
               :menu-trigger="false"
               icon="arrow_back" />
    <div class="content container">
      <div class="chat-container">
        <div class="warning">
          <i class="material-icons">warning</i>
          <div id="event-whereis-meeting-point"
               v-html="$translate('events.where_is.warning',
                                  { meeting_point: meetingPoint, client_name: selectedEvent.client.name })" />
        </div>
        <chat-bubble v-for="(c, idx) in chat" :key="idx"
                     :message="c.message"
                     :created-at="c._message_sent_at_in_seconds"
                     :seen="(c.group_id === 2) ? c.seen !== null : false"
                     :sent-by="(c.group_id === 2) ? c.sent_by : 0" />
        <div v-if="isFetching" class="margin-top-md padding center">Fetching messages...</div>
        <div v-if="remaining > 0 && !isFetching" class="message-left">
          <strong>{{ $translate('events.where_is.message_left', { count: remaining }) }}</strong>
        </div>
      </div>
      <div v-if="!isFetching">
        <form v-if="remaining > 0" @submit.prevent="send">
          <div class="chat-controls container">
            <md-textarea
              v-model="message"
              type="multiline"
              data-test="whereis"
              :placeholder="textPlaceholder"
              :disabled="!canSendMessage || chat.length === 0"
              :force-active="true"
              :maxlength="250"
              required
              @focus="onInputFocus"
            />
            <button
              id="event-whereis-send-btn"
              class="btn-floating btn-large btn-round waves-effect btn-krypton-pink waves-effect waves-light"
              type="submit"
              :class="{ 'animated infinite heartbeat' : chat.length === 0 }"
              :disabled="!canSendMessage">
              <i class="material-icons">send</i>
            </button>
          </div>
        </form>
        <div v-else class="placeholder-text krypton-pink-text center margin-top-sm">
          {{ $translate('events.where_is.no_message_left') }} <a class="underline clickable" @click.prevent="$router.push({
            name: 'support-v2',
            params: { navTitle: $translate('events.buttons.where_is_he') }
          })">{{ $translate('events.where_is.contact_support') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import NullSelectCheck from './mixins/nullSelectCheck.js'
import { PROVIDER_GROUP_ID } from '../../../constants/global'

const MAX_MESSAGE = 3

export default {
  mixins: [NullSelectCheck],
  computed: {
    ...mapGetters({
      chat: 'events/getMessages',
      user: 'getUser'
    }),
    remaining () {
      if (this.chat.length > 1) {
        const isFirstChat = this.chat[0].sent_by === this.user.id
        const yourMessages = this.chat
          .filter(c => c.sent_by === this.user.id &&
                                              c.group_id === PROVIDER_GROUP_ID)

        const remaining = MAX_MESSAGE - yourMessages.length

        return (isFirstChat) ? remaining + 1 : remaining
      }

      return MAX_MESSAGE
    },
    textPlaceholder () {
      if (this.chat.length === 0) {
        if (!this.isFetching) {
          return this.$translate('events.where_is.message_template', {
            client_name: this.selectedEvent.client.name
          })
        } else {
          return ''
        }
      }
      if (!this.canSendMessage) {
        return this.$translate('events.where_is.wait_reply', {
          client_name: this.selectedEvent.client.name
        })
      }
      return this.$translate('events.where_is.input_placeholder')
    },
    canSendMessage () {
      if (this.chat.length > 0) {
        this.scrollToLastMessage(300)

        const lastChat = this.chat[this.chat.length - 1]

        this.hasSeenMessage = false

        if (lastChat.group_id === PROVIDER_GROUP_ID) {
          return lastChat.sent_by !== this.user.id
        }
      }

      return true
    },
    meetingPoint () {
      if (this.selectedEvent) {
        if (this.isRoom) {
          return `${this.selectedEvent.meeting_point || ''}, ${this.selectedEvent.room_number}`
        }
        if (this.selectedEvent.meeting_point_details !== null) {
          return `${this.selectedEvent.meeting_point}, ${this.selectedEvent.meeting_point_details}`
        }
        return this.selectedEvent.meeting_point || ''
      }

      return ''
    },
    isRoom () {
      const meetingPoint = this.selectedEvent.meeting_point ? this.selectedEvent.meeting_point.toLowerCase() : ''

      return meetingPoint === 'room' || meetingPoint === 'zimmer'
    }
  },
  data () {
    return {
      message: '',
      isSending: false,
      isFetching: true,
      hasSeenMessage: false
    }
  },
  watch: {
    chat: {
      handler () {
        if (this.chat.length > 5) {
          this.scrollToLastMessage(500)
        }
      },
      deep: true
    }
  },
  mounted () {
    if (this.selectedEvent) {
      this.$store.commit('events/CLEAR_MESSAGES')
      this.$store
        .dispatch('events/fetchMessageHistory', this.selectedEvent.id)
        .then(() => {
          // Always auto-scroll into the last message
          setTimeout(this.scrollToLastMessage, 100)

          this.triggerSeenMessage()
        })
        .finally(() => {
          this.$store.commit('events/MESSAGES_SEEN', this.selectedEvent.id)
          this.isFetching = false
        })
    }
  },
  methods: {
    onInputFocus () {
      var isiPhoneSafari = navigator.userAgent.search(/CriOS/i) === -1 && navigator.userAgent.search(/iPhone/i) >= 0

      if (!isiPhoneSafari) {
        this.scrollToLastMessage(810)
      }
    },
    send () {
      if (this.chat.length > 0 && this.message.length === 0) {
        if (this.message === '') {
          this.errors.clear()

          var errorMsg = this.$translate('events.where_is.error_custom_msg', { client_name: this.selectedEvent.client.name })

          this.$notifications.toast(errorMsg, 5000)

          return false
        }
      }

      if (this.isSending) return
      if (this.chat.length === 0 && this.message.length === 0) {
        this.message = this.$translate('events.where_is.message_template', {
          client_name: this.selectedEvent.client.name
        })
      }

      this.isSending = true

      this.$store.dispatch('events/sendMessage', {
        id: this.selectedEvent.id,
        message: this.message,
        userID: this.user.id
      })
        .then(() => {
          this.message = ''
        })
        .finally(() => {
          this.isSending = false
          this.scrollToLastMessage()
        })
    },
    scrollToLastMessage (delay = 100) {
      const chats = document.querySelectorAll('.chat-bubble')

      if (chats.length) {
        setTimeout(() => {
          chats[chats.length - 1].scrollIntoView({ block: 'start', behavior: 'smooth' })

          this.triggerSeenMessage()
        }, delay)
      }
    },
    triggerSeenMessage () {
      if (this.selectedEvent) {
        // @NOTE: Removed has seen message flag.
        // if (!this.hasSeenMessage) {
        this.$store.dispatch('events/seenMessage', this.selectedEvent.id)
        // this.hasSeenMessage = true;
        // }
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('events/CLEAR_MESSAGES')

    next()
  }
}
</script>
