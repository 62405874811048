const ApiProvider = $h.getProvider('ApiProvider')

class UserProvider {
  getUser () {
    return new Promise((resolve, reject) => {
      window.api.get({ url: ApiProvider.getUser() },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response) })
    })
  }

  updateUser (params) {
    return new Promise((resolve, reject) => {
      window.api.put({ url: ApiProvider.updateUser(), payload: params },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response.data) })
    })
  }

  updateUserLanguage (params) {
    return new Promise((resolve, reject) => {
      window.api.put({ url: ApiProvider.updateUserLanguage(), payload: params },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response.data) })
    })
  }

  updateBasicDetails (params) {
    return new Promise((resolve, reject) => {
      window.api.put({ url: ApiProvider.updateProfileBasicDetails(), payload: params },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response.data) })
    })
  }

  updateCharacterDetails (params) {
    return new Promise((resolve, reject) => {
      window.api.put({ url: ApiProvider.updateProfileCharacterDetails(), payload: params },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response.data) })
    })
  }

  updateMotto (params) {
    return new Promise((resolve, reject) => {
      window.api.put({
        url: ApiProvider.updateMotto(),
        payload: {
          motto: params.motto
        }
      },
      (success) => { resolve(success.data) },
      (err) => { reject(err.response.data) })
    })
  }

  updateInterview (params) {
    return new Promise((resolve, reject) => {
      window.api.put({ url: ApiProvider.updateInterview(), payload: params },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response.data) })
    })
  }

  updateUsageNew (userID, isPWA, additionalPayload = {}) {
    // @TODO: Add download screen that is shown to user here...
    return new Promise((resolve, reject) => {
      window.api.put({
        url: ApiProvider.appUsage(userID, isPWA),
        payload: {
          build_version: APP_VERSION,
          ...additionalPayload
        }
      },
      (success) => { resolve(success.data) },
      (err) => {
        if (err.response) {
          reject(err.response.data)
        } else {
          reject(err)
        }
      })
    })
  }

  updateUsage (userID, isPWA) {
    return new Promise((resolve, reject) => {
      window.api.put({ url: ApiProvider.appUsage(userID, isPWA), payload: { build_version: APP_VERSION } },
        (success) => { resolve(success.data) },
        (err) => {
          if (err.response) {
            reject(err.response.data)
          } else {
            reject(err)
          }
        })
    })
  }
}

export default new UserProvider()
