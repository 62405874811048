var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "id-verification", attrs: { id: "verify-upload-id" } },
    [
      _c(
        "div",
        { staticClass: "container", attrs: { id: "content" } },
        [
          _c("navbar-close", { attrs: { push: { name: "dashboard" } } }),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$translate("verify.intro.title")) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("img", { attrs: { src: "/img/verify/id-front-placeholder.svg" } }),
          _vm._v("\n      \n    "),
          _c("img", { attrs: { src: "/img/verify/id-back-placeholder.svg" } }),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$translate("verify.intro.explanation_text")) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cta-fixed" }, [
            _c(
              "button",
              {
                staticClass:
                  "action-btn action-btn__krypton action-btn__blocked waves-effect waves-light",
                on: { click: _vm.gotoUpload }
              },
              [
                _c("strong", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$translate("verify.intro.cta_button")) +
                      "\n        "
                  )
                ])
              ]
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }