<template>
  <div
    v-if="dropdown && optsPiercing.length && optsSmoking.length && optsTattoo.length" id="style" class="wrapper"
  >
    <template>
      <div class="content__area">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <p class="content__stepper content__stepper--mobile"> {{ stepperLabel }}</p>
        <h2 class="content__title content__title--desktop">{{ $translate('register.personality.title.style') }}</h2>
      </div>

      <div class="content__area drawer max-height-mobile">
        <div class="content">
          <p class="content__stepper content__stepper--desktop"> {{ stepperLabel }}</p>
          <h2 class="content__title content__title--mobile">{{ $translate('register.personality.title.style') }}</h2>

          <div class="drawer-icon --no-selected-text center-align">
            <img src="/img/registration/personality/style.svg" alt="girl and boy dining">
          </div>

          <!-- Tattoo -->
          <md-select
            v-model="payload.tattoo"
            :force-mobile="true"
            desktop-display-mode="split"
            class="textfield-box-lg"
            :label="$translate('register.personality.style.tattoo_label')"
            :placeholder="$translate('details.tattoo')"
            :default="$translate('register.personality.style.select_tattoo')"
          >
            <option v-for="(opt, i) in optsTattoo" :key="`opt-tattoo-${i}`" slot="options" :value="opt.key">{{ opt.content }}</option>
            <div v-show="errors.has('tattoo')" slot="errors" class="input-helper red-text left-align">
              {{ errors.first('tattoo') }}
            </div>
          </md-select>
          <!-- Piercing -->
          <md-select
            v-model="payload.piercing"
            :force-mobile="true"
            desktop-display-mode="split"
            class="textfield-box-lg"
            :label="$translate('register.personality.style.piercing_label')"
            :placeholder="$translate('details.piercing')"
            :default="$translate('register.personality.style.select_piercing')"
          >
            <option v-for="(opt, i) in optsPiercing" :key="`opt-piercing-${i}`" slot="options" :value="opt.key">{{ opt.content }}</option>
            <div v-show="errors.has('piercing')" slot="errors" class="input-helper red-text left-align">
              {{ errors.first('piercing') }}
            </div>
          </md-select>
          <!-- Smoking -->
          <md-select
            v-model="payload.smoking"
            :force-mobile="true"
            desktop-display-mode="split"
            class="textfield-box-lg"
            :label="$translate('register.personality.style.smoking_label')"
            :placeholder="$translate('details.smoke')"
            :default="$translate('register.personality.style.select_smoking')"
          >
            <option v-for="(opt, i) in optsSmoking" :key="`opt-smoking-${i}`" slot="options" :value="opt.key">{{ opt.content }}</option>
            <div v-show="errors.has('smoking')" slot="errors" class="input-helper red-text left-align">
              {{ errors.first('smoking') }}
            </div>
          </md-select>
          <!-- Motto -->
          <md-textarea
            v-model="payload.motto"
            type="multiline"
            :force-active="false"
            :maxlength="mottoMaxLength"
            :label="$translate('motto.motto_optional')"
          >
            <div class="input-helper">
              <span class="right">{{ mottoLength }}</span>
              <div v-show="errors.has('motto')" class="red-text left-align">
                {{ errors.first('motto') }}
              </div>
            </div>
          </md-textarea>

          <register-navigator-v2
            :next-disabled="isCTADisabled"
            :total="total"
            :current="current"
            @prev.prevent="gotoPrev"
            @next.prevent="submitForm"
          />
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import { isEmpty } from '@/constants/helpers.js'
const LocaleProvider = $h.getProvider('LocaleProvider')
const RegisterProvider = $h.getProvider('RegisterProvider')

const MOTTO_MAX_LENGTH = 120

export default {
  name: 'RegisterPersonalityStyle',
  props: ['total', 'current', 'stepperLabel'],
  data () {
    return {
      mottoMaxLength: MOTTO_MAX_LENGTH,
      optsPiercing: [],
      optsSmoking: [],
      optsTattoo: [],
      payload: {
        piercing: null,
        smoking: null,
        tattoo: null,
        motto: null
      }
    }
  },
  computed: {
    isCTADisabled () {
      return isEmpty(this.payload.piercing) || isEmpty(this.payload.smoking) || isEmpty(this.payload.tattoo)
    },
    mottoLength () {
      if (!this.payload.motto) { return `0/${this.mottoMaxLength}` } else { return `${this.payload.motto.length}/${this.mottoMaxLength}` }
    },
    dropdown () {
      return this.$store.getters.getDropdown
    },
    user () {
      return this.$store.getters.getUser
    },
    isValidForm () {
      return this.payload.piercing != null && this.payload.smoking != null && this.payload.tattoo != null
    }
  },
  watch: {
    dropdown (newval, oldval) {
      this.initDropdown()
    }
  },
  mounted () {
    this.initDropdown()
    this.initUser()
  },
  methods: {
    gotoPrev () {
      this.$router.push({ name: 'register-personality', params: { type: 'taste' } })
    },
    scrollDown () {
      if (this.FEATURE_APV_REG_REDESIGN) {
        return
      }

      /** We can deprecate this method as it causes problem on mobile */
      setTimeout(() => {
        window.scroll({ top: 800, left: 0, behavior: 'smooth' })
      }, 300)
    },
    submitForm () {
      this.errors.clear()

      if (this.getErrors()) {
        return false
      }

      var payload = {
        piercing: this.payload.piercing,
        smoking: this.payload.smoking,
        tattoo: this.payload.tattoo,
        motto: this.payload.motto
      }

      RegisterProvider
        .submitField('personality/style', payload)
        .then((res) => {
          this.$store.commit('setUser', res.data)
          this.$router.push({ name: 'register-personality', params: { type: 'name' } })
        })
    },
    getErrors () {
      if (this.isValidForm) {
        return false
      }

      if (this.payload.tattoo === null) {
        this.errors.add({
          field: 'tattoo',
          msg: this.$translate('errors.select', {
            field: this.$translate('register.personality.style.tattoo')
          })
        })
      }

      if (this.payload.piercing === null) {
        this.errors.add({
          field: 'piercing',
          msg: this.$translate('errors.select', {
            field: this.$translate('register.personality.style.piercing')
          })
        })
      }

      if (this.payload.smoking === null) {
        this.errors.add({
          field: 'smoking',
          msg: this.$translate('errors.select', {
            field: this.$translate('register.personality.style.smoking')
          })
        })
      }

      return true
    },
    initDropdown () {
      if (!this.dropdown) return

      this.optsSmoking = $h.obj2arr(this.dropdown.smoking)
      this.optsTattoo = $h.obj2arr(this.dropdown.tattoo)
      this.optsPiercing = $h.obj2arr(this.dropdown.piercing)
    },
    initUser () {
      if (!this.user) return

      this.payload.piercing = this.user.register_progress > 17 ? this.user.profiles.piercing : null
      this.payload.smoking = this.user.register_progress > 17 ? this.user.profiles.smoking : null
      this.payload.tattoo = this.user.register_progress > 17 ? this.user.profiles.tattoo : null

      if (this.user.profiles.slogan || this.user.profiles.slogan_de) {
        this.payload.motto = LocaleProvider.isLocaleDe()
          ? this.user.profiles.slogan_de
          : this.user.profiles.slogan
      } else {
        this.payload.motto = null
      }
    }
  }
}
</script>
