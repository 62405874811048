var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "details" } },
    [
      _c("md-new-navbar", { attrs: { "route-name": "profile" } }),
      _vm._v(" "),
      _vm.ready
        ? _c(
            "div",
            { staticClass: "container", attrs: { id: "content" } },
            [
              _c("h1", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$translate("profile.edit_information")))
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "header-img",
                attrs: {
                  src: "/img/profile-details/character-info-header.svg",
                  alt: "Character Info"
                }
              }),
              _vm._v(" "),
              _c(
                "vform",
                {
                  ref: "form",
                  staticClass: "details-form",
                  on: { submit: _vm.save }
                },
                [
                  _vm.user && _vm.dropdown && _vm.optsLanguages.length
                    ? _c(
                        "md-select",
                        {
                          attrs: {
                            label: _vm.$translate("details.first_language"),
                            placeholder: _vm.$translate("details.lang_1"),
                            "listen-for-reinitiate-options": true,
                            "force-mobile": true,
                            "new-design": true
                          },
                          on: { change: _vm.reInitiateOptions },
                          model: {
                            value: _vm.payload.lang_1,
                            callback: function($$v) {
                              _vm.$set(_vm.payload, "lang_1", $$v)
                            },
                            expression: "payload.lang_1"
                          }
                        },
                        [
                          _vm._l(_vm.optsLanguages, function(opt) {
                            return _c(
                              "option",
                              {
                                key: opt.key,
                                attrs: {
                                  slot: "options",
                                  disabled:
                                    opt.key == _vm.payload.lang_1 ||
                                    opt.key == _vm.payload.lang_2 ||
                                    opt.key == _vm.payload.lang_3
                                },
                                domProps: { value: opt.key },
                                slot: "options"
                              },
                              [_vm._v(_vm._s(opt.content))]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("lang_1"),
                                  expression: "errors.has('lang_1')"
                                }
                              ],
                              staticClass: "input-helper red-text center-align",
                              attrs: { slot: "errors" },
                              slot: "errors"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.errors.first("lang_1")) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user &&
                  _vm.dropdown &&
                  _vm.optsLanguages.length &&
                  _vm.showSecondLang
                    ? _c(
                        "div",
                        [
                          _c(
                            "md-select",
                            {
                              attrs: {
                                label: _vm.$translate(
                                  "details.second_language"
                                ),
                                placeholder: _vm.$translate("details.lang_2"),
                                "listen-for-reinitiate-options": true,
                                "force-mobile": true,
                                "new-design": true
                              },
                              on: { change: _vm.reInitiateOptions },
                              model: {
                                value: _vm.payload.lang_2,
                                callback: function($$v) {
                                  _vm.$set(_vm.payload, "lang_2", $$v)
                                },
                                expression: "payload.lang_2"
                              }
                            },
                            [
                              _vm._l(_vm.optsLanguages, function(opt) {
                                return _c(
                                  "option",
                                  {
                                    key: opt.key,
                                    attrs: {
                                      slot: "options",
                                      disabled:
                                        opt.key == _vm.payload.lang_2 ||
                                        opt.key == _vm.payload.lang_3 ||
                                        opt.key == _vm.payload.lang_1
                                    },
                                    domProps: { value: opt.key },
                                    slot: "options"
                                  },
                                  [_vm._v(_vm._s(opt.content))]
                                )
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("lang_2"),
                                      expression: "errors.has('lang_2')"
                                    }
                                  ],
                                  staticClass:
                                    "input-helper red-text center-align",
                                  attrs: { slot: "errors" },
                                  slot: "errors"
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.errors.first("lang_2")) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "remove-btn margin-bottom",
                              staticStyle: { overflow: "auto" }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "remove-lang-cta uppercase right waves-effect waves-dark padding-sm",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.removeLang(2)
                                    }
                                  }
                                },
                                [
                                  _c("small", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.$translate("app.remove"))
                                      )
                                    ])
                                  ])
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user &&
                  _vm.dropdown &&
                  _vm.optsLanguages.length &&
                  _vm.showThirdLang
                    ? _c(
                        "div",
                        [
                          _c(
                            "md-select",
                            {
                              attrs: {
                                label: _vm.$translate("details.third_language"),
                                placeholder: _vm.$translate("details.lang_3"),
                                "listen-for-reinitiate-options": true,
                                "force-mobile": true,
                                "new-design": true
                              },
                              on: { change: _vm.reInitiateOptions },
                              model: {
                                value: _vm.payload.lang_3,
                                callback: function($$v) {
                                  _vm.$set(_vm.payload, "lang_3", $$v)
                                },
                                expression: "payload.lang_3"
                              }
                            },
                            [
                              _vm._l(_vm.optsLanguages, function(opt) {
                                return _c(
                                  "option",
                                  {
                                    key: opt.key,
                                    attrs: {
                                      slot: "options",
                                      disabled:
                                        opt.key == _vm.payload.lang_3 ||
                                        opt.key == _vm.payload.lang_1 ||
                                        opt.key == _vm.payload.lang_2
                                    },
                                    domProps: { value: opt.key },
                                    slot: "options"
                                  },
                                  [_vm._v(_vm._s(opt.content))]
                                )
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("lang_3"),
                                      expression: "errors.has('lang_3')"
                                    }
                                  ],
                                  staticClass:
                                    "input-helper red-text center-align",
                                  attrs: { slot: "errors" },
                                  slot: "errors"
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.errors.first("lang_3")) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "remove-btn margin-bottom",
                              staticStyle: { overflow: "auto" }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "remove-lang-cta uppercase right waves-effect waves-dark padding-sm",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.removeLang(3)
                                    }
                                  }
                                },
                                [
                                  _c("small", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.$translate("app.remove"))
                                      )
                                    ])
                                  ])
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showAddLang
                    ? _c(
                        "div",
                        { staticClass: "add-lang-cta right-align margin-y-md" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "waves-effect waves-dark padding-sm",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.incrementLang2show($event)
                                }
                              }
                            },
                            [
                              _c("small", [
                                _c("strong", [
                                  _vm._v(
                                    "+ " +
                                      _vm._s(
                                        _vm.$translate("details.add_language")
                                      )
                                  )
                                ])
                              ])
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "md-textarea",
                    {
                      attrs: {
                        type: "multiline",
                        "force-active": true,
                        maxlength: _vm.interestsMaxLength,
                        label: _vm.$translate("profile.interests"),
                        placeholder: _vm.$translate(
                          "register.personality.intellect.interests_placeholder"
                        )
                      },
                      model: {
                        value: _vm.payload.interests,
                        callback: function($$v) {
                          _vm.$set(_vm.payload, "interests", $$v)
                        },
                        expression: "payload.interests"
                      }
                    },
                    [
                      _c("div", { staticClass: "input-helper" }, [
                        _c("span", { staticClass: "right" }, [
                          _vm._v(_vm._s(_vm.interestsLength))
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("interests"),
                                expression: "errors.has('interests')"
                              }
                            ],
                            staticClass: "input-helper red-text center-align"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errors.first("interests")) +
                                "\n          "
                            )
                          ]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.user && _vm.dropdown && _vm.optsProfession.length
                    ? _c(
                        "md-select",
                        {
                          attrs: {
                            label: _vm.$translate("profile.job"),
                            placeholder: _vm.$translate("details.job"),
                            "force-mobile": true,
                            "new-design": true
                          },
                          model: {
                            value: _vm.payload.profession,
                            callback: function($$v) {
                              _vm.$set(_vm.payload, "profession", $$v)
                            },
                            expression: "payload.profession"
                          }
                        },
                        [
                          _vm._l(_vm.optsProfession, function(opt) {
                            return _c(
                              "option",
                              {
                                key: opt.key,
                                attrs: { slot: "options" },
                                domProps: { value: opt.key },
                                slot: "options"
                              },
                              [_vm._v(_vm._s(opt.content))]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("profession"),
                                  expression: "errors.has('profession')"
                                }
                              ],
                              staticClass: "input-helper red-text center-align",
                              attrs: { slot: "errors" },
                              slot: "errors"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.errors.first("profession")) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user && _vm.dropdown && _vm.optsCuisine.length
                    ? _c(
                        "md-select",
                        {
                          attrs: {
                            multiple: "true",
                            limit: 3,
                            "force-mobile": true,
                            label: _vm.$translate("profile.cuisine"),
                            placeholder: _vm.$translate("details.cuisine"),
                            "new-design": true
                          },
                          model: {
                            value: _vm.payload.cuisine,
                            callback: function($$v) {
                              _vm.$set(_vm.payload, "cuisine", $$v)
                            },
                            expression: "payload.cuisine"
                          }
                        },
                        [
                          _vm._l(_vm.optsCuisine, function(opt) {
                            return _c(
                              "option",
                              {
                                key: opt.key,
                                attrs: { slot: "options" },
                                domProps: { value: opt.key },
                                slot: "options"
                              },
                              [_vm._v(_vm._s(opt.content))]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("cuisine"),
                                  expression: "errors.has('cuisine')"
                                }
                              ],
                              staticClass: "input-helper red-text center-align",
                              attrs: { slot: "errors" },
                              slot: "errors"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.errors.first("cuisine")) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "md-textarea",
                    {
                      attrs: {
                        type: "multiline",
                        "force-active": true,
                        maxlength: _vm.drinksMaxLength,
                        label: _vm.$translate(
                          "register.personality.taste.drinks_label"
                        ),
                        placeholder: _vm.$translate(
                          "profile.drinks_placeholder"
                        )
                      },
                      model: {
                        value: _vm.payload.drinks,
                        callback: function($$v) {
                          _vm.$set(_vm.payload, "drinks", $$v)
                        },
                        expression: "payload.drinks"
                      }
                    },
                    [
                      _c("div", { staticClass: "input-helper" }, [
                        _c("span", { staticClass: "right" }, [
                          _vm._v(_vm._s(_vm.drinksLength))
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("drinks"),
                                expression: "errors.has('drinks')"
                              }
                            ],
                            staticClass: "input-helper red-text center-align"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errors.first("drinks")) +
                                "\n          "
                            )
                          ]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.user && _vm.dropdown && _vm.optsSmoking.length
                    ? _c(
                        "md-select",
                        {
                          attrs: {
                            label: _vm.$translate("profile.smoking"),
                            placeholder: _vm.$translate("details.smoke"),
                            "force-mobile": true,
                            "new-design": true
                          },
                          model: {
                            value: _vm.payload.smoking,
                            callback: function($$v) {
                              _vm.$set(_vm.payload, "smoking", $$v)
                            },
                            expression: "payload.smoking"
                          }
                        },
                        [
                          _vm._l(_vm.optsSmoking, function(opt) {
                            return _c(
                              "option",
                              {
                                key: opt.key,
                                attrs: { slot: "options" },
                                domProps: { value: opt.key },
                                slot: "options"
                              },
                              [_vm._v(_vm._s(opt.content))]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("smoking"),
                                  expression: "errors.has('smoking')"
                                }
                              ],
                              staticClass: "input-helper red-text center-align",
                              attrs: { slot: "errors" },
                              slot: "errors"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.errors.first("smoking")) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user && _vm.dropdown && _vm.optsTattoo.length
                    ? _c(
                        "md-select",
                        {
                          attrs: {
                            label: _vm.$translate("profile.tattoo"),
                            placeholder: _vm.$translate("details.tattoo"),
                            "force-mobile": true,
                            "new-design": true
                          },
                          model: {
                            value: _vm.payload.tattoo,
                            callback: function($$v) {
                              _vm.$set(_vm.payload, "tattoo", $$v)
                            },
                            expression: "payload.tattoo"
                          }
                        },
                        [
                          _vm._l(_vm.optsTattoo, function(opt) {
                            return _c(
                              "option",
                              {
                                key: opt.key,
                                attrs: { slot: "options" },
                                domProps: { value: opt.key },
                                slot: "options"
                              },
                              [_vm._v(_vm._s(opt.content))]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("tattoo"),
                                  expression: "errors.has('tattoo')"
                                }
                              ],
                              staticClass: "input-helper red-text center-align",
                              attrs: { slot: "errors" },
                              slot: "errors"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.errors.first("tattoo")) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user && _vm.dropdown && _vm.optsPiercing.length
                    ? _c(
                        "md-select",
                        {
                          attrs: {
                            label: _vm.$translate("profile.piercing"),
                            placeholder: _vm.$translate("details.piercing"),
                            "force-mobile": true,
                            "new-design": true
                          },
                          model: {
                            value: _vm.payload.piercing,
                            callback: function($$v) {
                              _vm.$set(_vm.payload, "piercing", $$v)
                            },
                            expression: "payload.piercing"
                          }
                        },
                        [
                          _vm._l(_vm.optsPiercing, function(opt) {
                            return _c(
                              "option",
                              {
                                key: opt.key,
                                attrs: { slot: "options" },
                                domProps: { value: opt.key },
                                slot: "options"
                              },
                              [_vm._v(_vm._s(opt.content))]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("piercing"),
                                  expression: "errors.has('piercing')"
                                }
                              ],
                              staticClass: "input-helper red-text center-align",
                              attrs: { slot: "errors" },
                              slot: "errors"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.errors.first("piercing")) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("h-footer-btns-apv-redesign", {
                attrs: {
                  cancel: _vm.$translate("app.cancel"),
                  confirm: _vm.$translate("app.save")
                },
                on: {
                  cancel: function($event) {
                    return _vm.$router.push({ name: "profile" })
                  },
                  confirm: _vm.submitButton
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }