var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "new-navbar-wrapper",
      class: { "--with-top-status-indicator": _vm.hasTopStatusIndicator }
    },
    [
      _c("div", { staticClass: "new-navbar --fixed" }, [
        _c(
          "div",
          { staticClass: "new-navbar-container" },
          [
            _c(
              "a",
              {
                staticClass: "back-btn",
                staticStyle: { height: "100%" },
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.redirect($event)
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "responsive-img",
                  attrs: { src: "/img/back-arrow.svg", alt: "back-btn" }
                })
              ]
            ),
            _vm._v(" "),
            _vm._t("cta")
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "new-navbar__placeholder" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }