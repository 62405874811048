<template>
  <component :is="'RegisterController'" v-slot="{ data, bindings, actions }">
    <component
      :is="'AuthView'"
      :data="data"
      :route="$route.name"
    >
      <template v-slot:title>
        <h2>{{ actions.translate('register.title1') }}</h2>
        <h2>{{ actions.translate('register.title2') }}</h2>
      </template>
      <apv-form
        novalidate
        data-test="register-form"
        :is-loading="data.isLoading"
        @submit="bindings.handleRegister"
      >
        <apv-input
          type="email"
          autocomplete="username"
          tabindex="1"
          class="margin-bottom-md"
          data-test="input-email"
          :value="data.username"
          :error="actions.getFieldError('email')"
          :label="actions.translate('register.email')"
          @input="bindings.handleUsernameChange"
        />
        <apv-input
          type="password"
          autocomplete="password"
          tabindex="2"
          class="margin-bottom-xl"
          data-test="input-password"
          :value="data.password"
          :label="actions.translate('register.password')"
          :error="actions.getFieldError('password')"
          @input="bindings.handlePasswordChange"
        />

        <apv-button
          :rounded="10"
          :is-loading="data.isLoading"
          block
          gradient
          type="submit"
          size="large"
          waves-effect="dark"
          :disabled="bindings.isEmpty(data.username) || bindings.isEmpty(data.password)"
          data-test="register-cta"
        >
          {{ actions.translate('register.createFreeProfile') }}
        </apv-button>
      </apv-form>

      <div class="footer-auth-nav">
        <div class="login-container">
          <span class="login-label">{{ actions.translate('register.link2loginText1') }}</span>
          <router-link class="login-link" :to="data.loginRoute">{{ actions.translate('register.link2loginText2') }}</router-link>
        </div>
      </div>

    </component>
  </component>
</template>

<script>
import RegisterController from './RegisterController.vue'
import AuthView from './AuthView.vue'

export default {
  components: {
    RegisterController,
    AuthView
  }
}
</script>

<style lang="scss" scoped>

@import './authenticationNavigation.style.scss';

.password-hint {
    color: #2F3740;
    font-size: 12px;
    border: 1px solid #CED6E0;
    padding: 16px;
    border-radius: 12px;
    display: flex;

    .hint-text {
        text-align: left;
        padding-left: 8px;
    }
}
.password-hint-PRE_REDESIGN {
    color: #757575;
    font-size: 12px;
}
.register-container {
    margin-top: 8px;
    color: #2F3740;
}

.login-container {
    margin-top: 46px;

    .login-label {
        color: #2F3740;
    }
    .login-link {
        text-decoration: underline !important;
        color: #DC0064 !important;
    }
}
</style>
