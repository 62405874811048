/**
 * App Feature Management
 *
 * This file serves as a feature flag during runtime checks.
 * Entries added here should also reflect in `webpack.mix.js` under APP_FEATURE config.
 */
const FEATURES_LIST = {
}

require('./provider-faq')
require('./provider-performance')
require('./events')
require('./lite')
require('./registration')
require('./private-place')
require('./start-screen')
require('./onboarding-bottom-sheet')
require('./inbox')
require('./krypton-connect')
require('./language-switcher')

export default FEATURES_LIST
