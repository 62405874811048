<template>
  <div class="super-provider-video__parent margin-top-lg">
    <div class="super-provider-video__thumbnail">
      <a href="#" class="super-provider-video__play-button" @click.prevent="openVideo" data-test="open-video">
        <img src="/img/super-provider/video-player.svg" class="super-provider-video__play-button-icon">
      </a>
    </div>
    <div v-if="showVideo" class="super-provider-video__backdrop">
      <div class="super-provider-video__container">
        <video
          v-if="!hasVideoEnded"
          autoplay="false"
          controls
          class="super-provider-video"
          controlsList="nodownload"
          @ended="showReplayScreen"
        >
          <source data-test="video-source" :src="videoSrc" type="video/mp4">
        </video>
        <div v-else class="super-provider-video__replay-container">
          <div class="super-provider-video__replay">
            <a href="#" class="super-provider-video__replay-button">
              <img src="/img/super-provider/video-replay.svg" class="super-provider-video__replay-button-icon" @click.prevent="replay">
            </a>
            <div class="super-provider-video__replay-text">{{ $t('watchAgain') }}</div>
          </div>
        </div>
        <a href="#" class="super-provider-video__close-button" @click.prevent="closeVideo">
          <i class="material-icons">close</i>
        </a>
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/super-provider/translations/video.json"></i18n>

<script>
import { VIDEO_SRC_DE, VIDEO_SRC_EN } from '@/features/super-provider/constants'

export default {
  data: () => ({
    showVideo: false,
    hasVideoEnded: false
  }),
  computed: {
    PHOTO_FALLBACK_A2_2720 () {      
      return this.$store.getters['featureflag/isEnabled']('PHOTO_FALLBACK_A2_2720')
    },
    videoSrc () {
      if(this.PHOTO_FALLBACK_A2_2720) {
          this.$i18n.locale === 'de' ? 
            'https://d1muixarkip6az.cloudfront.net/super-provider/Superinserentin_DE_v2_LIGHT.mp4' :
            'https://d1muixarkip6az.cloudfront.net/super-provider/Superinserentin_EN_v2_light.mp4'
      } else {
        return this.$i18n.locale === 'de' ? VIDEO_SRC_DE : VIDEO_SRC_EN
      }
      
    }
  },
  methods: {
    openVideo () {
      this.showVideo = true
    },
    closeVideo () {
      this.showVideo = false
      this.hasVideoEnded = false
    },
    replay () {
      this.hasVideoEnded = false
    },
    showReplayScreen () {
      this.hasVideoEnded = true
    }
  }
}
</script>

<style lang="scss" scoped>
.super-provider-video__parent {
  .super-provider-video__thumbnail {
    position: relative;
    width: 100%;
    padding-top: 56.40%;
    background: transparent linear-gradient(241deg, #845BE7 0%, #608AFF 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 0.7;

    .super-provider-video__play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80px;
      height: 64px;
      margin-top: -32px;
      margin-left: -40px;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
  .super-provider-video__backdrop {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(46, 53, 61, 0.3);
    z-index: 9980;

    .super-provider-video__container {
      position: relative;
      width: 80%;
      max-width: 376px;

      video.super-provider-video {
        width: 100%;
        height: auto;
        border-radius: 8px;
      }
      .super-provider-video__close-button {
        position: absolute;
        top: -16px;
        right: -16px;
        width: 32px;
        height: 32px;
        background-color: #FFFFFF;
        border-radius: 50%;
        text-align: center;

        i.material-icons {
          display: block;
          color: #78838F;
          line-height: 32px;
        }
      }
      .super-provider-video__replay-container {
        width: 100%;
        aspect-ratio: 9/16;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent linear-gradient(212deg, #845BE7 0%, #608AFF 100%) 0% 0% no-repeat padding-box;

        .super-provider-video__replay-text {
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
