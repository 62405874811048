const redirectIfAuthenticated = require('@/middleware/redirectIfAuthenticated').default

const ReactivationComponent = require('@/features/registration/pages/reactivation/reactivation.vue').default
const ReactivationSuccessComponent = require('@/features/registration/pages/reactivation/reactivation-success.vue').default

export const reactivation = {
  path: '/reactivate',
  name: 'reactivate',
  component: ReactivationComponent,
  beforeEnter: redirectIfAuthenticated
}

export const reactivationSuccess = {
  path: '/reactivation-success',
  name: 'reactivation-success',
  component: ReactivationSuccessComponent,
  beforeEnter: redirectIfAuthenticated
}
