<template>
  <div
    data-test="onboarding-quick-start-shortcut"
    class="onboarding-bottom-sheet--quick-start"
    @click="onClickHandler"
  >
    <div class="quick-start--flex">
      <div class="quick-start--col quick-start--text">{{ $t('cta') }}</div>
      <div class="quick-start--col quick-start--img-container">
        <img src="@/features/onboarding-bottom-sheet/assets/onboarding-bottom-sheet-engine-start2.svg">
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/onboarding-bottom-sheet/translations/quick-start.json"></i18n>

<script>
export default {
  methods: {
    onClickHandler (e) {
      this.$router.push({ name: 'onboarding-lessons' })
    }
  }
}
</script>

<style lang="scss" scoped>
.onboarding-bottom-sheet--quick-start {
    padding: 21px 24px;
    background-color: #7638FA;
    border-radius: 16px;
    cursor: pointer;

    .quick-start--flex {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .quick-start--text {
            color: #fff;
            font-size: 20px;
            font-family: 'Proxima Nova Semibold';
        }

        .quick-start--img-container {
            img {
                display: block;
            }
        }
    }
}
</style>
