<template>
  <div class="modal left-align">
    <div class="modal-content">
      <h4 class="modal-title">
        {{ title }}
      </h4>
      <p class="grey-text text-darken-1" v-html="content" />
    </div>
    <div
      v-if="!reverse"
      class="modal-footer"
    >
      <button
        id="modal-confirm-cancel-reverse-btn"
        class="btn-flat waves-effect waves-dark modal-close"
        @click="cancel"
      >
        {{ cancelText || $translate('app.cancel') }}
      </button>
      <button
        id="modal-confirm-confirm-reverse-btn"
        class="btn-flat waves-effect waves-dark krypton-pink-text"
        @click="confirm"
      >
        {{ confirmText || $translate('app.ok') }}
      </button>
    </div>
    <div
      v-else
      class="modal-footer"
    >
      <button
        id="modal-confirm-cofirm-btn"
        class="btn-flat waves-effect waves-dark modal-close"
        @click="confirm"
      >
        {{ confirmText || $translate('app.ok') }}
      </button>
      <button
        id="modal-confirm-cancel-btn"
        class="btn-flat waves-effect waves-dark krypton-pink-text"
        @click="cancel"
      >
        {{ cancelText || $translate('app.cancel') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'title',
    'content',
    'cancelText',
    'confirmText',
    'reverse'
  ],
  mounted () {
    if (!this.cancelText) {
      this.cancelText = this.$translate('app.cancel')
    }
    if (!this.confirmText) {
      this.confirmText = this.$translate('app.ok')
    }
  },
  methods: {
    confirm ($e) {
      this.$emit('confirm', $e)
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
