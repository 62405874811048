<template>
  <div id="bottom-sheet-multiselect" class="btm-sheet-backdrop">
    <div class="menu-container">
      <div class="close-cta" @click.prevent="onClose"><img src="/img/registration/bottom-sheet-close.svg" alt=""></div>
      <div ref="header" class="title">{{ title }}</div>
      <div ref="content" class="option-menu">
        <div
          v-for="(option, i) in options"
          :key="i"
          class="option-item-container"
          :class="{
            'option-item-container--selected': (data && data.indexOf(option.val) >= 0),
            'option-item-container--disabled': (limitReached && data.indexOf(option.val) < 0),
          }"
          @click.prevent="onSelect(option)">
          <div
            class="option-item"
          >{{ option.text }}</div>
        </div>
      </div>
      <div class="btm-sheet-btn-container">
        <div class="select-count">{{ getSelectedCountStatus }}</div>
        <div class="btn btn-black" @click.prevent="onConfirm">{{ $translate('register.generic_terms.ready') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: null
    },
    limit: {
      type: [Number, String],
      default: null
    },
    input: {
      type: [Array],
      default: () => []
    }
  },
  data () {
    return {
      data: null,
      optionSelectedCount: 0
    }
  },
  computed: {
    getSelectedCountStatus () {
      return `${this.optionSelectedCount} ${this.$translate('register.generic_terms.ready')} ${this.limit}`
    },
    limitReached () {
      return (this.limit && this.optionSelectedCount === this.limit)
    }
  },
  watch: {
    data (newVal) {
      if (newVal.hasOwnProperty('length')) {
        this.optionSelectedCount = newVal.length
      } else {
        this.optionSelectedCount = 1
      }
    }
  },
  created () {
    this.data = this.multiple ? [] : null
  },
  mounted () {
    this.data = _.clone(this.input)
  },
  methods: {
    onClose () {
      this.$emit('onClose')
    },
    onConfirm () {
      this.$emit('onConfirm', this.data)
    },
    onSelect (option) {
      if (option.disabled) return false
      this.push2data(option.val)
    },
    setData (val) {
      this.data = val
    },
    push2data (val) {
      var index = this.data.indexOf(val)

      if (index >= 0) {
        this.data.splice(index, 1)
        this.data = this.data.filter(e => {
          return e !== null
        })
      } else {
        if (!this.limitReached) {
          this.data.push(val)
        }
      }
    },
    close () {
      this.$emit('close', this.data)
    }
  }
}
</script>

<style lang="scss" scoped>

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-up) {
  0% { transform: translateY(90%); }
  100% { transform: translateY(0); }
}

@include keyframes(slide-down) {
  0% { transform: translateY(0); }
  100% { transform: translateY(90%); }
}

#bottom-sheet-multiselect {

  & {
    display:none;
  }

  &.btm-sheet-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000000, 0.7);
    z-index: 998;
    justify-content: center;
    align-items: flex-end;
    box-sizing: content-box;

    @media (min-width: 992px) {
      justify-content: flex-end;
    }
  }

  .menu-container {
    position: relative;
    margin: 0;
    width: 100%;
    max-width: 375px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    font-family: 'Proxima Nova Semibold';
    border-radius: 12px 12px 0 0;
    padding-left: 24px;
    padding-right: 24px;
    max-height: 90%;
    height: 90%;
    display: none;
    flex-direction: column;

    @media (min-width: 992px) {
      max-height: 100%;
      height: 100%;
      width: 50%;
      max-width: 50%;
      border-radius: 0;
    }

    .close-cta {
      margin-top: 7px;
      margin-bottom: 8px;
      margin-left: -16px;
      width: 48px;

      @media (min-width: 992px) {
        margin-left: 48px;
        margin-top: 24px;
      }
    }

    .title {
      font-family: 'Proxima Nova Semibold';
      font-size: 22px;
      text-align: left;
      color: #2F3740;
      margin-bottom: 12px;

      @media (min-width: 992px) {
        max-width: 500px;
        width: 500px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .option-menu {
      height: calc(100% - 70px);
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      justify-content: center;
      align-self: center;
      max-width: 500px;
      text-align: center;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .option-item-container>*{
        flex: 1 1 120px;
        @media (min-width: 400px) {
          flex: 1 1 150px;
        }
      }

      .option-item-container {
        border: 1px solid #CED6E0;
        padding: 24px 8px;
        font-family: 'Proxima Nova Medium';
        color: #2F3740;
        font-size: 18px;
        border-radius: 12px;
        text-align: center;
        width: 120px;
        height: 52px;
        display: flex;
        align-items: center;

        @media (min-width: 400px) {
          width: 134px;
          height: 52px;
        }

        &:last-child > .option-item {
          border-bottom: none;
        }
      }

      .option-item-container--selected {
        border: 2px solid #2F3740;
        background-color: #F7FAFF;
      }

      .option-item-container--disabled {
        opacity: 0.5;
      }
    }
  }

  .btm-sheet-btn-container {
    display: flex;
    justify-content: space-between;
    margin-left: -24px;
    margin-right: -24px;
    padding: 24px;
    border-top: 1px solid #CAD1D9;

    .select-count {
      line-height: 46px;
      height: 46px;
    }

    .btn-black {
      color: white;
      border-radius: 10px;
      background: #2F3740;
      font-family: 'Proxima Nova Semibold';
      font-size: 16px;
      padding-left: 32px;
      padding-right: 32px;
      line-height: 46px;
      height: 46px;
    }
  }

  &.element--visible {
    display: flex;

    .menu-container {
      display: flex;
      @include animation('slide-up 0.3s ease');
    }
  }
}
</style>
