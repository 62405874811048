import ApiProvider from '../providers/ApiProvider'

/**
 * Helper functions
*/

const helpers = {

  getAvailabilitiesList (date) {
    return new Promise((resolve, reject) => {
      window.api.get({ url: ApiProvider.availability({ date }) },
        (success) => { resolve(success) },
        (err) => { reject(err) })
    })
  },

  getToday () {
    return moment(new Date())
  }
}

/**
 * Vuex State
*/
const state = {
  availabilities: [],
  lastAvailability: null,
  needSave: false
  // Availability input check
}

/**
 * State getters
*/
const getters = {
  getAvailabilitiesByMonth: (state) => (set, filter) => {
    const today = helpers.getToday()

    // Filter availabilities
    const availabilities = state.availabilities
      .filter(availability => moment(availability.date).isSameOrAfter(moment(today.format('YYYY-MM-DD'))))
      .filter((availability) => {
        switch (filter) {
          case AVAILABILITY_FILTER_TYPES.UNFILLED:
            if (availability.id === null) {
              return true
            }
            break
          case AVAILABILITY_FILTER_TYPES.FILLED:
            if (availability.id) {
              return true
            }
            break
          default:
            return true
        }

        return false
      })
      .slice(0, 7 * set)

    // Get all months based from filtered availabiltiies
    const months = availabilities.map((a) => {
      const d = moment(a.date)
      return d.month()
    }).reduce((acc, curr) => {
      const length = acc.length

      if (length === 0 || acc[length - 1] !== curr) {
        acc.push(curr)
      }

      return acc
    }, [])

    // Group availabilities by month
    const result = months.map((m) => {
      return {
        month: m,
        availabilities: availabilities.filter((a) => {
          const d = moment(a.date)
          return d.month() === m
        })
      }
    })

    return result
  },
  getAvailabilities: (state) => (set, filter) => {
    const today = helpers.getToday()
    return state.availabilities
      .filter(availability => moment(availability.date).isSameOrAfter(moment(today.format('YYYY-MM-DD'))))
      .filter((availability) => {
        switch (filter) {
          case AVAILABILITY_FILTER_TYPES.UNFILLED:
            if (availability.id === null) {
              return true
            }
            break
          case AVAILABILITY_FILTER_TYPES.FILLED:
            if (availability.id) {
              return true
            }
            break
          default:
            return true
        }

        return false
      })
    // .slice(0, 7 * set);
  },
  getAvailabilitiesOverview: (state) => {
    const today = helpers.getToday()

    return state.availabilities
      .slice(0, 21) // Overview is only the first 21 days...
      .map((availability) => {
        if (moment(availability.date).isBefore(moment(today.format('YYYY-MM-DD')))) {
          return AVAILABILITY_STATUS.PAST
        } else {
          if (availability.id > 0) {
            if (availability.not_available) {
              return AVAILABILITY_STATUS.UNAVAILABLE
            } else {
              return AVAILABILITY_STATUS.FILLED
            }
          } else {
            return AVAILABILITY_STATUS.UNFILLED
          }
        }
      })
  },
  getLastAvailabilityDate: (state) => {
    if (state.availabilities && state.availabilities.length > 0) { return state.availabilities[state.availabilities.length - 1].date } else { return null }
  },
  getLastAvailability: (state) => {
    return state.lastAvailability
  },
  getFilledAvailabilityCount: (state) => {
    const today = helpers.getToday()

    return state.availabilities
      .filter(availability => moment(availability.date).isSameOrAfter(moment(today.format('YYYY-MM-DD'))))
      .filter(availability => availability.id > 0)
      .slice(0, 14)
      .length
  },
  getNeedSave: (state) => {
    return state.needSave
  }
}

/**
 * State mutations
*/
const mutations = {
  setAvailabilities: (state, availabilities) => {
    state.availabilities = availabilities
  },
  refreshAvailabilities: (state, availabilities) => {
    for (let i = 0, limit = availabilities.length; i < limit; ++i) {
      for (const k in availabilities[i]) {
        if (availabilities[i].hasOwnProperty(k)) {
          state.availabilities[i][k] = availabilities[i][k]
        }
      }
    }
  },
  setInitialAvailabilities: (state, availabilities) => {
    state.availabilities = _.flatten(availabilities.map(a => a.data.data))
  },
  setAvailability: (state, availability) => {
    const idx = state.availabilities.findIndex(a => a.date === availability.date)

    if (idx >= 0) {
      state.availabilities[idx]._city_name = availability._city_name
      state.availabilities[idx]._requests_end = availability._requests_end
      state.availabilities[idx]._requests_start = availability._requests_start
      state.availabilities[idx].accept_overnight = availability.accept_overnight
      state.availabilities[idx].availability_end = availability.availability_end
      state.availabilities[idx].availability_start = availability.availability_start
      state.availabilities[idx].city_id = availability.city_id
      state.availabilities[idx].date = availability.date
      state.availabilities[idx].id = availability.id
      state.availabilities[idx].not_available = availability.not_available
      state.availabilities[idx].out_of_town = availability.out_of_town
    }
  },
  setNeedSave: (state, needSave) => {
    state.needSave = needSave
  },
  setLastAvailability: (state, lastAvailability) => {
    state.lastAvailability = lastAvailability
  },
  reset: (state) => {
    state.availabilities = []
  }
}

/**
 * State actions
*/
const actions = {
  refreshAvailabilities (context) {
    const date = moment(new Date()).startOf('isoWeek').format('YYYY-MM-DD')
    const range = 28

    return new Promise((resolve, reject) => {
      window.api.get({ url: ApiProvider.availability(date, range) },
        (success) => {
          context.commit('refreshAvailabilities', success.data.data)
          resolve(success.data.data)
        },
        (err) => {
          reject(err)
        })
    })
  },
  fetchInitialAvailabilities (context) {
    const date = moment(new Date()).startOf('isoWeek').format('YYYY-MM-DD')
    const range = 28

    return new Promise((resolve, reject) => {
      window.api.get({ url: ApiProvider.availability(date, range) },
        (success) => {
          context.commit('setAvailabilities', success.data.data)
          resolve(success.data.data)
        },
        (err) => {
          reject(err)
        })
    })
  },
  saveAvailability (context, payload) {
    return new Promise((resolve, reject) => {
      window.api.post({ url: ApiProvider.availability(), payload: payload },
        (success) => {
          context.commit('setAvailability', success.data.data)
          resolve(success.data.data)
        },
        (err) => {
          reject(err)
        })
    })
  },
  updateAvailability (context, payload) {
    return new Promise((resolve, reject) => {
      window.api.put({ url: ApiProvider.updateAvailability(payload.id), payload: payload },
        (success) => {
          context.commit('setAvailability', success.data.data)
          resolve(success.data.data)
        },
        (err) => { reject(err) })
    })
  }
}

/// ////////////////////////
// Nothing to do here...
/// ////////////////////////

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  helpers
}
