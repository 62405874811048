/**
 * Vuex State
*/
const state = {
  interviews: [],
  selectedInterview: null,
};

/**
 * State getters
*/
const getters = {
  GET_SELECTED_INTERVIEW(state) {
    return state.selectedInterview;
  },
  GET_INTERVIEWS(state) {
    return state.interviews;
  },
};

/**
 * State mutations
*/
const mutations = {
  SET_SELECTED_INTERVIEW(state, selectedInterview) {
    return state.selectedInterview = selectedInterview;
  },
  SET_INTERVIEWS(state, interviews) {
    return state.interviews = interviews;
  },
  UPDATE_INTERVIEW(state, payload) {
    const newData = {
      question: payload.question,
      answer: payload.answer,
      title: payload.title,
    };

    if (!state.interviews) {
      state.interviews = [newData];
    } else {
      if (payload.index >= 0) {
        const idx = payload.index;

        if (state.interviews[idx] !== undefined) {
          state.interviews[idx] = {
            ...newData
          };
        } else {
          state.interviews.push(newData);
        }
      } else {
        state.interviews.push(newData);
      }
    }

  },
};

/**
 * State actions
*/
const actions = {
};

///////////////////////////
// Nothing to do here...
///////////////////////////

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
