var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        ref: "onboarding_bottom_sheet",
        class: _vm.getOBSClass,
        attrs: { id: "onboarding-bottom-sheet" }
      },
      [
        _c(
          "div",
          { staticClass: "bottom-sheet-container" },
          [
            _c(
              "div",
              {
                staticClass: "bottom-sheet-bar-container",
                attrs: {
                  "data-test": "bottom-sheet-resize-cta",
                  "data-expanded": this.isMaxView
                },
                on: { click: _vm.onBottomSheetResize }
              },
              [
                _c("div", { staticClass: "bottom-sheet-bar-header" }, [
                  _c("div", [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        attrs: { "data-test": "bottom-sheet-collapse-icon" }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.isMaxView ? "expand_more" : "expand_less")
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.collapseText))])
                  ]),
                  _vm._v(" "),
                  !_vm.isActiveLessonCompleted
                    ? _c(
                        "div",
                        {
                          attrs: {
                            "data-test":
                              "bottom-sheet-collapse-new-lesson-indicator"
                          }
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("lens")
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$translate("onboarding.new")))
                          ])
                        ]
                      )
                    : _vm._e()
                ])
              ]
            ),
            _vm._v(" "),
            _c("onboarding-progress-greeting"),
            _vm._v(" "),
            _c("onboarding-verification-banner"),
            _vm._v(" "),
            _c("onboarding-bottom-sheet-dashboard-intro"),
            _vm._v(" "),
            _c("onboarding-active-lesson"),
            _vm._v(" "),
            _c("onboarding-bottom-sheet-quick-start")
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }