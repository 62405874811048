<template>
  <div id="basic">
    <template>
      <register-basic-city
        v-if="$route.params.type == 'city'"
        :total="stepper.total"
        :current="stepper.current"
        :stepper-label="stepperLabel" />
      <register-basic-eye-color
        v-if="$route.params.type == 'eye-color'"
        :total="stepper.total"
        :current="stepper.current"
        :stepper-label="stepperLabel" />
      <register-basic-hair-color
        v-if="$route.params.type == 'hair-color'"
        :total="stepper.total"
        :current="stepper.current"
        :stepper-label="stepperLabel" />
      <register-basic-origin
        v-if="$route.params.type == 'origin'"
        :total="stepper.total"
        :current="stepper.current"
        :stepper-label="stepperLabel" />
      <register-basic-height
        v-if="$route.params.type == 'height'"
        :total="stepper.total"
        :current="stepper.current"
        :stepper-label="stepperLabel" />
      <register-basic-weight
        v-if="$route.params.type == 'weight'"
        :total="stepper.total"
        :current="stepper.current"
        :stepper-label="stepperLabel" />
      <register-basic-bra-cup
        v-if="$route.params.type == 'bra-cup'"
        :total="stepper.total"
        :current="stepper.current"
        :stepper-label="stepperLabel" />
      <register-basic-dress-size
        v-if="$route.params.type == 'dress-size'"
        :total="stepper.total"
        :current="stepper.current"
        :stepper-label="stepperLabel" />
    </template>
  </div>
</template>

<script>
const TOTAL_STEPS = 8

export default {
  data () {
    return {
      stepper: {
        total: TOTAL_STEPS,
        current: null
      }
    }
  },
  computed: {
    stepperLabel () {
      return this.$translate('register.basic.stepper') + ' ' + this.stepper.current + '/' + this.stepper.total
    }
  },
  watch: {
    $route: function (to, from) {
      this.getCurrentStep()
    }
  },
  mounted () {
    this.getCurrentStep()
  },
  methods: {
    getCurrentStep () {
      switch (this.$route.params.type) {
        case 'city':
          this.stepper.current = 1
          break
        case 'eye-color':
          this.stepper.current = 2
          break
        case 'hair-color':
          this.stepper.current = 3
          break
        case 'origin':
          this.stepper.current = 4
          break
        case 'height':
          this.stepper.current = 5
          break
        case 'weight':
          this.stepper.current = 6
          break
        case 'bra-cup':
          this.stepper.current = 7
          break
        case 'dress-size':
          this.stepper.current = 8
          break
        default:
          break
      }
    }
  }
}
</script>
