var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "button",
        {
          ref: "slideToTop",
          staticClass: "slide-top",
          class: _vm.classes,
          on: { click: _vm.slideToTop }
        },
        [
          _c("i", { staticClass: "material-icons margin-right-sm" }, [
            _vm._v("arrow_upward")
          ]),
          _c("span", [_vm._v(_vm._s(_vm.text))])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }