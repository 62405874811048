
import { mapGetters } from 'vuex'

import Worker from '../workers/setInterval.worker.js'

const ApiProvider = $h.getProvider('ApiProvider')
const WORKER_INTERVAL = $h.secToMilliseconds(60) // In milliseconds (default: 60 secs)

export default {
  data: () => ({
    networkWorker: null
  }),
  methods: {

    /**
     * @method startNetworkCheck
     *
     * Starts the network check worker and execute the callback function passed.
     *
     * @param {function} cb - Callback function
    */
    startNetworkCheck () {
      if (this.networkWorker === null) {
        this.networkWorker = new Worker()
        this.networkWorker.postMessage(WORKER_INTERVAL)
        this.networkWorker.onmessage = this.pingCheck
      }
    },

    /**
     * @method stopNetworkCheck
     *
     * Stop the network check worker.
    */
    stopNetworkCheck () {
      if (this.networkWorker) {
        this.networkWorker.terminate()
        this.networkWorker = null
      }
    },

    /**
     * @method pingCheck
     *
     * Check if app has connection to server.
    */
    pingCheck () {
      window.api.get({ url: ApiProvider.ping() },
        (_) => {
          this.$store.commit('setIsOnline', true)
        },
        (_) => {
          this.$store.commit('setIsOnline', false)
        })
    }

  },
  beforeRouteLeave (to, from, next) {
    this.stopNetworkCheck()

    next()
  }
}
