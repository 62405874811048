<template>
  <div class="modal verti-centered">
    <div class="new-modal-content">
      <h4 class="modal-title">{{ $translate('app.feature_lock_popup.title') }}</h4>
      <i class="mdi mdi-lock" />
      <p>{{ $translate('app.feature_lock_popup.explanation_text') }}</p>
    </div>

    <div class="new-modal-footer">
      <a href="#" @click.prevent="confirm">
        <strong>{{ $translate('app.ok_understand') }}</strong>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    confirm ($e) {
      this.$emit('confirm', $e)
    }
  }
}
</script>

<style scoped lang="scss">

.verti-centered {
    top: 30%;

    @media only screen and (max-width: 992px) {
        top: 20%;
    }

}

.modal {

    .new-modal-content {
        padding: 32px 24px 18px 24px;
        text-align: center;

        .modal-title {
            color: #2F3740;
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 0;
        }

        .mdi-lock {
            font-size: 74px;
            color: #E30074;
        }

        p {
            color: #2F3740;
            font-size: 18px;
            line-height: 28px;
            margin: 0;
        }
    }

    .new-modal-footer {
        border-top: solid 1px #CAD1D9;
        height: 95px;
        text-align: center;
        width: 100%;

        a {
            color: #E30074;
            cursor: pointer;
            display: inline-block;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
            margin: 30px auto 0 auto;
            position: relative;
            text-align: center;
            text-decoration: underline;
        }
    }

}

</style>
