export const reviews = [
  {
    id: 1,
    client_name: 'Kristof',
    client_rating: 4,
    date_created: "2018-12-20",
    client_review: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut laborum reiciendis molestias.',
    show_review: true,
    private_review: false,
  },
  {
    id: 2,
    client_name: 'Kristof',
    client_rating: 5,
    date_created: "2018-12-20",
    client_review: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut laborum reiciendis molestias.',
    show_review: true,
    private_review: true,
  },
  {
    id: 3,
    client_name: 'Kristof',
    client_rating: 2,
    date_created: "2018-12-20",
    client_review: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
    show_review: true,
    private_review: false,
  },
  {
    id: 4,
    client_name: 'Kristof',
    client_rating: 5,
    date_created: "2018-12-20",
    client_review: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit laborumad123.',
    show_review: false,
    private_review: false,
  },
];
