<template>
  <div id="register-new">
    <router-view />
  </div>
</template>

<script>
const CitiesProvider = $h.getProvider('CitiesProvider')
const SettingsProvider = $h.getProvider('SettingsProvider')
const DropdownProvider = $h.getProvider('DropdownProvider')
const UserProvider = $h.getProvider('UserProvider')

const MIN_WIDTH = 992

export default {
  data: () => ({
    width: 0
  }),
  watch: {
    width (value) {
      if (value <= 320) {
        document.body.style['background-color'] = '#ffffff'
      } else {
        document.body.removeAttribute('style')
      }
    }
  },
  mounted () {
    this.getDropdown()
    this.getUser()
    this.getCities()
    this.fetchCallCodes()

    this.width = window.innerWidth

    window.addEventListener('resize', event => {
      this.width = window.innerWidth
      this.handleScrollOnIphone()
    }, true)

    this.handleScrollOnIphone()
  },
  beforeDestroy () {
    window.removeEventListener('resize', event => {
      this.width = window.innerWidth

      this.handleScrollOnIphone()
    }, true)
  },
  methods: {
    getDropdown () {
      DropdownProvider.get()
        .then((res) => {
          this.$store.commit('setDropdown', res.data)
        })
        .catch((err) => {
          console.error('Failed to fetch dropdown options!', err)
        })
    },
    getUser () {
      UserProvider.getUser().then((res) => {
        this.$store.commit('setUser', res.data)
      })
    },
    getCities () {
      CitiesProvider.get()
        .then((res) => {
          this.$store.commit('setCities', res.data)
        })
        .catch((err) => {
          console.error('Failed to fetch list of cities', err)
        })
    },
    fetchCallCodes () {
      SettingsProvider.fetchCallingCodes().then((res) => {
        const callingCodes = []

        res.forEach((data) => {
          if (data) {
            const code = (data.callingCodes && data.callingCodes.length) ? data.callingCodes[0] : 0

            if (code) {
              callingCodes.push({
                id: callingCodes.length + 1,
                name: data.name,
                country: data.alpha2Code,
                country_code: code,
                title: `${data.alpha2Code} +${code}`
              })
            }
          }
        })

        this.$store.commit('setContactCodes', callingCodes)
      })
    },
    handleScrollOnIphone () {
      const body = document.body
      const app = document.getElementById('app')
      const iphone = /iPhone/i.test(navigator.userAgent)

      if (!iphone) return
      body.classList.add('iphone')

      if (this.width < MIN_WIDTH) {
        body.classList.add('disable-scroll')
        app.classList.add('scroll')

        return
      }

      body.classList.remove('disable-scroll')
      app.classList.remove('scroll')
    }
  }
}
</script>

<style lang="scss">
.iphone {
  &.disable-scroll {
    overflow-y: hidden;
    position: fixed;
  }

  .scroll {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: auto;
  }

  .disable-vertical-scroll {
    overflow-y: hidden;
  }
}
</style>
