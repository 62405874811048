var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "z-depth-0 login-nav", attrs: { id: "desktop-header" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.selectedLocale === "en"
        ? _c("div", { staticClass: "links right-align" }, [
            _c(
              "a",
              { attrs: { href: "https://kryptonescort.com/en/discovery/" } },
              [_vm._v(_vm._s(_vm.$translate("app.header.about")))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticStyle: { margin: "0 12px 0 13px" },
                attrs: { href: "https://kryptonescort.com/en/information/" }
              },
              [_vm._v(_vm._s(_vm.$translate("app.header.faq")))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticStyle: { margin: "0 13px" },
                attrs: { href: "https://kryptonescort.com/en/new/" }
              },
              [_vm._v(_vm._s(_vm.$translate("app.header.start_escort")))]
            ),
            _vm._v(" "),
            _c("a", { attrs: { href: "https://kryptonescort.com/blog/en/" } }, [
              _vm._v(_vm._s(_vm.$translate("app.header.blog")))
            ])
          ])
        : _c("div", { staticClass: "links right-align" }, [
            _c(
              "a",
              { attrs: { href: "https://kryptonescort.com/entdecken/" } },
              [_vm._v(_vm._s(_vm.$translate("app.header.about")))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticStyle: { margin: "0 12px 0 13px" },
                attrs: { href: "https://kryptonescort.com/information/" }
              },
              [_vm._v(_vm._s(_vm.$translate("app.header.faq")))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticStyle: { margin: "0 13px" },
                attrs: { href: "https://kryptonescort.com/new/" }
              },
              [_vm._v(_vm._s(_vm.$translate("app.header.start_escort")))]
            ),
            _vm._v(" "),
            _c("a", { attrs: { href: "https://kryptonescort.com/blog/" } }, [
              _vm._v(_vm._s(_vm.$translate("app.header.blog")))
            ])
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "language-selector right-align" }, [
        _c(
          "a",
          {
            staticClass: "padding-x-xs",
            class: _vm.isGermanSelected,
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.setLanguage("de")
              }
            }
          },
          [_vm._v("DE")]
        ),
        _c("span", { staticClass: "lang-switch-link separator" }, [
          _vm._v("|")
        ]),
        _c(
          "a",
          {
            staticClass: "padding-x-xs",
            class: _vm.isEnglishSelected,
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.setLanguage("en")
              }
            }
          },
          [_vm._v("EN")]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "site-logo" }, [
      _c("a", { attrs: { href: "https://kryptonescort.com" } }, [
        _c("img", {
          staticClass: "valign-middle",
          staticStyle: { "border-radius": "4px", "margin-right": "12px" },
          attrs: { src: "/img/icons/kr-logo-144.png", width: "56" }
        }),
        _c(
          "strong",
          {
            staticClass: "valign-middle",
            staticStyle: { "font-size": "20px", color: "#2F3740" }
          },
          [_vm._v("girl next door")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }