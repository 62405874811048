import $ from 'jquery'
window.Materialize = {}

try {
  window.Materialize = require('materialize-css')
} catch {
  // do nothing
}

const DEFAULT_MESSAGE = ''
const DEFAULT_DURATION = Infinity

const getTemplate = (message) => {
  return $(`
      <span style="font-size:14px">${message}</span>
      <i class="material-icons">clear</i>
  `)
}

const getTemplateNew = (message, type = 'success') => {
  return $(`
      ${
        type === 'info'
          ? '<img src="/img/icons/info-circle-outline.svg" alt="" />'
          : ''
      }

      ${
        type === 'success'
          ? '<img src="/img/icons/ionic-ios-checkmark-circle-outline.svg" alt="" />'
          : ''
      }

      <span style="font-size:16px">${message}</span>
  `)
}

const hasActiveToast = () => {
  var toast = $('.toast')

  return toast.length > 0
}

const delegateCloseHandler = () => {
  $(document).on('click', '#toast-container .custom-tooltip > i.material-icons', function () {
    var toastElement = $('.toast').first()[0]
    var toastInstance = toastElement.M_Toast
    toastInstance.remove()
  })
}

export const tooltipPlugin = {
  toast: (message = DEFAULT_MESSAGE, duration = DEFAULT_DURATION) => {
    // remove toast if there's any
    if (hasActiveToast()) window.Materialize.Toast.removeAll()

    // create new toast
    window.Materialize.toast(getTemplate(message), duration, 'custom-tooltip')

    // add class to toast container to customize
    $('#toast-container').addClass('tooltip-container')

    // add event listener to close btn
    delegateCloseHandler()
  },

  toastV2: (message = DEFAULT_MESSAGE, duration = DEFAULT_DURATION, position = 'top', classes = [], type = 'success') => {
    // remove toast if there's any
    if (hasActiveToast()) window.Materialize.Toast.removeAll()

    // create new toast
    window.Materialize.toast(getTemplateNew(message, type), duration, 'custom-tooltip')

    // add class to toast container to customize
    $('#toast-container').addClass(['tooltip-container-new', position, ...classes])

    // add event listener to close btn
    delegateCloseHandler()
  },

  removeAll: () => {
    if (hasActiveToast()) window.Materialize.Toast.removeAll()
  }
}
