var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "details" } },
    [
      _c("md-new-navbar", { attrs: { "route-name": "profile" } }),
      _vm._v(" "),
      _vm.ready
        ? _c(
            "div",
            { staticClass: "container", attrs: { id: "content" } },
            [
              _c("h1", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$translate("profile.edit_information")))
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "header-img",
                attrs: {
                  src: "/img/profile-details/basic-info-header-img.svg",
                  alt: "Basic Info"
                }
              }),
              _vm._v(" "),
              _c(
                "vform",
                {
                  ref: "form",
                  staticClass: "details-form",
                  on: { submit: _vm.save }
                },
                [
                  _vm.user && _vm.dropdown && _vm.optsEyeColor.length
                    ? _c("div", { staticClass: "color-switcher-container" }, [
                        _c("div", { staticClass: "color-selection-label" }, [
                          _vm._v(_vm._s(_vm.$translate("profile.eyes")))
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "color-swatch" },
                          _vm._l(_vm.optsEyeColor, function(eyeColor, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                class: eyeColor.key,
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.setSelectedEyeColor(eyeColor.key)
                                  }
                                }
                              },
                              [
                                _vm.isEyeColorSelected(eyeColor.key)
                                  ? _c("img", {
                                      attrs: {
                                        src:
                                          "/img/registration/ic_check_color_selection.svg",
                                        alt: "checkmark"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user && _vm.dropdown && _vm.optsHairColor.length
                    ? _c(
                        "div",
                        { staticClass: "color-switcher-container spaced-top" },
                        [
                          _c("div", { staticClass: "color-selection-label" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$translate("profile.hair")) +
                                "\n        "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "color-swatch hair-color" },
                            _vm._l(_vm.optsHairColor, function(hairColor, i) {
                              return _c(
                                "div",
                                {
                                  key: i,
                                  class: hairColor.key,
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.setSelectedHairColor(
                                        hairColor.key
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm.isHairColorSelected(hairColor.key)
                                    ? _c("img", {
                                        attrs: {
                                          src:
                                            "/img/registration/ic_check_color_selection.svg",
                                          alt: "checkmark"
                                        }
                                      })
                                    : _vm._e()
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user && _vm.dropdown && _vm.optsHeight.length
                    ? _c(
                        "md-select",
                        {
                          staticClass: "spaced-top",
                          attrs: {
                            label: _vm.$translate("profile.height"),
                            placeholder: _vm.$translate("details.height"),
                            "force-mobile": true,
                            "new-design": true
                          },
                          model: {
                            value: _vm.payload.height,
                            callback: function($$v) {
                              _vm.$set(_vm.payload, "height", $$v)
                            },
                            expression: "payload.height"
                          }
                        },
                        [
                          _vm._l(_vm.optsHeight, function(opt) {
                            return _c(
                              "option",
                              {
                                key: opt.key,
                                attrs: { slot: "options" },
                                domProps: { value: opt.key },
                                slot: "options"
                              },
                              [_vm._v(_vm._s(opt.content))]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("height"),
                                  expression: "errors.has('height')"
                                }
                              ],
                              staticClass: "input-helper red-text center-align",
                              attrs: { slot: "errors" },
                              slot: "errors"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.errors.first("height")) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user && _vm.dropdown && _vm.optsWeight.length
                    ? _c(
                        "md-select",
                        {
                          attrs: {
                            label: _vm.$translate("profile.weight"),
                            placeholder: _vm.$translate("details.weight"),
                            "force-mobile": true,
                            "new-design": true
                          },
                          model: {
                            value: _vm.payload.weight,
                            callback: function($$v) {
                              _vm.$set(_vm.payload, "weight", $$v)
                            },
                            expression: "payload.weight"
                          }
                        },
                        [
                          _vm._l(_vm.optsWeight, function(opt) {
                            return _c(
                              "option",
                              {
                                key: opt.key,
                                attrs: { slot: "options" },
                                domProps: { value: opt.key },
                                slot: "options"
                              },
                              [_vm._v(_vm._s(opt.content))]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("weight"),
                                  expression: "errors.has('weight')"
                                }
                              ],
                              staticClass: "input-helper red-text center-align",
                              attrs: { slot: "errors" },
                              slot: "errors"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.errors.first("weight")) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user && _vm.dropdown && _vm.optsBraCup.length
                    ? _c(
                        "md-select",
                        {
                          attrs: {
                            label: _vm.$translate("profile.bra"),
                            placeholder: _vm.$translate("details.bra"),
                            "force-mobile": true,
                            "new-design": true
                          },
                          model: {
                            value: _vm.bra_cup,
                            callback: function($$v) {
                              _vm.bra_cup = $$v
                            },
                            expression: "bra_cup"
                          }
                        },
                        [
                          _vm._l(_vm.optsBraCup, function(opt) {
                            return _c(
                              "option",
                              {
                                key: opt.key,
                                attrs: { slot: "options" },
                                domProps: { value: opt.key },
                                slot: "options"
                              },
                              [_vm._v(_vm._s(opt.content))]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("bra_cup"),
                                  expression: "errors.has('bra_cup')"
                                }
                              ],
                              staticClass: "input-helper red-text center-align",
                              attrs: { slot: "errors" },
                              slot: "errors"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.errors.first("bra_cup")) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.bra_cup
                    ? _c(
                        "div",
                        { staticClass: "margin-bottom switch-container" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "left-align padding-right-md bra-cup-label"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "register.basic.bra_cup.natural"
                                  )
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "right",
                              class: {
                                "switch-pill--APV_REG_REDESIGN": true
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  class: { active: _vm.natural === false },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.setNatural(false)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$translate(
                                          "register.basic.bra_cup.natural_no"
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  class: { active: _vm.natural },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.setNatural(true)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$translate(
                                          "register.basic.bra_cup.natural_yes"
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user && _vm.dropdown && _vm.optsDressSize.length
                    ? _c(
                        "md-select",
                        {
                          attrs: {
                            label: _vm.$translate("profile.dress"),
                            placeholder: _vm.$translate("details.dress"),
                            "force-mobile": true,
                            "new-design": true
                          },
                          model: {
                            value: _vm.payload.dress_size,
                            callback: function($$v) {
                              _vm.$set(_vm.payload, "dress_size", $$v)
                            },
                            expression: "payload.dress_size"
                          }
                        },
                        [
                          _vm._l(_vm.optsDressSize, function(opt, i) {
                            return _c(
                              "option",
                              {
                                key: i,
                                attrs: { slot: "options" },
                                domProps: { value: opt.key },
                                slot: "options"
                              },
                              [_vm._v(_vm._s(opt.content))]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("dress_size"),
                                  expression: "errors.has('dress_size')"
                                }
                              ],
                              staticClass: "input-helper red-text center-align",
                              attrs: { slot: "errors" },
                              slot: "errors"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.errors.first("dress_size")) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user && _vm.dropdown && _vm.optsOrigin.length
                    ? _c(
                        "md-select",
                        {
                          attrs: {
                            label: _vm.$translate("profile.origin"),
                            placeholder: _vm.$translate("details.origin"),
                            "force-mobile": true,
                            "new-design": true
                          },
                          model: {
                            value: _vm.payload.origin,
                            callback: function($$v) {
                              _vm.$set(_vm.payload, "origin", $$v)
                            },
                            expression: "payload.origin"
                          }
                        },
                        [
                          _vm._l(_vm.optsOrigin, function(opt, i) {
                            return _c(
                              "option",
                              {
                                key: i,
                                attrs: { slot: "options" },
                                domProps: { value: opt.key },
                                slot: "options"
                              },
                              [_vm._v(_vm._s(opt.content))]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("origin"),
                                  expression: "errors.has('origin')"
                                }
                              ],
                              staticClass: "input-helper red-text center-align",
                              attrs: { slot: "errors" },
                              slot: "errors"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.errors.first("origin")) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("h-footer-btns-apv-redesign", {
                attrs: {
                  cancel: _vm.$translate("app.cancel"),
                  confirm: _vm.$translate("app.save")
                },
                on: {
                  cancel: function($event) {
                    return _vm.$router.push({ name: "profile" })
                  },
                  confirm: _vm.submitButton
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }