import ApiProvider from '@/providers/ApiProvider'

/**
 * Update Krypton Connect setting value
 *
 * @param {Number} enable - Boolean flag for updating setting. (1 = True, 0 = False)
 *
 * @returns {Promise}
*/
export function UpdateKryptonConnectSetting (enable) {
  const payload = { enable }

  return new Promise((resolve, reject) => {
    window.api
      .put({ url: ApiProvider.kryptonConnectSetting(), payload },
        (res) => {
          resolve(res.data.data)
        },
        (err) => {
          this.$notifications.toast(err.message)
          reject(err)
        })
  })
}

/**
 * Update Krypton Private Place setting value
 *
 * @param {Number} enable - Boolean flag for updating setting. (1 = True, 0 = False)
 *
 * @returns {Promise}
*/
export function UpdateKryptonPrivatePlaceSetting (enable) {
  const payload = { enable }

  return new Promise((resolve, reject) => {
    window.api
      .put({ url: ApiProvider.kryptonPrivatePlaceSetting(), payload },
        (res) => {
          resolve(res.data.data)
        },
        (err) => {
          this.$notifications.toast(err.message)
          reject(err)
        })
  })
}

/**
 * Fetch approved connect messages from client.
 *
 * @param {Object} queryFilters - Refer to API docs (https://eventinstitut.atlassian.net/wiki/spaces/AMA/pages/1416790669/Krypton+Connect+API+Documentation#Get-All-Inbox-Data-(APV))
 *
 * @returns {Promise}
*/
export function GetInboxMessages (queryFilters = {}) {
  return new Promise((resolve, reject) => {
    window.api.get({ url: ApiProvider.kryptonConnectInbox(queryFilters) },
      (res) => { resolve(res.data.data) },
      (err) => { reject(err.response) })
  })
}

export function GetInboxMessages2250 (queryFilters = {}) {
  return new Promise((resolve, reject) => {
    window.api.get({ url: ApiProvider.kryptonConnectInbox(queryFilters) },
      (res) => { resolve(res.data) },
      (err) => { reject(err.response) })
  })
}

/**
 * Fetch chat history using token ID.
 *
 * @param {Number} tokenID
 *
 * @returns {Promise}
*/
export function GetMessageHistory (tokenID) {
  return new Promise((resolve, reject) => {
    window.api.get({ url: ApiProvider.kryptonConnectMessages(tokenID) },
      (res) => { resolve(res.data) },
      (err) => { reject(err.response) })
  })
}

/**
 * Send reply message to the given token ID.
 *
 * @param {Number} tokenID
 * @param {String} content
 *
 * @returns {Promise}
*/
export function MessageReply (tokenID, content) {
  const payload = { content }

  return new Promise((resolve, reject) => {
    window.api.post({
      url: ApiProvider.kryptonConnectMessageReply(tokenID),
      payload
    },
    (res) => { resolve(res.data) },
    (err) => { reject(err.response) })
  })
}

/**
 * Fetch client feedbacks.
 *
 * @returns {Promise}
*/
export function FetchFeedbacks () {
  return new Promise((resolve, reject) => {
    window.api.get({ url: ApiProvider.fetchFeedbacks() },
      (res) => { resolve(res.data.data.reviews) },
      (err) => { reject(err.response) })
  })
}

/**
 * Fetch client feedbacks.
 *
 * @returns {Promise}
*/
export function FetchFeedbacksA22250 (query) {
  return new Promise((resolve, reject) => {
    window.api.get({ url: ApiProvider.fetchFeedbacksA22250(null, query) },
      (res) => { resolve(res.data.data.reviews) },
      (err) => { reject(err.response) })
  })
}

/**
 * Blacklist given client ID to the provider.
 *
 * @param {Number} clientID
 *
 * @returns {Promise}
*/
export function BlacklistClient (clientID) {
  return new Promise((resolve, reject) => {
    window.api.put({ url: ApiProvider.kryptonConnectBlacklistClient(clientID) },
      (res) => { resolve(res.data) },
      (err) => { reject(err.response) })
  })
}
