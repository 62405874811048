var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "billing-page" } },
    [
      _c("md-new-navbar"),
      _vm._v(" "),
      _c("div", { staticClass: "container", attrs: { id: "content" } }, [
        _c("div", { staticClass: "billing-header" }, [
          _c("h1", [_vm._v(_vm._s(_vm.$t("title")))]),
          _vm._v(" "),
          _c("div", { staticClass: "billing-header__cards" }, [
            _c(
              "div",
              {
                staticClass:
                  "billing-header__card billing-header__card--unpaid",
                class: {
                  "billing-header__card--disabled":
                    _vm.billingOverview.unpaid_balance === 0
                },
                attrs: { "data-test": "unpaid-container" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "billing-header__card-title",
                    attrs: { "data-test": "unpaid-title" }
                  },
                  [_vm._v(_vm._s(_vm.$t("billing_overview.unpaid")))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "billing-header__card-value",
                    attrs: { "data-test": "unpaid-balance" }
                  },
                  [_vm._v(_vm._s(_vm.unpaidBalance))]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "billing-header__card billing-header__card--credit",
                class: {
                  "billing-header__card--disabled":
                    _vm.billingOverview.credit_balance === 0
                },
                attrs: { "data-test": "credit-container" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "billing-header__card-title",
                    attrs: { "data-test": "credit-title" }
                  },
                  [_vm._v(_vm._s(_vm.$t("billing_overview.credit")))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "billing-header__card-value",
                    attrs: { "data-test": "credit-balance" }
                  },
                  [_vm._v(_vm._s(_vm.creditBalance))]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "billing-header__card-icon",
                    attrs: { href: "#", "data-test": "credit-tooltip" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.showTooltip($event)
                      }
                    }
                  },
                  [_c("i", { staticClass: "material-icons" }, [_vm._v("help")])]
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "billing-tabs" },
          [
            _c(
              "router-link",
              {
                staticClass: "billing-tabs__tab waves-effect",
                class: {
                  "billing-tabs__tab--active": _vm.isTabActive(
                    "billing-v2-unpaid"
                  )
                },
                attrs: { to: { name: "billing-v2-unpaid" } }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("unpaid.label")) + "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "billing-tabs__tab waves-effect",
                class: {
                  "billing-tabs__tab--active": _vm.isTabActive(
                    "billing-v2-paid"
                  )
                },
                attrs: { to: { name: "billing-v2-paid" } }
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("paid.label")) + "\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "billing-content" }, [_c("router-view")], 1)
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }