import FeatureFlagService from '../services/FeatureFlagService'
import Monitoring from '../services/MonitoringService'
import _ from 'lodash'
import CookieService from '../services/CookieService'

/**
 *
 * @param {Object} listeners - {error?:[Func(obj)], init?:[Func], ready?:[Func], update?:[Func], impression?:[Func(obj)]}
 * @param {Object} bootstrap - the bootstrap fflags
 * @returns {Object} Feature Flag Service
 */
const CreateFeatureFlagService = (listeners = {}, bootstrap = { features: [] }) => {
  if (window.Feature) { return false }

  // dont use unleash if in automated test mode
  if (process.env.NODE_ENV === 'test') {
    window.Feature = {
      isEnabled: () => {
        // do nothing
        return false
      },
      getAllEnabledFlags: () => [],
      getVariant: () => ({ isEnabled: false, payload: {} })
    }
    return true
  }

  const mergeCustomizer = (objValue, srcValue) => {
    if (_.isArray(objValue)) {
      return objValue.concat(srcValue)
    }
  }

  // add premade events
  const finalListeners = _.mergeWith(listeners, {
    error: [(err) => {
      console.error('feature flag server cannot be reached', err)
      Monitoring.sendAction('feature flag init error', err)
    }],
    update: [(fflag) => {
      const evt = new CustomEvent('FeatureUpdate', {
        detail: fflag
      })
      document.getRootNode().dispatchEvent(evt)
    }],
    ready: [(fflag) => {
      const evt = new CustomEvent('FeatureReady', {
        detail: fflag
      })
      document.getRootNode().dispatchEvent(evt)
    }],
    impression: [(context) => {
      Monitoring.sendLog('feature flag', Monitoring.LOG_TYPE.INFO, context)
      const evt = new CustomEvent('FeatureImpression', {
        detail: context
      })
      document.getRootNode().dispatchEvent(evt)
    }]
  }, mergeCustomizer)

  const fflag = new FeatureFlagService(
    {
      url: process.env.FE_FLAGS_SERVER_URL || process.env.VUE_APP_FE_FLAGS_SERVER_URL,
      clientKey: process.env.FE_FLAGS_CLIENT_KEY || process.env.VUE_APP_FE_FLAGS_CLIENT_KEY,
      appName: process.env.FE_FLAGS_APP_NAME || process.env.VUE_APP_FE_FLAGS_APP_NAME,
      environment: process.env.FE_FLAGS_ENVIRONMENT || process.env.VUE_APP_FE_FLAGS_ENVIRONMENT,
      bootstrap: bootstrap,
      refreshInterval: process.env.FE_FLAGS_REFRESH_INTERVAL || process.env.VUE_APP_FE_FLAGS_REFRESH_INTERVAL
    },
    finalListeners
  )

  // get session id
  const cookieService = new CookieService(process.env.FE_COOKIE_DOMAIN || process.env.VUE_APP_FE_COOKIE_DOMAIN)
  fflag.setSessionId(cookieService.getCookie('ssn_id'))

  // get user id when available + update session_id
  document.getRootNode().addEventListener('storeUserChange', (evt) => {
    const id = evt.detail.user?.id ?? null
    fflag.setUserId(id)
    fflag.setSessionId(cookieService.getCookie('ssn_id'))
  })

  // assign Feature windowObject to be accessed globally and non modifiable
  Object.defineProperty(window, 'Feature', {
    value: fflag,
    writable: false,
    configurable: false
  })

  return true
}

export {
  CreateFeatureFlagService
}
