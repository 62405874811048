const LoginComponent = require('@/features/auth/components/LoginView.vue').default
const redirectIfAuthenticated = require('@/middleware/redirectIfAuthenticated').default

const loginRouteObject = {
  path: '/:lang(en)?/login',
  name: 'login',
  component: LoginComponent,
  beforeEnter: redirectIfAuthenticated
}

export default loginRouteObject
