import { tooltipPlugin } from '@/features/super-provider/plugins/vue-custom-tooltip.js'
const CLOUDINARY_URL = 'https://res.cloudinary.com/ama-production/image/private/'
const UserProvider = $h.getProvider('UserProvider')
const PhotosProvider = $h.getProvider('PhotosProvider')
const OverlayModifiers = {
  l: 'white.png',
  g: 'adv_eyes',
  fl: 'region_relative'
}

export default {
  data () {
    return {
      photo: null,
      imgSrc: null,
      payload: null,
      hasOverlay: false,
      isSliderActive: false,
      isSending: false,
      imageLoading: false,
      detectedWidth: null
    }
  },
  computed: {
    showMenuTrigger () {
      return !this.isRegister
    },
    FEATURE_VIP_PHOTOS_A2_2415 () {
      return this.$store.getters['featureflag/isEnabled']('VIP_PHOTOS_A2_2415')
    },
    showNewDesign () {
      return this.$route.name === 'register-photos-anonimize' || this.$route.name === 'photos-anonimize'
    },
    isRegister () {
      return this.$route.name === 'register-photos-anonimize'
    },
    isApv () {
      return this.$route.name === 'photos-anonimize'
    }
  },
  mounted () {
    if (!this.$route.params.photo) {
      this.$router.go(-1)
      return
    }

    if (this.showNewDesign) {
      this.setPhoto(this.$route.params.photo)
      this.setImgSrc(this.photo)

      const img = new Image()
      img.src = this.imgSrc
      this.imageLoading = true

      img.decode().then(() => {
        this.imageLoading = false
      })
    } else {
      this.setPhoto(this.$route.params.photo)
      this.setImgSrc(this.photo)
    }

    window.addEventListener('beforeunload', this.setAskBeforeClose)
    window.addEventListener('popstate', this.onPopState)
  },
  methods: {
    async anonimizePhoto (next) {
      if (this.isSending) return

      this.isSending = true

      const isRegister = (this.isRegister) ? 1 : 0

      if (this.FEATURE_VIP_PHOTOS_A2_2415) {
        try {
          const { data: anonimizeData } = await PhotosProvider.anonimizePhoto(this.photo.id, this.payload, isRegister)

          this.setPhoto(anonimizeData)
          this.isSending = false
          this.publishPhoto(next)
        } catch (err) {
          tooltipPlugin.toastV2(err.message, 5000, 'bottom', ['overlay', 'with-cta'], 'info')

          this.isSending = false
          next()
        }
      } else {
        PhotosProvider.anonimizePhoto(this.photo.id, this.payload, isRegister)
          .then((res) => {
            this.setPhoto(res.data)
            this.isSending = false
            this.publishPhoto(next)
          })
          .catch((err) => {
            tooltipPlugin.toastV2(err.message, 5000, 'bottom', ['overlay', 'with-cta'], 'info')
            this.isSending = false
            next()
          })
      }
    },
    async publishPhoto (next) {
      if (this.isSending) return

      this.isSending = true

      window.removeEventListener('beforeunload', this.setAskBeforeClose)
      window.removeEventListener('popstate', this.onPopState)

      if (this.FEATURE_VIP_PHOTOS_A2_2415) {
        try {
          await PhotosProvider.publishPhoto(this.photo.id)

          const { data: publishVariantData } = await PhotosProvider.publishPhotoVariant(this.photo.id)

          this.$store.commit('setLivePhotos', publishVariantData)
          this.$store.commit('sortOtherPhotos')

          const { data: userData } = await UserProvider.getUser()

          this.isSending = false
          this.$store.commit('setPhotos', userData.profiles.photos)

          tooltipPlugin.toastV2(this.$translate('photos.v2.replace_photo_confirmation.message'), 5000, 'bottom', ['overlay'])
          this.$router.push(this.$route.params.return)
        } catch (err) {
          this.isSending = false
          this.$notifications.toast(err.message, 5000)
          next()
        }
      } else {
        PhotosProvider.publishPhoto(this.photo.id)
          .then((res) => {
            this.$store.commit('setLivePhotos', res.data)
            this.$store.commit('sortOtherPhotos')

            UserProvider.getUser()
              .then((user) => {
                this.isSending = false
                this.$store.commit('setPhotos', user.data.profiles.photos)

                tooltipPlugin.toastV2(this.$translate('photos.v2.replace_photo_confirmation.message'), 5000, 'bottom', ['overlay'])
                this.$router.push(this.$route.params.return)
              })
              .catch((err) => {
                this.$notifications.toast(err.message, 5000)
                next()
              })
          })
          .catch((err) => {
            this.isSending = false
            this.$notifications.toast(err.message, 5000)

            next()
          })
      }
    },
    setPayload (width, height) {
      this.payload = { width: width, height: height }
    },
    enableSlider () {
      const sliderRef = document.getElementById('overlaySlider')

      noUiSlider.create(sliderRef, {
        start: 0,
        connect: [true, false],
        range: {
          min: [0, 1],
          '1%': [1, 0.01],
          max: [3]
        }
      })

      this.isSliderActive = true

      sliderRef.noUiSlider.on('change', () => {
        const height = sliderRef.noUiSlider.get()

        if (height > 0) {
          this.enterOverlayMode(height)
        } else {
          this.setHasOverlay(false)
          this.setImgSrc(this.photo)
        }
      })
    },
    enterOverlayMode (height) {
      if (this.detectedWidth) {
        return this.setImageModifiers({
          width: this.detectedWidth,
          height: height
        })
      }

      const isRegister = (this.isRegister) ? 1 : 0

      PhotosProvider.getOverlayDefaults(this.photo.id, isRegister)
        .then((res) => {
          this.detectedWidth = res.data.width
          this.setImageModifiers({
            width: this.detectedWidth,
            height: height
          })
        })
        .catch((err) => {
          tooltipPlugin.toastV2(err.message, 5000, 'bottom', ['overlay', 'with-cta'], 'info')
        })
    },
    setImageModifiers ({ width, height }) {
      const modifiers = _.clone(OverlayModifiers)

      modifiers.w = width
      modifiers.h = height

      var origModifiers = this.parseTransformations(this.photo.transformations)
      var newModifiers = this.parseTransformations({ overlay: modifiers })

      this.setImgSrc(origModifiers + newModifiers)
      this.setPayload(modifiers.w, modifiers.h)
      this.setHasOverlay(true)
    },
    exitOverlayMode () {
      this.setHasOverlay(false)
      this.destroyOverlay()
      this.setImgSrc(this.photo)
    },
    initOverlay (start, callback) {
      var sliderRef = document.getElementById('overlaySlider')
      var slider = noUiSlider.create(sliderRef, {
        start: start,
        connect: [true, false],
        range: {
          min: [!this.showNewDesign ? 1 : 0],
          max: [3]
        }
      })

      sliderRef.noUiSlider.on('change', () => {
        callback(sliderRef.noUiSlider.get())
      })
    },
    destroyOverlay () {
      document.getElementById('overlaySlider').noUiSlider.destroy()
    },
    setHasOverlay (val) {
      this.hasOverlay = val
    },
    parseTransformations (transformations) {
      if (!transformations) return null

      var imgModifier = ''

      for (var type in transformations) {
        if (type === 'sign') continue

        for (var prop in transformations[type]) {
          imgModifier += prop + '_' + transformations[type][prop] + ','
        }

        imgModifier = imgModifier.substring(0, imgModifier.length - 1)
        imgModifier = imgModifier + '/'
      }

      return imgModifier
    },
    setPhoto (photo) {
      this.photo = photo
    },
    setImgSrc (params) {
      if (typeof params === 'string') { this.imgSrc = CLOUDINARY_URL + params + this.photo.public_id } else { this.imgSrc = CLOUDINARY_URL + this.parseTransformations(params.transformations) + params.public_id }
    },
    async setAskBeforeClose (evt) {
      evt.preventDefault()

      evt.returnValue = ''

      const res = await this.$store.dispatch('cancelUpload', this.photo.id)

      this.$router.go(-1)
    },
    cancelUpload (event) {
      window.removeEventListener('beforeunload', this.setAskBeforeClose)
      window.removeEventListener('popstate', this.cancelUpload)

      this.$store.dispatch('cancelUpload', this.photo.id)
    },
    triggerCancelUpload (event) {
      this.cancelUpload()

      if (this.isRegister) {
        this.$router.push(`/registration/photos/${this.$route.params.cancel?.params?.type}`)
      } else if (this.isApv) {
        this.$router.push('/profile/photos-v2')
      } else {
        this.$router.go(-1)
      }
    },
    onPopState () {
      window.removeEventListener('beforeunload', this.setAskBeforeClose)
      window.removeEventListener('popstate', this.onPopState)
    }
  }
}
