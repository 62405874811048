import { buildParam } from '@/helpers/api'

export function firstLoginSeen () {
  return new Promise((resolve, reject) => {
    window.api
      .put({ url: buildParam('/v2/first-login/seen') },
        (success) => {
          resolve(success.data.data)
        },
        (err) => {
          window.NotificationPlugin.toast(err.message)
          reject(err)
        })
  })
}
