const KILOBYTE = 0.001

export function groupBy (arr, fn) {
  return arr.map(typeof fn === 'function' ? fn : val => val[fn])
    .reduce((acc, val, i) => {
      acc[val] = (acc[val] || []).concat(arr[i])
      return acc
    }, {})
}

export function hrToSeconds (hour) {
  return hour * 3600
}

export function minutesToSeconds (minutes) {
  return minutes * 60
}
export function secondsToMilliseconds (seconds) {
  return seconds * 1000
}

export function getProvider (file) {
  return require('../providers/' + file + '.js').default
}

export function getManager (file) {
  return require('../managers/' + file + '.js').default
}

export function getModel (file) {
  return require('../models/' + file + '.js').default
}

export function getMixin (file) {
  return require('../mixins/' + file + '.js').default
}

export function getFixture (file) {
  return require('../fixtures/' + file + '.js').default
}

export function getConstant (file, target) {
  if (!target) target = 'default'
  return require('../constants/' + file + '.js')[target]
}

export function processErrors (vm, errors) {
  for (const key in errors) {
    vm.errors.add(key, errors[key][0])
  }
}

export function obj2arr (obj) {
  var array = []

  for (const key in obj) {
    array.push({
      key: key,
      content: obj[key]
    })
  }

  return array
}

export function string2json (data) {
  if (_.isString(data)) return JSON.parse(data)
  return data
}

/**
 * Calculate countdown of two datetime and return a formatted value.
 *
 * @param {momentjs} start - Start Time
 * @param {momentjs} end - End Time
 *
 * @return String -- Countdown Format
 */
export function calcCountdown (start, end, altFormat = false, format = 'full', reverse = true) {
  if (start && end && start._isValid) {
    const dur = moment.duration(end.diff(start))

    let h = Math.abs(Math.floor(dur.asHours()))
    let m = Math.abs(Math.floor(dur.minutes()))
    let s = Math.abs(Math.floor(dur.seconds()))

    if (h < 10) { h = `0${h}` }
    if (m < 10) { m = `0${m}` }
    if (s < 10) { s = `0${s}` }

    if (reverse) {
      // Prevent the countdown from displaying timer below 00h:00m:00s
      if (dur.valueOf() > 0) {
        if (format === 'full') { return (altFormat) ? `${h}:${m}:${s}` : `${h}h : ${m}m : ${s}s` }
        return (altFormat) ? `${m}:${s}` : `${m}m : ${s}s`
      }
    } else {
      if (format === 'full') { return (altFormat) ? `${h}:${m}:${s}` : `${h}h : ${m}m : ${s}s` }
      return (altFormat) ? `${m}:${s}` : `${m}m : ${s}s`
    }
  }

  if (format === 'full') { return (altFormat) ? '00:00:00' : '00h : 00m : 00s' }
  return (altFormat) ? '00:00' : '00m : 00s'
}

/**
 * Get the time difference of two moment.js objects and return value by `measurement`
 *
 * @param {momentjs} start - Start Time
 * @param {momentjs} end - End Time
 * @param {string} measurement - Value type to return - ['hours', 'minutes', 'seconds'] DEFAULT: 'hours'
 *
 * @return Number
 */
export function calcTimeDiff (start, end, measurement = 'hours') {
  if (start && end) {
    const dur = moment.duration(end.diff(start))

    switch (measurement) {
      case 'hours':
        return Math.floor(dur.asHours())
      case 'minutes':
        return Math.floor(dur.asMinutes())
      case 'seconds':
        return Math.floor(dur.asSeconds())
      case 'days':
        return Math.floor(dur.asDays())
    }
  }
  return null
}

export function minToSeconds (min) {
  return min * 60
}

export function secToMilliseconds (sec) {
  return sec * 1000
}

export function byteToKilobyte (byte = 1, decimal = 1) {
  return (byte * KILOBYTE).toFixed(decimal)
}

export function byteToMegabyte (byte = 1, decimal = 1) {
  return ((byte * KILOBYTE) / 1000).toFixed(decimal)
}

/**
 * Prettify seconds to time format (eg: 60 seconds to 00:01:00).
 *
 * @param {Number} seconds
 * @param {String} format - Moment.JS accepted format
 *
 * @returns {String}
*/
export function prettifySecondsDisplay (seconds, format) {
  return moment().hour(0)
    .minutes(0)
    .second(seconds)
    .format(format)
}

export function isCandidateProfile (user) {
  if (user) {
    return user?.register_progress === 0 && user?.profiles?.ccc_provider_id === null
  }
  return false
}

export function isProviderProfile (user) {
  if (user) {
    return user.register_progress === 0 && user.profiles.ccc_provider_id !== null
  }
  return false
}

export function isEmpty (value = null) {
  return value === null || value === ''
}
