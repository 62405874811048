<template>
  <div id="block-client-success" class="modal left-align">
    <div class="modal-content center-align">
      <h4 class="modal-title" v-html="$translate('inbox.block.success.title')" />
      <i class="mdi mdi-check-circle krypton-green-text valign-middle" style="font-size: 72px;" /><br>
      <p class="content grey-text text-darken-1">
        <span v-html="$translate('inbox.block.success.explanation_1')" /> <a href="#" class="krypton-pink-text" @click.prevent="onSupportLink">{{ $translate('inbox.block.success.explanation_support') }}</a>{{ $translate('inbox.block.success.explanation_2') }}
      </p>
    </div>
    <div class="modal-footer" style="border-top: 1px solid #E1E6EB; text-align: center;">
      <a href="#" class="btn-flat waves-effect waves-dark krypton-pink-text" @click.prevent="confirm">
        {{ $translate('inbox.cta_buttons.ok_understand') }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    confirm ($e) {
      this.$emit('confirm', $e)
    },
    onSupportLink () {
      this.$emit('onsupport')
    }
  }
}
</script>

<style lang="scss">
#block-client-success {
  @media screen and (max-width: 320px) {
    .content {
      margin: 0 !important;
    }
    .modal-footer .btn-flat {
      font-size: 14px !important;
    }
  }
}
</style>
