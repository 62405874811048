<template>
  <div id="registration-complete">
    <template>
      <template v-if="showSuccessScreenWithCta">
        <complete-desktop-view />
        <complete-mobile-view />
      </template>
      <complete-default-view v-else />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import CompleteDefaultView from './default.vue'
import CompleteDesktopView from './desktop.vue'
import CompleteMobileView from './mobile.vue'

export default {
  components: {
    CompleteDefaultView,
    CompleteDesktopView,
    CompleteMobileView
  },
  computed: {
    ...mapGetters({
      showSuccessScreenWithCta: 'showSuccessScreenWithCta',
      showDefaultScreen: 'showDefaultScreen'
    })
  },
  mounted () {
    // We'll do a little hack for background color because of mobile bug on keyboard
    if (document.getElementById('register')) {
      document.getElementById('register').style.padding = '0'
    }
  },
  beforeDestroy () {
    if (document.getElementById('register')) {
      document.getElementById('register').removeAttribute('style')
    }
  }
}
</script>

<style lang="scss" scoped>
#registration-complete-desktop.wrapper {
    display: none;

    @media screen and (min-width: 1025px) {
        display: flex;
    }
}

#registration-complete-mobile.wrapper {
  display: flex;

  @media screen and (min-width: 992px) {
      display: none;
  }
}
</style>
