<template>
  <div id="affiliate-faq" class="page">
    <div class="container">
      <!-- Connect FAQ Header -->
      <faq-header
        :header-title="$translate('faq.affiliate.title')"
        :header-tagline="$translate('faq.affiliate.intro')"
        main-header-logo="/img/affiliate/affiliate-reward-modal.svg"
        primary-header-color="#FF0073"
        secondary-header-color="#962BDA"
        header-gradient-angle="234deg"
        tagline-gradient-angle="249deg"
        :header-img-dimensions="{ height: '107px', width: '125px' }"
      />
      <div id="content" class="container">
        <div class="faq">
          <ul class="q-and-a">
            <li v-for="(item, key) in $t('faq.list')" :key="key">
              <div class="question">
                <span class="question-number">{{ getItemNumber(key) }}.</span> <span class="label">{{ $t(`faq.list[${key}].title`, {
                  amount: getRewardByCountryCode
                }) }}</span>
              </div>
              <div class="answer">
                <span v-html="$t(`faq.list[${key}].content`, getArgumentsForFAQContent)" />
                <router-link v-if="isLastItem(key)" :to="{ name: 'support-v2' }" style="text-decoration: underline">Support</router-link>
              </div>
            </li>
          </ul>
        </div>
        <faq-cta
          route-name="affiliate-overview"
          :cta-txt="$translate('faq.affiliate.cta')"
          background-gradient-class="--pink"
        />
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/affiliate/translations/landing-page.json"></i18n>

<script>
import FAQHeader from '@/features/provider-faq/components/FAQHeader.vue'
import FAQCta from '@/features/provider-faq/components/FAQCta.vue'
import faqMixin from '@/features/affiliate/mixins/faq.mixin'

export default {
  components: {
    'faq-header': FAQHeader,
    'faq-cta': FAQCta
  },
  mixins: [faqMixin],
  data: () => ({
    prevRoute: null
  }),
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  methods: {
    getItemNumber (itemKey) {
      // We need to add 1 to the itemKey since it always starts at index 0
      // Doing so will make the numbering correct
      return parseInt(itemKey) + 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/q-and-a.scss';

#affiliate-faq #content {
  text-align: left;
  padding-left: 32px;
  padding-right: 32px;
  font-family: 'Proxima Nova';
  font-size: 18px;
  color: #2F3740;
  background: #FFFFFF;
}
</style>
