<template>
  <div v-if="isVisible" data-test="video-shortcut" class="video-shortcut">
    <div class="video-shortcut--container">
      <div class="video-shortcut--content" :class="{ 'full': hasBeenPlayed || hasVideoEnded }">
        <video
          v-if="hasBeenPlayed"
          autoplay
          controls
          class="video-shortcut--video"
          controlsList="nodownload"
          @ended="showReplayScreen"
        >
          <source data-test="video-source" :src="videoSrc" type="video/mp4">
        </video>
        <div v-else-if="hasVideoEnded" class="video-shortcut--replay">
          <div>
            <a href="#" class="video-shortcut--replay-cta" @click.prevent="playVideo">
              <img data-test="video-replay" src="@/features/onboarding-lesson/assets/onboarding-lesson-replay.svg" alt="replay icon">
            </a>
            <span>{{ $t('replay') }}</span>
          </div>
        </div>
        <div v-else class="video-shortcut--thumbnail">
          <div class="video-shortcut--thumbnail-bg" />
          <a href="#" data-test="video-play" class="video-shortcut--thumbnail-cta" @click.prevent="playVideo">
            <img src="@/features/onboarding-lesson/assets/onboarding-lesson-video-player-lg.svg">
          </a>
        </div>
        <a href="#" data-test="video-close" class="video-shortcut--close" @click.prevent="closeShortcut">
          <i class="material-icons">close</i>
        </a>
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/onboarding-lesson/translations/video.json"></i18n>

<script>
import { mapGetters } from 'vuex'
import OnboardingProvider from '@/providers/OnboardingProvider'
import { LESSONS, VIDEOS } from '@/features/onboarding-lesson/constants'

const ACCEPTED_VALUES = [
  LESSONS.INTERVIEW,
  LESSONS.AVAILABILITY,
  LESSONS.DATES,
  LESSONS.FEEDBACK
]

export default {
  props: {
    value: {
      type: Number,
      default: LESSONS.INTERVIEW
    }
  },
  data () {
    return {
      isVisible: false,
      hasBeenPlayed: false,
      hasVideoEnded: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    }),
    PHOTO_FALLBACK_A2_2720 () {
      return this.$store.getters['featureflag/isEnabled']('PHOTO_FALLBACK_A2_2720')
    },
    videoSrc () {
      const countryCode = this.user?.profiles?.country_code?.toLowerCase() ?? 'de'

      let lesson

      switch (this.value) {
        case LESSONS.FEEDBACK:
          lesson = 'feedback'
          break
        case LESSONS.DATES:
          lesson = 'dates'
          break
        case LESSONS.AVAILABILITY:
          lesson = 'availability'
          break
        case LESSONS.INTERVIEW:
        default:
          lesson = 'interview'
      }

      if (VIDEOS[lesson].hasOwnProperty(countryCode) && VIDEOS[lesson][countryCode].hasOwnProperty(this.$i18n.locale)) {
        if (this.PHOTO_FALLBACK_A2_2720) {
          return VIDEOS[countryCode].photo_fall_back_A2_2720[this.$i18n.locale]
        }

        return VIDEOS[countryCode][this.$i18n.locale]
      } else if (VIDEOS[lesson].hasOwnProperty(countryCode)) {
        if (this.PHOTO_FALLBACK_A2_2720) {
          return VIDEOS[countryCode] + 'photo_fall_back_A2_2720'
        }

        return VIDEOS[countryCode].de
      }

      if (this.$i18n.locale !== 'de' && VIDEOS[lesson].hasOwnProperty(`url_${countryCode}`)) {
        if (this.PHOTO_FALLBACK_A2_2720) {
          return countryCode == 'gb' ? VIDEOS[lesson].photo_fall_back_A2_2720_gb : VIDEOS[lesson].photo_fall_back_A2_2720_en
        }
        return VIDEOS[lesson][`url_${countryCode}`]
      }

      if (this.$i18n.locale === 'en') {
        if (this.PHOTO_FALLBACK_A2_2720) {
          return VIDEOS[lesson].photo_fall_back_A2_2720_en
        }

        return VIDEOS[lesson].url_en
      }

      if (this.PHOTO_FALLBACK_A2_2720) {
        return VIDEOS[lesson].photo_fall_back_A2_2720
      } else {
        return VIDEOS[lesson].url
      }
    }
  },
  mounted () {
    this.initializeComponent()
  },
  methods: {
    playVideo () {
      this.hasVideoEnded = false
      this.hasBeenPlayed = true
    },
    showReplayScreen () {
      this.hasVideoEnded = true
      this.hasBeenPlayed = false
    },
    closeShortcut () {
      this.isVisible = false
    },
    async initializeComponent () {
      if (!ACCEPTED_VALUES.includes(this.value)) {
        return
      }

      const isBottomSheetShown = await this.isBottomSheetShown()

      if (!isBottomSheetShown) {
        return
      }

      const isModuleViewed = await this.isModuleViewed()

      this.isVisible = !isModuleViewed

      if (!isModuleViewed) {
        this.viewModule()
      }
    },
    async isBottomSheetShown () {
      let activeLesson

      try {
        activeLesson = await OnboardingProvider.fetchActiveLesson()
      } catch (e) {
        activeLesson = {
          data: {
            show_bottom_sheet: 0
          }
        }
      }

      return activeLesson?.data?.show_bottom_sheet ?? 0
    },
    async isModuleViewed () {
      let lessonProgress

      try {
        lessonProgress = await OnboardingProvider.fetchLessonProgress(this.value)
      } catch (e) {
        lessonProgress = {
          data: {
            is_module_viewed: 1
          }
        }
      }

      return (lessonProgress?.data?.is_module_viewed ?? 1) === 1
    },
    async viewModule () {
      await OnboardingProvider.viewLesson(this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.video-shortcut {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.7) 0% 0% no-repeat padding-box;;
    z-index: 9980;

    .video-shortcut--container {
        width: 100%;
        height: 100%;
        max-width: 376px;
        margin: 0 auto;
        padding: 0 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        .video-shortcut--content {
            position: relative;
            width: 100%;
            padding-top: 56.40%;

            .video-shortcut--video {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                border-radius: 8px;
            }

            .video-shortcut--replay {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                background: transparent linear-gradient(212deg, #D300C5 0%, #7638FA 100%) 0% 0% no-repeat padding-box;
                border-radius: 8px;
                text-align: center;
                font-size: 14px;
                color: #fff;
                opacity: 0.7;

                .video-shortcut--replay-cta {
                  display: block;

                  img {
                    display: block;
                    margin: 0 auto 8px;
                  }
                }

                span {
                  display: block;
                }
            }

            .video-shortcut--thumbnail {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                position: absolute;

                .video-shortcut--thumbnail-bg {
                    width: 100%;
                    height: 100%;
                    border-radius: 12px;
                    background: transparent linear-gradient(241deg, #D300C5 0%, #7638FA 100%) 0% 0% no-repeat padding-box;
                    opacity: 0.7;
                }

                .video-shortcut--thumbnail-cta {
                    top: 50%;
                    left: 50%;
                    margin-top: -32px;
                    margin-left: -40px;
                    display: block;
                    position: absolute;
                    cursor: pointer;

                    img {
                      display: block;
                    }
                }
            }

            a.video-shortcut--close {
                top: 6px;
                right: 6px;
                position: absolute;
                display: block;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                text-align: center;

                i.material-icons {
                    display: block;
                    color: white;
                    line-height: 32px;
                }
            }
        }
        .video-shortcut--content.full {
            padding-top: 177.777777%;
        }
    }
}
</style>
