<template>
  <div id="support">
    <md-navbar :push="{ name: prevNav.name }"
               :brand="prevNav.title"
               icon="arrow_back" />
    <div id="content" class="container">
      <h1 class="title">{{ $translate('support.support_title') }}</h1>
      <vform ref="form" @submit="submitForm">
        <div v-if="errors.has('support')"
             class="grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align">
          <small>{{ errors.first('support') }}</small>
        </div>
        <md-input v-model="subject" type="text" :label="$translate('support.subject_label')" required />
        <md-textarea v-model="message"
                     type="multiline"
                     :label="$translate('support.message_label')" />
      </vform>
      <h-footer-btns :cancel="$translate('app.cancel')"
                     :confirm="$translate('app.send')"
                     @cancel="$router.go(-1)"
                     @confirm="confirm" />
    </div>
  </div>
</template>

<script>
const SettingsProvider = $h.getProvider('SettingsProvider')

export default {
  name: 'Support',
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (to.params.navTitle) {
        vm.prevNav.title = to.params.navTitle
        vm.prevNav.name = from.name
      }
    })
  },
  data () {
    return {
      subject: '',
      message: '',
      prevNav: {
        title: this.$translate('dashboard.title'),
        name: 'dashboard'
      }
    }
  },
  methods: {
    submitForm (next) {
      this.errors.clear()
      SettingsProvider
        .submitSupportTicket(this.subject, this.message)
        .then(() => {
          this.$notifications.toast(this.$translate('support.success_message'), 5000)
          this.$router.push({ name: 'dashboard' })
        })
        .catch((err) => {
          if (err.data.data.subject) {
            this.errors.add('support', err.data.data.subject[0])
          } else if (err.data.data.message) {
            this.errors.add('support', err.data.data.message[0])
          } else {
            this.errors.add('support', err.data.message)
          }
        })
        .finally(() => { next() })
    },
    confirm () {
      this.$refs.form.submit()
    }
  }
}
</script>
