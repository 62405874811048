var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "connect_overview" } },
    [
      _c(
        "div",
        { staticClass: "container", attrs: { id: "content" } },
        [
          _c("feature-overview", {
            attrs: {
              "header-title": _vm.$translate("krypton_connect.header.title"),
              "header-subtitle": _vm.$translate(
                "krypton_connect.header.subtitle"
              ),
              "header-tagline": _vm.$translate(
                "krypton_connect.header.explanation"
              ),
              "main-header-logo":
                "/img/krypton-connect/header-img-v2-overview.svg",
              "tagline-logo": "/img/krypton-connect/header-chat-img-v2.svg",
              "primary-header-color": "#004EE8",
              "secondary-header-color": "#46E4E9",
              "header-gradient-angle": "44deg",
              "tagline-gradient-angle": "18deg",
              "header-navbar-redirection": "dashboard",
              "header-img-dimensions": { height: "209px", width: "245px" },
              features: _vm.features
            }
          }),
          _vm._v(" "),
          _c("q-and-a", {
            attrs: {
              title: _vm.$translate("krypton_connect.q_and_a.title"),
              "default-opened-answer": 0,
              questions: _vm.questions
            }
          }),
          _vm._v(" "),
          _c("overview-cta", {
            attrs: {
              "redirect-to": "krypton-connect-setting",
              label: _vm.$translate("krypton_connect.header.cta"),
              icon: "forum"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }