import { timezoneByCountryCode } from '@/services/TimezoneService'

const ApiProvider = $h.getProvider('ApiProvider')

const TOOLTIP_TIMEOUT = 10000 //  in milliseconds

export default {
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    providerTimezone () {
      return timezoneByCountryCode(this.user?.profiles?.country_code ?? undefined)
    }
  },
  methods: {
    resetSlider () {
      this.pulleeVal = 0
      this.isSending = false
    },
    initializeTooltip () {
      const tooltip = document.querySelector('.material-tooltip')

      if (tooltip === null) {
        $(this.$refs.tooltip).tooltip()
      } else {
        tooltip.remove()
        $(this.$refs.tooltip).tooltip()
      }
    },
    destroyTooltipInstance () {
      document.querySelector('.material-tooltip').remove()
    },
    onClickSupport () {
      if (!this.eventDetail) return
      if (this.eventDetail._support === null) {
        const payload = {
          id: (this.eventDetail._support === null) ? 'new' : this.eventDetail._support.id,
          ticketID: this.eventDetail.id
        }
        this.$router.push({
          name: 'support-v2-request',
          params: payload
        })
      } else {
        this.$store.commit('events/RESET_SELECTED_EVENT_SUPPORT_UNREAD')
        this.$router.push({
          name: 'support-v2-request',
          query: {
            id: this.eventDetail._support.id
          }
        })
      }
    },
    onClickWhereIs () {
      this.navigateTo({ name: 'event-whereis' })
    },
    onClickLate () {
      this.navigateTo({ name: 'event-late' })
    },
    onClickNotAvailable () {
      if (this.isReactivateDeclining || this.isReactivating) return
      if (this.selectedEvent) {
        this.isReactivateDeclining = true
        this.$store
          .dispatch('events/declineReactivation', this.selectedEvent.id)
          .then(() => {
            if (this.user.availability_enabled) {
              this.$store.dispatch(
                'availabilities/refreshAvailabilities',
                false
              )
              this.$router.replace({
                name: 'availability-block',
                params: {
                  event: this.selectedEvent
                }
              })
            }
          })
          .finally(() => {
            this.isReactivateDeclining = false
          })
      }
    },
    onClickReactivate () {
      if (this.isReactivateDeclining || this.isReactivating) return
      if (this.selectedEvent) {
        this.isReactivating = true
        this.$store
          .dispatch('events/reactivate', this.selectedEvent)
          .then(() => {
            this.$store.dispatch('events/syncEvents')
            this.$store.commit('events/SET_EVENT_EXPIRED_SEEN', {
              eventID: this.selectedEvent,
              is_expired_seen: 0
            })

            $h.reactivationSuccesModal(
              this.selectedEvent.client.name,
              this.onReactivationSuccessClose
            )
          })
          .finally(() => {
            this.isReactivating = false
          })
      }
    },
    onReactivationSuccessClose () {
      this.$router.replace({ name: 'events' })
    },
    onPageLeaveCheck (next) {
      const options = {
        title: this.$translate('events.accept_popup.title'),
        content: this.$translate('events.accept_popup.content'),
        confirmText: this.$translate('events.buttons.continue'),
        cancelText: this.$translate('events.buttons.cancel')
      }

      $h.invertedCustomModalConfirm(options, $e => {
        this.destroyTooltipInstance()
        next()
      })
    },
    acceptRequest () {
      if (this.isSending) return

      // Check if meeting falls to midnight, then show late night popup
      let needConfirmation = false

      const selectedEventMeetingStart = this.selectedEvent._meeting_start_at.clone().tz(this.providerTimezone, true)

      const daysDiff = selectedEventMeetingStart.days() - moment().tz(this.providerTimezone).days()

      // We only show late night confirmation if it will happen today at midnight.
      // Our maximum limit for booking is until 01:30 the next day
      if (daysDiff === 1 && selectedEventMeetingStart.hours() <= 1 && selectedEventMeetingStart.minutes() <= 30) {
        needConfirmation = true
      }

      if (needConfirmation) {
        const durationInSeconds = $h.calcTimeDiff(
          moment().tz(this.providerTimezone),
          selectedEventMeetingStart,
          'seconds'
        )

        const convertedDurationToMinutes = Math.floor(durationInSeconds / 60)
        const durationHours = Math.floor(convertedDurationToMinutes / 60)
        const durationMinutes = convertedDurationToMinutes % 60

        const splitTime = [
          durationHours,
          durationMinutes
        ]

        const mTitle = this.$translate('events.late_night.title')
        const mContent = this.$translate('events.late_night.content', { hr: splitTime[0], min: splitTime[1] })

        $h.newCustomModalConfirm({
          title: mTitle,
          content: mContent,
          cancelText: this.$translate('events.late_night.cancel_btn'),
          confirmText: this.$translate('events.late_night.confirm_btn')
        }, () => {
          this.sendConfirmation()
        })
      } else {
        this.sendConfirmation()
      }
    },
    sendConfirmation () {
      this.isSending = true

      const pl = {
        id: this.selectedEvent.id,
        payload: {}
      }

      this.$store
        .dispatch('events/confirm', pl)
        .then(res => {
          this.$notifications.toast(this.$translate('events.accept_request'), 5000)
          this.$store.dispatch('events/syncEvents')
          this.$store.commit('events/UPDATE_EVENT_STATUS', {
            id: this.selectedEvent.id,
            newStatus: EVENT_STATUS.CONFIRMED
          })
          this.$router.push({ name: 'event-confirmed' })
          // Refetch availabilities
          if (this.user.availability_enabled) {
            this.$store.dispatch('availabilities/refreshAvailabilities')
          }
        })
        .finally(() => {
          this.isSending = false
        })
    },
    declineRequest () {
      this.$router.push({ name: 'event-declined-v2' })
    },
    slideEnd () {
      if (this.eventDetail._show_check_in_popup) {
        const modalContent = {
          title: this.$translate('events.checkin.modal.confirmation.title', {
            client_name: this.selectedEvent.client.name
          }),
          content: this.$translate('events.checkin.modal.confirmation.content'),
          cancelText: this.$translate('events.checkin.modal.confirmation.cancel_btn'),
          confirmText: this.$translate('events.checkin.modal.confirmation.confirm_btn')
        }

        $h.newCustomModalConfirm(modalContent, this.updateTicketToCheckin, this.resetSlider)
      } else {
        this.updateTicketToCheckin()
      }
    },
    onDisabledSwipe () {
      if (!this.isOnline) {
        this.$notifications.toast(this.$translate('app.no_internet'), 5000)
        this.pulleeVal = 0
      } else {
        if (!this.isCancelled) {
          this.$notifications.toast(this.$translate('events.event_details.slide_timeout'), 5000)
        }
      }
    },
    navigateTo (to) {
      if (to.name === 'event-late' && !this.canSendLateNotification) {
        return
      }
      if (to.name === 'event-whereis' && !this.canAskWhereIsHe) {
        return
      }
      if (to.name === 'support' && !this.isSupportEnabled) {
        return
      }
      this.$router.push(to)
    },
    getOutfit (key) {
      if (this.dropdown) {
        if (this.dropdown.dress_code.hasOwnProperty(key)) {
          return this.dropdown.dress_code[key]
        }
        return key
      }
      return ''
    },
    setTooltipSeen () {
      window.api.put({ url: ApiProvider.eventTooltipSeen(this.selectedEvent.id) })
    },
    fetchEvents () {
      if (this.selectedEvent) {
        this.$store.dispatch('events/fetchEvents', this.selectedEvent.id)
      }
    },
    fetchEventDetails () {
      if (this.selectedEvent) {
        this.$store
          .dispatch('events/fetchEvents', this.selectedEvent.id)
          .then(event => {
            this.eventDetail = event

            if (
              event._has_confirmed_ticket === 0 &&
              event.is_tooltip_seen === 0 &&
              this.isConfirmed
            ) {
              $(this.$refs.tooltip).trigger('mouseenter')

              this.setTooltipSeen()

              setTimeout(() => {
                $(this.$refs.tooltip).trigger('mouseleave')
              }, TOOLTIP_TIMEOUT)
            }
          })
      }
    },
    setEventSeen () {
      if (this.selectedEvent) {
        if (this.selectedEvent.event_seen === 0) {
          this.$store.commit('events/EVENT_SEEN', this.selectedEvent.id)
        }
      }
    },
    setPendingSeen () {
      if (this.selectedEvent) {
        if (this.selectedEvent.pending_seen === 0) {
          this.$store
            .dispatch('events/pendingEventSeen', this.selectedEvent.id)
            .then(() => {
              this.fetchEvents()
            })
        }
      }
    },
    setExpiredSeen () {
      if (this.selectedEvent) {
        if (this.isUnseenExpired && !this.isReactivated) {
          this.$store
            .dispatch('events/expiredEventSeen', this.selectedEvent.id)
            .then(() => {
              this.fetchEvents()
            })
        }
        if (
          this.selectedEvent.reactivation_status ===
            REACTIVATION_STATUS.REACTIVATION_EXPIRED &&
          this.selectedEvent.reactivation_status ===
            REACTIVATION_STATUS.PROVIDER_DECLINED &&
          this.selectedEvent.reactivation_status ===
            REACTIVATION_STATUS.CANNOT_REACTIVATE &&
          this.selectedEvent.reactivation_failed_seen === 0
        ) {
          this.$store.dispatch(
            'events/reactivatedExpiredEventSeen',
            this.selectedEvent.id
          )
        }
      }
    },
    doCheckin () {
      this.$notifications.toast(this.$translate('events.checkin.success_toast'), 5000)
      this.$store.commit('events/SET_ACTIVE', this.selectedEvent)
      this.$store.commit('events/CHECKIN', this.selectedEvent.id)
      this.$router.replace({ name: 'event-checkin' })
    },
    updateTicketToCheckin () {
      if (this.isSending) return
      if (!this.isOnline) {
        return
      }

      this.timeoutID = null

      this.isSending = true
      this.$store
        .dispatch('events/checkin', this.selectedEvent)
        .then(() => {
          this.doCheckin()
        })
        .catch((err) => {
          /**
           * AMA010-5247
           * This is a special exception that if we get an error message that the provider already checked-in then, we force the local data to be set as checked-in.
           *
           * Note: This is not a long-term solution and should be replaced with more reliable data checking with the API.
           */
          if (err.data.message === 'You have already checked-in') {
            this.doCheckin()
          } else {
            this.$store.commit('events/SET_ACTIVE', null)
            this.$notifications.toast(err.data.message, 5000)
          }
        })
        .finally(() => {
          this.resetSlider()
        })

      // Since axios can't catch timeout as an error :(
      // will need to trigger reset slider by delay
      setTimeout(() => {
        this.resetSlider()
      }, 10500)
    }
  }
}
