<template>
  <div id="krypton-connect" class="page">
    <md-navbar :push="{ name: 'inbox' }" :brand="$translate('sidenav.inbox.title')" :menu-trigger="false"
      icon="arrow_back" />
    <div id="content" class="container">
      <div class="ticket-title left-align container padding-y-md">
        <strong v-if="!isFetching">{{ ticketTitle }}</strong>
        <strong v-else>Fetching messages...</strong>
      </div>
      <div v-if="header.type == 3" class="photo-like-guide">
        <div v-if="header.like_type == 2" class="photo-like-guide__content">
          <div v-html="$translate('inbox.chat.favorite_like', {
            client_details: ticketTitle
          })" />
        </div>
        <div v-else class="photo-like-guide__content">
          <div v-html="$translate('inbox.chat.photo_like', {
            client_details: ticketTitle
          })" />
        </div>
      </div>
      <div :class="messageListClassName">
        <chat-bubble v-for="(mes, i) in messages" :key="i" :me="isProvider(mes.from_group_id)" :content="mes.content"
          :sent-at="mes.created_at" :seen-at="mes.seen_at" @block="onBlockClient(mes.from_user_id)" />
      </div>
      <div class="chat-controls f-position">
        <div class="control-info margin-y-sm">
          <div class="left-side">
            <strong class="message-count left valign-middle">{{ $translate('events.where_is.message_left', {
              count:
                sendMessageRemaining
            }) }}</strong>
            <small class="character-count right">{{ message.length }}/{{ MAX_CHARACTER }}</small>
          </div>
          <div class="spacer" />
        </div>
        <form @submit.prevent="send">
          <md-textarea id="chat-message" v-model="message" type="multiline" class="textfield-box-pink"
            :placeholder="textPlaceholder" :force-active="true" :maxlength="MAX_CHARACTER"
            :disabled="!canSendMessage || isFetching" required @click="onMessageClicked" />
          <button id="send-btn"
            class="btn-floating btn-large btn-round waves-effect btn-krypton-pink waves-effect waves-light margin-top-sm"
            type="submit" :disabled="!canSendMessage || isFetching">
            <i class="material-icons">send</i>
          </button>
        </form>
      </div>
    </div>
  </div>
</div></template>

<script>
import ChatBubble from '@/features/inbox/components/chat/ChatBubble.vue'

import ControllerMixin from './KryptonConnectPage.controller'

export default {
  components: { ChatBubble },
  mixins: [ControllerMixin]
}
</script>

<style lang="scss">
@import "./KryptonConnectPage.style";
</style>
