var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "mobile-setting" } },
    [
      _c("bottomsheet", {
        class: { "max-z-index": _vm.countryPickerShown },
        attrs: { "is-shown": _vm.isShown },
        on: { close: _vm.reset },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("h2", { staticClass: "bottom-sheet__title" }, [
                  _vm._v(_vm._s(_vm.$translate("setting.mobile_setting_title")))
                ]),
                _vm._v(" "),
                _c(
                  "vform",
                  {
                    ref: "form",
                    staticStyle: { "margin-bottom": "24px" },
                    on: { submit: _vm.requestSMSCode }
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "div",
                          { staticClass: "verification-field-container" },
                          [
                            _c(
                              "div",
                              { staticClass: "country-picker-container" },
                              [
                                _c("country-picker-autocomplete", {
                                  staticClass:
                                    "country-picker textfield-box-lg verify-dropdown",
                                  class: {
                                    "has-status-indicator":
                                      _vm.hasTopStatusIndicator,
                                    "country-picker--APV_REG_REDESIGN": true
                                  },
                                  attrs: { caret: true },
                                  on: {
                                    select: _vm.countrySelected,
                                    shown: function($event) {
                                      _vm.countryPickerShown = true
                                    },
                                    closed: function($event) {
                                      _vm.countryPickerShown = false
                                    }
                                  },
                                  model: {
                                    value: _vm.payload.input_code,
                                    callback: function($$v) {
                                      _vm.$set(_vm.payload, "input_code", $$v)
                                    },
                                    expression: "payload.input_code"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mobile-field-container" },
                              [
                                _c(
                                  "md-input",
                                  {
                                    class: {
                                      "with-error": _vm.errors.has("mobile")
                                    },
                                    attrs: {
                                      type: "text",
                                      label: _vm.$translate(
                                        "setting.new_mobile_label"
                                      ),
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.payload.phone_number,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.payload,
                                          "phone_number",
                                          $$v
                                        )
                                      },
                                      expression: "payload.phone_number"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "text-error" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.errors.first("mobile")) +
                                          "\n                "
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.isRequestCode
                          ? _c(
                              "md-input",
                              {
                                class: {
                                  "with-error": _vm.errors.has(
                                    "verification_code"
                                  )
                                },
                                staticStyle: {
                                  background: "#FFFFFF",
                                  "margin-top": "16px"
                                },
                                attrs: {
                                  type: "tel",
                                  label: _vm.$translate(
                                    "setting.sms_code_label"
                                  )
                                },
                                model: {
                                  value: _vm.payload.smsCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.payload, "smsCode", $$v)
                                  },
                                  expression: "payload.smsCode"
                                }
                              },
                              [
                                _c("div", { staticClass: "text-error" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.errors.first("verification_code")
                                      ) +
                                      "\n            "
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                !_vm.isRequestCode
                  ? _c("p", { staticClass: "form-description" }, [
                      _vm._v(_vm._s(_vm.$translate("setting.sms_code_notice")))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isRequestCode
                  ? _c("p", { staticClass: "resend" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$translate("setting.havent_receive")) +
                          "\n        "
                      ),
                      !_vm.resentCountdown
                        ? _c(
                            "strong",
                            {
                              on: {
                                click: function() {
                                  _vm.isRequestCode = false
                                  _vm.submit()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$translate("setting.resend")) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.resentCountdown
                        ? _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$translate("setting.resend")) +
                                " in " +
                                _vm._s(_vm.resentCountdown) +
                                "s\n        "
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c("div", [_vm._v(" ")]),
                _vm._v(" "),
                !_vm.isRequestCode
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "bottom-sheet__cta bottom-sheet__cta--confirm",
                        attrs: { disabled: !_vm.isFormComplete },
                        on: { click: _vm.submit }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$translate("app.save")) +
                            "\n      "
                        )
                      ]
                    )
                  : _c(
                      "button",
                      {
                        staticClass:
                          "bottom-sheet__cta bottom-sheet__cta--confirm full-width",
                        attrs: {
                          disabled:
                            !_vm.isFormComplete || _vm.payload.smsCode === ""
                        },
                        on: { click: _vm.submit }
                      },
                      [_vm._v("\n        Verify\n      ")]
                    )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }