import { buildParam } from '@/services/ApiService'

export const fetch = () => {
  return new Promise((resolve, reject) => {
    window.api.get({
      url: buildParam('/v2/multi-location')
    }, (res) => {
      resolve(res.data.data)
    }, (err) => {
      reject(err?.response?.data ?? err)
    })
  })
}

export const update = (cities) => {
  return new Promise((resolve, reject) => {
    window.api.post({
      url: buildParam('/v2/multi-location'),
      payload: {
        cities,
        _method: 'PUT'
      }
    }, (res) => {
      resolve(res.data.data)
    }, (err) => {
      reject(err?.response?.data ?? err)
    })
  })
}

export const disable = () => {
  return new Promise((resolve, reject) => {
    window.api.post({
      url: buildParam('/v2/multi-location/disable')
    }, (res) => {
      resolve(res.data.data)
    }, (err) => {
      reject(err?.response?.data ?? err)
    })
  })
}

export default {
  fetch,
  update,
  disable
}
