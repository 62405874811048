<template>
  <div id="super-provider-status">
    <promo-card />
    <prime-time-availability />
    <client-rating />
    <reliability-rating />
    <unpaid-invoices />
    <cancellations />
    <super-provider-video />
  </div>
</template>

<script>
import PromoCard from '@/features/super-provider/components/SuperProviderCard.vue'
import PrimeTimeAvailability from '@/features/super-provider/components/requirements/PrimeTimeAvailability.vue'
import ClientRating from '@/features/super-provider/components/requirements/ClientRating.vue'
import ReliabilityRating from '@/features/super-provider/components/requirements/ReliabilityRating.vue'
import UnpaidInvoices from '@/features/super-provider/components/requirements/UnpaidInvoices.vue'
import Cancellations from '@/features/super-provider/components/requirements/Cancellations.vue'
import SuperProviderVideo from '@/features/super-provider/components/SuperProviderVideo.vue'

export default {
  components: {
    PromoCard,
    PrimeTimeAvailability,
    ClientRating,
    ReliabilityRating,
    UnpaidInvoices,
    Cancellations,
    SuperProviderVideo
  }
}
</script>
