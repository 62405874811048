<template>
  <div id="krypton-connect-setting" class="wrapper">
    <md-new-navbar
      :route-name="getRedirectBackRouteName"
    />
    <div id="content" class="container">
      <h1 class="title">{{ $translate('inbox.settings.title') }}</h1>
      <p ref="description" class="description" v-html="$translate('inbox.settings.link_to_connect')" />
      <div class="switch-input margin-bottom-md">
        <span>{{ $translate('inbox.settings.setting_label') }}</span>
        <div class="switch switch-solid">
          <label>
            <input id="switch" v-model="isEnabled" type="checkbox" :disabled="isRequesting">
            <span class="lever " />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { UpdateKryptonConnectSetting } from '@/features/inbox/utils/KrptonConnectAPI'

export default {
  data: () => ({
    isRequesting: false,
    isEnabledCopy: false,
    isEnabled: false,
    prevRoute: null
  }),
  computed: {
    ...mapGetters({
      connectEnabled: 'getConnectEnabled'
    }),
    getRedirectBackRouteName () {
      return this.prevRoute && this.prevRoute.name === 'connect-faq' ? 'connect-faq' : 'setting'
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  watch: {
    isEnabled () {
      if (this.isEnabled === this.isEnabledCopy) return

      if (!this.isEnabled) {
        const mTitle = this.$translate('inbox.settings.deactivation_confirmation.title')
        const mContent = this.$translate('inbox.settings.deactivation_confirmation.explanation')
        const confirmText = this.$translate('inbox.cta_buttons.deactivate')
        const onConfirm = this.doDeactivate

        $h.invertedCustomModalConfirm({
          title: mTitle,
          content: mContent,
          cancelText: this.$translate('inbox.cta_buttons.cancel'),
          confirmText: confirmText
        }, onConfirm, this.onConfirmationDecline)

        return
      }

      this.doActivate()
    }
  },
  mounted () {
    this.isEnabledCopy = this.isEnabled = this.connectEnabled

    const vm = this

    if (this.$refs.description) {
      this.$refs.description.querySelector('a')?.addEventListener('click', function (e) {
        e.preventDefault()
        vm.$router.push({ name: 'connect-faq' })
      })
    }
  },
  methods: {
    onConfirmationDecline () {
      this.isEnabled = !this.isEnabled
    },
    doActivate () {
      this.isRequesting = true
      this.isEnabledCopy = true

      this.$store.commit('setConnectEnabled', 1)

      UpdateKryptonConnectSetting(1)
        .finally(() => {
          this.isRequesting = false
        })
    },
    doDeactivate () {
      this.isRequesting = true
      this.isEnabledCopy = false

      this.$store.commit('setConnectEnabled', 0)

      UpdateKryptonConnectSetting(0)
        .finally(() => {
          this.isRequesting = false
        })
    }
  }
}
</script>

<style lang="scss">
@import "./KryptonConnectSettingPage.style";
</style>
