var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "long-term-booking-popup__backdrop",
      attrs: { "data-test": "affiliate-popup" }
    },
    [
      _c(
        "div",
        {
          staticClass: "long-term-booking-popup",
          attrs: { "data-test": "affilate-popup-backdrop" }
        },
        [
          _c("div", { staticClass: "long-term-booking-popup__card" }, [
            _c(
              "a",
              {
                staticClass: "long-term-booking-popup__close-btn",
                attrs: { href: "#" }
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    attrs: { "data-test": "ltbp-close-btn" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.closePopup({})
                      }
                    }
                  },
                  [_vm._v("close")]
                )
              ]
            ),
            _vm._v(" "),
            _c("h2", { attrs: { "data-test": "ltbp-title" } }, [
              _vm._v(_vm._s(_vm.$t("title")))
            ]),
            _vm._v(" "),
            _c("img", {
              attrs: {
                "data-test": "ltbp-image",
                src: "/img/availability/ltbp-header-img.svg",
                alt: ""
              }
            }),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "header-description",
                attrs: { "data-test": "ltbp-header-description" }
              },
              [_vm._v(_vm._s(_vm.$t("header_description")))]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "description",
                attrs: { "data-test": "ltbp-description" }
              },
              [_vm._v(_vm._s(_vm.$t("description")))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn waves-effect",
                attrs: { "data-test": "ltbp-cta", href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.closePopup({
                      redirect: { name: "availability" }
                    })
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("cta")))]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }