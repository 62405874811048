var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.invoiceData
    ? _c("div", { attrs: { id: "billing-modal" } }, [
        _c("div", { staticClass: "billing-modal__backdrop" }, [
          _c("div", { staticClass: "billing-modal__card" }, [
            _c("div", { staticClass: "billing-modal__card-title" }, [
              _vm._v(_vm._s(_vm.$t("bank_details.title")))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "billing-modal__card-content" }, [
              _c("div", { staticClass: "billing-modal__card-content-rows" }, [
                _c("div", { staticClass: "billing-modal__card-content-row" }, [
                  _c(
                    "div",
                    { staticClass: "billing-modal__card-content-row-label" },
                    [_vm._v(_vm._s(_vm.$t("bank_details.name")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "billing-modal__card-content-row-value",
                      attrs: { "data-test": "account-name" }
                    },
                    [_vm._v(_vm._s(_vm.bankDetails.account_name))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "billing-modal__card-content-row-icon" },
                    [
                      _c("copy-icon", {
                        attrs: { val: _vm.bankDetails.account_name }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "billing-modal__card-content-row" }, [
                  _c(
                    "div",
                    { staticClass: "billing-modal__card-content-row-label" },
                    [_vm._v(_vm._s(_vm.$t("bank_details.bank")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "billing-modal__card-content-row-value",
                      attrs: { "data-test": "bank-name" }
                    },
                    [_vm._v(_vm._s(_vm.bankDetails.account_bank))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "billing-modal__card-content-row-icon" },
                    [
                      _c("copy-icon", {
                        attrs: { val: _vm.bankDetails.account_bank }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "billing-modal__card-content-row" }, [
                  _c(
                    "div",
                    { staticClass: "billing-modal__card-content-row-label" },
                    [_vm._v("IBAN:")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "billing-modal__card-content-row-value",
                      attrs: { "data-test": "iban" }
                    },
                    [_vm._v(_vm._s(_vm.bankDetails.account_iban))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "billing-modal__card-content-row-icon" },
                    [
                      _c("copy-icon", {
                        attrs: { val: _vm.bankDetails.account_iban }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "billing-modal__card-content-row" }, [
                  _c(
                    "div",
                    { staticClass: "billing-modal__card-content-row-label" },
                    [_vm._v("SWIFT / BIC:")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "billing-modal__card-content-row-value",
                      attrs: { "data-test": "swift" }
                    },
                    [_vm._v(_vm._s(_vm.bankDetails.account_swift))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "billing-modal__card-content-row-icon" },
                    [
                      _c("copy-icon", {
                        attrs: { val: _vm.bankDetails.account_swift }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "billing-modal__card-content-row" }, [
                  _c(
                    "div",
                    { staticClass: "billing-modal__card-content-row-label" },
                    [_vm._v(_vm._s(_vm.$t("bank_details.amount")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "billing-modal__card-content-row-value",
                      attrs: { "data-test": "amount" }
                    },
                    [_vm._v(_vm._s(_vm.amount))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "billing-modal__card-content-row-icon" },
                    [
                      _c("copy-icon", {
                        attrs: { val: _vm.invoiceData.amount.toString() }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "billing-modal__card-content-row" }, [
                  _c(
                    "div",
                    { staticClass: "billing-modal__card-content-row-label" },
                    [_vm._v(_vm._s(_vm.$t("bank_details.reference")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "billing-modal__card-content-row-value",
                      attrs: { "data-test": "invoice-number" }
                    },
                    [_vm._v(_vm._s(_vm.invoiceData.invoice_number))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "billing-modal__card-content-row-icon" },
                    [
                      _c("copy-icon", {
                        attrs: { val: _vm.invoiceData.invoice_number }
                      })
                    ],
                    1
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "billing-modal__card-content--with-note" },
              [
                _c("div", { staticClass: "billing-modal__card-content-note" }, [
                  _vm._v(_vm._s(_vm.$t("bank_details.reference_note")))
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "billing-modal__card-footer" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-link waves-effect",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.closeModal($event)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("bank_details.close")))]
              )
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }