const state = {
  phone_number: null,
  server_error_message: null
}

const getters = {
  getPhoneNumber (state) {
    return state.phone_number
  },
  setServerErrorMessage (state) {
    return state.server_error_message
  }
}

const mutations = {
  setPhoneNumber (state, value) {
    state.phone_number = value
  },
  setServerErrorMessage (state, value) {
    state.server_error_message = value
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
