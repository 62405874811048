var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "rating-box",
      class: { "no-rating": !_vm.hasRating },
      attrs: { id: "performance-rating" }
    },
    [
      _vm.hasRating
        ? _c("div", { staticClass: "average-rating valign-middle" }, [
            _c("i", { staticClass: "valign-middle material-icons" }, [
              _vm._v("star")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "valign-middle" }, [
              _c("strong", [_vm._v(_vm._s(_vm.ratingText))])
            ])
          ])
        : _c("div", { staticClass: "average-rating no-rating" }, [
            _vm.hasRating
              ? _c("i", { staticClass: "valign-middle material-icons" }, [
                  _vm._v("star")
                ])
              : _c("i", { staticClass: "valign-middle material-icons" }, [
                  _vm._v("star_outline")
                ]),
            _vm._v(" "),
            _c("span", { staticClass: "valign-middle" }, [
              _vm._v(
                _vm._s(_vm.$translate("provider_performance.rating.no_rating"))
              )
            ])
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "note" }, [
        _vm.hasRating
          ? _c("span", [
              _vm.rank === _vm.RANKING.TOP_10
                ? _c("strong", [
                    _c("img", {
                      staticClass: "valign-middle",
                      attrs: {
                        src: "/img/performance-overview/medal_enabled.svg"
                      }
                    }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$translate("provider_performance.rating.top_10")
                        )
                    )
                  ])
                : _vm.rank === _vm.RANKING.TOP_30
                ? _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.$translate("provider_performance.rating.top_30")
                      )
                    )
                  ])
                : _c(
                    "strong",
                    {
                      staticClass: "clickable",
                      on: {
                        click: function($event) {
                          return _vm.$router.push({ name: "faq" })
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "display-block",
                          staticStyle: {
                            "font-size": "14px",
                            "line-height": "14px"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$translate("provider_performance.rating.tip")
                            ) + " "
                          ),
                          _c("i", {
                            staticClass: "mdi mdi-information valign-middle",
                            staticStyle: {
                              color: "#78838f",
                              "font-size": "16px"
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "krypton-pink-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$translate(
                              "provider_performance.rating.below_top_30"
                            )
                          )
                        )
                      ])
                    ]
                  )
            ])
          : _c("span", [
              _c("img", {
                staticClass: "valign-middle",
                staticStyle: { "margin-right": "29px" },
                attrs: { src: "/img/performance-overview/medal.svg" }
              }),
              _vm._v(" —")
            ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }