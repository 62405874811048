import { buildParam } from '@/services/ApiService'

export const getCities = (countryCode = null) => {
  let query = 'custom_content:{null}'

  if (countryCode) {
    query += `,country_code:=:${countryCode}&order_by=ordering:asc`
  }

  const url = buildParam(`${process.env.KRYPTON_CLIENT_API}/cities`, { paginate: 0, query: query })

  return new Promise((resolve, reject) => {
    window.api.get({ url },
      (success) => { resolve(success.data) },
      (err) => { reject(err.response.data) })
  })
}
