<template>
  <div v-if="isShown" class="notice-modal">
    <div class="notice-modal__container">
      <div class="notice-modal__content">
        <slot name="content">
          <h2 class="notice-modal__title">{{ title }}</h2>
          <p class="notice-modal__text">{{ text }}</p>
        </slot>
      </div>
      <div class="notice-modal__footer">
        <slot name="footer">
          <div class="notice-modal__confirm-cta">
            <a href="#" @click.prevent="confirm">{{ confirmText }}</a>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isShown: {
      type: Boolean,
      default: false
    },
    onConfirm: {
      type: Function,
      default: undefined
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: ''
    }
  },
  methods: {
    confirm () {
      if (typeof this.onConfirm === 'function') {
        this.onConfirm()
      }

      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.notice-modal {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  top: 0;
  left: 0;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.7;
  }

  .notice-modal__container {
    width: 100%;
    max-width: 568px;
    background-color: white;
    height: auto;
    position: relative;
    top: 50% !important;
    transform: scalex(1) translate(0, -50%) !important;
    border-radius: 12px;
    text-align: center;
    margin: 0 auto;

    @media screen and (max-width: 600px) {
      margin: 0 16px;
      width: 94%;
    }
    @media screen and (max-width: 420px) {
      width: 92%;
    }
  }

  .notice-modal__content {
    padding: 32px 24px 24px;
  }

  .notice-modal__title {
    margin-top: 0;
    margin-bottom: 19px;
    font-size: 20px;
    color: #2F3740;
  }

  .notice-modal__text {
    font-size: 18px;
    color: #2F3740;
    line-height: 28px;
    margin: 0;
  }

  .notice-modal__footer {
    padding: 17px 0 30px;
    border-top: 1px solid #CAD1D9;
  }

  .notice-modal__confirm-cta {
    a {
      color: #E30074;
      cursor: pointer;
      display: inline-block;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      position: relative;
      text-align: center;
      text-decoration: underline;
      text-transform: uppercase;
      padding: 10px 0;
    }
  }
}
</style>
