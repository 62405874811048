export default {
  2: {
    name: 'register-agreement'
  },
  3: {
    name: 'register-overview'
  },
  4: {
    name: 'register-basic',
    params: {
      type: 'city'
    }
  },
  5: {
    name: 'register-basic',
    params: {
      type: 'eye-color'
    }
  },
  6: {
    name: 'register-basic',
    params: {
      type: 'hair-color'
    }
  },
  7: {
    name: 'register-basic',
    params: {
      type: 'origin'
    }
  },
  8: {
    name: 'register-basic',
    params: {
      type: 'height'
    }
  },
  9: {
    name: 'register-basic',
    params: {
      type: 'weight'
    }
  },
  10: {
    name: 'register-basic',
    params: {
      type: 'bra-cup'
    }
  },
  11: {
    name: 'register-basic',
    params: {
      type: 'dress-size'
    }
  },
  12: {
    name: 'register-overview'
  },
  13: {
    name: 'register-photos',
    params: {
      type: 'primary'
    }
  },
  14: {
    name: 'register-overview'
  },
  15: {
    name: 'register-personality',
    params: {
      type: 'intellect'
    }
  },
  16: {
    name: 'register-personality',
    params: {
      type: 'taste'
    }
  },
  17: {
    name: 'register-personality',
    params: {
      type: 'style'
    }
  },
  18: {
    name: 'register-personality',
    params: {
      type: 'name'
    }
  },
  29: {
    name: 'register-potential'
  },
  20: {
    name: 'register-verify'
  },
  21: {
    name: 'register-verify'
  },
  22: {
    name: 'register-rates'
  },
  31: {
    name: 'register-verify'
  },
  32: {
    name: 'register-verify'
  },
  33: {
    name: 'register-rates'
  },
  34: {
    name: 'register-survey'
  },
  41: {
    name: 'register-potential'
  },
  42: {
    name: 'register-verify'
  },
  43: {
    name: 'register-verify'
  },
  44: {
    name: 'register-rates'
  },
  45: {
    name: 'register-survey'
  },
  100: {
    name: 'register-complete'
  }
}
