var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bottom-sheet-autocomplete-backdrop",
      attrs: { id: "bottom-sheet-autocomplete" }
    },
    [
      _c("div", { staticClass: "menu-container" }, [
        _c("div", { staticClass: "menu-header" }, [
          _c(
            "div",
            {
              staticClass: "close-cta",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.onClose($event)
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  src: "/img/registration/bottom-sheet-close.svg",
                  alt: ""
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.header))]),
          _vm._v(" "),
          _c("div", { staticClass: "autocomplete-input-container" }, [
            _c(
              "div",
              { staticClass: "input-field" },
              [
                _c("apv-input", {
                  ref: "btm-sht-autocomplete-input",
                  staticClass: "textfield-box-lg",
                  attrs: {
                    id: "autocomplete-dropdown-input",
                    type: "text",
                    autocomplete: "test",
                    label: _vm.header,
                    "redesign-flag": true
                  },
                  on: {
                    focus: _vm.onInputFocus,
                    onClearInput: _vm.onClearSearchInput
                  },
                  nativeOn: {
                    keydown: function($event) {
                      return _vm.search($event)
                    }
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { ref: "content", staticClass: "option-menu" },
          _vm._l(_vm.clonedOptions, function(option) {
            return _c(
              "div",
              {
                key: _vm.getArrFirstValue(Object.keys(option)),
                staticClass: "option-item-container"
              },
              [
                _c("div", {
                  staticClass: "option-item",
                  attrs: {
                    "data-option-value": _vm.getArrFirstValue(
                      Object.keys(option)
                    )
                  },
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.getArrFirstValue(Object.values(option))
                    )
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.onSelect(_vm.getArrFirstValue(Object.keys(option)))
                    }
                  }
                })
              ]
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }