var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "faq-navbar-wrapper",
      class: { "--with-top-status-indicator": _vm.hasTopStatusIndicator }
    },
    [
      _c(
        "div",
        {
          staticClass: "faq-navbar-container",
          class: { white: _vm.hasScrolledFromTop }
        },
        [
          _c(
            "a",
            {
              staticClass: "back-btn",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.redirect($event)
                }
              }
            },
            [
              !_vm.hasScrolledFromTop
                ? _c("img", {
                    staticClass: "responsive-img",
                    attrs: { src: "/img/white-back-arrow.svg", alt: "back-btn" }
                  })
                : _c("img", {
                    staticClass: "responsive-img",
                    attrs: { src: "/img/back-arrow.svg", alt: "back-btn" }
                  })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "faq-navbar__placeholder" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }