<template>
  <div class="faq-text-item">
    <div class="question">
      <span class="question-number">{{ getItemNumber }}.</span> <span v-text="$t(`faq.list[${itemKey}].title`)" />
    </div>
    <!-- content from translation file -->
    <div
      v-for="(item2, subItemKey) in $t(`faq.list[${itemKey}].content`)"
      :key="subItemKey"
      class="answer"
      v-html="$t(`faq.list[${itemKey}].content[${subItemKey}]`)"
    />
  </div>
</template>

<i18n src="@/features/super-provider/translations/landing-page.json"></i18n>

<script>
export default {
  props: {
    itemKey: {
      type: Number,
      required: true
    }
  },
  computed: {
    getItemNumber () {
      // We need to add 1 to the itemKey since it always starts at index 0
      // Doing so will make the numbering correct
      return parseInt(this.itemKey) + 1
    }
  }
}
</script>

<style lang="scss" scoped>
  .faq-text-item {font-family: 'Proxima Nova';
    color: #2F3740;

    .question {
      display: flex;
      font-size: 20px;
      font-family: 'Proxima Nova Semibold';

      .question-number {
        color: #E30074;
      margin-right: 4px;
      font-size: 18px;
      }
    }

    .answer {
      font-size: 18px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
</style>
