<template>
  <div class="navigator">
    <div>
      <button class="btn btn-secondary display-block"
        :disabled="prevDisabled"
        @click.prevent="onPrev"
      >
        {{ prevText }}
      </button>
    </div>
    <div>
      <button class="btn btn-raised waves-effect waves-light display-block btn-krypton-pink waves-effect waves-light"
        :disabled="nextDisabled"
        data-test="navigator-cta"
        @click.prevent="onNext"
      >
        {{ nextText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'prev',
    'next',
    'prevDisabled',
    'nextDisabled'
  ],
  computed: {
    prevText () {
      return this.prev ? this.prev : this.$translate('register.navigator.prev')
    },
    nextText () {
      return this.next ? this.next : this.$translate('register.navigator.next')
    }
  },
  methods: {
    onPrev ($event) {
      this.$emit('prev', $event)
    },
    onNext ($event) {
      this.$emit('next', $event)
    }
  }
}
</script>

<style lang="scss" scoped>
.navigator {
  & {
    left: 0;
    bottom: 15px;
    width: 100%;
    padding: 8px 24px 16px;
    position: fixed;
    box-sizing: border-box;
  }
  & > div {
    & {
      width: 50%;
      float: left;
    }
    &:first-child {
      padding-right: 4px;
    }
    &:last-child {
      padding-left: 4px;
    }
    & > button {
      width: 100%;
      height: 44px;
      font-weight: 900;
      font-size: 0.875rem;
    }
  }
  @media (min-width: 992px) {
    & {
      right: 0;
      width: 100%;
      max-width: 600px;
      margin: 60px auto 0;
      padding: 8px 0 32px;
      position: relative;
      left: unset;
      bottom: unset;
    }
  }
}
</style>
