var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "photo-guide" } },
    [
      _c("bottomsheet", {
        attrs: { "is-shown": _vm.isShown },
        on: {
          close: function() {
            return _vm.$emit("close")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("h2", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$translate("photos.guide.title")))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "sub-title" }, [
                  _vm._v(
                    _vm._s(_vm.$translate("photos.guide.explanation_text"))
                  )
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("div", { staticClass: "case-study" }, [
                  _c("strong", { staticStyle: { "font-style": "italic" } }, [
                    _vm._v("Case Study")
                  ]),
                  _vm._v(": "),
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$translate("photos.guide.case_study")
                      )
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    ref: "collapsible",
                    staticClass: "collapsible apv-collapsible"
                  },
                  [
                    _c("li", [
                      _c("div", { staticClass: "collapsible-header" }, [
                        _c(
                          "p",
                          { staticClass: "tips", attrs: { id: "tip1" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$translate(
                                  "photos.guide.collapsible.tip1.title"
                                )
                              )
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "collapsible-body" }, [
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$translate(
                                "photos.guide.collapsible.tip1.content"
                              )
                            )
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "center" }, [
                          _c("img", {
                            staticClass: "responsive-img",
                            attrs: {
                              src: "/img/photos/guide/tip-1.png",
                              alt: ""
                            }
                          })
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("div", { staticClass: "collapsible-header" }, [
                        _c(
                          "p",
                          { staticClass: "tips", attrs: { id: "tip2" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$translate(
                                  "photos.guide.collapsible.tip2.title"
                                )
                              )
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "collapsible-body" }, [
                        _c("div", { staticClass: "center" }, [
                          _c("img", {
                            staticClass: "responsive-img",
                            attrs: { src: "/img/photos/guide/tip-2.png" }
                          })
                        ]),
                        _vm._v(" "),
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$translate(
                                "photos.guide.collapsible.tip2.content"
                              )
                            )
                          }
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", { staticClass: "center" }, [
                          _c("img", {
                            attrs: {
                              width: "70",
                              height: "50",
                              src: "/img/quote-yellow.png",
                              alt: ""
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "center quote-title" }, [
                          _c("strong", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$translate(
                                  "photos.guide.collapsible.tip2.quotes.quote_1.title"
                                )
                              )
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$translate(
                                "photos.guide.collapsible.tip2.quotes.quote_1.content"
                              )
                            )
                          }
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", { staticClass: "center" }, [
                          _c("img", {
                            attrs: {
                              width: "70",
                              height: "50",
                              src: "/img/quote-yellow.png",
                              alt: ""
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "center quote-title" }, [
                          _c("strong", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$translate(
                                  "photos.guide.collapsible.tip2.quotes.quote_2.title"
                                )
                              )
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$translate(
                                "photos.guide.collapsible.tip2.quotes.quote_2.content"
                              )
                            )
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("div", { staticClass: "collapsible-header" }, [
                        _c(
                          "p",
                          { staticClass: "tips", attrs: { id: "tip3" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$translate(
                                  "photos.guide.collapsible.tip3.title"
                                )
                              )
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "collapsible-body" }, [
                        _c("div", { staticClass: "center" }, [
                          _c("img", {
                            staticClass: "responsive-img",
                            attrs: { src: "/img/photos/guide/tip-3.png" }
                          })
                        ]),
                        _vm._v(" "),
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$translate(
                                "photos.guide.collapsible.tip3.content"
                              )
                            )
                          }
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", { staticClass: "center" }, [
                          _c("img", {
                            attrs: {
                              width: "70",
                              height: "50",
                              src: "/img/quote-yellow.png",
                              alt: ""
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "center quote-title" }, [
                          _c("strong", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$translate(
                                  "photos.guide.collapsible.tip3.quotes.quote_1.title"
                                )
                              )
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$translate(
                                "photos.guide.collapsible.tip3.quotes.quote_1.content"
                              )
                            )
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("div", { staticClass: "collapsible-header" }, [
                        _c(
                          "p",
                          { staticClass: "tips", attrs: { id: "tip4" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$translate(
                                  "photos.guide.collapsible.tip4.title"
                                )
                              )
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "collapsible-body" }, [
                        _c("div", { staticClass: "center" }, [
                          _c("img", {
                            staticClass: "responsive-img",
                            attrs: { src: "/img/photos/guide/tip-4.png" }
                          })
                        ]),
                        _vm._v(" "),
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$translate(
                                "photos.guide.collapsible.tip4.content"
                              )
                            )
                          }
                        })
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "photo-tips" }, [
                  _c("div", { staticClass: "center" }, [
                    _c("img", {
                      attrs: {
                        width: "56",
                        height: "50",
                        src: "/img/camera-yellow.png",
                        alt: ""
                      }
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "tip-title" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$translate("photos.guide.more_tips.title")
                          ) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "slider-tips" }, [
                    _c(
                      "div",
                      {
                        ref: "carousel",
                        staticClass: "carousel carousel-slider center"
                      },
                      [
                        _c("div", { staticClass: "carousel-wrapper" }, [
                          _c(
                            "div",
                            {
                              staticClass: "carousel-item left-align",
                              attrs: { href: "#one!" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "carousel-item-content" },
                                [
                                  _c("div", { staticClass: "tips" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$translate(
                                          "photos.guide.more_tips.tip1"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "carousel-item left-align",
                              attrs: { href: "#two!" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "carousel-item-content" },
                                [
                                  _c("div", { staticClass: "tips" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$translate(
                                          "photos.guide.more_tips.tip2"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "carousel-item left-align",
                              attrs: { href: "#three!" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "carousel-item-content" },
                                [
                                  _c("div", { staticClass: "tips" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$translate(
                                          "photos.guide.more_tips.tip3"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "carousel-item left-align",
                              attrs: { href: "#four!" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "carousel-item-content" },
                                [
                                  _c("div", { staticClass: "tips" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$translate(
                                          "photos.guide.more_tips.tip4"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "nav-control",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.clickControl($event)
                        }
                      }
                    })
                  ])
                ])
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c("div", [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "bottom-sheet__cta bottom-sheet__cta--confirm --black",
                    staticStyle: { "text-transform": "uppercase" },
                    on: {
                      click: function() {
                        return _vm.$emit("close")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$translate("photos.back")) +
                        "\n      "
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }