<template>
  <div id="add-primary-photo" class="wrapper">
    <h2 class="center title">{{ $translate('photos.v2.add_main_photo') }}</h2>
    <img src="/img/registration/photos/primary.svg" alt="">
    <p>{{ $translate('photos.v2.add_main_photo_explanation') }}</p>
    <div class="photo-gallery-v2-photo empty center" @click.prevent="uploadphoto">
      <span class="add-photo-text">{{ $translate('photos.v2.add_photo2') }}</span>
      <a
        href="#"
        class="add-more center-align"
      >
        <div class="add-btn" />
      </a>
      <div class="photo-add" />
    </div>

    <vform ref="uploadMainForm" @submit="uploadMainPhoto">
      <input ref="uploadMainFormFile" type="file" accept="image/*" class="hide" @change="uploadMainFormSubmit">
    </vform>
  </div>
</template>

<script>
const PhotosProvider = $h.getProvider('PhotosProvider')

export default {
  computed: {
    isRegisterMode () {
      return this.$route.name.indexOf('register') >= 0
    }
  },
  methods: {
    uploadphoto () {
      this.$refs.uploadMainFormFile.click()
    },
    uploadMainPhoto (next) {
      const file = this.$refs.uploadMainFormFile.files[0]

      PhotosProvider.uploadMainPhoto(file, false)
        .then((res) => {
          this.$router.push({
            name: 'photos-crop',
            params: {
              photo: res.data,
              return: { name: 'photos' },
              cancel: { name: 'photos' }
            }
          })
        })
        .catch((err) => {
          if (_.isEmpty(err.data)) {
            $h.photoErrorModal(err.message)
          } else {
            $h.photoErrorModal(err.data)
          }
        })
        .finally(() => {
          if (this.$refs.uploadMainFormFile) {
            this.$refs.uploadMainFormFile.value = ''
          }
          next()
        })
    },
    uploadMainFormSubmit () {
      this.$refs.uploadMainForm.submit()
    }
  }
}
</script>
