var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showDashboardIntro
    ? _c(
        "div",
        {
          staticClass: "onboarding-dashboard-intro",
          attrs: { "data-test": "onboarding-dashboard-intro" }
        },
        [
          _c("div", { staticClass: "onboarding-dashboard-intro__visual" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("content_1")))]),
            _vm._v(" "),
            _c("img", {
              attrs: { src: "/img/dashboard/dashboard-onboarding-intro.svg" }
            })
          ]),
          _vm._v(" "),
          _c("p", { staticStyle: { "margin-top": "8px" } }, [
            _vm._v(_vm._s(_vm.$t("content_2")))
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }