<template>
  <div id="other-menu" class="wrapper">
    <div class="menu-container">
      <div class="menu-content">
        <h2>{{ $translate('sidenav.menu') }}</h2>
        <ul class="menus">
          <li @click.prevent="navigatePage('profile', false)">
            <a href="#">
              <div>
                <img src="@/features/other-menu/assets/profile.svg" alt="manage profile icon"></img>
              </div>
              <strong>{{ $translate('sidenav.profile') }}</strong>
            </a>
            <img src="@/features/other-menu/assets/menu-icon.svg" alt="chevron right icon">
          </li>

          <li
            class="waves-effect waves-dark"
            :class="{
              'locked': isFeatureLocked,
              'has-notif': showNotifFeedback
            }"
            @click.prevent="navigatePage('feedback', true)"
          >
            <a href="#">
              <div>
                <img v-if="isFeatureLocked" src="@/features/other-menu/assets/locked/feedback-locked.svg" alt="manage profile icon">
                <img v-else src="@/features/other-menu/assets/feedback.svg" alt="manage profile icon">
              </div>
              <strong>{{ $translate('sidenav.feedback') }}</strong>
            </a>
            <img v-if="isFeatureLocked" src="@/features/other-menu/assets/locked/menu-icon-locked.svg" alt="chevron right icon">
            <img v-else src="@/features/other-menu/assets/menu-icon.svg" alt="chevron right icon">
          </li>

          <li class="waves-effect waves-dark" :class="{'locked': isFeatureLocked}" @click.prevent="navigatePage('super-provider', true)">
            <a href="#">
              <div>
                <img
                  v-if="isSuperProvider && !isFeatureLocked"
                  src="@/features/other-menu/assets/super-provider-gold.svg"
                  alt="manage profile icon"
                >
                <img v-else-if="isFeatureLocked" src="@/features/other-menu/assets/locked/super-provider-locked.svg" alt="manage profile icon">
                <img v-else src="@/features/other-menu/assets/super-provider.svg" alt="manage profile icon">
              </div>
              <strong>{{ $translate('sidenav.super_provider') }}</strong>
            </a>
            <img v-if="isFeatureLocked" src="@/features/other-menu/assets/locked/menu-icon-locked.svg" alt="chevron right icon">
            <img v-else src="@/features/other-menu/assets/menu-icon.svg" alt="chevron right icon">
          </li>

          <li
            class="waves-effect waves-dark"
            :class="{'locked': isFeatureLocked}"
            @click.prevent="navigatePage(getAffiliatePage, true)"
          >
            <a href="#">
              <div>
                <img v-if="isFeatureLocked" src="@/features/other-menu/assets/locked/affiliates-locked.svg" alt="manage profile icon">
                <img v-else src="@/features/other-menu/assets/affiliates.svg" alt="manage profile icon">
              </div>
              <strong>{{ $translate('sidenav.billing_A2_2159.affiliate') }}</strong>
            </a>
            <img v-if="isFeatureLocked" src="@/features/other-menu/assets/locked/menu-icon-locked.svg" alt="chevron right icon">
            <img v-else src="@/features/other-menu/assets/menu-icon.svg" alt="chevron right icon">
          </li>

          <li
            class="waves-effect waves-dark"
            :class="{
              'locked': isFeatureLocked,
              'has-notif': showNotifBilling
            }"
            @click.prevent="navigatePage('billing-v2-unpaid', true)"
          >
            <a href="#">
              <div>
                <img v-if="isFeatureLocked" src="@/features/other-menu/assets/locked/invoices-locked.svg" alt="manage profile icon">
                <img v-else src="@/features/other-menu/assets/invoices.svg" alt="manage profile icon">
              </div>
              <strong>{{ $translate('sidenav.billing_A2_2159.billing') }}</strong>
            </a>
            <img v-if="isFeatureLocked" src="@/features/other-menu/assets/locked/menu-icon-locked.svg" alt="chevron right icon">
            <img v-else src="@/features/other-menu/assets/menu-icon.svg" alt="chevron right icon">
          </li>

          <li class="waves-effect waves-dark" @click.prevent="navigatePage('setting', false)">
            <a href="#">
              <div>
                <img src="@/features/other-menu/assets/settings.svg" alt="manage profile icon"></img>
              </div>
              <strong>{{ $translate('sidenav.settings') }}</strong>
            </a>
            <img src="@/features/other-menu/assets/menu-icon.svg" alt="chevron right icon">
          </li>

          <li
            class="waves-effect waves-dark"
            :class="{
              'has-notif': hasSupportUpdates
            }"
            @click.prevent="navigatePage('help', false)"
          >
            <a href="#">
              <div>
                <img src="@/features/other-menu/assets/help.svg" alt="manage profile icon"></img>
              </div>
              <strong>{{ $translate('sidenav.help') }}</strong>
            </a>
            <img src="@/features/other-menu/assets/menu-icon.svg" alt="chevron right icon">
          </li>
        </ul>
      </div>
    </div>
    <fixed-menu />
  </div>
</template>

<script>
import FixedMenu from '@components/v2/utilities/fixed-menu.vue'
import { mapGetters } from 'vuex'

import { LITE_EVENTS } from '@/features/lite/constants'
import { isProviderProfile } from '@/constants/helpers'
import FEATURES_LIST from '@/features'
const { INBOX_FILTER } = require('@/features/inbox/constants')

const ComponentData = {
  FEATURES_LIST,
  inboxExpanded: false,
  feedbackExpanded: true,
  billingExpanded: true,
  INBOX_FILTER: INBOX_FILTER
}

export default {
  components: {
    FixedMenu
  },
  data: () => (ComponentData),
  computed: {
    ...mapGetters({
      hasFeedbackUpdates: 'notification/GET_HAS_NEW_FEEDBACK',
      hasSupportUpdates: 'support/hasUpdates',
      isCandidateProfile: 'isCandidateProfile',
      hasKryptonConnectNotification: 'notification/HAS_INBOX_NOTIFICATION'
    }),
    getAffiliatePage () {
      return 'affiliate-overview'
    },
    user () {
      return this.$store.getters.getUser
    },
    isProviderProfile () {
      return isProviderProfile(this.user)
    },
    isFeatureLocked () {
      return this.isCandidateProfile
    },
    hasInboxNotification () {
      return this.hasKryptonConnectNotification
    },
    isSuperProvider () {
      return this.user?.settings?.is_super_provider
    },
    hasMenuAffiliatesUpdates () {
      return this.user?.settings?.menu_affiliates_seen === 0
    },
    hasMenuBillingUpdates () {
      return this.user?.settings?.menu_billing_seen === 0
    },
    showNotifFeedback () {
      return this.hasFeedbackUpdates
    },
    showNotifBilling () {
      return this.hasMenuBillingUpdates && !this.isFeatureLocked
    },
    showNotifSupport () {
      return this.hasSupportUpdates
    }
  },
  mounted () {
    this.$store.dispatch('support/checkNewUpdates')
  },
  methods: {
    gotoPage (name, params = {}) {
      if (this.$router.history.current.name !== name) {
        this.$router.push({ name, params })
      }
    },
    navigatePage (name, restrictCandidateProfile = false, params = {}) {
      if (this.isCandidateProfile && restrictCandidateProfile) {
        $h.EventBus.$emit(LITE_EVENTS.ONFEATURELOCK)
      } else {
        this.gotoPage(name, params)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/menu.scss';

.menu-container .menu-content h2 {
  margin: 48px 0 32px;
}

.auth-footer {
  padding-top: 45px;
  padding-bottom: 45px;

  & ::v-deep .lang-switcher {
    display: flex;
    justify-content: center;
    a {
      font-family: 'Proxima Nova Semibold';
      font-size: 14px;
      padding: 0 11px;
      color: #A6B2BF;
    }
    a:first-child {
      border-right: 1px solid #E1E6EB;
    }
    a.active {
      color: #DC0064;
    }
  }
}
</style>
