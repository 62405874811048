import { fetchProviderPreferences } from '@/features/provider-preferences/services/ProviderPreferencesService.js'

const state = {
  /**
   * Array of provider preferences
   */
  providerPreferences: []
}

const getters = {
  getProviderPreferences (state) {
    return state.providerPreferences
  }
}

const mutations = {
  setProviderPreferences (state, value) {
    state.providerPreferences = value
  }
}

const actions = {
  async fetch ({ commit }) {
    try {
      const providerPreferences = await fetchProviderPreferences()
      commit('setProviderPreferences', providerPreferences)
    } catch (err) {
      console.error(err)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
