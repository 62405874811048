var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: {
        id: "page-onboarding-lesson-overview",
        "data-test": "overview-component"
      }
    },
    [
      _c("md-navbar", {
        attrs: {
          push: { name: "dashboard" },
          brand: _vm.$t("messages.globals.dashboard"),
          icon: "arrow_back"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "content" } },
        [
          _c("h1", [_vm._v(_vm._s(_vm.$t("title")))]),
          _vm._v(" "),
          _vm._l(_vm.LESSON_CARDS, function(card, key) {
            return _c("onboarding-lesson-card", {
              key: key,
              staticClass: "overview-lesson-card",
              attrs: { "data-test": "overview-card", "lesson-card": card },
              on: {
                click: function($event) {
                  return _vm.onLessonClicked(card.id)
                }
              }
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }