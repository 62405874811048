<template>
  <div
    id="cancelled-event-card"
    class="apv-card"
  >
    <div class="card-header">
      <div class="card-header__flex">
        <pre class="date uppercase">{{ date }}</pre>
      </div>
      <div class="grey-text card-header__flex card-header__flex--mid">
        <span class="schedule">
          <strong
            class="schedule__countdown lilac"
            :class="{
              'lilac--red': hasNotification,
            }"
          >
            <span>
              {{ meetingStart }}
            </span>
          </strong>
          <small>
            {{ $translate('events.labels.for') }} {{ payload.meeting_duration }}h
          </small>
        </span>
        <p
          v-if="!needsFeedback"
          class="location location-status"
        >
          <span>
            {{ $translate(`events.card_status.${ payload._event_status }`) }}
          </span>
        </p>
      </div>
      <div class="card-header__flex">
        <strong class="amount">
          <currency />{{ payload.amount_payable }}
        </strong>
      </div>
    </div>
  </div>
</template>

<script>
import EventCardViewMixin from '../../mixins/event-card-view'

export default {
  mixins: [
    EventCardViewMixin
  ]
}
</script>
