var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "video-date-setting" } },
    [
      _c("md-navbar", {
        attrs: {
          go: -1,
          brand: _vm.$translate("sidenav.settings"),
          icon: "arrow_back"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "container", attrs: { id: "content" } }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$translate("video-date.settings.video_date_setting_title")
              ) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "setting-indicator margin-bottom-lg",
            class: _vm.statudIndicatorClass
          },
          [
            _c("i", { staticClass: "mdi valign-middle", class: _vm.iconClass }),
            _vm._v(" " + _vm._s(_vm.statudIndicatorText) + "\n    ")
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { position: "relative" } },
          [
            _c("md-input", {
              staticClass: "textfield-box-lg",
              class: {
                "textfield-box-purple": !_vm.isActive && _vm.isEmptyInput
              },
              attrs: {
                type: "text",
                autocomplete: "username",
                tabindex: "1",
                label: _vm.$translate("video-date.settings.skype_name_label")
              },
              model: {
                value: _vm.skypeUsername,
                callback: function($$v) {
                  _vm.skypeUsername = $$v
                },
                expression: "skypeUsername"
              }
            }),
            _vm._v(" "),
            (!_vm.isActive || _vm.hasChanges) && _vm.isValidSkypeID
              ? _c("i", {
                  staticClass: "mdi mdi-check krypton-green-text valign-middle",
                  staticStyle: {
                    position: "absolute",
                    right: "15px",
                    top: "25px"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "confirm-btn btn btn-large btn-full",
            class: {
              "btn-krypton-grey": _vm.isActive && !_vm.hasChanges,
              "btn-krypton-purple waves-effect waves-light":
                !_vm.isActive || _vm.hasChanges
            },
            on: { click: _vm.doSave }
          },
          [
            !_vm.isSaving
              ? _c("div", [
                  !_vm.isActive
                    ? _c("span", { staticClass: "valign-middle" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$translate(
                              "video-date.settings.activate_video_date"
                            )
                          )
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isActive && _vm.hasChanges
                    ? _c("span", { staticClass: "valign-middle" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$translate(
                              "video-date.settings.video_date_save_changes"
                            )
                          )
                        )
                      ])
                    : _vm.isActive && !_vm.hasChanges
                    ? _c("span", { staticClass: "valign-middle" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$translate(
                              "video-date.settings.deactivate_video_date"
                            )
                          )
                        )
                      ])
                    : _vm._e()
                ])
              : _c("div", { staticClass: "preloader-wrapper small active" }, [
                  _vm._m(0)
                ])
          ]
        ),
        _vm._v(" "),
        !_vm.isActive
          ? _c("p", { staticClass: "margin-y-lg" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.agree,
                    expression: "agree"
                  }
                ],
                staticClass: "krypton-purple-checkbox",
                attrs: { id: "agree", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.agree)
                    ? _vm._i(_vm.agree, null) > -1
                    : _vm.agree
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.agree,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.agree = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.agree = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.agree = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "agree" } }, [
                _vm._v(
                  _vm._s(
                    _vm.$translate(
                      "video-date.settings.video_date_allow_show_username"
                    )
                  )
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("p", {
          staticStyle: { "font-size": "12px" },
          domProps: {
            innerHTML: _vm._s(
              _vm.$translate("video-date.settings.video_date_explanation")
            )
          }
        }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "krypton-purple-text",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.viewInstruction($event)
              }
            }
          },
          [
            _vm._v(
              _vm._s(
                _vm.$translate("video-date.settings.how_to_find_skype_name")
              )
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner-layer spinner-green-only" }, [
      _c("div", { staticClass: "circle-clipper left" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gap-patch" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "circle-clipper right" }, [
        _c("div", { staticClass: "circle" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }