var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showVerificationBanner
    ? _c(
        "div",
        {
          staticClass: "mb-16",
          attrs: {
            id: "onboarding-verification-banner",
            "data-test": "onboarding-verification-banner"
          },
          on: { click: _vm.gotoVerify }
        },
        [
          _c("div", { staticClass: "header-container" }, [
            _c("div", [
              _c("div", [
                _vm._v(
                  "\n        " + _vm._s(_vm.getVerificationTitle) + "\n      "
                )
              ]),
              _vm._v(" "),
              _vm.showCountdownTimer
                ? _c(
                    "div",
                    {
                      staticClass: "timer",
                      attrs: { "data-test": "verification-countdown-timer" }
                    },
                    [_vm._v("\n        " + _vm._s(_vm.getTimer) + "\n      ")]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm._m(0)
          ]),
          _vm._v(" "),
          _vm.showCountdownTimer
            ? _c("div", { staticClass: "timer-description" }, [
                _c("span", [_vm._v(_vm._s(_vm.getTimerDescription) + " ")]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "support-link underline",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$router.push({ name: "support-v2" })
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.$translate(
                            "onboarding.verification.contact_support"
                          )
                        ) +
                        "\n    "
                    )
                  ]
                )
              ])
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "bottom-sheet-verification-banner-img",
        attrs: {
          id: "bottom-sheet-verification-banner-img",
          src: "/img/bottom-sheet/verification-banner-img-v2.svg"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }