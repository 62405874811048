var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-page", attrs: { id: "login-mobile" } },
    [
      _c(
        "div",
        { staticClass: "container", attrs: { id: "content" } },
        [
          _c("img", {
            staticStyle: { "border-radius": "4px" },
            attrs: { src: "/img/icons/kr-logo-144.png", width: "48" }
          }),
          _vm._v(" "),
          _c("div", { attrs: { id: "header" } }, [
            _c(
              "div",
              {
                staticClass: "text-darken-1",
                staticStyle: { "line-height": "36px" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$translate("login.header_line_1")) +
                    "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "text-darken-1" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$translate("login.header_line_2")) +
                  "\n      "
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "vform",
            { on: { submit: _vm.doSubmit } },
            [
              _vm.error
                ? _c(
                    "div",
                    {
                      staticClass:
                        "grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align",
                      attrs: { id: "login-mobile-error-msgs" }
                    },
                    [_c("small", [_vm._v(_vm._s(_vm.error))])]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("md-input", {
                staticClass: "textfield-box-lg",
                attrs: {
                  id: "login-mobile-username",
                  "data-cy": "email-field",
                  value: _vm.username,
                  type: "text",
                  autocomplete: "username",
                  tabindex: "1",
                  label: _vm.$translate("login.email")
                },
                on: { input: _vm.onUsernameChange }
              }),
              _vm._v(" "),
              _c("md-input", {
                staticClass: "textfield-box-lg",
                attrs: {
                  id: "login-mobile-password",
                  "data-cy": "password-field",
                  value: _vm.password,
                  type: "password",
                  autocomplete: "password",
                  tabindex: "2",
                  label: _vm.$translate("login.password")
                },
                on: { input: _vm.onPasswordChange }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-raised btn-large waves-effect waves-light display-block btn-krypton-pink waves-effect waves-light",
                  attrs: {
                    id: "login-mobile-submit-btn",
                    "data-cy": "login-btn",
                    type: "submit",
                    tabindex: "3"
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$translate("login.continue")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "margin-top-lg",
                  staticStyle: { "font-size": "14px" }
                },
                [
                  _c(
                    "a",
                    {
                      attrs: { id: "login-mobile-forgotpass-link", href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.$router.push({ name: "forgot-password" })
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$translate("login.forgot_password")))]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", { staticStyle: { color: "#757575" } }, [
                      _vm._v(_vm._s(_vm.$translate("login.no_account_yet")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { id: "login-mobile-register-link", href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.$router.push({ name: "register-start" })
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$translate("login.register")) +
                            "\n          "
                        )
                      ]
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }