const ApiProvider = $h.getProvider('ApiProvider')

const axiosConfig = {
  timeout: 0 // For photo uploading we disable the timeout
}

class SupportProvider {
  fetchSupportTickets (page = 1) {
    return new Promise((resolve, reject) => {
      window.api.get({ url: ApiProvider.supportTicket(page) },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response) })
    })
  }

  getSupportCategories (ticketID) {
    return new Promise((resolve, reject) => {
      window.api.get({ url: ApiProvider.supportCategories(ticketID) },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response) })
    })
  }

  sendSupportTicket (payload) {
    return new Promise((resolve, reject) => {
      window.api.post({ url: ApiProvider.supportTicket(), payload: payload },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response) })
    })
  }

  sendReply (ticketID, message) {
    return new Promise((resolve, reject) => {
      const payload = {
        message
      }

      window.api.post({ url: ApiProvider.supportTicketReply(ticketID), payload: payload },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response) })
    })
  }

  sendFile (ticketID, file) {
    return new Promise((resolve, reject) => {
      const payload = new FormData()

      payload.append('attachments', file)

      window.api.post({ url: ApiProvider.supportTicketReply(ticketID), payload: payload, config: axiosConfig },
      (succcess) => { resolve(succcess.data) },
      (err) => { reject(err.response) })
    })
  }

  checkNewUpdates () {
    return new Promise((resolve, reject) => {
      window.api.get({ url: ApiProvider.checkSupportUpdates() },
        (success) => { resolve(success.data) },
        (err) => { reject(err.message) })
    })
  }

  fetchSupportTicketDetail (id) {
    return new Promise((resolve, reject) => {
      window.api.get({ url: ApiProvider.supportTicketDetail(id) },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response) })
    })
  }
}

export default new SupportProvider()
