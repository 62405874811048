const ApiProvider = window?.$h?.getProvider('ApiProvider')

const axiosConfig = {
  timeout: 0 // For photo uploading we disable the timeout
}

class VerificationProvider {
  /**
   * Fetch provider billing details.
   *
   * @returns {Promise}
   */
  fetchBillingDetails () {
    return new Promise((resolve, reject) => {
      window.api.get({ url: ApiProvider.billingDetails() },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response) })
    })
  }

  /**
   * Fetch provider id deadline.
   *
   * @returns {Promise}
   */
  fetchIdDeadline () {
    return new Promise((resolve, reject) => {
      window.api.get({ url: ApiProvider.idDeadline() },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response) })
    })
  }

  /**
   * Process candidate rejection due to id-deadline
   *
   * @param  {Object} payload
   *
   * @return {Promise}
   */
  processIdDeadline (payload) {
    return new Promise((resolve, reject) => {
      window.api.put({ url: ApiProvider.idDeadlineProcess(), payload: payload },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response) })
    })
  }

  /**
   * Update provider's billing details.
   *
   * @param  {Object} payload
   *
   * @return {Promise}
   */
  updateBillingDetails (payload) {
    return new Promise((resolve, reject) => {
      window.api.put({ url: ApiProvider.billingDetails(), payload: payload },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response) })
    })
  }

  /**
   * Upload provider ID.
   *
   * @param  {File} id_front
   * @param  {File} id_back
   * @param  {Boolean} isReupload
   *
   * @return {Promise}
   */
  uploadIDs (id_front, id_back, isReupload = false) {
    return new Promise((resolve, reject) => {
      const payload = new FormData()

      if (id_front) {
        payload.append('id_front', id_front)
      }
      if (id_back) {
        payload.append('id_back', id_back)
      }

      window.api.post({ url: ApiProvider.uploadIDs(isReupload), payload: payload, config: axiosConfig },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response) })
    })
  }

  /**
   * Updated billing details popup visibility.
   *
   * @param {Object} payload
   *
   * @returns {Promise}
  */
  popupSeen (payload) {
    return new Promise((resolve, reject) => {
      window.api.put({ url: ApiProvider.billingDetailsSeen(), payload: payload },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response) })
    })
  }
}

export default new VerificationProvider()
