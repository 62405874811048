<template>
  <div id="super-provider-prerequire">
    <promo-card class="margin-bottom-lg" />
    <prereq-status />
  </div>
</template>

<script>
import PromoCard from '@/features/super-provider/components/SuperProviderPrereqCard.vue'
import PrereqStatus from '@/features/super-provider/components/SuperProviderPrereqStatus.vue'

export default {
  components: {
    PromoCard,
    PrereqStatus
  }
}
</script>
