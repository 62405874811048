export const AGE_REQUIREMENT = 33

export const BMI_REQUIREMENT = 23

export const REGISTRATION_COUNTRIES = [
  {
    key: 'DE',
    name: 'Germany',
    name_de: 'Deutschland'
  },
  {
    key: 'GB',
    name: 'United Kingdom',
    name_de: 'Großbritannien'
  },
  {
    key: 'DE_AU',
    name: 'Austria',
    name_de: 'Österreich'
  },
  {
    key: 'DE_CH',
    name: 'Switzerland',
    name_de: 'Schweiz'
  }
]

export const CHECKED_TIERS = {
  DE:
  [
    { tier: 0, value: 400 },
    { tier: 1, value: 500 },
    { tier: 2, value: 600 }
  ],
  FR:
  [
    { tier: 0, value: 550 },
    { tier: 1, value: 650 },
    { tier: 2, value: 750 }
  ],
  GB:
  [
    { tier: 0, value: 300 },
    { tier: 1, value: 400 },
    { tier: 2, value: 500 }
  ]
}

export const CURRENCY_SYMBOLS = {
  DE: '\u20AC',
  FR: '\u20AC',
  GB: '\u00A3'
}

export const CHECKED_DURATION_FEES = {
  DE: {
    2: { tier_0: 400, tier_1: 500, tier_2: 600 },
    4: { tier_0: 600, tier_1: 750, tier_2: 950 },
    6: { tier_0: 800, tier_1: 950, tier_2: 1200 },
    12: { tier_0: 1100, tier_1: 1350, tier_2: 1700 },
    24: { tier_0: 1700, tier_1: 2000, tier_2: 2400 }
  },
  FR: {
    2: { tier_0: 550, tier_1: 650, tier_2: 750 },
    4: { tier_0: 900, tier_1: 1100, tier_2: 1300 },
    6: { tier_0: 1200, tier_1: 1400, tier_2: 1700 },
    12: { tier_0: 1700, tier_1: 1950, tier_2: 2300 },
    24: { tier_0: 2250, tier_1: 2550, tier_2: 3000 }
  },
  GB: {
    1: { tier_0: 200, tier_1: 250, tier_2: 300 },
    2: { tier_0: 300, tier_1: 400, tier_2: 500 },
    4: { tier_0: 500, tier_1: 700, tier_2: 900 },
    6: { tier_0: 650, tier_1: 900, tier_2: 1150 },
    12: { tier_0: 1050, tier_1: 1350, tier_2: 1650 },
    24: { tier_0: 1600, tier_1: 1950, tier_2: 2350 }
  }
}

export const UNCHECKED_DURATION_FEES = {
  DE: {
    1: { tier_0: 20, tier_1: 30, tier_2: 40 },
    2: { tier_0: 30, tier_1: 50, tier_2: 70 },
    4: { tier_0: 50, tier_1: 90, tier_2: 120 },
    6: { tier_0: 70, tier_1: 120, tier_2: 160 },
    12: { tier_0: 110, tier_1: 160, tier_2: 210 },
    24: { tier_0: 200, tier_1: 290, tier_2: 320 }
  },
  FR: {
    1: { tier_0: 20, tier_1: 30, tier_2: 40 },
    2: { tier_0: 30, tier_1: 50, tier_2: 70 },
    4: { tier_0: 50, tier_1: 90, tier_2: 120 },
    6: { tier_0: 70, tier_1: 120, tier_2: 160 },
    12: { tier_0: 110, tier_1: 160, tier_2: 210 },
    24: { tier_0: 200, tier_1: 290, tier_2: 320 }
  },
  GB: {
    1: { tier_0: 20, tier_1: 30, tier_2: 40 },
    2: { tier_0: 30, tier_1: 50, tier_2: 70 },
    4: { tier_0: 50, tier_1: 90, tier_2: 120 },
    6: { tier_0: 70, tier_1: 120, tier_2: 160 },
    12: { tier_0: 110, tier_1: 160, tier_2: 210 },
    24: { tier_0: 200, tier_1: 290, tier_2: 320 }
  }
}

export const PHOTO_TRIGGER_VALUES = Object.freeze({
  PRIMARY_PHOTO: 'primary-photo',
  PHOTO_TIPS_CTA: 'photo-tips-cta',
  OTHER_PHOTO: 'other-photo',
  OTHER_PHOTO_PILL: 'other-photo-pill'
})
