var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "padding-bottom-xs",
      class: {
        "md-select--NEW_APV_REG_REDESIGN": true,
        "input-field--NEW_APV_REG_REDESIGN": true,
        "half-width": _vm.halfWidth,
        "has-value": !_vm.isEmpty(_vm.model),
        "--disabled": _vm.disabled
      },
      on: { click: _vm.onClickHandler }
    },
    [
      _vm.multiple
        ? _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model,
                  expression: "model"
                }
              ],
              ref: "select",
              attrs: { multiple: "" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.model = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              !_vm.isSelectedExists
                ? _c("option", { attrs: { disabled: _vm.disableDefault } }, [
                    _vm._v(_vm._s(_vm.defaultOpt))
                  ])
                : _vm._e(),
              _vm._t("options")
            ],
            2
          )
        : _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model,
                  expression: "model"
                }
              ],
              ref: "select",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.model = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              !_vm.isSelectedExists
                ? _c(
                    "option",
                    { attrs: { disabled: _vm.disableDefault, value: "null" } },
                    [_vm._v(_vm._s(_vm.defaultOpt))]
                  )
                : _vm._e(),
              _vm._t("options")
            ],
            2
          ),
      _vm._v(" "),
      _vm.isMobile() || _vm.forceMobile
        ? _c(
            "div",
            {
              staticClass: "select-wrapper initialized",
              attrs: { id: "mocked-select-wrapper-for-mobile" }
            },
            [
              _c("input", {
                staticClass: "select-dropdown",
                attrs: { type: "text", readonly: "true" },
                domProps: { value: _vm.selectedValueText }
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "labelcontainer",
          staticClass: "label-and-addons-container",
          on: { click: _vm.labelContainerHandler }
        },
        [
          _c("label", { class: { active: !_vm.isEmpty(_vm.model) } }, [
            _vm._v(_vm._s(_vm.label))
          ]),
          _vm._v(" "),
          _c("i", { staticClass: "material-icons" }, [_vm._v("expand_more")])
        ]
      ),
      _vm._v(" "),
      _vm._t("errors")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }