var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal left-align", attrs: { id: "photo-replace-modal" } },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v(
            "\n          " +
              _vm._s(_vm.$translate("photos.v2.replace_popup.title")) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "grey-text text-darken-1" }, [
          _vm._v(
            "\n          " +
              _vm._s(_vm.$translate("photos.v2.replace_popup.content")) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "refresh-icon" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass:
              "btn btn-raised waves-effect waves-light display-block btn-krypton-pink waves-effect waves-light",
            on: { click: _vm.confirm }
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$translate("photos.v2.replace_popup.ok")) +
                "\n        "
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }