var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "private-place-promo-banner max-width-center",
      on: {
        click: function($event) {
          return _vm.gotoPage("private-place-overview")
        }
      }
    },
    [
      _c(
        "div",
        {
          ref: "banner-container",
          staticClass: "wrapper promo-banner-container  --green",
          class: { "--expanded-height": _vm.expandedHeight }
        },
        [
          _c("div", { staticClass: "promo-banner" }, [
            _c("div", { staticClass: "more-requests" }, [
              _c(
                "i",
                {
                  staticClass: "material-icons",
                  attrs: { "data-test": "pp-bottom-banner-icon" }
                },
                [_vm._v("person_pin_circle")]
              ),
              _vm._v(" "),
              _c("span", { attrs: { "data-test": "pp-bottom-banner-title" } }, [
                _vm._v(_vm._s(_vm.getBannerTitle))
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "learn-how",
                attrs: { "data-test": "pp-bottom-banner-cta" }
              },
              [_vm._v("\n        " + _vm._s(_vm.getCTAText) + "\n      ")]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }