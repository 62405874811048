var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c("div", { staticClass: "wrapper", attrs: { id: "overview" } }, [
        _c("div", { staticClass: "content__area" }, [
          _c("img", {
            attrs: {
              id: "k-logo",
              src: "/img/registration/k-logo.svg",
              alt: "girl with heart graphics"
            }
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "content__title content__title--desktop" }, [
            _vm._v(_vm._s(_vm.headerLine1 + " " + _vm.headerLine2))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content__area no-actions drawer max-height-mobile" },
          [
            _c("div", { staticClass: "content" }, [
              _c(
                "h2",
                {
                  staticClass:
                    "content__title content__title--mobile content__title--steppers"
                },
                [
                  _vm._v(_vm._s(_vm.headerLine1) + " "),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.headerLine2))
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "step left-align margin-bottom-lg",
                  class: _vm.getStepClass(_vm.REGISTRATION_STEPS.STEP_1)
                },
                [
                  _vm.isStepDone(_vm.REGISTRATION_STEPS.STEP_1)
                    ? _c(
                        "div",
                        {
                          staticClass: "right",
                          staticStyle: { "line-height": "95px" }
                        },
                        [
                          _c("img", {
                            staticClass: "step-check",
                            attrs: {
                              src: "/img/registration/ic-check-circle-dark.svg",
                              width: "35"
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "step__label" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.$translate("register.overview.step_1.label")
                        ) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "step__title" }, [
                    _vm._v(
                      _vm._s(_vm.$translate("register.overview.step_1.title"))
                    )
                  ]),
                  _vm._v(" "),
                  !_vm.isStepDone(_vm.REGISTRATION_STEPS.STEP_1)
                    ? _c("div", { staticClass: "step__subtitle" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$translate("register.overview.step_1.subtitle")
                          )
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isStepDone(_vm.REGISTRATION_STEPS.STEP_1)
                    ? _c(
                        "a",
                        {
                          staticClass: "edit-link",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.pushToStep("register-basic", {
                                type: "city"
                              })
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$translate("register.overview.edit")) +
                              "\n            "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "margin-top-md" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn__overview btn-primary btn-blocked",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.pushToStep("register-basic", {
                              type: "city"
                            })
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$translate("register.overview.continue")
                            ) +
                            "\n              "
                        )
                      ]
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "step left-align margin-bottom-lg",
                  class: _vm.getStepClass(_vm.REGISTRATION_STEPS.STEP_2)
                },
                [
                  _vm.isStepDone(_vm.REGISTRATION_STEPS.STEP_2)
                    ? _c(
                        "div",
                        {
                          staticClass: "right",
                          staticStyle: { "line-height": "95px" }
                        },
                        [
                          _c("img", {
                            staticClass: "step-check",
                            attrs: {
                              src: "/img/registration/ic-check-circle-dark.svg",
                              width: "35"
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "step__label" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.$translate("register.overview.step_2.label")
                        ) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "step__title" }, [
                    _vm._v(
                      _vm._s(_vm.$translate("register.overview.step_2.title"))
                    )
                  ]),
                  _vm._v(" "),
                  !_vm.isStepDone(_vm.REGISTRATION_STEPS.STEP_2)
                    ? _c("div", { staticClass: "step__subtitle" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$translate("register.overview.step_2.subtitle")
                          )
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isStepDone(_vm.REGISTRATION_STEPS.STEP_2)
                    ? _c(
                        "a",
                        {
                          staticClass: "edit-link",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.pushToPhotos($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$translate("register.overview.edit")) +
                              "\n            "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "margin-top-md" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn__overview btn-primary btn-blocked",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.pushToPhotos($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$translate("register.overview.continue")
                            ) +
                            "\n              "
                        )
                      ]
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "step left-align margin-bottom-lg",
                  class: _vm.getStepClass(_vm.REGISTRATION_STEPS.STEP_3)
                },
                [
                  _vm.isStepDone(_vm.REGISTRATION_STEPS.STEP_3)
                    ? _c(
                        "div",
                        {
                          staticClass: "right",
                          staticStyle: { "line-height": "95px" }
                        },
                        [
                          _c("img", {
                            staticClass: "step-check",
                            attrs: {
                              src: "/img/registration/ic-check-circle-dark.svg",
                              width: "35"
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "step__label" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.$translate("register.overview.step_3.label")
                        ) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "step__title" }, [
                    _vm._v(
                      _vm._s(_vm.$translate("register.overview.step_3.title"))
                    )
                  ]),
                  _vm._v(" "),
                  !_vm.isStepDone(_vm.REGISTRATION_STEPS.STEP_3)
                    ? _c("div", { staticClass: "step__subtitle" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$translate("register.overview.step_3.subtitle")
                          )
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isStepDone(_vm.REGISTRATION_STEPS.STEP_3)
                    ? _c(
                        "a",
                        {
                          staticClass: "edit-link",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.pushToStep("register-personality", {
                                type: "intellect"
                              })
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$translate("register.overview.edit")) +
                              "\n            "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "margin-top-md" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn__overview btn-primary btn-blocked",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.pushToStep("register-personality", {
                              type: "intellect"
                            })
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$translate("register.overview.continue")
                            ) +
                            "\n              "
                        )
                      ]
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.isStepDone(_vm.REGISTRATION_STEPS.STEP_3)
                ? _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-btn__overview btn-primary btn-blocked",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.redirectToLastStep($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$translate("register.overview.last_step")
                          ) +
                          "\n          "
                      )
                    ]
                  )
                : _vm._e()
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }