import Vuex from 'vuex'
import ApiProvider from '../providers/ApiProvider'

import availabilitiesStore from './availabilities'
import supportStore from './support'
import eventsStore from './events'
import interviewStore from './interview'
import feedbacksStore from './feedbacks'
import billingStore from './billing'
import notificationStore from './notification'
import onboardingStore from './onboarding'
import { FeatureFlagPlugin, FeatureFlagStore } from '@/services/ama-tools/plugins/vuex/FeatureFlag'

import { isCandidateProfile } from '@/constants/helpers'

const UserProvider = window?.$h?.getProvider('UserProvider')
const RegisterProvider = window?.$h?.getProvider('RegisterProvider')

const state = {
  user: {},
  birthday: {},
  dropdown: {},
  countries: [],
  cities: [],
  contactCodes: [],
  isPWA: false,
  // Manage Photos
  isPhotosEditMode: false,
  rejectedPhotos: [],
  livePhotos: {
    primary: [],
    others: []
  },
  isFetchingLivePhotos: false,
  hasStatusIndicator: false,
  hasBottomStatusIndicator: false,
  hasTopStatusIndicator: false,
  // Time
  currentTime: moment(),
  currentTimeTz: moment.tz(),
  // Network
  isOnline: true,
  // AMA International,
  homeBaseCountry: null,
  showPWADownloadSuccessScreen: false,
  showPWADownloadScreen: false,
  hasFixedMenu: false,
  hasFixedNavigator: false,
  hasFixedFooterButtons: false,
  hasFixedChatControls: false,
  // Defaults to true and only gets false on event-confirmed.vue -> mounted
  isCWPReadyForReveal: true
}

export const indexStore = {
  plugins: [FeatureFlagPlugin],
  state,
  modules: {
    availabilities: availabilitiesStore,
    support: supportStore,
    events: eventsStore,
    interview: interviewStore,
    feedbacks: feedbacksStore,
    billing: billingStore,
    notification: notificationStore,
    onboarding: onboardingStore,
    featureflag: FeatureFlagStore
  },
  mutations: {
    // 1. User related setters
    setUser: (state, user) => {
      state.user = user
      // Sort by `order` in ascending values
      state.user.profiles.photos.others = state.user.profiles.photos.others.sort((a, b) => {
        return a.order - b.order
      })
      // Set the homeBaseCountry if there are any
      state.homeBaseCountry = user?.profiles?.country_code ?? null
    },
    setUserNsfwPreferences: (state, value) => {
      state.user.nsfw_preferences = value
    },
    setUserSettingReviewPopupSeen: (state, value) => {
      state.user.settings.is_super_provider_review_seen = value
    },
    setUserInactiveRequestRewardPopupSeen: (state, value) => {
      state.user._show_inactive_request_reward_popup = value
    },
    setUserSettingProviderPreferencesSeen: (state, value) => {
      if (state.user?.settings) {
        state.user.settings.nsfw_preferences_promo_popup_seen = value
      }
    },
    setUserContactWarningPopupStatus: (state, value) => {
      state.user.show_contact_warning_popup = value
    },
    setUserSettingPromoPopupSeen: (state, value) => {
      state.user.settings.is_super_provider_promo_popup_seen = value
    },
    setUserSettingReferralPromoPopupSeen: (state, value) => {
      state.user.settings.referral_promo_popup_seen = value
    },
    setUserSettingReferralRewardPopupSeen: (state, value) => {
      state.user.settings.referral_reward_popup_seen = value
    },
    setUserSettingReferralNewAffiliatePopupSeen: (state, value) => {
      state.user.settings.referral_new_affiliate_popup_seen = value
    },
    setUserSettingMenuAffiliatesSeen: (state, value) => {
      state.user.settings.menu_affiliates_seen = value
    },
    setUserSettingMenuBillingSeen: (state, value) => {
      state.user.settings.menu_billing_seen = value
    },
    setUserSettingIsLongTermBookingPromoPopupSeen: (state, value) => {
      state.user.settings.is_long_term_booking_promo_popup_seen = value
    },
    setAgreeTerms: (state, value) => {
      state.user.agree_terms = value
    },
    setProgress: (state, progress) => {
      state.user.register_progress = progress
    },
    setProfile: (state, profile) => {
      for (var key in profile) {
        state.user.profiles[key] = profile[key]
      }
    },
    setContactCodes: (state, contactCodes) => {
      state.contactCodes = contactCodes
    },
    setLang: (state, lang) => {
      state.user.profiles.lang_1 = lang.lang_1 ? {
        short: lang.lang_1
      } : null
      state.user.profiles.lang_2 = lang.lang_2 ? {
        short: lang.lang_2
      } : null
      state.user.profiles.lang_3 = lang.lang_3 ? {
        short: lang.lang_3
      } : null
    },
    setCuisine: (state, cuisine) => {
      state.user.profiles.cuisine = JSON.stringify(cuisine)
    },
    setMotto: (state, motto) => {
      state.user.profiles.slogan = motto
    },
    setMottoDe: (state, motto) => {
      state.user.profiles.slogan_de = motto
    },
    setInterview: (state, interview) => {
      state.user.profiles.interview = JSON.stringify(interview)
    },
    setInterviewDe: (state, interview) => {
      state.user.profiles.interview_de = JSON.stringify(interview)
    },
    setPhotos: (state, photos) => {
      state.user.profiles.photos = photos
    },
    setPhone: (state, phone) => {
      state.user.phone = phone
    },
    setBirthMonth: (state, month) => {
      state.birthday.month = month
    },
    setBirthYear: (state, year) => {
      state.birthday.year = year
    },
    // 2. Dropdown related getters
    setDropdown: (state, dropdown) => {
      state.dropdown = dropdown
    },
    // 3. Cities related getters
    setCities: (state, cities) => {
      state.cities = cities
    },
    removePhoto: (state, photo) => {
      if (state.user) {
        if (photo.primary) {
          const index = _.findIndex(state.user.profiles.photos.primary, {
            id: photo.photo_id
          })
          state.user.profiles.photos.primary.splice(index, 1)
        } else {
          const index = _.findIndex(state.user.profiles.photos.others, {
            id: photo.photo_id
          })
          state.user.profiles.photos.others.splice(index, 1)
        }
      }
    },
    // 4. Availabilities
    // 5. Service Worker
    setServiceWorker: (state, sw) => {
      state.serviceWorker = sw
    },
    setShowPWADownloadSuccessScreen: (state, val) => {
      state.showPWADownloadSuccessScreen = val
    },
    setShowPWADownloadScreen: (state, val) => {
      state.showPWADownloadScreen = val
    },
    setIsPWA: (state, isPWA) => {
      state.isPWA = isPWA
    },
    setEditPhotoMode: (state, status) => {
      state.isPhotosEditMode = status
    },
    swapPhotoOrder: (state, photos) => {
      if (photos.original && photos.over) {
        const originalIdx = state.livePhotos.others.findIndex(p => p.id === photos.original)
        const overIdx = state.livePhotos.others.findIndex(p => p.id === photos.over)

        if (originalIdx >= 0 && overIdx >= 0) {
          const originalOrder = state.livePhotos.others[originalIdx].order

          // Swap order values.
          state.livePhotos.others[originalIdx].order = state.livePhotos.others[overIdx].order
          state.livePhotos.others[overIdx].order = originalOrder
        }
      }
    },
    sortOtherPhotos: (state) => {
      // Re-sort array by ascending order value
      if (state.livePhotos.others) {
        state.livePhotos.others = state.livePhotos.others.sort((a, b) => {
          return a.order - b.order
        })

        // Reset order values
        for (let i = 0, limit = state.livePhotos.others.length; i < limit; ++i) {
          state.livePhotos.others[i].order = i + 1
        }
      }
    },
    setRejectedPhotos: (state, rejectedPhotos) => {
      state.rejectedPhotos = rejectedPhotos
    },
    resetRejectedPhotos: (state) => {
      state.rejectedPhotos = []
    },
    replaceOtherPhoto: (state, payload) => {
      const newPhoto = payload.newPhoto
      const removePhotoID = payload.removePhotoID

      // Replace photo
      state.livePhotos.others = state.livePhotos.others.filter(p => p.id !== removePhotoID)
      state.livePhotos.others.push(newPhoto)
    },
    replaceOtherPrimary: (state, payload) => {
      state.user.profiles.photos.other_primary = Object.assign({}, payload.newPhoto)
    },
    setLivePhotos: (state, livePhotos) => {
      state.livePhotos = livePhotos
    },
    setReplaceConfirm: (state, value) => {
      state.user.replace_confirm = value
    },
    setIsFetchingLivePhotos: (state, isFetchingLivePhotos) => {
      state.isFetchingLivePhotos = isFetchingLivePhotos
    },
    addRejectedPhoto: (state, rejectedPhoto) => {
      if (state.rejectedPhotos.findIndex(p => p.id === rejectedPhoto.id) === -1) {
        state.rejectedPhotos.push(rejectedPhoto)
      }
    },
    setSelectedEvent: (state, selectedEvent) => {
      state.selectedEvent = Object.assign({}, JSON.parse(JSON.stringify(selectedEvent)))
    },
    resetSelectedEvent: (state) => {
      state.selectedEvent = null
    },
    setStatusIndicator: (state, val) => {
      state.hasStatusIndicator = val
    },
    setBottomStatusIndicator: (state, val) => {
      state.hasBottomStatusIndicator = val
    },
    setTopStatusIndicator: (state, val) => {
      state.hasTopStatusIndicator = val
    },
    setCurrentTime: (state, currentTime) => {
      state.currentTime = currentTime
    },
    setCurrentTimeTz: (state, currentTimeTz) => {
      state.currentTimeTz = currentTimeTz
    },
    setIsOnline: (state, val) => {
      state.isOnline = val
    },
    setPhotoTipSeen: (state) => {
      if (state.user) {
        state.user.is_photo_tip_seen = 1
      }
    },
    setCountries: (state, countries) => {
      state.countries = countries
    },
    setRequestEnable: (state, newValue) => {
      if (state.user.profiles) {
        state.user.profiles.request_enable = newValue
      }
    },
    setIDVerificationEnabled: (state) => {
      if (state.user) {
        state.user._casting = {
          id_request: moment().format('YYYY-MM-DD HH:mm')
        }
      }
    },
    setSkypeName: (state, skypeName) => {
      if (state.user) {
        state.user.settings.skype_name = skypeName
      }
    },
    setShowCTAButton: (state, value) => {
      if (state.user) {
        state.user.profiles._show_cta_button = value
      }
    },
    setConnectEnabled: (state, value) => {
      if (state.user) {
        state.user.settings.connect_enabled = value
      }
    },
    setPrivatePlaceEnabled: (state, value) => {
      if (state.user) {
        state.user.settings.private_place_enable = value
      }
    },
    setVipPhotosEnabled: (state, value) => {
      if (state.user) {
        state.user.settings.is_vip_photos_enabled = value
      }
    },
    setMultiLocationEnabled: (state, value) => {
      if (state.user) {
        state.user.settings.multi_location_enabled = value
      }
    },
    setHomeBaseCountry: (state, value) => {
      state.homeBaseCountry = value
    },
    setHasFixedMenu: (state, value) => {
      state.hasFixedMenu = value
    },
    setHasFixedNavigator: (state, value) => {
      state.hasFixedNavigator = value
    },
    setHasFixedFooterButtons: (state, value) => {
      state.hasFixedFooterButtons = value
    },
    setHasFixedChatControls: (state, value) => {
      state.hasFixedChatControls = value
    },
    setIsCWPReadyForReveal: (state, value) => {
      state.isCWPReadyForReveal = value
    }
  },
  getters: {
    // 1. User related getters
    getUser: (state) => {
      return _.isEmpty(state.user) ? null : state.user
    },
    getContactCodes: (state) => {
      return state.contactCodes
    },
    getUserPrimaryPhoto: (state, getters, rootState, rootGetters) => {
      var placeholder = 'https://res.cloudinary.com/ama-production/image/upload/c_scale,w_375,h_500,q_90/v1513029938/misc/profile/amaavatar.jpg'

      if (rootGetters['featureflag/isEnabled']('PHOTO_FALLBACK_A2_2720')) {
        placeholder = 'https://d1muixarkip6az.cloudfront.net/375x500/misc/profile/amaavatar.jpg'
      }

      if (_.isEmpty(state.user) || !state.user.profiles.photos.primary) {
        return {
          _url: placeholder,
          isEmpty: true
        }
      }

      return state.user.profiles.photos.primary
    },
    getUserRegisterProgress: (state, getters) => {
      return !state.user ? null : state.user.register_progress
    },
    getBirthday: (state) => (key) => {
      if (key == null) return state.birthday
      return state.birthday[key]
    },
    // 2. Dropdown related getters
    getDropdown: (state) => {
      return _.isEmpty(state.dropdown) ? null : state.dropdown
    },
    getDropdownOrigin: (state) => (key) => {
      if (key == null) return state.dropdown.origin
      return _.isEmpty(state.dropdown) ? null : state.dropdown.origin[key]
    },
    getDropdownLanguage: (state) => (key) => {
      if (key == null) return state.dropdown.language
      return _.isEmpty(state.dropdown) ? null : state.dropdown.language[key]
    },
    getDropdownCuisine: (state) => (key) => {
      if (key == null) return state.dropdown.cuisine
      return _.isEmpty(state.dropdown) ? null : state.dropdown.cuisine[key]
    },
    getDropdownEyeColor: (state) => (key) => {
      if (key == null) return state.dropdown.eye_color
      return _.isEmpty(state.dropdown) ? null : state.dropdown.eye_color[key]
    },
    getDropdownHairColor: (state) => (key) => {
      if (key == null) return state.dropdown.hair_color
      return _.isEmpty(state.dropdown) ? null : state.dropdown.hair_color[key]
    },
    getDropdownProfession: (state) => (key) => {
      if (key == null) return state.dropdown.profession
      return _.isEmpty(state.dropdown) ? null : state.dropdown.profession[key]
    },
    getDropdownSmoking: (state) => (key) => {
      if (key == null) return state.dropdown.smoking
      return _.isEmpty(state.dropdown) ? null : state.dropdown.smoking[key]
    },
    getDropdownTattoo: (state) => (key) => {
      if (key == null) return state.dropdown.tattoo
      return _.isEmpty(state.dropdown) ? null : state.dropdown.tattoo[key]
    },
    getDropdownPiercing: (state) => (key) => {
      if (key == null) return state.dropdown.piercing
      return _.isEmpty(state.dropdown) ? null : state.dropdown.piercing[key]
    },
    getDropdownBraCup: (state) => (key) => {
      if (key == null) return state.dropdown.bra_cup
      return _.isEmpty(state.dropdown) ? null : state.dropdown.bra_cup[key]
    },
    getDropdownInterviews: (state) => (key) => {
      if (key == null) return state.dropdown.interviews
      return _.isEmpty(state.dropdown) ? null : state.dropdown.interviews[key]
    },
    getInterviewsSelection: (state) => {
      return state.dropdown.interviews || []
    },
    // 3. Cities related setters
    getCities: (state) => {
      return _.isEmpty(state.cities) ? null : state.cities
    },
    // 5. Service Worker
    getServiceWorker: (state) => {
      return state.serviceWorker
    },
    getShowPWADownloadSuccessScreen: (state) => {
      return state.showPWADownloadSuccessScreen
    },
    getShowPWADownloadScreen: (state) => {
      return state.showPWADownloadScreen
    },
    getIsPWA: (state) => {
      return state.isPWA
    },
    // 6. Manage Photos
    getPhotoEditMode: (state) => {
      return state.isPhotosEditMode
    },
    getPhotos: (state) => {
      let photos = []

      if (state.user.profiles) {
        if (state.user.profiles.photos.primary) {
          photos.push(state.user.profiles.photos.primary)
        } else {
          photos.push(state.user.profiles.photos.other_primary)
        }
        // if (state.user.profiles.photos.other_primary) {
        //     photos.push(state.user.profiles.photos.other_primary);
        // }

        // Add other photos
        photos = photos.concat(state.user.profiles.photos.others)

        const remainingOtherPhotos = 5 - state.user.profiles.photos.others.length

        // Add empty slots
        for (let c = 1; c <= remainingOtherPhotos; ++c) {
          photos.push({ id: 0 })
        }
      }

      return photos
    },
    getRejectedPhotos: (state) => {
      return state.rejectedPhotos
    },
    getLivePhotos: (state) => {
      const MAX_OTHER_PHOTOS = 5

      let photos = []

      if (state.livePhotos.primary && state.livePhotos.others) {
        photos.push(state.livePhotos.primary)

        // Sort ascending value
        photos = photos.concat(state.livePhotos.others.sort((a, b) => {
          return a.order - b.order
        }))

        // Reset order values
        for (let i = 0, limit = state.livePhotos.length; i < limit; ++i) {
          photos.others[i].order = i + 1
        }

        // Get remaining slot for other photos
        const remaining = MAX_OTHER_PHOTOS - state.livePhotos.others.length

        if (remaining) {
          // Add empty slots
          for (let c = 1; c <= remaining; ++c) {
            photos.push({ id: 0 })
          }
        }
      }

      return photos
    },
    hasReplaceConfirm: (state) => {
      return state.user.replace_confirm
    },
    isFetchingLivePhotos: (state) => {
      return state.isFetchingLivePhotos
    },
    getStatusIndicator: (state) => {
      return state.hasStatusIndicator
    },
    getBottomStatusIndicator: (state) => {
      return state.hasBottomStatusIndicator
    },
    getTopStatusIndicator: (state) => {
      return state.hasTopStatusIndicator
    },
    getCurrentTime: (state) => {
      return state.currentTime
    },
    getCurrentTimeTz: (state) => {
      return state.currentTimeTz
    },
    getIsOnline: (state) => {
      return state.isOnline
    },
    getCountries: (state) => {
      return state.countries
    },
    getIsRequestEnabled: (state) => {
      if (state.user.profiles) {
        return state.user.profiles.request_enable == 1
      }
      return false
    },
    bypassBillingDetailsCheck: (state) => {
      if (state.user.profiles) {
        return state.user.register_progress === 0 &&
               state.user.profiles.ccc_provider_id !== null
      }
      return false
    },
    getIsVideoRequestEnabled: (state) => {
      if (state.user.profiles) {
        return state.user.profiles.accept_video_request === 1
      }
      return false
    },
    getSkypeName: (state) => {
      if (state.user) {
        return state.user.settings.skype_name
      }
      return false
    },
    isCandidateProfile: (state) => {
      if (state.user) {
        return isCandidateProfile(state.user)
      }
      return false
    },
    getUserBMI: (state) => {
      if (state.user) {
        const heightInMeters = state.user.profiles.height * 0.01 // Convert cm to meters
        return (state.user.profiles.weight / Math.pow(heightInMeters, 2)).toFixed(1)
      }
      return 0
    },
    showDefaultScreen: (state) => {
      if (state.user) {
        return state.user.profiles._show_cta_button === 0
      }

      return false
    },
    showSuccessScreenWithCta: (state) => {
      return (state?.user?.profiles?._show_cta_button ?? false) === 1
    },
    getConnectEnabled: (state, value) => {
      if (state.user) {
        return state.user.settings.connect_enabled === 1
      }
      return false
    },
    getPrivatePlaceEnabled: (state) => {
      return state.user?.settings?.private_place_enable === 1
    },
    getMultiLocationEnabled: (state) => {
      return state.user?.settings?.multi_location_enabled === 1
    },
    getVipPhotosEnabled: (state) => {
      return state.user?.settings?.is_vip_photos_enabled === 1
    },
    getHasSuccessfulMeetings: (state) => {
      if (state.user) {
        return state.user._has_successful_meeting === 1
      }
      return false
    },
    getHomeBaseCountry: (state) => {
      return state.homeBaseCountry
    },
    hasFixedMenu: (state) => {
      return state.hasFixedMenu
    },
    hasFixedNavigator: (state) => {
      return state.hasFixedNavigator
    },
    hasFixedFooterButtons: (state) => {
      return state.hasFixedFooterButtons
    },
    hasFixedChatControls: (state) => {
      return state.hasFixedChatControls
    },
    isCWPReadyForReveal: (state) => {
      return state.isCWPReadyForReveal
    }
  },
  actions: {
    /**
     * Action to dispatch the basic registration
     *
     * @param {String} type
     * @param {Object} payload
     * @returns {Promise}
     */
    updateBasicRegistration ({ commit }, { type, payload }) {
      return new Promise((resolve, reject) => {
        RegisterProvider.updateBasic(type, payload)
          .then((res) => {
            commit('setUser', res.data)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    updatePhotoSort (context, payload) {
      return new Promise((resolve, reject) => {
        window.api.put({ url: ApiProvider.sortPhotos(), payload: payload },
          (success) => { resolve(success.data.data) },
          (err) => { reject(err) })
      })
    },
    fetchRejectedPhotos (context, toStore = true) {
      return new Promise((resolve, reject) => {
        window.api.get({ url: ApiProvider.rejectdPhotos() },
          (success) => {
            if (toStore) {
              context.commit('setRejectedPhotos', success.data.data)
            }

            resolve(Array.from(success.data.data))
          },
          (err) => { reject(err) })
      })
    },
    fetchLivePhotos (context) {
      return new Promise((resolve, reject) => {
        context.commit('setIsFetchingLivePhotos', true)
        window.api.get({ url: ApiProvider.fetchLivePhotos() },
          (success) => {
            context.commit('setIsFetchingLivePhotos', false)
            context.commit('setLivePhotos', success.data.data)
            resolve(success.data.data)
          },
          (err) => {
            reject(err)
          })
      })
    },
    updateReplaceConfirm (context) {
      return new Promise((resolve, reject) => {
        window.api.put({ url: ApiProvider.updateReplaceConfirm() },
          (success) => {
            context.commit('setReplaceConfirm', 1)
            resolve(success.data.data)
          },
          (err) => {
            reject(err)
          })
      })
    },
    cancelUpload (context, photoID) {
      return new Promise((resolve, reject) => {
        window.api.put({ url: ApiProvider.cancelUpload(photoID) },
          (success) => { resolve(success.data.data) },
          (err) => { reject(err) })
      })
    },
    getUserDetails (context) {
      return new Promise((resolve, reject) => {
        UserProvider.getUser()
          .then((res) => {
            context.commit('setUser', res.data)
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    updateAgreeTerms (context, payload) {
      context.commit('setAgreeTerms', payload.agree_terms)

      return new Promise((resolve, reject) => {
        window.api.put({ url: ApiProvider.agreeTerms(), payload: payload },
          (success) => { resolve(success.data.data) },
          (err) => { reject(err) })
      })
    },
    setPhotoTipSeen ({ commit }) {
      return new Promise((resolve, reject) => {
        window.api.put({ url: ApiProvider.photoTipSeen() },
          (success) => { resolve(success.data.data) },
          (err) => { reject(err) })
      })
    },
    fetchCountries ({ commit }) {
      return new Promise((resolve, reject) => {
        window.api.get({ url: ApiProvider.countries() },
          (success) => {
            const countries = success.data.data
            resolve(countries)
            commit('setCountries', countries)
          },
          (err) => { reject(err) })
      })
    },
    updateSkypeName ({ commit }, skypeName) {
      return new Promise((resolve, reject) => {
        const payload = {
          skype_name: skypeName
        }

        window.api.put({ url: ApiProvider.skypeSetting(), payload: payload },
          (success) => {
            commit('setSkypeName', skypeName)
            resolve(success)
          },
          (err) => {
            reject(err)
          })
      })
    }
  }
}

indexStore.modules.pwaAnimation = require('./pwa-animation').default
indexStore.modules.magicLink = require('./magic-link').default
indexStore.modules.multiLocation = require('./multi-location').default
indexStore.modules.providerPreferences = require('./provider-preferences.js').default
indexStore.modules.affiliate = require('@/features/affiliate/store/affiliate.js').default

const store = new Vuex.Store(indexStore)

// emit store changes
store.subscribe((mutation, state) => {
  if (mutation.type === 'setUser') {
    const evt = new CustomEvent('storeUserChange', {
      detail: {
        user: mutation.payload
      }
    })
    document.getRootNode().dispatchEvent(evt)
  }
})

export default store
