var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lesson-screen", attrs: { id: "lesson-screen-feedback" } },
    [
      _c("div", { staticClass: "wrapper" }, [
        _c(
          "div",
          { staticClass: "content__drawer content__drawer-filled" },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.hasLoaded,
                    expression: "hasLoaded"
                  }
                ],
                staticClass: "content lesson-screen--container"
              },
              [
                _c("div", { staticClass: "lesson-screen--content" }, [
                  _vm.isLessonOpened(_vm.LESSONS.MONEY)
                    ? _c("img", {
                        staticClass: "cover-photo",
                        attrs: {
                          src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-money-v2.svg")
                        }
                      })
                    : _vm.isLessonOpened(_vm.LESSONS.FEEDBACK)
                    ? _c("img", {
                        staticClass: "cover-photo",
                        attrs: {
                          src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-feedback-v2.svg")
                        }
                      })
                    : _vm.isLessonOpened(_vm.LESSONS.DATES)
                    ? _c("img", {
                        staticClass: "cover-photo",
                        attrs: {
                          src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-dates-v2.svg")
                        }
                      })
                    : _vm.isLessonOpened(_vm.LESSONS.REQUESTS)
                    ? _c("img", {
                        staticClass: "cover-photo",
                        attrs: {
                          src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-requests-v2.svg")
                        }
                      })
                    : _vm.isLessonOpened(_vm.LESSONS.CLIENTS)
                    ? _c("img", {
                        staticClass: "cover-photo",
                        attrs: {
                          src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-clients-v2.svg")
                        }
                      })
                    : _vm.isLessonOpened(_vm.LESSONS.SAFETY)
                    ? _c("img", {
                        staticClass: "cover-photo",
                        attrs: {
                          src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-safety-v2.svg")
                        }
                      })
                    : _vm.isLessonOpened(_vm.LESSONS.AVAILABILITY)
                    ? _c("img", {
                        staticClass: "cover-photo",
                        attrs: {
                          src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-availability-v2.svg")
                        }
                      })
                    : _vm.isLessonOpened(_vm.LESSONS.INTERVIEW)
                    ? _c("img", {
                        staticClass: "cover-photo",
                        attrs: {
                          src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-interview-v2.svg")
                        }
                      })
                    : _c("img", {
                        staticClass: "cover-photo",
                        attrs: {
                          src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-photos-v2.svg")
                        }
                      }),
                  _vm._v(" "),
                  _c("h2", [
                    _vm._v(_vm._s(_vm.$t(_vm.openedLessonName + ".title")))
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t(_vm.openedLessonName + ".subtitle")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-outline",
                      attrs: { href: "#", "data-test": "photo-faq-link" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.redirectToFAQ($event)
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-info-24px-baseline-v2.svg"),
                          alt: "info icon"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t(_vm.openedLessonName + ".faq")))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-link",
                      attrs: { href: "#", "data-test": "photo-support-link" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.redirectToSupport($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("contact_support")))]
                  ),
                  _vm._v(" "),
                  _c("h3", { staticClass: "feedback-question" }, [
                    _vm._v(_vm._s(_vm.$t("h3")))
                  ]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "#",
                            "data-test": "photo-smiley-rate"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.setFeedback(1)
                            }
                          }
                        },
                        [
                          _c("img", {
                            class: { inactive: !_vm.isFeedbackActive(1) },
                            attrs: {
                              src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-smile-v2.svg"),
                              alt: "smile emoji"
                            }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "#",
                            "data-test": "photo-smiley-rate"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.setFeedback(2)
                            }
                          }
                        },
                        [
                          _c("img", {
                            class: { inactive: !_vm.isFeedbackActive(2) },
                            attrs: {
                              src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-speechless-v2.svg"),
                              alt: "speechless emoji"
                            }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "#",
                            "data-test": "photo-smiley-rate"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.setFeedback(3)
                            }
                          }
                        },
                        [
                          _c("img", {
                            class: { inactive: !_vm.isFeedbackActive(3) },
                            attrs: {
                              src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-angry-face-v2.svg"),
                              alt: "smile emoji"
                            }
                          })
                        ]
                      )
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c("navigator", {
              attrs: {
                current: _vm.currentPage,
                "no-prev": "",
                next: _vm.$t("finish"),
                "next-class": "action-btn__lilac-gradient action-btn__blocked",
                total: _vm.totalPages,
                "step-class": "--purple"
              },
              on: {
                next: function($event) {
                  $event.preventDefault()
                  return _vm.completeLesson($event)
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }