<template>
  <div id="language-setting" class="wrapper">
    <md-new-navbar
      :route-name="getRedirectBackRouteName"
    />
    <div id="content" data-test="lang-switch" class="container">
      <h1 class="title">{{ $translate('language.title') }}</h1>
      <p class="description">{{ $translate('language.choose_language_message') }}</p>
      <p class="description">{{ $translate('language.choose_language_message_alt') }}</p>
      <div class="switch-input margin-bottom-xl">
        <span>{{ $translate('language.choose_language_option_1') }}</span>
        <div class="switch switch-solid">
          <label>
            <input id="switchDE" v-model="selectedLanguage" name="selectedLanguage" type="radio" value="DE" data-test="lang-switch-de"
                   checked="{isLocale('de')}"
                   @click.prevent="toggleLocale('de')">
            <span class="lever " />
          </label>
        </div>
      </div>
      <div class="switch-input">
        <span>{{ $translate('language.choose_language_option_2') }}</span>
        <div class="switch switch-solid">
          <label>
            <input id="switchEN" v-model="selectedLanguage" name="selectedLanguage" type="radio" value="EN" data-test="lang-switch-en"
                   checked="{isLocale('en')}"
                   @click.prevent="toggleLocale('en')">
            <span class="lever " />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocalizationService, { ENGLISH_LOCALE } from '@/services/LocalizationService'
import { updateUserLanguage } from '@/services/UserService.js'

export default {
  computed: {
    getRedirectBackRouteName () {
      return this.prevRoute && this.prevRoute.name === 'connect-faq' ? 'connect-faq' : 'setting'
    },
    selectedLanguage () {
      return this.isLocale('de') ? 'DE' : 'EN'
    },
    isLocale () {
      return (locale) => this.$i18n?.locale === locale
    },
    linkToDE () {
      return this.$router.resolve({
        name: this.$router.currentRoute.name,
        query: {
          ...this.$router.currentRoute.query
        }
      }).href
    },
    linkToEN () {
      return this.$router.resolve({
        name: this.$router.currentRoute.name,
        params: {
          lang: ENGLISH_LOCALE
        },
        query: {
          ...this.$router.currentRoute.query
        }
      }).href
    }
  },
  methods: {
    async setLocaleApiRequest (val) {
      await updateUserLanguage({ app_lang: val })
      await LocalizationService.saveLocale(val)
      window.location.href = val === 'en' ? this.linkToEN : this.linkToDE
    },
    toggleLocale () {
      if (this.isLocale('de')) {
        this.setLocaleApiRequest('en')
      } else {
        this.setLocaleApiRequest('de')
      }
    }
  }
}
</script>

  <style lang="scss">
  @import "./LanguageSettingPage.style";
  </style>
