<template functional>
  <component :is="injections.components.ForgotPasswordController" v-slot="{ data, bindings, actions }">
    <component
      :is="injections.components.AuthView"
      :data="data"
    >
      <template v-slot:title>
        <h2>{{ actions.translate('forgotPassword.title1') }}</h2>
        <h2>{{ actions.translate('forgotPassword.title2') }}</h2>
      </template>

      <apv-form
        :is-loading="data.isLoading"
        novalidate
        data-test="forgot-password-form"
        @submit="bindings.handleForgotPassword"
      >
        <apv-input
          :value="data.email"
          data-test="forgotPassword-input-email"
          type="email"
          tabindex="1"
          class="APV-onbo-redesign margin-bottom-lg"
          :label="actions.translate('forgotPassword.email')"
          :error="actions.getFieldError('email')"
          @input="bindings.handleEmailChange"
        />
        <apv-button
          block
          gradient
          type="submit"
          size="large"
          waves-effect="dark"
          data-test="login-cta"
          :disabled="bindings.isEmpty(data.email)"
          :rounded="10"
          :is-loading="data.isLoading"
        >
          {{ actions.translate('messages.globals.continue') }}
        </apv-button>
      </apv-form>

      <div class="footer-auth-nav">
        <div class="login-container">
          <router-link class="login-link" :to="data.loginRoute">{{ actions.translate('register.link2loginText2') }}</router-link>
        </div>
        <div class="register-container">
          <span class="login-label">{{ actions.translate('register.register4free') }}</span>
          <router-link class="register-link" :to="data.registerRoute">{{ actions.translate('login.signUp') }}</router-link>
        </div>
      </div>

    </component>
  </component>
</template>

<script>
import ForgotPasswordController from './ForgotPasswordController.vue'
import AuthView from '../AuthView.vue'

export default {
  inject: {
    components: {
      default: {
        ForgotPasswordController,
        AuthView
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.footer-auth-nav {
  font-size: 14px;
  line-height: 18px;
  margin: 46px 0 38px 0;

  .login-label { color: #2F3740; }

  .login-container { margin-bottom: 28px; }

  .login-link, .register-link {
      color: #DC0064;
      text-decoration: underline;
  }
}

.forgot-pass-auth-nav {
    font-size: 14px;
    line-height: 18px;
    margin: 46px 0 38px 0;

    .login-label { color: #2F3740; }

    .login-container { margin-bottom: 28px; }

    .login-link, .register-link {
        color: #DC0064;
        text-decoration: underline;
    }
}
.auth-background {
  position: relative;
  width: 100vw;
  height: 100dvh;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  background-color: white;

  @media (min-width: 769px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

</style>
