var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page", attrs: { id: "provider-performance" } },
    [
      _vm.fromDashboard
        ? _c("md-navbar", {
            attrs: {
              push: { name: "dashboard" },
              brand: _vm.$translate("sidenav.dashboard"),
              icon: "arrow_back"
            }
          })
        : _c("md-new-navbar"),
      _vm._v(" "),
      _c("div", { attrs: { id: "content" } }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("lesson-shortcut", {
              attrs: { "translation-key": "feedback" },
              model: {
                value: _vm.LESSONS.FEEDBACK,
                callback: function($$v) {
                  _vm.$set(_vm.LESSONS, "FEEDBACK", $$v)
                },
                expression: "LESSONS.FEEDBACK"
              }
            }),
            _vm._v(" "),
            _c("h5", { staticClass: "margin-bottom-lg margin-top-none" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$translate(
                      "provider_performance.performance_page.title"
                    )
                  ) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c(
              "performance-card",
              {
                staticClass: "total-rating-chart",
                attrs: {
                  icon: _vm.RATING_ICON.STAR,
                  rating: _vm.ratings.totalRating,
                  ranking: _vm.rankings.totalRating
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _vm.isFetchingData
                          ? _c("strong", [
                              _vm._v(
                                _vm._s(_vm.$translate("app.fetching_data"))
                              )
                            ])
                          : _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "provider_performance.performance_page.total_rating.title"
                                  )
                                )
                              )
                            ])
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _vm._v(" "),
                [
                  _vm.hasRating
                    ? _c("div", [
                        _vm.ratings.rank === _vm.RANKING.TOP_10
                          ? _c("div", { staticClass: "rank-comment" }, [
                              _c("img", {
                                staticClass: "valign-middle",
                                attrs: {
                                  src:
                                    "/img/performance-overview/medal_enabled.svg",
                                  width: "48"
                                }
                              }),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$translate(
                                      "provider_performance.performance_page.total_rating.top_10"
                                    )
                                  )
                                )
                              ])
                            ])
                          : _vm.ratings.rank === _vm.RANKING.TOP_30
                          ? _c("div", { staticClass: "rank-comment" }, [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$translate(
                                      "provider_performance.performance_page.total_rating.top_30"
                                    )
                                  )
                                )
                              ])
                            ])
                          : _c("div", { staticClass: "rank-comment" }, [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$translate(
                                      "provider_performance.performance_page.total_rating.below_top_30_pt_1"
                                    )
                                  ) + " "
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.$router.replace({
                                          name: "faq"
                                        })
                                      }
                                    }
                                  },
                                  [_vm._v("FAQ")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$translate(
                                        "provider_performance.performance_page.total_rating.below_top_30_pt_2"
                                      )
                                    )
                                )
                              ])
                            ])
                      ])
                    : _c("div", [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn total-rating-faq-btn btn-secondary display-block waves-effect waves-dark",
                            on: {
                              click: function($event) {
                                return _vm.$router.push({
                                  name: "total-rating-faq"
                                })
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "mdi mdi-information valign-middle"
                            }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "provider_performance.performance_page.total_rating.cta_button"
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      ])
                ]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "performance-card",
              {
                staticClass: "reliability-rating-chart",
                attrs: {
                  rating: _vm.ratings.reliability,
                  ranking: _vm.rankings.reliability
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _vm.isFetchingData
                          ? _c("strong", [
                              _vm._v(
                                _vm._s(_vm.$translate("app.fetching_data"))
                              )
                            ])
                          : _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "provider_performance.performance_page.reliability.title"
                                  )
                                )
                              )
                            ])
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _vm._v(" "),
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-secondary display-block waves-effect waves-dark",
                      on: {
                        click: function($event) {
                          return _vm.$router.push({ name: "reliability-faq" })
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "mdi mdi-information valign-middle"
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$translate(
                              "provider_performance.performance_page.reliability.cta_button"
                            )
                          )
                        )
                      ])
                    ]
                  )
                ]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "performance-card",
              {
                staticClass: "client-rating-chart",
                attrs: {
                  rating: _vm.ratings.client,
                  ranking: _vm.rankings.client
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _vm.isFetchingData
                          ? _c("strong", [
                              _vm._v(
                                _vm._s(_vm.$translate("app.fetching_data"))
                              )
                            ])
                          : _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "provider_performance.performance_page.client.title"
                                  )
                                )
                              )
                            ])
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _vm._v(" "),
                [
                  _vm.hasClientRating
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn reviews-btn btn-secondary display-block margin-bottom-md waves-effect waves-dark",
                          on: {
                            click: function($event) {
                              return _vm.gotoPage("inbox", {
                                filter: _vm.INBOX_FILTER.REVIEWS
                              })
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              class: {
                                "lilac lilac--red": _vm.hasFeedbackUpdates
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "provider_performance.performance_page.client.review_cta_button",
                                    { count: _vm.totalFeedback }
                                  )
                                )
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn client-faq-btn btn-secondary display-block waves-effect waves-dark",
                      on: {
                        click: function($event) {
                          return _vm.$router.push({ name: "client-faq" })
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "mdi mdi-information valign-middle"
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$translate(
                              "provider_performance.performance_page.client.cta_button"
                            )
                          )
                        )
                      ])
                    ]
                  )
                ]
              ],
              2
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("video-shortcut", {
        model: {
          value: _vm.LESSONS.FEEDBACK,
          callback: function($$v) {
            _vm.$set(_vm.LESSONS, "FEEDBACK", $$v)
          },
          expression: "LESSONS.FEEDBACK"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }