import { mapGetters } from 'vuex'

export default {
  mounted () {
    if (this.selectedEvent === null) {
      this.$router.replace({ name: 'events' })
    }
  },
  computed: {
    ...mapGetters({
      selectedEvent: 'events/getSelected'
    }),
    isViewReady () {
      return this.selectedEvent !== null
    }
  }
}
