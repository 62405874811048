<template>
  <div id="private-place-list-item" data-test="private-place-promo-card" @click="gotoPrivatePlacePromoPage">
    <div class="left-panel">
      <div class="promo-title">{{ $translate('private-place.news_promo_banner.title') }}</div>
      <div class="promo-subtitle">{{ $translate('private-place.news_promo_banner.subtitle') }}</div>
      <div class="promo-cta">{{ $translate('private-place.news_promo_banner.cta') }}</div>
    </div>
    <div class="right-panel">
      <img :src="item.image">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({
        image: 'img/private-place/promo-image-icon.svg'
      })
    }
  },
  methods: {
    gotoPrivatePlacePromoPage () {
      this.$router.push({ name: 'private-place-overview' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './PrivatePlacePromoCard.style';
</style>
