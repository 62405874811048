<script>
/**
 * Circular preloader
 *
 * @param string size - Set to "large" or "small" to modify preloader size. Leave empty to use the default size.
 * @param string color - Can be set to 'red', 'blue', 'yellow', or 'green'. Leave empty to use primary theme color.
 */
export default {
  props: {
    size: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    }
  },
  computed: {
    preloaderWrapperClass () {
      return {
        small: this.size === 'small',
        big: this.size === 'large'
      }
    },
    spinnerLayerClass () {
      return {
        'spinner-red-only': this.color === 'red',
        'spinner-blue-only': this.color === 'blue',
        'spinner-yellow-only': this.color === 'yellow',
        'spinner-green-only': this.color === 'green'
      }
    }
  }
}
</script>

<template>
  <div class="preloader-wrapper active" :class="preloaderWrapperClass">
    <div class="spinner-layer" :class="spinnerLayerClass">
      <div class="circle-clipper left">
        <div class="circle" />
      </div><div class="gap-patch">
        <div class="circle" />
      </div><div class="circle-clipper right">
        <div class="circle" />
      </div>
    </div>
  </div>
</template>
