<template>
  <div v-if="showDashboardIntro" data-test="onboarding-dashboard-intro" class="onboarding-dashboard-intro">
    <div class="onboarding-dashboard-intro__visual">
      <p>{{ $t('content_1') }}</p>
      <img src="/img/dashboard/dashboard-onboarding-intro.svg">
    </div>
    <p style="margin-top: 8px">{{ $t('content_2') }}</p>
  </div>
</template>

<i18n src="@/features/onboarding-bottom-sheet/translations/dashboard-intro.json"></i18n>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('onboarding', {
      showDashboardIntro: 'GET_SHOW_DASHBOARD_INTRO'
    })
  }
}
</script>

<style lang="scss" scoped>
.onboarding-dashboard-intro {
    margin-bottom: 16px;
    padding: 21px 24px;
    border-radius: 16px;
    background: transparent;
    font-size: 18px;
    color: #2F3740;
    border: 1px solid #CED6E0;
    text-align: left;

    &__visual {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    img {
        display: block;
        margin-left: 10px
    }

    p {
        margin: 0;
    }
}
</style>
