<template>
  <div
    v-if="canShowStatusIndicatorAll"
    :id="canShowNeedVerification ? 'status-indicator' : 'status-indicator-old'"
    :class="{
      'status-indicator--id-verification': canShowNeedVerification,
      'status-indicator--bottom': !hasFixedMenu,
      // for new fixed menu
      'status-indicator--bottom status-indicator--with-fixed-menu': hasFixedMenu,
      // for pages with navigator component
      'status-indicator--bottom status-indicator--with-fixed-navigator': hasFixedNavigator,
      // for pages with footer-button component
      'status-indicator--bottom status-indicator--with-fixed-footer-buttons': hasFixedFooterButtons,
      // for pages with chat controls
      'status-indicator--bottom status-indicator--with-fixed-chat-controls': hasFixedChatControls,
    }"
  >
    <div
      v-if="canShowEventStatus"
      class="event-status"
    >
      <div
        class="pre-nav-content"
        :class="{
          'pending': canShowPendingEvent,
          'cancellation-request': canShowCancellationRequest
        }"
      >
        <div class="container">
          <div
            v-if="canShowActiveEvent"
            id="status-indicator-active-event"
            class="active-event clickable"
            @click="gotoEventCheckin"
          >
            <div class="display-inline-block">
              <i class="material-icons valign-middle">expand_more</i>
              <span class="valign-middle">
                {{ $translate('app.status-indicator.ongoing') }}
              </span>
            </div>
            <span class="right timer valign-middle">
              {{ timeElapsed }}
            </span>
          </div>
          <div
            v-if="canShowUpcomingEvent"
            id="status-indicator-upcoming-event"
            class="active-event clickable"
            @click="gotoUpcomingEvent"
          >
            <div class="display-inline-block valign-middle">
              <i class="material-icons valign-middle">expand_more</i>
              <span class="valign-middle">
                {{ $translate('app.status-indicator.upcoming') }}
              </span>
              <span
                v-if="unreadMessageCount > 0"
                id="status-indicator-unread-msg-count"
                class="unread-message margin-left-xs pulse"
              >
                {{ unreadMessageCount }}
              </span>
            </div>
            <span class="right timer valign-middle">
              {{ timeRemaining }}
            </span>
          </div>
          <div
            v-if="canShowPendingEvent"
            id="status-indicator-pending-event"
            class="active-event pending-event clickable"
            @click="gotoPendingEvent"
          >
            <input
              type="hidden"
              name="shown-pending-event"
              :value="pendingEvent.meeting_start_at"
            >
            <div class="display-inline-block">
              <i class="material-icons valign-middle">expand_more</i>
              <span class="valign-middle">
                {{ $translate('app.status-indicator.pending') }}
              </span>
            </div>
            <span class="right timer valign-middle">
              {{ acceptCountdown }}
            </span>
          </div>
          <div
            v-if="canShowCancellationRequest"
            id="cancel-event-popup"
            class="active-event clickable"
            @click="gotoCancellationRequestPage"
          >
            <div class="display-inline-block">
              <span class="valign-middle">
                {{ $translate('app.status-indicator.cancellation_request') }}
              </span>
            </div>
            <span class="right timer valign-middle">
              {{ cancelRequestCountdown }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="canShowNeedVerification"
      class="need-verification clickable"
      @click="gotoVerify"
    >
      <div class="nav-content yellow warning-base">
        <div class="container">
          <p v-if="isIDVerificationPending" class="verification-pending">
            {{ $translate('verify.status_indicator.verification_title') }}
          </p>
          <a
            v-if="!isIDVerificationPending"
            class="waves-effect waves-dark darken verify-cta"
            href="#"
          >
            {{ $translate('verify.status_indicator.cta_button') }}
          </a>
        </div>
      </div>
    </div>
    <div
      v-if="canShowRequestDisabled"
      class="need-verification"
    >
      <div class="nav-content yellow darken-3">
        <div class="new-container">
          <p class="proper-font">{{ $translate('app.new_request_disabled') }}</p>
          <p class="billing-btn"
             @click="gotoPage('billing-v2-unpaid')">
            <span>{{ $translate('app.new_request_disabled_btn') }}</span>
          </p>
        </div>
      </div>
    </div>
    <div v-if="showVipPhotosBanner && VIP_PHOTOS_A2_2416" class="pre-nav-content vip-photo-banner">
      <div class="container">

        <div
          id="status-indicator-pending-event"
          class="active-event pending-event clickable"
        >

          <div class="display-inline-block">

            <span class="valign-middle">
              {{ $translate('app.vip_photo_banner_text') }}
            </span>
          </div>
          <span class="right vip-photo-banner-cta valign-middle" style="font-size:16px;" @click="gotoVipPhotosSetting">
            {{ $translate('app.vip_photo_banner_cta') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import fixedMenuMixin from '@components/v2/utilities/fixedMenu'

export default {
  mixins: [fixedMenuMixin],
  data () {
    return {
      excludePath: {
        events: [
          'event-details',
          'event-checkin',
          'event-declined',
          'event-declined-v2',
          'event-confirmed',
          'event-rate',
          'event-late',
          'event-whereis',
          'billing',
          'billing-form',
          'event-cancelled'
        ],
        verify: [
          'verify',
          'verify-intro',
          'verify-upload',
          'verify-pending',
          'billing',
          'billing-form'
        ],
        photos: [
          'photos-crop',
          'photos-anonimize'
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      cancellationRequestEvent: 'events/getCancellationRequestEvent',
      activeEventTimeElapsed: 'events/getActiveEventTimeElapsed',
      isIDVerificationPending: 'billing/isIDVerificationPending',
      hasBillingDetails: 'billing/hasBillingDetails',
      canShowStatusIndicator: 'getStatusIndicator',
      upcomingEvents: 'events/getUpcomingEvents',
      hasIDSubmitted: 'billing/hasIDSubmitted',
      pendingEvents: 'events/getPendingEvents',
      upcomingEvent: 'events/getUpcomingEvent',
      pendingEvent: 'events/getPendingEvent',
      hasIDRejected: 'billing/hasIDRejected',
      hasIDApproved: 'billing/hasIDApproved',
      activeEvent: 'events/getActiveEvent',
      canShowStatus: 'getStatusIndicator',
      events: 'events/get',
      isRequestEnabled: 'getIsRequestEnabled',
      bypassBillingDetailsCheck: 'bypassBillingDetailsCheck',
      user: 'getUser',
      hasFixedMenu: 'hasFixedMenu',
      hasFixedNavigator: 'hasFixedNavigator',
      hasFixedFooterButtons: 'hasFixedFooterButtons',
      hasFixedChatControls: 'hasFixedChatControls'
    }),
    VIP_PHOTOS_A2_2416 () {
      return this.$store.getters['featureflag/isEnabled']('VIP_PHOTOS_A2_2416')
    },
    totalMeetingStartInSeconds () {
      const upcomingEvent = this.upcomingEvent
      let delay = 0

      if (upcomingEvent) {
        const clientDelay = upcomingEvent.client_delay
        const providerDelay = upcomingEvent.provider_delay

        if (upcomingEvent.client_delay_seen && (clientDelay > 0 || providerDelay > 0)) {
          delay = clientDelay

          if (providerDelay > delay) {
            delay = providerDelay
          }
        }

        return upcomingEvent._meeting_start_in_seconds + $h.minToSeconds(delay)
      }
      return delay
    },
    timeElapsed () {
      if (this.activeEvent) {
        if (this.activeEvent._check_in_seconds <= 0) {
          return moment().hour(0)
            .minutes(0)
            .second(Math.abs(this.activeEvent._check_in_seconds))
            .format('HH[h] : mm[m] : ss[s]')
        }
      }
      return '00h : 00m : 00s'
    },
    timeRemaining () {
      if (this.totalMeetingStartInSeconds > 0) {
        return moment().hour(0)
          .minutes(0)
          .second(this.totalMeetingStartInSeconds)
          .format('HH[h] : mm[m] : ss[s]')
      }
      return '00h : 00m : 00s'
    },
    acceptCountdown () {
      if (this.pendingEvent) {
        const expirationSec = this.pendingEvent._expiration_in_seconds

        if (expirationSec !== null && expirationSec > 0) {
          return moment().hour(0)
            .minutes(0)
            .second(expirationSec)
            .format('HH[h] : mm[m] : ss[s]')
        }
      }
      return '00h : 00m : 00s'
    },
    cancelRequestCountdown () {
      if (this.cancellationRequestEvent) {
        const expirationSec = this.cancellationRequestEvent._cancellation_expiration_in_seconds

        if (expirationSec !== null && expirationSec > 0) {
          return moment().hour(0)
            .minutes(0)
            .second(expirationSec)
            .format('HH[h] : mm[m] : ss[s]')
        }
      }
      return '00h : 00m : 00s'
    },
    canShowUpcomingEvent () {
      return this.activeEvent === null &&
             this.pendingEvent === null &&
             this.cancellationRequestEvent === null &&
             this.upcomingEvent !== null &&
             this.isRequestEnabled
    },
    canShowActiveEvent () {
      return this.activeEvent !== null &&
             this.pendingEvent === null &&
             this.cancellationRequestEvent === null &&
             this.isRequestEnabled
    },
    canShowPendingEvent () {
      return this.pendingEvent !== null &&
             this.cancellationRequestEvent === null &&
             this.isRequestEnabled
    },
    canShowCancellationRequest () {
      return this.cancellationRequestEvent !== null &&
             this.isRequestEnabled
    },
    canShowEventStatus () {
      return this.canShowUpcomingEvent ||
             this.canShowActiveEvent ||
             this.canShowPendingEvent ||
             this.canShowCancellationRequest
    },
    canShowNeedVerification () {
      return !this.bypassBillingDetailsCheck &&
             !this.hasIDApproved &&
             this.isIDVerificationRequested &&
             this.hasBillingDetails
    },
    unreadMessageCount () {
      if (this.upcomingEvent) {
        return this.upcomingEvent._unread_message_count
      }
      return 0
    },
    showVipPhotosBanner () {
      return (this.canShowEventStatus == false &&
      this.canShowNeedVerification == false &&
      this.canShowRequestDisabled == false && this.user?.settings?.is_vip_photos_enabled == 0 &&
      this.$route.name === 'dashboard')
    },
    canShowStatusIndicatorAll () {
      const showTopStatusIndicator = ((this.showVipPhotosBanner && this.VIP_PHOTOS_A2_2416) ||
          this.canShowEventStatus ||
          this.canShowRequestDisabled) &&
          !this.isCurrentPageExcluded

      const showBottomStatusIndicator = this.canShowNeedVerification &&
          !this.isCurrentPageExcluded

      if (showTopStatusIndicator) {
        this.$store.commit('setTopStatusIndicator', true)
      } else {
        this.$store.commit('setTopStatusIndicator', false)
      }

      if (showBottomStatusIndicator) {
        this.$store.commit('setBottomStatusIndicator', true)
      } else {
        this.$store.commit('setBottomStatusIndicator', false)
      }

      const showStatusIndicator = showTopStatusIndicator || showBottomStatusIndicator

      this.$store.commit('setStatusIndicator', showStatusIndicator)

      return showStatusIndicator
    },
    isCurrentPageExcluded () {
      const routeName = this.$route.name

      if (this.canShowEventStatus) {
        if (this.excludePath.events.indexOf(routeName) >= 0) {
          return true
        }
      }
      if (this.canShowNeedVerification) {
        if (this.excludePath.verify.indexOf(routeName) >= 0) {
          return true
        }
      }
      if (this.canShowCancellationRequest) {
        if (this.excludePath.verify.indexOf(routeName) >= 0) {
          return true
        }
      }

      if (this.excludePath.photos.indexOf(routeName) >= 0) {
        return true
      }

      return false
    },
    canShowRequestDisabled () {
      return !this.isRequestEnabled
    },
    isIDVerificationRequested () {
      if (this.user) {
        return this.user._casting &&
               this.user._casting.id_request !== null &&
               this.user._casting.id_request !== undefined
      }
      return false
    }
  },
  watch: {
    canShowStatusIndicatorAll (newVal, oldVal) {
      this.$store.commit('setStatusIndicator', newVal)
    }
  },
  methods: {
    gotoPage (name) {
      this.$router.push({ name: name })
    },
    gotoEventCheckin () {
      this.$store.commit('events/SET_SELECTED', this.activeEvent)
      this.$router.push({ name: 'event-checkin' })
    },
    gotoUpcomingEvent () {
      this.$store.commit('events/SET_SELECTED', this.upcomingEvent)
      this.$router.push({ name: 'event-details' })
    },
    gotoPendingEvent () {
      this.$store.commit('events/SET_SELECTED', this.pendingEvent)
      this.$router.push({ name: 'event-details' })
    },
    gotoEventDetails (evt) {
      this.$store.commit('events/SET_SELECTED', evt)
      this.$router.push({ name: 'event-checkin' })
    },
    gotoCancellationRequestPage () {
      this.$store.commit('events/SET_SELECTED', this.cancellationRequestEvent)
      this.$router.push({ name: 'event-cancelled' })
    },
    gotoVipPhotosSetting (evt) {
      this.$store.commit('events/SET_SELECTED', evt)
      this.$router.push({ name: 'vip-photos-setting' })
    },
    gotoVerify (evt) {
      evt.preventDefault()

      if (this.hasIDRejected) {
        this.$router.replace({ name: 'verify-upload' })
      } else if (this.hasIDSubmitted) {
        this.$router.push({ name: 'verify-pending' })
      } else {
        this.$router.replace({ name: 'verify-intro' })
      }
    }
  }
}
</script>
