import { render, staticRenderFns } from "./FAQ.vue?vue&type=template&id=c2882d0a&scoped=true&"
import script from "./FAQ.vue?vue&type=script&lang=js&"
export * from "./FAQ.vue?vue&type=script&lang=js&"
import style0 from "./FAQ.vue?vue&type=style&index=0&id=c2882d0a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2882d0a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider8-stage.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c2882d0a')) {
      api.createRecord('c2882d0a', component.options)
    } else {
      api.reload('c2882d0a', component.options)
    }
    module.hot.accept("./FAQ.vue?vue&type=template&id=c2882d0a&scoped=true&", function () {
      api.rerender('c2882d0a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/features/provider-faq/pages/FAQ.vue"
export default component.exports