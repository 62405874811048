var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal",
      attrs: { "data-test": "onboarding-lesson-reminder-modal" }
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c(
          "h4",
          {
            staticClass: "modal-title margin-bottom-sm",
            staticStyle: { "font-size": "20px" }
          },
          [_vm._v(_vm._s(_vm.$t("title")))]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass:
              "center grey-text margin-bottom-none margin-top-none text-darken-1",
            staticStyle: { "font-size": "14px" }
          },
          [_vm._v(_vm._s(_vm.$t("body")))]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-footer",
          staticStyle: { "text-align": "center" }
        },
        [
          _c(
            "a",
            {
              staticClass: "btn-flat waves-effect waves-dark krypton-pink-text",
              staticStyle: { "font-size": "16px" },
              attrs: {
                href: "#",
                "data-test": "onboarding-lesson-reminder-modal-confirm"
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.confirm($event)
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("cta")) + "\n    ")]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }