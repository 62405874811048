<template functional>
  <div class="auth-background" :class="{
    [`auth-${props.route}`]: props.route,
  }">
    <div class="auth-parent">
      <div class="auth-child">
        <div class="auth-child--header">

          <img class="k-logo-new" src="/img/icons/k-logo-new.svg">

          <div class="auth-title">
            <slot name="title" />
          </div>
        </div>

        <!-- NOTE: currently deprecated, will not show if error message was set per field -->
        <div
          v-if="(props.data.errorMsg && props.showGenericErrorMsg)"
          data-test="error-msg"
          class="error-message margin-bottom-md">{{ props.data.errorMsg }}
        </div>
        <slot />

        <div class="auth-footer" :class="{ 'p-fixed': props.positionfixed }">
          <component :is="injections.components.LanguageSwitcherAuthView" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSwitcherAuthView from '@/features/language-switcher/components/LanguageSwitcherAuthView.vue'

export default {
  props: {
    data: {
      type: Object,
      default () {
        return {
          username: null,
          password: null,
          errorMsg: null,
          isLoading: false
        }
      }
    },
    showGenericErrorMsg: {
      type: Boolean,
      default () {
        return false
      }
    },
    positionfixed: {
      type: Boolean,
      default () {
        return true
      }
    },
    route: {
      type: String,
      default () {
        return ''
      }
    }
  },
  inject: {
    components: {
      default: {
        LanguageSwitcherAuthView
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-background {
  position: relative;
  width: 100vw;
  height: 100dvh;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  background-color: white;

  @media (min-width: 769px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.auth-register-start {
    background-image: url("/img/registration/apv-reg-mobile-bg.png");

    @media (min-width: 769px) {
      background-image: url("/img/registration/apv-reg-desktop-bg.png");
    }

    .auth-parent {
      background: transparent;

      @media (min-width: 769px) {
        background-color: #fff;
      }
    }
  }
}

.auth-parent {
  background: white;
  width: 100%;
  height: auto;
  padding: 13px 24px 0;
  .auth-child {
    width: 100%;
    max-width: 327px;
    margin: 0 auto;
    text-align: center;
    .auth-child--header {
      .k-logo-new { margin-bottom: 48px; }
      img.app-logo {
        display: block;
        width: 48px;
        margin: 0 auto 48px;
        border-radius: 4px;
      }
      .auth-title {
        margin-bottom: 48px;
        ::v-deep h2 {
          margin: 0;
          font-family: 'Proxima Nova Semibold';
          font-size: 32px;
          line-height: normal;
          color: #2F3740;
        }
      }
    }
    .error-message {
      padding: 16px;
      border-radius: 4px;
      background-color: #8A8F95;
      color: #fff;
      font-size: 14px;
    }
    ::v-deep .auth-nav {
      color: #757575;
      font-size: 14px;
      a {
        color: #C51162;
        text-decoration: none;
      }
    }
    .auth-footer {
      padding-bottom: 45px;

      & ::v-deep .lang-switcher {
        display: flex;
        justify-content: center;
        a {
          font-family: 'Proxima Nova Semibold';
          font-size: 14px;
          padding: 0 11px;
          color: #A6B2BF;
        }
        a:first-child {
          border-right: 1px solid #E1E6EB;
        }
        a.active {
          color: #DC0064;
        }
      }
    }
    .p-fixed {

      @media only screen and (max-width: 769px) {
        bottom: 45px;
        left: 50%;
        padding: 0;
        position: fixed;
        transform: translate(-50%, -50%);
      }

      @media only screen and (max-width: 420px) {
        bottom: unset;
        left: unset;
        padding: 0;
        position: relative;
        transform: unset;
      }

    }
  }
  @media (min-width: 769px) {
    width: 672px;
    min-height: 687px;
    padding: 48px 172px;
    border-radius: 24px;
    background-color: #fff;
  }
  @media (min-width: 1280px) {
    width: 687px;
    min-height: 672px;
    padding: 48px 172px;
    border-radius: 24px;
    box-shadow: 0px 16px 32px #2F37403D;
    .new-A2-2771-parent {
        max-width: 696px !important;
    }
  }
  @media screen and (max-height: 675px) and (orientation: landscape) {
    height: 100dvh;
    min-height: unset;
    overflow-y: scroll;
  }
}

</style>
