'use strict'

function isMobile () {
  return /Android|SamsungBrowser|webOS|iPhone|iPad|Opera Mini/i.test(navigator.userAgent)
}

function isPWAMode () {
  return window.matchMedia('(display-mode: standalone)').matches ||
         window.matchMedia('(display-mode: fullscreen)').matches ||
         window.navigator.standalone === true ||
         window.location.search.indexOf('utm_source=homescreen') >= 0
}

function isAndroidChrome () {
  return navigator.userAgent.search(/Chrome/i) >= 0 &&
         navigator.userAgent.search(/Android/i) >= 0 &&
         navigator.userAgent.search(/SamsungBrowser/i) === -1
}

function isiPhoneSafari () {
  return navigator.userAgent.search(/CriOS/i) === -1 &&
         navigator.userAgent.search(/iPhone|iPad/i) >= 0
}

var AddToHomeScreen = (function (document, localStorage) {
  var defaultConfig = {
    hasBookmarked: false,
    showOnReload: true,
    appIconURL: `${window.location.origin}/img/pwa/K-app-logo.svg`,
    spritesURL: `${window.location.origin}/img/pwa/mobile-sprite.png`,
    gifGuide: 'https://res.cloudinary.com/ama-production/image/upload/q_auto/v1636360994/apv/ios-v2.gif',
    blurElement: '',
    appName: 'Krypton App',
    appDescription: 'Verwalte dein Profil',
    appDescriptionV2: 'Verwalte dein Profil und deine Termine',
    template: '',
    platform: '',
    addText: 'Zum Home-Bildschirm',
    previewText: 'Preview in browser',
    iosMenuText1: 'Tippe unten auf',
    iosMenuText2: 'und dann',
    iosMenuText3: 'Zum Home-Bildschirm',
    androidMenuText1: 'Tippe auf',
    androidMenuText2: 'und dann',
    androidMenuText3: 'Zum Startbildschirm hinzufügen',
    androidSuccess1: 'Glückwunsch!',
    androidSuccess2: 'Du hast die Krypton App installiert.',
    androidSuccess3: 'Schau auf deinem Startbildschirm nach und tippe auf das Krypton Logo um die App zu öffnen.',
    androidSuccess4: 'Du findest es nicht?'
  }

  if (!defaultConfig.appIconURL) {
    if (isiPhoneSafari()) {
      // Show iphone screen
      defaultConfig.appIconURL = `${window.location.origin}/img/pwa/K-app-logo.svg`
    } else {
      // Show android screen
      defaultConfig.appIconURL = `${window.location.origin}/img/pwa/K-app-logo.svg`
    }
  }
  if (!defaultConfig.template) {
    if (isiPhoneSafari()) {
      // Show iphone screen
      defaultConfig.template = 'IOS'
      defaultConfig.platform = 'ios'
    } else {
      // Show android screen
      defaultConfig.template = defaultConfig.hasBookmarked ? 'ANDROID_EXIST' : 'ANDROID'
      defaultConfig.platform = 'android'
      defaultConfig.gifGuide = `${window.location.origin}/img/pwa/a2hs/android_chrome.gif`
      defaultConfig.addText = 'Zum Startbildschirm hinzufügen'
    }
  }

  const iosTpl = `
    <div class="add-to-home ios">
      <div class="title">
        <div class="app-icon">
          <img class="responsive-img" src="{appIconURL}" />
        </div>
        <div class="app-details">
          <h5>{appName}</h5>
          <p>{appDescriptionV2}</p>
        </div>
      </div>
      <div class="gif-guide">
        <img class="responsive-img" src="{gifGuide}">
      </div>
      <div class="instruction">
        <div class="homescreen-text">
          {iosMenuText1} <div class="icon-addToHome sprite-mobile"><img class="responsive-img" src="/img/pwa/ic-ios-options.svg" /></div> {iosMenuText2}<br/> {iosMenuText3}
        </div>
        <div class="icon-homePointer sprite-mobile"></div>
      </div>
    </div>
  `

  const androidTpl = `
    <div class="add-to-home android">
      <div class="instruction-text">
        <div class="icon-homePointer sprite-mobile"></div>
        <div class="homescreen-text margin-x-xl">
          {androidMenuText1} <div class="icon-addToHome sprite-mobile" style="background-image:url({spritesURL})"></div> {androidMenuText2}<br />
          {androidMenuText3}
        </div>
        <div class="gif-guide">
          <img class="responsive-img" src="{gifGuide}">
        </div>
        <div class="title">
          <div class="app-icon">
            <img class="responsive-img" src="{appIconURL}" />
          </div>
          <div class="app-details">
            <h5>{appName}</h5>
            <p>{appDescriptionV2}</p>
          </div>
        </div>
      </div>
      <div class="existing hide">
        <div class="title">
          <div class="app-icon">
            <img class="responsive-img" src="{appIconURL}" />
          </div>
          <div class="app-details">
            <h5>{appName}</h5>
            <p>{appDescriptionV2}</p>
          </div>
        </div>
        <div class="instruction-install">
          <span class="existing">
            {androidSuccess1}<br />{androidSuccess2}<br /><br />
            {androidSuccess3}<br>
            <a id="find-btn" href="#">{androidSuccess4}</a>
          </span>
        </div>
      </div>
    </div>
  `

  const androidExistsTpl = `
    <div class="add-to-home android">
      <div class="instruction-text hide">
        <div class="icon-homePointer sprite-mobile"></div>
        <div class="homescreen-text margin-x-xl">
          {androidMenuText1} <div class="icon-addToHome sprite-mobile" style="background-image:url({spritesURL})"></div> {androidMenuText2}<br />
          {androidMenuText3}
        </div>
        <div class="gif-guide">
          <img class="responsive-img" src="{gifGuide}">
        </div>
        <div class="title">
          <div class="app-icon">
            <img class="responsive-img" src="{appIconURL}" />
          </div>
          <div class="app-details">
            <h5>{appName}</h5>
            <p>{appDescriptionV2}</p>
          </div>
        </div>
      </div>
      <div class="existing">
        <div class="title">
          <div class="app-icon">
            <img class="responsive-img" src="{appIconURL}" />
          </div>
          <div class="app-details">
            <h5>{appName}</h5>
            <p>{appDescriptionV2}</p>
          </div>
        </div>
        <div class="instruction-install">
          <span class="existing">
            {androidSuccess1}<br />{androidSuccess2}<br /><br />
            {androidSuccess3}<br>
            <a id="find-btn" href="#">{androidSuccess4}</a>
          </span>
        </div>
      </div>
    </div>
  `

  var TEMPLATES = {
    IOS: iosTpl,
    ANDROID: androidTpl,
    ANDROID_EXIST: androidExistsTpl
  }

  function showOverlay () {
    document.querySelector('.add-to-home').style.display = 'block'
    document.querySelector('#app').style.display = 'none'
    document.querySelector(defaultConfig.blurElement).classList.add('blur')
  }

  function hideOverlay () {
    if (!defaultConfig.showOnReload) {
      localStorage.setItem('overlayStatus', 'hidden')
    }

    document.querySelector('.add-to-home').style.display = 'none'
    document.querySelector('#app').style.display = 'block'
    document.querySelector(defaultConfig.blurElement).classList.remove('blur')
  }

  function replaceString (obj, str) {
    var retStr = str

    Object.keys(obj).forEach(function (key) {
      var regexPattern = '{' + key + '}'
      retStr = retStr.replace(new RegExp(regexPattern, 'g'), obj[key])
    })

    return retStr
  }

  function initDom (config) {
    if (isiPhoneSafari()) {
      // Show iphone screen
      config.template = 'IOS'
      config.platform = 'ios'
      config.appDescriptionV2 = window.i18n.t('messages.pwa.appDescription')
      config.iosMenuText1 = window.i18n.t('messages.pwa.menuIconText1')
      config.iosMenuText2 = window.i18n.t('messages.pwa.menuIconText2')
      config.iosMenuText3 = window.i18n.t('messages.pwa.menuIconText3')
    } else {
      // Show android screen
      config.template = config.hasBookmarked ? 'ANDROID_EXIST' : 'ANDROID'
      config.platform = 'android'
      config.gifGuide = `${window.location.origin}/img/pwa/a2hs/android_chrome.gif`
      config.addText = 'Zum Startbildschirm hinzufügen'
      config.appDescriptionV2 = window.i18n.t('messages.pwa.appDescription')
      config.androidMenuText1 = window.i18n.t('messages.pwa.menuIconText1Android')
      config.androidMenuText2 = window.i18n.t('messages.pwa.menuIconText2Android')
      config.androidMenuText3 = window.i18n.t('messages.pwa.menuIconText3Android')
      config.androidSuccess1 = window.i18n.t('messages.pwa.androidSuccess1')
      config.androidSuccess2 = window.i18n.t('messages.pwa.androidSuccess2')
      config.androidSuccess3 = window.i18n.t('messages.pwa.androidSuccess3')
      config.androidSuccess4 = window.i18n.t('messages.pwa.androidSuccess4')
    }

    var dom = replaceString(config, TEMPLATES[config.template])
    var wrapper = document.createElement('div')

    wrapper.innerHTML = dom
    document.body.appendChild(wrapper)

    if (!isPWAMode()) {
      if (!defaultConfig.showOnReload && localStorage.getItem('overlayStatus') === 'hidden') {
        return
      }
      showOverlay()
    }
  }

  function initClickEvents () {
    const btn = document.getElementById('find-btn')

    if (btn) {
      btn.addEventListener('click', (e) => {
        e.preventDefault()
        document.querySelector('.instruction-text.hide').classList.remove('hide')
        document.querySelector('.existing').classList.add('hide')
      })
    }
  }

  return {
    init: function () {
      const isVerifyEmailPath = window.location.pathname === '/verify-email'

      if (isVerifyEmailPath) return
      if (isPWAMode() || !isMobile() || (!isAndroidChrome() && !isiPhoneSafari())) return

      var config = arguments.length <= 0 || arguments[0] === undefined ? defaultConfig : arguments[0]

      if (!config.blurElement) {
        console.error('Blur Element is required in config')
        return
      }

      initDom(Object.assign(defaultConfig, config))

      initClickEvents()
    },
    hideOverlay: hideOverlay,
    showOverlay: showOverlay
  }
})(document, localStorage)

module.exports = AddToHomeScreen
