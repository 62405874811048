import moment from 'moment'
import { mapGetters } from 'vuex'
const LocaleProvider = $h.getProvider('LocaleProvider')

export default {
  computed: {
    ...mapGetters({
      user: 'getUser',
      dropdown: 'getDropdown',
      isOnline: 'getIsOnline'
    }),
    getSelectedEventHotelName () {
      if (this.isMultiLocationMeeting) {
        return this.selectedEvent?._city_name
      }

      return this.selectedEvent?._hotel_name ?? ''
    },
    clientDelay () {
      if (this.selectedEvent) {
        return this.selectedEvent.client_delay
      }

      return 0
    },
    statusText () {
      if (this.isReactivated) {
        return this.$translate('events.status.client_reply_pending')
      }
      if (this.selectedEvent) {
        return this.$translate(`events.status.${this.selectedEvent._event_status}`)
      }

      return ''
    },
    canReactivateEvent () {
      return (
        this.isExpired &&
        this.selectedEvent._meeting_start_in_seconds > $h.hrToSeconds(1) &&
        this.selectedEvent.reactivation_status ===
          REACTIVATION_STATUS.CAN_REACTIVATE
      )
    },
    statusClass () {
      if (this.selectedEvent) {
        // Priority check status conditions if it falls as cancelled or re-activated event
        if (this.selectedEvent.status === TICKET_STATUS.CANCELLED) {
          if (this.isReactivated) {
            return { 'event-status--reactivated': true }
          }
        }

        // Do common status event status logic checks
        switch (this.selectedEvent._event_status) {
          case EVENT_STATUS.PENDING:
            return { 'event-status--pending': true }
          case EVENT_STATUS.CONFIRMED:
          case EVENT_STATUS.CONFIRMED_PREPARING:
            return { 'event-status--preparing': true }
          case EVENT_STATUS.CONFIRMED_READY:
            return { 'event-status--ready-pre': true }
          case EVENT_STATUS.CANCELLED_AGENT:
            return { 'event-status--cancelled-agent': true }
          case EVENT_STATUS.CANCELLED_PROVIDER:
            return { 'event-status--cancelled-provider': true }
          case EVENT_STATUS.CANCELLED_CLIENT:
            return { 'event-status--cancelled-client': true }
          case EVENT_STATUS.EXPIRED:
            return { 'event-status--expired': true }
          case EVENT_STATUS.DECLINED:
            return { 'event-status--declined': true }
        }
      }

      return ''
    },
    tooltipText () {
      return this.$translate('events.event_tooltip')
    },
    isPending () {
      return this.selectedEvent._event_status === EVENT_STATUS.PENDING
    },
    shouldShowMeetingPoint () {
      return this.isReadyToGo || (this.isReadyToGo && this.isTicketCancelled)
    },
    shouldShowIdentifier () {
      return (this.isReadyToGo && !this.isRoom) || ((this.isReadyToGo && !this.isRoom) && this.isTicketCancelled)
    },
    isTicketCancelled () {
      return this.selectedEvent.status === TICKET_STATUS.CANCELLED
    },
    isCancelled () {
      return (
        this.selectedEvent.status === TICKET_STATUS.CANCELLED ||
        this.selectedEvent._event_status === EVENT_STATUS.EXPIRED
      )
    },
    isExpired () {
      return this.selectedEvent._event_status === EVENT_STATUS.EXPIRED
    },
    isReactivated () {
      return (
        this.isExpired &&
        this.selectedEvent.reactivation_status ===
          REACTIVATION_STATUS.REACTIVATED
      )
    },
    isUnseenExpired () {
      return this.isExpired && this.selectedEvent.is_expired_seen === 0
    },
    isConfirmed () {
      return (
        this.selectedEvent.status === TICKET_STATUS.CONFIRMED ||
        (this.selectedEvent._event_status === EVENT_STATUS.CONFIRMED ||
          this.selectedEvent._event_status === EVENT_STATUS.CONFIRMED_PREPARING)
      )
    },
    isSliderEnabled () {
      const is5MinsAway = this.totalMeetingStart <= $h.minToSeconds(5)

      if (!this.isOnline) {
        return false
      }
      return is5MinsAway && this.isReadyToGo && !this.isReactivated
    },
    isReadyToGo () {
      if (this.selectedEvent) {
        return (
          this.selectedEvent._event_status === EVENT_STATUS.CONFIRMED_READY
        )
      }
      return false
    },
    eventDate () {
      this.selectedEvent._meeting_start_at.locale(LocaleProvider.getLocale())
      const date = this.selectedEvent._meeting_start_at
        .format('DD MMM')
        .replace('.', '')

      if (this.isToday) {
        return `${this.$translate('apv-card.today')}, ${date}`
      }

      if (this.isTomorrow) {
        return `${this.$translate('apv-card.tomorrow')}, ${date}`
      }

      return this.selectedEvent._meeting_start_at
        .format('ddd, DD MMM')
        .replaceAll('.', '')
    },
    today () {
      return moment()
    },
    isToday () {
      if (this.selectedEvent) {
        return this.selectedEvent._meeting_start_at.isSame(this.today, 'day')
      }
      return false
    },
    isTomorrow () {
      if (this.selectedEvent) {
        const tomorrow = this.today.add(1, 'days')

        return this.selectedEvent._meeting_start_at.isSame(tomorrow, 'day')
      }
      return false
    },
    eventDelay () {
      let delay = 0

      if (this.selectedEvent) {
        if (
          this.selectedEvent.client_delay_seen &&
          (this.selectedEvent.client_delay > 0 ||
            this.selectedEvent.provider_delay > 0)
        ) {
          delay = this.selectedEvent.client_delay

          if (this.selectedEvent.provider_delay > delay) {
            delay = this.selectedEvent.provider_delay
          }
        }
      }

      return delay
    },
    eventDuration () {
      if (this.selectedEvent) {
        const meetingStartAt = this.selectedEvent._meeting_start_at.clone()

        const duration = this.$translate('events.labels.meeting_hours', {
          hours: this.selectedEvent.meeting_duration
        })

        meetingStartAt.add(this.eventDelay, 'minutes')

        return `${meetingStartAt.format('HH:mm')}, ${duration}`
      }

      return ''
    },
    totalMeetingStart () {
      if (this.selectedEvent) {
        return (
          this.selectedEvent._meeting_start_in_seconds +
          $h.minToSeconds(this.eventDelay)
        )
      }

      return 0
    },
    canSendLateNotification () {
      if (this.selectedEvent) {
        const is90MinsAway =
          this.totalMeetingStart > 0 &&
          this.totalMeetingStart <= $h.minutesToSeconds(90)

        return (
          is90MinsAway &&
          this.selectedEvent.provider_delay === 0 &&
          this.selectedEvent.client_delay < 15 &&
          this.isReadyToGo
        )
      }

      return false
    },
    canAskWhereIsHe () {
      // Enable where is he immediately on meeting start
      const isMeetingStart = this.totalMeetingStart <= $h.minToSeconds(0)

      return isMeetingStart && this.isReadyToGo
    },
    shouldDisplayAdditionalDetailsSection () {
      return (
        (!this.isPending && !this.isReadyToGo) ||
        (this.isReadyToGo && this.shouldShowIdentifier)
      ) &&
      (!this.isVideoDateMeeting && this.isPrivatePlaceMeeting)
    },
    additionalDetails () {
      let additionalDetails = null

      if (this.selectedEvent) {
        if (
          !this.isRoom &&
          this.isReadyToGo &&
          this.selectedEvent?.additional_details
        ) {
          additionalDetails = this.selectedEvent?.additional_details
        } else {
          additionalDetails = this.$translate('events.labels.note_placeholder', {
            client_name: this.selectedEvent?.client?.name
          })
        }
      }

      return additionalDetails
    },
    clientIdentifier () {
      let identifier = null

      if (this.selectedEvent) {
        if (
          !this.isRoom &&
          this.isReadyToGo &&
          this.selectedEvent.client_identifier
        ) {
          identifier = this.selectedEvent.client_identifier
        } else {
          identifier = this.$translate('events.labels.note_placeholder', {
            client_name: this.selectedEvent.client.name
          })
        }
      }

      return identifier
    },
    meetingPoint () {
      let meetingPoint = null

      if (this.selectedEvent) {
        if (this.shouldShowMeetingPoint) {
          meetingPoint = this.selectedEvent.meeting_point

          if (this.selectedEvent.meeting_point_details !== null) {
            meetingPoint = `${this.selectedEvent.meeting_point}, ${this.selectedEvent.meeting_point_details}`
          }
          if (this.isRoom) {
            meetingPoint = `${this.selectedEvent.meeting_point} ${
              this.selectedEvent.room_number || ''
            } ${this.selectedEvent.room_number_comment || ''}`
          }
        } else {
          meetingPoint = this.$translate('events.labels.note_placeholder', {
            client_name: this.selectedEvent.client.name
          })
        }
      }

      return meetingPoint
    },
    getMeetingPointDisplay () {
      return this.shouldShowMeetingPoint
        ? this.meetingPoint
        : this.$translate('events.labels.note_placeholder', { client_name: this.selectedEvent.client.name })
    },
    getClientIdentifierDisplay () {
      return this.shouldShowIdentifier
        ? this.clientIdentifier
        : this.$translate('events.labels.note_placeholder', { client_name: this.selectedEvent.client.name })
    },
    isRoom () {
      if (this.selectedEvent && this.selectedEvent.meeting_point) {
        const meetingPoint = this.selectedEvent.meeting_point.toLowerCase()

        return (
          meetingPoint === this.dropdown.meeting_point.Room.toLowerCase() ||
          meetingPoint === 'room'
        )
      }
      return false
    },
    hotelStatusUpdate () {
      if (this.selectedEvent) {
        if (!this.selectedEvent._hotel_name) {
          return { 'pending-check': true }
        }
        return { checked: true }
      }
    },
    clientStatusUpdate () {
      if (this.selectedEvent) {
        if (!this.selectedEvent.client) {
          return { 'pending-check': true }
        }
        return { checked: true }
      }
    },
    outfitStatusUpdate () {
      if (this.selectedEvent) {
        if (this.selectedEvent._updates.dress_code) {
          return { 'lilac lilac--red': true }
        }
        if (
          !this.selectedEvent.dress_code &&
          !this.selectedEvent._updates.dress_code
        ) {
          return { 'pending-check': true }
        }
        return { checked: true }
      }
    },
    notesStatusUpdate () {
      if (this.selectedEvent) {
        if (this.selectedEvent._updates.custom_notes) {
          return { 'lilac lilac--red': true }
        }
        if (
          (this.selectedEvent.custom_notes || this.selectedEvent.travel_cost) &&
          !this.selectedEvent._updates.custom_notes
        ) {
          return { checked: true }
        }
      }
    },
    meetingPointStatusUpdate () {
      if (this.selectedEvent) {
        if (
          this.shouldShowMeetingPoint &&
          this.selectedEvent._updates.meeting_point
        ) {
          return { 'lilac lilac--red': true }
        }
        if (
          !this.shouldShowMeetingPoint ||
          (this.shouldShowMeetingPoint && !this.meetingPoint)
        ) {
          return { 'pending-check': true }
        }
        if (
          this.shouldShowMeetingPoint &&
          !this.selectedEvent._updates.meeting_point &&
          this.meetingPoint
        ) {
          return { checked: true }
        }
      }
    },
    identifierStatusUpdate () {
      if (this.selectedEvent) {
        if (
          this.shouldShowIdentifier &&
          this.selectedEvent._updates.client_identifier
        ) {
          return { 'lilac lilac--red': true }
        }
        if (
          !this.shouldShowIdentifier ||
          (this.shouldShowIdentifier && !this.selectedEvent.client_identifier)
        ) {
          return { 'pending-check': true }
        }
        if (
          this.shouldShowIdentifier &&
          this.selectedEvent.client_identifier &&
          !this.selectedEvent._updates.client_identifier
        ) {
          return { checked: true }
        }
      }
    },
    additionalDetailsStatusUpdate () {
      if (this.selectedEvent) {
        if (
          this.shouldShowIdentifier &&
          !this.selectedEvent?.additional_details
        ) {
          return { 'lilac lilac--red': true }
        }
        if (
          !this.shouldShowIdentifier ||
          (this.shouldShowIdentifier && !this.selectedEvent?.additional_details)
        ) {
          return { 'pending-check': true }
        }
        if (
          this.shouldShowIdentifier &&
          this.selectedEvent?.additional_details
        ) {
          return { checked: true }
        }
      }
    },
    eventNote () {
      let result = ''

      if (this.selectedEvent) {
        if (this.selectedEvent.custom_notes) {
          result = this.selectedEvent.custom_notes
        }
        if (this.selectedEvent.travel_cost) {
          result += ` Travel cost: ${this.user?._currency}${this.selectedEvent.travel_cost}`
        }
      }

      return result
    },
    isVideoDateMeeting () {
      return this.selectedEvent.from_video_form === 1
    },
    isPrivatePlaceMeeting () {
      return this.selectedEvent?.is_private_place === 1
    },
    isMultiLocationMeeting () {
      return this.selectedEvent?.is_multi_location === 1
    }
  }
}
