<template>
  <div id="rejected-photos" class="modal photo-error-modal">
    <div class="modal-content">
      <div>
        <p class="status-label">
          {{ $translate('photos.v2.rejected_photos.title') }}
        </p>
        <p class="status-text">
          {{ $translate('photos.v2.rejected_photos.content_1') }}
        </p>
      </div>
      <ul class="rejected-photos-list">
        <li v-for="(photos, idx) in rejectedPhotos" :key="idx">
          <div class="rejected-photo" :style="createBG(photos._url)" />
          <span>{{ photos.reject_reason }}</span>
        </li>
      </ul>
    </div>
    <div class="modal-footer">
      <button class="btn display-block modal-close" @click="confirm">
        {{ $translate('photos.v2.replace_popup.ok') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'title',
    'content',
    'rejectedPhotos'
  ],
  methods: {
    createBG (url) {
      return `background-image: url(${url})`
    },
    confirm ($e) {
      this.$emit('confirm', $e)
    }
  }
}
</script>
