<template>
  <div id="event-late" v-if="isViewReady">
    <md-navbar :push="{ name: 'event-details', params: { event: selectedEvent } }"
               :brand="$translate('events.nav_title')"
               :menuTrigger="false"
               icon="arrow_back">
    </md-navbar>
    <div class="content container">
      <div class="send-notification" v-if="!isUpdateSent">
        <h1 class="margin-top-md header">{{ $translate('events.late.header') }}</h1>
        <div class="warning padding-md">
          <i class="material-icons">warning</i>
          <div v-html="$translate('events.late.warning', { meeting_start_at: timeStart })"></div>
        </div>
        <md-select v-model="selectedVal"
          :label="$translate('events.late.input_label')"
          :placeholder="$translate('events.late.input_label')">
            <option slot="options"
                v-for="(i, idx) in filteredLateOps"
                :key="idx"
                :value="i">+{{ $translate('events.late.value_prefix', { val: i }) }}</option>
        </md-select>
        <div class="row center-align" v-if="newTime !== timeStart">
          <span style="font-size: 14px; color: #757575;">{{ $translate('events.late.new_meeting_start') }}</span><br>
          <strong style="font-size: 22px; color: #212121;">{{ newTime }}</strong>
        </div>
        <div class="navigator row margin-top-lg">
            <button class="btn btn-large no-padding btn-secondary"
                    @click="$router.go(-1)">
                {{ $translate('events.buttons.cancel') }}
            </button>
            <button class="btn btn-large no-padding btn-krypton-pink waves-effect waves-light"
                    @click="send()"
                    :disabled="!canSend">
                <span v-if="!isSending">{{ $translate('events.buttons.send') }}</span>
                <div class="preloader-wrapper small active" v-else>
                  <div class="spinner-layer spinner-green-only">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div><div class="gap-patch">
                      <div class="circle"></div>
                    </div><div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
            </button>
        </div>
      </div>
      <div class="success-notification center-align" v-else>
        <h1 class="success margin-top-md">
          {{ $translate('events.late.success_header') }}
        </h1>
        <p class="sub-title">
          {{ $translate('events.late.sub_title', { client_name: selectedEvent.client.name }) }}
        </p>
        <i class="material-icons">alarm</i>
        <div class="border">
          <strong class="be-on-time">
            {{ $translate('events.late.be_on_time') }}
          </strong>
          <div class="notes" v-html="$translate('events.late.notes', { client_name: selectedEvent.client.name, time: newTime })"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NullSelectCheck from './mixins/nullSelectCheck.js';


export default {
  mixins: [NullSelectCheck],
  computed: {
    timeStart() {
      if (this.selectedEvent) {
        return this.selectedEvent._meeting_start_at.format('HH:mm');
      }

      return '';
    },
    newTime() {
      if (this.selectedEvent) {
        const newTime = this.selectedEvent._meeting_start_at.clone();

        return newTime.add(this.selectedVal, 'm').format('HH:mm');
      }

      return '';
    },
    canSend() {
      return this.selectedVal > 0;
    },
    filteredLateOps() {
      if (this.selectedEvent) {
        return this.lateOpts.filter(i => i > this.selectedEvent.client_delay);
      }

      return this.lateOpts;
    },
  },
  data() {
    return {
      lateOpts: [5, 10, 15],
      isUpdateSent: false,
      selectedVal: 0,
      isSending: false,
    };
  },
  methods: {
    send() {
      if (this.isSending) return;

      this.isSending = true;

      const payload = {
        id: this.selectedEvent.id,
        delay: this.selectedVal
      };

      this.$store
          .dispatch('events/late', payload)
          .then(() => {
            this.isUpdateSent = true;
          })
          .finally(() => {
            this.isSending = false;
          });
    },
  },
}
</script>

