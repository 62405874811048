import { buildParam } from '@/services/ApiService'
import { SP_SETTINGS_SEEN_FIELD_TYPES } from '@/features/super-provider/constants/super-provider-defaults.js'
export const superProviderSettingsPath = '/v2/super-provider/settings'
export const superProviderStatusCheckPath = '/v2/super-provider/status-check'
export const superProviderSettingsSeenPath = '/v2/super-provider/settings/seen'

export const getSuperProviderSettings = () => {
  const url = buildParam(`${process.env.KRYPTON_PROVIDER_API}${superProviderSettingsPath}`)
  return new Promise((resolve, reject) => {
    window.api.get({ url },
      (success) => { resolve(success.data) },
      (err) => { reject(err.response.data) })
  })
}

export const postSuperProviderStatusCheck = () => {
  const url = buildParam(`${process.env.KRYPTON_PROVIDER_API}${superProviderStatusCheckPath}`)
  return new Promise((resolve, reject) => {
    window.api.post({ url },
      (success) => { resolve(success.data) },
      (err) => { reject(err.response.data) })
  })
}

export const putSuperProviderSettingsSeen = (payload = { field: SP_SETTINGS_SEEN_FIELD_TYPES.REVIEW }) => {
  const url = buildParam(`${process.env.KRYPTON_PROVIDER_API}${superProviderSettingsSeenPath}`)
  return new Promise((resolve, reject) => {
    window.api.put({ url, payload: payload },
      (success) => { resolve(success.data) },
      (err) => { reject(err.response.data) })
  })
}
