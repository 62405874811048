var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "super-provider-video__parent margin-top-lg" },
    [
      _c("div", { staticClass: "super-provider-video__thumbnail" }, [
        _c(
          "a",
          {
            staticClass: "super-provider-video__play-button",
            attrs: { href: "#", "data-test": "open-video" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.openVideo($event)
              }
            }
          },
          [
            _c("img", {
              staticClass: "super-provider-video__play-button-icon",
              attrs: { src: "/img/super-provider/video-player.svg" }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _vm.showVideo
        ? _c("div", { staticClass: "super-provider-video__backdrop" }, [
            _c("div", { staticClass: "super-provider-video__container" }, [
              !_vm.hasVideoEnded
                ? _c(
                    "video",
                    {
                      staticClass: "super-provider-video",
                      attrs: {
                        autoplay: "false",
                        controls: "",
                        controlsList: "nodownload"
                      },
                      on: { ended: _vm.showReplayScreen }
                    },
                    [
                      _c("source", {
                        attrs: {
                          "data-test": "video-source",
                          src: _vm.videoSrc,
                          type: "video/mp4"
                        }
                      })
                    ]
                  )
                : _c(
                    "div",
                    { staticClass: "super-provider-video__replay-container" },
                    [
                      _c(
                        "div",
                        { staticClass: "super-provider-video__replay" },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "super-provider-video__replay-button",
                              attrs: { href: "#" }
                            },
                            [
                              _c("img", {
                                staticClass:
                                  "super-provider-video__replay-button-icon",
                                attrs: {
                                  src: "/img/super-provider/video-replay.svg"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.replay($event)
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "super-provider-video__replay-text"
                            },
                            [_vm._v(_vm._s(_vm.$t("watchAgain")))]
                          )
                        ]
                      )
                    ]
                  ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "super-provider-video__close-button",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.closeVideo($event)
                    }
                  }
                },
                [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])]
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }