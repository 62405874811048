var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page", attrs: { id: "super-provider-faq" } },
    [
      _c(
        "div",
        { staticClass: "container", attrs: { id: "content" } },
        [
          _c("super-provider-faq-header", {
            attrs: {
              "header-title": _vm.$t("faq.faq_page_title"),
              "header-tagline": _vm.$t("faq.faq_page_description")
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "question-answer-section" },
            [
              _c("div", [
                _c(
                  "ul",
                  { staticClass: "q-and-a" },
                  _vm._l(_vm.faqList, function(__, key) {
                    return _c(
                      "li",
                      { key: key },
                      [
                        _c(
                          _vm.renderListItem(key),
                          _vm._b(
                            { tag: "component" },
                            "component",
                            { itemKey: key },
                            false
                          )
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("faq-cta", {
                attrs: {
                  "route-name": "super-provider",
                  "cta-txt": _vm.$translate(
                    "faq.performance_btn.super_provider_overview"
                  ),
                  "background-gradient-class": "--purple"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }