<template>
  <div id="reliability-rating" class="rating-box" :class="{ 'no-rating': !hasRating }">
    <h5>{{ $translate('provider_performance.overview.reliability_title') }}</h5>
    <ul class="rating">
      <li v-for="(type, idx) in renderedPoints" :key="idx">
        <i class="mdi" :class="ratingIconClass(type)" />
      </li>
    </ul>
    <p v-if="hasRating">{{ ratingText }}</p>
    <p v-else>{{ $translate('provider_performance.rating.no_rating') }}</p>
  </div>
</template>

<script>
import ratingMixin from '@/features/provider-performance/mixins/rating'

export default {
  mixins: [ratingMixin]
}
</script>
