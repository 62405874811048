import { render, staticRenderFns } from "./hair-color.vue?vue&type=template&id=518bb6a9&scoped=true&"
import script from "./hair-color.vue?vue&type=script&lang=js&"
export * from "./hair-color.vue?vue&type=script&lang=js&"
import style0 from "./hair-color.vue?vue&type=style&index=0&id=518bb6a9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "518bb6a9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider8-stage.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('518bb6a9')) {
      api.createRecord('518bb6a9', component.options)
    } else {
      api.reload('518bb6a9', component.options)
    }
    module.hot.accept("./hair-color.vue?vue&type=template&id=518bb6a9&scoped=true&", function () {
      api.rerender('518bb6a9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/register/basic/hair-color.vue"
export default component.exports