<template>
  <div v-if="options.length" id="dress_size" class="wrapper">
    <template>
      <div class="content__area">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <p class="content__stepper content__stepper--mobile"> {{ stepperLabel }}</p>
        <h2 class="content__title content__title--desktop">{{ $t('title') }}</h2>
      </div>
      <div class="content__area drawer">
        <div class="content">
          <p class="content__stepper content__stepper--desktop"> {{ stepperLabel }}</p>
          <h2 class="content__title content__title--mobile">{{ $t('title') }}</h2>

          <div class="drawer-icon center-align">
            <img src="/img/registration/basic/dress-size.svg" alt="girl on a weighing scale icon">
          </div>

          <div class="selected-text-container">
            <p v-if="selectedText" class="center-align selected-text">
              {{ selectedText }}
            </p>

            <p v-else> &nbsp; </p>
          </div>

          <md-select
            v-model="payload.dress_size"
            class="textfield-box-lg"
            :label="$t('input_label')"
            :default="$t('input_default')"
            :placeholder="$t('title')"
            :force-mobile="true"
            desktop-display-mode="split"
          >
            <option
              v-for="(option, index) in options"
              slot="options"
              :key="index"
              :value="option.key"
            >{{ option.content }}</option>
            <div v-if="errors.has('dress_size')" slot="errors" class="input-helper red-text">
              {{ errors.first('dress_size') }}
            </div>
          </md-select>

          <register-navigator-v2 :next-disabled="isCTADisabled" :total="total" :current="current" @prev.prevent="gotoPrev" @next.prevent="submit" />

        </div>
      </div>
    </template>
  </div>
</template>

<i18n src="@components/register/translations/dress-size.json"></i18n>

<script>
import { isEmpty } from '@/constants/helpers.js'

const LocaleProvider = $h.getProvider('LocaleProvider')
// const RegisterProvider = $h.getProvider('RegisterProvider')
const REG_DRESS_SIZE_CONST = Object.freeze({
  ERR_DISP_DELAY: 5000,
  EU_DRESS_SIZE_MIN: 32,
  EU_DRESS_SIZE_MAX: 44,
  UK_DRESS_SIZE_MIN: 4,
  UK_DRESS_SIZE_MAX: 16
})

export default {
  props: ['total', 'current', 'stepperLabel'],
  data () {
    return {
      options: [],
      payload: {
        dress_size: null
      }
    }
  },
  computed: {
    isCTADisabled () {
      return isEmpty(this.payload.dress_size)
    },
    homeBaseCountry () {
      return this.$store.getters.getHomeBaseCountry
    },
    user () {
      return this.$store.getters.getUser
    },
    selectedText () {
      if (!this.payload.dress_size) {
        return null
      }

      var result = this.payload.dress_size

      if (LocaleProvider.isLocaleEn()) {
        result = 'Size ' + result
      } else {
        result = 'Größe ' + result
      }

      return result
    },
    isFormValid () {
      return this.payload.dress_size
    }
  },
  mounted () {
    this.initializePayload()
    this.initializeOptions()
  },
  methods: {
    submit () {
      this.errors.clear()

      if (!this.isFormValid) {
        var errorMsg = this.$t('error_select', {
          field: this.$t('name')
        })

        this.errors.add({
          field: 'dress_size',
          msg: errorMsg
        })

        return false
      }

      this.$store.dispatch('updateBasicRegistration', { type: 'dress-size', payload: this.payload })
        .then(() => this.$router.push({ name: 'register-overview' }))
        .catch(err => this.displayErrorMessages(err))
    },
    displayErrorMessages (err) {
      this.$notifications.toast(err.message, REG_DRESS_SIZE_CONST.ERR_DISP_DELAY)
    },
    gotoPrev () {
      this.$router.push(this.resolveBasicRoute('bra-cup'))
    },
    resolveBasicRoute (type) {
      return {
        name: 'register-basic',
        params: {
          type: type
        }
      }
    },
    initializePayload () {
      this.payload.dress_size = this.user.profiles.dress_size
    },
    displayOptionContent (euSize, ukSize) {
      if (this.homeBaseCountry === 'GB') {
        return `${euSize} EU / ${ukSize} UK`
      }

      return euSize
    },
    initializeOptions () {
      let ukSize = REG_DRESS_SIZE_CONST.UK_DRESS_SIZE_MIN
      for (var euSize = REG_DRESS_SIZE_CONST.EU_DRESS_SIZE_MIN; euSize <= REG_DRESS_SIZE_CONST.EU_DRESS_SIZE_MAX; euSize++) {
        if (euSize % 2 !== 0) {
          ukSize += 2
          continue
        }

        this.options.push({
          key: euSize,
          content: this.displayOptionContent(euSize, ukSize)
        })
      }
    }
  }
}
</script>
