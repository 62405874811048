var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "basic" } },
    [
      [
        _vm.$route.params.type == "city"
          ? _c("register-basic-city", {
              attrs: {
                total: _vm.stepper.total,
                current: _vm.stepper.current,
                "stepper-label": _vm.stepperLabel
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.$route.params.type == "eye-color"
          ? _c("register-basic-eye-color", {
              attrs: {
                total: _vm.stepper.total,
                current: _vm.stepper.current,
                "stepper-label": _vm.stepperLabel
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.$route.params.type == "hair-color"
          ? _c("register-basic-hair-color", {
              attrs: {
                total: _vm.stepper.total,
                current: _vm.stepper.current,
                "stepper-label": _vm.stepperLabel
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.$route.params.type == "origin"
          ? _c("register-basic-origin", {
              attrs: {
                total: _vm.stepper.total,
                current: _vm.stepper.current,
                "stepper-label": _vm.stepperLabel
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.$route.params.type == "height"
          ? _c("register-basic-height", {
              attrs: {
                total: _vm.stepper.total,
                current: _vm.stepper.current,
                "stepper-label": _vm.stepperLabel
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.$route.params.type == "weight"
          ? _c("register-basic-weight", {
              attrs: {
                total: _vm.stepper.total,
                current: _vm.stepper.current,
                "stepper-label": _vm.stepperLabel
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.$route.params.type == "bra-cup"
          ? _c("register-basic-bra-cup", {
              attrs: {
                total: _vm.stepper.total,
                current: _vm.stepper.current,
                "stepper-label": _vm.stepperLabel
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.$route.params.type == "dress-size"
          ? _c("register-basic-dress-size", {
              attrs: {
                total: _vm.stepper.total,
                current: _vm.stepper.current,
                "stepper-label": _vm.stepperLabel
              }
            })
          : _vm._e()
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }