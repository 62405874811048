var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "chat-bubble-support chat-bubble reverse",
      class: { me: _vm.self }
    },
    [
      _c(
        "div",
        {
          staticClass: "chat-bubble__container",
          class: { "chat-bubble__container--self": _vm.self }
        },
        [
          _c("div", { staticClass: "bubble-texts" }, [
            _c(
              "div",
              { staticClass: "text" },
              [
                _c("div", { staticClass: "text-message" }, [
                  _vm._v(_vm._s(_vm.message))
                ]),
                _vm._v(" "),
                _vm._l(_vm.attachments, function(file, idx) {
                  return _c("div", { key: idx }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "file-upload container--flex container--flex-align-item-center clickable padding-y-md",
                        on: {
                          click: function($event) {
                            return _vm.downloadFile(file)
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "/img/support/image-icon-24x24.svg",
                            alt: "image icon"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "file-info left-align flex-1" },
                          [
                            _c("span", { staticClass: "file-name" }, [
                              _vm._v(_vm._s(file.original_file_name))
                            ]),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", { staticClass: "file-size" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.prettifySize(file.file_size)) +
                                  " "
                              ),
                              file.downloadProgress >= 0 &&
                              file.downloadProgress < 100
                                ? _c("small", [
                                    _vm._v(
                                      "(Download progress: " +
                                        _vm._s(file.downloadProgress) +
                                        " %)"
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              file.downloadProgress === 100
                                ? _c("small", [_vm._v("(Download complete)")])
                                : _vm._e(),
                              _vm._v(" "),
                              file.downloadProgress === -100
                                ? _c("small", [
                                    _vm._v("(An error has occured)")
                                  ])
                                : _vm._e()
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                })
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "status" }, [
              _vm.self && _vm.seen
                ? _c("small", { staticClass: "seen pink-text left" }, [
                    _c("i", { staticClass: "material-icons valign-middle" }, [
                      _vm._v("check")
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$translate("events.where_is.seen")) +
                        "\n        "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("small", { staticClass: "time-ago" }, [
                _vm._v(_vm._s(_vm.timeAgo))
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.self
            ? _c("img", {
                staticClass: "bubble-icon",
                attrs: { src: "/img/support/me-icon.svg", alt: "me icon" }
              })
            : _c("img", {
                staticClass: "bubble-icon",
                attrs: { src: "/img/support/admin-icon.svg", alt: "me icon" }
              })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }