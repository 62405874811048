var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wrapper",
      attrs: { id: "app-feedback", "data-test": "app-feedback" }
    },
    [
      _c("md-new-navbar", { attrs: { redirect: "help" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-feedback__container" },
        [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticStyle: { "text-align": "center" } }, [
              _c("h2", { attrs: { "data-test": "app-feedback-title" } }, [
                _vm._v(_vm._s(_vm.$t("title")))
              ]),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  src: "/img/app-feedback/app-feedback.svg",
                  alt: "app feedback image",
                  "data-test": "app-feedback-image"
                }
              })
            ]),
            _vm._v(" "),
            _c("p", {
              attrs: { "data-test": "app-feedback-helper-text" },
              domProps: { innerHTML: _vm._s(_vm.$t("helper")) }
            }),
            _vm._v(" "),
            _c("p", {
              attrs: { "data-test": "app-feedback-support-text" },
              domProps: { innerHTML: _vm._s(_vm.$t("support")) }
            }),
            _vm._v(" "),
            _c("p", {
              attrs: { "data-test": "app-feedback-explanation-text" },
              domProps: { innerHTML: _vm._s(_vm.explanationText) }
            }),
            _vm._v(" "),
            _c(
              "form",
              { attrs: { "data-test": "app-feedback-explanation-form" } },
              [
                _c(
                  "div",
                  [
                    _c(
                      "md-select",
                      {
                        attrs: {
                          placeholder: _vm.$t("category"),
                          label: _vm.$t("category"),
                          "disable-default": true,
                          "force-mobile": true,
                          "listen-for-reinitiate-options": true
                        },
                        model: {
                          value: _vm.form.category,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "category", $$v)
                          },
                          expression: "form.category"
                        }
                      },
                      _vm._l(_vm.categories, function(opt) {
                        return _c(
                          "option",
                          {
                            key: opt.key,
                            attrs: {
                              slot: "options",
                              disabled:
                                opt.disabled && opt.key !== _vm.form.category
                            },
                            domProps: { value: opt.value },
                            slot: "options"
                          },
                          [_vm._v(_vm._s(opt.text))]
                        )
                      }),
                      0
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "md-textarea",
                  {
                    staticClass: "margin",
                    staticStyle: { height: "90px" },
                    attrs: {
                      type: "multiline",
                      label: _vm.$t("message"),
                      maxlength: 2000
                    },
                    model: {
                      value: _vm.form.message,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "message", $$v)
                      },
                      expression: "form.message"
                    }
                  },
                  [
                    _c("span", { staticClass: "text-counter" }, [
                      _vm._v(" " + _vm._s(_vm.form.message.length) + "/2000")
                    ])
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("navigator", {
            attrs: {
              total: 0,
              current: 0,
              "no-prev": "",
              "primary-at-last": "",
              next: _vm.$t("cta"),
              "next-class": "action-btn__primary action-btn__blocked'",
              "next-disabled": !_vm.formValid || _vm.loading
            },
            on: {
              next: function($event) {
                $event.preventDefault()
                return _vm.submitFeedback($event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.showSuccessModal
        ? _c("app-feedback-success-modal-vue", {
            on: { close: _vm.closeModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }