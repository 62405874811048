var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "steps", attrs: { "data-test": "steps" } },
    _vm._l(_vm.total, function(i) {
      return _c("div", {
        key: "step-item-" + i,
        staticClass: "step",
        class: [_vm.classes, { active: i <= _vm.current }],
        attrs: { "data-test": "step-item" }
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }