<template>
  <div id="personality">
    <template>
      <register-personality-intellect
        v-if="$route.params.type == 'intellect'"
        :total="stepper.total"
        :current="stepper.current"
        :stepper-label="stepperLabel" />
      <register-personality-taste
        v-if="$route.params.type == 'taste'"
        :total="stepper.total"
        :current="stepper.current"
        :stepper-label="stepperLabel" />
      <register-personality-style
        v-if="$route.params.type == 'style'"
        :total="stepper.total"
        :current="stepper.current"
        :stepper-label="stepperLabel" />
      <register-personality-name
        v-if="$route.params.type == 'name'"
        :total="stepper.total"
        :current="stepper.current"
        :stepper-label="stepperLabel" />
    </template>
  </div>
</template>

<script>
export default {
  name: 'Personality',
  data () {
    return {
      stepper: {
        total: 4,
        current: null
      }
    }
  },
  computed: {
    stepperLabel () {
      return this.$translate('register.personality.stepper') + ' ' + this.stepper.current + '/' + this.stepper.total
    }
  },
  watch: {
    $route: function (to, from) {
      this.getCurrentStep()
    }
  },
  mounted () {
    this.getCurrentStep()
  },
  methods: {
    getCurrentStep () {
      switch (this.$route.params.type) {
        case 'intellect':
          this.stepper.current = 1
          break
        case 'taste':
          this.stepper.current = 2
          break
        case 'style':
          this.stepper.current = 3
          break
        case 'name':
          this.stepper.current = 4
          break
      }
    }
  }
}
</script>
