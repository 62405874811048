export const AFFILIATE_SETTINGS_SEEN_FIELD_TYPES = {
  NEW_AFFILIATE: 'new_affiliate',
  REWARD: 'reward',
  PROMO: 'promo',
  MENU_AFFILIATE: 'menu_affiliates',
  MENU_BILLING: 'menu_billing'
}

export const AFFILIATE_OVERVIEW = {
  settings: {
    rewards: {
      eur: 300,
      gbp: 200
    },
    currency_codes: {
      eur: 'EUR',
      gbp: 'GBP'
    },
    currency_symbols: {
      eur: '€',
      gbp: '£'
    }
  },
  total_rewards: null,
  pending_referrals: [],
  successful_referrals: []
}

export const FAQ_TEST_AMOUNTS = Object.freeze({
  uk_based: {
    nominal_amount: 50,
    invoice_amount: 75,
    new_invoice_amount: 25
  },
  de_based: {
    nominal_amount: 100,
    invoice_amount: 125,
    new_invoice_amount: 25
  }
})
