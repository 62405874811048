var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "billing-item",
      class: {
        "billing-item--overdue": _vm.isOverdue,
        "billing-item--late": _vm.isUnpaid,
        "billing-item--cancelled": _vm.isCancelled,
        "billing-item--paid": _vm.isPaid,
        "billing-item--expanded": _vm.isExpanded
      },
      attrs: { "data-test": "billing-item" },
      on: {
        click: function($event) {
          return _vm.handleItemClick(_vm.index)
        }
      }
    },
    [
      _c("div", { staticClass: "billing-item__header" }, [
        _c("div", { staticClass: "billing-item__header-icon" }, [
          _c("i", { staticClass: "material-icons" }, [_vm._v("description")]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "billing-item__header-invoice-id",
              attrs: { "data-test": "invoice-id" }
            },
            [_vm._v(_vm._s(_vm.invoice.invoice_number))]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "billing-item__header-amount",
            attrs: { "data-test": "amount" }
          },
          [_vm._v(_vm._s(_vm.amount))]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "billing-item__header-status",
            attrs: { "data-test": "status" }
          },
          [_vm._v(_vm._s(_vm.statusText))]
        )
      ]),
      _vm._v(" "),
      _vm.isExpanded
        ? _c("div", { staticClass: "billing-item__body" }, [
            _vm.hasCreditUsed
              ? _c(
                  "div",
                  {
                    staticClass: "billing-item__body-row",
                    attrs: { "data-test": "credit-used" }
                  },
                  [
                    _c("div", { staticClass: "billing-item__body-row-label" }, [
                      _vm._v(_vm._s(_vm.$t("credit_used")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "billing-item__body-row-value" }, [
                      _vm._v(_vm._s(_vm.creditUsed))
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "billing-item__body-row",
                attrs: { "data-test": "invoice-number" }
              },
              [
                _c("div", { staticClass: "billing-item__body-row-label" }, [
                  _vm._v(_vm._s(_vm.$t("invoice_number")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "billing-item__body-row-value" }, [
                  _vm._v(_vm._s(_vm.invoice.invoice_number))
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "billing-item__body-row",
                attrs: { "data-test": "invoice-date" }
              },
              [
                _c("div", { staticClass: "billing-item__body-row-label" }, [
                  _vm._v(_vm._s(_vm.$t("invoice_date")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "billing-item__body-row-value" }, [
                  _vm._v(_vm._s(_vm.invoiceDate))
                ])
              ]
            ),
            _vm._v(" "),
            _vm.isPaid
              ? _c(
                  "div",
                  {
                    staticClass: "billing-item__body-row",
                    attrs: { "data-test": "paid-date" }
                  },
                  [
                    _c("div", { staticClass: "billing-item__body-row-label" }, [
                      _vm._v(_vm._s(_vm.$t("paid.date")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "billing-item__body-row-value" }, [
                      _vm._v(_vm._s(_vm.paidDate))
                    ])
                  ]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isExpanded && (_vm.isOverdue || _vm.isUnpaid)
        ? _c("div", { staticClass: "billing-item__footer" }, [
            _c("img", {
              attrs: { src: "/img/billing/billing-bank-icon.svg", alt: "" }
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { href: "#", "data-test": "view-bank-cta" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.handleShowModal(_vm.index)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("bank_details.cta")))]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }