<template>
  <div id="onboarding-lesson">
    <screen-start v-if="isLessonScreenOpened(SCREENS.START)" data-test="onboarding-start-cpt" />
    <screen-thumbs-up v-if="isLessonScreenOpened(SCREENS.THUMBS_UP)" data-test="onboarding-thumbsup-cpt" />
    <screen-thumbs-down v-if="isLessonScreenOpened(SCREENS.THUMBS_DOWN)" data-test="onboarding-thumbsdown-cpt" />
    <screen-photos-good v-if="isLessonScreenOpened(SCREENS.PHOTOS_GOOD)" data-test="onboarding-photosgood-cpt" />
    <screen-photos-bad v-if="isLessonScreenOpened(SCREENS.PHOTOS_BAD)" data-test="onboarding-photosbad-cpt" />
    <screen-video v-if="isLessonScreenOpened(SCREENS.VIDEO)" data-test="onboarding-video-cpt" />
    <screen-feedback v-if="isLessonScreenOpened(SCREENS.FEEDBACK)" data-test="onboarding-feedback-cpt" />
  </div>
</template>

<script>
import ScreenStart from './ScreenStart.vue'
import ScreenThumbsUp from './ScreenThumbsUp.vue'
import ScreenThumbsDown from './ScreenThumbsDown.vue'
import ScreenPhotosGood from './ScreenPhotosGood.vue'
import ScreenPhotosBad from './ScreenPhotosBad.vue'
import ScreenVideo from './ScreenVideo.vue'
import ScreenFeedback from './ScreenFeedback.vue'
import { LESSONS, SCREENS } from '@/features/onboarding-lesson/constants'
import { OnboardingLessons } from '@/mixins/onboarding'

export default {
  components: {
    ScreenStart,
    ScreenThumbsUp,
    ScreenThumbsDown,
    ScreenPhotosGood,
    ScreenPhotosBad,
    ScreenVideo,
    ScreenFeedback
  },
  mixins: [
    OnboardingLessons
  ],
  mounted () {
    this.fetchOpenedLessonFromStorage()
  },
  methods: {
    async fetchOpenedLessonFromStorage () {
      let openedLesson

      try {
        openedLesson = await window.localforage.getItem('openedLesson')
      } finally {
        if (openedLesson) {
          this.updateActiveLesson({
            type: openedLesson?.type ?? LESSONS.NONE,
            screen: openedLesson?.screen ?? SCREENS.NONE
          })
        }
      }
    }
  }
}
</script>
