<template>
  <div class="modal left-align" style="max-height: 80%;">
    <div class="modal-content" style="padding: 32px 24px 2px;">
      <h4 id="client-late-modal-minutes" class="modal-title center">
        {{
          this.$translate('events.late.client_late_popup_title', {
            client_name: clientName,
            minutes_late: minutesLate,
          })
        }}
      </h4>

      <div class="alarm-icon center">
        <i class="material-icons">alarm</i>
      </div>

      <p id="client-late-new-meeting-at-text" class="text-meeting-at center" style="font-size: 14px;">
        {{
          this.$translate('events.late.new_meeting_time')
        }}
      </p>
      <h4 id="client-late-new-meeting-at" class="modal-title-new modal-title__meeting-at center">
        {{
          meetingAt
        }}
      </h4>

      <p class="grey-text text-darken-1 center" style="font-size: 14px;">
        {{
          this.$translate('events.late.client_late_popup_description', {
            client_name: clientName,
          })
        }}
      </p>
    </div>
    <div class="modal-footer" style="border-top: 1px solid #E1E6EB;">
      <a id="client-late-modal-confirm" href="#" class="btn-flat btn-confirm waves-effect waves-dark krypton-pink-text" @click.prevent="confirm">
        <strong>{{ this.$translate('events.late.client_late_popup_button') }}</strong>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    minutesLate: { type: Number, required: true },
    clientName: { type: String, required: true },
    meetingAt: { type: String, required: true }
  },
  methods: {
    confirm ($e) {
      $e.preventDefault()
      this.$emit('confirm', $e)
    }
  }
}
</script>

<style lang="scss" scoped>
.alarm-icon .material-icons {
    font-size: 72px;
    color: #BFB38F;
}
.modal-content {
    padding-bottom: 0;
}
.modal {
  .modal-title {
    color: #2F3740;
    font-size: 20px;

    &.modal-title__meeting-at {
      margin-bottom: 16px;
    }
  }
  .modal-footer {
    text-align: center;
  }
}
.text-meeting-at {
  color: #757575;
  font-size: 14px;
  margin-bottom: 8px;
}
.text-content {
  color: #959DA6;
  font-size: 14px;
}
.btn-confirm {
  font-size: 16px !important;
}
</style>
