<template>
  <div v-if="isViewReady" id="event-details" class="event-status" :class="statusClass">
    <md-navbar-event
      :push="{ name: 'events' }"
      :brand="$translate('events.nav_title')"
      :disabled="isSending"
      icon="arrow_back"
    />
    <div
      id="content"
      class="container"
      :class="{
        'video-date': isVideoDateMeeting,
        'has-custom-note': !isPending && eventNote,
      }">
      <h1 class="title">
        <span>{{ statusText }}</span>
        <a
          ref="tooltip"
          href="#"
          class="white-text tooltip tooltipped"
          data-position="bottom"
          :class="{ 'hide': !isConfirmed }"
          :data-tooltip="tooltipText"
          @click.prevent
        >
          <i
            class="material-icons"
            style="font-size: 16px;"
          >help</i>
        </a>
      </h1>
      <div
        class="row schedule"
      >
        <div id="event-details-event-date" class="center-align">
          <i class="material-icons valign-middle">today</i>
          <span>{{ eventDate }}</span>
        </div>
        <div id="event-details-event-duration" class="center-align">
          <i class="material-icons valign-middle">access_time</i>
          <span>{{ eventDuration }}</span>
        </div>
      </div>
      <div class="notes radius16">
        <ul class="left-align notes-detail">
          <li
            v-if="isVideoDateMeeting"
            id="event-details-video-date-status"
            class="checked"
          >
            <strong class="krypton-purple-text">{{ $translate('events.video_date.title') }}</strong>
            <span>Skype Call</span>
          </li>
          <li
            v-else
            id="event-details-hotel-status"
            :class="hotelStatusUpdate"
          >
            <div class="event-details-hotel-name">
              <i
                v-if="isPrivatePlaceMeeting"
                data-test="event-details-pp-icon"
                class="material-icons"
              >
                person_pin_circle
              </i>
              <multi-location-event-icon v-if="isMultiLocationMeeting" />
              <strong>{{ getSelectedEventHotelName }}</strong>
            </div>
            <multi-location-event-address v-if="isMultiLocationMeeting" :event="selectedEvent" is-event-details />
            <span v-else>{{ selectedEvent._hotel_address || '' }}</span>
          </li>
          <li
            id="event-details-client-status"
            :class="clientStatusUpdate"
          >
            <strong>{{ $translate('events.labels.meeting') }}</strong>
            <span>
              <i
                v-if="selectedEvent._have_met_before"
                class="material-icons valign-middle tiny rotate-opposite"
              >refresh</i>
              {{ selectedEvent.client.name || '' }}, {{ selectedEvent.client.age || '' }}
              <span
                v-if="selectedEvent._have_met_before"
              >{{ $translate('events.event_details.met_before') }}</span>
            </span>
          </li>
          <li
            id="event-details-dress-code"
            :class="outfitStatusUpdate"
          >
            <strong>{{ $translate('events.labels.outfit') }}</strong>
            <span>{{ getOutfit(selectedEvent.dress_code) || '' }}</span>
          </li>
          <li
            v-if="!isPending && eventNote"
            id="event-details-custom-notes"
            :class="notesStatusUpdate"
          >
            <strong>{{ $translate('events.labels.note') }}</strong>
            <span>{{ eventNote }}</span>
          </li>
          <li
            v-if="!isPending && !isVideoDateMeeting"
            id="event-details-meeting-point"
            :class="meetingPointStatusUpdate"
          >
            <strong>{{ $translate('events.labels.meeting_point') }}</strong>
            <span data-test="meeting-point-text">{{ getMeetingPointDisplay }}</span>
          </li>
          <li
            v-if="((!isPending && !isReadyToGo) || (isReadyToGo && shouldShowIdentifier)) && !isVideoDateMeeting"
            id="event-details-client-identifier"
            :class="identifierStatusUpdate"
          >
            <strong>{{ $translate('events.labels.identifier') }}</strong>
            <span data-test="client-identifier-text">{{ getClientIdentifierDisplay }}</span>
          </li>
          <li
            v-if="shouldDisplayAdditionalDetailsSection"
            id="event-details-client-additional-details"
            :class="additionalDetailsStatusUpdate"
          >
            <strong>{{ $translate('events.labels.additional_details') }}</strong>
            <span>{{ additionalDetails }}</span>
          </li>
        </ul>
        <div
          v-if="isPending"
          class="request-acceptance"
        >
          <div class="coin" />
          <p>
            <strong>
              <currency />{{ selectedEvent.amount_payable }}
              <span v-if="isMultiLocationMeeting">+ <currency />{{ selectedEvent.travel_cost }}</span>
            </strong>
          </p>

        </div>
        <div v-if="isPending">
          <div
            class="accept-btn btn btn-krypton-pink waves-effect waves-light"
            @click.prevent="acceptRequest"
          >
            <span
              v-if="!isSending"
              :class="{ 'grey-text': isRejecting }"
            >{{ $translate('events.buttons.accept') }}</span>
            <div
              v-else
              class="preloader-wrapper small active"
            >
              <div class="spinner-layer spinner-green-only">
                <div class="circle-clipper left">
                  <div class="circle" />
                </div>
                <div class="gap-patch">
                  <div class="circle" />
                </div>
                <div class="circle-clipper right">
                  <div class="circle" />
                </div>
              </div>
            </div>
          </div>
          <p class="margin-md">
            <small>{{ $translate('events.labels.disclaimer') }}</small>
          </p>
        </div>
      </div>
    </div>

    <div
      class="event-details-wrapper"
      :class="{
        'video-date': isVideoDateMeeting,
        'has-custom-note': !isPending && eventNote
      }">
      <div class="event-details-control">
        <!-- Slide to check-in -->
        <div
          v-if="(!isPending && !canReactivateEvent) || isReactivated"
          class="slider-container container">
          <v-touch @swiperight="onDisabledSwipe">
            <div
              v-if="!isSliderEnabled"
              class="cannot-slide"
            />
          </v-touch>
          <md-slide-to-unlock
            v-model="pulleeVal"
            :min="0"
            :max="100"
            :label="$translate('events.labels.slide_check_in')"
            :disabled="!isSliderEnabled"
            :is-sending="isSending"
          />
        </div>

        <!-- Default Controls -->
        <div
          v-if="(!isPending && !canReactivateEvent) || isReactivated"
          id="controls"
          class="align-center container">
          <div
            id="event-details-is-late-btn"
            class="control-btn col s4 clickable waves-effect waves-light"
            :class="{
              'disabled': !canSendLateNotification || isReactivated || isSending,
            }"
            @click="onClickLate"
          >
            <i class="material-icons valign-middle">access_time</i>
            <span>{{ $translate('events.buttons.late') }}</span>
          </div>
          <div
            id="event-details-where-is-he-btn"
            class="control-btn col s4 clickable waves-effect waves-light"
            :class="{
              'disabled': !canAskWhereIsHe || isReactivated || isSending,
            }"
            style="line-height: 16px;"
            @click="onClickWhereIs"
          >
            <div class="where-is-she-icon" />
            <span>{{ $translate('events.buttons.where_is_he') }}</span>
            <span
              v-if="selectedEvent._unread_message_count > 0 && canAskWhereIsHe"
              class="unread-message pulse"
            >{{ selectedEvent._unread_message_count }}</span>
          </div>
          <div
            id="event-details-support-btn"
            class="control-btn col s4 clickable waves-effect waves-light"
            :class="{
              'disabled': isCancelled || isReactivated || isSending,
            }"
            @click="onClickSupport"
          >
            <i class="material-icons valign-middle">help_outline</i>
            <span>{{ $translate('events.buttons.support') }}</span>
            <span
              v-if="eventDetail && eventDetail._support !== null && eventDetail._support.unread > 0"
              class="unread-message pulse"
            >{{ eventDetail._support.unread }}</span>
          </div>
        </div>

        <!-- Reactivation Controls -->
        <div
          v-if="canReactivateEvent"
          id="controls"
          class="align-center container margin-top-xl">
          <button
            class="btn btn-secondary col s4 clickable waves-effect waves-dark"
            :class="{
              'disabled': isReactivating,
            }"
            @click="onClickNotAvailable"
          >
            <span v-if="!isReactivateDeclining">{{ $translate('events.buttons.not_availble') }}</span>
            <div
              v-else
              class="preloader-wrapper small active"
            >
              <div class="spinner-layer spinner-green-only">
                <div class="circle-clipper left">
                  <div class="circle" />
                </div>
                <div class="gap-patch">
                  <div class="circle" />
                </div>
                <div class="circle-clipper right">
                  <div class="circle" />
                </div>
              </div>
            </div>
          </button>
          <button
            class="btn col s4 clickable waves-effect waves-light"
            :class="{
              'disabled': isReactivateDeclining,
            }"
            @click="onClickReactivate"
          >
            <span v-if="!isReactivating">{{ $translate('events.buttons.reactivate') }}</span>
            <div
              v-else
              class="preloader-wrapper small active"
            >
              <div class="spinner-layer spinner-green-only">
                <div class="circle-clipper left">
                  <div class="circle" />
                </div>
                <div class="gap-patch">
                  <div class="circle" />
                </div>
                <div class="circle-clipper right">
                  <div class="circle" />
                </div>
              </div>
            </div>
          </button>
        </div>

        <!-- Pending event controls -->
        <div
          v-if="isPending"
          id="controls"
          class="align-center container decline-controls margin-top-xl">
          <a
            href="#"
            :class="{ 'waves-effect waves-dark': !isSending }"
            @click.prevent="declineRequest"
          >
            <strong :class="{ 'grey-text': isSending }">
              <span v-if="!isRejecting">{{ $translate('events.buttons.decline') }}</span>
              <div
                v-else
                class="preloader-wrapper small active"
                hotelStatusUpdate
              >
                <div class="spinner-layer spinner-green-only">
                  <div class="circle-clipper left">
                    <div class="circle" />
                  </div>
                  <div class="gap-patch">
                    <div class="circle" />
                  </div>
                  <div class="circle-clipper right">
                    <div class="circle" />
                  </div>
                </div>
              </div>
            </strong>
          </a>
          <p>
            <small>{{ $translate('events.labels.footer_note') }}</small>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NullSelectCheck from './mixins/nullSelectCheck.js'
import EventDetailsComputedMixin from './mixins/event-details-computed'
import EventDetailsMethodsMixin from './mixins/event-details-methods'
import EventDetailsWatchMixin from './mixins/event-details-watch'

const BYPASS_POPUP_PATH = [
  'event-declined-v2',
  'event-declined',
  'event-confirmed'
]

export default {
  name: 'EventDetails',
  mixins: [
    EventDetailsComputedMixin,
    EventDetailsMethodsMixin,
    EventDetailsWatchMixin,
    NullSelectCheck
  ],
  data () {
    return {
      pulleeVal: 0,
      isSending: false,
      isRejecting: false,
      isReactivating: false,
      isReactivateDeclining: false,
      eventDetail: null
    }
  },
  mounted () {
    this.fetchEventDetails()
    this.setEventSeen()
    this.setPendingSeen()
    this.setExpiredSeen()
    this.initializeTooltip()
  },
  beforeRouteLeave (to, from, next) {
    if (this.isViewReady) {
      $(this.$refs.tooltip).trigger('mouseleave')

      this.$store.commit('events/EVENT_DETAILS_SEEN', this.selectedEvent.id)

      this.setEventSeen()
      this.setExpiredSeen()

      if (BYPASS_POPUP_PATH.indexOf(to.name) === -1 && this.isPending) {
        this.onPageLeaveCheck(next)
      } else {
        this.destroyTooltipInstance()
        next()
      }
    } else {
      next()
    }
  }
}
</script>

<style lang="scss" scoped>
#event-details {

  .height100 {
    height: 100% !important;
  }

  .radius16 {
    border-radius: 16px;
  }
  .notes {

    .notes-detail {
      padding: 0;
      margin: 30px 0 24px 0;
    }
  }
}
#event-details-event-date {
  margin-right: 1rem;
}
.event-details-hotel-name {
  display: flex;
  align-items: center;

  .material-icons {
    font-size: 20px;
    margin-right: 4px;
  }
}
.currency-symbol {
  font-size: 18px;
}
</style>
