<template>
  <div id="availability">
    <div
      id="content"
      class="container"
    >
      <lesson-shortcut v-model="LESSONS.AVAILABILITY" translation-key="availability" />
      <div class="row">
        <div class="title-block col s12">
          <h3 class="title title secondary-title">
            {{ $translate('availability.title') }}
          </h3>
          <div class="right">
            <div
              class="overview-indicator"
              :class="{
                'sad': filledAvailabilityCount < 7,
                'neutral': filledAvailabilityCount >= 7 &&
                  filledAvailabilityCount <= 10,
                'happy': filledAvailabilityCount > 10,
              }"
            />
            <strong>{{ filledAvailabilityCount }}</strong> {{ $translate('availability.of') }} <strong>14</strong>
          </div>
        </div>
        <div class="description col s12">
          <strong>{{ $translate('availability.description_1') }}</strong><br>
          <span>{{ $translate('availability.description_2') }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <div class="overview-table">
            <div class="overview-table--title">
              <div class="overview-table--block">
                {{ $translate('availability.day_short.monday') }}
              </div>
              <div class="overview-table--block">
                {{ $translate('availability.day_short.tuesday') }}
              </div>
              <div class="overview-table--block">
                {{ $translate('availability.day_short.wednesday') }}
              </div>
              <div class="overview-table--block">
                {{ $translate('availability.day_short.thursday') }}
              </div>
              <div class="overview-table--block">
                {{ $translate('availability.day_short.friday') }}
              </div>
              <div class="overview-table--block">
                {{ $translate('availability.day_short.saturday') }}
              </div>
              <div class="overview-table--block">
                {{ $translate('availability.day_short.sunday') }}
              </div>
            </div>
            <div
              class="overview-table--schedule"
              :class="{
                'summary': !isFullTableView,
                'full': isFullTableView,
              }"
            >
              <div
                v-for="(status, idx) in availabilitiesOverview"
                :key="idx"
                class="overview-table--block"
                :class="{
                  'unavailable': status === AVAILABILITY_STATUS.UNAVAILABLE,
                  'unfilled': status === AVAILABILITY_STATUS.UNFILLED,
                  'filled': status === AVAILABILITY_STATUS.FILLED,
                  'past': status === AVAILABILITY_STATUS.PAST,
                }"
              />
            </div>
            <div
              class="expand-table-btn clickable"
              :class="{ 'active': isFullTableView }"
              @click="isFullTableView = !isFullTableView"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="month col s6 m9 left-align">
          <small>{{ thisMonth }}</small>
        </div>
        <div class="col s6 m3 right-align">
          <span class="arrow">&dtrif;</span>
          <select
            v-model="filterType"
            class="browser-default"
          >
            <option :value="AVAILABILITY_FILTER_TYPES.ALL">
              {{ $translate('availability.filter_types.show_all') }}
            </option>
            <option :value="AVAILABILITY_FILTER_TYPES.UNFILLED">
              {{ $translate('availability.filter_types.show_not_set') }}
            </option>
            <option :value="AVAILABILITY_FILTER_TYPES.FILLED">
              {{ $translate('availability.filter_types.show_set') }}
            </option>
          </select>
        </div>
        <div class="availabilities">
          <div
            v-for="(am, idx) in availabilitiesMonth"
            :key="idx"
          >
            <div
              v-if="idx > 0"
              class="col s12 month left-align margin-top-md"
            >
              <small>{{ month(am.month) }}</small>
            </div>
            <availability-card
              v-for="(availability, ix) in am.availabilities"
              :key="availability.date"
              :style="{ zIndex: 100 - ix }"
              :idx="availability.date"
              :pkey="ix"
              :payload="availability"
            />
          </div>
        </div>
      </div>
      <div v-if="!haveLoadedMore" class="row">
        <div class="col s12">
          <button
            id="availability-loadmore"
            type="button"
            class="btn btn-secondary btn-large display-block waves-effect waves-dark"
            @click="loadMore"
          >
            <strong>{{ $translate('availability.load_btn') }}</strong>
          </button>
        </div>
      </div>
    </div>
    <video-shortcut v-model="LESSONS.AVAILABILITY" />
    <fixed-menu />
  </div>
</template>

<script>
import AvailabilityMixin from './mixins/availability-mixin'
import LessonShortcut from '@/features/onboarding-lesson/components/LessonShortcut.vue'
import VideoShortcut from '@/features/onboarding-lesson/components/VideoShortcut.vue'
import FixedMenu from '@components/v2/utilities/fixed-menu.vue'
import { LESSONS } from '@/features/onboarding-lesson/constants'

export default {
  components: {
    LessonShortcut,
    VideoShortcut,
    FixedMenu
  },
  mixins: [AvailabilityMixin],
  data () {
    return {
      LESSONS

    }
  }
}
</script>

<style lang="scss" scoped>
#availability {
  #content.container {
    margin-bottom: 150px;
  }
}
</style>
