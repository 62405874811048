import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

const LOG_TYPE = Object.freeze({
  INFO: 0,
  WARN: 1,
  ERROR: 2
})

const isDatadogAllowed = () => {
  return (process.env.FE_DATADOG_RUM_ENV || process.env.VUE_APP_FE_DATADOG_RUM_ENV) === 'prod' ||
    (process.env.FE_DATADOG_RUM_ENV || process.env.VUE_APP_FE_DATADOG_RUM_ENV) === 'staging'
}

const isDatadogRUMInitialized = () => {
  return window.DD_RUM?.getInternalContext() !== undefined
}

const isDatadogLogInitialized = () => {
  return window.DD_LOGS !== undefined
}

const init = () => {
  if (isDatadogAllowed()) {
    // datadog rum
    datadogRum.init({
      applicationId: process.env.FE_DATADOG_RUM_APP_ID || process.env.VUE_APP_FE_DATADOG_RUM_APP_ID,
      clientToken: process.env.FE_DATADOG_RUM_CLIENT_TOKEN || process.env.VUE_APP_FE_DATADOG_RUM_CLIENT_TOKEN,
      site: process.env.FE_DATADOG_RUM_SITE || process.env.VUE_APP_FE_DATADOG_RUM_SITE,
      service: process.env.FE_DATADOG_RUM_SERVICE_NAME || process.env.VUE_APP_FE_DATADOG_RUM_SERVICE_NAME,
      env: process.env.FE_DATADOG_RUM_ENV || process.env.VUE_APP_FE_DATADOG_RUM_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: process.env.FE_DATADOG_RUM_VERSION || process.env.VUE_APP_FE_DATADOG_RUM_VERSION,
      sessionSampleRate: Number(process.env.FE_DATADOG_RUM_SAMPLE || process.env.VUE_APP_FE_DATADOG_RUM_SAMPLE),
      sessionReplaySampleRate: Number(process.env.FE_DATADOG_RUM_REPLAY_SAMPLE || process.env.VUE_APP_FE_DATADOG_RUM_REPLAY_SAMPLE),
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [(process.env.FE_APP_URL || process.env.VUE_APP_FE_APP_URL), // update
        (process.env.FE_CLIENT_BACKEND_URL || process.env.VUE_APP_FE_CLIENT_BACKEND_URL),
        (process.env.FE_APV_URL || process.env.VUE_APP_FE_APV_URL), process.env.VUE_APP_FE_ADMIN_URL],
      trackSessionAcrossSubdomains: true
    })

    datadogRum.startSessionReplayRecording()

    // datadog logs
    datadogLogs.init({
      clientToken: process.env.FE_DATADOG_RUM_CLIENT_TOKEN || process.env.VUE_APP_FE_DATADOG_RUM_CLIENT_TOKEN,
      site: process.env.FE_DATADOG_RUM_SITE || process.env.VUE_APP_FE_DATADOG_RUM_SITE,
      service: process.env.FE_DATADOG_RUM_SERVICE_NAME || process.env.VUE_APP_FE_DATADOG_RUM_SERVICE_NAME,
      env: process.env.FE_DATADOG_RUM_ENV || process.env.VUE_APP_FE_DATADOG_RUM_ENV,
      version: process.env.FE_DATADOG_RUM_VERSION || process.env.VUE_APP_FE_DATADOG_RUM_VERSION,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
      sessionSampleRate: 100,
      trackSessionAcrossSubdomains: true
    })

    return true
  }
  return false
}

const sendAction = (name, data) => {
  if (isDatadogRUMInitialized()) {
    datadogRum.addAction(name, data)
  }
}

const setUser = (email, role) => {
  if (isDatadogRUMInitialized()) {
    datadogRum.setUser({
      id: email,
      role: role
    })
  }

  if (isDatadogLogInitialized()) {
    datadogLogs.setUser({
      id: email,
      role: role
    })
  }
}

const setAttribute = (key, value) => {
  if (isDatadogRUMInitialized()) {
    datadogRum.setUserProperty(key, value)
  }
}

/**
 * @param {int} type - use LOG_TYPE
* @param {string} name - name of event
* @param {object} data - context data. Up to you
*/
const sendLog = (message, type = LOG_TYPE.INFO, data = {}) => {
  if (isDatadogLogInitialized()) {
    switch (type) {
      case LOG_TYPE.WARN:
        datadogLogs.logger.warn(message, data)
        break
      case LOG_TYPE.ERROR:
        datadogLogs.logger.error(message, data)
        break
      case LOG_TYPE.INFO:
      default:
        datadogLogs.logger.info(message, data)
        break
    }
  }
}

const getRole = (id) => {
  switch (id) {
    case 1:
      return 'client'
    case 2:
      return 'provider'
    case 98:
    case 99:
    case 100:
      return 'admin'
    default:
      return 'unknown: ' + id
  }
}

// get user data when available through storeUserChange event
document.getRootNode().addEventListener('storeUserChange', (evt) => {
  const email = evt.detail.user?.email ?? 'guest'
  const role = getRole(evt.detail.user?.group_id ?? -1)
  setUser(email, role)
})

export default {
  init,
  sendAction,
  setUser,
  setAttribute,
  LOG_TYPE,
  sendLog
}
