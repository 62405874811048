var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal left-align",
      staticStyle: { "max-height": "80%" },
      attrs: { id: "pending-event-modal" }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-content",
          staticStyle: { padding: "32px 12px 2px" }
        },
        [
          _c(
            "h4",
            {
              staticClass: "modal-title center",
              staticStyle: { "font-size": "17px" }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$translate("events.pending_event_popup.title")) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "apv-card" },
            [
              _c("div", { staticClass: "card-header" }, [
                _c("div", { staticClass: "card-header__flex" }, [
                  _c("pre", { staticClass: "date uppercase" }, [
                    _vm._v(_vm._s(_vm.date))
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "grey-text card-header__flex card-header__flex--mid"
                  },
                  [
                    _c("em", { staticClass: "schedule" }, [
                      _c(
                        "span",
                        {
                          staticClass: "schedule__countdown lilac lilac--red",
                          staticStyle: { "font-size": "12px", color: "#757575" }
                        },
                        [
                          _c(
                            "strong",
                            {
                              staticStyle: {
                                color: "#2F3740",
                                "font-size": "16px"
                              }
                            },
                            [_vm._v(_vm._s(_vm.meetingStart))]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$translate("events.labels.for")) +
                              " " +
                              _vm._s(_vm.evt.meeting_duration) +
                              "h\n            "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "location" }, [
                      _vm.isVideoDateMeeting
                        ? _c(
                            "p",
                            { staticClass: "margin-none krypton-purple-text" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate("events.video_date.title")
                                )
                              )
                            ]
                          )
                        : _vm.isPrivatePlaceOrMultiLocationMeeting
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "event-details-hotel-name margin-none"
                            },
                            [
                              _vm.isPrivatePlaceMeeting
                                ? _c(
                                    "i",
                                    {
                                      staticClass: "material-icons",
                                      attrs: {
                                        "data-test": "event-details-pp-icon"
                                      }
                                    },
                                    [_vm._v("person_pin_circle")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isMultiLocationMeeting
                                ? _c("multi-location-event-icon")
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isMultiLocationMeeting
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.evt._city_name))
                                  ])
                                : _c("span", [
                                    _vm._v(_vm._s(_vm.evt._hotel_name))
                                  ])
                            ],
                            1
                          )
                        : _c("p", { staticClass: "margin-none" }, [
                            _vm._v(_vm._s(_vm.evt._hotel_name))
                          ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "card-header__flex" }, [
                  _c(
                    "strong",
                    { staticClass: "amount" },
                    [
                      _c("currency"),
                      _vm._v(_vm._s(_vm.evt.amount_payable) + "\n            "),
                      _vm.isMultiLocationMeeting
                        ? _c(
                            "div",
                            [
                              _vm._v("+ "),
                              _c("currency"),
                              _vm._v(_vm._s(_vm.evt.travel_cost))
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.isMultiLocationMeeting
                ? _c("multi-location-event-address", {
                    attrs: { event: _vm.evt }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "card-content" }, [
                _c("div", { staticClass: "row controls" }, [
                  _c("strong", { staticClass: "expiration-time" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$translate("events.labels.expires")) +
                          " " +
                          _vm._s(_vm.acceptCountdown)
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("label", { staticStyle: { "font-size": "12px" } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$translate("events.labels.acceptance_note")
                        ) +
                        "\n          "
                    )
                  ])
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("p", {
            staticClass: "grey-text text-darken-1 center",
            staticStyle: { "font-size": "14px" }
          })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn-flat waves-effect waves-dark modal-close",
            on: { click: _vm.closeModal }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$translate("events.buttons.stay_here")) +
                "\n    "
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn-flat waves-effect waves-dark krypton-pink-text",
            on: { click: _vm.confirm }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$translate("events.buttons.respond_now")) +
                "\n    "
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }