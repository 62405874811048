<template>
  <div id="help">
    <md-new-navbar />

    <div id="content" class="container">
      <h2>{{ $translate('register.photos.others.help') }}</h2>

      <div class="help-icon">
        <img class="responsive-img" src="/img/help/help.svg" alt="help">
      </div>

      <ul>
        <li id="faq-btn" class="faq">
          <a href="#" class="faq-link" @click.prevent="gotoPage('faq')">
            <strong>FAQ</strong>
          </a>
        </li>

        <li id="contact-us-btn" class="contact-us">
          <a href="#" class="contact-us-link" @click.prevent="gotoPage('support-v2')">
            <strong>{{ $translate('support.contact_us') }}</strong>
          </a>
        </li>

        <li id="feedback-btn" class="feedback">
          <a href="#" class="feedback-link" @click.prevent="gotoPage('app-feedback')">
            <strong>{{ $translate('support.tell_us_more') }}</strong>
          </a>
        </li>
      </ul>

    </div>
  </div>
</template>

<script>

export default {
  methods: {
    gotoPage (name) {
      this.$router.push({ name })
    }
  }
}
</script>

<style lang="scss" scoped>

#help {
  background-color: white;
  width: 100%;
  height: 100dvh;

    #content.container {
        padding-top: 0;
        background-color: white;
    }

    h2 {
        color: #2F3740;
        font-size: 26px;
        line-height: 32px;
        margin: 5px 0 0 0;
        text-transform: capitalize;
    }

    .help-icon {
        margin: 32px auto 41px auto;
    }

    li {
        border-radius: 16px;
        color: #ffffff;
        display: inline-block;
        height: 56px;
        margin-bottom: 16px;
        text-align: center;
        width: 100%;

        a {
            color: #ffffff;
            display: block;
            font-size: 22px;
            line-height: 32px;
            padding: 11px;
        }
    }

    .quick-start { background: transparent linear-gradient(262deg, #7638FA 0%, #D300C5 100%) 0% 0% no-repeat padding-box; }
    .faq { background: transparent linear-gradient(261deg, #FF0073 0%, #DC0064 100%) 0% 0% no-repeat padding-box; }
    .contact-us { background: transparent linear-gradient(261deg, #FF0073 0%, #DC0064 100%) 0% 0% no-repeat padding-box; }
    .feedback { background: #2F3740 0% 0% no-repeat padding-box; }

}

</style>
