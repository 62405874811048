<template>
  <div id="inbox-list-item" class="card" @click.prevent="goToType">
    <div class="card-content">
      <div class="card-header">
        <h5 v-if="isKryptonConnectType" class="type">
          <i class="mdi valign-middle mdi-forum" /> {{ $translate('inbox.inbox_item.krypton_connect.title') }}
        </h5>
        <h5 v-else-if="isReviewType" class="type">
          {{ $translate('inbox.inbox_item.review.title', { name: computedSenderName, age: computedSenderAge }) }}
        </h5>
        <h5 v-else-if="isPhotoLikeType" class="type">
          <i class="mdi valign-middle mdi-image" /> {{ $translate('inbox.inbox_item.photo_like.title') }}
        </h5>
        <h5 v-else-if="isFavoriteLikeType" class="type">
          <i class="mdi valign-middle mdi-image" /> {{ $translate('inbox.inbox_item.favorite_like.title') }}
        </h5>
        <span class="datetime valign-middle" :class="{ 'lilac-left lilac--red': hasNotification }">{{ lastUpdate }}</span>
      </div>
      <div v-if="isReviewType" class="review-rating left-align">
        <i v-for="n in MAX_RATING"
           :key="n"
           class="mdi"
           :class="ratingIconClass(n, rating)"
        />
      </div>
      <p v-if="isKryptonConnectType" class="left-align">
        {{ $translate('inbox.inbox_item.krypton_connect.content', { client_details: computedSenderDetails }) }}
      </p>
      <div v-else-if="isReviewType">
        <p v-if="showReview" class="left-align">{{ content }}</p>
        <div v-else class="message">
          <span class="blur-content">
            Curabitur eleifend felis a hendrerit ultricies. Donec at semper justo, et hendrerit metus. Fusce et tellus sapien. Duis nulla enim, egestas ac imperdiet ut, vulputate pellentesque turpis.
          </span>
          <a id="feedback-hint" href="#" @click.prevent="showHint">
            {{ $translate('feedback.cannot_see_comment_text') }}
          </a>
        </div>
      </div>
      <p v-else-if="isPhotoLikeType" class="left-align">
        {{ $translate('inbox.inbox_item.photo_like.content', { client_details: computedSenderDetails }) }}
      </p>
      <p v-else-if="isFavoriteLikeType" class="left-align">
        {{ $translate('inbox.inbox_item.favorite_like.content', { client_details: computedSenderDetails }) }}
      </p>
      <div class="card-footer">
        <div class="left-content left-align">
          <span v-if="isKryptonConnectType">
            {{ $translate('inbox.inbox_item.krypton_connect.message_count', { count: messageCount }) }}
          </span>
        </div>
        <a v-if="isKryptonConnectType || isLikeType" href="#" class="cta-button padding-sm waves-effect waves-dark" @click.prevent="onClick">
          <strong>{{ $translate('inbox.cta_buttons.reply') }}</strong>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { INBOX_TYPE, INBOX_LIKE_TYPE, CLIENT_DEFAULT } from '@/features/inbox/constants'

import ControllerMixin from './InboxListItem.controller'

export default {
  mixins: [ControllerMixin],
  props: {
    id: {
      type: Number,
      default: 0
    },
    tokenID: {
      type: Number,
      default: 0
    },
    clientID: {
      type: Number,
      default: 0
    },
    providerID: {
      type: Number,
      default: 0
    },
    messageCount: {
      type: Number,
      default: 0
    },
    hasNotification: {
      type: Number,
      default: 0
    },
    createdAt: {
      type: String,
      default: ''
    },
    updatedAt: {
      type: String,
      default: ''
    },
    type: {
      type: Number,
      default: INBOX_TYPE.KRYPTON_CONNECT
    },
    likeType: {
      type: Number,
      default: INBOX_LIKE_TYPE.PHOTO
    },
    rating: {
      type: Number,
      default: 0
    },
    senderName: {
      type: String,
      default: ''
    },
    senderAge: {
      type: Number,
      default: 0
    },
    subject: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    showReview: {
      type: Number,
      default: 0
    }
  },
  computed: {
    computedSenderDetails () {
      if (this.computedSenderName == CLIENT_DEFAULT.NAME) {
        return `${this.computedSenderName}`
      } else {
        return `${this.computedSenderName}, ${this.computedSenderAge}`
      }
    },
    computedSenderName () {
      if (this.isLikeType && this.senderName === null) {
        return CLIENT_DEFAULT.NAME
      }

      if (this.senderName === null || this.senderName.length === 0) {
        return CLIENT_DEFAULT.NAME
      }

      return this.senderName
    },
    computedSenderAge () {
      if (this.isLikeType && this.senderName === null) {
        return CLIENT_DEFAULT.AGE
      }

      if (this.senderAge === 0) {
        return CLIENT_DEFAULT.AGE
      }

      return this.senderAge
    }
  }
}
</script>

<style lang="scss" scoped>
@import "InboxListItem.style";
</style>
