var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal center-align",
      staticStyle: { "max-height": "80%" },
      attrs: { id: "video-date-instruction" }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-content",
          staticStyle: { padding: "12px 12px 2px" }
        },
        [
          _c("p", { staticClass: "center" }, [
            _c(
              "video",
              {
                attrs: {
                  autoplay: "",
                  height: "312",
                  muted: "",
                  loop: "",
                  playsinline: ""
                },
                domProps: { muted: true }
              },
              [
                _c("source", {
                  attrs: {
                    src: "/img/video-date/video-date-steps.webm",
                    type: "video/webm"
                  }
                }),
                _vm._v(" "),
                _c("source", {
                  attrs: {
                    src: "/img/video-date/video-date-steps.m4v",
                    type: "video/mp4"
                  }
                }),
                _vm._v(
                  "\n        Your browser does not support the video tag.\n      "
                )
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-footer",
          staticStyle: { "border-top": "1px solid #E1E6EB" }
        },
        [
          _c(
            "a",
            {
              staticClass: "btn-flat waves-effect waves-dark krypton-pink-text",
              staticStyle: { width: "100%", "text-align": "center" },
              attrs: { href: "#" },
              on: { click: _vm.confirm }
            },
            [
              _c("strong", [
                _vm._v(
                  _vm._s(this.$translate("events.closed_event_popup.button"))
                )
              ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }