<template>
    <div class="modal left-align">
        <div class="modal-content" style="padding: 24px;">
            <h4 class="modal-title center" style="font-size: 20px;" v-if="title">
                {{ title }}
            </h4>

            <p class="grey-text text-darken-1 center" style="font-size: 14px;">
                {{ content }}
            </p>
        </div>
        <div class="modal-footer" style="border-top: 1px solid #E1E6EB; text-align: center;">
            <a href="#" class="btn-flat waves-effect waves-dark krypton-pink-text" @click.prevent="confirm">
                <strong>{{ confirmText || $translate('app.ok_understand') }}</strong>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'title',
        'content',
        'confirmText'
    ],
    mounted() {
        if (!this.confirmText) {
            this.confirmText = this.$translate('app.ok_understand');
        }
    },
    methods: {
        confirm($e) {
            this.$emit('confirm', $e);
        }
    }
}
</script>
