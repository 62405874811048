<template>
  <div id="event-declined">
    <div v-if="!isFinishScreen"
         class="padding-x-xl right-align white"
         style="height: 48px; padding-top: 12px;">
      <div class="container">
        <span id="event-decline-close-btn"
              class="close grey-text text-darken-1"
              @click.prevent="$router.back()">
          <i class="material-icons">close</i>
        </span>
      </div>
    </div>

    <event-declined-not-available
      v-if="isNotAvailableScreen"
      :client-name="selectedEvent.client.name"
      :event-date="eventDate"
      :step="step"
      :error_form="error_form"
      @ondeclineblacklist="notComfortable">
      <template v-slot:input>
        <div v-if="error_form.not_available"
             id="event-declined-not-available-error-form"
             class="grey darken-1 padding-md border-rad-sm margin-bottom-md left-align">
          <small class="white-text">{{ $translate('events.decline_popup.not_available_error', { client_name: selectedEvent.client.name }) }}</small>
        </div>
        <md-textarea
          id="event-declined-not-available-textarea"
          v-model="message"
          class="textfield-box-pink textfield-box-large textfield-label-pink not-comfortabe-message"
          type="multiline"
          :force-active="true"
          :maxlength="notAvailableMaxChar"
          :label="$translate('events.decline_popup.not_available_textarea_label')"
          :placeholder="$translate('events.decline_popup.not_available_textarea_placeholder', { client_name: selectedEvent.client.name })">
          <div>
            <span class="input-helper grey-text text-darken-1 right">
              {{ getNotAvailableMaxChar(message) }}
            </span>
          </div>
        </md-textarea>
      </template>
    </event-declined-not-available>

    <event-declined-uncomfortable
      v-else-if="isNotComforableScreen"
      :client-name="selectedEvent.client.name"
      :error_form="error_form">
      <template v-slot:input>
        <div v-if="error_form.not_comfortable"
             id="event-declined-uncomfortable-error-form"
             class="grey darken-1 padding-md border-rad-sm margin-bottom-md left-align">
          <small class="white-text">{{ $translate('events.decline_popup.not_comfortable_error') }}</small>
        </div>
        <md-textarea
          id="event-declined-uncomfortable-textarea"
          v-model="message"
          class="textfield-box-pink textfield-box-large textfield-label-pink"
          type="multiline"
          :force-active="true"
          :maxlength="answerMaxChar"
          :label="$translate('events.decline_popup.not_comfortable_textarea_label')"
          :placeholder="$translate('events.decline_popup.not_comfortable_textarea_placeholder')">
          <div>
            <span class="input-helper grey-text text-darken-1 right">
              {{ getMaxChar(message) }}
            </span>
          </div>
        </md-textarea>
        <p class="margin-none">
          <input id="agree" v-model="agree" class="mdi-checkbox-pink" type="checkbox">
          <label for="agree" style="color: #2E353D; font-size: 14px;">{{ $translate('events.decline_popup.not_comfortable_agree') }}</label>
        </p>
      </template>
    </event-declined-uncomfortable>

    <div v-if="isFinishScreen" id="event-declined-finish-step" class="declined-page">
      <div id="heading" class="white-text padding-x-md padding-y-x3" style="background: #8995A3;">
        <div class="container">{{ $translate('events.decline_popup.finish_title') }}</div>
        <i class="cancel-icon material-icons">cancel</i>
      </div>
      <div class="container padding-xl padding-x-xl padding-y-x3">
        <p>
          {{
            answer == 1 ? $translate('events.decline_popup.finish_note_1') : $translate('events.decline_popup.finish_note_2')
          }}
        </p>
        <button
          id="event-declined-finish-btn"
          class="btn btn-full btn-large margin-top-xl waves-effect waves-dark"
          @click.prevent="$router.replace({ name: 'events' });">
          {{ $translate('events.buttons.go_to_dashboard') }}
        </button>
      </div>
    </div>

    <div v-if="!isFinishScreen" class="container center navigator margin-bottom-xl" style="padding: 0 32px;">
      <button
        id="event-declined-prevstep-btn"
        class="btn btn-large btn-secondary waves-effect waves-dark display-inline-block"
        @click="prevStep">
        {{ $translate('events.buttons.back') }}
      </button>
      <button
        id="event-declined-nextstep-btn"
        class="btn btn-large btn-krypton-pink waves-effect waves-light display-inline-block"
        @click="nextStep">
        <span v-if="!isSending">{{ nextStepText }}</span>
        <div v-else class="preloader-wrapper small active">
          <div class="spinner-layer spinner-green-only">
            <div class="circle-clipper left">
              <div class="circle" />
            </div><div class="gap-patch">
              <div class="circle" />
            </div><div class="circle-clipper right">
              <div class="circle" />
            </div>
          </div>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import EventDeclinedUncomfortable from '../components/event-declined/event-declined-uncomfortable'
import EventDeclinedNotAvailable from '../components/event-declined/event-declined-not-available'

import NullSelectCheck from '../mixins/nullSelectCheck'
import eventDeclinedMixin from '../mixins/eventDeclined.mixin'

export default {
  name: 'EventDeclined',
  components: {
    EventDeclinedUncomfortable,
    EventDeclinedNotAvailable
  },
  mixins: [NullSelectCheck, eventDeclinedMixin],
  mounted () {
    // We'll do a little hack for background color because of mobile bug on keyboard
    document.getElementsByTagName('body')[0].classList.add('dirty-white-bg')
    document.getElementsByTagName('html')[0].classList.add('dirty-white-bg')
  },
  beforeDestroy () {
    document.getElementsByTagName('body')[0].classList.remove('dirty-white-bg')
    document.getElementsByTagName('html')[0].classList.remove('dirty-white-bg')
  }
}
</script>

<style lang="scss" scoped>
@import "./event-declined.style";
</style>
