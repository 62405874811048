var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "email-verify", attrs: { id: "account-setting" } },
    [
      !_vm.isVerified ? [_vm._v("Verifying email...")] : _vm._e(),
      _vm._v(" "),
      _vm.isVerified
        ? _c("div", { staticClass: "email-verify__content" }, [
            _c("img", {
              attrs: { src: "/img/icons/k-logo-new.svg", alt: "krypton logo" }
            }),
            _vm._v(" "),
            _c("h2", { staticClass: "email-verify__title" }, [
              _vm._v("Success!")
            ]),
            _vm._v(" "),
            _c("img", {
              attrs: {
                width: "60",
                src: "/img/icons/ic-check-circle-v2.svg",
                alt: "check icon"
              }
            }),
            _vm._v(" "),
            _c("p", { staticClass: "email-verify__subtitle" }, [
              _vm._v("Now you can return to the App")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "email-verify__text" }, [
              _vm._v(
                "Your new email address has been saved. Please go back to KryptonApp now."
              )
            ])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }