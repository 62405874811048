var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "super-provider-prerequire-card margin-bottom-lg",
      attrs: { id: "promo-card" }
    },
    [
      _c("div", { staticClass: "super-provider-prerequire-card__body" }, [
        _c(
          "h2",
          {
            staticClass: "super-provider-prerequire-card__body-title",
            attrs: { "data-test": "title" }
          },
          [
            _c("img", {
              attrs: {
                src: "/img/super-provider/super-provider-diamond.svg",
                "data-test": "icon"
              }
            }),
            _vm._v(" "),
            _c("span", {
              domProps: { textContent: _vm._s(_vm.$t("body.title.super")) }
            }),
            _vm._v(" "),
            _c("span", {
              domProps: { textContent: _vm._s(_vm.$t("body.title.provider")) }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "super-provider-prerequire-card__body-status",
            attrs: { "data-test": "status" }
          },
          [
            _c(
              "i",
              {
                staticClass: "material-icons",
                class: { "material-icons--super-provider": _vm.isSuperProvider }
              },
              [_vm._v(_vm._s(_vm.statusIcons))]
            ),
            _vm._v(" "),
            _c("span", { domProps: { textContent: _vm._s(_vm.statusText) } })
          ]
        ),
        _vm._v(" "),
        _c("p", {
          staticClass: "super-provider-prerequire-card__body-text",
          attrs: { "data-test": "description" },
          domProps: { textContent: _vm._s(_vm.contentText) }
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "super-provider-prerequire-card__body-next-review",
          attrs: { "data-test": "next-review" },
          domProps: { innerHTML: _vm._s(_vm.nextReviewText) }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "super-provider-prerequire-card__footer",
          attrs: { "data-test": "collapse" }
        },
        [
          _c(
            "div",
            { staticClass: "super-provider-prerequire-card__footer-header" },
            [
              _c("span", {
                domProps: { textContent: _vm._s(_vm.$t("benefits.title")) }
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.expandBenefits($event)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v(_vm._s(_vm.benefitsIcons))
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "ul",
            {
              staticClass: "super-provider-prerequire-card__footer-list",
              class: { expanded: _vm.benefitsListExpanded }
            },
            _vm._l(_vm.$t("benefits.items"), function(item, key) {
              return _c("li", { key: key }, [
                _c("h3", [
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v("check_circle")
                  ]),
                  _c("span", { domProps: { textContent: _vm._s(item.title) } })
                ]),
                _vm._v(" "),
                _c("p", { domProps: { textContent: _vm._s(item.content) } })
              ])
            }),
            0
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }