<template>
  <div id="performance-overview" class="card">
    <div class="section-label">
      <img src="/img/dashboard/dashboard-performance-icon.svg" alt="tips and infos icon">
      <h2>{{ $translate('provider_performance.overview.title') }}</h2>
    </div>
    <a href="#"
       class="cta-button padding-sm waves-effect waves-dark margin-bottom-lg"
       :class="{
         'feature-lock-indicator': isFeatureLocked
       }"
       @click.prevent="gotoPerformancePage">
      <strong v-if="!isFetchingData">{{ $translate('provider_performance.overview.all_stats_btn') }}</strong>
      <strong v-else>{{ $translate('app.fetching_data') }}</strong>
    </a>
    <performance-rating
      :rating="overallRatings.totalRating"
      :rank="overallRatings.rank"
      :is-top-rating="isTopRating"
    />
    <hr>
    <section class="sub-ratings">
      <reliability-rating :rating="overallRatings.reliability" />
      <client-rating :rating="overallRatings.client" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { LITE_EVENTS } from '@/features/lite/constants'

import { fetchOverallRating } from '@/features/provider-performance/utils/RatingAPI'

export default {
  data: () => ({
    overallRatings: {
      totalRating: 0,
      reliability: 0,
      client: 0,
      rank: null
    },
    isTopRating: false,
    isFetchingData: false
  }),
  computed: {
    ...mapGetters({
      isCandidateProfile: 'isCandidateProfile'
    }),
    isFeatureLocked () {
      return this.isCandidateProfile
    }
  },
  mounted () {
    if (!this.isFeatureLocked) {
      this.fetchData()
    }
  },
  methods: {
    gotoPerformancePage () {
      if (this.isFeatureLocked) {
        $h.EventBus.$emit(LITE_EVENTS.ONFEATURELOCK)
      } else {
        this.$router.push({ name: 'feedback' })
      }
    },
    setData (rating) {
      this.overallRatings = { ...rating }
    },
    fetchData () {
      this.isFetchingData = true

      fetchOverallRating()
        .then(this.setData)
        .finally(() => {
          this.isFetchingData = false
        })
    }
  }
}
</script>

<style lang="scss">
@import "./PerformanceOverview.style.scss";
</style>
