<template>
  <div id="feedback" class="page">
    <md-new-navbar />
    <div class="header-content center">
      <h1 class="title center">{{ $translate('feedback.title') }}</h1>
      <div class="review-rating">
        <i v-for="n in MAX_RATING"
           :key="n"
           class="mdi"
           :class="ratingIconClass(n, totalRating)"
        />
      </div>
      <div class="rate valign-wrapper">
        {{ totalRating }}/{{ MAX_RATING }}
      </div>
      <div class="reviews-counter margin-left-sm krypton-pink-text">
        {{ totalReviews }}
      </div>
    </div>
    <div id="content" class="container">
      <div v-for="(review, idx) in reviews"
           v-if="!isFetching"
           :key="idx"
           class="card-panel left-align">
        <div class="card-header flex-grid">
          <div class="flex-1">
            <strong style="vertical-align: middle;">{{ review.client_name }}</strong>
            <span v-if="review.private_review"
                  class="is-private" style="vertical-align: middle;">(Private)</span>
          </div>
          <div class="flex-1 right-align date">
            {{ displayDateCreated(review.created_at) }}
          </div>
        </div>
        <div class="card-content">
          <div class="review-rating">
            <i v-for="n in MAX_RATING"
               :key="n"
               class="mdi"
               :class="ratingIconClass(n, review.client_rating)"
            />
          </div>
          <div v-if="review.show_review === 1" class="message">
            {{ review.client_review }}
          </div>
          <div v-else class="message">
            <span class="blur-content">
              Curabitur eleifend felis a hendrerit ultricies. Donec at semper justo, et hendrerit metus. Fusce et tellus sapien. Duis nulla enim, egestas ac imperdiet ut, vulputate pellentesque turpis.
            </span>
            <a href="#" @click.prevent="showHint">
              {{ $translate('feedback.cannot_see_comment_text') }}
            </a>
          </div>
        </div>
      </div>
      <div v-if="reviews.length === 0 && !isFetching" class="card-panel">
        {{ $translate('feedback.empty_feedback') }}
      </div>
      <div v-if="reviews.length === 0 && isFetching" class="card-panel">
        {{ $translate('feedback.fetch_feedback') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { LITE_EVENTS } from '@/features/lite/constants'

const LocaleProvider = $h.getProvider('LocaleProvider')

const MAX_RATING = 5

export default {
  data: () => ({
    MAX_RATING,
    fallbackRoute: {
      name: 'dashboard',
      title: 'sidenav.dashboard'
    },
    previousLink: '',
    previousTitle: ''
  }),
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from && from.name === 'feedback') {
        vm.previousLink = { name: from.name }
        vm.previousTitle = from.name
      } else {
        vm.previousLink = { name: vm.fallbackRoute.name }
        vm.previousTitle = vm.$translate(vm.fallbackRoute.title)
      }
    })
  },
  computed: {
    ...mapGetters({
      reviews: 'feedbacks/GET_REVIEWS',
      totalRating: 'feedbacks/GET_TOTAL_RATING',
      totalFeedback: 'feedbacks/GET_TOTAL_FEEDBACK',
      hasFeedbackUpdates: 'notification/GET_HAS_NEW_FEEDBACK',
      isFetching: 'feedbacks/GET_IS_FETCHING',
      isCandidateProfile: 'isCandidateProfile'
    }),
    totalReviews () {
      if (this.totalFeedback > 1) {
        if (this.totalFeedback > 10) { return `${this.totalFeedback}+ Reviews` }
        return `${this.totalFeedback} Reviews`
      }
      return `${this.totalFeedback} Review`
    }
  },
  watch: {
    isCandidateProfile () {
      if (this.isCandidateProfile) {
        this.$router.replace({ name: 'dashboard' })
        $h.EventBus.$emit(LITE_EVENTS.ONFEATURELOCK)
      }
    }
  },
  mounted () {
    if (this.hasFeedbackUpdates) {
      this.$store.dispatch('notification/SEEN_FEEDBACK_NOTIFICATION')
    }
  },
  methods: {
    displayDateCreated (date) {
      return moment(date).locale(LocaleProvider.getLocale())
        .format('ddd, DD MMM')
        .replace('.', '')
    },
    showHint () {
      $h.confirmOnlyModal(this.$translate('feedback.cannot_see_comment_explanation'))
    },
    ratingIconClass (count, compare) {
      const diff = compare - count

      if (diff >= 0) {
        return { 'mdi-circle': true }
      } else if (diff > -1 && diff < 0) {
        return { 'mdi-circle-half-full': true }
      } else {
        return { 'mdi-circle-outline': true }
      }
    }
  }
}
</script>
