import { buildParam } from '@/helpers/api'

export function sendSMSLink () {
  return new Promise((resolve, reject) => {
    window.api
      .put({ url: buildParam('/v2/register/sms-link-apv') },
        (success) => { resolve(success) },
        (err) => {
          window.NotificationPlugin.toast(err.response.data.message, 30000)
          reject(err)
        })
  })
}
