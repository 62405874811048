<template>
  <div id="logout">
    Logging out...
  </div>
</template>


<script>
const AuthProvider = $h.getProvider('AuthProvider');

export default {
  mounted() {
      AuthProvider.logout(() => {
          AuthProvider.setAuthorizationHeader();
          this.$router.push({ name: 'login' });
          this.$store.commit('reset');
          this.$store.commit('billing/reset');
          this.$store.commit('events/RESET');
      });
  },
}
</script>
