var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isViewReady
    ? _c(
        "div",
        { attrs: { id: "event-checkin" } },
        [
          _c("md-navbar-event", {
            attrs: {
              icon: "arrow_back",
              push: { name: "events" },
              "show-currency": false,
              brand: _vm.$translate("events.nav_title")
            }
          }),
          _vm._v(" "),
          _c("div", { attrs: { id: "content" } }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "timer" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("h2", { staticClass: "countdown-timer" }, [
                  _vm._v(_vm._s(_vm.countdown))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "details" }, [
                  _c("div", { staticClass: "hours" }, [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("schedule")
                    ]),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.meetingDuration) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "rate" }, [
                    _c("i", { staticClass: "icon-payment white-icon" }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.amountPayable) +
                        "\n          "
                    )
                  ])
                ]),
                _vm._v(" "),
                !_vm.isAddExtension && _vm.canAddMoreExtension
                  ? _c("div", { staticClass: "add-extension" }, [
                      _c(
                        "a",
                        {
                          staticClass: "white-text",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.isAddExtension = true
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$translate("events.checkin.add_extension_btn")
                            )
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _vm.isAddExtension
              ? _c("div", { staticClass: "time-extension" }, [
                  _c("div", { staticClass: "time-extension-controls" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn subtract-time btn-krypton-pink waves-effect waves-light",
                        attrs: { id: "event-checkin-decrement-ext" },
                        on: { click: _vm.decrementExtension }
                      },
                      [_vm._v("−")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "added-time" }, [
                      _vm._v("+" + _vm._s(_vm.hrExtension))
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn add-time btn-krypton-pink waves-effect waves-light",
                        attrs: { id: "event-checkin-increment-ext" },
                        on: { click: _vm.incrementExtension }
                      },
                      [_vm._v("+")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "time-extension-details" }, [
                    _c("div", { staticClass: "hours" }, [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("schedule")
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.computedHrExtension) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "rate" }, [
                      _c("i", { staticClass: "icon-payment" }),
                      _vm._v(
                        "\n          " + _vm._s(_vm.computedFee) + "\n        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$translate("events.checkin.extension_note", {
                          meeting_duration: _vm.meetingDuration
                        })
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "controls" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-large btn-secondary btn-full",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.isAddExtension = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$translate("events.checkin.cancel")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-large btn-full btn-krypton-pink waves-effect waves-light",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.extendNow($event)
                          }
                        }
                      },
                      [
                        !_vm.isSending
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate("events.checkin.extend_now")
                                )
                              )
                            ])
                          : _c(
                              "div",
                              { staticClass: "preloader-wrapper small active" },
                              [_vm._m(1)]
                            )
                      ]
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.isAddExtension
              ? _c(
                  "div",
                  { staticClass: "container" },
                  [
                    _c("md-slide-to-unlock", {
                      attrs: {
                        min: 0,
                        max: 100,
                        label: _vm.$translate("events.checkin.slide_check_out"),
                        disabled: false,
                        "is-reversed": true,
                        "is-sending": _vm.isSending
                      },
                      model: {
                        value: _vm.pulleeVal,
                        callback: function($$v) {
                          _vm.pulleeVal = $$v
                        },
                        expression: "pulleeVal"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "get-support-link grey-text",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onClickSupport($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$translate("events.checkin.get_support"))
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "countdown-icon" }, [
      _c("img", {
        staticClass: "responsive-img",
        attrs: { src: "/img/events/ic-countdown.svg", alt: "countdown" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner-layer spinner-green-only" }, [
      _c("div", { staticClass: "circle-clipper left" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gap-patch" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "circle-clipper right" }, [
        _c("div", { staticClass: "circle" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }