<template>
  <div id="super-provider-list-item" data-test="super-provider-promo-card" @click="gotoSuperProviderPromoPage">
    <div class="left-panel">
      <div class="promo-title">{{ $t('card.title') }}</div>
      <div class="promo-subtitle">{{ $t('card.subtitle') }}</div>
      <div class="promo-cta">{{ $t('card.cta') }}</div>
    </div>
    <div class="right-panel">
      <img :src="item.image">
    </div>
  </div>
</template>

<i18n src="@/features/super-provider/translations/banners.json"></i18n>

<script>
import { putSuperProviderSettingsSeen } from '@/features/super-provider/services/SuperProviderService.js'
import { SP_SETTINGS_SEEN_FIELD_TYPES } from '@/features/super-provider/constants/super-provider-defaults.js'

export default {
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({
        image: 'img/super-provider/promo-image-icon.svg'
      })
    }
  },
  methods: {
    gotoSuperProviderPromoPage () {
      return putSuperProviderSettingsSeen({ field: SP_SETTINGS_SEEN_FIELD_TYPES.PROMO })
        .then(data => {
          if (data && data.data) {
            this.$store.dispatch('getUserDetails')
            this.$router.push({ name: 'super-provider-overview' })
          }
        })
        .catch(err => this.handleErrorMessages(err))
    },
    handleErrorMessages (err = ({ message: '' })) {
      this.$notifications.toast(err?.message, 5000)
    }
  }
}
</script>

<style lang="scss" scoped>
#super-provider-list-item {
  background: transparent linear-gradient(68deg, #845BE7 0%, #608AFF 100%) 0% 0% no-repeat padding-box;
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 4px;

  .left-panel {
    text-align: left;
  }

  .right-panel {
    padding-left: 16px;
    display: flex;
    align-items: flex-start;
  }

  .promo-title {
    line-height: 1.5rem;
  }

  .promo-subtitle {
    padding-top: 4px;
    line-height: 1.5rem;
    font-size: 1.5rem;
    font-weight: normal;
  }

  .promo-cta {
    padding-top: 16px;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
  }
}
</style>
