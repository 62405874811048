<template>
  <img :src="source">
</template>

<script>
export default {
  props: {
    grey: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    source () {
      if (this.grey) {
        return '/img/multi-location/icon_grey_24dp.svg'
      }

      return '/img/multi-location/icon_black_24dp.svg'
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  margin-right: 4px;
}
</style>
