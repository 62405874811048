var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "motto" } },
    [
      _c("md-new-navbar", { attrs: { "route-name": "profile" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container", attrs: { id: "content" } },
        [
          _c("h1", { staticClass: "title" }, [
            _vm._v(
              _vm._s(
                _vm.hasMotto
                  ? _vm.$translate("motto.header")
                  : _vm.$translate("motto.header_add")
              )
            )
          ]),
          _vm._v(" "),
          _c("img", {
            staticClass: "motto-img",
            attrs: { src: "/img//motto.svg", alt: "motto" }
          }),
          _vm._v(" "),
          _c(
            "vform",
            { ref: "form", on: { submit: _vm.save } },
            [
              _c(
                "md-textarea",
                {
                  attrs: {
                    rows: "10",
                    type: "multiline",
                    maxlength: _vm.mottoMaxChar,
                    label: _vm.$translate("motto.motto")
                  },
                  model: {
                    value: _vm.motto,
                    callback: function($$v) {
                      _vm.motto = $$v
                    },
                    expression: "motto"
                  }
                },
                [
                  _c("div", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("motto"),
                            expression: "errors.has('motto')"
                          }
                        ],
                        staticClass: "input-helper red-text center-align"
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.first("motto")) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "right input-helper grey-text text-darken-1"
                      },
                      [_vm._v(_vm._s(_vm.mottoCharLimit))]
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("h-footer-btns-apv-redesign", {
            attrs: {
              cancel: _vm.$translate("app.cancel"),
              disabled: _vm.isMottoEmpty,
              confirm: _vm.$translate("app.save")
            },
            on: {
              cancel: function($event) {
                return _vm.$router.push({ name: "profile" })
              },
              confirm: _vm.confirm
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }