<template>
  <div class="reactivation">
    <div id="content" class="container">
      <h2 data-test="reactivation-title" class="title">{{ title }}</h2>
      <img data-test="reactivation-image" class="icon" src="/img/reactivation/lock.svg" alt="lock icon">

      <div data-test="reactivation-explanation-text" class="description-box">
        <strong>{{ subtitle }}</strong>
        <p>
          {{ text }}
        </p>
        <button
          data-test="reactivation-cta"
          class="cta btn btn-full waves-effect waves-dark"
          :disabled="isFormSubmitting"
          @click.prevent="reactivateAccount"
        >{{ cta }}</button>
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/registration/pages/reactivation/translations/reactivation.json"></i18n>

<script>
import { reactivation } from '@/services/ReactivationService.js'

export default {
  data: () => ({
    isFormSubmitting: false
  }),
  computed: {
    title () {
      return this.$t('main.title')
    },
    subtitle () {
      return this.$t('main.subtitle')
    },
    text () {
      return this.$t('main.text')
    },
    cta () {
      return this.$t('main.cta')
    }
  },
  beforeDestroy () {
    this.cleanUpLocalStorage()
  },
  methods: {
    async cleanUpLocalStorage () {
      await window.localforage.removeItem('contact_detail')
    },
    async reactivateAccount () {
      if (this.isFormSubmitting) return false

      this.isFormSubmitting = true

      const contactDetail = await localforage.getItem('contact_detail')
      const payload = { contact_detail: contactDetail }
      reactivation(payload)
        .then(() => {
          this.cleanUpLocalStorage()
          this.isFormSubmitting = false // Intentionally placed this inside .then for the test
          this.$router.replace({ name: 'reactivation-success' })
        })
        .catch(err => {
          console.log(err)
          this.isFormSubmitting = false // Intentionally placed this inside .catch for the test
        })
    }
  }
}
</script>

<style lang="scss" style="scoped">
.reactivation {
  height: 100vh;
  background-color: white;

  > #content {
    background-color: white !important;
    max-width: 376px;
    padding: 32px;
  }

  h2.title {
    font-size: 26px;
    padding: 0 2rem;
  }

  .description-box {
    width: 100%;
    border: 1px solid #CED6E0;
    border-radius: 8px;
    padding: 24px;

    strong {
      font-size: 18px;
      padding: 0 1rem;
    }
  }

  .icon {
    margin-top: 18px;
    margin-bottom: 32px;
  }

  .cta {
    font-family: 'Proxima Nova Semibold';
    background: transparent linear-gradient(270deg, #E61472 0%, #B30F59 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 2px #0000003D;
    border-radius: 8px;
    height: 44px;
    font-size: inherit;
  }
}
</style>
