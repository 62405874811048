var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "photos" } }, [
    _c(
      "div",
      { staticClass: "wrapper wrapper_fixed", attrs: { id: "anonimize" } },
      [
        _c("div", { staticClass: "content__area overlay" }, [
          _c("img", {
            attrs: {
              id: "k-logo",
              src: "/img/registration/k-logo.svg",
              alt: "k-logo"
            }
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "content__title content__title--desktop" }, [
            _vm._v(_vm._s(_vm.$translate("photos.anonimize")))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "content__area drawer --with-photo-content --with-both-bars-crop no-y-padding"
          },
          [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "div",
                  {
                    staticClass: "top-bar--drawer",
                    staticStyle: {
                      "border-bottom": "none",
                      "justify-content": "space-between"
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.triggerCancelUpload($event)
                          }
                        }
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons",
                            staticStyle: { "margin-right": "8px" }
                          },
                          [_vm._v("close")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "cancel-upload-label" }, [
                          _vm._v(_vm._s(_vm.$translate("photos.cancel_upload")))
                        ])
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "hide", attrs: { id: "content" } },
                  [
                    _c("vform", {
                      ref: "enterOverlayForm",
                      on: { submit: _vm.enterOverlayMode }
                    }),
                    _vm._v(" "),
                    _c("vform", {
                      ref: "anonimizeForm",
                      on: { submit: _vm.anonimizePhoto }
                    }),
                    _vm._v(" "),
                    _c("vform", {
                      ref: "publishOverlayForm",
                      on: { submit: _vm.publishPhoto }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "anonymize-container",
                    class: { "--loading": _vm.imageLoading }
                  },
                  [
                    !_vm.imageLoading && _vm.imgSrc
                      ? _c("img", {
                          ref: "anonymize_image",
                          attrs: { src: _vm.imgSrc }
                        })
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "photo-text",
                    staticStyle: { cursor: "pointer" }
                  },
                  [
                    _c(
                      "strong",
                      {
                        staticClass: "slider-label",
                        class: { "disabled-slider": !_vm.isSliderActive },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.enableSlider($event)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$translate("photos.anonimize")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isSliderActive,
                            expression: "isSliderActive"
                          }
                        ],
                        staticClass: "padding-md",
                        attrs: { id: "overlaySliderContainer" }
                      },
                      [_c("div", { attrs: { id: "overlaySlider" } })]
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.hasOverlay
                  ? _c("register-navigator-v2", {
                      attrs: {
                        "no-prev": true,
                        next: !_vm.isSending
                          ? _vm.$translate("photos.publish")
                          : "Saving..."
                      },
                      on: {
                        next: function($event) {
                          $event.preventDefault()
                          return _vm.$refs.anonimizeForm.submit($event)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.hasOverlay
                  ? _c("register-navigator-v2", {
                      attrs: {
                        "no-prev": true,
                        next: !_vm.isSending
                          ? _vm.$translate("photos.publish")
                          : "Saving..."
                      },
                      on: {
                        next: function($event) {
                          $event.preventDefault()
                          return _vm.$refs.publishOverlayForm.submit($event)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _vm.isSending || _vm.imageLoading ? _c("md-screen-preloader") : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }