var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "btm-sheet-backdrop",
      attrs: { id: "bottom-sheet-multiselect" }
    },
    [
      _c("div", { staticClass: "menu-container" }, [
        _c(
          "div",
          {
            staticClass: "close-cta",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.onClose($event)
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: "/img/registration/bottom-sheet-close.svg",
                alt: ""
              }
            })
          ]
        ),
        _vm._v(" "),
        _c("div", { ref: "header", staticClass: "title" }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { ref: "content", staticClass: "option-menu" },
          _vm._l(_vm.options, function(option, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "option-item-container",
                class: {
                  "option-item-container--selected":
                    _vm.data && _vm.data.indexOf(option.val) >= 0,
                  "option-item-container--disabled":
                    _vm.limitReached && _vm.data.indexOf(option.val) < 0
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.onSelect(option)
                  }
                }
              },
              [
                _c("div", { staticClass: "option-item" }, [
                  _vm._v(_vm._s(option.text))
                ])
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "btm-sheet-btn-container" }, [
          _c("div", { staticClass: "select-count" }, [
            _vm._v(_vm._s(_vm.getSelectedCountStatus))
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "btn btn-black",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.onConfirm($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$translate("register.generic_terms.ready")))]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }