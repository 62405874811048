<template>
  <div>
    <div
      class="navbar-fixed navbar navbar-event"
      :class="statusBarClass"
    >
      <nav class="z-depth-0 center-align pre">
        <div class="nav-wrapper container">
          <a
            id="navbar-event-back-btn"
            href="#"
            class="brand-logo"
            :class="{ 'grey-text': disabled }"
            @click.prevent="redirect"
          >
            <i class="material-icons">{{ icon }}</i>
            <strong>{{ brand }}</strong>
          </a>
          <strong
            v-if="isReady && !isCheckIn"
            class="center-countdown countdown"
          >{{ meetingCountdown }}</strong>
          <strong
            v-if="isReactivated && !isCheckIn"
            class="center-countdown countdown"
          >{{ confirmationCountdown }}</strong>
          <ul class="right margin-right-md">
            <li
              v-if="isPending && !showHelp"
              class="countdown-holder"
            >
              <strong
                class="countdown"
              >{{ $translate('events.labels.expires') }} {{ confirmationCountdown }}</strong>
            </li>
            <li
              v-if="!isPending && (!showHelp && showCurrency)"
              class="padding-right-md amount-payable"
            >
              <strong><currency />{{ selectedEvent.amount_payable }}</strong>
            </li>
            <li
              v-if="showHelp"
              class="padding-x-sm"
            >
              <div
                id="robot-icon"
                class="white-icon"
              />
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <md-sidenav />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    showCurrency: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    showHelp: { type: Boolean, default: false },
    brand: { type: String, default: '' },
    icon: { type: String, default: 'arrow_back' },
    push: { type: Object, default: {} },
    go: { type: String, default: '' }
  },
  computed: {
    ...mapGetters({
      selectedEvent: 'events/getSelected',
      user: 'getUser'
    }),
    tier () {
      if (this.user) {
        return this.user.settings.tier
      }
      return 1
    },
    confirmationCountdown () {
      if (this.selectedEvent._expiration_in_seconds > 0) {
        return moment()
          .hour(0)
          .minutes(0)
          .second(this.selectedEvent._expiration_in_seconds)
          .format('HH[h] : mm[m] : ss[s]')
      }
      return '00h : 00m :00s'
    },
    meetingCountdown () {
      let delay = 0

      if (
        this.selectedEvent.client_delay_seen &&
        (this.selectedEvent.client_delay > 0 ||
          this.selectedEvent.provider_delay > 0)
      ) {
        delay = this.selectedEvent.client_delay

        if (this.selectedEvent.provider_delay > delay) {
          delay = this.selectedEvent.provider_delay
        }
      }

      if (
        this.selectedEvent._meeting_start_in_seconds + $h.minToSeconds(delay) >
        0
      ) {
        return moment()
          .hour(0)
          .minutes(0)
          .second(
            this.selectedEvent._meeting_start_in_seconds +
              $h.minToSeconds(delay)
          )
          .format('HH[h] : mm[m] : ss[s]')
      }
      return '00h : 00m : 00s'
    },
    isPending () {
      return this.selectedEvent._event_status === EVENT_STATUS.PENDING
    },
    isReady () {
      return this.selectedEvent._event_status === EVENT_STATUS.CONFIRMED_READY
    },
    isExpired () {
      return this.selectedEvent._event_status === EVENT_STATUS.EXPIRED
    },
    isReactivated () {
      return (
        this.isExpired &&
        this.selectedEvent.reactivation_status ===
          REACTIVATION_STATUS.REACTIVATED
      )
    },
    isCheckIn () {
      return this.$route.name === 'event-checkin'
    },
    statusBarClass () {
      if (this.isReady) {
        return { 'navbar-ready': true }
      }
      if (this.isReactivated) {
        return { 'navbar-reactivated': true }
      }
    }
  },
  methods: {
    redirect () {
      if (this.disabled) return
      if (this.push) this.$router.push(this.push)
      if (this.go) this.$router.go(this.go)
      return false
    }
  }
}
</script>
