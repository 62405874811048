import { buildParam } from '@/services/ApiService'
export const inactiveRequestRewardPopupSeenPath = '/v2/popup/inactive-request-rewards/seen'

export const putInactiveRequestRewardSeen = (payload = {}) => {
  const url = buildParam(`${process.env.KRYPTON_PROVIDER_API}${inactiveRequestRewardPopupSeenPath}`)
  return new Promise((resolve, reject) => {
	  window.api.put({ url, payload: payload },
      (success) => { resolve(success.data) },
      (err) => { reject(err.response.data) })
  })
}
