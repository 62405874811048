<script>
import AuthService from '@/services/AuthService'
import RegisterSteps from '@/constants/registration/steps'
import { string2json } from '@/constants/helpers'

export default {
  name: 'Login',
  data () {
    return {
      username: null,
      password: null,
      errorMsg: null,
      errorsObj: null,
      isLoading: false
    }
  },
  computed: {
    PROVIDER_MAGIC_LINKS () {
      return this.$store.getters['featureflag/isEnabled']('PROVIDER_MAGIC_LINKS')
    },
    locale () {
      return this.$i18n.locale
    },
    forgotPasswordRoute () {
      return {
        name: 'forgot-password',
        ...(
          this.locale === 'en' && {
            params: {
              lang: 'en'
            }
          })
      }
    },
    requestMagicLinkRoute () {
      return {
        name: 'request-magic-link',
        ...(
          this.locale === 'en' && {
            params: {
              lang: 'en'
            }
          })
      }
    },
    registerRoute () {
      return {
        name: 'register-start',
        ...(
          this.locale === 'en' && {
            params: {
              lang: 'en'
            }
          })
      }
    }
  },
  mounted () {
    if (this.$route.query.ml) {
      this.loginWithMagicLink()
    }

    if (this.$route.query['reset-password']) {
      this.errorMsg = this.$t('login.resetPassSuccessful')
    }
  },
  methods: {
    isEmpty (field = null) {
      return field === null || field === ''
    },
    async loginWithMagicLink () {
      if (!this.$route.query.ml) {
        return false
      }

      let response

      try {
        response = await AuthService.loginWithMagicLink(this.$route.query.ml)
        await this.setupUser(response.authorization)
      } catch (err) {
        this.errorMsg = err.message
      }
    },
    async handleLogin (event) {
      let response

      this.errorMsg = null
      this.isLoading = true

      try {
        response = await AuthService.login(this.username, this.password, 1)
        this.isLoading = false
        await this.setupUser(response.authorization)
      } catch (err) {
        this.isLoading = false
        this.errorMsg = err.message
        this.errorsObj = ({
          email: err.message,
          password: err.message
        })

        const NETWORK_ERROR = 'Network Error'
        const TIMEOUT_ERROR = 'timeout of 10000ms exceeded'

        if (err.message === NETWORK_ERROR) {
          // Network Error
          this.errorMsg = this.$t('login.no_connection')
        } else if (err.message === TIMEOUT_ERROR) {
          this.errorMsg = this.$t('login.timeout_error')
        } else {
          this.errorMsg = err.message
        }

        return false
      }
    },
    async setupUser (authorizationToken) {
      let user

      window.api.authorization = authorizationToken

      await window.localforage.setItem('authToken', btoa(authorizationToken))

      try {
        user = await AuthService.getUser()
      } catch (err) {
        this.errorMsg = err.message
        return false
      }

      let interviews = []

      this.$store.commit('setUser', user)

      if (window.i18n.locale === 'de' && user.profiles.interview_de) {
        interviews = string2json(user.profiles.interview_de)
      } else {
        interviews = string2json(user.profiles.interview)
      }

      this.$store.commit('interview/SET_INTERVIEWS', interviews)

      if (user.register_progress === 0) {
        const getUTMSource = Object.fromEntries(new URLSearchParams(window.location.search).entries())?.utm_source ?? null
        if (getUTMSource === 'homescreen') {
          this.$router.push({ name: 'dashboard', query: { utm_source: getUTMSource } })
        } else {
          this.$router.push({ name: 'dashboard' })
        }
      } else {
        this.$router.push(RegisterSteps[user.register_progress])
      }
    },
    handleUsernameChange (val) {
      this.username = val
      this.removeFieldError('email')
    },
    handlePasswordChange (val) {
      this.password = val
      this.removeFieldError('password')
    },
    removeFieldError (field = null) {
      if (this.errorsObj && this.errorsObj[field]) {
        delete this.errorsObj[field]
      }
    }
  },
  render () {
    return this.$scopedSlots.default({
      data: {
        username: this.username,
        password: this.password,
        errorMsg: this.errorMsg,
        errorsObj: this.errorsObj,
        isLoading: this.isLoading,
        forgotPasswordRoute: this.forgotPasswordRoute,
        requestMagicLinkRoute: this.requestMagicLinkRoute,
        registerRoute: this.registerRoute
      },
      actions: {
        translate: (key, opts) => this.$t(key, opts),
        getFieldError: (field) => (this.errorsObj && this.errorsObj[field]) ? this.errorsObj[field] : null
      },
      bindings: {
        handleLogin: this.handleLogin,
        handleUsernameChange: this.handleUsernameChange,
        handlePasswordChange: this.handlePasswordChange,
        isEmpty: this.isEmpty,
        PROVIDER_MAGIC_LINKS: this.PROVIDER_MAGIC_LINKS
      }
    })
  }
}
</script>

<i18n src="../translations.json"></i18n>
