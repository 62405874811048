var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "margin-bottom", attrs: { id: "news-list" } },
    [
      _c("div", { staticClass: "section-label" }, [
        _c("img", {
          attrs: {
            src: "/img/dashboard/dashboard-features-icon.svg",
            alt: "tips and infos icon"
          }
        }),
        _vm._v(" "),
        _c("h2", [_vm._v(_vm._s(_vm.$translate("news.title")))])
      ]),
      _vm._v(" "),
      _vm._l(_vm.filteredItems, function(news, i) {
        return _c(
          "div",
          { key: i },
          [
            news.type == "promotional" && news.item === "news_promo"
              ? _c("krypton-connect-promo-card", { attrs: { item: news } })
              : _vm._e(),
            _vm._v(" "),
            news.type == "promotional" && news.item === "news_private_place"
              ? _c("private-place-promo-card", { attrs: { item: news } })
              : _vm._e(),
            _vm._v(" "),
            news.type === "promotional" && news.item === "news_multi_location"
              ? _c("multi-location-promo-card", { attrs: { item: news } })
              : _vm._e(),
            _vm._v(" "),
            news.type === "promotional" &&
            news.item === "news_super_provider" &&
            _vm.user.settings.is_super_provider_promo_seen === 0 &&
            !_vm.isCandidateProfile
              ? _c("super-provider-promo-card", { attrs: { item: news } })
              : _vm._e(),
            _vm._v(" "),
            news.type == "news"
              ? _c("news-list-item", {
                  attrs: { news: news, disabled: news.disabled }
                })
              : _vm._e()
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.isEmptyList
        ? _c("div", { staticClass: "card-panel" }, [
            _c("span", { staticClass: "grey-text text-darken-1" }, [
              _vm._v(_vm._s(_vm.$translate("dashboard.updates.no_update")))
            ])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }