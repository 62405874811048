<template>
    <div class="box">
        <h1 class="title">{{ title }}</h1>
        <img :src="image" />
        <div class="content strong" v-html="parsedValue"></div>
    </div>
</template>

<script>
export default {
    props: [
        'title',
        'image',
        'value',
    ],
    computed: {
        parsedValue() {
            return this.value ? this.value : '&nbsp;';
        }
    }
}
</script>

<style lang="scss" scoped>
.box {
    & {
        padding: 16px 12px;
        text-align: center;
        border-radius: 8px;
        border: 1px solid #C4C4C4;

        @media (min-width: 361px) {
            padding: 24px 16px;
        }
    }
    & > .title {
        margin: 0;
        margin-bottom: 24px;
        font-weight: 700;
        font-size: 1.125rem !important;

        @media (min-width: 361px) {
            margin-bottom: 44px;
            font-size: 1.375rem !important;
        }
    }
    & > img {
        height: 48px;
        margin-bottom: 24px;

        @media (min-width: 361px) {
            height: 64px;
        }
    }
}
</style>
