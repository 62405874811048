const DEFAULT_COUNTRY_CODE = 'DE'

const SUPPORTED_TIMEZONES = {
  de: 'Europe/Berlin',
  gb: 'Europe/London',
  uk: 'Europe/London',
  ch: 'Europe/Berlin'
}

export const timezoneByCountryCode = (code = 'de') => {
  return SUPPORTED_TIMEZONES[code.toLowerCase()] ?? SUPPORTED_TIMEZONES[DEFAULT_COUNTRY_CODE]
}

export default {
  timezoneByCountryCode
}
