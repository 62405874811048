import { getPropertyOrDefault } from '@/helpers/modelUtils'
import { INBOX_TYPE, INBOX_LIKE_TYPE } from '@/features/inbox/constants'

/**
 * @type
 *
 * Inbox Message model.
*/
export default function InboxMessage (props) {
  this.id = getPropertyOrDefault(props, 'id', 0)
  this.tokenID = getPropertyOrDefault(props, 'token_id', 0)
  this.providerID = getPropertyOrDefault(props, 'provider_id', 0)
  this.clientID = getPropertyOrDefault(props, 'client_id', 0)
  this.subject = getPropertyOrDefault(props, 'subject', '')
  this.content = getPropertyOrDefault(props, 'content', '')
  this.senderName = getPropertyOrDefault(props, 'sender_name', '')
  this.senderAge = getPropertyOrDefault(props, 'sender_age', 0)
  this.type = getPropertyOrDefault(props, 'type', INBOX_TYPE.KRYPTON_CONNECT)
  this.likeType = getPropertyOrDefault(props, 'like_type', INBOX_LIKE_TYPE.PHOTO)
  this.messageCount = getPropertyOrDefault(props, '_message_count', 0)
  this.rating = getPropertyOrDefault(props, 'rating', 0)
  this.createdAt = getPropertyOrDefault(props, 'created_at', moment().format('YYYY-MM-DD HH:mm:ss'))
  this.lastReceivedAt = getPropertyOrDefault(props, 'last_received_at', null)
  this.seenAt = getPropertyOrDefault(props, 'seen_at', null)
  this.showReview = getPropertyOrDefault(props, 'show_review', 0)

  Object.defineProperty(this, 'hasNotification', {
    get () {
      if (this.type === INBOX_TYPE.KRYPTON_CONNECT || this.type === INBOX_TYPE.LIKE) {
        return this.seenAt !== null ? 0 : 1
      }

      return 0
    }
  })
  Object.defineProperty(this, 'updatedAt', {
    get () {
      return this.lastReceivedAt ? this.lastReceivedAt : this.createdAt
    }
  })
}
