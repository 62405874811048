<template>
  <div id="client-faq" class="page">
    <div class="container">
      <faq-header
        :header-title="$translate('faq.client_faq.title')"
        :header-tagline="$translate('faq.client_faq.intro')"
        main-header-logo="/img/faq/client-faq-header-img.svg"
        primary-header-color="#FF0073"
        secondary-header-color="#962BDA"
        header-gradient-angle="236deg"
        tagline-gradient-angle="249deg"
        header-navbar-redirection="faq"
      />
      <div class="container content-section" data-test="faq-content-section">
        <div v-html="$translate('faq.client_faq.explanation')" />
        <faq-cta
          route-name="feedback"
          :cta-txt="$translate('faq.performance_btn.performance_overview')"
          background-gradient-class="--pink"
        />
      </div>
    </div>
  </div>
</template>

<script>
import redirectMixin from '@/features/provider-faq/mixins/redirect'
import FAQHeader from '@/features/provider-faq/components/FAQHeader.vue'
import FAQCta from '@/features/provider-faq/components/FAQCta.vue'

export default {
  components: {
    'faq-header': FAQHeader,
    'faq-cta': FAQCta
  },
  mixins: [redirectMixin],
  data: () => ({
    fallbackRoute: {
      name: 'faq',
      title: 'sidenav.faq'
    }
  })
}
</script>

<style lang="scss" scoped>
  #client-faq {
    .content-section {
      font-size: 18px;
      text-align: left;
      padding: 24px;
      background: #FFFFFF;
      color: #2F3740;

      .faq-cta-container {
        margin-top: 56px;
      }
    }

  }
  </style>

  <style lang="scss">
  #client-faq {
    .content-section {
      ul li::marker {
        color: #C51162;
        font-size: 1.5rem;
      }
    }
  }
  </style>
