<template>
  <div id="multi-location-faq" class="page">
    <div>
      <div class="container">
        <!-- Multiloc FAQ Header -->
        <faq-header
          :header-title="$t('header.title')"
          :header-tagline="$t('header.tagline')"
          main-header-logo="/img/multi-location/multiloc-header-logo.svg"
          tagline-logo="/img/multi-location/multiloc-tagline-logo.svg"
          primary-header-color="#FF665B"
          secondary-header-color="#FFD04D"
          header-gradient-angle="54deg"
          tagline-gradient-angle="48deg"
          header-navbar-redirection="faq"
        />
        <!-- Multiloc FAQ Items -->
        <div id="content" data-test="faq-q-and-a-section">
          <ul class="q-and-a">
            <li
              v-for="(item, key) in $t('faq.list')"
              :key="key">
              <div class="question">
                <span class="question-number">{{ `${key+1}.` }}</span> <span>{{ $t(`faq.list[${key}].title`) }}</span>
              </div>
              <div
                v-for="(item2, key2) in $t(`faq.list[${key}].content`)"
                :key="key2"
                class="answer"
              >
                {{ $t(`faq.list[${key}].content[${key2}]`) }}
                <a v-if="isLastItem(key) && isLastParagraph(key2, key)" href="#" class="support" @click.prevent="$router.push({name: 'support-v2'})">
                  {{ $t('faq.support') }}
                </a>
              </div>
            </li>
          </ul>
          <faq-cta
            v-if="isMultiLocationSupported"
            route-name="multi-location-setting"
            :cta-txt="$t('faq.cta')"
            background-gradient-class="--orange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FAQHeader from '@/features/provider-faq/components/FAQHeader.vue'
import FAQCta from '@/features/provider-faq/components/FAQCta.vue'

export default {
  components: {
    'faq-header': FAQHeader,
    'faq-cta': FAQCta
  },
  data: () => ({
    prevRoute: null
  }),
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters({
      isCandidateProfile: 'isCandidateProfile',
      user: 'getUser'
    }),
    isLastItem () {
      return (key) => {
        const faqlListLength = (Object.keys(this.$t('faq.list') ?? []).length - 1)
        return parseInt(key) === faqlListLength
      }
    },
    isLastParagraph () {
      return (key2, key) => {
        const faqListLength = (Object.keys(this.$t('faq.list')[key]?.content ?? []).length - 1)
        return parseInt(key2) === faqListLength
      }
    },
    isMultiLocationSupported () {
      if (!this.user._has_successful_meeting || this.isCandidateProfile) {
        return false
      }

      return this.$store.getters['multiLocation/isMultiLocationSupported']
    }
  },
  mounted () {
    // Because of the change of translation source
    // There are 2 files to refer when turning the flag on and off
    // We can make it backwards compatible by modifying the instance of $i18n,
    // instead of using the normal <i18n> blocks that is out of scope of the <template>
    this.setupLanguageTranslationFileSrc()
  },
  methods: {
    setupLanguageTranslationFileSrc () {
      const messagesEn = require('@/features/multi-location/translations/landing-page.json').en
      const messagesDe = require('@/features/multi-location/translations/landing-page.json').de

      this.$i18n?.setLocaleMessage('en', messagesEn)
      this.$i18n?.setLocaleMessage('de', messagesDe)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/features/provider-faq/scss/q-and-a.scss';

#multi-location-faq {
  #content {
    text-align: left;
    font-size: 14px;
    padding-left: 32px;
    padding-right: 32px;
    color: #2F3740;

    & {
      background: #FFFFFF;
    }

    .title {
      font-size: 22px;
    }
    .faq-img-container {
      padding: 30px 8px;
      background-color: #F5F7FA;
      border: 1px solid #E1E6EB;
      border-radius: 8px;

      img {
        margin: 0 auto;
        display: block
      };
    }
    #q-and-a {
    li {
      margin: 0;
      text-align: left;
    }

    li:not(:last-child) {
      border-bottom: 1px solid #CAD1D9;
    }

    li:not(:first-child) {
      padding-top: 18px;
    }

    .question {
      display: flex;
      font-size: 20px;
      font-family: 'Proxima Nova Semibold';
      font-weight: 600;
      color: #2E353D;
      margin: 0;

      .question-number {
        color: #C51162;
        margin-right: 8px;
      }
    }

    .answer {
      color: #2F3740;
      font-size: 18px;
      margin: 0;
      margin-left: 18px;
      padding-top: 18px;
      padding-bottom: 18px;

      a {
        text-decoration: underline;
      }
    }
  }
  }
}
</style>
