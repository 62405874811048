<template functional>
  <a
    href="#"
    class="btn"
    :class="props.data.classes"
    :style="props.data.styles"
    @click.prevent="props.data.onClick"
  >
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default () {
        return {
          size: null,
          type: null,
          link: false,
          disabled: false,
          wavesEffect: false,
          styles: null,
          classes: null,
          onClick: () => { console.error('No click handler specified') }
        }
      }
    }
  }
}
</script>
