<template>
  <div id="details">
    <md-navbar :push="{ name: 'profile' }" :brand="$translate('profile.title')" icon="arrow_back" />
    <div v-if="ready" id="content" class="container">
      <h1 class="title">{{ $translate('details.header') }}</h1>
      <vform ref="form" @submit="save">
        <!-- Eye Color -->
        <md-select v-if="user && dropdown && optsEyeColor.length"
                   v-model="payload.eye_color"
                   :label="$translate('profile.eyes')"
                   :placeholder="$translate('details.eye')">
          <option v-for="opt in optsEyeColor" slot="options" :value="opt.key">{{ opt.content }}</option>
          <div v-show="errors.has('eye_color')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('eye_color') }}
          </div>
        </md-select>
        <!-- Hair Color -->
        <md-select v-if="user && dropdown && optsHairColor.length"
                   v-model="payload.hair_color"
                   :label="$translate('profile.hair')"
                   :placeholder="$translate('details.hair')">
          <option v-for="opt in optsHairColor" slot="options" :value="opt.key">{{ opt.content }}</option>
          <div v-show="errors.has('hair_color')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('hair_color') }}
          </div>
        </md-select>
        <!-- Height -->
        <md-select v-if="user && dropdown && optsHeight.length"
                   v-model="payload.height"
                   :label="$translate('profile.height')"
                   :placeholder="$translate('details.height')">
          <option v-for="opt in optsHeight" slot="options" :value="opt.key">{{ opt.content }}</option>
          <div v-show="errors.has('height')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('height') }}
          </div>
        </md-select>
        <!-- Weight -->
        <md-select v-if="user && dropdown && optsWeight.length"
                   v-model="payload.weight"
                   :label="$translate('profile.weight')"
                   :placeholder="$translate('details.weight')">
          <option v-for="opt in optsWeight" slot="options" :value="opt.key">{{ opt.content }}</option>
          <div v-show="errors.has('weight')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('weight') }}
          </div>
        </md-select>
        <!-- Bra Cup -->
        <md-select v-if="user && dropdown && optsBraCup.length"
                   v-model="bra_cup"
                   :label="$translate('profile.bra')"
                   :placeholder="$translate('details.bra')">
          <option v-for="opt in optsBraCup" slot="options" :value="opt.key">{{ opt.content }}</option>
          <div v-show="errors.has('bra_cup')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('bra_cup') }}
          </div>
        </md-select>
        <div v-if="bra_cup" class="margin-bottom switch-container">
          <div class="strong left-align padding-right-md">{{ $translate('register.basic.bra_cup.natural') }}</div>
          <div class="right" :class="{
            'switch-pill--APV_REG_REDESIGN': true
          }">
            <span :class="{ 'active': natural === false }" @click.prevent="setNatural(false)">
              {{ $translate('register.basic.bra_cup.natural_no') }}
            </span>
            <span :class="{ 'active': natural }" @click.prevent="setNatural(true)">
              {{ $translate('register.basic.bra_cup.natural_yes') }}
            </span>
          </div>
        </div>
        <!-- Dress Size -->
        <md-select v-if="user && dropdown && optsDressSize.length"
                   v-model="payload.dress_size"
                   :label="$translate('profile.dress')"
                   :placeholder="$translate('details.dress')">
          <option v-for="opt in optsDressSize" slot="options" :value="opt.key">{{ opt.content }}</option>
          <div v-show="errors.has('dress_size')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('dress_size') }}
          </div>
        </md-select>
        <!-- Origin -->
        <md-select v-if="user && dropdown && optsOrigin.length"
                   v-model="payload.origin"
                   :label="$translate('profile.origin')"
                   :placeholder="$translate('details.origin')">
          <option v-for="opt in optsOrigin" slot="options" :value="opt.key">{{ opt.content }}</option>
          <div v-show="errors.has('origin')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('origin') }}
          </div>
        </md-select>

        <!-- Lang 1 -->
        <md-select
          v-if="user && dropdown && optsLanguages.length"
          v-model="payload.lang_1"
          :label="$translate('details.first_language')"
          :placeholder="$translate('details.lang_1')"
          :listen-for-reinitiate-options="true"
          @change="reInitiateOptions"
        >
          <option
            v-for="opt in optsLanguages"
            slot="options"
            :key="opt.key"
            :value="opt.key"
            :disabled="opt.key == payload.lang_1 || opt.key == payload.lang_2 || opt.key == payload.lang_3"
          >{{ opt.content }}</option>
          <div v-show="errors.has('lang_1')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('lang_1') }}
          </div>
        </md-select>

        <!-- Lang 2 -->
        <div v-if="user && dropdown && optsLanguages.length && showSecondLang">
          <md-select
            v-model="payload.lang_2"
            :label="$translate('details.second_language')"
            :placeholder="$translate('details.lang_2')"
            :listen-for-reinitiate-options="true"
            @change="reInitiateOptions"
          >
            <option
              v-for="opt in optsLanguages"
              slot="options"
              :key="opt.key"
              :value="opt.key"
              :disabled="opt.key == payload.lang_2 || opt.key == payload.lang_3 || opt.key == payload.lang_1"
            >{{ opt.content }}</option>
            <div v-show="errors.has('lang_2')" slot="errors" class="input-helper red-text center-align">
              {{ errors.first('lang_2') }}
            </div>
          </md-select>
          <div class="remove-btn margin-bottom" style="overflow: auto">
            <a href="#" class="grey-text text-darken-1 uppercase right waves-effect waves-dark padding-sm" @click.prevent="removeLang(2)">
              <small><strong>{{ $translate('app.remove') }}</strong></small>
            </a>
          </div>
        </div>

        <!-- Lang 3 -->
        <div v-if="user && dropdown && optsLanguages.length && showThirdLang">
          <md-select
            v-model="payload.lang_3"
            :label="$translate('details.third_language')"
            :placeholder="$translate('details.lang_3')"
            :listen-for-reinitiate-options="true"
            @change="reInitiateOptions"
          >
            <option
              v-for="opt in optsLanguages"
              slot="options"
              :key="opt.key"
              :value="opt.key"
              :disabled="opt.key == payload.lang_3 || opt.key == payload.lang_1 || opt.key == payload.lang_2"
            >{{ opt.content }}</option>
            <div v-show="errors.has('lang_3')" slot="errors" class="input-helper red-text center-align">
              {{ errors.first('lang_3') }}
            </div>
          </md-select>
          <div class="remove-btn margin-bottom" style="overflow: auto">
            <a href="#" class="grey-text text-darken-1 uppercase right" @click.prevent="removeLang(3)">
              <small><strong>{{ $translate('app.remove') }}</strong></small>
            </a>
          </div>
        </div>

        <!-- Add Lang -->
        <div v-if="showAddLang" class="right-align margin-y-md">
          <a href="#" class="waves-effect waves-dark padding-sm" @click.prevent="incrementLang2show">
            <small><strong>+ {{ $translate('details.add_language') }}</strong></small>
          </a>
        </div>

        <!-- Interests -->
        <md-textarea v-model="payload.interests"
                     type="multiline"
                     :force-active="true"
                     :maxlength="interestsMaxLength"
                     :label="$translate('profile.interests')"
                     :placeholder="$translate('register.personality.intellect.interests_placeholder')">
          <div class="input-helper">
            <span class="right">{{ interestsLength }}</span>
            <div v-show="errors.has('interests')" class="input-helper red-text center-align">
              {{ errors.first('interests') }}
            </div>
          </div>
        </md-textarea>

        <!-- Profession -->
        <md-select v-if="user && dropdown && optsProfession.length"
                   v-model="payload.profession"
                   :label="$translate('profile.job')"
                   :placeholder="$translate('details.job')">
          <option v-for="opt in optsProfession" slot="options" :value="opt.key">{{ opt.content }}</option>
          <div v-show="errors.has('profession')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('profession') }}
          </div>
        </md-select>

        <!-- Cuisine -->
        <md-select
          v-if="user && dropdown && optsCuisine.length"
          v-model="payload.cuisine"
          multiple="true"
          :limit="3"
          force-mobile="true"
          :label="$translate('profile.cuisine')"
          :placeholder="$translate('details.cuisine')"
        >
          <option v-for="opt in optsCuisine" slot="options" :value="opt.key">{{ opt.content }}</option>
          <div v-show="errors.has('cuisine')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('cuisine') }}
          </div>
        </md-select>

        <!-- Drinks -->
        <md-textarea v-model="payload.drinks"
                     type="multiline"
                     :force-active="true"
                     :maxlength="drinksMaxLength"
                     :label="$translate('register.personality.taste.drinks_label')"
                     :placeholder="$translate('profile.drinks_placeholder')">
          <div class="input-helper">
            <span class="right">{{ drinksLength }}</span>
            <div v-show="errors.has('drinks')" class="input-helper red-text center-align">
              {{ errors.first('drinks') }}
            </div>
          </div>
        </md-textarea>
        <!-- Smoking -->
        <md-select v-if="user && dropdown && optsSmoking.length"
                   v-model="payload.smoking"
                   :label="$translate('profile.smoking')"
                   :placeholder="$translate('details.smoke')">
          <option v-for="opt in optsSmoking" slot="options" :value="opt.key">{{ opt.content }}</option>
          <div v-show="errors.has('smoking')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('smoking') }}
          </div>
        </md-select>
        <!-- Tattoo -->
        <md-select v-if="user && dropdown && optsTattoo.length"
                   v-model="payload.tattoo"
                   :label="$translate('profile.tattoo')"
                   :placeholder="$translate('details.tattoo')">
          <option v-for="opt in optsTattoo" slot="options" :value="opt.key">{{ opt.content }}</option>
          <div v-show="errors.has('tattoo')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('tattoo') }}
          </div>
        </md-select>
        <!-- Piercing -->
        <md-select v-if="user && dropdown && optsPiercing.length"
                   v-model="payload.piercing"
                   :label="$translate('profile.piercing')"
                   :placeholder="$translate('details.piercing')">
          <option v-for="opt in optsPiercing" slot="options" :value="opt.key">{{ opt.content }}</option>
          <div v-show="errors.has('piercing')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('piercing') }}
          </div>
        </md-select>
      </vform>
      <h-footer-btns :cancel="$translate('app.cancel')"
                     :confirm="$translate('app.save')"
                     @cancel="$router.push({ name: 'profile' })"
                     @confirm="submitButton" />
    </div>
  </div>
</template>

<script>
const UserProvider = $h.getProvider('UserProvider')
const LocaleProvider = $h.getProvider('LocaleProvider')
export default {
  data () {
    return {
      interestsMaxLength: 60,
      drinksMaxLength: 60,
      lang2show: 0,
      natural: false,
      bra_cup: false,
      /// /////////
      payload: {
        lang_1: null,
        lang_2: null,
        lang_3: null,
        interests: '',
        drinks: '',
        eye_color: null
      },
      /// /////////
      optsLanguages: [],
      optsEyeColor: [],
      optsHairColor: [],
      optsHeight: [],
      optsWeight: [],
      optsBraCup: [],
      optsDressSize: [],
      optsOrigin: [],
      optsProfession: [],
      optsCuisine: [],
      optsSmoking: [],
      optsTattoo: [],
      optsPiercing: []
    }
  },
  computed: {
    ready () {
      return this.dropdown && this.user
    },
    dropdown () {
      return this.$store.getters.getDropdown
    },
    user () {
      return this.$store.getters.getUser
    },
    showSecondLang () {
      return this.lang2show >= 2
    },
    showThirdLang () {
      return this.lang2show >= 3
    },
    showAddLang () {
      return this.lang2show <= 2
    },
    interestsLength: {
      cache: false,
      get () {
        return `${this.payload.interests.length}/${this.interestsMaxLength}`
      }
    },
    drinksLength: {
      cache: false,
      get () {
        return `${this.payload.drinks.length}/${this.drinksMaxLength}`
      }
    },
    homeBaseCountry () {
      return this.$store.getters.getHomeBaseCountry
    }
  },
  watch: {
    dropdown (newval, oldval) {
      this.initDropdown()
    },
    bra_cup (newval, oldval) {
      this.analyzeBraCup()
    },
    natural (newval, oldval) {
      this.analyzeBraCup()
    }
  },
  mounted () {
    this.initPayload()
    this.initLang2show()
    this.initDropdown()
    this.generateHeightOpts()
    this.generateWeightOpts()
    this.generateDressSizeOpts()
  },
  methods: {
    reInitiateOptions () {
      this.$root.$emit('parent-reinitiate-options')
    },
    submitButton () {
      this.$refs.form.submit()
    },
    save (next) {
      this.errors.clear()

      UserProvider.updateUser(this.payload)
        .then((res) => {
          this.$notifications.toast(this.$translate('profile.profile_updated'), 5000)
          this.$store.commit('setProfile', res.data)
          this.$store.commit('setCuisine', res.data.cuisine)
          this.$store.commit('setLang', {
            lang_1: res.data.lang_1,
            lang_2: res.data.lang_2,
            lang_3: res.data.lang_3
          })
          this.$router.push({ name: 'profile' })
        })
        .catch((err) => {
          $h.processErrors(this, err.data)
        })
        .finally(() => {
          next()
        })
    },
    setPayloadProp (key, val) {
      if (val === undefined) val = null
      this.payload[key] = val
    },
    parseInterests () {
      if (LocaleProvider.isLocaleDe() && this.user.profiles.interests_de) { return this.user.profiles.interests_de }
      return this.user.profiles.interests
    },
    parseDrinks () {
      if (LocaleProvider.isLocaleDe() && this.user.profiles.drinks_de) return this.user.profiles.drinks_de
      return this.user.profiles.drinks
    },
    incrementLang2show () {
      if (this.lang2show < 3) this.lang2show++
      this.reInitiateOptions()
    },
    decrementLang2show () {
      if (this.lang2show > 1) this.lang2show--
    },
    removeLang (index) {
      if (index === 2 && this.payload.lang_3) {
        this.payload.lang_2 = this.payload.lang_3
        index = 3
      }

      var key = 'lang_' + index

      this.setPayloadProp(key)
      this.decrementLang2show()
      this.reInitiateOptions()
    },
    setNatural (val) {
      this.natural = val
    },
    analyzeBraCup () {
      if (this.bra_cup == null || this.natural == null) { return this.payload.bra_cup = null }

      if (this.bra_cup && this.natural) { return this.payload.bra_cup = this.bra_cup + 'n' } else { return this.payload.bra_cup = this.bra_cup }
    },
    calcLang () {
      var tmpLang = 0

      if (this.payload.lang_1) tmpLang++
      if (this.payload.lang_2) tmpLang++
      if (this.payload.lang_3) tmpLang++

      this.lang2show = tmpLang
    },
    initPayload () {
      this.payload.city_id = this.user.profiles.city_id
      this.payload.eye_color = this.user.profiles.eye_color
      this.payload.hair_color = this.user.profiles.hair_color
      this.payload.height = this.user.profiles.height
      this.payload.weight = this.user.profiles.weight
      this.payload.dress_size = this.user.profiles.dress_size
      this.payload.origin = this.user.profiles.origin
      this.payload.lang_1 = this.user.profiles.lang_1 ? this.user.profiles.lang_1.short : null
      this.payload.lang_2 = this.user.profiles.lang_2 ? this.user.profiles.lang_2.short : null
      this.payload.lang_3 = this.user.profiles.lang_3 ? this.user.profiles.lang_3.short : null
      this.payload.interests = this.parseInterests() || ''
      this.payload.profession = this.user.profiles.profession
      this.payload.cuisine = $h.string2json(this.user.profiles.cuisine)
      this.payload.drinks = this.parseDrinks() || ''
      this.payload.smoking = this.user.profiles.smoking
      this.payload.tattoo = this.user.profiles.tattoo
      this.payload.piercing = this.user.profiles.piercing
      this.payload.bra_cup = this.user.profiles.bra_cup

      if (!this.payload.bra_cup) return

      if (this.payload.bra_cup.length == 1) {
        this.bra_cup = this.payload.bra_cup[0]
        this.natural = false
      } else {
        this.bra_cup = this.payload.bra_cup[0]
        this.natural = true
      }
    },
    initDropdown () {
      if (!this.dropdown) return

      this.optsEyeColor = $h.obj2arr(this.dropdown.eye_color)
      this.optsHairColor = $h.obj2arr(this.dropdown.hair_color)
      // this.optsBraCup     = $h.obj2arr(this.dropdown.bra_cup);

      this.optsOrigin = $h.obj2arr(this.dropdown.origin).filter((origin) => {
        let excludeOrigin = []
        if (this.homeBaseCountry.toLowerCase() === 'de') {
          excludeOrigin = ['uk', 'ch', 'at']
        } else if (this.homeBaseCountry.toLowerCase() === 'gb') {
          excludeOrigin = ['de', 'ch', 'at']
        }

        return !excludeOrigin.includes(origin.key)
      })

      this.optsLanguages = $h.obj2arr(this.dropdown.language)
      this.optsProfession = $h.obj2arr(this.dropdown.profession)
      this.optsCuisine = $h.obj2arr(this.dropdown.cuisine)
      this.optsSmoking = $h.obj2arr(this.dropdown.smoking)
      this.optsTattoo = $h.obj2arr(this.dropdown.tattoo)
      this.optsPiercing = $h.obj2arr(this.dropdown.piercing)

      // Bra Cup
      var cups = ['A', 'B', 'C', 'D', 'E', 'F', 'G']

      for (var i = 0; i < cups.length; i++) {
        this.optsBraCup.push({
          key: cups[i],
          content: cups[i]
        })
      }
    },
    initLang2show () {
      if (this.payload.lang_1) this.lang2show++
      if (this.payload.lang_2) this.lang2show++
      if (this.payload.lang_3) this.lang2show++
    },
    generateHeightOpts () {
      for (var i = 150; i <= 200; i++) {
        this.optsHeight.push({ key: i, content: i + ' cm' })
      }
    },
    generateWeightOpts () {
      for (var i = 40; i <= 100; i++) {
        this.optsWeight.push({ key: i, content: i + ' kg' })
      }
    },
    generateDressSizeOpts () {
      for (var i = 32; i <= 44; i++) {
        if (i % 2 != 0) {
          continue
        }

        this.optsDressSize.push({ key: i, content: 'Size ' + i })
      }
    }
  }
}
</script>
