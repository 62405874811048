<template>
  <div
    class="onboarding-lesson-card"
    :class="[lessonCard.class]"
    @click="onClickHandler"
  >
    <div class="onboarding-lesson-card--container">
      <div class="onboarding-lesson-card--details">
        <h2>{{ $t(lessonCard.title) }}</h2>
        <p>{{ $t('subtitle') }}</p>
        <div class="cta">
          <img src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-video-player-v2.svg" alt="video player icon">
          <span>{{ $t('video') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/onboarding-lesson-card/translations/messages.json"></i18n>

<script>
import { LESSON_CARDS } from '@/features/onboarding-lesson-card/constants'

export default {
  props: {
    lessonCard: {
      type: Object,
      default () {
        return LESSON_CARDS.PHOTOS
      }
    }
  },
  methods: {
    onClickHandler (e) {
      return this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" scoped>

.onboarding-lesson-card {
    display: block;
    width: 100%;
    height: 180px;
    max-width: 312px;
    border-radius: 16px;
    background-color: #efe8ff;
    background-repeat: no-repeat;
    background-position: bottom right;
    cursor: pointer;
    text-align: left;

    &.onboarding-lesson-card--bottom-sheet {
      max-width: 100%;
    }

    div.onboarding-lesson-card--container {
        height: 100%;
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        div.onboarding-lesson-card--details {
            h2 {
                margin: 0 0 4px;
                font-size: 22px;
                color: #2F3740;
            }
            p {
                margin: 0 0 0;
                font-size: 16px;
                color: #737B85;
            }
            div.cta {
                img, span {
                    display: inline-block;
                    vertical-align: middle;
                }
                span {
                    font-size: 14px;
                    color: #7638FA;
                }
            }
        }
    }

    &.onboarding-lesson-card__photos {
        background-image: url('~@/features/onboarding-lesson-card/assets/v2/onboarding-lesson-overview-photos.svg');
    }
    &.onboarding-lesson-card__interview {
        background-image: url('~@/features/onboarding-lesson-card/assets/v2/onboarding-lesson-overview-interview.svg');
    }
    &.onboarding-lesson-card__availability {
        background-image: url('~@/features/onboarding-lesson-card/assets/v2/onboarding-lesson-overview-availability.svg');
    }
    &.onboarding-lesson-card__safety {
        background-image: url('~@/features/onboarding-lesson-card/assets/v2/onboarding-lesson-overview-safety.svg');
    }
    &.onboarding-lesson-card__clients {
        background-image: url('~@/features/onboarding-lesson-card/assets/v2/onboarding-lesson-overview-clients.svg');
    }
    &.onboarding-lesson-card__requests {
        background-image: url('~@/features/onboarding-lesson-card/assets/v2/onboarding-lesson-overview-requests.svg');
    }
    &.onboarding-lesson-card__dates {
        background-image: url('~@/features/onboarding-lesson-card/assets/v2/onboarding-lesson-overview-dates.svg');
    }
    &.onboarding-lesson-card__feedback {
        background-image: url('~@/features/onboarding-lesson-card/assets/v2/onboarding-lesson-overview-feedback.svg');
    }
    &.onboarding-lesson-card__money {
        background-image: url('~@/features/onboarding-lesson-card/assets/v2/onboarding-lesson-overview-money.svg');
    }
}
</style>
