<template>
  <div id="faq_header__container" :style="cssVars">
    <div id="faq__header">
      <div id="faq_header__main_container">
        <md-faq-navbar :route-name="headerNavbarRedirection" />
        <h1
          data-test="faq-title"
          v-text="headerTitle"
        />
        <img
          data-test="faq-main-header-logo"
          :src="mainHeaderLogo"
        >
      </div>
    </div>
    <div v-if="hasTagline" class="faq_header__tagline_container">
      <p
        data-test="faq-tagline-text"
        v-html="headerTagline"
      />
      <img
        v-if="taglineLogo"
        data-test="faq-tagline-logo"
        :src="taglineLogo"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headerTitle: {
      type: String,
      required: true,
      default: ''
    },
    headerTagline: {
      type: String,
      required: true,
      default: ''
    },
    mainHeaderLogo: {
      type: String,
      required: true,
      default: ''
    },
    taglineLogo: {
      type: String,
      required: false,
      default: null
    },
    primaryHeaderColor: {
      type: String,
      required: false,
      default: '#F5187C'
    },
    secondaryHeaderColor: {
      type: String,
      required: false,
      default: '#C51162'
    },
    headerGradientAngle: {
      type: String,
      required: false,
      default: '244deg'
    },
    taglineGradientAngle: {
      type: String,
      required: false,
      default: '245deg'
    },
    headerImgDimensions: {
      type: Object,
      required: false,
      default: () => ({
        height: '107px',
        width: '125px'
      })
    },
    headerNavbarRedirection: {
      type: String,
      default: 'faq'
    },
    hasTagline: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    cssVars () {
      // Declaration of dynamic css variables
      return {
        '--primary-header-color': this.primaryHeaderColor,
        '--secondary-header-color': this.secondaryHeaderColor,
        '--header-gradient-angle': this.headerGradientAngle,
        '--tagline-gradient-angle': this.taglineGradientAngle,
        '--header-img-height': this.headerImgDimensions?.height,
        '--header-img-width': this.headerImgDimensions?.width
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#faq_header__container {
    #faq__header {
        width: 100%;
        height: 200px;
        position: relative;
        background: transparent linear-gradient(var(--header-gradient-angle), var(--primary-header-color) 0%,var(--secondary-header-color) 100%) 0% 0% no-repeat padding-box;
        color: #ffffff;
        text-align: center;
        height: 224px;

        #faq_header__main_container {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        top: 0;

        h1, p {
            line-height: 1;
        }

        h1 {
            margin: 0;
            font-family: 'Proxima Nova Semibold';
            font-size: 24px;
            padding-top: 5px;
        }

        img {
            height: var(--header-img-height);
            width: var(--header-img-width);
            margin-top: 24px;
            margin-top: 19px;
        }
      }
    }

    .faq_header__tagline_container {
        position: relative;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-size: 18px;
        color: #2F3740;
        padding: 40px 32px;

        &::before {
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background: transparent linear-gradient(var(--tagline-gradient-angle), var(--primary-header-color) 0%,var(--secondary-header-color) 100%) 0% 0% no-repeat padding-box;
        opacity: 0.1;
        z-index: 0;
        }

        p {
        margin: 0;
        }

        img {
        padding-top: 24px;
        }
    }
}
</style>
