var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "event-declined" } },
    [
      !_vm.isFinishScreen
        ? _c(
            "div",
            {
              staticClass: "padding-x-xl right-align white",
              staticStyle: { height: "48px", "padding-top": "12px" }
            },
            [
              _c("div", { staticClass: "container" }, [
                _c(
                  "span",
                  {
                    staticClass: "close grey-text text-darken-1",
                    attrs: { id: "event-decline-close-btn" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.$router.back()
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("close")
                    ])
                  ]
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isNotAvailableScreen
        ? _c("event-declined-not-available", {
            attrs: {
              "client-name": _vm.selectedEvent.client.name,
              "event-date": _vm.eventDate,
              step: _vm.step,
              error_form: _vm.error_form
            },
            on: { ondeclineblacklist: _vm.notComfortable },
            scopedSlots: _vm._u(
              [
                {
                  key: "input",
                  fn: function() {
                    return [
                      _vm.error_form.not_available
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "grey darken-1 padding-md border-rad-sm margin-bottom-md left-align",
                              attrs: {
                                id: "event-declined-not-available-error-form"
                              }
                            },
                            [
                              _c("small", { staticClass: "white-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$translate(
                                      "events.decline_popup.not_available_error",
                                      {
                                        client_name:
                                          _vm.selectedEvent.client.name
                                      }
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "md-textarea",
                        {
                          staticClass:
                            "textfield-box-pink textfield-box-large textfield-label-pink not-comfortabe-message",
                          attrs: {
                            id: "event-declined-not-available-textarea",
                            type: "multiline",
                            "force-active": true,
                            maxlength: _vm.notAvailableMaxChar,
                            label: _vm.$translate(
                              "events.decline_popup.not_available_textarea_label"
                            ),
                            placeholder: _vm.$translate(
                              "events.decline_popup.not_available_textarea_placeholder",
                              { client_name: _vm.selectedEvent.client.name }
                            )
                          },
                          model: {
                            value: _vm.message,
                            callback: function($$v) {
                              _vm.message = $$v
                            },
                            expression: "message"
                          }
                        },
                        [
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "input-helper grey-text text-darken-1 right"
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.getNotAvailableMaxChar(_vm.message)
                                    ) +
                                    "\n          "
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1520340881
            )
          })
        : _vm.isNotComforableScreen
        ? _c("event-declined-uncomfortable", {
            attrs: {
              "client-name": _vm.selectedEvent.client.name,
              error_form: _vm.error_form
            },
            scopedSlots: _vm._u([
              {
                key: "input",
                fn: function() {
                  return [
                    _vm.error_form.not_comfortable
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "grey darken-1 padding-md border-rad-sm margin-bottom-md left-align",
                            attrs: {
                              id: "event-declined-uncomfortable-error-form"
                            }
                          },
                          [
                            _c("small", { staticClass: "white-text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "events.decline_popup.not_comfortable_error"
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "md-textarea",
                      {
                        staticClass:
                          "textfield-box-pink textfield-box-large textfield-label-pink",
                        attrs: {
                          id: "event-declined-uncomfortable-textarea",
                          type: "multiline",
                          "force-active": true,
                          maxlength: _vm.answerMaxChar,
                          label: _vm.$translate(
                            "events.decline_popup.not_comfortable_textarea_label"
                          ),
                          placeholder: _vm.$translate(
                            "events.decline_popup.not_comfortable_textarea_placeholder"
                          )
                        },
                        model: {
                          value: _vm.message,
                          callback: function($$v) {
                            _vm.message = $$v
                          },
                          expression: "message"
                        }
                      },
                      [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "input-helper grey-text text-darken-1 right"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.getMaxChar(_vm.message)) +
                                  "\n          "
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "margin-none" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.agree,
                            expression: "agree"
                          }
                        ],
                        staticClass: "mdi-checkbox-pink",
                        attrs: { id: "agree", type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.agree)
                            ? _vm._i(_vm.agree, null) > -1
                            : _vm.agree
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.agree,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.agree = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.agree = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.agree = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticStyle: {
                            color: "#2E353D",
                            "font-size": "14px"
                          },
                          attrs: { for: "agree" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$translate(
                                "events.decline_popup.not_comfortable_agree"
                              )
                            )
                          )
                        ]
                      )
                    ])
                  ]
                },
                proxy: true
              }
            ])
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isFinishScreen
        ? _c(
            "div",
            {
              staticClass: "declined-page",
              attrs: { id: "event-declined-finish-step" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "white-text padding-x-md padding-y-x3",
                  staticStyle: { background: "#8995A3" },
                  attrs: { id: "heading" }
                },
                [
                  _c("div", { staticClass: "container" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$translate("events.decline_popup.finish_title")
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("i", { staticClass: "cancel-icon material-icons" }, [
                    _vm._v("cancel")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "container padding-xl padding-x-xl padding-y-x3"
                },
                [
                  _c("p", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.answer == 1
                            ? _vm.$translate(
                                "events.decline_popup.finish_note_1"
                              )
                            : _vm.$translate(
                                "events.decline_popup.finish_note_2"
                              )
                        ) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-full btn-large margin-top-xl waves-effect waves-dark",
                      attrs: { id: "event-declined-finish-btn" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.$router.replace({ name: "events" })
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$translate("events.buttons.go_to_dashboard")
                          ) +
                          "\n      "
                      )
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isFinishScreen
        ? _c(
            "div",
            {
              staticClass: "container center navigator margin-bottom-xl",
              staticStyle: { padding: "0 32px" }
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-large btn-secondary waves-effect waves-dark display-inline-block",
                  attrs: { id: "event-declined-prevstep-btn" },
                  on: { click: _vm.prevStep }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$translate("events.buttons.back")) +
                      "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-large btn-krypton-pink waves-effect waves-light display-inline-block",
                  attrs: { id: "event-declined-nextstep-btn" },
                  on: { click: _vm.nextStep }
                },
                [
                  !_vm.isSending
                    ? _c("span", [_vm._v(_vm._s(_vm.nextStepText))])
                    : _c(
                        "div",
                        { staticClass: "preloader-wrapper small active" },
                        [_vm._m(0)]
                      )
                ]
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner-layer spinner-green-only" }, [
      _c("div", { staticClass: "circle-clipper left" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _c("div", { staticClass: "gap-patch" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _c("div", { staticClass: "circle-clipper right" }, [
        _c("div", { staticClass: "circle" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }