var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "support" } },
    [
      _c("md-navbar", {
        attrs: {
          push: { name: _vm.prevNav.name },
          brand: _vm.prevNav.title,
          icon: "arrow_back"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container", attrs: { id: "content" } },
        [
          _c("h1", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$translate("support.support_title")))
          ]),
          _vm._v(" "),
          _c(
            "vform",
            { ref: "form", on: { submit: _vm.submitForm } },
            [
              _vm.errors.has("support")
                ? _c(
                    "div",
                    {
                      staticClass:
                        "grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align"
                    },
                    [_c("small", [_vm._v(_vm._s(_vm.errors.first("support")))])]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("md-input", {
                attrs: {
                  type: "text",
                  label: _vm.$translate("support.subject_label"),
                  required: ""
                },
                model: {
                  value: _vm.subject,
                  callback: function($$v) {
                    _vm.subject = $$v
                  },
                  expression: "subject"
                }
              }),
              _vm._v(" "),
              _c("md-textarea", {
                attrs: {
                  type: "multiline",
                  label: _vm.$translate("support.message_label")
                },
                model: {
                  value: _vm.message,
                  callback: function($$v) {
                    _vm.message = $$v
                  },
                  expression: "message"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("h-footer-btns", {
            attrs: {
              cancel: _vm.$translate("app.cancel"),
              confirm: _vm.$translate("app.send")
            },
            on: {
              cancel: function($event) {
                return _vm.$router.go(-1)
              },
              confirm: _vm.confirm
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }