var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reactivation" }, [
    _c("div", { staticClass: "container", attrs: { id: "content" } }, [
      _c(
        "h2",
        {
          staticClass: "title",
          attrs: { "data-test": "reactivation-success-title" }
        },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _vm._v(" "),
      _c("img", {
        staticClass: "icon",
        attrs: {
          "data-test": "reactivation-success-icon",
          src: "/img/reactivation/success.svg",
          alt: "lock icon"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "description-box",
          attrs: { "data-test": "reactivation-success-explanation-text" }
        },
        [_c("strong", [_vm._v(_vm._s(_vm.subtitle))])]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }