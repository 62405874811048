import ApiProvider from './ApiProvider'

class NotificationProvider {
  get () {
    return new Promise((resolve, reject) => {
      window.api.get({ url: ApiProvider.appNotifications() },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response.data) })
    })
  }

  seen (field) {
    return new Promise((resolve, reject) => {
      const payload = {}

      payload[field] = 0

      window.api.put({
        url: ApiProvider.notificationSeen(),
        payload
      },
      (success) => { resolve(success.data) },
      (err) => { reject(err.response.data) })
    })
  }
}

export default new NotificationProvider()
