<template>
  <div id="billing-form" class="billing-form">
    <div id="content" class="container">
      <h5>
        {{ $translate('billing.form.title') }}
      </h5>
      <p>
        {{ $translate('billing.form.explanation_text') }}
      </p>
      <div class="inputfield-container">
        <md-input
          v-model="billing.address"
          type="text"
          :label="$translate('billing.form.address')"
          required />
      </div>
      <div class="inputfield-container">
        <md-input
          v-model="billing.post_code"
          type="text"
          :label="$translate('billing.form.postcode')"
          required />
      </div>
      <div class="inputfield-container">
        <md-input
          v-model="billing.city"
          class="textfield-box-lg"
          :class="{
            'valid': billing.city,
          }"
          type="text"
          :label="$translate('billing.form.city')" />
      </div>
      <div class="inputfield-container">
        <md-select
          v-if="countries.length > 0"
          v-model="billing.country"
          class="textfield-box-lg"
          :class="{
            'valid': billing.country,
          }"
          :force-mobile="true"
          :label="$translate('billing.form.country')">
          <option v-for="(country, idx) in countries"
                  slot="options"
                  :key="idx"
                  :value="country.alpha2Code">{{ country.name }}</option>
        </md-select>
      </div>
      <div class="margin-bottom-x4" />
      <button
        :disabled="!isFormComplete"
        class="action-btn action-btn__primary waves-effect waves-light display-block waves-effect waves-light cta-btn"
        @click="doSubmit">
        <span v-if="!isSending">
          {{ $translate('billing.form.cta_button') }}
        </span>
        <div v-else class="preloader-wrapper small active">
          <div class="spinner-layer spinner-green-only">
            <div class="circle-clipper left">
              <div class="circle" />
            </div><div class="gap-patch">
              <div class="circle" />
            </div><div class="circle-clipper right">
              <div class="circle" />
            </div>
          </div>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const VerificationProvider = $h.getProvider('VerificationProvider')

export default {
  data: () => ({
    isSending: false
  }),
  computed: {
    ...mapGetters({
      billing: 'billing/getBilling',
      countries: 'getCountries',
      cities: 'getCities'
    }),
    isFormComplete () {
      return this.billing.post_code &&
             this.billing.address &&
             this.billing.country &&
             this.billing.city
    }
  },
  mounted () {
    this.$store.dispatch('fetchCountries')
  },
  methods: {
    doSubmit () {
      this.isSending = true

      VerificationProvider
        .updateBillingDetails(this.billing)
        .then(() => {
          this.$router.replace({ name: 'dashboard' })
        })
        .catch(() => {})
        .finally(() => {
          this.isSending = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.inputfield-container {
  margin-bottom: 16px;

  .md-select--NEW_APV_REG_REDESIGN {
    width: 100%;
  }
}

.cta-btn {
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 20px;
  width: 90%;
  max-width: 600px;
  z-index: 11;

  &.scroll-on-small-screen {
    @media only screen and (max-height: 568px) {
      position: relative;
      margin-top: 24px;
    }
  }
}

#billing-form {
  width: 100%;
  height: 100dvh;
  background-color: white;

  #content.container {
    background-color: white;
    text-align: center;

    h5, p {
      margin: 0;
      color: #2F3740;
    }

    h5 {
      margin-top: 29px;
      font-size: 26px;
    }

    p {
      font-size: 16px;
      margin: 24px 0 34px 0;
    }

    .action-btn.action-btn__primary {
      background: transparent linear-gradient(262deg, #FF0073 0%, #DC0064 100%) 0% 0% no-repeat padding-box;
    }
  }
}
</style>
