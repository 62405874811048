var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "rating-box",
      class: { "no-rating": !_vm.hasRating },
      attrs: { id: "reliability-rating" }
    },
    [
      _c("h5", [
        _vm._v(
          _vm._s(
            _vm.$translate("provider_performance.overview.reliability_title")
          )
        )
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "rating" },
        _vm._l(_vm.renderedPoints, function(type, idx) {
          return _c("li", { key: idx }, [
            _c("i", { staticClass: "mdi", class: _vm.ratingIconClass(type) })
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.hasRating
        ? _c("p", [_vm._v(_vm._s(_vm.ratingText))])
        : _c("p", [
            _vm._v(
              _vm._s(_vm.$translate("provider_performance.rating.no_rating"))
            )
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }