<template>
    <div class="modal left-align">
        <div class="modal-content">
            <h4 class="modal-title">{{ this.$translate('events.rate.leave_title') }}</h4>
            <p class="grey-text text-darken-1">{{ this.$translate('events.rate.leave_note') }}</p>
        </div>
        <div class="modal-footer">
            <button class="btn-flat waves-effect waves-dark" @click="confirm">
                {{ $translate('events.buttons.leave') }}
            </button>
            <button class="btn-flat waves-effect waves-dark krypton-pink-text modal-close">
                {{ $translate('events.buttons.stay_here') }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        confirm($e) {
            this.$emit('confirm', $e);
        }
    }
}
</script>
