var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      }
    },
    [
      _c(
        "fieldset",
        { attrs: { disabled: _vm.onTransit } },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.onTransit
        ? _c(
            "div",
            { staticClass: "vform-preloader-wrapper" },
            [_c("md-preloader", { attrs: { color: "krypton-pink" } })],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }