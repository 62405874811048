<template>
  <div id="details" class="wrapper">
    <md-new-navbar route-name="profile" />
    <div v-if="ready" id="content" class="container">
      <h1 class="title">{{ $translate('profile.edit_information') }}</h1>
      <img
        src="/img/profile-details/basic-info-header-img.svg"
        alt="Basic Info"
        class="header-img"
      >
      <vform
        ref="form"
        class="details-form"
        @submit="save"
      >
        <!-- Eye Color -->
        <div
          v-if="user && dropdown && optsEyeColor.length"
          class="color-switcher-container"
        >
          <div class="color-selection-label">{{ $translate('profile.eyes') }}</div>
          <div class="color-swatch">
            <div
              v-for="(eyeColor, i) in optsEyeColor"
              :key="i"
              :class="eyeColor.key"
              @click.prevent="setSelectedEyeColor(eyeColor.key)"
            >
              <img
                v-if="isEyeColorSelected(eyeColor.key)"
                src="/img/registration/ic_check_color_selection.svg"
                alt="checkmark"
              >
            </div>
          </div>
        </div>

        <!-- Hair Color -->
        <div
          v-if="user && dropdown && optsHairColor.length"
          class="color-switcher-container spaced-top"
        >
          <div class="color-selection-label">
            {{ $translate('profile.hair') }}
          </div>
          <div class="color-swatch hair-color">
            <div
              v-for="(hairColor, i) in optsHairColor"
              :key="i"
              :class="hairColor.key"
              @click.prevent="setSelectedHairColor(hairColor.key)"
            >
              <img
                v-if="isHairColorSelected(hairColor.key)"
                src="/img/registration/ic_check_color_selection.svg"
                alt="checkmark"
              >
            </div>
          </div>
        </div>

        <!-- Height -->
        <md-select
          v-if="user && dropdown && optsHeight.length"
          v-model="payload.height"
          class="spaced-top"
          :label="$translate('profile.height')"
          :placeholder="$translate('details.height')"
          :force-mobile="true"
          :new-design="true"
        >
          <option
            v-for="opt in optsHeight"
            slot="options"
            :key="opt.key"
            :value="opt.key"
          >{{ opt.content }}</option>
          <div v-show="errors.has('height')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('height') }}
          </div>
        </md-select>

        <!-- Weight -->
        <md-select
          v-if="user && dropdown && optsWeight.length"
          v-model="payload.weight"
          :label="$translate('profile.weight')"
          :placeholder="$translate('details.weight')"
          :force-mobile="true"
          :new-design="true"
        >
          <option
            v-for="opt in optsWeight"
            slot="options"
            :key="opt.key"
            :value="opt.key"
          >{{ opt.content }}</option>
          <div v-show="errors.has('weight')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('weight') }}
          </div>
        </md-select>

        <!-- Bra Cup -->
        <md-select
          v-if="user && dropdown && optsBraCup.length"
          v-model="bra_cup"
          :label="$translate('profile.bra')"
          :placeholder="$translate('details.bra')"
          :force-mobile="true"
          :new-design="true"
        >
          <option
            v-for="opt in optsBraCup"
            slot="options"
            :key="opt.key"
            :value="opt.key"
          >{{ opt.content }}</option>
          <div v-show="errors.has('bra_cup')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('bra_cup') }}
          </div>
        </md-select>
        <div v-if="bra_cup" class="margin-bottom switch-container">
          <div class="left-align padding-right-md bra-cup-label">{{ $translate('register.basic.bra_cup.natural') }}</div>
          <div class="right" :class="{
            'switch-pill--APV_REG_REDESIGN': true
          }">
            <span :class="{ 'active': natural === false }" @click.prevent="setNatural(false)">
              {{ $translate('register.basic.bra_cup.natural_no') }}
            </span>
            <span :class="{ 'active': natural }" @click.prevent="setNatural(true)">
              {{ $translate('register.basic.bra_cup.natural_yes') }}
            </span>
          </div>
        </div>

        <!-- Dress Size -->
        <md-select
          v-if="user && dropdown && optsDressSize.length"
          v-model="payload.dress_size"
          :label="$translate('profile.dress')"
          :placeholder="$translate('details.dress')"
          :force-mobile="true"
          :new-design="true"
        >
          <option
            v-for="(opt, i) in optsDressSize"
            slot="options"
            :key="i"
            :value="opt.key">{{ opt.content }}</option>
          <div v-show="errors.has('dress_size')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('dress_size') }}
          </div>
        </md-select>

        <!-- Origin -->
        <md-select
          v-if="user && dropdown && optsOrigin.length"
          v-model="payload.origin"
          :label="$translate('profile.origin')"
          :placeholder="$translate('details.origin')"
          :force-mobile="true"
          :new-design="true"
        >
          <option
            v-for="(opt, i) in optsOrigin" slot="options"
            :key="i"
            :value="opt.key">{{ opt.content }}</option>
          <div v-show="errors.has('origin')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('origin') }}
          </div>
        </md-select>

      </vform>

      <h-footer-btns-apv-redesign
        :cancel="$translate('app.cancel')"
        :confirm="$translate('app.save')"
        @cancel="$router.push({ name: 'profile' })"
        @confirm="submitButton"
      />
    </div>
  </div>
</template>

<script>
const UserProvider = $h.getProvider('UserProvider')

export default {
  data () {
    return {
      natural: false,
      bra_cup: false,
      /// /////////
      payload: {
        eye_color: null,
        hair_color: null
      },
      /// /////////
      optsLanguages: [],
      optsEyeColor: [],
      optsHairColor: [],
      optsHeight: [],
      optsWeight: [],
      optsBraCup: [],
      optsDressSize: [],
      optsOrigin: []
    }
  },
  computed: {
    ready () {
      return this.dropdown && this.user
    },
    dropdown () {
      return this.$store.getters.getDropdown
    },
    user () {
      return this.$store.getters.getUser
    },
    homeBaseCountry () {
      return this.$store.getters.getHomeBaseCountry
    },
    isEyeColorSelected () {
      return color => {
        return this.payload.eye_color === color
      }
    },
    isHairColorSelected () {
      return color => {
        return this.payload.hair_color === color
      }
    }
  },
  watch: {
    dropdown () {
      this.initDropdown()
    },
    bra_cup () {
      this.analyzeBraCup()
    },
    natural () {
      this.analyzeBraCup()
    }
  },
  mounted () {
    this.initPayload()
    this.initDropdown()
    this.generateHeightOpts()
    this.generateWeightOpts()
    this.generateDressSizeOpts()
  },
  methods: {
    setSelectedEyeColor (color) {
      this.payload.eye_color = color
    },
    setSelectedHairColor (color) {
      this.payload.hair_color = color
    },
    reInitiateOptions () {
      this.$root.$emit('parent-reinitiate-options')
    },
    submitButton () {
      this.$refs.form.submit()
    },
    save (next) {
      this.errors.clear()

      UserProvider.updateBasicDetails(this.payload)
        .then((res) => {
          this.$notifications.toast(this.$translate('profile.profile_updated'), 5000)
          this.$store.commit('setProfile', res.data)
          this.$store.commit('setCuisine', res.data.cuisine)
          this.$store.commit('setLang', {
            lang_1: res.data.lang_1,
            lang_2: res.data.lang_2,
            lang_3: res.data.lang_3
          })
          this.$router.push({ name: 'profile' })
        })
        .catch((err) => {
          $h.processErrors(this, err.data)
        })
        .finally(() => {
          next()
        })
    },
    setPayloadProp (key, val) {
      if (val === undefined) val = null
      this.payload[key] = val
    },
    setNatural (val) {
      this.natural = val
    },
    analyzeBraCup () {
      if (this.bra_cup == null || this.natural == null) { return this.payload.bra_cup = null }

      if (this.bra_cup && this.natural) { return this.payload.bra_cup = this.bra_cup + 'n' } else { return this.payload.bra_cup = this.bra_cup }
    },
    initPayload () {
      this.payload.city_id = this.user.profiles.city_id
      this.payload.eye_color = this.user.profiles.eye_color
      this.payload.hair_color = this.user.profiles.hair_color
      this.payload.height = this.user.profiles.height
      this.payload.weight = this.user.profiles.weight
      this.payload.bra_cup = this.user.profiles.bra_cup
      this.payload.dress_size = this.user.profiles.dress_size
      this.payload.origin = this.user.profiles.origin

      if (!this.payload.bra_cup) return

      if (this.payload.bra_cup.length == 1) {
        this.bra_cup = this.payload.bra_cup[0]
        this.natural = false
      } else {
        this.bra_cup = this.payload.bra_cup[0]
        this.natural = true
      }
    },
    initDropdown () {
      if (!this.dropdown) return

      this.optsEyeColor = $h.obj2arr(this.dropdown.eye_color)
      this.optsHairColor = $h.obj2arr(this.dropdown.hair_color)
      this.optsOrigin = $h.obj2arr(this.dropdown.origin).filter((origin) => {
        let excludeOrigin = []
        if (this.homeBaseCountry.toLowerCase() === 'de') {
          excludeOrigin = ['uk', 'ch', 'at']
        } else if (this.homeBaseCountry.toLowerCase() === 'gb') {
          excludeOrigin = ['de', 'ch', 'at']
        }

        return !excludeOrigin.includes(origin.key)
      })

      // Bra Cup
      var cups = ['A', 'B', 'C', 'D', 'E', 'F', 'G']

      for (var i = 0; i < cups.length; i++) {
        this.optsBraCup.push({
          key: cups[i],
          content: cups[i]
        })
      }
    },
    generateHeightOpts () {
      for (var i = 150; i <= 200; i++) {
        this.optsHeight.push({ key: i, content: i + ' cm' })
      }
    },
    generateWeightOpts () {
      for (var i = 40; i <= 100; i++) {
        this.optsWeight.push({ key: i, content: i + ' kg' })
      }
    },
    generateDressSizeOpts () {
      for (var i = 32; i <= 44; i++) {
        if (i % 2 != 0) {
          continue
        }

        this.optsDressSize.push({ key: i, content: 'Size ' + i })
      }
    }
  }
}
</script>
