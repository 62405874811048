var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: _vm.cssVars, attrs: { id: "faq_header__container" } },
    [
      _c("div", { attrs: { id: "faq__header" } }, [
        _c(
          "div",
          { attrs: { id: "faq_header__main_container" } },
          [
            _c("md-faq-navbar", {
              attrs: { "route-name": _vm.headerNavbarRedirection }
            }),
            _vm._v(" "),
            _c("h1", {
              attrs: { "data-test": "faq-title" },
              domProps: { textContent: _vm._s(_vm.headerTitle) }
            }),
            _vm._v(" "),
            _c("img", {
              attrs: {
                "data-test": "faq-main-header-logo",
                src: _vm.mainHeaderLogo
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.hasTagline
        ? _c("div", { staticClass: "faq_header__tagline_container" }, [
            _c("p", {
              attrs: { "data-test": "faq-tagline-text" },
              domProps: { innerHTML: _vm._s(_vm.headerTagline) }
            }),
            _vm._v(" "),
            _vm.taglineLogo
              ? _c("img", {
                  attrs: {
                    "data-test": "faq-tagline-logo",
                    src: _vm.taglineLogo
                  }
                })
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }