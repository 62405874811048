var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value
    ? _c("div", { attrs: { id: "decline-terms-popup" } }, [
        _c(
          "div",
          {
            staticClass: "popup__backdrop",
            attrs: { "data-test": "sp-popup-backdrop" }
          },
          [
            _c("div", { staticClass: "popup__modal" }, [
              _c("div", { staticClass: "popup__title" }, [
                _c("h2", {
                  staticClass: "title",
                  attrs: { "data-test": "popup-title" },
                  domProps: { textContent: _vm._s(_vm.$t("title")) }
                })
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "popup__description",
                attrs: { "data-test": "popup-explanation-text" },
                domProps: { textContent: _vm._s(_vm.$t("description")) }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "popup__actions" }, [
                _c(
                  "button",
                  {
                    staticClass: "popup__cta cta_cancel btn btn-secondary",
                    attrs: { "data-test": "popup-cancel-cta" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.confirm($event)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("cancel_cta")))]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "popup__cta cta_confirm btn btn-black",
                    attrs: { "data-test": "popup-confirm-cta" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.cancel($event)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("confirm_cta")))]
                )
              ])
            ])
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }