<template>
  <div id="motto">
    <md-new-navbar route-name="profile" />
    <div id="content" class="container">
      <h1 class="title">{{ (hasMotto) ? $translate('motto.header') : $translate('motto.header_add') }}</h1>
      <img class="motto-img" src="/img//motto.svg" alt="motto">
      <vform ref="form" @submit="save">
        <md-textarea
          v-model="motto"
          rows="10"
          type="multiline"
          :maxlength="mottoMaxChar"
          :label="$translate('motto.motto')"
        >
          <div>
            <div v-show="errors.has('motto')" class="input-helper red-text center-align">
              {{ errors.first('motto') }}
            </div>
            <span class="right input-helper grey-text text-darken-1">{{ mottoCharLimit }}</span>
          </div>
        </md-textarea>
      </vform>
      <h-footer-btns-apv-redesign
        :cancel="$translate('app.cancel')"
        :disabled="isMottoEmpty"
        :confirm="$translate('app.save')"
        @cancel="$router.push({ name: 'profile' })"
        @confirm="confirm"
      />
    </div>
  </div>
</template>

<script>
const MOTTO_MAX_CHAR = 160

const UserProvider = $h.getProvider('UserProvider')

export default {
  data () {
    return {
      motto: null,
      mottoMaxChar: MOTTO_MAX_CHAR
    }
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    mottoLength () {
      if (!this.motto) return 0
      return this.motto.length
    },
    isMottoEmpty () {
      return this.mottoLength === 0
    },
    mottoCharLimit () {
      return `${this.mottoLength}/${this.mottoMaxChar}`
    },
    hasMotto () {
      return (this.user.profiles.slogan)
    }
  },
  mounted () {
    this.motto = this.user.profiles.slogan_de
  },
  methods: {
    save (next) {
      this.errors.clear()

      UserProvider.updateMotto({
        motto: this.motto
      })
        .then((res) => {
          this.$notifications.toast(this.$translate('profile.profile_updated'), 5000)
          this.$store.commit('setMotto', res.data.slogan)
          this.$store.commit('setMottoDe', res.data.slogan_de)
          this.$router.push({ name: 'profile' })
        })
        .catch((err) => {
          $h.processErrors(this, err.data)
        })
        .finally(() => {
          next()
        })
    },
    confirm () {
      this.$refs.form.submit()
    }
  }
}
</script>

<style lang="scss" scoped>

#motto {
    background-color: white;
    width: 100%;
    height: 100dvh;

    #content {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        padding: 5px 24px 0 24px;

        .title {
          color: #2F3740;
          font-size: 22px;
          line-height: 26px;
          margin-bottom: 36px;
          padding: 0;
        }

        form {
          margin-top: 45px;
        }

    }

}

</style>
