var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-page" }, [
    _c("div", { staticClass: "input-field" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.model,
            expression: "model"
          }
        ],
        ref: "textarea",
        class: _vm.textareaClass,
        attrs: {
          rows: _vm.rows,
          cols: _vm.cols,
          placeholder: _vm.placeholder,
          tabindex: _vm.tabindex,
          disabled: _vm.disabled,
          maxlength: _vm.maxlength
        },
        domProps: { value: _vm.model },
        on: {
          focus: function($event) {
            return _vm.onFocus()
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.model = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("label", { class: { active: _vm.isLabelActive } }, [
        _vm._v(_vm._s(_vm.label))
      ]),
      _vm._v(" "),
      _vm.maxlength
        ? _c("div", [
            _c(
              "span",
              { staticClass: "input-helper grey-text text-darken-1 right" },
              [_vm._v("\n        " + _vm._s(_vm.inputCount) + "\n      ")]
            )
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "overflow-modal", class: { active: _vm.showModal } },
      [
        _c("nav", { staticClass: "z-depth-0" }, [
          _c("div", { staticClass: "nav-wrapper container" }, [
            _c(
              "a",
              {
                staticClass: "brand-logo waves-effect waves-dark",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.showModal = false
                  }
                }
              },
              [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("arrow_back")
                ]),
                _vm._v(" "),
                _c("strong", [_vm._v(_vm._s(_vm.$translate("app.cancel")))])
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticClass: "right" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "uppercase",
                    attrs: {
                      id: "sidenav-trigger",
                      "data-activates": "sidenav"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.doSave()
                      }
                    }
                  },
                  [_c("strong", [_vm._v(_vm._s(_vm.$translate("app.save")))])]
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container", attrs: { id: "content" } }, [
          _c("div", { staticClass: "input-field" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.modelCopy,
                  expression: "modelCopy"
                }
              ],
              ref: "modaltextarea",
              class: _vm.textareaClass,
              attrs: {
                rows: _vm.rows,
                cols: _vm.cols,
                placeholder: _vm.placeholder,
                tabindex: _vm.tabindex,
                disabled: _vm.disabled,
                maxlength: _vm.maxlength
              },
              domProps: { value: _vm.modelCopy },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.modelCopy = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("label", { class: { active: _vm.isLabelActive } }, [
              _vm._v(_vm._s(_vm.label))
            ]),
            _vm._v(" "),
            _vm.maxlength
              ? _c("div", [
                  _c(
                    "span",
                    {
                      staticClass: "input-helper grey-text text-darken-1 right"
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.characterCount) +
                          "\n          "
                      )
                    ]
                  )
                ])
              : _vm._e()
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }