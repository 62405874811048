import Vue from 'vue'
import VueI18n from 'vue-i18n'

import de from './de/_de'
import en from './en/_en'

Vue.use(VueI18n)

const messages = {
  de,
  en
}

const locale = 'de'

export default new VueI18n({
  locale,
  messages,
  silentFallbackWarn: true
})
