var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal left-align", staticStyle: { "max-height": "80%" } },
    [
      _c(
        "div",
        {
          staticClass: "modal-content",
          staticStyle: { padding: "32px 24px 2px" }
        },
        [
          _c(
            "h4",
            {
              staticClass: "modal-title center",
              attrs: { id: "client-late-modal-minutes" }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    this.$translate("events.late.client_late_popup_title", {
                      client_name: _vm.clientName,
                      minutes_late: _vm.minutesLate
                    })
                  ) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "text-meeting-at center",
              staticStyle: { "font-size": "14px" },
              attrs: { id: "client-late-new-meeting-at-text" }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(this.$translate("events.late.new_meeting_time")) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "h4",
            {
              staticClass: "modal-title-new modal-title__meeting-at center",
              attrs: { id: "client-late-new-meeting-at" }
            },
            [_vm._v("\n      " + _vm._s(_vm.meetingAt) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "grey-text text-darken-1 center",
              staticStyle: { "font-size": "14px" }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    this.$translate(
                      "events.late.client_late_popup_description",
                      {
                        client_name: _vm.clientName
                      }
                    )
                  ) +
                  "\n    "
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-footer",
          staticStyle: { "border-top": "1px solid #E1E6EB" }
        },
        [
          _c(
            "a",
            {
              staticClass:
                "btn-flat btn-confirm waves-effect waves-dark krypton-pink-text",
              attrs: { id: "client-late-modal-confirm", href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.confirm($event)
                }
              }
            },
            [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    this.$translate("events.late.client_late_popup_button")
                  )
                )
              ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "alarm-icon center" }, [
      _c("i", { staticClass: "material-icons" }, [_vm._v("alarm")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }