import { mapGetters } from 'vuex'

export default {
  props: {
    payload: {
      type: Object,
      required: true
    },
    isClickable: {
      type: Boolean,
      default: true
    },
    showCountdown: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      activeEvent: 'events/getActiveEvent'
    }),
    month () {
      return this.payload._meeting_start_at.format('MMM YYYY').replace('.', '')
    },
    isSoon () {
      const is12HrsAway = this.payload._meeting_start_in_seconds <= $h.hrToSeconds(12)

      return this.isToday || is12HrsAway
    },
    isPending () {
      return this.payload._event_status === EVENT_STATUS.PENDING
    },
    isCancelled () {
      return (this.payload.status === TICKET_STATUS.CANCELLED && !this.isExpired) ||
             (this.isUnseenExpired && !this.isReactivated)
    },
    isExpired () {
      return this.payload._event_status === EVENT_STATUS.EXPIRED
    },
    isUnseenExpired () {
      return this.isExpired &&
             this.payload.event_seen === 0 &&
             this.payload.reactivation_status === REACTIVATION_STATUS.CAN_REACTIVATE
    },
    isRejected () {
      return this.payload._event_status === EVENT_STATUS.DECLINED
    },
    isReadyToGo () {
      return this.payload._event_status === EVENT_STATUS.CONFIRMED_READY
    },
    isReactivated () {
      return this.isExpired &&
             this.payload.reactivation_status === REACTIVATION_STATUS.REACTIVATED
    },
    isVideoDateMeeting () {
      return this.payload.from_video_form === 1
    },
    isOngoingMeeting () {
      return this.payload.status === TICKET_STATUS.MEETING
    },
    isPrivatePlaceMeeting () {
      return this.payload?.is_private_place === 1
    },
    isMultiLocationMeeting () {
      return this.payload?.is_multi_location === 1
    },
    isPrivatePlaceOrMultiLocationMeeting () {
      return this.isPrivatePlaceMeeting || this.isMultiLocationMeeting
    }
  },
  methods: {
    isEventNeedsFeedback (evt) {
      return evt.status === TICKET_STATUS.POST_MEETING &&
             evt._feedback_metadata.expiration_in_seconds > 0 &&
             evt._feedback_metadata.show === 1
    },
    gotoEventDetail () {
      if (!this.isClickable) return

      this.$store.commit('events/SET_SELECTED', this.payload)
      this.$store.commit('events/EVENT_SEEN', this.payload.id)

      const routerPayload = {}

      if (this.activeEvent && this.payload.id === this.activeEvent.id) {
        routerPayload.name = 'event-checkin'
      } else {
        if (this.isEventNeedsFeedback(this.payload)) {
          routerPayload.name = 'event-rate'
        } else {
          routerPayload.name = 'event-details'
        }
      }

      this.$router.push(routerPayload)
    }
  }
}
