/**
 * Get value of the specified key in the object, else return default value if not found.
 *
 * @param {Object} obj
 * @param {String} key
 * @param {Any} defaultValue
 *
 * @returns {Any}
*/
export function getPropertyOrDefault (obj, key, defaultValue = null) {
  if (obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return obj[key] || defaultValue
    }
  }
  return defaultValue
}
