var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "profile" } },
    [
      _c("md-new-navbar"),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c("div", { attrs: { id: "primary-photo" } }, [
          _vm.isPrimaryPhotoEmpty
            ? _c(
                "div",
                {
                  staticClass: "empty-photo-placeholder",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$router.push({ name: "photos-v2" })
                    }
                  }
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$translate("photos.v2.add_main_photo")))
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "add-photo-img",
                    attrs: {
                      src: "/img/profile-details/add-photo-img.svg",
                      alt: "Add Photo"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "description" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$translate("photos.v2.add_main_photo_explanation")
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "add-photo-icon-container" }, [
                    _c("img", {
                      staticClass: "add-photo-icon",
                      attrs: {
                        src: "/img/profile-details/add-photo-icon.svg",
                        alt: "Add Photo"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "add-photo-icon-desc" }, [
                      _vm._v(_vm._s(_vm.$translate("photos.v2.add_photo2")))
                    ])
                  ])
                ]
              )
            : _c("img", { attrs: { src: _vm.primaryPhoto._url } }),
          _vm._v(" "),
          _c("div", { staticClass: "manage-photo-container" }, [
            _c(
              "a",
              {
                staticClass:
                  "valign-middle waves-effect waves-dark padding-sm floating-cta",
                attrs: { id: "manage-photos" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$router.push({ name: "photos-v2" })
                  }
                }
              },
              [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("photo_library")
                ])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { id: "provider-vitals" } },
          [
            _c("h1", { attrs: { id: "nameAge" } }, [
              _vm._v(_vm._s(_vm.providerNameAge))
            ]),
            _vm._v(" "),
            _c("h2", { attrs: { id: "cityName" } }, [
              _vm._v(_vm._s(_vm.user.profiles._city_name))
            ]),
            _vm._v(" "),
            _vm.hasMotto
              ? _c("p", {
                  class: _vm.hasMotto ? "enquote" : "italic",
                  attrs: { id: "profile-motto" },
                  domProps: { innerHTML: _vm._s(_vm.providerMotto) }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.hasMotto
              ? _c("h-edit-btn", {
                  attrs: {
                    push: { name: "profile-motto" },
                    label: _vm.mottoBtnLabel,
                    redesign: true
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.hasMotto
              ? _c(
                  "div",
                  {
                    staticClass: "add-motto-cta",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.$router.push({ name: "profile-motto" })
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$translate("profile.add_motto")) +
                        "\n      "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "details-container" },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$translate("profile.basic_information")))
                ]),
                _vm._v(" "),
                _c("table", [
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$translate("profile.eyes")))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.providerEyeColor))])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$translate("profile.hair")))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.providerHairColor))])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$translate("profile.height")))
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.providerHeight))])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$translate("profile.weight")))
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.providerWeight))])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$translate("profile.bra")))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.providerBraCup))])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$translate("profile.dress")))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.providerDress))])
                  ])
                ]),
                _vm._v(" "),
                _c("h-edit-btn", {
                  attrs: {
                    push: { name: "profile-basic-details" },
                    redesign: true
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "details-container margin-top-12" },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$translate("profile.character")))
                ]),
                _vm._v(" "),
                _c("table", [
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$translate("profile.languages")))
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.providerLanguages))])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$translate("profile.interests")))
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.providerInterests))])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$translate("profile.job")))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.providerJob))])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$translate("profile.cuisine")))
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.providerCuisine))])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$translate("profile.drinks")))
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.providerDrinks))])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$translate("profile.smoking")))
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.providerSmoking))])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$translate("profile.tattoo")))
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.providerTattoo))])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$translate("profile.piercing")))
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.providerPiercing))])
                  ])
                ]),
                _vm._v(" "),
                _c("h-edit-btn", {
                  attrs: {
                    push: { name: "profile-character-details" },
                    redesign: true
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { id: "provider-interview" } },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$translate("profile.interview_title")))
                ]),
                _vm._v(" "),
                _vm._l(_vm.providerInterview, function(interview, idx) {
                  return _c("div", { key: idx, staticClass: "interview" }, [
                    _c("div", { staticClass: "interview-question" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$store.getters.getDropdownInterviews(
                              interview.title
                            )
                          ) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "interview-answer" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(interview.answer) +
                          "\n          "
                      )
                    ])
                  ])
                }),
                _vm._v(" "),
                _vm.hasInterview
                  ? _c("h-edit-btn", {
                      attrs: {
                        redesign: true,
                        push: { name: "profile-interview-v2" }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.hasInterview
                  ? _c("div", [
                      _c("div", { staticClass: "placeholder" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$translate("profile.interview_placeholder")
                            ) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "add-interview-cta",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.$router.push({
                                name: "profile-interview-v2"
                              })
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$translate("profile.add_interview_cta")
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _vm.shouldShowProviderPreferences
              ? _c(
                  "div",
                  {
                    staticClass: "provider-preferences",
                    attrs: { id: "provider-preferences" }
                  },
                  [
                    _c("div", { staticClass: "provider-preferences-title" }, [
                      _vm._v(
                        _vm._s(_vm.$translate("profile.preferences.title"))
                      )
                    ]),
                    _vm._v(" "),
                    _vm.hasSelectedPreferences
                      ? _c(
                          "div",
                          { staticClass: "spp-items-container" },
                          _vm._l(_vm.selectedNsfwPreferences, function(pp) {
                            return _c("div", { staticClass: "spp-item" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(pp.title) +
                                  "\n          "
                              )
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.hasSelectedPreferences
                      ? _c(
                          "div",
                          { staticClass: "provider-preferences-placeholder" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$translate(
                                  "profile.preferences.description"
                                )
                              )
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.hasSelectedPreferences
                      ? _c(
                          "button",
                          {
                            staticClass: "provider-preferences-cta",
                            on: { click: _vm.showPPBottomSheet }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$translate(
                                  "profile.preferences.cta_add_preferences"
                                )
                              )
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasSelectedPreferences
                      ? _c("h-edit-btn", {
                          attrs: { redesign: true },
                          on: { click: _vm.showPPBottomSheet }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      !_vm.showBottomStatusIndicator
        ? _c(
            "div",
            [
              _vm.shouldDisplayConnectPromo
                ? _c("krypton-connect-promo-banner", {
                    attrs: { "expanded-height": true }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.shouldDisplayPrivatePlacePromoBanner
                ? _c("private-place-promo-banner", {
                    attrs: { "expanded-height": true }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.shouldDisplayMultiLocationPromo_PRE_A2_1815
                ? _c("multi-location-promo-banner", {
                    attrs: { "expanded-height": true }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      true
        ? _c("provider-preferences-selection", {
            attrs: { "is-shown": _vm.isPPBottomSheetShown },
            on: {
              submit: _vm.submitProviderPreferences,
              close: _vm.closePPBottomSheet
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }