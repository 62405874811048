var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "billing-tab", attrs: { id: "billing-paid" } },
    [
      !_vm.isLoading
        ? [
            _vm.items.length === 0
              ? _c(
                  "div",
                  {
                    staticClass: "billing-placeholder",
                    attrs: { "data-test": "billing-placeholder" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/img/billing/billing-placeholder.svg",
                        alt: "no-invoice-placeholder"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "billing-placeholder__text" }, [
                      _vm._v(_vm._s(_vm.$t("paid.empty_placeholder")))
                    ])
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass: "billing-items",
                    attrs: { "data-test": "billing-items" }
                  },
                  _vm._l(_vm.items, function(item, index) {
                    return _c("billing-item", {
                      key: item.invoice_number,
                      attrs: { invoice: item, index: index },
                      on: { "item-collapse": _vm.toggleItem }
                    })
                  }),
                  1
                )
          ]
        : [
            _c("infinite-loading", {
              ref: "infiniteLoader",
              attrs: { direction: "top", spinner: "spiral" }
            })
          ],
      _vm._v(" "),
      _vm.showLoadMore
        ? _c(
            "a",
            {
              staticClass: "btn btn-outlined display-block waves-effect",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.loadMore($event)
                }
              }
            },
            [_vm._v("LOAD MORE")]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }