<template>
  <div v-if="isShown" class="email-setting-modal">
    <notice-modal
      :is-shown="isShown" :title="$t('title')"
      :text="$t('text', {email})" :confirm-text="$t('cta_text')"
      @confirm="() => $emit('confirm')"
    />
  </div>
</template>

<i18n src="@components/account/settings/translations/email-setting-success-popup.json"></i18n>

<script>
import noticeModal from '@components/v2/modal/notice-modal.vue'

export default {
  components: {
    noticeModal
  },
  props: {
    isShown: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
