var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "faq-item" }, [
    _vm.isItemTypeCheck
      ? _c("div", [
          _c("div", { staticClass: "text-content" }, [
            _c("img", {
              staticClass: "faq-item-checklist-icon",
              attrs: { src: "/img/faq/faq-green-checkmark.svg" }
            }),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.content))])
          ]),
          _vm._v(" "),
          _vm.img ? _c("img", { attrs: { src: _vm.img } }) : _vm._e()
        ])
      : _c("div", { staticClass: "flash-tip" }, [
          _c("img", {
            staticClass: "faq-item-checklist-icon",
            attrs: { src: "/img/faq/faq-orange-mark.svg" }
          }),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.content))])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }