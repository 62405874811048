var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "ul",
      {
        ref: "sidenav",
        staticClass: "side-nav side-nav--v2 right-aligned",
        class: {
          "has-status-indicator": _vm.showStatus,
          "side-nav--super-provider": _vm.isSuperProvider
        },
        attrs: { id: "sidenav" }
      },
      [
        _c("li", { staticClass: "nav-control" }, [
          _c(
            "div",
            {
              attrs: { id: "sidenav-close" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.closeSidenav($event)
                }
              }
            },
            [
              _c("i", { staticClass: "material-icons right" }, [
                _vm._v("close")
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-user" }, [
          _c("div", { attrs: { id: "sidenav-user" } }, [
            _c("strong", [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$translate("sidenav.header", {
                      provider: _vm.user.profiles.name
                    })
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _vm.isSuperProvider
              ? _c("img", {
                  attrs: {
                    src: "/img/super-provider/super-provider-diamond.svg"
                  }
                })
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "router-link waves-effect waves-dark",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.navigatePage("dashboard", false)
                }
              }
            },
            [
              _c(
                "i",
                {
                  staticClass: "material-icons",
                  staticStyle: { "margin-right": "19px !important" }
                },
                [_vm._v("home")]
              ),
              _vm._v(" "),
              _c("strong", [
                _vm._v(_vm._s(_vm.$translate("sidenav.dashboard")))
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _vm.user.availability_enabled
          ? _c("li", [
              _c(
                "a",
                {
                  staticClass: "router-link waves-effect waves-dark",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.navigatePage("availability", false)
                    }
                  }
                },
                [
                  _c(
                    "i",
                    {
                      staticClass: "material-icons",
                      staticStyle: { "margin-right": "19px !important" }
                    },
                    [_vm._v("assignment_turned_in")]
                  ),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$translate("sidenav.availability")))
                  ])
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.user.events_enabled
          ? _c("li", [
              _c(
                "a",
                {
                  staticClass: "router-link waves-effect waves-dark",
                  class: {
                    "feature-lock-indicator": _vm.isFeatureLocked,
                    "lilac-left lilac--red": _vm.hasEventUpdates
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.navigatePage("events", true)
                    }
                  }
                },
                [
                  _c(
                    "i",
                    {
                      staticClass: "material-icons",
                      staticStyle: { "margin-right": "19px !important" }
                    },
                    [_vm._v("today")]
                  ),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$translate("sidenav.events")))
                  ])
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "feedback-menu menu-group",
            class: { "menu-group--expanded": _vm.feedbackExpanded }
          },
          [
            _c(
              "a",
              {
                staticClass: "router-link waves-effect waves-dark",
                class: {
                  "feature-lock-indicator": _vm.isFeatureLocked,
                  "lilac-left lilac--red": _vm.hasFeedbackUpdates
                },
                attrs: { href: "#" }
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    staticStyle: { "margin-right": "19px !important" },
                    on: {
                      click: function($event) {
                        return _vm.navigatePage("feedback", true)
                      }
                    }
                  },
                  [_vm._v("star")]
                ),
                _vm._v(" "),
                _c(
                  "strong",
                  {
                    on: {
                      click: function($event) {
                        return _vm.navigatePage("feedback", true)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$translate("sidenav.feedback")))]
                ),
                _vm._v(" "),
                !_vm.isFeatureLocked
                  ? _c(
                      "i",
                      {
                        staticClass: "material-icons drop-icon right",
                        on: {
                          click: function($event) {
                            return _vm.toggleMenuGroup("feedbackExpanded")
                          }
                        }
                      },
                      [_vm._v("arrow_drop_down")]
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticClass: "sub-links" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "waves-effect waves-dark super-provider",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.navigatePage("super-provider", false)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$translate("sidenav.super_provider")))]
                )
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "inbox-menu menu-group",
            class: {
              "menu-group--expanded": _vm.inboxExpanded && !_vm.isFeatureLocked
            }
          },
          [
            _c(
              "a",
              {
                staticClass: "router-link waves-effect waves-dark",
                class: {
                  "lilac-left lilac--red":
                    (_vm.hasInboxNotification || _vm.hasFeedbackUpdates) &&
                    !_vm.isFeatureLocked,
                  "feature-lock-indicator": _vm.isFeatureLocked
                },
                attrs: { href: "#" }
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    staticStyle: { "margin-right": "19px !important" },
                    on: {
                      click: function($event) {
                        return _vm.navigatePage("inbox", true, {
                          filter: _vm.INBOX_FILTER.ALL
                        })
                      }
                    }
                  },
                  [_vm._v("notifications_active")]
                ),
                _vm._v(" "),
                _c(
                  "strong",
                  {
                    on: {
                      click: function($event) {
                        return _vm.navigatePage("inbox", true, {
                          filter: _vm.INBOX_FILTER.ALL
                        })
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$translate("sidenav.inbox.title")))]
                ),
                _vm._v(" "),
                !_vm.isFeatureLocked
                  ? _c(
                      "i",
                      {
                        staticClass: "material-icons drop-icon right",
                        on: {
                          click: function($event) {
                            return _vm.toggleMenuGroup("inboxExpanded")
                          }
                        }
                      },
                      [_vm._v("arrow_drop_down")]
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticClass: "sub-links" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "waves-effect waves-dark inbox-connect",
                    class: {
                      "lilac-left lilac--red":
                        _vm.hasKryptonConnectNotification &&
                        !_vm.isFeatureLocked
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.navigatePage("inbox", true, {
                          filter: _vm.INBOX_FILTER.CONNECT
                        })
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$translate("sidenav.inbox.connect")))]
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "waves-effect waves-dark inbox-review",
                    class: {
                      "lilac-left lilac--red":
                        _vm.hasFeedbackUpdates && !_vm.isFeatureLocked
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.navigatePage("inbox", true, {
                          filter: _vm.INBOX_FILTER.REVIEWS
                        })
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$translate("sidenav.inbox.reviews")))]
                )
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "router-link waves-effect waves-dark",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.navigatePage("profile", false)
                }
              }
            },
            [
              _c(
                "i",
                {
                  staticClass: "material-icons",
                  staticStyle: { "margin-right": "19px !important" }
                },
                [_vm._v("face_4")]
              ),
              _vm._v(" "),
              _c("strong", [_vm._v(_vm._s(_vm.$translate("sidenav.profile")))])
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "billing-menu menu-group",
            class: { "menu-group--expanded": _vm.billingExpanded }
          },
          [
            _c(
              "a",
              {
                staticClass: "router-link waves-effect waves-dark",
                class: {
                  "feature-lock-indicator": _vm.isFeatureLocked,
                  "lilac-left lilac--red":
                    _vm.hasMenuBillingUpdates && !_vm.isFeatureLocked
                },
                attrs: { href: "#" }
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    staticStyle: { "margin-right": "19px !important" },
                    on: {
                      click: function($event) {
                        return _vm.navigatePage("billing-v2-unpaid", true)
                      }
                    }
                  },
                  [_vm._v("receipt")]
                ),
                _vm._v(" "),
                _c(
                  "strong",
                  {
                    on: {
                      click: function($event) {
                        return _vm.navigatePage("billing-v2-unpaid", true)
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$translate("sidenav.billing_A2_2159.billing"))
                    )
                  ]
                ),
                _vm._v(" "),
                !_vm.isFeatureLocked
                  ? _c(
                      "i",
                      {
                        staticClass: "material-icons drop-icon right",
                        on: {
                          click: function($event) {
                            return _vm.toggleMenuGroup("billingExpanded")
                          }
                        }
                      },
                      [_vm._v("arrow_drop_down")]
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticClass: "sub-links" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "waves-effect waves-dark affiliate-menu",
                    class: {
                      "lilac-left lilac--red": _vm.hasMenuAffiliatesUpdates
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.navigatePage("affiliate-pending", false)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$translate("sidenav.billing_A2_2159.affiliate")
                        ) +
                        "\n          "
                    )
                  ]
                )
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "router-link waves-effect waves-dark",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.navigatePage("faq", false)
                }
              }
            },
            [
              _c(
                "i",
                {
                  staticClass: "material-icons",
                  staticStyle: { "margin-right": "19px !important" }
                },
                [_vm._v("info")]
              ),
              _vm._v(" "),
              _c("strong", { staticClass: "valign-middle" }, [
                _vm._v(_vm._s(_vm.$translate("sidenav.faq")))
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "router-link waves-effect waves-dark",
              class: {
                "lilac-left lilac--red": _vm.hasSupportUpdates
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.navigatePage("support-v2", false)
                }
              }
            },
            [
              _c(
                "i",
                {
                  staticClass: "material-icons",
                  staticStyle: { "margin-right": "19px !important" }
                },
                [_vm._v("contact_support")]
              ),
              _vm._v(" "),
              _c("strong", [_vm._v(_vm._s(_vm.$translate("sidenav.help")))])
            ]
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "router-link waves-effect waves-dark",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.navigatePage("setting", false)
                }
              }
            },
            [
              _c(
                "i",
                {
                  staticClass: "material-icons",
                  staticStyle: { "margin-right": "19px !important" }
                },
                [_vm._v("settings")]
              ),
              _vm._v(" "),
              _c("strong", [_vm._v(_vm._s(_vm.$translate("sidenav.settings")))])
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }