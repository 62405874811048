const LARGE_SCREEN_WIDTH = 992

const BOUNDARY_WIDTH = {
  MIN: 355,
  MAX: 395
}

function isLandscapeScreen () {
  return window.innerWidth > window.innerHeight
}

export function calculateThreeByFourRatio (value) {
  return Number((value * 4) / 3).toFixed(2)
}

function calculateSpacing (value) {
  return Math.round(value * 0.30) // 0.30 = 30%
}

export function calculateBoundarySize ({
  width = null,
  height = null
}) {
  const newWidth = width ?? ((window.innerWidth < LARGE_SCREEN_WIDTH) ? window.innerWidth : BOUNDARY_WIDTH.MAX)
  const newheight = height ?? calculateThreeByFourRatio(newWidth)

  return {
    width: newWidth,
    height: newheight
  }
}

export function calculateCropViewSize (boundarySize) {
  let spacing = calculateSpacing(boundarySize.width)
  let vpSize = boundarySize.width - spacing

  if (isLandscapeScreen()) {
    spacing = calculateSpacing(boundarySize.height)
    vpSize = boundarySize.height - spacing
  }

  return {
    width: vpSize,
    height: calculateThreeByFourRatio(vpSize)
  }
}
