export default {
  methods: {
    onEmailChange (val) {
      this.$emit('update:email', val)
    },
    doSubmit (next) {
      this.$emit('submit', next)
    }
  },
  props: {
    email: { type: String, default: '' },
    error: { type: String, default: '' }
  }
}
