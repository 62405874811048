const AuthProvider = window?.$h?.getProvider('AuthProvider')
const UserProvider = window?.$h?.getProvider('UserProvider')
const RegisterProvider = window?.$h?.getProvider('RegisterProvider')

export default {
  methods: {
    register (next) {
      this.errorMessages = {
        start: '',
        email: '',
        password: ''
      }

      const passwordPattern = /(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{6,}/
      const validPassword = passwordPattern.exec(this.password)

      if (!validPassword) {
        this.errorMessages.start = this.$translate('register.start.password_tip')
        next()
        return
      }

      RegisterProvider.register({
        email: this.email,
        password: this.password
      })
        .then((res) => {
          this.login(next)
        })
        .catch((err) => {
          for (const key in err.data) {
            this.errorMessages[key] = err.data[key][0]
          }

          next()
        })
    },
    login (next) {
      AuthProvider.login({
        email: this.email,
        password: this.password,
        register: 1
      })
        .then((res) => {
          var loginResult = res.data
          AuthProvider.setAuthorizationHeader(res.data.authorization)
          UserProvider.getUser()
            .then((res) => {
              this.$store.commit('setUser', res.data)
              localforage.setItem('authToken', btoa(loginResult.authorization), () => {
                this.$router.push({ name: 'register-agreement' })
              })
            })
            .catch((err) => {
              console.error(err.data)
            })
        })
        .catch((err) => {
          this.errorMessages.start = err.message
        })
        .finally(() => {
          next()
        })
    }
  },
  data () {
    return {
      email: null,
      password: null,
      errorMessages: {
        start: '',
        email: '',
        password: ''
      }
    }
  }
}
