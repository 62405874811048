var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "onboarding-lesson-card",
      class: [_vm.lessonCard.class],
      on: { click: _vm.onClickHandler }
    },
    [
      _c("div", { staticClass: "onboarding-lesson-card--container" }, [
        _c("div", { staticClass: "onboarding-lesson-card--details" }, [
          _c("h2", [_vm._v(_vm._s(_vm.$t(_vm.lessonCard.title)))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.$t("subtitle")))]),
          _vm._v(" "),
          _c("div", { staticClass: "cta" }, [
            _c("img", {
              attrs: {
                src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-video-player-v2.svg"),
                alt: "video player icon"
              }
            }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.$t("video")))])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }