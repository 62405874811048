export default {
  watch: {
    pulleeVal (newVal, oldVal) {
      if (newVal >= oldVal) {
        if (this.timeoutID) {
          clearTimeout(this.timeoutID)
        }
        if (newVal === 100) {
          this.slideEnd()
          return
        }

        this.timeoutID = setTimeout(() => {
          while (this.pulleeVal !== 0) {
            this.pulleeVal -= 1
          }
          this.pulleeVal = 0
          this.timeoutID = null
          clearTimeout(this.timeoutID)
        }, 100)
      }
    },
    isOnline () {
      if (!this.isOnline) {
        this.resetSlider()
      }
    }
  }
}
