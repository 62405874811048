var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-container", class: { "has-error": _vm.hasError } },
    [
      _c("div", { staticClass: "input-field apv-input-field" }, [
        _vm.type === "password"
          ? _c("input", {
              ref: "inputfield",
              staticClass: "apv-input-field--input",
              class: _vm.computedClasses,
              attrs: {
                type: _vm.checkingPasswordType,
                placeholder: _vm.placeholder,
                autocomplete: _vm.autocomplete,
                tabindex: _vm.tabindex,
                maxlength: _vm.maxlength,
                disabled: _vm.disabled
              },
              domProps: { value: _vm.value },
              on: {
                input: _vm.handleInputEvent,
                focus: _vm.handleFocusEvent,
                blur: _vm.handleBlurEvent,
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleKeyupEnterEvent($event)
                }
              }
            })
          : _c("input", {
              ref: "inputfield",
              staticClass: "apv-input-field--input",
              class: _vm.computedClasses,
              attrs: {
                type: _vm.type,
                placeholder: _vm.placeholder,
                autocomplete: _vm.autocomplete,
                tabindex: _vm.tabindex,
                maxlength: _vm.maxlength,
                disabled: _vm.disabled
              },
              domProps: { value: _vm.value },
              on: {
                input: _vm.handleInputEvent,
                focus: _vm.handleFocusEvent,
                blur: _vm.handleBlurEvent,
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleKeyupEnterEvent($event)
                }
              }
            }),
        _vm._v(" "),
        _c("label", { ref: "label" }, [_vm._v(_vm._s(_vm.label))]),
        _vm._v(" "),
        _vm.type !== "password"
          ? _c("img", {
              ref: "clearcta",
              staticClass: "clear-cta",
              attrs: { src: "/img/registration/input/clearcta.svg", alt: "X" },
              on: { click: _vm.clearAndFocusInput }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.type === "password"
          ? _c("img", {
              staticClass: "show-hide-cta",
              attrs: { src: _vm.showPasswordImgSrc },
              on: { click: _vm.showPassword }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            {
              staticClass: "error-msg-txt",
              attrs: { "data-test": "input-error-msg" }
            },
            [_vm._v(_vm._s(_vm.error))]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }