var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "add-primary-photo" } },
    [
      _c("h2", { staticClass: "center title" }, [
        _vm._v(_vm._s(_vm.$translate("photos.v2.add_main_photo")))
      ]),
      _vm._v(" "),
      _c("img", {
        attrs: { src: "/img/registration/photos/primary.svg", alt: "" }
      }),
      _vm._v(" "),
      _c("p", [
        _vm._v(_vm._s(_vm.$translate("photos.v2.add_main_photo_explanation")))
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "photo-gallery-v2-photo empty center",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.uploadphoto($event)
            }
          }
        },
        [
          _c("span", { staticClass: "add-photo-text" }, [
            _vm._v(_vm._s(_vm.$translate("photos.v2.add_photo2")))
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "photo-add" })
        ]
      ),
      _vm._v(" "),
      _c(
        "vform",
        { ref: "uploadMainForm", on: { submit: _vm.uploadMainPhoto } },
        [
          _c("input", {
            ref: "uploadMainFormFile",
            staticClass: "hide",
            attrs: { type: "file", accept: "image/*" },
            on: { change: _vm.uploadMainFormSubmit }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "add-more center-align", attrs: { href: "#" } },
      [_c("div", { staticClass: "add-btn" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }