var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "personality" } },
    [
      [
        _vm.$route.params.type == "intellect"
          ? _c("register-personality-intellect", {
              attrs: {
                total: _vm.stepper.total,
                current: _vm.stepper.current,
                "stepper-label": _vm.stepperLabel
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.$route.params.type == "taste"
          ? _c("register-personality-taste", {
              attrs: {
                total: _vm.stepper.total,
                current: _vm.stepper.current,
                "stepper-label": _vm.stepperLabel
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.$route.params.type == "style"
          ? _c("register-personality-style", {
              attrs: {
                total: _vm.stepper.total,
                current: _vm.stepper.current,
                "stepper-label": _vm.stepperLabel
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.$route.params.type == "name"
          ? _c("register-personality-name", {
              attrs: {
                total: _vm.stepper.total,
                current: _vm.stepper.current,
                "stepper-label": _vm.stepperLabel
              }
            })
          : _vm._e()
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }