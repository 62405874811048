<template>
  <div class="modal photo-error-modal">
    <div class="modal-content">
      <!-- Pending -->
      <div v-if="errorType === ERROR_TYPES.REVIEW_PENDING">
        <p class="status-label">
          {{ $translate('photos.error_modal.REVIEW_PENDING.label') }}
        </p>
        <p class="status-text">
          {{ errorMessage }}
        </p>
        <div class="error-img center">
          <img class="responsive-img" src="/img/photos/edit-error/error-5.png" alt="review-pending">
        </div>
      </div>

      <!-- Face not visible -->
      <div v-if="errorType === ERROR_TYPES.NO_FACE">
        <p class="status-label">
          {{ $translate('photos.error_modal.NO_FACE.label') }}
        </p>
        <p class="status-text">
          {{ errorMessage }}
        </p>
        <div class="error-img center">
          <img class="responsive-img" src="/img/photos/edit-error/error-1-new.png" alt="review-pending">
        </div>
      </div>

      <!-- Low resolution -->
      <div v-if="errorType === ERROR_TYPES.LOW_RESOLUTION">
        <p class="status-label">
          {{ $translate('photos.error_modal.LOW_RESOLUTION.label') }}
        </p>
        <p class="status-text">
          {{ errorMessage }}
        </p>
        <div class="error-img center">
          <img class="responsive-img" src="/img/photos/edit-error/error-4-new.png" alt="review-pending">
        </div>
      </div>

      <!-- Need full body -->
      <div v-if="errorType === ERROR_TYPES.NEED_FULL_BODY">
        <p class="status-label">
          {{ $translate('photos.error_modal.NEED_FULL_BODY.label') }}
        </p>
        <p class="status-text">
          {{ errorMessage }}
        </p>
        <div class="error-img center">
          <img class="responsive-img" src="/img/photos/edit-error/error-3.png" alt="review-pending">
        </div>
      </div>

      <!-- Too close -->
      <div v-if="errorType === ERROR_TYPES.TOO_CLOSE">
        <p class="status-label">
          {{ $translate('photos.error_modal.TOO_CLOSE.label') }}
        </p>
        <p class="status-text">
          {{ errorMessage }}
        </p>
        <div class="error-img center">
          <img class="responsive-img" src="/img/photos/edit-error/error-2.png" alt="review-pending">
        </div>
      </div>

      <!-- Too close edge -->
      <div v-if="errorType === ERROR_TYPES.TOO_CLOSE_EDGE">
        <p class="status-label">
          {{ $translate('photos.error_modal.TOO_CLOSE_EDGE.label') }}
        </p>
        <p class="status-text">
          {{ errorMessage }}
        </p>
        <div class="error-img center">
          <img class="responsive-img error-6-photo" src="/img/photos/edit-error/error-6.png" alt="review-pending">
        </div>
      </div>

      <!-- Api Exception -->
      <div v-if="errorType > 6">
        <p class="status-label">
          {{ error.error_title }}
        </p>
        <p class="status-text">
          {{ errorMessage }}
        </p>
      </div>
    </div>
    <div class="modal-footer">
      <a
        href="#"
        class="btn display-block modal-close"
      >
        {{ $translate('photos.error_modal.ok_button') }}
      </a>
    </div>
  </div>
</template>

<script>
const ERROR_TYPES = {
  NO_FACE: 1,
  TOO_CLOSE: 2,
  NEED_FULL_BODY: 3,
  LOW_RESOLUTION: 4,
  REVIEW_PENDING: 5,
  TOO_CLOSE_EDGE: 6
}

export default {
  props: [
    'error'
  ],
  data () {
    return {
      ERROR_TYPES,
      errorType: ERROR_TYPES.REVIEW_PENDING,
      errorMessage: ''
    }
  },
  mounted () {
    if (typeof this.error === 'string') {
      if (this.error.indexOf('Bitte lade ein Foto hoch, auf dem dein Gesicht zu sehen ist') >= 0) {
        this.errorType = ERROR_TYPES.NO_FACE
      } else if (this.error.indexOf('Bitte lade ein Foto mit höherer Auflösung hoch') >= 0) {
        this.errorType = ERROR_TYPES.LOW_RESOLUTION
      } else if (this.error.indexOf('Bitte lade ein Foto hoch, auf dem der Großteil deines Körpers zu sehen ist') >= 0) {
        this.errorType = ERROR_TYPES.NEED_FULL_BODY
      } else if (this.error.indexOf('Der obere Teil deines Kopfes ist zu nahe am Rand des Fotos.') >= 0) {
        this.errorType = ERROR_TYPES.TOO_CLOSE
      }
      this.errorMessage = this.error
    } else if (this.error.error_code && this.error.error_message) {
      this.errorType = this.error.error_code
      this.errorMessage = this.error.error_message
    } else if (this.error.file) {
      if (this.error.file[0].indexOf('Bitte lade ein Foto hoch, auf dem dein Gesicht zu sehen ist') >= 0) {
        this.errorType = ERROR_TYPES.NO_FACE
      } else if (this.error.file[0].indexOf('Bitte lade ein Foto mit höherer Auflösung hoch') >= 0) {
        this.errorType = ERROR_TYPES.LOW_RESOLUTION
      } else if (this.error.file[0].indexOf('Bitte lade ein Foto hoch, auf dem der Großteil deines Körpers zu sehen ist') >= 0) {
        this.errorType = ERROR_TYPES.NEED_FULL_BODY
      } else if (this.error.file[0].indexOf('Der obere Teil deines Kopfes ist zu nahe am Rand des Fotos.') >= 0) {
        this.errorType = ERROR_TYPES.TOO_CLOSE
      }
      this.errorMessage = this.error.file[0]
    } else {
      this.errorMessage = 'Unknown error has occured.'
    }
  }
}
</script>
