<template>
  <div
    id="login-mobile"
    class="login-page"
  >
    <div
      id="content"
      class="container"
    >
      <img
        src="/img/icons/kr-logo-144.png"
        width="48"
        style="border-radius: 4px;"
      >
      <div id="header">
        <div
          class="text-darken-1"
          style="line-height: 36px"
        >
          {{ $translate('login.header_line_1') }}
        </div>
        <div class="text-darken-1">
          {{ $translate('login.header_line_2') }}
        </div>
      </div>
      <vform @submit="doSubmit">
        <div
          v-if="error"
          id="login-mobile-error-msgs"
          class="grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align"
        >
          <small>{{ error }}</small>
        </div>
        <md-input
          id="login-mobile-username"
          data-cy="email-field"
          :value="username"
          type="text"
          class="textfield-box-lg"
          autocomplete="username"
          tabindex="1"
          :label="$translate('login.email')"
          @input="onUsernameChange"
        />
        <md-input
          id="login-mobile-password"
          data-cy="password-field"
          :value="password"
          type="password"
          class="textfield-box-lg"
          autocomplete="password"
          tabindex="2"
          :label="$translate('login.password')"
          @input="onPasswordChange"
        />
        <button
          id="login-mobile-submit-btn"
          data-cy="login-btn"
          type="submit"
          tabindex="3"
          class="btn btn-raised btn-large waves-effect waves-light display-block btn-krypton-pink waves-effect waves-light"
        >
          {{ $translate('login.continue') }}
        </button>
        <div
          class="margin-top-lg"
          style="font-size: 14px;"
        >
          <a
            id="login-mobile-forgotpass-link"
            href="#"
            @click.prevent="$router.push({ name: 'forgot-password' })"
          >{{ $translate('login.forgot_password') }}</a>
          <div>
            <span style="color: #757575;">{{ $translate('login.no_account_yet') }}</span>
            <a
              id="login-mobile-register-link"
              href="#"
              @click.prevent="$router.push({ name: 'register-start' })"
            >
              {{ $translate('login.register') }}
            </a>
          </div>
        </div>
      </vform>
    </div>
  </div>
</template>

<script>
import LoginViewController from './mixins/login-view.controller'

export default {
  mixins: [LoginViewController]
}
</script>
