<template>
  <div class="super-provider-card super-provider-card__req margin-bottom-md" :class="cardStatusClass" id="client-rating">
    <h2 class="super-provider-card__title margin-bottom-lg" v-text="title" data-test="title" />
    <div class="super-provider-card__value margin-bottom-md">
      <i class="material-icons margin-right-md" data-test="icon">star</i>
      <span data-test="value">{{ actualClientRating }}</span>
    </div>
    <div class="super-provider-card__tooltip-trigger" data-test="tooltip" @click="showTooltip">
      <span class="super-provider-card__tooltip-trigger-text" v-text="tooltipText"/>
      <i class="material-icons">help_outline</i>
    </div>
    <div class="super-provider-card__req-status" data-test="status">
      <span v-text="cardStatusText" />
      <i class="material-icons">{{ cardStatusIcon }}</i>
    </div>
  </div>
</template>

<i18n src="@/features/super-provider/translations/super-provider-card.json"></i18n>

<script>
import { tooltipPlugin } from '@/features/super-provider/plugins/vue-custom-tooltip.js'
import {
  DEFAULT_SUPER_PROVIDER_REQUIREMENTS,
  DEFAULT_SUPER_PROVIDER_STATUS,
} from '@/features/super-provider/constants/super-provider-defaults.js'

export default {
  inject: {
    superProviderData: {
      default: () => ({
        superProviderRequirements: DEFAULT_SUPER_PROVIDER_REQUIREMENTS,
        superProviderStatus: DEFAULT_SUPER_PROVIDER_STATUS,
      })
    }
  },
  computed: {
    minReqClientRating() {
      const { client_rating } = DEFAULT_SUPER_PROVIDER_REQUIREMENTS
      return parseFloat(this.superProviderData?.superProviderRequirements?.client_rating?.value ?? client_rating.value)
    },
    actualClientRating() {
      return this.superProviderData?.superProviderStatus?.client_rating ?? 0
    },
    parsedClientRating() {
      return parseFloat(this.actualClientRating)
    },
    title() {
      return this.$t('statusChecks.clientRating.title')
    },
    tooltipText() {
      return this.$t('statusChecks.clientRating.tooltipText', {value: this.minReqClientRating})
    },
    tooltipExplanation() {
      return this.$t('statusChecks.clientRating.tooltipExplanation')
    },
    onTrack() {
      // check value against condition here
      return this.parsedClientRating >= this.minReqClientRating;
    },
    cardStatusIcon() {
      return this.onTrack
        ? 'check'
        : 'clear'
    },
    cardStatusText() {
      return this.onTrack
        ? this.$t('statusText.onTrack')
        : this.$t('statusText.notMet')
    },
    cardStatusClass() {
      return this.onTrack ? '--success' : '--warning'
    }
  },
  methods: {
    showTooltip() {
      tooltipPlugin.toast(this.tooltipExplanation)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/features/super-provider/sass/super-provider-card";

#prime-time-availability {
  .prime-time {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    i {
      color: #F9A825;
    }

    span {
      line-height: 12px;
    }

    strong {
      font-family: 'Proxima Nova Semibold', sans-serif;
    }
  }
}
</style>
