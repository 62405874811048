<template>
  <div class="app-feedback-success-modal-container">
    <div class="app-feedback-success-popup">
      <h2>{{ $t('title') }}</h2>
      <p v-html="$t('contact_support')" />

      <div class="app-feedback-success-popup__footer">
        <a href="#" @click.prevent="$emit('close')">{{ $t('cta') }}</a>
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/app-feedback/translations/app-feedback-modal.json"></i18n>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.app-feedback-success-modal-container {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;

  .app-feedback-success-popup {
    width: 100%;
    min-height: 310px;
    border-radius: 12px;
    background-color: white;
    padding: 32px 24px 0;
    position: relative;
    top: 50% !important;
    transform: scalex(1) translate(0, -50%) !important;
    text-align: center;
    margin: 0 auto;

    @media screen and (max-width: 600px) {
      margin: 0 16px;
      width: 94%;
    }
    @media screen and (max-width: 420px) {
      width: 92%;
    }

    h2 {
      margin: 0 0 24px;
      font-size: 20px;
    }

    p {
      font-size: 18px;

      a {
        color: #E30074;
      }
    }
  }

  .app-feedback-success-popup__footer {
    width: 100%;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    ::before {
      content: " ";
      height: 1px;
      position: absolute;
      top: 0;
      left: -24px;
      background-color: #CAD1D9;
      display: block;
      width: 343px;
    }

    a {
      width: 148px;
      height: 48px;
      background: transparent;
      color: #E30074;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}
</style>
