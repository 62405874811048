<template>
  <div id="rates" class="wrapper">
    <template>
      <div class="content__area">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <h2 class="content__title content__title--desktop">{{ $translate('register.rates.title') }}</h2>
      </div>

      <div class="content__area">
        <div class="content">
          <h2 class="content__title content__title--mobile">{{ $translate('register.rates.title') }}</h2>

          <ul class="tiers">
            <li
              v-for="(availableTier, i) in getAvailableTiers"
              :key="i"
              ref="tiers"
              :class="[
                'select-tier-pill',
                {
                  'active selected-pill': selectedTier == availableTier.tier,
                }
              ]"
              :style="{'order': i+1}"
              @click="() => {
                selectTier(availableTier)
                arrangeTier(i)
              }"
            >
              <strong v-if="getCurrencySymbol.length === 1">{{ `${getCurrencySymbol}${availableTier.value}` }}</strong>
              <strong v-else>{{ `${availableTier.value}` }}<span class="smaller">{{ `${getCurrencySymbol}` }}</span> </strong>
              <span>2h</span>
            </li>
          </ul>

          <div class="rates-conditions">
            <ul>
              <li
                v-for="(checklistModel, i) in checklistModels"
                :key="i"
                class="checkedbox flex-justify-left-align-center padding-bottom-24" :data-checkbox-container="checklistModel"
              >
                <div :class="['checkbox-label', { 'unchecked': !getCheckboxStatus(checklistModel) }]">
                  <i class="material-icons" @click="onSelectInfo(checklistModel)">info</i>
                  <span>{{ $translate(`register.rates.checkbox_label_${checklistModel}`) }}</span>
                </div>
                <div class="checkbox-container">
                  <input
                    :id="checklistModel"
                    v-model="selectedChecklistModels"
                    :value="checklistModel"
                    type="checkbox"
                    @change="onCheckboxChange(checklistModel)"
                  >
                  <label :for="checklistModel" />
                </div>
              </li>
            </ul>
            <div :class="['information-container',
              {
                'unchecked': getSelectedInfoCheckStatus === 'unchecked'
              }
            ]">
              {{ $translate(`register.rates.explanation_${selectedInfoModel}_${getSelectedInfoCheckStatus}`) }}
            </div>
          </div>

          <div class="actions flex-grid space-between">
            <button class="btn btn-primary btn-verify" @click.prevent="submit">{{ $translate('register.rates.cta') }}</button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { CHECKED_TIERS, CURRENCY_SYMBOLS, CHECKED_DURATION_FEES, UNCHECKED_DURATION_FEES } from '@/features/registration/constants.js'
import { getTiers } from '@/services/TierService'

const RegisterProvider = $h.getProvider('RegisterProvider')

export default {
  data: () => ({
    isConfirmed: false,
    register_a: false,
    checklistModels: ['a', 'b', 'c'],
    selectedChecklistModels: ['a', 'b', 'c'],
    selectedInfoModel: 'a',
    availableTiers: [{ tier: 0, value: 350 }, { tier: 1, value: 450 }, { tier: 2, value: 550 }],
    availableTiersUnchecked: [{ tier: 0, value: 30 }, { tier: 1, value: 50 }, { tier: 2, value: 70 }],
    selectedTier: 1,
    durationsToShow: [4, 6, 12, 24],
    allTiers: null
  }),
  computed: {
    isCheckboxASelected () {
      return this.selectedChecklistModels.includes('a')
    },
    getAvailableTiers () {
      if (this.isCheckboxASelected) {
        return this.checkedTiersComputed[this.getHomeBaseCountry]
      }

      return this.availableTiersUnchecked
    },
    getFilteredDurationFees () {
      let filteredFees = {}

      let durationsToShow = this.durationsToShow

      if (this.getHomeBaseCountry) {
        if (this.getHomeBaseCountry === 'GB') {
          durationsToShow = [1, 4, 12, 24]
        }

        if (this.isCheckboxASelected) {
          const countryCode = this.checkedDurationFeeComputed.hasOwnProperty(this.getHomeBaseCountry) ? this.getHomeBaseCountry : 'DE'

          filteredFees = durationsToShow.map(i => ({ duration: i, fee: this.checkedDurationFeeComputed[countryCode][i][`tier_${this.selectedTier}`] }))
        } else {
          filteredFees = durationsToShow.map(i => ({ duration: i, fee: this.uncheckedDurationFeeComputed[this.getHomeBaseCountry][i][`tier_${this.selectedTier}`] }))
        }
      } else {
        const FEES = require('@/stores/events').DURATION_FEES

        filteredFees = this.durationsToShow.map(i => ({ duration: i, fee: FEES[i][`tier_${this.selectedTier}`] }))
      }

      return filteredFees
    },
    getSelectedInfoCheckStatus () {
      return this.selectedChecklistModels.includes(this.selectedInfoModel) ? 'checked' : 'unchecked'
    },
    getHomeBaseCountry () {
      return this.$store.getters.getHomeBaseCountry
    },
    getCurrencySymbol () {
      return this.currencySymbolsComputed[this.getHomeBaseCountry]
    },
    currencySymbolsComputed () {
      const symbols = CURRENCY_SYMBOLS

      if (!this.allTiers || CURRENCY_SYMBOLS.hasOwnProperty(this.getHomeBaseCountry)) {
        return symbols
      }

      const matchingTier = this.allTiers.find(i => i.country_code === this.getHomeBaseCountry)

      if (!matchingTier) {
        return symbols
      }

      const currency = String.fromCharCode(parseInt(matchingTier.currency, 16))

      symbols[this.getHomeBaseCountry] = currency === '\f' ? `${matchingTier.currency} ` : currency

      return symbols
    },
    checkedTiersComputed () {
      const tiers = CHECKED_TIERS

      if (!this.allTiers || CHECKED_TIERS.hasOwnProperty(this.getHomeBaseCountry)) {
        return tiers
      }

      const matchingTiers = this.allTiers.filter(i => i.country_code === this.getHomeBaseCountry)

      if (!matchingTiers.length) {
        return tiers
      }

      tiers[this.getHomeBaseCountry] = [
        { tier: 0, value: matchingTiers[0].rate_2hr },
        { tier: 1, value: matchingTiers[1].rate_2hr },
        { tier: 2, value: matchingTiers[2].rate_2hr }
      ]

      return tiers
    },
    checkedDurationFeeComputed () {
      const fees = CHECKED_DURATION_FEES

      if (!this.allTiers || CHECKED_DURATION_FEES.hasOwnProperty(this.getHomeBaseCountry)) {
        return fees
      }

      const matchingTiers = this.allTiers.filter(i => i.country_code === this.getHomeBaseCountry)

      if (!matchingTiers.length) {
        return fees
      }

      fees[this.getHomeBaseCountry] = {
        2: {
          tier_0: matchingTiers[0].rate_2hr,
          tier_1: matchingTiers[1].rate_2hr,
          tier_2: matchingTiers[2].rate_2hr
        },
        4: {
          tier_0: matchingTiers[0].rate_4hr,
          tier_1: matchingTiers[1].rate_4hr,
          tier_2: matchingTiers[2].rate_4hr
        },
        6: {
          tier_0: matchingTiers[0].rate_6hr,
          tier_1: matchingTiers[1].rate_6hr,
          tier_2: matchingTiers[2].rate_6hr
        },
        12: {
          tier_0: matchingTiers[0].rate_12hr,
          tier_1: matchingTiers[1].rate_12hr,
          tier_2: matchingTiers[2].rate_12hr
        },
        24: {
          tier_0: matchingTiers[0].rate_24hr,
          tier_1: matchingTiers[1].rate_24hr,
          tier_2: matchingTiers[2].rate_24hr
        }
      }

      return fees
    },
    uncheckedDurationFeeComputed () {
      const tiers = UNCHECKED_DURATION_FEES

      if (!this.allTiers || UNCHECKED_DURATION_FEES.hasOwnProperty(this.getHomeBaseCountry)) {
        return tiers
      }

      tiers[this.getHomeBaseCountry] = tiers.DE

      return tiers
    }
  },
  mounted () {
    getTiers()
      .then(res => {
        this.allTiers = res.data
      })
      .catch(err => {
        console.error(err)
      })
  },
  methods: {
    getCheckListModelValue (model) {
      return this.selectedChecklistModels.includes(model) ? 1 : 0
    },
    getCheckboxStatus (checklistModel) {
      return !!this.selectedChecklistModels.includes(checklistModel)
    },
    onCheckboxChange (model) {
      this.onSelectInfo(model)
    },
    onSelectInfo (model) {
      this.selectedInfoModel = model
    },
    selectTier (availableTier) {
      this.selectedTier = availableTier.tier
    },
    arrangeTier (index) {
      const clickedOrder = parseInt(this.$refs.tiers[index].style.order)

      if (clickedOrder === 3) {
        const activeToFirst = this.$refs.tiers.find((el) => {
          return parseInt(el.style.order) === 2
        })

        const firstToLast = this.$refs.tiers.find((el) => {
          return parseInt(el.style.order) === 1
        })

        activeToFirst.style.order = 1
        firstToLast.style.order = 3
      } else if (clickedOrder === 1) {
        const activeToLast = this.$refs.tiers.find((el) => {
          return parseInt(el.style.order) === 2
        })

        const lastToFirst = this.$refs.tiers.find((el) => {
          return parseInt(el.style.order) === 3
        })

        activeToLast.style.order = 3
        lastToFirst.style.order = 1
      }

      // set the active tier in css
      this.$refs.tiers[index].style.order = 2
    },
    publishProvider () {
      this.$router.push({ name: 'register-survey' })
    },
    submit () {
      RegisterProvider.setFees(this.selectedTier, this.getCheckListModelValue('a'))
        .then((res) => {
          this.$store.commit('setUser', res.data)
          this.publishProvider()
        })
        .catch((err) => {
          this.$notifications.toast(err.message, 5000)
        })
    }
  }
}
</script>

<style lang="scss">
#rates {

  .rates-conditions{
    ul {
      margin-bottom: 32px;
    }
    li {
      height: 44px;
      margin-bottom: 12px;
      padding: 0;
    }
    .checkbox-label span {
      padding-left: 10px;
      font-size: 18px;

      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
    #a, .checkbox-container {
      height: 44px;
      width: 44px;
      -webkit-tap-highlight-color: transparent;
    }
    .checkbox-container [type="checkbox"] + label {
      height: 44px;
      padding: 0;
      text-align: left;
      width: 44px
    }
    .checkbox-container [type="checkbox"]:checked + label:before, .checkbox-container [type="checkbox"]:not(.filled-in) + label:after, .checkbox-container [type="checkbox"] + label:before {
      height: 44px;
      left: auto;
      right: -20px;
      top: 8px;
      width: 44px;
    }

    .checkedbox [type="checkbox"] + label:before,
    .checkedbox [type="checkbox"]:not(.filled-in) + label:after {
      content: "\F043D";
    }

    .checkedbox [type="checkbox"]:checked + label:before {
      z-index: 1;
    }

  }

  .checkbox-container {
    .mdi {
      font-size: 20px;
    }
    [type="checkbox"] + label {
      text-align: left;
      padding: 0;
      height: 18px;
      width: 18px;
    }
    [type="checkbox"] + label:before,
    [type="checkbox"]:not(.filled-in) + label:after {
      font: normal normal normal 24px/1 "Material Design Icons";
      border: none;
      color: #78838F;
      content: "\F05E1";
      transform: none;
      top: 0;
      left: 0;
    }
    [type="checkbox"]:checked + label:after {
      content: "";
    }
    [type="checkbox"]:checked + label:before {
      font: normal normal normal 24px/1 "Material Design Icons";
      border: none;
      color: #06D6A0;
      content: "\F05E0";
      transform: none;
      top: 0;
      left: 0;
    }
  }
  .checkbox-label {
    flex: 1;
    text-align: left;
    display: flex;
    align-items: center;

    span {
      padding-left: 8px;
      color: #2E353D;
    }

    .material-icons {
      color: #78838F;
      cursor: pointer;
    }
  }
  .checkbox-label.unchecked > span {
    text-decoration: line-through;
    color: #78838F;
  }
  .flex-justify-left-align-center {
    display: flex;
    justify-content: left;
    justify-items: left;
    align-items: center;
    align-content: center;
  }
  .padding-bottom-24 {
    padding-bottom: 24px;
    padding-right: 5px;
  }
  .information-container {
    border: solid 1px #CED6E0;
    background: #FFFFFF;
    border-radius: 8px;
    color: #2E353D;
    padding: 24px 16px;
    text-align: center;
  }
  .information-container.unchecked {
    background:#2F3740;
    border: solid 1px #2F3740;
    color: #FFFFFF;
  }
  .pills {
      & {
          display: flex;
          flex-direction: row;
          border-radius: 4px;
          border: 2px solid #CED6E0;
      }
      & > li,
      & > li > a {
          color: #78838F;
      }
      & > li {
          flex: 1 1 auto;
          line-height: 44px;
          font-weight: 900;
          font-size: 1rem;
          cursor: pointer;

          &:nth-child(2) {
              border-left: 2px solid #CED6E0;
              border-right: 2px solid #CED6E0;
          }
      }
      & > li > a {
          display: block;
      }
      & > li.active,
      & > li.active > a {
          color: #FFFFFF;
      }
      & > li.active {
          background-color: #00E676;
          font-size: 1.25rem;
      }
      & > li.selected-pill {
          display: flex;
          align-items: center;
          justify-content: center;
      }
      & > li.selected-pill > .material-icons {
          padding-left: 8px;
      }
  }
  .duration-fees-table {
    display: flex;
    justify-content: space-around;
  }
  .duration-fees-item, .duration-fee-selected {
    .fee {
      color: #2E353D;
      font-size: 1rem;
      font-weight: 600;
    }
    .duration {
      color: #78838F;
      font-size: 0.875rem;
    }
    .label {
      color: #2E353D;
    }
  }
  .btn {
      background: linear-gradient(to left, #E61472, #B30F59);
  }
}
</style>
