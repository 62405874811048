<template>
  <div id="super-provider-landing-page" class="wraper">
    <div id="content" class="container">
      <feature-overview
        :header-title="$t('header.title')"
        :header-subtitle="$t('header.subtitle')"
        :header-tagline="$t('content.tagline')"
        :main-header-logo="'/img/super-provider/super-provider-overview.svg'"
        :tagline-logo="'/img/super-provider/super-provider-tagline-logo.svg'"
        primary-header-color="#4B38FA"
        secondary-header-color="#D300C5"
        header-gradient-angle="46deg"
        tagline-gradient-angle="18deg"
        :header-navbar-redirection="'dashboard'"
        :header-img-dimensions="{ height: '209px', width: '245px' }"
        :features="features"
      />
      <q-and-a
        :title="$t('faq.title')"
        :default-opened-answer="0"
        :questions="questions"
      >
        <template v-slot:questionItem="{question, index}">
          <div v-if="index === VIDEO_ITEM_KEY">
            <div class="question" @click.prevent="setOpenedAnswer(index)">
              <i class="material-icons">{{ getIcon(index) }}</i>
              <span class="label" v-text="$t('faq.videoItem.title')" />
            </div>
            <div v-if="openedAnswer === index" class="answer">
              <div v-text="$t('faq.videoItem.section_1')" />
              <div v-text="$t('faq.videoItem.section_2')" />
              <super-provider-video />
              <div class="margin-top-lg"><strong v-text="$t('faq.videoItem.section_3')" /></div>
              <!-- content from translation file -->
              <div v-html="$t('faq.videoItem.section_4')" />
              <div>
                <ol>
                  <li v-for="(item2, key2) in $t('faq.videoItem.list')" :key="key2">
                    <strong>{{ item2.title }}</strong>
                    <!-- content from translation file -->
                    <span v-if="item2.content.length" v-html="item2.content" />
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div v-else-if="index === LINK_ITEM_KEY">
            <div class="question" @click.prevent="setOpenedAnswer(index)">
              <i class="material-icons">{{ getIcon(index) }}</i>
              <span class="label" v-text="$t('faq.linkItem.title')" />
            </div>
            <div v-if="openedAnswer === index" class="answer">
              <span v-text="$t('faq.linkItem.section_1')" />
              <router-link :to="{ name: 'reliability-faq' }">{{ $t('faq.linkItem.section_2') }}</router-link>
              <span v-text="$t('faq.linkItem.section_3')" />
            </div>
          </div>
          <div v-else>
            <div class="question" @click="setOpenedAnswer(index)">
              <i class="material-icons">{{ getIcon(index) }}</i>
              <span class="label">{{ question.title }}</span>
            </div>
            <div v-if="openedAnswer === index" class="answer" v-html="question.content" />
          </div>
        </template>
      </q-and-a>
      <overview-cta :redirect-to="'super-provider'" :label="$t('cta2')" :img="'/img/super-provider/super-provider-cta.svg'" />
    </div>
  </div>
</template>

<i18n src="@/features/super-provider/translations/landing-page.json"></i18n>

<script>
import SuperProviderVideo from '@/features/super-provider/components/SuperProviderVideo.vue'

import FeatureOverview from '@/features/overview/FeatureOverview.vue'
import QAndA from '@/features/overview/QAndA.vue'
import OverviewCta from '@/features/overview/OverviewCTA.vue'

import QAndAMixin from '@/features/overview/mixins/q-and-a.mixin'

import values from 'lodash/values'

const VIDEO_ITEM_KEY = 0
const LINK_ITEM_KEY = 3

export default {
  components: {
    SuperProviderVideo,
    FeatureOverview,
    QAndA,
    OverviewCta
  },
  mixins: [QAndAMixin],
  data () {
    return {
      features: values(this.$t('content.features2')),
      questions: values(this.$t('faq.list2')),
      VIDEO_ITEM_KEY,
      LINK_ITEM_KEY,
      openedAnswer: 0
    }
  },
  computed: {
    REDESIGN_A2_3287 () {
      return this.$store.getters['featureflag/isEnabled']('REDESIGN_A2_3287')
    }
  }
}
</script>

<style lang="scss" scoped>
#super-provider-landing-page #content {
  padding: 0;
}
</style>
