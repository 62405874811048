<template>
  <div
    id="bottom-sheet-autocomplete"
    class="bottom-sheet-autocomplete-backdrop"
  >
    <div class="menu-container">
      <div class="menu-header">
        <div class="close-cta" @click.prevent="onClose"><img src="/img/registration/bottom-sheet-close.svg" alt=""></div>
        <div class="title">{{ header }}</div>
        <div class="autocomplete-input-container">
          <div class="input-field">
            <apv-input
              id="autocomplete-dropdown-input"
              ref="btm-sht-autocomplete-input"
              type="text"
              autocomplete="test"
              class="textfield-box-lg"
              :label="header"
              :redesign-flag="true"
              @keydown.native="search"
              @focus="onInputFocus"
              @onClearInput="onClearSearchInput"
            />
          </div>
        </div>
      </div>
      <div
        ref="content"
        class="option-menu"
      >
        <div
          v-for="option in clonedOptions"
          :key="getArrFirstValue(Object.keys(option))"
          class="option-item-container"
        >
          <div
            class="option-item"
            :data-option-value="getArrFirstValue(Object.keys(option))"
            @click.prevent="onSelect(getArrFirstValue(Object.keys(option)))"
            v-html="getArrFirstValue(Object.values(option))"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import latinize from 'latinize'

export default {
  props: {
    header: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      clonedOptions: [],
      searchKey: null
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleIOSKeyboardBehaviour)
  },
  mounted () {
    const options = this.options.length >= 0 ? this.options.slice(0, 20) : []
    this.setClonedOptions(options)
    this.scrollToTop()
    window.addEventListener('resize', this.handleIOSKeyboardBehaviour)
  },
  methods: {
    // Function to handle when the keyboard shows up
    handleKeyboardShow () {
      const viewportHeight = window.innerHeight
      const outerHeight = window.outerHeight

      // Calculate the height difference between viewport and outer window
      const heightDifference = outerHeight - viewportHeight

      // Set the bottom padding of the body to the height difference
      document.body.style.paddingBottom = heightDifference + 'px'
    },
    handleKeyboardHide () {
      // Reset the bottom padding of the body
      document.body.style.paddingBottom = '0'
    },
    handleIOSKeyboardBehaviour () {
      if (window.innerHeight < window.outerHeight) {
        // Keyboard is shown
        this.handleKeyboardShow()
      } else {
        // Keyboard is hidden
        this.handleKeyboardHide()
      }
    },
    getArrFirstValue (value) {
      const [firstValue] = value
      return firstValue
    },
    onClearSearchInput () {
      this.setClonedOptions(this.options)
    },
    onInputFocus () {
      this.scrollToTopMenuContainer()
      this.$emit('onInputFocus')
    },
    async scrollToTopMenuContainer () {
      document.body.scrollTop = 0
      await this.$nextTick()
    },
    async scrollToTop () {
      this.$refs.content.scrollTop = 0
      await this.$nextTick()
    },
    onSelect (option) {
      this.scrollToTop()
      this.$emit('onSelect', option)
      this.setClonedOptions(this.options)
    },
    onClose () {
      this.scrollToTop()
      // Reset the options when closing
      this.setClonedOptions(this.options)
      this.$emit('onClose')
    },
    strip (html) {
      var tmp = document.createElement('DIV')
      tmp.innerHTML = html

      return tmp.textContent || tmp.innerText || ''
    },
    setClonedOptions (val) {
      this.clonedOptions = val
    },
    close () {
      this.$emit('close', this.data)
    },
    search ($event) {
      this.debounceStore = _.debounce(() => {
        this.searchKey = $event.target.value
        if (($event.target.value === null || $event.target.value.length === 0)) {
          this.setClonedOptions(this.options)
          return false
        }

        const filteredOptions = this.options.filter(opt => {
          const [value] = Object.values(opt)
          return this.testMatch(value, $event.target.value)
        })

        const mappedValuesWithHighlights = filteredOptions.map(opt => {
          const [val, key] = Object.entries(opt)[0]
          const highlightedVal = val.replace(new RegExp($event.target.value.toLowerCase(), 'gi'), `<b>${$event.target.value.toLowerCase()}</b>`)
          return Object.assign({}, { [key]: highlightedVal })
        })

        this.setClonedOptions(mappedValuesWithHighlights)
      }, 100)

      this.debounceStore()
    },
    testMatch (str1, str2) {
      return (new RegExp(`^.*${latinize(str2)}.*$`, 'i')).test(latinize(str1))
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-up) {
  0% { transform: translateY(90%); }
  100% { transform: translateY(0); }
}

@include keyframes(slide-down) {
  0% { transform: translateY(0); }
  100% { transform: translateY(90%); }
}

#bottom-sheet-autocomplete {

  & {
    display:none;
  }

  &.bottom-sheet-autocomplete-backdrop {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000000, 0.7);
    z-index: 998;
    justify-content: center;
    align-items: flex-end;
    box-sizing: content-box;

    @media (min-width: 992px) {
      position: fixed;
      justify-content: flex-end;
    }
  }

  .close-cta {
    margin-left: -16px;
    width: 48px;

    @media (min-width: 992px) {
      margin-left: 48px;
      margin-top: 24px;
    }
  }

  .menu-container {
    position: relative;
    margin: 0;
    width: 100%;
    max-width: 375px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    font-family: 'Proxima Nova Semibold';
    border-radius: 12px 12px 0 0;
    padding-left: 24px;
    padding-right: 24px;
    max-height: 90%;
    height: 90%;
    display: none;
    flex-direction: column;

    @media (min-width: 992px) {
      max-height: 100%;
      height: 100%;
      width: 50%;
      max-width: 50%;
      border-radius: 0;
    }

    input[type="text"] {
      border-bottom: none;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;
      background: #f7fafc !important;
    }

    .menu-header {
      padding-top: 20px;

      @media (min-width: 992px) {
        padding-top: 0;
      }
    }

    .title {
      font-family: 'Proxima Nova Semibold';
      font-size: 22px;
      text-align: left;
      color: #2F3740;
      margin-top: 28px;
      margin-bottom: 12px;

      @media (min-width: 992px) {
        max-width: 464px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .autocomplete-input-container {
      @media (min-width: 992px) {
        max-width: 464px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 12px;
      }
    }

    .option-menu {
      max-height: 37em;
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* Internet Explorer 10+ */

      @media (min-width: 992px) {
        max-height: 100%;
        max-width: 464px;
        margin-left: auto;
        margin-right: auto;
      }

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .option-item-container {
        .option-item {
          &::first-letter {
            text-transform: uppercase;
          }

          border-bottom: 1px solid #CED6E0;
          padding-top: 20px;
          padding-bottom: 20px;
          font-family: 'Proxima Nova Medium';
          color: #2F3740;
          font-size: 18px;
        }
      }
    }
  }

  &.element--visible {
    display: flex;

    .menu-container {
      display: block;
      @include animation('slide-up 0.3s ease');
    }
  }
}
</style>
