var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "video-date-landing-page" } },
    [
      _c("md-navbar", {
        attrs: {
          push: { name: "dashboard" },
          brand: _vm.$translate("dashboard.title"),
          icon: "arrow_back"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "explanation center-align" }, [
        _c("div", { staticClass: "padding-xl content" }, [
          _c(
            "h1",
            {
              staticClass: "heading",
              staticStyle: { "margin-bottom": "12px" }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$translate(
                      "video-date.landing_page.explanation.headline"
                    )
                  ) +
                  "\n      "
              )
            ]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "description margin-top-none" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$translate("video-date.landing_page.explanation.subtitle")
                ) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "confirm-btn btn btn-large waves-effect waves-light btn-full btn-krypton-purple",
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "video-date-setting" })
                }
              }
            },
            [
              _c("span", { staticClass: "valign-middle" }, [
                _vm._v(_vm._s(_vm.$translate("video-date.cta_button.activate")))
              ]),
              _vm._v("  "),
              _c("i", {
                staticClass: "mdi mdi-arrow-right-circle valign-middle",
                staticStyle: { "font-size": "29px" }
              })
            ]
          ),
          _vm._v(" "),
          _c("p", [
            _c("span", { staticClass: "how-it-works-text" }, [
              _vm._v(
                _vm._s(
                  _vm.$translate(
                    "video-date.landing_page.explanation.how_it_works"
                  )
                )
              )
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "krypton-purple-text clickable",
                on: {
                  click: function($event) {
                    return _vm.scrollTo("how-it-works")
                  }
                }
              },
              [
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.$translate("video-date.cta_button.find_out_how"))
                  )
                ])
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "incentives center-align" }, [
        _c(
          "div",
          { staticClass: "padding-x-xl padding-y-x3" },
          _vm._l(3, function(i) {
            return _c("div", { key: i, staticClass: "item" }, [
              _c("h5", { staticClass: "title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$translate(
                        "video-date.landing_page.incentives.item_" +
                          i +
                          ".title"
                      )
                    ) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$translate(
                        "video-date.landing_page.incentives.item_" +
                          i +
                          ".description"
                      )
                    ) +
                    "\n        "
                )
              ])
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "how-it-works" }, [
        _c(
          "div",
          { staticClass: "padding-x-xl padding-top-x3 padding-bottom-lg" },
          [
            _c(
              "h1",
              {
                staticClass: "heading center-align margin-top-none",
                attrs: { id: "how-it-works" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$translate(
                        "video-date.landing_page.how_it_works.title"
                      )
                    )
                ),
                _c("br"),
                _vm._v(" "),
                _vm._m(0)
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "description left-align margin-bottom-none" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$translate(
                        "video-date.landing_page.how_it_works.description"
                      )
                    ) +
                    "\n      "
                )
              ]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "q-and-a" }, [
        _c(
          "div",
          { staticClass: "padding-x-lg padding-top-x3 padding-bottom-xl" },
          [
            _c("h1", { staticClass: "heading center-align margin-top-none" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$translate("video-date.landing_page.q_and_a.heading")
                  ) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c(
              "ul",
              {
                ref: "collapsible",
                staticClass: "collapsible apv-collapsible margin-bottom-none"
              },
              _vm._l(9, function(i) {
                return _c("li", { key: i, staticClass: "item" }, [
                  _c(
                    "div",
                    { staticClass: "collapsible-header padding-left-xl" },
                    [
                      _c("h5", { staticClass: "title" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$translate(
                                "video-date.landing_page.q_and_a.items.item_" +
                                  i +
                                  ".question"
                              )
                            ) +
                            "\n            "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "collapsible-body" }, [
                    _c(
                      "p",
                      {
                        staticClass: "description left-align padding-bottom-md"
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$translate(
                                "video-date.landing_page.q_and_a.items.item_" +
                                  i +
                                  ".answer"
                              )
                            ) +
                            " "
                        ),
                        i === 9
                          ? _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.$router.replace({
                                      name: "support-v2"
                                    })
                                  }
                                }
                              },
                              [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$translate(
                                        "video-date.cta_button.support"
                                      )
                                    )
                                  )
                                ]),
                                _vm._v(".")
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                ])
              }),
              0
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "activate-section padding-x-xl padding-y-lg center-align"
        },
        [
          _c(
            "button",
            {
              staticClass:
                "confirm-btn btn btn-large waves-effect waves-light btn-full btn-krypton-purple",
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "video-date-setting" })
                }
              }
            },
            [
              _c("span", { staticClass: "valign-middle" }, [
                _vm._v(_vm._s(_vm.$translate("video-date.cta_button.activate")))
              ]),
              _vm._v("  "),
              _c("i", {
                staticClass: "mdi mdi-arrow-right-circle valign-middle",
                staticStyle: { "font-size": "29px" }
              })
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-wrapper" }, [
      _c("img", {
        staticClass: "margin-top-lg",
        attrs: { src: "img/video-date/how_it_works.png", width: "100%" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }