<template>
  <div class="footer-button">

    <ul>
      <li>
        <button class="cancel" @click.prevent="onCancel">
          {{ cancel }}
        </button>
      </li>

      <li>
        <button
          class="save"
          data-test="footer-cta-confirm"
          :disabled="loading || disabled"
          @click.prevent="onConfirm">
          {{ confirm }}
        </button>
      </li>
    </ul>

  </div>
</template>

<style lang="scss" scoped>
.footer-button {
  & {
    background: #ffffff;
    border-top: 1px solid #CAD1D9;
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    padding: 17px 24px 31px;
    position: fixed;
    width: 100%;
    z-index: 990;
  }

  & > ul {
    & {
      float: left;
      margin: 0;
      padding: 0;
      width: 100%;
    }

    & > li {
      & {
        color: #ffffff;
        cursor: pointer;
        display: inline-block;
      }

      & > .cancel {
        color: #707070 !important;
        background: #ffffff;
        border: none;
        font-size: 16px;
        font-weight: 600;
        line-height: 14px;
        padding: 15px 0;
        text-decoration: underline;
        text-transform: uppercase;
      }

      & > .save {
        background: #2F3740 0% 0% no-repeat padding-box;
        border: none;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
        line-height: 14px;
        padding: 16px 44px 17px;
        text-transform: uppercase;
      }

      & > button[disabled] {
        background: #CED6E0 0% 0% no-repeat padding-box !important;
      }

    }

    & > li:first-child {
      float: left;
    }

    & > li:last-child {
      float: right;
    }
    
  }
  
  @media (min-width: 992px) {
    & {
      right: 0;
      width: 600px;
      margin: 0 auto;
    }
  }

}

</style>

<script>
export default {
  props: [
    'cancel',
    'confirm',
    'loading',
    'disabled'
  ],
  beforeDestroy () {
    this.$store.commit('setHasFixedFooterButtons', false)
  },
  mounted () {
    this.$store.commit('setHasFixedFooterButtons', true)
  },
  methods: {
    onCancel ($e) {
      this.$emit('cancel', $e)
    },
    onConfirm ($e) {
      this.$emit('confirm', $e)
    }
  }
}
</script>
