var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "photos-v2" } },
    [
      _c("md-new-navbar", {
        attrs: { "route-name": "profile" },
        scopedSlots: _vm._u([
          {
            key: "cta",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticClass: "help-btn",
                    attrs: { "data-test": "help-btn" },
                    on: {
                      click: function() {
                        return (_vm.isPhotoGuideShown = true)
                      }
                    }
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$translate("register.photos.others.help"))
                      )
                    ])
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("gallery"),
      _vm._v(" "),
      _c("photo-guide", {
        attrs: {
          "is-shown": _vm.isPhotoGuideShown,
          "data-test": "photo-guide"
        },
        on: {
          close: function() {
            return (_vm.isPhotoGuideShown = false)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }