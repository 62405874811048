var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page", attrs: { id: "private-place-faq" } },
    [
      _c("div", { attrs: { id: "faq-pplace" } }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("faq-header", {
              attrs: {
                "header-title": _vm.$translate("faq.private_place_faq.title"),
                "header-tagline": _vm.$translate("faq.private_place_faq.intro"),
                "main-header-logo":
                  "/img/private-place/private-place-header-img-v2.svg",
                "tagline-logo":
                  "/img/private-place/private-place-subheader-img-v2.svg",
                "primary-header-color": "#3AE86C",
                "secondary-header-color": "#46E4E9",
                "header-gradient-angle": "63deg",
                "tagline-gradient-angle": "50deg",
                "header-navbar-redirection": "faq"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "question-answer-section",
                attrs: { id: "content", "data-test": "faq-q-and-a-section" }
              },
              [
                _c("div", { staticClass: "q-n-a-container" }, [
                  _c(
                    "ul",
                    { staticClass: "q-and-a" },
                    _vm._l(_vm.QnALength, function(i) {
                      return _c("li", { key: i }, [
                        _c("p", { staticClass: "question" }, [
                          _c("span", { staticClass: "question-number" }, [
                            _vm._v(_vm._s(i + "."))
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                "" +
                                  _vm.$translate(
                                    "faq.private_place_faq.q_and_a.item_" +
                                      i +
                                      ".question"
                                  )
                              )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "answer" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$translate(
                                  "faq.private_place_faq.q_and_a.item_" +
                                    i +
                                    ".answer"
                                )
                              ) +
                              "\n                "
                          ),
                          _vm.isLastItem(i)
                            ? _c("span", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "support",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.$router.push({
                                          name: "support-v2"
                                        })
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$translate(
                                            "krypton_connect.support"
                                          )
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                ),
                                _vm._v(".")
                              ])
                            : _vm._e()
                        ])
                      ])
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c("faq-cta", {
                  attrs: {
                    "route-name": "private-place-setting",
                    "cta-txt": _vm.$translate("private-place.header.cta")
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }