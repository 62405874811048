var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tips-and-infos", attrs: { "data-test": "tips-and-infos" } },
    [
      _c("div", { staticClass: "section-label" }, [
        _c("img", {
          attrs: {
            src: "/img/dashboard/dashboard-tips-and-infos-icon.svg",
            alt: "tips and infos icon"
          }
        }),
        _vm._v(" "),
        _c("h2", [_vm._v(_vm._s(_vm.$translate("onboarding.tips_and_infos")))])
      ]),
      _vm._v(" "),
      _c("onboarding-verification-banner"),
      _vm._v(" "),
      _c("onboarding-bottom-sheet-dashboard-intro"),
      _vm._v(" "),
      _c("onboarding-active-lesson"),
      _vm._v(" "),
      _c("onboarding-bottom-sheet-quick-start")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }