var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lesson-screen", attrs: { id: "lesson-screen-video" } },
    [
      _c("div", { staticClass: "wrapper" }, [
        _c(
          "div",
          { staticClass: "content__drawer content__drawer-filled" },
          [
            _c("div", { staticClass: "content lesson-screen--container" }, [
              _vm.isVideoElementShown
                ? _c("div", { staticClass: "lesson-screen--content" }, [
                    _vm.isVideoPlayerShown
                      ? _c(
                          "div",
                          {
                            staticClass: "video-content--video-player",
                            attrs: {
                              "data-test": "video-player",
                              controls: "",
                              autoplay: ""
                            }
                          },
                          [
                            _c(
                              "video",
                              {
                                attrs: {
                                  controls: "",
                                  autoplay: "",
                                  controlsList: "nodownload"
                                },
                                on: { ended: _vm.showReplayScreen }
                              },
                              [
                                _vm.PHOTO_FALLBACK_A2_2720
                                  ? _c("source", {
                                      attrs: {
                                        "data-test": "video-source",
                                        src: _vm.videoSource(
                                          _vm.VIDEOS[_vm.openedLessonName]
                                        ),
                                        type: "video/mp4"
                                      }
                                    })
                                  : _c("source", {
                                      attrs: {
                                        "data-test": "video-source",
                                        src: _vm.videoSource(
                                          _vm.VIDEOS[_vm.openedLessonName]
                                        ),
                                        type: "video/mp4"
                                      }
                                    })
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isReplayScreenShown
                      ? _c(
                          "div",
                          { staticClass: "lesson-screen--video-replay" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "lesson-screen--video-replay-control"
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    "data-test": "video-replay",
                                    src: require("@/features/onboarding-lesson/assets/onboarding-lesson-replay.svg"),
                                    alt: "replay icon"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.replay($event)
                                    }
                                  }
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("replay")) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                : _c("div", { staticClass: "lesson-screen--content" }, [
                    _c("div", { staticClass: "lesson-screen--header" }, [
                      _c("div", { staticClass: "photos-remark--container" }, [
                        _c("div", { staticClass: "photos-remark--content" }, [
                          _c("img", {
                            staticClass: "img-icon",
                            attrs: {
                              src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-video-player-v2.svg"),
                              alt: "video player icon"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.openedLessonName + ".header_title")
                              )
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "examples-text--container",
                          attrs: { "data-test": "video-duration" }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t("video_duration", {
                                  duration: _vm.videoDuration(
                                    _vm.VIDEOS[_vm.openedLessonName]
                                  )
                                })
                              ) +
                              "\n            "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "video-content" }, [
                      _c(
                        "div",
                        {
                          staticClass: "video-thumbnail",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.showVideoPlayer($event)
                            }
                          }
                        },
                        [
                          !_vm.isVideoPlayerShown
                            ? _c("img", {
                                attrs: {
                                  "data-test": "video-thumbnail",
                                  src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-video-thumbnail-v2.svg"),
                                  alt: "video thumbnail"
                                }
                              })
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c("h2", [
                        _vm._v(
                          _vm._s(_vm.$t(_vm.openedLessonName + ".video_title"))
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.openedLessonName + ".video_description")
                          )
                        )
                      ])
                    ])
                  ])
            ]),
            _vm._v(" "),
            _c("navigator", {
              attrs: {
                current: _vm.currentPage,
                "no-prev": "",
                next: _vm.$t("messages.globals.continue"),
                "next-class": "action-btn__black",
                total: _vm.totalPages,
                "step-class": "--purple"
              },
              on: {
                next: function($event) {
                  $event.preventDefault()
                  return _vm.gotoNextLessonScreen($event)
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }