<template>
  <div id="private-photos">
    <h1>Private Gallery</h1>
  </div>
</template>

<script>
export default {
}
</script>

