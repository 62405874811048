export const REACTIVATION_STATUS = {
  PROVIDER_DECLINED: -3,
  REACTIVATION_EXPIRED: -2,
  CANNOT_REACTIVATE: -1,
  CAN_REACTIVATE: 0,
  REACTIVATED: 1,
  CONFIRMED: 2
}

export const EVENT_STATUS = {
  PENDING: 1,
  CONFIRMED: 2,
  CONFIRMED_PREPARING: 3,
  CONFIRMED_READY: 4,
  CANCELLED_CLIENT: 5,
  CANCELLED_PROVIDER: 6,
  EXPIRED: 7,
  DECLINED: 8,
  EARLY_CHECKOUT: 9,
  CANCELLED_AGENT: 10
}

export const TICKET_STATUS = {
  UNVERIFIED: -1,
  PREOPEN: 0,
  OPEN: 1,
  CONFIRMED: 2,
  PREMEETING: 3,
  MEETING: 4,
  POST_MEETING: 5,
  SUCCESS: 6,
  CANCELLED: 8
}
export const AVAILABILITY_STATUS = {
  PAST: 1,
  UNFILLED: 2,
  FILLED: 3,
  UNAVAILABLE: 4
}
export const AVAILABILITY_FILTER_TYPES = {
  ALL: 1,
  UNFILLED: 2,
  FILLED: 3
}
export const AGREEMENT_TERM = {
  DISABLED: -1,
  ENABLED: 2,
  OVERRIDE_AGREED: 3,
  DISAGREED: 0,
  AGREED: 1
}

export const SUPPORT_CATEGORY = {
  MEETING: 1,
  INVOICE: 2,
  ACCOUNT: 3,
  TECHNICAL: 4,
  OTHER: 5
}

export const SUPPORT_TICKET_STATUS = {
  PREOPEN: 0,
  OPEN: 1,
  PENDING: 2,
  SOLVED: 3,
  CLOSED: 4
}

export const BILLING_ID_STATUS = {
  REJECTED: -1,
  PENDING: 0,
  APPROVED: 1
}

export const COUNTRY_ORDER = [
  'DE',
  'AT',
  'CH',
  'US',
  'CA',
  'NL',
  'ES',
  'PT',
  'IT',
  'GB',
  'AD',
  'BE',
  'DK',
  'FI',
  'FR',
  'GR',
  'IE',
  'IS',
  'LI',
  'LU',
  'MC',
  'NO',
  'PL',
  'SE',
  'SK',
  'CZ',
  'HU'
]

export const DAYS = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6
}

export const CANCELLATION_REQUEST_SENT = {
  DEFAULT: 0,
  PENDING: 1,
  CANCELLED: 2
}

export const GROUP_ID = {
  CLIENT: 1,
  PROVIDER: 2,
  CANDIDATE: 3,
  SUPER_ADMIN: 100
}

export const VISIBILITY_STATES = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
  PRERENDER: 'prerender',
  UNLOADED: 'unloaded'
}

export const PROVIDER_GROUP_ID = 2

export const MAX_CHECKIN_HR_EXTENSION = 12

export const ROUTE_NAMES = Object.freeze({
  VERIFY_UPLOAD: 'verify-upload',
  VERIFY_INTRO: 'verify-intro',
  VERIFY_PENDING: 'verify-pending'
})

export const APP_FEEDBACK_CATEGORY = {
  PRAISE: 1,
  SUGGEST: 2,
  COMPLAIN: 3
}
