var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide" } }, [
    _c(
      "div",
      { staticClass: "apv-v2", attrs: { id: "new-terms-conditions" } },
      [
        _vm.hasAgreedTerms
          ? _c("md-navbar", {
              attrs: {
                icon: "arrow_back",
                push: { name: "setting" },
                brand: _vm.$translate("sidenav.settings")
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { ref: "scrollArea", staticClass: "new-container" },
          [
            _c("h1", { staticClass: "title strong" }, [
              _vm._v("\n        " + _vm._s(_vm.$t("header")) + "\n      ")
            ]),
            _vm._v(" "),
            _vm._l(10, function(n) {
              return _c("div", { key: n, staticClass: "terms" }, [
                _c("h5", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("content.term_" + n + ".title")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "preline" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.getDescription(n)) +
                      "\n        "
                  )
                ])
              ])
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "move-top-btn",
                class: { "b-important": !_vm.hasAgreedTerms }
              },
              [
                _c("slideToTopBtn", {
                  attrs: {
                    text: _vm.$translate("register.navigator.go_top"),
                    classes: "action-btn action-btn__black"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            !_vm.hasAgreedTerms
              ? _c("div", { staticClass: "controls" }, [
                  _c("form", { attrs: { action: "#" } }, [
                    _c("p", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.hasAgreed,
                            expression: "hasAgreed"
                          }
                        ],
                        attrs: {
                          id: "agree",
                          type: "checkbox",
                          "data-test": "tc-checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.hasAgreed)
                            ? _vm._i(_vm.hasAgreed, null) > -1
                            : _vm.hasAgreed
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.hasAgreed,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.hasAgreed = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.hasAgreed = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.hasAgreed = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "agree" } }, [
                        _vm._v(_vm._s(_vm.$t("checkmark_text")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn-large display-block",
                        attrs: {
                          id: "terms-btn",
                          disabled: _vm.isDisabled,
                          "data-test": "tc-cta"
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.doAgree($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("confirm_btn")) +
                            "\n          "
                        )
                      ]
                    )
                  ])
                ])
              : _vm._e()
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }