var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal left-align",
      staticStyle: { "max-height": "80%" },
      attrs: { id: "closed-event-modal" }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-content",
          staticStyle: { padding: "32px 12px 2px" }
        },
        [
          _c(
            "h4",
            {
              staticClass: "modal-title center",
              staticStyle: { "font-size": "20px" }
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row schedule" }, [
            _c("div", { staticClass: "col s6 center-align" }, [
              _c("i", { staticClass: "material-icons valign-middle" }, [
                _vm._v("today")
              ]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.eventDate))])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col s6 center-align" }, [
              _c("i", { staticClass: "material-icons valign-middle" }, [
                _vm._v("access_time")
              ]),
              _vm._v("\n        " + _vm._s(_vm.eventDuration) + "\n      ")
            ])
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("p", { staticClass: "center" }, [
            _c("strong", { staticStyle: { "font-size": "14px" } }, [
              _vm._v(_vm._s(_vm.summary))
            ])
          ]),
          _vm._v(" "),
          _c("p", {
            staticClass: "grey-text text-darken-1 center",
            staticStyle: { "font-size": "11px" },
            domProps: { innerHTML: _vm._s(_vm.explanation) }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-footer",
          staticStyle: {
            "border-top": "1px solid #E1E6EB",
            "text-align": "center"
          }
        },
        [
          _c(
            "a",
            {
              staticClass: "btn-flat waves-effect waves-dark krypton-pink-text",
              attrs: { href: "#" },
              on: { click: _vm.confirm }
            },
            [
              _c("strong", [
                _vm._v(
                  _vm._s(this.$translate("events.closed_event_popup.button"))
                )
              ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "alarm-icon center" }, [
      _c(
        "i",
        { staticClass: "material-icons", staticStyle: { color: "#939ea8" } },
        [_vm._v("cancel")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }