<template>
  <div class="faq-video-item">
    <div class="question">
      <span class="question-number">{{ getItemNumber }}.</span> <span v-text="$t('faq.videoItem.title')" />
    </div>
    <div class="answer" v-text="$t('faq.videoItem.section_1')" />
    <div class="answer" v-text="$t('faq.videoItem.section_2')" />
    <super-provider-video class="answer" />
    <div class="answer"><strong v-text="$t('faq.videoItem.section_3')" /></div>
    <!-- content from translation file -->
    <div class="answer" v-html="$t('faq.videoItem.section_4')" />
    <div class="answer">
      <ol>
        <li v-for="(videoItemList, key) in $t('faq.videoItem.list')" :key="key">
          <strong>{{ videoItemList.title }}</strong>
          <!-- content from translation file -->
          <span v-if="videoItemList.content.length" v-html="videoItemList.content" />
        </li>
      </ol>
    </div>
  </div>
</template>

<i18n src="@/features/super-provider/translations/landing-page.json"></i18n>

<script>
import SuperProviderVideo from '@/features/super-provider/components/SuperProviderVideo.vue'

export default {
  components: {
    SuperProviderVideo
  },
  props: {
    itemKey: {
      type: Number,
      required: true
    }
  },
  computed: {
    getItemNumber () {
      // We need to add 1 to the itemKey since it always starts at index 0
      // Doing so will make the numbering correct
      return parseInt(this.itemKey) + 1
    }
  }
}
</script>

<style lang="scss" scoped>
.faq-video-item {
  font-family: 'Proxima Nova';
  color: #2F3740;

  .question {
    display: flex;
    font-size: 20px;
    font-family: 'Proxima Nova Semibold';

    .question-number {
      color: #E30074;
      margin-right: 4px;
      font-size: 18px;
    }
  }

  .answer {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
  }
}
</style>
