import CookieService from '@/services/ama-tools/services/CookieService'

const DEFAULT_LOCALE = 'de'
export const ENGLISH_LOCALE = 'en'

const cookieProvider = new CookieService(process.env.FE_COOKIE_DOMAIN)

export async function saveLocale (locale) {
  try {
    cookieProvider.setCookie('locale', locale)
    await window.localforage.setItem('app_lang', locale)
  } catch (e) {
    console.error(e)
    return false
  }

  return true
}

export async function getSavedLocale () {
  try {
    const locale = cookieProvider.getCookie('locale')

    if (locale) {
      return locale
    }

    return await window.localforage.getItem('app_lang')
  } catch (e) {
    console.log(e)
    return null
  }
}

export function setLocale (locale) {
  try {
    window.i18n.locale = locale
    window.Lang.setLocale(locale)
  } catch (e) {
    console.error(e)
    return false
  }

  return true
}

export function getLocale (locale, driver = 'i18n') {
  try {
    let locale

    if (driver === 'legacy') {
      locale = window.Lang.locale()
    } else {
      locale = window.i18n.locale
    }

    if (locale === null) {
      return DEFAULT_LOCALE
    }

    return locale
  } catch (e) {
    console.error(e)
    return DEFAULT_LOCALE
  }
}

export function defaultLocale () {
  return DEFAULT_LOCALE
}

export default {
  saveLocale,
  getSavedLocale,
  setLocale,
  getLocale,
  defaultLocale
}
