var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal left-align" }, [
    _c(
      "div",
      { staticClass: "modal-content", staticStyle: { padding: "24px" } },
      [
        _vm.title
          ? _c(
              "h4",
              {
                staticClass: "modal-title center",
                staticStyle: { "font-size": "20px" }
              },
              [_vm._v("\n            " + _vm._s(_vm.title) + "\n        ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "grey-text text-darken-1 center",
            staticStyle: { "font-size": "14px" }
          },
          [_vm._v("\n            " + _vm._s(_vm.content) + "\n        ")]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal-footer",
        staticStyle: {
          "border-top": "1px solid #E1E6EB",
          "text-align": "center"
        }
      },
      [
        _c(
          "a",
          {
            staticClass: "btn-flat waves-effect waves-dark krypton-pink-text",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.confirm($event)
              }
            }
          },
          [
            _c("strong", [
              _vm._v(
                _vm._s(_vm.confirmText || _vm.$translate("app.ok_understand"))
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }