import MonitoringService from '@/services/ama-tools/services/MonitoringService'

const init = () => {
  const app = document.getRootNode()

  // do the datadog stuff
  const handler = (evt) => {
    MonitoringService.sendAction(evt.type, {
      event: evt.type,
      message: evt.detail.message
    })
    if (process.env.APPLICATION_ENVIRONMENT !== 'production') {
      console.log('successfully called monitoring service for event ' + evt.type)
    }
  }

  // add event listeners
  app.addEventListener('serviceworker:loaded', handler)
  app.addEventListener('serviceworker:waiting', handler)
  app.addEventListener('serviceworker:installing', handler)
  app.addEventListener('serviceworker:installed', handler)
  app.addEventListener('serviceworker:activating', handler)
  app.addEventListener('serviceworker:activated', handler)
  app.addEventListener('serviceworker:redundant', handler)
  app.addEventListener('serviceworker:default', handler)
  app.addEventListener('serviceworker:update-clicked', handler)
  app.addEventListener('serviceworker:update-checked', handler)
  app.addEventListener('serviceworker:visibility-changed', handler)
  app.addEventListener('serviceworker:controllerchange', handler)
  app.addEventListener('serviceworker:refreshed', handler)
}

export default {
  init
}
