<template>
  <div id="support-v2-request-new">
    <md-new-navbar :route-name="'support-v2'" />
    <div id="content" class="container">
      <h1 class="support-request__title">{{ $translate('support.new_request') }}</h1>
      <h2 class="support-request__subtitle">{{ $translate('support.form.subtitle') }}</h2>
      <form>
        <div v-if="categoriesSelection.length > 0">
          <md-select
            v-model="form.category"
            :placeholder="$translate('support.form.category')"
            :label="$translate('support.form.category')"
            :disable-default="true"
            :disabled="isInitializedWithTicket"
            :force-mobile="true"
            :listen-for-reinitiate-options="true"
            :new-design="true"
          >
            <option
              v-for="opt in categoriesSelection"
              slot="options"
              :key="opt.key"
              :value="opt.value"
              :disabled="opt.disabled && opt.key !== form.category"
            >{{ opt.text }}</option>
          </md-select>
        </div>

        <div v-if="isFetchingTickets">
          <div v-if="form.category === SUPPORT_CATEGORY.MEETING ||
            form.category === SUPPORT_CATEGORY.INVOICE">
            <md-input type="text" :label="$translate('support.form.fetching_tickets')" :disabled="true" />
          </div>
        </div>
        <div v-show="!isFetchingTickets">
          <!-- Dropdown Input for Meeting/Invoice Categories -->
          <div v-show="form.category === SUPPORT_CATEGORY.MEETING ||
            form.category === SUPPORT_CATEGORY.INVOICE">
            <div v-show="ticketSelection.length > 0">
              <md-select
                v-model="form.reference_id"
                :listen-for-reinitiate-options="true"
                :placeholder="$translate('support.form.subject')"
                :label="$translate('support.form.subject')"
                :disabled="isInitializedWithTicket"
                :force-mobile="true"
                :new-design="true"
              >
                <option
                  v-for="opt in ticketSelection"
                  slot="options"
                  :key="opt.id"
                  :value="opt.id"
                  :disabled="opt.id === form.reference_id"
                >{{ prettifyTicketTitle(opt) }}</option>
              </md-select>
            </div>
            <div v-if="ticketSelection.length === 0">
              <md-select
                :placeholder="$translate('support.form.subject')"
                :label="$translate('support.form.subject')"
                :disabled="true"
              >
                <option slot="options">{{ $translate('app.select') }}</option>
              </md-select>
            </div>
          </div>
        </div>
        <div v-if="form.category !== SUPPORT_CATEGORY.MEETING &&
          form.category !== SUPPORT_CATEGORY.INVOICE">
          <md-input
            v-model="form.subject"
            type="text"
            :maxlength="SUBJECT_TITLE_MAX_LEN"
            :label="$translate('support.form.subject')"
            :disabled="form.category === null"
          >
            <div class="char-count" style="margin-top: 8px; margin-bottom: 16px;">{{ subjectCharCount }}</div>
          </md-input>
        </div>

        <md-textarea
          v-model="form.message"
          type="multiline"
          :maxlength="MESSAGE_MAX_LEN"
          :label="$translate('support.form.message')"
          style="margin-top: 16px;"
        >
          <div class="char-count" style="margin-right: 0;">{{ messageCharCount }}</div>
        </md-textarea>

        <div class="support-request__files">
          <div v-for="(file, idx) in newFileUploads" :key="idx" class="support-request__file-item">
            <img src="/img/support/image-icon-24x24.svg" alt="image icon">
            <div class="file-info">
              <span class="file-name">{{ file.name }}</span><br>
            </div>
            <i class="material-icons clickable" @click="removeFile(idx)">close</i>
          </div>
        </div>
      </form>
      <div class="support-request__footer">
        <div class="support-request__attach-cta">
          <img src="/img/support/image-icon-44x44.svg" alt="image icon">
          <a href="#" class="support-request__attach" @click.prevent="triggerFileUpload">
            {{ $translate('support.form.upload') }}
          </a>
        </div>

        <button class="support-request__cta" :disabled="formInvalid" @click.prevent="submit">
          {{ $translate('support.buttons.send') }}
        </button>
      </div>

      <input
        ref="fileUpload"
        type="file"
        accept="image/*,.pdf"
        class="hide"
        multiple
        @change="onFileUploadChange"
      >
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations
} from 'vuex'
import { tooltipPlugin } from '@/features/super-provider/plugins/vue-custom-tooltip.js'
import { MAX_FILE_COUNT_UPLOAD } from '../../../../stores/support'
const { isValidTextInput } = require('@/features/core/validators/inputValidator.js').default

const SupportProvider = $h.getProvider('SupportProvider')
const LocaleProvider = $h.getProvider('LocaleProvider')

const REQUEST_DELAY = 300 // in milliseconds
const SUBJECT_TITLE_MAX_LEN = 30
const MESSAGE_MAX_LEN = 1000

export default {
  props: {
    ticketId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      SUBJECT_TITLE_MAX_LEN,
      MESSAGE_MAX_LEN,
      SUPPORT_CATEGORY,

      isFetchingTickets: false,
      isInitializedWithTicket: false,

      ticketSelection: [],
      requestTimeout: null,
      lastCategory: null,

      hasMeetingTickets: false,
      hasInvoiceTickets: false,

      isSending: false
    }
  },
  computed: {
    ...mapGetters({
      newFileUploads: 'support/GET_NEW_FILE_UPLOAD',
      tickets: 'support/GET_TICKETS',
      form: 'support/GET_SUPPORT_FORM'
    }),
    categoriesSelection () {
      const list = []

      if (this.hasMeetingTickets) {
        list.push(
          { text: this.$translate('support.category.meeting'), value: SUPPORT_CATEGORY.MEETING }
        )
      }
      if (this.hasInvoiceTickets) {
        list.push(
          { text: this.$translate('support.category.invoice'), value: SUPPORT_CATEGORY.INVOICE }
        )
      }

      list.push(
        { text: this.$translate('support.category.account'), value: SUPPORT_CATEGORY.ACCOUNT }
      )
      list.push(
        { text: this.$translate('support.category.technical'), value: SUPPORT_CATEGORY.TECHNICAL }
      )
      list.push(
        { text: this.$translate('support.category.other'), value: SUPPORT_CATEGORY.OTHER }
      )

      return list
    },
    formInvalid () {
      if (!this.form.category) {
        return true
      }

      if (!this.form.message) {
        return true
      }

      if ([SUPPORT_CATEGORY.MEETING, SUPPORT_CATEGORY.INVOICE].includes(this.form.category) && !this.form.reference_id) {
        return true
      }

      if ([SUPPORT_CATEGORY.ACCOUNT, SUPPORT_CATEGORY.TECHNICAL, SUPPORT_CATEGORY.OTHER].includes(this.form.category) && !this.form.subject) {
        return true
      }

      return false
    },
    subjectCharCount () {
      const max = this.SUBJECT_TITLE_MAX_LEN
      const length = this.form.subject ? this.form.subject.length : 0

      return `${length}/${max}`
    },
    messageCharCount () {
      const max = this.MESSAGE_MAX_LEN
      const length = this.form.message ? this.form.message.length : 0

      return `${length}/${max}`
    }
  },
  watch: {
    form: {
      handler (newVal, oldVal) {
        if (!this.isFetchingTickets) {
          if (newVal.category !== this.lastCategory) {
            // Prevent API fetch if category is string
            if (typeof this.form.category === 'string') {
              this.$store.commit('support/RESET_FORM_TICKET')
              return
            }

            this.lastCategory = newVal.category
            this.isFetchingTickets = true

            // Prevent spamming of fetch support categories
            if (this.requestTimeout !== null) {
              clearTimeout(this.requestTimeout)
            }

            // Prevent spamming of fetch support categories
            if (this.requestTimeout !== null) {
              clearTimeout(this.requestTimeout)
            }

            this.requestTimeout = setTimeout(() => {
              SupportProvider
                .getSupportCategories(this.form.category)
                .then((res) => {
                  if (this.form.category === SUPPORT_CATEGORY.MEETING) {
                  // Sort meeting tickets by meeting start
                    this.ticketSelection = res.data.sort((a, b) => moment(b.meeting_start_at).diff(moment(a.meeting_start_at)))
                  } else {
                    this.ticketSelection = res.data
                  }

                  // Always reset ticket selection
                  this.$store.commit('support/RESET_FORM_TICKET')
                  this.reInitiateOptions()
                })
                .catch((err) => {
                  console.error(err)
                })
                .finally(() => {
                  this.isFetchingTickets = false
                  this.requestTimeout = null
                })
            }, REQUEST_DELAY)
          }
        }
      },
      deep: true
    }
  },
  beforeDestroy () {
    this.$store.commit('setHasFixedFooterButtons', false)
  },
  mounted () {
    this.$store.commit('support/RESET_FORM_FIELD')

    if (this.ticketId > 0) {
      this.form.category = SUPPORT_CATEGORY.MEETING
      this.isInitializedWithTicket = true
    }

    this.checkIfHasInvoiceTickets()
    this.checkIfHasMeetingTickets().then(() => {
      if (this.ticketId > 0) {
        setTimeout(() => {
          this.form.reference_id = this.ticketId
        }, 1000)
      }
    })

    this.$store.commit('setHasFixedFooterButtons', true)
  },
  methods: {
    reInitiateOptions () {
      this.$root.$emit('parent-reinitiate-options')
    },
    ...mapMutations({
      removeFile: 'support/REMOVE_NEW_FILE_UPLOAD'
    }),
    isImage (type) {
      return type.indexOf('image/') >= 0
    },
    prettifySize (size) {
      const sizeKB = $h.byteToKilobyte(size)
      const sizeMB = $h.byteToMegabyte(size)

      if (sizeKB < 1000) { return `${sizeKB} KB` }
      return `${sizeMB} MB`
    },
    prettifyTicketTitle (ticket) {
      let title = ''

      switch (this.form.category) {
        case SUPPORT_CATEGORY.MEETING:
          title = `${ticket.provider_name} + ${ticket.client_name}, ${moment(ticket.meeting_start_at)
            .locale(LocaleProvider.getLocale())
            .format('ddd, DD MMM, HH:mm')}`
          break
        case SUPPORT_CATEGORY.INVOICE:
          title = `Invoice ID${ticket.invoice_number}`
          break
        default:
          break
      }

      return title
    },
    checkIfHasInvoiceTickets () {
      SupportProvider
        .getSupportCategories(SUPPORT_CATEGORY.INVOICE)
        .then((res) => {
          if (res.data.length > 0) {
            this.hasInvoiceTickets = true
            // Reinitiate options to re-render the category bottomsheet
            this.reInitiateOptions()
          }
        })
    },
    checkIfHasMeetingTickets () {
      return new Promise((resolve, reject) => {
        SupportProvider
          .getSupportCategories(SUPPORT_CATEGORY.MEETING)
          .then((res) => {
            if (res.data.length > 0) {
              this.hasMeetingTickets = true
              // Reinitiate options to re-render the category bottomsheet
              this.reInitiateOptions()
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    triggerFileUpload () {
      this.$refs.fileUpload.click()
    },
    isLargeFile (file) {
      if (!file) return

      const FILE_LIMIT_MB = 5
      const sizeMB = $h.byteToMegabyte(file.size)

      return sizeMB > FILE_LIMIT_MB
    },
    isTotalFileSizeTooLarge (filesSelected) {
      const FILE_LIMIT_IN_MB = 5
      let totalFileSize = 0

      for (let i = 0, limit = filesSelected.length; i < limit; ++i) {
        const singleFileSize = $h.byteToMegabyte(filesSelected[i]?.size ?? 0)
        totalFileSize += parseFloat(singleFileSize)
      }

      return totalFileSize > FILE_LIMIT_IN_MB
    },
    onFileUploadChange (e) {
      const filesSelected = Array.from(this.$refs.fileUpload.files)

      if ((filesSelected.length + this.newFileUploads.length) > MAX_FILE_COUNT_UPLOAD) {
        const toastMessage = this.$translate('support.form.errors.max_file_count_reached', { count: MAX_FILE_COUNT_UPLOAD })

        tooltipPlugin.toastV2(toastMessage, 5000, 'bottom', ['overlay', 'with-cta'], 'info')

        return false
      }

      // Loops through the selected files and determine the size
      if (this.isTotalFileSizeTooLarge(filesSelected)) {
        const toastMessage = this.$translate('support.form.errors.big_file_size')

        tooltipPlugin.toastV2(toastMessage, 5000, 'bottom', ['overlay', 'with-cta'], 'info')

        return false
      }

      this.$store.commit('support/PUSH_NEW_FILE_UPLOAD', filesSelected)
    },
    submit () {
      if (this.isSending) return

      this.errors.clear()
      this.isSending = true

      const payload = new FormData()

      payload.append('category', this.form.category)

      switch (this.form.category) {
        case SUPPORT_CATEGORY.MEETING:
        case SUPPORT_CATEGORY.INVOICE:
          payload.append('reference_id', this.form.reference_id)
          break
        case SUPPORT_CATEGORY.ACCOUNT:
        case SUPPORT_CATEGORY.TECHNICAL:
        case SUPPORT_CATEGORY.OTHER:
          if (isValidTextInput(this.form.subject)) {
            payload.append('subject', this.form.subject)
          }
          break
        default:
          payload.append('reference_id', this.form.reference_id)
          if (isValidTextInput(this.form.subject)) {
            payload.append('subject', this.form.subject)
          }
      }

      payload.append('message', this.form.message)

      for (let i = 0, limit = this.newFileUploads.length; i < limit; ++i) {
        payload.append(`attachments[${i}]`, this.newFileUploads[i])
      }

      this.$store
        .dispatch('support/sendSupportTicket', payload)
        .then((res) => {
          tooltipPlugin.toastV2(this.$translate('support.form.success'), 5000, 'bottom', ['overlay'])

          this.$store.commit('support/RESET_FORM_FIELD')
          this.$router.replace({ name: 'support-v2' })
        })
        .catch(err => this.handleErrorsForSendSupportTicket(err))
        .finally(() => {
          this.isSending = false
        })
    },
    handleErrorsForSendSupportTicket (err) {
      for (const key in err.data.data) {
        if (key) {
          tooltipPlugin.toastV2(err.data.data[key][0], 5000, 'bottom', ['overlay', 'with-cta'], 'info')
          return false
        }
      }
    }
  }
}
</script>
