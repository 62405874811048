<template>
  <div v-if="showPopup" id="provider-preferences-popup">
    <div class="provider-preferences-popup__backdrop" data-test="pp-popup-backdrop">
      <div class="provider-preferences-popup__modal">
        <a href="#" class="provider-preferences-popup__close-btn" data-test="sp-popup-close"
           @click.prevent="closePopup">
          <i class="material-icons">close</i>
        </a>
        <div class="provider-preferences-popup__title">
          <h2>{{ $t('title') }}</h2>
          <img src="@/features/provider-preferences/images/provider-preferences-popup.svg" alt="provider-preferences-icon">
        </div>
        <div class="provider-preferences-popup__description" data-test="sp-popup-explanation-text"> {{ $t('description') }}</div>
        <button data-test="sp-popup-cta" class="provider-preferences-popup__cta btn waves-effect waves-dark"
                @click.prevent="closePopup({ redirect: { name: 'profile', params: { showPPBottomSheet: true } } })">{{ $t('cta') }}</button>
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/provider-preferences/translations/popup.json"></i18n>

<script>
import { putProviderPreferencesSettingsSeen } from '@/features/provider-preferences/services/ProviderPreferencesService.js'

export default {
  data: () => ({
    isLoading: false
  }),
  computed: {
    showPopup () {
      // Value should NOT be seen in order to show the popup
      const DEFAULT_SEEN_STATUS = 1
      return !Boolean(this.user?.settings?.nsfw_preferences_promo_popup_seen ?? DEFAULT_SEEN_STATUS)
    },
    user () {
      return this.$store.getters.getUser
    }
  },
  methods: {
    closePopup (actions = null) {
      if (this.isLoading) return false

      this.isLoading = true
      return putProviderPreferencesSettingsSeen()
        .then(() => {
          this.loading = false
          this.updateStoreForProviderPreferencesSeen()
          if (actions?.redirect) {
            this.$router.push(actions.redirect)
          }
        })
        .catch(err => {
          this.loading = false
          this.handleErrorMessages(err)
        })
    },
    handleErrorMessages (err = ({ message: '' })) {
      this.$notifications.toast(err?.message, 5000)
    },
    updateStoreForProviderPreferencesSeen (val = 1) {
      /**
       * Instead of doing a full API call for /user endpoint
       * just to make sure that the popup is seen
       * we will instead mutate the vuex
      */
      this.$store.commit('setUserSettingProviderPreferencesSeen', val)
    }
  }
}
</script>

<style lang="scss" scoped>
#provider-preferences-popup {
  font-family: 'Proxima Nova';

  .provider-preferences-popup__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#2E353D, 0.3);
    z-index: 998;

    .provider-preferences-popup__modal {
      position: relative;
      width: 100%;
      max-width: 568px;
      padding: 46px 28px;
      background-color: #FFFFFF;
      border-radius: 16px;
      box-shadow: 0px 3px 6px #00000029;
      top: 50% !important;
      transform: scalex(1) translate(0, -50%) !important;
      margin: 0 auto;

      @media screen and (max-width: 600px) {
        margin: 0 16px;
        width: 94%;
      }

      @media screen and (max-width: 420px) {
        width: 92%;
      }

      .provider-preferences-popup__close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 24px;
        color: #8995A3;
      }

      .provider-preferences-popup__title {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        img {
          display: block;
          width: 80px;
          height: 84px;
          margin-top: 24px;
        }

        h2 {
          margin: 0;
          font-size: 24px;
          font-family: 'Proxima Nova Semibold', sans-serif;
          color: #2F3740;
        }
      }

      .provider-preferences-popup__description {
        margin-top: 32px;
        margin-bottom: 34px;
        font-size: 18px;
        font-family: 'Proxima Nova', sans-serif;
        text-align: center;
        color: #2F3740;
      }

      .provider-preferences-popup__cta {
        display: block;
        margin: 0 auto;
        width: fit-content;
        line-height: 47px;
        height: 47px;
        border-radius: 10px;
        font-size: 16px;
        padding: 0px 32px;
        min-width: 295px;
        background: transparent linear-gradient(261deg, #FF0073 0%, #DC0064 100%) 0% 0% no-repeat padding-box;
      }
    }
  }
}
</style>
