const VerificationProvider = window?.$h?.getProvider('VerificationProvider')

/**
 * Vuex State
*/
const state = {
  file_id_front_status: null,
  file_id_front_error: null,
  file_id_back_status: null,
  approved_id_seen: null,
  rejected_id_front_seen: null,
  rejected_id_back_seen: null,
  file_id_back_error: null,
  file_id_front: null,
  file_id_back: null,
  _id_front_url: null,
  _id_back_url: null,
  post_code: null,
  user_id: null,
  address: null,
  country: null,
  city: null,
  hasBillingDetails: false,
  hasBillingModalShown: false
}

/**
 * State getters
*/
const getters = {
  getBilling (state) {
    return state
  },
  isIDVerificationPending (state) {
    const bothPending = state.file_id_front_status === window?.BILLING_ID_STATUS?.PENDING &&
                        state._id_front_url !== null &&
                        state.file_id_back_status === window?.BILLING_ID_STATUS?.PENDING &&
                        state._id_back_url !== null

    const hasPending = (state.file_id_front_status === window?.BILLING_ID_STATUS?.PENDING &&
                        state._id_front_url !== null &&
                        state.file_id_back_status === window?.BILLING_ID_STATUS?.APPROVED) ||
                       (state.file_id_back_status === window?.BILLING_ID_STATUS?.PENDING &&
                        state._id_back_url !== null &&
                        state.file_id_front_status === window?.BILLING_ID_STATUS?.APPROVED)

    return bothPending || hasPending
  },
  hasIDSubmitted (state) {
    const hasFrontID = state.file_id_front_status === window?.BILLING_ID_STATUS?.PENDING &&
                       state._id_front_url !== null
    const hasBackID = state.file_id_back_status === window?.BILLING_ID_STATUS?.PENDING &&
                       state._id_back_url !== null

    return hasFrontID || hasBackID
  },
  hasIDApproved (state) {
    return state.file_id_front_status === window?.BILLING_ID_STATUS?.APPROVED &&
           state.file_id_back_status === window?.BILLING_ID_STATUS?.APPROVED
  },
  hasIDRejected (state) {
    return state.file_id_front_status === window?.BILLING_ID_STATUS?.REJECTED ||
           state.file_id_back_status === window?.BILLING_ID_STATUS?.REJECTED
  },
  hasBillingSubmitted (state) {
    return state.post_code !== null &&
           state.address !== null &&
           state.country !== null &&
           state.city !== null
  },
  getFrontIDUrl (state) {
    return state._id_front_url
  },
  getBackIDUrl (state) {
    return state._id_back_url
  },
  hasRejectedID (state) {
    return state.file_id_front_status === window?.BILLING_ID_STATUS?.REJECTED ||
           state.file_id_back_status === window?.BILLING_ID_STATUS?.REJECTED
  },
  hasBillingDetails (state) {
    return state.hasBillingDetails
  },
  getRejectedIDs (state) {
    const res = {
      file_id_front_error: null,
      file_id_back_error: null
    }

    if (state.file_id_front_status === window?.BILLING_ID_STATUS?.REJECTED &&
        state.rejected_id_front_seen === 0) {
      res.file_id_front_error = state.file_id_front_error
    }
    if (state.file_id_back_status === window?.BILLING_ID_STATUS?.REJECTED &&
        state.rejected_id_back_seen === 0) {
      res.file_id_back_error = state.file_id_back_error
    }

    return res
  },
  getVerificationIDs (state) {
    return {
      file_id_front_status: state.file_id_front_status,
      file_id_front_error: state.file_id_front_error,
      file_id_back_status: state.file_id_back_status,
      file_id_back_error: state.file_id_back_error,
      _id_front_url: state._id_front_url,
      _id_back_url: state._id_back_url
    }
  },
  getHasBillingModalShown (state) {
    return state.hasBillingModalShown
  },
  shouldShowSuccessVerification (state) {
    return state.file_id_front_status === window?.BILLING_ID_STATUS?.APPROVED &&
           state.file_id_back_status === window?.BILLING_ID_STATUS?.APPROVED &&
           state.approved_id_seen === 0
  },
  shouldShowRejectedVerification (state) {
    return (state.file_id_front_status === window?.BILLING_ID_STATUS?.REJECTED &&
      state.rejected_id_front_seen === 0) ||
           (state.file_id_back_status === window?.BILLING_ID_STATUS?.REJECTED &&
            state.rejected_id_back_seen === 0)
  }
}

/**
 * State mutations
*/
const mutations = {
  setBilling (state, newBilling) {
    for (const k in newBilling) {
      if (newBilling.hasOwnProperty(k)) {
        state[k] = newBilling[k]
      }
    }

    state.hasBillingDetails = true
  },
  updateApproveSeen (state, payload) {
    state.approved_id_seen = 1
  },
  updateIDStatus (state, payload) {
    state[`file_id_${payload.side}_status`] = payload.value
  },
  updateIDURL (state, payload) {
    state[`_id_${payload.side}_url`] = payload.url
  },
  updateIDRejectReason (state, payload) {
    state[`file_id_${payload.side}_error`] = payload.reason
    state[`file_id_${payload.side}_status`] = window?.BILLING_ID_STATUS?.REJECTED
    state[`rejected_id_${payload.side}_seen`] = 0
    state[`_id_${payload.side}_url`] = null
  },
  setHasBillingModalShown (state, value) {
    state.hasBillingModalShown = value
  },
  reset (state) {
    for (const k in state) {
      if (state.hasOwnProperty(k)) {
        state[k] = null
      }
    }
  }
}

/**
 * State actions
*/
const actions = {
  fetchBilling ({ commit }) {
    return new Promise((resolve, reject) => {
      VerificationProvider
        .fetchBillingDetails()
        .then((res) => {
          const data = res.data

          commit('setBilling', data)

          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  uploadIDs ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      VerificationProvider
        .uploadIDs(payload.frontID, payload.backID, payload.isReupload)
        .then((res) => {
          if (payload.frontID) {
            commit('updateIDStatus', {
              side: 'front',
              value: window?.BILLING_ID_STATUS?.PENDING
            })
          }
          if (payload.backID) {
            commit('updateIDStatus', {
              side: 'back',
              value: window?.BILLING_ID_STATUS?.PENDING
            })
          }

          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          this.isSubmitting = false
        })
    })
  },
  popupSeen ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      VerificationProvider
        .popupSeen(payload)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          this.isSubmitting = false
        })
    })
  }
}

/// ////////////////////////
// Nothing to do here...
/// ////////////////////////

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
