var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c(
        "div",
        {
          staticClass: "video-shortcut",
          attrs: { "data-test": "video-shortcut" }
        },
        [
          _c("div", { staticClass: "video-shortcut--container" }, [
            _c(
              "div",
              {
                staticClass: "video-shortcut--content",
                class: { full: _vm.hasBeenPlayed || _vm.hasVideoEnded }
              },
              [
                _vm.hasBeenPlayed
                  ? _c(
                      "video",
                      {
                        staticClass: "video-shortcut--video",
                        attrs: {
                          autoplay: "",
                          controls: "",
                          controlsList: "nodownload"
                        },
                        on: { ended: _vm.showReplayScreen }
                      },
                      [
                        _c("source", {
                          attrs: {
                            "data-test": "video-source",
                            src: _vm.videoSrc,
                            type: "video/mp4"
                          }
                        })
                      ]
                    )
                  : _vm.hasVideoEnded
                  ? _c("div", { staticClass: "video-shortcut--replay" }, [
                      _c("div", [
                        _c(
                          "a",
                          {
                            staticClass: "video-shortcut--replay-cta",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.playVideo($event)
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                "data-test": "video-replay",
                                src: require("@/features/onboarding-lesson/assets/onboarding-lesson-replay.svg"),
                                alt: "replay icon"
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("replay")))])
                      ])
                    ])
                  : _c("div", { staticClass: "video-shortcut--thumbnail" }, [
                      _c("div", {
                        staticClass: "video-shortcut--thumbnail-bg"
                      }),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "video-shortcut--thumbnail-cta",
                          attrs: { href: "#", "data-test": "video-play" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.playVideo($event)
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/features/onboarding-lesson/assets/onboarding-lesson-video-player-lg.svg")
                            }
                          })
                        ]
                      )
                    ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "video-shortcut--close",
                    attrs: { href: "#", "data-test": "video-close" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.closeShortcut($event)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("close")
                    ])
                  ]
                )
              ]
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }