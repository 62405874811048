var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container",
      attrs: {
        id: "content",
        "data-test": "providerpreferences__city-selection"
      }
    },
    [
      _c("bottomsheet", {
        attrs: { "is-shown": _vm.isShown },
        on: { close: _vm.closeBottomSheet },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("h2", {
                  staticClass: "bottom-sheet__title",
                  domProps: { textContent: _vm._s(_vm.$t("title")) }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "providerPreferences" },
                  _vm._l(_vm.sPPCopy, function(pp, key) {
                    return _c(
                      "div",
                      {
                        key: key,
                        class: ["option-item", { selected: pp.selected }],
                        on: {
                          click: function($event) {
                            return _vm.selectPP(key)
                          }
                        }
                      },
                      [_vm._v(_vm._s(pp.title))]
                    )
                  }),
                  0
                )
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c("register-stepper-v2", { attrs: { total: 1, current: 0 } }),
                _vm._v(" "),
                _c("div", { class: _vm.ctaContainerClass }, [
                  _c("div", {
                    staticClass: "select-count",
                    attrs: { "data-test": "pp-multi-selection-cta" },
                    domProps: { textContent: _vm._s(_vm.multiSelectCta) },
                    on: { click: _vm.onClickMutliSelect }
                  }),
                  _vm._v(" "),
                  _c("button", {
                    staticClass: "cta waves-effect",
                    attrs: { "data-test": "city-selection-cta" },
                    domProps: { textContent: _vm._s(_vm.saveCta) },
                    on: { click: _vm.submit }
                  })
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }