import { render, staticRenderFns } from "./empty-event.vue?vue&type=template&id=42145a79&"
import script from "./empty-event.vue?vue&type=script&lang=js&"
export * from "./empty-event.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider8-stage.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('42145a79')) {
      api.createRecord('42145a79', component.options)
    } else {
      api.reload('42145a79', component.options)
    }
    module.hot.accept("./empty-event.vue?vue&type=template&id=42145a79&", function () {
      api.rerender('42145a79', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/account/events/components/empty-event.vue"
export default component.exports