<template>
  <div class="referral">
    <div class="referral__container">
      <section>

        <img class="referral__icon" width="48" height="48" src="/img/icons/k-logo-new.svg" alt="krypton icon">
        <h1 class="referral__title">{{ $t('referral.title') }}</h1>
        <p class="referral__text">{{ $t('referral.subtitle_1') }}</p>
        <button class="referral__cta" @click.prevent="goToRegistration">
          {{ $t('referral.cta') }} <span style="margin-left: 12px;">&#10140;</span>
        </button>
      </section>

      <picture>
        <source media="(min-width: 600px)" srcset="/img/affiliate/referral-landing-page.png">
        <img class="referral__banner" src="/img/affiliate/referral-landing-page-mobile.png" alt="referral banner">
      </picture>

      <div class="referral__info">
        <section>
          <h2 class="referral__info-title">{{ $t('referral.info.title') }}</h2>
          <p class="referral__info-subtitle">{{ $t('referral.info.subtitle') }}</p>
          <ul class="referral__info-list">
            <li>
              <p><strong>{{ $t('referral.info.item_1_title') }}</strong></p>
              <p>{{ $t('referral.info.item_1_text') }}</p>
            </li>

            <li>
              <p><strong>{{ $t('referral.info.item_2_title') }}</strong></p>
              <p>{{ $t('referral.info.item_2_text') }}</p>
            </li>

            <li>
              <p><strong>{{ $t('referral.info.item_3_title') }}</strong></p>
              <p>{{ $t('referral.info.item_3_text') }}</p>
            </li>
          </ul>

          <div class="referral__link">
            <a href="" @click.prevent="goToRegistration">
              {{ $t('referral.cta') }}
            </a><span style="margin-left: 15px; text-decoration: none;">&#10140;</span>
          </div>
        </section>
      </div>

      <LanguageSwitcherAuthView />
    </div>
  </div>
</template>

<i18n src="../translations.json"></i18n>

<script>
import LanguageSwitcherAuthView from '@/features/language-switcher/components/LanguageSwitcherAuthView.vue'

export default {
  components: {
    LanguageSwitcherAuthView
  },
  beforeRouteEnter: (to, from, next) => {
    // guard route if there's no refcode
    if (!to.query.hasOwnProperty('refcode')) {
      try {
        const refcodeFromStorage = localStorage.getItem('refcode')
        const trackingcodeFromStorage = localStorage.getItem('trackingcode')

        if (refcodeFromStorage && trackingcodeFromStorage) {
          next({
            query: {
              refcode: refcodeFromStorage,
              trackingcode: trackingcodeFromStorage
            },
            name: 'referral',
            params: { ...to.params }
          })
        } else if (refcodeFromStorage) {
            next({
              query: {
                refcode: refcodeFromStorage
              },
              name: 'referral',
              params: { ...to.params }
            })
        } else {
          next({ name: 'login' })
        }
      } catch (err) {
        next({ name: 'login' })
      }
    }

    next()
  },
  mounted () {
    const refcodeFromUrl = this.$route?.query?.refcode ?? null
    localStorage.setItem('refcode', refcodeFromUrl)

    const trackingcodeFromUrl = this.$route?.query?.trackingcode ?? null
    localStorage.setItem('trackingcode', trackingcodeFromUrl)

    this.$nextTick(() => {
      document.title = this.$t('referral.title_tag')
    })
  },
  methods: {
    goToRegistration () {
      let refCode = this.$route?.query?.refcode
      let trackingCode = this.$route?.query?.trackingcode ?? null

      this.$router.push({
        name: 'register-start',
        query: {
          refcode: refCode,
          trackingcode: trackingCode,
        },
        params: { ...this.$route.params }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.referral {
  width: 100%;
  min-height: 100dvh;
  position: relative;
  background-color: #F7FAFF;

  .referral__container {
    background-color: #FFFFFF;
    width: 100%;
    max-width: 696px;
    box-shadow: 0px 16px 32px #2F37403D;
    text-align: center;
    padding: 13px 0 101px;
    color: #2F3740;

    @media screen and (min-width: 600px) {
      margin: 119px auto 216px;
      padding: 81px 0 84px;
      border-radius: 24px;
    }

    section {
      padding: 0 24px;

      @media screen and (min-width: 600px) {
        margin: 0 auto;
        max-width: 329px;
        padding: 0;
      }
    }

    .referral__icon {
      vertical-align: middle;
    }

    .referral__title {
      font-family: 'Proxima Nova Semibold';
      font-size: 32px;
      margin: 48px 0 32px;
      color: #2F3740;
    }

    .referral__text {
      font-family: 'Proxima Nova Medium';
      font-size: 18px;
      margin: 0;
      color: #2F3740;
    }

    .referral__cta {
      font-family: 'Proxima Nova Semibold';
      font-size: 15px;
      width: 100%;
      min-width: 329px;
      height: 47px;
      border-radius: 10px;
      background: transparent linear-gradient(262deg, #FF0073 0%, #DC0064 100%) 0% 0% no-repeat padding-box;
      color: white;
      box-shadow: none;
      border: none;
      outline: none;
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 6px;
    }

    .referral__banner {
      width: 100%;
      margin: 36px 0 0;
    }

    .referral__info {
      padding-top: 40px;
      padding-bottom: 34px;
      background-color: #F7FAFF;
      width: 100%;
      height: auto;

      .referral__info-title {
        font-size: 26px;
        margin-top: 0;
        margin-bottom: 40px;
        color: #2F3740;
      }

      .referral__info-subtitle {
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 30px;
        color: #2E353D;
      }

      ul {
        padding-block-start: 0;
        padding-inline-start: 24px;

        li {
          text-align: left;
          list-style-type: none;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 6px;
            left: -24px;
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background: #2F3740 0% 0% no-repeat padding-box;
            border: 1px solid #2F3740;
          }

          &:not(:last-of-type) {
            margin-bottom: 24px;
          }

          p {
            margin: 0;
            font-size: 18px;
            line-height: 24px;
            color: #2E353D;

            &:first-of-type {
              margin-bottom: 12px;
            }

            strong {
              font-family: 'Proxima Nova Bold';
            }
          }
        }
      }
    }

    .referral__link {
      margin-top: 32px;
      text-align: left;

      a {
        font-family: 'Proxima Nova Bold';
        color: #2F3740;
        text-decoration: underline;
        font-size: 15px;
      }
    }
  }

  & ::v-deep .lang-switcher {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    a {
      font-family: 'Proxima Nova Semibold';
      font-size: 14px;
      padding: 0 11px;
      color: #A6B2BF;
    }
    a:first-child {
      border-right: 1px solid #E1E6EB;
    }
    a.active {
      color: #DC0064;
    }
  }
}
</style>
