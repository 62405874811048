import { FAQ_TEST_AMOUNTS } from '@/features/affiliate/constants.js'

export default {
  computed: {
    getArgumentsForFAQContent () {
      const getMultiplesByCountry = this.isFromGB ? this.$t('multiples_4') : this.$t('multiples_3')
      const getNominalAmountByCountry = this.isFromGB ? FAQ_TEST_AMOUNTS.uk_based.nominal_amount : FAQ_TEST_AMOUNTS.de_based.nominal_amount
      const getInvoiceAmount = this.isFromGB ? FAQ_TEST_AMOUNTS.uk_based.invoice_amount : FAQ_TEST_AMOUNTS.de_based.invoice_amount
      const getNoOfInvoices = this.isFromGB ? this.$t('multiples_4_word') : this.$t('multiples_3_word')
      const getNewInvoiceAmount = this.isFromGB ? FAQ_TEST_AMOUNTS.uk_based.new_invoice_amount : FAQ_TEST_AMOUNTS.de_based.new_invoice_amount
      const arrangeAmountAndCurrencyByCountry = amount => {
        return this.isFromGB
          ? `${this.currency} ${amount}`
          : `${amount} ${this.currency}`
      }

      return {
        amount: this.getRewardByCountryCode,
        nominal_amount: `${getMultiplesByCountry} ${arrangeAmountAndCurrencyByCountry(getNominalAmountByCountry)}`,
        invoice_amount: `${arrangeAmountAndCurrencyByCountry(getInvoiceAmount)}`,
        no_of_invoices: getNoOfInvoices,
        new_invoice_amount: `${arrangeAmountAndCurrencyByCountry(getNewInvoiceAmount)}`
      }
    },
    affiliateSettings () {
      return this.$store.getters['affiliate/getAffiliateSettings']
    },
    user () {
      return this.$store.getters.getUser
    },
    currency () {
      return this.user?._currency ?? '€'
    },
    isFromGB () {
      return (this.user?.profiles?.country_code ?? '').toLowerCase() === 'gb'
    },
    getCurrencySymbolByCountryCode () {
      return this.isFromGB ? this.affiliateSettings?.currency_symbols?.gbp : this.affiliateSettings?.currency_symbols?.eur
    },
    getRewardByCountryCode () {
      return this.isFromGB
        ? `${this.currency} ${this.affiliateSettings?.rewards?.gbp}`
        : `${this.affiliateSettings?.rewards?.eur} ${this.currency} `
    },
    isArrowUpOrDown () {
      return (key) => {
        return parseInt(key) === this.active ? 'keyboard_arrow_up' : 'keyboard_arrow_right'
      }
    },
    isActive () {
      return (key) => {
        return parseInt(key) === this.active
      }
    },
    isLastItem () {
      return key => (Object.keys(this.$t('faq.list') ?? []).length - 1) === parseInt(key)
    }
  }
}
