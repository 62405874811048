var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isViewReady
    ? _c(
        "div",
        {
          staticClass: "event-status",
          class: _vm.statusClass,
          attrs: { id: "event-details" }
        },
        [
          _c("md-navbar-event", {
            attrs: {
              push: { name: "events" },
              brand: _vm.$translate("events.nav_title"),
              disabled: _vm.isSending,
              icon: "arrow_back"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "container",
              class: {
                "video-date": _vm.isVideoDateMeeting,
                "has-custom-note": !_vm.isPending && _vm.eventNote
              },
              attrs: { id: "content" }
            },
            [
              _c("h1", { staticClass: "title" }, [
                _c("span", [_vm._v(_vm._s(_vm.statusText))]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    ref: "tooltip",
                    staticClass: "white-text tooltip tooltipped",
                    class: { hide: !_vm.isConfirmed },
                    attrs: {
                      href: "#",
                      "data-position": "bottom",
                      "data-tooltip": _vm.tooltipText
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        staticStyle: { "font-size": "16px" }
                      },
                      [_vm._v("help")]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row schedule" }, [
                _c(
                  "div",
                  {
                    staticClass: "center-align",
                    attrs: { id: "event-details-event-date" }
                  },
                  [
                    _c("i", { staticClass: "material-icons valign-middle" }, [
                      _vm._v("today")
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.eventDate))])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "center-align",
                    attrs: { id: "event-details-event-duration" }
                  },
                  [
                    _c("i", { staticClass: "material-icons valign-middle" }, [
                      _vm._v("access_time")
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.eventDuration))])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "notes radius16" }, [
                _c("ul", { staticClass: "left-align notes-detail" }, [
                  _vm.isVideoDateMeeting
                    ? _c(
                        "li",
                        {
                          staticClass: "checked",
                          attrs: { id: "event-details-video-date-status" }
                        },
                        [
                          _c("strong", { staticClass: "krypton-purple-text" }, [
                            _vm._v(
                              _vm._s(_vm.$translate("events.video_date.title"))
                            )
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v("Skype Call")])
                        ]
                      )
                    : _c(
                        "li",
                        {
                          class: _vm.hotelStatusUpdate,
                          attrs: { id: "event-details-hotel-status" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "event-details-hotel-name" },
                            [
                              _vm.isPrivatePlaceMeeting
                                ? _c(
                                    "i",
                                    {
                                      staticClass: "material-icons",
                                      attrs: {
                                        "data-test": "event-details-pp-icon"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              person_pin_circle\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isMultiLocationMeeting
                                ? _c("multi-location-event-icon")
                                : _vm._e(),
                              _vm._v(" "),
                              _c("strong", [
                                _vm._v(_vm._s(_vm.getSelectedEventHotelName))
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.isMultiLocationMeeting
                            ? _c("multi-location-event-address", {
                                attrs: {
                                  event: _vm.selectedEvent,
                                  "is-event-details": ""
                                }
                              })
                            : _c("span", [
                                _vm._v(
                                  _vm._s(_vm.selectedEvent._hotel_address || "")
                                )
                              ])
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class: _vm.clientStatusUpdate,
                      attrs: { id: "event-details-client-status" }
                    },
                    [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$translate("events.labels.meeting")))
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm.selectedEvent._have_met_before
                          ? _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons valign-middle tiny rotate-opposite"
                              },
                              [_vm._v("refresh")]
                            )
                          : _vm._e(),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.selectedEvent.client.name || "") +
                            ", " +
                            _vm._s(_vm.selectedEvent.client.age || "") +
                            "\n            "
                        ),
                        _vm.selectedEvent._have_met_before
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "events.event_details.met_before"
                                  )
                                )
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class: _vm.outfitStatusUpdate,
                      attrs: { id: "event-details-dress-code" }
                    },
                    [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$translate("events.labels.outfit")))
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.getOutfit(_vm.selectedEvent.dress_code) || ""
                          )
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isPending && _vm.eventNote
                    ? _c(
                        "li",
                        {
                          class: _vm.notesStatusUpdate,
                          attrs: { id: "event-details-custom-notes" }
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$translate("events.labels.note")))
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.eventNote))])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isPending && !_vm.isVideoDateMeeting
                    ? _c(
                        "li",
                        {
                          class: _vm.meetingPointStatusUpdate,
                          attrs: { id: "event-details-meeting-point" }
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.$translate("events.labels.meeting_point")
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { attrs: { "data-test": "meeting-point-text" } },
                            [_vm._v(_vm._s(_vm.getMeetingPointDisplay))]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  ((!_vm.isPending && !_vm.isReadyToGo) ||
                    (_vm.isReadyToGo && _vm.shouldShowIdentifier)) &&
                  !_vm.isVideoDateMeeting
                    ? _c(
                        "li",
                        {
                          class: _vm.identifierStatusUpdate,
                          attrs: { id: "event-details-client-identifier" }
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.$translate("events.labels.identifier"))
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              attrs: { "data-test": "client-identifier-text" }
                            },
                            [_vm._v(_vm._s(_vm.getClientIdentifierDisplay))]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.shouldDisplayAdditionalDetailsSection
                    ? _c(
                        "li",
                        {
                          class: _vm.additionalDetailsStatusUpdate,
                          attrs: {
                            id: "event-details-client-additional-details"
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.$translate(
                                  "events.labels.additional_details"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.additionalDetails))])
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.isPending
                  ? _c("div", { staticClass: "request-acceptance" }, [
                      _c("div", { staticClass: "coin" }),
                      _vm._v(" "),
                      _c("p", [
                        _c(
                          "strong",
                          [
                            _c("currency"),
                            _vm._v(
                              _vm._s(_vm.selectedEvent.amount_payable) +
                                "\n            "
                            ),
                            _vm.isMultiLocationMeeting
                              ? _c(
                                  "span",
                                  [
                                    _vm._v("+ "),
                                    _c("currency"),
                                    _vm._v(
                                      _vm._s(_vm.selectedEvent.travel_cost)
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isPending
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "accept-btn btn btn-krypton-pink waves-effect waves-light",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.acceptRequest($event)
                            }
                          }
                        },
                        [
                          !_vm.isSending
                            ? _c(
                                "span",
                                { class: { "grey-text": _vm.isRejecting } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$translate("events.buttons.accept")
                                    )
                                  )
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "preloader-wrapper small active"
                                },
                                [_vm._m(0)]
                              )
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "margin-md" }, [
                        _c("small", [
                          _vm._v(
                            _vm._s(_vm.$translate("events.labels.disclaimer"))
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "event-details-wrapper",
              class: {
                "video-date": _vm.isVideoDateMeeting,
                "has-custom-note": !_vm.isPending && _vm.eventNote
              }
            },
            [
              _c("div", { staticClass: "event-details-control" }, [
                (!_vm.isPending && !_vm.canReactivateEvent) || _vm.isReactivated
                  ? _c(
                      "div",
                      { staticClass: "slider-container container" },
                      [
                        _c(
                          "v-touch",
                          { on: { swiperight: _vm.onDisabledSwipe } },
                          [
                            !_vm.isSliderEnabled
                              ? _c("div", { staticClass: "cannot-slide" })
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c("md-slide-to-unlock", {
                          attrs: {
                            min: 0,
                            max: 100,
                            label: _vm.$translate(
                              "events.labels.slide_check_in"
                            ),
                            disabled: !_vm.isSliderEnabled,
                            "is-sending": _vm.isSending
                          },
                          model: {
                            value: _vm.pulleeVal,
                            callback: function($$v) {
                              _vm.pulleeVal = $$v
                            },
                            expression: "pulleeVal"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                (!_vm.isPending && !_vm.canReactivateEvent) || _vm.isReactivated
                  ? _c(
                      "div",
                      {
                        staticClass: "align-center container",
                        attrs: { id: "controls" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "control-btn col s4 clickable waves-effect waves-light",
                            class: {
                              disabled:
                                !_vm.canSendLateNotification ||
                                _vm.isReactivated ||
                                _vm.isSending
                            },
                            attrs: { id: "event-details-is-late-btn" },
                            on: { click: _vm.onClickLate }
                          },
                          [
                            _c(
                              "i",
                              { staticClass: "material-icons valign-middle" },
                              [_vm._v("access_time")]
                            ),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$translate("events.buttons.late"))
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "control-btn col s4 clickable waves-effect waves-light",
                            class: {
                              disabled:
                                !_vm.canAskWhereIsHe ||
                                _vm.isReactivated ||
                                _vm.isSending
                            },
                            staticStyle: { "line-height": "16px" },
                            attrs: { id: "event-details-where-is-he-btn" },
                            on: { click: _vm.onClickWhereIs }
                          },
                          [
                            _c("div", { staticClass: "where-is-she-icon" }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate("events.buttons.where_is_he")
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _vm.selectedEvent._unread_message_count > 0 &&
                            _vm.canAskWhereIsHe
                              ? _c(
                                  "span",
                                  { staticClass: "unread-message pulse" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.selectedEvent._unread_message_count
                                      )
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "control-btn col s4 clickable waves-effect waves-light",
                            class: {
                              disabled:
                                _vm.isCancelled ||
                                _vm.isReactivated ||
                                _vm.isSending
                            },
                            attrs: { id: "event-details-support-btn" },
                            on: { click: _vm.onClickSupport }
                          },
                          [
                            _c(
                              "i",
                              { staticClass: "material-icons valign-middle" },
                              [_vm._v("help_outline")]
                            ),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$translate("events.buttons.support"))
                              )
                            ]),
                            _vm._v(" "),
                            _vm.eventDetail &&
                            _vm.eventDetail._support !== null &&
                            _vm.eventDetail._support.unread > 0
                              ? _c(
                                  "span",
                                  { staticClass: "unread-message pulse" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.eventDetail._support.unread)
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.canReactivateEvent
                  ? _c(
                      "div",
                      {
                        staticClass: "align-center container margin-top-xl",
                        attrs: { id: "controls" }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-secondary col s4 clickable waves-effect waves-dark",
                            class: {
                              disabled: _vm.isReactivating
                            },
                            on: { click: _vm.onClickNotAvailable }
                          },
                          [
                            !_vm.isReactivateDeclining
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$translate(
                                        "events.buttons.not_availble"
                                      )
                                    )
                                  )
                                ])
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "preloader-wrapper small active"
                                  },
                                  [_vm._m(1)]
                                )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn col s4 clickable waves-effect waves-light",
                            class: {
                              disabled: _vm.isReactivateDeclining
                            },
                            on: { click: _vm.onClickReactivate }
                          },
                          [
                            !_vm.isReactivating
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$translate(
                                        "events.buttons.reactivate"
                                      )
                                    )
                                  )
                                ])
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "preloader-wrapper small active"
                                  },
                                  [_vm._m(2)]
                                )
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isPending
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "align-center container decline-controls margin-top-xl",
                        attrs: { id: "controls" }
                      },
                      [
                        _c(
                          "a",
                          {
                            class: {
                              "waves-effect waves-dark": !_vm.isSending
                            },
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.declineRequest($event)
                              }
                            }
                          },
                          [
                            _c(
                              "strong",
                              { class: { "grey-text": _vm.isSending } },
                              [
                                !_vm.isRejecting
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$translate(
                                            "events.buttons.decline"
                                          )
                                        )
                                      )
                                    ])
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "preloader-wrapper small active",
                                        attrs: { hotelStatusUpdate: "" }
                                      },
                                      [_vm._m(3)]
                                    )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", [
                          _c("small", [
                            _vm._v(
                              _vm._s(
                                _vm.$translate("events.labels.footer_note")
                              )
                            )
                          ])
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner-layer spinner-green-only" }, [
      _c("div", { staticClass: "circle-clipper left" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gap-patch" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "circle-clipper right" }, [
        _c("div", { staticClass: "circle" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner-layer spinner-green-only" }, [
      _c("div", { staticClass: "circle-clipper left" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gap-patch" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "circle-clipper right" }, [
        _c("div", { staticClass: "circle" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner-layer spinner-green-only" }, [
      _c("div", { staticClass: "circle-clipper left" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gap-patch" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "circle-clipper right" }, [
        _c("div", { staticClass: "circle" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner-layer spinner-green-only" }, [
      _c("div", { staticClass: "circle-clipper left" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gap-patch" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "circle-clipper right" }, [
        _c("div", { staticClass: "circle" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }