import { render, staticRenderFns } from "./billing-paid.vue?vue&type=template&id=2f3bab46&"
import script from "./billing-paid.vue?vue&type=script&lang=js&"
export * from "./billing-paid.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider8-stage.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2f3bab46')) {
      api.createRecord('2f3bab46', component.options)
    } else {
      api.reload('2f3bab46', component.options)
    }
    module.hot.accept("./billing-paid.vue?vue&type=template&id=2f3bab46&", function () {
      api.rerender('2f3bab46', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/account/billing/tabs/billing-paid.vue"
export default component.exports