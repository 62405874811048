import { buildParam } from '@/services/ApiService'

const submitField = (field, payload) => {
  var url = buildParam('/v2/register/' + field)

  return new Promise((resolve, reject) => {
    window.api.put({ url: url, payload: payload },
      (success) => {
        resolve(success.data)
      },
      (err) => {
        reject(err.response.data)
      })
  })
}

export default {
  submitField
}
