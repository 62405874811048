var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("RegisterController", {
    tag: "component",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var data = ref.data
          var bindings = ref.bindings
          var actions = ref.actions
          return [
            _c(
              "AuthView",
              {
                tag: "component",
                attrs: { data: data, route: _vm.$route.name },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c("h2", [
                            _vm._v(_vm._s(actions.translate("register.title1")))
                          ]),
                          _vm._v(" "),
                          _c("h2", [
                            _vm._v(_vm._s(actions.translate("register.title2")))
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _vm._v(" "),
                _c(
                  "apv-form",
                  {
                    attrs: {
                      novalidate: "",
                      "data-test": "register-form",
                      "is-loading": data.isLoading
                    },
                    on: { submit: bindings.handleRegister }
                  },
                  [
                    _c("apv-input", {
                      staticClass: "margin-bottom-md",
                      attrs: {
                        type: "email",
                        autocomplete: "username",
                        tabindex: "1",
                        "data-test": "input-email",
                        value: data.username,
                        error: actions.getFieldError("email"),
                        label: actions.translate("register.email")
                      },
                      on: { input: bindings.handleUsernameChange }
                    }),
                    _vm._v(" "),
                    _c("apv-input", {
                      staticClass: "margin-bottom-xl",
                      attrs: {
                        type: "password",
                        autocomplete: "password",
                        tabindex: "2",
                        "data-test": "input-password",
                        value: data.password,
                        label: actions.translate("register.password"),
                        error: actions.getFieldError("password")
                      },
                      on: { input: bindings.handlePasswordChange }
                    }),
                    _vm._v(" "),
                    _c(
                      "apv-button",
                      {
                        attrs: {
                          rounded: 10,
                          "is-loading": data.isLoading,
                          block: "",
                          gradient: "",
                          type: "submit",
                          size: "large",
                          "waves-effect": "dark",
                          disabled:
                            bindings.isEmpty(data.username) ||
                            bindings.isEmpty(data.password),
                          "data-test": "register-cta"
                        }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              actions.translate("register.createFreeProfile")
                            ) +
                            "\n      "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "footer-auth-nav" }, [
                  _c(
                    "div",
                    { staticClass: "login-container" },
                    [
                      _c("span", { staticClass: "login-label" }, [
                        _vm._v(
                          _vm._s(actions.translate("register.link2loginText1"))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "login-link",
                          attrs: { to: data.loginRoute }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              actions.translate("register.link2loginText2")
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }