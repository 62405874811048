var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page", attrs: { id: "krypton-connect" } },
    [
      _c("md-navbar", {
        attrs: {
          push: { name: "inbox" },
          brand: _vm.$translate("sidenav.inbox.title"),
          "menu-trigger": false,
          icon: "arrow_back"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "container", attrs: { id: "content" } }, [
        _c(
          "div",
          { staticClass: "ticket-title left-align container padding-y-md" },
          [
            !_vm.isFetching
              ? _c("strong", [_vm._v(_vm._s(_vm.ticketTitle))])
              : _c("strong", [_vm._v("Fetching messages...")])
          ]
        ),
        _vm._v(" "),
        _vm.header.type == 3
          ? _c("div", { staticClass: "photo-like-guide" }, [
              _vm.header.like_type == 2
                ? _c("div", { staticClass: "photo-like-guide__content" }, [
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$translate("inbox.chat.favorite_like", {
                            client_details: _vm.ticketTitle
                          })
                        )
                      }
                    })
                  ])
                : _c("div", { staticClass: "photo-like-guide__content" }, [
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$translate("inbox.chat.photo_like", {
                            client_details: _vm.ticketTitle
                          })
                        )
                      }
                    })
                  ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.messageListClassName },
          _vm._l(_vm.messages, function(mes, i) {
            return _c("chat-bubble", {
              key: i,
              attrs: {
                me: _vm.isProvider(mes.from_group_id),
                content: mes.content,
                "sent-at": mes.created_at,
                "seen-at": mes.seen_at
              },
              on: {
                block: function($event) {
                  return _vm.onBlockClient(mes.from_user_id)
                }
              }
            })
          }),
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "chat-controls f-position" }, [
          _c("div", { staticClass: "control-info margin-y-sm" }, [
            _c("div", { staticClass: "left-side" }, [
              _c(
                "strong",
                { staticClass: "message-count left valign-middle" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$translate("events.where_is.message_left", {
                        count: _vm.sendMessageRemaining
                      })
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c("small", { staticClass: "character-count right" }, [
                _vm._v(
                  _vm._s(_vm.message.length) + "/" + _vm._s(_vm.MAX_CHARACTER)
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "spacer" })
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.send($event)
                }
              }
            },
            [
              _c("md-textarea", {
                staticClass: "textfield-box-pink",
                attrs: {
                  id: "chat-message",
                  type: "multiline",
                  placeholder: _vm.textPlaceholder,
                  "force-active": true,
                  maxlength: _vm.MAX_CHARACTER,
                  disabled: !_vm.canSendMessage || _vm.isFetching,
                  required: ""
                },
                on: { click: _vm.onMessageClicked },
                model: {
                  value: _vm.message,
                  callback: function($$v) {
                    _vm.message = $$v
                  },
                  expression: "message"
                }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn-floating btn-large btn-round waves-effect btn-krypton-pink waves-effect waves-light margin-top-sm",
                  attrs: {
                    id: "send-btn",
                    type: "submit",
                    disabled: !_vm.canSendMessage || _vm.isFetching
                  }
                },
                [_c("i", { staticClass: "material-icons" }, [_vm._v("send")])]
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }