var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "register-page", attrs: { id: "register-mobile" } },
    [
      _c("div", { attrs: { id: "start" } }, [
        _c(
          "div",
          { staticClass: "container", attrs: { id: "content" } },
          [
            _c("img", {
              staticStyle: { "border-radius": "4px" },
              attrs: { src: "/img/icons/kr-logo-144.png", width: "48" }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "font-size": "32px" }, attrs: { id: "header" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "text-darken-1",
                    staticStyle: { "line-height": "36px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$translate("register.start.header_line_1")) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "text-darken-1" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$translate("register.start.header_line_2")) +
                      "\n        "
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "vform",
              { on: { submit: _vm.doSubmit } },
              [
                _vm.errorStart
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align"
                      },
                      [_c("small", [_vm._v(_vm._s(_vm.errorStart))])]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "md-input",
                  {
                    staticClass: "textfield-box-lg",
                    attrs: {
                      value: _vm.username,
                      type: "text",
                      autocomplete: "username",
                      tabindex: "1",
                      label: _vm.$translate("register.start.email")
                    },
                    on: { input: _vm.onUsernameChange }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errorEmail,
                            expression: "errorEmail"
                          }
                        ],
                        staticClass: "left-align input-helper red-text"
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errorEmail) +
                            "\n          "
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "md-input",
                  {
                    staticClass: "textfield-box-lg",
                    attrs: {
                      value: _vm.password,
                      type: "password",
                      autocomplete: "password",
                      tabindex: "2",
                      label: _vm.$translate("register.start.password")
                    },
                    on: { input: _vm.onPasswordChange }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errorPassword,
                            expression: "errorPassword"
                          }
                        ],
                        staticClass: "left-align input-helper red-text"
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errorPassword) +
                            "\n          "
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "left-align margin-bottom-md" }, [
                  _c("span", { staticClass: "grey-text xsmall" }, [
                    _vm._v(
                      _vm._s(_vm.$translate("register.start.password_tip"))
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-raised btn-large waves-effect waves-light display-block btn-krypton-pink waves-effect waves-light",
                    attrs: { type: "submit", tabindex: "3" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$translate("register.start.register_btn")) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("em", [
                  _c("small", [
                    _vm._v(
                      _vm._s(_vm.$translate("register.start.register_sub_text"))
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "margin-top-lg",
                    staticStyle: { "font-size": "14px" }
                  },
                  [
                    _c("span", { staticStyle: { color: "#757575" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$translate("register.start.already_registered")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.$router.push({ name: "login" })
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$translate("register.start.login_instead")
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }