var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wraper", attrs: { id: "super-provider-landing-page" } },
    [
      _c(
        "div",
        { staticClass: "container", attrs: { id: "content" } },
        [
          _c("feature-overview", {
            attrs: {
              "header-title": _vm.$t("header.title"),
              "header-subtitle": _vm.$t("header.subtitle"),
              "header-tagline": _vm.$t("content.tagline"),
              "main-header-logo":
                "/img/super-provider/super-provider-overview.svg",
              "tagline-logo":
                "/img/super-provider/super-provider-tagline-logo.svg",
              "primary-header-color": "#4B38FA",
              "secondary-header-color": "#D300C5",
              "header-gradient-angle": "46deg",
              "tagline-gradient-angle": "18deg",
              "header-navbar-redirection": "dashboard",
              "header-img-dimensions": { height: "209px", width: "245px" },
              features: _vm.features
            }
          }),
          _vm._v(" "),
          _c("q-and-a", {
            attrs: {
              title: _vm.$t("faq.title"),
              "default-opened-answer": 0,
              questions: _vm.questions
            },
            scopedSlots: _vm._u([
              {
                key: "questionItem",
                fn: function(ref) {
                  var question = ref.question
                  var index = ref.index
                  return [
                    index === _vm.VIDEO_ITEM_KEY
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "question",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.setOpenedAnswer(index)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v(_vm._s(_vm.getIcon(index)))
                              ]),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "label",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("faq.videoItem.title")
                                  )
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _vm.openedAnswer === index
                            ? _c(
                                "div",
                                { staticClass: "answer" },
                                [
                                  _c("div", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("faq.videoItem.section_1")
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("div", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("faq.videoItem.section_2")
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("super-provider-video"),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "margin-top-lg" }, [
                                    _c("strong", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("faq.videoItem.section_3")
                                        )
                                      }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("faq.videoItem.section_4")
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c(
                                      "ol",
                                      _vm._l(
                                        _vm.$t("faq.videoItem.list"),
                                        function(item2, key2) {
                                          return _c("li", { key: key2 }, [
                                            _c("strong", [
                                              _vm._v(_vm._s(item2.title))
                                            ]),
                                            _vm._v(" "),
                                            item2.content.length
                                              ? _c("span", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      item2.content
                                                    )
                                                  }
                                                })
                                              : _vm._e()
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      : index === _vm.LINK_ITEM_KEY
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "question",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.setOpenedAnswer(index)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v(_vm._s(_vm.getIcon(index)))
                              ]),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "label",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("faq.linkItem.title")
                                  )
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _vm.openedAnswer === index
                            ? _c(
                                "div",
                                { staticClass: "answer" },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("faq.linkItem.section_1")
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "router-link",
                                    {
                                      attrs: { to: { name: "reliability-faq" } }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("faq.linkItem.section_2"))
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("faq.linkItem.section_3")
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      : _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "question",
                              on: {
                                click: function($event) {
                                  return _vm.setOpenedAnswer(index)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v(_vm._s(_vm.getIcon(index)))
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "label" }, [
                                _vm._v(_vm._s(question.title))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _vm.openedAnswer === index
                            ? _c("div", {
                                staticClass: "answer",
                                domProps: {
                                  innerHTML: _vm._s(question.content)
                                }
                              })
                            : _vm._e()
                        ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("overview-cta", {
            attrs: {
              "redirect-to": "super-provider",
              label: _vm.$t("cta2"),
              img: "/img/super-provider/super-provider-cta.svg"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }