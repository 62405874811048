export default {
  computed: {
    errorEmail () {
      return this.error.email.length > 0 ? this.error.email : null
    },
    errorPassword () {
      return this.error.password.length > 0 ? this.error.email : null
    },
    errorStart () {
      return this.error.start.length > 0 ? this.error.start : null
    }
  },
  methods: {
    onUsernameChange (val) {
      this.$emit('update:username', val)
    },
    onPasswordChange (val) {
      this.$emit('update:password', val)
    },
    doSubmit (next) {
      this.$emit('submit', next)
    }
  },
  props: {
    username: { type: String, default: '' },
    password: { type: String, default: '' },
    error: { type: Object, required: true }
  }
}
