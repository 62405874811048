<template>
  <div class="steps-container">
    <div v-for="i in total" :key="`step-item-${i}`" class="step" 
        :class="{ 'active': i <= current }" 
    />
  </div>
</template>

<script>
export default {
  props: [
    'current',
    'total'
  ],
  computed: {
    stepperLabel () {
      return this.title + ' ' + this.current + '/' + this.total
    }
  }
}
</script>

<style lang="scss" scoped>

.new-step {
    &:not(.active) {
        &:last-child {
            border-bottom: 1px solid #E0E0E0;
        }
    }
}

.steps-container {
    & {
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .step {
        height: 2px;
        flex: 1 1 auto;
        background-color: #c4c4c4;

        &:not(.active) {
            &:last-child {
                border-bottom: 1px solid #E0E0E0;
            }
        }
    }

    /* .step:not(:last-child) {
        margin-right: 1px;
    } */

    .step.active {
        background-color: #2F3740;
    }
}
</style>
