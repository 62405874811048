const ApiProvider = window?.$h?.getProvider('ApiProvider')

class OnboardingProvider {
  fetchActiveLesson () {
    return new Promise((resolve, reject) => {
      window.api.get({ url: ApiProvider.fetchActiveLesson() },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response) })
    })
  }

  fetchLessonProgress (lessonId) {
    return new Promise((resolve, reject) => {
      window.api.get({ url: ApiProvider.fetchLessonProgress(lessonId) }, (success) => {
        resolve(success.data)
      }, (err) => {
        reject(err.response)
      })
    })
  }

  completeLesson (lessonId) {
    return new Promise((resolve, reject) => {
      window.api.post({
        url: ApiProvider.completeLesson(lessonId),
        payload: {
          _method: 'PUT'
        }
      }, (success) => {
        resolve(success.data)
      }, (err) => {
        reject(err.response)
      })
    })
  }

  submitLessonFeedback (lessonId, feedback) {
    return new Promise((resolve, reject) => {
      window.api.post({
        url: ApiProvider.submitLessonFeedback(lessonId),
        payload: {
          feedback
        }
      }, (success) => {
        resolve(success.data)
      }, (err) => {
        reject(err.response)
      })
    })
  }

  viewLesson (lessonId) {
    return new Promise((resolve, reject) => {
      window.api.post({
        url: ApiProvider.viewLesson(lessonId),
        payload: {
          _method: 'PUT'
        }
      }, (success) => {
        resolve(success.data)
      }, (err) => {
        err.response
      })
    })
  }
}

export default new OnboardingProvider()
