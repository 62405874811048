var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "a",
    {
      staticClass: "btn",
      class: _vm.props.data.classes,
      style: _vm.props.data.styles,
      attrs: { href: "#" },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.props.data.onClick($event)
        }
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }