<template>
  <transition name="slide">
    <div v-if="event !== null" id="availability-block">
      <div class="container center-align padding-x-xl">
        <h1 class="title strong margin-top-none" style="margin-top: 23px;">
          {{
            $translate('availability.availability_date_blocked_title', {
              meeting_start_at: event._meeting_start_at.format('MMMM DD')
            })
          }}
        </h1>
        <p>
          <small v-html="$translate('availability.availability_date_blocked_description', {
            meeting_start_at: event._meeting_start_at.format('MMMM DD')
          })" />
        </p>
        <img src="/img/events/availability.png" class="availability-img responsive-img" alt="" style="object-fit: contain; max-width: 100%; height: auto;">
        <div class="navigator row margin-top-lg">
          <button class="btn btn-full btn-large btn-krypton-pink waves-effect waves-light"
                  @click.prevent="$router.replace({ name: 'availability' })">
            {{ $translate('availability.availability_date_blocked_confirm_btn') }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      event: null
    }
  },
  mounted () {
    if (!this.$route.params.event) {
      this.$router.replace({ name: 'dashboard' })
    }

    this.event = this.$route.params.event
  }
}
</script>
