<template>
  <div
    class="input-field textarea-field"
    :class="{
      'active': forceActive,
      'has-placeholder': placeholder,
    }"
    @click="onClick"
  >
    <textarea
      ref="textarea"
      v-model="model"
      :class="textareaClass"
      :rows="rows"
      :cols="cols"
      :placeholder="placeholder"
      :tabindex="tabindex"
      :disabled="disabled"
      :maxlength="maxlength"
      @blur="onBlur"
      @focus="onFocus"
    />
    <label :class="{ 'active': isLabelActive }">{{ label }}</label>
    <slot />
  </div>
</template>

<script>
export default {
  props: [
    'type',
    'label',
    'rows',
    'cols',
    'value',
    'disabled',
    'tabindex',
    'maxlength',
    'placeholder',
    'forceActive'
  ],
  data () {
    return {
      model: null
    }
  },
  computed: {
    isLabelActive () {
      if ((this.model == null || !this.model.length) && this.placeholder == null) {
        return false
      }

      return true
    },
    textareaClass () {
      let mainClass
      if (this.type === null || this.type === 'textarea') {
        mainClass = 'materialize-textarea'
      } else {
        mainClass = 'materialize-multiline'
      }

      return [mainClass, {
        'has-value': this.model
      }]
    }
  },
  watch: {
    value (newval, oldval) {
      this.setModel(newval)
    },
    model (newval, oldval) {
      this.$emit('input', newval)
    },
    type (newval, oldval) {
      this.initMultiline()
    }
  },
  mounted () {
    this.setModel(this.value)
    this.initMultiline()
  },
  updated () {
    autosize.update(this.$refs.textarea)
  },
  methods: {
    onFocus () {
      this.$emit('focus')

      const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && screen.width < 600

      autosize(this.$refs.textarea)

      if (isMobile) {
        const footerButtons = document.querySelector('.footer-buttons')

        if (footerButtons) { footerButtons.style.display = 'none' }

        this.hideNavigator()
      }
    },
    onBlur () {
      this.$emit('blur')

      const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && screen.width < 600

      if (isMobile) {
        const footerButtons = document.querySelector('.footer-buttons')

        if (footerButtons) { footerButtons.style.display = 'initial' }

        this.showNavigator()
      }
    },
    onClick () {
      this.$emit('click')
    },
    initMultiline () {
      if (this.type != 'multiline') { return false }

      autosize(this.$refs.textarea)
    },
    setModel (val) {
      this.model = val
    },
    showNavigator () {
      const actions = document.getElementById('actions')

      if (actions && actions.style.display === 'none') {
        actions.style.display = 'flex'
      }
    },
    hideNavigator () {
      const actions = document.getElementById('actions')

      if (actions && actions.style.display !== 'none') {
        actions.style.display = 'none'
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.textarea-field {

  textarea.materialize-multiline {
    border-radius: 12px;
    border: 1px solid #CED6E0;
    font-family: 'Proxima Nova Semibold';
    padding: 24px 16px;
    box-sizing: border-box;
    width: 100%;
    min-height: 68px;
    overflow-y: hidden;

    &.has-value {
      border-color: #2F3740;
    }

    &:focus {
      border: 1px solid #2F3740 !important;
    }
  }

  label {
    color: #2F3740;
    font-family: 'Proxima Nova Semibold';
    font-size: 16px;
  }
  label.active {
    height: 16px;
    top: -8px !important;
    background: #FFFFFF;
    color: #52575B;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    font-family: 'Proxima Nova Medium';
  }
  .input-helper {
    font-family: 'Proxima Nova Medium';
    color: #52575B;
    font-size: 12px;
  }
}
</style>
