<template>
  <div
    id="content"
    class="container"
    data-test="multilocation__city-selection">
    <bottomsheet
      :is-shown="isShown"
      @close="closeBottomSheet"
    >
      <template v-slot:body>
        <h2 class="bottom-sheet__title" v-text="$t('citySelection.title')" />
        <div class="bottom-sheet__description">
          <h3 v-text="$t('citySelection.description.title')" />
          <p v-text="$t('citySelection.description.content')" />
        </div>
        <div class="cities">
          <multi-location-city-pill
            v-for="(city, key) in cities"
            :key="key"
            :city-name="city.name"
            :selected="city.selected"
            @select="selectCity(key)"
          />
        </div>
      </template>
      <template v-slot:footer>
        <register-stepper-v2 :total="2" :current="1" />
        <div :class="ctaContainerClass">
          <div
            class="select-count"
            data-test="selectCount"
            :data-value="selectCount"
            v-text="$t('citySelection.selectedCitiesCount', { count: selectCount })"
          />
          <button
            class="cta waves-effect"
            data-test="city-selection-cta"
            :disabled="noCitiesSelected"
            @click="submit"
            v-text="citySelectionCta"
          />
        </div>
      </template>
    </bottomsheet>
  </div>
</template>

<i18n src="@/features/multi-location/translations/settings.json"></i18n>

<script>
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'
import MultiLocationCityPill from '../common/MultiLocationCityPill.vue'
import bottomsheet from '@components/v2/utilities/bottomsheet.vue'
import registerStepperV2 from '@components/register/utilities/stepper-v2.vue'

export default {
  components: {
    MultiLocationCityPill,
    bottomsheet,
    registerStepperV2
  },
  props: {
    /**
     * If array is not empty it means user is coming from fee setup step
     */
    selectedCities: {
      type: Array,
      required: true
    },
    isShown: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data: () => ({
    hasLoaded: false,
    cities: []
  }),
  computed: {
    ...mapGetters({
      user: 'getUser',
      multiLocation: 'multiLocation/getMultiLocation'
    }),
    noCitiesSelected () {
      return this.selectCount === 0
    },
    hasUser () {
      return !isEmpty(this.user)
    },
    hasMultiLocation () {
      return this.multiLocation.length > 0
    },
    selectCount () {
      return this.cities.filter(i => i.selected === true).length
    },
    ctaContainerClass () {
      return 'cta-container'
    },
    citySelectionCta () {
      return this.$t('citySelection.cta')
    }
  },
  mounted () {
    this.mapCities()
  },
  methods: {
    closeBottomSheet () {
      this.$emit('close')
    },
    selectCity (key) {
      this.cities[key].selected = !this.cities[key].selected
    },
    mapCities () {
      this.cities = this.multiLocation.map(city => {
        const selectedCity = this.selectedCities.find(sc => sc.city_id === city.city_id)
        const isSelected = selectedCity !== undefined

        return {
          city_id: city.city_id,
          name: city._city_name,
          selected: isSelected,
          travel_cost: isSelected ? selectedCity.travel_cost : city.default_cost,
          min_cost: city.min_cost,
          max_cost: city.max_cost
        }
      })
    },
    submit () {
      const filteredCitiesOnlySelected = this.cities.filter(city => city.selected === true)

      if (filteredCitiesOnlySelected.length === 0) {
        return this.$notifications.toast(this.$t('errors.noCitySelected'))
      }

      this.$emit('submit', filteredCitiesOnlySelected)
    }
  }
}
</script>

<style lang="scss" scoped>
#content {
  margin-bottom: 80px;
  font-family: 'Proxima Nova';

  .bottom-sheet__title {
    margin-bottom: 32px;
  }

  .cities {
    text-align: left;
  }

  .cta-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    z-index: 1;

    .select-count {
      font-size: 16px;
      color: #2F3740;
      font-family: 'Proxima Nova Semibold';
    }
    button {
      flex-basis: 50%;
      height: 47px;
      background: #2F3740;
      border-radius: 10px;
      line-height: 44px;
      font-family: 'Proxima Nova Semibold';
      font-size: 16px;
      color: #FFFFFF;
      max-width: 128px;
      text-align: center;
      border: none;

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        background: #CED6E0 !important;
        color: #FFFFFF !important;
        cursor: not-allowed;
      }
    }
  }
}
</style>
