var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper", attrs: { id: "survey" } }, [
    _c("div", { staticClass: "content__area" }, [
      _c("img", {
        attrs: {
          id: "k-logo",
          src: "/img/registration/k-logo.svg",
          alt: "k-logo"
        }
      }),
      _vm._v(" "),
      _c("h2", { staticClass: "content__title content__title--desktop" }, [
        _vm._v(_vm._s(_vm.$translate("register.survey.title")))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content__area" }, [
      _c("div", { staticClass: "content" }, [
        _c("h2", { staticClass: "content__title content__title--mobile-new" }, [
          _vm._v(_vm._s(_vm.$translate("register.survey.title")))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "diamond-container" }, [
          _c("img", { attrs: { src: _vm.getIconBasedOnSelectedValue(1) } }),
          _vm._v(" "),
          _c("div", { staticClass: "center-diamond-container" }, [
            _c("img", { attrs: { src: _vm.getIconBasedOnSelectedValue(2) } }),
            _vm._v(" "),
            _c("div", { staticClass: "percentage-text" }, [
              _vm._v(_vm._s(_vm.getPercentage))
            ])
          ]),
          _vm._v(" "),
          _c("img", { attrs: { src: _vm.getIconBasedOnSelectedValue(3) } })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "survey-options-container" },
          _vm._l([1, 2, 3], function(item) {
            return _c(
              "div",
              {
                key: item,
                staticClass: "survey-option-item",
                class: {
                  selected: _vm.isItemSelected(item)
                },
                on: {
                  click: function($event) {
                    return _vm.selectSurveyOptionItem(item)
                  }
                }
              },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$translate(
                        "register.survey.options." + item + ".title"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "description" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$translate(
                        "register.survey.options." + item + ".desc"
                      )
                    )
                  )
                ])
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "actions flex-grid space-between" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-verify",
              attrs: { disabled: _vm.nextDisabled || _vm.isLoading },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.submit($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$translate("register.survey.cta")))]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }