var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "name" } },
    [
      [
        _c("div", { staticClass: "content__area" }, [
          _c("img", {
            attrs: {
              id: "k-logo",
              src: "/img/registration/k-logo.svg",
              alt: "k-logo"
            }
          }),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "content__stepper content__stepper--mobile" },
            [_vm._v(" " + _vm._s(_vm.stepperLabel))]
          ),
          _vm._v(" "),
          _c("h2", { staticClass: "content__title content__title--desktop" }, [
            _vm._v(_vm._s(_vm.$translate("register.personality.title.name")))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content__area drawer" }, [
          _c(
            "div",
            { staticClass: "content center-align" },
            [
              _c(
                "p",
                { staticClass: "content__stepper content__stepper--desktop" },
                [_vm._v(" " + _vm._s(_vm.stepperLabel))]
              ),
              _vm._v(" "),
              _c(
                "h2",
                { staticClass: "content__title content__title--mobile" },
                [
                  _vm._v(
                    _vm._s(_vm.$translate("register.personality.title.name"))
                  )
                ]
              ),
              _vm._v(" "),
              !_vm.payload.name
                ? _c(
                    "strong",
                    { staticClass: "name-placeholder margin-bottom-lg" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$translate(
                            "register.personality.name_placeholder"
                          )
                        )
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.payload.name
                ? _c("strong", { staticClass: "name margin-bottom-lg" }, [
                    _vm._v(
                      _vm._s(_vm.payload.name) +
                        ", " +
                        _vm._s(_vm.user.profiles.age)
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.errors.has("name")
                ? _c(
                    "div",
                    { staticClass: "input-helper red-text center-align" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.errors.first("name")) +
                          "\n        "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn generate-btn margin-bottom-5x",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.generateName($event)
                    }
                  }
                },
                [
                  !_vm.payload.name
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$translate(
                              "register.personality.create_name_btn"
                            )
                          )
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.payload.name
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$translate(
                              "register.personality.change_name_btn"
                            )
                          )
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "center-align" }, [
                _vm._v(
                  _vm._s(
                    _vm.$translate("register.personality.create_name_hint")
                  )
                )
              ]),
              _vm._v(" "),
              _c("register-navigator-v2", {
                attrs: {
                  "next-disabled": _vm.isCTADisabled,
                  total: _vm.total,
                  current: _vm.current
                },
                on: {
                  prev: function($event) {
                    $event.preventDefault()
                    return _vm.gotoPrev($event)
                  },
                  next: function($event) {
                    $event.preventDefault()
                    return _vm.submitForm($event)
                  }
                }
              })
            ],
            1
          )
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }