<template>
  <div
    id="multi-location-setting"
    class="wrapper"
  >
    <md-new-navbar route-name="setting" />
    <div id="content" data-test="multilocation__main" class="container">
      <h1 class="title">{{ $t('title') }}</h1>
      <div class="description">
        <span>{{ $t('shortFAQP1') }}</span>
        <a data-test="faqBtn" href="#" @click.prevent="$router.push({ name: 'faq.multi-location' })">{{ $t('shortFAQTxt') }}</a>
        <span>{{ $t('shortFAQP2') }}</span>
      </div>
      <div
        v-if="hasMultiLocation"
        data-test="feature-toggle"
        class="switch-input margin-bottom-xl"
      >
        <span>{{ $t('multiCity') }}</span>
        <div class="switch switch-solid">
          <label>
            <input
              id="switch"
              v-model="isEnabled"
              data-test="feature-toggle-checkbox"
              type="checkbox"
            >
            <span class="lever" />
          </label>
        </div>
      </div>
      <div v-if="selectedCities.length && multiLocationEnabled" class="selected-cities__title" v-text="$t('citiesAndFees')" />
      <div v-if="selectedCities.length && multiLocationEnabled" class="cities">
        <div v-for="(city, key) in selectedCities" :key="key" class="city-fee">
          <div :data-main-selected-city="city.name" class="city-fee__city" v-text="city.name" />
          <div class="city-fee__fee"><currency />{{ city.travel_cost }}</div>
        </div>
        <div class="cities-cta">
          <div class="cities-cta__cta-container">
            <a
              v-if="hasMultiLocation"
              class="cities-cta__cta"
              @click="editCitySelections"
              v-text="$t('cta.change')"
            />
          </div>
        </div>
      </div>
    </div>
    <multi-location-settings-cities-selection
      v-if="inCitiesSelectionStep"
      :selected-cities="selectedCities"
      :is-shown="inCitiesSelectionStep"
      @submit="proceedToFeeSetup"
      @close="closeBottomSheet"
    />
    <multi-location-settings-fees-setup
      v-else-if="inFeesSetupStep"
      :selected-cities="selectedCities"
      :is-shown="inFeesSetupStep"
      @cancel="proceedToCitySelection"
      @submit="enableFeatureNew"
      @close="closeBottomSheet"
    />
  </div>
</template>

<i18n src="@/features/multi-location/translations/settings.json"></i18n>

<script>
import { mapGetters } from 'vuex'

import MultiLocationService from '@/services/MultiLocationService'

import MultiLocationSettingsCitiesSelection from './MultiLocationSettingsCitiesSelection.vue'
import MultiLocationSettingsFeesSetup from './MultiLocationSettingsFeesSetup.vue'

const STEP_MAIN = 1
const STEP_CITIES_SELECTION = 2
const STEP_FEES_SETUP = 3

export default {
  components: {
    MultiLocationSettingsCitiesSelection,
    MultiLocationSettingsFeesSetup
  },
  data: () => ({
    /**
     * Sets which screen to show
     */
    step: STEP_MAIN,
    hasInitialized: false,
    isEnabledCopy: false,
    isEnabled: false,
    isEditingCitySelection: false,
    selectedCities: []
  }),
  computed: {
    ...mapGetters({
      user: 'getUser',
      multiLocationEnabled: 'getMultiLocationEnabled',
      multiLocation: 'multiLocation/getMultiLocation'
    }),
    hasMultiLocation () {
      return this.multiLocation.length > 0
    },
    inMainStep () {
      return this.step === STEP_MAIN
    },
    inCitiesSelectionStep () {
      return this.step === STEP_CITIES_SELECTION
    },
    inFeesSetupStep () {
      return this.step === STEP_FEES_SETUP
    }
  },
  watch: {
    isEnabled (newValue) {
      if (this.isEnabledCopy === this.isEnabled) {
        return false
      }

      if (newValue) {
        return this.proceedToCitySelection()
      }

      this.showDeactivationPopup()
    }
  },
  mounted () {
    this.isEnabledCopy = this.isEnabled = this.multiLocationEnabled
    this.mapSelectedCities()
  },
  methods: {
    editCitySelections () {
      this.isEditingCitySelection = true
      this.proceedToCitySelection()
    },
    reset () {
      this.$emit('close')
    },
    mapSelectedCities () {
      this.selectedCities = this.user._provider_cities.map(city => ({
        city_id: city.id,
        name: city._city_name,
        travel_cost: city.travel_cost
      }))
    },
    enableFeature () {
      this.step = STEP_CITIES_SELECTION
      this.isEnabledCopy = true
    },
    enableFeatureNew () {
      this.isEnabledCopy = true
      this.isEnabled = true
      this.proceedToMain()
    },
    async disableFeature () {
      try {
        await MultiLocationService.disable()
        this.$store.commit('setMultiLocationEnabled', false)
        this.isEnabledCopy = false
      } catch (err) {
        console.error(err)
      }
    },
    toggleFeature () {
      this.isEnabled = !this.isEnabled
    },
    showActivationPopup () {
      this.showPopup(
        this.$t('activatePopup.title'),
        this.$t('activatePopup.description'),
        this.$t('activatePopup.cta.confirm'),
        this.$t('activatePopup.cta.cancel'),
        this.enableFeature,
        this.toggleFeature
      )
    },
    showDeactivationPopup () {
      const mTitle = this.$t('deactivatePopup.title')
      const mContent = this.$t('deactivatePopup.description')
      const confirmText = this.$t('deactivatePopup.cta.confirm')
      const mCancelText = this.$t('deactivatePopup.cta.cancel')

      $h.invertedCustomModalConfirm({
        title: mTitle,
        content: mContent,
        cancelText: mCancelText,
        confirmText: confirmText
      }, this.disableFeature,
      this.toggleFeature)
    },
    showPopup (title, description, ctaConfirm, ctaCancel, onConfirm, onDecline) {
      $h.newCustomModalConfirm({
        title: title,
        content: description,
        cancelText: ctaCancel,
        confirmText: ctaConfirm
      }, onConfirm, onDecline)
    },
    proceedToCitySelection () {
      this.step = STEP_CITIES_SELECTION
    },
    proceedToFeeSetup (cities) {
      this.selectedCities = cities
      this.step = STEP_FEES_SETUP
    },
    closeBottomSheet () {
      this.step = STEP_MAIN
      if (!this.isEditingCitySelection) {
        this.toggleFeature()
        this.isEditingCitySelection = false
      }
    },
    proceedToMain () {
      this.step = STEP_MAIN
    }
  }
}
</script>

<style lang="scss" scoped>
#multi-location-setting {
  background-color: white;

  > .container#content {
    background-color: white;
    padding: 0 24px 24px;
  }

  h1.title {
    font-size: 26px;
    color: #2F3740;
    margin: 5px 0 32px;
  }

  .description {
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    color: #2F3740;
    margin: 0;
    margin-bottom: 32px;

    a {
      color: #E30074;
      text-decoration: underline;
    }
  }

  .switch-input {
    display: flex;
    align-items: center;

    & > span {
      flex: 1;
      text-align: left;
      font-size: 20px;
      color: #2F3740;
      font-family: 'Proxima Nova Semibold';
    }
  }

  .selected-cities__title {
    font-family: 'Proxima Nova Semibold';
    font-size: 18px;
    color: #2F3740;
    margin-bottom: 12px;
    text-align: left;
  }
  .cities {
    margin-bottom: 24px;
    padding: 24px 16px;
    border-radius: 12px;
    border: 1px solid #CED6E0;

    .city-fee {
      margin-bottom: 30px;
    }
    .city-fee:last-child {
      margin-bottom: 0;
    }

    .city-fee,
    .cities-cta {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .city-fee .city-fee__city {
      color: #2F3740;
      font-size: 18px;
      font-family: 'Proxima Nova Medium';
    }

    .city-fee .city-fee__fee,
    .cities-cta .cities-cta__selected-count {
      color: #2F3740;
      font-size: 18px;
      font-family: 'Proxima Nova Medium';
    }

    .cities-cta {
      margin-top: 32px;
      justify-content: end;
    }
    .cities-cta a.cities-cta__cta {
      color: #E30074;
      text-decoration: underline;
      font-size: 16px;
      font-family: 'Proxima Nova Semibold';
    }
    .cities-cta a.cities-cta__cta:hover {
      cursor: pointer;
    }
  }
}
</style>
