<template>
  <div id="photos">
    <div id="anonimize" class="wrapper wrapper_fixed">
      <div class="content__area overlay">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <h2 class="content__title content__title--desktop">{{ $translate('photos.anonimize') }}</h2>
      </div>
      <div class="content__area drawer --with-photo-content --with-both-bars-crop no-y-padding">
        <div class="content">
          <div class="top-bar--drawer" style="border-bottom: none; justify-content: space-between;">
            <a href="#" @click.prevent="triggerCancelUpload">
              <i class="material-icons" style="margin-right: 8px;">close</i>
              <span class="cancel-upload-label">{{ $translate('photos.cancel_upload') }}</span>
            </a>
          </div>

          <div id="content" class="hide">
            <vform ref="enterOverlayForm" @submit="enterOverlayMode" />
            <vform ref="anonimizeForm" @submit="anonimizePhoto" />
            <vform ref="publishOverlayForm" @submit="publishPhoto" />
          </div>

          <div class="anonymize-container" :class="{'--loading': imageLoading}">
            <img v-if="!imageLoading && imgSrc" ref="anonymize_image" :src="imgSrc">
          </div>

          <div class="photo-text" style="cursor: pointer">
            <strong
              class="slider-label"
              :class="{'disabled-slider': !isSliderActive}"
              @click.prevent="enableSlider"
            >{{ $translate('photos.anonimize') }}</strong>
            <div v-show="isSliderActive" id="overlaySliderContainer" class="padding-md">
              <div id="overlaySlider" />
            </div>
          </div>

          <register-navigator-v2
            v-if="hasOverlay"
            :no-prev="true"
            :next="!isSending ? $translate('photos.publish') : 'Saving...'"
            @next.prevent="$refs.anonimizeForm.submit"
          />

          <register-navigator-v2
            v-if="!hasOverlay"
            :no-prev="true"
            :next="!isSending ? $translate('photos.publish') : 'Saving...'"
            @next.prevent="$refs.publishOverlayForm.submit"
          />
        </div>
      </div>
      <md-screen-preloader v-if="isSending || imageLoading" />
    </div>
  </div>
</template>

<script>
import AnonimizeController from '@components/account/photos/anonimize.controller'

export default {
  mixins: [AnonimizeController]
}
</script>
