<template>
  <div class="faq-navbar-wrapper" :class="{'--with-top-status-indicator': hasTopStatusIndicator}">
    <div class="faq-navbar-container" :class="{'white': hasScrolledFromTop}">
      <a class="back-btn" href="#" @click.prevent="redirect">
        <img v-if="!hasScrolledFromTop" class="responsive-img" src="/img/white-back-arrow.svg" alt="back-btn">
        <img v-else class="responsive-img" src="/img/back-arrow.svg" alt="back-btn">
      </a>
    </div>
    <div class="faq-navbar__placeholder" />
  </div>
</template>
<script>
export default {
  props: {
    routeName: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      watchEl: null,
      observer: null,
      hasScrolledFromTop: false
    }
  },
  computed: {
    hasTopStatusIndicator () {
      return this.$store.getters.getTopStatusIndicator
    }
  },
  mounted () {
    this.watchEl = document.getElementsByTagName('h1')[0]

    this.observer = new IntersectionObserver(this.observeCallback, {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0
    })

    this.observer.observe(this.watchEl)
  },
  destroyed () {
    this.observer.unobserve(this.watchEl)
  },
  methods: {
    redirect () {
      if (this.routeName) {
        this.$router.push({ name: this.routeName })
      } else {
        this.$router.push({ name: 'menu' })
      }
    },
    observeCallback (sections, observer) {
      sections.forEach((section) => {
        if (!section.isIntersecting) {
          this.hasScrolledFromTop = true
        } else {
          this.hasScrolledFromTop = false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.faq-navbar-container {
    height: 48px;
    margin: 0 auto;
    max-width: 600px;
    padding: 0 12px;
    text-align: left;

    .back-btn {
        height: 48px;
        width: 48px;
    }
}

.faq-navbar-wrapper {
  position: relative;
  display: block;

  .faq-navbar-container {
    box-shadow: none;
    display: block;
    height: 48px;
    margin: 0 auto;
    width: 100%;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    max-width: 600px;
    width: 100%;

    @media screen and (min-width: 600px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  // necessary as the navbar is fixed now, so that contents
  // would be pushed to their original position
  .faq-navbar__placeholder {
    height: 48px;
    background: transparent;
  }

  &.--with-top-status-indicator {
    .new-navbar {
      top: 61px;
    }
  }
}
</style>
