var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "super-provider-card super-provider-card__req margin-bottom-md",
      class: _vm.cardStatusClass,
      attrs: { id: "unpaid-invoices" }
    },
    [
      _c("h2", {
        staticClass: "super-provider-card__title margin-bottom-lg",
        attrs: { "data-test": "title" },
        domProps: { textContent: _vm._s(_vm.title) }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "super-provider-card__box-content invoices" }, [
        _c(
          "div",
          {
            staticClass: "invoice__status",
            attrs: { "data-test": "invoice_section" }
          },
          [
            _c(
              "i",
              { staticClass: "material-icons icon-status margin-right-xs" },
              [_vm._v("description")]
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.onTrack && !_vm.hasUnpaidInvoiceWithAgeLessThanThreshold
                  ? _c("span", { attrs: { "invoice__no-invoice": "" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("statusChecks.unpaidInvoices.noInvoices"))
                      )
                    ])
                  : _vm.hasUnpaidInvoiceWithAgeLessThanThreshold
                  ? [
                      _c("strong", { staticClass: "invoice__title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("statusChecks.unpaidInvoices.oldestInvoice")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass: "invoice__number",
                          attrs: { "data-test": "invoice_number" }
                        },
                        [_vm._v("INV " + _vm._s(_vm.invoiceNumber))]
                      )
                    ]
                  : _vm.hasUnpaidInvoiceWithAgeMoreThanThreshold
                  ? [
                      _c("strong", { staticClass: "invoice__title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("statusChecks.unpaidInvoices.oldestInvoice")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass: "invoice__number",
                          attrs: { "data-test": "invoice_number" }
                        },
                        [_vm._v("INV " + _vm._s(_vm.invoiceNumber))]
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ]
        ),
        _vm._v(" "),
        _c("div", [
          _c(
            "button",
            {
              staticClass: "btn-status btn-status--warning",
              attrs: { type: "button", "data-test": "btn_status" }
            },
            [
              _vm._v(_vm._s(_vm.btnStatusText) + " "),
              _vm.hasUnpaidInvoiceWithAgeLessThanThreshold
                ? _c("span", { attrs: { "data-test": "overdue_in" } }, [
                    _vm._v(_vm._s(_vm.overdueIn) + "d")
                  ])
                : _vm._e()
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "super-provider-card__box-content",
        attrs: { "data-test": "explanation_text" },
        domProps: {
          innerHTML: _vm._s(_vm.$t("statusChecks.unpaidInvoices.boxContent"))
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "super-provider-card__tooltip-trigger margin-top-md",
          attrs: { "data-test": "tooltip" },
          on: { click: _vm.showTooltip }
        },
        [
          _c("span", {
            staticClass: "super-provider-card__tooltip-trigger-text",
            domProps: { textContent: _vm._s(_vm.tooltipText) }
          }),
          _vm._v(" "),
          _c("i", { staticClass: "material-icons" }, [_vm._v("help_outline")])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "super-provider-card__req-status",
          attrs: { "data-test": "status" }
        },
        [
          _c("span", { domProps: { textContent: _vm._s(_vm.cardStatusText) } }),
          _vm._v(" "),
          _c("i", { staticClass: "material-icons" }, [
            _vm._v(_vm._s(_vm.cardStatusIcon))
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }