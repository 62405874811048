<template>
  <div id="provider-performance" class="page">
    <md-navbar
      v-if="fromDashboard"
      :push="{ name: 'dashboard' }"
      :brand="$translate('sidenav.dashboard')"
      icon="arrow_back"
    />
    <md-new-navbar v-else />
    <div id="content">
      <div class="container">
        <lesson-shortcut v-model="LESSONS.FEEDBACK" translation-key="feedback" />
        <h5 class="margin-bottom-lg margin-top-none">
          {{ $translate('provider_performance.performance_page.title') }}
        </h5>
        <performance-card
          class="total-rating-chart"
          :icon="RATING_ICON.STAR"
          :rating="ratings.totalRating"
          :ranking="rankings.totalRating">
          <template v-slot:header>
            <strong v-if="isFetchingData">{{ $translate('app.fetching_data') }}</strong>
            <strong v-else>{{ $translate('provider_performance.performance_page.total_rating.title') }}</strong>
          </template>
          <template>
            <div v-if="hasRating">
              <div v-if="ratings.rank === RANKING.TOP_10" class="rank-comment">
                <img class="valign-middle" src="/img/performance-overview/medal_enabled.svg" width="48">
                <p>{{ $translate('provider_performance.performance_page.total_rating.top_10') }}</p>
              </div>
              <div v-else-if="ratings.rank === RANKING.TOP_30" class="rank-comment">
                <p>{{ $translate('provider_performance.performance_page.total_rating.top_30') }}</p>
              </div>
              <div v-else class="rank-comment">
                <p>{{ $translate('provider_performance.performance_page.total_rating.below_top_30_pt_1') }} <a href="#" @click.prevent="$router.replace({ name: 'faq' })">FAQ</a> {{ $translate('provider_performance.performance_page.total_rating.below_top_30_pt_2') }}</p>
              </div>
            </div>
            <div v-else>
              <button class="btn total-rating-faq-btn btn-secondary display-block waves-effect waves-dark" @click="$router.push({ name: 'total-rating-faq' })">
                <i class="mdi mdi-information valign-middle" /> <span>{{ $translate('provider_performance.performance_page.total_rating.cta_button') }}</span>
              </button>
            </div>
          </template>
        </performance-card>
        <performance-card
          class="reliability-rating-chart"
          :rating="ratings.reliability"
          :ranking="rankings.reliability">
          <template v-slot:header>
            <strong v-if="isFetchingData">{{ $translate('app.fetching_data') }}</strong>
            <strong v-else>{{ $translate('provider_performance.performance_page.reliability.title') }}</strong>
          </template>
          <template>
            <button class="btn btn-secondary display-block waves-effect waves-dark" @click="$router.push({ name: 'reliability-faq' })">
              <i class="mdi mdi-information valign-middle" /> <span>{{ $translate('provider_performance.performance_page.reliability.cta_button') }}</span>
            </button>
          </template>
        </performance-card>
        <performance-card
          class="client-rating-chart"
          :rating="ratings.client"
          :ranking="rankings.client">
          <template v-slot:header>
            <strong v-if="isFetchingData">{{ $translate('app.fetching_data') }}</strong>
            <strong v-else>{{ $translate('provider_performance.performance_page.client.title') }}</strong>
          </template>
          <template>
            <button v-if="hasClientRating"
                    class="btn reviews-btn btn-secondary display-block margin-bottom-md waves-effect waves-dark"
                    @click="gotoPage('inbox', { filter: INBOX_FILTER.REVIEWS })">
              <span :class="{ 'lilac lilac--red': hasFeedbackUpdates }">{{ $translate('provider_performance.performance_page.client.review_cta_button', { count: totalFeedback }) }}</span>
            </button>
            <button class="btn client-faq-btn btn-secondary display-block waves-effect waves-dark" @click="$router.push({ name: 'client-faq' })">
              <i class="mdi mdi-information valign-middle" /> <span>{{ $translate('provider_performance.performance_page.client.cta_button') }}</span>
            </button>
          </template>
        </performance-card>
      </div>
    </div>
    <video-shortcut v-model="LESSONS.FEEDBACK" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { LITE_EVENTS } from '@/features/lite/constants'
import {
  fetchRatingSummary,
  fetchOverallRating
} from '@/features/provider-performance/utils/RatingAPI'
import PerformanceRankingModel from '@/features/provider-performance/models/PerformanceRanking'
import {
  RANKING,
  RATING_ICON
} from '@/features/provider-performance/constants'

import LessonShortcut from '@/features/onboarding-lesson/components/LessonShortcut.vue'
import VideoShortcut from '@/features/onboarding-lesson/components/VideoShortcut.vue'
import { LESSONS } from '@/features/onboarding-lesson/constants'
import { INBOX_FILTER } from '@/features/inbox/constants'

export default {
  components: {
    LessonShortcut,
    VideoShortcut
  },
  data: () => ({
    RANKING,
    RATING_ICON,
    ratings: {
      totalRating: 0,
      reliability: 0,
      client: 0,
      rank: null
    },
    rankings: {
      totalRating: new PerformanceRankingModel(),
      reliability: new PerformanceRankingModel(),
      client: new PerformanceRankingModel()
    },
    isFetchingData: false,
    LESSONS,
    INBOX_FILTER: INBOX_FILTER,
    fromDashboard: false
  }),
  computed: {
    ...mapGetters({
      hasFeedbackUpdates: 'feedbacks/hasUpdates',
      totalFeedback: 'feedbacks/GET_TOTAL_FEEDBACK',
      isCandidateProfile: 'isCandidateProfile'
    }),
    hasRating () {
      return this.ratings.totalRating > 0
    },
    hasClientRating () {
      return this.ratings.client > 0
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.name === 'dashboard') {
        // eslint-disable-next-line no-param-reassign
        vm.fromDashboard = true
      }
    })
  },
  watch: {
    isCandidateProfile () {
      if (this.isCandidateProfile) {
        this.$router.replace({ name: 'dashboard' })
        $h.EventBus.$emit(LITE_EVENTS.ONFEATURELOCK)
      }
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    gotoPage (name, params = {}) {
      this.$router.push({ name, params })
    },
    setData ([rankings, ratings]) {
      this.ratings = { ...ratings }
      this.rankings = { ...rankings }
    },
    fetchData () {
      this.$store.dispatch('notification/SEEN_FEEDBACK_NOTIFICATION')

      const requests = [
        fetchRatingSummary(),
        fetchOverallRating()
      ]

      this.isFetchingData = true

      Promise
        .all(requests)
        .then(this.setData)
        .finally(() => {
          this.isFetchingData = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./ProviderPerformancePage.style";
</style>
