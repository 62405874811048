const newModalConfirm = (title, content, callback) => {
  // Create new instance of the component
  var modal = Vue.component('md-modal-confirm')
  var exModal = Vue.extend(modal)
  var newModal = new exModal({
    propsData: {
      title: title,
      content: content
    }
  })

  newModal.$mount()

  var flyModal = $(newModal.$el).appendTo('#app')

  flyModal.modal({
    dismissible: false,
    complete: () => {
      flyModal.remove()
    }
  })

  newModal.$on('confirm', ($e) => {
    if (callback) {
      callback($e)
    }
    flyModal.modal('close')
  })

  flyModal.modal('open')

  return newModal
}

const pendingEventModal = (propsData) => {
  // Create new instance of the component
  let modal
  modal = Vue.component('md-pending-event')
  const exModal = Vue.extend(modal)
  const newModal = new exModal({ propsData })

  newModal.$mount()

  const flyModal = $(newModal.$el).appendTo('#app')

  flyModal.modal({
    dismissible: false,
    complete: () => {
      flyModal.remove()
    }
  })

  newModal.$on('confirm', ($e) => {
    flyModal.modal('close')
  })

  flyModal.modal('open')

  return newModal
}

const closedEventModal = (propsData, callback) => {
  // Create new instance of the component
  const modal = Vue.component('md-closed-event')
  const exModal = Vue.extend(modal)
  const newModal = new exModal({ propsData })

  newModal.$mount()

  const flyModal = $(newModal.$el).appendTo('#app')

  flyModal.modal({
    dismissible: false,
    complete: () => {
      flyModal.remove()
    }
  })

  newModal.$on('confirm', ($e) => {
    if (callback) {
      callback($e)
    }
    flyModal.modal('close')
  })

  flyModal.modal('open')

  return newModal
}

const clientLateModal = (propsData, callback) => {
  // Create new instance of the component
  const modal = Vue.component('md-client-late')
  const exModal = Vue.extend(modal)
  const newModal = new exModal({ propsData })

  newModal.$mount()

  const flyModal = $(newModal.$el).appendTo('#app')

  flyModal.modal({
    dismissible: false,
    complete: () => {
      flyModal.remove()
    }
  })

  newModal.$on('confirm', ($e) => {
    if (callback) {
      callback($e)
    }
    flyModal.modal('close')
  })

  flyModal.modal('open')

  return newModal
}

const newCustomModalConfirm = (propsData, callback, cancelCallback) => {
  // Create new instance of the component
  const modal = Vue.component('md-modal-confirm')
  const exModal = Vue.extend(modal)
  const newModal = new exModal({ propsData })

  newModal.$mount()

  const flyModal = $(newModal.$el).appendTo('#app')

  flyModal.modal({
    dismissible: false,
    complete: () => {
      flyModal.remove()
    }
  })

  newModal.$on('confirm', ($e) => {
    if (callback) {
      callback($e)
    }
    flyModal.modal('close')
  })

  newModal.$on('cancel', ($e) => {
    if (cancelCallback) {
      cancelCallback()
    }
    flyModal.modal('close')
  })

  flyModal.modal('open')

  return newModal
}

const invertedCustomModalConfirm = (propsData, callback = undefined, cancelCallback = undefined) => {
  // Create new instance of the component
  const modal = Vue.component('md-inverted-modal-confirm')
  const exModal = Vue.extend(modal)
  const newModal = new exModal({ propsData })

  newModal.$mount()

  const flyModal = $(newModal.$el).appendTo('#app')

  flyModal.modal({
    dismissible: false,
    complete: () => {
      flyModal.remove()
    }
  })

  newModal.$on('confirm', ($e) => {
    if (callback && typeof callback === 'function') {
      callback($e)
    }
    flyModal.modal('close')
  })

  newModal.$on('cancel', ($e) => {
    if (cancelCallback && typeof cancelCallback === 'function') {
      cancelCallback()
    }
    flyModal.modal('close')
  })

  flyModal.modal('open')

  return newModal
}

const rejectedPhotosModal = (rejectedPhotos, callback) => {
  // Create new instance of the component
  var modal = Vue.component('photos-gallery-v2-rejected-photos')
  var exModal = Vue.extend(modal)
  var newModal = new exModal({
    propsData: {
      rejectedPhotos: rejectedPhotos
    }
  })

  newModal.$mount()

  var flyModal = $(newModal.$el).appendTo('#app')

  flyModal.modal({
    dismissible: false,
    complete: () => {
      flyModal.remove()
    }
  })

  newModal.$on('confirm', ($e) => {
    if (callback) {
      callback($e)
    }
    flyModal.modal('close')
  })

  flyModal.modal('open')

  return newModal
}

const replacePhotoModal = (callback) => {
  // Create new instance of the component
  var modal = Vue.component('md-photo-replace-modal')
  var exModal = Vue.extend(modal)
  var newModal = new exModal()

  newModal.$mount()

  var flyModal = $(newModal.$el).appendTo('#app')

  flyModal.modal({
    dismissible: false,
    complete: () => {
      flyModal.remove()
    }
  })

  newModal.$on('confirm', ($e) => {
    if (callback) {
      callback($e)
    }
    flyModal.modal('close')
  })

  flyModal.modal('open')
}

const photoErrorModal = (error) => {
  // Create new instance of the component
  var modal = Vue.component('md-photo-error-modal')
  var exModal = Vue.extend(modal)
  var newModal = new exModal({
    propsData: { error }
  })

  newModal.$mount()

  var flyModal = $(newModal.$el).appendTo('#app')

  flyModal.modal({
    dismissible: false,
    complete: () => {
      flyModal.remove()
    }
  })

  flyModal.modal('open')
}

const rateLeaveConfirm = (callback) => {
  // Create new instance of the component
  var modal = Vue.component('md-modal-rate-leave')
  var exModal = Vue.extend(modal)
  var newModal = new exModal()

  newModal.$mount()

  var flyModal = $(newModal.$el).appendTo('#app')

  flyModal.modal({
    dismissible: false,
    complete: () => {
      flyModal.remove()
    }
  })

  newModal.$on('confirm', ($e) => {
    if (callback) {
      callback($e)
    }
    flyModal.modal('close')
  })

  flyModal.modal('open')

  return newModal
}

const confirmOnlyModal = (content, title = '', callback) => {
  // Create new instance of the component
  var modal = Vue.component('md-confirm-only')
  var exModal = Vue.extend(modal)
  var newModal = new exModal({
    propsData: {
      title: title,
      content: content
    }
  })

  newModal.$mount()

  var flyModal = $(newModal.$el).appendTo('#app')

  flyModal.modal({
    dismissible: false,
    complete: () => {
      flyModal.remove()
    }
  })

  newModal.$on('confirm', ($e) => {
    if (callback) {
      callback($e)
    }
    flyModal.modal('close')
  })

  flyModal.modal('open')

  return newModal
}

const verifyIDSuccessModal = (callback) => {
  // Create new instance of the component
  const modal = Vue.component('md-verify-id-success')
  const exModal = Vue.extend(modal)
  const newModal = new exModal()

  newModal.$mount()

  const flyModal = $(newModal.$el).appendTo('#app')

  flyModal.modal({
    dismissible: false,
    complete: () => {
      flyModal.remove()
    }
  })

  newModal.$on('confirm', ($e) => {
    if (callback) {
      callback($e)
    }
    flyModal.modal('close')
  })

  flyModal.modal('open')

  return newModal
}

const verifyIDRejectedModal = (payload, callback) => {
  // Create new instance of the component
  const modal = Vue.component('md-verify-id-rejected')
  const exModal = Vue.extend(modal)
  const newModal = new exModal({
    propsData: {
      ...payload
    }
  })

  newModal.$mount()

  const flyModal = $(newModal.$el).appendTo('#app')

  flyModal.modal({
    dismissible: false,
    complete: () => {
      flyModal.remove()
    }
  })

  newModal.$on('confirm', ($e) => {
    if (callback) {
      callback($e)
    }
    flyModal.modal('close')
  })

  flyModal.modal('open')

  return newModal
}

const reactivationSuccesModal = (clientName, callback) => {
  // Create new instance of the component
  var modal = Vue.component('md-reactivation-success-event')
  var exModal = Vue.extend(modal)
  var newModal = new exModal({
    propsData: {
      clientName
    }
  })

  newModal.$mount()

  const flyModal = $(newModal.$el).appendTo('#app')

  flyModal.modal({
    dismissible: false,
    complete: () => {
      flyModal.remove()
    }
  })

  newModal.$on('confirm', ($e) => {
    if (callback) {
      callback($e)
    }
    flyModal.modal('close')
  })

  flyModal.modal('open')

  return newModal
}

const billingRequiredModal = (callback) => {
  // Create new instance of the component
  const modal = Vue.component('md-billing-required')
  const exModal = Vue.extend(modal)
  const newModal = new exModal()

  newModal.$mount()

  const flyModal = $(newModal.$el).appendTo('#app')

  flyModal.modal({
    dismissible: false,
    complete: () => {
      flyModal.remove()
    }
  })

  newModal.$on('confirm', ($e) => {
    if (callback) {
      callback($e)
    }
    flyModal.modal('close')
  })

  flyModal.modal('open')

  return newModal
}

const videoDateInstruction = (callback) => {
  // Create new instance of the component
  const modal = Vue.component('video-date-instruction')
  const exModal = Vue.extend(modal)
  const newModal = new exModal()

  newModal.$mount()

  const flyModal = $(newModal.$el).appendTo('#app')

  flyModal.modal({
    dismissible: false,
    complete: () => {
      flyModal.remove()
    }
  })

  newModal.$on('confirm', ($e) => {
    if (callback) {
      callback($e)
    }
    flyModal.modal('close')
  })

  flyModal.modal('open')

  return newModal
}

export const CLOSED_EVENT_MODAL_TYPE = {
  TIMEOUT: 'md-modal-closed-event-timeout',
  SUCCESS: 'md-modal-closed-event-success',
  ABORT: 'md-modal-closed-event-abort'
}

const closedEventTimeoutModal = (evt, type = $h.CLOSED_EVENT_MODAL_TYPE.TIMEOUT, callback) => {
  // Create new instance of the component
  const modal = Vue.component(type)
  const exModal = Vue.extend(modal)
  const newModal = new exModal({
    propsData: { evt }
  })

  newModal.$mount()

  const flyModal = $(newModal.$el).appendTo('#app')

  flyModal.modal({
    dismissible: false,
    complete: () => {
      flyModal.remove()
    }
  })

  newModal.$on('confirm', ($e) => {
    if (callback) {
      callback($e)
    }
    flyModal.modal('close')
  })

  flyModal.modal('open')

  return newModal
}

const blockClientModal = (propsData, callback) => {
  // Create new instance of the component
  const modal = Vue.component('block-client-modal')
  const ExModal = Vue.extend(modal)
  const newModal = new ExModal({ propsData })

  newModal.$mount()

  const flyModal = $(newModal.$el).appendTo('#app')

  flyModal.modal({
    dismissible: false,
    complete: () => {
      flyModal.remove()
    }
  })

  newModal.$on('confirm', ($e) => {
    if (callback) {
      callback($e)
    }
    flyModal.modal('close')
  })

  flyModal.modal('open')

  return newModal
}

const blockClientSuccessModal = (callback, supportLinkCallback) => {
  // Create new instance of the component
  const modal = Vue.component('block-client-success-modal')
  const ExModal = Vue.extend(modal)
  const newModal = new ExModal()

  newModal.$mount()

  const flyModal = $(newModal.$el).appendTo('#app')

  flyModal.modal({
    dismissible: false,
    complete: () => {
      flyModal.remove()
    }
  })

  newModal.$on('confirm', ($e) => {
    if (callback) {
      callback($e)
    }
    flyModal.modal('close')
  })

  newModal.$on('onsupport', () => {
    if (supportLinkCallback) {
      supportLinkCallback()
    }
    flyModal.modal('close')
  })

  flyModal.modal('open')

  return newModal
}

// export
export default {
  newModalConfirm,
  pendingEventModal,
  closedEventModal,
  clientLateModal,
  newCustomModalConfirm,
  invertedCustomModalConfirm,
  rejectedPhotosModal,
  replacePhotoModal,
  photoErrorModal,
  rateLeaveConfirm,
  confirmOnlyModal,
  verifyIDSuccessModal,
  verifyIDRejectedModal,
  reactivationSuccesModal,
  billingRequiredModal,
  CLOSED_EVENT_MODAL_TYPE,
  closedEventTimeoutModal,
  videoDateInstruction,
  blockClientModal,
  blockClientSuccessModal
}
