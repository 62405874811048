var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "btn display-block waves-effect",
      attrs: { "data-test": "affiliate-share-btn", disabled: _vm.loading },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.share($event)
        }
      }
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.$t("cta")))]),
      !_vm.isNavigatorShareApiSupported
        ? _c(
            "i",
            {
              staticClass: "material-icons",
              attrs: { "data-test": "affiliate-copy-icon" }
            },
            [_vm._v("content_copy")]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }