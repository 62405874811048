<script>
import LocalizationService, { ENGLISH_LOCALE } from '@/services/LocalizationService'
import { updateUserLanguage } from '@/services/UserService.js'

export default {
  computed: {
    isLocale () {
      return (locale) => this.$i18n?.locale === locale
    },
    linkToDE () {
      return this.$router.resolve({
        name: this.$router.currentRoute.name,
        query: {
          ...this.$router.currentRoute.query
        }
      }).href
    },
    linkToEN () {
      return this.$router.resolve({
        name: this.$router.currentRoute.name,
        params: {
          lang: ENGLISH_LOCALE
        },
        query: {
          ...this.$router.currentRoute.query
        }
      }).href
    }
  },
  methods: {
    async setLocale (val) {
      await LocalizationService.saveLocale(val)
    },
    async setLocaleApiRequest (val) {
      await updateUserLanguage({ app_lang: val })
      await LocalizationService.saveLocale(val)
      window.location.href = val === 'en' ? this.linkToEN : this.linkToDE
    }
  },
  render () {
    return this.$scopedSlots.default({
      data: {
        isLocale: this.isLocale,
        linkToDE: this.linkToDE,
        linkToEN: this.linkToEN
      },
      bindings: {
        setLocale: this.setLocale,
        setLocaleApiRequest: this.setLocaleApiRequest
      }
    })
  }
}
</script>
