export const ticketsFixture = [
  {
    id: 1,
    ticket_number: 'ID91243151',
    title: 'Meetings, Emily + Roger, Tue 19 Aug, 18:00',
    created_at: '2018-12-20\ 06:40:00',
    messageCount: 3,
    category_id: 1,
    is_seen: 0,
    is_closed: 0,
  },
  {
    id: 2,
    ticket_number: 'ID931',
    title: 'Invoices, Invoice #654789',
    created_at: '2018-12-20\ 06:40:00',
    messageCount: 1,
    category_id: 2,
    is_seen: 1,
    is_closed: 0,
  },
  {
    id: 3,
    ticket_number: 'ID912431',
    title: 'Meetings, Eve + Test, Tue 19 Aug, 18:00',
    created_at: '2018-12-20\ 06:40:00',
    messageCount: 5,
    category_id: 3,
    is_seen: 1,
    is_closed: 1,
  },
  {
    id: 4,
    ticket_number: 'ID987456',
    title: 'Krypton App, Something is not working',
    created_at: '2018-12-20\ 06:40:00',
    messageCount: 5,
    category_id: 4,
    is_seen: 1,
    is_closed: 1,
  },
  {
    id: 5,
    ticket_number: 'ID6756',
    title: 'Krypton App, Something is not working',
    created_at: '2018-12-20\ 06:40:00',
    messageCount: 3,
    category_id: 5,
    is_seen: 0,
    is_closed: 0,
  },
];

export const ticketsChat = [
    {
        ticket_id: 1,
        chat: [
            {
                id: 1,
                message: 'Sure! Here are some other girls you might like and meet without changing the date and time you requested.',
                sent_by: 99,
                seen: null,
                group_id: 99,
                created_at: '2018-12-20\ 06:40:00',
                _message_sent_at_in_seconds: 100,
            },
            {
                id: 2,
                message: 'Sure! Here are some other girls you might like and meet without changing the date and time you requested.',
                sent_by: 1,
                seen: null,
                group_id: 2,
                created_at: '2018-12-20\ 06:40:00',
                _message_sent_at_in_seconds: 100,
            },
            {
                id: 3,
                message: 'This ticket has been inactive for a while so the status of your support request was changed to: Closed.',
                sent_by: 99,
                seen: null,
                group_id: 99,
                created_at: '2018-12-20\ 06:40:00',
                _message_sent_at_in_seconds: 100,
            },
        ],
    },
    {
        ticket_id: 2,
        chat: [
            {
                id: 1,
                message: 'Sure! Here are some other girls you might like and meet without changing the date and time you requested.',
                sent_by: 99,
                seen: null,
                group_id: 99,
                created_at: '2018-12-20\ 06:40:00',
                _message_sent_at_in_seconds: 100,
            },
            {
                id: 2,
                message: 'Sure! Here are some other girls you might like and meet without changing the date and time you requested.',
                sent_by: 1,
                seen: null,
                group_id: 2,
                created_at: '2018-12-20\ 06:40:00',
                _message_sent_at_in_seconds: 100,
            },
            {
                id: 3,
                message: 'This ticket has been inactive for a while so the status of your support request was changed to: Closed.',
                sent_by: 99,
                seen: null,
                group_id: 99,
                created_at: '2018-12-20\ 06:40:00',
                _message_sent_at_in_seconds: 100,
            },
        ],
    },
    {
        ticket_id: 3,
        chat: [
            {
                id: 1,
                message: 'Sure! Here are some other girls you might like and meet without changing the date and time you requested.',
                sent_by: 99,
                seen: null,
                group_id: 99,
                created_at: '2018-12-20\ 06:40:00',
                _message_sent_at_in_seconds: 100,
            },
            {
                id: 2,
                message: 'Sure! Here are some other girls you might like and meet without changing the date and time you requested.',
                sent_by: 1,
                seen: null,
                group_id: 2,
                created_at: '2018-12-20\ 06:40:00',
                _message_sent_at_in_seconds: 100,
            },
            {
                id: 3,
                message: 'This ticket has been inactive for a while so the status of your support request was changed to: Closed.',
                sent_by: 99,
                seen: null,
                group_id: 99,
                created_at: '2018-12-20\ 06:40:00',
                _message_sent_at_in_seconds: 100,
            },
        ],
    },
    {
        ticket_id: 4,
        chat: [
            {
                id: 1,
                message: 'Sure! Here are some other girls you might like and meet without changing the date and time you requested.',
                sent_by: 99,
                seen: null,
                group_id: 99,
                created_at: '2018-12-20\ 06:40:00',
                _message_sent_at_in_seconds: 100,
            },
            {
                id: 2,
                message: 'Sure! Here are some other girls you might like and meet without changing the date and time you requested.',
                sent_by: 1,
                seen: null,
                group_id: 2,
                created_at: '2018-12-20\ 06:40:00',
                _message_sent_at_in_seconds: 100,
            },
            {
                id: 3,
                message: 'This ticket has been inactive for a while so the status of your support request was changed to: Closed.',
                sent_by: 99,
                seen: null,
                group_id: 99,
                created_at: '2018-12-20\ 06:40:00',
                _message_sent_at_in_seconds: 100,
            },
        ],
    },
    {
        ticket_id: 5,
        chat: [
            {
                id: 1,
                message: 'Sure! Here are some other girls you might like and meet without changing the date and time you requested.',
                sent_by: 99,
                seen: null,
                group_id: 99,
                created_at: '2018-12-20\ 06:40:00',
                _message_sent_at_in_seconds: 100,
            },
            {
                id: 2,
                message: 'Sure! Here are some other girls you might like and meet without changing the date and time you requested.',
                sent_by: 1,
                seen: null,
                group_id: 2,
                created_at: '2018-12-20\ 06:40:00',
                _message_sent_at_in_seconds: 100,
            },
            {
                id: 3,
                message: 'This ticket has been inactive for a while so the status of your support request was changed to: Closed.',
                sent_by: 99,
                seen: null,
                group_id: 99,
                created_at: '2018-12-20\ 06:40:00',
                _message_sent_at_in_seconds: 100,
            },
        ],
    },
];
