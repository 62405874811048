export const LESSONS = Object.freeze({
  NONE: 0,
  PHOTOS: 1,
  INTERVIEW: 2,
  AVAILABILITY: 3,
  SAFETY: 4,
  CLIENTS: 5,
  REQUESTS: 6,
  DATES: 7,
  FEEDBACK: 8,
  MONEY: 9
})

export const SCREENS = Object.freeze({
  NONE: 0,
  START: 1,
  THUMBS_UP: 2,
  THUMBS_DOWN: 3,
  PHOTOS_GOOD: 4,
  PHOTOS_BAD: 5,
  VIDEO: 6,
  FEEDBACK: 7
})

export const GOOD_PHOTOS = Object.freeze([
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232725/onboarding/bilder-fur-app/yes/1.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232725/onboarding/bilder-fur-app/yes/1.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/1.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/2.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/2.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/2.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/3.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/3.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/3.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/4.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/4.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/4.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/5.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/5.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/5.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232725/onboarding/bilder-fur-app/yes/6.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232725/onboarding/bilder-fur-app/yes/6.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/6.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/7.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/7.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/7.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/8.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/8.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/8.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/9.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/9.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/157x212/onboarding/bilder-fur-app/yes/9.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/10.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/10.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/10.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232725/onboarding/bilder-fur-app/yes/11.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232725/onboarding/bilder-fur-app/yes/11.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/11.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/12.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/12.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/12.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/13.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/13.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/13.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/14.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/14.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/14.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/15.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/15.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/15.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232725/onboarding/bilder-fur-app/yes/16.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232725/onboarding/bilder-fur-app/yes/16.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/16.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/17.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/17.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/17.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/18.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/18.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/18.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/19.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/19.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/19.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/20.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/20.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/20.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232725/onboarding/bilder-fur-app/yes/21.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232725/onboarding/bilder-fur-app/yes/21.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/21.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/22.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/22.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/22.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/23.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/23.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/23.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/24.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/24.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/24.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/25.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/25.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/25.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232725/onboarding/bilder-fur-app/yes/26.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232725/onboarding/bilder-fur-app/yes/26.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/26.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/27.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/27.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/27.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/28.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/28.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/28.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/29.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/29.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/29.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/30.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/30.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/30.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232725/onboarding/bilder-fur-app/yes/31.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232725/onboarding/bilder-fur-app/yes/31.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/31.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/32.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/32.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/32.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/33.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/33.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/33.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/34.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/34.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/34.jpg'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/35.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232730/onboarding/bilder-fur-app/yes/35.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/yes/35.jpg'
  }
])

export const BAD_PHOTOS = Object.freeze([
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-1.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-1.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/no/Foto-1.jpg',
    captionEN: 'Careful with Selfies',
    captionDE: 'Vorsicht mit Selfies'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-2.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-2.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/no/Foto-2.jpg',
    captionEN: 'Head cut off',
    captionDE: 'Kopf abgeschnitten'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-3.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-3.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/no/Foto-3.jpg',
    captionEN: 'Mirror Selfie',
    captionDE: 'Spiegelselfie'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-4.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-4.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/no/Foto-4.jpg',
    captionEN: 'Black and White',
    captionDE: 'Schwarz und Weiß'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-5.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-5.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/no/Foto-5.jpg',
    captionEN: 'Careful with Filters',
    captionDE: 'Vorsicht mit Filtern'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-6.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-6.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/no/Foto-6.jpg',
    captionEN: 'No Other People',
    captionDE: 'Keine anderen Personen'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-7.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-7.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/no/Foto-7.jpg',
    captionEN: 'Too Dark',
    captionDE: 'Zu Dunkel'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-8.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-8.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/no/Foto-8.jpg',
    captionEN: 'Too Blurred',
    captionDE: 'Zu Unscharf'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-9.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-9.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/no/Foto-9.jpg',
    captionEN: 'Too Nude',
    captionDE: 'Zu Nackt'
  },
  {
    thumbnail: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-10.jpg',
    full: 'https://res.cloudinary.com/ama-production/image/upload/ar_3:4,c_crop,g_north,f_auto/v1618232721/onboarding/bilder-fur-app/no/Foto-10.jpg',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/375x500/onboarding/bilder-fur-app/no/Foto-10.jpg',
    captionEN: 'Too Strong Headache',
    captionDE: 'Zu starke Kopfschmerzen'
  }
])

export const VIDEOS = Object.freeze({
  interview: {
    url: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1618460641/onboarding/videos-with-subs-v2/interview_sub-converted.mp4',
    duration: '00:58',
    url_en: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1622713636/onboarding/lesson-videos-en/interview_sub-converted.mp4',
    duration_en: '00:54',
    url_gb: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1622713636/onboarding/lesson-videos-en/interview_sub-converted.mp4',
    duration_gb: '00:54',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/onboarding/videos-with-subs-v2/interview_sub-converted.mp4',
    photo_fall_back_A2_2720_gb: 'https://d1muixarkip6az.cloudfront.net/onboarding/lesson-videos-en/interview_sub-converted.mp4',
    photo_fall_back_A2_2720_en: 'https://d1muixarkip6az.cloudfront.net/onboarding/lesson-videos-en/interview_sub-converted.mp4'
  },
  availability: {
    url: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1618460637/onboarding/videos-with-subs-v2/Verf%C3%BCgbarkeiten_sub-converted.mp4',
    duration: '00:44',
    url_en: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1622713638/onboarding/lesson-videos-en/availabilities_sub-converted.mp4',
    duration_en: '00:50',
    url_gb: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1622713638/onboarding/lesson-videos-en/availabilities_sub-converted.mp4',
    duration_gb: '00:50',
    photo_fall_back_A2_2720: 'https://kr-storage.s3.eu-central-1.amazonaws.com/onboarding/videos-with-subs-v2/Verf%C3%BCgbarkeiten_sub-converted.mp4',
    photo_fall_back_A2_2720_gb: 'https://d1muixarkip6az.cloudfront.net/onboarding/lesson-videos-en/availabilities_sub-converted.mp4',
    photo_fall_back_A2_2720_en: 'https://d1muixarkip6az.cloudfront.net/onboarding/lesson-videos-en/availabilities_sub-converted.mp4'
  },
  safety: {
    url: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1618460637/onboarding/videos-with-subs-v2/Diskretion_sub-converted.mp4',
    duration: '00:53',
    url_en: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1622713634/onboarding/lesson-videos-en/discretion_sub-converted.mp4',
    duration_en: '00:51',
    url_gb: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1622713634/onboarding/lesson-videos-en/discretion_sub-converted.mp4',
    duration_gb: '00:51',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/onboarding/videos-with-subs-v2/Diskretion_sub-converted.mp4',
    photo_fall_back_A2_2720_en: 'https://d1muixarkip6az.cloudfront.net/onboarding/lesson-videos-en/discretion_sub-converted.mp4',
    photo_fall_back_A2_2720_gb: 'https://d1muixarkip6az.cloudfront.net/onboarding/lesson-videos-en/discretion_sub-converted.mp4'
  },
  clients: {
    url: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1618460637/onboarding/videos-with-subs-v2/Kunden_sub-converted.mp4',
    duration: '00:58',
    url_en: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1622713634/onboarding/lesson-videos-en/clients_sub-converted.mp4',
    duration_en: '01:00',
    url_gb: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1622713634/onboarding/lesson-videos-en/clients_sub-converted.mp4',
    duration_gb: '01:00',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/onboarding/videos-with-subs-v2/Kunden_sub-converted.mp4',
    photo_fall_back_A2_2720_en: 'https://d1muixarkip6az.cloudfront.net/onboarding/lesson-videos-en/clients_sub-converted.mp4',
    photo_fall_back_A2_2720_gb: 'https://d1muixarkip6az.cloudfront.net/onboarding/lesson-videos-en/clients_sub-converted.mp4'
  },
  requests: {
    url: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1618460642/onboarding/videos-with-subs-v2/Anfragen_sub-converted.mp4',
    duration: '00:48',
    url_en: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1622713638/onboarding/lesson-videos-en/requests_subs-converted.mp4',
    duration_en: '00:47',
    url_gb: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1622713638/onboarding/lesson-videos-en/requests_subs-converted.mp4',
    duration_gb: '00:47',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/onboarding/videos-with-subs-v2/Anfragen_sub-converted.mp4',
    photo_fall_back_A2_2720_en: 'https://d1muixarkip6az.cloudfront.net/onboarding/lesson-videos-en/requests_subs-converted.mp4',
    photo_fall_back_A2_2720_gb: 'https://d1muixarkip6az.cloudfront.net/onboarding/lesson-videos-en/requests_subs-converted.mp4'
  },
  dates: {
    url: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1618460637/onboarding/videos-with-subs-v2/Treffen_sub-converted.mp4',
    duration: '00:45',
    url_en: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1622713635/onboarding/lesson-videos-en/meetings_subs-converted.mp4',
    duration_en: '00:45',
    url_gb: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1622713635/onboarding/lesson-videos-en/meetings_subs-converted.mp4',
    duration_gb: '00:45',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/onboarding/videos-with-subs-v2/Treffen_sub-converted.mp4',
    photo_fall_back_A2_2720_en: 'https://d1muixarkip6az.cloudfront.net/onboarding/lesson-videos-en/meetings_subs-converted.mp4',
    photo_fall_back_A2_2720_gb: 'https://d1muixarkip6az.cloudfront.net/onboarding/lesson-videos-en/meetings_subs-converted.mp4'
  },
  feedback: {
    url: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1618460641/onboarding/videos-with-subs-v2/Feedback_sub-converted.mp4',
    duration: '01:01',
    url_en: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1622713635/onboarding/lesson-videos-en/feedback_Sub-converted.mp4',
    duration_en: '01:05',
    url_gb: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1622713635/onboarding/lesson-videos-en/feedback_Sub-converted.mp4',
    duration_gb: '01:05',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/onboarding/videos-with-subs-v2/Feedback_sub-converted.mp4',
    photo_fall_back_A2_2720_en: 'https://d1muixarkip6az.cloudfront.net/onboarding/lesson-videos-en/feedback_Sub-converted.mp4',
    photo_fall_back_A2_2720_gb: 'https://d1muixarkip6az.cloudfront.net/onboarding/lesson-videos-en/feedback_Sub-converted.mp4'
  },
  money: {
    url: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1618460637/onboarding/videos-with-subs-v2/Geld_33_sub-converted.mp4',
    duration: '01:00',
    url_en: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1646373460/onboarding/lesson-videos-en/money_EN_Germany_1.mp4',
    duration_en: '01:03',
    url_gb: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1622713637/onboarding/lesson-videos-en/money_sub-converted.mp4',
    duration_gb: '01:03',
    photo_fall_back_A2_2720: 'https://d1muixarkip6az.cloudfront.net/onboarding/videos-with-subs-v2/Geld_33_sub-converted.mp4',
    photo_fall_back_A2_2720_en: 'https://d1muixarkip6az.cloudfront.net/onboarding/lesson-videos-en/money_EN_Germany_1.mp4',
    photo_fall_back_A2_2720_gb: 'https://d1muixarkip6az.cloudfront.net/onboarding/lesson-videos-en/money_sub-converted.mp4',
    ch: {
      de: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1678869485/onboarding/videos-with-subs-v2/Dein_Geld_25-converted.mp4',
      en: 'https://res.cloudinary.com/ama-production/video/upload/ac_aac,q_auto/v1622713637/onboarding/lesson-videos-en/money_sub-converted.mp4',
      photo_fall_back_A2_2720: {
        de: 'https://d1muixarkip6az.cloudfront.net/onboarding/videos-with-subs-v2/Dein_Geld_25-converted.mp4',
        en: 'https://d1muixarkip6az.cloudfront.net/onboarding/lesson-videos-en/money_sub-converted.mp4'
      }

    }
  }
})
