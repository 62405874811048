import { buildParam } from '@/services/ApiService'

export const providerAppFeedbackPath = '/v2/app-feedback'

export const postAppFeedback = (payload) => {
  const url = buildParam(`${process.env.KRYPTON_PROVIDER_API}${providerAppFeedbackPath}`)
  return new Promise((resolve, reject) => {
    window.api.post({ url, payload },
      (success) => { resolve(success.data) },
      (err) => { reject(err.response.data) })
  })
}
