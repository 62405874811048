<script>
import AuthService from '@/services/AuthService'
export default {
  name: 'ResetPassword',
  data () {
    return {
      password: null,
      confirmPassword: null,
      errorMsg: null,
      errorsObj: null,
      isLoading: false,
      isDisabled: true,
      pFixedFalse: false
    }
  },
  async mounted () {
    await this.validateResetPassword(this.$route.query.email, this.$route.query.token)
  },
  computed: {
    registerRoute () {
      return {
        name: 'register-start',
        ...(
        this.locale === 'en' && {
          params: {
          lang: 'en'
          }
        })
      }
    },
    loginRoute () {
      return {
        name: 'login',
        ...(
        this.locale === 'en' && {
          params: {
          lang: 'en'
          }
        })
      }
    }
  },
  methods: {
    async handlePasswordReset (event) {
      this.errorMsg = null
      this.isLoading = true

      try {
        await AuthService.resetPassword(
          this.$route.query.email,
          this.$route.query.token,
          this.password,
          this.confirmPassword
        )
        this.$router.replace({ name: 'login', query: { 'reset-password': true } })
      } catch (err) {
        this.errorMsg = err.message

        const invalid_inputs = 'Invalid inputs'

        if (err.message === invalid_inputs) {
          this.errorMsg = this.$t('resetPassword.notMatch')
        } else {
          this.errorMsg = err.message
        }

        this.errorsObj = ({
          password: this.errorMsg,
          confirmPassword: this.errorMsg
        })

      }

      this.isLoading = false
    },
    async validateResetPassword (email, token) {
      try {
        await AuthService.resetPasswordValidate(email, token)
        this.isDisabled = false
      } catch (err) {
        this.errorMsg = err.message

        this.errorsObj = ({
          password: err.message,
          confirmPassword: err.message
        })
      }
    },
    isEmpty (field = null) {
      return field === null || field === ''
    },
    handlePasswordChange (val) {
      this.password = val
      this.removeFieldError('password')
    },
    handleConfirmPasswordChange (val) {
      this.confirmPassword = val
      this.removeFieldError('confirmPassword')
    },
    removeFieldError (field = null) {
      if (this.errorsObj && this.errorsObj[field]) {
        delete this.errorsObj[field]
      }
    }
  },
  render () {
    return this.$scopedSlots.default({
      data: {
        password: this.password,
        confirmPassword: this.confirmPassword,
        errorMsg: this.errorMsg,
        errorsObj: this.errorsObj,
        isLoading: this.isLoading,
        isDisabled: this.isDisabled,
        registerRoute: this.registerRoute,
        loginRoute: this.loginRoute,
        pFixedFalse: this.pFixedFalse
      },
      actions: {
        translate: (key, opts) => { return this.$t(key, opts) },
        getFieldError: (field) => (this.errorsObj && this.errorsObj[field]) ? this.errorsObj[field] : null
      },
      bindings: {
        handlePasswordChange: this.handlePasswordChange,
        handleConfirmPasswordChange: this.handleConfirmPasswordChange,
        handlePasswordReset: this.handlePasswordReset,
        isEmpty: this.isEmpty
      }
    })
  }
}
</script>

<i18n src="../../translations.json"></i18n>
