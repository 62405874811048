<template>
  <auth-view :data="data">
    <template v-slot:title>
      <h2>{{ $t('forgotPassword.successMainTitle') }}</h2>
    </template>

    <div class="instructions">
      <p class="padding-bottom-md" v-text="$t('forgotPassword.successTitle')" />
      <p class="padding-bottom-md" v-text="$t('forgotPassword.successText1')" />
      <p v-text="$t('forgotPassword.successText2')" />
    </div>

    <div class="footer-auth-nav">
      <div class="forgot-container">
        <router-link class="forgot-link" :to="forgotPasswordRoute">{{ $t('tryAgain.tryagain') }}</router-link>
      </div>
      <span class="label">{{ $t('tryAgain.or') }}</span>
      <div class="register-container">
        <router-link class="register-link" :to="registerRoute">{{ $t('tryAgain.register') }}</router-link>
      </div>
    </div>

  </auth-view>
</template>

<i18n src="../../translations.json"></i18n>

<script>
import AuthView from '../AuthView.vue'

export default {
  components: {
    AuthView
  },
  computed: {
    data () {
      return {
        errorMsg: null
      }
    },
    registerRoute () {
      return {
        name: 'register-start',
        ...(
          this.locale === 'en' && {
            params: {
              lang: 'en'
            }
          })
      }
    },
    forgotPasswordRoute () {
      return {
        name: 'forgot-password',
        ...(
          this.locale === 'en' && {
            params: {
              lang: 'en'
            }
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.instructions {
  padding: 24px;
  border-radius: 12px;
  background-color: #F7FAFF;

  h2 {
    color: #2F3740;
    font-size: 32px;
    line-height: 36px;
  }

  p {
    margin: 0;
    color: #2F3740;
    font-size: 18px;
    line-height: 22px;
  }
}

.footer-auth-nav {
  font-size: 14px;
  line-height: 18px;
  margin: 46px 0 38px 0;

  .login-label { color: #2F3740; }

  .login-container { margin-bottom: 28px; }

  .login-link, .register-link {
      color: #DC0064;
      text-decoration: underline;
  }
}

.forgot-pass-auth-nav {
  font-size: 14px;
  line-height: 18px;
  margin: 32px 0 60px 0;

  .label { color: #2F3740; }

  .forgot-container { margin-bottom: 18px; }
  .register-container { margin-top: 18px; }

  .forgot-link, .register-link { color: #DC0064; text-decoration: underline; }
}

</style>
