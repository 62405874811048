var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bottom-sheet",
      class: { "bottom-sheet--visible": _vm.isShown }
    },
    [
      _c("div", { staticClass: "bottom-sheet__container" }, [
        _c("div", { staticClass: "bottom-sheet__header" }, [
          _c(
            "div",
            {
              staticClass: "bottom-sheet__close-cta",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return (function() {
                    return _vm.$emit("close")
                  })($event)
                }
              }
            },
            [
              _c("img", {
                attrs: { src: "/img/icons/close-icon-48.png", alt: "" }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "bottom-sheet__body" },
          [_vm._t("body", [_c("h2", { staticClass: "bottom-sheet__title" })])],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "bottom-sheet__footer" },
          [_vm._t("footer")],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }