const redirectNotAuthenticated = require('@/middleware/redirectNotAuthenticated').default

export const privacy = {
  path: '/legal/privacy',
  name: 'legal-privacy',
  component: Vue.component('legal-privacy')
}

export const philosophy = {
  path: '/legal/philosophy',
  name: 'legal-philosophy',
  component: Vue.component('legal-philosophy')
}

export const termsConditions = {
  path: '/legal/terms-conditions',
  name: 'legal-terms-conditions',
  component: Vue.component('legal-terms-conditions'),
  beforeEnter: redirectNotAuthenticated
}
