<template>
  <div class="faq-item">
    <div v-if="isItemTypeCheck">
      <div class="text-content">
        <img
          class="faq-item-checklist-icon"
          src="/img/faq/faq-green-checkmark.svg"
        >
        <p>{{ content }}</p>
      </div>
      <img v-if="img" :src="img">
    </div>
    <div v-else class="flash-tip">
      <img
        class="faq-item-checklist-icon"
        src="/img/faq/faq-orange-mark.svg"
      >
      <p>{{ content }}</p>
    </div>
  </div>
</template>

<script>
const ITEM_TYPE = {
  CHECK: 'check',
  FLASH: 'flash'
}

export default {
  props: {
    itemType: { type: String, default: ITEM_TYPE.CHECK },
    content: { type: String, default: '' },
    img: { type: String, default: '' }
  },
  computed: {
    isItemTypeCheck () {
      return this.itemType === ITEM_TYPE.CHECK
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./FAQItem.style.scss";
</style>
