<template>
  <div id="billing-page">
    <md-new-navbar />
    <div id="content" class="container">
      <div class="billing-header">
        <h1>{{ $t('title') }}</h1>
        <div class="billing-header__cards">
          <div
            class="billing-header__card billing-header__card--unpaid"
            :class="{'billing-header__card--disabled': billingOverview.unpaid_balance === 0}"
            data-test="unpaid-container"
          >
            <div class="billing-header__card-title" data-test="unpaid-title">{{ $t('billing_overview.unpaid') }}</div>
            <div class="billing-header__card-value" data-test="unpaid-balance">{{ unpaidBalance }}</div>
          </div>
          <div
            class="billing-header__card billing-header__card--credit"
            :class="{'billing-header__card--disabled': billingOverview.credit_balance === 0}"
            data-test="credit-container"
          >
            <div class="billing-header__card-title" data-test="credit-title">{{ $t('billing_overview.credit') }}</div>
            <div class="billing-header__card-value" data-test="credit-balance">{{ creditBalance }}</div>
            <a href="#" class="billing-header__card-icon" data-test="credit-tooltip" @click.prevent="showTooltip">
              <i class="material-icons">help</i>
            </a>
          </div>
        </div>
      </div>
      <div class="billing-tabs">
        <router-link :to="{ name: 'billing-v2-unpaid' }"
                     class="billing-tabs__tab waves-effect"
                     :class="{ 'billing-tabs__tab--active': isTabActive('billing-v2-unpaid') }"
        >
          {{ $t('unpaid.label') }}
        </router-link>
        <router-link :to="{ name: 'billing-v2-paid' }"
                     class="billing-tabs__tab waves-effect"
                     :class="{ 'billing-tabs__tab--active': isTabActive('billing-v2-paid') }"
        >
          {{ $t('paid.label') }}
        </router-link>
      </div>
      <div class="billing-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/billing/translations/billing.json"></i18n>

<script>
import { tooltipPlugin } from '@/features/super-provider/plugins/vue-custom-tooltip.js'
import { getBillingOverview } from '@/features/billing/services/BillingServices'
import {
  DEFAULT_BILLING_OVERVIEW
} from '@/features/billing/constants/billing-overview-defaults'
import { AFFILIATE_SETTINGS_SEEN_FIELD_TYPES } from '@/features/affiliate/constants'
import { putAffiliateSettingsSeen } from '@/features/affiliate/services/AffiliateService.js'

export default {
  provide () {
    const billingData = {}

    Object.defineProperty(billingData,
      'billingOverview', {
        enumerable: true,
        get: () => this.billingOverview
      }
    )

    Object.defineProperty(billingData,
      'isEUR', {
        enumerable: true,
        get: () => this.isEUR
      }
    )

    return {
      billingData
    }
  },
  data () {
    return {
      isLoading: false,
      billingOverview: DEFAULT_BILLING_OVERVIEW,
      unpaidInvoices: [],
      paidInvoices: []
    }
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    currency () {
      return this.user?._currency ?? '€'
    },
    isTabActive () {
      return tab => tab === this.$route.name
    },
    tooltipInfo () {
      return this.$t('tooltip_info')
    },
    isEUR () {
      return this.billingOverview.currency_code === 'EUR' || this.billingOverview.currency_symbol === '€'
    },
    unpaidBalance () {
      const decrement = `${this.billingOverview.unpaid_balance === 0 ? '' : '- '}`

      const unpaid = this.isEUR
        ? `${decrement}${this.billingOverview.unpaid_balance?.toFixed(2)} ${this.currency}`
        : `${decrement}${this.currency} ${this.billingOverview.unpaid_balance?.toFixed(2)}`

      return unpaid
    },
    creditBalance () {
      const increment = `${this.billingOverview.credit_balance === 0 ? '' : '+ '}`

      const unpaid = this.isEUR
        ? `${increment}${this.billingOverview.credit_balance?.toFixed(2)} ${this.currency}`
        : `${increment}${this.currency} ${this.billingOverview.credit_balance?.toFixed(2)}`

      return unpaid
    }
  },
  async mounted () {
    try {
      this.isLoading = true
      await this.fetchBillingOverview()
      this.isLoading = false
    } catch (err) {
      console.log('err', err)
    }
    this.checkMenuBillingSeen()
  },
  methods: {
    showTooltip () {
      tooltipPlugin.toast(this.tooltipInfo)
    },
    fetchBillingOverview () {
      return getBillingOverview()
        .then(data => {
          if (data?.data) {
            this.billingOverview = Object.assign(DEFAULT_BILLING_OVERVIEW, data.data)
          }
        })
    },
    checkMenuBillingSeen () {
      if (this.user?.settings?.menu_billing_seen === 0) {
        putAffiliateSettingsSeen({ field: AFFILIATE_SETTINGS_SEEN_FIELD_TYPES.MENU_BILLING })
          .then(() => this.$store.commit('setUserSettingMenuBillingSeen', 1))
          .catch(err => this.handleErrorMessages(err))
      }
    },
    handleErrorMessages (err = ({ message: '' })) {
      this.$notifications.toast(err?.message, 5000)
    }
  }
}
</script>

<style lang="scss">
#billing-page {
  #content {
    display: flex;
    flex-direction: column;
    height: calc(100svh - 56px);

    .billing-header {
      margin: -24px -24px 0;
      padding: 24px;
      background-color: #FFFFFF;

      h1 {
        margin: 0 0 19px;
        font-size: 26px;
        font-family: 'Proxima Nova Semibold', sans-serif;
        color: #2E353D;
      }
      .billing-header__cards {
        display: flex;
        justify-content: space-between;

        .billing-header__card {
          position: relative;
          width: calc(50% - 8px);
          padding: 15px 20px 16px;
          border-radius: 12px;
          font-family: 'Proxima Nova Semibold', sans-serif;
          text-align: left;
          color: #FFFFFF;

          .billing-header__card-title {
            font-size: 16px;
          }
          .billing-header__card-value {
            font-size: 20px;
          }
          .billing-header__card-icon {
            position: absolute;
            top: 15px;
            right: 20px;
            font-size: 24px;
            color: #FFFFFF;
          }
          &.billing-header__card--unpaid {
            background-color: #ff6785;
            background-image: url('/img/billing/billing-card-bg-top.svg');
            background-repeat: no-repeat;
            background-position: top -2px right -2px;
          }
          &.billing-header__card--credit {
            background-color: #00d96f;
            background-image: url('/img/billing/billing-card-bg-bottom.svg');
            background-repeat: no-repeat;
            background-position: bottom -2px right -2px;
          }
          &.billing-header__card--disabled {
            background-color: #F5F7FA;

            .billing-header__card-title {
              color: #2E353D;
            }
            .billing-header__card-value {
              color: #8995A3;
            }
            .billing-header__card-icon {
              color: #898e94;
            }
          }
        }
      }
    }
    .billing-tabs {
      display: flex;
      margin: 0 -24px;
      background-color: #FFFFFF;

      .billing-tabs__tab {
        width: 50%;
        padding: 14px 16px 16px;
        border-bottom: 2px solid transparent;
        font-size: 15px;
        font-family: 'Proxima Nova Semibold', sans-serif;
        color: rgba(#2E353D, 0.54);
      }
      .billing-tabs__tab--active {
        border-bottom: 2px solid #C51162;
        color: #2E353D;
      }
    }
    .billing-content {
      flex: 1 1 auto;
      overflow: auto;
    }
  }
}

.billing-tab {
  padding: 24px 0;

  .billing-bulletin {
    padding: 16px 24px;
    border-radius: 8px;
    background-color: #FFFFFF;

    .billing-bulletin__title {
      margin: 0 0 6px;
      font-size: 16px;
      font-family: 'Proxima Nova Semibold', sans-serif;
      color: #2E353D;
    }
    .billing-bulletin__content {
      margin: 0;
      font-size: 14px;
      color: #2E353D;
    }
  }

  .billing-placeholder {
    margin-top: 12px;

    img {
      display: block;
      margin: 0 auto 10px;
    }
    .billing-placeholder__text {
      font-size: 22px;
      font-family: 'Proxima Nova Bold', sans-serif;
      color: #2F3740;
    }
  }
  .billing-items {
    margin-top: 8px;
    margin-bottom: 24px;

    .billing-item {
      margin: 0 0 8px;
      padding: 20px 16px 13px;
      border-radius: 8px;
      box-shadow: 0px 3px 6px #00000029;;
      background-color: #FFFFFF;
      cursor: pointer;

      .billing-item__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .billing-item__header-icon {
          display: flex;
          align-items: center;

          i.material-icons {
            display: block;
            font-size: 24px;
          }
        }
        .billing-item__header-invoice-id {
          font-size: 12px;
          color: #2F3740;
          margin-left: 2px;
        }
        .billing-item__header-amount {
          font-size: 16px;
          font-family: 'Proxima Nova Semibold', sans-serif;
          color: #2E353D;
        }
        .billing-item__header-status {
          padding: 5px 18px;
          border-radius: 8px;
          font-size: 12px;
          text-align: center;
          color: #FFFFFF;
          width: 120px;
        }
      }

      &.billing-item--overdue {
        .billing-item__header-icon {
          color: #FF6584;
        }
        .billing-item__header-status {
          background-color: #FF6584;
        }
      }

      &.billing-item--late {
        .billing-item__header-icon {
          color: #F9A825;
        }
        .billing-item__header-status {
          background-color: #F9A825;
        }
      }

      &.billing-item--paid {
        .billing-item__header-icon {
          color: #00D96F;
        }
        .billing-item__header-status {
          background-color: #00D96F;
        }

        .billing-item__body {
          border-bottom: unset;
        }

        .billing-item__body-row:last-child {
          padding: 6.5px 8px 0;
        }
      }

      &.billing-item--cancelled {
        background-color: #F5F7FA;

        .billing-item__header-icon,
        .billing-item__header-invoice-id,
        .billing-item__header-amount {
          color: #8995A3;
        }
        .billing-item__header-status {
          background-color: #8995A3;
        }
      }

      .billing-item__body {
        display: none;
        margin: 20px -8px 0;
        border-top: 1px solid #E0E0E0;
        border-bottom: 1px solid #E0E0E0;

        .billing-item__body-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 6.5px 8px;

          .billing-item__body-row-label {
            font-size: 14px;
            color: #8995A3;
          }
          .billing-item__body-row-value {
            font-size: 14px;
            color: #2F3740;
          }
        }
      }

      .billing-item__footer {
        display: none;
        justify-content: flex-end;
        align-items: center;
        margin-top: 13px;

        img {
          margin-right: 4px;
        }
        a {
          font-size: 12px;
          font-weight: 600;
          color: #C51162;
        }
      }

      &.billing-item--expanded {
        .billing-item__body {
          display: block;
        }
        .billing-item__footer {
          display: flex;
        }
      }
    }
  }
  .btn.btn-outlined {
    height: 44px;
    line-height: 44px;
    border: 2px solid #CC1467;
    border-radius: 8px;
    background-color: transparent;
    font-size: 16px;
    font-family: 'Proxima Nova Semibold', sans-serif;
    text-align: center;
    color: #CC1467;
  }
}
</style>
