<template>
  <div>
    <div
      id="onboarding-bottom-sheet"
      ref="onboarding_bottom_sheet"
      :class="getOBSClass"
    >
      <div class="bottom-sheet-container">
        <div class="bottom-sheet-bar-container" data-test="bottom-sheet-resize-cta" :data-expanded="this.isMaxView" @click="onBottomSheetResize">
          <div class="bottom-sheet-bar-header">
            <div>
              <i class="material-icons" data-test="bottom-sheet-collapse-icon">{{ isMaxView ? 'expand_more' : 'expand_less' }}</i>
              <span>{{ collapseText }}</span>
            </div>
            <div v-if="!isActiveLessonCompleted" data-test="bottom-sheet-collapse-new-lesson-indicator">
              <i class="material-icons">lens</i>
              <span>{{ $translate('onboarding.new') }}</span>
            </div>
          </div>
        </div>

        <onboarding-progress-greeting />

        <onboarding-verification-banner />

        <onboarding-bottom-sheet-dashboard-intro />

        <onboarding-active-lesson />

        <onboarding-bottom-sheet-quick-start />

      </div>
    </div>
  </div>
</template>

<script>
import { BOTTOM_SHEET_SIZES } from '@/features/onboarding-bottom-sheet/constants.js'
import OnboardingProvider from '@/providers/OnboardingProvider'
import { mapGetters } from 'vuex'

export default {
  props: {
    isMaxViewByDefault: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    onboardingHeight: null
  }),
  computed: {
    ...mapGetters({
      activeLesson: 'onboarding/GET_ACTIVE_LESSON',
      isActiveLessonCompleted: 'onboarding/GET_IS_ACTIVE_LESSON_COMPLETED'
    }),
    getResizeShortcutImage () {
      const shortcutImage = (this.isMaxView) ? 'window-zoom-out.svg' : 'window-zoom-in.svg'

      return require(`@/features/onboarding-bottom-sheet/assets/${shortcutImage}`)
    },
    isMaxView () {
      return this.onboardingHeight === BOTTOM_SHEET_SIZES.MAX
    },
    getOBSClass () {
      return this.isMaxView ? 'obs-max' : 'obs-min'
    },
    collapseText () {
      return this.isMaxView ? this.$translate('onboarding.minimize') : this.$translate('onboarding.maximize')
    }
  },
  destroyed () {
    this.addOrRemoveOverflowClass('remove')
  },
  mounted () {
    this.initializeBottomSheetHeight()

    if (this.activeLesson) {
      this.fetchLessonProgress()
    }
  },
  methods: {
    initializeBottomSheetHeight () {
      if (this.isMaxViewByDefault) {
        this.setHeight(BOTTOM_SHEET_SIZES.MAX)
      } else {
        this.setHeight(BOTTOM_SHEET_SIZES.MIN)
      }

      this.setBodyScrollability()
    },
    onBottomSheetResize () {
      const newHeight = this.isMaxView ? BOTTOM_SHEET_SIZES.MIN : BOTTOM_SHEET_SIZES.MAX
      const newIsMaxView = this.isMaxView ? 0 : 1

      this.$store.commit('onboarding/SET_DISPLAY_BOTTOM_SHEET_IN_MAX_VIEW', newIsMaxView)
      this.setHeight(newHeight)

      this.setBodyScrollability()
    },
    setBodyScrollability () {
      if (this.isMaxView) {
        this.addOrRemoveOverflowClass('add')
      } else {
        this.addOrRemoveOverflowClass('remove')
      }
    },
    addOrRemoveOverflowClass (action) {
      const el = document.body

      switch (action) {
        case 'add':
          el.classList.add('hide-overflow')
          break
        case 'remove':
          el.classList.remove('hide-overflow')
          break
        default:
          break
      }
    },
    setHeight (newHeight) {
      const panel = this.$refs.onboarding_bottom_sheet
      this.onboardingHeight = newHeight
      panel.style.height = newHeight
    },
    async fetchLessonProgress () {
      try {
        const progress = await OnboardingProvider.fetchLessonProgress(this.activeLesson)
        this.$store.dispatch('onboarding/UPDATE_IS_ACTIVE_LESSON_COMPLETED', progress.data.is_completed)
      } catch (e) {}
    }
  }
}
</script>

<style lang="scss">
.hide-overflow {
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
  position: fixed;
  width: 100%;
}
</style>

<style lang="scss" scoped>

#onboarding-bottom-sheet {
  position: fixed;
  background: #FFFFFF;
  display: block;
  bottom: 88px;
  box-shadow: 0px -8px 12px #2F374014;
  border-radius: 16px 16px 0 0;
  z-index: 998;
  width: 100%;
  overflow-x: hidden;

  &.obs-max {
    overflow: auto;
    padding-bottom: 20px
  }

  &.obs-min {
    overflow: hidden;
  }

  .bottom-sheet-container {
    padding-bottom: 20px;
    width:100%;
    padding-left: 32px;
    padding-right: 32px;
  }

  .bottom-sheet-bar-container {
      padding-top: 8px;
      padding-bottom: 8px;
      margin-left: -32px;
      margin-right: -32px;
      margin-bottom: 8px;
      border-bottom: 1px solid #CED6E0;

      div.bottom-sheet-bar-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 32px;
      padding-left: 32px;
      cursor: pointer;

      > div {
        display: flex;
        align-items: center;

        span {
          font-size: 14px;
          color: #78838F;
        }

        i {
          color: #78838F;
        }

        &:nth-of-type(2) {
          span {
            font-size: 14px;
            color: #CC1467;
          }

          i {
            font-size: 8px;
            color: #CC1467;
            margin-right: 4px;
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    width: 600px;
    left: 50%;
    margin-left: -300px;
  }
}
</style>
