var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "availability-card no-select",
      class: { "is-card-active": _vm.isToggled }
    },
    [
      _vm.isMonday && !_vm.isFirstDayMonth && _vm.$props.pkey !== 0
        ? _c("div", { staticClass: "col s12" }, [_c("br")])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col s12" }, [
        _c(
          "div",
          {
            staticClass: "card-panel no-select",
            class: {
              inactive: !_vm.isToggled,
              active: _vm.isToggled,
              today: _vm.isToday
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "card-panel--header no-select",
                class: { today: _vm.isToday, monday: _vm.isMonday },
                attrs: { id: "availability-card-no-select-toggle" },
                on: { click: _vm.toggle }
              },
              [
                _c("div", {
                  staticClass: "availability-link",
                  attrs: { id: _vm.availabilityID }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "availabilities--date",
                    class: { "black-text": _vm.isMonday || _vm.isToday }
                  },
                  [_c("pre", [_vm._v(_vm._s(_vm.date))])]
                ),
                _vm._v(" "),
                (_vm.formData.id &&
                  (!_vm.formData.not_available || _vm.shouldConfirm)) ||
                _vm.isToggled
                  ? _c("div", { staticClass: "availabilities--summary" }, [
                      _c(
                        "div",
                        { staticClass: "meeting-indicators" },
                        _vm._l(_vm.meetings, function(status, idx) {
                          return _c("div", {
                            key: idx,
                            staticClass: "indicator",
                            class: { active: status }
                          })
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "time" }, [
                        _vm.isAvailableAndNotLateNight
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.formData.availability_start) +
                                  " - " +
                                  _vm._s(_vm.formData.availability_end)
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.notAvailableOrIsLateNight
                          ? _c("span", [_vm._v("-:- - -:-")])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "spacer" })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.formData.id &&
                !_vm.isToggled &&
                _vm.formData.not_available && !_vm.shouldConfirm
                  ? _c(
                      "div",
                      {
                        staticClass: "availabilities--summary",
                        class: {
                          "not-available":
                            _vm.formData.not_available && !_vm.shouldConfirm
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "meeting-indicators" },
                          _vm._l(_vm.meetings, function(status, idx) {
                            return _c("div", {
                              key: idx,
                              staticClass: "indicator",
                              class: { active: status }
                            })
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "time" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$translate("availability.not_available")
                              )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "spacer" })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.formData.id && !_vm.isToggled
                  ? _c("div", { staticClass: "availabilities--summary" }, [
                      _c("p", [
                        _vm._v(_vm._s(_vm.$translate("availability.set_now")))
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", {
                  staticClass: "availability-status",
                  class: {
                    unavailable: _vm.notAvailableOrIsLateNight,
                    unfilled: !_vm.formData.id,
                    filled: _vm.formData.id
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "card-panel--body" }, [
              _c("div", {
                ref: "rangeinput",
                staticClass: "availability-schedule--input"
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "availability-schedule--indicator",
                  class: {
                    disabled: _vm.notAvailableOrIsLateNight
                  }
                },
                _vm._l(_vm.schedule, function(status, idx) {
                  return _c("div", {
                    key: idx,
                    staticClass: "indicator",
                    class: {
                      "half-available-meeting-2":
                        status ===
                        _vm.MEETING_INDICATOR_STATUS.HALF_MEETING_AVAILABLE_2,
                      "half-available-meeting-1":
                        status ===
                        _vm.MEETING_INDICATOR_STATUS.HALF_MEETING_AVAILABLE_1,
                      "half-available-2":
                        status ===
                        _vm.MEETING_INDICATOR_STATUS.HALF_AVAILABLE_2,
                      "half-available-1":
                        status ===
                        _vm.MEETING_INDICATOR_STATUS.HALF_AVAILABLE_1,
                      "half-meeting-1":
                        status === _vm.MEETING_INDICATOR_STATUS.HALF_MEETING_1,
                      "half-meeting-2":
                        status === _vm.MEETING_INDICATOR_STATUS.HALF_MEETING_2,
                      unavailable:
                        status === _vm.MEETING_INDICATOR_STATUS.UNAVAILABLE,
                      available:
                        status === _vm.MEETING_INDICATOR_STATUS.AVAILABLE,
                      meeting: status === _vm.MEETING_INDICATOR_STATUS.MEETING,
                      "prime-time": _vm.getIndicatorPrimeTimeStartIndex(idx)
                    }
                  })
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "input-group" }, [
                _c("div", { staticClass: "left-btn" }, [
                  _c(
                    "div",
                    {
                      staticClass: "btn-click waves-effect waves-dark",
                      class: { disabled: _vm.meetings.indexOf(true) >= 0 },
                      on: { click: _vm.confirm }
                    },
                    [
                      !_vm.formData.not_available || _vm.shouldConfirm
                        ? _c("span", { attrs: { id: "avail-away-btn" } }, [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("block")
                            ]),
                            _vm._v(" "),
                            _c("small", [
                              _vm._v(
                                _vm._s(_vm.$translate("availability.away_btn"))
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formData.not_available && !_vm.shouldConfirm
                        ? _c(
                            "span",
                            {
                              staticClass: "pink-text make-available",
                              class: { disabled: _vm.isTimeWithinLateNight },
                              attrs: { id: "avail-make-btn" }
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("refresh")
                              ]),
                              _vm._v(" "),
                              _c("small", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$translate(
                                      "availability.make_available_btn"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "right-btn" }, [
                  (!_vm.formData.id || _vm.shouldConfirm) && !_vm.isSaving
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "confirm-btn btn waves-effect waves-light",
                          attrs: {
                            id: "save-avail-btn",
                            type: "button",
                            disabled: _vm.isTimeWithinLateNight
                          },
                          on: { click: _vm.save }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$translate("availability.confirm_btn")
                              ) +
                              "\n            "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.formData.id && !_vm.shouldConfirm) || _vm.isSaving
                    ? _c("div", { staticClass: "save-indicator green-text" }, [
                        _vm.isSaving
                          ? _c("div", { staticClass: "saving" }, [
                              _vm._m(0),
                              _vm._v(" "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.$translate("availability.saving"))
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isSaving
                          ? _c("div", { staticClass: "saved" }, [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("check")
                              ]),
                              _vm._v(" "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.$translate("availability.saved"))
                                )
                              ])
                            ])
                          : _vm._e()
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              !_vm.isInPrimeTime && !_vm.isTimeWithinLateNight
                ? _c("div", { staticClass: "explanation-text" }, [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("flash_on")
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$translate("availability.prime_time_explanation")
                        )
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isTimeWithinLateNight
                ? _c("div", { staticClass: "explanation-text" }, [
                    _c("p", [
                      _vm._v(_vm._s(_vm.$translate("availability.late_night")))
                    ])
                  ])
                : _vm._e()
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "preloader-wrapper", attrs: { small: "", active: "" } },
      [
        _c("div", { staticClass: "spinner-layer spinner-green-only" }, [
          _c("div", { staticClass: "circle-clipper left" }, [
            _c("div", { staticClass: "circle" })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gap-patch" }, [
            _c("div", { staticClass: "circle" })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "circle-clipper right" }, [
            _c("div", { staticClass: "circle" })
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }