<template>
  <div id="forgotpass-desktop" class="forgotpass-page">
    <desktop-header />
    <div id="content" class="container margin-top-x6">
      <div class="card">
        <div id="header">
          <div class="text-darken-1" style="line-height: 36px">{{ $translate('forgotPassword.header_line_1') }}</div>
          <div class="text-darken-1">{{ $translate('forgotPassword.header_line_2') }}</div>
        </div>
        <vform @submit="doSubmit">
          <div v-if="error" id="forgotpassword-desktop-error" class="grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align">
            <small>{{ error }}</small>
          </div>
          <email-input class="email-input" :email="email" @onInput="onEmailChange" />
          <button
            id="forgotpassword-desktop-submit-btn"
            type="submit"
            tabindex="3"
            class="btn btn-raised btn-large waves-effect waves-light display-block">
            {{ $translate('forgotPassword.continue') }}
          </button>
        </vform>
      </div>
    </div>
  </div>
</template>

<script>
import DesktopHeader from './desktop-header.vue'
import ForgotPasswordViewController from './mixins/forgotpass-view.controller'
import EmailInput from './components/email-input.vue'

export default {
  components: {
    DesktopHeader,
    EmailInput
  },
  mixins: [ForgotPasswordViewController]
}
</script>
