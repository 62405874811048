<template>
  <div id="chat-bubble" class="chat-bubble" :class="{ 'me': self }">
    <div class="text">
      <div>{{ message }}</div>
      <div v-if="file !== null"
           class="file-upload container--flex container--flex-align-item-center clickable"
           @click="downloadFile(file)">
        <i class="material-icons">{{ isImage(file.type) ? 'image' : 'insert_drive_file' }}</i>
        <div class="file-info left-align flex-1">
          <span class="file-name">{{ file.original_file_name }}</span><br>
          <span class="file-size">{{ prettifySize(file.file_size) }}</span>
        </div>
      </div>
    </div>
    <div class="status">
      <small v-if="self && seen" class="seen pink-text left">
        <i class="material-icons valign-middle">check</i> {{ $translate('events.where_is.seen') }}
      </small>
      <small class="time-ago right">{{ timeAgo }}</small>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    message: {
      type: String,
      required: true
    },
    createdAt: {
      type: Number,
      required: true
    },
    seen: {
      type: Boolean,
      default: false
    },
    sentBy: {
      type: Number,
      required: true
    },
    file: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      currentTime: 'getCurrentTime',
      user: 'getUser'
    }),
    absoluteCreatedAt () {
      return Math.abs(this.createdAt)
    },
    timeAgo () {
      const totalTimeDiff = this.absoluteCreatedAt + this.timeElapsed++

      // Subtract elapsed time in seconds from server to the system time
      // to get the projected elapsed time of the app.
      const createdAt = this.currentTime.clone().subtract(totalTimeDiff, 'seconds')

      // Then compare it against the system time to get the correct time difference.
      return createdAt.from(this.currentTime)
    },
    self () {
      return this.sentBy === this.user.id
    }
  },
  methods: {
    isImage (type) {
      if (type) { return type.indexOf('image/') >= 0 }
      return false
    },
    prettifySize (size) {
      if (size) {
        return `${size} KB`
      }
      return ''
    },
    downloadFile (file) {
      window.open(file._url, '_blank')
    }
  },
  data () {
    return {
      timeElapsed: 0
    }
  }
}
</script>
