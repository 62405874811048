var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isViewReady
    ? _c("div", { attrs: { id: "event-rate" } }, [
        _c("div", { staticClass: "content" }, [
          _c("p", { staticClass: "header" }, [
            _c("span", { staticClass: "please-rate" }, [
              _vm._v(_vm._s(_vm.$translate("events.rate.please_rate")))
            ]),
            _c("br"),
            _vm._v(" "),
            _c("span", { staticClass: "grey-text" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$translate("events.rate.please_rate_client", {
                      client_name: _vm.selectedEvent.client.name
                    })
                  ) +
                  "\n      "
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                background: "#000",
                color: "#FFF",
                padding: "5px",
                "font-size": "14px",
                width: "135px",
                "border-radius": "4px"
              }
            },
            [
              _c("strong", { staticClass: "center-countdown countdown" }, [
                _vm._v("\n        " + _vm._s(_vm.meetingCountdown) + "\n      ")
              ])
            ]
          ),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "rate-btns" }, [
            _c(
              "a",
              {
                staticClass: "rate",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.selectedRate(1)
                  }
                }
              },
              [_c("i", { staticClass: "material-icons" }, [_vm._v("thumb_up")])]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "rate",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.selectedRate(0)
                  }
                }
              },
              [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("thumb_down")
                ])
              ]
            )
          ]),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "note" }, [
            _c("h5", { staticClass: "black-text" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$translate("events.rate.be_honest_and_fair")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "grey-text" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$translate("events.rate.be_honest_and_fair_explanation")
                  ) +
                  "\n      "
              )
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }