<template>
    <div id="photo-replace-modal" class="modal left-align">
        <div class="modal-content">
            <h4 class="modal-title">
              {{ $translate('photos.v2.replace_popup.title') }}
            </h4>
            <p class="grey-text text-darken-1">
              {{ $translate('photos.v2.replace_popup.content') }}
            </p>
            <div class="refresh-icon"></div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-raised waves-effect waves-light display-block btn-krypton-pink waves-effect waves-light" @click="confirm">
                {{ $translate('photos.v2.replace_popup.ok') }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        confirm($e) {
            this.$emit('confirm', $e);
        }
    }
}
</script>
