<template>
  <div class="chat-bubble-support chat-bubble reverse" :class="{ 'me': self }">
    <div class="chat-bubble__container" :class="{'chat-bubble__container--self': self}">
      <div class="bubble-texts">
        <div class="text">
          <div class="text-message">{{ message }}</div>
          <div v-for="(file, idx) in attachments" :key="idx">
            <div class="file-upload container--flex container--flex-align-item-center clickable padding-y-md"
                 @click="downloadFile(file)">
              <img src="/img/support/image-icon-24x24.svg" alt="image icon">
              <div class="file-info left-align flex-1">
                <span class="file-name">{{ file.original_file_name }}</span><br>
                <span class="file-size">
                  {{ prettifySize(file.file_size) }} <small v-if="file.downloadProgress >= 0 && file.downloadProgress < 100">(Download progress: {{ file.downloadProgress }} %)</small>
                  <small v-if="file.downloadProgress === 100">(Download complete)</small>
                  <small v-if="file.downloadProgress === -100">(An error has occured)</small>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="status">
          <small v-if="self && seen" class="seen pink-text left">
            <i class="material-icons valign-middle">check</i> {{ $translate('events.where_is.seen') }}
          </small>
          <small class="time-ago">{{ timeAgo }}</small>
        </div>
      </div>
      <img v-if="self" class="bubble-icon" src="/img/support/me-icon.svg" alt="me icon">
      <img v-else class="bubble-icon" src="/img/support/admin-icon.svg" alt="me icon">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    message: {
      type: String,
      required: true
    },
    createdAt: {
      type: Object,
      required: true
    },
    seen: {
      type: Boolean,
      default: false
    },
    sentBy: {
      type: Number,
      required: true
    },
    attachments: {
      type: Array,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      currentTime: 'getCurrentTime',
      user: 'getUser'
    }),
    timeAgo () {
      return this.createdAt.from(this.currentTime)
    },
    self () {
      return this.sentBy === this.user.id
    }
  },
  methods: {
    isImage (type) {
      if (type) { return type.indexOf('image/') >= 0 }
      return false
    },
    prettifySize (size) {
      if (size) {
        return `${size} KB`
      }
      return ''
    },
    downloadFile (file) {
      file.downloadProgress = 0

      const config = {
        responseType: 'blob',
        onDownloadProgress: function (progressEvent) {
          const fileSize = file.file_size * 1000 // File size in bytes
          const currentProgress = Math.round((progressEvent.loaded / fileSize) * 100)

          file.downloadProgress = (currentProgress > 100) ? 100 : currentProgress
        }
      }

      window.api.get({ url: file._url, config: config },
        (success) => {
          file.downloadProgress = 100 // Sometimes it gets stuck to 98% but it already fetched, so we force it to 100% to display correct message.

          const fileURL = URL.createObjectURL(success.data)
          let win = null

          if ($h.isPWAMode) {
            win = window.open(fileURL, '_top')
          } else {
            win = window.open(fileURL, '_blank')
          }

          win.focus()
        },
        (err) => {
          file.downloadProgress = -100
          this.$notifications.toast(err.response.message, 3000)
        })
    }
  },
  data () {
    return {
      timeElapsed: 0
    }
  }
}
</script>
