var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasSelected
    ? _c(
        "div",
        {
          staticClass: "country-picker",
          class: { focused: _vm.focused },
          attrs: { id: _vm.component_id }
        },
        [
          _c(
            "div",
            {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.show($event)
                }
              }
            },
            [
              _c("span", { staticClass: "label active" }, [
                _vm._v(_vm._s(_vm.$t("country")))
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "selected",
                  attrs: { "data-test": "selected-country" }
                },
                [
                  _c("img", {
                    staticClass: "selected-flag",
                    attrs: { src: _vm.selected.flag }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "selected-dial-code" }, [
                    _vm._v("+" + _vm._s(_vm.selected.callingCode))
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.showCaret
                ? _c("span", { staticClass: "material-icons" }, [
                    _vm._v("expand_more")
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "focus-item-wrapper" },
            [
              _c("autocomplete", {
                ref: "countrypicker",
                class: { "autocomplete--registration": _vm.onRegistration },
                attrs: {
                  type: "dropdown",
                  "value-key": "alpha2Code",
                  "search-key": "name",
                  "show-on-empty": true,
                  label: _vm.$t("country"),
                  options: _vm.countries,
                  "options-callback": _vm.countrySearch,
                  "is-shown": _vm.isShown
                },
                on: {
                  focus: _vm.onPickerFocus,
                  blur: _vm.onPickerBlur,
                  exitfullscreen: _vm.exitFullScreen,
                  select: _vm.onSelect,
                  close: _vm.close
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(option) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "suggestion-item",
                              attrs: { "data-test": "dropdown-item" }
                            },
                            [
                              _c("img", {
                                staticClass: "inline-block-middle",
                                staticStyle: {
                                  width: "24px",
                                  "margin-right": "8px"
                                },
                                attrs: {
                                  src: option.value.flag,
                                  loading: "lazy"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "inline-block-middle" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$i18n.locale === "de" &&
                                          "deName" in option.value
                                          ? option.value.deName
                                          : option.value.name
                                      ) +
                                      "\n            (+" +
                                      _vm._s(option.value.callingCode) +
                                      ")\n          "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  121362830
                )
              })
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }