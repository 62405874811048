var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "multi-location-event-address",
      class: {
        "multi-location-event-address--event-details": _vm.isEventDetails
      }
    },
    [
      _vm.hotelName && !_vm.isPrivatePlace
        ? _c("div", [_vm._v(_vm._s(_vm.hotelName))])
        : _vm._e(),
      _vm._v(" "),
      _vm.hotelAddress
        ? _c("div", [_vm._v(_vm._s(_vm.hotelAddress))])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }