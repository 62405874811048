import { responseCodes } from '../constants/ReponseCodes'

export const breakdownAxiosResponse = (payload) => {
  let code = 500
  let headers = []
  let data = {}
  let method = ''
  let path = window.location.pathname
  let params = JSON.stringify(Object.fromEntries(new URLSearchParams(window.location.search).entries()))

  if (payload) {
    headers = payload.headers
    code = payload.status
    path = payload.config.url
    params = JSON.stringify(Object.fromEntries(new URLSearchParams(payload.config.url.split('?')[1]).entries()))
    data = payload.data
    method = payload.config.method
  }
  return { code, headers, path, params, data, method }
}

/**
 * @param payload - the axios/api payload
 * @returns func - the action to be executed
 */
export const getRedirectAction = (code, headers, method) => {
  if (code === responseCodes.BLOCKED) {
    return () => {
      window.location.replace(headers.location)
    }
  }

  return () => {
    // DO NOTHING
  }
}
