<template>
  <div>
    <template>
      <div v-if="photos.length"
           class="photos-register"
           :class="{
             'bottom-spacer': photos.length >= 2
           }"
      >
        <div
          v-for="(photo, i) in photos"
          :key="`photo-item-${i}`"
          class="photo margin-bottom-sm"
          :style="createBG(photo._url)"
          @click.self="previewPhoto(photo)"
        >
          <div v-if="mainPhotoTextDisplay(photo)" class="primary-photo-indicator" />
          <!-- <div v-if="isPhotoPrimary(photo)" class="delete-btn" @click.prevent="confirmUploadMain" /> -->
          <!-- <div v-if="!isPhotoPrimary(photo)" class="delete-btn" @click.prevent="confirmDelete(photo)" /> -->
        </div>
        <div v-if="!isOtherPhotosMaxed" class="photo margin-bottom-sm empty">
          <upload-other-photo
            :is-register-mode="isRegisterMode"
            :is-other-photo-empty="isOtherPhotoEmpty"
          />
        </div>
      </div>

      <template v-else>
        <div class="description"><strong>{{ $translate('photos.primary_tip') }}</strong></div>
        <upload-main-photo class="margin-bottom-lg" :is-register-mode="isRegisterMode" />
      </template>

      <vform ref="deleteForm" @submit="deletePhoto" />
    </template>
  </div>
</template>

<script>
import UploadMainPhoto from './upload-main-photo.vue'
import UploadOtherPhoto from './upload-other-photo.vue'
const MAX_OTHER_PHOTOS = 5
const PhotosProvider = $h.getProvider('PhotosProvider')

export default {
  components: {
    UploadMainPhoto,
    UploadOtherPhoto
  },
  data () {
    return {
      photos: []
    }
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    isRegisterMode () {
      return this.$route.name.indexOf('register') >= 0
    },
    isOtherPhotoEmpty () {
      return this.user.profiles.photos.others.length === 0
    },
    isOtherPhotosMaxed () {
      return this.user.profiles.photos.others.length >= MAX_OTHER_PHOTOS
    }
  },
  mounted () {
    this.initPhotos()
  },
  methods: {
    /** Action methods **/
    mainPhotoTextDisplay (photo) {
      let result = false

      if (this.isPhotoPrimary(photo)) {
        result = true
      } else if (this.isPhotoPrimary(photo) && !this.user.profiles.photos.primary) {
        result = true
      }
      return result
    },
    uploadMainPhoto (next) {
      var file = this.$refs.uploadMainFormFile.files[0]
      PhotosProvider.uploadMainPhoto(file, this.isRegisterMode)
        .then((res) => {
          const otherPhotosRoute = {
            name: 'register-photos',
            params: { type: 'others' }
          }

          this.$router.push({
            name: this.isRegisterMode ? 'register-photos-crop' : 'photos-anonimize',
            params: {
              photo: res.data,
              return: this.isRegisterMode
                ? otherPhotosRoute
                : { name: 'photos' },
              cancel: this.isRegisterMode
                ? { name: 'register-photos', params: { type: 'primary' } }
                : { name: 'photos' }
            }
          })
        })
        .catch((err) => {
          if (_.isEmpty(err.data)) {
            $h.photoErrorModal(err.message)
          } else {
            $h.photoErrorModal(err.data)
          }
        })
        .finally(() => {
          if (this.$refs.uploadMainFormFile) {
            this.$refs.uploadMainFormFile.value = ''
          }
          next()
        })
    },
    confirmUploadMain () {
      var title = this.$translate('photos.upload_main_photo')
      var content = this.$translate('photos.confirm_replace_main')

      $h.newModalConfirm(title, content, ($e) => {
        this.$refs.uploadMainFormFile.click()
      })
    },
    uploadPhoto (next) {
      var file = this.$refs.uploadFormFile.files[0]
      PhotosProvider.uploadPhoto(file, this.isRegisterMode)
        .then((res) => {
          this.$router.push({
            name: this.isRegisterMode ? 'register-photos-crop' : 'photos-crop',
            params: {
              photo: res.data,
              return: this.isRegisterMode
                ? { name: 'register-photos', params: { type: 'others' } }
                : { name: 'photos' },
              cancel: this.isRegisterMode
                ? { name: 'register-photos', params: { type: 'others' } }
                : { name: 'photos' }
            }
          })
        })
        .catch((err) => {
          if (_.isEmpty(err.data)) {
            $h.photoErrorModal(err.message)
          } else {
            $h.photoErrorModal(err.data)
          }
        })
        .finally(() => {
          if (this.$refs.uploadFormFile) {
            this.$refs.uploadFormFile.value = ''
          }
          next()
        })
    },
    previewPhoto (photo) {
      if (this.isRegisterMode) { this.$router.push({ name: 'register-photos-preview', query: { url: photo._url } }) } else { this.$router.push({ name: 'photos-preview', query: { url: photo._url } }) }
    },
    deletePhoto (next, params) {
      PhotosProvider.deletePhoto(params.photo_id)
        .then((res) => {
          var index = _.findIndex(this.photos, { id: params.photo_id })

          if (index) {
            this.$store.commit('removePhoto', params)
            this.photos.splice(index, 1)
          }
        })
        .catch((err) => {
          this.$notifications.toast(err.message, 12000)
        })
        .finally(() => {
          next()
        })
    },
    confirmDelete (photo) {
      var title = this.$translate('photos.deleting_photo')
      var content = this.$translate('photos.confirm_delete_photo')

      $h.newModalConfirm(title, content, ($e) => {
        this.$refs.deleteForm.setData({ photo_id: photo.id })
        this.$refs.deleteForm.submit()
      })
    },
    /** Photo related methods **/
    createBG (url) {
      return `background-image: url(${url})`
    },
    isPhotoPrimary (photo) {
      if (this.user.profiles.photos.primary) {
        return photo.id == this.user.profiles.photos.primary.id
      } else if (this.user.profiles.photos.other_primary) {
        return photo.id == this.user.profiles.photos.other_primary.id
      }

      return false
    },
    getPhotoClass (photo) {
      switch (photo.status) {
        case 0:
          return 'yellow darken-3 white-text'
        case 1:
          return 'krypton-green white-text'
        case 2:
          return 'krypton-pink white-text'
      }
    },
    getPhotoStatus (photo) {
      switch (photo.status) {
        case 0:
          return this.$translate('photos.pending')
        case 1:
          return this.$translate('photos.online')
        case 2:
          return this.$translate('photos.rejected')
      }
    },
    /** Initialization methods **/
    initPhotos () {
      if (this.user.profiles.photos.primary) { this.photos.push(this.user.profiles.photos.primary) }

      if (this.user.profiles.photos.other_primary) { this.photos.push(this.user.profiles.photos.other_primary) }

      if (!this.user.profiles.photos.others || !this.user.profiles.photos.others.length) { return }

      for (var i = 0; i < this.user.profiles.photos.others.length; i++) { this.photos.push(this.user.profiles.photos.others[i]) }
    },
    uploadFormSubmit () {
      this.$refs.uploadForm.submit()
    },
    uploadMainFormSubmit () {
      this.$refs.uploadMainForm.submit()
    }
  }
}
</script>
