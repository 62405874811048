var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "setting" } },
    [
      _c("md-new-navbar"),
      _vm._v(" "),
      _c("div", { staticClass: "menu-container" }, [
        _c("div", { staticClass: "menu-content" }, [
          _c("h2", [_vm._v(_vm._s(_vm.$translate("setting.setting_title")))]),
          _vm._v(" "),
          _c("ul", { staticClass: "menus" }, [
            _c(
              "li",
              {
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.gotoPage("account-setting")
                  }
                }
              },
              [
                _c("a", { attrs: { href: "#" } }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$translate("setting.account_btn")))
                  ])
                ]),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: require("@/features/other-menu/assets/menu-icon.svg"),
                    alt: "chevron right icon"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _vm.isVideoDateEnabled
              ? _c(
                  "li",
                  {
                    class: { locked: _vm.isFeatureLocked },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.gotoPage("video-date-setting", true)
                      }
                    }
                  },
                  [
                    _c("a", { attrs: { href: "#" } }, [
                      _c("div", [
                        _vm.isFeatureLocked
                          ? _c("img", {
                              attrs: {
                                src: require("./assets/video-date-locked-setting.svg"),
                                alt: "manage profile icon"
                              }
                            })
                          : _c("img", {
                              attrs: {
                                src: require("./assets/video-date-setting.svg"),
                                alt: "manage profile icon"
                              }
                            })
                      ]),
                      _vm._v(" "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$translate("video-date.settings.video_date_btn")
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: require("@/features/other-menu/assets/menu-icon.svg"),
                        alt: "chevron right icon"
                      }
                    })
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "li",
              {
                class: { locked: _vm.isFeatureLocked },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.gotoPage("private-place-setting", true)
                  }
                }
              },
              [
                _c("a", { attrs: { href: "#" } }, [
                  _c("div", [
                    _vm.isFeatureLocked
                      ? _c("img", {
                          attrs: {
                            src: require("./assets/private-place-locked-setting.svg"),
                            alt: "manage profile icon"
                          }
                        })
                      : _c("img", {
                          attrs: {
                            src: require("./assets/private-place-setting.svg"),
                            alt: "manage profile icon"
                          }
                        })
                  ]),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.$translate(
                          "private-place.settings.private_place_btn"
                        )
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: require("@/features/other-menu/assets/menu-icon.svg"),
                    alt: "chevron right icon"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _vm.isMultiLocationSupported
              ? _c(
                  "li",
                  {
                    class: { locked: !_vm.isMultiLocationSupported },
                    attrs: { "data-test": "multiLocationSettingBtn" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.gotoMultilocation($event)
                      }
                    }
                  },
                  [
                    _c("a", { attrs: { href: "#" } }, [
                      _c("div", [
                        !_vm.isMultiLocationSupported
                          ? _c("img", {
                              attrs: {
                                src: require("./assets/multi-city-locked-setting.svg"),
                                alt: "manage profile icon"
                              }
                            })
                          : _c("img", {
                              attrs: {
                                src: require("./assets/multi-city-setting.svg"),
                                alt: "manage profile icon"
                              }
                            })
                      ]),
                      _vm._v(" "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.$translate("setting.multi_location_btn"))
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: require("@/features/other-menu/assets/menu-icon.svg"),
                        alt: "chevron right icon"
                      }
                    })
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "li",
              {
                class: { locked: _vm.isFeatureLocked },
                attrs: { id: "krypton-connect-btn" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.gotoPage("krypton-connect-setting", true)
                  }
                }
              },
              [
                _c("a", { attrs: { href: "#" } }, [
                  _c("div", [
                    _vm.isFeatureLocked
                      ? _c("img", {
                          attrs: {
                            src: require("./assets/krypton-connect-locked-setting.svg"),
                            alt: "manage profile icon"
                          }
                        })
                      : _c("img", {
                          attrs: {
                            src: require("./assets/krypton-connect-setting.svg"),
                            alt: "manage profile icon"
                          }
                        })
                  ]),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.$translate(
                          "inbox.settings.krypton_connect_setting_btn"
                        )
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: require("@/features/other-menu/assets/menu-icon.svg"),
                    alt: "chevron right icon"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                class: { locked: _vm.isFeatureLocked },
                attrs: { id: "language-setting-btn" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.gotoPage("language-setting")
                  }
                }
              },
              [
                _c("a", { attrs: { href: "#" } }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.$translate("setting.language_setting_btn"))
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: require("@/features/other-menu/assets/menu-icon.svg"),
                    alt: "chevron right icon"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _vm.canShowVipPhotos
              ? _c(
                  "li",
                  {
                    class: { locked: _vm.isFeatureLocked },
                    attrs: { "data-test": "vipPhotosSettingBtn" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.gotoPage("vip-photos-setting", true)
                      }
                    }
                  },
                  [
                    _c("a", { attrs: { href: "#" } }, [
                      _c("div", [
                        _vm.isFeatureLocked
                          ? _c("img", {
                              attrs: {
                                src: require("./assets/vip-photos-locked-setting.svg"),
                                alt: "manage profile icon"
                              }
                            })
                          : _c("img", {
                              attrs: {
                                src: require("./assets/vip-photos-setting.svg"),
                                alt: "manage profile icon"
                              }
                            })
                      ]),
                      _vm._v(" "),
                      _c("strong", [_vm._v("VIP Photos")])
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: require("@/features/other-menu/assets/menu-icon.svg"),
                        alt: "chevron right icon"
                      }
                    })
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.canShowLegalTerms
              ? _c(
                  "li",
                  {
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.gotoPage("legal-terms-conditions", false)
                      }
                    }
                  },
                  [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._m(2),
                      _vm._v(" "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.$translate("setting.legal_setting_title"))
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: require("@/features/other-menu/assets/menu-icon.svg"),
                        alt: "chevron right icon"
                      }
                    })
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "build-version" }, [
            _vm._v(
              "Build version: " + _vm._s(_vm.APP_VERSION.replace(/\"/gi, ""))
            )
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "logout-link",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.logout($event)
                }
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$translate("app.logout.link")) +
                  "\n      "
              )
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("./assets/account-setting.svg"),
          alt: "manage profile icon"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("./assets/language-setting.svg"),
          alt: "language setting icon"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("./assets/legal-terms-setting.svg"),
          alt: "manage profile icon"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }