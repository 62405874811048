<template>
  <div id="vip-photos-setting">
    <md-navbar
      :go="-1"
      :brand="$translate('sidenav.settings')"
      icon="arrow_back"
    />
    <div id="content" class="container">
      <h1 class="title" data-test="title">{{ $translate('vip-photos.settings.title') }}</h1>
      <p class="description" data-test="description">{{ $translate('vip-photos.settings.description') }}</p>
      <div class="switch-input margin-bottom-md">
        <span data-test="label">{{ $translate('vip-photos.settings.title') }}</span>
        <div class="switch" data-test="toggle">
          <label>
            <input
              id="switch"
              v-model="isEnabled"
              type="checkbox"
              :disabled="isRequesting"
            >
            <span class="lever" />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { toggleVipPhotosSetting } from '@/features/vip-photos/services/VipPhotosServices.js'

export default {
  data: () => ({
    isRequesting: false,
    isEnabledCopy: false,
    isEnabled: false
  }),
  computed: {
    ...mapGetters({
      vipPhotosEnabled: 'getVipPhotosEnabled'
    })
  },
  watch: {
    isEnabled (newVal, oldVal) {
      if (this.isEnabled === this.isEnabledCopy) return

      // if enabling call api immediately
      if (oldVal === false && newVal === true) {
        this.doActivate()
        return
      }

      // else show disabling popup first
      const mTitle = this.$translate('vip-photos.settings.disabling.title')
      const mContent = this.$translate('vip-photos.settings.disabling.explanation_text')
      const confirmText = this.$translate('vip-photos.settings.disabling.disable')
      const cancelText = this.$translate('vip-photos.settings.disabling.cancel')
      const onConfirm = this.doDeactivate

      $h.newCustomModalConfirm({
        title: mTitle,
        content: mContent,
        cancelText,
        confirmText: confirmText
      }, onConfirm, this.onConfirmationDecline)
    }
  },
  mounted () {
    this.isEnabledCopy = this.isEnabled = this.vipPhotosEnabled
  },
  methods: {
    onConfirmationDecline () {
      this.isEnabled = !this.isEnabled
    },
    doActivate () {
      this.isRequesting = true
      this.isEnabledCopy = true

      this.$store.commit('setVipPhotosEnabled', 1)

      toggleVipPhotosSetting()
        .finally(() => {
          this.isRequesting = false
        })
    },
    doDeactivate () {
      this.isRequesting = true
      this.isEnabledCopy = false

      this.$store.commit('setVipPhotosEnabled', 0)

      toggleVipPhotosSetting()
        .finally(() => {
          this.isRequesting = false
        })
    }
  }
}
</script>

<style lang="scss">
@import "./vip-photos-setting.style";
</style>
