<template>
  <div id="events">
    <div
      id="content"
      class="container"
    >
      <lesson-shortcut v-model="LESSONS.DATES" translation-key="dates" />
      <h1 class="title">
        {{ $translate('events.title') }}
      </h1>

      <p
        v-if="hasNoEvents"
        class="margin-bottom-lg note"
      >
        <small>{{ $translate('events.empty_event.note') }}</small>
      </p>
      <div v-if="isFetchingEvents">
        {{ $translate('events.fetching_events') }}
      </div>

      <!-- Pending & Reactivated Events -->
      <event-card
        v-for="(evt, idx) in pendingReactivatedEvent"
        :key="idx + 1"
        :idx="idx"
        :payload="evt"
      />
      <!-- Other Events -->
      <div
        v-for="(month, mIdx) in groupByOtherEvents"
        :key="mIdx + 1"
        class="other-events"
      >
        <event-card
          v-for="(evt, eIdx) in month"
          :key="eIdx + 1"
          :idx="eIdx"
          :payload="evt"
        />
      </div>

      <div
        v-if="!hasNoEvents"
        class="card-controls grey-text margin-top-md margin-bottom-md"
      >
        <div class="card-date left-align">
          <small>{{ $translate('events.empty_event.tip') }}</small>
        </div>
      </div>

      <krypton-connect-promo-card
        v-if="shouldDisplayConnectPromo"
        :item="promo"
      />
      <private-place-promo-card
        v-if="shouldDisplayPrivatePlacePromo"
      />
      <multi-location-promo-card
        v-if="shouldDisplayMultiLocationPromo"
      />

      <empty-event />
    </div>
    <video-shortcut v-model="LESSONS.DATES" />
    <fixed-menu />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { LITE_EVENTS } from '@/features/lite/constants'

import promoMixin from '@/features/private-place/mixins/promo.js'
import EventCard from './event-card'

import LessonShortcut from '@/features/onboarding-lesson/components/LessonShortcut.vue'
import VideoShortcut from '@/features/onboarding-lesson/components/VideoShortcut.vue'
import { LESSONS } from '@/features/onboarding-lesson/constants'

import FixedMenu from '@components/v2/utilities/fixed-menu.vue'

export default {
  name: 'Events',
  components: {
    EventCard,
    LessonShortcut,
    VideoShortcut,
    FixedMenu
  },
  mixins: [
    promoMixin
  ],
  data: () => ({
    isAllEvents: false,
    promo: {
      item: 'news_promo',
      type: 'promotional',
      image: 'img/krypton-connect/promo-image-icon.svg'
    },
    LESSONS
  }),
  methods: {
    evtDate (evt) {
      return evt._meeting_start_at.format('MMM YYYY')
    },
    isEventNeedsFeedback (evt) {
      return (
        evt.status === TICKET_STATUS.POST_MEETING &&
        evt._feedback_metadata.expiration_in_seconds > 0 &&
        evt._feedback_metadata.show === 1
      )
    },
    isConfirmedEvent (evt) {
      return (
        (evt._event_status === EVENT_STATUS.CONFIRMED ||
          evt._event_status === EVENT_STATUS.CONFIRMED_PREPARING ||
          evt._event_status === EVENT_STATUS.CONFIRMED_READY) &&
        evt.status !== TICKET_STATUS.CANCELLED
      )
    },
    isUnseenCancelledEvent (evt) {
      return (
        evt.status === TICKET_STATUS.CANCELLED &&
        evt._event_status !== EVENT_STATUS.EXPIRED &&
        evt.event_seen === 0
      )
    },
    isReactivatedExpiredEvent (evt) {
      return (
        evt.status === TICKET_STATUS.CANCELLED &&
        evt._event_status === EVENT_STATUS.EXPIRED &&
        evt.reactivation_status === REACTIVATION_STATUS.REACTIVATED
      )
    },
    isUnseenExpiredEvent (evt) {
      return (
        evt.status === TICKET_STATUS.CANCELLED &&
        evt._event_status === EVENT_STATUS.EXPIRED &&
        evt.reactivation_status === REACTIVATION_STATUS.CANNOT_REACTIVATE &&
        evt.event_seen === 0
      )
    }
  },
  computed: {
    ...mapGetters({
      reactivatedEvent: 'events/getReactivatedExpiredEvent',
      isFetchingEvents: 'events/getIsFetching',
      pendingEvent: 'events/getPendingEvent',
      events: 'events/getSortedEvents',
      isCandidateProfile: 'isCandidateProfile',
      user: 'getUser'
    }),

    otherEvents () {
      return this.events.filter(
        e => this.isConfirmedEvent(e) ||
             this.isUnseenCancelledEvent(e) ||
             this.isEventNeedsFeedback(e) ||
             this.isUnseenExpiredEvent(e)
      )
    },
    groupByOtherEvents () {
      return $h.groupBy(this.otherEvents, this.evtDate)
    },
    pendingReactivatedEvent () {
      const result = []

      if (this.pendingEvent !== null) {
        result.push(this.pendingEvent)
      }
      if (this.reactivatedEvent !== null) {
        result.push(this.reactivatedEvent)
      }

      return result
    },
    hasNoEvents () {
      return (
        this.pendingEvent === null &&
        this.reactivatedEvent === null &&
        this.otherEvents.length === 0 &&
        this.pendingReactivatedEvent.length === 0
      )
    }
  },
  watch: {
    isCandidateProfile () {
      if (this.isCandidateProfile) {
        this.$router.replace({ name: 'dashboard' })
        $h.EventBus.$emit(LITE_EVENTS.ONFEATURELOCK)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#events {
  #content.container {
    margin-bottom: 100px;
  }
}
</style>
