var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "affiliate-received" } },
    [
      !_vm.isLoading
        ? [
            _vm.successfulReferrals.length === 0
              ? _c(
                  "div",
                  {
                    staticClass: "affiliate-placeholder",
                    attrs: { "data-test": "affiliate-placeholder" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/img/affiliate/affiliate-placeholder.svg",
                        alt: "no-affiliate-placeholder"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "affiliate-placeholder__text" }, [
                      _vm._v(_vm._s(_vm.$t("tabs.received.placeholder")))
                    ])
                  ]
                )
              : _vm._l(_vm.successfulReferrals, function(item) {
                  return _c(
                    "div",
                    {
                      key: "succesful-referrals-" + item.name,
                      staticClass: "affiliate-item",
                      attrs: { "data-test": "successful-referrals" }
                    },
                    [
                      _c("div", { staticClass: "affiliate-item__icon" }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "affiliate-item__text",
                        domProps: { innerHTML: _vm._s(_vm.getEntryText(item)) }
                      })
                    ]
                  )
                })
          ]
        : [
            _c("infinite-loading", {
              ref: "infiniteLoader",
              attrs: { direction: "top", spinner: "spiral" }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }