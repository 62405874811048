<template>
  <a :href="href" :class="{ 'router-link-active': isActive }" class="router-link" @click.prevent="push">
    <slot />
  </a>
</template>

<script>
export default {
  props: [
    'to'
  ],
  data () {
    return {
      pathname: this.$router.resolve(location.pathname).href
    }
  },
  computed: {
    href () {
      return this.$router.resolve(this.to).href
    },
    isActive: {
      get () {
        return this.removeTrailingSlash(this.pathname) == this.removeTrailingSlash(this.href)
      },
      set (newval) {
        this.pathname = newval
      }
    }
  },
  watch: {
    $route (newval, oldval) {
      this.isActive = newval.fullPath
    }
  },
  methods: {
    push () {
      $('#sidenav').sideNav('hide')
      this.$router.push(this.to)
    },
    removeTrailingSlash (url) {
      return url.replace(/\/$/, '')
    }
  }
}
</script>
