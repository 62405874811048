<template>
  <div id="account-setting" class="email-verify">
    <template v-if="!isVerified">Verifying email...</template>
    <div v-if="isVerified" class="email-verify__content">
      <img src="/img/icons/k-logo-new.svg" alt="krypton logo">
      <h2 class="email-verify__title">Success!</h2>
      <img width="60" src="/img/icons/ic-check-circle-v2.svg" alt="check icon">
      <p class="email-verify__subtitle">Now you can return to the App</p>
      <p class="email-verify__text">Your new email address has been saved. Please go back to KryptonApp now.</p>
    </div>
  </div>
</template>

<script>
import { tooltipPlugin } from '@/features/super-provider/plugins/vue-custom-tooltip.js'
const SettingsProvider = $h.getProvider('SettingsProvider')

export default {
  name: 'EmailSetting',
  data: function () {
    return {
      isVerified: false
    }
  },
  mounted () {
    if (this.$route.query.t && this.$route.query.e) {
      const verificationCode = this.$route.query.t
      const email = this.$route.query.e.replace(' ', '+')

      SettingsProvider
        .verifyEmailChange(email, verificationCode)
        .then((res) => {
          this.isVerified = true

          setTimeout(() => {
            tooltipPlugin.toastV2(res.message, 5000, 'bottom')
            this.$router.replace({ name: 'account-setting' })
          }, 5000)
        }).catch((err) => {
          this.$notifications.toast(err.message)
          this.$router.replace({ name: 'account-setting' })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.email-verify {
  width: 100dvw;
  height: 100dvh;
  margin: 0 auto;
  color: #2F3740;
  background-color: white;

  .email-verify__content {
    max-width: 343px;
    margin: 0 auto;
    padding-top: 100px;
    text-align: center;
  }

  .email-verify__title {
    font-size: 32px;
  }

  .email-verify__subtitle {
    font-family: 'Proxima Nova Semibold';
    font-size: 18px;
  }

  .email-verify__text {
    font-size: 18px;
  }
}
</style>
