/**
 * Modal priority level.
 *
 * @constant PRIORITY_LEVEL
*/
export const PRIORITY_LEVEL = {
  HIGH: 3,
  MED: 2,
  LOW: 1,
}

/**
 * Class model representing for ModalOptions.
 *
 * @class ModalOptions
*/
export class ModalOptions {

  /**
   * @constructor
  */
  constructor(dismissible, onOpen) {

    /**
     * @property {Boolean} dismissible - Option flag to allow modal to be closed when clicking the background.
    */
    this.dismissible = (dismissible) ? dismissible : false;

    /**
     * @property {Function} onOpen - Function callback to trigger on `onOpen` event.
    */
    this.onOpen = (onOpen) ? onOpen : () => {};

  }

}


/**
 * Class model representing for Modal.
 *
 * @class Modal
*/
export class Modal {

  /**
   * @constructor
   *
   * @param {Any} id - The assigned identifier for the modal.
   * @param {String} name - The modal name to instantiate.
   * @param {Function} callback - Function callback to trigger when `confirm` event is triggered.
   * @param {Object} propsData - The payload to pass to the modal component.
   * @param {ModalOptions} opts - The modal settings
   * @param {String[]} excludedRoutes - List of routes to prevent showing the modal.
   * @param {Number} priority - Priority value of modal
  */
  constructor(id, name, callback, propsData, opts, excludedRoutes, priority) {

    /**
     * @property {Any} id - A unique identifier for the modal.
    */
    this.id = id;

    /**
     * @property {String} name - Name of registered Vue modal to instantiate.
    */
    this.name = name;

    /**
     * @property {Function} callback - Function callback to trigger on `confirm` event.
    */
    this.callback = (callback) ? callback : () => {};

    /**
     * @property {Object} propsData - The data payload to pass to the modal.
    */
    this.propsData = (propsData) ? propsData : {};

    /**
     * @property {ModalOptions} opts - Modal settings
    */
    this.opts = (opts) ? opts : new ModalOptions();

    /**
     * @property {String[]} excludedRoutes - List of application paths to prevent showing the modal.
    */
    this.excludedRoutes = (excludedRoutes) ? excludedRoutes : [];

    /**
     * @property {Number} priority - Priority value
    */
    this.priority = (priority) ? priority : PRIORITY_LEVEL.LOW;

  }

}
