import { buildParam } from '@/services/ApiService'
export const nsfwPreferencesPopupSeen = '/v2/popup/nsfw-preferences/seen'
export const nsfwPreferences = '/v2/nsfw-preferences'

export const putProviderPreferencesSettingsSeen = (payload = {}) => {
  const url = buildParam(`${process.env.KRYPTON_PROVIDER_API}${nsfwPreferencesPopupSeen}`)
  return new Promise((resolve, reject) => {
    window.api.put({ url, payload: payload },
      (success) => { resolve(success.data) },
      (err) => { reject(err.response.data) })
  })
}

export const fetchProviderPreferences = () => {
  const url = buildParam(`${process.env.KRYPTON_PROVIDER_API}${nsfwPreferences}`)
  return new Promise((resolve, reject) => {
    window.api.get({ url },
      (success) => { resolve(success.data.data) },
      (err) => { reject(err.response.data) })
  })
}

export const putProviderPreferencesSettings = (payload = {}) => {
  const url = buildParam(`${process.env.KRYPTON_PROVIDER_API}${nsfwPreferences}`)
  return new Promise((resolve, reject) => {
    window.api.put({ url, payload: payload },
      (success) => { resolve(success.data) },
      (err) => { reject(err.response.data) })
  })
}
