var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal left-align" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("h4", { staticClass: "modal-title" }, [
        _vm._v(_vm._s(this.$translate("events.rate.leave_title")))
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "grey-text text-darken-1" }, [
        _vm._v(_vm._s(this.$translate("events.rate.leave_note")))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn-flat waves-effect waves-dark",
          on: { click: _vm.confirm }
        },
        [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$translate("events.buttons.leave")) +
              "\n        "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "btn-flat waves-effect waves-dark krypton-pink-text modal-close"
        },
        [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$translate("events.buttons.stay_here")) +
              "\n        "
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }