import { mapGetters } from 'vuex'

import { LITE_EVENTS } from '@/features/lite/constants'
import { INBOX_FILTER } from '@/features/inbox/constants'
import privatePlacePromoMixin from '@/features/private-place/mixins/promo.js'

export default {
  data: () => ({
    filter: INBOX_FILTER.ALL
  }),
  mixins: [privatePlacePromoMixin],
  computed: {
    ...mapGetters({
      isCandidateProfile: 'isCandidateProfile'
    })
  },
  watch: {
    isCandidateProfile () {
      if (this.isCandidateProfile) {
        this.$router.replace({ name: 'dashboard' })
        $h.EventBus.$emit(LITE_EVENTS.ONFEATURELOCK)
      }
    },
    $route: {
      handler () {
        this.filter = this.$route.params.filter ?? INBOX_FILTER.ALL
      },
      deep: true
    }
  },
  mounted () {
    if (this.$route.params.filter) {
      this.filter = this.$route.params.filter
    }
    this.$store.commit('notification/SEEN_ALL')
  }
}
