var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container",
      attrs: { id: "content", "data-test": "multilocation__city-selection" }
    },
    [
      _c("bottomsheet", {
        attrs: { "is-shown": _vm.isShown },
        on: { close: _vm.closeBottomSheet },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("h2", {
                  staticClass: "bottom-sheet__title",
                  domProps: {
                    textContent: _vm._s(_vm.$t("citySelection.title"))
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "bottom-sheet__description" }, [
                  _c("h3", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("citySelection.description.title")
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("p", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("citySelection.description.content")
                      )
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cities" },
                  _vm._l(_vm.cities, function(city, key) {
                    return _c("multi-location-city-pill", {
                      key: key,
                      attrs: {
                        "city-name": city.name,
                        selected: city.selected
                      },
                      on: {
                        select: function($event) {
                          return _vm.selectCity(key)
                        }
                      }
                    })
                  }),
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c("register-stepper-v2", { attrs: { total: 2, current: 1 } }),
                _vm._v(" "),
                _c("div", { class: _vm.ctaContainerClass }, [
                  _c("div", {
                    staticClass: "select-count",
                    attrs: {
                      "data-test": "selectCount",
                      "data-value": _vm.selectCount
                    },
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("citySelection.selectedCitiesCount", {
                          count: _vm.selectCount
                        })
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("button", {
                    staticClass: "cta waves-effect",
                    attrs: {
                      "data-test": "city-selection-cta",
                      disabled: _vm.noCitiesSelected
                    },
                    domProps: { textContent: _vm._s(_vm.citySelectionCta) },
                    on: { click: _vm.submit }
                  })
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }