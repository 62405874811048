var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShown
    ? _c("div", { staticClass: "notice-modal" }, [
        _c("div", { staticClass: "notice-modal__container" }, [
          _c(
            "div",
            { staticClass: "notice-modal__content" },
            [
              _vm._t("content", [
                _c("h2", { staticClass: "notice-modal__title" }, [
                  _vm._v(_vm._s(_vm.title))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "notice-modal__text" }, [
                  _vm._v(_vm._s(_vm.text))
                ])
              ])
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "notice-modal__footer" },
            [
              _vm._t("footer", [
                _c("div", { staticClass: "notice-modal__confirm-cta" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.confirm($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.confirmText))]
                  )
                ])
              ])
            ],
            2
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }