import { mapGetters } from 'vuex'

import {
  INBOX_TYPE,
  INBOX_FILTER,
  INBOX_FILTER_VALUE,
  INBOX_EVENTS
} from '@/features/inbox/constants'
import {
  GetInboxMessages,
  GetInboxMessages2250,
  FetchFeedbacks,
  FetchFeedbacksA22250
} from '@/features/inbox/utils/KrptonConnectAPI'
import { initializeList } from '@/features/inbox/utils/helpers'

export default {
  data: () => ({
    isFetching: false,
    page: 1,
    feedbackspage: 1,
    messages: [],
    showLoadMore: false
  }),
  computed: {
    ...mapGetters({
      hasFeedbackUpdates: 'notification/GET_HAS_NEW_FEEDBACK',
      currentTime: 'getCurrentTime'
    }),
    isEmpty () {
      return this.messages.length === 0
    },
    isReviewFilter () {
      return this.filter === INBOX_FILTER.REVIEWS
    }
  },
  watch: {
    filter () {
      this.resetList()
    }
  },
  methods: {
    onClick (val) {
        this.page = 1;
        this.feedbackspage = 1;
        this.showLoadMore = false;
      if (this.isFetching) {
        this.$notifications.toast('Currently fetching data. Please wait.', 5000)
      } else {
        this.$emit('update:filter', val)
      }
    },
    infiniteHandler ($state) {
      this.fetchData()
        .then((data) => {
          if (this.isReviewFilter) {
            this.messages = this.messages.concat(data)
            $state.complete()
          } else {
            if (data.length) {
                this.messages = this.messages.concat(data)
                $state.complete()
            } else {
              this.showLoadMore = false
              $state.complete()
            }
          }
        })
    },
    loadMore () {
      this.showLoadMore = false
      this.fetchData()
        .then((data) => {
          if (this.isReviewFilter) {
            this.messages = this.messages.concat(data);
            if(data.length >= 25) {
              this.showLoadMore = true;
              this.page += 1;
              this.feedbackspage += 1;
            }
          } else {
            this.messages = this.messages.concat(data)
          }
        })
    },
    fetchData () {
      if (this.isReviewFilter) {
        return this.fetchFeedbacks()
      }
      return this.fetchMessages()
    },
    fetchMessages () {
      this.isFetching = true

      const orderBy = 'last_received_at:desc'
      const queryFilters = {
        page: this.page,
        order_by: orderBy
      }

      if (this.filter in INBOX_FILTER_VALUE) {
        queryFilters.query = `type:${INBOX_FILTER_VALUE[this.filter]}`
      }

      return new Promise((resolve, reject) => {
        GetInboxMessages2250(queryFilters)
          .then((res) => {

            if(res.data.next_page_url !== null) {
              this.showLoadMore = true;
              this.page = res.data.next_page_url.slice(-1)
            }
            resolve(initializeList(res.data.data))
          })
          .catch((err) => { reject(err) })
          .finally(() => { this.isFetching = false })
      })

    },
    fetchFeedbacks () {
      this.$store.dispatch('notification/SEEN_FEEDBACK_NOTIFICATION')

      this.isFetching = true
      this.showLoadMore = false

      return new Promise((resolve, reject) => {
          FetchFeedbacksA22250({ page: this.feedbackspage })
            .then((feedbacks) => {
              if (feedbacks.length >= 25) {
                this.showLoadMore = true
                this.feedbackspage = this.feedbackspage + 1
              } else {
                this.showLoadMore = false
              }
              feedbacks = initializeList(feedbacks.map((f) => ({
                _feedback: f,
                sender_name: f.client_name,
                sender_age: f.client_age,
                rating: f.client_rating,
                content: f.client_review,
                created_at: f.created_at,
                updated_at: f.created_at, // updated_at does not exist, use created_at instead.
                id: f.request_id,
                type: INBOX_TYPE.REVIEW,
                show_review: f.show_review
              })))
              resolve(feedbacks)
            })
            .catch((err) => { reject(err) })
            .finally(() => { this.isFetching = false })
      })
    },
    resetList () {
      this.messages = []
      this.page = 1

      if (this.$refs.infiniteLoader) {
        this.$refs.infiniteLoader.stateChanger.reset()
      }
    },
    onClientReply (res) {
      for (let i = 0, limit = this.messages.length; i < limit; ++i) {
        if (this.messages[i].tokenID === res.token_id) {
          this.messages[i].seenAt = null
          this.messages[i].last_received_at = this.currentTime.clone().format('YYYY-MM-DD HH:mm:ss')
          break
        }
      }
    }
  },
  mounted () {
    $h.EventBus.$on(INBOX_EVENTS.ON_KRYPTON_INBOX, this.resetList)
    $h.EventBus.$on(INBOX_EVENTS.ON_KRYPTON_CONNECT_REPLY, this.onClientReply)
  }
}
