<template>
  <div class="browser-banner">
    <div class="banner-content">
      <img v-if="PHOTO_FALLBACK_A2_2720" src="https://d1muixarkip6az.cloudfront.net/apv/layer1.svg">
      <img v-else src="https://res.cloudinary.com/ama-production/image/upload/q_auto/v1644308609/apv/layer1.svg">
      <p v-text="$t('safariBanner.content')" />
      <a href="https://apps.apple.com/no/app/safari/id1146562112" class="banner-content__cta" v-text="$t('safariBanner.cta')" />
    </div>
  </div>
</template>

<i18n src="@/features/pwa-animation/translations/ios.json"></i18n>
<script>
export default {
  computed: {
    PHOTO_FALLBACK_A2_2720 () {      
      return this.$store.getters['featureflag/isEnabled']('PHOTO_FALLBACK_A2_2720')
    },
  }
}
</script>
<style lang="scss" scoped>
.browser-banner {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F7FAFC;

  .banner-content {
    max-width: 311px;
    text-align: center;

    img {
      height: 120px;
      display: block;
      margin: 0 auto;
    }
    p {
      color: #757575;
      font-size: 16px;
    }
    .banner-content__cta {
      display: block;
      width: 100%;
      height: 56px;
      line-height: 56px;
      font-size: 16px;
      color: #FFFFFF;
      background-color: #2F3740;
      border-radius: 4px;
      margin-top: 32px;
    }
  }
}
</style>
