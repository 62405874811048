<template>
  <div v-if="options.length" id="weight" class="wrapper">
    <template>
      <div class="content__area">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <p class="content__stepper content__stepper--mobile"> {{ stepperLabel }}</p>
        <h2 class="content__title content__title--desktop">{{ $t('title') }}</h2>
      </div>
      <div class="content__area drawer">
        <div class="content">
          <p class="content__stepper content__stepper--desktop"> {{ stepperLabel }}</p>
          <h2 class="content__title content__title--mobile">{{ $t('title') }}</h2>

          <div class="drawer-icon center-align">
            <img src="/img/registration/basic/weight.svg" alt="girl on a weighing scale icon">
          </div>

          <div class="selected-text-container">
            <p v-if="selectedText" class="center-align selected-text">
              {{ selectedText }}
            </p>

            <p v-else> &nbsp; </p>
          </div>

          <md-select
            v-model="payload.weight"
            class="textfield-box-lg"
            :scroll-to-value="50"
            :label="$t('input_label')"
            :default="$t('input_default')"
            :placeholder="$t('title')"
            :force-mobile="true"
            desktop-display-mode="split"
          >
            <option
              v-for="(option, index) in options"
              slot="options"
              :key="index"
              :value="option.key"
            >{{ option.content }}</option>
            <div v-if="errors.has('weight')" slot="errors" class="input-helper red-text">
              {{ errors.first('weight') }}
            </div>
          </md-select>

          <register-navigator-v2 :next-disabled="isCTADisabled" :total="total" :current="current" @prev.prevent="gotoPrev" @next.prevent="submit" />

        </div>
      </div>
    </template>
  </div>
</template>

<i18n src="@components/register/translations/weight.json"></i18n>

<script>
import { getV2ProviderDropdownService } from '@/services/DropdownService.js'
import { isEmpty } from '@/constants/helpers.js'

const REG_WEIGHT_CONST = Object.freeze({
  ERR_DISP_DELAY: 5000
})

export default {
  props: ['total', 'current', 'stepperLabel'],
  data () {
    return {
      options: [],
      payload: {
        weight: null
      }
    }
  },
  computed: {
    isCTADisabled () {
      return isEmpty(this.payload.weight)
    },
    getTitle () {
      return this.$t('title')
    },
    homeBaseCountry () {
      return this.$store.getters.getHomeBaseCountry
    },
    user () {
      return this.$store.getters.getUser
    },
    payloadWeight () {
      return this.payload?.weight ?? null
    },
    selectedText () {
      return this.payloadWeight ? this.getContentFromDropdown(this.payloadWeight) : ''
    },
    isFormValid () {
      return this.payloadWeight ?? false
    },
    userProfileWeight () {
      return this.user?.profiles?.weight ?? null
    }
  },
  mounted () {
    this.initializePayload()
    this.fetchDropdownOptions()
  },
  methods: {
    getContentFromDropdown (payloadWeight) {
      const optionItem = this.options.find(o => o.key === payloadWeight)

      return optionItem?.content ?? ''
    },
    extractWeightOptions (res) {
      const weightOptions = res?.data?.weight ?? {}

      this.options = Object.entries(weightOptions).map(option => ({
        key: parseInt(option[0]),
        content: option[1]
      }))
    },
    fetchDropdownOptions () {
      getV2ProviderDropdownService()
        .then(res => this.extractWeightOptions(res))
        .catch(err => console.log('err', err))
    },
    submit () {
      this.errors.clear()

      if (!this.isFormValid) {
        var errorMsg = this.$t('error_select', {
          field: this.$t('name')
        })

        this.errors.add({
          field: 'weight',
          msg: errorMsg
        })

        return false
      }

      this.$store
        .dispatch('updateBasicRegistration', { type: 'weight', payload: this.payload })
        .then(() => this.$router.push(this.resolveBasicRoute('bra-cup')))
        .catch(err => this.displayErrorMessages(err))
    },
    displayErrorMessages (err) {
      this.$notifications.toast(err.message, REG_WEIGHT_CONST.ERR_DISP_DELAY)
    },
    gotoPrev () {
      this.$router.push(this.resolveBasicRoute('height'))
    },
    resolveBasicRoute (type) {
      return {
        name: 'register-basic',
        params: {
          type: type
        }
      }
    },
    initializePayload () {
      if (this.userProfileWeight) {
        this.setPayloadWeight(this.userProfileWeight)
      }
    },
    setPayloadWeight (weight) {
      this.payload.weight = weight
    }
  }
}
</script>
