<template>
  <div id="page-onboarding-lesson-overview" data-test="overview-component">
    <md-navbar :push="{ name: 'dashboard' }" :brand="$t('messages.globals.dashboard')" icon="arrow_back" />
    <div id="content">
      <h1>{{ $t('title') }}</h1>
      <onboarding-lesson-card
        v-for="(card, key) in LESSON_CARDS"
        :key="key"
        data-test="overview-card"
        :lesson-card="card"
        class="overview-lesson-card"
        @click="onLessonClicked(card.id)"
      />
    </div>
  </div>
</template>

<i18n src="@/features/onboarding-lesson-overview/translations/messages.json"></i18n>

<script>
import OnboardingLessonCard from '@/features/onboarding-lesson-card/components/OnboardingLessonCard.vue'
import { OnboardingLessons } from '@/mixins/onboarding'
import { LESSON_CARDS } from '@/features/onboarding-lesson-card/constants'

export default {
  components: {
    OnboardingLessonCard
  },
  mixins: [
    OnboardingLessons
  ],
  data () {
    return {
      LESSON_CARDS
    }
  },
  methods: {
    onLessonClicked (type) {
      switch (type) {
        case this.LESSONS.PHOTOS:
          this.openPhotosLesson()
          break
        case this.LESSONS.INTERVIEW:
          this.openInterviewLesson()
          break
        case this.LESSONS.AVAILABILITY:
          this.openAvailabilityLesson()
          break
        case this.LESSONS.SAFETY:
          this.openSafetyLesson()
          break
        case this.LESSONS.CLIENTS:
          this.openClientsLesson()
          break
        case this.LESSONS.REQUESTS:
          this.openRequestsLesson()
          break
        case this.LESSONS.DATES:
          this.openDatesLesson()
          break
        case this.LESSONS.FEEDBACK:
          this.openFeedbackLesson()
          break
        case this.LESSONS.MONEY:
          this.openMoneyLesson()
          break
        default:
          this.resetActiveLesson()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#page-onboarding-lesson-overview {
  #content {
    h1 {
      font-size: 26px;
      margin: 0 auto 20px;
    }
    .overview-lesson-card {
      margin: 0 auto 16px;
    }
  }
}
</style>
