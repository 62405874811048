<template>
  <div id="photos-v2" class="wrapper">
    <md-new-navbar route-name="profile">
      <template v-slot:cta>
        <div
          class="help-btn"
          data-test="help-btn"
          @click="() => isPhotoGuideShown = true"
        ><span>{{ $translate('register.photos.others.help') }}</span></div>
      </template>
    </md-new-navbar>
    <gallery />
    <photo-guide :is-shown="isPhotoGuideShown" data-test="photo-guide" @close="() => isPhotoGuideShown = false" />
  </div>
</template>

<script>
import Gallery from './gallery'
import photoGuide from '../photo-guide.vue'

export default {
  components: { Gallery, photoGuide },
  data () {
    return {
      rejectedPhotosModal: null,
      isPhotoGuideShown: false
    }
  },
  computed: {
    isEditMode () {
      return this.$store.getters.getPhotoEditMode
    },
    rejectedPhotos () {
      return this.$store.getters.getRejectedPhotos
    }
  },
  watch: {
    rejectedPhotos: function (newVal, oldVal) {
      if (newVal.length > 0 && this.rejectedPhotosModal === null) {
        this.openRejectedModal()
      } else if (this.rejectedPhotosModal !== null) {
        this.rejectedPhotosModal.rejectedPhotos = newVal
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.isEditMode) {
      this.$store.commit('setEditPhotoMode', false)
    }

    if (to.name !== 'photos-crop' && to.name !== 'photos-anonimize') {
      this.$store
        .dispatch('fetchLivePhotos')
        .then(() => {
          this.$store.commit('sortOtherPhotos')
        })
    }

    next()
  },
  mounted () {
    this.$store.dispatch('fetchRejectedPhotos').then(res => {
      const rejectedPhotos = res || []
      this.$store.commit('setRejectedPhotos', rejectedPhotos)
    })
  },
  methods: {
    openRejectedModal () {
      if (this.rejectedPhotos.length > 0) {
        this.isModalOpen = true
        this.rejectedPhotosModal = $h.rejectedPhotosModal(this.rejectedPhotos, () => {
          this.isModalOpen = false
          this.rejectedPhotosModal = null
          this.$store.commit('resetRejectedPhotos')
        })
      }
    }
  }
}
</script>
