<template>
  <div id="app-feedback" data-test="app-feedback" class="wrapper">
    <md-new-navbar :redirect="'help'" />
    <div class="app-feedback__container">
      <div class="content">
        <div style="text-align: center">
          <h2 data-test="app-feedback-title">{{ $t('title') }}</h2>
          <img src="/img/app-feedback/app-feedback.svg" alt="app feedback image" data-test="app-feedback-image">
        </div>
        <p data-test="app-feedback-helper-text" v-html="$t('helper')" />
        <p data-test="app-feedback-support-text" v-html="$t('support')" />
        <p data-test="app-feedback-explanation-text" v-html="explanationText" />
        <form data-test="app-feedback-explanation-form">
          <div>
            <md-select
              v-model="form.category"
              :placeholder="$t('category')"
              :label="$t('category')"
              :disable-default="true"
              :force-mobile="true"
              :listen-for-reinitiate-options="true"
            >
              <option
                v-for="opt in categories"
                slot="options"
                :key="opt.key"
                :value="opt.value"
                :disabled="opt.disabled && opt.key !== form.category"
              >{{ opt.text }}</option>
            </md-select>
          </div>

          <md-textarea
            v-model="form.message"
            type="multiline"
            class="margin"
            :label="$t('message')"
            :maxlength="2000"
            style="height: 90px"
          >
            <span class="text-counter"> {{ form.message.length }}/2000</span>
          </md-textarea>
        </form>
      </div>

      <navigator
        :total="0"
        :current="0"
        no-prev
        primary-at-last
        :next="$t('cta')"
        next-class="action-btn__primary action-btn__blocked'"
        :next-disabled="!formValid || loading"
        @next.prevent="submitFeedback"
      />
    </div>

    <app-feedback-success-modal-vue v-if="showSuccessModal" @close="closeModal" />
  </div>
</template>

<i18n src="@/features/app-feedback/translations/app-feedback.json"></i18n>

<script>
import { APP_FEEDBACK_CATEGORY } from '@/constants/global'
import { postAppFeedback } from '@/features/app-feedback/services/AppFeedbackService'

import navigator from '@components/v2/utilities/navigator.vue'
import AppFeedbackSuccessModalVue from '../components/modals/AppFeedbackSuccessModal.vue'

export default {
  components: {
    navigator,
    AppFeedbackSuccessModalVue
  },
  data () {
    return {
      form: {
        category: 1,
        message: ''
      },
      showSuccessModal: false,
      loading: false
    }
  },
  computed: {
    categories () {
      return [
        {
          text: this.$t('categories.praise.title'),
          value: APP_FEEDBACK_CATEGORY.PRAISE
        },
        {
          text: this.$t('categories.suggest.title'),
          value: APP_FEEDBACK_CATEGORY.SUGGEST
        },
        {
          text: this.$t('categories.complain.title'),
          value: APP_FEEDBACK_CATEGORY.COMPLAIN
        }
      ]
    },
    explanationText () {
      let text = ''
      switch (this.form.category) {
        case 1:
          text = this.$t('categories.praise.text')
          break
        case 2:
          text = this.$t('categories.suggest.text')
          break
        case 3:
          text = this.$t('categories.complain.text')
          break
        default:
          text = ''
          break
      }

      return text
    },
    formValid () {
      return this.form.category && this.form.message.length > 0
    }
  },
  methods: {
    submitFeedback () {
      this.loading = true

      postAppFeedback(this.form)
        .then(data => {
          this.showSuccessModal = true

          this.form = {
            category: 1,
            message: ''
          }

          this.loading = false
        })
        .catch(err => this.$notifications.toast(err?.message, 5000))
    },
    closeModal () {
      this.showSuccessModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
#app-feedback {
  background-color: white;

  .app-feedback__container {
    padding-bottom: calc(100px + 2rem);
    background-color: white;
  }

  .content {
    padding: 0 24px;
  }

  h2 {
    color: #2F3740;
    font-family: 'Proxima Nova Semibold';
    font-size: 26px;
    margin: 5px 0 24px;
  }

  img {
    margin-bottom: 24px;
  }

  p {
    color: #2F3740;
    font-size: 18px;
    margin: 0 0 24px;

    a {
      color: #E30074 !important;
    }
  }

  .md-select--NEW_APV_REG_REDESIGN {
    width: 100% !important;
  }

  form {
    margin-bottom: 100px;
  }

  textarea.materialize-multiline {
    height: 90px !important;
    margin-bottom: 0 !important;
  }

  .text-counter {
    float: right;
    font-size: 12px;
  }
}
</style>
