var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "other-menu" } },
    [
      _c("div", { staticClass: "menu-container" }, [
        _c("div", { staticClass: "menu-content" }, [
          _c("h2", [_vm._v(_vm._s(_vm.$translate("sidenav.menu")))]),
          _vm._v(" "),
          _c("ul", { staticClass: "menus" }, [
            _c(
              "li",
              {
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.navigatePage("profile", false)
                  }
                }
              },
              [
                _c("a", { attrs: { href: "#" } }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$translate("sidenav.profile")))
                  ])
                ]),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: require("@/features/other-menu/assets/menu-icon.svg"),
                    alt: "chevron right icon"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "waves-effect waves-dark",
                class: {
                  locked: _vm.isFeatureLocked,
                  "has-notif": _vm.showNotifFeedback
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.navigatePage("feedback", true)
                  }
                }
              },
              [
                _c("a", { attrs: { href: "#" } }, [
                  _c("div", [
                    _vm.isFeatureLocked
                      ? _c("img", {
                          attrs: {
                            src: require("@/features/other-menu/assets/locked/feedback-locked.svg"),
                            alt: "manage profile icon"
                          }
                        })
                      : _c("img", {
                          attrs: {
                            src: require("@/features/other-menu/assets/feedback.svg"),
                            alt: "manage profile icon"
                          }
                        })
                  ]),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$translate("sidenav.feedback")))
                  ])
                ]),
                _vm._v(" "),
                _vm.isFeatureLocked
                  ? _c("img", {
                      attrs: {
                        src: require("@/features/other-menu/assets/locked/menu-icon-locked.svg"),
                        alt: "chevron right icon"
                      }
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/features/other-menu/assets/menu-icon.svg"),
                        alt: "chevron right icon"
                      }
                    })
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "waves-effect waves-dark",
                class: { locked: _vm.isFeatureLocked },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.navigatePage("super-provider", true)
                  }
                }
              },
              [
                _c("a", { attrs: { href: "#" } }, [
                  _c("div", [
                    _vm.isSuperProvider && !_vm.isFeatureLocked
                      ? _c("img", {
                          attrs: {
                            src: require("@/features/other-menu/assets/super-provider-gold.svg"),
                            alt: "manage profile icon"
                          }
                        })
                      : _vm.isFeatureLocked
                      ? _c("img", {
                          attrs: {
                            src: require("@/features/other-menu/assets/locked/super-provider-locked.svg"),
                            alt: "manage profile icon"
                          }
                        })
                      : _c("img", {
                          attrs: {
                            src: require("@/features/other-menu/assets/super-provider.svg"),
                            alt: "manage profile icon"
                          }
                        })
                  ]),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$translate("sidenav.super_provider")))
                  ])
                ]),
                _vm._v(" "),
                _vm.isFeatureLocked
                  ? _c("img", {
                      attrs: {
                        src: require("@/features/other-menu/assets/locked/menu-icon-locked.svg"),
                        alt: "chevron right icon"
                      }
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/features/other-menu/assets/menu-icon.svg"),
                        alt: "chevron right icon"
                      }
                    })
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "waves-effect waves-dark",
                class: { locked: _vm.isFeatureLocked },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.navigatePage(_vm.getAffiliatePage, true)
                  }
                }
              },
              [
                _c("a", { attrs: { href: "#" } }, [
                  _c("div", [
                    _vm.isFeatureLocked
                      ? _c("img", {
                          attrs: {
                            src: require("@/features/other-menu/assets/locked/affiliates-locked.svg"),
                            alt: "manage profile icon"
                          }
                        })
                      : _c("img", {
                          attrs: {
                            src: require("@/features/other-menu/assets/affiliates.svg"),
                            alt: "manage profile icon"
                          }
                        })
                  ]),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.$translate("sidenav.billing_A2_2159.affiliate")
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm.isFeatureLocked
                  ? _c("img", {
                      attrs: {
                        src: require("@/features/other-menu/assets/locked/menu-icon-locked.svg"),
                        alt: "chevron right icon"
                      }
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/features/other-menu/assets/menu-icon.svg"),
                        alt: "chevron right icon"
                      }
                    })
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "waves-effect waves-dark",
                class: {
                  locked: _vm.isFeatureLocked,
                  "has-notif": _vm.showNotifBilling
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.navigatePage("billing-v2-unpaid", true)
                  }
                }
              },
              [
                _c("a", { attrs: { href: "#" } }, [
                  _c("div", [
                    _vm.isFeatureLocked
                      ? _c("img", {
                          attrs: {
                            src: require("@/features/other-menu/assets/locked/invoices-locked.svg"),
                            alt: "manage profile icon"
                          }
                        })
                      : _c("img", {
                          attrs: {
                            src: require("@/features/other-menu/assets/invoices.svg"),
                            alt: "manage profile icon"
                          }
                        })
                  ]),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.$translate("sidenav.billing_A2_2159.billing"))
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm.isFeatureLocked
                  ? _c("img", {
                      attrs: {
                        src: require("@/features/other-menu/assets/locked/menu-icon-locked.svg"),
                        alt: "chevron right icon"
                      }
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/features/other-menu/assets/menu-icon.svg"),
                        alt: "chevron right icon"
                      }
                    })
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "waves-effect waves-dark",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.navigatePage("setting", false)
                  }
                }
              },
              [
                _c("a", { attrs: { href: "#" } }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$translate("sidenav.settings")))
                  ])
                ]),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: require("@/features/other-menu/assets/menu-icon.svg"),
                    alt: "chevron right icon"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "waves-effect waves-dark",
                class: {
                  "has-notif": _vm.hasSupportUpdates
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.navigatePage("help", false)
                  }
                }
              },
              [
                _c("a", { attrs: { href: "#" } }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("strong", [_vm._v(_vm._s(_vm.$translate("sidenav.help")))])
                ]),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: require("@/features/other-menu/assets/menu-icon.svg"),
                    alt: "chevron right icon"
                  }
                })
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("fixed-menu")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("@/features/other-menu/assets/profile.svg"),
          alt: "manage profile icon"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("@/features/other-menu/assets/settings.svg"),
          alt: "manage profile icon"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("@/features/other-menu/assets/help.svg"),
          alt: "manage profile icon"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }