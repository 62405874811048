export const LESSON_CARDS = Object.freeze({
  PHOTOS: {
    id: 1,
    title: 'photos.title',
    class: 'onboarding-lesson-card__photos'
  },
  INTERVIEW: {
    id: 2,
    title: 'interview.title',
    class: 'onboarding-lesson-card__interview'
  },
  AVAILABILITY: {
    id: 3,
    title: 'availability.title',
    class: 'onboarding-lesson-card__availability'
  },
  SAFETY: {
    id: 4,
    title: 'safety.title',
    class: 'onboarding-lesson-card__safety'
  },
  CLIENTS: {
    id: 5,
    title: 'clients.title',
    class: 'onboarding-lesson-card__clients'
  },
  REQUESTS: {
    id: 6,
    title: 'requests.title',
    class: 'onboarding-lesson-card__requests'
  },
  DATES: {
    id: 7,
    title: 'dates.title',
    class: 'onboarding-lesson-card__dates'
  },
  FEEDBACK: {
    id: 8,
    title: 'feedback.title',
    class: 'onboarding-lesson-card__feedback'
  },
  MONEY: {
    id: 9,
    title: 'money.title',
    class: 'onboarding-lesson-card__money'
  }
})
