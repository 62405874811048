export default {
  methods: {
    onUsernameChange(val) {
      this.$emit('update:username', val);
    },
    onPasswordChange(val) {
      this.$emit('update:password', val);
    },
    doSubmit(next) {
      this.$emit('submit', next);
    },
  },
  props: {
    username: { type: String, default: '' },
    password: { type: String, default: '' },
    error:    { type: String, default: '' },
  },
}
