import '../localization/localization.js'

const VueTranslate = {}

VueTranslate.install = function (Vue, options) {
  Vue.prototype.$translate = function (key, params) {
    return Lang.get(key, params)
  }
}

export default VueTranslate
