var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal modal-dropdown left-align md-autocomplete-dropdown",
      staticStyle: {
        top: "0!important",
        height: "100%!important",
        width: "100%",
        margin: "0",
        "max-height": "100%"
      }
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "modal-header row",
          staticStyle: { padding: "0" }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col s1", staticStyle: { "padding-top": "20px" } },
              [
                _c(
                  "i",
                  { staticClass: "material-icons", on: { click: _vm.close } },
                  [_vm._v("arrow_back")]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "input-field col s10" }, [
              _c("input", {
                attrs: {
                  id: "autocomplete-dropdown-input",
                  type: "text",
                  autocomplete: "test"
                },
                on: { keydown: _vm.search }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "autocomplete-dropdown-input" } }, [
                _vm._v(_vm._s(_vm.header))
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "content", staticClass: "modal-content" },
        _vm._l(_vm.clonedOptions, function(option) {
          return _c("div", { staticClass: "autocomplete-option" }, [
            _c("label", {
              staticClass: "autocomplete-option-label",
              attrs: { "data-option-value": _vm.strip(option) },
              domProps: { innerHTML: _vm._s(option) },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.select(option)
                }
              }
            })
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }