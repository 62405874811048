import { buildParam } from '@/services/ApiService'

export const getAppVersion = () => {
  const version = require('~@/package.json').version
  return JSON.stringify(version).replace(/\"/g, '')
}

export const updateUsage = (userID, isPWA) => {
  const apiEndpoint = isPWA
    ? buildParam(`/v2/usage/${userID}/pwa`)
    : buildParam(`/v2/usage/${userID}/browser`)

  return new Promise((resolve, reject) => {
    window.api.put({
      url: apiEndpoint,
      payload: { build_version: getAppVersion() }
    }, (success) => {
      resolve(success.data)
    }, (err) => {
      reject(err?.response?.data ?? err)
    })
  })
}

export const sendTracking = (data) => {
  if (data.authorization) {
    window.api.authorization = data.authorization
  }

  const isPWA =
      window.matchMedia('(display-mode: standalone)').matches ||
      window.matchMedia('(display-mode: fullscreen)').matches ||
      window.navigator.standalone === true ||
      window.location.search.indexOf('utm_source=homescreen') >= 0

  updateUsage(data.id, isPWA)
}

export default {
  sendTracking
}
