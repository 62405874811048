var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cta__holder" }, [
    _c(
      "a",
      {
        staticClass: "cta__close waves-effect",
        attrs: { "data-test": "photo-cls-btn", href: "#" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.redirect($event)
          }
        }
      },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }