<template>
  <div
    id="anonimize"
    class="wrapper"
  >
    <div class="photo-container__overlay" />
    <div class="photo-container__drawer">
      <div class="content">
        <div class="photo-container__header" style="border-bottom: none; justify-content: space-between;">
          <a href="#" @click.prevent="triggerCancelUpload">
            <i class="material-icons" style="margin-right: 8px;">close</i>
            <span class="cancel-upload-label">{{ $translate('photos.cancel_upload') }}</span>
          </a>
        </div>

        <div id="content" class="hide">
          <vform ref="enterOverlayForm" @submit="enterOverlayMode" />
          <vform ref="anonimizeForm" @submit="anonimizePhoto" />
          <vform ref="publishOverlayForm" @submit="publishPhoto" />
        </div>

        <div ref="anonymizeContainer" class="anonymize-container" :class="{'--loading': imageLoading}">
          <img v-if="!imageLoading && imgSrc" ref="anonymize_image" :src="imgSrc">
        </div>

        <div class="photo-text" style="cursor: pointer">
          <strong
            class="slider-label"
            :class="{'disabled-slider': !isSliderActive}"
            @click.prevent="enableSlider"
          >{{ $translate('photos.anonimize') }}</strong>
          <div v-show="isSliderActive" id="overlaySliderContainer" class="padding-md">
            <div id="overlaySlider" />
          </div>
        </div>

        <register-navigator-v2
          v-if="hasOverlay"
          class="photo-container__footer"
          :no-prev="true"
          :next="!isSending ? $translate('photos.publish') : 'Saving...'"
          @next.prevent="$refs.anonimizeForm.submit"
        />

        <register-navigator-v2
          v-if="!hasOverlay"
          class="photo-container__footer"
          :no-prev="true"
          :next="!isSending ? $translate('photos.publish') : 'Saving...'"
          @next.prevent="$refs.publishOverlayForm.submit"
        />
      </div>
    </div>
    <md-screen-preloader v-if="isSending || imageLoading" />
  </div>
</template>

<script>
import AnonimizeController from '@components/account/photos/anonimize.controller'

export default {
  mixins: [AnonimizeController]
}
</script>

<style lang="scss">
@import "./photo-drawer"
</style>
