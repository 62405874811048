<template>
  <div id="live-photos">

    <div class="photo-container">
      <div v-for="(p, i) in photos"
           :key="p.id || i"
           :class="{ 'shake-effect': p.id !== 0 }">

        <!-- Provider photos -->
        <photos-gallery-v2-photo
          v-if="p.id !== 0"
          :id="`photo-upload-${i}`"
          :data-id="p.id"
          :photo="p"
          :index="i"
          @stop-replace="stopReplace"
        />

        <!-- Upload Photo Slot -->
        <photos-gallery-v2-photo-empty
          v-if="p.id === 0 && !isEditMode"
          :id="`photo-upload-${i}`"
          :index="i"
        />

      </div>
    </div>

    <div class="row center">
      <div>
        <div v-if="!isEditMode" class="pro-tip">
          <strong>PRO TIP</strong> <span>{{ $translate('photos.v2.photos_required') }}</span>
        </div>
        <span v-else>{{ $translate('photos.v2.hold_drag') }}</span>
      </div>
    </div>

    <vform ref="uploadMainForm" @submit="uploadMainPhoto">
      <input ref="uploadMainFormFile" type="file" accept="image/*" class="hide" @change="uploadMainFormSubmit">
    </vform>
    <vform ref="uploadForm" @submit="uploadPhoto">
      <input ref="uploadFormFile" type="file" accept="image/*" class="hide" @change="uploadFormSubmit">
    </vform>
  </div>
</template>

<script>
const PhotosProvider = $h.getProvider('PhotosProvider')

export default {
  data () {
    return {
      swappable: null,
      replacePhotoID: 0,
      replacePrimary: false,
      activePhotoEl: null,
      activePhotoIndex: null,
      tooltip: {}
    }
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    isEditMode () {
      return this.$store.getters.getPhotoEditMode
    },
    photos () {
      return this.$store.getters.getLivePhotos
    },
    tooltipPlacement () {
      if ([0, 2].includes(this.activePhotoIndex)) {
        return 'bottom-start'
      }

      if ([1, 3].includes(this.activePhotoIndex)) {
        return 'bottom-end'
      }

      if (this.activePhotoIndex == 4) {
        return 'top-start'
      }

      if (this.activePhotoIndex == 5) {
        return 'top-end'
      }

      return 'top'
    }
  },
  methods: {
    toggleEditMode () {
      // This means we are on edit mode atm.
      // So we save the new ordering of the photos.
      if (this.$store.getters.getPhotoEditMode) {
        this.savePhotoOrder()
      }

      this.$store.commit('setEditPhotoMode', !this.$store.getters.getPhotoEditMode)
    },
    savePhotoOrder () {
      const payload = {
        ordering: [].slice
          .call(
            document.querySelectorAll('#live-photos .photo-gallery-v2-photo.is-draggable')
          )
          .map((p, idx) => {
            return { id: p.dataset.id, order: idx + 1 }
          })
      }

      this.$store.dispatch('updatePhotoSort', payload)
    },
    replacePhoto (replacePhotoID, replacePrimary) {
      this.replacePhotoID = replacePhotoID
      this.replacePrimary = replacePrimary
      this.$refs.uploadFormFile.click()
    },
    deletePhoto () {
      PhotosProvider
        .deletePhoto(this.replacePhotoID)
        .then((res) => {
          this.$store.commit('removePhoto', this.replacePhotoID)
          this.replacePhotoID = 0
        })
        .catch((err) => {
          this.$notifications.toast(err.message, 5000)
        })
    },
    uploadMainPhoto (next) {
      const file = this.$refs.uploadMainFormFile.files[0]

      PhotosProvider.uploadMainPhoto(file, this.isRegisterMode)
        .then((res) => {
          const routeName = this.isRegisterMode ? 'register-photos-crop' : 'photos-crop'

          this.$router.push({
            name: routeName,
            params: {
              photo: res.data,
              return: this.isRegisterMode
                ? { name: 'register-photos', params: { type: 'others', showTipOnMount: true } }
                : { name: 'photos' },
              cancel: this.isRegisterMode
                ? { name: 'register-photos', params: { type: 'primary' } }
                : { name: 'photos' }
            }
          })
        })
        .catch((err) => {
          if (_.isEmpty(err.data)) {
            $h.photoErrorModal(err.message)
          } else {
            $h.photoErrorModal(err.data)
          }
        })
        .finally(() => {
          if (this.$refs.uploadMainFormFile) {
            this.$refs.uploadMainFormFile.value = ''
          }
          next()
        })
    },
    uploadPhoto (next) {
      const file = this.$refs.uploadFormFile.files[0]
      // If we are replacing another photo, remove the old one.
      if (this.replacePhotoID !== 0) {
        PhotosProvider.replacePhoto(this.replacePhotoID, file, 0, this.replacePrimary)
          .then((res) => {
            const payload = {
              removePhotoID: this.replacePhotoID,
              newPhoto: res.data
            }

            const routeName = 'photos-crop'

            this.$router.push({
              name: routeName,
              params: {
                photo: res.data,
                return: { name: 'photos' },
                cancel: { name: 'photos' }
              }
            })
          })
          .catch((err) => {
            if (_.isEmpty(err.data)) {
              $h.photoErrorModal(err.message)
            } else {
              $h.photoErrorModal(err.data)
            }
          })
          .finally(() => {
            if (this.$refs.uploadFormFile) {
              this.$refs.uploadFormFile.value = ''
            }
            this.replacePhotoID = 0
            this.replacePrimary = false
            next()
          })
      } else {
        PhotosProvider.uploadPhoto(file, this.isRegisterMode)
          .then((res) => {
            this.$router.push({
              name: this.isRegisterMode ? 'register-photos-crop' : 'photos-crop',
              params: {
                photo: res.data,
                return: this.isRegisterMode
                  ? { name: 'register-photos', params: { type: 'others' } }
                  : { name: 'photos' },
                cancel: this.isRegisterMode
                  ? { name: 'register-photos', params: { type: 'others' } }
                  : { name: 'photos' }
              }
            })
          })
          .catch((err) => {
            if (_.isEmpty(err.data)) {
              $h.photoErrorModal(err.message)
            } else {
              $h.photoErrorModal(err.data)
            }
          })
          .finally(() => {
            if (this.$refs.uploadFormFile) {
              this.$refs.uploadFormFile.value = ''
            }
            next()
          })
      }
    },
    uploadFormSubmit () {
      this.$refs.uploadForm.submit()
    },
    uploadMainFormSubmit () {
      this.$refs.uploadMainForm.submit()
    },
    stopReplace (el, index) {
      this.activePhotoEl = el
      this.activePhotoIndex = index

      const indexName = `photo-upload-tooltip-${this.activePhotoIndex}`

      // eslint-disable-next-line no-prototype-builtins
      if (!this.tooltip.hasOwnProperty(indexName)) {
        this.tooltip[indexName] = this.$tooltip.create(this.activePhotoEl, indexName, {
          trigger: 'click',
          content: this.$translate('photos.photo_still_in_review'),
          placement: this.tooltipPlacement,
          interactive: true,
          appendTo: document.body
        }, {
          maxWidth: `${464}px`,
          closeable: false,
          icon: '<img style="margin-right: 14px" src="/img/icons/info-circle-outline.svg" alt="" />',
          classes: 'tooltip-overlay'
        })
      }
    }
  }
}
</script>
