import { buildParam } from '@/services/ApiService'
import { AFFILIATE_SETTINGS_SEEN_FIELD_TYPES } from '@/features/affiliate/constants.js'
export const affiliateSettingsSeenPath = '/v2/affiliates/settings/seen'
export const affiliateShareLinkPath = '/v2/affiliates/share-link'
export const affiliateOverviewPath = '/v2/affiliates/overview'

export const putAffiliateSettingsSeen = (payload = { field: AFFILIATE_SETTINGS_SEEN_FIELD_TYPES.PROMO }) => {
  const url = buildParam(`${process.env.KRYPTON_PROVIDER_API}${affiliateSettingsSeenPath}`)
  return new Promise((resolve, reject) => {
    window.api.put({ url, payload: payload },
      (success) => { resolve(success.data) },
      (err) => { reject(err.response.data) })
  })
}

export const postAffiliateShareLink = (locale = 'de') => {
  const url = locale === 'en'
    ? buildParam(`${process.env.KRYPTON_PROVIDER_API}${affiliateShareLinkPath}`, { locale: 'en' })
    : buildParam(`${process.env.KRYPTON_PROVIDER_API}${affiliateShareLinkPath}`)

  return new Promise((resolve, reject) => {
    window.api.post({ url },
      (success) => { resolve(success.data) },
      (err) => { reject(err.response.data) })
  })
}

export const getAffiliateOverview = () => {
  const url = buildParam(`${process.env.KRYPTON_PROVIDER_API}${affiliateOverviewPath}`)
  return new Promise((resolve, reject) => {
    window.api.get({ url },
      (success) => { resolve(success.data) },
      (err) => { reject(err.response.data) })
  })
}
