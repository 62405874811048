<template>
  <div id="support-v2-request" class="page wrapper">
    <support-v2-request-new
      v-if="isNewRequest"
      :ticket-id="ticketID"
    />
    <support-v2-request-chat
      v-else
    />
  </div>
</template>

<script>
export default {
  computed: {
    isNewRequest () {
      // eslint-disable-next-line no-prototype-builtins
      return this.$route.params.id === 'new' || !this.$route.query.hasOwnProperty('id')
    },
    ticketID () {
      return this.$route.params.ticketID || null
    }
  },
  beforeRouteLeave (to, from, next) {
    // Always reset the file upload array when leaving.
    this.$store.commit('support/SET_NEW_FILE_UPLOAD', [])
    next()
  }
}
</script>
