<template>
  <div class="lesson-screen--photos">
    <div v-for="(photo, key) in photos" :key="key" class="lesson-screen--photo">
      <div
        v-if="PHOTO_FALLBACK_A2_2720"
        data-test="photo-img-thumb"
        class="lesson-screen--img"
        :style="{ 'background-image': `url(${photo.photo_fall_back_A2_2720})` }"
        @click.prevent="focusOnKey(key)"
      />
      <div
        v-else
        data-test="photo-img-thumb"
        class="lesson-screen--img"
        :style="{ 'background-image': `url(${photo.thumbnail})` }"
        @click.prevent="focusOnKey(key)"
      />
      <div data-test="photo-img-container" class="lesson-screen--fullscreen-backdrop" :class="{ 'focus': isFullscreen(key) }">
        <div class="lesson-screen--fullscreen-content">
          <div class="lesson-screen--img-full">
            <div class="lesson-screen--img-navigation-container">
              <div class="lesson-screen--img-navigation">
                <a data-test="prev-button" href="#" @click.prevent="navigatePrev">
                  <i class="material-icons">chevron_left</i>
                </a>
                <a data-test="next-button" href="#" @click.prevent="navigateNext">
                  <i class="material-icons">chevron_right</i>
                </a>
              </div>
            </div>
            <img v-if="PHOTO_FALLBACK_A2_2720" data-test="photo-img-full" :src="photo.photo_fall_back_A2_2720">
            <img v-else data-test="photo-img-full" :src="photo.full">
          </div>
          <div class="lesson-screen--fullscreen-controller">
            <div class="lesson-screen--controller-progress">{{ $t('progress', { current: currentFocus, total: photosArraySize }) }}</div>
            <div v-if="photoCaption(key)" data-test="photo-img-caption" class="lesson-screen--controller-caption">{{ photoCaption(key) }}</div>
            <div class="lesson-screen--controller-action">
              <a data-test="photo-img-close" href="#" @click.prevent="closeFullscreen">
                <i class="material-icons">close</i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "progress": "{current} of {total}"
  },
  "de": {
    "progress": "{current} von {total}"
  }
}
</i18n>

<script>
export default {
  props: {
    photos: {
      type: Array,
      default () {
        return [{
          thumbnail: '',
          full: '',
          caption: ''
        }]
      }
    }
  },
  data () {
    return {
      focusedKey: null
    }
  },
  computed: {
    PHOTO_FALLBACK_A2_2720 () {      
      return this.$store.getters['featureflag/isEnabled']('PHOTO_FALLBACK_A2_2720')
    },
    isFullscreen () {
      return (key) => {
        return this.focusedKey === key
      }
    },
    currentFocus () {
      if (this.focusedKey === null) {
        return 0
      }

      return this.focusedKey + 1
    },
    photosArraySize () {
      return this.photos.length
    },
    photoCaption () {
      return (key) => {
        if (this.$i18n.locale === 'de') {
          return this.photos[key]?.captionDE
        } else {
          return this.photos[key]?.captionEN
        }
      }
    }
  },
  methods: {
    focusOnKey (key) {
      this.focusedKey = key
    },
    closeFullscreen () {
      this.focusedKey = null
    },
    navigatePrev () {
      let key = this.focusedKey - 1

      if (key < 0) {
        key = this.photos.length - 1
      }

      this.focusOnKey(key)
    },
    navigateNext () {
      let key = this.focusedKey + 1

      if (key >= this.photos.length) {
        key = 0
      }

      this.focusOnKey(key)
    }
  }
}
</script>

<style lang="scss" scoped>
.lesson-screen--photos {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px;

  .lesson-screen--photo {
    flex: 0 0 50%;
    padding: 0 4px;

    .lesson-screen--img {
      display: block;
      width: 100%;
      padding-top: 133%;
      border-radius: 8px;
      border: 1px solid #CED6E0;
      margin-bottom: 8px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center top;
      cursor: pointer;
      position: relative;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
    }

    .lesson-screen--img::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: 0.15;
    }

    .lesson-screen--fullscreen-backdrop {
      display: none;
      position: fixed;
      background-color: rgba(#212121, 0.75);
      z-index: 9991;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;

      .lesson-screen--fullscreen-content {
        width: calc(100vw - 48px);
        max-width: 375px;

        img {
          width: 100%;
          display: block;
          margin-bottom: 8px;
        }

        .lesson-screen--img-full {
          position: relative;
          margin-bottom: 8px;

          .lesson-screen--img-navigation-container {
            &.dnone {
              display: none;
            }

            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            display: block;

            .lesson-screen--img-navigation {
              padding: 0 8px;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              visibility: visible;

              a {
                color: #fff;
                border-radius: 50%;
                background-color: rgba(0,0,0,0.3);

                i.material-icons {
                  display: block;
                  font-size: 40px;
                }
              }
            }
          }

          img {
            width: 100%;
            display: block;
            margin-bottom: 0;
          }
        }

        .lesson-screen--fullscreen-controller {
          display: flex;
          border-radius: 4px;;
          background-color: #FFFFFF;
          color: #757575;
          font-size: 11px;

          .lesson-screen--controller-progress,
          .lesson-screen--controller-action {
            flex: 0 0 auto;
          }
          .lesson-screen--controller-progress {
            padding: 18px 16px;
            line-height: 24px;
          }
          .lesson-screen--controller-caption {
            padding: 18px 0;
            flex: 1 0 auto;
            text-align: center;
            line-height: 24px;
          }
          .lesson-screen--controller-action {
            margin-left: auto;

            a {
              display: block;
              color: #757575;
              padding: 18px 16px;

              i.material-icons {
                display: block;
                font-size: 24px;
              }
            }
          }
        }
      }
    }

    .lesson-screen--fullscreen-backdrop.focus {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
