var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("md-new-navbar", {
        staticClass: "navbar-support",
        attrs: { "route-name": "support-v2" },
        scopedSlots: _vm._u([
          {
            key: "cta",
            fn: function() {
              return [
                _c(
                  "span",
                  { staticClass: "support-v2-request__ticket-title" },
                  [_vm._v(_vm._s(_vm.ticketTitle))]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          class: { "ticket-closed": _vm.isTicketClosed },
          attrs: { id: "support-v2-request-chat" }
        },
        [
          _c(
            "div",
            {
              staticClass: "container",
              class: {
                "--with-bottom-status-indicator": _vm.showBottomStatusIndicator
              },
              attrs: { id: "content" }
            },
            [
              _c(
                "infinite-loading",
                {
                  attrs: { direction: "bottom", spinner: "spiral" },
                  on: { infinite: _vm.infiniteHandler }
                },
                [
                  _c("div", { attrs: { slot: "no-more" }, slot: "no-more" }),
                  _vm._v(" "),
                  _c("div", {
                    attrs: { slot: "no-results" },
                    slot: "no-results"
                  })
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.chatHistory, function(c, idx) {
                return _c("support-v2-chat-bubble", {
                  key: idx,
                  attrs: {
                    message: c.message ? c.message : "",
                    attachments: _vm.supportAttachments(c.attachments),
                    "created-at": c._created_at,
                    seen: false,
                    "sent-by": c.group_id === 2 ? c.user_id : 0
                  }
                })
              }),
              _vm._v(" "),
              !_vm.isTicketClosed
                ? _c(
                    "form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.sendReply($event)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "chat-controls container" },
                        [
                          _c("img", {
                            staticClass: "clickable attach-file",
                            attrs: {
                              src: "/img/support/image-icon-44x44.svg",
                              alt: "image icon"
                            },
                            on: { click: _vm.onClickFileUpload }
                          }),
                          _vm._v(" "),
                          _c("md-textarea", {
                            staticClass: "chat-message",
                            attrs: {
                              type: "multiline",
                              placeholder: _vm.inputPlaceholder,
                              disabled: _vm.isFetching || _vm.isTicketClosed,
                              "force-active": true,
                              maxlength: 250,
                              required: ""
                            },
                            on: {
                              focus: _vm.onInputFocus,
                              blur: function() {
                                return (_vm.focused = false)
                              }
                            },
                            model: {
                              value: _vm.message,
                              callback: function($$v) {
                                _vm.message = $$v
                              },
                              expression: "message"
                            }
                          }),
                          _vm._v(" "),
                          _vm.focused || _vm.message !== ""
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-submit btn-round",
                                  class: {
                                    "btn--submit-disabled": _vm.submitDisabled
                                  },
                                  attrs: {
                                    type: "submit",
                                    disabled: _vm.submitDisabled
                                  }
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("send")
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("input", {
                        ref: "fileUpload",
                        staticClass: "hide",
                        attrs: { type: "file", accept: "image/*,.pdf" },
                        on: { change: _vm.onFileUploadChange }
                      })
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }