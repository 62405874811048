var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "router-link",
      class: { "router-link-active": _vm.isActive },
      attrs: { href: _vm.href },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.push($event)
        }
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }