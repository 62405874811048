import { render, staticRenderFns } from "./video-item.vue?vue&type=template&id=62c281fb&scoped=true&"
import script from "./video-item.vue?vue&type=script&lang=js&"
export * from "./video-item.vue?vue&type=script&lang=js&"
import style0 from "./video-item.vue?vue&type=style&index=0&id=62c281fb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62c281fb",
  null
  
)

/* custom blocks */
import block0 from "@/features/super-provider/translations/landing-page.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fforge%2Fama-provider8-stage.noznom.tech%2Fsrc%2Fjs%2Ffeatures%2Fsuper-provider%2Fcomponents%2Ffaq-list-item%2Fvideo-item.vue"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider8-stage.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('62c281fb')) {
      api.createRecord('62c281fb', component.options)
    } else {
      api.reload('62c281fb', component.options)
    }
    module.hot.accept("./video-item.vue?vue&type=template&id=62c281fb&scoped=true&", function () {
      api.rerender('62c281fb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/features/super-provider/components/faq-list-item/video-item.vue"
export default component.exports