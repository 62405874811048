<template>
  <div id="verify-upload-id" class="id-verification">
    <div id="content" class="container">
      <h5>
        {{ pageTitle }}
      </h5>
      <p>
        {{ $translate('verify.upload.explanation_text') }}
      </p>

      <div class="flex-grid-column margin-bottom-x5">
        <upload-slot :image-src="frontID" :is-replaceable="isFrontIDReplaceable" :placeholder="frontIDPlaceholder"
          @onclick="onClick(SIDES.FRONT)" />
        <div class="margin-y-sm" />
        <upload-slot :image-src="backID" :is-replaceable="isBackIDReplaceable" :placeholder="backIDPlaceholder"
          :is-front="false" @onclick="onClick(SIDES.BACK)" />
      </div>
      <div class="cta-fixed">
        <button :disabled="!isComplete"
          class="action-btn action-btn__krypton action-btn__blocked waves-effect waves-light" @click="doContinue">
          <strong v-if="!isSubmitting" style="font-size: 16px">
            {{ $translate('verify.upload.cta_button') }}
          </strong>
          <div v-else class="preloader-wrapper small active">
            <div class="spinner-layer spinner-green-only">
              <div class="circle-clipper left">
                <div class="circle" />
              </div>
              <div class="gap-patch">
                <div class="circle" />
              </div>
              <div class="circle-clipper right">
                <div class="circle" />
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>

    <input id="front-id-input" ref="uploadFrontID" class="hide" type="file" accept="image/*"
      @change="onChange(SIDES.FRONT)">
    <input id="back-id-input" ref="uploadBackID" class="hide" type="file" accept="image/*"
      @change="onChange(SIDES.BACK)">
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import UploadSlot from './slots/upload-slot.vue'

const VerificationProvider = $h.getProvider('VerificationProvider')

const FRONT_IMG_PLACEHOLDER = '/img/verify/select-id-front-placeholder.svg'
const BACK_IMG_PLACEHOLDER = '/img/verify/select-id-back-placeholder.svg'
const SIDES = {
  FRONT: 'front',
  BACK: 'back'
}

export default {
  data: () => ({
    SIDES,
    isSubmitting: false,
    successfullySaved: false
  }),
  computed: {
    ...mapGetters({
      isReupload: 'billing/hasRejectedID',
      billing: 'billing/getBilling'
    }),
    pageTitle() {
      if (this.isReupload) {
        return this.$translate('verify.upload.reupload_title')
      }
      return this.$translate('verify.upload.title')
    },
    isComplete() {
      return this.frontID !== null &&
        this.backID !== null
    },
    frontID() {
      const b = this.billing

      if (b) {
        return b._id_front_url
      }

      return null
    },
    backID() {
      const b = this.billing

      if (b) {
        return b._id_back_url
      }

      return null
    },
    isFrontIDReplaceable() {
      return this.isReplaceable('front')
    },
    isBackIDReplaceable() {
      return this.isReplaceable('back')
    },
    frontIDPlaceholder() {
      if (this.isReupload) {
        return this.$translate('verify.reupload.front_id_placeholder')
      }
      return this.$translate('verify.upload.front_id_placeholder')
    },
    backIDPlaceholder() {
      if (this.isReupload) {
        return this.$translate('verify.reupload.back_id_placeholder')
      }
      return this.$translate('verify.upload.back_id_placeholder')
    }
  },
  methods: {
    doContinue() {
      this.doUpload()
    },
    doUpload() {
      const payload = {
        frontID: this.$refs.uploadFrontID.files[0],
        backID: this.$refs.uploadBackID.files[0],
        isReupload: this.isReupload
      }

      this.isSubmitting = true
      this.$store
        .dispatch('billing/uploadIDs', payload)
        .then(() => {
          this.successfullySaved = true
          this.$router.push({ name: 'verify-pending' })
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },
    onClick(side) {
      switch (side) {
        case SIDES.FRONT:
          this.$refs.uploadFrontID.click()
          break
        case SIDES.BACK:
          this.$refs.uploadBackID.click()
          break
      }
    },
    onChange(side) {
      const reader = new FileReader()
      const file = ''

      switch (side) {
        case SIDES.FRONT:
          reader.onload = (e) => {
            this.$store.commit('billing/updateIDURL', {
              side: 'front',
              url: e.target.result
            })
          }
          reader.readAsDataURL(this.$refs.uploadFrontID.files[0])
          break
        case SIDES.BACK:
          reader.onload = (e) => {
            this.$store.commit('billing/updateIDURL', {
              side: 'back',
              url: e.target.result
            })
          }
          reader.readAsDataURL(this.$refs.uploadBackID.files[0])
          break
        default:
          break
      }
    },
    isReplaceable(side) {
      const b = this.billing

      if (b) {
        return b[`file_id_${side}_status`] !== BILLING_ID_STATUS.APPROVED
      }

      return true
    },
    showWarningPopup(next) {
      $h.invertedCustomModalConfirm({
        title: this.$translate('verify.onLeaveModalWarning.title'),
        content: this.$translate('verify.onLeaveModalWarning.content'),
        confirmText: this.$translate('verify.onLeaveModalWarning.ConfirmText'),
        cancelText: this.$translate('verify.onLeaveModalWarning.cancelText')
      }, $e => {
        this.$store.commit('billing/updateIDURL', {
          side: 'front',
          url: null
        })

        this.$store.commit('billing/updateIDURL', {
          side: 'back',
          url: null
        })

        next()
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.successfullySaved === true || (this.backID === null && this.frontID === null)) {
      return next()
    }

    this.showWarningPopup(next)
  },
  components: {
    UploadSlot
  }
}
</script>

<style lang="scss" scoped>
@import './id-verification.styles';

.id-verification {
  h5 {
    margin-top: 30px;
    margin-bottom: 24px;
  }

  p {
    margin-top: 0;
    margin-bottom: 32px;
  }
}
</style>
