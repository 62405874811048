var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "help" } },
    [
      _c("md-new-navbar"),
      _vm._v(" "),
      _c("div", { staticClass: "container", attrs: { id: "content" } }, [
        _c("h2", [
          _vm._v(_vm._s(_vm.$translate("register.photos.others.help")))
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("ul", [
          _c("li", { staticClass: "faq", attrs: { id: "faq-btn" } }, [
            _c(
              "a",
              {
                staticClass: "faq-link",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.gotoPage("faq")
                  }
                }
              },
              [_c("strong", [_vm._v("FAQ")])]
            )
          ]),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "contact-us", attrs: { id: "contact-us-btn" } },
            [
              _c(
                "a",
                {
                  staticClass: "contact-us-link",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.gotoPage("support-v2")
                    }
                  }
                },
                [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$translate("support.contact_us")))
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("li", { staticClass: "feedback", attrs: { id: "feedback-btn" } }, [
            _c(
              "a",
              {
                staticClass: "feedback-link",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.gotoPage("app-feedback")
                  }
                }
              },
              [
                _c("strong", [
                  _vm._v(_vm._s(_vm.$translate("support.tell_us_more")))
                ])
              ]
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "help-icon" }, [
      _c("img", {
        staticClass: "responsive-img",
        attrs: { src: "/img/help/help.svg", alt: "help" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }