<template>
  <div id="lesson-screen-thumbs-up" class="lesson-screen">
    <div class="wrapper">
      <div class="content__drawer content__drawer-filled">
        <div class="content content__tip lesson-screen--container">
          <div class="lesson-screen--content">
            <div class="lesson-screen--story">
              <div>
                <div class="icon-container">
                  <i class="material-icons">thumb_up</i>
                </div>
                <h2>{{ $t(`${openedLessonName}.title`) }}</h2>
              </div>
            </div>
          </div>
        </div>

        <navigator
          :current="currentPage"
          no-prev
          :next="$t('messages.globals.continue')"
          :next-class="'action-btn__black'"
          :total="totalPages"
          :step-class="'--purple'"
          @next.prevent="gotoNextLessonScreen"
        />
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/onboarding-lesson/translations/thumbs-up.json"></i18n>

<script>
import navigator from '@components/v2/utilities/navigator.vue'
import { OnboardingLessons } from '@/mixins/onboarding'

export default {
  components: {
    navigator
  },
  mixins: [
    OnboardingLessons
  ]
}
</script>

<style lang="scss" scoped>
@import "@/features/onboarding-lesson/sass/styles";

#lesson-screen-thumbs-up {
  .lesson-screen--container {
    .lesson-screen--content {
      .lesson-screen--story {
        background-color: #06D6A0;

        div {
          .icon-container {
            i.material-icons {
              color: #06D6A0;
            }
          }
        }
      }
    }
  }
}
</style>
