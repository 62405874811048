<template>
  <div class="bottom-sheet" :class="{ 'bottom-sheet--visible': isShown }">
    <div class="bottom-sheet__container">
      <div class="bottom-sheet__header">
        <div class="bottom-sheet__close-cta" @click.prevent="() => $emit('close')"><img src="/img/icons/close-icon-48.png" alt=""></div>
      </div>
      <div class="bottom-sheet__body">
        <slot name="body">
          <h2 class="bottom-sheet__title" />
        </slot>
      </div>
      <div class="bottom-sheet__footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isShown: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isShown (newVal) {
      if (newVal) {
        this.$store.commit('setHasFixedFooterButtons', true)
      } else {
        this.$store.commit('setHasFixedFooterButtons', false)
      }
    }
  }
}
</script>

<style lang="scss">
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-up) {
  0% { transform: translateY(90%); }
  100% { transform: translateY(0); }
}

@include keyframes(slide-down) {
  0% { transform: translateY(0); }
  100% { transform: translateY(90%); }
}

.bottom-sheet {
  position: fixed;
  width: 100%;
  max-width: 600px;
  height: 100dvh;
  margin: 0 auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  z-index: 199;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.7;
    left: 0;
  }

  .bottom-sheet__container {
    width: 100%;
    height: 90dvh;
    background-color: #fff;
    border-radius: 12px 12px 0px 0px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
    padding: 0 24px;
    display: none;
  }

  .bottom-sheet__header {
    margin-top: 7px;
    height: 48px;
    display: flex;
    align-items: center;
    margin-bottom: 28px;
  }

  .bottom-sheet__close-cta {
    margin-left: -16px;
    width: 48px;
    height: 100%;
    cursor: pointer;
  }

  .bottom-sheet__body {
    height: calc(90dvh - 119px); // 95px of footer + 24px padding
    overflow-y: scroll;
    padding-bottom: 60px;

    &::-webkit-scrollbar{
      display: none;
    }
  }

  .bottom-sheet__title {
    font-size: 22px;
    color: #2F3740;
    margin: 0 0 40px;
  }

  .bottom-sheet__description {
    color: #2F3740;
    text-align: center;
    background: #F7FAFF;
    padding: 16px 12px;
    border-radius: 12px;
    margin-bottom: 12px;
    font-family: 'Proxima Nova Medium';
    font-size: 16px;

    h3 {
      margin: 0 0 8px;
      font-family: 'Proxima Nova Semibold';
      font-size: 20px;
    }
    p {
      margin: 0;
      font-size: 16px;
    }
  }

  .bottom-sheet__footer {
    width: 100%;
    height: 95px;
    border-top: 1px solid #CAD1D9;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 24px 31px;
    background-color: white;
    z-index: 1;
  }

  .bottom-sheet__cta {
    min-width: 128px;
    height: 47px;
    padding: 15px 24px 14px;
    border-radius: 10px;
    font-size: 16px;
    outline: none;
    box-shadow: none;
    border: unset;
    text-transform: uppercase;
    font-family: 'Proxima Nova Semibold';
    display: flex;
    align-items: center;
    justify-content: center;

    &.bottom-sheet__cta--confirm {
      background: transparent linear-gradient(250deg, #FF0073 0%, #DC0064 100%) 0% 0% no-repeat padding-box;
      color: white;

      &.--black {
        background: #2F3740;
      }

      &:disabled {
        background: #CED6E0;
      }
    }
  }

  &.bottom-sheet--visible {
    display: block;

    .bottom-sheet__container {
      display: block;
      @include animation('slide-up 0.3s ease');
    }
  }

  .full-width {
    width: 100%;
  }
}
</style>
