var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "affiliate-reward-modal",
      attrs: { "data-test": "affiliate-popup" }
    },
    [
      _c(
        "div",
        {
          staticClass: "affiliate-reward-modal__backdrop",
          attrs: { "data-test": "affilate-popup-backdrop" }
        },
        [
          _c("div", { staticClass: "affiliate-reward-modal__card" }, [
            _c(
              "a",
              {
                staticClass: "affiliate-reward-modal__close-btn",
                attrs: { href: "#" }
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    attrs: { "data-test": "affiliate-close-btn" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.closePopup({ type: _vm.affiliatePopupType })
                      }
                    }
                  },
                  [_vm._v("close")]
                )
              ]
            ),
            _vm._v(" "),
            _c("img", {
              attrs: {
                "data-test": "affiliate-image",
                src: "/img/affiliate/affiliate-reward-modal.svg",
                alt: ""
              }
            }),
            _vm._v(" "),
            _c(
              "h2",
              {
                staticClass: "title-main",
                attrs: { "data-test": "affiliate-title-main" }
              },
              [_vm._v(_vm._s(_vm.$t("reward.title_main")))]
            ),
            _vm._v(" "),
            _c("h2", { attrs: { "data-test": "affiliate-title" } }, [
              _vm._v(
                _vm._s(
                  _vm.$t("reward.title", {
                    amount: _vm.getRewardByCountryCode
                  })
                )
              )
            ]),
            _vm._v(" "),
            _c("p", { attrs: { "data-test": "affiliate-description" } }, [
              _vm._v(_vm._s(_vm.$t("reward.description")))
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn waves-effect",
                attrs: { "data-test": "affiliate-cta", href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.closePopup({
                      redirect: { name: "affiliate-received" },
                      type: _vm.affiliatePopupType
                    })
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("reward.cta")))]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }