var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPopup
    ? _c("div", { attrs: { id: "super-provider-popup" } }, [
        _c(
          "div",
          {
            staticClass: "super-provider-popup__backdrop",
            attrs: { "data-test": "sp-popup-backdrop" }
          },
          [
            _c(
              "div",
              { staticClass: "super-provider-popup__modal" },
              [
                _c(
                  "a",
                  {
                    staticClass: "super-provider-popup__close-btn",
                    attrs: { href: "#", "data-test": "sp-popup-close" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.closePopup($event)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("close")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(_vm.renderHeaderComponent, { tag: "component" }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "super-provider-popup__description",
                  attrs: { "data-test": "sp-popup-explanation-text" },
                  domProps: { textContent: _vm._s(_vm.description) }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "super-provider-popup__cta btn waves-effect waves-dark",
                    attrs: { "data-test": "sp-popup-cta" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.closePopup({
                          redirect: { name: "super-provider" }
                        })
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.ctaText))]
                )
              ],
              1
            )
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }