<template>
  <div v-if="value" id="decline-terms-popup">
    <div class="popup__backdrop" data-test="sp-popup-backdrop">
      <div class="popup__modal">
        <div class="popup__title">
          <h2
            class="title"
            data-test="popup-title"
            v-text="$t('title')"
          />
        </div>
        <div
          class="popup__description"
          data-test="popup-explanation-text"
          v-text="$t('description')"
        />
        <div class="popup__actions">
          <button
            data-test="popup-cancel-cta"
            class="popup__cta cta_cancel btn btn-secondary"
            @click.prevent="confirm">{{ $t('cancel_cta') }}</button>
          <button
            data-test="popup-confirm-cta"
            class="popup__cta cta_confirm btn btn-black"
            @click.prevent="cancel">{{ $t('confirm_cta') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n src="@/components/register/agreement/translations/popup.json"></i18n>

<script>
const AuthProvider = $h.getProvider('AuthProvider')

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    cancel () {
      // leave popup
      this.$emit('change', false)
    },
    confirm () {
      // logout user
      // close popup
      // redirect to register page
      this.$emit('change', false)
      AuthProvider.logout(() => {
        AuthProvider.setAuthorizationHeader()
        this.$router.push({ name: 'register-start' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#decline-terms-popup {
  font-family: 'Proxima Nova';

  .popup__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 16px;
    width: 100%;
    height: 100%;
    background: rgba(#000000, 0.7);
    z-index: 998;
    display: flex;
    justify-content: center;
    align-items: center;

    .popup__modal {
      position: relative;
      margin: 0;
      width: 100%;
      max-width: 375px;
      background-color: #FFFFFF;
      box-shadow: 0px 3px 6px #00000029;
      font-family: 'Proxima Nova Semibold';
      border-radius: 12px;

      .popup__title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        padding-top: 32px;
        padding-left: 24px;
        padding-right: 24px;

        h2 {
          margin: 0;
          font-size: 20px;
          font-family: 'Proxima Nova Semibold', sans-serif;
          color: #2F3740;
        }
      }

      .popup__description {
        font-size: 18px;
        color: #2F3740;
        padding-top: 24px;
        padding-left: 24px;
        padding-right: 24px;
        text-align: left;
        margin: 0;
        padding-bottom: 16px;
        font-family: 'Proxima Nova', sans-serif;
      }

      .popup__actions {
        border-top: 1px solid #CAD1D9;
        display: flex;
        padding: 16px 24px;
        justify-content: space-between;
      }

    }
  }
}
</style>
