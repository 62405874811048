<template>
  <div id="connect-faq" class="page">
    <div class="container">
      <!-- Connect FAQ Header -->
      <faq-header
        :header-title="$translate('krypton_connect.header.title')"
        :header-tagline="$translate('krypton_connect.header.faq_explanation')"
        :main-header-logo="'/img/krypton-connect/header-img-v2.svg'"
        :tagline-logo="'/img/krypton-connect/header-chat-img-v2.svg'"
        primary-header-color="#2D69E0"
        secondary-header-color="#46E4E9"
        header-gradient-angle="63deg"
        tagline-gradient-angle="48deg"
        :header-img-dimensions="{ height: '107px', width: '125px' }"
      />
      <!-- Numbered list of Connect Question and Answer Section -->
      <div id="content" class="container">
        <div class="faq">
          <ul class="q-and-a">
            <li v-for="(q_and_a, i) in Q_AND_AS" :key="i">
              <div class="question">
                <span class="question-number">{{ getItemNumber(i) }}.</span> <span class="label">{{ $translate(`krypton_connect.q_and_a.question_${q_and_a}`) }}</span>
              </div>
              <div
                class="answer"
              >
                {{ $translate(`krypton_connect.q_and_a.answer_${q_and_a}`) }}
                <span v-if="isLastItem(i)"><a href="#" class="support" @click.prevent="$router.push({name: 'support-v2'})">
                  {{ $translate('krypton_connect.support') }}
                </a>.</span>
              </div>
            </li>
          </ul>
        </div>
        <faq-cta
          route-name="krypton-connect-setting"
          :cta-txt="$translate('faq.connect_faq.cta')"
          background-gradient-class="--blue"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Q_AND_AS } from '@/features/krypton-connect/constants'
import FAQHeader from '@/features/provider-faq/components/FAQHeader.vue'
import FAQCta from '@/features/provider-faq/components/FAQCta.vue'

export default {
  components: {
    'faq-header': FAQHeader,
    'faq-cta': FAQCta
  },
  data: () => ({
    Q_AND_AS: Q_AND_AS,
    prevRoute: null
  }),
  computed: {
    isLastItem () {
      return (key) => {
        const faqlListLength = Q_AND_AS.length - 1
        return parseInt(key) === faqlListLength
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  methods: {
    getItemNumber (itemKey) {
      // We need to add 1 to the itemKey since it always starts at index 0
      // Doing so will make the numbering correct
      return parseInt(itemKey) + 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/features/provider-faq/scss/q-and-a.scss';

#connect-faq #content {
  text-align: left;
  padding-left: 32px;
  padding-right: 32px;
  font-family: 'Proxima Nova';
  font-size: 18px;
  color: #2F3740;
  background: #FFFFFF;
}
</style>
