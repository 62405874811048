var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "events" } },
    [
      _c(
        "div",
        { staticClass: "container", attrs: { id: "content" } },
        [
          _c("lesson-shortcut", {
            attrs: { "translation-key": "dates" },
            model: {
              value: _vm.LESSONS.DATES,
              callback: function($$v) {
                _vm.$set(_vm.LESSONS, "DATES", $$v)
              },
              expression: "LESSONS.DATES"
            }
          }),
          _vm._v(" "),
          _c("h1", { staticClass: "title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$translate("events.title")) + "\n    "
            )
          ]),
          _vm._v(" "),
          _vm.hasNoEvents
            ? _c("p", { staticClass: "margin-bottom-lg note" }, [
                _c("small", [
                  _vm._v(_vm._s(_vm.$translate("events.empty_event.note")))
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isFetchingEvents
            ? _c("div", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$translate("events.fetching_events")) +
                    "\n    "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.pendingReactivatedEvent, function(evt, idx) {
            return _c("event-card", {
              key: idx + 1,
              attrs: { idx: idx, payload: evt }
            })
          }),
          _vm._v(" "),
          _vm._l(_vm.groupByOtherEvents, function(month, mIdx) {
            return _c(
              "div",
              { key: mIdx + 1, staticClass: "other-events" },
              _vm._l(month, function(evt, eIdx) {
                return _c("event-card", {
                  key: eIdx + 1,
                  attrs: { idx: eIdx, payload: evt }
                })
              }),
              1
            )
          }),
          _vm._v(" "),
          !_vm.hasNoEvents
            ? _c(
                "div",
                {
                  staticClass:
                    "card-controls grey-text margin-top-md margin-bottom-md"
                },
                [
                  _c("div", { staticClass: "card-date left-align" }, [
                    _c("small", [
                      _vm._v(_vm._s(_vm.$translate("events.empty_event.tip")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.shouldDisplayConnectPromo
            ? _c("krypton-connect-promo-card", { attrs: { item: _vm.promo } })
            : _vm._e(),
          _vm._v(" "),
          _vm.shouldDisplayPrivatePlacePromo
            ? _c("private-place-promo-card")
            : _vm._e(),
          _vm._v(" "),
          _vm.shouldDisplayMultiLocationPromo
            ? _c("multi-location-promo-card")
            : _vm._e(),
          _vm._v(" "),
          _c("empty-event")
        ],
        2
      ),
      _vm._v(" "),
      _c("video-shortcut", {
        model: {
          value: _vm.LESSONS.DATES,
          callback: function($$v) {
            _vm.$set(_vm.LESSONS, "DATES", $$v)
          },
          expression: "LESSONS.DATES"
        }
      }),
      _vm._v(" "),
      _c("fixed-menu")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }