<template>
  <nav
    id="desktop-header"
    class="z-depth-0 login-nav"
  >
    <div class="site-logo">
      <a href="https://kryptonescort.com"><img
        class="valign-middle"
        src="/img/icons/kr-logo-144.png"
        width="56"
        style="border-radius: 4px; margin-right: 12px;"
      ><strong
        class="valign-middle"
        style="font-size: 20px; color: #2F3740;"
      >girl next door</strong></a>
    </div>
    <div
      v-if="selectedLocale === 'en'"
      class="links right-align"
    >
      <a href="https://kryptonescort.com/en/discovery/">{{ $translate('app.header.about') }}</a>
      <a
        href="https://kryptonescort.com/en/information/"
        style="margin: 0 12px 0 13px;"
      >{{ $translate('app.header.faq') }}</a>
      <a
        href="https://kryptonescort.com/en/new/"
        style="margin: 0 13px;"
      >{{ $translate('app.header.start_escort') }}</a>
      <a href="https://kryptonescort.com/blog/en/">{{ $translate('app.header.blog') }}</a>
    </div>
    <div
      v-else
      class="links right-align"
    >
      <a href="https://kryptonescort.com/entdecken/">{{ $translate('app.header.about') }}</a>
      <a
        href="https://kryptonescort.com/information/"
        style="margin: 0 12px 0 13px;"
      >{{ $translate('app.header.faq') }}</a>
      <a
        href="https://kryptonescort.com/new/"
        style="margin: 0 13px;"
      >{{ $translate('app.header.start_escort') }}</a>
      <a href="https://kryptonescort.com/blog/">{{ $translate('app.header.blog') }}</a>
    </div>
    <div class="language-selector right-align">
      <a
        href="#"
        class="padding-x-xs"
        :class="isGermanSelected"
        @click.prevent="setLanguage('de')"
      >DE</a><span class="lang-switch-link separator">|</span><a
        href="#"
        class="padding-x-xs"
        :class="isEnglishSelected"
        @click.prevent="setLanguage('en')"
      >EN</a>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    isEnglishSelected () {
      return {
        'krypton-pink-text': Lang.locale() === 'en'
      }
    },
    isGermanSelected () {
      return {
        'krypton-pink-text': Lang.locale() === 'de'
      }
    },
    selectedLocale () {
      return Lang.locale()
    }
  },
  methods: {
    setLanguage (lang) {
      localforage.setItem('app_lang', lang)
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
#desktop-header {
    background: transparent;
    display: flex;
    padding: 21px 47px 0px 24px;

    @media screen and (min-width: 1680px) {
      padding: 21px 11px 0px 0;
      max-width: 1428px;
      margin: auto;
    }

    .site-logo {
        flex: 7;
    }
    .links {
        flex: 13;
    }
    .links a,
    .language-selector a {
      font-family: Proxima Nova Semibold;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-smoothing: antialiased;
    }
    .material-icons {
        display: inline-block;
    }
    .language-selector {
        flex: 2;
        line-height: 58px;
        max-width: 105px;

        a {
          vertical-align: bottom;
          color: #a6b2bf;
          font-size: 15px;
        }
        .separator {
          color: #e1e6eb;
          height: 16px;
          font-size: 19px;
          vertical-align: middle;
          padding: 0 6px;
        }
    }
    .links {
        font-size: 20px;
        padding-top: 2px;
        margin-right: -2.5px;

        a {
            color: #000;
            margin: 0 12px;
        }
    }
    a {
        font-weight: 600;
    }
}
</style>
