import Store from '../stores/index.js'

import { buildParam } from '../helpers/api'

import Monitoring from '@/services/ama-tools/services/MonitoringService'
import { getUser, authenticateUsingMagicLink } from '@/services/AuthService.js'
import LocalizationService from '@/services/LocalizationService.js'

const ApiProvider = $h.getProvider('ApiProvider')
const UserProvider = $h.getProvider('UserProvider')
const RegisterSteps = $h.getConstant('registration/steps')
const LocaleProvider = $h.getProvider('LocaleProvider')

const axiosConfig = {
  timeout: 15000 // For some reason there are Auth API response that takes a long time to complete, we need to remove timeout restrictions for them.
}

function sendTracking (data) {
  if (data.authorization) {
    window.api.authorization = data.authorization
  }

  const isPWA =
    window.matchMedia('(display-mode: standalone)').matches ||
    window.matchMedia('(display-mode: fullscreen)').matches ||
    window.navigator.standalone === true ||
    window.location.search.indexOf('utm_source=homescreen') >= 0

  UserProvider.updateUsage(data.id, isPWA)
}

class AuthProvider {
  constructor () {
    this.api = {
      login_with_magic_link: buildParam('/login-with-link'),
      forgot_password: buildParam('/v2/forgot-password/request'),
      forgot_password_validate: buildParam('/v2/forgot-password/validate'),
      forgot_password_reset: buildParam('/v2/forgot-password/reset')
    }
  }

  login (params) {
    return new Promise((resolve, reject) => {
      window.api.post({
        url: ApiProvider.login(),
        payload: {
          email: params.email,
          password: params.password,
          register: params.register ? params.register : 0
        }
      },
      (res) => {
        sendTracking(res.data.data)
        resolve(res.data)
      },
      (err) => err.response ? reject(err.response.data) : reject(err))
    })
  }

  loginWithMagicLink (uid) {
    return new Promise((resolve, reject) => {
      window.api.post({
        url: this.api.login_with_magic_link,
        payload: {
          uid: uid,
          register: 1
        }
      },
      (res) => {
        sendTracking(res.data.id, res.data.authorization)
        resolve(res.data)
      },
      (err) => reject(err.response.data))
    })
  }

  logout (callback) {
    Monitoring.sendLog('logout', Monitoring.LOG_TYPE.INFO, { info: 'will delete authtoken' })
    localforage.removeItem('authToken', callback)
  }

  forgotPassword (params) {
    return new Promise((resolve, reject) => {
      const payload = {
        email: params.email
      }
      window.api.post({ url: this.api.forgot_password, payload: payload, config: axiosConfig },
        (res) => resolve(res.data),
        (err) => reject(err.response.data))
    })
  }

  forgotPasswordValidate (params) {
    return new Promise((resolve, reject) => {
      const payload = {
        email: params.email,
        token: params.token
      }

      window.api.post({ url: this.api.forgot_password, payload: payload, config: axiosConfig },
        (res) => resolve(res.data),
        (err) => reject(err.response.data))
    })
  }

  forgotPasswordReset (params) {
    return new Promise((resolve, reject) => {
      const payload = { ...params }

      window.api.put({ url: this.api.forgot_password_reset, payload: payload, config: axiosConfig },
        (res) => resolve(res.data),
        (err) => reject(err.response.data))
    })
  }

  /**
     * Set authorization header
     */
  setAuthorizationHeader (token) {
    window.api.authorization = token
  }

  fetchUser () {
    return new Promise((resolve, reject) => {
      UserProvider.getUser()
        .then((res) => {
          const user = res.data

          Store.commit('setUser', user)

          let interviews = []

          if (LocaleProvider.isLocaleDe() && user.profiles.interview_de) {
            interviews = $h.string2json(user.profiles.interview_de)
          } else {
            interviews = $h.string2json(user.profiles.interview)
          }

          Store.commit('interview/SET_INTERVIEWS', interviews)

          document.dispatchEvent(new Event('onTokenChecked'))

          resolve()
        })
        .catch((err) => {
          this.setAuthorizationHeader()
          Monitoring.sendLog('fetch user error', Monitoring.LOG_TYPE.ERROR, { info: 'will delete authtoken' })
          localforage.removeItem('authToken', function () {
            document.dispatchEvent(new Event('onTokenChecked'))
          })
          reject(err)
        })
    })
  }

  /**
     * Validates auth token if present
     *
     * This method checks the validity of the auth token if present. If valid, this method will set the
     * token as the value for the Authorization header, while it will set is as null otherwise.
     * finally, this method broadcasts an event that signals completion of token checking.
     * This method should be used on app's startup/boot.
     */
  validateToken () {
    localforage.getItem('authToken', (err, token) => {
      if (err !== null || token === null) {
        this.setAuthorizationHeader()
        return document.dispatchEvent(new Event('onTokenChecked'))
      }

      let decryptedToken = token

      // Encrypt token and save to cache
      if (decryptedToken.indexOf('Basic') >= 0) {
        localforage.setItem('authToken', btoa(token))
      } else {
        if (/^[\x00-\x7F]+$/.test(token)) {
          decryptedToken = atob(token)
        } else {
          // Force logout
          this.setAuthorizationHeader()
          Monitoring.sendLog('token validation failed', Monitoring.LOG_TYPE.INFO, { info: 'will delete authtoken' })
          return localforage.removeItem('authToken', function () {
            document.dispatchEvent(new Event('onTokenChecked'))
          })
        }
      }

      this.setAuthorizationHeader(decryptedToken)
    })

    localforage.getItem('user_id', (err, userID) => {
      if (err !== null) {
        sendTracking({ id: userID })
      }
      this.fetchUser()
    })
  }

  async authUsingMagicLink (code) {
    const setInterviews = (user) => {
      let interviews = []
      if (LocaleProvider.isLocaleDe() && user.profiles.interview_de) {
        interviews = $h.string2json(user.profiles.interview_de)
      } else {
        interviews = $h.string2json(user.profiles.interview)
      }

      Store.commit('interview/SET_INTERVIEWS', interviews)
    }

    try {
      const response = await authenticateUsingMagicLink({ code })
      const authHeaderToken = response.authorization
      this.setAuthorizationHeader(authHeaderToken)
      await window.localforage.setItem('authToken', btoa(authHeaderToken))
      const user = await getUser()
      Store.commit('setUser', user)
      LocalizationService.setLocale(user.app_lang.toLowerCase())
      setInterviews(user)
      document.dispatchEvent(new Event('onTokenChecked'))
    } catch (err) {
      console.error('ERROR CATCHED', err.message)
      this.setAuthorizationHeader()
      localforage.removeItem('authToken', function () {
        document.dispatchEvent(new Event('onTokenChecked'))
      })

      return false
    }
  }

  /**
     * Redirect user if already authenticated
     *
     * To be used as route guard, this method will redirect user if they are already authenticated.
     * Otherwise, it will resume to load the state normally. Ideal as login route guard.
     */
  redirectIfAuthenticated (to, from, next) {
    var onTokenChecked = () => {
      if (window.api.Authorization == null) {
        next()
      } else {
        if (Store.state.user.register_progress === 0) {
          next({ name: 'dashboard' })
        } else {
          next(RegisterSteps[Store.state.user.register_progress])
        }
      }
    }

    if (window.api.headers.hasOwnProperty('Authorization')) {
      onTokenChecked()
    }

    document.addEventListener('onTokenChecked', onTokenChecked)
  }

  /**
     * Redirect user if not authenticated
     *
     * To be used as route guard, this method will redirect user if they are not authenticated.
     * Otherwise, it will resume to load state normally. Ideal as route guard for protected states such as dashboard.
     */
  redirectNotAuthenticated (to, from, next) {
    var accessDenied = (next) => {
      window.NotificationPlugin.toast('You are not allowed to access the module.', 5000)
      next({ name: 'dashboard' })
    }
    var onTokenChecked = () => {
      if (window.api.authorization == null) {
        next({ name: 'login' })
      } else {
        if (Store.state.user.register_progress == 0) {
          Lang.setLocale(Store.state.user.app_lang)
          window.i18n.locale = Store.state.user.app_lang

          // Route guard check on specific modules
          switch (to.name) {
            case 'events':
              if (Store.state.user.events_enabled === 1) {
                next()
              } else {
                accessDenied(next)
              }
              break
            case 'availability':
              if (Store.state.user.availability_enabled === 1) {
                next()
              } else {
                accessDenied(next)
              }
              break
            default:
              next()
          }

          // agree_terms === 2 - If enabled
          if (Store.state.user.agree_terms !== AGREEMENT_TERM.ENABLED) {
            next()
          } else {
            if (to.name !== 'legal-terms-conditions') {
              next({ name: 'legal-terms-conditions' })
            } else {
              next()
            }
          }
        } else {
          next(RegisterSteps[Store.state.user.register_progress])
        }
      }
    }

    if (window.api.headers.hasOwnProperty('Authorization')) {
      onTokenChecked()
    }

    document.addEventListener('onTokenChecked', onTokenChecked)
  }
}

export default new AuthProvider()
