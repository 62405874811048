
const FeatureFlagPlugin = (store) => {
  if (!window.Feature) {
    require('../../factories/FeatureFlagFactory').CreateFeatureFlagService({
      update: [(fflag) => {
        store.commit('featureflag/setFeatureFlag', fflag.getAllEnabledFlags())
      }]
    })
  } else {
    document.getRootNode().addEventListener('FeatureUpdate', (evt) => {
      store.commit('featureflag/setFeatureFlag', evt.detail.getAllEnabledFlags())
    })
  }
  store.commit('featureflag/setFeatureFlag', window.Feature.getAllEnabledFlags())
}

const FeatureFlagState = {
  featureFlag: []
}

const FeatureFlagMutations = {
  setFeatureFlag (state, featureFlag) {
    state.featureFlag = Array.isArray(featureFlag) ? featureFlag : []
  }
}

const FeatureFlagGetters = {
  isEnabled: (state) => (flag) => {
    return !!state.featureFlag.find(curr => flag === curr)
  },
  getVariant: (state, getters) => (flag) => {
    if (getters.isEnabled(flag)) {
      return window.Feature.getVariant(flag)
    } else {
      return {
        isEnabled: false,
        payload: {}
      }
    }
  }
}

const FeatureFlagStore = {
  namespaced: true,
  state: FeatureFlagState,
  getters: FeatureFlagGetters,
  mutations: FeatureFlagMutations
}

export { FeatureFlagPlugin, FeatureFlagState, FeatureFlagMutations, FeatureFlagGetters, FeatureFlagStore }
