<template>
  <div id="event-declined-uncomfortable" class="container center-align padding-x-xl padding-y-xl">
    <h1 class="title strong margin-top-none">
      {{ $translate('events.decline_popup.not_comfortable_title') }}
    </h1>
    <p class="margin-y-lg">
      <small>
        {{
          $translate('events.decline_popup.not_comfortable_note', {
            client_name: clientName
          })
        }}
      </small>
    </p>
    <slot name="input" />
  </div>
</template>

<script>
import eventDeclinedSubViewMixin from '@/features/events/mixins/eventDeclinedSubView.mixin'

export default {
  mixins: [eventDeclinedSubViewMixin]
}
</script>
