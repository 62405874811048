const AuthProvider = $h.getProvider('AuthProvider')
const UserProvider = $h.getProvider('UserProvider')
const RegisterSteps = $h.getConstant('registration/steps')
const LocaleProvider = $h.getProvider('LocaleProvider')

export default {
  methods: {

    /**
     * @method submit
     *
     * Submit login form values.
    */
    submit (next) {
      this.next = next
      this.error = null

      AuthProvider
        .login({
          email: this.email,
          password: this.password,
          register: 1
        })
        .then((register) => {
          this.storeToken(register.data.authorization)
        })
        .catch((err) => {
          if (err.data) {
            this.error = null
            if (err.data.email) {
              this.error = `${this.error ? `${this.error}, ` : ''}${err.data.email[0]}`
            }
            if (err.data.password) {
              this.error = `${this.error ? `${this.error}, ` : ''}${err.data.password[0]}`
            }
          }
          if (err.message) {
            this.error = `${this.error ? `${this.error}, ` : ''}${err.message}`
          }
        })
        .finally(() => {
          this.next()
        })

      setTimeout(() => {
        next()
      }, 15000)
    },

    /**
     * @method storeToken
     *
     * Store received token from the API to the local storage of the app.
    */
    storeToken (token) {
      AuthProvider.setAuthorizationHeader(token)

      localforage.setItem('authToken', btoa(token), this.onTokenStored)
    },

    sendTracking (userID) {
      localforage.setItem('user_id', userID)
      const isPWA =
        window.matchMedia('(display-mode: standalone)').matches ||
        window.matchMedia('(display-mode: fullscreen)').matches ||
        window.navigator.standalone === true ||
        window.location.search.indexOf('utm_source=homescreen') >= 0

      UserProvider.updateUsage(userID, isPWA)
    },

    /**
     * @method onTokenStored
     *
     * Callback for token when stored.
    */
    onTokenStored () {
      UserProvider
        .getUser()
        .then((res) => {
          const user = res.data
          let interviews = []

          this.$store.commit('setUser', user)

          if (LocaleProvider.isLocaleDe() && user.profiles.interview_de) {
            interviews = $h.string2json(user.profiles.interview_de)
          } else {
            interviews = $h.string2json(user.profiles.interview)
          }

          this.$store.commit('interview/SET_INTERVIEWS', interviews)

          if (user.register_progress === 0) {
            this.$router.push({ name: 'dashboard' })
          } else {
            this.$router.push(RegisterSteps[user.register_progress])
          }
        })
        .catch((err) => {
          this.error = err.message
          this.next()
        })
    },

    /**
     * @method loginWithMagicLink
     *
     * Execute login if the user uses magic link.
    */
    loginWithMagicLink () {
      if (!this.$route.query.ml) {
        return false
      }

      AuthProvider
        .loginWithMagicLink(this.$route.query.ml)
        .then((res) => {
          this.storeToken(res.data.authorization)
        })
        .catch((err) => {
          this.error = err.message
        })
    }

  },
  data: () => ({
    next: null,
    error: null,
    email: null,
    password: null
  }),
  mounted () {
    if (this.$route.query.ml) {
      this.loginWithMagicLink()
    }

    if (this.$route.query['reset-password']) {
      this.error = this.$translate('forgotPassword.password_reset_successful')
    }
  }
}
