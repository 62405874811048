<template>
  <div id="ready-to-go-event-card" class="apv-card">
    <div class="card-header">
      <div class="card-header__flex">
        <pre class="date uppercase">{{ date }}</pre>
      </div>
      <div class="grey-text card-header__flex card-header__flex--mid">
        <span class="schedule">
          <strong
            class="schedule__countdown lilac"
            :class="{
              'lilac--red': hasNotification,
              'lilac--green': payload.event_seen === 1
            }"
          >
            <span v-if="!isToday && !needsFeedback">{{ meetingStart }}</span>
            <span v-if="isToday && !needsFeedback">{{ meetingCountdown }}</span>
            <span
              v-if="!isPending && !isCancelled && needsFeedback"
            >{{ feedbackConfirmationCountdown }}</span>
          </strong>
          <small>
            <span v-if="isToday && !isPending && !isCancelled">{{ meetingStart }}</span>
            {{ $translate('events.labels.for') }} {{ payload.meeting_duration }}h
          </small>
        </span>
        <div v-if="!isCancelled && !isRejected && needsFeedback" class="location">
          <p class="margin-none">{{ $translate('events.feedback_required') }}</p>
        </div>
        <div v-if="!isCancelled && !isRejected && !needsFeedback" class="location">
          <p v-if="isVideoDateMeeting" class="margin-none krypton-purple-text">{{ $translate('events.video_date.title') }}</p>
          <div
            v-else-if="isPrivatePlaceOrMultiLocationMeeting"
            class="event-details-hotel-name">
            <i
              v-if="isPrivatePlaceMeeting"
              data-test="event-details-pp-icon"
              class="material-icons"
            >
              person_pin_circle
            </i>
            <multi-location-event-icon v-if="isMultiLocationMeeting" />
            <span v-if="isMultiLocationMeeting">{{ payload._city_name }}</span>
            <span v-else>{{ payload._hotel_name }}</span>
          </div>
          <p v-else class="margin-none">{{ payload._hotel_name }}</p>
        </div>
      </div>
      <div class="card-header__flex">
        <strong class="amount">
          <currency />{{ payload.amount_payable }}
          <div v-if="isMultiLocationMeeting">+ <currency />{{ payload.travel_cost }}</div>
        </strong>
      </div>
    </div>
  </div>
</template>

<script>
import EventCardViewMixin from '../../mixins/event-card-view'

export default {
  mixins: [EventCardViewMixin]
}
</script>
