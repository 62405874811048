<template>
  <div v-if="dropdown && optsCuisine.length" id="taste" class="wrapper">
    <template>
      <div class="content__area">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <p class="content__stepper content__stepper--mobile"> {{ stepperLabel }}</p>
        <h2 class="content__title content__title--desktop">{{ $translate('register.personality.title.taste') }}</h2>
      </div>

      <div class="content__area drawer">
        <div class="content">
          <p class="content__stepper content__stepper--desktop"> {{ stepperLabel }}</p>
          <h2 class="content__title content__title--mobile">{{ $translate('register.personality.title.taste') }}</h2>

          <div class="drawer-icon --no-selected-text center-align">
            <img src="/img/registration/personality/taste.svg" alt="girl and boy dining">
          </div>

          <!-- Cuisine -->
          <md-select
            v-model="payload.cuisine"
            desktop-display-mode="split"
            :force-mobile="true"
            class="textfield-box-lg"
            :multiple="true"
            :limit="3"
            :label="$translate('register.personality.taste.cuisine_label')"
            :placeholder="$translate('details.cuisine')"
            :default="$translate('register.personality.taste.select_cuisine')"
          >
            <option v-for="(opt, i) in optsCuisine" :key="`opt-cuisine-${i}`" slot="options" :value="opt.key">{{ opt.content }}</option>
            <div v-show="errors.has('cuisine')" slot="errors" class="input-helper red-text left-align">
              {{ errors.first('cuisine') }}
            </div>
          </md-select>
          <!-- Drinks -->
          <md-textarea
            v-model="payload.drinks"
            type="multiline"
            :force-active="false"
            :maxlength="drinksMaxLength"
            :label="$translate('register.personality.taste.drinks_label')"
          >
            <div class="input-helper">
              <span class="right">{{ drinksLength }}</span>
              <div v-show="errors.has('drinks')" class="red-text left-align">
                {{ errors.first('drinks') }}
              </div>
            </div>
          </md-textarea>

          <register-navigator-v2
            :next-disabled="isCTADisabled"
            :total="total"
            :current="current"
            @prev.prevent="gotoPrev"
            @next.prevent="submitForm"
          />
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import { isEmpty } from '@/constants/helpers.js'
const RegisterProvider = $h.getProvider('RegisterProvider')
const LocaleProvider = $h.getProvider('LocaleProvider')

const DRINKS_MAX_LENGTH = 60

export default {
  name: 'RegisterPersonalityTaste',
  props: ['total', 'current', 'stepperLabel'],
  data () {
    return {
      drinksMaxLength: DRINKS_MAX_LENGTH,
      optsCuisine: [],
      payload: {
        cuisine: [],
        drinks: ''
      }
    }
  },
  computed: {
    isCTADisabled () {
      return (this.payload.cuisine.length === 0) || isEmpty(this.payload.drinks)
    },
    drinksLength () {
      if (!this.payload.drinks) { return `0/${this.drinksMaxLength}` } else { return `${this.payload.drinks.length}/${this.drinksMaxLength}` }
    },
    dropdown () {
      return this.$store.getters.getDropdown
    },
    user () {
      return this.$store.getters.getUser
    },
    isValidForm () {
      return this.payload.cuisine.length && this.payload.drinks
    }
  },
  watch: {
    dropdown (newval, oldval) {
      this.initDropdown()
    }
  },
  mounted () {
    this.initDropdown()
    this.initUser()
  },
  methods: {
    gotoPrev () {
      this.$router.push({ name: 'register-personality', params: { type: 'intellect' } })
    },
    scrollDown () {
      if (this.FEATURE_APV_REG_REDESIGN) {
        return
      }

      /** We can deprecate this method as it causes problem on mobile */
      setTimeout(() => {
        window.scroll({ top: 1000, left: 0, behavior: 'smooth' })
      }, 300)
    },
    submitForm () {
      this.errors.clear()

      if (this.getErrors()) {
        return false
      }

      var payload = {
        cuisine: this.payload.cuisine,
        drinks: this.payload.drinks
      }

      RegisterProvider
        .submitField('personality/taste', payload)
        .then((res) => {
          this.$store.commit('setUser', res.data)
          this.$router.push({ name: 'register-personality', params: { type: 'style' } })
        })
    },
    getErrors () {
      if (this.isValidForm) {
        return false
      }

      if (!this.payload.cuisine.length) {
        this.errors.add({
          field: 'cuisine',
          msg: this.$translate('errors.select', {
            field: this.$translate('register.personality.taste.cuisine')
          })
        })
      }

      if (!this.payload.drinks) {
        const fieldErrorMsg = this.$translate('errors.input2', {
          field: this.$translate('register.personality.taste.drinks')
        })

        this.errors.add({
          field: 'drinks',
          msg: fieldErrorMsg
        })
      }

      return true
    },
    initDropdown () {
      if (!this.dropdown) return

      this.optsCuisine = $h.obj2arr(this.dropdown.cuisine)
    },
    parseDrinks () {
      if (LocaleProvider.isLocaleDe() && this.user.profiles.drinks_de) return this.user.profiles.drinks_de
      return this.user.profiles.drinks
    },
    initUser () {
      if (!this.user) return

      this.payload.cuisine = this.user.profiles.cuisine ? $h.string2json(this.user.profiles.cuisine) : []
      this.payload.drinks = this.parseDrinks()
    }
  }
}
</script>
