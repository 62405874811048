var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "photo-upload",
      staticClass: "photo-gallery-v2-photo empty",
      on: { click: _vm.uploadPhoto }
    },
    [_vm._m(0), _vm._v(" "), _c("div", { staticClass: "photo-add" })]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "add-more center-align", attrs: { href: "#" } },
      [_c("div", { staticClass: "add-btn" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }