var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card",
      attrs: { id: "inbox-list-item" },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.goToType($event)
        }
      }
    },
    [
      _c("div", { staticClass: "card-content" }, [
        _c("div", { staticClass: "card-header" }, [
          _vm.isKryptonConnectType
            ? _c("h5", { staticClass: "type" }, [
                _c("i", { staticClass: "mdi valign-middle mdi-forum" }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$translate("inbox.inbox_item.krypton_connect.title")
                    ) +
                    "\n      "
                )
              ])
            : _vm.isReviewType
            ? _c("h5", { staticClass: "type" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$translate("inbox.inbox_item.review.title", {
                        name: _vm.computedSenderName,
                        age: _vm.computedSenderAge
                      })
                    ) +
                    "\n      "
                )
              ])
            : _vm.isPhotoLikeType
            ? _c("h5", { staticClass: "type" }, [
                _c("i", { staticClass: "mdi valign-middle mdi-image" }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$translate("inbox.inbox_item.photo_like.title")
                    ) +
                    "\n      "
                )
              ])
            : _vm.isFavoriteLikeType
            ? _c("h5", { staticClass: "type" }, [
                _c("i", { staticClass: "mdi valign-middle mdi-image" }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$translate("inbox.inbox_item.favorite_like.title")
                    ) +
                    "\n      "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "datetime valign-middle",
              class: { "lilac-left lilac--red": _vm.hasNotification }
            },
            [_vm._v(_vm._s(_vm.lastUpdate))]
          )
        ]),
        _vm._v(" "),
        _vm.isReviewType
          ? _c(
              "div",
              { staticClass: "review-rating left-align" },
              _vm._l(_vm.MAX_RATING, function(n) {
                return _c("i", {
                  key: n,
                  staticClass: "mdi",
                  class: _vm.ratingIconClass(n, _vm.rating)
                })
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isKryptonConnectType
          ? _c("p", { staticClass: "left-align" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$translate("inbox.inbox_item.krypton_connect.content", {
                      client_details: _vm.computedSenderDetails
                    })
                  ) +
                  "\n    "
              )
            ])
          : _vm.isReviewType
          ? _c("div", [
              _vm.showReview
                ? _c("p", { staticClass: "left-align" }, [
                    _vm._v(_vm._s(_vm.content))
                  ])
                : _c("div", { staticClass: "message" }, [
                    _c("span", { staticClass: "blur-content" }, [
                      _vm._v(
                        "\n          Curabitur eleifend felis a hendrerit ultricies. Donec at semper justo, et hendrerit metus. Fusce et tellus sapien. Duis nulla enim, egestas ac imperdiet ut, vulputate pellentesque turpis.\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { id: "feedback-hint", href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.showHint($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$translate("feedback.cannot_see_comment_text")
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ])
            ])
          : _vm.isPhotoLikeType
          ? _c("p", { staticClass: "left-align" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$translate("inbox.inbox_item.photo_like.content", {
                      client_details: _vm.computedSenderDetails
                    })
                  ) +
                  "\n    "
              )
            ])
          : _vm.isFavoriteLikeType
          ? _c("p", { staticClass: "left-align" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$translate("inbox.inbox_item.favorite_like.content", {
                      client_details: _vm.computedSenderDetails
                    })
                  ) +
                  "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "card-footer" }, [
          _c("div", { staticClass: "left-content left-align" }, [
            _vm.isKryptonConnectType
              ? _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$translate(
                          "inbox.inbox_item.krypton_connect.message_count",
                          { count: _vm.messageCount }
                        )
                      ) +
                      "\n        "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.isKryptonConnectType || _vm.isLikeType
            ? _c(
                "a",
                {
                  staticClass: "cta-button padding-sm waves-effect waves-dark",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onClick($event)
                    }
                  }
                },
                [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$translate("inbox.cta_buttons.reply")))
                  ])
                ]
              )
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }