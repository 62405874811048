<template>
  <div
    id="survey"
    class="wrapper"
  >
    <div class="content__area">
      <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
      <h2 class="content__title content__title--desktop">{{ $translate('register.survey.title') }}</h2>
    </div>

    <div class="content__area">
      <div class="content">
        <h2 class="content__title content__title--mobile-new">{{ $translate('register.survey.title') }}</h2>

        <div class="diamond-container">
          <!-- Diamond container here -->
          <img :src="getIconBasedOnSelectedValue(1)">
          <div class="center-diamond-container">
            <img :src="getIconBasedOnSelectedValue(2)">
            <div class="percentage-text">{{ getPercentage }}</div>
          </div>
          <img :src="getIconBasedOnSelectedValue(3)">
        </div>

        <div class="survey-options-container">
          <!-- Survey option items here -->
          <div
            v-for="item in [1, 2, 3]"
            :key="item"
            class="survey-option-item"
            :class="{
              'selected': isItemSelected(item)
            }"
            @click="selectSurveyOptionItem(item)"
          >
            <div class="title">{{ $translate(`register.survey.options.${item}.title`) }}</div>
            <div class="description">{{ $translate(`register.survey.options.${item}.desc`) }}</div>
          </div>
        </div>

        <div class="actions flex-grid space-between">
          <button
            :disabled="nextDisabled || isLoading"
            class="btn btn-primary btn-verify"
            @click.prevent="submit"
          >{{ $translate('register.survey.cta') }}</button>
        </div>
      </div>
    </div>
  </div></template>

<script>
const { submitField } = require('@/services/RegisterService').default
const RegisterProvider = $h.getProvider('RegisterProvider')

export default {
  data: () => ({
    selectedSurveyItem: [],
    isLoading: false
  }),
  computed: {
    nextDisabled () {
      return this.selectedSurveyItem.length === 0
    },
    getPercentage () {
      let percentage = 0
      switch (this.selectedSurveyItem.length) {
        case 1:
          percentage = 33
          break
        case 2:
          percentage = 67
          break
        case 3:
          percentage = 100
          break
        default:
          percentage = 0
          break
      }

      return `${percentage}%`
    }
  },
  methods: {
    publishProvider () {
      const payload = {}
      RegisterProvider.publish(payload)
        .then((res) => {
          const showCTAButton = parseInt(res.data?.show_cta_button ?? 0)
          this.$store.commit('setShowCTAButton', showCTAButton)
          this.$router.push({ name: 'register-complete' })
        })
        .catch((err) => {
          this.$notifications.toast(err.message, 5000)
        })
    },
    isItemSelected (key) {
      return this.selectedSurveyItem.includes(key)
    },
    getIconBasedOnSelectedValue (key) {
      return this.selectedSurveyItem.length >= key
        ? '/img/registration/survey/diamond-selected.svg'
        : '/img/registration/survey/diamond-unselected.svg'
    },
    selectSurveyOptionItem (key) {
      if (this.selectedSurveyItem.includes(key)) {
        const itemIndex = this.selectedSurveyItem.indexOf(key)
        this.selectedSurveyItem.splice(itemIndex, 1)
      } else {
        this.selectedSurveyItem.push(key)
      }
    },
    submit () {
      const payload = {
        survey_options: this.selectedSurveyItem
      }

      this.isLoading = true

      submitField('survey', payload)
        .then((res) => {
          this.$store.commit('setUser', res.data)
          this.publishProvider()
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
#survey {

  .content__title--mobile-new {
    color: #2E353D;
    font-size: 32px;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 32px;
    line-height: 36px;
  }
}

.diamond-container {
  height: 137px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  .center-diamond-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .percentage-text {
      font-family: 'Proxima Nova Semibold';
      color: #2E353D;
      font-size: 38px;
    }
  }
}

.survey-option-item {

  & {
    border: 1px solid #CED6E0;
    padding: 20px 16px;
    font-family: 'Proxima Nova';
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 12px;
    margin-bottom: 12px;
  }

  &.selected{
    background: #F7FAFF;
    border: 2px solid #2F3740;
  }

  .title {
    font-family: 'Proxima Nova Semibold';
    color: #2E353D;
    font-size: 18px;
  }

  .description {
    color: #2F3740;
    font-size: 16px;
  }

  img {
    width: 120px;
  }
}
</style>
