<template>
  <div id="lesson-screen-video" class="lesson-screen">
    <div class="wrapper">
      <div class="content__drawer content__drawer-filled">
        <div class="content lesson-screen--container">
          <div v-if="isVideoElementShown" class="lesson-screen--content">
            <div v-if="isVideoPlayerShown" data-test="video-player" controls autoplay class="video-content--video-player">
              <video controls autoplay controlsList="nodownload" @ended="showReplayScreen">
                <source
                  v-if="PHOTO_FALLBACK_A2_2720"
                  data-test="video-source"
                  :src="videoSource(VIDEOS[openedLessonName])"
                  type="video/mp4">
                <source v-else data-test="video-source" :src="videoSource(VIDEOS[openedLessonName])" type="video/mp4">
              </video>
            </div>
            <div v-if="isReplayScreenShown" class="lesson-screen--video-replay">
              <div class="lesson-screen--video-replay-control">
                <img data-test="video-replay" src="@/features/onboarding-lesson/assets/onboarding-lesson-replay.svg" alt="replay icon"
                     @click.prevent="replay">
                {{ $t('replay') }}
              </div>
            </div>
          </div>
          <div v-else class="lesson-screen--content">
            <div class="lesson-screen--header">
              <div class="photos-remark--container">
                <div class="photos-remark--content">
                  <img class="img-icon" src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-video-player-v2.svg" alt="video player icon">
                  <span>{{ $t(`${openedLessonName}.header_title`) }}</span>
                </div>
              </div>
              <div
                data-test="video-duration"
                class="examples-text--container"
              >
                {{ $t('video_duration', { duration: videoDuration(VIDEOS[openedLessonName]) }) }}
              </div>
            </div>
            <div class="video-content">
              <div class="video-thumbnail" @click.prevent="showVideoPlayer">
                <img
                  v-if="!isVideoPlayerShown"
                  data-test="video-thumbnail"
                  src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-video-thumbnail-v2.svg" alt="video thumbnail"
                >
              </div>
              <h2>{{ $t(`${openedLessonName}.video_title`) }}</h2>
              <p>{{ $t(`${openedLessonName}.video_description`) }}</p>
            </div>
          </div>
        </div>

        <navigator
          :current="currentPage"
          no-prev
          :next="$t('messages.globals.continue')"
          :next-class="'action-btn__black'"
          :total="totalPages"
          :step-class="'--purple'"
          @next.prevent="gotoNextLessonScreen"
        />
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/onboarding-lesson/translations/video.json"></i18n>

<script>
import { mapGetters } from 'vuex'

import navigator from '@components/v2/utilities/navigator.vue'
import { VIDEOS } from '@/features/onboarding-lesson/constants'
import { OnboardingLessons } from '@/mixins/onboarding'

export default {
  components: {
    navigator
  },
  mixins: [
    OnboardingLessons
  ],
  data () {
    return {
      VIDEOS,
      isVideoPlayerShown: false,
      isReplayScreenShown: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    }),
    PHOTO_FALLBACK_A2_2720 () {
      return this.$store.getters['featureflag/isEnabled']('PHOTO_FALLBACK_A2_2720')
    },
    isVideoElementShown () {
      return this.isVideoPlayerShown || this.isReplayScreenShown
    },
    videoSource () {
      return (video) => {
        const countryCode = this.user?.profiles?.country_code?.toLowerCase() ?? 'de'

        if (video.hasOwnProperty(countryCode) && video[countryCode].hasOwnProperty(this.$i18n.locale)) {
          if (this.PHOTO_FALLBACK_A2_2720) {
            return video[countryCode].photo_fall_back_A2_2720[this.$i18n.locale]
          }

          return video[countryCode][this.$i18n.locale]
        } else if (video.hasOwnProperty(countryCode)) {
          if (this.PHOTO_FALLBACK_A2_2720) {
            return video[countryCode].photo_fall_back_A2_2720
          }

          return video[countryCode].de
        }

        if (countryCode !== 'de' && video.hasOwnProperty(`url_${countryCode}`)) {
          if (this.PHOTO_FALLBACK_A2_2720) {
            if (countryCode == 'gb') {
              return video.photo_fall_back_A2_2720_gb
            } else {
              return video.photo_fall_back_A2_2720_en
            }
          }

          return video[`url_${countryCode}`]
        }

        if (this.$i18n.locale === 'en') {
          if (this.PHOTO_FALLBACK_A2_2720) {
            return video.photo_fall_back_A2_2720_en
          }

          return video.url_en
        }

        if (this.PHOTO_FALLBACK_A2_2720) {
          return video.photo_fall_back_A2_2720
        }

        return video.url
      }
    },
    videoDuration () {
      return (video) => {
        const countryCode = this.user?.profiles?.country_code?.toLowerCase() ?? 'de'

        if (countryCode !== 'de') {
          if (countryCode === 'gb') {
            return video.duration_gb
          }
          return video.duration_en
        }

        if (this.$i18n.locale === 'en') {
          return video.duration_en
        }

        return video.duration
      }
    }
  },
  methods: {
    showVideoPlayer () {
      this.isVideoPlayerShown = 1
    },
    showReplayScreen () {
      this.isVideoPlayerShown = 0
      this.isReplayScreenShown = 1
    },
    replay () {
      this.isReplayScreenShown = 0
      this.isVideoPlayerShown = 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/features/onboarding-lesson/sass/styles";

#lesson-screen-video {
  .lesson-screen--container {
    .lesson-screen--content {
      .video-content--video-player {
        display: block;
        width: 100%;
        max-width: 327px;
        padding-top: 177.777777%;
        position: relative;
        margin: 0 auto 16px;

        video {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }
      .lesson-screen--video-replay {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 327px;
        height: calc(100vh - 124px);
        max-height: 543px;
        background: transparent linear-gradient(212deg, #D300C5 0%, #7638FA 100%) 0% 0% no-repeat padding-box;
        border-radius: 8px;
        margin: 0 auto;
        opacity: 0.7;

        .lesson-screen--video-replay-control {
          text-align: center;
          font-size: 14px;
          color: #fff;

          img {
            display: block;
            margin: 0 auto 8px;
            cursor: pointer;
          }
        }
      }
      .lesson-screen--header {
          margin-bottom: 144px;
      }
      .video-content {
        img {
          display: block;
          margin: 0 auto 16px;
          width: 100%;
          cursor: pointer;
        }
        h2 {
            margin: 0 0 8px;
            font-size: 18px;
            color: #2E353D;
        }
        p {
            margin: 0;
            font-size: 16px;
            color: #78838F;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .lesson-screen--container {
      .lesson-screen--content {
        .lesson-screen--header {
          margin-bottom: 24px;
        }

        .video-content--video-player {
          padding-top: 100%;
        }
      }
    }
  }

  .video-thumbnail {
    width: 100%;
    height: 185px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent linear-gradient(241deg, #D300C5 0%, #7638FA 100%) 0% 0% no-repeat padding-box;
    opacity: .7;
    margin: 140px auto 24px;
    cursor: pointer;

    img {
      width: 80px;
      height: 64px;
    }
  }
}

</style>
