<template>
  <div
    class="slide-to-unlock"
    :class="{ 'is-sending': isSending, 'reversed': isReversed }"
  >
    <label v-if="!isSending">{{ $props.label }}</label>
    <div
      v-else
      class="preloader-wrapper small active"
    >
      <div class="spinner-layer spinner-green-only">
        <div class="circle-clipper left">
          <div class="circle" />
        </div><div class="gap-patch">
          <div class="circle" />
        </div><div class="circle-clipper right">
          <div class="circle" />
        </div>
      </div>
    </div>
    <input
      type="range"
      :min="min"
      :max="max"
      :value="value"
      :disabled="disabled"
      v-bind="$attrs"
      class="pullee"
      :class="{ 'reversed': isReversed }"
      v-on="inputListeners"
    >
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Number, default: 0 },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    isSending: { type: Boolean, default: false },
    isReversed: { type: Boolean, default: false }
  },
  computed: {
    inputListeners: function () {
      var vm = this
      // `Object.assign` merges objects together to form a new object
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit('input', Number(event.target.value))
          }
        }
      )
    }
  }
}
</script>
