<template>
  <div class="edit-button">
    <a
      href="#"
      class="valign-middle waves-effect waves-dark"
      :class="{
        'padding-sm': !redesign,
        'padding-md': redesign
      }"
      @click.prevent="fire"
    >
      <i v-if="!redesign" class="material-icons">edit</i>
      <strong>{{ btnLabel }}</strong>
    </a>
  </div>
</template>

<script>
export default {
  props: [
    'label',
    'go',
    'push',
    'redesign'
  ],
  computed: {
    btnLabel () {
      return (this.label) ? this.label : this.$translate('app.edit')
    }
  },
  methods: {
    fire ($e) {
      if (this.push) { this.$router.push(this.push) }
      if (this.go) { this.$router.go(this.go) }
      this.$emit('click', $e)
    }
  }
}
</script>
