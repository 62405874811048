export function buildParam (rawUrl, rawObj = null) {
  let url = rawUrl
  let obj = rawObj

  if (url[0] === '/') {
    url = process.env.KRYPTON_PROVIDER_API + url
  }

  var round = 0
  var param = null

  if (obj === null) obj = {}

  obj.locale = window.i18n.locale

  for (var key in obj) {
    if (!obj.hasOwnProperty(key)) {
      continue
    }

    param = (!round) ? '?' : param + '&'
    param = param + key + '=' + obj[key]
    round++
  }

  return url + param
}

export default {
  buildParam
}
