<template>
  <div id="billing-unpaid" class="billing-tab">
    <template v-if="!isLoading">
      <div class="billing-bulletin" data-test="text-explanation">
        <div class="billing-bulletin__title">{{ infoBoxTitle }}</div>
        <p class="billing-bulletin__content">{{ infoBoxContent }}</p>
      </div>
      <div v-if="items.length === 0" class="billing-placeholder" data-test="billing-placeholder">
        <img src="/img/billing/billing-placeholder.svg" alt="no-invoice-placeholder">
        <div class="billing-placeholder__text">{{ $t('unpaid.empty_placeholder') }}</div>
      </div>
      <div v-else class="billing-items" data-test="billing-items">
        <billing-item
          v-for="(item, index) in items"
          :key="item.invoice_number"
          :invoice="item"
          :index="index"
          @item-collapse="toggleItem"
          @show-bank-details="showModal"
        />
      </div>
    </template>

    <template v-else>
      <infinite-loading
        ref="infiniteLoader"
        direction="top"
        spinner="spiral"
      />
    </template>
    <a v-if="showLoadMore" href="#" class="btn btn-outlined display-block waves-effect" @click="loadMore">LOAD MORE</a>
    <billing-modal
      v-if="items.length > 0"
      :invoice-data="modalData"
      :bank-details="bankDetails"
      :currency="currency"
      @close="closeModal"
    />
  </div>
</template>

<i18n src="@/features/billing/translations/billing.json"></i18n>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import BillingModal from '@/features/billing/components/BillingModal.vue'
import BillingItem from '@/features/billing/components/BillingItem.vue'
import { getBillingUnpaid } from '@/features/billing/services/BillingServices'
import {
  DEFAULT_BILLING_OVERVIEW,
  INVOICE,
  PAGINATION
} from '@/features/billing/constants/billing-overview-defaults'

export default {
  inject: {
    billingData: {
      default: () => ({
        billingOverview: DEFAULT_BILLING_OVERVIEW
      })
    }
  },
  components: {
    BillingModal,
    BillingItem,
    InfiniteLoading
  },
  data: () => ({
    isLoading: false,
    modalData: null,
    pagination: PAGINATION,
    items: []
  }),
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    infoBoxTitle () {
      return this.$t('unpaid.info_box.title')
    },
    infoBoxContent () {
      return this.$t('unpaid.info_box.content')
    },
    showLoadMore () {
      return this.items.length > 0 && (this.pagination.current_page && this.pagination.current_page < this.pagination.pages)
    },
    bankDetails () {
      return this.billingData?.billingOverview?.bank_details ?? DEFAULT_BILLING_OVERVIEW.bank_details
    },
    currency () {
      return this.user?._currency ?? DEFAULT_BILLING_OVERVIEW.currency_symbol
    }
  },
  async mounted () {
    try {
      this.isLoading = true
      await this.fetchUnpaidInvoices(this.pagination.current_page)

      this.isLoading = false
    } catch (err) {
      console.log('err', err)
    }
  },
  methods: {
    showModal (index) {
      this.modalData = { ...this.items[index] }
    },
    closeModal () {
      this.modalData = null
    },
    toggleItem (index) {
      this.items = [...this.items].map((item, i) => {
        return {
          ...item,
          _expanded: this.isExpanded(index, item, i)
        }
      })
    },
    isCancelled (status) {
      return status === 2
    },
    isExpanded (itemIndex, item, loopIndex) {
      if (itemIndex === loopIndex && !this.isCancelled(item.status)) {
        return !item._expanded
      }

      return false
    },
    fetchUnpaidInvoices (page) {
      return getBillingUnpaid({ page })
        .then(data => {
          if (data?.data) {
            this.items = [...this.items, ...data.data].map((item) => ({
              ...INVOICE,
              ...item
            }))
          }

          if (data.metadata?.pagination) {
            this.pagination = { ...data.metadata.pagination }
          }
        })
    },
    async loadMore () {
      const page = this.pagination.current_page + 1
      try {
        await this.fetchUnpaidInvoices(page)
      } catch (err) {
        console.log('err', err)
      }
    }
  }
}
</script>
