<template>
  <a v-if="showShortcut"
     href="#"
     data-test="lesson-shortcut"
     class="lesson-shortcut"
     @click.prevent="onClickHandler"
  >
    <img src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-info-24px-baseline-v2.svg" alt="onboarding lesson info">
    <span>{{ $t(translationKey) }}</span>
  </a>
</template>

<i18n src="@/features/onboarding-lesson/translations/lesson-shortcut.json"></i18n>

<script>
import OnboardingProvider from '@/providers/OnboardingProvider'
import { OnboardingLessons } from '@/mixins/onboarding'

export default {
  mixins: [
    OnboardingLessons
  ],
  props: {
    translationKey: {
      type: String,
      default: 'photos'
    },
    value: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      showShortcut: false
    }
  },
  mounted () {
    this.initializeComponent()
  },
  methods: {
    onClickHandler () {
      switch (this.value) {
        case this.LESSONS.PHOTOS:
          this.openPhotosLesson()
          break
        case this.LESSONS.INTERVIEW:
          this.openInterviewLesson()
          break
        case this.LESSONS.AVAILABILITY:
          this.openAvailabilityLesson()
          break
        case this.LESSONS.DATES:
          this.openDatesLesson()
          break
        case this.LESSONS.FEEDBACK:
          this.openFeedbackLesson()
          break
        default:
          this.resetActiveLesson()
      }
    },
    async initializeComponent () {
      let activeLesson

      try {
        activeLesson = await OnboardingProvider.fetchActiveLesson()
      } catch (e) {
        activeLesson = {
          data: {
            show_bottom_sheet: false
          }
        }
      }

      this.showShortcut = activeLesson?.data?.show_bottom_sheet ?? false
    }
  }
}
</script>

<style lang="scss" scoped>
.lesson-shortcut {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 2px solid #7638FA;
    color: #7638FA;
    font-size: 16px;
    margin-bottom: 24px;

    img {
        margin-right: 8px;
    }
}
</style>
