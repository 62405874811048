var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShown
    ? _c(
        "div",
        { staticClass: "email-setting-modal" },
        [
          _c("notice-modal", {
            attrs: {
              "is-shown": _vm.isShown,
              title: _vm.$t("title"),
              text: _vm.$t("text", { email: _vm.email }),
              "confirm-text": _vm.$t("cta_text")
            },
            on: {
              confirm: function() {
                return _vm.$emit("confirm")
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }