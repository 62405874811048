var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth-view",
    {
      attrs: { data: _vm.data },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c("h2", [
                _vm._v(_vm._s(_vm.$t("requestMagicLink.successTitle")))
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c("div", { staticClass: "instructions" }, [
        _c("p", {
          staticClass: "padding-bottom-md",
          domProps: {
            textContent: _vm._s(
              _vm.$t("requestMagicLink.successText1", {
                number: _vm.phoneNumber
              })
            )
          }
        }),
        _vm._v(" "),
        _c("p", {
          staticClass: "padding-bottom-md",
          domProps: {
            textContent: _vm._s(_vm.$t("requestMagicLink.successText2"))
          }
        }),
        _vm._v(" "),
        _c("p", {
          domProps: {
            innerHTML: _vm._s(_vm.$t("requestMagicLink.successText3"))
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer-auth-nav" }, [
        _c(
          "div",
          { staticClass: "login-container" },
          [
            _c(
              "router-link",
              { staticClass: "login-link", attrs: { to: _vm.loginRoute } },
              [_vm._v(_vm._s(_vm.$t("requestMagicLink.login_with_password")))]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }