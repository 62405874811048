<template>
  <div
    id="potential_redesign"
    class="wrapper"
  >
    <div class="content__area">
      <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
      <h2 class="content__title content__title--desktop">{{ $translate('register.potential.title') }}</h2>
    </div>

    <div class="content__area vertically-scrollable">
      <div class="content container">
        <h2 class="content__title content__title--mobile">{{ $translate('register.potential.title') }}</h2>

        <div class="content-body">
          <img class="img-potential-lady" src="/img/registration/ic-potential-lady.svg">
          <div class="description">
            {{ $translate('register.potential.description') }}
          </div>
          <div class="img-container blur">
            <img class="img-potential-rates" :src="getImgByCountryCode" alt="potential rates">
          </div>
        </div>
        <div class="actions flex-grid justify-content-center">
          <button
            class="btn btn-primary"
            @click.prevent="$router.push({ name: 'register-verify' })">
            {{ $translate('register.potential.continue') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    getImgByCountryCode () {
      if (this.getHomeBaseCountry === 'GB') {
        return '/img/registration/potential/pound_blurred_potential_rates.svg'
      }

      if (this.getHomeBaseCountry === 'CH') {
        return '/img/registration/potential/chf_blurred_potential_rates.svg'
      }

      return '/img/registration/potential/euro_blurred_potential_rates.svg'
    },
    getHomeBaseCountry () {
      return this.$store.getters.getHomeBaseCountry
    }
  }
}
</script>

<style lang="scss" scoped>

  .iphone .vertically-scrollable {
    overflow-y: scroll !important;
    height: 100%;
    position: fixed !important;
    top: 0 !important;
  }

.content-body {
  text-align: center;

  .img-potential-lady {
    height: 212px;
  }

  .img-potential-rates {
    height: 120px;
  }

  .description, .img-container {
    margin-top: 24px;
  }

  @media (min-width: 992px) {
    .description {
      margin-top: 100px;
    }

    .img-container {
      margin-top: 32px;
    }
}

  .img-container.blur {
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px);
    padding-bottom: 136px; // This is for iPhone SE

    @media (min-width: 390px) {
      padding-bottom: 73px;
    }

    @media (min-width: 992px) {
      padding-bottom: 0;
    }
  }
  .img-container img {
    width: 100%;
  }
}
.btn {
  width: 100%;
  max-width: 327px;
}
</style>
