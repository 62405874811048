<template>
    <div class="stepper">
        <div class="grey-text text-darken-1">{{ stepperLabel }}</div>
        <div class="steps-container">
            <div class="step" v-for="i in total" :class="{'active': i <= current}"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'title',
        'current',
        'total',
    ],
    computed: {
        stepperLabel() {
            return this.title + ' ' + this.current + '/' + this.total;
        }
    }
}
</script>

<style lang="scss" scoped>
.stepper {
    .steps-container {
        & {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .step {
            height: 4px;
            flex: 1 1 auto;
            background-color: #c4c4c4;
        }
        .step:not(:last-child) {
            margin-right: 1px;
        }
        .step.active {
            background-color: #00E676;
        }
    }
}
</style>
