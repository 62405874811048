var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "multi-location-setting" } },
    [
      _c("md-new-navbar", { attrs: { "route-name": "setting" } }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "container",
          attrs: { id: "content", "data-test": "multilocation__main" }
        },
        [
          _c("h1", { staticClass: "title" }, [_vm._v(_vm._s(_vm.$t("title")))]),
          _vm._v(" "),
          _c("div", { staticClass: "description" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("shortFAQP1")))]),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { "data-test": "faqBtn", href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$router.push({ name: "faq.multi-location" })
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("shortFAQTxt")))]
            ),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.$t("shortFAQP2")))])
          ]),
          _vm._v(" "),
          _vm.hasMultiLocation
            ? _c(
                "div",
                {
                  staticClass: "switch-input margin-bottom-xl",
                  attrs: { "data-test": "feature-toggle" }
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("multiCity")))]),
                  _vm._v(" "),
                  _c("div", { staticClass: "switch switch-solid" }, [
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.isEnabled,
                            expression: "isEnabled"
                          }
                        ],
                        attrs: {
                          id: "switch",
                          "data-test": "feature-toggle-checkbox",
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.isEnabled)
                            ? _vm._i(_vm.isEnabled, null) > -1
                            : _vm.isEnabled
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.isEnabled,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.isEnabled = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.isEnabled = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.isEnabled = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "lever" })
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedCities.length && _vm.multiLocationEnabled
            ? _c("div", {
                staticClass: "selected-cities__title",
                domProps: { textContent: _vm._s(_vm.$t("citiesAndFees")) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedCities.length && _vm.multiLocationEnabled
            ? _c(
                "div",
                { staticClass: "cities" },
                [
                  _vm._l(_vm.selectedCities, function(city, key) {
                    return _c("div", { key: key, staticClass: "city-fee" }, [
                      _c("div", {
                        staticClass: "city-fee__city",
                        attrs: { "data-main-selected-city": city.name },
                        domProps: { textContent: _vm._s(city.name) }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "city-fee__fee" },
                        [_c("currency"), _vm._v(_vm._s(city.travel_cost))],
                        1
                      )
                    ])
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "cities-cta" }, [
                    _c("div", { staticClass: "cities-cta__cta-container" }, [
                      _vm.hasMultiLocation
                        ? _c("a", {
                            staticClass: "cities-cta__cta",
                            domProps: {
                              textContent: _vm._s(_vm.$t("cta.change"))
                            },
                            on: { click: _vm.editCitySelections }
                          })
                        : _vm._e()
                    ])
                  ])
                ],
                2
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.inCitiesSelectionStep
        ? _c("multi-location-settings-cities-selection", {
            attrs: {
              "selected-cities": _vm.selectedCities,
              "is-shown": _vm.inCitiesSelectionStep
            },
            on: { submit: _vm.proceedToFeeSetup, close: _vm.closeBottomSheet }
          })
        : _vm.inFeesSetupStep
        ? _c("multi-location-settings-fees-setup", {
            attrs: {
              "selected-cities": _vm.selectedCities,
              "is-shown": _vm.inFeesSetupStep
            },
            on: {
              cancel: _vm.proceedToCitySelection,
              submit: _vm.enableFeatureNew,
              close: _vm.closeBottomSheet
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }