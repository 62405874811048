var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "billing-form", attrs: { id: "billing-form" } },
    [
      _c("div", { staticClass: "container", attrs: { id: "content" } }, [
        _c("h5", [
          _vm._v(
            "\n      " + _vm._s(_vm.$translate("billing.form.title")) + "\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$translate("billing.form.explanation_text")) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "inputfield-container" },
          [
            _c("md-input", {
              attrs: {
                type: "text",
                label: _vm.$translate("billing.form.address"),
                required: ""
              },
              model: {
                value: _vm.billing.address,
                callback: function($$v) {
                  _vm.$set(_vm.billing, "address", $$v)
                },
                expression: "billing.address"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "inputfield-container" },
          [
            _c("md-input", {
              attrs: {
                type: "text",
                label: _vm.$translate("billing.form.postcode"),
                required: ""
              },
              model: {
                value: _vm.billing.post_code,
                callback: function($$v) {
                  _vm.$set(_vm.billing, "post_code", $$v)
                },
                expression: "billing.post_code"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "inputfield-container" },
          [
            _c("md-input", {
              staticClass: "textfield-box-lg",
              class: {
                valid: _vm.billing.city
              },
              attrs: {
                type: "text",
                label: _vm.$translate("billing.form.city")
              },
              model: {
                value: _vm.billing.city,
                callback: function($$v) {
                  _vm.$set(_vm.billing, "city", $$v)
                },
                expression: "billing.city"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "inputfield-container" },
          [
            _vm.countries.length > 0
              ? _c(
                  "md-select",
                  {
                    staticClass: "textfield-box-lg",
                    class: {
                      valid: _vm.billing.country
                    },
                    attrs: {
                      "force-mobile": true,
                      label: _vm.$translate("billing.form.country")
                    },
                    model: {
                      value: _vm.billing.country,
                      callback: function($$v) {
                        _vm.$set(_vm.billing, "country", $$v)
                      },
                      expression: "billing.country"
                    }
                  },
                  _vm._l(_vm.countries, function(country, idx) {
                    return _c(
                      "option",
                      {
                        key: idx,
                        attrs: { slot: "options" },
                        domProps: { value: country.alpha2Code },
                        slot: "options"
                      },
                      [_vm._v(_vm._s(country.name))]
                    )
                  }),
                  0
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "margin-bottom-x4" }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "action-btn action-btn__primary waves-effect waves-light display-block waves-effect waves-light cta-btn",
            attrs: { disabled: !_vm.isFormComplete },
            on: { click: _vm.doSubmit }
          },
          [
            !_vm.isSending
              ? _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$translate("billing.form.cta_button")) +
                      "\n      "
                  )
                ])
              : _c("div", { staticClass: "preloader-wrapper small active" }, [
                  _vm._m(0)
                ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner-layer spinner-green-only" }, [
      _c("div", { staticClass: "circle-clipper left" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _c("div", { staticClass: "gap-patch" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _c("div", { staticClass: "circle-clipper right" }, [
        _c("div", { staticClass: "circle" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }