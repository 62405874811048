<template>
  <div id="city" class="wrapper">
    <template>
      <div class="content__area">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <p class="content__stepper content__stepper--mobile"> {{ stepperLabel }}</p>
        <h2 class="content__title content__title--desktop">{{ $t('title') }}</h2>
      </div>
      <div class="content__area drawer">
        <div class="content">
          <p class="content__stepper content__stepper--desktop"> {{ stepperLabel }}</p>
          <h2 class="content__title content__title--mobile">{{ $t('title') }}</h2>

          <div class="drawer-icon center-align">
            <img src="/img/registration/basic/city.svg" alt="locator icon">
          </div>

          <div class="selected-text-container">
            <p v-if="selectedText" class="center-align selected-text">
              {{ selectedText }}
            </p>

            <p v-else> &nbsp; </p>
          </div>

          <div class="tooltip-container center-align">
            <a ref="tooltip"
               href="#"
               class="xsmall valign-middle tooltip padding-none"
            >
              <i class="material-icons krypton-pink-text">lightbulb_outline</i>
              <span class="underline">
                {{ $t('tooltip_button') }}
              </span>
            </a>
          </div>

          <md-select
            v-model="payload.country"
            :force-mobile="true"
            desktop-display-mode="split"
            class="textfield-box-lg"
            :label="$t('country')"
            :default="$t('select_country')"
            :placeholder="$t('country')"
            :listen-for-reinitiate-options="true"
            data-test="registration-country"
          >
            <option
              v-for="(country, i) in countries"
              :key="i"
              slot="options"
              :value="country.key"
            >{{ getCountryName(country) }}</option>
            <div
              v-if="errors.has('country')"
              slot="errors"
              class="input-helper red-text"
            >
              {{ errors.first('country') }}
            </div>
          </md-select>

          <div>
            <md-autocomplete
              v-model="payload.city"
              data-test="registration-city"
              :label="$t('input_label')"
              :options="cityOptions"
              :disabled="shouldDisableCityField"
              :force-mobile="true"
              @onInputFocus="onAutocompleteInputFocus"
            >
              <div
                v-if="errors.has('city')"
                slot="errors"
                class="input-helper red-text"
              >
                {{ errors.first('city') }}
              </div>
            </md-autocomplete>
          </div>

          <register-navigator-v2
            :total="total"
            :current="current"
            :next-disabled="isCTADisabled"
            @prev.prevent="gotoPrev"
            @next.prevent="submit" />

        </div>
      </div>
    </template>

  </div>
</template>

<i18n src="@components/register/translations/city.json"></i18n>

<script>
import { mapGetters } from 'vuex'
import { REGISTRATION_COUNTRIES } from '@/features/registration/constants.js'
import { getCities as getCitiesByCountryService } from '@/services/CityService.js'
const REG_CITY_CONST = Object.freeze({
  STARTING_INDEX: 0,
  ENDING_INDEX: 20,
  ERR_DISP_DELAY: 5000,
  MAX_CITY_OPTIONS_LENGTH: 20,
  CITY_REGISTRATION_STEP: 3
})

export default {
  props: ['total', 'current', 'stepperLabel'],
  data: () => ({
    countries: REGISTRATION_COUNTRIES,
    payload: {
      country: null,
      city: null
    },
    cityTooltip: null
  }),
  computed: {
    ...mapGetters({
      cities: 'getCities',
      homeBaseCountry: 'getHomeBaseCountry'
    }),
    isCTADisabled () {
      return this.isEmpty(this.payload.country) || this.isEmpty(this.payload.city)
    },
    profileCityId () {
      return this.user?.profiles?.city_id ?? null
    },
    cityOptions () {
      return this.cities
    },
    shouldDisableCityField () {
      return !this.countryPayload ||
      this.countryPayload === this.$t('select_country') ||
      !this.cities
    },
    user () {
      return this.$store.getters.getUser
    },
    userRegisterProgress () {
      return parseInt(this.user?.register_progress ?? 0)
    },
    selectedText () {
      if (!this.cities) {
        return null
      }

      var selectedOption = this.cities.find(c => c.id === this.payload.city)

      if (!selectedOption) {
        return null
      }

      return this.$t('city_selected', { selected: selectedOption.name })
    },
    countryPayload () {
      return this.payload?.country ?? null
    }
  },
  watch: {
    countryPayload () {
      if (this.countryPayload) {
        this.interceptEuropePayloadCountry()
        this.resetCityField()
        this.getCitiesByCountry(this.countryPayload)
          .then(() => this.setCityModelByExistingValueFromProfile())
      }
    }
  },
  mounted () {
    var chIndex = this.countries.findIndex(i => i.key === 'DE_CH')

    if (chIndex !== -1) {
      this.countries[chIndex].key = 'CH'
    }
    // Reinitiate options if something is changes on the option list
    this.reInitiateOptions()

    this.checkExistingValues()
    this.initializeTooltip()
  },
  methods: {
    reInitiateOptions () {
      this.$root.$emit('parent-reinitiate-options')
    },
    onAutocompleteInputFocus () {
      this.hideTooltip()
    },
    hideTooltip () {
      if (window['city-tooltip']) {
        window['city-tooltip'].hide()
      }
    },
    isEmpty (value = null) {
      return value === null || value === ''
    },
    getCountryName (country) {
      return this.$i18n?.locale === 'en' ? country.name : country.name_de
    },
    setCityModelByExistingValueFromProfile () {
      if (this.profileCityId) {
        this.payload.city = this.profileCityId
      }
    },
    checkExistingValues () {
      if (this.homeBaseCountry && this.userRegisterProgress > REG_CITY_CONST.CITY_REGISTRATION_STEP) {
        this.payload.country = this.homeBaseCountry
      }
    },
    getCitiesByCountry (country) {
      return new Promise((resolve, reject) => {
        getCitiesByCountryService(country)
          .then(res => {
            resolve(res)
            this.$store.commit('setCities', res.data)
          })
          .catch((err) => {
            reject(err)
            console.error('Failed to fetch list of cities', err)
          })
      })
    },
    resetCityField () {
      this.payload.city = null
      $('#autocomplete-input').val('')
    },
    isFormValid () {
      return $('#autocomplete-input').val() !== '' && (this.payload.city || this.payload.city !== '')
    },
    submit () {
      this.errors.clear()

      if (!this.isFormValid()) {
        var errorMsg = this.$t('error_select', {
          field: this.$t('city_name')
        })

        this.errors.add({
          field: 'city',
          msg: errorMsg
        })

        return false
      }

      this.$store.commit('setHomeBaseCountry', this.countryPayload)

      this.$store.dispatch('updateBasicRegistration', { type: 'city', payload: this.payload })
        .then(() => this.$router.push(this.resolveBasicRoute('eye-color')))
        .catch(err => this.displayErrorMessages(err))
    },
    displayErrorMessages (err) {
      this.$notifications.toast(err.message, REG_CITY_CONST.ERR_DISP_DELAY)
    },
    gotoPrev () {
      this.$router.push({ name: 'register-overview' })
    },
    resolveBasicRoute (type) {
      return {
        name: 'register-basic',
        params: {
          type: type
        }
      }
    },
    initializeTooltip () {
      const vm = this

      const el = this.$refs.tooltip
      this.cityTooltip = this.$tooltip.create(el, 'city-tooltip', {
        trigger: 'click',
        content: vm.$t('tooltip_text'),
        appendTo: document.body
      }, {
        maxWidth: `${464}px`
      })
    },
    interceptEuropePayloadCountry () {
      if (this.payload.country === 'DE_AU' || this.payload.country === 'DE_CH') {
        this.payload.country = 'DE'
      }
    }
  }
}
</script>
