var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "faq-video-item" },
    [
      _c("div", { staticClass: "question" }, [
        _c("span", { staticClass: "question-number" }, [
          _vm._v(_vm._s(_vm.getItemNumber) + ".")
        ]),
        _vm._v(" "),
        _c("span", {
          domProps: { textContent: _vm._s(_vm.$t("faq.videoItem.title")) }
        })
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "answer",
        domProps: { textContent: _vm._s(_vm.$t("faq.videoItem.section_1")) }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "answer",
        domProps: { textContent: _vm._s(_vm.$t("faq.videoItem.section_2")) }
      }),
      _vm._v(" "),
      _c("super-provider-video", { staticClass: "answer" }),
      _vm._v(" "),
      _c("div", { staticClass: "answer" }, [
        _c("strong", {
          domProps: { textContent: _vm._s(_vm.$t("faq.videoItem.section_3")) }
        })
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "answer",
        domProps: { innerHTML: _vm._s(_vm.$t("faq.videoItem.section_4")) }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "answer" }, [
        _c(
          "ol",
          _vm._l(_vm.$t("faq.videoItem.list"), function(videoItemList, key) {
            return _c("li", { key: key }, [
              _c("strong", [_vm._v(_vm._s(videoItemList.title))]),
              _vm._v(" "),
              videoItemList.content.length
                ? _c("span", {
                    domProps: { innerHTML: _vm._s(videoItemList.content) }
                  })
                : _vm._e()
            ])
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }