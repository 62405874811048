var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "live-photos" } },
    [
      _c(
        "div",
        { staticClass: "photo-container" },
        _vm._l(_vm.photos, function(p, i) {
          return _c(
            "div",
            { key: p.id || i, class: { "shake-effect": p.id !== 0 } },
            [
              p.id !== 0
                ? _c("photos-gallery-v2-photo", {
                    attrs: {
                      id: "photo-upload-" + i,
                      "data-id": p.id,
                      photo: p,
                      index: i
                    },
                    on: { "stop-replace": _vm.stopReplace }
                  })
                : _vm._e(),
              _vm._v(" "),
              p.id === 0 && !_vm.isEditMode
                ? _c("photos-gallery-v2-photo-empty", {
                    attrs: { id: "photo-upload-" + i, index: i }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row center" }, [
        _c("div", [
          !_vm.isEditMode
            ? _c("div", { staticClass: "pro-tip" }, [
                _c("strong", [_vm._v("PRO TIP")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.$translate("photos.v2.photos_required")))
                ])
              ])
            : _c("span", [
                _vm._v(_vm._s(_vm.$translate("photos.v2.hold_drag")))
              ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "vform",
        { ref: "uploadMainForm", on: { submit: _vm.uploadMainPhoto } },
        [
          _c("input", {
            ref: "uploadMainFormFile",
            staticClass: "hide",
            attrs: { type: "file", accept: "image/*" },
            on: { change: _vm.uploadMainFormSubmit }
          })
        ]
      ),
      _vm._v(" "),
      _c("vform", { ref: "uploadForm", on: { submit: _vm.uploadPhoto } }, [
        _c("input", {
          ref: "uploadFormFile",
          staticClass: "hide",
          attrs: { type: "file", accept: "image/*" },
          on: { change: _vm.uploadFormSubmit }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }