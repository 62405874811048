var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "input-field textarea-field",
      class: {
        active: _vm.forceActive,
        "has-placeholder": _vm.placeholder
      },
      on: { click: _vm.onClick }
    },
    [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.model,
            expression: "model"
          }
        ],
        ref: "textarea",
        class: _vm.textareaClass,
        attrs: {
          rows: _vm.rows,
          cols: _vm.cols,
          placeholder: _vm.placeholder,
          tabindex: _vm.tabindex,
          disabled: _vm.disabled,
          maxlength: _vm.maxlength
        },
        domProps: { value: _vm.model },
        on: {
          blur: _vm.onBlur,
          focus: _vm.onFocus,
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.model = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("label", { class: { active: _vm.isLabelActive } }, [
        _vm._v(_vm._s(_vm.label))
      ]),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }