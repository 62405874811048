var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "forgotpass-page", attrs: { id: "forgotpass-desktop" } },
    [
      _c("desktop-header"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container margin-top-x6", attrs: { id: "content" } },
        [
          _c(
            "div",
            { staticClass: "card" },
            [
              _c("div", { attrs: { id: "header" } }, [
                _c(
                  "div",
                  {
                    staticClass: "text-darken-1",
                    staticStyle: { "line-height": "36px" }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$translate("forgotPassword.header_line_1"))
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "text-darken-1" }, [
                  _vm._v(_vm._s(_vm.$translate("forgotPassword.header_line_2")))
                ])
              ]),
              _vm._v(" "),
              _c(
                "vform",
                { on: { submit: _vm.doSubmit } },
                [
                  _vm.error
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align",
                          attrs: { id: "forgotpassword-desktop-error" }
                        },
                        [_c("small", [_vm._v(_vm._s(_vm.error))])]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("email-input", {
                    staticClass: "email-input",
                    attrs: { email: _vm.email },
                    on: { onInput: _vm.onEmailChange }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-raised btn-large waves-effect waves-light display-block",
                      attrs: {
                        id: "forgotpassword-desktop-submit-btn",
                        type: "submit",
                        tabindex: "3"
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$translate("forgotPassword.continue")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }