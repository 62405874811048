<template>
  <div id="affiliate-received">
    <template v-if="!isLoading">
      <div v-if="successfulReferrals.length === 0" class="affiliate-placeholder" data-test="affiliate-placeholder">
        <img src="/img/affiliate/affiliate-placeholder.svg" alt="no-affiliate-placeholder">
        <div class="affiliate-placeholder__text">{{ $t('tabs.received.placeholder') }}</div>
      </div>
      <div
        v-for="item in successfulReferrals"
        v-else
        :key="`succesful-referrals-${item.name}`"
        class="affiliate-item"
        data-test="successful-referrals"
      >
        <div class="affiliate-item__icon" />
        <div class="affiliate-item__text" v-html="getEntryText(item)" />
      </div>
    </template>

    <template v-else>
      <infinite-loading
        ref="infiniteLoader"
        direction="top"
        spinner="spiral"
      />
    </template>
  </div>
</template>

<i18n src="@/features/affiliate/translations/affiliate.json"></i18n>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import { AFFILIATE_OVERVIEW } from '@/features/affiliate/constants'

export default {
  inject: {
    affiliateData: {
      default: () => ({
        affiliateOverview: AFFILIATE_OVERVIEW,
        currency: '',
        amountEarnable: null,
        isLoading: false
      })
    }
  },
  components: {
    InfiniteLoading
  },
  computed: {
    successfulReferrals () {
      if (this.affiliateData?.affiliateOverview?.successful_referrals.length === 0) {
        return []
      }

      return this.affiliateData?.affiliateOverview?.successful_referrals.map((item) => {
        return {
          currency: this.affiliateData.currency,
          amount: this.affiliateData.amountEarnable,
          name: item.name
        }
      })
    },
    isLoading () {
      return this.affiliateData?.isLoading
    }
  },
  methods: {
    getEntryText (item) {
      const amount = !this.affiliateData.isFromGB
        ? `${item.amount} ${item.currency}`
        : `${item.currency} ${item.amount}`

      return this.$t('tabs.received.entry', { amount, name: item.name })
    }
  }
}
</script>

<style lang="scss" scoped>
#affiliate-received {
  padding: 24px 0;

  .affiliate-item {
    margin-bottom: 8px;
    padding: 13px 16px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    text-align: left;

    & .affiliate-item__icon {
      flex-shrink: 0;
      width: 34px;
      height: 34px;
      margin-right: 15px;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center;
    }
    &:nth-of-type(3n+1) .affiliate-item__icon {
      background: url('/img/affiliate/affiliate-icon-blue.svg');
    }
    &:nth-of-type(3n+2) .affiliate-item__icon {
      background: url('/img/affiliate/affiliate-icon-purple.svg');
    }
    &:nth-of-type(3n+3) .affiliate-item__icon {
      background: url('/img/affiliate/affiliate-icon-green.svg');
    }

    .affiliate-item__text {
      font-size: 16px;
      color: #2E353D;

      &::v-deep strong {
        font-weight: 400;
        font-family: 'Proxima Nova Semibold';
        color: #C51162;
      }
    }
  }
}

.affiliate-placeholder {
  &__text {
    font-size: 22px;
    font-family: 'Proxima Nova Semibold';
    color: #2F3740;
  }
}
</style>
