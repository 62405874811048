<template>
  <div id="hair-color" class="wrapper">
    <template>
      <div class="content__area">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <p class="content__stepper content__stepper--mobile"> {{ stepperLabel }}</p>
        <h2 class="content__title content__title--desktop">{{ $translate('register.basic.title.hair_color') }}</h2>
      </div>
      <div class="content__area drawer">
        <div class="content" style="max-width: unset;">
          <p class="content__stepper content__stepper--desktop"> {{ stepperLabel }}</p>
          <h2 class="content__title content__title--mobile">{{ $translate('register.basic.title.hair_color') }}</h2>

          <div class="drawer-icon center-align">
            <img :src="selectedImage" alt="hair icon">
          </div>

          <div class="selected-text-container">
            <p v-if="selectedText" class="center-align selected-text">
              {{ selectedText }}
            </p>

            <p v-else>
            &nbsp;
            </p>
          </div>

          <div class="color-swatch margin-bottom-lg">
            <div class="dark-brown" @click.prevent="setSelected('brown')">
              <img v-if="isSelected('brown')" src="/img/registration/ic_check_color_selection.svg" alt="checkmark">
            </div>
            <div class="blonde" @click.prevent="setSelected('blonde')">
              <img v-if="isSelected('blonde')" src="/img/registration/ic_check_color_selection.svg" alt="checkmark">
            </div>
            <div class="black" @click.prevent="setSelected('black')">
              <img v-if="isSelected('black')" src="/img/registration/ic_check_color_selection.svg" alt="checkmark">
            </div>
            <div class="red" @click.prevent="setSelected('red')">
              <img v-if="isSelected('red')" src="/img/registration/ic_check_color_selection.svg" alt="checkmark">
            </div>
          </div>

          <div class="center-align">
            <div v-if="errors.has('hair_color')" class="red-text">
              {{ errors.first('hair_color') }}
            </div>
            <p class="hint">{{ $translate('register.basic.hair_color.hint') }}</p>
          </div>

          <register-navigator-v2 :next-disabled="isCTADisabled" :total="total" :current="current" @prev.prevent="gotoPrev" @next.prevent="submit" />

        </div>
      </div>
    </template>

  </div>
</template>

<script>
import { isEmpty } from '@/constants/helpers.js'
const LocaleProvider = $h.getProvider('LocaleProvider')
const RegisterProvider = $h.getProvider('RegisterProvider')
export default {
  props: ['total', 'current', 'stepperLabel'],
  data () {
    return {
      payload: {
        hair_color: null
      }
    }
  },
  computed: {
    isCTADisabled () {
      return isEmpty(this.payload.hair_color)
    },
    user () {
      return this.$store.getters.getUser
    },
    selectedImage () {
      switch (this.payload.hair_color) {
        case 'blonde':
          return '/img/registration/hair/hair-blonde-new.svg'
        case 'black':
          return '/img/registration/hair/hair-black-new.svg'
        case 'red':
          return '/img/registration/hair/hair-red-new.svg'
        case 'brown': default:
          return '/img/registration/hair/hair-brown-new.svg'
      }
    },
    selectedText () {
      if (!this.payload.hair_color) return null
      var result = this.$store.getters.getDropdownHairColor(this.payload.hair_color)

      if (LocaleProvider.isLocaleEn()) {
        result = result + ' Hair'
      } else {
        result = result + 'e' + ' Haare'
      }

      return result
    },
    isSelected () {
      return (color) => {
        return this.payload.hair_color == color
      }
    },
    isFormValid () {
      return this.payload.hair_color
    }
  },
  mounted () {
    this.initializePayload()
  },
  methods: {
    submit () {
      this.errors.clear()

      if (!this.isFormValid) {
        var errorMsg = this.$translate('errors.select', {
          field: this.$translate('register.basic.hair_color.name')
        })

        this.errors.add({
          field: 'hair_color',
          msg: errorMsg
        })

        return false
      }

      RegisterProvider.updateBasic('hair-color', this.payload)
        .then((res) => {
          this.$store.commit('setUser', res.data)
          this.$router.push(this.resolveBasicRoute('origin'))
        })
        .catch((err) => {
          this.$notifications.toast(err.message, 5000)
        })
    },
    gotoPrev () {
      this.$router.push(this.resolveBasicRoute('eye-color'))
    },
    setSelected (color) {
      this.payload.hair_color = color
    },
    resolveBasicRoute (type) {
      return {
        name: 'register-basic',
        params: {
          type: type
        }
      }
    },
    initializePayload () {
      this.payload.hair_color = this.user.profiles.hair_color
    }
  }
}
</script>

<style lang="scss" scoped>

#hair-color {
  .color-swatch {
    & {
      padding: 0 16px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 12px;

      @media (min-width: 361px) {
          padding: 0 32px;
      }

      @media (min-width: 992px) {
        margin-top: 120px;
      }
    }
    & > div {
      & {
        width: 50px;
        height: 50px;
        flex: 0 1 auto;
        line-height: 50px;
        border-radius: 100%;
        color: #FFFFFF;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: 992px) {
          width: 103px;
          height: 103px;
        }
      }
      .material-icons {
        line-height: inherit;

        @media (min-width: 992px) {
          font-size: 64px;
        }
      }
    }
  }
}
</style>
