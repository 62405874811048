var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stepper" }, [
    _c("div", { staticClass: "grey-text text-darken-1" }, [
      _vm._v(_vm._s(_vm.stepperLabel))
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "steps-container" },
      _vm._l(_vm.total, function(i) {
        return _c("div", {
          staticClass: "step",
          class: { active: i <= _vm.current }
        })
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }