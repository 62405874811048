import { render, staticRenderFns } from "./circular.vue?vue&type=template&id=1c13f734&"
import script from "./circular.vue?vue&type=script&lang=js&"
export * from "./circular.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider8-stage.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1c13f734')) {
      api.createRecord('1c13f734', component.options)
    } else {
      api.reload('1c13f734', component.options)
    }
    module.hot.accept("./circular.vue?vue&type=template&id=1c13f734&", function () {
      api.rerender('1c13f734', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/features/apv/preloaders/circular.vue"
export default component.exports