<template>
  <div id="eye-color" class="wrapper">
    <template>
      <div class="content__area">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <p class="content__stepper content__stepper--mobile"> {{ stepperLabel }}</p>
        <h2 class="content__title content__title--desktop">{{ $translate('register.basic.title.eye_color') }}</h2>
      </div>
      <div class="content__area drawer">
        <div class="content" style="max-width: unset;">
          <p class="content__stepper content__stepper--desktop"> {{ stepperLabel }}</p>
          <h2 class="content__title content__title--mobile">{{ $translate('register.basic.title.eye_color') }}</h2>

          <div class="drawer-icon center-align">
            <img :src="selectedImage" alt="eye icon">
          </div>

          <div class="selected-text-container">
            <p v-if="selectedText" class="center-align selected-text">
              {{ selectedText }}
            </p>

            <p v-else>
            &nbsp;
            </p>
          </div>

          <div class="color-swatch margin-bottom-lg">
            <div class="brown" @click.prevent="setSelected('brown')">
              <img v-if="isSelected('brown')" src="/img/registration/ic_check_color_selection.svg" alt="checkmark">
            </div>
            <div class="blue" @click.prevent="setSelected('blue')">
              <img v-if="isSelected('blue')" src="/img/registration/ic_check_color_selection.svg" alt="checkmark">
            </div>
            <div class="green" @click.prevent="setSelected('green')">
              <img v-if="isSelected('green')" src="/img/registration/ic_check_color_selection.svg" alt="checkmark">
            </div>
            <div class="grey" @click.prevent="setSelected('grey')">
              <img v-if="isSelected('grey')" src="/img/registration/ic_check_color_selection.svg" alt="checkmark">
            </div>
          </div>

          <div class="center-align">
            <div v-if="errors.has('eye_color')" class="red-text">
              {{ errors.first('eye_color') }}
            </div>
            <p class="hint">{{ $translate('register.basic.eye_color.hint') }}</p>
          </div>

          <register-navigator-v2
            :next-disabled="isCTADisabled"
            :total="total"
            :current="current"
            @prev.prevent="gotoPrev"
            @next.prevent="submit"
          />

        </div>
      </div>
    </template>

  </div>
</template>

<script>
import { isEmpty } from '@/constants/helpers.js'
const LocaleProvider = $h.getProvider('LocaleProvider')
const RegisterProvider = $h.getProvider('RegisterProvider')

export default {
  props: ['total', 'current', 'stepperLabel'],
  data () {
    return {
      payload: {
        eye_color: null
      }
    }
  },
  computed: {
    isCTADisabled () {
      return isEmpty(this.payload.eye_color)
    },
    user () {
      return this.$store.getters.getUser
    },
    selectedImage () {
      switch (this.payload.eye_color) {
        case 'blue':
          return '/img/registration/eyes/eye-blue-new.svg'
        case 'green':
          return '/img/registration/eyes/eye-green-new.svg'
        case 'grey':
          return '/img/registration/eyes/eye-grey-new.svg'
        case 'brown': default:
          return '/img/registration/eyes/eye-brown-new.svg'
      }
    },
    selectedText () {
      if (!this.payload.eye_color) return null
      var result = this.$store.getters.getDropdownEyeColor(this.payload.eye_color)

      if (LocaleProvider.isLocaleEn()) {
        result = result + ' Eyes'
      }

      return result
    },
    isSelected () {
      return (color) => {
        return this.payload.eye_color == color
      }
    },
    isFormValid () {
      return this.payload.eye_color
    }
  },
  mounted () {
    this.initializePayload()
  },
  methods: {
    submit () {
      this.errors.clear()

      if (!this.isFormValid) {
        var errorMsg = this.$translate('errors.select', {
          field: this.$translate('register.basic.eye_color.name')
        })

        this.errors.add({
          field: 'eye_color',
          msg: errorMsg
        })

        return false
      }

      RegisterProvider.updateBasic('eye-color', this.payload)
        .then((res) => {
          this.$store.commit('setUser', res.data)
          this.$router.push(this.resolveBasicRoute('hair-color'))
        })
        .catch((err) => {
          this.$notifications.toast(err.message, 5000)
        })
    },
    gotoPrev () {
      this.$router.push(this.resolveBasicRoute('city'))
    },
    setSelected (color) {
      this.payload.eye_color = color
    },
    resolveBasicRoute (type) {
      return {
        name: 'register-basic',
        params: {
          type: type
        }
      }
    },
    initializePayload () {
      this.payload.eye_color = this.user.profiles.eye_color
    }
  }
}
</script>

<style lang="scss" scoped>
#eye-color {
  .color-swatch {
    & {
      padding: 0 16px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 12px;

      @media (min-width: 361px) {
          padding: 0 32px;
      }

      @media (min-width: 992px) {
        margin-top: 120px;
      }
    }
    & > div {
      & {
        width: 50px;
        height: 50px;
        flex: 0 1 auto;
        line-height: 50px;
        border-radius: 100%;
        color: #FFFFFF;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: 992px) {
          width: 103px;
          height: 103px;
        }
      }
      .material-icons {
        line-height: inherit;

        @media (min-width: 992px) {
          font-size: 64px;
        }
      }
    }
  }
}
</style>
