var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "chat-bubble",
      class: { me: _vm.self },
      attrs: { id: "chat-bubble" }
    },
    [
      _c("div", { staticClass: "text" }, [
        _c("div", [_vm._v(_vm._s(_vm.message))]),
        _vm._v(" "),
        _vm.file !== null
          ? _c(
              "div",
              {
                staticClass:
                  "file-upload container--flex container--flex-align-item-center clickable",
                on: {
                  click: function($event) {
                    return _vm.downloadFile(_vm.file)
                  }
                }
              },
              [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v(
                    _vm._s(
                      _vm.isImage(_vm.file.type) ? "image" : "insert_drive_file"
                    )
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "file-info left-align flex-1" }, [
                  _c("span", { staticClass: "file-name" }, [
                    _vm._v(_vm._s(_vm.file.original_file_name))
                  ]),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", { staticClass: "file-size" }, [
                    _vm._v(_vm._s(_vm.prettifySize(_vm.file.file_size)))
                  ])
                ])
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "status" }, [
        _vm.self && _vm.seen
          ? _c("small", { staticClass: "seen pink-text left" }, [
              _c("i", { staticClass: "material-icons valign-middle" }, [
                _vm._v("check")
              ]),
              _vm._v(
                " " + _vm._s(_vm.$translate("events.where_is.seen")) + "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("small", { staticClass: "time-ago right" }, [
          _vm._v(_vm._s(_vm.timeAgo))
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }