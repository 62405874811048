var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal left-align" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("h4", { staticClass: "modal-title" }, [
        _vm._v("\n      " + _vm._s(_vm.title) + "\n    ")
      ]),
      _vm._v(" "),
      _c("p", {
        staticClass: "grey-text text-darken-1",
        domProps: { innerHTML: _vm._s(_vm.content) }
      })
    ]),
    _vm._v(" "),
    !_vm.reverse
      ? _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn-flat waves-effect waves-dark modal-close",
              attrs: { id: "modal-confirm-cancel-reverse-btn" },
              on: { click: _vm.cancel }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.cancelText || _vm.$translate("app.cancel")) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn-flat waves-effect waves-dark krypton-pink-text",
              attrs: { id: "modal-confirm-confirm-reverse-btn" },
              on: { click: _vm.confirm }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.confirmText || _vm.$translate("app.ok")) +
                  "\n    "
              )
            ]
          )
        ])
      : _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn-flat waves-effect waves-dark modal-close",
              attrs: { id: "modal-confirm-cofirm-btn" },
              on: { click: _vm.confirm }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.confirmText || _vm.$translate("app.ok")) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn-flat waves-effect waves-dark krypton-pink-text",
              attrs: { id: "modal-confirm-cancel-btn" },
              on: { click: _vm.cancel }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.cancelText || _vm.$translate("app.cancel")) +
                  "\n    "
              )
            ]
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }