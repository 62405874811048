var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isCWPReadyForReveal
    ? _c(
        "div",
        {
          staticStyle: { "border-radius": "4px" },
          attrs: { id: "modal-contact-warning-popup" }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-contact-warning-popup__backdrop",
              attrs: { "data-test": "mcw-popup-backdrop" }
            },
            [
              _c("div", { staticClass: "modal-contact-warning-popup__modal" }, [
                _c("div", { staticClass: "modal-content" }, [
                  _c(
                    "h2",
                    { staticClass: "contact-warning-modal-title center-align" },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.$t("title")) + "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "contact-warning-modal-content center-align"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("content")) +
                          "\n        "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("figure", {
                  staticStyle: {
                    "border-top": "solid 1px #E1E6EB",
                    margin: "0"
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer" }, [
                  _c("div", { staticClass: "terms-agree-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.termsAgree,
                          expression: "termsAgree"
                        }
                      ],
                      staticClass: "filled-in",
                      attrs: {
                        id: "terms-agree",
                        type: "checkbox",
                        "true-value": 1,
                        "false-value": 0
                      },
                      domProps: {
                        checked: Array.isArray(_vm.termsAgree)
                          ? _vm._i(_vm.termsAgree, null) > -1
                          : _vm._q(_vm.termsAgree, 1)
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.termsAgree,
                            $$el = $event.target,
                            $$c = $$el.checked ? 1 : 0
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.termsAgree = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.termsAgree = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.termsAgree = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "terms-agree" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("checkbox_text")) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "center-align" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "waves-effect waves-dark btn-flat  krypton-pink-text cta-btn",
                        class: {
                          disabled: !_vm.termsAgree,
                          active: _vm.termsAgree
                        },
                        attrs: { type: "submit", form: "room-number-form" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.closePopup($event)
                          }
                        }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("cta")) +
                              "\n            "
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }