<template>
    <div class="preloader-wrapper active">
        <div class="spinner-layer" :class="spinnerColor">
            <div class="circle-clipper left">
                <div class="circle"></div>
            </div>
            <div class="gap-patch">
                <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
                <div class="circle"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'color'
    ],
    computed: {
        getColor() {
            return this.color ? this.color : 'krypton-pink';
        },
        spinnerColor() {
            return 'spinner-' + this.getColor + '-only';
        }
    }
}
</script>
