const ApiProvider = $h.getProvider('ApiProvider')

const apiconfig = {
  timeout: 0 // For photo uploading we disable the timeout
}
class PhotosProvider {
  uploadMainPhoto (file, isRegister) {
    return new Promise((resolve, reject) => {
      var data = new FormData()

      data.append('file', file)
      data.append('is_register', (isRegister) ? 1 : 0)

      window.api.post({ url: ApiProvider.uploadMainPhoto(), payload: data, config: apiconfig },
        (success) => {
          resolve(success.data)
        },
        (err) => {
          reject(err.response.data)
        })
    })
  }

  uploadPhoto (file, isRegister) {
    return new Promise((resolve, reject) => {
      var data = new FormData()

      data.append('file', file)
      data.append('is_register', (isRegister) ? 1 : 0)
      data.append('live_photo', 1)

      window.api.post({ url: ApiProvider.uploadPhoto(1), payload: data, config: apiconfig },
        (success) => {
          resolve(success.data)
        },
        (err) => {
          reject(err.response.data)
        })
    })
  }

  replacePhoto (replacePhotoID, file, isRegister, replacePrimary) {
    return new Promise((resolve, reject) => {
      var data = new FormData()

      data.append('file', file)
      data.append('is_register', (isRegister) ? 1 : 0)

      if (replacePrimary) {
        window.api.post({ url: ApiProvider.replacePrimaryPhoto(replacePhotoID), payload: data, config: apiconfig },
          (success) => {
            resolve(success.data)
          },
          (err) => {
            reject(err.response.data)
          })
      } else {
        window.api.post({ url: ApiProvider.replaceOtherPhoto(replacePhotoID), payload: data, config: apiconfig },
          (success) => {
            resolve(success.data)
          },
          (err) => {
            reject(err.response.data)
          })
      }
    })
  }

  cropPhoto (id, params) {
    return new Promise((resolve, reject) => {
      window.api.put({ url: ApiProvider.cropPhoto(id), payload: params },
        (success) => {
          resolve(success.data)
        },
        (err) => {
          reject(err.response.data)
        })
    })
  }

  anonimizePhoto (id, payload, isRegister) {
    return new Promise((resolve, reject) => {
      window.api.put({ url: ApiProvider.anonimizePhoto(id, isRegister), payload: payload },
        (success) => {
          resolve(success.data)
        },
        (err) => {
          reject(err.response.data)
        })
    })
  }

  publishPhoto (id) {
    return new Promise((resolve, reject) => {
      window.api.put({ url: ApiProvider.publishPhoto(id) },
        (success) => {
          resolve(success.data)
        },
        (err) => {
          reject(err.response.data)
        })
    })
  }

  publishPhotoVariant (id) {
    return new Promise((resolve, reject) => {
      window.api.put({ url: ApiProvider.publishPhoto(id), payload: { new_variant: true } },
        (success) => {
          resolve(success.data)
        },
        (err) => {
          reject(err.response.data)
        })
    })
  }

  getOverlayDefaults (id, isRegister) {
    return new Promise((resolve, reject) => {
      window.api.get({ url: ApiProvider.photoOverlayDefaultWidth(id, isRegister) },
        (success) => {
          resolve(success.data)
        },
        (err) => {
          reject(err.response.data)
        })
    })
  }

  deletePhoto (id) {
    return new Promise((resolve, reject) => {
      window.api.delete({ url: ApiProvider.deletePhoto(id) },
        (success) => {
          resolve(success.data)
        },
        (err) => {
          reject(err.response.data)
        })
    })
  }
}

export default new PhotosProvider()
