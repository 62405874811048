var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "super-provider-card super-provider-card__req margin-bottom-md",
      class: _vm.cardStatusClass,
      attrs: { id: "client-rating" }
    },
    [
      _c("h2", {
        staticClass: "super-provider-card__title margin-bottom-lg",
        attrs: { "data-test": "title" },
        domProps: { textContent: _vm._s(_vm.title) }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "super-provider-card__value margin-bottom-md" },
        [
          _c(
            "i",
            {
              staticClass: "material-icons margin-right-md",
              attrs: { "data-test": "icon" }
            },
            [_vm._v("star")]
          ),
          _vm._v(" "),
          _c("span", { attrs: { "data-test": "value" } }, [
            _vm._v(_vm._s(_vm.actualClientRating))
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "super-provider-card__tooltip-trigger",
          attrs: { "data-test": "tooltip" },
          on: { click: _vm.showTooltip }
        },
        [
          _c("span", {
            staticClass: "super-provider-card__tooltip-trigger-text",
            domProps: { textContent: _vm._s(_vm.tooltipText) }
          }),
          _vm._v(" "),
          _c("i", { staticClass: "material-icons" }, [_vm._v("help_outline")])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "super-provider-card__req-status",
          attrs: { "data-test": "status" }
        },
        [
          _c("span", { domProps: { textContent: _vm._s(_vm.cardStatusText) } }),
          _vm._v(" "),
          _c("i", { staticClass: "material-icons" }, [
            _vm._v(_vm._s(_vm.cardStatusIcon))
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }