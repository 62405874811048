var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "hair-color" } },
    [
      [
        _c("div", { staticClass: "content__area" }, [
          _c("img", {
            attrs: {
              id: "k-logo",
              src: "/img/registration/k-logo.svg",
              alt: "k-logo"
            }
          }),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "content__stepper content__stepper--mobile" },
            [_vm._v(" " + _vm._s(_vm.stepperLabel))]
          ),
          _vm._v(" "),
          _c("h2", { staticClass: "content__title content__title--desktop" }, [
            _vm._v(_vm._s(_vm.$translate("register.basic.title.hair_color")))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content__area drawer" }, [
          _c(
            "div",
            { staticClass: "content", staticStyle: { "max-width": "unset" } },
            [
              _c(
                "p",
                { staticClass: "content__stepper content__stepper--desktop" },
                [_vm._v(" " + _vm._s(_vm.stepperLabel))]
              ),
              _vm._v(" "),
              _c(
                "h2",
                { staticClass: "content__title content__title--mobile" },
                [
                  _vm._v(
                    _vm._s(_vm.$translate("register.basic.title.hair_color"))
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "drawer-icon center-align" }, [
                _c("img", {
                  attrs: { src: _vm.selectedImage, alt: "hair icon" }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "selected-text-container" }, [
                _vm.selectedText
                  ? _c("p", { staticClass: "center-align selected-text" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.selectedText) +
                          "\n          "
                      )
                    ])
                  : _c("p", [_vm._v("\n           \n          ")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "color-swatch margin-bottom-lg" }, [
                _c(
                  "div",
                  {
                    staticClass: "dark-brown",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.setSelected("brown")
                      }
                    }
                  },
                  [
                    _vm.isSelected("brown")
                      ? _c("img", {
                          attrs: {
                            src:
                              "/img/registration/ic_check_color_selection.svg",
                            alt: "checkmark"
                          }
                        })
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "blonde",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.setSelected("blonde")
                      }
                    }
                  },
                  [
                    _vm.isSelected("blonde")
                      ? _c("img", {
                          attrs: {
                            src:
                              "/img/registration/ic_check_color_selection.svg",
                            alt: "checkmark"
                          }
                        })
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "black",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.setSelected("black")
                      }
                    }
                  },
                  [
                    _vm.isSelected("black")
                      ? _c("img", {
                          attrs: {
                            src:
                              "/img/registration/ic_check_color_selection.svg",
                            alt: "checkmark"
                          }
                        })
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "red",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.setSelected("red")
                      }
                    }
                  },
                  [
                    _vm.isSelected("red")
                      ? _c("img", {
                          attrs: {
                            src:
                              "/img/registration/ic_check_color_selection.svg",
                            alt: "checkmark"
                          }
                        })
                      : _vm._e()
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "center-align" }, [
                _vm.errors.has("hair_color")
                  ? _c("div", { staticClass: "red-text" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.errors.first("hair_color")) +
                          "\n          "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("p", { staticClass: "hint" }, [
                  _vm._v(
                    _vm._s(_vm.$translate("register.basic.hair_color.hint"))
                  )
                ])
              ]),
              _vm._v(" "),
              _c("register-navigator-v2", {
                attrs: {
                  "next-disabled": _vm.isCTADisabled,
                  total: _vm.total,
                  current: _vm.current
                },
                on: {
                  prev: function($event) {
                    $event.preventDefault()
                    return _vm.gotoPrev($event)
                  },
                  next: function($event) {
                    $event.preventDefault()
                    return _vm.submit($event)
                  }
                }
              })
            ],
            1
          )
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }