var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-page", attrs: { id: "login-desktop" } },
    [
      _c("desktop-header"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container margin-top-x6", attrs: { id: "content" } },
        [
          _c(
            "div",
            { staticClass: "card" },
            [
              _c("div", { attrs: { id: "header" } }, [
                _c(
                  "div",
                  {
                    staticClass: "text-darken-1",
                    staticStyle: { "line-height": "36px" }
                  },
                  [_vm._v(_vm._s(_vm.$translate("login.header_line_1")))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "text-darken-1" }, [
                  _vm._v(_vm._s(_vm.$translate("login.header_line_2")))
                ])
              ]),
              _vm._v(" "),
              _c(
                "vform",
                { on: { submit: _vm.doSubmit } },
                [
                  _vm.error
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align",
                          attrs: { id: "login-desktop-error-msgs" }
                        },
                        [_c("small", [_vm._v(_vm._s(_vm.error))])]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("md-input", {
                    staticClass: "textfield-box-lg",
                    attrs: {
                      id: "login-desktop-username",
                      "data-cy": "email-field",
                      value: _vm.username,
                      type: "text",
                      autocomplete: "username",
                      tabindex: "1",
                      label: _vm.$translate("login.email")
                    },
                    on: { input: _vm.onUsernameChange }
                  }),
                  _vm._v(" "),
                  _c("md-input", {
                    staticClass: "textfield-box-lg",
                    attrs: {
                      id: "login-desktop-password",
                      "data-cy": "password-field",
                      value: _vm.password,
                      type: "password",
                      autocomplete: "password",
                      tabindex: "2",
                      label: _vm.$translate("login.password")
                    },
                    on: { input: _vm.onPasswordChange }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-raised btn-large waves-effect waves-light display-block btn-krypton-pink waves-effect waves-light",
                      attrs: {
                        id: "login-desktop-submit-btn",
                        "data-cy": "login-btn",
                        type: "submit",
                        tabindex: "3"
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$translate("login.continue")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "margin-top-lg",
                      staticStyle: { "font-size": "14px" }
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            id: "login-desktop-forgotpass-link",
                            href: "#"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.$router.push({
                                name: "forgot-password"
                              })
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$translate("login.forgot_password"))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", { staticStyle: { color: "#757575" } }, [
                          _vm._v(_vm._s(_vm.$translate("login.no_account_yet")))
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              id: "login-desktop-register-link",
                              href: "#"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.$router.push({
                                  name: "register-start"
                                })
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$translate("login.register")) +
                                "\n            "
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }