<template>
  <div class="root">
    <div id="not-found">
      <div id="content" class="container">
        <div class="main">
          <div class="not-found-icon">
            <img class="responsive-img" src="/img/icons/k-logo-new.svg" alt="k-logo-new">
          </div>

          <h2>{{ $t('title') }}</h2>

          <p>{{ $t('subtitle') }}</p>
        </div>

        <div class="footer">
          <a href="#" class="dashboard-link" @click.prevent="gotoPage('dashboard')">
            <strong>{{ $t('cta_btn') }}</strong>
          </a>
        </div>

      </div>
    </div>
  </div>
</template>

<i18n src="@components/not-found/translations/not-found.json"></i18n>

<script>
export default {
  methods: {
    gotoPage (name) {
      this.$router.push({ name })
    }
  }
}
</script>

<style lang="scss" scoped>
.root {
  background-color: white;

  @media (min-width: 991px) {
    & {
      overflow: hidden;
      height: 100vh;
    }
  }
}
#not-found {
  width: 100%;
  height: 100svh;
  top: 125px;

  @media (min-width: 991px) {
    & {
      position: relative;
      background: url('/img/not-found/404-background.svg') repeat-x, white;
      height: calc(100vh - 60px);
      background-size: 100% auto;
      margin: 0 auto;
      max-width: 85%;
    }
  }

  #content.container {
    background-color: #FFFFFF;
    padding: 13px 0 31px 0;
    height: 100%;
    @media (max-width: 991px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
  }

  .main {
    padding: 0 24px;

    h2 {
      color: #2F3740;
      font-size: 40px;
      line-height: 46px;
      padding: 48px 0 32px 0;
      margin: 0;
    }

    p {
      color: #2F3740;
      font-size: 20px;
      line-height: 30px;
      margin: 0 0 32px 0;
    }

    .not-found-icon {
        margin: 0 auto;
    }

    // media for main
    @media (max-width: 400px) {
      h2 {
        font-size: 32px;
        padding: 48px 0 40px 0;
        line-height: 36px;
      }
      p {
        font-size: 18px;
      }
    }
  }

  .footer {

    @media (max-width: 991px) {
      border-top: 1px solid #CAD1D9;
    }
    padding: 17px 24px 0 24px;
    width: 100%;

    a {
      display: block;
    }

    .dashboard-link {
      background: transparent linear-gradient(82deg, #DC0064 0%, #FF0073 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 2px #0000003D;
      padding: 13px 70px;
      border-radius: 8px;
      min-width: 312px;

      @media (max-width: 400px) {
        padding: 13px 50px;
      }

      strong {
        color: #2F3740;
        font-size: 16px;
        line-height: 14px;
        color: #FFFFFF;
      }
    }
  }
}

</style>
