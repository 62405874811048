<template>
  <div>
    <a
      v-if="photoTrigger === PHOTO_TRIGGER_VALUES.OTHER_PHOTO"
      href="#"
      class="add-more center-align"
      @click.prevent="showPhotoTipsForFirstTimeUser"
    >
      <div class="add-btn" />
    </a>
    <button
      v-if="photoTrigger === PHOTO_TRIGGER_VALUES.PHOTO_TIPS_CTA"
      class="btn btn-black btn-photo-tip-upload"
      data-test="upload-cta-navigator"
      @click.prevent="showFilePicker"
    >
      <img src="/img/registration/tips/photo-tips/cta-upload-photo-icon.svg" alt="">
      <span>{{ $translate('photos.add_photo2') }}</span>
    </button>
    <div
      v-if="photoTrigger === PHOTO_TRIGGER_VALUES.OTHER_PHOTO_PILL"
      class="upload-pill"
      @click.prevent="showPhotoTipsForFirstTimeUser"
    >
      <i class="material-icons">arrow_upward</i>
      <span>{{ $translate('verify.upload.cta_button') }}</span>
    </div>
    <vform ref="uploadForm" @submit="uploadPhoto">
      <input
        ref="uploadFormFile"
        type="file"
        accept="image/*"
        class="hide"
        @change="uploadFormSubmit"
      >
    </vform>
  </div>
</template>

<script>
import { PHOTO_TRIGGER_VALUES } from '@/features/registration/constants.js'
const PhotosProvider = $h.getProvider('PhotosProvider')

export default {
  props: {
    isRegisterMode: {
      type: Boolean,
      default: false
    },
    photoTrigger: {
      type: String,
      default: PHOTO_TRIGGER_VALUES.OTHER_PHOTO
    },
    isOtherPhotoEmpty: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    PHOTO_TRIGGER_VALUES,
    hasSeenOtherPhotoTips: false
  }),
  mounted () {
    this.detectIfUserHasSeenPhotoTips()
  },
  methods: {
    detectIfUserHasSeenPhotoTips () {
      // Check if user has seen the tips
      window.localforage.getItem('hasSeenOtherPhotoTips', (err, hasSeenOtherPhotoTips) => {
        if (!err) {
          if (hasSeenOtherPhotoTips) {
            this.hasSeenOtherPhotoTips = true
          }
        }
      })
    },
    showPhotoTipsForFirstTimeUser () {
      if (this.hasSeenOtherPhotoTips) {
        this.showFilePicker()
      } else {
        window.localforage.setItem('hasSeenOtherPhotoTips', true)
        this.$router.push({
          name: 'register-photo-tips',
          params: {
            inSession: true,
            isOtherPhotos: true,
            return: this.$route.path
          }
        })
      }
    },
    showFilePicker () {
      this.$refs.uploadFormFile.click()
    },
    uploadPhoto (next) {
      var file = this.$refs.uploadFormFile.files[0]
      PhotosProvider.uploadPhoto(file, this.isRegisterMode)
        .then((res) => {
          this.$router.push({
            name: this.isRegisterMode ? 'register-photos-crop' : 'photos-crop',
            params: {
              photo: res.data,
              return: this.isRegisterMode
                ? { name: 'register-photos', params: { type: 'others' } }
                : { name: 'photos' },
              cancel: this.isRegisterMode
                ? { name: 'register-photos', params: { type: 'others' } }
                : { name: 'photos' }
            }
          })
        })
        .catch((err) => {
          if (_.isEmpty(err.data)) {
            $h.photoErrorModal(err.message)
          } else {
            $h.photoErrorModal(err.data)
          }
        })
        .finally(() => {
          if (this.$refs.uploadFormFile) {
            this.$refs.uploadFormFile.value = ''
          }
          next()
        })
    },
    uploadFormSubmit () {
      this.$refs.uploadForm.submit()
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-photo-tip-upload {
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 16px;
  font-family: 'Proxima Nova Semibold';

  & > span {
    padding-left: 8px;
  }
}
</style>
