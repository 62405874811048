<template>
  <div>
    <div class="input-month-container desktop-container">
      <!-- Desktop -->
      desktop month container
    </div>
    <div class="input-month-container mobile-container">
      <label for="input-month" class="active">{{ label }}</label>
      <input
        v-model="model"
        type="month"
        :min="min"
        :max="max"
      >
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: String,
      default: 'MMMM-YY'
    },
    min: {
      type: String,
      default: '1970-01'
    },
    max: {
      type: String,
      default: '2023-01' // Not sure if we should put here max year
    },
    label: {
      type: String,
      default: 'MMMM-YY'
    }
  },
  data () {
    return {
      model: null
    }
  },
  watch: {
    value (newval) {
      this.model = newval
    },
    model (newval) {
      this.$emit('input', newval)
    }
  },
  mounted () {
    window.addEventListener('resize', this.switchInput())
    this.switchInput()
  },
  methods: {
    switchInput () {
      // Get the screen width
      const screenWidth = window.innerWidth

      // Get the input container element
      const inputMonthContainer = document.querySelector('.input-month-container')

      // Get the browser-default input and custom input elements
      const desktopContainer = inputMonthContainer.querySelector('.desktop-container')
      const mobileContainer = inputMonthContainer.querySelector('.mobile-container')

      if (screenWidth < 768) {
        // Switch to the custom input if the screen width is less than 768 pixels
        desktopContainer.style.display = 'none'
        mobileContainer.style.display = 'block'
      } else {
        // Switch to the browser-default input if the screen width is greater than or equal to 768 pixels
        desktopContainer.style.display = 'block'
        mobileContainer.style.display = 'none'
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.mobile-container  {
  display: none;
}

/* Media query for small screens */
@media screen and (max-width: 768px) {
  /* Hide the browser-default input */
  .desktop-container {
    display: none;
  }

  /* Show the custom input */
  .mobile-container {
    display: block;
  }
}

.input-month-container.mobile-container {
  & {
    width: 100%;
  }

  & > label {
    & {
      top: 12px;
      color: #52575B;
      left: 16px;
      font-family: 'Proxima Nova Semibold';
      font-size: 16px;
      position: relative;
    }

    &.active {
      top: 10px;
      left: 11px; // Deduct the 5px padding from the minimized label
      color: #2F3740;
      background: #FFFFFF;
      padding-left: 5px;
      padding-right: 5px;
      font-family: 'Proxima Nova Medium';
      font-size: 12px;
      top: 12px;
    }
  }

  & > input {
    background: #FFFFFF;
    width: 100%;
    height: 70px;
    margin: 0 0;
    padding: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    border: 1px solid #CED6E0;
    border-radius: 12px;
    color: #52575B;
    font-size: 16px;
    font-family: 'Proxima Nova Semibold';

    &.has-value:not([readonly]) {
      border: 1px solid #0c0c0d;
      color: #2F3740;
    }

    &:focus:not([readonly]) {
      border: 2px solid #2F3740;
    }

    &.has-value:not([readonly]) + label.active {
      color: #52575B;
    }

    &:focus:not([readonly]) + label.active {
      color: #2F3740;
    }
  }
}
</style>
