var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "private-place_overview" } },
    [
      _c(
        "div",
        { staticClass: "container", attrs: { id: "content" } },
        [
          _c("feature-overview", {
            attrs: {
              "header-title": _vm.$translate("private-place.header.title"),
              "header-subtitle": _vm.$translate(
                "private-place.header.subtitle"
              ),
              "header-tagline": _vm.$translate(
                "private-place.header.explanation"
              ),
              "main-header-logo":
                "/img/private-place/private-place-header-img-v2-overview.svg",
              "tagline-logo":
                "/img/private-place/private-place-subheader-img-v2.svg",
              "primary-header-color": "#3AE86C",
              "secondary-header-color": "#46E4E9",
              "header-gradient-angle": "46deg",
              "tagline-gradient-angle": "18deg",
              "header-navbar-redirection": "dashboard",
              "header-img-dimensions": { height: "209px", width: "245px" },
              features: _vm.features
            }
          }),
          _vm._v(" "),
          _c("q-and-a", {
            attrs: {
              title: _vm.$translate("private-place.q_and_a.title"),
              "default-opened-answer": 0,
              questions: _vm.questions
            }
          }),
          _vm._v(" "),
          _c("overview-cta", {
            attrs: {
              "redirect-to": "private-place-setting",
              label: _vm.$translate("private-place.header.cta"),
              icon: "person_pin_circle"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }