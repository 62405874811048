<template>
  <a
    :class="getCityPillClass"
    @click="selectCity"
    v-text="cityName"
  />
</template>

<script>
export default {
  props: {
    cityName: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getCityPillClass () {
      return ['city-pill', {
        selected: this.selected
      }]
    }
  },
  methods: {
    selectCity () {
      this.$emit('select')
    }
  }
}
</script>

<style lang="scss" scoped>
.city-pill {
  &, &:hover, &:focus, &:active, &:visited {
    display: inline-flex;
    height: 53px;
    align-items: center;
    border: 1px solid #CED6E0;
    border-radius: 27px;
    min-width: 67px;
    justify-content: center;
    color: #2F3740;
    font-family: 'Proxima Nova Medium';
    font-size: 18px;
    padding: 16px 18px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-decoration: none;
    transition: all 0.2s;
    line-height: 1;
  }
  &:hover {
    cursor: pointer;
  }
  &.selected {
    color: #2F3740;
    background-color: #F7FAFF;
    border-color: #06D6A0;
    border-width: 2px;
  }
}
</style>
