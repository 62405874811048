<template>
  <div id="interview">
    <md-new-navbar route-name="profile" />
    <div v-if="user" id="content" class="container">
      <lesson-shortcut v-model="LESSONS.INTERVIEW" translation-key="interview" />
      <h1 class="title">{{ (hasInterview) ? $translate('interview.header') : $translate('interview.new_header_add') }}</h1>
      <img src="/img/interview.svg" alt="interview">
      <vform ref="form">
        <div v-if="errors.has('interview')"
             class="grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align">
          <small>{{ errors.first('interview') }}</small>
        </div>
        <md-krypton-interview-input
          v-for="(interview, index) in interviewItems" :key="`interview-item-${index}-${interview.answer}`" class="margin-bottom-xl"
          :interview="interview"
          :index="index"
          @item-clicked="itemClicked"
        />
        <div v-if="canAddInterview" class="add-mode">
          <md-krypton-interview-input
            :index="nextInterviewIndex"
            @item-clicked="itemClicked" />
        </div>
      </vform>
    </div>
    <video-shortcut v-model="LESSONS.INTERVIEW" />

    <interviewEdit
      :is-shown="isInterviewEditShown"
      :selected-item="selectedItem"
      @close="() => isInterviewEditShown = false"
    />
  </div>
</template>

<script>
import interviewEdit from '@components/account/profile/interview-v2/interview-edit.vue'

import { mapGetters } from 'vuex'

import LessonShortcut from '@/features/onboarding-lesson/components/LessonShortcut.vue'
import VideoShortcut from '@/features/onboarding-lesson/components/VideoShortcut.vue'
import { LESSONS } from '@/features/onboarding-lesson/constants'
import interview from '@/stores/interview'

export default {
  computed: {
    ...mapGetters({
      user: 'getUser',
      interviewsSelection: 'getInterviewsSelection',
      interviews: 'interview/GET_INTERVIEWS'
    }),
    interviewItems () {
      return this.$store?.getters['interview/GET_INTERVIEWS']
    },
    hasInterview () {
      if (this.interviews) { return this.interviews.length > 0 }
      return false
    },
    canAddInterview () {
      if (this.hasInterview) { return this.interviews.length < this.interviewOpts.length }
      return true
    },
    nextInterviewIndex () {
      if (this.hasInterview) { return this.interviews.length }
      return 0
    },
    interviewOpts () {
      let interviewOpts = $h.obj2arr(this.interviewsSelection)

      interviewOpts = interviewOpts.map((s) => {
        return {
          ...s,
          disabled: this.interviews
            .findIndex(i => i.title === s.key) !== -1
        }
      })

      return interviewOpts
    }
  },
  watch: {
    isInterviewEditShown (newVal) {
      if (newVal) {
        this.$store.commit('setHasFixedFooterButtons', true)
      } else {
        this.$store.commit('setHasFixedFooterButtons', false)
      }
    }

  },
  methods: {
    itemClicked (selectedItem) {
      this.selectedItem = selectedItem
      this.isInterviewEditShown = true
    }
  },
  components: {
    LessonShortcut,
    VideoShortcut,
    interviewEdit
  },
  data () {
    return {
      LESSONS,
      isInterviewEditShown: false,
      selectedItem: ''
    }
  }
}
</script>

<style lang="scss" scoped>

#interview {
  background: #ffffff;
  min-height: 100dvh;

  #content {
    background: #ffffff;
    padding: 5px 24px 24px 24px;

    .title {
      color: #2F3740;
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 36px;
    }

    form {
      margin-top: 37px;
    }

    @media only screen and (max-width: 992px) {
      width: 600px;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
    }

  }

}

</style>
