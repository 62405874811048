<template>
  <div id="register-preview" class="container">
    <div :id="`${canShowStatusIndicator ? 'preview' : 'preview_pre'}`" :style="background" :class="{ 'no-ui': noUi }" @click.prevent="toggleNoUi">
      <div class="gradient-effect top" />
      <div class="gradient-effect bottom" />
      <a href="#" class="close white-text" @click.prevent="closePhoto">
        <i class="material-icons">close</i>
      </a>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      noUi: false
    }
  },
  computed: {
    ...mapGetters({
      canShowStatusIndicator: 'getStatusIndicator'
    }),
    background () {
      return 'background-image: url($url)'.replace('$url', this.$route.query.url)
    }
  },
  methods: {
    closePhoto () {
      if (this.noUi) return false
      this.$router.go(-1)
    },
    toggleNoUi () {
      this.noUi = !this.noUi
    }
  }
}
</script>
