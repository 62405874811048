<template>
  <div id="dashboard">
    <gradient-header />
    <div id="content" class="container" :class="{
      'bottom-space': addBottomSpace,
    }">
      <tips-and-infos v-if="shouldShowTipsAndInfos" />

      <performance-overview />

      <div v-if="isAvailabilityEnabled" class="margin-y-xl">
        <div class="section-label">
          <img src="/img/dashboard/dashboard-availability-icon.svg" alt="tips and infos icon">
          <h2>{{ $translate('availability.title') }}</h2>
        </div>
        <div class="row">
          <div class="col s12">
            <div class="overview-table">
              <div class="overview-table--title">
                <div class="overview-table--block">
                  {{ $translate('availability.day_short.monday') }}
                </div>
                <div class="overview-table--block">
                  {{ $translate('availability.day_short.tuesday') }}
                </div>
                <div class="overview-table--block">
                  {{ $translate('availability.day_short.wednesday') }}
                </div>
                <div class="overview-table--block">
                  {{ $translate('availability.day_short.thursday') }}
                </div>
                <div class="overview-table--block">
                  {{ $translate('availability.day_short.friday') }}
                </div>
                <div class="overview-table--block">
                  {{ $translate('availability.day_short.saturday') }}
                </div>
                <div class="overview-table--block">
                  {{ $translate('availability.day_short.sunday') }}
                </div>
              </div>
              <div class="overview-table--schedule summary" style="margin-bottom: 14px;">
                <div v-for="(status, idx) in availabilitiesOverview" :key="idx" class="overview-table--block"
                     :class="getClassAccordingToStatus(status)" />
              </div>
              <div class="left margin-top-sm">
                <div class="overview-indicator" :class="getClassAccordingToEmotion(filledAvailabilityCount)" />
                <strong>{{ filledAvailabilityCount }}</strong> {{ $translate('availability.of') }} <strong>14</strong>
              </div>
              <div class="right" style="margin-right: -6px;">
                <vlink class="waves-effect waves-dark padding-sm" style="vertical-align: middle; margin-top: 1px;"
                       :to="{ name: 'availability' }">
                  <small><strong>{{ $translate('availability.set_more') }}</strong></small>
                </vlink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <news-list />
    </div>
    <fixed-menu />
    <div v-if="!showBottomStatusIndicator">
      <krypton-connect-promo-banner v-if="shouldDisplayConnectPromo" />
      <private-place-promo-banner v-if="shouldDisplayPrivatePlacePromoBanner" />
      <multi-location-promo-banner v-if="shouldDisplayMultiLocationPromo_PRE_A2_1815" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.bottom-space {
  padding-bottom: 150px !important;
}
</style>

<script>
import FixedMenu from '@components/v2/utilities/fixed-menu.vue'
import TipsAndInfos from '@components/account/tips-and-infos.vue'
import GradientHeader from '@components/account/gradient-header.vue'
import { mapGetters } from 'vuex'
import privatePlacePromoMixin from '@/features/private-place/mixins/promo.js'
import { isCandidateProfile } from '@/constants/helpers'

export default {
  components: {
    FixedMenu,
    TipsAndInfos,
    GradientHeader
  },
  mixins: [privatePlacePromoMixin],
  data: () => ({
    AVAILABILITY_STATUS: AVAILABILITY_STATUS
  }),
  computed: {
    ...mapGetters({
      user: 'getUser',
      showBottomSheet: 'onboarding/GET_SHOW_BOTTOM_SHEET',
      displayBottomSheetInMaxView: 'onboarding/GET_DISPLAY_BOTTOM_SHEET_IN_MAX_VIEW',
      filledAvailabilityCount: 'availabilities/getFilledAvailabilityCount',
      showBottomStatusIndicator: 'getBottomStatusIndicator'
    }),
    hasSeenPrompt () {
      // If provider_id under push notification setting is present
      // _push_notification_setting contains interactions of provider on the prompt
      // if they have accepted it from before it will be marked as has seen prompt
      // if they declined from before, it will be marked has seen prompt for a month then soft deleted via cron
      if (this.user?.settings?._push_notification_setting.length > 0) {
        return true
      }

      return false
    },
    isCandidateAndAcceptedLegalDetails () {
      return (isCandidateProfile(this.user) && (this.user.agree_terms === 1 || this.user.agree_terms === 3))
    },
    isProvider () {
      return !isCandidateProfile(this.user)
    },
    availabilitiesOverview () {
      const result = this.$store.getters['availabilities/getAvailabilitiesOverview']
      return (result) ? result.slice(0, 7) : []
    },
    isAvailabilityEnabled () {
      return this.user?.availability_enabled ?? false
    },
    shouldShowTipsAndInfos () {
      return this.showBottomSheet === 1
    },
    shouldDisplayInMaxView () {
      return this.displayBottomSheetInMaxView === 1
    },
    addBottomSpace () {
      return this.shouldDisplayConnectPromo ||
        this.shouldDisplayPrivatePlacePromoBanner ||
        this.shouldDisplayMultiLocationPromo_PRE_A2_1815 ||
        this.showBottomStatusIndicator
    }
  },
  mounted () {
    this.askForNotificationPermission()
  },
  methods: {
    askForNotificationPermission () {
      // If candidate and accepted terms and agreement
      // or if provider
      // has NOT seen prompt - accepted or declined
      if (process.env.ONE_SIGNAL_APP_ID &&
      (this.isCandidateAndAcceptedLegalDetails || this.isProvider) && !this.hasSeenPrompt) {
        this.$OneSignal.Slidedown.promptPush({ force: true })
      }
    },
    getClassAccordingToStatus (status) {
      return {
        unavailable: status === AVAILABILITY_STATUS.UNAVAILABLE,
        unfilled: status === AVAILABILITY_STATUS.UNFILLED,
        filled: status === AVAILABILITY_STATUS.FILLED,
        past: status === AVAILABILITY_STATUS.PAST
      }
    },
    getClassAccordingToEmotion (filledAvailabilityCount) {
      return {
        sad: filledAvailabilityCount < 7,
        neutral: filledAvailabilityCount >= 7 && filledAvailabilityCount <= 10,
        happy: filledAvailabilityCount > 10
      }
    }
  }
}
</script>
