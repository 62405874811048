import { DURATION_FEES } from '../../../../stores/events.js'

import EventCardMixin from './event-card'

export default {
  computed: {
    tier () {
      if (this.user) {
        return this.user.settings.tier
      }
      return 1
    },
    date () {
      const meetingStartAt = this.payload._meeting_start_at

      if (this.isToday) {
        return this.$translate('apv-card.today')
      }

      return `${meetingStartAt.format('dd')}\n${meetingStartAt.format('DD')}`
    },
    isToday () {
      const today = moment()
      const meetingStart = this.payload._meeting_start_at
      const isSame = meetingStart.format('YYYY-MM-DD') === today.format('YYYY-MM-DD')

      return isSame
    },
    isRoom () {
      const meetingPoint = this.payload.meeting_point

      if (meetingPoint) {
        const meetingPoint = meetingPoint.toLowerCase()

        return meetingPoint === 'room' ||
               meetingPoint === 'zimmer'
      }
    },
    meetingStart () {
      const meetingStartAt = this.payload._meeting_start_at.clone()

      let delay = 0

      if (this.payload.client_delay_seen &&
         (this.payload.client_delay > 0 || this.payload.provider_delay > 0)) {
        delay = this.payload.client_delay

        if (this.payload.provider_delay > delay) {
          delay = this.payload.provider_delay
        }
      }

      return meetingStartAt.add(delay, 'minutes').format('HH:mm')
    },
    acceptCountdown () {
      const expSeconds = this.payload._expiration_in_seconds

      if (expSeconds !== null && expSeconds > 0) {
        return moment().hour(0)
          .minutes(0)
          .second(expSeconds)
          .format('HH[h] : mm[m] : ss[s]')
      }
      return '00h : 00m : 00s'
    },
    feedbackConfirmationCountdown () {
      const expSeconds = this.payload._feedback_metadata.expiration_in_seconds

      if (expSeconds !== null && expSeconds > 0) {
        return moment().hour(0)
          .minutes(0)
          .second(expSeconds)
          .format('HH:mm:ss')
      }
    },
    meetingCountdown () {
      let delay = 0

      const clientDelay = this.payload.client_delay
      const providerDelay = this.payload.provider_delay
      const clientDelaySeen = this.payload.client_delay_seen
      const meetingStartSec = this.payload._meeting_start_in_seconds

      if (clientDelaySeen && (clientDelay > 0 || providerDelay > 0)) {
        delay = clientDelay

        if (providerDelay > delay) {
          delay = providerDelay
        }
      }

      if (!this.isOngoingMeeting) {
        if ((meetingStartSec + $h.minToSeconds(delay)) > 0) {
          return moment().hour(0)
            .minutes(0)
            .second(meetingStartSec + $h.minToSeconds(delay))
            .format('HH:mm:ss')
        }
      }
      return '00:00:00'
    },
    meetingDurationAmountPayable () {
      const meetingDur = this.payload.meeting_duration

      return DURATION_FEES[meetingDur][`tier_${this.tier}`]
    },
    hasNotification () {
      let hasTicketUpdate = this.payload.event_seen === 0

      // Do now show unread notification if the event is still pending
      // since the user cannot see the update for meeting point and identifier fields;
      const updates = this.payload._updates

      if (!this.isReadyToGo &&
         (updates.meeting_point ||
          updates.client_identifier ||
          // check if empty array
          updates == false)) {
        hasTicketUpdate = false
      }

      return hasTicketUpdate || this.payload._unread_message_count > 0
    },
    needsFeedback () {
      return this.isEventNeedsFeedback(this.payload)
    }
  },
  mixins: [
    EventCardMixin
  ]
}
