var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page", attrs: { id: "multi-location-faq" } },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("faq-header", {
              attrs: {
                "header-title": _vm.$t("header.title"),
                "header-tagline": _vm.$t("header.tagline"),
                "main-header-logo":
                  "/img/multi-location/multiloc-header-logo.svg",
                "tagline-logo": "/img/multi-location/multiloc-tagline-logo.svg",
                "primary-header-color": "#FF665B",
                "secondary-header-color": "#FFD04D",
                "header-gradient-angle": "54deg",
                "tagline-gradient-angle": "48deg",
                "header-navbar-redirection": "faq"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { id: "content", "data-test": "faq-q-and-a-section" } },
              [
                _c(
                  "ul",
                  { staticClass: "q-and-a" },
                  _vm._l(_vm.$t("faq.list"), function(item, key) {
                    return _c(
                      "li",
                      { key: key },
                      [
                        _c("div", { staticClass: "question" }, [
                          _c("span", { staticClass: "question-number" }, [
                            _vm._v(_vm._s(key + 1 + "."))
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("faq.list[" + key + "].title"))
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm._l(
                          _vm.$t("faq.list[" + key + "].content"),
                          function(item2, key2) {
                            return _c(
                              "div",
                              { key: key2, staticClass: "answer" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "faq.list[" +
                                          key +
                                          "].content[" +
                                          key2 +
                                          "]"
                                      )
                                    ) +
                                    "\n              "
                                ),
                                _vm.isLastItem(key) &&
                                _vm.isLastParagraph(key2, key)
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "support",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.$router.push({
                                              name: "support-v2"
                                            })
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("faq.support")) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          }
                        )
                      ],
                      2
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.isMultiLocationSupported
                  ? _c("faq-cta", {
                      attrs: {
                        "route-name": "multi-location-setting",
                        "cta-txt": _vm.$t("faq.cta"),
                        "background-gradient-class": "--orange"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }