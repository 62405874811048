<template>
  <div class="multi-location-event-address" :class="{ 'multi-location-event-address--event-details': isEventDetails }">
    <div v-if="hotelName && !isPrivatePlace">{{ hotelName }}</div>
    <div v-if="hotelAddress">{{ hotelAddress }}</div>
  </div>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      required: true
    },
    isEventDetails: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hotelName () {
      return this.event?._hotel_name ?? null
    },
    hotelAddress () {
      return this.event?._hotel_address ?? null
    },
    isPrivatePlace () {
      return this.event.is_private_place === 1
    }
  }
}
</script>

<style lang="scss" scoped>
.multi-location-event-address {
  color: #78838F;
  font-size: 14px;
  margin-bottom: 16px;

  &.multi-location-event-address--event-details {
    font-size: 13px;
    margin-bottom: 0px;
  }
}
</style>
