<template functional>
  <component :is="injections.components.RequestMagicLinkController" v-slot="{ data, bindings, actions }">
    <component
      :is="injections.components.AuthView"
      :data="data"
    >
      <template v-slot:title>
        <h2>{{ actions.translate('requestMagicLink.title1') }}</h2>
        <h2>{{ actions.translate('requestMagicLink.title2') }}</h2>
      </template>

      <apv-form
        :is-loading="data.isLoading"
        novalidate
        data-test="forgot-password-form"
        @submit="bindings.handleRequestMagicLink"
      >
        <div class="mobile-number-container">
          <div class="country-code-container">
            <div class="country-picker-container">
              <country-picker-autocomplete
                v-model="data.countryCode"
                class="country-picker country-picker--APV_REG_REDESIGN"
                :caret="true"
                @select="bindings.handleCountrySelected"
              />
            </div>
          </div>

          <div class="phone-number-container">
            <apv-input
              :value="data.phoneNumber"
              data-test="forgotPassword-input-email"
              type="text"
              tabindex="1"
              class="APV-onbo-redesign margin-bottom-lg"
              :label="actions.translate('requestMagicLink.mobile_number')"
              :error="actions.getFieldError('phone_number')"
              @input="bindings.handlePhoneNumberChange"
            />
          </div>
        </div>

        <div class="explanation-text">
          {{ actions.translate('requestMagicLink.explanation') }}
        </div>
        <apv-button
          block
          gradient
          type="submit"
          size="large"
          waves-effect="dark"
          data-test="login-cta"
          :disabled="bindings.isEmpty(data.phoneNumber)"
          :rounded="10"
          :is-loading="data.isLoading"
        >
          {{ actions.translate('requestMagicLink.cta') }}
        </apv-button>
      </apv-form>

      <div class="footer-auth-nav">
        <div class="login-container">
          <router-link class="login-link" :to="data.loginRoute">{{ actions.translate('requestMagicLink.login_with_password') }}</router-link>
        </div>
      </div>

    </component>
  </component>
</template>

<script>
import RequestMagicLinkController from './RequestMagicLinkController.vue'
import AuthView from '@/features/auth/components/AuthView.vue'
import CountryPickerAutocomplete from '@/features/country-picker/components/CountryPickerAutocomplete.vue'

export default {
  inject: {
    components: {
      default: {
        RequestMagicLinkController,
        AuthView
      }
    }
  },
  components: { CountryPickerAutocomplete }
}
</script>

<style lang="scss" scoped>

.country-picker--APV_REG_REDESIGN {
    top: 0;
    border: 1px solid #2f3740;
    border-radius: 12px;
    height: 68px;
    color: #2f3740;
    background: #fff;
    margin-bottom: 20px;
    padding-top: 5px;
    width: 144px;

    span.label.active {
      font-size: 12px;
      top: -10px;
      background: #FFFFFF;
      height: 16px;
      padding-left: 5px;
      padding-right: 5px;
      font-family: 'Proxima Nova Medium';
    }
  }

.mobile-number-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .country-code-container {
    width: 144px;
  }

  .phone-number-container {
    width: 175px;
  }
}

.explanation-text {
  margin-bottom: 24px;
  font-size: 14px;
  color: #2F3740;
  font-size: 'Proxima Nova';
}

.footer-auth-nav {
  font-size: 14px;
  line-height: 18px;
  margin: 46px 0 38px 0;

  .login-label { color: #2F3740; }

  .login-container { margin-bottom: 28px; }

  .login-link, .register-link {
      color: #DC0064;
      text-decoration: underline;
  }
}

.forgot-pass-auth-nav {
    font-size: 14px;
    line-height: 18px;
    margin: 46px 0 38px 0;

    .login-label { color: #2F3740; }

    .login-container { margin-bottom: 28px; }

    .login-link, .register-link {
        color: #DC0064;
        text-decoration: underline;
    }
}
.auth-background {
  position: relative;
  width: 100vw;
  height: 100dvh;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  background-color: white;

  @media (min-width: 769px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

</style>
