var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "password-setting" } },
    [
      _c("bottomsheet", {
        attrs: { "is-shown": _vm.isShown },
        on: { close: _vm.reset },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("h2", { staticClass: "bottom-sheet__title" }, [
                  _vm._v(
                    _vm._s(_vm.$translate("setting.password_setting_title"))
                  )
                ]),
                _vm._v(" "),
                _c(
                  "vform",
                  { ref: "form", on: { submit: _vm.submitForm } },
                  [
                    _c(
                      "md-input",
                      {
                        staticClass: "setting-input",
                        class: { "with-error": _vm.errors.has("old_password") },
                        attrs: {
                          type: "password",
                          label: _vm.$translate("setting.old_password_label"),
                          required: ""
                        },
                        model: {
                          value: _vm.payload.old_password,
                          callback: function($$v) {
                            _vm.$set(_vm.payload, "old_password", $$v)
                          },
                          expression: "payload.old_password"
                        }
                      },
                      [
                        _c("div", { staticClass: "text-error" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.first("old_password")) +
                              "\n          "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "md-input",
                      {
                        staticClass: "setting-input",
                        class: { "with-error": _vm.errors.has("new_password") },
                        attrs: {
                          type: "password",
                          label: _vm.$translate("setting.new_password_label"),
                          required: ""
                        },
                        model: {
                          value: _vm.payload.new_password,
                          callback: function($$v) {
                            _vm.$set(_vm.payload, "new_password", $$v)
                          },
                          expression: "payload.new_password"
                        }
                      },
                      [
                        _c("div", { staticClass: "text-error" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.first("new_password")) +
                              "\n          "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "md-input",
                      {
                        staticClass: "setting-input",
                        class: { "with-error": _vm.errors.has("input") },
                        attrs: {
                          type: "password",
                          label: _vm.$translate(
                            "setting.verify_new_password_label"
                          ),
                          required: ""
                        },
                        model: {
                          value: _vm.payload.new_password_confirmation,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.payload,
                              "new_password_confirmation",
                              $$v
                            )
                          },
                          expression: "payload.new_password_confirmation"
                        }
                      },
                      [
                        _c("div", { staticClass: "text-error" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.first("input")) +
                              "\n          "
                          )
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "password-tip" }, [
                  _c("img", { attrs: { src: "/img/asterisk.svg", alt: "" } }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$translate("register.start.password_tip"))
                    )
                  ])
                ])
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    staticClass:
                      "bottom-sheet__cta bottom-sheet__cta--confirm full-width",
                    attrs: {
                      disabled:
                        !_vm.payload.old_password ||
                        !_vm.payload.new_password ||
                        !_vm.payload.new_password_confirmation
                    },
                    on: { click: _vm.submit }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$translate("app.save")) +
                        "\n      "
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }