<template>
  <div id="lesson-screen-start" class="lesson-screen">
    <div class="wrapper">
      <div class="content__drawer content__drawer-filled">
        <div class="content lesson-screen--container">
          <div class="lesson-screen--content">
            <div class="cta__holder">
              <a data-test="photo-cls-btn" href="#" class="cta__close waves-effect" @click.prevent="resetActiveLesson">
                <i class="material-icons">close</i>
              </a>
            </div>
            <h2>{{ $t(`${openedLessonName}.title`) }}</h2>
            <p>{{ $t(`${openedLessonName}.subtitle`) }}</p>
            <img
              v-if="isLessonOpened(LESSONS.MONEY)"
              src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-money-v2.svg" alt="money"
            >
            <img
              v-else-if="isLessonOpened(LESSONS.FEEDBACK)"
              src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-feedback-v2.svg" alt="feedback"
            >
            <img
              v-else-if="isLessonOpened(LESSONS.DATES)"
              src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-dates-v2.svg" alt="dates"
            >
            <img
              v-else-if="isLessonOpened(LESSONS.REQUESTS)"
              src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-requests-v2.svg" alt="requests"
            >
            <img
              v-else-if="isLessonOpened(LESSONS.CLIENTS)"
              src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-clients-v2.svg" alt="clients"
            >
            <img
              v-else-if="isLessonOpened(LESSONS.SAFETY)"
              src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-safety-v2.svg" alt="safety"
            >
            <img
              v-else-if="isLessonOpened(LESSONS.AVAILABILITY)"
              src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-availability-v2.svg" alt="availability"
            >
            <img
              v-else-if="isLessonOpened(LESSONS.INTERVIEW)"
              src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-interview-v2.svg" alt="interview"
            >
            <img
              v-else
              data-test="photo-start-img"
              src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-photos-v2.svg" alt="photos"
            >
          </div>
        </div>

        <navigator
          :current="0"
          no-prev
          :next="$t('messages.globals.continue')"
          :next-class="'action-btn__lilac-gradient action-btn__blocked'"
          :total="totalPages"
          :step-class="'--purple'"
          @next.prevent="gotoNextLessonScreen"
        />
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/onboarding-lesson/translations/start.json"></i18n>

<script>
import { OnboardingLessons } from '@/mixins/onboarding'

import navigator from '@components/v2/utilities/navigator.vue'

export default {
  components: {
    navigator
  },
  mixins: [
    OnboardingLessons
  ]
}
</script>

<style lang="scss" scoped>
@import "@/features/onboarding-lesson/sass/styles";

#lesson-screen-start {
  .lesson-screen--container {
    text-align: center;

    .lesson-screen--content {
      position: relative;

      .cta__holder {
        display: flex;
        justify-content: flex-start;
      }

      a.cta__close {
        font-size: 24px;
        border-radius: 50%;
        padding: 12px;
        position: relative;
        left: -12px;
        top: -18px;

        i.material-icons {
          display: block;
        }
      }

      a.cta__close,
      a.cta__close:hover,
      a.cta__close:visited {
        color: #78838F;
      }

      h2 {
        margin: 28px auto 16px;
        font-size: 26px;
        color: #2F3740;
      }
      p {
        margin: 0 auto 56px;
        font-size: 18px;
        color: #2F3740;
      }
      img {
        margin: 0 auto;
        display: block;
        max-width: 100%;
        margin-bottom: 48px;
      }
      .btn {
        width: 152px;
        margin: 0 auto;
        display: flex;
        justify-content: center;

        i.material-icons {
          display: block;
          font-size: 24px;
          margin-left: 4px;
          margin-right: -8px;
        }
      }
    }
  }

  @media (min-width: 375px) {
    .lesson-screen--container {
      .lesson-screen--content {
        img {
          margin-bottom: 128px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .lesson-screen--container {
      .lesson-screen--content {
        padding-top: 0px;
      }
    }
  }
}
</style>
