const ApiProvider = $h.getProvider('ApiProvider')

class SettingProvider {
  submitSupportTicket (subject, message) {
    const payload = { subject, message }

    return new Promise((resolve, reject) => {
      window.api.post({ url: ApiProvider.submitSupportTicket(), payload: payload },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response) })
    })
  }

  fetchCallingCodes () {
    return new Promise((resolve, reject) => {
      window.api.get({ url: ApiProvider.countries() },
        (success) => {
          const countries = success.data.data
          const sortedCountries = []

          // Since the response in the API is not sorted according from POs spec,
          // We sort countries here instead.
          for (let i = 0, limit = COUNTRY_ORDER.length; i < limit; ++i) {
            const search = COUNTRY_ORDER[i]

            const idx = countries.findIndex(c => c.alpha2Code === search)

            if (idx !== -1) {
              const res = countries.splice(idx, 1)
              sortedCountries.push(res[0])
            }
          }

          // To be sure, we merge the remaining then return
          resolve(sortedCountries.concat(countries))
        },
        (err) => { reject(err) })
    })
  }

  changePassword (old_password, new_password, new_password_confirmation) {
    const payload = {
      old_password,
      new_password,
      new_password_confirmation
    }

    return new Promise((resolve, reject) => {
      window.api.put({ url: ApiProvider.changePassword(), payload: payload },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response) })
    })
  }

  verifySMSCode (phone_number, country_code, verification_code, country) {
    const payload = {
      phone_number,
      country_code,
      verification_code,
      country
    }

    return new Promise((resolve, reject) => {
      window.api.put({ url: ApiProvider.verifySMSVerification(), payload: payload },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response) })
    })
  }

  requestSMSCode (phone_number, country_code, country) {
    const payload = {
      phone_number,
      country_code,
      country
    }

    return new Promise((resolve, reject) => {
      window.api.post({ url: ApiProvider.requestSMSVerification(), payload: payload },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response) })
    })
  }

  requestEmailChange (newEmail) {
    const payload = {
      email: newEmail,
      verification_url: window.location.origin + '/verify-email'
    }

    return new Promise((resolve, reject) => {
      window.api.post({ url: ApiProvider.requestEmailChange(), payload: payload },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response) })
    })
  }

  verifyEmailChange (email, verification_code) {
    const payload = { email, verification_code }

    return new Promise((resolve, reject) => {
      window.api.put({ url: ApiProvider.verifyEmailChange(), payload: payload },
        (success) => { resolve(success.data) },
        (err) => { reject(err) })
    })
  }
}

export default new SettingProvider()
