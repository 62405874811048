var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "apv-card", attrs: { id: "cancelled-event-card" } },
    [
      _c("div", { staticClass: "card-header" }, [
        _c("div", { staticClass: "card-header__flex" }, [
          _c("pre", { staticClass: "date uppercase" }, [
            _vm._v(_vm._s(_vm.date))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grey-text card-header__flex card-header__flex--mid" },
          [
            _c("span", { staticClass: "schedule" }, [
              _c(
                "strong",
                {
                  staticClass: "schedule__countdown lilac",
                  class: {
                    "lilac--red": _vm.hasNotification
                  }
                },
                [
                  _c("span", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.meetingStart) +
                        "\n          "
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("small", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$translate("events.labels.for")) +
                    " " +
                    _vm._s(_vm.payload.meeting_duration) +
                    "h\n        "
                )
              ])
            ]),
            _vm._v(" "),
            !_vm.needsFeedback
              ? _c("p", { staticClass: "location location-status" }, [
                  _c("span", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$translate(
                            "events.card_status." + _vm.payload._event_status
                          )
                        ) +
                        "\n        "
                    )
                  ])
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-header__flex" }, [
          _c(
            "strong",
            { staticClass: "amount" },
            [
              _c("currency"),
              _vm._v(_vm._s(_vm.payload.amount_payable) + "\n      ")
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }