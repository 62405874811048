<template>
  <div id="password-setting">
    <bottomsheet :is-shown="isShown" @close="reset">
      <template v-slot:body>
        <h2 class="bottom-sheet__title">{{ $translate('setting.password_setting_title') }}</h2>
        <vform ref="form" @submit="submitForm">
          <md-input
            v-model="payload.old_password"
            class="setting-input"
            :class="{'with-error': errors.has('old_password')}"
            type="password"
            :label="$translate('setting.old_password_label')"
            required
          >
            <div class="text-error">
              {{ errors.first('old_password') }}
            </div>
          </md-input>
          <md-input
            v-model="payload.new_password"
            class="setting-input"
            :class="{'with-error': errors.has('new_password')}"
            type="password"
            :label="$translate('setting.new_password_label')"
            required
          >
            <div class="text-error">
              {{ errors.first('new_password') }}
            </div>
          </md-input>
          <md-input
            v-model="payload.new_password_confirmation"
            class="setting-input"
            :class="{'with-error': errors.has('input')}"
            type="password"
            :label="$translate('setting.verify_new_password_label')"
            required
          >
            <div class="text-error">
              {{ errors.first('input') }}
            </div>
          </md-input>
        </vform>

        <div class="password-tip">
          <img src="/img/asterisk.svg" alt="">
          <span>{{ $translate('register.start.password_tip') }}</span>
        </div>
      </template>

      <template v-slot:footer>
        <button
          class="bottom-sheet__cta bottom-sheet__cta--confirm full-width"
          :disabled="!payload.old_password || !payload.new_password || !payload.new_password_confirmation"
          @click="submit"
        >
          {{ $translate('app.save') }}
        </button>
      </template>
    </bottomsheet>
  </div>
</template>

<script>
import bottomsheet from '@components/v2/utilities/bottomsheet.vue'
import { tooltipPlugin } from '@/features/super-provider/plugins/vue-custom-tooltip.js'
const SettingsProvider = $h.getProvider('SettingsProvider')

export default {
  name: 'PasswordSetting',
  components: {
    bottomsheet
  },
  props: {
    isShown: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      payload: {
        old_password: '',
        new_password: '',
        new_password_confirmation: ''
      }
    }
  },
  methods: {
    onConfirm () {
      this.$refs.form.submit()
    },
    submitForm (next) {
      this.errors.clear()
      SettingsProvider.changePassword(
        this.payload.old_password,
        this.payload.new_password,
        this.payload.new_password_confirmation
      )
        .then((res) => {
          this.reset()
          tooltipPlugin.toastV2(res.message, 5000, 'bottom')
        })
        .catch((err) => {
          if (err.data.data) {
            for (const [key, value] of Object.entries(err.data.data)) {
              if (value.length > 0) {
                this.errors.add({
                  field: key,
                  msg: value[0]
                })
              }
            }
          }
        })
        .finally(() => {
          next()
        })
    },
    submit () {
      this.$refs.form.submit()
    },
    reset () {
      this.payload = {
        old_password: '',
        new_password: '',
        new_password_confirmation: ''
      }

      this.errors.clear()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.setting-input.input-field--APV_REG_REDESIGN {
  background: #FFFFFF;
  margin-bottom: 16px;
}

#password-setting {
  .password-tip {
    width: 100%;
    height: 58px;
    border: 1px solid #CED6E0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    color: #2F3740;
    padding: 16px;
    font-size: 12px;

    img {
      margin-right: 8px;
    }
  }
}
</style>
