var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page", attrs: { id: "affiliate-faq" } }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("faq-header", {
          attrs: {
            "header-title": _vm.$translate("faq.affiliate.title"),
            "header-tagline": _vm.$translate("faq.affiliate.intro"),
            "main-header-logo": "/img/affiliate/affiliate-reward-modal.svg",
            "primary-header-color": "#FF0073",
            "secondary-header-color": "#962BDA",
            "header-gradient-angle": "234deg",
            "tagline-gradient-angle": "249deg",
            "header-img-dimensions": { height: "107px", width: "125px" }
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "container", attrs: { id: "content" } },
          [
            _c("div", { staticClass: "faq" }, [
              _c(
                "ul",
                { staticClass: "q-and-a" },
                _vm._l(_vm.$t("faq.list"), function(item, key) {
                  return _c("li", { key: key }, [
                    _c("div", { staticClass: "question" }, [
                      _c("span", { staticClass: "question-number" }, [
                        _vm._v(_vm._s(_vm.getItemNumber(key)) + ".")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("faq.list[" + key + "].title", {
                              amount: _vm.getRewardByCountryCode
                            })
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "answer" },
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "faq.list[" + key + "].content",
                                _vm.getArgumentsForFAQContent
                              )
                            )
                          }
                        }),
                        _vm._v(" "),
                        _vm.isLastItem(key)
                          ? _c(
                              "router-link",
                              {
                                staticStyle: { "text-decoration": "underline" },
                                attrs: { to: { name: "support-v2" } }
                              },
                              [_vm._v("Support")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("faq-cta", {
              attrs: {
                "route-name": "affiliate-overview",
                "cta-txt": _vm.$translate("faq.affiliate.cta"),
                "background-gradient-class": "--pink"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }