<template>
  <div class="ios-main">
    <div class="contain">
      <div class="content">
        <h2>{{ $t('appName') }}</h2>
        <p>{{ $t('appDescription') }}</p>
        <div class="steps">
          <ul>
            <li>
              <p>{{ $t('contentLine1') }}</p>
              <img data-test="ios-pwa-animation-step-1" class="li-img" :src="getSrc1" @click="setOverlay(true)">
            </li>
            <li>
              <p>{{ $t('contentLine2') }}</p>
              <img class="li-img" :src="getSrc2" @click="setOverlay(true)">
            </li>
            <li>
              <p>{{ $t('contentLine3') }}</p>
              <img class="li-img" :src="getSrc3" @click="setOverlay(true)">
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="showOverlay" class="ios-pwa-dl-backdrop" data-test="ios-pwa-animation-overlay" @click="setOverlay(false)">
      <div class="ios-pwa-dl-popover">
        <div class="instructions-container"><span>{{ $t('additionalOverlay_split1') }}</span> <img class="li-img" src="/img/pwa/screen/ios/tap-arrow-icon.svg"> <span>{{ $t('additionalOverlay_split2') }}</span></div>
        <img src="/img/pwa/screen/ios/arrowdown-popover.svg" class="arrowdown-popover">
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/pwa-animation/translations/ios.json"></i18n>

<script>
export default {
  data: () => ({
    showOverlay: false
  }),
  computed: {
    getSrc1 () {
      return this.isEn ? '/img/pwa/screen/ios/tip-1-ios-pwa-en.png' : '/img/pwa/screen/ios/tip-1-ios-pwa.png'
    },
    getSrc2 () {
      return this.isEn ? '/img/pwa/screen/ios/tip-2-ios-pwa-en.png' : '/img/pwa/screen/ios/tip-2-ios-pwa.png'
    },
    getSrc3 () {
      return this.isEn ? '/img/pwa/screen/ios/tip-3-ios-pwa-en.png' : '/img/pwa/screen/ios/tip-3-ios-pwa.png'
    },
    isEn () {
      return this.$i18n.locale === 'en'
    },
    appUrl () {
      return process.env.KRYPTON_APP_URL
    },
    PHOTO_FALLBACK_A2_2720 () {
      return this.$store.getters['featureflag/isEnabled']('PHOTO_FALLBACK_A2_2720')
    },
    gifGuideUrl () {
      return this.PHOTO_FALLBACK_A2_2720 ? 'https://d1muixarkip6az.cloudfront.net/apv/ios-v2.gif' : 'https://res.cloudinary.com/ama-production/image/upload/q_auto/v1636360994/apv/ios-v2.gif'
      // return 'https://res.cloudinary.com/ama-production/image/upload/q_auto/v1636360994/apv/ios-v2.gif'
    }
  },
  methods: {
    setOverlay (so = true) {
      this.showOverlay = so
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes topToBottom {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, -20px);
  }
}

.ios-main {
  background: rgb(118,56,250);
  background: linear-gradient(124deg, rgba(118,56,250,1) 0%, rgba(211,0,197,1) 50%, rgba(255,0,115,1) 100%);
  position: relative;
  height: 100vh;
  width: 100%;

  .contain {
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    bottom: 0;
    height: 92%;
    overflow: scroll;
    padding: 32px 24px 0 24px;
    position: fixed;
    text-align: center;
    width: 100%;

    .content {
      margin: 0 auto;
      width: 360px;

      @media (max-width: 420px) { width: 100%; }

      .li-img { margin-top: 12px; width: 100%; }
      h2 { color: #2E353D; font-size: 38px; line-height: 44px; margin: 0 0 32px 0; }
      p { color: #2E353D; font-size: 18px; line-height: 26px; margin-bottom: 24px; }

      .steps {
        margin: 32px 0 20px 0; text-align: left;

        ul { margin: 0; padding: 0; }

        li {
          display: flex;
          flex-direction: column;
          margin-bottom: 32px;
          width: 100%;

          p { margin: 0; padding: 0; }
          .forward-img { display: inline-flex; margin-right: 8px; vertical-align: text-bottom; }
          .one { height: 24px; }
          .two { height: 20px; margin-top: 3px; }
          .three { height: 20px; margin-top: 3px; }
        }
      }
    }
  }

  .ios-pwa-dl-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7) !important;

    .ios-pwa-dl-popover {
      bottom: 0;
      position: fixed;
      text-align: center;
      width: 100%;

      .instructions-container {
        width: 100%;
        padding: 32px 24px;
        text-align: center;
        background: #FFFFFF;
        color: #2F3740;
        border-bottom: 4px solid #06D6A0;

        img {
          margin-left: 8px;
          margin-right: 8px;
        }
      }

      .triangle-pointer {
        width: 30px;
        height: 16px;
        background: linear-gradient(154deg, #06D6A0, #FFD600);
        clip-path: polygon(50% 100%, 0% 0%, 100% 0%);
        display: inline-flex;
        align-self: center;
      }

      .arrowdown-popover {
        width: 38px;
        height: 20px;
      }
    }

  }
}

</style>
