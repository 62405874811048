
var sizes = []

sizes.results = {
  xs: 160,
  sm: 190,
  sl: 220,
  sxl1: 250,
  sxl2: 300,
  m: 160,
  xm: 190,
  xxm1: 220,
  xxm2: 230,
  l: 250,
  xl1: 270,
  xl2: 300,
  xl3: 320,
  xxl: 350
}

sizes.profile = {
  xs: 320,
  sm: 375,
  sl: 425,
  sxl1: 500,
  sxl2: 600,
  m: 700,
  xm: 800,
  xxm1: 900,
  xxm2: 992,
  l: 360,
  xl1: 380,
  xl2: 400,
  xl3: 450,
  xxl: 550
}

sizes.lightbox = {
  xs: 320,
  sm: 375,
  sl: 425,
  sxl1: 500,
  sxl2: 600,
  m: 700,
  xm: 800,
  xxm1: 900,
  xxm2: 992,
  l: 1100,
  xl1: 1080,
  xl2: 1080,
  xl3: 1080,
  xxl: 1080
}

export default {
  link (link, crop, overlay, size) {
    var url = link.replace('resp_size', size)

    url = url.replace('crop/', crop)

    return url.replace('overlay/', overlay)
  },
  size (type) {
    var width = this.width(type)
    var crop = process.env.FE_PHOTO_CROP
    var height = Math.floor((width * process.env.FE_PHOTO_RATIO_HEIGHT) / process.env.FE_PHOTO_RATIO_WIDTH)

    return type !== 'lightbox'
      ? crop + ',w_' + width + ',h_' + height + ',q_' + process.env.FE_PHOTO_QUALITY
      : crop + ',w_' + width + ',q_' + process.env.FE_PHOTO_QUALITY
  },
  transform (transformations, type) {
    var url = ''

    if (transformations) {
      if (transformations.hasOwnProperty(type)) {
        for (var param in transformations[type]) {
          url += transformations[type].hasOwnProperty(param)
            ? param + '_' + transformations[type][param] + ','
            : ''
        }

        // Remove the last comma from the url
        url = url.replace(/\,$/, '') + '/'
      }
    }

    return url
  },
  width (type) {
    var width = document.body.clientWidth

    if (width >= 1440) {
      return sizes[type].xxl
    } else if (width > 1300 && width < 1440) {
      return sizes[type].xl3
    } else if (width > 1200 && width <= 1300) {
      return sizes[type].xl2
    } else if (width > 1100 && width <= 1200) {
      return sizes[type].xl1
    } else if (width > 992 && width <= 1100) {
      return sizes[type].l
    } else if (width > 900 && width <= 992) {
      return sizes[type].xxm2
    } else if (width > 800 && width <= 900) {
      return sizes[type].xxm1
    } else if (width > 700 && width <= 800) {
      return sizes[type].xm
    } else if (width > 600 && width <= 700) {
      return sizes[type].m
    } else if ((width > 500) && (width <= 600)) {
      return sizes[type].sxl2
    } else if ((width > 425) && (width <= 500)) {
      return sizes[type].sxl1
    } else if ((width > 375) && (width <= 425)) {
      return sizes[type].sl
    } else if ((width > 320) && (width <= 375)) {
      return sizes[type].sm
    } else {
      return sizes[type].xs
    }
  }
}
