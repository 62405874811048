import { render, staticRenderFns } from "./dress-size.vue?vue&type=template&id=a1288170&"
import script from "./dress-size.vue?vue&type=script&lang=js&"
export * from "./dress-size.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@components/register/translations/dress-size.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fforge%2Fama-provider8-stage.noznom.tech%2Fsrc%2Fjs%2Fcomponents%2Fregister%2Fbasic%2Fdress-size.vue"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider8-stage.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a1288170')) {
      api.createRecord('a1288170', component.options)
    } else {
      api.reload('a1288170', component.options)
    }
    module.hot.accept("./dress-size.vue?vue&type=template&id=a1288170&", function () {
      api.rerender('a1288170', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/register/basic/dress-size.vue"
export default component.exports