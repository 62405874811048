var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "animated",
        xmlns: "http://www.w3.org/2000/svg",
        width: "65.569",
        height: "65.569",
        viewBox: "0 0 100 100"
      }
    },
    [
      _c("circle", { attrs: { cx: "50", cy: "50", r: "45", fill: "none" } }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          fill: "none",
          "stroke-linecap": "round",
          "stroke-width": "5",
          stroke: "#f5f7fa",
          "stroke-dasharray": "251.2,0",
          d: "M50 10\n         a 40 40 0 0 1 0 80\n         a 40 40 0 0 1 0 -80"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          fill: "none",
          "stroke-linecap": "round",
          "stroke-width": "5",
          stroke: "#00d96f",
          "stroke-dasharray": _vm.getPercentageBarValue,
          d: "M50 10\n         a 40 40 0 0 1 0 80\n         a 40 40 0 0 1 0 -80"
        }
      }),
      _vm._v(" "),
      _c(
        "text",
        {
          staticClass: "count-class",
          attrs: {
            id: "count",
            x: "50",
            y: "50",
            "text-anchor": "middle",
            dy: "7",
            "font-size": "20",
            fill: "#78838F",
            "font-family": "Proxima Nova, Helvetica, sans-serif"
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.percentage + "%") + "\n  ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }