var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ios-main" }, [
    _c("div", { staticClass: "contain" }, [
      _c("div", { staticClass: "content" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("appName")))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("appDescription")))]),
        _vm._v(" "),
        _c("div", { staticClass: "steps" }, [
          _c("ul", [
            _c("li", [
              _c("p", [_vm._v(_vm._s(_vm.$t("contentLine1")))]),
              _vm._v(" "),
              _c("img", {
                staticClass: "li-img",
                attrs: {
                  "data-test": "ios-pwa-animation-step-1",
                  src: _vm.getSrc1
                },
                on: {
                  click: function($event) {
                    return _vm.setOverlay(true)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", [_vm._v(_vm._s(_vm.$t("contentLine2")))]),
              _vm._v(" "),
              _c("img", {
                staticClass: "li-img",
                attrs: { src: _vm.getSrc2 },
                on: {
                  click: function($event) {
                    return _vm.setOverlay(true)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("li", [
              _c("p", [_vm._v(_vm._s(_vm.$t("contentLine3")))]),
              _vm._v(" "),
              _c("img", {
                staticClass: "li-img",
                attrs: { src: _vm.getSrc3 },
                on: {
                  click: function($event) {
                    return _vm.setOverlay(true)
                  }
                }
              })
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.showOverlay
      ? _c(
          "div",
          {
            staticClass: "ios-pwa-dl-backdrop",
            attrs: { "data-test": "ios-pwa-animation-overlay" },
            on: {
              click: function($event) {
                return _vm.setOverlay(false)
              }
            }
          },
          [
            _c("div", { staticClass: "ios-pwa-dl-popover" }, [
              _c("div", { staticClass: "instructions-container" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("additionalOverlay_split1")))
                ]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "li-img",
                  attrs: { src: "/img/pwa/screen/ios/tap-arrow-icon.svg" }
                }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.$t("additionalOverlay_split2")))])
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "arrowdown-popover",
                attrs: { src: "/img/pwa/screen/ios/arrowdown-popover.svg" }
              })
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }