<template>
  <div id="performance-card"
       class="card"
       :class="{ 'no-rating': !hasRating, 'sub-rating': icon !== RATING_ICON.STAR }">
    <div class="header">
      <slot name="header" />
    </div>

    <!-- Rating -->
    <ul class="rating">
      <li v-for="(type, idx) in renderedPoints" :key="idx">
        <i class="mdi" :class="ratingIconClass(type)" />
      </li>
    </ul>
    <p v-if="hasRating" class="rating-text">
      <strong v-if="icon === RATING_ICON.STAR">{{ ratingText }}</strong>
      <span v-else>{{ ratingText }}</span>
    </p>
    <p v-else class="rating-text">{{ $translate('provider_performance.rating.no_rating') }}</p>

    <!-- Chart -->
    <table id="performance-graph">
      <tbody>
        <tr id="bars" class="qtr">
          <td>
            <div class="last_month bar" :style="{ height: `${ranking.lastMonthPercentage}%` }" />
          </td>
          <td>
            <div class="this_month bar" :style="{ height: `${ranking.thisMonthPercentage}%` }" />
          </td>
          <td>
            <div class="others bar" :style="{ height: `${ranking.othersPercentage}%` }" />
          </td>
        </tr>
        <tr id="labels" class="qtr">
          <td scope="row"><span>{{ $translate('provider_performance.performance_page.rating_last_month') }}</span></td>
          <td scope="row"><strong>{{ $translate('provider_performance.performance_page.rating_this_month') }}</strong></td>
          <td scope="row"><span>{{ $translate('provider_performance.performance_page.rating_others') }}</span></td>
        </tr>
        <tr id="values" class="qtr">
          <td scope="row">
            <span v-if="hasRating">{{ ranking.lastMonth }}</span>
            <span v-else>—</span>
          </td>
          <td scope="row">
            <strong v-if="hasRating">{{ ranking.thisMonth }}</strong>
            <strong v-else>—</strong>
          </td>
          <td scope="row">
            <span v-if="hasRating">{{ ranking.others }}</span>
            <span v-else>—</span>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
import PerformanceRankingModel from '@/features/provider-performance/models/PerformanceRanking'

import ratingMixin from '@/features/provider-performance/mixins/rating'

export default {
  mixins: [ratingMixin],
  props: {
    ranking: {
      type: Object,
      default: () => new PerformanceRankingModel()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./PerformanceCard.style.scss";
</style>
