var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide" } }, [
    _c("div", { attrs: { id: "legal-new" } }, [
      _c("div", { staticClass: "wrapper", attrs: { id: "philosophy" } }, [
        _c("div", { staticClass: "content__area overlay" }, [
          _c("img", {
            attrs: {
              id: "k-logo",
              src: "/img/registration/k-logo.svg",
              alt: "girl with heart graphics"
            }
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "content__title content__title--desktop" }, [
            _vm._v(_vm._s(_vm.$translate("register.agreement.title")))
          ])
        ]),
        _vm._v(" "),
        _c("div", { ref: "scrollArea", staticClass: "content__area" }, [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "top-bar" }, [
              _c(
                "a",
                {
                  staticClass: "back grey-text text-darken-1",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$router.go(-1)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v("chevron_left")
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("h1", { staticClass: "title strong margin-top-none" }, [
              _vm._v(_vm._s(_vm.$translate("philosophy.header")))
            ]),
            _vm._v(" "),
            _c("h2", { staticClass: "subtitle strong" }, [
              _vm._v(_vm._s(_vm.$translate("philosophy.section_1.title")))
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$translate("philosophy.section_1.content_1")))
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$translate("philosophy.section_1.content_2")))
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$translate("philosophy.section_1.content_3")))
            ]),
            _vm._v(" "),
            _c("h2", { staticClass: "subtitle strong" }, [
              _vm._v(_vm._s(_vm.$translate("philosophy.section_2.title")))
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$translate("philosophy.section_2.content_1")))
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$translate("philosophy.section_2.content_2")))
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$translate("philosophy.section_2.content_3")))
            ]),
            _vm._v(" "),
            _c("h2", { staticClass: "subtitle strong" }, [
              _vm._v(_vm._s(_vm.$translate("philosophy.section_3.title")))
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$translate("philosophy.section_3.content_1")))
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$translate("philosophy.section_3.content_2")))
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$translate("philosophy.section_3.content_3")))
            ]),
            _vm._v(" "),
            _vm.$translate("philosophy.section_3.content_4").length
              ? _c("p", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$translate("philosophy.section_3.content_4")) +
                      "\n          "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("h2", { staticClass: "subtitle strong" }, [
              _vm._v(_vm._s(_vm.$translate("philosophy.section_4.title")))
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$translate("philosophy.section_4.content_1")))
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$translate("philosophy.section_4.content_2")))
            ]),
            _vm._v(" "),
            _vm.$translate("philosophy.section_4.content_3").length
              ? _c("p", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$translate("philosophy.section_4.content_3")) +
                      "\n          "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("h2", { staticClass: "subtitle strong" }, [
              _vm._v(_vm._s(_vm.$translate("philosophy.section_5.title")))
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$translate("philosophy.section_5.content_1")))
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$translate("philosophy.section_5.content_2")))
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "actions flex-grid" },
              [
                _c("slideToTopBtn", {
                  attrs: {
                    text: _vm.$translate("register.navigator.go_top"),
                    classes: "btn btn-black"
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }