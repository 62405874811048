// Load babel polyfill
import 'babel-polyfill'

// Load js-localization
import './localization/localization.js'
import './localization/messages.js'

import {
  AGREEMENT_TERM,
  AVAILABILITY_FILTER_TYPES,
  AVAILABILITY_STATUS,
  BILLING_ID_STATUS,
  COUNTRY_ORDER,
  EVENT_STATUS,
  REACTIVATION_STATUS,
  SUPPORT_CATEGORY,
  SUPPORT_TICKET_STATUS,
  TICKET_STATUS,
  DAYS
} from './constants/global'

import API from './features/api'
import Monitoring from '@/services/ama-tools/services/MonitoringService'
import ServiceWorkerMonitor from '@/services/ServiceWorkerMonitoringService'
import VisibilityService from '@/services/ama-tools/services/VisibilityService'

Monitoring.init()
ServiceWorkerMonitor.init()

VisibilityService.addListeners([(visibility) => {
  if (visibility === VisibilityService.VISIBILITY_TYPE.HIDDEN) {
    Monitoring.sendAction('app in background')
  } else {
    Monitoring.sendAction('app in focus')
  }
}])

const APP_VERSION = JSON.stringify(
  require('../../package.json').version
).replace(/\"/g, '')
const isPWA =
  window.matchMedia('(display-mode: standalone)').matches ||
  window.matchMedia('(display-mode: fullscreen)').matches ||
  window.navigator.standalone === true ||
  window.location.search.indexOf('utm_source=homescreen') >= 0

// Load localforage
window.localforage = require('localforage')

// Set default locale
const re = /lang=(en|de)/m
const query = location.search
const searchRes = (typeof query === 'string') ? re.exec(query) : []

if (searchRes) {
  Lang.setLocale(searchRes[1])
  // Reset URL query
  const uri = window.location.toString()
  if (uri.indexOf('?') > 0) {
    const cleanURI = uri.substring(0, uri.indexOf('?'))
    window.history.replaceState({}, document.title, cleanURI)
  }
} else {
  localforage.getItem('app_lang', (err, lang) => {
    if (!err && lang) {
      Lang.setLocale(lang)
    } else {
      Lang.setLocale('de')
    }
  })
}

// Load lodash library
window._ = require('lodash')

// Load jQuery library
window.$ = window.jQuery = require('jquery')

// Load materialize library
window.Materialize = require('materialize-css')
// Load no UI slider from materialize extras
window.noUiSlider = require('./nouislider/nouislider.js')

// Load autosize
window.autosize = require('autosize')

// Inject moment into window
window.moment = require('moment-timezone')

moment.tz.setDefault(process.env.TIMEZONE)

window.api = new API()

window.api.requestInterceptor = {
  success: (response) => {
    // Add pwa_version on URL payload
    if (response.url.indexOf('/api/provider') > 0 && isPWA) {
      response.url = `${response.url}&version=${APP_VERSION}`
    }
    return response
  }
}

// Adds finally method to axios class
require('promise.prototype.finally')

// Load plugins
require('./plugins/_plugins.js')

// Load plugins
require('./helpers/_helpers.js')

// Load the components
require('./components/_components.js')

// Trigger token checking
const AuthProvider = $h.getProvider('AuthProvider')
const getMagicLinkCode = Object.fromEntries(new URLSearchParams(window.location.search).entries())?.utm_tcode ?? null

if (getMagicLinkCode) {
  AuthProvider.authUsingMagicLink(getMagicLinkCode)
} else {
  AuthProvider.validateToken()
}

// GLOBAL VARIABLE
window.AGREEMENT_TERM = AGREEMENT_TERM
window.AVAILABILITY_FILTER_TYPES = AVAILABILITY_FILTER_TYPES
window.AVAILABILITY_STATUS = AVAILABILITY_STATUS
window.BILLING_ID_STATUS = BILLING_ID_STATUS
window.COUNTRY_ORDER = COUNTRY_ORDER
window.DAYS = DAYS
window.EVENT_STATUS = EVENT_STATUS
window.REACTIVATION_STATUS = REACTIVATION_STATUS
window.SUPPORT_CATEGORY = SUPPORT_CATEGORY
window.SUPPORT_TICKET_STATUS = SUPPORT_TICKET_STATUS
window.TICKET_STATUS = TICKET_STATUS

// iPhone add to home screen overlay
window.AddToHomeScreen = require('./add-to-homescreen')

Monitoring.sendAction('App bootstrap finished')
