<template>
  <div id="login-desktop" class="login-page">
    <desktop-header />
    <div id="content" class="container margin-top-x6">
      <div class="card">
        <div id="header">
          <div class="text-darken-1" style="line-height: 36px">{{ $translate('login.header_line_1') }}</div>
          <div class="text-darken-1">{{ $translate('login.header_line_2') }}</div>
        </div>
        <vform @submit="doSubmit">
          <div
            v-if="error"
            id="login-desktop-error-msgs" class="grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align">
            <small>{{ error }}</small>
          </div>
          <md-input
            id="login-desktop-username"
            data-cy="email-field"
            :value="username"
            type="text"
            class="textfield-box-lg"
            autocomplete="username"
            tabindex="1"
            :label="$translate('login.email')"
            @input="onUsernameChange" />
          <md-input
            id="login-desktop-password"
            data-cy="password-field"
            :value="password"
            type="password"
            class="textfield-box-lg"
            autocomplete="password"
            tabindex="2"
            :label="$translate('login.password')"
            @input="onPasswordChange" />
          <button
            id="login-desktop-submit-btn"
            data-cy="login-btn"
            type="submit"
            tabindex="3"
            class="btn btn-raised btn-large waves-effect waves-light display-block btn-krypton-pink waves-effect waves-light">
            {{ $translate('login.continue') }}
          </button>
          <div class="margin-top-lg" style="font-size: 14px;">
            <a id="login-desktop-forgotpass-link" href="#" @click.prevent="$router.push({ name: 'forgot-password' })">{{ $translate('login.forgot_password') }}</a>
            <div>
              <span style="color: #757575;">{{ $translate('login.no_account_yet') }}</span>
              <a id="login-desktop-register-link" href="#" @click.prevent="$router.push({ name: 'register-start' })">
                {{ $translate('login.register') }}
              </a>
            </div>
          </div>
        </vform>
      </div>
    </div>
  </div>
</template>

<script>
import DesktopHeader from './desktop-header.vue'
import LoginViewController from './mixins/login-view.controller'

export default {
  components: { DesktopHeader },
  mixins: [LoginViewController]
}
</script>
