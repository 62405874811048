var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.options.length
    ? _c(
        "div",
        { staticClass: "wrapper", attrs: { id: "origin" } },
        [
          [
            _c("div", { staticClass: "content__area" }, [
              _c("img", {
                attrs: {
                  id: "k-logo",
                  src: "/img/registration/k-logo.svg",
                  alt: "k-logo"
                }
              }),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "content__stepper content__stepper--mobile" },
                [_vm._v(" " + _vm._s(_vm.stepperLabel))]
              ),
              _vm._v(" "),
              _c(
                "h2",
                { staticClass: "content__title content__title--desktop" },
                [_vm._v(_vm._s(_vm.$translate("register.basic.title.origin")))]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content__area drawer" }, [
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c(
                    "p",
                    {
                      staticClass: "content__stepper content__stepper--desktop"
                    },
                    [_vm._v(" " + _vm._s(_vm.stepperLabel))]
                  ),
                  _vm._v(" "),
                  _c(
                    "h2",
                    { staticClass: "content__title content__title--mobile" },
                    [
                      _vm._v(
                        _vm._s(_vm.$translate("register.basic.title.origin"))
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "selected-text-container" }, [
                    _vm.selectedText
                      ? _c("p", { staticClass: "center-align selected-text" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.selectedText) +
                              "\n          "
                          )
                        ])
                      : _c("p", [_vm._v("   ")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "md-select",
                    {
                      staticClass: "textfield-box-lg",
                      attrs: {
                        "force-mobile": true,
                        "desktop-display-mode": "split",
                        label: _vm.$translate(
                          "register.basic.origin.input_label"
                        ),
                        default: _vm.$translate(
                          "register.basic.origin.input_default"
                        ),
                        placeholder: _vm.$translate(
                          "register.basic.title.origin"
                        )
                      },
                      model: {
                        value: _vm.payload.origin,
                        callback: function($$v) {
                          _vm.$set(_vm.payload, "origin", $$v)
                        },
                        expression: "payload.origin"
                      }
                    },
                    [
                      _vm._l(_vm.options, function(option, i) {
                        return _c(
                          "option",
                          {
                            key: "origin-option-" + i,
                            attrs: { slot: "options" },
                            domProps: { value: option.key },
                            slot: "options"
                          },
                          [_vm._v(_vm._s(option.content))]
                        )
                      }),
                      _vm._v(" "),
                      _vm.errors.has("origin")
                        ? _c(
                            "div",
                            {
                              staticClass: "input-helper red-text",
                              attrs: { slot: "errors" },
                              slot: "errors"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.errors.first("origin")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("register-navigator-v2", {
                    attrs: {
                      "next-disabled": _vm.isCTADisabled,
                      total: _vm.total,
                      current: _vm.current
                    },
                    on: {
                      prev: function($event) {
                        $event.preventDefault()
                        return _vm.gotoPrev($event)
                      },
                      next: function($event) {
                        $event.preventDefault()
                        return _vm.submit($event)
                      }
                    }
                  })
                ],
                1
              )
            ])
          ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "drawer-icon center-align" }, [
      _c("img", {
        attrs: { src: "/img/registration/basic/origin.svg", alt: "globe icon" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }