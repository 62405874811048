export const MAX_RATING = 5
export const RANKING = {
  TOP_10: 10,
  TOP_30: 30,
  BELOW_TOP_30: 31
}
export const RATING_ICON = {
  CIRCLE: 'circle',
  STAR: 'star'
}
export const RENDER_RATING = {
  EMPTY: 0,
  HALF: 1,
  FULL: 2
}
