<template>
  <div id="name" class="wrapper">
    <template>
      <div class="content__area">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <p class="content__stepper content__stepper--mobile"> {{ stepperLabel }}</p>
        <h2 class="content__title content__title--desktop">{{ $translate('register.personality.title.name') }}</h2>
      </div>

      <div class="content__area drawer">
        <div class="content center-align">
          <p class="content__stepper content__stepper--desktop"> {{ stepperLabel }}</p>
          <h2 class="content__title content__title--mobile">{{ $translate('register.personality.title.name') }}</h2>

          <strong v-if="!payload.name" class="name-placeholder margin-bottom-lg">{{ $translate('register.personality.name_placeholder') }}</strong>
          <strong v-if="payload.name" class="name margin-bottom-lg">{{ payload.name }}, {{ user.profiles.age }}</strong>

          <div v-if="errors.has('name')" class="input-helper red-text center-align">
            {{ errors.first('name') }}
          </div>

          <a href="#" class="btn generate-btn margin-bottom-5x" @click.prevent="generateName">
            <span v-if="!payload.name">{{ $translate('register.personality.create_name_btn') }}</span>
            <span v-if="payload.name">{{ $translate('register.personality.change_name_btn') }}</span>
          </a>

          <p class="center-align">{{ $translate('register.personality.create_name_hint') }}</p>

          <register-navigator-v2
            :next-disabled="isCTADisabled"
            :total="total"
            :current="current"
            @prev.prevent="gotoPrev"
            @next.prevent="submitForm"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { isEmpty } from '@/constants/helpers.js'
const RegisterProvider = $h.getProvider('RegisterProvider')

export default {
  name: 'RegisterPersonalityStyle',
  props: ['total', 'current', 'stepperLabel'],
  data () {
    return {
      payload: {
        name: ''
      }
    }
  },
  computed: {
    isCTADisabled () {
      return isEmpty(this.payload.name)
    },
    user () {
      return this.$store.getters.getUser
    },
    isValidForm () {
      return this.payload.name
    }
  },
  mounted () {
    this.initUser()
  },
  methods: {
    gotoPrev () {
      this.$router.push({ name: 'register-personality', params: { type: 'style' } })
    },
    submitForm () {
      this.errors.clear()

      if (this.getErrors()) {
        return false
      }

      var payload = {
        name: this.payload.name
      }

      RegisterProvider
        .submitField('submit-name', payload)
        .then((res) => {
          this.$store.commit('setUser', res.data)
          this.$router.push({ name: 'register-overview' })
        })
    },
    getErrors () {
      if (this.isValidForm) {
        return false
      }

      if (!this.payload.name) {
        this.errors.add({
          field: 'name',
          msg: this.$translate('errors.select', {
            field: this.$translate('register.personality.name.name')
          })
        })
      }

      return true
    },
    generateName () {
      RegisterProvider
        .generateName()
        .then((res) => {
          this.payload.name = res.data.name
        })
    },
    initUser () {
      if (!this.user) return

      this.payload.name = this.user.profiles.name
    }
  }
}
</script>
