const VISIBILITY_TYPE = Object.freeze({
  HIDDEN: 0,
  VISIBLE: 1
})

const getEventName = () => {
  // Set the name of the hidden property and the change event for visibility
  let visibilityChange
  if (typeof document.hidden !== 'undefined') {
    visibilityChange = 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    visibilityChange = 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    visibilityChange = 'webkitvisibilitychange'
  } else {
    throw new Error('visibility api not detected')
  }

  return visibilityChange
}

function createVisibilityHandler (listener) {
  return () => {
    if (document.visibilityState === 'hidden' ||
      document.visibilityState === 'unloaded' ||
      document.visibilityState === 'prerender') {
      listener(VISIBILITY_TYPE.HIDDEN)
    } else {
      listener(VISIBILITY_TYPE.VISIBLE)
    }
  }
}

/**
 * the visibility event listener
 * @callback listener
 * @param {VISIBILITY_TYPE}
 */

/**
 * @param {listener[]} listeners
 */
const addListeners = (listeners) => {
  try {
    const visibilitychange = getEventName()
    // Handle page visibility change
    listeners.forEach(listener => {
      document.addEventListener(visibilitychange, createVisibilityHandler(listener), false)
    })
  } catch (err) {
    console.log(err.message)
  }
}

export default {
  addListeners,
  VISIBILITY_TYPE
}
