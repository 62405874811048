var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal left-align", staticStyle: { "max-height": "80%" } },
    [
      _c(
        "div",
        {
          staticClass: "modal-content",
          staticStyle: { padding: "32px 24px 2px" }
        },
        [
          _c("h4", {
            staticClass: "modal-title center",
            staticStyle: { "font-size": "20px" },
            domProps: {
              innerHTML: _vm._s(
                _vm.$translate(
                  "events.event_cancelled.modal.abort_cancellation.title",
                  {
                    client_name: _vm.evt.client.name
                  }
                )
              )
            }
          }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "grey-text text-darken-1 center",
              staticStyle: { "font-size": "14px" }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$translate(
                      "events.event_cancelled.modal.abort_cancellation.explanation"
                    )
                  ) +
                  "\n    "
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-footer",
          staticStyle: { "border-top": "1px solid #E1E6EB" }
        },
        [
          _c(
            "a",
            {
              staticClass:
                "btn-flat waves-effect waves-dark krypton-pink-text center",
              attrs: { id: "closed-event-abort-confirm-btn", href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.confirm($event)
                }
              }
            },
            [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$translate(
                      "events.event_cancelled.cta_button.ok_understand"
                    )
                  )
                )
              ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "alarm-icon center" }, [
      _c("i", { staticClass: "material-icons" }, [_vm._v("timer")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }