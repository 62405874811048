<template>
  <div v-if="isViewReady" id="event-confirmed">
    <md-navbar
      :go="-1"
      :brand="$translate('events.nav_title')"
      :menu-trigger="false"
      icon="arrow_back"
    />
    <div v-if="checkExpiration" class="container padding-x-xl center">
      <h1 class="title strong center-align margin-top-none">
        {{
          $translate('events.confirm_popup.header', {
            client_name: selectedEvent.client.name
          })
        }}
      </h1>
      <p>{{ $translate('events.confirm_popup.note', { client_name: selectedEvent.client.name }) }}</p>
      <div>
        <strong class="center-countdown countdown">{{ meetingCountdown }}</strong>
      </div>
      <br>
      <md-textarea
        id="event-confirmed-textarea"
        v-model="message"
        type="multiline"
        :force-active="true"
        :maxlength="answerMaxChar"
        :label="$translate('events.confirm_popup.message_label')"
      >
        <div>
          <span class="input-helper grey-text text-darken-1 right">{{ getMaxChar(message) }}</span>
        </div>
      </md-textarea>
      <button
        id="event-confirmed-send-btn"
        class="btn btn-large btn-krypton-pink btn-full margin-top-lg margin-bottom waves-effect waves-dark"
        @click="send"
      >
        <span v-if="!isSending">{{ $translate('events.buttons.send') }}</span>
        <div v-else id="event-confirmed-send-loading" class="preloader-wrapper small active">
          <div class="spinner-layer spinner-green-only">
            <div class="circle-clipper left">
              <div class="circle" />
            </div>
            <div class="gap-patch">
              <div class="circle" />
            </div>
            <div class="circle-clipper right">
              <div class="circle" />
            </div>
          </div>
        </div>
      </button>
      <a
        href="#"
        class="waves-effect waves-dark"
        @click.prevent="showTooltip"
      >{{ $translate('events.confirm_popup.explanation_link') }}</a>
    </div>
  </div>
</template>

<script>
import NullSelectCheck from '../mixins/nullSelectCheck.js'

import { mapGetters } from 'vuex'

import ModalManager from '@/managers/ModalManager'
import { Modal } from '@/models/Modal'

const ApiProvider = $h.getProvider('ApiProvider')

export default {
  name: 'EventConfirmed',
  mixins: [NullSelectCheck],
  props: ['event'],
  data () {
    return {
      shouldAskUser: true,
      isSending: false,

      messageDeadline: null,
      answerMaxChar: 120,
      message: ''
    }
  },
  mounted () {
    /**
     * Set the CWP Ready for reveal to false
     * because we want to show it after lesson reminder or confirm message
     * */
    this.$store.commit('setIsCWPReadyForReveal', false)
  },
  methods: {
    send () {
      if (this.isSending) return
      if (this.message.length < 30 || this.message.length > this.answerMaxChar) {
        this.$notifications.toast(
          this.$translate('events.rate.thumbs_down_textarea_error'),
          5000
        )
        return
      }
      if ($h.calcTimeDiff(
        this.currentTime,
        this.messageDeadline,
        'seconds'
      ) < 0) {
        return
      }

      const pl = {
        id: this.selectedEvent.id,
        payload: {
          message: this.message
        }
      }

      this.shouldAskUser = false

      this.isSending = true
      this.$store
        .dispatch('events/confirm', pl)
        .then(res => {
          this.$notifications.toast(res.message, 5000)
        })
        .finally(() => {
          this.isSending = false

          if (!this.openOnboardingLessonReminder()) {
            this.$router.replace({ name: 'events' })
            this.$store.commit('events/SET_SELECTED', null)
            // Reveal CWP after event confirmation
            this.$store.commit('setIsCWPReadyForReveal', true)
          }
        })
    },
    showTooltip () {
      this.$notifications.toast(
        this.$translate('events.confirm_popup.explanation_text'),
        5000
      )
    },
    openOnboardingLessonReminder () {
      if (!this.showOnboardingLessonReminder) {
        return false
      }

      const id = 'onboarding-lesson-reminder-modal'
      const modalName = 'onboarding-lesson-reminder-modal'
      const propsData = {}
      const callback = () => {
        // Reveal CWP after event confirmation
        this.$store.commit('setIsCWPReadyForReveal', true)

        // Redirect to onboarding lessons page
        this.$router.replace({ name: 'onboarding-lessons' })
      }
      const modal = new Modal(id, modalName, callback, propsData)
      ModalManager.showModal(modal)

      return true
    }
  },
  computed: {
    ...mapGetters({
      currentTime: 'getCurrentTime',
      user: 'getUser',
      onboardingProgress: 'onboarding/GET_ONBOARDING_PROGRESS',
      onboardingShowBottomSheet: 'onboarding/GET_SHOW_BOTTOM_SHEET'
    }),
    showOnboardingLessonReminder () {
      return this.user._has_successful_meeting === 0 && this.onboardingProgress < 100 && this.onboardingShowBottomSheet
    },
    getCharCount () {
      return answer => {
        if (!answer) return 0
        return answer.length
      }
    },
    getMaxChar () {
      return answer => {
        return this.getCharCount(answer) + '/' + this.answerMaxChar
      }
    },
    meetingCountdown () {
      return `${this.$translate(
        'events.confirm_popup.time_left'
      )}: ${$h.calcCountdown(
        this.messageDeadline,
        this.currentTime,
        false,
        'alt',
        false
      )}`
    },
    checkExpiration () {
      const diff = $h.calcTimeDiff(
        this.currentTime,
        this.messageDeadline,
        'seconds'
      )

      if (diff === -1) {
        if (!this.openOnboardingLessonReminder()) {
          this.$store.commit('setIsCWPReadyForReveal', true)
        }

        this.$notifications.toast('Accept message expired', 5000)
        this.shouldAskUser = false
        this.$router.go(-1)
      }

      return true
    }
  },
  created () {
    this.messageDeadline = moment(this.currentTime).add(3, 'minutes')
  },
  beforeRouteLeave (to, from, next) {
    if (this.selectedEvent !== null) {
      if (this.shouldAskUser) {
        const options = {
          title: this.$translate('events.accept_popup.confirm_title'),
          content: this.$translate('events.accept_popup.confirm_content', {
            client_name: this.selectedEvent.client.name
          }),
          confirmText: this.$translate('events.accept_popup.confirm_button'),
          cancelText: this.$translate('events.accept_popup.cancel_button')
        }

        $h.invertedCustomModalConfirm(options, $e => {
          if (this.openOnboardingLessonReminder()) {
            this.shouldAskUser = false
          } else {
            next()
          }
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }
}
</script>

<style lang="scss" scoped>
#event-confirmed {
  & {
    position: relative;
  }
  .close {
    padding: 10px 10px 4px;
    top: 16px;
    right: 16px;
    position: absolute;
    cursor: pointer;
    z-index: 999;
  }
  .title {
    font-size: 38px;

    @media only screen and (max-width: 326px) {
      font-size: 32px;
    }
  }
  .subtitle {
    font-size: 1.125rem;
  }
  .accepted-page {
    & {
      text-align: center;
    }
    #heading {
      & {
        font-weight: 900;
        font-size: 2.75rem;
        font-style: italic;
        line-height: 45px;
      }
      .ic {
        margin-top: 10px;
        filter: brightness(0) invert(1);
      }
    }
  }
}
</style>
