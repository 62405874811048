import {
  calculateBoundarySize,
  calculateCropViewSize,
  calculateThreeByFourRatio
} from './utils'

const CLOUDINARY_URL = 'https://res.cloudinary.com/ama-production/image/private/'
const PhotosProvider = $h.getProvider('PhotosProvider')

export default {
  data () {
    return {
      photo: {},
      croppieBoundary: {
        width: null,
        height: null
      },
      croppieViewport: {
        width: null,
        height: null
      },
      loading: true,
      debouncedView: null,
      debounceTimeout: 600
    }
  },
  computed: {
    showMenuTrigger () {
      return !this.isRegister
    },
    showNewDesign () {
      return this.$route.name === 'register-photos-crop' || this.$route.name === 'photos-crop'
    },
    isCroppieViewable () {
      return this.croppieBoundary.width !== null &&
        this.croppieBoundary.height !== null &&
        this.croppieViewport.width !== null &&
        this.croppieViewport.height !== null
    },
    isRegister () {
      return this.$route.name === 'register-photos-crop'
    },
    isApv () {
      return this.$route.name === 'photos-crop'
    }
  },
  created () {
    this.calculateCroppieSize()
  },
  mounted () {
    if (!this.$route.params.photo) {
      this.$router.go(-1)
      return
    }

    this.setPhoto(this.$route.params.photo)

    this.calculateCroppieSize()

    setTimeout(() => {
      this.bindImageToCroppie()
    }, this.debounceTimeout)

    window.addEventListener('beforeunload', this.setAskBeforeClose)
    window.addEventListener('popstate', this.cancelUpload)

    window.addEventListener('resize', this.resetCroppie)
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.setAskBeforeClose)
    window.removeEventListener('popstate', this.cancelUpload)
    window.removeEventListener('resize', this.resetCroppie)

    if (this.debouncedView) {
      clearTimeout(this.debouncedView)
    }
  },
  methods: {
    async bindImageToCroppie () {
      this.loading = true

      await this.$refs.croppie?.bind({
        url: CLOUDINARY_URL + 'w_auto/' + this.photo.public_id
      })

      this.loading = false
    },
    adjustZoom (isAdd) {
      if (!this.$refs.croppie) return

      let zoomVal = this.$refs.croppie.get().zoom

      if (isAdd) {
        zoomVal += 0.1
      } else {
        zoomVal -= 0.1
      }

      this.$refs.croppie.setZoom(zoomVal)
    },
    resetCroppie: function () {
      if (!this.$refs.croppie) return

      this.calculateCroppieSize()

      if (this.debouncedView) {
        clearTimeout(this.debouncedView)
      }

      this.debouncedView = setTimeout(() => {
        this.$refs.croppie.refresh()
        this.bindImageToCroppie()
      }, this.debounceTimeout)
    },
    crop (next, params) {
      if (this.isRegister) {
        params.is_register = true
      } else {
        params.is_register = false
      }

      window.removeEventListener('beforeunload', this.setAskBeforeClose)
      window.removeEventListener('popstate', this.cancelUpload)

      PhotosProvider.cropPhoto(this.photo.id, params)
        .then((res) => {
          this.$router.push({
            name: this.isRegister ? 'register-photos-anonimize' : 'photos-anonimize',
            params: {
              photo: res.data,
              return: this.$route.params.return,
              cancel: this.$route.params.cancel
            }
          })
        })
        .catch((err) => {
          this.$notifications.toast(err.message, 5000)
        })
        .finally(() => {
          next()
        })
    },
    getCropData () {
      const options = {
        type: 'html',
        size: 'original',
        format: 'jpeg',
        quality: 1
      }

      this.$refs.croppie.result(options, (output) => {
        const generatedWidth = Number(output.style.width.split('px')[0])

        const payload = {
          x: Math.abs(Number(output.querySelector('img').style.left.split('px')[0])),
          y: Math.abs(Number(output.querySelector('img').style.top.split('px')[0])),
          w: generatedWidth,
          h: Math.round(calculateThreeByFourRatio(generatedWidth))
        }

        this.$refs.cropForm.setData(payload)
        this.$refs.cropForm.submit()
      })
    },
    setPhoto (photo) {
      this.photo = photo
    },
    async setAskBeforeClose (evt) {
      evt.preventDefault()

      evt.returnValue = ''

      const res = await this.$store.dispatch('cancelUpload', this.photo.id)

      this.$router.go(-1)
    },
    cancelUpload (event) {
      window.removeEventListener('beforeunload', this.setAskBeforeClose)
      window.removeEventListener('popstate', this.cancelUpload)

      this.$store.dispatch('cancelUpload', this.photo.id)
    },
    triggerCancelUpload (event) {
      this.cancelUpload()

      if (this.isRegister) {
        this.$router.push(`/registration/photos/${this.$route.params.cancel?.params?.type}`)
      } else if (this.isApv) {
        this.$router.push('/profile/photos-v2')
      } else {
        this.$router.go(-1)
      }
    },
    calculateCroppieSize () {
      this.$nextTick(() => {
        const drawer = document.getElementById('photo-drawer')
        let boundarySize = {}
        let height = null
        let width = null

        if ((this.isRegister && window.innerWidth < 992) || this.isApv) {
          const drawerHeight = drawer.clientHeight
          const drawerHeaderHeight = 55
          const drawerFixedItemsHeight = 131

          height = drawerHeight - (drawerHeaderHeight + drawerFixedItemsHeight)
        }

        if (this.isApv) {
          width = document.getElementById('photo-drawer').clientWidth
        }

        boundarySize = calculateBoundarySize({
          width,
          height
        })

        this.croppieBoundary = { ...boundarySize }
        this.croppieViewport = { ...calculateCropViewSize(boundarySize) }
      })
    }
  }
}
