<template>
  <div id="photos">
    <div id="crop" class="wrapper wrapper_fixed">
      <div class="content__area overlay">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <h2 class="content__title content__title--desktop">{{ $translate('photos.pinch_zoom_to_crop') }}</h2>
      </div>
      <div id="photo-drawer" class="content__area drawer no-y-padding --with-photo-content --with-both-bars-crop content__drawer">
        <div class="content" style="max-width: unset;">
          <div class="top-bar--drawer" style="border-bottom: none; justify-content: space-between;">
            <a href="#" @click.prevent="triggerCancelUpload">
              <i class="material-icons" style="margin-right: 8px;">close</i>
              <span class="cancel-upload-label">{{ $translate('photos.cancel_upload') }}</span>
            </a>
          </div>

          <div id="content" class="hide"><vform ref="cropForm" @submit="crop" /></div>

          <div v-if="isCroppieViewable" class="crop-container">
            <vue-croppie
              ref="croppie"
              :show-zoomer="false"
              :enable-resize="false"
              :enable-exif="true"
              :enable-orientation="true"
              :boundary="croppieBoundary"
              :viewport="croppieViewport"
            />

            <div class="crop-controls hide-on-med-and-down">
              <button class="crop-controls-btn__zoom-in" @click.prevent="adjustZoom(true)">
                <!-- <i class="material-icons">add</i> -->
              </button>
              <button class="crop-controls-btn__zoom-out" @click.prevent="adjustZoom(false)">
                <!-- <i class="material-icons">remove</i> -->
              </button>
            </div>
          </div>

          <div class="photo-text">
            <strong>{{ $translate('photos.pinch_zoom_to_crop') }}</strong>
          </div>

          <register-navigator-v2
            :no-prev="true"
            @next.prevent="getCropData"
          />
        </div>
      </div>
      <md-screen-preloader v-if="loading" />
    </div>
  </div>
</template>

<script>
import CropController from '@components/account/photos/crop.controller'

export default {
  mixins: [CropController]
}
</script>

<style lang="scss" scoped>
.wrapper_fixed {
  @media screen and (max-width: 991px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    z-index: 9999;
    position: fixed;
    background-color: white;
    overflow: auto;

    .content__drawer {
      width: 100%;
      height: calc(100dvh - 8%);
      background-color: #fff;
      border-radius: 12px 12px 0px 0px;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 10;

      .content {
        height: calc(calc(100dvh - 8%) - 186px);
      }
    }
  }
}
</style>
