<template>
  <div id="performance-rating" class="rating-box" :class="{ 'no-rating': !hasRating }">
    <div v-if="hasRating" class="average-rating valign-middle">
      <i class="valign-middle material-icons">star</i>
      <span class="valign-middle"><strong>{{ ratingText }}</strong></span>
    </div>
    <div v-else class="average-rating no-rating">
      <i v-if="hasRating" class="valign-middle material-icons">star</i>
      <i v-else class="valign-middle material-icons">star_outline</i>
      <span class="valign-middle">{{ $translate('provider_performance.rating.no_rating') }}</span>
    </div>
    <div class="note">
      <span v-if="hasRating">
        <strong v-if="rank === RANKING.TOP_10"><img class="valign-middle" src="/img/performance-overview/medal_enabled.svg"> {{ $translate('provider_performance.rating.top_10') }}</strong>
        <strong v-else-if="rank === RANKING.TOP_30">{{ $translate('provider_performance.rating.top_30') }}</strong>
        <strong v-else class="clickable" @click="$router.push({ name: 'faq' })">
          <span class="display-block" style="font-size: 14px; line-height: 14px;">{{ $translate('provider_performance.rating.tip') }} <i class="mdi mdi-information valign-middle" style="color: #78838f; font-size: 16px;" /></span>
          <span class="krypton-pink-text">{{ $translate('provider_performance.rating.below_top_30') }}</span>
        </strong>
      </span>
      <span v-else><img class="valign-middle" src="/img/performance-overview/medal.svg" style="margin-right: 29px;"> —</span>
    </div>
  </div>
</template>

<script>
import ratingMixin from '@/features/provider-performance/mixins/rating'

export default {
  mixins: [ratingMixin],
  props: {
    isTopRating: {
      type: Boolean,
      default: false
    },
    rank: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./PerformanceRating.style.scss"
</style>
