<template>
  <transition name="slide">

    <div id="new-terms-conditions" class="apv-v2">
      <md-navbar v-if="hasAgreedTerms" icon="arrow_back" :push="{ name: 'setting' }"
        :brand="$translate('sidenav.settings')" />

      <div ref="scrollArea" class="new-container">
        <h1 class="title strong">
          {{ $t('header') }}
        </h1>

        <div v-for="n in 10" :key="n" class="terms">
          <h5 class="title">{{ $t(`content.term_${n}.title`) }}</h5>
          <div class="preline">
            {{ getDescription(n) }}
          </div>
        </div>

        <div class="move-top-btn" :class="{ 'b-important': !hasAgreedTerms }">
          <slideToTopBtn :text="$translate('register.navigator.go_top')" classes="action-btn action-btn__black" />
        </div>

        <div v-if="!hasAgreedTerms" class="controls">
          <form action="#">
            <p>
              <input id="agree" v-model="hasAgreed" type="checkbox" data-test="tc-checkbox">
              <label for="agree">{{ $t('checkmark_text') }}</label>
            </p>
            <button id="terms-btn" :disabled="isDisabled" data-test="tc-cta" class="btn-large display-block"
              @click.prevent="doAgree">
              {{ $t('confirm_btn') }}
            </button>
          </form>
        </div>
      </div>

    </div>

  </transition>
</template>

<i18n src="./translations/terms-conditions.json"></i18n>

<script>
import slideToTopBtn from '@components/helpers/slide-to-top-btn.vue'

const TOAST_ERROR_MESSAGE_DURATION = 5000
const AuthProvider = window?.$h?.getProvider('AuthProvider')

export default {
  components: {
    slideToTopBtn
  },

  data: () => ({
    hasAgreed: false,
    rate: ''
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    homeBaseCountry() {
      return this.$store.getters.getHomeBaseCountry
    },
    newRate() {
      return this.rate
    },
    isDisabled() {
      if (this.hasAgreed === false) {
        return true
      }
      return false
    },
    hasAgreedTerms() {
      if (this.user) {
        return this.user.agree_terms === AGREEMENT_TERM.OVERRIDE_AGREED ||
          this.user.agree_terms === AGREEMENT_TERM.AGREED
      }
      return false
    }
  },
  beforeDestroy() {
    const body = document.getElementsByTagName('body')[0]

    if (body.style.overflowY) {
      body.style.removeProperty('overflow-y')
    }
  },
  mounted() {
    this.setLanguageByHomeBaseCountry()

    const body = document.getElementsByTagName('body')[0]
    body.style.overflowY = 'hidden'
  },
  methods: {
    setLanguageByHomeBaseCountry() {
      if (this.homeBaseCountry === 'DE') {
        this.rate = '33,32'
      } else if (this.homeBaseCountry === 'GB') {
        this.rate = '25'
      } else if (this.homeBaseCountry === 'CH') {
        this.rate = '19'
      } else {
        return false
      }
    },
    getDescription(n) {
      return Object.values(this.$t(`content.term_${n}.desc`)).join('\n\n').replace(':percent', this.newRate)
    },
    doAgree() {
      if (this.hasAgreed) {
        this.$store
          .dispatch('updateAgreeTerms', { agree_terms: AGREEMENT_TERM.AGREED })
          .then(() => {
            /**
             * Get an updated copy of user data
             * and set it on vuex
             * before redirecting to dashboard
             * */
            AuthProvider.fetchUser().then(() => {
              this.$router.push({ name: 'dashboard', query: { utm_source: 'homescreen' } })
            })
          })
          .catch(err => {
            this.$notifications.toast(err, TOAST_ERROR_MESSAGE_DURATION)
          })
      } else {
        this.showErrorMessage()
      }
    },
    showErrorMessage() {
      this.$notifications.toast(this.$t('error_message'), TOAST_ERROR_MESSAGE_DURATION)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './terms-conditions.style';
</style>
