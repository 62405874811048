class CookieService {
  constructor (defaultCookieDomain = null) {
    this.defaultCookieDomain = defaultCookieDomain
  }

  /**
   * Find the correct object from split cookie string and return only the decoded value
   *
   * @param string name
   * @param array cookieArr
   * @return string|null
   */
  static findFromCookieArray (name, cookieArr) {
    const matchingObject = cookieArr.find(o => {
      return name === o.split('=')[0].trim()
    })

    if (matchingObject !== null && matchingObject !== undefined) {
      return decodeURIComponent(matchingObject.split('=')[1])
    }

    return null
  }

  /**
   * Get value of a specific cookie
   *
   * @param string name of cookie
   * @return mixed
   */
  getCookie (name) {
    const cookieArr = window.document.cookie.split(';')
    return this.constructor.findFromCookieArray(name, cookieArr)
  }

  /**
   * Set a cookie
   *
   * @param string name of the cookie
   * @param string value of the cookie
   * @param int duration of the cookie in days
   * @param string path where to save the cookie
   * @param string domain the cookie belongs to
   * @param bool httpOnly
   * @param bool secure
   * @param string samesite Strict|Lax|None
   */
  setCookie (name, val, duration = 30, path = '/', domain = null,
    http = false, secure = false, samesite = '') {
    if (!Number.isInteger(duration)) {
      return false
    }

    const encodedName = encodeURI(name)
    const encodedVal = encodeURI(val)
    const encodedPath = encodeURI(path)
    let cookieString = `${encodedName}=${encodedVal}; path=${encodedPath}`

    // add max-age
    const SESSION_LIFETIME = 0
    const DAYS_IN_SECONDS = 86400
    let computedDuration = ''
    if (duration > SESSION_LIFETIME) {
      computedDuration = duration * DAYS_IN_SECONDS
      cookieString += `; max-age=${computedDuration}`
    }

    // add domain
    if (domain !== null) {
      cookieString += `; domain=${encodeURI(domain)}`
    } else if (this.defaultCookieDomain !== null) {
      cookieString += `; domain=${this.defaultCookieDomain}`
    }

    // add http
    if (http) {
      cookieString += '; HttpOnly'
    }

    // add secure
    if (secure) {
      cookieString += '; secure'
    }

    // add samesite
    if (samesite !== '') {
      cookieString += `; samesite=${samesite}`
    }

    window.document.cookie = cookieString
  }

  /**
   * Get the default domain name for cookies
   *
   * @return string|null
   */
  getDefaultCookieDomain () {
    return decodeURI(this.defaultCookieDomain)
  }

  /**
   * Set a default domain for the cookie
   *
   * @param string domain name
   */
  setDefaultCookieDomain (domain) {
    this.defaultCookieDomain = encodeURI(domain)
  }
}

export default CookieService
