import {
  MAX_RATING,
  RANKING,
  RATING_ICON,
  RENDER_RATING
} from '@/features/provider-performance/constants'

export default {
  computed: {
    renderedPoints () {
      const points = []

      for (let i = 1; i <= MAX_RATING; ++i) {
        const diff = this.rating - i
        if (diff >= 0) {
          points.push(RENDER_RATING.FULL)
        } else if (diff > -1 && diff < 0) {
          points.push(RENDER_RATING.HALF)
        } else {
          points.push(RENDER_RATING.EMPTY)
        }
      }

      return points
    },
    hasRating () {
      return this.rating > 0
    },
    ratingText () {
      return `${this.rating}/${MAX_RATING}`
    }
  },
  data: () => ({
    RANKING,
    RATING_ICON,
    RENDER_RATING,
    MAX_RATING
  }),
  methods: {
    ratingIconClass (type) {
      const result = {}
      const fullIcon = `mdi-${this.icon}`
      const halfFullIcon = `mdi-${this.icon}-half-full`
      const emptyIcon = `mdi-${this.icon}-outline`

      result[halfFullIcon] = type === RENDER_RATING.HALF
      result[emptyIcon] = type === RENDER_RATING.EMPTY
      result[fullIcon] = type === RENDER_RATING.FULL

      return result
    }
  },
  props: {
    icon: {
      type: String,
      default: RATING_ICON.CIRCLE
    },
    rating: {
      type: [Number, String],
      default: 0
    }
  }
}
