var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "browser-banner" }, [
    _c("div", { staticClass: "banner-content" }, [
      _vm.PHOTO_FALLBACK_A2_2720
        ? _c("img", {
            attrs: {
              src: "https://d1muixarkip6az.cloudfront.net/apv/layer1.svg"
            }
          })
        : _c("img", {
            attrs: {
              src:
                "https://res.cloudinary.com/ama-production/image/upload/q_auto/v1644308609/apv/layer1.svg"
            }
          }),
      _vm._v(" "),
      _c("p", {
        domProps: { textContent: _vm._s(_vm.$t("safariBanner.content")) }
      }),
      _vm._v(" "),
      _c("a", {
        staticClass: "banner-content__cta",
        attrs: { href: "https://apps.apple.com/no/app/safari/id1146562112" },
        domProps: { textContent: _vm._s(_vm.$t("safariBanner.cta")) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }