import { getPropertyOrDefault } from '@/helpers/modelUtils'
import { MAX_RATING } from '@/features/provider-performance/constants'

/**
 * @type
 *
 * News model.
*/
export default function PerformanceRanking (props) {
  this.lastMonth = getPropertyOrDefault(props, 'lastMonth', '0.0')
  this.thisMonth = getPropertyOrDefault(props, 'thisMonth', '0.0')
  this.others = getPropertyOrDefault(props, 'others', '0.0')

  // Getters
  /**
   * Last month percentage value.
   * @returns {Number}
  */
  Object.defineProperty(this, 'lastMonthPercentage', {
    get () {
      return (this.lastMonth / MAX_RATING) * 100
    }
  })
  /**
   * This month percentage value.
   * @returns {Number}
  */
  Object.defineProperty(this, 'thisMonthPercentage', {
    get () {
      return (this.thisMonth / MAX_RATING) * 100
    }
  })
  /**
   * Other provider percentage value.
   * @returns {Number}
  */
  Object.defineProperty(this, 'othersPercentage', {
    get () {
      return (this.others / MAX_RATING) * 100
    }
  })
}
