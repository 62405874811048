var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal modal-dropdown modal-fixed-footer left-align" },
    [
      _c("div", { ref: "header", staticClass: "modal-header" }, [
        _vm._v(_vm._s(_vm.header))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { ref: "content", staticClass: "modal-content" },
        _vm._l(_vm.options, function(option) {
          return _c("div", { staticClass: "input-radio" }, [
            _vm.multiple
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data,
                      expression: "data"
                    }
                  ],
                  staticClass: "filled-in multiple",
                  attrs: {
                    type: "checkbox",
                    disabled: option.disabled || _vm.limitReached
                  },
                  domProps: {
                    value: option.val,
                    checked: Array.isArray(_vm.data)
                      ? _vm._i(_vm.data, option.val) > -1
                      : _vm.data
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.data,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = option.val,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.data = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.data = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.data = $$c
                      }
                    }
                  }
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data,
                      expression: "data"
                    }
                  ],
                  staticClass: "with-gap",
                  attrs: { type: "radio", disabled: option.disabled },
                  domProps: {
                    value: option.val,
                    checked: _vm._q(_vm.data, option.val)
                  },
                  on: {
                    change: function($event) {
                      _vm.data = option.val
                    }
                  }
                }),
            _vm._v(" "),
            _c(
              "label",
              {
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.select(option)
                  }
                }
              },
              [_vm._v(_vm._s(option.text))]
            )
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { ref: "footer", staticClass: "modal-footer" }, [
        _c(
          "a",
          {
            staticClass: "btn-flat waves-effect waves-dark",
            attrs: { href: "#!" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.close($event)
              }
            }
          },
          [_vm._v(_vm._s(_vm.$translate("app.cancel")))]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn-flat waves-effect waves-dark krypton-pink-text",
            attrs: { href: "#!" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.confirm($event)
              }
            }
          },
          [_vm._v(_vm._s(_vm.$translate("app.ok")))]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }