<template>
  <div id="closed-event-modal" class="modal left-align" style="max-height: 80%;">
    <div class="modal-content" style="padding: 32px 12px 2px;">
      <h4 class="modal-title center" style="font-size: 20px;">{{ title }}</h4>
      <div class="row schedule">
        <div class="col s6 center-align">
          <i class="material-icons valign-middle">today</i>
          <span>{{ eventDate }}</span>
        </div>
        <div class="col s6 center-align">
          <i class="material-icons valign-middle">access_time</i>
          {{ eventDuration }}
        </div>
      </div>

      <div class="alarm-icon center">
        <i class="material-icons" style="color: #939ea8;">cancel</i>
      </div>

      <p class="center">
        <strong style="font-size: 14px;">{{ summary }}</strong>
      </p>
      <p class="grey-text text-darken-1 center" style="font-size: 11px;" v-html="explanation" />
    </div>
    <div class="modal-footer" style="border-top: 1px solid #E1E6EB; text-align: center;">
      <a href="#" class="btn-flat waves-effect waves-dark krypton-pink-text" @click="confirm">
        <strong>{{ this.$translate('events.closed_event_popup.button') }}</strong>
      </a>
    </div>
  </div>
</template>

<script>
import { getCurrencyByCountry } from '@/helpers/UnitHelper'
export default {
  props: {
    evt: { type: Object, required: true },
    prevState: { type: Object, required: true }
  },
  data () {
    return {
      title: '',
      summary: '',
      explanation: ''
    }
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    currency () {
      return getCurrencyByCountry(this.user?.profiles?.country_code ?? undefined)
    },
    isToday () {
      const today = moment()
      const isSame =
        this.evt._meeting_start_at.format('YYYY-MM-DD') ===
        today.format('YYYY-MM-DD')

      return isSame
    },
    eventDate () {
      if (this.isToday) {
        return this.$translate('apv-card.today')
      }
      return this.evt._meeting_start_at.format('dd, DD MMM').replace('.', '')
    },
    eventDuration () {
      const timeStart = this.evt._meeting_start_at.format('HH:mm')
      const duration = this.$translate('events.labels.meeting_hours', {
        hours: this.evt.meeting_duration
      })

      return `${timeStart}, ${duration}`
    },
    isExpired () {
      return this.evt._event_status === EVENT_STATUS.EXPIRED
    },
    canReactivateEvent () {
      return this.evt._can_reactivate === 1
    },
    canRequestReactivation () {
      return (
        this.evt.reactivation_status === REACTIVATION_STATUS.CAN_REACTIVATE
      )
    },
    isExpiredReactivation () {
      return (
        this.evt.reactivation_status ===
          REACTIVATION_STATUS.REACTIVATION_EXPIRED ||
        this.evt.reactivation_status === REACTIVATION_STATUS.PROVIDER_DECLINED
      )
    }
  },
  mounted () {
    let status = this.evt._event_status

    if (this.evt._prevState) {
      status = this.evt._prevState._event_status
    }

    switch (status) {
      case EVENT_STATUS.CANCELLED_AGENT:
      case EVENT_STATUS.CONFIRMED_READY:
        this.title = this.$translate(
          'events.closed_event_popup.premeeting_cancelled.title'
        )
        this.summary = this.$translate(
          'events.closed_event_popup.premeeting_cancelled.summary'
        )
        this.explanation = this.$translate(
          'events.closed_event_popup.premeeting_cancelled.explanation'
        )
        break
      case EVENT_STATUS.PENDING:
        this.title = this.$translate(
          'events.closed_event_popup.open_cancelled.title'
        )
        this.summary = this.$translate(
          'events.closed_event_popup.open_cancelled.summary'
        )
        this.explanation = this.$translate(
          'events.closed_event_popup.open_cancelled.explanation'
        )
        break
      case EVENT_STATUS.EXPIRED:
        if (this.canReactivateEvent) {
          if (this.canRequestReactivation) {
            this.title = this.$translate(
              'events.closed_event_popup.expired.title'
            )
            this.summary = this.$translate(
              'events.closed_event_popup.expired.summary'
            )
            this.explanation = this.$translate(
              'events.closed_event_popup.expired.explanation'
            )
          } else if (this.isExpiredReactivation) {
            this.title = this.$translate(
              'events.closed_event_popup.expired_reactivated.title'
            )
            this.summary = this.$translate(
              'events.closed_event_popup.expired_reactivated.summary',
              { client_name: this.evt.client.name }
            )
            this.explanation = this.$translate(
              'events.closed_event_popup.expired_reactivated.explanation'
            )
          } else {
            this.title = this.$translate(
              'events.closed_event_popup.expired_old.title'
            )

            this.summary = this.$translate(
              'events.closed_event_popup.expired_old.summary',
              {
                amount_payable: this.prevState.amount_payable,
                currency: this.currency
              }
            )

            this.explanation = this.$translate(
              'events.closed_event_popup.expired_old.explanation'
            )
          }
        } else {
          this.title = this.$translate(
            'events.closed_event_popup.expired_old.title'
          )

          this.summary = this.$translate(
            'events.closed_event_popup.expired_old.summary',
            {
              amount_payable: this.prevState.amount_payable,
              currency: this.currency
            }
          )

          this.explanation = this.$translate(
            'events.closed_event_popup.expired_old.explanation'
          )
        }
        break
      case EVENT_STATUS.CONFIRMED_PREPARING:
      case EVENT_STATUS.CANCELLED_CLIENT:
      case EVENT_STATUS.CONFIRMED:
        this.title = this.$translate(
          'events.closed_event_popup.cancelled.title'
        )
        this.summary = this.$translate(
          'events.closed_event_popup.cancelled.summary'
        )
        this.explanation = this.$translate(
          'events.closed_event_popup.cancelled.explanation'
        )
        break
      default:
        this.title = null
        this.summary = null
        this.explanation = null
    }
  },
  methods: {
    confirm () {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.alarm-icon .material-icons {
  font-size: 72px;
  color: #bfb38f;
}
.modal-content {
  padding-bottom: 0;
}
.modal-footer {
  text-align: center;
}
</style>
