import ModalManager from '@/managers/ModalManager'
import { Modal } from '@/models/Modal'

import { LITE_EVENTS } from '@/features/lite/constants'

function onFeatureLock (callback = () => {}) {
  const id = 'feature-lock-modal'
  const modalName = 'feature-lock-modal'
  const propsData = {}
  const modal = new Modal(id, modalName, callback, propsData)

  ModalManager.showModal(modal)
}

function onAdminReview (callback = () => {}) {
  const id = 'registration-review-modal'
  const modalName = 'registration-review-modal'
  const propsData = {}
  const modal = new Modal(id, modalName, callback, propsData)

  ModalManager.showModal(modal)
}

$h.EventBus.$on(LITE_EVENTS.ONFEATURELOCK, onFeatureLock)
$h.EventBus.$on(LITE_EVENTS.ONADMINREVIEW, onAdminReview)
