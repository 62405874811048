<template>
  <div class="steps" data-test="steps">
    <div
      v-for="i in total"
      :key="`step-item-${i}`"
      class="step"
      :class="[classes, {'active': i <= current}]"
      data-test="step-item"
    />
  </div>
</template>

<script>
export default {
  props: {
    current: {
      type: Number,
      default: () => 0
    },
    total: {
      type: Number,
      default: () => 0
    },
    classes: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    stepperLabel () {
      return this.title + ' ' + this.current + '/' + this.total
    }
  }
}
</script>

<style lang="scss" scoped>
.steps {
  & {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .step {
    height: 2px;
    flex: 1 1 auto;
    background-color: #CAD1D9;
  }
  .step.active {
    background-color: #2F3740;

    &.--purple {
      background-color: #7638FA;
    }
  }
}
</style>
