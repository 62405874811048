const forgotPasswordComponent = require('@/features/auth/components/ForgotPassword/ForgotPasswordView.vue').default
const forgotPasswordSuccessComponent = require('@/features/auth/components/ForgotPassword/ForgotPasswordSuccess.vue').default
const redirectIfAuthenticated = require('@/middleware/redirectIfAuthenticated').default

export const forgotPassword = {
  path: '/:lang(en)?/forgot-password',
  name: 'forgot-password',
  component: forgotPasswordComponent,
  beforeEnter: redirectIfAuthenticated
}

export const forgotPasswordSuccess = {
  path: '/:lang(en)?/forgot-password/email-sent',
  name: 'forgot-password-success',
  component: forgotPasswordSuccessComponent,
  beforeEnter: redirectIfAuthenticated
}
