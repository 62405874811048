<template>
  <div>
    <login-mobile-view
      :username.sync="email"
      :password.sync="password"
      :error="error"
      @submit="submit"
    />
    <login-desktop-view
      :username.sync="email"
      :password.sync="password"
      :error="error"
      @submit="submit"
    />
  </div>
</template>

<script>
import LoginMobileView from './login-mobile'
import LoginDesktopView from './login-desktop'
import LoginController from './mixins/login.controller'

export default {
  components: {
    LoginDesktopView,
    LoginMobileView
  },
  mixins: [LoginController]
}
</script>
