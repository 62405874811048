<template>
  <component :is="'LoginController'" v-slot="{ data, bindings, actions }">
    <component
      :is="'AuthView'"
      :data="data"
    >
      <template v-slot:title>
        <h2>{{ actions.translate('login.title1') }}</h2>
        <h2>{{ actions.translate('login.title2') }}</h2>
      </template>
      <apv-form
        :is-loading="data.isLoading"
        novalidate
        data-test="login-form"
        @submit="bindings.handleLogin"
      >
        <apv-input
          :value="data.username"
          data-test="input-email"
          type="email"
          autocomplete="username"
          tabindex="1"
          class="margin-bottom-md"
          :label="actions.translate('login.email')"
          :error="actions.getFieldError('email')"
          @input="bindings.handleUsernameChange"
        />
        <apv-input
          :value="data.password"
          data-test="input-password"
          type="password"
          autocomplete="password"
          tabindex="2"
          class="margin-bottom-lg"
          :label="actions.translate('login.password')"
          :error="actions.getFieldError('password')"
          @input="bindings.handlePasswordChange"
        />
        <apv-button
          :rounded="10"
          :is-loading="data.isLoading"
          block
          gradient
          type="submit"
          class="margin-bottom-lg"
          size="large"
          waves-effect="dark"
          :disabled="bindings.isEmpty(data.username) || bindings.isEmpty(data.password)"
          data-test="login-cta"
        >
          {{ actions.translate('messages.globals.continue') }}
        </apv-button>
      </apv-form>

      <div class="footer-auth-nav">
        <div class="forgot-pass-container">
          <router-link
            v-if="bindings.PROVIDER_MAGIC_LINKS"
            class="forgot-pass"
            :to="data.requestMagicLinkRoute"
          >{{ actions.translate('login.forgotPassword') }}</router-link>
          <router-link
            v-else
            class="forgot-pass"
            :to="data.forgotPasswordRoute"
          >{{ actions.translate('login.forgotPassword') }}</router-link>
        </div>
        <div class="signup-container">
          <span class="signup-label">{{ actions.translate('login.noAccountYet') }}</span>
          <router-link class="signup-link" :to="data.registerRoute">{{ actions.translate('login.signUp') }}</router-link>
        </div>
      </div>

    </component>
  </component>
</template>

<script>
import LoginController from './LoginController.vue'
import AuthView from './AuthView.vue'

export default {
  components: {
    LoginController,
    AuthView
  }
}
</script>

<style lang="scss" scoped>

@import './authenticationNavigation.style.scss';

.signup-label {
    color: #707070;
}
.forgot-pass, .signup-link {
    text-decoration: underline !important;
    color: #DC0064;
}
.forgot-pass-container {
    margin-top: 8px;
}
.signup-container {
    margin-top: 24px;
}
</style>
