<template>
  <div
    id="video-date-instruction"
    class="modal center-align"
    style="max-height: 80%;"
  >
    <div
      class="modal-content"
      style="padding: 12px 12px 2px;"
    >
      <p class="center">
        <video
          autoplay
          height="312"
          muted
          loop
          playsinline
        >
          <source
            src="/img/video-date/video-date-steps.webm"
            type="video/webm"
          >
          <source
            src="/img/video-date/video-date-steps.m4v"
            type="video/mp4"
          >
          Your browser does not support the video tag.
        </video>
      </p>
    </div>
    <div
      class="modal-footer"
      style="border-top: 1px solid #E1E6EB;"
    >
      <a
        href="#"
        class="btn-flat waves-effect waves-dark krypton-pink-text"
        style="width: 100%;text-align: center;"
        @click="confirm"
      >
        <strong>{{ this.$translate('events.closed_event_popup.button') }}</strong>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    confirm () {
      this.$emit('confirm')
    }
  }
}
</script>
