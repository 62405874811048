var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "language-setting" } },
    [
      _c("md-new-navbar", {
        attrs: { "route-name": _vm.getRedirectBackRouteName }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "container",
          attrs: { id: "content", "data-test": "lang-switch" }
        },
        [
          _c("h1", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$translate("language.title")))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "description" }, [
            _vm._v(_vm._s(_vm.$translate("language.choose_language_message")))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "description" }, [
            _vm._v(
              _vm._s(_vm.$translate("language.choose_language_message_alt"))
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "switch-input margin-bottom-xl" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.$translate("language.choose_language_option_1"))
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "switch switch-solid" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedLanguage,
                      expression: "selectedLanguage"
                    }
                  ],
                  attrs: {
                    id: "switchDE",
                    name: "selectedLanguage",
                    type: "radio",
                    value: "DE",
                    "data-test": "lang-switch-de",
                    checked: "{isLocale('de')}"
                  },
                  domProps: { checked: _vm._q(_vm.selectedLanguage, "DE") },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.toggleLocale("de")
                    },
                    change: function($event) {
                      _vm.selectedLanguage = "DE"
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "lever " })
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "switch-input" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.$translate("language.choose_language_option_2"))
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "switch switch-solid" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedLanguage,
                      expression: "selectedLanguage"
                    }
                  ],
                  attrs: {
                    id: "switchEN",
                    name: "selectedLanguage",
                    type: "radio",
                    value: "EN",
                    "data-test": "lang-switch-en",
                    checked: "{isLocale('en')}"
                  },
                  domProps: { checked: _vm._q(_vm.selectedLanguage, "EN") },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.toggleLocale("en")
                    },
                    change: function($event) {
                      _vm.selectedLanguage = "EN"
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "lever " })
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }