var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "feature-overview__container", style: _vm.cssVars },
    [
      _c("div", { staticClass: "feature-overview__banner" }, [
        _c(
          "div",
          { staticClass: "feature-overview__banner-container" },
          [
            _c("md-faq-navbar", {
              attrs: { "route-name": _vm.headerNavbarRedirection }
            }),
            _vm._v(" "),
            _c("h1", { staticClass: "feature-overview__banner-title" }, [
              _vm._v(_vm._s(_vm.headerTitle))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "feature-overview__banner-subtitle" }, [
              _vm._v(_vm._s(_vm.headerSubtitle))
            ]),
            _vm._v(" "),
            _c("img", {
              attrs: {
                "data-test": "faq-main-header-logo",
                src: _vm.mainHeaderLogo
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.hasTagline
        ? _c(
            "div",
            { staticClass: "feature-overview__body" },
            [
              _c("div", { staticClass: "feature-overview__tagline" }, [
                _c("p", {
                  staticClass: "feature-overview__tagline-text",
                  attrs: { "data-test": "faq-tagline-text" },
                  domProps: { innerHTML: _vm._s(_vm.headerTagline) }
                }),
                _vm._v(" "),
                _vm.taglineLogo
                  ? _c("img", {
                      staticClass: "feature-overview__tagline-logo",
                      attrs: {
                        "data-test": "faq-tagline-logo",
                        src: _vm.taglineLogo
                      }
                    })
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("card-panels", { attrs: { features: _vm.features } })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }