var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container center-align padding-x-xl padding-y-xl",
      attrs: { id: "event-declined-uncomfortable" }
    },
    [
      _c("h1", { staticClass: "title strong margin-top-none" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$translate("events.decline_popup.not_comfortable_title")
            ) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "margin-y-lg" }, [
        _c("small", [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$translate("events.decline_popup.not_comfortable_note", {
                  client_name: _vm.clientName
                })
              ) +
              "\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _vm._t("input")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }