<template>
  <div v-if="isViewReady" id="event-rate-feedback">
    <md-navbar :push="{ name: 'events' }"
               :brand="$translate('events.nav_title')"
               :menu-trigger="false"
               icon="arrow_back" />
    <div class="content container">
      <div class="rate-btns">
        <a id="event-feedback-thumbs-up" href="#" class="rate"
           :class="{ 'active': selectedRate === 1 }"
           @click.prevent="switchRate(1)">
          <i class="material-icons">thumb_up</i>
        </a>
        <a id="event-feedback-thumbs-down" href="#" class="rate"
           :class="{ 'active': selectedRate === 0 }"
           @click.prevent="switchRate(0)">
          <i class="material-icons">thumb_down</i>
        </a>
      </div><br>
      <div v-if="selectedRate === 1" class="positive-feedback content">
        <h5 class="center margin-top-none">{{ $translate('events.rate.thumbs_up_title', { client_name: selectedEvent.client.name }) }}</h5>
        
        <div class="new-note">
          <ul>
            <li>
              <p><i class="blacked tiny material-icons">warning</i> <span v-html="$translate('events.rate.thumbs_up_note_title')" /></p>
            </li>
            <li>
              <p><i class="blacked extra-tiny material-icons">lens</i> <span v-html="$translate('events.rate.thumbs_up_note_list_1')" /></p>
            </li>
            <li>
              <p><i class="blacked extra-tiny material-icons">lens</i> <span v-html="$translate('events.rate.thumbs_up_note_list_2')" /></p>
            </li>
          </ul>
        </div>
        
        <br>
        <div style="background: #000; color: #FFF; padding: 5px; font-size: 14px; width: 135px; border-radius: 4px; text-align: center;">
          <strong class="center-countdown countdown">
            {{ meetingCountdown }}
          </strong>
        </div><br>
        <md-textarea
          id="event-feedback-up-message"
          v-model.trim="message"
          type="multiline"
          :maxlength="answerMaxChar"
          :force-active="true"
          :label="$translate('events.rate.thumbs_up_textarea_placeholder')">
          <div>
            <span id="event-feedback-up-char-count" class="input-helper grey-text text-darken-1 right">
              {{ getMaxChar(message) }}
            </span>
          </div>
        </md-textarea>
        <br>
        <button id="event-feedback-up-submit"
                class="btn btn-krypton-pink btn-full"
                @click="submitFeedback">
          <span v-if="!isSending">{{ $translate('events.buttons.send') }}</span>
          <div v-else class="preloader-wrapper small active">
            <div class="spinner-layer spinner-green-only">
              <div class="circle-clipper left">
                <div class="circle" />
              </div><div class="gap-patch">
                <div class="circle" />
              </div><div class="circle-clipper right">
                <div class="circle" />
              </div>
            </div>
          </div>
        </button>
      </div>
      <div v-if="selectedRate === 0" class="negative-feedback content">
        <h5 class="center margin-top-none">
          {{ $translate('events.rate.thumbs_down_title') }}
        </h5>
        <p class="center p-note">
          {{ $translate('events.rate.thumbs_down_note') }}
        </p>
        <div style="background: #000; color: #FFF; padding: 5px; font-size: 14px; width: 135px; border-radius: 4px; text-align: center;">
          <strong class="center-countdown countdown">
            {{ meetingCountdown }}
          </strong>
        </div><br>
        <md-textarea id="event-feedback-down-message"
                     v-model="message"
                     type="multiline"
                     :maxlength="answerMaxChar"
                     :force-active="true"
                     :label="$translate('events.rate.thumbs_down_textarea_placeholder')">
          <div>
            <span id="event-feedback-down-char-count" class="input-helper grey-text text-darken-1 right">
              {{ getMaxChar(message) }}
            </span>
          </div>
        </md-textarea>
        <label class="checkbox-container">
          <input
            id="event-feedback-meet-checkbox"
            v-model="blacklist"
            type="checkbox"
            class="filled-in"
            name="review_policy"
            true-value="1"
            false-value="0"
          >
          <span>{{ $translate('events.rate.thumbs_down_checkbox', { client_name: selectedEvent.client.name }) }}</span>
        </label><br>
        <button id="event-feedback-down-submit"
                class="btn btn-krypton-pink btn-full"
                @click="submitFeedback">
          <span v-if="!isSending">{{ $translate('events.buttons.send') }}</span>
          <div v-else class="preloader-wrapper small active">
            <div class="spinner-layer spinner-green-only">
              <div class="circle-clipper left">
                <div class="circle" />
              </div><div class="gap-patch">
                <div class="circle" />
              </div><div class="circle-clipper right">
                <div class="circle" />
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import NullSelectCheck from './mixins/nullSelectCheck.js'

export default {
  mixins: [NullSelectCheck],
  data () {
    return {
      answerMaxChar: 80,
      selectedRate: -1,
      message: '',
      errorForm: false,
      hasSubmitted: false,
      isSending: false,
      blacklist: '1'
    }
  },
  computed: {
    getMaxChar () {
      return (answer) => {
        return this.getCharCount(answer) + '/' + this.answerMaxChar
      }
    },
    getCharCount (answer) {
      return (answer) => {
        if (!answer) return 0
        return answer.length
      }
    },
    meetingCountdown () {
      const expirationSeconds = this.selectedEvent._feedback_metadata.expiration_in_seconds
      const countdown = $h.prettifySecondsDisplay(expirationSeconds, 'mm[m]:ss[s]')

      return `${this.$translate('events.confirm_popup.time_left')}: ${countdown}`
    }
  },
  mounted () {
    if (this.selectedEvent === null) {
      this.$router.replace({ name: 'events' })
      return
    }

    this.selectedRate = this.$route.params.rate
  },
  methods: {
    submitFeedback () {
      if (this.isSending) return
      if (this.message.length < 30 || this.message.length > this.answerMaxChar) {
        this.$notifications.toast(this.$translate('events.rate.thumbs_down_textarea_error'), 5000)
        return
      }

      this.errorForm = false
      this.isSending = true

      const payload = {
        id: this.selectedEvent.id,
        rating: this.selectedRate,
        message: this.message,
        blacklist: 0
      }

      // Include blacklist checkbox on thumbs down
      if (this.selectedRate === 0) {
        payload.blacklist = this.blacklist
      }

      this.$store
        .dispatch('events/sendRateFeedback', payload)
        .then(() => {
          this.$notifications.toast(this.$translate('events.rate.success_feedback'), 5000)
          this.hasSubmitted = true
          this.$router.replace({ name: 'events' })
        })
        .finally(() => {
          this.isSending = false
        })
    },
    switchRate (val) {
      this.selectedRate = val
      this.message = ''
      this.errorForm = false
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.selectedEvent && !this.hasSubmitted) {
      if (this.selectedRate === 1) {
        $h.rateLeaveConfirm(() => {
          next()
        })
      } else {
        this.$notifications.toast(this.$translate('events.rate.thumbs_down_note'), 5000)
      }
    } else {
      this.$store.commit('events/SET_SELECTED', null)
      next()
    }
  }
}
</script>

<style lang="scss" scoped>

#event-rate-feedback {

  .new-note {
    background: #E1E6EB 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding: 16px 18px 12px 15px;
    margin-top: 26px;
    opacity: 0.54;
    width: 100%;

    ul {
      margin: 0;
      padding-left: 0 !important;

      li {
        color: #2F3740;
        font-size: 14px;
        line-height: 17px;
        list-style: none;
        margin-top: 2px;

        .blacked {
          color: #2F3740;
        }

        p {
          margin: 0;
        }

        .material-icons { 
          vertical-align: text-bottom;
        }

        .extra-tiny {
          font-size: 8px;
          padding: 0 4px;
          text-align: center;
          vertical-align: inherit;
        }
      }
    }
  }

}
  
</style>