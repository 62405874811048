import Pusher from 'pusher-js'
import { mapGetters } from 'vuex'

export const PUSHER_EVENT = {
  ON_CANCEL: 'oncancel',
  ON_CANCELLATION_REQUEST: 'oncancellationrequest',
  ON_CHATBOT_REQUEST: 'onchatbotrequest',
  ON_EXPIRED: 'onexpired',
  ON_LATE: 'onlate',
  ON_MESSAGE: 'onmessage',
  ON_REACTIVATE_CONFIRM: 'onreactivateconfirm',
  ON_REACTIVATE_DECLINE: 'onreactivatedecline',
  ON_SEEN: 'onseen',
  ON_SUPPORT_REPLY: 'onsupportreply',
  ON_VERIFICATION_REQUEST: 'onverificationrequest',
  ON_CONFIRMED: 'onconfirmed',

  CLIENT_FEEDBACK: 'clientfeedback',

  EVENT_CANCEL_TIMEOUT: 'event-cancel-timeout',
  EVENT_OVERRIDE: 'event-override',
  EVENT: 'event',

  TOGGLE_REQUEST: 'toggle-request',

  BILLING_REQUIRED: 'billing-required',

  ID_VERIFICATION: 'id-verification',
  ID_REJECTED_FRONT: 'id-rejected-front',
  ID_REJECTED_BACK: 'id-rejected-back',
  ID_VERIFIED: 'id-verified',
  LEGAL_AGREEMENT: 'legal-agreement',

  ON_KRYPTON_CONNECT_INBOX: 'krypton-connect-inbox',
  ON_KRYPTON_CONNECT_MESSAGE_SEEN: 'seen-message',
  ON_KRYPTON_CONNECT_REPLY: 'reply-message'
}

export default {
  mounted () {
    this.initializePusher()
  },
  data: () => ({
    pusher: null,
    pusherChannel: null
  }),
  computed: {
    ...mapGetters({
      events: 'events/getSortedEvents',
      user: 'getUser'
    })
  },
  methods: {

    /**
     * Starts the timer worker and execute the callback function passed.
     */
    initializePusher () {
      Pusher.logToConsole = process.env.NODE_ENV !== 'production'

      this.pusher = new Pusher(process.env.PUSHER_KEY, {
        cluster: process.env.PUSHER_CLUSTER,
        forceTLS: true
      })

      this.pusherChannel = this.pusher.subscribe(`krypton-${this.user.id}`)

      this._initializeIDVerificationListeners()
      this._initializePhotoManagerListeners()
      this._initializeEventsListeners()
      this._initializeKryptonConnectListeners()
    },

    /**
     * Initialize event listener callback for events.
     */
    _initializeEventsListeners () {
      if (this.user.events_enabled) {
        this.pusherChannel.bind(PUSHER_EVENT.EVENT, this._onEventRequest)
        this.pusherChannel.bind(PUSHER_EVENT.EVENT_OVERRIDE, this._onEventOverride)
        this.pusherChannel.bind(PUSHER_EVENT.ON_CANCEL, this._onEventCancelled)
        this.pusherChannel.bind(PUSHER_EVENT.ON_CHATBOT_REQUEST, this._onChatbotRequest)
        this.pusherChannel.bind(PUSHER_EVENT.ON_REACTIVATE_DECLINE, this._onReactivateDecline)
        this.pusherChannel.bind(PUSHER_EVENT.ON_REACTIVATE_CONFIRM, this._onReactivateConfirm)
        this.pusherChannel.bind(PUSHER_EVENT.ON_EXPIRED, this._onEventExpired)
        this.pusherChannel.bind(PUSHER_EVENT.ON_VERIFICATION_REQUEST, this._onVerificationRequest)
        this.pusherChannel.bind(PUSHER_EVENT.ON_SUPPORT_REPLY, this._onSupportReplyReceived)
        this.pusherChannel.bind(PUSHER_EVENT.ON_CANCELLATION_REQUEST, this._onCancellationRequest)
        this.pusherChannel.bind(PUSHER_EVENT.ON_CONFIRMED, this._onEventConfirmedByProvider)
        this.pusherChannel.bind(PUSHER_EVENT.EVENT_CANCEL_TIMEOUT, this._onCancellationRequestTimeout)

        // Client late notification
        this.pusherChannel.bind(PUSHER_EVENT.ON_LATE, this._onLateNotification)

        // Where is He listen events
        this.pusherChannel.bind(PUSHER_EVENT.ON_MESSAGE, this._onMessageReceived)
        this.pusherChannel.bind(PUSHER_EVENT.ON_SEEN, this._onMessageSeen)

        // Event Feedback events
        this.pusherChannel.bind(PUSHER_EVENT.CLIENT_FEEDBACK, this._onClientFeedback)

        // Request toggle event
        this.pusherChannel.bind(PUSHER_EVENT.TOGGLE_REQUEST, this._onToggleRequest)
      }
    },

    /**
     * Initialize event listener callback for events.
     */
    _initializeIDVerificationListeners () {
      // Billing
      this.pusherChannel.bind(PUSHER_EVENT.BILLING_REQUIRED, this._onBillingRequired)

      // ID Verification
      this.pusherChannel.bind(PUSHER_EVENT.ID_VERIFICATION, this._onIDVerification)
      this.pusherChannel.bind(PUSHER_EVENT.ID_REJECTED_FRONT, this._onFrontIDReject)
      this.pusherChannel.bind(PUSHER_EVENT.ID_REJECTED_BACK, this._onBackIDReject)
      this.pusherChannel.bind(PUSHER_EVENT.LEGAL_AGREEMENT, this._onLegalAgreement)
      this.pusherChannel.bind(PUSHER_EVENT.ID_VERIFIED, this._onIDVerified)
    },

    /**
     * Initialize event listener callback for events.
     */
    _initializePhotoManagerListeners () {
      this.pusherChannel.bind('reject-photo', rejectedPhoto => {
        this.$store.commit('addRejectedPhoto', rejectedPhoto)
        this.$store.commit('setLivePhotos', [])
        this.$store.dispatch('fetchRejectedPhotos', false)
        this.$store.dispatch('fetchLivePhotos')
        this.$store.dispatch('getUserDetails')
      })

      this.pusherChannel.bind('photo-approve', () => {
        this.$store.commit('setLivePhotos', [])
        this.$store.dispatch('fetchLivePhotos')
        this.$store.dispatch('getUserDetails')
      })
    },

    _initializeKryptonConnectListeners () {
      this.pusherChannel.bind(PUSHER_EVENT.ON_KRYPTON_CONNECT_INBOX, this._onKryptonConnectInbox)
      this.pusherChannel.bind(PUSHER_EVENT.ON_KRYPTON_CONNECT_MESSAGE_SEEN, this._onKryptonConnectSeenMessage)
      this.pusherChannel.bind(PUSHER_EVENT.ON_KRYPTON_CONNECT_REPLY, this._onKryptonConnectReply)
    },

    /**
     * Callback method when `event` is received from Pusher.
     *
     * @param {Object} evt - The event object.
     */
    _onEventRequest (evt) {
      if (!this.isRequestEnabled) return

      this._refreshEventAvailabilities()

      this.$store.commit('events/ADD_OR_UPDATE', evt)
    },

    /**
     * Callback method when `event-override` is received from Pusher.
     *
     * @param {Object} evt - The event object.
     */
    _onEventOverride (evt) {
      this._refreshEventAvailabilities()

      this.$store.commit('events/ADD_OR_UPDATE', evt)
    },

    /**
     * Callback method when `onmessage` is received from Pusher.
     *
     * @param {Object} message - The message payload
     */
    _onMessageReceived (message) {
      this.$store.commit('events/PUSH_MESSAGE', message)

      if (this.$route.name !== 'event-whereis') {
        this.$store.commit(
          'events/INCREMENT_MESSAGE_UNREAD',
          message.request_id
        )
      }
    },

    /**
     * Callback method when `onmessage` is received from Pusher.
     *
     * @param {Object} evt - The event object
     */
    _onMessageSeen (evt) {
      // add slight delay since UI data could not react immediately
      setTimeout(() => {
        this.$store.commit('events/SEEN_LAST_MESSAGE')
      }, 500)
    },

    /**
     * Callback method when `onmessage` is received from Pusher.
     *
     * @param {Object} evt - The event object
     */
    _onLateNotification (evt) {
      this.$store.commit('events/SET_CLIENT_DELAY', evt)
    },

    /**
     * Callback method when `oncancel` is received from Pusher.
     *
     * @param {Object} evt - The event object.
     */
    _onEventCancelled (evt) {
      this.$store.commit('events/EVENT_CANCELLED', evt)

      this._refreshEventAvailabilities()
    },

    /**
     * Callback method when `onchatbotrequest` is received from Pusher.
     *
     * @param {Object} evt - The event object.
     */
    _onChatbotRequest (evt) {
      const payload = {
        id: evt.id,
        vm: this
      }

      this.$store.commit('events/EVENT_FORCE_CANCELLED', payload)
    },

    /**
     * Callback method when `onreactivatedecline` is received from Pusher.
     *
     * @param {Object} evt - The event object.
     */
    _onReactivateDecline (evt) {
      this.$store.commit('events/DECLINED_REACTIVATED_EVENT', evt.id)
    },

    /**
     * Callback method when `onreactivateconfirm` is received from Pusher.
     *
     * @param {Object} evt - The event object.
     */
    _onReactivateConfirm (evt) {
      // Add timeout before syncing the events data, since there will be slight delay in the API
      setTimeout(() => {
        this.$store.dispatch('events/fetchEvents')
      }, 500)
    },

    /**
     * Callback method when `event-override` is received from Pusher.
     *
     * @param {Object} evt - The event object.
     */
    _onEventExpired (evt) {
      this.$store.commit('events/SET_EVENT_EXPIRED', evt.id)

      this._refreshEventAvailabilities()
    },

    /**
     * Callback method when `onverificationrequest` is received from Pusher.
     */
    _onVerificationRequest () {
      this.$store.dispatch('getUserDetails')
      this.$store.dispatch('billing/fetchBilling')
    },

    /**
     * Callback method when `onverificationrequest` is received from Pusher.
     */
    _onSupportReplyReceived (evt) {
      $h.EventBus.$emit('onsupportreply', evt)

      if (this.$route.name !== 'support-v2-request') {
        this.$store.commit('events/INCREMENT_SELECTED_EVENT_SUPPORT_UNREAD')
      }
    },

    /**
     * Callback method when `oncancellationrequest` is received from Pusher.
     */
    _onCancellationRequest (evt) {
      this.$store.commit('events/ADD_OR_UPDATE', evt)
    },

    /**
     * Callback method when `oncancellationrequesttimeout` is received from Pusher.
     */
    _onCancellationRequestTimeout (evt) {
      this.$store.commit('events/FORCE_REQUEST_CANCELLATION_EXPIRED', evt.id)
    },

    /**
     * Callback method when `clientfeedback` is received from Pusher.
     *
     * @param {Object} feedback - The feedback object.
     */
    _onClientFeedback () {
      this.$store.dispatch('feedbacks/fetch')
      if (this.$route.path.includes('reviews')) {
        this.$store.dispatch('notification/SEEN_FEEDBACK_NOTIFICATION')
      } else {
        this.$store.commit('notification/SET_HAS_NEW_FEEDBACK', 1)
      }
    },

    /**
     * Re-sync availabilities and events data.
     */
    _refreshEventAvailabilities (refreshAvailabilities = true) {
      // Refetch availabilities
      if (this.user.availability_enabled && refreshAvailabilities) {
        this.$store.dispatch(
          'availabilities/refreshAvailabilities',
          false
        )
      }

      // Refetch events
      if (this.user.events_enabled) {
        this.$store.dispatch('events/syncEvents')
      }
    },

    /**
     * On ID Verification callback
     */
    _onIDVerified () {
      this.$store.commit('billing/updateIDStatus', {
        side: 'front',
        value: BILLING_ID_STATUS.APPROVED
      })

      this.$store.commit('billing/updateIDStatus', {
        side: 'back',
        value: BILLING_ID_STATUS.APPROVED
      })

      $h.verifyIDSuccessModal(async () => {
        try {
          await this.$store
            .dispatch('billing/popupSeen', { approved_id_seen: true })
        } catch (err) {
          console.log('err', err)
        }
      })
    },

    /**
     * On billing required callback.
     */
    _onBillingRequired (data) {
      this.$store.dispatch('billing/fetchBilling')

      if (this.$route.name.indexOf('billing') === -1) {
        $h.billingRequiredModal(() => {
          this.$router.push({ name: 'billing-form' })
        })
      }
    },

    /**
     * On Front ID Rejection callback.
     */
    _onFrontIDReject (data) {
      this.$store.commit('billing/updateIDRejectReason', {
        side: 'front',
        reason: data.file_id_front_error
      })
    },

    /**
     * On Back ID rejection callback.
     */
    _onBackIDReject (data) {
      this.$store.commit('billing/updateIDRejectReason', {
        side: 'back',
        reason: data.file_id_back_error
      })
    },

    /**
     * On ID verification callback.
     */
    _onIDVerification () {
      this.$store.commit('setIDVerificationEnabled')
      this.$store
        .dispatch('billing/fetchBilling')
        .then(this.shouldShowBilling)
    },

    /**
     * On Legal agreement event callback.
     */
    _onLegalAgreement () {
      this.$router.push({ name: 'legal-terms-conditions' })
    },

    /**
     * On toggle request event callback.
     */
    _onToggleRequest (res) {
      if (res.request_enable == 1) {
        this.fetchEvents()
      }

      this.$store.commit('setRequestEnable', res.request_enable)
    },

    /**
     * On Event confirmed by provider callback.
     * If the show_contact_warning_popup is present from the response
     * we update the store - user.show_contact_warning_popup
     *
     * @param {res} res
     *
     */
    _onEventConfirmedByProvider (res) {
      const POSSIBLE_VALUES = {
        TRUE: 1,
        FALSE: 0
      }
      if (res.show_contact_warning_popup === POSSIBLE_VALUES.TRUE || res.show_contact_warning_popup === POSSIBLE_VALUES.FALSE) {
        this.$store.commit('setUserContactWarningPopupStatus', res.show_contact_warning_popup)
      }
    },

    /**
     * On Krypton Connect Inbox event callback.
    */
    _onKryptonConnectInbox (res) {
      const { INBOX_EVENTS } = require('@/features/inbox/constants')
      this.$store.commit('notification/INCREMENT_INBOX_COUNT')
      $h.EventBus.$emit(INBOX_EVENTS.ON_KRYPTON_INBOX, res)
    },

    /**
     * On Krypton Connect Inbox event callback.
    */
    _onKryptonConnectSeenMessage (res) {
      const { INBOX_EVENTS } = require('@/features/inbox/constants')
      $h.EventBus.$emit(INBOX_EVENTS.ON_KRYPTON_CONNECT_MESSAGE_SEEN, res)
    },

    /**
     * On Krypton Connect Inbox event callback.
    */
    _onKryptonConnectReply (res) {
      const { INBOX_EVENTS } = require('@/features/inbox/constants')
      this.$store.commit('notification/INCREMENT_INBOX_COUNT')
      $h.EventBus.$emit(INBOX_EVENTS.ON_KRYPTON_CONNECT_REPLY, res)
    }

  },

  beforeRouteLeave (to, from, next) {
    localforage.getItem('authToken', (err, token) => {
      if (err !== null || token === null) {
        if (this.pusher) {
          this.pusher.disconnect()
        }
      }
    })

    next()
  }
}
