<template>
  <div id="verify-id-success-modal" class="modal center-align id-verify-success">
    <div class="modal-content">
      <h4 class="modal-title">
        {{ $translate('verify.verification_success.title') }}
      </h4>
      <img class="margin-y-lg" src="/img/verify/id-verify-success.svg">
      <p>
        {{ $translate('verify.verification_success.explanation_text') }}
      </p>
    </div>

    <div class="new-modal-footer">
      <a href="#" @click="confirm">
        <strong>{{ this.$translate('events.closed_event_popup.button') }}</strong>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    confirm ($e) {
      this.$emit('confirm', $e)
    }
  }
}
</script>

<style lang="scss" scoped>
.id-verify-success {
    background: #FFFFFF;
    border-radius: 12px;
    top: 30%;

    @media screen and (max-width: 600px) {
        margin: 0 16px;
        width: 94%;
    }
    @media screen and (max-width: 420px) {
        width: 92%;
    }

    .modal-content {
        padding: 32px 24px 28px 24px;

        h4 {
            color: #2F3740;
            font-size: 20px;
            line-height: 32px;
            margin: 0;
        }

        p {
            color: #2F3740;
            font-size: 18px;
            line-height: 28px;
            margin: 0;
        }
    }

    .new-modal-footer {
        background: #FFFFFF;
        border-top: 1px solid #CAD1D9;
        height: 95px;

        a {
            color: #E30074;
            display: inline-block;
            font-size: 16px;
            line-height: 14px;
            padding: 32px 0 45px 0;
            text-decoration: underline;
        }
    }
}
</style>
