<template>
  <div class="multi-location-fee-input">
    <img
      :class="['fee-adjust-cta', {'disabled': isMinValue}]"
      :src="getDecrementIcon"
      :data-decrement="city"
      @click="decrement"
    >
    <div
      class="value-display"
      :data-travel-cost="city"
      v-text="`${currency}${value}`"
    />
    <img
      :class="['fee-adjust-cta', {'disabled': isMaxValue}]"
      :src="getIncrementIcon"
      :data-increment="city"
      @click="increment"
    >
  </div>
</template>

<script>
import decrementIcon from '@/features/multi-location/icons/fee-icon-subtract.svg'
import decrementIconDisabled from '@/features/multi-location/icons/fee-icon-subtract-disabled.svg'
import incrementIcon from '@/features/multi-location/icons/fee-icon-add.svg'
import incrementIconDisabled from '@/features/multi-location/icons/fee-icon-add-disabled.svg'

const STEP_VALUE = 10

export default {
  props: {
    city: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    minValue: {
      type: Number,
      required: true
    },
    maxValue: {
      type: Number,
      required: true
    }
  },
  computed: {
    getDecrementIcon () {
      return this.isMinValue
        ? decrementIconDisabled
        : decrementIcon
    },
    getIncrementIcon () {
      return this.isMaxValue
        ? incrementIconDisabled
        : incrementIcon
    },
    isMinValue () {
      return this.minValue === this.value
    },
    isMaxValue () {
      return this.maxValue === this.value
    },
    user () {
      return this.$store.getters.getUser
    },
    currency () {
      return this.user?._currency ?? '€'
    }
  },
  methods: {
    decrement () {
      let result = this.value - STEP_VALUE

      if (result < this.minValue) {
        result = this.minValue
      }

      this.$emit('input', result)
    },
    increment () {
      let result = this.value + STEP_VALUE

      if (result > this.maxValue) {
        result = this.maxValue
      }

      this.$emit('input', result)
    }
  }
}
</script>

<style lang="scss" scoped>
.multi-location-fee-input {
  display: flex;
  align-items: center;

  a {
    padding: 0;
    width: 44px;
    height: 44px;
    line-height: 44px;
    border-radius: 8px;
    box-shadow: 0px 2px 2px #0000003D;
    font-size: 24px;
  }
  .value-display {
    min-width: 46px;
    color: #2F3740;
    font-size: 18px;
    height: 21px;
    font-family: 'Proxima Nova Medium';
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .fee-adjust-cta {
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }
  }
}
</style>
