import Store from '../stores/index.js'
import { buildParam } from '../helpers/api'

const ApiProvider = $h.getProvider('ApiProvider')
const RegisterSteps = $h.getConstant('registration/steps')

class RegisterProvider {
  constructor () {
    this.api = {
      age: buildParam('/v2/register/age'),
      basic: buildParam('/v2/register/basic/:type'),
      photos: buildParam('/v2/register/photos/publish'),
      name: buildParam('/v2/register/generate-name'),
      // verify: buildParam('/v2/register/verify-phone'),
      // verify_request: buildParam('/v2/register/verification-request'),
      getFees: buildParam('/v2/register/fee'),
      setFees: buildParam('/v2/register/fee'),
      publish: buildParam('/v2/register/publish')
    }
  }

  register (params) {
    return new Promise((resolve, reject) => {
      window.api.post({
        url: ApiProvider.register(),
        payload: {
          email: params.email,
          password: params.password
        }
      },
      (success) => {
        resolve(success.data)
      },
      (err) => {
        reject(err.response.data)
      })
    })
  }

  setAge (params) {
    return new Promise((resolve, reject) => {
      window.api.put({ url: this.api.age, payload: params },
        (success) => {
          resolve(success.data)
        },
        (err) => {
          reject(err.response.data)
        })
    })
  }

  updateBasic (type, params) {
    return new Promise((resolve, reject) => {
      window.api.put({ url: this.api.basic.replace(':type', type), payload: params },
        (success) => {
          resolve(success.data)
        },
        (err) => {
          if (err.response) {
            reject(err.response.data)
          } else {
            reject(err)
          }
        })
    })
  }

  publishPhotos () {
    return new Promise((resolve, reject) => {
      window.api.post({ url: this.api.photos },
        (success) => {
          resolve(success.data)
        },
        (err) => {
          reject(err.response.data)
        })
    })
  }

  requestSMSCode (phone_number, country_code, country) {
    const payload = {
      phone_number,
      country_code,
      country
    }

    var api = buildParam('/v2/register/verification-request')

    return new Promise((resolve, reject) => {
      window.api.post({ url: api, payload: payload },
        (success) => {
          resolve(success.data)
        },
        (err) => {
          reject(err.response)
        })
    })
  }

  verifySMSCode (phone_number, country_code, verification_code, country) {
    const payload = {
      phone_number,
      country_code,
      verification_code,
      country
    }

    var api = buildParam('/v2/register/verify-phone')

    return new Promise((resolve, reject) => {
      window.api.put({ url: api, payload: payload },
        (success) => {
          resolve(success.data)
        },
        (err) => {
          reject(err.response)
        })
    })
  }

  getFees () {
    return new Promise((resolve, reject) => {
      window.api.get({ url: this.api.getFees },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response.data) })
    })
  }

  setFees (fee, acceptHotelRequest = null) {
    return new Promise((resolve, reject) => {
      window.api.put({ url: this.api.setFees, payload: { fee, check_1: acceptHotelRequest } },
        (success) => {
          resolve(success.data)
        },
        (err) => {
          reject(err.response.data)
        })
    })
  }

  publish (payload = {}) {
    const publishPromise = new Promise((resolve, reject) => {
      window.api.put({ url: this.api.publish, payload },
        (success) => {
          resolve(success.data)
        },
        (err) => {
          reject(err.response.data)
        })
    })

    return publishPromise
  }

  /**
     * Redirect user if already authenticated
     *
     * To be used as route guard, this method will redirect user if they are already authenticated.
     * Otherwise, it will resume to load the state normally. Ideal as login route guard.
     */
  redirectIfAuthenticated (to, from, next) {
    var onTokenChecked = () => {
      if (window.api.authorization == null) {
        next()
      } else {
        next(RegisterSteps[Store.state.user.register_progress])
      }
    }

    if (window.api.headers.hasOwnProperty('Authorization')) {
      onTokenChecked()
    }

    document.addEventListener('onTokenChecked', onTokenChecked)
  }

  /**
     * Redirect user if not authenticated
     *
     * To be used as route guard, this method will redirect user if they are not authenticated.
     * Otherwise, it will resume to load state normally. Ideal as route guard for protected states such as dashboard.
     */
  redirectNotAuthenticated (to, from, next) {
    var onTokenChecked = () => {
      if (window.api.authorization == null) {
        next({ name: 'register-start' })
      } else if (window.api.authorization != null) {
        Lang.setLocale(Store.state.user.app_lang)
        next()
      }
    }

    if (window.api.headers.hasOwnProperty('Authorization')) {
      onTokenChecked()
    }

    document.addEventListener('onTokenChecked', onTokenChecked)
  }

  submitField (field, payload) {
    var url = buildParam('/v2/register/' + field)

    return new Promise((resolve, reject) => {
      window.api.put({ url: url, payload: payload },
        (success) => {
          resolve(success.data)
        },
        (err) => {
          reject(err.response.data)
        })
    })
  }

  generateName () {
    return new Promise((resolve, reject) => {
      window.api.get({ url: this.api.name },
        (success) => {
          resolve(success.data)
        },
        (err) => {
          reject(err.response.data)
        })
    })
  }
}

export default new RegisterProvider()
