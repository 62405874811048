<template>
  <div id="account-setting">
    <bottomsheet :is-shown="isShown" @close="reset">
      <template v-slot:body>
        <h2 class="bottom-sheet__title">{{ $translate('setting.email_setting_title') }}</h2>
        <vform ref="form" style="margin-bottom: 24px;" @submit="submitForm">
          <md-input
            v-model="email"
            type="email"
            :label="$translate('setting.new_email_label')"
            required
            class="setting-input"
            :class="{'with-error': errors.has('email')}"
          >
            <div class="text-error">
              {{ errors.first('email') }}
            </div>
          </md-input>
        </vform>
      </template>

      <template v-slot:footer>
        <button
          class="bottom-sheet__cta bottom-sheet__cta--confirm full-width"
          :disabled="email === ''"
          data-test="footer-cta-confirm"
          @click="submit">
          {{ $translate('app.save') }}
        </button>
      </template>
    </bottomsheet>
  </div>
</template>

<script>
import bottomsheet from '@components/v2/utilities/bottomsheet.vue'
const SettingsProvider = window?.$h?.getProvider('SettingsProvider')

export default {
  name: 'EmailSetting',
  components: {
    bottomsheet
  },
  props: {
    isShown: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      email: '',
      loading: false
    }
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    }
  },
  methods: {
    confirmMethod (next) {
      return this.submitForm()
    },
    submitForm (next = null) {
      this.errors.clear()
      this.loading = true

      SettingsProvider
        .requestEmailChange(this.email)
        .then((res) => {
          this.$emit('success-request', this.email)
          this.reset()
        })
        .catch((err) => {
          if (err.data.data) {
            for (const [key, value] of Object.entries(err.data.data)) {
              if (value.length > 0) {
                this.errors.add({
                  field: key,
                  msg: value[0]
                })
              }
            }
          }
        })
        .finally(() => {
          this.loading = false
          next()
        })
    },
    submit () {
      this.$refs.form.submit()
    },
    reset () {
      this.email = ''
      this.errors.clear()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.setting-input {
  background: #FFFFFF;
}
</style>
