<template>
  <span v-text="currency" />
</template>

<script>
export default {
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    currency () {
      return this.user?._currency ?? '€'
    }
  }
}
</script>
