const redirectIfAuthenticated = require('@/middleware/redirectIfAuthenticated').default
const redirectNotAuthenticated = require('@/middleware/redirectNotAuthenticated').default
const registerStartComponent = require('@/features/auth/components/RegisterView.vue').default

const RegisterComponent = require('@/features/pwa-animation/tpl/PwaCheckpoint.vue').default

export const start = {
  path: '/:lang(en)?/registration/get-started',
  name: 'register-start',
  component: registerStartComponent,
  beforeEnter: redirectIfAuthenticated
}

export const register = {
  path: '/registration',
  component: RegisterComponent,
  beforeEnter: redirectNotAuthenticated,
  children: [
    {
      path: 'agreement',
      name: 'register-agreement',
      component: Vue.component('register-agreement')
    },
    {
      path: 'overview',
      name: 'register-overview',
      component: Vue.component('register-overview'),
      params: {
        step: null
      }
    },
    {
      path: 'basic/:type',
      name: 'register-basic',
      component: Vue.component('register-basic')
    },
    {
      path: 'personality/:type',
      name: 'register-personality',
      component: Vue.component('register-personality')
    },
    {
      path: 'photos/preview',
      name: 'register-photos-preview',
      component: Vue.component('register-photos-preview')
    },
    {
      path: 'photos/crop',
      name: 'register-photos-crop',
      component: Vue.component('register-photos-crop'),
      params: { photo: null, return: null, cancel: null }
    },
    {
      path: 'photos/anonimize',
      name: 'register-photos-anonimize',
      component: Vue.component('register-photos-anonimize'),
      params: { photo: null, return: null, cancel: null }
    },
    {
      path: 'photos/:type',
      name: 'register-photos',
      component: Vue.component('register-photos'),
      params: {
        showTipOnMount: false
      }
    },
    {
      path: 'photos/tips',
      name: 'register-photo-tips',
      component: Vue.component('register-photo-tips'),
      params: {
        title: null,
        inSession: false,
        isOtherPhotos: false,
        return: null
      }
    },
    {
      path: 'potential',
      name: 'register-potential',
      component: Vue.component('register-potential')
    },
    {
      path: 'verify',
      name: 'register-verify',
      component: Vue.component('register-verify')
    },
    {
      path: 'rates',
      name: 'register-rates',
      component: Vue.component('register-rates')
    },
    {
      path: 'survey',
      name: 'register-survey',
      component: Vue.component('register-survey')
    },
    {
      path: 'complete',
      name: 'register-complete',
      component: Vue.component('register-complete')
    }
  ]
}
