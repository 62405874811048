import { mapGetters } from 'vuex'

export const PAGE_STEPS = {
  NOT_AVAILABLE_1: 'not-available-1',
  NOT_AVAILABLE_2: 'not-available-2',
  NOT_COMFORTABLE: 'not-comfortable',
  FINISH: 'finish'
}

export const ANSWER_OPTIONS = {
  NOT_AVAILABLE: 1,
  NOT_COMFORTABLE: 2
}

const MIN_MESSAGE_LENGTH = 30
const MAX_NOT_AVAILABLE_MESSAGE_LENGTH = 160
const MAX_DEFAULT_MESSAGE_LENGTH = 500

export default {
  data: () => ({
    PAGE_STEPS,

    agree: false,
    notAvailableMaxChar: MAX_NOT_AVAILABLE_MESSAGE_LENGTH,
    answerMaxChar: MAX_DEFAULT_MESSAGE_LENGTH,
    message: '',
    answer: ANSWER_OPTIONS.NOT_AVAILABLE,
    isDeclined: false,
    step: PAGE_STEPS.NOT_AVAILABLE_1,

    error_form: {
      decline: false,
      not_available: false,
      not_comfortable: false
    },

    isSending: false
  }),
  methods: {
    nextStep () {
      switch (this.step) {
        case PAGE_STEPS.NOT_AVAILABLE_1:
          if (this.message.length < MIN_MESSAGE_LENGTH ||
            this.message.length > MAX_NOT_AVAILABLE_MESSAGE_LENGTH) {
            this.error_form.not_available = true
          } else {
            this.step = PAGE_STEPS.NOT_AVAILABLE_2
          }
          break
        case PAGE_STEPS.NOT_AVAILABLE_2:
          this.decline()
          break
        case PAGE_STEPS.NOT_COMFORTABLE:
          if (this.message.length < MIN_MESSAGE_LENGTH ||
            this.message.length > MAX_DEFAULT_MESSAGE_LENGTH) {
            this.error_form.not_comfortable = true
          } else {
            this.decline()
          }
          break
      }
    },
    prevStep () {
      switch (this.step) {
        case PAGE_STEPS.NOT_COMFORTABLE:
          this.message = ''
          this.error_form.not_comfortable = false
          this.step = PAGE_STEPS.NOT_AVAILABLE_1
          break
        case PAGE_STEPS.NOT_AVAILABLE_2:
          this.error_form.not_available = false
          this.answer = ANSWER_OPTIONS.NOT_AVAILABLE
          this.step = PAGE_STEPS.NOT_AVAILABLE_1
          break
        default:
          this.$router.go(-1)
      }
    },
    decline () {
      if (this.isSending) return

      this.error_form.not_available = false
      this.error_form.not_comfortable = false

      if (this.message.length < MIN_MESSAGE_LENGTH) {
        this.error_form.not_available = true
        this.error_form.not_comfortable = true
        return
      }
      if (this.isNotComforableScreen && !this.agree) {
        this.$notifications.toast(
          this.$translate('events.decline_popup.not_comfortable_check_error'),
          5000
        )
        return
      }

      const pl = {
        id: this.selectedEvent.id,
        payload: {
          message: this.message,
          decline_status: this.answer
        }
      }

      this.isSending = true
      this.$store
        .dispatch('events/decline', pl)
        .then((evt) => {
          this.step = PAGE_STEPS.FINISH

          this.$notifications.toast(
            this.$translate('events.decline_popup.success_toast'),
            5000
          )

          this.$store.commit('events/SET_SELECTED', null)
          this.$store.commit('events/ADD_OR_UPDATE', evt)
          // Refetch availabilities

          if (this.user.availability_enabled) {
            this.$store.dispatch('availabilities/refreshAvailabilities')
          }
        })
        .finally(() => {
          this.isSending = false
        })
    },
    showTooltip () {
      this.$notifications.toast(
        this.$translate('events.decline_popup.declined_hint'),
        5000
      )
    },
    goBack () {
      if (!this.isSending) {
        this.$router.back()
      }
    },
    notComfortable () {
      this.message = ''
      this.answer = ANSWER_OPTIONS.NOT_COMFORTABLE
      this.step = PAGE_STEPS.NOT_COMFORTABLE
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    }),
    getCharCount () {
      return (answer) => {
        if (!answer) return 0
        return answer.length
      }
    },
    getMaxChar () {
      return (answer) => {
        return this.getCharCount(answer) + '/' + this.answerMaxChar
      }
    },
    getNotAvailableMaxChar () {
      return (answer) => {
        return this.getCharCount(answer) + '/' + this.notAvailableMaxChar
      }
    },
    eventDate () {
      return this.selectedEvent
        ._meeting_start_at
        .format('MMMM DD')
    },
    nextStepText () {
      switch (this.step) {
        case PAGE_STEPS.NOT_AVAILABLE_1:
          return this.$translate('events.buttons.continue')
        case PAGE_STEPS.NOT_AVAILABLE_2:
        case PAGE_STEPS.NOT_COMFORTABLE:
          return this.$translate('events.buttons.decline_v2')
      }
    },
    isNotAvailableScreen () {
      return this.step === PAGE_STEPS.NOT_AVAILABLE_1 ||
             this.step === PAGE_STEPS.NOT_AVAILABLE_2
    },
    isNotComforableScreen () {
      return this.step === PAGE_STEPS.NOT_COMFORTABLE
    },
    isFinishScreen () {
      return this.step === PAGE_STEPS.FINISH
    }
  }
}
