var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "navbar-fixed" }, [
        _c("nav", { staticClass: "z-depth-0" }, [
          _c("div", { staticClass: "nav-wrapper container" }, [
            _c(
              "a",
              {
                staticClass: "brand-logo align-center",
                class: { "waves-effect waves-dark": _vm.hasWaves },
                attrs: { id: "navbar-back-button", href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.redirect($event)
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "responsive-img",
                  attrs: { src: "/img/back-arrow.svg", alt: "back-btn" }
                }),
                _vm._v(" "),
                _c("strong", [_vm._v(_vm._s(_vm.brand))])
              ]
            ),
            _vm._v(" "),
            _vm.showMenuTrigger
              ? _c("ul", { staticClass: "right" }, [
                  _vm.shouldShowKrybo
                    ? _c(
                        "li",
                        {
                          staticClass: "padding-x-sm",
                          staticStyle: { position: "relative" }
                        },
                        [
                          _c("div", {
                            staticClass: "waves-effect waves-dark",
                            attrs: { id: "robot-icon" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.onKryboClick($event)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.showPulse
                            ? _c("span", { staticClass: "pulse custom-pulse" })
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c("md-sidenav")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }