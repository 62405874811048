<template>
  <div id="prime-time-availability" class="super-provider-card super-provider-card__req margin-bottom-md" :class="cardStatusClass">
    <h2 class="super-provider-card__title margin-bottom-lg" data-test="sp-pta-title" v-text="title" />
    <div class="super-provider-card__value margin-bottom-md">
      <i class="material-icons margin-right-md" data-test="sp-pta-icon">assignment_turned_in</i>
      <span data-test="sp-pta-value">{{ actualPrimeTimeAvailabilityHours }}</span>
    </div>
    <div class="super-provider-card__tooltip-trigger" data-test="sp-pta-tooltip" @click="showTooltip">
      <span class="super-provider-card__tooltip-trigger-text" v-text="tooltipText" />
      <i class="material-icons">help_outline</i>
    </div>
    <span class="text-content">{{ $t('statusChecks.primeTimeAvailability.missingHours', { hours: remainingPTAHours }) }}</span>
    <div class="prime-time margin-top-md" data-test="sp-pta-primetime-info">
      <i class="material-icons">flash_on</i>
      <span v-html="$t('statusChecks.primeTimeAvailability.primetime')" />
    </div>
    <div class="super-provider-card__box-content" data-test="sp-pta-exception-info">
      <div>{{ $t('statusChecks.primeTimeAvailability.boxContent', {
        successful_request_exception: minReqSuccessfulRequestsException
      }) }}
      </div>
      <b data-test="sp-pta-number-of-meetings-info">{{ $t('statusChecks.primeTimeAvailability.currentNumberOfMeetings', {
        num_of_meetings: actualSuccessfulRequests
      }) }}</b>
    </div>
    <div class="super-provider-card__req-status" data-test="sp-pta-status">
      <span v-text="cardStatusText" />
      <i class="material-icons">{{ cardStatusIcon }}</i>
    </div>
  </div>
</template>

<i18n src="@/features/super-provider/translations/super-provider-card.json"></i18n>

<script>
import { tooltipPlugin } from '@/features/super-provider/plugins/vue-custom-tooltip.js'
import {
  DEFAULT_SUPER_PROVIDER_REQUIREMENTS,
  DEFAULT_SUPER_PROVIDER_STATUS
} from '@/features/super-provider/constants/super-provider-defaults.js'
export default {
  inject: {
    superProviderData: {
      default: () => ({
        superProviderRequirements: DEFAULT_SUPER_PROVIDER_REQUIREMENTS,
        superProviderStatus: DEFAULT_SUPER_PROVIDER_STATUS
      })
    }
  },
  computed: {
    remainingPTAHours () {
      return this.minReqPrimeTimeAvailabilityHours - this.actualPrimeTimeAvailabilityHours
    },
    actualPrimeTimeAvailabilityHours () {
      return parseInt(this.superProviderData?.superProviderStatus?.primetime_availability_hours)
    },
    actualSuccessfulRequests () {
      const { successful_requests } = DEFAULT_SUPER_PROVIDER_REQUIREMENTS

      return parseInt(this.superProviderData?.superProviderStatus?.successful_requests ?? successful_requests.value)
    },
    minReqPrimeTimeAvailabilityHours () {
      const { primetime_availability_hours } = DEFAULT_SUPER_PROVIDER_REQUIREMENTS

      return parseInt(this.superProviderData?.superProviderRequirements?.primetime_availability_hours?.value ?? primetime_availability_hours.value)
    },
    minReqSuccessfulRequestsException () {
      const { primetime_availability_hours } = DEFAULT_SUPER_PROVIDER_REQUIREMENTS.primetime_availability_hours.subsettings

      return parseInt(
        this.superProviderData?.superProviderRequirements?.primetime_availability_hours?.subsettings?.successful_meetings_exemption?.value ??
        primetime_availability_hours?.subsettings?.successful_meetings_exemption?.value
      )
    },
    title () {
      return this.$t('statusChecks.primeTimeAvailability.title')
    },
    tooltipText () {
      return this.$t('statusChecks.primeTimeAvailability.tooltipText', { min: this.minReqPrimeTimeAvailabilityHours })
    },
    tooltipExplanation () {
      return this.$t('statusChecks.primeTimeAvailability.tooltipExplanation')
    },
    onTrack () {
      // Check if primetime availability exceed min req
      // Or number of successful requests exceeds min req exception
      return (this.actualPrimeTimeAvailabilityHours >= this.minReqPrimeTimeAvailabilityHours) ||
      (this.actualSuccessfulRequests >= this.minReqSuccessfulRequestsException)
    },
    cardStatusIcon () {
      return this.onTrack
        ? 'check'
        : 'clear'
    },
    cardStatusText () {
      return this.onTrack
        ? this.$t('statusText.onTrack')
        : this.$t('statusText.notMet')
    },
    cardStatusClass () {
      return this.onTrack ? '--success' : '--warning'
    }
  },
  methods: {
    showTooltip () {
      tooltipPlugin.toast(this.tooltipExplanation)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/features/super-provider/sass/super-provider-card";

#prime-time-availability {
  .prime-time {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    i {
      color: #F9A825;
    }

    span {
      line-height: 12px;
    }

    strong {
      font-family: 'Proxima Nova Semibold', sans-serif;
    }
  }

  .text-content {
    color: #78838F;
    line-height: 16px;
    justify-content: center;
    display: flex;
    margin-top: 9px;
  }
}
</style>
