<template>
  <div>
    <ul
      id="sidenav"
      ref="sidenav"
      class="side-nav side-nav--v2 right-aligned"
      :class="{ 'has-status-indicator': showStatus, 'side-nav--super-provider': isSuperProvider }"
    >
      <li class="nav-control">
        <div
          id="sidenav-close"
          @click.prevent="closeSidenav"
        >
          <i class="material-icons right">close</i>
        </div>
      </li>
      <li class="nav-user">
        <div id="sidenav-user">
          <strong>
            {{ $translate('sidenav.header', { provider: user.profiles.name }) }}
          </strong>
          <img v-if="isSuperProvider" src="/img/super-provider/super-provider-diamond.svg">
        </div>
      </li>
      <li>
        <a class="router-link waves-effect waves-dark"
           @click.prevent="navigatePage('dashboard', false)">
          <i class="material-icons" style="margin-right: 19px !important;">home</i>
          <strong>{{ $translate('sidenav.dashboard') }}</strong>
        </a>
      </li>
      <li v-if="user.availability_enabled">
        <a class="router-link waves-effect waves-dark"
           @click.prevent="navigatePage('availability', false)">
          <i class="material-icons" style="margin-right: 19px !important;">assignment_turned_in</i>
          <strong>{{ $translate('sidenav.availability') }}</strong>
        </a>
      </li>
      <li v-if="user.events_enabled">
        <a class="router-link waves-effect waves-dark"
           :class="{
             'feature-lock-indicator': isFeatureLocked,
             'lilac-left lilac--red': hasEventUpdates
           }"
           @click.prevent="navigatePage('events', true)">
          <i class="material-icons" style="margin-right: 19px !important;">today</i>
          <strong>{{ $translate('sidenav.events') }}</strong>
        </a>
      </li>
      <li class="feedback-menu menu-group" :class="{ 'menu-group--expanded': feedbackExpanded}">
        <a href="#" class="router-link waves-effect waves-dark"
           :class="{
             'feature-lock-indicator': isFeatureLocked,
             'lilac-left lilac--red': hasFeedbackUpdates
           }"
        >
          <i class="material-icons" style="margin-right: 19px !important;" @click="navigatePage('feedback', true)">star</i>
          <strong @click="navigatePage('feedback', true)">{{ $translate('sidenav.feedback') }}</strong>
          <i v-if="!isFeatureLocked" class="material-icons drop-icon right" @click="toggleMenuGroup('feedbackExpanded')">arrow_drop_down</i>
        </a>
        <ul class="sub-links">
          <li>
            <a class="waves-effect waves-dark super-provider"
               @click.prevent="navigatePage('super-provider', false)">{{ $translate('sidenav.super_provider') }}</a>
          </li>
        </ul>
      </li>
      <li class="inbox-menu menu-group" :class="{ 'menu-group--expanded': inboxExpanded && !isFeatureLocked }">
        <a href="#"
           class="router-link waves-effect waves-dark"
           :class="{
             'lilac-left lilac--red': (hasInboxNotification || hasFeedbackUpdates) && !isFeatureLocked,
             'feature-lock-indicator': isFeatureLocked,
           }">
          <i class="material-icons" style="margin-right: 19px !important;" @click="navigatePage('inbox', true, { filter: INBOX_FILTER.ALL })">notifications_active</i>
          <strong @click="navigatePage('inbox', true, { filter: INBOX_FILTER.ALL })">{{ $translate('sidenav.inbox.title') }}</strong>
          <i v-if="!isFeatureLocked" class="material-icons drop-icon right" @click="toggleMenuGroup('inboxExpanded')">arrow_drop_down</i>
        </a>
        <ul class="sub-links">
          <li>
            <a class="waves-effect waves-dark inbox-connect"
               :class="{
                 'lilac-left lilac--red': hasKryptonConnectNotification && !isFeatureLocked
               }"
               @click.prevent="navigatePage('inbox', true, { filter: INBOX_FILTER.CONNECT })">{{ $translate('sidenav.inbox.connect') }}</a>
          </li>
          <!-- TODO: missing API implementation for Like type query -->
          <!-- <li>
            <a class="waves-effect waves-dark"
               :class="{
                 'lilac-left lilac--red': true && !isFeatureLocked
               }"
               @click.prevent="navigatePage('inbox', true, { filter: INBOX_FILTER.LIKES })">{{ $translate('sidenav.inbox.likes') }}</a>
          </li> -->
          <li>
            <a class="waves-effect waves-dark inbox-review"
               :class="{
                 'lilac-left lilac--red': hasFeedbackUpdates && !isFeatureLocked
               }"
               @click.prevent="navigatePage('inbox', true, { filter: INBOX_FILTER.REVIEWS })">{{ $translate('sidenav.inbox.reviews') }}</a>
          </li>
        </ul>
      </li>
      <li>
        <a class="router-link waves-effect waves-dark"
           @click.prevent="navigatePage('profile', false)">
          <i class="material-icons" style="margin-right: 19px !important;">face_4</i>
          <strong>{{ $translate('sidenav.profile') }}</strong>
        </a>
      </li>

      <li class="billing-menu menu-group" :class="{ 'menu-group--expanded': billingExpanded }">
        <a href="#"
           class="router-link waves-effect waves-dark"
           :class="{
             'feature-lock-indicator': isFeatureLocked,
             'lilac-left lilac--red': (hasMenuBillingUpdates && !isFeatureLocked)
           }"
        >
          <i class="material-icons" style="margin-right: 19px !important;" @click="navigatePage('billing-v2-unpaid', true)">receipt</i>
          <strong @click="navigatePage('billing-v2-unpaid', true)">{{ $translate('sidenav.billing_A2_2159.billing') }}</strong>
          <i v-if="!isFeatureLocked" class="material-icons drop-icon right" @click="toggleMenuGroup('billingExpanded')">arrow_drop_down</i>
        </a>
        <ul class="sub-links">
          <li>
            <a
              class="waves-effect waves-dark affiliate-menu"
              :class="{ 'lilac-left lilac--red': hasMenuAffiliatesUpdates }"
              @click.prevent="navigatePage('affiliate-pending', false)"
            >
              {{ $translate('sidenav.billing_A2_2159.affiliate') }}
            </a>
          </li>
        </ul>
      </li>

      <li>
        <a class="router-link waves-effect waves-dark"
           @click.prevent="navigatePage('faq', false)">
          <i class="material-icons" style="margin-right: 19px !important;">info</i>
          <strong class="valign-middle">{{ $translate('sidenav.faq') }}</strong>
        </a>
      </li>

      <li>
        <a class="router-link waves-effect waves-dark"
           :class="{
             'lilac-left lilac--red': hasSupportUpdates
           }"
           @click.prevent="navigatePage('support-v2', false)">
          <i class="material-icons" style="margin-right: 19px !important;">contact_support</i>
          <strong>{{ $translate('sidenav.help') }}</strong>
        </a>
      </li>
      <li>
        <a class="router-link waves-effect waves-dark"
           @click.prevent="navigatePage('setting', false)">
          <i class="material-icons" style="margin-right: 19px !important;">settings</i>
          <strong>{{ $translate('sidenav.settings') }}</strong>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { LITE_EVENTS } from '@/features/lite/constants'
import { isProviderProfile } from '@/constants/helpers'
import FEATURES_LIST from '@/features'
const { INBOX_FILTER } = require('@/features/inbox/constants')

const ComponentData = {
  FEATURES_LIST,
  inboxExpanded: false,
  feedbackExpanded: true,
  billingExpanded: true,
  INBOX_FILTER: INBOX_FILTER
}

export default {
  data: () => (ComponentData),
  computed: {
    ...mapGetters({
      showStatus: 'getStatusIndicator',
      hasFeedbackUpdates: 'notification/GET_HAS_NEW_FEEDBACK',
      hasEventUpdates: 'events/hasUpdates',
      hasSupportUpdates: 'support/hasUpdates',
      isCandidateProfile: 'isCandidateProfile',
      hasKryptonConnectNotification: 'notification/HAS_INBOX_NOTIFICATION'
    }),
    user () {
      return this.$store.getters.getUser
    },
    isProviderProfile () {
      return isProviderProfile(this.user)
    },
    isFeatureLocked () {
      return this.isCandidateProfile
    },
    hasInboxNotification () {
      return this.hasKryptonConnectNotification
    },
    isSuperProvider () {
      return this.user?.settings?.is_super_provider
    },
    hasMenuAffiliatesUpdates () {
      return this.user?.settings?.menu_affiliates_seen === 0
    },
    hasMenuBillingUpdates () {
      return this.user?.settings?.menu_billing_seen === 0
    }
  },
  mounted () {
    this.$store.dispatch('support/checkNewUpdates')
    $('#sidenav-trigger').sideNav({
      edge: 'right',
      draggable: false,
      closeOnClick: false
    })

    this.feedbackExpanded = false

    if (this.isCandidateProfile) {
      this.billingExpanded = false
    }
  },
  methods: {
    toggleMenuGroup (menu, restrictCandidateProfile = true) {
      if (this.isCandidateProfile && restrictCandidateProfile) {
        $h.EventBus.$emit(LITE_EVENTS.ONFEATURELOCK)
      } else {
        this[menu] = !this[menu]
      }
    },
    closeSidenav () {
      $('#sidenav-trigger').sideNav('hide')
    },
    gotoPage (name, params = {}) {
      if (this.$router.history.current.name !== name) {
        this.$router.push({ name, params })
      }
      this.closeSidenav()
    },
    navigatePage (name, restrictCandidateProfile = false, params = {}) {
      if (this.isCandidateProfile && restrictCandidateProfile) {
        $h.EventBus.$emit(LITE_EVENTS.ONFEATURELOCK)
      } else {
        this.gotoPage(name, params)
      }
    }
  }
}
</script>
