var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "navigator flex-grid",
      class: {
        "space-between": !_vm.noPrev && !_vm.noNext,
        "justify-start": !_vm.noPrev && _vm.noNext,
        "justify-end": _vm.noPrev && !_vm.noNext
      }
    },
    [
      _c("steps", {
        attrs: {
          total: _vm.total,
          current: _vm.current,
          classes: _vm.stepClass
        }
      }),
      _vm._v(" "),
      !_vm.noPrev
        ? _c(
            "button",
            {
              staticClass: "action-btn action-btn__secondary",
              attrs: {
                disabled: _vm.prevDisabled,
                "data-test": "navigator-prev"
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.onPrev($event)
                }
              }
            },
            [_vm._v("\n    " + _vm._s(_vm.prevText) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.noNext
        ? _c(
            "button",
            {
              staticClass: "action-btn",
              class: _vm.nextClass,
              attrs: {
                disabled: _vm.nextDisabled,
                "data-test": "navigator-next"
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.onNext($event)
                }
              }
            },
            [_vm._v("\n    " + _vm._s(_vm.nextText) + "\n  ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }