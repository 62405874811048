<template>
  <div id="verify" class="wrapper">
    <template>
      <div class="content__area">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <h2 class="content__title content__title--desktop">{{ $translate('register.verify.title') }}</h2>
      </div>

      <div class="content__area padding-bottom">
        <div class="content">
          <h2 class="content__title content__title--mobile">{{ $translate('register.verify.title') }}</h2>

          <div class="context">
            <div class="subtitle">
              {{ $translate('register.verify.description') }}
            </div>
          </div>

          <div>

            <div v-if="callingCodes.length" class="margin-top-lg">

              <apv-form data-test="submit-form" @submit.prevent="isRequestCode ? verifySMSCode() : requestSMSCode()">
                <div class="verification-field-container">
                  <div id="land" :class="{ 'disable': isRequestCode }" class="country-field-container baselined">
                    <country-picker-autocomplete
                      v-model="payload.code"
                      class="country-picker textfield-box-lg verify-dropdown"
                      :class="{
                        'country-picker--APV_REG_REDESIGN': true
                      }"
                      on-registration
                      :caret="true"
                      :disabled="isRequestCode"
                      @select="countrySelected"
                    />
                  </div>

                  <div :class="{ 'disable': isRequestCode }" class="mobile-field-container-new baselined">
                    <apv-input
                      ref="clear"
                      v-model="payload.phone_number"
                      type="tel"
                      class="textfield-box-lg phoned"
                      :label="$translate('register.verify.mobile_number')"
                      :error="errors.first('mobile')"
                      :disabled="isRequestCode"
                      required
                      style="margin-top: -20px;"
                      :blur-on-keyup-enter="true"
                      @focus="hideButton"
                      @blur="showButton"
                      @onKeyupEnter="handleEnterKey"
                    />
                  </div>
                </div>
                <div class="sms-field-container baselined breaked">
                  <apv-input
                    v-if="isRequestCode"
                    v-model="payload.smsCode"
                    class="smscode textfield-box-lg"
                    data-test="sms-code"
                    type="tel"
                    :error="errors.first('verified')"
                    :label="$translate('setting.sms_code_label')"
                    @focus="scrollDown"
                    @onKeyupEnter="handleEnterKey"
                  />
                  <ul v-if="isRequestCode" class="resend-text">
                    <li v-html="$translate('register.verify.resend')" />
                    <li @click.prevent="resendSMSCode"><span class="special">{{ $translate('register.verify.resend_btn') }}</span></li>
                  </ul>
                </div>
              </apv-form>

            </div>
          </div>

          <div class="actions flex-grid justify-content-center">
            <template v-if="isRequestCode">
              <button
                class="btn btn-primary btn-verify"
                :disabled="isLoading || !payload.phone_number || !payload.smsCode"
                @click.prevent="verifySMSCode"
              >{{ $translate('register.verify.continue') }}</button>
            </template>

            <template v-else>
              <button
                class="btn btn-primary"
                :disabled="isLoading || !payload.phone_number"
                @click.prevent="requestSMSCode"
              >{{ $translate('register.verify.continue') }}</button>
            </template>
          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import { DIAL_CODES } from '@/components/register/final/constants'
import { responseCodes } from '@/services/ama-tools/constants/ReponseCodes'
import CountryPickerAutocomplete from '@/features/country-picker/components/CountryPickerAutocomplete.vue'

const RegisterProvider = window?.$h?.getProvider('RegisterProvider')
const AuthProvider = window?.$h?.getProvider('AuthProvider')

export default {
  components: { CountryPickerAutocomplete },
  data () {
    return {
      isRequestCode: false,
      isButtonHidden: false,
      phoneVerificationRes: null,
      isLoading: false,
      payload: {
        code: 'DE',
        country: null,
        country_code: null,
        phone_number: '',
        smsCode: ''
      },
      isCountryPickerShown: false
    }
  },
  computed: {
    callingCodes () {
      return this.$store.getters.getContactCodes
    },
    homebaseCountry () {
      return this.$store.getters.getHomeBaseCountry
    },
    FEATURE_A2_483 () {
      return this.$store.getters['featureflag/isEnabled']('A2_483')
    },
    drawerIconSrc () {
      if (this.homebaseCountry === 'GB') {
        return '/img/registration/checkout/verify-uk.png'
      }

      if (this.homebaseCountry === 'CH') {
        return '/img/registration/checkout/verify-ch.png'
      }

      return '/img/registration/checkout/verify-de.png'
    }
  },
  mounted () {
    const country = this.homebaseCountry

    this.payload.country_code = DIAL_CODES[country]

    this.payload.code = country

    this.payload.country = country
  },
  methods: {
    gotoPrev () {
      this.$router.push({ name: 'register-overview' })
    },
    countrySelected (value) {
      this.payload.country_code = value.callingCode

      this.payload.country = value.alpha2Code
    },
    hideButton () {
      this.isButtonHidden = true
    },
    showButton () {
      this.isButtonHidden = false
    },
    scrollDown () {
      setTimeout(() => {
        window.scroll({ top: 300, left: 0, behavior: 'smooth' })
      }, 300)
    },
    async redirectToReactivationRequest () {
      // Logout the registering user
      await AuthProvider.logout()
      // Since this is a candidate - No need to reset availabilities, billing and events
      await AuthProvider.setAuthorizationHeader()
      // Set contact_detail then redirect to reactivate page
      await window.localforage.setItem('contact_detail', this.payload.phone_number)
      this.$router.push({ name: 'reactivate' })
    },
    resendSMSCode () {
      this.errors.clear()
      this.isRequestCode = false
      this.isLoading = false
      this.$refs.clear.clearAndFocusInput()
    },
    requestSMSCode () {
      const code = this.getCallCode(this.payload.code)
      this.isLoading = true

      this.errors.clear()

      RegisterProvider
        .requestSMSCode(
          this.payload.phone_number,
          this.payload.country_code,
          this.payload.country
        )
        .then((res) => {
          // If detected that the mobile number being used belongs to deactivated user
          if (res?.data?.deactivated === 1) {
            this.redirectToReactivationRequest()
          // Catch if with flag MAX_REQUEST_CODE_ATTEMPT
          } else if (res?.data?.flag === 'max_request_code_attempt') {
            this.$notifications.toast(res.data.message, 5000)
          } else {
            this.phoneVerificationRes = res
            this.isRequestCode = true
            this.isLoading = false
          }
        })
        .catch((err) => {
          let errorMsg = 'An error has occured.'

          if (this.FEATURE_A2_483 && err.status && [responseCodes.SERVER_ERROR, responseCodes.RATE_LIMIT].includes(err.status)) {
            let placeholder = this.$translate('errors.server_error')

            if (err.status === responseCodes.RATE_LIMIT) {
              placeholder = this.$translate('errors.rate_limit')
            }
            return this.$notifications.toast(err.data.data.message || placeholder)
          }

          if (err.data.data.country_code) {
            errorMsg = err.data.data.country_code[0]
          } else if (err.data.data.phone_number) {
            errorMsg = err.data.data.phone_number[0]
          } else {
            errorMsg = err.data.message
          }

          this.errors.add({
            field: 'mobile',
            msg: errorMsg
          })
          this.isLoading = false
        })
        .finally(() => {
          if (document.querySelector('.smscode input') !== null) {
            document.querySelector('.smscode input').focus()
          }

          this.isLoading = false
        })
    },
    verifySMSCode () {
      this.errors.clear()

      const code = this.getCallCode(this.payload.code)

      this.isLoading = true

      RegisterProvider.verifySMSCode(
        this.phoneVerificationRes.data.phone_number,
        this.phoneVerificationRes.data.country_code,
        this.payload.smsCode,
        this.payload.country
      )
        .then((res) => {
          this.isLoading = false
          this.$store.commit('setPhone', this.phoneVerificationRes.data.phone_number)
          this.$router.push({ name: 'register-rates' })
        })
        .catch((err) => {
          let errorMsg = 'An error has occured.'

          if (this.FEATURE_A2_483 && err.status && [responseCodes.SERVER_ERROR, responseCodes.RATE_LIMIT].includes(err.status)) {
            let placeholder = this.$translate('errors.server_error')

            if (err.status === responseCodes.RATE_LIMIT) {
              placeholder = this.$translate('errors.rate_limit')
            }
            return this.$notifications.toast(err.data.data.message || placeholder)
          }

          if (err?.data?.data?.flag === 'max_verify_code_attempt') {
            this.$notifications.toast(err?.data?.data?.message || this.$translate('errors.server_error'), 5000)
            return
          }

          if (err.data.data.country_code) {
            errorMsg = err.data.data.country_code[0]
          } else if (err.data.data.phone_number) {
            errorMsg = err.data.data.phone_number[0]
          } else if (err.data.data.verification_code) {
            errorMsg = err.data.data.verification_code[0]
          } else {
            errorMsg = err.data.message
          }

          this.errors.add({
            field: 'verified',
            msg: errorMsg
          })

          this.isLoading = false
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getCallCode (id) {
      return _.find(this.callingCodes, { id: id })
    },
    handleEnterKey () {
      // if (event.key === 'Enter') {
      // event.preventDefault()
      console.log('keyup enter triggered')
      if (this.isRequestCode) {
        this.verifySMSCode()
      } else {
        this.requestSMSCode()
      }
      // }
    }
  }
}
</script>

<style lang="scss" scoped>

#verify {

  .content__area {
    .disable {
      opacity: 0.5;
      pointer-events: none;
    }

    .resend-text {
      padding: 32px 0 140px 0;
      text-align: center;

      li {
        color: #2E353D;
        display: contents;
        font-family: 'Proxima Nova';
        font-size: 14px;
        line-height: 20px;
      }

      .special {
        color: #E30074;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    #land {
      flex-grow: unset;
      margin-right: 5px;
      width: 145px;

      .country-picker {
        width: 145px;
      }
    }

    .breaked {
      flex-basis: 100%;
      height: 0;

      @media screen and (max-width: 400px) {
        flex-basis: unset;
      }
    }

    .baselined {
      align-self: baseline;
    }

    .btn {
      width: 100%;
      justify-content: center;
      max-width: 327px;
    }
  }
}

</style>
