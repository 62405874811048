var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        ref: "photo-upload",
        staticClass: "photo-gallery-v2-photo",
        class: {
          "is-draggable": _vm.$props.photo.primary === 0 && _vm.isEditMode,
          "not-published": _vm.$props.photo.status === 0
        },
        style: _vm.bgImage
      },
      [
        _c("div", {
          staticClass: "preview-btn",
          on: { click: _vm.previewPhoto }
        }),
        _vm._v(" "),
        _vm.$props.photo.primary !== 0
          ? _c("div", { staticClass: "primary-photo-indicator" })
          : _vm._e(),
        _vm._v(" "),
        _vm.isEditMode && _vm.$props.photo.primary !== 0
          ? _c("div", { staticClass: "edit-locked" })
          : _vm._e(),
        _vm._v(" "),
        _vm.showPhotoInsights
          ? _c(
              "div",
              {
                staticClass: "photo-insights",
                attrs: { "data-test": "photo-insights" }
              },
              [
                _c("div", { staticClass: "photo-insights__like" }, [
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v("favorite")
                  ]),
                  _vm._v(" "),
                  _c("span", { attrs: { "data-test": "photo-likes-count" } }, [
                    _vm._v(_vm._s(_vm.photo._likes))
                  ])
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isEditMode
          ? _c("div", {
              staticClass: "photo-delete waves-effect waves-dark",
              on: { click: _vm.replacePhoto }
            })
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }