var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal left-align", attrs: { id: "block-client" } },
    [
      _c("div", { staticClass: "modal-content center-align" }, [
        _c("h4", {
          staticClass: "modal-title",
          domProps: { innerHTML: _vm._s(_vm.$translate("inbox.block.title")) }
        }),
        _vm._v(" "),
        _c("i", {
          staticClass: "mdi mdi-account-off krypton-pink-text valign-middle",
          staticStyle: { "font-size": "72px" }
        }),
        _c("br"),
        _vm._v(" "),
        _c("p", {
          staticClass: "content grey-text text-darken-1",
          domProps: { innerHTML: _vm._s(_vm.content) }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn-flat waves-effect waves-dark modal-close",
            on: { click: _vm.cancel }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$translate("inbox.cta_buttons.cancel")) +
                "\n    "
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn-flat waves-effect waves-dark krypton-pink-text",
            on: { click: _vm.confirm }
          },
          [
            _vm.isLoading
              ? _c("div", { staticClass: "preloader-wrapper small active" }, [
                  _vm._m(0)
                ])
              : _c("span", [
                  _vm._v(_vm._s(_vm.$translate("inbox.cta_buttons.confirm")))
                ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner-layer spinner-green-only" }, [
      _c("div", { staticClass: "circle-clipper left" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _c("div", { staticClass: "gap-patch" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _c("div", { staticClass: "circle-clipper right" }, [
        _c("div", { staticClass: "circle" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }