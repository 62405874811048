<template>
  <div
    class="input-field"
    :class="{
      'active': forceActive,
      'has-placeholder': placeholder,
      'input-field--APV_REG_REDESIGN': true,
      'has-value': !isEmpty(model),
      'disabled': disabled
    }"
  >
    <input
      v-if="type === 'password'"
      ref="textinput"
      v-model="model"
      :type="checkingPasswordType"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :tabindex="tabindex"
      :maxlength="maxlength"
      :disabled="disabled"
      @focus="onFocus"
      @blur="onBlur"
    >
    <input
      v-else
      ref="textinput"
      v-model="model"
      :type="type"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :tabindex="tabindex"
      :maxlength="maxlength"
      :disabled="disabled"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
    >
    <label :class="{ 'active': isLabelActive }">{{ label }}</label>
    <img
      v-if="type === 'password'"
      class="show-hide-cta"
      :src="showPasswordImgSrc"
      @click="showPassword"
    >
    <img
      v-if="type !== 'password' && clearable"
      ref="clearcta"
      src="/img/registration/input/clearcta.svg"
      alt="X"
      class="clear-cta"
      @click="clearAndFocusInput"
    >
    <slot />
  </div>
</template>

<script>
import { isEmpty } from '@/constants/helpers.js'

export default {
  props: [
    'type',
    'label',
    'value',
    'disabled',
    'tabindex',
    'maxlength',
    'placeholder',
    'autocomplete',
    'forceActive',
    'clearable'
  ],
  data () {
    return {
      model: null,
      isShowedPassword: false
    }
  },
  computed: {
    showPasswordImgSrc () {
      return this.checkingPasswordType === 'password' ? '/img/eyes/eye.svg' : '/img/eyes/eye-slash.svg'
    },
    checkingPasswordType () {
      if (this.type === 'password' && !this.isShowedPassword) {
        return 'password'
      } else if (this.type === 'password' && this.isShowedPassword) {
        return 'text'
      }
    },
    isLabelActive () {
      if ((this.model == null || !this.model.length) && this.placeholder == null) {
        return false
      }

      return true
    }
  },
  watch: {
    value (newval, oldval) {
      this.model = newval
    },
    model (newval, oldval) {
      this.$emit('input', newval)
    }
  },
  mounted () {
    this.model = this.value
  },
  methods: {
    showPassword () {
      if (this.type !== 'password') {
        return
      }

      this.isShowedPassword = !this.isShowedPassword
    },
    isEmpty (val) {
      return isEmpty(val)
    },
    onFocus ($event) {
      this.$emit('focus', $event)

      const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && screen.width < 600

      if (isMobile) {
        const footerButtons = document.querySelector('.footer-buttons')

        if (footerButtons) { footerButtons.style.display = 'none' }

        setTimeout(() => {
          this.$refs
            .textinput
            .scrollIntoView({ block: 'center', behavior: 'smooth' })
        }, 500)
      }

      if ($event.target.value && $event.target.value.length > 0) {
        this.addActiveClass('clearcta')
      }
    },
    onBlur ($event) {
      const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && screen.width < 600

      if (isMobile) {
        const footerButtons = document.querySelector('.footer-buttons')

        if (footerButtons) { footerButtons.style.display = 'initial' }
      }

      this.$emit('blur', $event)
    },
    onInput ($event) {
      this.$emit('input', $event.target.value)

      if ($event.target.value) {
        this.addActiveClass('clearcta')
      } else {
        this.removeActiveClass('clearcta')
      }
    },
    clearInput () {
      this.$emit('input', null)
      this.$emit('onClearInput')
      this.$refs.textinput.value = null
      this.removeActiveClass('clearcta')
    },
    focusInput () {
      this.$refs.textinput.focus()
    },
    clearAndFocusInput () {
      this.clearInput()
      this.focusInput()
    },
    addActiveClass (element = null) {
      if (element) {
        this.$refs[element]?.classList.add('active')
      }
    },
    removeActiveClass (element = null) {
      if (element) {
        this.$refs[element]?.classList.remove('active')
      }
    }
  }
}
</script>

<style type="text/css">
input[type=text]:not(.browser-default)[readonly="readonly"] {
  color: #2F3740;
  border-bottom: 2px solid #2F3740;
}
</style>

<style lang="scss" scoped>
.input-field--APV_REG_REDESIGN {
  width: 100%;
  height: 70px;
  margin: 0 0;
  padding: 0;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  border: 1px solid #CED6E0;
  border-radius: 12px;
  color: #52575B;
  font-size: 16px;
  font-family: 'Proxima Nova Semibold';
  background: #FFFFFF;

  &:focus-within:not([readonly]) {
    border: 2px solid #2F3740;

    label.active {
      color: #2F3740;
    }
  }

  label {
    color: #52575B;
    top: 12px;
    left: 16px;
    font-family: 'Proxima Nova Semibold';
    font-size: 16px;

    &.active {
      top: -10px !important;
      transform: translate(0px, 0px) scale(1);
      left: 11px !important; // Deduct the 5px padding from the minimized label
      color: #2F3740;
      background: #FFFFFF;
      padding-left: 5px;
      padding-right: 5px;
      font-family: 'Proxima Nova Medium';
      font-size: 12px;
      height: 16px;
    }
  }

  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="password"] {
    border-bottom: none;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;

    &:focus, &:focus:not([readonly]) {
      border-bottom: none !important;
      border-radius: 12px;
    }

    &:disabled {
      background: #f7fafc;
      border-bottom: none;

      &+label {
        background: #f7fafc;
      }
    }
  }

  &.has-value:not(.disabled) {
    & {
      border: 1px solid #2F3740;
      color: #2F3740;
    }

    label.active {
      color: #52575B
    }

    &.with-error {
      border: 2px solid #FF0033;
      margin-bottom: 38px;

      label {
        color: #FF0033;
      }
    }
  }

  .show-hide-cta {
    position: absolute;
    top: 14px;
    right: 4px;
    cursor: pointer;
  }

  .text-error {
    color: #FF0033;
    font-size: 12px;
    margin-top: 7px;
  }

  .clear-cta {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 24px;
    right: 16px;
    cursor: pointer;
    display: none;

    &.active {
      display: block;
    }
  }
}
</style>
