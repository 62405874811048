<template>
  <div id="photo-guide">
    <bottomsheet :is-shown="isShown" @close="() => $emit('close')">
      <template v-slot:body>
        <h2 class="title">{{ $translate('photos.guide.title') }}</h2>
        <p class="sub-title">{{ $translate('photos.guide.explanation_text') }}</p>
        <br>
        <div class="case-study">
          <strong style="font-style: italic;">Case Study</strong>: <span v-html="$translate('photos.guide.case_study')" />
        </div>
        <ul ref="collapsible" class="collapsible apv-collapsible">
          <li>
            <div class="collapsible-header">
              <p id="tip1" class="tips">{{ $translate('photos.guide.collapsible.tip1.title') }}</p>
            </div>
            <div class="collapsible-body">
              <p v-html="$translate('photos.guide.collapsible.tip1.content')" />
              <div class="center">
                <img class="responsive-img" src="/img/photos/guide/tip-1.png" alt="">
              </div>
            </div>
          </li>
          <li>
            <div class="collapsible-header">
              <p id="tip2" class="tips">{{ $translate('photos.guide.collapsible.tip2.title') }}</p>
            </div>
            <div class="collapsible-body">
              <div class="center">
                <img src="/img/photos/guide/tip-2.png" class="responsive-img">
              </div>
              <p v-html="$translate('photos.guide.collapsible.tip2.content')" />
              <br>
              <div class="center">
                <img width="70" height="50" src="/img/quote-yellow.png" alt="">
              </div>
              <p class="center quote-title">
                <strong v-html="$translate('photos.guide.collapsible.tip2.quotes.quote_1.title')" />
              </p>
              <p v-html="$translate('photos.guide.collapsible.tip2.quotes.quote_1.content')" />
              <br>
              <div class="center">
                <img width="70" height="50" src="/img/quote-yellow.png" alt="">
              </div>
              <p class="center quote-title">
                <strong v-html="$translate('photos.guide.collapsible.tip2.quotes.quote_2.title')" />
              </p>
              <p v-html="$translate('photos.guide.collapsible.tip2.quotes.quote_2.content')" />
            </div>
          </li>
          <li>
            <div class="collapsible-header">
              <p id="tip3" class="tips">{{ $translate('photos.guide.collapsible.tip3.title') }}</p>
            </div>
            <div class="collapsible-body">
              <div class="center">
                <img src="/img/photos/guide/tip-3.png" class="responsive-img">
              </div>
              <p v-html="$translate('photos.guide.collapsible.tip3.content')" />
              <br>
              <div class="center">
                <img width="70" height="50" src="/img/quote-yellow.png" alt="">
              </div>
              <p class="center quote-title">
                <strong v-html="$translate('photos.guide.collapsible.tip3.quotes.quote_1.title')" />
              </p>
              <p v-html="$translate('photos.guide.collapsible.tip3.quotes.quote_1.content')" />
            </div>
          </li>
          <li>
            <div class="collapsible-header">
              <p id="tip4" class="tips">{{ $translate('photos.guide.collapsible.tip4.title') }}</p>
            </div>
            <div class="collapsible-body">
              <div class="center">
                <img src="/img/photos/guide/tip-4.png" class="responsive-img">
              </div>
              <p v-html="$translate('photos.guide.collapsible.tip4.content')" />
            </div>
          </li>
        </ul>
        <div class="photo-tips">
          <div class="center">
            <img width="56" height="50" src="/img/camera-yellow.png" alt="">
            <h5 class="tip-title">
              {{ $translate('photos.guide.more_tips.title') }}
            </h5>
          </div>
          <div class="slider-tips">
            <div ref="carousel" class="carousel carousel-slider center">
              <div class="carousel-wrapper">
                <div class="carousel-item left-align" href="#one!">
                  <div class="carousel-item-content">
                    <div class="tips">{{ $translate('photos.guide.more_tips.tip1') }}</div>
                  </div>
                </div>
                <div class="carousel-item left-align" href="#two!">
                  <div class="carousel-item-content">
                    <div class="tips">{{ $translate('photos.guide.more_tips.tip2') }}</div>
                  </div>
                </div>
                <div class="carousel-item left-align" href="#three!">
                  <div class="carousel-item-content">
                    <div class="tips">{{ $translate('photos.guide.more_tips.tip3') }}</div>
                  </div>
                </div>
                <div class="carousel-item left-align" href="#four!">
                  <div class="carousel-item-content">
                    <div class="tips">{{ $translate('photos.guide.more_tips.tip4') }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="nav-control" @click.prevent="clickControl" />
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <div>&nbsp;</div>

        <button class="bottom-sheet__cta bottom-sheet__cta--confirm --black" style="text-transform: uppercase;" @click="() => $emit('close')">
          {{ $translate('photos.back') }}
        </button>
      </template>
    </bottomsheet>
  </div>
</template>

<script>
import bottomsheet from '@components/v2/utilities/bottomsheet.vue'

export default {
  name: 'PhotoGuide',
  components: {
    bottomsheet
  },
  props: {
    isShown: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      carousel: null
    }
  },
  computed: {
    PHOTO_FALLBACK_A2_2720 () {
      return this.$store.getters['featureflag/isEnabled']('PHOTO_FALLBACK_A2_2720')
    }
  },
  watch: {
    isShown (newVal) {
      if (newVal) {
        this.$nextTick(() => {
          $(this.$refs.collapsible).collapsible({
            onOpen: (el) => {
              setTimeout(() => {
                const top = el[0].offsetTop

                $('html,body').animate({
                  scrollTop: top
                }, 300, 'swing')
              }, 300)
            }
          })

          setTimeout(() => {
            $(this.$refs.carousel).carousel({
              fullWidth: true,
              indicators: true
            })
          }, 1000)
        })
      }
    }
  },
  mounted () {
    this.$store.commit('setPhotoTipSeen')
    this.$store.dispatch('setPhotoTipSeen')
  },
  methods: {
    clickControl (evt) {
      if (evt.offsetX < 100) {
        $(this.$refs.carousel).carousel('prev')
      } else {
        $(this.$refs.carousel).carousel('next')
      }
    }
  }
}
</script>
