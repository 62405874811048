var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.photoTrigger === _vm.PHOTO_TRIGGER_VALUES.PRIMARY_PHOTO
        ? _c("div", { staticClass: "upload-container" }, [
            _c(
              "a",
              {
                staticClass: "primary-upload",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.showPhotoTipsForFirstTimeUser($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "primary-upload-content" }, [
                  _c("img", {
                    attrs: {
                      src: "/img/registration/photos/img-placeholder-new.svg",
                      alt: "image placeholder"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$translate("photos.add_photo")))
                    ])
                  ])
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.photoTrigger === _vm.PHOTO_TRIGGER_VALUES.PHOTO_TIPS_CTA
        ? _c(
            "button",
            {
              staticClass: "btn btn-black btn-photo-tip-upload",
              attrs: { "data-test": "upload-cta-navigator" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.showFilePicker($event)
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  src:
                    "/img/registration/tips/photo-tips/cta-upload-photo-icon.svg",
                  alt: ""
                }
              }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$translate("photos.add_photo2")))])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "vform",
        { ref: "uploadMainForm", on: { submit: _vm.uploadMainPhoto } },
        [
          _c("input", {
            ref: "uploadMainFormFile",
            staticClass: "hide",
            attrs: { type: "file", accept: "image/*" },
            on: { change: _vm.submitMainForm }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }