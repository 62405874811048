var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "photos-gallery" } }, [
    (_vm.hasPrimaryPhoto && !_vm.isFetchingLivePhotos) ||
    _vm.isFetchingLivePhotos
      ? _c("div", [
          _c("h2", { staticClass: "photos-gallery__title" }, [
            _vm._v(_vm._s(_vm.$translate("photos.v2.tabs.live_photos")))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { attrs: { id: "live-photos" } },
              [
                _c(
                  "div",
                  { staticClass: "lesson-shortcut--container" },
                  [
                    _c("lesson-shortcut", {
                      attrs: { "translation-key": "photos" },
                      model: {
                        value: _vm.LESSONS.PHOTOS,
                        callback: function($$v) {
                          _vm.$set(_vm.LESSONS, "PHOTOS", $$v)
                        },
                        expression: "LESSONS.PHOTOS"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("live-photos")
              ],
              1
            )
          ])
        ])
      : _c("div", [_c("add-primary-photo")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }