<template>
  <div id="affiliate-page">
    <md-new-navbar route-name="affiliate-overview" />
    <div id="content" class="container">
      <div class="affiliate-header">
        <h1 data-test="title" v-text="$t('title')" />
        <div class="affiliate-header__card" data-test="banner">
          <div class="affiliate-header__card-container">
            <div class="affiliate-header__card-title" data-test="banner-title" v-text="$t('card.title')" />
            <div class="affiliate-header__card-chip" data-test="banner-credits">{{ creditBalance }}</div>
          </div>
        </div>
      </div>
      <div class="affiliate-tabs">
        <router-link
          :to="{ name: 'affiliate-received' }"
          class="affiliate-tabs__tab waves-effect"
          :class="{ 'affiliate-tabs__tab--active': isTabActive('affiliate-received') }"
          data-test="received-tab"
          v-text="$t('tabs.received.label')"
        />
        <router-link
          :to="{ name: 'affiliate-pending' }"
          class="affiliate-tabs__tab waves-effect"
          :class="{ 'affiliate-tabs__tab--active': isTabActive('affiliate-pending') }"
          data-test="pending-tab"
          v-text="$t('tabs.pending.label')"
        />
      </div>
      <div class="affiliate-content">
        <router-view />
      </div>
      <div class="affiliate-cta">
        <affiliate-share-button data-test="share-link-cta" />
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/affiliate/translations/affiliate.json"></i18n>

<script>
import AffiliateShareButton from '@/features/affiliate/components/common/AffiliateShareButton.vue'
import { AFFILIATE_OVERVIEW, AFFILIATE_SETTINGS_SEEN_FIELD_TYPES } from '@/features/affiliate/constants'
import { putAffiliateSettingsSeen } from '@/features/affiliate/services/AffiliateService.js'

export default {
  provide () {
    const affiliateData = {}
    Object.defineProperty(affiliateData,
      'affiliateOverview', {
        enumerable: true,
        get: () => this.affiliateOverview
      }
    )

    Object.defineProperty(affiliateData,
      'isFromGB', {
        enumerable: true,
        get: () => this.isFromGB
      }
    )

    Object.defineProperty(affiliateData,
      'currency', {
        enumerable: true,
        get: () => this.currency
      }
    )

    Object.defineProperty(affiliateData,
      'amountEarnable', {
        enumerable: true,
        get: () => this.amountEarnable
      }
    )

    Object.defineProperty(affiliateData,
      'isLoading', {
        enumerable: true,
        get: () => this.isLoading
      }
    )

    return {
      affiliateData
    }
  },
  components: {
    AffiliateShareButton
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    isFromGB () {
      return (this.user?.profiles?.country_code ?? '').toLowerCase() === 'gb'
    },
    isTabActive () {
      return tab => tab === this.$route.name
    },
    affiliateSettings () {
      return this.$store.getters['affiliate/getAffiliateSettings']
    },
    totalRewards () {
      return this.$store.getters['affiliate/getAffiliateTotalRewards']
    },
    pendingReferrals () {
      return this.$store.getters['affiliate/getAffiliatePendingReferrals']
    },
    successfulReferrals () {
      return this.$store.getters['affiliate/getAffiliateSuccessfulReferrals']
    },
    affiliateOverview () {
      return {
        ...AFFILIATE_OVERVIEW,
        settings: this.affiliateSettings,
        total_rewards: this.totalRewards,
        pending_referrals: this.pendingReferrals,
        successful_referrals: this.successfulReferrals
      }
    },
    currency () {
      return this.user?._currency ?? '€'
    },
    amountEarnable () {
      if (this.isFromGB) {
        return this.affiliateOverview?.settings?.rewards?.gbp
      }

      return this.affiliateOverview?.settings?.rewards?.eur
    },
    creditBalance () {
      const balance = !this.isFromGB
        ? `${this.affiliateOverview?.total_rewards ?? 0} ${this.currency}`
        : `${this.currency} ${this.affiliateOverview?.total_rewards ?? 0}`

      return balance
    }
  },
  mounted () {
    if (!this.totalRewards) {
      this.fetchAffiliateOverview()
    }
    this.checkMenuAffiliateSeen()
  },
  methods: {
    async fetchAffiliateOverview () {
      this.isLoading = true
      await this.$store.dispatch('affiliate/fetchAffiliateOverview')
      this.isLoading = false
    },
    checkMenuAffiliateSeen () {
      if (this.user?.settings?.menu_affiliates_seen === 0) {
        putAffiliateSettingsSeen({ field: AFFILIATE_SETTINGS_SEEN_FIELD_TYPES.MENU_AFFILIATE })
          .then(() => this.$store.commit('setUserSettingMenuAffiliatesSeen', 1))
          .catch(err => this.handleErrorMessages(err))
      }
    },
    handleErrorMessages (err = ({ message: '' })) {
      this.$notifications.toast(err?.message, 5000)
    }
  }
}
</script>

<style lang="scss" scoped>
#affiliate-page {
  #content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 56px);
    padding: 24px 24px 0;

    .affiliate-header {
      margin: -24px -24px 0;
      padding: 32px 24px 14px;
      background-color: #FFFFFF;

      h1 {
        margin: 0 0 32px;
        font-size: 22px;
        font-family: 'Proxima Nova Semibold', sans-serif;
        color: #2E353D;
      }

      .affiliate-header__card {
        border-radius: 8px;
        background: transparent linear-gradient(252deg, #F5187C 0%, #CC1467 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 12px #C511623D;

        .affiliate-header__card-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 28px 40px;
          max-width: 100%;
          background-image: url('/img/affiliate/gruppe-9197.svg');
          background-repeat: no-repeat;
          background-position: bottom 2px right 4px;
          text-align: left;
          color: #FFFFFF;

          .affiliate-header__card-title {
            margin-bottom: 10px;
            font-size: 14px;
          }
          .affiliate-header__card-chip {
            width: fit-content;
            display: inline-block;
            padding: 8px 16px;
            border-radius: 21px;
            background-color: #e769a2;
            font-size: 20px;
            font-family: 'Proxima Nova Semibold', sans-serif;
          }
        }
      }
    }

    .affiliate-tabs {
      display: flex;
      background-color: #FFFFFF;
      margin: 0 -24px;

      .affiliate-tabs__tab {
        width: 50%;
        padding: 14px 0 12px;
        border-bottom: 2px solid transparent;
        font-size: 15px;
        font-family: 'Proxima Nova Semibold', sans-serif;
        text-align: center;
        color: rgba(#2E353D, 0.54);
      }
      .affiliate-tabs__tab.affiliate-tabs__tab--active {
        border-bottom: 2px solid #C51162;
        color: #2E353D;
      }
    }

    .affiliate-content {
      flex: 1 1 auto;
      overflow: auto;
    }

    .affiliate-cta {
      margin: 0 -24px;
      padding: 24px;
      background-color: #FFFFFF;
    }
  }
}
</style>
