import { render, staticRenderFns } from "./TotalRatingFAQ.vue?vue&type=template&id=543cd080&scoped=true&"
import script from "./TotalRatingFAQ.vue?vue&type=script&lang=js&"
export * from "./TotalRatingFAQ.vue?vue&type=script&lang=js&"
import style0 from "./TotalRatingFAQ.vue?vue&type=style&index=0&id=543cd080&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "543cd080",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider8-stage.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('543cd080')) {
      api.createRecord('543cd080', component.options)
    } else {
      api.reload('543cd080', component.options)
    }
    module.hot.accept("./TotalRatingFAQ.vue?vue&type=template&id=543cd080&scoped=true&", function () {
      api.rerender('543cd080', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/features/provider-faq/pages/TotalRatingFAQ.vue"
export default component.exports