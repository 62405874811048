<template>
  <div id="registration">
    <start-desktop-view
      :username.sync="email"
      :password.sync="password"
      :error="errorMessages"
      @submit="register"
    />
    <start-mobile-view
      :username.sync="email"
      :password.sync="password"
      :error="errorMessages"
      @submit="register"
    />
  </div>
</template>

<script>
import StartController from './mixins/start.controller'
import StartDesktopView from './desktop.vue'
import StartMobileView from './mobile.vue'

export default {
  components: {
    StartDesktopView,
    StartMobileView
  },
  mixins: [StartController]
}
</script>
