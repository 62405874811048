var render = function(_h, _vm) {
  var _obj
  var _c = _vm._c
  return _c(
    "div",
    {
      staticClass: "auth-background",
      class:
        ((_obj = {}), (_obj["auth-" + _vm.props.route] = _vm.props.route), _obj)
    },
    [
      _c("div", { staticClass: "auth-parent" }, [
        _c(
          "div",
          { staticClass: "auth-child" },
          [
            _c("div", { staticClass: "auth-child--header" }, [
              _c("img", {
                staticClass: "k-logo-new",
                attrs: { src: "/img/icons/k-logo-new.svg" }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "auth-title" }, [_vm._t("title")], 2)
            ]),
            _vm._v(" "),
            _vm.props.data.errorMsg && _vm.props.showGenericErrorMsg
              ? _c(
                  "div",
                  {
                    staticClass: "error-message margin-bottom-md",
                    attrs: { "data-test": "error-msg" }
                  },
                  [_vm._v(_vm._s(_vm.props.data.errorMsg) + "\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._t("default"),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "auth-footer",
                class: { "p-fixed": _vm.props.positionfixed }
              },
              [
                _c(_vm.injections.components.LanguageSwitcherAuthView, {
                  tag: "component"
                })
              ],
              1
            )
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }