<template>
  <button v-if="show" ref="slideToTop" class="slide-top" :class="classes" @click="slideToTop">
    <i class="material-icons margin-right-sm">arrow_upward</i><span>{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: 'SlideToTopBtn',
  props: {
    classes: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: 'Go to top'
    }
  },
  data () {
    return {
      show: false,
      observer: null
    }
  },
  mounted () {
    const watchEl = this.$parent.$refs.scrollArea.getElementsByTagName('h1')[0]

    this.observer = new IntersectionObserver(this.observeCallback, {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0
    })

    this.observer.observe(watchEl)
  },
  methods: {
    slideToTop () {
      const scrollArea = this.$parent.$refs.scrollArea

      return scrollArea.scrollTo({ top: 0, behavior: 'smooth' })
    },
    observeCallback (sections, observer) {
      sections.forEach((section) => {
        if (!section.isIntersecting) {
          this.show = true
        } else {
          this.show = false
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
  .slide-top {
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 99;
    padding: 0px 22px;

    span {
      margin-top: 2px;
    }

    @media screen and (max-width: 991px) {
      height: 44px !important;
    }
  }
</style>
