<template>
  <div
    class="input-field col s12 autocomplete-parent"
    :class="{
      'autocomplete-parent--APV_REG_REDESIGN': true,
      'disabled': shouldDisableAutocomplete
    }"
    @click="onClickHandler">
    <input
      id="autocomplete-input"
      v-model="model"
      type="text"
      class="autocomplete"
      :class="{
        'has-value': model
      }"
      autocomplete="test"
      :disabled="shouldDisableAutocomplete"
      @focus="onInputFocus"
    >
    <label
      for="autocomplete-input"
      :class="{
        'active': model
      }">{{ label }}</label>
    <slot name="errors" />
    <i class="material-icons">expand_more</i>
  </div>
</template>

<script>
export default {
  props: [
    'label',
    'options',
    'value',
    'disabled',
    'forceMobile',
    'disableDefaultAutofocus'
  ],
  data: () => ({
    model: null,
    shouldDisableAutocomplete: false,
    bottomSheetInstance: null
  }),
  computed: {
    isMobile () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      }

      return false
    }
  },
  watch: {
    model (newval) {
      const value = $.isNumeric(newval)
        ? parseInt(newval)
        : newval

      const idValue = this.options?.find(opt => opt?.name === value)
        ? this.options?.find(opt => opt?.name === value).id
        : null

      this.$emit('input', idValue)
      this.$emit('change', idValue)
    },
    value (newval) {
      if (newval > 0) {
        this.model = this.options?.find(opt => opt?.id === newval)?.name
      }
    },
    options () {
      if (!this.options || this.options.length === 0) {
        this.shouldDisableAutocomplete = true
      } else {
        this.shouldDisableAutocomplete = this.disabled
      }

      if (!this.shouldDisableAutocomplete) {
        if (this.bottomSheetInstance) {
          // Destroy and recreate bottom sheet once options are loaded
          this.bottomSheetInstance.$destroy()
          document.getElementById('app').removeChild(this.bottomSheetInstance.$el)
        }
        this.createBottomSheetAutocomplete()
      }
    }
  },
  mounted () {
    this.initializeSelect()
  },
  methods: {
    onInputFocus () {
      this.$emit('onInputFocus')
    },
    onClickHandler () {
      if (this.isMobile || this.forceMobile) {
        this.setBottomSheetVisibility(true)
      }
    },
    initializeSelect () {
      this.shouldDisableAutocomplete = this.disabled ?? false

      // Don't initialize if mobile and not forceMobile
      if (!this.isMobile || !this.forceMobile) {
        return
      }

      this.createBottomSheetAutocomplete()
    },
    createBottomSheetAutocomplete () {
      const BottomSheetAutocomplete = Vue.component('md-bottomsheet-autocomplete')
      const ExtendedBottomSheetAutocomplete = Vue.extend(BottomSheetAutocomplete)
      const options = this.options ? this.options?.map(option => ({ [option?.name]: option?.name })) : []

      const BottomSheetAutocompleteWithProps = new ExtendedBottomSheetAutocomplete({
        propsData: {
          header: this.label,
          options
        }
      })
      BottomSheetAutocompleteWithProps.$mount()

      document.getElementById('app').appendChild(BottomSheetAutocompleteWithProps.$el)

      this.bottomSheetInstance = BottomSheetAutocompleteWithProps
      this.setBottomSheetVisibility(false)

      // On Select
      BottomSheetAutocompleteWithProps.$on('onSelect', (val) => {
        this.model = val
        this.setBottomSheetVisibility(false)
      })

      // On Close
      BottomSheetAutocompleteWithProps.$on('onClose', () => {
        this.setBottomSheetVisibility(false)
      })
    },
    setBottomSheetVisibility (display) {
      if (this.bottomSheetInstance) {
        if (display === true) {
          this.bottomSheetInstance.$el.classList.add('element--visible')
          if (this.disableDefaultAutofocus) {
            this.bottomSheetInstance.$refs['btm-sht-autocomplete-input'].clearInput()
          } else {
            this.bottomSheetInstance.$refs['btm-sht-autocomplete-input'].clearAndFocusInput()
          }
        } else {
          this.bottomSheetInstance.$el.classList.remove('element--visible')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$input-border-disabled-color: #CED6E0 !default;

.autocomplete-parent.autocomplete-parent--APV_REG_REDESIGN {
  #autocomplete-input {
    border: 1px solid #2F3740;
    border-radius: 12px;
    box-sizing: border-box;
    border: 1px solid #CED6E0;
    border-radius: 12px;
    color: #52575B;
    font-size: 16px;
    font-family: 'Proxima Nova Semibold';
    height: 68px;
    padding-top: 0;
    padding-left: 16px;
    width: 100%;

    &:focus:not([readonly]) {
      border: 2px solid #2F3740;
    }

    &.has-value:not([readonly]) {
    border: 1px solid #2F3740;
    color: #2F3740;
  }

    &.has-value:not([readonly]) + label.active {
      color: #52575B;
    }

    &:focus:not([readonly]) + label.active {
      color: #2F3740;
    }

    & + label {
      color: #52575B;
      top: 12px;
      left: 16px;
      font-family: 'Proxima Nova Semibold';
      font-size: 16px;
    }

    & + label.active {
      height: 16px;
      top: -10px;
      transform: translate(0px, 0px) scale(1);
      left: 11px; // Deduct the 5px padding from the minimized label
      color: #52575B;
      background: #FFFFFF;
      padding-left: 5px;
      padding-right: 5px;
      font-family: 'Proxima Nova Medium';
      font-size: 12px;
    }

    &:disabled,
    &[readonly] {
      border: 1px solid $input-border-disabled-color;
      color: #CED6E0;
    }
  }

  i.material-icons {
    position: absolute;
    top: 24px;
    right: 16px;
    color: #78838F;
  }

  &.disabled {
    label {
      color: #CED6E0 !important;
    }
    i.material-icons {
      color: #CED6E0 !important
    }
  }

}

</style>
