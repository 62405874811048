import { mapGetters } from 'vuex'
const AuthProvider = global?.$h?.getProvider('AuthProvider')

export default {
  computed: {
    ...mapGetters({
      bypassBillingDetailsCheck: 'bypassBillingDetailsCheck',
      hasIDApproved: 'billing/hasIDApproved',
      hasIDRejected: 'billing/hasIDRejected',
      hasBillingDetails: 'billing/hasBillingDetails',
      hasIDSubmitted: 'billing/hasIDSubmitted',
      isIDVerificationPending: 'billing/isIDVerificationPending',
      user: 'getUser'
    }),
    isIDVerificationRequested () {
      if (this.user) {
        return this.user._casting &&
               this.user._casting.id_request !== null &&
               this.user._casting.id_request !== undefined
      }
      return false
    },
    canShowNeedVerification () {
      return !this.bypassBillingDetailsCheck &&
             !this.hasIDApproved &&
             !this.isIDVerificationPending &&
             !this.hasIDSubmitted
    }
  },
  methods: {
    logoutAndRedirectToLogin () {
      AuthProvider.logout(() => {
        AuthProvider.setAuthorizationHeader()
        this.$store.commit('availabilities/reset')
        this.$store.commit('billing/reset')
        this.$store.commit('events/RESET')
        this.$router.push({ name: 'login' })
      })
    }
  }
}
