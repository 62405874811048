<template>
  <div v-if="VIP_PHOTOS_A2_2416" id="empty-event event-tips">
    <div v-for="(n, idx) in tipsVipPhotos2416" :key="idx" class="card">
      <div class="card-content left-align">
        <div class="card-title"><strong>{{ getTitle(n, idx) }}</strong></div>
        <div class="card-sub-title">{{ getSubtitle(n) }}</div>
        <div class="description" v-html="getDescription(n)" /><br>
        <div class="right-align">
          <a v-if="n === 9" href="#" class="waves-effect waves-dark padding-sm" @click.prevent="copyToClipboard(user.profiles._profile_url)">
            <strong>{{ getLinkLabel(n) }}</strong>
          </a>
          <vlink v-else class="waves-effect waves-dark padding-sm" :to="getLink(n)">
            <strong>{{ getLinkLabel(n) }}</strong>
          </vlink>
        </div>
      </div>
    </div>
  </div>

  <div v-else id="empty-event event-tips">
    <div v-for="(n, idx) in tips" :key="idx" class="card">
      <div class="card-content left-align">
        <div class="card-title"><strong>{{ getTitle(n, idx) }}</strong></div>
        <div class="card-sub-title">{{ getSubtitle(n) }}</div>
        <div class="description" v-html="getDescription(n)" /><br>
        <div class="right-align">
          <a v-if="n === 9" href="#" class="waves-effect waves-dark padding-sm" @click.prevent="copyToClipboard(user.profiles._profile_url)">
            <strong>{{ getLinkLabel(n) }}</strong>
          </a>
          <vlink v-else class="waves-effect waves-dark padding-sm" :to="getLink(n)">
            <strong>{{ getLinkLabel(n) }}</strong>
          </vlink>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import privatePlacePromoMixin from '@/features/private-place/mixins/promo.js'

const MIN_LIVE_PHOTOS = 4
const TIPS = {
  AVAILABILITY: 1,
  BETTER_PHOTOS: 2,
  MORE_PHOTOS: 3,
  CONNECT: 4,
  INTERVIEW: 5,
  SUPPORT: 6,
  VIP_PHOTOS: 7,
  BOOKING: 8,
  INSTA: 9
}

export default {
  mixins: [privatePlacePromoMixin],
  computed: {
    ...mapGetters({
      photos: 'getLivePhotos',
      user: 'getUser'
    }),
    VIP_PHOTOS_A2_2416 () {
      return this.$store.getters['featureflag/isEnabled']('VIP_PHOTOS_A2_2416')
    },
    livePhotos () {
      return this.photos.filter(p => p && p.id !== 0)
    },
    photoCount () {
      return this.livePhotos.length
    },
    tips () {
      if (this.livePhotos.length < MIN_LIVE_PHOTOS && !this.shouldDisplayConnectPromo) {
        return [
          TIPS.AVAILABILITY,
          TIPS.BETTER_PHOTOS,
          TIPS.MORE_PHOTOS,
          TIPS.INTERVIEW,
          TIPS.SUPPORT,
          TIPS.INSTA
        ]
      } else if (this.livePhotos.length < MIN_LIVE_PHOTOS && this.shouldDisplayConnectPromo) {
        return [
          TIPS.AVAILABILITY,
          TIPS.BETTER_PHOTOS,
          TIPS.MORE_PHOTOS,
          TIPS.CONNECT,
          TIPS.INTERVIEW,
          TIPS.SUPPORT,
          TIPS.INSTA
        ]
      } else if (this.livePhotos.length > MIN_LIVE_PHOTOS && this.shouldDisplayConnectPromo) {
        return [
          TIPS.AVAILABILITY,
          TIPS.BETTER_PHOTOS,
          TIPS.CONNECT,
          TIPS.INTERVIEW,
          TIPS.SUPPORT,
          TIPS.INSTA
        ]
      } else {
        return [
          TIPS.AVAILABILITY,
          TIPS.BETTER_PHOTOS,
          TIPS.INTERVIEW,
          TIPS.SUPPORT,
          TIPS.INSTA
        ]
      }
    },
    tipsVipPhotos2416 () {
      const tips = []

      tips[0] = TIPS.AVAILABILITY
      tips[1] = TIPS.BETTER_PHOTOS
      tips[2] = TIPS.INTERVIEW
      tips[3] = TIPS.SUPPORT

      if (!this.isVipPhotosEnabled) {
        tips[4] = TIPS.VIP_PHOTOS
      }

      tips.push(TIPS.INSTA)

      if (this.livePhotos.length < MIN_LIVE_PHOTOS && !this.shouldDisplayConnectPromo) {
        tips.splice(2, 0, TIPS.MORE_PHOTOS)
      }
      if (this.livePhotos.length < MIN_LIVE_PHOTOS && this.shouldDisplayConnectPromo) {
        tips.splice(2, 0, TIPS.MORE_PHOTOS)
        tips.splice(3, 0, TIPS.CONNECT)
      }
      if (this.livePhotos.length > MIN_LIVE_PHOTOS && this.shouldDisplayConnectPromo) {
        tips.splice(2, 0, TIPS.CONNECT)
      }

      return tips
    }
  },
  methods: {
    getTitle (tip, idx) {
      if (tip === 4) {
        return this.$translate(`events.empty_event.tip_${tip}.new_title`, { tip_number: idx + 1 })
      } else {
        return this.$translate(`events.empty_event.tip_${tip}.title`, { tip_number: idx + 1 })
      }
    },
    getSubtitle (tip) {
      if (tip === 2 || tip === 4) {
        return this.$translate(`events.empty_event.tip_${tip}.new_sub_title`)
      } else {
        return this.$translate(`events.empty_event.tip_${tip}.sub_title`)
      }
    },
    getDescription (tip) {
      if (tip === 2 || tip === 3 || tip === 4) {
        return this.$translate(`events.empty_event.tip_${tip}.new_description`, { photo_count: this.photoCount })
      } else {
        return this.$translate(`events.empty_event.tip_${tip}.description`)
      }
    },
    getLink (tip) {
      if (tip === 4) {
        return { name: this.$translate(`events.empty_event.tip_${tip}.new_route_name`) }
      } else {
        return { name: this.$translate(`events.empty_event.tip_${tip}.route_name`) }
      }
    },
    getLinkLabel (tip) {
      return this.$translate(`events.empty_event.tip_${tip}.route_label`)
    },
    copyToClipboard (url) {
      navigator.clipboard.writeText(url)
      this.$notifications.toast(this.$translate('app.link_copied_to_clipboard'))
    }
  }
}
</script>
