<template>
  <div id="news-list" class="margin-bottom">
    <div class="section-label">
      <img src="/img/dashboard/dashboard-features-icon.svg" alt="tips and infos icon">
      <h2>{{ $translate('news.title') }}</h2>
    </div>
    <div v-for="(news, i) in filteredItems" :key="i">
      <krypton-connect-promo-card
        v-if="news.type == 'promotional' && news.item === 'news_promo'"
        :item="news"
      />
      <private-place-promo-card
        v-if="news.type == 'promotional' && news.item === 'news_private_place'"
        :item="news"
      />
      <multi-location-promo-card
        v-if="news.type === 'promotional' && news.item === 'news_multi_location'"
        :item="news"
      />
      <super-provider-promo-card
        v-if="news.type === 'promotional' &&
          news.item === 'news_super_provider' &&
          user.settings.is_super_provider_promo_seen === 0 &&
          !isCandidateProfile
        "
        :item="news"
      />
      <news-list-item
        v-if="news.type == 'news'"
        :news="news"
        :disabled="news.disabled"
      />
    </div>
    <div v-if="isEmptyList" class="card-panel">
      <span class="grey-text text-darken-1">{{ $translate('dashboard.updates.no_update') }}</span>
    </div>
  </div>
</template>

<script>
import NewsListItem from './NewsListItem.vue'
import NewsListController from './NewsList.controller'

import SuperProviderPromoCard from '@/features/super-provider/components/SuperProviderPromoCard.vue'

export default {
  components: {
    NewsListItem,
    SuperProviderPromoCard
  },
  mixins: [NewsListController]
}
</script>

<style lang="scss" scoped>
.margin-bottom {
  margin-bottom: 96px !important;
}
</style>
