var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { attrs: { id: "super-provider-landing-page__header" } }, [
      _c(
        "div",
        { attrs: { id: "super-provider-landing-page__header-wrapper" } },
        [
          _c("md-faq-navbar", { attrs: { "route-name": "faq" } }),
          _vm._v(" "),
          _c("h1", { domProps: { textContent: _vm._s(_vm.headerTitle) } }),
          _vm._v(" "),
          _c("img", { attrs: { src: "/img/super-provider/gruppe-4570.svg" } })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "super-provider-landing-page__content-section super-provider-landing-page__content-section--tagline"
      },
      [
        _c("p", { domProps: { textContent: _vm._s(_vm.headerTagline) } }),
        _vm._v(" "),
        _c("img", {
          attrs: { src: "/img/super-provider/tagline-header-img.svg" }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }