var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", [
    _c("h2", { staticClass: "title" }, [
      _vm._v(
        _vm._s(_vm.$translate("onboarding.greeting") + " " + _vm.name) + "!"
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }