/* eslint-disable no-prototype-builtins */
import { CANCELLATION_REQUEST_SENT } from '../constants/global'
import ApiProvider from '../providers/ApiProvider'

const LocaleProvider = $h.getProvider('LocaleProvider')

const APP_LOCALE = LocaleProvider.getLocale()
const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const DURATION_FEES = {
  2: { tier_0: 350, tier_1: 450, tier_2: 550, tier_3: 650 },
  3: { tier_1: 600, tier_2: 750, tier_3: 900 },
  4: { tier_0: 650, tier_1: 750, tier_2: 950, tier_3: 1100 },
  5: { tier_1: 850, tier_2: 1100, tier_3: 1250 },
  6: { tier_0: 800, tier_1: 950, tier_2: 1200, tier_3: 1400 },
  7: { tier_1: 1050, tier_2: 1300, tier_3: 1500 },
  8: { tier_1: 1150, tier_2: 1400, tier_3: 1600 },
  9: { tier_1: 1200, tier_2: 1500, tier_3: 1700 },
  10: { tier_1: 1250, tier_2: 1600, tier_3: 1800 },
  11: { tier_1: 1300, tier_2: 1650, tier_3: 1900 },
  12: { tier_0: 1150, tier_1: 1350, tier_2: 1700, tier_3: 2000 },
  13: { tier_1: 1450, tier_2: 1800, tier_3: 2100 },
  14: { tier_1: 1500, tier_2: 1900, tier_3: 2200 },
  15: { tier_1: 1550, tier_2: 1950, tier_3: 2300 },
  16: { tier_1: 1600, tier_2: 2000, tier_3: 2400 },
  17: { tier_1: 1650, tier_2: 2050, tier_3: 2450 },
  18: { tier_1: 1700, tier_2: 2100, tier_3: 2500 },
  19: { tier_1: 1750, tier_2: 2150, tier_3: 2550 },
  20: { tier_1: 1800, tier_2: 2200, tier_3: 2600 },
  21: { tier_1: 1850, tier_2: 2250, tier_3: 2650 },
  22: { tier_1: 1900, tier_2: 2300, tier_3: 2700 },
  23: { tier_1: 1950, tier_2: 2350, tier_3: 2750 },
  24: { tier_0: 1750, tier_1: 2000, tier_2: 2400, tier_3: 2800 },
  25: { tier_1: 2050, tier_2: 2450, tier_3: 2850 },
  26: { tier_1: 2100, tier_2: 2500, tier_3: 2900 },
  27: { tier_1: 2150, tier_2: 2550, tier_3: 2950 },
  28: { tier_1: 2200, tier_2: 2600, tier_3: 3000 },
  29: { tier_1: 2250, tier_2: 2650, tier_3: 3050 },
  30: { tier_1: 2300, tier_2: 2700, tier_3: 3100 },
  31: { tier_1: 2350, tier_2: 2750, tier_3: 3150 },
  32: { tier_1: 2400, tier_2: 2800, tier_3: 3200 },
  33: { tier_1: 2450, tier_2: 2850, tier_3: 3250 },
  34: { tier_1: 2500, tier_2: 2900, tier_3: 3300 },
  35: { tier_1: 2550, tier_2: 2950, tier_3: 3350 },
  36: { tier_1: 2600, tier_2: 3000, tier_3: 3400 }
}

/**
 * Helper functions
 */

/**
 * Fetch item index in list based on the event id.
 *
 * @param {Array} list - The list of events.
 * @param {Number} id - The event id.
 *
 * @returns {Number}
*/
function getIdx (list, id) {
  return list.findIndex(i => i.id === id)
}

/**
 * Sort events based on meeting start vlaue in ascending order.
 *
 * @param {Object} a - The event object.
 * @param {Object} b - The event object.
 *
 * @returns {Boolean}
*/
function ascendingOrder (a, b) {
  return a._meeting_start_at.valueOf() - b._meeting_start_at.valueOf()
}

/**
 * Filter list of events by pending status.
 *
 * @param {Object} e - The event object.
 *
 * @returns {Boolean}
*/
function filterPending (e) {
  return e._event_status === EVENT_STATUS.PENDING
}

/**
 * Filter list by events that is/are in confirmed ready and pre-meeting.
 *
 * @param {Object} e - The event object.
 *
 * @returns {Boolean}
*/
function filterUpcoming (e) {
  return e._meeting_start_in_seconds <= $h.hrToSeconds(12) &&
         e._event_status === EVENT_STATUS.CONFIRMED_READY &&
         e.status === TICKET_STATUS.PREMEETING
}

/**
 * Filter list by events that is/are in confirmed ready and pre-meeting.
 *
 * @param {Object} e - The event object.
 *
 * @returns {Boolean}
*/
function filterReactivatedExpiredEvent (e) {
  return (
    e.status === TICKET_STATUS.CANCELLED &&
    e._event_status === EVENT_STATUS.EXPIRED &&
    e.reactivation_status === REACTIVATION_STATUS.REACTIVATED
  )
}

/**
 * Array reduce callback for return the first value in the array.
 *
 * @param {Any} acc - Accumulated value. default: null
 * @param {Any} cur - Current value during traversal
 * @param {Number} idx - Index value of `cur` in the array.
 *
 * @returns {Object}
*/
function getFirstValue (acc, cur, idx) {
  if (idx === 0) {
    return cur
  }

  return acc
}

/**
 * Filter events from list where it has ticket updates.
 *
 * @param {Object} e - The event object.
 *
 * @returns {Boolean}
*/
function filterHasEventUpdates (e) {
  return e.event_seen === 0 || e._unread_message_count > 0
}

/**
 * Filter events from list where cancellation request has been enabled.
 *
 * @param {Object} e - The event object.
 *
 * @returns {Boolean}
*/
function filterCancellationRequests (e) {
  return typeof e._cancellation_expiration_in_seconds === 'number' &&
                e.is_cancellation_request_sent === CANCELLATION_REQUEST_SENT.PENDING &&
                (
                  e.status === TICKET_STATUS.CONFIRMED ||
                  e.status === TICKET_STATUS.PREMEETING
                )
}

/// /////////

/**
 * Vuex State
 */
const state = {
  selectedEvent: null,
  activeEvent: null,
  isFetching: false,
  clientDelay: 0,
  events: [],
  messages: []
}

/**
 * State getters
 */
const getters = {
  /**
   * Fetch events list.
   *
   * @returns {Array}
   */
  get (state) {
    return state.events
  },

  /**
   * FIXME: found a probable bug in unit testing where sort
   * directly mutates state causing endless loop computed property updates
   *
   * Fetch events which is sorted by `_meeting_start_at`.
   *
   * @returns {Array}
   */
  getSortedEvents (state) {
    return state.events.sort(ascendingOrder)
  },

  /**
   * Get pending events on the list.
   *
   * @returns {Array}
   */
  getPendingEvents (state, getters) {
    return getters.getSortedEvents.filter(filterPending)
  },

  /**
   * Get upcoming events on the list.
   *
   * @returns {Array}
   */
  getUpcomingEvents (state, getters) {
    return getters.getSortedEvents.filter(filterUpcoming)
  },

  /**
   * Get the first pending event
   *
   * @returns {Object | Null}
   */
  getPendingEvent (state, getters) {
    return getters.getPendingEvents
      .reduce(getFirstValue, null)
  },

  /**
   * Get the first upcoming event
   *
   * @returns {Object | Null}
   */
  getUpcomingEvent (state, getters) {
    return getters.getUpcomingEvents
      .reduce(getFirstValue, null)
  },

  /**
   * Get the first upcoming event
   *
   * @returns {Object | Null}
   */
  getReactivatedExpiredEvent (state, getters) {
    return getters.getSortedEvents
      .filter(filterReactivatedExpiredEvent)
      .reduce(getFirstValue, null)
  },

  /**
   * Get current selected event (UI).
   *
   * @returns {Object}
   */
  getSelected (state) {
    return state.selectedEvent
  },

  /**
   * Get currently running event in background.
   *
   * @returns {Object}
   */
  getActiveEvent (state) {
    return state.activeEvent
  },

  /**
   * Get event meeting end of the current active event (getActiveEvent).
   *
   * @returns {Number}
   */
  getActiveEventMeetingEnd (state) {
    if (state.activeEvent) {
      const duration = moment.duration({
        hours:
          state.activeEvent._meeting_duration + state.activeEvent.extension
      })

      const meetingEnd = state.activeEvent._meeting_start_at.clone()

      meetingEnd.add(duration)

      return meetingEnd
    }
    return null
  },

  /**
   * Get calculated total duration of the current active event (getActiveEvent).
   *
   * Total duration = meeting duration + meeting extension
   *
   * @returns {Number}
   */
  getActiveTotalDuration (state) {
    if (state.activeEvent) {
      return state.activeEvent._meeting_duration + state.activeEvent.extension
    }

    return 0
  },

  /**
   * Get `meeting_start_at` value of the current active event (getActiveEvent).
   *
   * @returns {Object}
   */
  getActiveEventMeetingStart (state) {
    if (state.activeEvent) {
      return state.activeEvent._meeting_start_at.clone()
    }

    return null
  },

  /**
   * Get `extension` value of the current active event (getActiveEvent).
   *
   * @returns {Number}
   */
  getActiveEventTotalExtension (state) {
    if (state.activeEvent) {
      return state.activeEvent.extension
    }

    return 0
  },

  /**
   * Get the check-in duration of the current active event (getActiveEvent).
   *
   * @returns {Number|Null}
   */
  getActiveEventTimeElapsed (state) {
    if (state.activeEvent) {
      return state.activeEvent._check_in_seconds
    }

    return null
  },

  /**
   * Get fetching status of the event.
   *
   * @returns {Boolean}
   */
  getIsFetching (state) {
    return state.isFetching
  },

  /**
   * Get `check_in_value` of the event.
   *
   * @deprecated
   *
   * @returns {Number|Null}
   */
  getActiveEventcheckinTime (state) {
    if (state.activeEvent) {
      return state.activeEvent._provider_check_in
    }

    return null
  },

  /**
   * Get messages for `where-is-he` module.
   *
   * @returns {Array}
   */
  getMessages (state) {
    return state.messages
  },

  /**
   * Check if there are event(s) detail/status updates.
   *
   * @returns {Boolean}
   */
  getEventsWithUpdates (state) {
    return state.events.filter(filterHasEventUpdates)
  },

  /**
   * Check if there are event(s) detail/status updates.
   *
   * @returns {Boolean}
   */
  hasUpdates (state, getters) {
    const count = getters.getEventsWithUpdates.length

    return count > 0
  },

  /**
   * Get the list of events where cancellation request is enabled.
   *
   * @returns {Array}
   */
  getCancellationRequestEvents (state, getters) {
    return getters.getSortedEvents
      .filter(filterCancellationRequests)
  },

  /**
   * Get the first event that has the cancellation request enabled.
   *
   * @returns {Array}
   */
  getCancellationRequestEvent (state, getters) {
    return getters.getCancellationRequestEvents
      .reduce(getFirstValue, null)
  }
}

/**
 * State mutations
 */
const mutations = {
  SET (state, events) {
    const parsedEvt = events.map(e => {
      let checkin = moment()

      if (e.provider_check_in !== null) {
        checkin = moment(e.provider_check_in, DATETIME_FORMAT).locale(
          APP_LOCALE
        )
      }

      const event_seen =
        e._event_status === EVENT_STATUS.CONFIRMED_PREPARING ? 1 : e.event_seen

      // Since the logic check for pending event is the same with
      // pending re-activated event, we need to transfer the value
      // from `_reactivation_expiration_in_seconds` to `_expiration_in_seconds`
      // to avoid breaking the app.
      if (
        e._expiration_in_seconds === null &&
        e._reactivation_expiration_in_seconds > 0
      ) {
        e._expiration_in_seconds = e._reactivation_expiration_in_seconds
      }

      return {
        // copy all values as-is
        ...e,
        // Replace event_seen
        event_seen,
        // Create parsed date/time values for easy use throughout on the code.
        _event_expired_at: moment(e.event_expired_at, DATETIME_FORMAT).locale(
          APP_LOCALE
        ),
        _meeting_start_at: moment(e.meeting_start_at, DATETIME_FORMAT).locale(
          APP_LOCALE
        ),
        _meeting_duration: e.meeting_duration,
        _provider_check_in: checkin,
        _prevState: null
      }
    })

    state.events = parsedEvt

    // Get existing active event
    const getActiveEvent = (acc, curr) => {
      if (curr.status === TICKET_STATUS.MEETING) {
        return curr
      }
      return acc
    }

    state.activeEvent = parsedEvt.reduce(getActiveEvent, null)
  },
  SET_SELECTED (state, evt) {
    state.selectedEvent = evt
  },
  SET_SELECTED_BY_ID (state, evtID) {
    const idx = state.events.findIndex(e => e.id === evtID)

    if (idx >= 0) {
      state.selectedEvent = state.events[idx]
    }
  },
  SET_ACTIVE (state, evt) {
    state.activeEvent = evt
  },
  CHECKIN (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx].provider_check_in = moment()
      state.events[idx]._provider_check_in = moment()
      state.events[idx]._meeting_start_in_seconds = 0
      state.events[idx].status = TICKET_STATUS.MEETING
    }
  },
  SET_FEEDBACK_SENT (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx]._feedback_metadata.show = 0
      state.events[idx]._feedback_metadata.expiration_in_seconds = 0
    }
  },
  CHECKOUT (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx].status = TICKET_STATUS.POST_MEETING
      state.events[idx]._feedback_metadata.show = 1
      state.events[
        idx
      ]._feedback_metadata.expiration_in_seconds = $h.minToSeconds(30)
    }
  },
  EVENT_SEEN (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx].event_seen = 1
    }
  },
  PENDING_EVENT_SEEN (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx].pending_seen = 1

      if (state.selectedEvent && state.selectedEvent.id === eventID) {
        state.selectedEvent.pending_seen = 1
      }
    }
  },
  EXPIRED_EVENT_SEEN (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx].is_expired_seen = 1

      if (state.selectedEvent && state.selectedEvent.id === eventID) {
        state.selectedEvent.is_expired_seen = 1
      }
    }
  },
  EXPIRED_REACTIVATION_EVENT_SEEN (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx].reactivation_failed_seen = 1

      if (state.selectedEvent && state.selectedEvent.id === eventID) {
        state.selectedEvent.reactivation_failed_seen = 1
      }
    }
  },
  SET_EVENT_SEEN (state, payload) {
    const idx = getIdx(state.events, payload.eventID)

    if (idx >= 0) {
      state.events[idx].event_seen = payload.eventSeen
    }
  },
  SET_EVENT_EXPIRED_SEEN (state, payload) {
    const eventID = payload.eventID
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx].is_expired_seen = payload.is_expired_seen

      if (state.selectedEvent && state.selectedEvent.id === eventID) {
        state.selectedEvent.is_expired_seen = payload.is_expired_seen
      }
    }
  },
  SET_REACTIVATED_EVENT_EXPIRED (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx].reactivation_status = REACTIVATION_STATUS.REACTIVATION_EXPIRED
      state.events[idx].reactivation_failed_seen = 0

      if (state.selectedEvent && state.selectedEvent.id === eventID) {
        state.selectedEvent.reactivation_status = REACTIVATION_STATUS.REACTIVATION_EXPIRED
        state.selectedEvent.reactivation_failed_seen = 0
      }
    }
  },
  EVENT_DETAILS_SEEN (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx]._updates.client_identifier = 0
      state.events[idx]._updates.custom_notes = 0
      state.events[idx]._updates.dress_code = 0
      state.events[idx]._updates.meeting_point = 0
    }
  },
  INCREMENT_MESSAGE_UNREAD (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      const unreadCount = state.events[idx]._unread_message_count
      state.events[idx]._unread_message_count = unreadCount + 1
    }
  },
  MESSAGES_SEEN (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx]._unread_message_count = 0
    }
  },
  /**
   * Add hour extension on the active event
   *
   * @param {Number} duration
   */
  ADD_EXTENSION (state, duration) {
    if (state.activeEvent) {
      state.activeEvent.extension += duration
    }
  },
  /**
   * Update event status.
   *
   * @param {Object} payload - Contains { id, newStatus }
   */
  UPDATE_EVENT_STATUS (state, payload) {
    const idx = getIdx(state.events, payload.id)

    if (idx >= 0) {
      state.events[idx]._event_status = payload.newStatus
      state.events[idx].event_seen = 0

      if (state.selectedEvent && state.selectedEvent.id === payload.id) {
        state.selectedEvent._event_status = payload.newStatus
      }
    }
  },
  /**
   * Update ticket status.
   *
   * @param {Object} payload - Contains { id, newStatus }
   */
  UPDATE_TICKET_STATUS (state, payload) {
    const idx = getIdx(state.events, payload.id)

    if (idx >= 0) {
      state.events[idx].status = payload.newStatus
      state.events[idx].event_seen = 0

      if (state.selectedEvent && state.selectedEvent.id === payload.id) {
        state.selectedEvent.status = payload.newStatus
      }
    }
  },
  /**
   * Add or update an event.
   *
   * @param {Object} evt - The event data
   */
  ADD_OR_UPDATE (state, evt) {
    if (evt.status === TICKET_STATUS.PREOPEN) return // Pre-open events should not appear in APV

    const idx = getIdx(state.events, evt.id)
    const newEvt = Object.assign(
      {
        _event_expired_at: moment(evt.event_expired_at, DATETIME_FORMAT).locale(
          APP_LOCALE
        ),
        _meeting_start_at: moment(evt.meeting_start_at, DATETIME_FORMAT).locale(
          APP_LOCALE
        ),
        _meeting_duration: evt.meeting_duration
      },
      evt
    )

    if (idx >= 0) {
      if (evt.status === TICKET_STATUS.PREOPEN) {
        state.events.splice(idx, 1)
        return
      }

      // Copy all values from its properties
      for (const k in newEvt) {
        if (newEvt.hasOwnProperty(k)) {
          state.events[idx][k] = newEvt[k]
        }
      }

      if (newEvt._event_status >= EVENT_STATUS.CONFIRMED_READY) {
        state.events[idx].event_seen = newEvt.event_seen
      }
      if (state.events[idx]._feedback_metadata !== undefined) {
        for (const k in newEvt._feedback_metadata) {
          if (state.events[idx]._feedback_metadata.hasOwnProperty(k)) {
            state.events[idx]._feedback_metadata[k] =
              newEvt._feedback_metadata[k]
          }
        }
      }

      if (state.selectedEvent && state.selectedEvent.id === evt.id) {
        for (const k in newEvt) {
          if (newEvt.hasOwnProperty(k)) {
            state.selectedEvent[k] = newEvt[k]
          }
        }

        if (newEvt._event_status >= EVENT_STATUS.CONFIRMED_READY) {
          state.selectedEvent.event_seen = newEvt.event_seen
        }
        if (state.selectedEvent._feedback_metadata !== undefined) {
          for (const k in newEvt._feedback_metadata) {
            if (newEvt._feedback_metadata.hasOwnProperty(k)) {
              state.selectedEvent._feedback_metadata[k] =
                newEvt._feedback_metadata[k]
            }
          }
        }
      }
    } else {
      state.events.push(newEvt)
    }
  },
  /**
   * Delete event by ID.
   *
   * @param {Number} id - Event ID
   */
  DELETE (state, id) {
    state.events.splice(getIdx(state.events, id), 1)
  },
  RESET (state) {
    state.selectedEvent = null
    state.activeEvent = null
    state.messages.length = 0
    state.events.length = 0
  },
  SET_IS_FETCHING (state, isFetching) {
    state.isFetching = isFetching
  },
  SET_MESSAGES (state, messages) {
    state.messages = messages.map(m => {
      return {
        ...m, // Copy value as is

        _created_at: moment(m.created_at) // Create a wrapped value for created_at
      }
    })
  },
  PUSH_MESSAGE (state, message) {
    const idx = getIdx(state.messages, message.id)

    if (idx >= 0) {
      state.messages[idx].seen = message.seen
    } else {
      // Only push when there's a selected event
      if (state.selectedEvent) {
        if (state.selectedEvent.id === message.request_id) {
          state.messages.push({
            ...message, // Copy value as is
            seen: null,

            _created_at: moment(message.created_at) // Create a wrapped value for created_at
          })
        }
      }
    }
  },
  UPDATE_LAST_MESSAGE_ID (state, messageID) {
    const idx = getIdx(state.messages, -1)

    if (idx >= 0) {
      state.messages[idx].id = messageID
    }
  },
  SEEN_LAST_MESSAGE (state) {
    const PROVIDER_GROUP_ID = 2

    for (let i = 0; i < state.messages.length; ++i) {
      if (state.messages[i].group_id === PROVIDER_GROUP_ID) {
        state.messages[i].seen = 1
      }
    }
  },
  SET_CLIENT_DELAY (state, payload) {
    const idx = getIdx(state.events, payload.id)

    if (idx >= 0) {
      state.events[idx].client_delay = Number(payload.delay)

      if (state.selectedEvent && state.selectedEvent.id === payload.id) {
        state.selectedEvent.client_delay = Number(payload.delay)
      }
    }
  },
  SET_CLIENT_DELAY_SEEN (state, payload) {
    const idx = getIdx(state.events, payload.id)

    if (idx >= 0) {
      state.events[idx].client_delay_seen = payload.seen

      if (state.selectedEvent && state.selectedEvent.id === payload.id) {
        state.selectedEvent.client_delay_seen = payload.seen
      }
    }
  },
  DECREMENT_MEETING_START_SECONDS (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      const meetingStartSeconds = state.events[idx]._meeting_start_in_seconds

      if (meetingStartSeconds !== null) {
        state.events[idx]._meeting_start_in_seconds = meetingStartSeconds - 1

        if (state.selectedEvent && state.selectedEvent.id === eventID) {
          state.selectedEvent._meeting_start_in_seconds = meetingStartSeconds - 1
        }
      }
    }
  },
  DECREMENT_EXPIRATION_SECONDS (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      const expirationInSeconds = state.events[idx]._expiration_in_seconds

      if (expirationInSeconds !== null) {
        state.events[idx]._expiration_in_seconds = expirationInSeconds - 1

        if (state.selectedEvent && state.selectedEvent.id === eventID) {
          state.selectedEvent._expiration_in_seconds = expirationInSeconds - 1
        }
      }
    }
  },
  DECREMENT_CHECKIN_SECONDS (state, eventID) {
    if (state.activeEvent && state.activeEvent.id === eventID) {
      const checkInSeconds = state.activeEvent._check_in_seconds
      if (checkInSeconds <= 0) {
        state.activeEvent._check_in_seconds = checkInSeconds - 1
      } else {
        state.activeEvent._check_in_seconds = 0
      }
    }
  },
  DECREMENT_FEEDBACK_EXPIRATION_SECONDS (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      if (state.events[idx]._feedback_metadata !== undefined) {
        const expirationInSeconds =
          state.events[idx]._feedback_metadata.expiration_in_seconds

        if (expirationInSeconds !== null) {
          state.events[idx]._feedback_metadata.expiration_in_seconds =
            expirationInSeconds - 1
        }
        if (state.selectedEvent && state.selectedEvent.id === eventID) {
          state.selectedEvent._feedback_metadata.expiration_in_seconds =
            expirationInSeconds - 1
        }
      }
    }
  },
  DECREMENT_EVENT_CANCELLATION_SECONDS (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      const expirationInSeconds = state.events[idx]._cancellation_expiration_in_seconds

      if (expirationInSeconds !== null) {
        state.events[idx]._cancellation_expiration_in_seconds = expirationInSeconds - 1

        if (state.selectedEvent && state.selectedEvent.id === eventID) {
          state.selectedEvent._cancellation_expiration_in_seconds = expirationInSeconds - 1
        }
      }
    }
  },
  UPDATE_EVENT_DELAY (state, payload) {
    const idx = getIdx(state.events, payload.id)

    if (idx >= 0) {
      state.events[idx].provider_delay = payload.delay

      if (state.selectedEvent && state.selectedEvent.id === payload.id) {
        state.selectedEvent.provider_delay = payload.delay
      }
    }
  },
  EVENT_CANCELLED (state, evt) {
    const idx = getIdx(state.events, evt.event_id)

    if (idx >= 0) {
      const evtCopy = Object.assign({}, state.events[idx])

      state.events[idx]._event_status = evt._event_status
      state.events[idx].status = TICKET_STATUS.CANCELLED
      state.events[idx]._prevState = evtCopy

      if (state.activeEvent !== null && state.activeEvent.id === evt.event_id) {
        state.activeEvent = null
      }

      // There are cases where cancel popup does not needed to be shown...
      if (
        evtCopy.status === TICKET_STATUS.MEETING ||
        evtCopy.status === TICKET_STATUS.POST_MEETING ||
        evt._event_status === EVENT_STATUS.CANCELLED_PROVIDER
      ) {
        state.events[idx].event_seen = 1
        state.events[idx].is_expired_seen = 1
      } else {
        state.events[idx].event_seen = 0
        state.events[idx].is_expired_seen = 0
      }
    }
  },
  EVENT_FORCE_CANCELLED (state, payload) {
    const idx = getIdx(state.events, payload.id)

    if (idx >= 0) {
      state.events[idx]._event_status = EVENT_STATUS.EXPIRED
      state.events[idx].status = TICKET_STATUS.CANCELLED
      state.events[idx].reactivation_status =
        REACTIVATION_STATUS.CANNOT_REACTIVATE
      state.events[idx].is_expired_seen = 0
      state.events[idx]._can_reactivate = 0
      state.events[idx].reactivation_failed_seen = 0
      state.events[idx]._meeting_start_in_seconds = null

      if (state.selectedEvent && state.selectedEvent.id === payload.id) {
        state.selectedEvent._event_status = EVENT_STATUS.EXPIRED
        state.selectedEvent.status = TICKET_STATUS.CANCELLED
        state.selectedEvent.reactivation_status =
          REACTIVATION_STATUS.CANNOT_REACTIVATE
        state.selectedEvent.is_expired_seen = 0
        state.selectedEvent._can_reactivate = 0
        state.selectedEvent.reactivation_failed_seen = 0
        state.selectedEvent._meeting_start_in_seconds = null
      }
    }
  },
  DECLINED_REACTIVATED_EVENT (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx]._event_status = EVENT_STATUS.EXPIRED
      state.events[idx].status = TICKET_STATUS.CANCELLED
      state.events[idx].reactivation_status =
        REACTIVATION_STATUS.REACTIVATION_EXPIRED
      state.events[idx]._meeting_start_in_seconds = 0
      state.events[idx].reactivation_failed_seen = 0
    }
  },
  SET_REACTIVATE_STATUS (state, payload) {
    const eventID = payload.id
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx].reactivation_status = payload.reactivation_status
      state.events[idx].reactivation_failed_seen = 0

      if (state.selectedEvent && state.selectedEvent.id === eventID) {
        state.selectedEvent.reactivation_status = payload.reactivation_status
        state.selectedEvent.reactivation_failed_seen = 0
      }
    }
  },
  SET_EVENT_EXPIRED (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx]._event_status = EVENT_STATUS.EXPIRED
      state.events[idx].status = TICKET_STATUS.CANCELLED

      if (state.events[idx]._meeting_start_in_seconds > $h.hrToSeconds(1)) {
        state.events[idx].reactivation_status =
          REACTIVATION_STATUS.CAN_REACTIVATE
        state.events[idx]._can_reactivate = 1
      } else {
        state.events[idx].reactivation_status =
          REACTIVATION_STATUS.REACTIVATION_EXPIRED
        state.events[idx]._can_reactivate = 0
      }
    }
  },
  SET_EVENT_REACTIVATE_CONFIRMED (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx]._event_status = EVENT_STATUS.CONFIRMED_PREPARING
      state.events[idx].status = TICKET_STATUS.CONFIRMED
      state.events[idx].reactivation_status = REACTIVATION_STATUS.CONFIRMED
    }
  },
  CLEAR_MESSAGES (state) {
    state.messages = []
  },
  INCREMENT_SELECTED_EVENT_SUPPORT_UNREAD (state) {
    if (state.selectedEvent) {
      if (state.selectedEvent._support) {
        state.selectedEvent._support.unread += 1
      }
    }
  },
  RESET_SELECTED_EVENT_SUPPORT_UNREAD (state) {
    if (state.selectedEvent) {
      if (state.selectedEvent._support) {
        state.selectedEvent._support.unread = 0
      }
    }
  },
  RESET_EVENT_CANCELLATION_REQUEST_COUNTDOWN (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx]._cancellation_expiration_in_seconds = -1

      if (state.selectedEvent && state.selectedEvent.id === eventID) {
        state.selectedEvent._cancellation_expiration_in_seconds = -1
      }
    }
  },
  SET_REQUEST_CANCELLATION_SEEN (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx].cancellation_request_seen = 1

      if (state.selectedEvent && state.selectedEvent.id === eventID) {
        state.selectedEvent.cancellation_request_seen = 1
      }
    }
  },
  SET_REQUEST_CANCELLATION_CONFIRM (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx].status = TICKET_STATUS.CANCELLED
      state.events[idx]._event_status = EVENT_STATUS.CANCELLED_PROVIDER
      state.events[idx]._can_reactivate = 0
      state.events[idx].event_seen = 0
    }
  },
  SET_REQUEST_CANCELLATION_ABORT (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx].is_cancellation_request_sent = CANCELLATION_REQUEST_SENT.DEFAULT
    }
  },
  FORCE_REQUEST_CANCELLATION_EXPIRED (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx].status = TICKET_STATUS.CANCELLED
      state.events[idx]._event_status = EVENT_STATUS.CANCELLED_PROVIDER
      state.events[idx]._can_reactivate = 0
      state.events[idx].event_seen = 0
      state.events[idx].cancellation_request_timeout_seen = 0
      state.events[idx]._cancellation_expiration_in_seconds = -1
      state.events[idx].is_cancellation_request_sent = CANCELLATION_REQUEST_SENT.CANCELLED
    }
  },
  REQUEST_CANCELLATION_EXPIRED_SEEN (state, eventID) {
    const idx = getIdx(state.events, eventID)

    if (idx >= 0) {
      state.events[idx].cancellation_request_timeout_seen = 1
    }
  }
}

/**
 * State actions
 */
const actions = {
  fetchEvents ({ commit }, eventID = null) {
    commit('SET_IS_FETCHING', true)

    return new Promise((resolve, reject) => {
      window.api
        .get({ url: ApiProvider.events(eventID) },
          (sucess) => {
            const events = sucess.data.data.data

            if (eventID === null) {
              commit('SET', events)
            }

            resolve(sucess.data.data)
          },
          (err) => {
            reject(err)
          },
          () => {
            commit('SET_IS_FETCHING', false)
          })
    })
  },

  // sync events api response to the PWA request cache
  syncEvents ({ commit }) {
    commit('SET_IS_FETCHING', true)

    return new Promise((resolve, reject) => {
      window.api
        .get({ url: ApiProvider.events() },
          (success) => {
            const events = success.data.data.data

            resolve(events)
          },
          (err) => {
            reject(err)
          },
          () => {
            commit('SET_IS_FETCHING', false)
          })
    })
  },

  /**
   * Update event ticket to check-in.
   */
  confirm ({ commit }, evt) {
    return new Promise((resolve, reject) => {
      window.api
        .put({ url: ApiProvider.approveEvent(evt.id), payload: evt.payload },
          (success) => { resolve(success.data) },
          (err) => {
            window.NotificationPlugin.toast(err.message, 5000)
            reject(err)
          })
    })
  },

  /**
   * Update event ticket to check-in.
   */
  decline ({ commit }, evt) {
    // Update store ticket status
    commit('UPDATE_EVENT_STATUS', {
      id: evt.id,
      newStatus: EVENT_STATUS.DECLINED
    })
    commit('UPDATE_TICKET_STATUS', {
      id: evt.id,
      newStatus: TICKET_STATUS.CANCELLED
    })

    return new Promise((resolve, reject) => {
      window.api
        .put({ url: ApiProvider.declineEvent(evt.id), payload: evt.payload },
          (success) => { resolve(success.data) },
          (err) => {
            window.NotificationPlugin.toast(err.message, 5000)
            reject(err)
          })
    })
  },

  /**
   * Update event ticket to check-in.
   */
  reactivate ({ commit }, evt) {
    return new Promise((resolve, reject) => {
      window.api
        .put({ url: ApiProvider.reactivateEvent(evt.id) },
          (success) => {
            const data = success.data.data
            const evt = {
              ...data,
              // To avoid breaking the app for expiration countdown,
              // transfer value from `_reactivation_expired_in_seconds` to `_expiration_in_seconds`
              _expiration_in_seconds: data._reactivation_expiration_in_seconds
            }

            commit('ADD_OR_UPDATE', evt)

            resolve(success.data)
          },
          (err) => {
            window.NotificationPlugin.toast(err.message, 5000)
            reject(err)
          })
    })
  },

  /**
   * Update event ticket to check-in.
   */
  declineReactivation ({ commit }, eventID) {
    return new Promise((resolve, reject) => {
      window.api
        .put({ url: ApiProvider.declineReactivation(eventID) },
          (success) => { resolve(success.data.data) },
          (err) => {
            window.NotificationPlugin.toast(err.message, 5000)
            reject(err)
          })
    })
  },

  /**
   * Update event ticket to check-in.
   */
  checkin ({ commit }, evt) {
    return new Promise((resolve, reject) => {
      window.api
        .put({ url: ApiProvider.buildEventsPath(evt.id, 'check-in') },
          (success) => {
            commit('SET_ACTIVE', evt)
            commit('CHECKIN', evt.id)
            resolve(success.data)
          },
          (err) => {
            reject(err.response)
          })
    })
  },

  /**
   * Update event ticket to check-out.
   */
  checkout ({ commit }, evt) {
    return new Promise((resolve, reject) => {
      window.api
        .put({ url: ApiProvider.buildEventsPath(evt.id, 'check-out') },
          (success) => {
            // window.NotificationPlugin.toast(res.data.message, 5000);

            commit('SET_ACTIVE', null)
            commit('CHECKOUT', evt.id)

            resolve(success.data)
          },
          (err) => {
            window.NotificationPlugin.toast(err.message, 5000)
            reject(err)
          })
    })
  },

  /**
   * Add extension to the Date.
   *
   * @param {Object} params - Contains { id, extension }
   */
  addExtension ({ commit }, params) {
    return new Promise((resolve, reject) => {
      const payload = {
        extension: params.extension
      }

      window.api
        .put({ url: ApiProvider.buildEventsPath(params.id, 'extension'), payload: payload },
          (success) => {
            window.NotificationPlugin.toast(success.data.message, 5000)
            resolve(success.data)
          },
          (err) => {
            window.NotificationPlugin.toast(err.message, 5000)
            reject(err)
          })
    })
  },

  /**
   * Send I am late notification
   *
   * @param {Object} params - Contains { id, extension }
   */
  late ({ commit }, params) {
    return new Promise((resolve, reject) => {
      const payload = {
        delay: params.delay
      }

      window.api
        .put({ url: ApiProvider.buildEventsPath(params.id, 'late'), payload: payload },
          (success) => {
            commit('UPDATE_EVENT_DELAY', params)
            resolve(success.data)
          },
          (err) => {
            window.NotificationPlugin.toast(err.message, 5000)
            reject(err)
          })
    })
  },

  /**
   * Send message for I am here.
   *
   * @param {Object} params - Contains { id, extension }
   */
  sendMessage ({ commit }, params) {
    return new Promise((resolve, reject) => {
      const payload = {
        message: params.message
      }

      commit('PUSH_MESSAGE', {
        id: -1, // Set temporary ID to be pushed in local state, we'll update this after we get response from API.
        created_at: moment().format(DATETIME_FORMAT),
        request_id: params.id,
        message: params.message,
        sent_by: params.userID,
        seen: null,
        group_id: 2,
        _message_sent_at_in_seconds: 0
      })

      window.api
        .put({ url: ApiProvider.buildEventsPath(params.id, 'where-is-he'), payload: payload },
          (success) => {
            commit('UPDATE_LAST_MESSAGE_ID', success.data.data.id)
            resolve(success.data)
          },
          (err) => {
            const message = err.response.data.data.message
              ? err.response.data.data.message[0]
              : err.response.data.message

            window.NotificationPlugin.toast(message, 5000)
            reject(err)
          })
    })
  },

  /**
   * Send feedback for Event Rate
   *
   * @param {Object} params - Contains { id, extension }
   */
  sendRateFeedback ({ commit }, params) {
    return new Promise((resolve, reject) => {
      const payload = {
        rating: params.rating,
        message: params.message,
        blacklist: params.blacklist
      }

      window.api
        .post({ url: ApiProvider.sendRateFeedback(params.id), payload: payload },
          (success) => {
            commit('DELETE', params.id)
            resolve(success.data)
          },
          (err) => {
            window.NotificationPlugin.toast(err.message, 5000)
            reject(err)
          })
    })
  },

  /**
   * Fetch message history
   *
   * @param {Object} params - Contains { id, extension }
   */
  fetchMessageHistory ({ commit }, eventID) {
    return new Promise((resolve, reject) => {
      window.api
        .get({ url: ApiProvider.fetchMessageHistory(eventID) },
          (success) => {
            commit('SET_MESSAGES', success.data.data)
            resolve(success.data.data)
          },
          (err) => {
            window.NotificationPlugin.toast(err.message, 5000)
            reject(err)
          })
    })
  },

  /**
   * Set message as seen.
   *
   * @param {Number} eventID
  */
  seenMessage ({ commit }, eventID) {
    return new Promise((resolve, reject) => {
      window.api
        .get({ url: ApiProvider.buildEventsPath(eventID, 'seen') },
          (success) => {
            commit('MESSAGES_SEEN', eventID)
            resolve(success.data)
          },
          (err) => {
            window.NotificationPlugin.toast(err.response.message, 5000)
            reject(err)
          })
    })
  },

  /**
   * Set client delay as seen.
   *
   * @param {Number} eventID
  */
  clientDelaySeen ({ commit }, eventID) {
    return new Promise((resolve, reject) => {
      window.api
        .put({ url: ApiProvider.buildEventsPath(eventID, 'delay-seen') },
          (success) => {
            commit('SET_CLIENT_DELAY_SEEN', {
              id: eventID,
              seen: 1
            })
            resolve(success.data)
          },
          (err) => {
            window.NotificationPlugin.toast(err.response, 5000)
            reject(err)
          })
    })
  },

  /**
   * Set pending event as seen.
   *
   * @param {Number} eventID
  */
  pendingEventSeen ({ commit }, eventID) {
    return new Promise((resolve, reject) => {
      window.api
        .put({ url: ApiProvider.buildEventsPath(eventID, 'pending-seen') },
          (success) => {
            commit('PENDING_EVENT_SEEN', eventID)
            resolve(success.data)
          },
          (err) => {
            window.NotificationPlugin.toast(err.response, 5000)
            reject(err)
          })
    })
  },

  /**
   * Set expired event as seen.
   *
   * @param {Number} eventID
  */
  expiredEventSeen ({ commit }, eventID) {
    commit('EXPIRED_EVENT_SEEN', eventID)

    return new Promise((resolve, reject) => {
      window.api
        .put({ url: ApiProvider.buildEventsPath(eventID, 'expired-seen') },
          (success) => { resolve(success.data) },
          (err) => {
            window.NotificationPlugin.toast(err.response, 5000)
            reject(err)
          })
    })
  },

  /**
   * Set reactivated expired event as seen.
   *
   * @param {Number} eventID
  */
  reactivatedExpiredEventSeen ({ commit }, eventID) {
    return new Promise((resolve, reject) => {
      const payload = {
        reactivation_failed_seen: 1
      }

      window.api
        .put({ url: ApiProvider.eventNotificationSeen(eventID), payload: payload },
          (success) => {
            commit('EXPIRED_REACTIVATION_EVENT_SEEN', eventID)
            resolve(success.data)
          },
          (err) => {
            window.NotificationPlugin.toast(err.response.message, 5000)
            reject(err)
          })
    })
  },

  /**
   * Force event expiration.
   *
   * @param {Number} eventID
  */
  forceEventExpire ({ commit }, eventID) {
    commit('SET_EVENT_EXPIRED', eventID)

    return new Promise((resolve, reject) => {
      window.api
        .put({ url: ApiProvider.expireEvent(eventID) },
          (success) => {
            const data = success.data

            // Do final update
            commit('ADD_OR_UPDATE', data)

            resolve(data)
          },
          (err) => {
            reject(err)
          })
    })
  },

  /**
   * Force reactivated event expiration.
   *
   * @param {Number} eventID
  */
  forceReactivatedEventExpire ({ commit }, eventID) {
    commit('SET_REACTIVATED_EVENT_EXPIRED', eventID)

    return new Promise((resolve, reject) => {
      window.api
        .put({ url: ApiProvider.expireEvent(eventID) },
          (success) => {
            // Do final update
            commit('ADD_OR_UPDATE', success.data)

            resolve(success.data)
          },
          (err) => {
            reject(err)
          })
    })
  },

  /**
   * Abort request cancellation.
   *
   * @param {Number} eventID
  */
  abortRequestCancellation ({ commit }, eventID) {
    return new Promise((resolve, reject) => {
      window.api
        .put({ url: ApiProvider.abortRequestCancellation(eventID) },
          (success) => {
            commit('RESET_EVENT_CANCELLATION_REQUEST_COUNTDOWN', eventID)
            commit('SET_REQUEST_CANCELLATION_ABORT', eventID)

            resolve(success.data)
          },
          (err) => {
            reject(err)
          })
    })
  },

  /**
   * Confirm request cancellation.
   *
   * @param {Number} eventID
  */
  confirmRequestCancellation ({ commit }, params) {
    return new Promise((resolve, reject) => {
      const payload = {}

      if (params.cancellation_message) {
        payload.cancellation_message = params.cancellation_message
      }

      window.api
        .put({ url: ApiProvider.confirmRequestCancellation(params.eventID), payload: payload },
          (success) => {
            commit('RESET_EVENT_CANCELLATION_REQUEST_COUNTDOWN', params.eventID)
            commit('SET_REQUEST_CANCELLATION_CONFIRM', params.eventID)
            commit('ADD_OR_UPDATE', success.data)

            resolve(success.data)
          },
          (err) => {
            reject(err)
          })
    })
  },

  /**
   * Set request cancellation seen.
   *
   * @param {Number} eventID
  */
  requestCancellationSeen ({ commit }, eventID) {
    return new Promise((resolve, reject) => {
      window.api
        .put({
          url: ApiProvider.requestCancellationSeen(eventID),
          payload: {
            cancellation_request_seen: 1
          }
        },
        (success) => {
          commit('SET_REQUEST_CANCELLATION_SEEN', eventID)

          resolve(success.data)
        },
        (err) => {
          reject(err)
        })
    })
  },

  /**
   * Set request cancellation seen.
   *
   * @param {Number} eventID
  */
  requestCancellationTimeoutSeen ({ commit }, eventID) {
    return new Promise((resolve, reject) => {
      window.api
        .put({
          url: ApiProvider.requestCancellationSeen(eventID),
          payload: {
            cancellation_request_timeout_seen: 1
          }
        },
        (success) => {
          commit('REQUEST_CANCELLATION_EXPIRED_SEEN', eventID)

          resolve(success.data)
        },
        (err) => { reject(err) })
    })
  },

  /**
   * Set request cancellation seen.
   *
   * @param {Number} eventID
  */
  forceRequestCancellationTimeout ({ commit }, eventID) {
    return new Promise((resolve, reject) => {
      commit('FORCE_REQUEST_CANCELLATION_EXPIRED', eventID)

      window.api
        .put({ url: ApiProvider.requestCancellationTimeout(eventID) },
          (success) => { resolve(success.data) },
          (err) => { reject(err) })
    })
  }

}

/// ////////////////////////
// Nothing to do here...
/// ////////////////////////

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
