<template>
  <div id="register-new">
    <div id="registration-complete-mobile" class="wrapper">
      <div class="content__area --success">
        <!-- This div is irrelevant on desktop since this only shows on mobile -->
      </div>
      <div class="content__area drawer max-height-mobile">
        <div class="content center-align">
          <h2
            class="content__title"
            v-html="$translate('register.complete_success.title')"
          />
          <div class="krypton-logo-container">
            <img src="/img/registration/success/pwa-download-success-krypton-logo.svg">
            <div class="logo-desc">
              <div class="logo-title">{{ $translate('register.complete_success.logo_title') }}</div>
              <div class="logo-subtitle">{{ $translate('register.complete_success.logo_subtitle') }}</div>
            </div>
          </div>
          <div class="text-subtitle">{{ $translate('register.complete_success.subtitle') }}</div>
          <div class="text-subtext">{{ $translate('register.complete_success.content_1') }}</div>
          <span v-if="A2_3980" href="#" class="missing-link" @click="redirectToManualDownloadPage">{{ $t('existsLine4') }}</span>
        </div>

        <!-- Open PWA CTA -->
        <div class="actions flex-grid space-between">
          <a
            v-if="FEATURE_APV_REDESIGN_A2_2825"
            href="/"
            target="_blank"
            class="btn btn-primary btn-open-pwa"
            data-id="open-pwa-cta">
            {{ $translate('register.complete_success.open_pwa') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/pwa-animation/translations/android.json"></i18n>

<script>

const logEvent = (evtName, message) => {
  console.log(message)
  const evt = new CustomEvent(evtName, {
    detail: {
      message
    }
  })
  document.getRootNode().dispatchEvent(evt)
}

export default {
  computed: {
    FEATURE_APV_REDESIGN_A2_2825 () {
      return this.$store.getters['featureflag/isEnabled']('APV_REDESIGN_A2_2825')
    },
    A2_3980 () {
      return this.$store.getters['featureflag/isEnabled']('A2_3980')
    }
  },
  mounted () {
    logEvent('pwa-download-screen', 'pwa download success screen')
  },
  methods: {
    redirectToManualDownloadPage () {
      this.$router.push({ name: 'manual-download' })
    },
    setForcePWAAnimation () {
      this.$store.commit('pwaAnimation/setForceShowAndroidAnimation', true)
      this.$store.commit('setShowPWADownloadSuccessScreen', false)
    }
  }
}
</script>

<style lang="scss" scoped>
#register-new .wrapper .content__area:first-of-type.--success {
  background: linear-gradient(123deg, #06D6A0, #FFD600);
}

.content {
  & {
    font-size: 18px;
  }

  .content__title {
    font-family: 'Proxima Nova Semibold';
    font-size: 38px;
    margin-bottom: 40px;
  }

  .krypton-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-desc {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 16px;

      .logo-title {
        font-family: 'Proxima Nova Semibold';
        font-size: 26px;
        color: #2E353D;
      }

      .logo-subtitle {
        font-size: 20px;
        color: #2E353D;
      }
    }
  }

  .text-subtitle {
    font-family: 'Proxima Nova Semibold';
    font-size: 22px;
    padding-top: 42px;
  }

  .text-subtext {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .text-link {
    color: #E30074;
    text-decoration: underline;
    padding-top: 24px;
    cursor: pointer;
  }

  .missing-link {
    cursor: pointer;
    text-decoration: underline;
    color: #C51162;
  }
}

.btn-open-pwa {
  color: #FFFFFF !important;
  font-size: 16px !important;
  width: 100%;
}
</style>
