var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "overview-cta__container" }, [
    _c(
      "a",
      {
        staticClass: "overview-cta__btn",
        on: {
          click: function($event) {
            return _vm.gotoPage(_vm.redirectTo)
          }
        }
      },
      [
        _vm.img
          ? _c("img", {
              staticClass: "overview-cta__img",
              attrs: { src: _vm.img }
            })
          : _c("i", { staticClass: "material-icons" }, [
              _vm._v(_vm._s(_vm.icon))
            ]),
        _c("span", [_vm._v(_vm._s(_vm.label))])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }