export default {
  globals: {
    welcome: 'Hello Welt!',
    dashboard: 'Dashboard',
    continue: 'WEITER'
  },
  pwa: {
    appName: 'Krypton App',
    appDescription: 'Verwalte dein Profil und deine Termine',
    menuIconText1: 'Tippe unten auf',
    menuIconText2: 'und dann',
    menuIconText3: 'Zum Home-Bildschirm',
    menuIconText1Android: 'Tippe auf',
    menuIconText2Android: 'und dann',
    menuIconText3Android: 'Zum Startbildschirm hinzufügen',
    androidSuccess1: 'Glückwunsch!',
    androidSuccess2: 'Du hast die Krypton App installiert.',
    androidSuccess3: 'Schau auf deinem Startbildschirm nach und tippe auf das Krypton Logo um die App zu öffnen.',
    androidSuccess4: 'Du findest es nicht?'
  }
}
