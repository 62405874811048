var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isViewReady
    ? _c(
        "div",
        { attrs: { id: "event-confirmed" } },
        [
          _c("md-navbar", {
            attrs: {
              go: -1,
              brand: _vm.$translate("events.nav_title"),
              "menu-trigger": false,
              icon: "arrow_back"
            }
          }),
          _vm._v(" "),
          _vm.checkExpiration
            ? _c(
                "div",
                { staticClass: "container padding-x-xl center" },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "title strong center-align margin-top-none"
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            _vm.$translate("events.confirm_popup.header", {
                              client_name: _vm.selectedEvent.client.name
                            })
                          ) +
                          "\n    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$translate("events.confirm_popup.note", {
                          client_name: _vm.selectedEvent.client.name
                        })
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "strong",
                      { staticClass: "center-countdown countdown" },
                      [_vm._v(_vm._s(_vm.meetingCountdown))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "md-textarea",
                    {
                      attrs: {
                        id: "event-confirmed-textarea",
                        type: "multiline",
                        "force-active": true,
                        maxlength: _vm.answerMaxChar,
                        label: _vm.$translate(
                          "events.confirm_popup.message_label"
                        )
                      },
                      model: {
                        value: _vm.message,
                        callback: function($$v) {
                          _vm.message = $$v
                        },
                        expression: "message"
                      }
                    },
                    [
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "input-helper grey-text text-darken-1 right"
                          },
                          [_vm._v(_vm._s(_vm.getMaxChar(_vm.message)))]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-large btn-krypton-pink btn-full margin-top-lg margin-bottom waves-effect waves-dark",
                      attrs: { id: "event-confirmed-send-btn" },
                      on: { click: _vm.send }
                    },
                    [
                      !_vm.isSending
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.$translate("events.buttons.send"))
                            )
                          ])
                        : _c(
                            "div",
                            {
                              staticClass: "preloader-wrapper small active",
                              attrs: { id: "event-confirmed-send-loading" }
                            },
                            [_vm._m(0)]
                          )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "waves-effect waves-dark",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showTooltip($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$translate(
                            "events.confirm_popup.explanation_link"
                          )
                        )
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner-layer spinner-green-only" }, [
      _c("div", { staticClass: "circle-clipper left" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gap-patch" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "circle-clipper right" }, [
        _c("div", { staticClass: "circle" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }