var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "onboarding-active-lesson-container",
      attrs: { "data-test": "onboarding-active-lesson" }
    },
    [
      _vm.hasActiveLesson
        ? _c(
            "div",
            { attrs: { id: "onboarding-active-lesson" } },
            [
              _c("onboarding-lesson-card", {
                staticClass: "onboarding-lesson-card--bottom-sheet",
                attrs: { "lesson-card": _vm.getActiveLesson },
                on: { click: _vm.onLessonClicked }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }