import { CreateFeatureFlagService } from '@/services/ama-tools/factories/FeatureFlagFactory'
import IncognitoFactory from '@/services/ama-tools/factories/IncognitoFactory'
import { breakdownAxiosResponse } from '@/services/ama-tools/services/WebService'
import { getSecurityAction } from '@/services/ama-tools/services/SecurityService'
import i18n from './plugins/localization'

CreateFeatureFlagService()
IncognitoFactory.CreateIncognitoCookie()

window.i18n = i18n
// Bind Vue
window.Vue = require('vue')

require('./bootstrap')
require('./features')
export default new Vue({
  i18n: i18n,
  store: require('./stores').default,
  router: require('./routes').default,

  watch: {
    $route (to) {
      let eventName = 'Provider Backend'

      if (to.path.indexOf('registration') >= 0) {
        eventName = 'Provider Registration'
      } else if (to.path.indexOf('photos/tips') >= 0) {
        eventName = 'Provider Photo Tips'
      } else if (to.path.indexOf('photos/guide') >= 0) {
        eventName = 'Provider Photo Guide'
      }

      if (typeof gtag !== undefined) {
        gtag('event', eventName, {
          event_category: window.location.hostname,
          event_label: to.path
        })
      }
    }
  },

  mounted () {
    this.bootstrapAPI()
    this.checkBeforeInstallPrompt()

    this.$moment.tz(process.env.TIMEZONE)
  },
  methods: {
    bootstrapAPI () {
      window.api.responseInterceptor = {
        success: (response) => {
          return response
        },
        failed: (error) => {
          const { code, headers, data, params, path } = breakdownAxiosResponse(error.response)
          getSecurityAction(headers, code, data, params, path)()

          if (error.config.url.indexOf('/ping') === -1) {
            // Add toast messages on network errors
            if (error.code === 'ECONNABORTED' || error.response === undefined) {
              this.$notifications.toast(this.$translate('app.timeout_exceeded'), 5000)
            }
            return error
          }
        }
      }
    },
    checkBeforeInstallPrompt () {
      window.addEventListener('beforeinstallprompt', evt => {
        evt.preventDefault()

        this.$store.commit('pwaAnimation/setBeforeInstallPromptEvt', evt)
      })
    }
  }

}).$mount('#app')
