import { render, staticRenderFns } from "./SafariBanner.vue?vue&type=template&id=3b67ef12&scoped=true&"
import script from "./SafariBanner.vue?vue&type=script&lang=js&"
export * from "./SafariBanner.vue?vue&type=script&lang=js&"
import style0 from "./SafariBanner.vue?vue&type=style&index=0&id=3b67ef12&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b67ef12",
  null
  
)

/* custom blocks */
import block0 from "@/features/pwa-animation/translations/ios.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fforge%2Fama-provider8-stage.noznom.tech%2Fsrc%2Fjs%2Ffeatures%2Fpwa-animation%2Fcomponents%2FSafariBanner.vue"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider8-stage.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3b67ef12')) {
      api.createRecord('3b67ef12', component.options)
    } else {
      api.reload('3b67ef12', component.options)
    }
    module.hot.accept("./SafariBanner.vue?vue&type=template&id=3b67ef12&scoped=true&", function () {
      api.rerender('3b67ef12', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/features/pwa-animation/components/SafariBanner.vue"
export default component.exports