
export default {
  name: 'availability',
  data () {
    return {
      AVAILABILITY_FILTER_TYPES,
      AVAILABILITY_STATUS,
      filterType: AVAILABILITY_FILTER_TYPES.ALL,
      isFullTableView: false,
      setDisplayCount: 2, // Set count of 7-day availability to display. Default is 2 == 14 days
      haveLoadedMore: false,
      currentCard: null
    }
  },
  methods: {
    loadMore () {
      // @TODO wait for the API fix on the Availability API.
      // For now leave it as is...

      // We need to load two weeks in advance for caching...
      // Load next 7 days
      // this.$store
      //     .dispatch('fetchAvailabilities', { date: this.lastAvailabilityDate })
      //     .then(() => {
      //         // Load the next next 7 days
      //         this.$store.dispatch('fetchAvailabilities', { date: this.lastAvailabilityDate })
      //     });
      if (this.haveLoadedMore) return

      this.setDisplayCount += 2 // Display next 14 days...
      this.haveLoadedMore = true
    },
    setNeedToSave (val) {
      this.$store.commit('availabilities/setNeedSave', val)
    },
    month (m) {
      const d = moment()
      const month = this.$translate(`availability.month.${m}`)

      return `${month} ${d.format('YYYY')}`
    },
    scrollToView () {
      document.getElementById(this.lastAvailability.id)
        .scrollIntoView({ behavior: 'smooth' })
    }
  },
  watch: {
    filterType: function (newVal) {
      if (newVal === AVAILABILITY_FILTER_TYPES.FILLED) {
        this.setNeedToSave(false)
        this.$store.commit('availabilities/setLastAvailability', '')
      }
    }
  },
  computed: {
    availabilitiesOverview () {
      if (this.isFullTableView) {
        return this.$store.getters['availabilities/getAvailabilitiesOverview']
      } else {
        return this.$store.getters['availabilities/getAvailabilitiesOverview'].slice(0, 7)
      }
    },
    availabilitiesMonth () {
      return this.$store.getters['availabilities/getAvailabilitiesByMonth'](this.setDisplayCount, this.filterType)
    },
    filledAvailabilityCount () {
      return this.$store.getters['availabilities/getFilledAvailabilityCount']
    },
    lastAvailabilityDate () {
      return this.$store.getters['availabilities/getLastAvailabilityDate']
    },
    user () {
      return this.$store.getters.getUser
    },
    thisMonth () {
      if (this.availabilitiesMonth.length) {
        const date = moment(this.availabilitiesMonth[0].date)
        const month = this.$translate(`availability.month.${date.month()}`)
        return `${month} ${date.format('YYYY')}`
      } else {
        return ''
      }
    },
    lastAvailability () {
      return this.$store.getters['availabilities/getLastAvailability']
    },
    needSave () {
      return this.$store.getters['availabilities/getNeedSave']
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.needSave) {
      const content = this.$translate('availability.save_confirm_content', {
        date: this.lastAvailability.date
      })
      const title = this.$translate('availability.save_confirm_title', {
        date: this.lastAvailability.date
      })

      this.scrollToView()

      $h.newModalConfirm(title, content, () => {
        this.setNeedToSave(false)
        this.$store.commit('availabilities/setLastAvailability', null)
        next()
      })
    } else {
      next()
    }
  }
}
