import MonitoringService from '../services/MonitoringService'

export const getSecurityAction = (headers, code, data, params, path) => {
  return newChallengeHandler(headers, code, data, params, path)
}

const newChallengeHandler = (headers, code, data, params, path) => {
  const hasCFCHallengeHeader = typeof (headers) === 'object' && !!headers['cf-mitigated'] && headers['cf-mitigated'] === 'challenge'
  const isXHR = path.includes('/api')

  if (hasCFCHallengeHeader &&
      isXHR) {
    return () => {
      const msg = 'cloudflare challenge in XHR encountered, please inform devops to fix this.'
      MonitoringService.sendAction(msg, {
        path
      })
      console.log(msg, { path })
    }
  }

  return () => {
    // do nothing
  }
}
