var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "login" } }, [
    _c("div", { staticClass: "container", attrs: { id: "content" } }, [
      _vm.validationError
        ? _c("div", { attrs: { id: "resetpassword-error" } }, [
            _c("div", { staticClass: "padding-top-lg" }, [
              _c(
                "div",
                {
                  staticClass:
                    "grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align"
                },
                [_c("small", [_vm._v(_vm._s(_vm.validationError))])]
              )
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$router.push({ name: "forgot-password" })
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$translate("login.forgot_password")))]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.validationError != null && !_vm.validationError
        ? _c(
            "div",
            { attrs: { id: "resetpassword-form" } },
            [
              _c("img", {
                staticStyle: { "border-radius": "4px" },
                attrs: { src: "/img/icons/kr-logo-144.png", width: "48" }
              }),
              _vm._v(" "),
              _c("div", { attrs: { id: "header" } }, [
                _c(
                  "div",
                  {
                    staticClass: "text-darken-1",
                    staticStyle: { "line-height": "36px" }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$translate("forgotPassword.reset_header_line_1")
                      )
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "text-darken-1" }, [
                  _vm._v(
                    _vm._s(_vm.$translate("forgotPassword.reset_header_line_2"))
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "vform",
                { on: { submit: _vm.submit } },
                [
                  _vm.error
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align",
                          attrs: { id: "resetpassword-error-msg" }
                        },
                        [_c("small", [_vm._v(_vm._s(_vm.error))])]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("md-input", {
                    staticClass: "textfield-box-lg",
                    attrs: {
                      id: "resetpassword-password-new",
                      type: "password",
                      autocomplete: "password",
                      tabindex: "2",
                      label: _vm.$translate("forgotPassword.password")
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  }),
                  _vm._v(" "),
                  _c("md-input", {
                    staticClass: "textfield-box-lg",
                    attrs: {
                      id: "resetpassword-password-new-confirmation",
                      type: "password",
                      autocomplete: "password",
                      tabindex: "2",
                      label: _vm.$translate(
                        "forgotPassword.passwordConfirmation"
                      )
                    },
                    model: {
                      value: _vm.passwordConfirmation,
                      callback: function($$v) {
                        _vm.passwordConfirmation = $$v
                      },
                      expression: "passwordConfirmation"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-raised btn-large waves-effect waves-light display-block",
                      attrs: {
                        id: "resetpassword-submit-btn",
                        type: "submit",
                        tabindex: "3"
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$translate("forgotPassword.continue")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }