<template>
  <div id="total-rating-faq" class="page">
    <div class="container">
      <faq-header
        :header-title="$translate('faq.total_rating_faq.title')"
        :header-tagline="$translate('faq.total_rating_faq.tagline')"
        main-header-logo="/img/faq/total-rating-header-img.svg"
        primary-header-color="#FF0073"
        secondary-header-color="#962BDA"
        header-gradient-angle="235deg"
        tagline-gradient-angle="246deg"
      />
      <!-- eslint-disable vue/no-v-html -->
      <div data-test="faq-content-section" class="container content-section">
        <div>{{ $translate('faq.total_rating_faq.explanation') }}</div>
        <faq-cta
          route-name="feedback"
          :cta-txt="$translate('faq.performance_btn.performance_overview')"
          background-gradient-class="--pink"
        />
      </div>
    </div>
  </div>
</template>

<script>
import redirectMixin from '@/features/provider-faq/mixins/redirect'
import FAQHeader from '@/features/provider-faq/components/FAQHeader.vue'
import FAQCta from '@/features/provider-faq/components/FAQCta.vue'

export default {
  components: {
    'faq-header': FAQHeader,
    'faq-cta': FAQCta
  },
  mixins: [redirectMixin],
  data: () => ({
    fallbackRoute: {
      name: 'faq',
      title: 'sidenav.faq'
    }
  })
}
</script>

<style lang="scss" scoped>
 #total-rating-faq {
  & {
    font-family: 'Proxima Nova';
  }
  .content-section {
    font-size: 18px;
    text-align: left;
    background: #FFFFFF;
    min-height: calc(100dvh - 350px);
    color: #2F3740;
    padding-top: 48px;
    padding-left: 32px;
    padding-right: 32px;

    .faq-cta-container {
      margin-top: 56px;
    }
  }
 }
</style>
