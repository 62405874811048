var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.VIP_PHOTOS_A2_2416
    ? _c(
        "div",
        { attrs: { id: "empty-event event-tips" } },
        _vm._l(_vm.tipsVipPhotos2416, function(n, idx) {
          return _c("div", { key: idx, staticClass: "card" }, [
            _c("div", { staticClass: "card-content left-align" }, [
              _c("div", { staticClass: "card-title" }, [
                _c("strong", [_vm._v(_vm._s(_vm.getTitle(n, idx)))])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-sub-title" }, [
                _vm._v(_vm._s(_vm.getSubtitle(n)))
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "description",
                domProps: { innerHTML: _vm._s(_vm.getDescription(n)) }
              }),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right-align" },
                [
                  n === 9
                    ? _c(
                        "a",
                        {
                          staticClass: "waves-effect waves-dark padding-sm",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.copyToClipboard(
                                _vm.user.profiles._profile_url
                              )
                            }
                          }
                        },
                        [_c("strong", [_vm._v(_vm._s(_vm.getLinkLabel(n)))])]
                      )
                    : _c(
                        "vlink",
                        {
                          staticClass: "waves-effect waves-dark padding-sm",
                          attrs: { to: _vm.getLink(n) }
                        },
                        [_c("strong", [_vm._v(_vm._s(_vm.getLinkLabel(n)))])]
                      )
                ],
                1
              )
            ])
          ])
        }),
        0
      )
    : _c(
        "div",
        { attrs: { id: "empty-event event-tips" } },
        _vm._l(_vm.tips, function(n, idx) {
          return _c("div", { key: idx, staticClass: "card" }, [
            _c("div", { staticClass: "card-content left-align" }, [
              _c("div", { staticClass: "card-title" }, [
                _c("strong", [_vm._v(_vm._s(_vm.getTitle(n, idx)))])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-sub-title" }, [
                _vm._v(_vm._s(_vm.getSubtitle(n)))
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "description",
                domProps: { innerHTML: _vm._s(_vm.getDescription(n)) }
              }),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right-align" },
                [
                  n === 9
                    ? _c(
                        "a",
                        {
                          staticClass: "waves-effect waves-dark padding-sm",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.copyToClipboard(
                                _vm.user.profiles._profile_url
                              )
                            }
                          }
                        },
                        [_c("strong", [_vm._v(_vm._s(_vm.getLinkLabel(n)))])]
                      )
                    : _c(
                        "vlink",
                        {
                          staticClass: "waves-effect waves-dark padding-sm",
                          attrs: { to: _vm.getLink(n) }
                        },
                        [_c("strong", [_vm._v(_vm._s(_vm.getLinkLabel(n)))])]
                      )
                ],
                1
              )
            ])
          ])
        }),
        0
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }