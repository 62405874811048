var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "fixed-menu" } }, [
    _c("ul", { staticClass: "menus" }, [
      _c(
        "li",
        {
          staticClass: "waves-effect waves-dark",
          class: {
            active: _vm.isCurrentRoute("dashboard") && !_vm.isSuperProvider,
            "super-provider":
              _vm.isCurrentRoute("dashboard") && _vm.isSuperProvider
          },
          attrs: { "data-test": "menu-item-dashboard" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.navigatePage("dashboard", false)
            }
          }
        },
        [
          _c("a", { attrs: { href: "#" } }, [
            _c("img", {
              attrs: { src: _vm.dashboardNavIcon, alt: "home icon" }
            }),
            _vm._v(" "),
            _c("strong", [_vm._v(_vm._s(_vm.$translate("sidenav.dashboard")))])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.user.availability_enabled
        ? _c(
            "li",
            {
              staticClass: "waves-effect waves-dark",
              class: {
                active:
                  _vm.isCurrentRoute("availability") && !_vm.isSuperProvider,
                "super-provider":
                  _vm.isCurrentRoute("availability") && _vm.isSuperProvider
              },
              attrs: { "data-test": "menu-item-availability" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.navigatePage("availability", false)
                }
              }
            },
            [
              _c("a", { attrs: { href: "#" } }, [
                _c("img", {
                  attrs: {
                    src: _vm.availabilityNavIcon,
                    alt: "availability icon"
                  }
                }),
                _vm._v(" "),
                _c("strong", [
                  _vm._v(_vm._s(_vm.$translate("sidenav.availability")))
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "waves-effect waves-dark",
          class: {
            active:
              _vm.isCurrentRoute("inbox") &&
              !_vm.isFeatureLocked &&
              !_vm.isSuperProvider,
            "super-provider":
              _vm.isCurrentRoute("inbox") &&
              _vm.isSuperProvider &&
              !_vm.isFeatureLocked,
            locked: _vm.isFeatureLocked,
            "has-notif":
              (_vm.hasInboxNotification ||
                _vm.hasFeedbackUpdates ||
                _vm.hasKryptonConnectNotification) &&
              !_vm.isFeatureLocked
          },
          attrs: { "data-test": "menu-item-inbox" },
          on: {
            click: function($event) {
              return _vm.navigatePage("inbox", true, {
                filter: _vm.INBOX_FILTER.ALL
              })
            }
          }
        },
        [
          _c("a", { attrs: { href: "#" } }, [
            _c("img", { attrs: { src: _vm.inboxNavIcon, alt: "inbox icon" } }),
            _vm._v(" "),
            _c("strong", [
              _vm._v(_vm._s(_vm.$translate("sidenav.inbox.title")))
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.user.events_enabled
        ? _c(
            "li",
            {
              staticClass: "waves-effect waves-dark",
              class: {
                active:
                  _vm.isCurrentRoute("events") &&
                  !_vm.isFeatureLocked &&
                  !_vm.isSuperProvider,
                "super-provider":
                  _vm.isCurrentRoute("events") &&
                  _vm.isSuperProvider &&
                  !_vm.isFeatureLocked,
                locked: _vm.isFeatureLocked,
                "has-notif": _vm.hasEventUpdates
              },
              attrs: { "data-test": "menu-item-events" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.navigatePage("events", true)
                }
              }
            },
            [
              _c("a", { attrs: { href: "#" } }, [
                _c("img", {
                  attrs: { src: _vm.eventsNavIcon, alt: "events icon" }
                }),
                _vm._v(" "),
                _c("strong", [_vm._v("Events")])
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "waves-effect waves-dark",
          class: {
            active: _vm.isCurrentRoute("menu") && !_vm.isSuperProvider,
            "super-provider": _vm.isCurrentRoute("menu") && _vm.isSuperProvider,
            "has-notif": _vm.showNotifOtherMenu
          },
          attrs: { "data-test": "menu-item-other-menu" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.navigatePage("menu", false)
            }
          }
        },
        [
          _c("a", { attrs: { href: "#" } }, [
            _c("img", {
              attrs: { src: _vm.otherMenuNavIcon, alt: "home icon" }
            }),
            _vm._v(" "),
            _c("strong", [_vm._v("Menu")])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }