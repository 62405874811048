var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showShortcut
    ? _c(
        "a",
        {
          staticClass: "lesson-shortcut",
          attrs: { href: "#", "data-test": "lesson-shortcut" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.onClickHandler($event)
            }
          }
        },
        [
          _c("img", {
            attrs: {
              src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-info-24px-baseline-v2.svg"),
              alt: "onboarding lesson info"
            }
          }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.$t(_vm.translationKey)))])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }