import MultiLocationService from '@/services/MultiLocationService'

const state = {
  /**
   * Array of supported cities based on provider homebase
   */
  multiLocation: []
}

const getters = {
  getMultiLocation (state) {
    return state.multiLocation
  },
  isMultiLocationSupported (state) {
    return state.multiLocation.length > 0
  }
}

const mutations = {
  setMultiLocation (state, value) {
    state.multiLocation = value
  }
}

const actions = {
  async fetch ({ commit }) {
    try {
      const multiLocation = await MultiLocationService.fetch()
      commit('setMultiLocation', multiLocation)
    } catch (err) {
      console.error(err)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
