export const LESSON = Object.freeze({
  PHOTOS: 1,
  INTERVIEW: 2,
  AVAILABILITY: 3,
  SAFETY: 4,
  CLIENTS: 5,
  REQUESTS: 6,
  DATES: 7,
  FEEDBACK: 8,
  MONEY: 9
})

export const LESSON_IMAGES = Object.freeze({
  PHOTOS: '/img/bottom-sheet/photos-banner-img.svg',
  INTERVIEW: 2,
  AVAILABILITY: 3,
  SAFETY: 4,
  CLIENTS: 5,
  REQUESTS: 6,
  DATES: 7,
  FEEDBACK: 8,
  MONEY: 9
})

export const BOTTOM_SHEET_SIZES = Object.freeze({
  MIN: '136px',
  MAX: '80%'
})

export const ID_VERIFICATION_COUNTDOWN = Object.freeze({
  MIN: 0,
  MAX: 172800
})

export const PROVIDER_PLATFORM_AGE = Object.freeze({
  MIN_DAYS_FOR_LONG_TERM_BOOKING_POPUP: 14 // days
})
