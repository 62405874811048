import { render, staticRenderFns } from "./AffiliateLandingPageQA.vue?vue&type=template&id=0a37febe&scoped=true&"
import script from "./AffiliateLandingPageQA.vue?vue&type=script&lang=js&"
export * from "./AffiliateLandingPageQA.vue?vue&type=script&lang=js&"
import style0 from "./AffiliateLandingPageQA.vue?vue&type=style&index=0&id=0a37febe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a37febe",
  null
  
)

/* custom blocks */
import block0 from "@/features/affiliate/translations/landing-page.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fforge%2Fama-provider8-stage.noznom.tech%2Fsrc%2Fjs%2Ffeatures%2Faffiliate%2Fcomponents%2Flanding-page%2FAffiliateLandingPageQA.vue"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider8-stage.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0a37febe')) {
      api.createRecord('0a37febe', component.options)
    } else {
      api.reload('0a37febe', component.options)
    }
    module.hot.accept("./AffiliateLandingPageQA.vue?vue&type=template&id=0a37febe&scoped=true&", function () {
      api.rerender('0a37febe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/features/affiliate/components/landing-page/AffiliateLandingPageQA.vue"
export default component.exports