<template>
  <div ref="photo-upload" class="photo-gallery-v2-photo empty" @click="uploadPhoto">
    <a
      href="#"
      class="add-more center-align"
    >
      <div class="add-btn" />
    </a>
    <div class="photo-add" />
  </div>
</template>

<script>
export default {
  methods: {
    uploadPhoto () {
      this.$parent.replacePhotoID = 0 // Always reset replacement on add photo
      this.$parent.$refs.uploadFormFile.click()
    }
  }
}
</script>
