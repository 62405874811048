var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "multi-location-promo-banner max-width-center",
      attrs: { "data-test": "multi-location-promo-banner" },
      on: {
        click: function($event) {
          return _vm.gotoPage("multi-location-overview")
        }
      }
    },
    [
      _c(
        "div",
        {
          ref: "banner-container",
          staticClass: "wrapper promo-banner-container --orange",
          class: { "--expanded-height": _vm.expandedHeight }
        },
        [
          _c("div", { staticClass: "promo-banner" }, [
            _c("div", { staticClass: "more-requests" }, [
              _c("img", {
                attrs: { src: "/img/multi-location/commute_black_24dp.svg" }
              }),
              _vm._v(" "),
              _c("span", {
                attrs: { "data-test": "pp-bottom-banner-title" },
                domProps: { textContent: _vm._s(_vm.$t("banner.title")) }
              })
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "learn-how",
              attrs: { "data-test": "pp-bottom-banner-cta" },
              domProps: { textContent: _vm._s(_vm.$t("banner.cta")) }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }