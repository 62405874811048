var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "support-v2-item clickable",
      class: { "--unread": _vm.ticket.is_read === 0 },
      on: { click: _vm.gotoTicket }
    },
    [
      _c("div", { staticClass: "support-v2-item__container container--flex" }, [
        _c("div", { staticClass: "support-v2-item__labels" }, [
          _c("strong", { staticClass: "support-v2-item__category" }, [
            _vm._v(_vm._s(_vm.category))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "support-v2-item__ticket-title" }, [
            _vm._v("ID" + _vm._s(_vm.ticket.id) + " "),
            _vm.info ? _c("span", [_vm._v(_vm._s(_vm.info))]) : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "support-v2-item__indicators" }, [
          _vm.ticket.is_read === 0
            ? _c("strong", { staticClass: "support-v2-item__dot" })
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "support-v2-item__time-ago" }, [
            _vm._v(_vm._s(_vm.timeAgo))
          ])
        ])
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "support-v2-item__preview" }, [
        _vm._v(_vm._s(_vm.ticketTitle))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }