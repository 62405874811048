<template>
  <div id="details" class="wrapper">
    <md-new-navbar route-name="profile" />
    <div v-if="ready" id="content" class="container">
      <h1 class="title">{{ $translate('profile.edit_information') }}</h1>
      <img
        src="/img/profile-details/character-info-header.svg"
        alt="Character Info"
        class="header-img"
      >
      <vform
        ref="form"
        class="details-form"
        @submit="save"
      >
        <!-- Lang 1 -->
        <md-select
          v-if="user && dropdown && optsLanguages.length"
          v-model="payload.lang_1"
          :label="$translate('details.first_language')"
          :placeholder="$translate('details.lang_1')"
          :listen-for-reinitiate-options="true"
          :force-mobile="true"
          :new-design="true"
          @change="reInitiateOptions"
        >
          <option
            v-for="opt in optsLanguages"
            slot="options"
            :key="opt.key"
            :value="opt.key"
            :disabled="opt.key == payload.lang_1 || opt.key == payload.lang_2 || opt.key == payload.lang_3"
          >{{ opt.content }}</option>
          <div v-show="errors.has('lang_1')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('lang_1') }}
          </div>
        </md-select>

        <!-- Lang 2 -->
        <div v-if="user && dropdown && optsLanguages.length && showSecondLang">
          <md-select
            v-model="payload.lang_2"
            :label="$translate('details.second_language')"
            :placeholder="$translate('details.lang_2')"
            :listen-for-reinitiate-options="true"
            :force-mobile="true"
            :new-design="true"
            @change="reInitiateOptions"
          >
            <option
              v-for="opt in optsLanguages"
              slot="options"
              :key="opt.key"
              :value="opt.key"
              :disabled="opt.key == payload.lang_2 || opt.key == payload.lang_3 || opt.key == payload.lang_1"
            >{{ opt.content }}</option>
            <div v-show="errors.has('lang_2')" slot="errors" class="input-helper red-text center-align">
              {{ errors.first('lang_2') }}
            </div>
          </md-select>
          <div class="remove-btn margin-bottom" style="overflow: auto">
            <a href="#" class="remove-lang-cta uppercase right waves-effect waves-dark padding-sm" @click.prevent="removeLang(2)">
              <small><strong>{{ $translate('app.remove') }}</strong></small>
            </a>
          </div>
        </div>

        <!-- Lang 3 -->
        <div v-if="user && dropdown && optsLanguages.length && showThirdLang">
          <md-select
            v-model="payload.lang_3"
            :label="$translate('details.third_language')"
            :placeholder="$translate('details.lang_3')"
            :listen-for-reinitiate-options="true"
            :force-mobile="true"
            :new-design="true"
            @change="reInitiateOptions"
          >
            <option
              v-for="opt in optsLanguages"
              slot="options"
              :key="opt.key"
              :value="opt.key"
              :disabled="opt.key == payload.lang_3 || opt.key == payload.lang_1 || opt.key == payload.lang_2"
            >{{ opt.content }}</option>
            <div v-show="errors.has('lang_3')" slot="errors" class="input-helper red-text center-align">
              {{ errors.first('lang_3') }}
            </div>
          </md-select>
          <div class="remove-btn margin-bottom" style="overflow: auto">
            <a href="#" class="remove-lang-cta uppercase right waves-effect waves-dark padding-sm" @click.prevent="removeLang(3)">
              <small><strong>{{ $translate('app.remove') }}</strong></small>
            </a>
          </div>
        </div>

        <!-- Add Lang -->
        <div v-if="showAddLang" class="add-lang-cta right-align margin-y-md">
          <a href="#" class="waves-effect waves-dark padding-sm" @click.prevent="incrementLang2show">
            <small><strong>+ {{ $translate('details.add_language') }}</strong></small>
          </a>
        </div>

        <!-- Interests -->
        <md-textarea
          v-model="payload.interests"
          type="multiline"
          :force-active="true"
          :maxlength="interestsMaxLength"
          :label="$translate('profile.interests')"
          :placeholder="$translate('register.personality.intellect.interests_placeholder')"
        >
          <div class="input-helper">
            <span class="right">{{ interestsLength }}</span>
            <div v-show="errors.has('interests')" class="input-helper red-text center-align">
              {{ errors.first('interests') }}
            </div>
          </div>
        </md-textarea>

        <!-- Profession -->
        <md-select
          v-if="user && dropdown && optsProfession.length"
          v-model="payload.profession"
          :label="$translate('profile.job')"
          :placeholder="$translate('details.job')"
          :force-mobile="true"
          :new-design="true"
        >
          <option
            v-for="opt in optsProfession"
            slot="options"
            :key="opt.key"
            :value="opt.key"
          >{{ opt.content }}</option>
          <div v-show="errors.has('profession')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('profession') }}
          </div>
        </md-select>

        <!-- Cuisine -->
        <md-select
          v-if="user && dropdown && optsCuisine.length"
          v-model="payload.cuisine"
          multiple="true"
          :limit="3"
          :force-mobile="true"
          :label="$translate('profile.cuisine')"
          :placeholder="$translate('details.cuisine')"
          :new-design="true"
        >
          <option
            v-for="opt in optsCuisine"
            slot="options"
            :key="opt.key"
            :value="opt.key"
          >{{ opt.content }}</option>
          <div v-show="errors.has('cuisine')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('cuisine') }}
          </div>
        </md-select>

        <!-- Drinks -->
        <md-textarea
          v-model="payload.drinks"
          type="multiline"
          :force-active="true"
          :maxlength="drinksMaxLength"
          :label="$translate('register.personality.taste.drinks_label')"
          :placeholder="$translate('profile.drinks_placeholder')"
        >
          <div class="input-helper">
            <span class="right">{{ drinksLength }}</span>
            <div v-show="errors.has('drinks')" class="input-helper red-text center-align">
              {{ errors.first('drinks') }}
            </div>
          </div>
        </md-textarea>

        <!-- Smoking -->
        <md-select
          v-if="user && dropdown && optsSmoking.length"
          v-model="payload.smoking"
          :label="$translate('profile.smoking')"
          :placeholder="$translate('details.smoke')"
          :force-mobile="true"
          :new-design="true"
        >
          <option
            v-for="opt in optsSmoking"
            slot="options"
            :key="opt.key"
            :value="opt.key"
          >{{ opt.content }}</option>
          <div v-show="errors.has('smoking')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('smoking') }}
          </div>
        </md-select>

        <!-- Tattoo -->
        <md-select
          v-if="user && dropdown && optsTattoo.length"
          v-model="payload.tattoo"
          :label="$translate('profile.tattoo')"
          :placeholder="$translate('details.tattoo')"
          :force-mobile="true"
          :new-design="true"
        >
          <option
            v-for="opt in optsTattoo"
            slot="options"
            :key="opt.key"
            :value="opt.key"
          >{{ opt.content }}</option>
          <div v-show="errors.has('tattoo')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('tattoo') }}
          </div>
        </md-select>

        <!-- Piercing -->
        <md-select
          v-if="user && dropdown && optsPiercing.length"
          v-model="payload.piercing"
          :label="$translate('profile.piercing')"
          :placeholder="$translate('details.piercing')"
          :force-mobile="true"
          :new-design="true"
        >
          <option
            v-for="opt in optsPiercing"
            slot="options"
            :key="opt.key"
            :value="opt.key"
          >{{ opt.content }}</option>
          <div v-show="errors.has('piercing')" slot="errors" class="input-helper red-text center-align">
            {{ errors.first('piercing') }}
          </div>
        </md-select>
      </vform>

      <h-footer-btns-apv-redesign
        :cancel="$translate('app.cancel')"
        :confirm="$translate('app.save')"
        @cancel="$router.push({ name: 'profile' })"
        @confirm="submitButton"
      />
    </div>
  </div>
</template>

<script>
const UserProvider = $h.getProvider('UserProvider')
const LocaleProvider = $h.getProvider('LocaleProvider')
export default {
  data () {
    return {
      interestsMaxLength: 60,
      drinksMaxLength: 60,
      lang2show: 0,
      /// /////////
      payload: {
        lang_1: null,
        lang_2: null,
        lang_3: null,
        interests: '',
        drinks: ''
      },
      /// /////////
      optsLanguages: [],
      optsProfession: [],
      optsCuisine: [],
      optsSmoking: [],
      optsTattoo: [],
      optsPiercing: []
    }
  },
  computed: {
    ready () {
      return this.dropdown && this.user
    },
    dropdown () {
      return this.$store.getters.getDropdown
    },
    user () {
      return this.$store.getters.getUser
    },
    showSecondLang () {
      return this.lang2show >= 2
    },
    showThirdLang () {
      return this.lang2show >= 3
    },
    showAddLang () {
      return this.lang2show <= 2
    },
    interestsLength: {
      cache: false,
      get () {
        return `${this.payload.interests.length}/${this.interestsMaxLength}`
      }
    },
    drinksLength: {
      cache: false,
      get () {
        return `${this.payload.drinks.length}/${this.drinksMaxLength}`
      }
    }
  },
  watch: {
    dropdown (newval, oldval) {
      this.initDropdown()
    }
  },
  mounted () {
    this.initPayload()
    this.initLang2show()
    this.initDropdown()
  },
  methods: {
    reInitiateOptions () {
      this.$root.$emit('parent-reinitiate-options')
    },
    submitButton () {
      this.$refs.form.submit()
    },
    save (next) {
      this.errors.clear()

      UserProvider.updateCharacterDetails(this.payload)
        .then((res) => {
          this.$notifications.toast(this.$translate('profile.profile_updated'), 5000)
          this.$store.commit('setProfile', res.data)
          this.$store.commit('setCuisine', res.data.cuisine)
          this.$store.commit('setLang', {
            lang_1: res.data.lang_1,
            lang_2: res.data.lang_2,
            lang_3: res.data.lang_3
          })
          this.$router.push({ name: 'profile' })
        })
        .catch((err) => {
          $h.processErrors(this, err.data)
        })
        .finally(() => {
          next()
        })
    },
    setPayloadProp (key, val) {
      if (val === undefined) val = null
      this.payload[key] = val
    },
    parseInterests () {
      if (LocaleProvider.isLocaleDe() && this.user.profiles.interests_de) { return this.user.profiles.interests_de }
      return this.user.profiles.interests
    },
    parseDrinks () {
      if (LocaleProvider.isLocaleDe() && this.user.profiles.drinks_de) return this.user.profiles.drinks_de
      return this.user.profiles.drinks
    },
    incrementLang2show () {
      if (this.lang2show < 3) this.lang2show++
      this.reInitiateOptions()
    },
    decrementLang2show () {
      if (this.lang2show > 1) this.lang2show--
    },
    removeLang (index) {
      if (index === 2 && this.payload.lang_3) {
        this.payload.lang_2 = this.payload.lang_3
        index = 3
      }

      var key = 'lang_' + index

      this.setPayloadProp(key)
      this.decrementLang2show()
      this.reInitiateOptions()
    },
    calcLang () {
      var tmpLang = 0

      if (this.payload.lang_1) tmpLang++
      if (this.payload.lang_2) tmpLang++
      if (this.payload.lang_3) tmpLang++

      this.lang2show = tmpLang
    },
    initPayload () {
      this.payload.city_id = this.user.profiles.city_id
      this.payload.lang_1 = this.user.profiles.lang_1 ? this.user.profiles.lang_1.short : null
      this.payload.lang_2 = this.user.profiles.lang_2 ? this.user.profiles.lang_2.short : null
      this.payload.lang_3 = this.user.profiles.lang_3 ? this.user.profiles.lang_3.short : null
      this.payload.interests = this.parseInterests() || ''
      this.payload.profession = this.user.profiles.profession
      this.payload.cuisine = $h.string2json(this.user.profiles.cuisine)
      this.payload.drinks = this.parseDrinks() || ''
      this.payload.smoking = this.user.profiles.smoking
      this.payload.tattoo = this.user.profiles.tattoo
      this.payload.piercing = this.user.profiles.piercing
    },
    initDropdown () {
      if (!this.dropdown) return

      this.optsLanguages = $h.obj2arr(this.dropdown.language)
      this.optsProfession = $h.obj2arr(this.dropdown.profession)
      this.optsCuisine = $h.obj2arr(this.dropdown.cuisine)
      this.optsSmoking = $h.obj2arr(this.dropdown.smoking)
      this.optsTattoo = $h.obj2arr(this.dropdown.tattoo)
      this.optsPiercing = $h.obj2arr(this.dropdown.piercing)
    },
    initLang2show () {
      if (this.payload.lang_1) this.lang2show++
      if (this.payload.lang_2) this.lang2show++
      if (this.payload.lang_3) this.lang2show++
    }
  }
}
</script>
