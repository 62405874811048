<template>
  <div id="multi-location-list-item" data-test="multi-location-promo-card" @click="gotoPrivatePlacePromoPage">
    <div class="left-panel">
      <div class="promo-title">{{ $t('card.title') }}</div>
      <div class="promo-subtitle">{{ $t('card.subtitle') }}</div>
      <div class="promo-cta">{{ $t('card.cta') }}</div>
    </div>
    <div class="right-panel">
      <img :src="item.image">
    </div>
  </div>
</template>

<i18n src="@/features/multi-location/translations/banners.json"></i18n>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({
        image: 'img/multi-location/promo-image-icon.svg'
      })
    }
  },
  methods: {
    gotoPrivatePlacePromoPage () {
      this.$router.push({ name: 'multi-location-overview' })
    }
  }
}
</script>

<style lang="scss" scoped>
#multi-location-list-item {
  background: transparent linear-gradient(68deg, #FF665B 0%, #FFD04D 100%) 0% 0% no-repeat padding-box;
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 4px;

  .left-panel {
    text-align: left;
  }

  .right-panel {
    padding-left: 16px;
    display: flex;
    align-items: flex-start;
  }

  .promo-title {
    line-height: 1.5rem;
  }

  .promo-subtitle {
    padding-top: 4px;
    line-height: 1.5rem;
    font-size: 1.5rem;
    font-weight: normal;
  }

  .promo-cta {
    padding-top: 16px;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
  }
}
</style>
