import { PAGE_STEPS } from './eventDeclined.mixin'

export default {
  data: () => ({
    PAGE_STEPS,
  }),
  methods: {
    goBack () {
      if (!this.isSending) {
        this.$router.back()
      }
    }
  },
  props: {
    step: {
      type: String,
      default: PAGE_STEPS.INITIAL
    },
    error_form: {
      type: Object,
      required: true
    },
    eventDate: {
      type: String,
      default: ''
    },
    clientName: {
      type: String,
      default: ''
    }
  }
}
