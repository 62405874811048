const state = {
  /**
   * Force the PWA animation to show on routes which uses the
   * PwaCheckpoint but do not show the animation by default.
   */
  forceShowAnimation: false,

  /**
   * Force the safari banner to show
   */
  forceShowSafariBanner: false,

  /**
   * Force the android animation to show
   */
  forceShowAndroidAnimation: false,

  /**
   * Before install prompt event
   */
  beforeInstallPromptEvt: null
}

const getters = {
  forceShowAnimation (state) {
    return state.forceShowAnimation
  },
  forceShowSafariBanner (state) {
    return state.forceShowSafariBanner
  },
  forceShowAndroidAnimation (state) {
    return state.forceShowAndroidAnimation
  },
  beforeInstallPromptEvt (state) {
    return state.beforeInstallPromptEvt
  }
}

const mutations = {
  setForceShowAnimation (state, value) {
    state.forceShowAnimation = value
  },
  setForceShowSafariBanner (state, value) {
    state.forceShowSafariBanner = value
  },
  setForceShowAndroidAnimation (state, value) {
    state.forceShowAndroidAnimation = value
  },
  setBeforeInstallPromptEvt (state, value) {
    state.beforeInstallPromptEvt = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
