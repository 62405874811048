<template>
  <div id="billing-required-modal" class="modal center-align billing-required">
    <div class="modal-content">
      <h4>
        {{ $translate('billing.popup.title') }}
      </h4>
      <img class="margin-y-lg" src="/img/verify/billing-required-checked.svg">
      <p>
        {{ $translate('billing.popup.explanation_text') }}
      </p>
    </div>

    <div class="new-modal-footer">
      <a href="#" @click="confirm">
        <strong>{{ this.$translate('billing.popup.cta_button') }}</strong>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    confirm ($e) {
      $e.preventDefault()
      this.$emit('confirm', $e)
    }
  }
}
</script>

<style lang="scss" scoped>
.billing-required {
    border-radius: 12px;
    position: fixed;
    top: 50% !important;
    transform: scalex(1) translate(0, -50%) !important;
    margin: 0 auto;

    @media screen and (max-width: 600px) {
      margin: 0 16px;
      width: 94%;
    }
    @media screen and (max-width: 420px) {
      width: 92%;
    }

    .modal-content {
        border-bottom: 1px solid #CAD1D9;
        padding: 32px 24px 28px 24px;

        h4 {
            color: #2F3740;
            font-size: 20px;
            line-height: 32px;
            margin: 0;
        }

        p {
            color: #2F3740;
            font-size: 18px;
            line-height: 28px;
            margin: 0;
        }
    }

    .new-modal-footer {
        height: 95px;
        margin: 0;
        overflow: hidden;
        padding: 17px 24px 31px 24px;
        text-align: center;

        a {
            background: transparent linear-gradient(261deg, #FF0073 0%, #DC0064 100%) 0% 0% no-repeat padding-box;
            border: none;
            border-radius: 10px;
            box-shadow: none;
            color: #fff;
            display: inline-block;
            font-size: 16px;
            font-weight: 700;
            line-height: 14px;
            margin: 0;
            padding: 16px;
            width: 100%;
        }
    }

}
</style>
