import { render, staticRenderFns } from "./event-details.vue?vue&type=template&id=2ecb1567&scoped=true&"
import script from "./event-details.vue?vue&type=script&lang=js&"
export * from "./event-details.vue?vue&type=script&lang=js&"
import style0 from "./event-details.vue?vue&type=style&index=0&id=2ecb1567&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ecb1567",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider8-stage.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2ecb1567')) {
      api.createRecord('2ecb1567', component.options)
    } else {
      api.reload('2ecb1567', component.options)
    }
    module.hot.accept("./event-details.vue?vue&type=template&id=2ecb1567&scoped=true&", function () {
      api.rerender('2ecb1567', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/account/events/event-details.vue"
export default component.exports