var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "potential_redesign" } },
    [
      _c("div", { staticClass: "content__area" }, [
        _c("img", {
          attrs: {
            id: "k-logo",
            src: "/img/registration/k-logo.svg",
            alt: "k-logo"
          }
        }),
        _vm._v(" "),
        _c("h2", { staticClass: "content__title content__title--desktop" }, [
          _vm._v(_vm._s(_vm.$translate("register.potential.title")))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content__area vertically-scrollable" }, [
        _c("div", { staticClass: "content container" }, [
          _c("h2", { staticClass: "content__title content__title--mobile" }, [
            _vm._v(_vm._s(_vm.$translate("register.potential.title")))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-body" }, [
            _c("img", {
              staticClass: "img-potential-lady",
              attrs: { src: "/img/registration/ic-potential-lady.svg" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "description" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$translate("register.potential.description")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "img-container blur" }, [
              _c("img", {
                staticClass: "img-potential-rates",
                attrs: { src: _vm.getImgByCountryCode, alt: "potential rates" }
              })
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "actions flex-grid justify-content-center" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$router.push({ name: "register-verify" })
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$translate("register.potential.continue")) +
                      "\n        "
                  )
                ]
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }