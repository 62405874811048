import { buildParam } from '@/services/ApiService'
import { sendTracking } from '@/services/TrackingService'

const authenticateUsingMagicLinkEndpoint = '/v2/magic-link-login'

export const login = (email, password, register) => {
  return new Promise((resolve, reject) => {
    window.api.post({
      url: buildParam('/login'),
      payload: {
        email: email,
        password: password,
        register: register ?? 0
      },
      config: {
        params: {
          locale: window.i18n.locale
        }
      }
    }, (res) => {
      sendTracking(res.data.data)
      resolve(res.data.data)
    }, (err) => {
      reject(err?.response?.data ?? err)
    })
  })
}

export const loginWithMagicLink = (token) => {
  return new Promise((resolve, reject) => {
    window.api.post({
      url: buildParam('/login-with-link'),
      payload: {
        uid: token,
        register: 1
      }
    }, (res) => {
      sendTracking(res.data.data)
      resolve(res.data.data)
    }, (err) => {
      reject(err?.response?.data ?? err)
    })
  })
}

export const authenticateUsingMagicLink = (params) => {
  return new Promise((resolve, reject) => {
    window.api.post({
      url: buildParam(authenticateUsingMagicLinkEndpoint),
      payload: params
    },
    (res) => {
      sendTracking(res.data.data)
      resolve(res.data.data)
    },
    (err) => { reject(err?.response?.data ?? err) })
  })
}

export const getUser = () => {
  return new Promise((resolve, reject) => {
    window.api.get({
      url: buildParam('/user'),
      config: {
        params: {
          locale: window.i18n.locale
        }
      }
    }, (res) => {
      resolve(res.data.data)
    }, (err) => {
      reject(err.response)
    })
  })
}

export const register = (payload = { email: null, password: null }) => {
  return new Promise((resolve, reject) => {
    window.api.post({
      url: buildParam('/v2/register/account'),
      payload
    }, (success) => {
      resolve(success.data)
    }, (err) => {
      reject(err?.response?.data ?? err)
    })
  })
}

export const forgotPassword = (email) => {
  return new Promise((resolve, reject) => {
    window.api.post({
      url: buildParam('/v2/forgot-password/request'),
      payload: {
        email
      },
      config: {
        timeout: 15000
      }
    }, (res) => {
      resolve(res.data)
    }, (err) => {
      reject(err?.response?.data ?? err)
    })
  })
}

export const resetPasswordValidate = (email, token) => {
  return new Promise((resolve, reject) => {
    window.api.post({
      url: buildParam('/v2/forgot-password/validate'),
      payload: {
        email,
        token
      },
      config: {
        timeout: 15000
      }
    }, (res) => {
      resolve(res.data)
    }, (err) => {
      reject(err?.response?.data ?? err)
    })
  })
}

export const requestMagicLink = (countryCode, phone_number, email = null) => {
  return new Promise((resolve, reject) => {
    window.api.post({
      url: buildParam('/v2/magic-link/request'),
      payload: {
        country: countryCode,
        phone_number,
        ...(email && { email })
      },
      config: {
        timeout: 15000
      }
    }, (res) => {
      resolve(res.data)
    }, (err) => {
      reject(err?.response?.data ?? err)
    })
  })
}

export const resetPassword = (email, token, password, confirmPassword) => {
  return new Promise((resolve, reject) => {
    window.api.put({
      url: buildParam('/v2/forgot-password/reset'),
      payload: {
        email,
        token,
        password,
        password_confirmation: confirmPassword
      },
      config: {
        timeout: 15000
      }
    }, (res) => {
      resolve(res.data)
    }, (err) => {
      reject(err?.response?.data ?? err)
    })
  })
}

export default {
  login,
  loginWithMagicLink,
  authenticateUsingMagicLink,
  getUser,
  register,
  forgotPassword,
  resetPasswordValidate,
  resetPassword,
  requestMagicLink
}
