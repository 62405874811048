<template>
  <div id="complete" class="wrapper">
    <template>
      <div class="content__area --success">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <h2
          class="content__title content__title--desktop"
          v-html="$translate('register.complete.title_2')"
        />
      </div>

      <div class="content__area drawer max-height-mobile">
        <div class="content center-align">
          <h2
            class="content__title content__title--mobile"
            v-html="$translate('register.complete.title_2')"
          />

          <div class="drawer-icon margin-bottom-x5">
            <img src="/img/registration/success/success.svg" alt="Registration Success">
          </div>

          <strong class="subtitle">{{ $translate('register.complete.subtitle') }}</strong>
          <p class="text">{{ $translate('register.complete.content_1') }}</p>
          <p class="text">{{ $translate('register.complete.content_2') }}</p>

        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  methods: {
    redirectToHomebase () {
      window.location.href = process.env.KRYPTON_URL
    }
  }
}
</script>

<style lang="scss" scoped>

#register-new .wrapper .content__area:first-of-type {
    background: linear-gradient(123deg, #06D6A0, #FFD600);

    @media screen and (max-width: 480px) {
      height: 30vh;
    }

    @media screen and (max-width: 375px) {
      height: 14vh;
    }
}
</style>
