var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "preloader-wrapper active" }, [
    _c("div", { staticClass: "spinner-layer", class: _vm.spinnerColor }, [
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "circle-clipper left" }, [
      _c("div", { staticClass: "circle" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gap-patch" }, [
      _c("div", { staticClass: "circle" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "circle-clipper right" }, [
      _c("div", { staticClass: "circle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }