/* eslint-disable max-len */
'use strict'

function isMobile () {
  return /Android|SamsungBrowser|webOS|iPhone|iPad|Opera Mini/i.test(navigator.userAgent)
}

function isAndroidChrome () {
  return navigator.userAgent.search(/Chrome/i) >= 0 &&
         navigator.userAgent.search(/Android/i) >= 0 &&
         navigator.userAgent.search(/SamsungBrowser/i) === -1
}

function isiPhoneSafari () {
  return navigator.userAgent.search(/CriOS/i) === -1 &&
         navigator.userAgent.search(/iPhone|iPad/i) >= 0
}

const InstallChromeBanner = (function (document) {
  const blurElement = '#app'
  const defaultConfig = {
    iconURL: `${window.location.origin}/img/pwa/ic-chrome-logo.svg`,
    // linkURL: (isAndroidSamsung) ? 'https://play.google.com/store/apps/details?id=com.android.chrome' : 'market://details?id=com.android.chrome',
    linkURL: 'https://play.google.com/store/apps/details?id=com.android.chrome',
    linkText: 'Open Google Chrome',
    instructionText: 'Your Krypton is optimised for Google Chrome<br> For best experience, log in using using Google Chrome browser.'
  }

  const TEMPLATE = `
    <div id="install-chrome-banner">
      <div class="title">
        <div class="app-icon">
          <img class="responsive-img" src="{iconURL}" />
        </div>
      </div>
      <div class="instruction">
        <div class="homescreen-text">
          {instructionText}
        </div><br>
        <a href="{linkURL}" class="app-store-btn btn display-block uppercase">
          {linkText}
        </a>
      </div>
    </div>
  `

  /**
   * Show PWA popup.
  */
  function showOverlay () {
    document.getElementById('install-chrome-banner').style.display = 'block'
    document.querySelector('#app').style.display = 'none'
    document.querySelector(blurElement).classList.add('blur')
  }

  /**
   * Hide PWA popup.
  */
  function hideOverlay () {
    if (!defaultConfig.showOnReload) {
      localStorage.setItem('overlayStatus', 'hidden')
    }

    document.getElementById('install-chrome-banner').style.display = 'none'
    document.querySelector('#app').style.display = 'block'
    document.querySelector(blurElement).classList.remove('blur')
  }

  /**
   * Update string using regexp.
   *
   * @param {Object} obj
   * @param {String} str
   *
   * @return {String}
  */
  function replaceString (obj, str) {
    let retStr = str

    Object.keys(obj).forEach(function (key) {
      const regexPattern = '{' + key + '}'

      retStr = retStr.replace(new RegExp(regexPattern, 'g'), obj[key])
    })

    return retStr
  }

  /**
   * Initialize DOM.
   *
   * @param {Object} config
   */
  function initDom (config) {
    const dom = replaceString(config, TEMPLATE)
    const wrapper = document.createElement('div')

    wrapper.innerHTML = dom

    document.body.appendChild(wrapper)

    showOverlay()
  }

  return {
    init: function () {
      const isVerifyEmailPath = window.location.pathname === '/verify-email'

      if (!isVerifyEmailPath) {
        if (!isAndroidChrome() && !isiPhoneSafari() && isMobile()) {
          const config = arguments.length <= 0 ||
                         // eslint-disable-next-line prefer-rest-params
                         arguments[0] === undefined ? defaultConfig : arguments[0]

          initDom(Object.assign(defaultConfig, config))
        }
      }
    },
    hideOverlay: hideOverlay,
    showOverlay: showOverlay
  }
})(document)

module.exports = InstallChromeBanner
