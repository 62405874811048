import CookieService from '../services/CookieService'
import IncognitoService from '../services/IncognitoService'
import MonitoringService from '../services/MonitoringService'

const CreateIncognitoCookie = () => {
  const incognitoInterval = 60000 // every minute
  const destroyIncognitoInterval = IncognitoService.start(incognitoInterval, (browserName, isPrivate) => {
    const isTrue = 1
    const isFalse = 0
    const lifetime = -1 // session
    const cookieService = new CookieService(process.env.FE_COOKIE_DOMAIN || process.env.VUE_APP_FE_COOKIE_DOMAIN)
    cookieService.setCookie('privateMode', isPrivate ? isTrue : isFalse, lifetime, '/', null, false, true, 'Lax')
    MonitoringService.setAttribute('privateMode', isPrivate ? 'true' : 'false')
  })

  window.addEventListener('beforeunload', () => {
    destroyIncognitoInterval()
  })
}

// on window destroy event

export default { CreateIncognitoCookie }
