import { buildParam } from '@/services/ApiService'
export const vipPhotosSettingPath = '/v2/vip-photos/toggle'

export const toggleVipPhotosSetting = () => {
  const url = buildParam(`${process.env.KRYPTON_PROVIDER_API}${vipPhotosSettingPath}`)
  return new Promise((resolve, reject) => {
    window.api.post({
      url,
      payload: {
        _method: 'PUT'
      }
    },
    (success) => { resolve(success.data) },
    (err) => { reject(err.response.data) })
  })
}
