var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "root" }, [
    _c("div", { attrs: { id: "not-found" } }, [
      _c("div", { staticClass: "container", attrs: { id: "content" } }, [
        _c("div", { staticClass: "main" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("h2", [_vm._v(_vm._s(_vm.$t("title")))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.$t("subtitle")))])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "footer" }, [
          _c(
            "a",
            {
              staticClass: "dashboard-link",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.gotoPage("dashboard")
                }
              }
            },
            [_c("strong", [_vm._v(_vm._s(_vm.$t("cta_btn")))])]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "not-found-icon" }, [
      _c("img", {
        staticClass: "responsive-img",
        attrs: { src: "/img/icons/k-logo-new.svg", alt: "k-logo-new" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }