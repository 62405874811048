import { mapGetters } from 'vuex'

import { LITE_EVENTS } from '@/features/lite/constants'

export default {
  computed: {
    ...mapGetters({
      isVideoRequestEnabled: 'getIsVideoRequestEnabled',
      isCandidateProfile: 'isCandidateProfile',
      user: 'getUser'
    }),
    FEATURE_VIDEO_DATE () {
      return this.$store.getters['featureflag/isEnabled']('VIDEO_DATE')
    },
    isVideoDateEnabled () {
      return this.isVideoRequestEnabled && this.FEATURE_VIDEO_DATE
    },
    isConnectEnabled () {
      return this.user?.settings?.connect_enabled === 1
    },
    isPrivatePlaceEnabled () {
      return this.user?.settings?.private_place_enable === 1
    },
    isMultiLocationEnabled () {
      return this.user?.settings?.multi_location_enabled === 1
    },
    isMultiLocationSupported () {
      return this.$store.getters['multiLocation/isMultiLocationSupported']
    },
    shouldDisplayMultiLocationPromo () {
      return this.isMultiLocationSupported &&
        this.user._has_successful_meeting &&
        !this.isMultiLocationEnabled &&
        !this.isCandidateProfile
    },
    shouldDisplayPrivatePlacePromo () {
      return !this.isPrivatePlaceEnabled && !this.isCandidateProfile
    },
    shouldDisplayConnectPromo () {
      return !this.isConnectEnabled && !this.isCandidateProfile
    },
    filteredItems () {
      return this.items
        .filter(i => (this.isVideoDateEnabled) ? true : !i.is_video_request_feature)
    },
    isEmptyList () {
      return this.filteredItems.length === 0
    },
    isFeatureLocked () {
      return this.isCandidateProfile
    },
    items () {
      const newsItems = [
        {
          item: 'news_4',
          type: 'news',
          image: 'img/super-provider/news-banner.svg',
          isMultiLocationFeature: true,
          disabled: this.isCandidateProfile,
          onclick: this.gotoSuperProviderPage,
          dataTestName: 'super-provider-news-item'
        }
      ]

      newsItems.unshift({
        item: 'news_5',
        type: 'news',
        image: 'img/affiliate/affiliate-news.svg',
        isMultiLocationFeature: true,
        disabled: this.isCandidateProfile,
        onclick: this.gotoAffiliatePage,
        dataTestName: 'affiliate-news-item'
      })

      newsItems.unshift({
        item: 'news_super_provider',
        type: 'promotional',
        image: 'img/super-provider/super-provider-diamonds.svg'
      })

      if (this.shouldDisplayMultiLocationPromo) {
        newsItems.unshift({
          item: 'news_multi_location',
          type: 'promotional',
          image: 'img/multi-location/promo-image-icon.svg'
        })
      }

      if (this.shouldDisplayPrivatePlacePromo) {
        newsItems.unshift({
          item: 'news_private_place',
          type: 'promotional',
          image: 'img/private-place/promo-image-icon.svg',
          disabled: this.isFeatureLocked
        })
      }

      if (this.shouldDisplayConnectPromo) {
        newsItems.unshift({
          item: 'news_promo',
          type: 'promotional',
          image: 'img/krypton-connect/promo-image-icon.svg',
          disabled: this.isFeatureLocked
        })
      }

      return newsItems
    }
  },
  methods: {
    gotoVideoDatePage () {
      if (this.isCandidateProfile) {
        $h.EventBus.$emit(LITE_EVENTS.ONFEATURELOCK)
      } else {
        this.$router.push({ name: 'video-date' })
      }
    },
    gotoPrivatePlacePage () {
      if (this.isCandidateProfile) {
        $h.EventBus.$emit(LITE_EVENTS.ONFEATURELOCK)
      } else {
        this.$router.push({ name: 'private-place-overview' })
      }
    },
    gotoMultiLocatonPage () {
      if (this.isCandidateProfile) {
        $h.EventBus.$emit(LITE_EVENTS.ONFEATURELOCK)
      } else {
        this.$router.push({ name: 'multi-location-overview' })
      }
    },
    gotoSuperProviderPage () {
      if (this.isCandidateProfile) {
        $h.EventBus.$emit(LITE_EVENTS.ONFEATURELOCK)
      } else {
        this.$router.push({ name: 'super-provider-overview' })
      }
    },
    gotoAffiliatePage () {
      if (this.isCandidateProfile) {
        $h.EventBus.$emit(LITE_EVENTS.ONFEATURELOCK)
      } else {
        this.$router.push({ name: 'affiliate-overview' })
      }
    }
  }
}
