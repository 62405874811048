var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "account-setting" } },
    [
      _c("md-new-navbar", { attrs: { "route-name": "setting" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container account-overview", attrs: { id: "content" } },
        [
          _c("h1", { staticClass: "account-overview__title" }, [
            _vm._v(_vm._s(_vm.$translate("setting.contact_details")))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "account-overview__item" }, [
            _c("div", { staticClass: "account-overview__item-field" }, [
              _c("div", { staticClass: "account-overview__item-label" }, [
                _vm._v(_vm._s(_vm.$translate("setting.mobile_phone_label")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "account-overview__item-value" }, [
                _vm._v(_vm._s(_vm.maskedPhone))
              ])
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "account-overview__item-cta",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return (function() {
                      return (_vm.isMobileSettingShown = true)
                    })($event)
                  }
                }
              },
              [_c("strong", [_vm._v(_vm._s(_vm.$translate("app.edit")))])]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "account-overview__item" }, [
            _c("div", { staticClass: "account-overview__item-field" }, [
              _c("div", { staticClass: "account-overview__item-label" }, [
                _vm._v(_vm._s(_vm.$translate("setting.email_label")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "account-overview__item-value" }, [
                _vm._v(_vm._s(_vm.user.email))
              ])
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "account-overview__item-cta",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return (function() {
                      return (_vm.isEmailSettingShown = true)
                    })($event)
                  }
                }
              },
              [_c("strong", [_vm._v(_vm._s(_vm.$translate("app.edit")))])]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "account-overview__item" }, [
            _c("div", { staticClass: "account-overview__item-field" }, [
              _c("div", { staticClass: "account-overview__item-label" }, [
                _vm._v(_vm._s(_vm.$translate("setting.password_label")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "account-overview__item-value" }, [
                _vm._v(_vm._s(_vm.password))
              ])
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "account-overview__item-cta",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return (function() {
                      return (_vm.isPasswordSettingShown = true)
                    })($event)
                  }
                }
              },
              [_c("strong", [_vm._v(_vm._s(_vm.$translate("app.edit")))])]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("mobile-setting", {
        attrs: { "is-shown": _vm.isMobileSettingShown },
        on: {
          close: function() {
            return (_vm.isMobileSettingShown = false)
          }
        }
      }),
      _vm._v(" "),
      _c("email-setting", {
        attrs: { "is-shown": _vm.isEmailSettingShown },
        on: {
          close: function() {
            return (_vm.isEmailSettingShown = false)
          },
          "success-request": function(email) {
            _vm.isEmailSettingModalShown = true
            _vm.sendEmailTo = email
          }
        }
      }),
      _vm._v(" "),
      _c("password-setting", {
        attrs: { "is-shown": _vm.isPasswordSettingShown },
        on: {
          close: function() {
            return (_vm.isPasswordSettingShown = false)
          }
        }
      }),
      _vm._v(" "),
      _c("email-setting-modal", {
        attrs: {
          "is-shown": _vm.isEmailSettingModalShown,
          email: _vm.sendEmailTo
        },
        on: {
          confirm: function() {
            return (_vm.isEmailSettingModalShown = false)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }