var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal photo-error-modal",
      attrs: { id: "rejected-photos" }
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", [
          _c("p", { staticClass: "status-label" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$translate("photos.v2.rejected_photos.title")) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "status-text" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$translate("photos.v2.rejected_photos.content_1")) +
                "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "rejected-photos-list" },
          _vm._l(_vm.rejectedPhotos, function(photos, idx) {
            return _c("li", { key: idx }, [
              _c("div", {
                staticClass: "rejected-photo",
                style: _vm.createBG(photos._url)
              }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(photos.reject_reason))])
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn display-block modal-close",
            on: { click: _vm.confirm }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$translate("photos.v2.replace_popup.ok")) +
                "\n    "
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }