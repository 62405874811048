var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal center-align id-verify-success",
      attrs: { id: "verify-id-success-modal" }
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$translate("verify.verification_success.title")) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "margin-y-lg",
          attrs: { src: "/img/verify/id-verify-success.svg" }
        }),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$translate("verify.verification_success.explanation_text")
              ) +
              "\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "new-modal-footer" }, [
        _c("a", { attrs: { href: "#" }, on: { click: _vm.confirm } }, [
          _c("strong", [
            _vm._v(_vm._s(this.$translate("events.closed_event_popup.button")))
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }