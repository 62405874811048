export const DEFAULT_BILLING_OVERVIEW = {
  bank_details: {
    account_name: '',
    account_bank: '',
    account_iban: '',
    account_swift: ''
  },
  unpaid_balance: 0,
  credit_balance: 0,
  currency_symbol: '',
  currency_code: ''
}

export const INVOICE = {
  invoice_number: '',
  amount: 0,
  status: 0,
  _invoice_credits: 0,
  created_at: null,
  paid_at: null,
  _invoice_age: null,
  _status_text: '',
  _expanded: false
}

export const PAGINATION = {
  count: null,
  current_page: 1,
  per_page: null,
  results: null,
  pages: null,
  offset: null,
  limit: null
}
