import moment from 'moment'

export const fakeEvents = [
  {
    id: 1396,
    provider_id: 2,
    app_locale: 'de',
    room_number: null,
    room_number_comment: null,
    meeting_start_at: '2020-06-10 21:00:00',
    _meeting_start_at: moment('2020-06-10 21:00:00'),
    meeting_duration: 4,
    extension: 0,
    client_confirmed: 0,
    client_check_in: null,
    provider_check_in: null,
    provider_check_out: null,
    dress_code: 'business',
    client_identifier: null,
    custom_notes: null,
    meeting_point: null,
    meeting_point_details: null,
    status: 3,
    amount_payable: 2400,
    travel_cost: 0,
    client_delay: 0,
    provider_delay: 0,
    client_delay_seen: -1,
    event_expired_at: '2020-06-10 00:07:49',
    _event_expired_at: moment('2020-06-10 00:07:49'),
    event_seen: 1,
    pending_seen: 1,
    is_expired_seen: 1,
    is_tooltip_seen: 0,
    reactivation_status: 0,
    reactivation_failed_seen: -1,
    ready_to_go_override: 0,
    client: {
      id: 18,
      name: 'Kay',
      age: 38,
      lang: 'de'
    },
    amount_payable_extension: null,
    _hotel_name: 'Pullman Berlin Schweizerhof',
    _hotel_address: 'Budapester Straße 25, 10787 Berlin',
    _event_status: 4,
    _have_met_before: 0,
    _updates: [],
    _expiration_in_seconds: 999999,
    _meeting_start_in_seconds: 0,
    _check_in_seconds: null,
    _provider_name: 'Laila',
    _unread_message_count: 0,
    _feedback_metadata: {
      show: 0,
      expiration_in_seconds: null
    },
    _reactivation_expiration_in_seconds: null,
    _can_reactivate: 1,
    _support: null
  }
]
