<template>
  <div class="super-provider-card super-provider-card__req margin-bottom-md" :class="cardStatusClass" id="unpaid-invoices">
    <h2 class="super-provider-card__title margin-bottom-lg" v-text="title" data-test="title"/>
    <div class="super-provider-card__box-content invoices">
      <div class="invoice__status" data-test="invoice_section">
        <i class="material-icons icon-status margin-right-xs">description</i>
        <div>
          <span v-if="onTrack && !hasUnpaidInvoiceWithAgeLessThanThreshold" invoice__no-invoice>{{ $t('statusChecks.unpaidInvoices.noInvoices') }}</span>
          <template v-else-if="hasUnpaidInvoiceWithAgeLessThanThreshold">
            <strong class="invoice__title">{{ $t('statusChecks.unpaidInvoices.oldestInvoice') }}</strong>
            <p class="invoice__number" data-test="invoice_number">INV {{ invoiceNumber }}</p>
          </template>
          <template v-else-if="hasUnpaidInvoiceWithAgeMoreThanThreshold">
            <strong class="invoice__title">{{ $t('statusChecks.unpaidInvoices.oldestInvoice') }}</strong>
            <p class="invoice__number" data-test="invoice_number">INV {{ invoiceNumber }}</p>
          </template>
        </div>
      </div>
      <div>
        <button class="btn-status btn-status--warning" type="button" data-test="btn_status">{{ btnStatusText }} <span v-if="hasUnpaidInvoiceWithAgeLessThanThreshold" data-test="overdue_in">{{ overdueIn }}d</span></button>
      </div>
    </div>
    <div class="super-provider-card__box-content" v-html="$t('statusChecks.unpaidInvoices.boxContent')" data-test="explanation_text"/>
    <div class="super-provider-card__tooltip-trigger margin-top-md" data-test="tooltip" @click="showTooltip">
      <span class="super-provider-card__tooltip-trigger-text" v-text="tooltipText" />
      <i class="material-icons">help_outline</i>
    </div>
    <div class="super-provider-card__req-status" data-test="status">
      <span v-text="cardStatusText" />
      <i class="material-icons">{{ cardStatusIcon }}</i>
    </div>
  </div>
</template>

<i18n src="@/features/super-provider/translations/super-provider-card.json"></i18n>

<script>
import { tooltipPlugin } from '@/features/super-provider/plugins/vue-custom-tooltip.js'
import {
  DEFAULT_SUPER_PROVIDER_REQUIREMENTS,
  DEFAULT_SUPER_PROVIDER_STATUS,
} from '@/features/super-provider/constants/super-provider-defaults.js'

export default {
  inject: {
    superProviderData: {
      default: () => ({
        superProviderRequirements: DEFAULT_SUPER_PROVIDER_REQUIREMENTS,
        superProviderStatus: DEFAULT_SUPER_PROVIDER_STATUS,
      })
    }
  },
  computed: {
    maxUnpaidInvoiceDays () {
      const {
        unpaid_invoices: {
          subsettings: {
            unpaid_invoices_overdue
          }
        }
      } = DEFAULT_SUPER_PROVIDER_REQUIREMENTS

      return parseFloat(this.superProviderData?.superProviderRequirements?.unpaid_invoices?.subsettings?.unpaid_invoices_overdue?.value ?? unpaid_invoices_overdue.value)
    },
    invoiceNumber () {
      return this.superProviderData?.superProviderStatus?._oldest_invoice?.invoice_number
    },
    invoiceAge () {
      return parseFloat(this.superProviderData?.superProviderStatus?._oldest_invoice?.invoice_age)
    },
    title () {
      return this.$t('statusChecks.unpaidInvoices.title')
    },
    tooltipText () {
      return this.$t('statusChecks.unpaidInvoices.tooltipText')
    },
    tooltipExplanation () {
      return this.$t('statusChecks.unpaidInvoices.tooltipExplanation')
    },
    onTrack () {
      return this.invoiceNumber === ""
    },
    hasUnpaidInvoiceWithAgeLessThanThreshold () {
      return this.invoiceNumber !== "" && this.invoiceAge >= 0 && this.invoiceAge <= this.maxUnpaidInvoiceDays
    },
    hasUnpaidInvoiceWithAgeMoreThanThreshold () {
      return this.invoiceNumber !== "" && this.invoiceAge > this.maxUnpaidInvoiceDays
    },
    cardStatusIcon () {
      if (this.onTrack && !this.hasUnpaidInvoiceWithAgeLessThanThreshold) {
        return 'check'
      } else if (this.hasUnpaidInvoiceWithAgeLessThanThreshold) {
        return 'priority_high'
      } else if (this.hasUnpaidInvoiceWithAgeMoreThanThreshold) {
        return 'clear'
      }

      return 'clear'
    },
    overdueIn () {
      if (this.hasUnpaidInvoiceWithAgeLessThanThreshold) {
        return parseInt(this.maxUnpaidInvoiceDays - this.invoiceAge)
      }

      return 0
    },
    cardStatusText () {
      if (this.onTrack && !this.hasUnpaidInvoiceWithAgeLessThanThreshold) {
        return this.$t('statusText.onTrack')
      } else if (this.hasUnpaidInvoiceWithAgeLessThanThreshold) {
        return this.$t('statusText.danger')
      } else if (this.hasUnpaidInvoiceWithAgeMoreThanThreshold) {
        return this.$t('statusText.notMet')
      }

      return this.$t('statusText.notMet')
    },
    cardStatusClass () {
      if (this.onTrack && !this.hasUnpaidInvoiceWithAgeLessThanThreshold) {
        return '--success'
      } else if (this.hasUnpaidInvoiceWithAgeLessThanThreshold) {
        return '--danger'
      } else if (this.hasUnpaidInvoiceWithAgeMoreThanThreshold) {
        return '--warning'
      }

      return '--warning'
    },
    btnStatusText () {
      if (this.onTrack && !this.hasUnpaidInvoiceWithAgeLessThanThreshold) {
        return this.$t('statusChecks.unpaidInvoices.allPaid')
      } else if (this.hasUnpaidInvoiceWithAgeLessThanThreshold) {
        return this.$t('statusChecks.unpaidInvoices.overdueIn')
      } else if (this.hasUnpaidInvoiceWithAgeMoreThanThreshold) {
        return this.$t('statusChecks.unpaidInvoices.payAsap')
      }

      return this.$t('statusChecks.unpaidInvoices.payAsap')
    }
  },
  methods: {
    showTooltip () {
      tooltipPlugin.toast(this.tooltipExplanation)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/features/super-provider/sass/super-provider-card";

#unpaid-invoices {
  .invoices{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;

    .invoice {
      &__status {
        display: flex;
        align-items: center;

        div {
          text-align: left;
        }
      }

      &__title {
        font-family: 'Proxima Nova Semibold', sans-serif;
      }

      &__number {
        font-family: 'Proxima Nova', sans-serif;
        font-size: 10px;
        margin: 0;
        color: #78838F;
      }
    }

    /* i {
      color: #F9A825;
    }

    span {
      line-height: 12px;
    }

    strong {
      font-family: 'Proxima Nova Semibold', sans-serif;
    } */
  }
}
</style>
