var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "actions flex-grid",
      class: {
        "space-between": !_vm.noPrev && !_vm.noNext,
        "justify-start": !_vm.noPrev && _vm.noNext,
        "justify-end": _vm.noPrev && !_vm.noNext
      },
      attrs: { id: "actions" }
    },
    [
      _c("register-stepper-v2", {
        attrs: { total: _vm.total, current: _vm.current }
      }),
      _vm._v(" "),
      !_vm.noPrev
        ? _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              attrs: { disabled: _vm.prevDisabled },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.onPrev($event)
                }
              }
            },
            [_vm._v("\n    " + _vm._s(_vm.prevText) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.noNext
        ? _c(
            "button",
            {
              staticClass: "btn btn-black",
              attrs: {
                disabled: _vm.nextDisabled,
                "data-test": "navigator-cta"
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.onNext($event)
                }
              }
            },
            [_vm._v("\n    " + _vm._s(_vm.nextText) + "\n  ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }