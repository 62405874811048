import Store from '../stores/index.js'
import RegisterSteps from '@/constants/registration/steps'
import { attemptLocaleCheck } from './localeCheck.js'

export default function redirectIfAuthenticated (to, from, next) {
  var onTokenChecked = () => {
    attemptLocaleCheck(to)
    if (window.api.headers.Authorization === null || window.api.headers.Authorization === undefined) {
      next()
    } else {
      if (Store.state.user.register_progress === 0) {
        next({ name: 'dashboard' })
      } else {
        next(RegisterSteps[Store.state.user.register_progress])
      }
    }
  }

  if (window.api.headers.hasOwnProperty('Authorization')) {
    onTokenChecked()
  }

  document.addEventListener('onTokenChecked', onTokenChecked)
}
