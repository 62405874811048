var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page", attrs: { id: "connect-faq" } }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("faq-header", {
          attrs: {
            "header-title": _vm.$translate("krypton_connect.header.title"),
            "header-tagline": _vm.$translate(
              "krypton_connect.header.faq_explanation"
            ),
            "main-header-logo": "/img/krypton-connect/header-img-v2.svg",
            "tagline-logo": "/img/krypton-connect/header-chat-img-v2.svg",
            "primary-header-color": "#2D69E0",
            "secondary-header-color": "#46E4E9",
            "header-gradient-angle": "63deg",
            "tagline-gradient-angle": "48deg",
            "header-img-dimensions": { height: "107px", width: "125px" }
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "container", attrs: { id: "content" } },
          [
            _c("div", { staticClass: "faq" }, [
              _c(
                "ul",
                { staticClass: "q-and-a" },
                _vm._l(_vm.Q_AND_AS, function(q_and_a, i) {
                  return _c("li", { key: i }, [
                    _c("div", { staticClass: "question" }, [
                      _c("span", { staticClass: "question-number" }, [
                        _vm._v(_vm._s(_vm.getItemNumber(i)) + ".")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$translate(
                              "krypton_connect.q_and_a.question_" + q_and_a
                            )
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "answer" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$translate(
                              "krypton_connect.q_and_a.answer_" + q_and_a
                            )
                          ) +
                          "\n              "
                      ),
                      _vm.isLastItem(i)
                        ? _c("span", [
                            _c(
                              "a",
                              {
                                staticClass: "support",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.$router.push({
                                      name: "support-v2"
                                    })
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$translate("krypton_connect.support")
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _vm._v(".")
                          ])
                        : _vm._e()
                    ])
                  ])
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("faq-cta", {
              attrs: {
                "route-name": "krypton-connect-setting",
                "cta-txt": _vm.$translate("faq.connect_faq.cta"),
                "background-gradient-class": "--blue"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }