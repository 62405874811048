<template>
  <div
    id="crop"
    class="wrapper"
  >
    <div class="photo-container__overlay" />
    <div id="photo-drawer" class="photo-container__drawer">
      <div class="content" style="max-width: unset;">
        <div class="photo-container__header" style="border-bottom: none; justify-content: space-between;">
          <a href="#" @click.prevent="triggerCancelUpload">
            <i class="material-icons" style="margin-right: 8px;">close</i>
            <span class="cancel-upload-label">{{ $translate('photos.cancel_upload') }}</span>
          </a>
        </div>

        <div id="content" class="hide"><vform ref="cropForm" @submit="crop" /></div>

        <div v-if="isCroppieViewable" class="crop-container">
          <vue-croppie
            ref="croppie"
            :show-zoomer="false"
            :enable-resize="false"
            :enable-exif="true"
            :enable-orientation="true"
            :boundary="croppieBoundary"
            :viewport="croppieViewport"
          />

          <div class="crop-controls hide-on-med-and-down">
            <button class="crop-controls-btn__zoom-in" @click.prevent="adjustZoom(true)">
              <!-- <i class="material-icons">add</i> -->
            </button>
            <button class="crop-controls-btn__zoom-out" @click.prevent="adjustZoom(false)">
              <!-- <i class="material-icons">remove</i> -->
            </button>
          </div>
        </div>

        <div class="photo-text">
          <strong>{{ $translate('photos.pinch_zoom_to_crop') }}</strong>
        </div>

        <register-navigator-v2
          class="photo-container__footer"
          :no-prev="true"
          @next.prevent="getCropData"
        />
      </div>
    </div>
    <md-screen-preloader v-if="loading" />

    <!-- <template v-else>
      <div id="content" class="hide"><vform ref="cropForm" @submit="crop" /></div>
      <md-navbar icon="close"
                 :brand="$translate('photos.cancel_upload')"
                 :push="$route.params.cancel"
                 :photo-id="photo.id"
                 :menu-trigger="showMenuTrigger" />
      <div class="container">
        <vue-croppie ref="croppie"
                     :show-zoomer="false"
                     :enable-resize="false"
                     :enable-exif="true"
                     :enable-orientation="true"
                     :boundary="croppieBoundary"
                     :viewport="croppieViewport" />
        <div class="crop-controls hide-on-med-and-down">
          <button class="btn black-text grey lighten-2" @click.prevent="adjustZoom(true)">
            <i class="material-icons">add</i>
          </button>
          <button class="btn black-text grey lighten-2" @click.prevent="adjustZoom(false)">
            <i class="material-icons">remove</i>
          </button>
        </div>
        <div class="padding-md">
          <a href="#"
             class="right padding-sm border-rad-xs krypton-pink-text waves-effect waves-dark valign-middle"
             @click.prevent="getCropData">
            <small class="xstrong uppercase">{{ $translate('app.next') }}</small>
          </a>
          <span class="padding-sm border-rad-xs grey-text text-darken-1 valign-middle">
            <i class="material-icons" />
            <small class="xstrong">{{ $translate('photos.pinch_zoom_to_crop') }}</small>
          </span>
        </div>
      </div>
      <md-screen-preloader v-if="loading" />
    </template> -->
  </div>
</template>

<script>
import CropController from '@components/account/photos/crop.controller'

export default {
  mixins: [CropController]
}
</script>

<style lang="scss">
@import "./photo-drawer"
</style>
