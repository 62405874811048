<template>
    <div id="interview">
        <md-navbar :push="{ name: 'profile' }" :brand="$translate('profile.title')" icon="arrow_back"></md-navbar>
        <div v-if="user && dropdown && interviewOpts.length" id="content" class="container">
            <h1 class="title">{{ (hasInterview) ? $translate('interview.header') : $translate('interview.header_add') }}</h1>
            <vform ref="form" @submit="save">
                <div v-if="errors.has('interview')"
                    class="grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align">
                    <small>{{ errors.first('interview') }}</small>
                </div>
                <div v-for="(interview, index) in interviews" class="margin-bottom-md">
                    <div class="left-align">
                        <a href="#" class="underline valign-middle strong small margin-bottom" @click.prevent="triggerSelect(index)">
                            {{ $store.getters.getDropdownInterviews(interview.title) }}
                            <i class="material-icons" style="font-size: 18px">edit</i>
                        </a>
                        <md-select v-model="interview.title"
                            class="hide"
                            force-mobile="true"
                            :ref="createSelectRef(index)"
                            :label="$translate('interview.question')"
                            :placeholder="$translate('interview.select_question')"
                            @change="evaluateInterviewOpts">
                            <option slot="options"
                                v-for="i in interviewOpts"
                                :value="i.key"
                                :disabled="i.disabled && i.key != interview.title">{{ i.content }}</option>
                        </md-select>
                    </div>
                    <md-textarea type="multiline"
                        v-model="interview.answer"
                        :maxlength="answerMaxChar"
                        :label="$translate('interview.answer')">
                        <div>
                            <span class="input-helper grey-text text-darken-1 right">
                                {{ getMaxChar(interview.answer) }}
                            </span>
                        </div>
                    </md-textarea>
                    <div class="remove-btn margin-bottom" style="overflow: auto">
                        <a href="#"
                            class="grey-text text-darken-1 uppercase right"
                            @click.prevent="removeInterview(index)">
                            <small><strong>{{ $translate('app.remove') }}</strong></small>
                        </a>
                    </div>
                </div>
                <div class="right-align margin-bottom-md">
                    <a href="#"  @click.prevent="triggerSelect('add')">
                        <small><strong>+ {{ $translate('app.add_another') }}</strong></small>
                    </a>
                    <md-select class="hide"
                        force-mobile="true"
                        :ref="createSelectRef('add')"
                        :label="$translate('interview.question')"
                        :placeholder="$translate('interview.select_question')"
                        @change="addInterview">
                        <option slot="options"
                            v-for="i in interviewOpts"
                            :value="i.key"
                            :disabled="i.disabled">{{ i.content }}</option>
                    </md-select>
                </div>
            </vform>
            <h-footer-btns :cancel="$translate('app.cancel')"
                :confirm="$translate('app.save')"
                @cancel="$router.push({ name: 'profile' })"
                @confirm="$refs.form.submit()">
            </h-footer-btns>
        </div>
    </div>
</template>

<script>
const ANSWER_MAX_CHAR = 500;

const UserProvider   = $h.getProvider('UserProvider');
const LocaleProvider = $h.getProvider('LocaleProvider');

export default {
    watch: {
        dropdown() {
            this.initInterviewOpts();
        },
    },
    computed: {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
        user() {
            return this.$store.getters.getUser;
        },
        dropdown() {
            return this.$store.getters.getDropdownInterviews();
        },
        getCharCount() {
            return (answer) => {
                if (!answer) return 0;
                return answer.length;
            }
        },
        getMaxChar() {
            return (answer) => {
                return this.getCharCount(answer) + '/' + this.answerMaxChar;
            }
        },
        hasInterview() {
            if (LocaleProvider.isLocaleDe())
                return (this.user.profiles.interview_de);

            return (this.user.profiles.interview);
        },
    },
    methods: {
        save(next) {
            this.errors.clear();

            this.filterEmptyInterview();

            UserProvider.updateInterview({
                    interview: this.interviews
                })
                .then((res) => {
                    this.$notifications.toast(this.$translate('profile.profile_updated'), 5000);
                    this.$store.commit('setInterview', res.data.interview);
                    this.$store.commit('setInterviewDe', res.data.interview_de);
                    this.$router.push({ name: 'profile' });
                })
                .catch((err) => {
                    window.scrollTo(0,0);
                    $h.processErrors(this, err.data);
                })
                .finally(() => {
                    next();
                });
        },
        addInterview(question) {
            if (question === null) return false;
            if (this.interviews === null) this.interviews = [];

            this.interviews.push({ title: question, answer: null });
            this.evaluateInterviewOpts();
            this.$refs.selectadd.setModel(null);
        },
        removeInterview(index) {
            var interviews = _.clone(this.interviews);
            interviews.splice(index, 1);
            this.interviews = _.filter(interviews, (e) => { return e !== null; });
            this.evaluateInterviewOpts();
        },
        filterEmptyInterview() {
            if (!this.interviews.length) return false;
            for (var i = 0; i < this.interviews.length; i++) {
                if (!this.interviews[i].answer) {
                    this.interviews.splice(i, 1);
                }
            }
        },
        createSelectRef(index) {
            return 'select' + index;
        },
        triggerSelect(index) {
            if (index === 'add')
                var $select = this.$refs[this.createSelectRef(index)];
            else
                var $select = this.$refs[this.createSelectRef(index)][0];

            $($select.$el).find('input.select-dropdown').click();
        },
        initInterviews() {
            if (LocaleProvider.isLocaleDe() && this.user.profiles.interview_de) {
                this.interviews = $h.string2json(this.user.profiles.interview_de);
            } else {
                this.interviews = $h.string2json(this.user.profiles.interview);
            }
            return [];
        },
        initInterviewOpts() {
            if (!this.dropdown) return;
            this.interviewOpts = $h.obj2arr(this.dropdown);
            this.evaluateInterviewOpts();
        },
        evaluateInterviewOpts() {
            for (var index in this.interviewOpts) {
                var inInterviews = _.findIndex(this.interviews, (o) => {
                    return o.title === this.interviewOpts[index].key;
                });

                if (inInterviews === -1)
                    this.interviewOpts[index].disabled = false;
                else
                    this.interviewOpts[index].disabled = true;
            }
        }
    },
    data() {
        return {
            interviews:    [],
            interviewOpts: [],
            answerMaxChar: ANSWER_MAX_CHAR,
        }
    },
    mounted() {
        this.initInterviews();
        this.initInterviewOpts();
    }
}
</script>
