export default {
  methods: {
    adjustBannerBottomPosition () {
      const nav = document.getElementById('fixed-menu')

      if (nav) {
        this.$refs['banner-container']
          .style.bottom = '88px'
      }
    }
  }
}
