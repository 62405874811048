<template>
  <div
    id="verify-upload-slot"
    :class="{
      'empty': !imageSrc,
      'front-id': isFront,
      'back-id': !isFront,
      'repleaceable clickable': isReplaceable,
    }"
    @click="onClick"
  >
    <div v-if="hasImageSrc">
      <img
        :src="imageSrc"
        width="242"
        height="151"
      >
    </div>
    <button v-else class="btn-flat waves-effect waves-light">
      {{ placeholder }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    isFront: {
      type: Boolean,
      default: true
    },
    isReplaceable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasImageSrc () {
      return this.imageSrc !== null && this.imageSrc.length > 0
    }
  },
  methods: {
    onClick () {
      if (this.isReplaceable) {
        this.$emit('onclick')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#verify-upload-slot {
  position: relative;
  max-width: 242px;
  margin: auto;

  img, button {
    border-radius: 12px;
  }
  img {
    border-radius: 8px;
  }
  button {
    font-family: 'Proxima Nova Semibold';
    color: #2F3740;
    font-size: 16px;
    background-repeat: no-repeat;
    width: 242px;
    height: 151px;
    text-transform: capitalize;
  }
  &.repleaceable {
    &.front-id,
    &.back-id {
      &::after {
        content: "";
        position: absolute;
        background: url("/img/verify/remove-icon-v2.svg");
        background-repeat: no-repeat;
        bottom: 0;
        z-index: 10;
        width: 32px;
        height: 32px;
        margin: -6px;
        right: 0;
      }
    }
  }
  &.empty {
    &.front-id,
    &.back-id {
      &::after {
        content: "";
        position: absolute;
        background: url("/img/verify/add-icon-v2.svg");
        background-repeat: no-repeat;
        bottom: 0;
        z-index: 10;
        width: 32px;
        height: 32px;
        margin: -6px -26px;
        right: unset;
      }
    }
    &.front-id {
      button {
        background-position: center;
        background-size: 100%;
        background-image: url('/img/verify/select-id-front-placeholder-v2.svg');
      }
    }
    &.back-id {
      button {
        background-position: bottom;
        background-size: 100%;
        background-image: url('/img/verify/select-id-back-placeholder-v2.svg');
      }
    }
  }
}
</style>
