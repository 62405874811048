export default {
  globals: {
    welcome: 'Hello World!',
    dashboard: 'Dashboard',
    continue: 'CONTINUE'
  },
  pwa: {
    appName: 'Krypton App',
    appDescription: 'Manage your profile and appointments',
    menuIconText1: 'To install tap',
    menuIconText2: 'below and choose',
    menuIconText3: 'Add to Home Screen',
    menuIconText1Android: 'Tap on',
    menuIconText2Android: 'and then add to',
    menuIconText3Android: 'homescreen',
    androidSuccess1: 'Congratulations!',
    androidSuccess2: 'You have installed the Krypton App.',
    androidSuccess3: 'To open the app, check out your homescreen and tap the Krypton Icon.',
    androidSuccess4: 'Can\'t find it?'
  }
}
