<template>
  <div class="super-provider-prerequire-card">
    <div class="super-provider-prerequire-card__body">
      <h2 class="super-provider-prerequire-card__body-title">
        <img src="/img/super-provider/super-provider-diamond.svg">
        <span v-text="$t('body.title.super')" />
        <span v-text="$t('body.title.provider')" />
      </h2>
      <p class="super-provider-prerequire-card__body-text" v-text="$t('body.text')" />
    </div>
    <div class="super-provider-prerequire-card__footer">
      <div class="super-provider-prerequire-card__footer-header">
        <span v-text="$t('benefits.title')" />
        <a href="#" @click.prevent="expandBenefits">
          <i class="material-icons">{{ benefitsIcons }}</i>
        </a>
      </div>
      <ul class="super-provider-prerequire-card__footer-list" :class="{ 'expanded': benefitsListExpanded }">
        <li v-for="(item, key) in $t('benefits.items')" :key="key">
          <h3><i class="material-icons">check_circle</i><span v-text="item.title" /></h3>
          <p v-text="item.content" />
        </li>
      </ul>
    </div>
  </div>
</template>

<i18n src="@/features/super-provider/translations/super-provider-prereq-card.json"></i18n>

<script>
export default {
  data: () => ({
    benefitsListExpanded: false
  }),
  computed: {
    benefitsIcons () {
      return this.benefitsListExpanded ? 'expand_less' : 'expand_more'
    }
  },
  methods: {
    expandBenefits () {
      this.benefitsListExpanded = !this.benefitsListExpanded
    }
  }
}
</script>

<style lang="scss" scoped>
.super-provider-prerequire-card {
  .super-provider-prerequire-card__body {
    padding: 24px 32px 36px;
    background: transparent linear-gradient(57deg, #845BE7 0%, #608AFF 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0px 0px;

    .super-provider-prerequire-card__body-title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0;
      margin-bottom: 24px;
      font-size: 22px;
      font-family: 'Proxima Nova Semibold', sans-serif;
      color: #FFFFFF;

      span,
      img {
        margin: 0 4px;
      }
    }
    .super-provider-prerequire-card__body-text {
      margin-bottom: 0;
      font-size: 16px;
      font-family: 'Proxima Nova', sans-serif;
      color: #FFFFFF;
    }
  }
  .super-provider-prerequire-card__footer {
    background: #776CF0 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 8px 8px;

    .super-provider-prerequire-card__footer-header {
      display: flex;
      justify-content: space-between;
      padding: 13px 16px 13px 24px;
      font-size: 16px;
      font-family: 'Proxima Nova Semibold';
      color: #FFFFFF;

      a {
        color: #FFFFFF;

        i.material-icons {
          display: block;
        }
      }
    }
    .super-provider-prerequire-card__footer-list {
      display: none;
      margin: 0;
      padding: 24px 13px 24px 24px;
      border-top: 1px solid rgba(#FFFFFF, 0.7);

      li {
        margin-bottom: 24px;
        color: #FFFFFF;

        h3 {
          display: flex;
          align-items: center;
          width: 100%;
          margin: 0 0 8px;
          font-size: 14px;
          font-family: 'Proxima Nova Semibold';

          i.material-icons {
            display: block;
            margin-right: 8px;
            color: #FFFFFF;
          }
        }
        p {
          margin: 0;
          font-size: 12px;
          font-family: 'Proxima Nova';
          color: rgba(#FFFFFF, 0.7);
          text-align: left;
        }
      }
      li:last-child {
        margin-bottom: 0;
      }
    }
    .super-provider-prerequire-card__footer-list.expanded {
      display: block;
    }
  }
}
</style>
