var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "id-verification", attrs: { id: "verify-pending" } },
    [
      _c("div", { staticClass: "container", attrs: { id: "content" } }, [
        _c("h5", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$translate("verify.verification_pending.title")) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("img", { attrs: { src: "/img/verify/id-verify-success.svg" } }),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$translate("verify.verification_pending.explanation_text")
              ) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "cta-fixed" }, [
          _c(
            "button",
            {
              staticClass:
                "action-btn action-btn__krypton action-btn__blocked waves-effect waves-light",
              on: { click: _vm.doContinue }
            },
            [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$translate("verify.verification_pending.cta_button")
                  )
                )
              ])
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }