const RMLComponent = require('@/features/auth/components/RequestMagicLink/RequestMagicLinkView.vue').default
const RMLSuccessComponent = require('@/features/auth/components/RequestMagicLink/RequestMagicLinkSuccess.vue').default
const redirectIfAuthenticated = require('@/middleware/redirectIfAuthenticated').default

export const requestMagicLink = {
  path: '/:lang(en)?/request-magic-link',
  name: 'request-magic-link',
  component: RMLComponent,
  beforeEnter: redirectIfAuthenticated
}

export const requestMagicLinkSuccess = {
  path: '/:lang(en)?/request-magic-link/sms-sent',
  name: 'request-magic-link-success',
  component: RMLSuccessComponent,
  beforeEnter: redirectIfAuthenticated
}
