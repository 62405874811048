<template>
  <div>
    <div class="navbar-fixed">
      <nav class="z-depth-0">
        <div class="nav-wrapper container">
          <a
            id="navbar-back-button"
            href="#"
            class="brand-logo align-center"
            :class="{ 'waves-effect waves-dark': hasWaves }"
            @click.prevent="redirect"
          >
            <img class="responsive-img" src="/img/back-arrow.svg" alt="back-btn">
            <strong>{{ brand }}</strong>
          </a>
          <ul
            v-if="showMenuTrigger"
            class="right"
          >
            <li
              v-if="shouldShowKrybo"
              class="padding-x-sm"
              style="position: relative;"
            >
              <div
                id="robot-icon"
                class="waves-effect waves-dark"
                @click.prevent="onKryboClick"
              />
              <span
                v-if="showPulse"
                class="pulse custom-pulse"
              />
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <md-sidenav />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const KRYBO_PATHS = [
  {
    path: 'photos',
    dest: {
      name: 'photo-guide',
      params: {
        return: 'photos-v2'
      }
    }
  },
  {
    path: 'photos-v2',
    dest: {
      name: 'photo-guide',
      params: {
        return: 'photos-v2'
      }
    }
  }
]

export default {
  props: [
    'go',
    'push',
    'icon',
    'brand',
    'menuTrigger',
    'photoId'
  ],
  computed: {
    ...mapGetters({
      user: 'getUser'
    }),
    hasWaves () {
      return (this.push != null || this.go != null)
    },
    showMenuTrigger () {
      if (this.menuTrigger === false || this.menuTrigger === 'off') {
        return false
      }

      return true
    },
    kryboPath () {
      return KRYBO_PATHS.find(p => p.path === this.$route.name)
    },
    shouldShowKrybo () {
      return this.kryboPath !== undefined
    },
    showPulse () {
      if (this.user) {
        if (this.user._show_better_photo_tip === 1) {
          return this.user.is_photo_tip_seen === 0
        } else {
          return false
        }
      }
      return false
    },
    canShowMenu () {
      if (this.$route.name === 'legal-terms-conditions') {
        return false
      }

      return true
    }
  },
  methods: {
    redirect () {
      if (this.photoId) {
        this.$store.dispatch('cancelUpload', this.photoId)
      }
      if (this.push) { this.$router.push(this.push) }
      if (this.go) { this.$router.go(this.go) }

      return false
    },
    onKryboClick () {
      this.$router.push({
        name: this.kryboPath.dest.name,
        params: this.kryboPath.dest.params
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.align-center {
  display: flex;
  align-items: center;
  padding: 0 !important;
}
</style>
