<template>
  <div id="fixed-menu">
    <ul class="menus">
      <li
        class="waves-effect waves-dark"
        :class="{
          'active': isCurrentRoute('dashboard') && !isSuperProvider,
          'super-provider': isCurrentRoute('dashboard') && isSuperProvider,
        }"
        data-test="menu-item-dashboard"
        @click.prevent="navigatePage('dashboard', false)"
      >
        <a href="#">
          <img :src="dashboardNavIcon" alt="home icon">
          <strong>{{ $translate('sidenav.dashboard') }}</strong>
        </a>
      </li>

      <li
        v-if="user.availability_enabled"
        class="waves-effect waves-dark"
        :class="{
          'active': isCurrentRoute('availability') && !isSuperProvider,
          'super-provider': isCurrentRoute('availability') && isSuperProvider,
        }"
        data-test="menu-item-availability"
        @click.prevent="navigatePage('availability', false)"
      >
        <a href="#">
          <img :src="availabilityNavIcon" alt="availability icon">
          <strong>{{ $translate('sidenav.availability') }}</strong>
        </a>
      </li>

      <li
        class="waves-effect waves-dark"
        :class="{
          'active': isCurrentRoute('inbox') && !isFeatureLocked && !isSuperProvider,
          'super-provider': isCurrentRoute('inbox') && isSuperProvider && !isFeatureLocked,
          'locked': isFeatureLocked,
          'has-notif': (hasInboxNotification || hasFeedbackUpdates || hasKryptonConnectNotification) && !isFeatureLocked,
        }"
        data-test="menu-item-inbox"
        @click="navigatePage('inbox', true, { filter: INBOX_FILTER.ALL })"
      >
        <a href="#">
          <img :src="inboxNavIcon" alt="inbox icon">
          <strong>{{ $translate('sidenav.inbox.title') }}</strong>
        </a>
      </li>

      <li
        v-if="user.events_enabled"
        class="waves-effect waves-dark"
        :class="{
          'active': isCurrentRoute('events') && !isFeatureLocked && !isSuperProvider,
          'super-provider': isCurrentRoute('events') && isSuperProvider && !isFeatureLocked,
          'locked': isFeatureLocked,
          'has-notif': hasEventUpdates
        }"
        data-test="menu-item-events"
        @click.prevent="navigatePage('events', true)"
      >
        <a href="#">
          <img :src="eventsNavIcon" alt="events icon">
          <strong>Events</strong>
        </a>
      </li>

      <li
        class="waves-effect waves-dark"
        :class="{
          'active': isCurrentRoute('menu') && !isSuperProvider,
          'super-provider': isCurrentRoute('menu') && isSuperProvider,
          'has-notif': showNotifOtherMenu,
        }"
        data-test="menu-item-other-menu"
        @click.prevent="navigatePage('menu', false)"
      >
        <a href="#">
          <img :src="otherMenuNavIcon" alt="home icon">
          <strong>Menu</strong>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { LITE_EVENTS } from '@/features/lite/constants'
import { isProviderProfile } from '@/constants/helpers'

import homeIcon from '@/components/v2/utilities/assets/menu/home.svg'
import homeActiveIcon from '@/components/v2/utilities/assets/menu/home-active.svg'
import homeGoldIcon from '@/components/v2/utilities/assets/menu/home-gold.svg'
import availabilityIcon from '@/components/v2/utilities/assets/menu/availability.svg'
import availabilityActiveIcon from '@/components/v2/utilities/assets/menu/availability-active.svg'
import availabilityGoldIcon from '@/components/v2/utilities/assets/menu/availability-gold.svg'
import inboxIcon from '@/components/v2/utilities/assets/menu/inbox.svg'
import inboxActiveIcon from '@/components/v2/utilities/assets/menu/inbox-active.svg'
import inboxLockedIcon from '@/components/v2/utilities/assets/menu/inbox-locked.svg'
import inboxGoldIcon from '@/components/v2/utilities/assets/menu/inbox-gold.svg'
import eventsIcon from '@/components/v2/utilities/assets/menu/events.svg'
import eventsActiveIcon from '@/components/v2/utilities/assets/menu/events-active.svg'
import eventsLockedIcon from '@/components/v2/utilities/assets/menu/events-locked.svg'
import eventsGoldIcon from '@/components/v2/utilities/assets/menu/events-gold.svg'
import otherMenuIcon from '@/components/v2/utilities/assets/menu/other-menu.svg'
import otherMenuActiveIcon from '@/components/v2/utilities/assets/menu/other-menu-active.svg'
import otherMenuGoldIcon from '@/components/v2/utilities/assets/menu/other-menu-gold.svg'

const { INBOX_FILTER } = require('@/features/inbox/constants')

export default {
  name: 'FixedMenu',
  data: () => ({
    INBOX_FILTER
  }),
  computed: {
    ...mapGetters({
      hasFeedbackUpdates: 'notification/GET_HAS_NEW_FEEDBACK',
      hasEventUpdates: 'events/hasUpdates',
      hasSupportUpdates: 'support/hasUpdates',
      isCandidateProfile: 'isCandidateProfile',
      hasKryptonConnectNotification: 'notification/HAS_INBOX_NOTIFICATION'
    }),
    user () {
      return this.$store.getters.getUser
    },
    isProviderProfile () {
      return isProviderProfile(this.user)
    },
    isFeatureLocked () {
      return this.isCandidateProfile
    },
    hasInboxNotification () {
      return this.hasKryptonConnectNotification
    },
    isSuperProvider () {
      return this.user?.settings?.is_super_provider
    },
    hasMenuAffiliatesUpdates () {
      return this.user?.settings?.menu_affiliates_seen === 0
    },
    hasMenuBillingUpdates () {
      return this.user?.settings?.menu_billing_seen === 0
    },
    isCurrentRoute () {
      const vm = this
      return (name) => {
        return vm.$route?.name === name
      }
    },
    dashboardNavIcon () {
      if (this.isCurrentRoute('dashboard')) {
        if (this.isSuperProvider) {
          return homeGoldIcon
        }

        return homeActiveIcon
      }

      return homeIcon
    },
    availabilityNavIcon () {
      if (this.isCurrentRoute('availability')) {
        if (this.isSuperProvider) {
          return availabilityGoldIcon
        }

        return availabilityActiveIcon
      }

      return availabilityIcon
    },
    inboxNavIcon () {
      if (this.isFeatureLocked) {
        return inboxLockedIcon
      }

      if (this.isCurrentRoute('inbox')) {
        if (this.isSuperProvider) {
          return inboxGoldIcon
        }

        return inboxActiveIcon
      }

      return inboxIcon
    },
    eventsNavIcon () {
      if (this.isFeatureLocked) {
        return eventsLockedIcon
      }

      if (this.isCurrentRoute('events')) {
        if (this.isSuperProvider) {
          return eventsGoldIcon
        }

        return eventsActiveIcon
      }

      return eventsIcon
    },
    otherMenuNavIcon () {
      if (this.isCurrentRoute('menu')) {
        if (this.isSuperProvider) {
          return otherMenuGoldIcon
        }

        return otherMenuActiveIcon
      }

      return otherMenuIcon
    },
    showNotifFeedback () {
      return this.hasFeedbackUpdates
    },
    showNotifBilling () {
      return this.hasMenuBillingUpdates && !this.isFeatureLocked
    },
    showNotifSupport () {
      return this.hasSupportUpdates
    },
    showNotifOtherMenu () {
      return this.showNotifFeedback || this.showNotifBilling || this.showNotifSupport
    }
  },
  beforeDestroy () {
    this.$store.commit('setHasFixedMenu', false)
  },
  mounted () {
    this.$store.dispatch('support/checkNewUpdates')

    this.$store.commit('setHasFixedMenu', true)
  },
  methods: {
    gotoPage (name, params = {}) {
      if (this.$router.history.current.name !== name) {
        this.$router.push({ name, params })
      }
    },
    navigatePage (name, restrictCandidateProfile = false, params = {}) {
      if (this.isCandidateProfile && restrictCandidateProfile) {
        $h.EventBus.$emit(LITE_EVENTS.ONFEATURELOCK)
      } else {
        this.gotoPage(name, params)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#fixed-menu {
  display: block;
  width: 100%;
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  box-shadow: none;
  height: 88px;
  border-top: 1px solid #CAD1D9;
  background-color: white;
  z-index: 995;

  @media (min-width: 992px) {
    max-width: 600px;
  }

  ul.menus {
    font-family: 'Proxima Nova';
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 4px auto 0;

    li {
      cursor: pointer;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      a {
        color: #52575B;
        font-size: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        line-height: 1;
        position: relative;

        > img {
          padding: 0 0 4px;
        }
      }

      &.locked a {
        color: #CED6E0;
      }

      &.active a {
        color: #E30074;
      }

      &.super-provider a {
        color: #D4AF37;
      }

      &.has-notif {
        a::after {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          background-color: #e30074;
          border-radius: 50%;
          right: -6px;
        }
      }
    }
  }
}
</style>
