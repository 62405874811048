<template functional>
  <component :is="injections.components.LanguageSwitcherController" v-slot="{ data, bindings }">
    <div data-test="lang-switch" class="lang-switcher">
      <a :href="data.linkToEN" data-test="lang-switch-en" :class="{ 'active': data.isLocale('en') }" @click="bindings.setLocale('en')">EN</a>
      <a :href="data.linkToDE" data-test="lang-switch-de" :class="{ 'active': data.isLocale('de') }" @click="bindings.setLocale('de')">DE</a>
    </div>
  </component>
</template>

<script>
import LanguageSwitcherController from './LanguageSwitcherController.vue'

export default {
  inject: {
    components: {
      default: {
        LanguageSwitcherController
      }
    }
  }
}
</script>
