export default {
  methods: {
    getTitle (news) {
      return this.$translate(`news.items.${news}.title`)
    },
    getDescription (news) {
      return this.$translate(`news.items.${news}.description`)
    },
    isMultiLocationFeature (news) {
      return (news?.isMultiLocationFeature ?? false)
    },
    getCta (news) {
      if (this.isMultiLocationFeature(news)) {
        return this.$translate('news.cta_button.more')
      }

      return this.$translate('news.cta_button.read')
    }
  }
}
