import { buildParam } from '@/services/ApiService'

export const billingPath = '/v2/billing'
export const billingPaidPath = '/v2/billing/paid'
export const billingUnpaidPath = '/v2/billing/unpaid'

export const getBillingOverview = () => {
  const url = buildParam(`${process.env.KRYPTON_PROVIDER_API}${billingPath}`)
  return new Promise((resolve, reject) => {
    window.api.get({ url },
      (success) => { resolve(success.data) },
      (err) => { reject(err.response.data) })
  })
}

export const getBillingPaid = (payload = { page: 1 }) => {
  const url = buildParam(`${process.env.KRYPTON_PROVIDER_API}${billingPaidPath}`, payload)
  return new Promise((resolve, reject) => {
    window.api.get({ url },
      (success) => { resolve(success.data) },
      (err) => { reject(err.response.data) })
  })
}

export const getBillingUnpaid = (payload = { page: 1 }) => {
  const url = buildParam(`${process.env.KRYPTON_PROVIDER_API}${billingUnpaidPath}`, payload)
  return new Promise((resolve, reject) => {
    window.api.get({ url },
      (success) => { resolve(success.data) },
      (err) => { reject(err.response.data) })
  })
}
