<template>
  <div class="support-v2-item clickable" :class="{'--unread': ticket.is_read === 0}" @click="gotoTicket">
    <div class="support-v2-item__container container--flex">
      <div class="support-v2-item__labels">
        <strong class="support-v2-item__category">{{ category }}</strong>
        <p class="support-v2-item__ticket-title">ID{{ ticket.id }} <span v-if="info">{{ info }}</span>
        </p>
      </div>
      <div class="support-v2-item__indicators">
        <strong v-if="ticket.is_read === 0" class="support-v2-item__dot" />
        <span class="support-v2-item__time-ago">{{ timeAgo }}</span>
      </div>
    </div>
    <span class="support-v2-item__preview">{{ ticketTitle }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const LocaleProvider = $h.getProvider('LocaleProvider')
export default {
  props: {
    ticket: { type: Object, required: true }
  },
  data () {
    return {
      SUPPORT_TICKET_STATUS
    }
  },
  computed: {
    ...mapGetters({
      currentTime: 'getCurrentTime'
    }),
    category () {
      switch (this.ticket.cat_id) {
        case SUPPORT_CATEGORY.MEETING:
          return this.$translate('support.category.meeting')
        case SUPPORT_CATEGORY.INVOICE:
          return this.$translate('support.category.invoice')
        case SUPPORT_CATEGORY.ACCOUNT:
          return this.$translate('support.category.account')
        case SUPPORT_CATEGORY.TECHNICAL:
          return this.$translate('support.category.technical')
        default:
          return this.$translate('support.category.other')
      }
    },
    timeAgo () {
      return this.ticket._last_message_received_at.fromNow()
    },
    ticketTitle () {
      switch (this.ticket.cat_id) {
        case SUPPORT_CATEGORY.MEETING:
          const dt = moment(this.ticket._request.meeting_start_at)
            .locale(LocaleProvider.getLocale())
            .format('ddd, DD MMM, kk:mm')

          return `${this.ticket._request.provider_name} + ${this.ticket._request.client_name}, ${dt}`
        case SUPPORT_CATEGORY.INVOICE:
          if (this.ticket._invoice) {
            return `Invoice ID${this.ticket._invoice.invoice_number}`
          }
          break
        default:
          return this.ticket.subject
      }

      return ''
    },
    info () {
      const ticket = this.ticket
      let info = ''

      if (ticket._reply_count) {
        info = `(${ticket._reply_count})`
      }

      return info
    },
    isTicketClosed () {
      return this.ticket.status === SUPPORT_TICKET_STATUS.CLOSED
    }
  },
  methods: {
    gotoTicket () {
      this.$router.push({
        name: 'support-v2-request',
        query: {
          id: this.ticket.id
        }
      })
    }
  }
}
</script>

<style scoped>
.entry {
  padding-bottom: 0;
  font-size: 12px;
}
.entry:first-child {
  padding-left: 0;
}
.entry:last-child {
  padding-right: 0;
}
.lilac::after {
  right: -12px;
  left: inherit;
  top: 3px;
}
</style>
