var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        empty: !_vm.imageSrc,
        "front-id": _vm.isFront,
        "back-id": !_vm.isFront,
        "repleaceable clickable": _vm.isReplaceable
      },
      attrs: { id: "verify-upload-slot" },
      on: { click: _vm.onClick }
    },
    [
      _vm.hasImageSrc
        ? _c("div", [
            _c("img", {
              attrs: { src: _vm.imageSrc, width: "242", height: "151" }
            })
          ])
        : _c("button", { staticClass: "btn-flat waves-effect waves-light" }, [
            _vm._v("\n    " + _vm._s(_vm.placeholder) + "\n  ")
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }