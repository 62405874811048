<template>
  <div v-if="invoiceData" id="billing-modal">
    <div class="billing-modal__backdrop">
      <div class="billing-modal__card">
        <div class="billing-modal__card-title">{{ $t('bank_details.title') }}</div>
        <div class="billing-modal__card-content">
          <div class="billing-modal__card-content-rows">
            <div class="billing-modal__card-content-row">
              <div class="billing-modal__card-content-row-label">{{ $t('bank_details.name') }}</div>
              <div class="billing-modal__card-content-row-value" data-test="account-name">{{ bankDetails.account_name }}</div>
              <div class="billing-modal__card-content-row-icon">
                <copy-icon :val="bankDetails.account_name" />
              </div>
            </div>
            <div class="billing-modal__card-content-row">
              <div class="billing-modal__card-content-row-label">{{ $t('bank_details.bank') }}</div>
              <div class="billing-modal__card-content-row-value" data-test="bank-name">{{ bankDetails.account_bank }}</div>
              <div class="billing-modal__card-content-row-icon">
                <copy-icon :val="bankDetails.account_bank" />
              </div>
            </div>
            <div class="billing-modal__card-content-row">
              <div class="billing-modal__card-content-row-label">IBAN:</div>
              <div class="billing-modal__card-content-row-value" data-test="iban">{{ bankDetails.account_iban }}</div>
              <div class="billing-modal__card-content-row-icon">
                <copy-icon :val="bankDetails.account_iban" />
              </div>
            </div>
            <div class="billing-modal__card-content-row">
              <div class="billing-modal__card-content-row-label">SWIFT / BIC:</div>
              <div class="billing-modal__card-content-row-value" data-test="swift">{{ bankDetails.account_swift }}</div>
              <div class="billing-modal__card-content-row-icon">
                <copy-icon :val="bankDetails.account_swift" />
              </div>
            </div>
            <div class="billing-modal__card-content-row">
              <div class="billing-modal__card-content-row-label">{{ $t('bank_details.amount') }}</div>
              <div class="billing-modal__card-content-row-value" data-test="amount">{{ amount }}</div>
              <div class="billing-modal__card-content-row-icon">
                <copy-icon :val="invoiceData.amount.toString()" />
              </div>
            </div>
            <div class="billing-modal__card-content-row">
              <div class="billing-modal__card-content-row-label">{{ $t('bank_details.reference') }}</div>
              <div class="billing-modal__card-content-row-value" data-test="invoice-number">{{ invoiceData.invoice_number }}</div>
              <div class="billing-modal__card-content-row-icon">
                <copy-icon :val="invoiceData.invoice_number" />
              </div>
            </div>
          </div>
        </div>
        <div class="billing-modal__card-content--with-note">
          <div class="billing-modal__card-content-note">{{ $t('bank_details.reference_note') }}</div>
        </div>
        <div class="billing-modal__card-footer">
          <a href="#" class="btn btn-link waves-effect" @click.prevent="closeModal">{{ $t('bank_details.close') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/billing/translations/billing.json"></i18n>

<script>
import CopyIcon from '@/features/billing/components/CopyIcon.vue'

export default {
  inject: {
    billingData: {
      default: () => ({
        isEUR: false
      })
    }
  },
  components: {
    CopyIcon
  },
  props: {
    bankDetails: {
      type: Object,
      default: () => null
    },
    invoiceData: {
      type: Object,
      default: () => null
    },
    currency: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    amount () {
      const amount = this.billingData.isEUR
        ? `${this.invoiceData.amount?.toFixed(2)} ${this.currency}`
        : `${this.currency} ${this.invoiceData.amount?.toFixed(2)}`

      return amount
    }
  },
  methods: {
    closeModal () {
      return this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
#billing-modal {
  .billing-modal__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(#2E353D, 0.3);
    z-index: 999;

    .billing-modal__card {
      position: relative;
      max-width: 568px;
      top: 50% !important;
      transform: scalex(1) translate(0, -50%) !important;
      transition: top 0.2s, opacity 0.2s;
      width: 100%;
      margin: 0 auto;

      @media screen and (max-width: 600px) {
        margin: 0 16px;
        width: 94%;
      }
      @media screen and (max-width: 420px) {
        width: 92%;
      }

      .billing-modal__card-title {
        padding: 24px 24px 10px;
        border-radius: 16px 16px 0 0;
        background-color: #FFFFFF;
        font-size: 20px;
        font-family: 'Proxima Nova Semibold', sans-serif;
        text-align: left;
        color: #2E353D;
      }
      .billing-modal__card-content {
        padding: 0 2px 18px 24px;
        background-color: #FFFFFF;

        .billing-modal__card-content-rows {
          .billing-modal__card-content-row {
            display: flex;
            align-items: center;

            .billing-modal__card-content-row-label {
              margin-right: 16px;
              font-size: 12px;
              color: #8995A3;
            }
            .billing-modal__card-content-row-value {
              font-size: 14px;
              color: #2F3740;
            }
            .billing-modal__card-content-row-icon {
              margin-left: auto;

              a {
                display: block;
                border-radius: 50%;
                font-size: 24px;
                color: #8995A3;

                i.material-icons {
                  display: block;
                  width: 40px;
                  height: 40px;
                  line-height: 40px;
                  text-align: center;
                }
              }
            }
          }
        }

        &--with-note {
          padding: 0 24px 18px;
          background-color: #FFFFFF;

          .billing-modal__card-content-note {
            padding: 16px;
            border: 1px solid #CED6E0;
            border-radius: 12px;
            font-size: 12px;
            text-align: left;
            color: #2F3740;
          }
        }

      }
      .billing-modal__card-footer {
        padding: 4px 0;
        border-top: 1px solid #CED7E0;
        border-radius: 0 0 16px 16px;
        background-color: #FFFFFF;
        box-shadow: 0px 3px 6px #00000029;

        .btn.btn-link {
          padding: 0 16px;
          height: 36px;
          line-height: 36px;
          background: transparent;
          box-shadow: none;
          font-size: 16px;
          font-family: 'Proxima Nova Semibold', sans-serif;
          color: #C51162;
        }
      }
    }
  }
}
</style>
