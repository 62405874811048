var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "button",
    {
      staticClass: "btn",
      class: _vm.props.data.classes,
      style: _vm.props.data.styles,
      attrs: { type: _vm.type, disabled: _vm.props.data.disabled },
      on: { click: _vm.props.data.onClick }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }