import { currency } from './constants'

const DEFAULT_COUNTRY_CODE = 'de'

export function getCurrencyByCountry (countryCode = 'de') {
  return currency[countryCode.toLowerCase()] ?? currency[DEFAULT_COUNTRY_CODE]
}

export default {
  getCurrencyByCountry
}
