import ApiProvider from '../providers/ApiProvider'

/**
 * Vuex State
*/
const state = {
  reviews: [],
  totalRating: 0,
  totalFeedback: 0,
  isFetching: false,
  hasUpdates: false
}

/**
 * State getters
*/
const getters = {
  GET_REVIEWS (state) {
    return state.reviews
  },
  GET_TOTAL_RATING (state) {
    return state.totalRating
  },
  GET_TOTAL_FEEDBACK (state) {
    return state.totalFeedback
  },
  GET_IS_FETCHING (state) {
    return state.isFetching
  },
  hasUpdates (state) {
    return state.hasUpdates
  }
}

/**
 * State mutations
*/
const mutations = {
  SET_REVIEWS (state, reviews) {
    state.reviews = reviews
  },
  SET_TOTAL_RATING (state, totalRating) {
    state.totalRating = Number(totalRating).toFixed(1)
  },
  SET_TOTAL_FEEDBACK (state, totalFeedback) {
    state.totalFeedback = totalFeedback
  },
  SET_IS_FETCHING (state, isFetching) {
    state.isFetching = isFetching
  },
  SET_HAS_UPDATES (state, value = true) {
    state.hasUpdates = value
  }
}

/**
 * State actions
*/
const actions = {
  fetch ({ commit }) {
    commit('SET_IS_FETCHING', true)

    return new Promise((resolve, reject) => {
      window.api.get({ url: ApiProvider.fetchFeedbacks() },
        (success) => {
          commit('SET_REVIEWS', success.data.data.reviews)
          commit('SET_TOTAL_FEEDBACK', success.data.data.total_feedback_count)
          commit('SET_TOTAL_RATING', success.data.data.total_rating)

          resolve(success.data.data)
        },
        (err) => {
          window.NotificationPlugin(err.response, 5000)
          reject(err)
        },
        () => {
          commit('SET_IS_FETCHING', false)
        })
    })
  },
  seenFeedback ({ commit }) {
    return new Promise((resolve, reject) => {
      window.api.get({ url: ApiProvider.fetchFeedbacks(1) },
        (success) => {
          commit('SET_HAS_UPDATES', false)
          resolve(success.data)
        },
        (err) => {
          window.NotificationPlugin(err.response, 5000)
          reject(err)
        })
    })
  },
  checkUnseenFeedback ({ commit }) {
    return new Promise((resolve, reject) => {
      window.api.get({ url: ApiProvider.checkUnseenFeedback() },
        (success) => {
          if (success.data.data.unseen_feedbacks > 0) {
            commit('SET_HAS_UPDATES')
          }

          resolve(success.data)
        },
        (err) => {
          window.NotificationPlugin(err.response.data.message, 5000)
          reject(err)
        })
    })
  }
}

/// ////////////////////////
// Nothing to do here...
/// ////////////////////////

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
