<template>
  <div class="long-term-booking-popup__backdrop" data-test="affiliate-popup">
    <div class="long-term-booking-popup" data-test="affilate-popup-backdrop">
      <div class="long-term-booking-popup__card">
        <a href="#" class="long-term-booking-popup__close-btn">
          <i
            data-test="ltbp-close-btn"
            class="material-icons"
            @click.prevent="closePopup({})"
          >close</i>
        </a>
        <h2 data-test="ltbp-title">{{ $t('title') }}</h2>
        <img data-test="ltbp-image" src="/img/availability/ltbp-header-img.svg" alt="">
        <p data-test="ltbp-header-description" class="header-description">{{ $t('header_description') }}</p>
        <p data-test="ltbp-description" class="description">{{ $t('description') }}</p>
        <a
          data-test="ltbp-cta"
          href="#"
          class="btn waves-effect"
          @click.prevent="closePopup({ redirect: { name: 'availability' } })">{{ $t('cta') }}</a>
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/availability/translations/popup.json"></i18n>

<script>
import { putAvailabilityLongTermBookingSeen } from '@/features/availability/services/availabilityPopupService.js'

export default {
  computed: {
    affiliateSettings () {
      return this.$store.getters['affiliate/getAffiliateSettings']
    },
    user () {
      return this.$store.getters.getUser
    },
    isFromGB () {
      return (this.user?.profiles?.country_code ?? '').toLowerCase() === 'gb'
    },
    getRewardByCountryCode () {
      const currency = this.user?._currency ?? this.affiliateSettings?.currency_symbols?.eur

      return this.isFromGB
        ? `${currency}${this.affiliateSettings?.rewards?.gbp}`
        : `${currency}${this.affiliateSettings?.rewards?.eur}`
    }
  },
  methods: {
    closePopup ({ redirect }) {
      // Close popup
      // The delay/lag on closing the popup because of awaiting the response from API is causing usablility problems
      // This is why the placement of the marking vuex store as seen is put first to avoid lag
      // This is tolerable since showing the popup multiple times IF the API request failed is not a big deal
      this.$store.commit('setUserSettingIsLongTermBookingPromoPopupSeen', true)
      if (redirect) {
        this.$router.push(redirect)
      }

      putAvailabilityLongTermBookingSeen()
        .catch(err => {
          this.$notifications.toast(err?.message, 5000)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.long-term-booking-popup__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000000, 0.7);
  z-index: 999;

  .long-term-booking-popup {
    position: relative;
    max-width: 568px;
    top: 50% !important;
    transform: scalex(1) translate(0, -50%) !important;
    transition: top 0.2s, opacity 0.2s;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 600px) {
      margin: 0 16px;
      width: 94%;
      }
    @media screen and (max-width: 420px) {
      width: 92%;
    }

    .long-term-booking-popup__card {
      padding: 51px 24px 31px;
      background-color: #FFFFFF;
      border-radius: 16px;
      text-align: center;

      .long-term-booking-popup__close-btn {
        position: absolute;
        top: 6px;
        right: 6px;
        width: 48px;
        height: 48px;
        text-align: center;
        color: #737B85;

        i.material-icons {
          display: block;
          line-height: 48px;
          width: 48px;
        }
      }

      img {
        display: block;
        max-width: 100%;
        margin: 0 auto 8px;
      }

      h2 {
        margin: 0 0 24px;
        font-size: 24px;
        font-family: 'Proxima Nova Semibold';
        color: #2F3740;
      }

      p {
        font-family: 'Proxima Nova';
        font-size: 18px;
        color: #2F3740;
        margin: 0;

        &.header-description {
          font-family: 'Proxima Nova Semibold';
          margin-top: 24px;
          font-size: 20px;
        }

        &.description {
          margin-top: 24px;
          font-size: 18px;
        }
      }

      a.btn {
        font-family: 'Proxima Nova Semibold';
        padding: 0 35px;
        height: 47px;
        line-height: 47px;
        background: transparent linear-gradient(261deg, #FF0073 0%, #DC0064 100%) 0% 0% no-repeat padding-box;
        border-radius: 8px;
        font-size: 16px;
        min-width: 186px;
        border-radius: 10px;
        width: 100%;
        margin-top: 34px;
      }
    }
  }
}
</style>
