var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "city" } },
    [
      [
        _c("div", { staticClass: "content__area" }, [
          _c("img", {
            attrs: {
              id: "k-logo",
              src: "/img/registration/k-logo.svg",
              alt: "k-logo"
            }
          }),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "content__stepper content__stepper--mobile" },
            [_vm._v(" " + _vm._s(_vm.stepperLabel))]
          ),
          _vm._v(" "),
          _c("h2", { staticClass: "content__title content__title--desktop" }, [
            _vm._v(_vm._s(_vm.$t("title")))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content__area drawer" }, [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "p",
                { staticClass: "content__stepper content__stepper--desktop" },
                [_vm._v(" " + _vm._s(_vm.stepperLabel))]
              ),
              _vm._v(" "),
              _c(
                "h2",
                { staticClass: "content__title content__title--mobile" },
                [_vm._v(_vm._s(_vm.$t("title")))]
              ),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "selected-text-container" }, [
                _vm.selectedText
                  ? _c("p", { staticClass: "center-align selected-text" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.selectedText) +
                          "\n          "
                      )
                    ])
                  : _c("p", [_vm._v("   ")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tooltip-container center-align" }, [
                _c(
                  "a",
                  {
                    ref: "tooltip",
                    staticClass: "xsmall valign-middle tooltip padding-none",
                    attrs: { href: "#" }
                  },
                  [
                    _c(
                      "i",
                      { staticClass: "material-icons krypton-pink-text" },
                      [_vm._v("lightbulb_outline")]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "underline" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("tooltip_button")) +
                          "\n            "
                      )
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "md-select",
                {
                  staticClass: "textfield-box-lg",
                  attrs: {
                    "force-mobile": true,
                    "desktop-display-mode": "split",
                    label: _vm.$t("country"),
                    default: _vm.$t("select_country"),
                    placeholder: _vm.$t("country"),
                    "listen-for-reinitiate-options": true,
                    "data-test": "registration-country"
                  },
                  model: {
                    value: _vm.payload.country,
                    callback: function($$v) {
                      _vm.$set(_vm.payload, "country", $$v)
                    },
                    expression: "payload.country"
                  }
                },
                [
                  _vm._l(_vm.countries, function(country, i) {
                    return _c(
                      "option",
                      {
                        key: i,
                        attrs: { slot: "options" },
                        domProps: { value: country.key },
                        slot: "options"
                      },
                      [_vm._v(_vm._s(_vm.getCountryName(country)))]
                    )
                  }),
                  _vm._v(" "),
                  _vm.errors.has("country")
                    ? _c(
                        "div",
                        {
                          staticClass: "input-helper red-text",
                          attrs: { slot: "errors" },
                          slot: "errors"
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.first("country")) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "md-autocomplete",
                    {
                      attrs: {
                        "data-test": "registration-city",
                        label: _vm.$t("input_label"),
                        options: _vm.cityOptions,
                        disabled: _vm.shouldDisableCityField,
                        "force-mobile": true
                      },
                      on: { onInputFocus: _vm.onAutocompleteInputFocus },
                      model: {
                        value: _vm.payload.city,
                        callback: function($$v) {
                          _vm.$set(_vm.payload, "city", $$v)
                        },
                        expression: "payload.city"
                      }
                    },
                    [
                      _vm.errors.has("city")
                        ? _c(
                            "div",
                            {
                              staticClass: "input-helper red-text",
                              attrs: { slot: "errors" },
                              slot: "errors"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.first("city")) +
                                  "\n            "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("register-navigator-v2", {
                attrs: {
                  total: _vm.total,
                  current: _vm.current,
                  "next-disabled": _vm.isCTADisabled
                },
                on: {
                  prev: function($event) {
                    $event.preventDefault()
                    return _vm.gotoPrev($event)
                  },
                  next: function($event) {
                    $event.preventDefault()
                    return _vm.submit($event)
                  }
                }
              })
            ],
            1
          )
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "drawer-icon center-align" }, [
      _c("img", {
        attrs: { src: "/img/registration/basic/city.svg", alt: "locator icon" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }