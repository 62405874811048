var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dropdown && _vm.optsCuisine.length
    ? _c(
        "div",
        { staticClass: "wrapper", attrs: { id: "taste" } },
        [
          [
            _c("div", { staticClass: "content__area" }, [
              _c("img", {
                attrs: {
                  id: "k-logo",
                  src: "/img/registration/k-logo.svg",
                  alt: "k-logo"
                }
              }),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "content__stepper content__stepper--mobile" },
                [_vm._v(" " + _vm._s(_vm.stepperLabel))]
              ),
              _vm._v(" "),
              _c(
                "h2",
                { staticClass: "content__title content__title--desktop" },
                [
                  _vm._v(
                    _vm._s(_vm.$translate("register.personality.title.taste"))
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content__area drawer" }, [
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c(
                    "p",
                    {
                      staticClass: "content__stepper content__stepper--desktop"
                    },
                    [_vm._v(" " + _vm._s(_vm.stepperLabel))]
                  ),
                  _vm._v(" "),
                  _c(
                    "h2",
                    { staticClass: "content__title content__title--mobile" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$translate("register.personality.title.taste")
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "md-select",
                    {
                      staticClass: "textfield-box-lg",
                      attrs: {
                        "desktop-display-mode": "split",
                        "force-mobile": true,
                        multiple: true,
                        limit: 3,
                        label: _vm.$translate(
                          "register.personality.taste.cuisine_label"
                        ),
                        placeholder: _vm.$translate("details.cuisine"),
                        default: _vm.$translate(
                          "register.personality.taste.select_cuisine"
                        )
                      },
                      model: {
                        value: _vm.payload.cuisine,
                        callback: function($$v) {
                          _vm.$set(_vm.payload, "cuisine", $$v)
                        },
                        expression: "payload.cuisine"
                      }
                    },
                    [
                      _vm._l(_vm.optsCuisine, function(opt, i) {
                        return _c(
                          "option",
                          {
                            key: "opt-cuisine-" + i,
                            attrs: { slot: "options" },
                            domProps: { value: opt.key },
                            slot: "options"
                          },
                          [_vm._v(_vm._s(opt.content))]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("cuisine"),
                              expression: "errors.has('cuisine')"
                            }
                          ],
                          staticClass: "input-helper red-text left-align",
                          attrs: { slot: "errors" },
                          slot: "errors"
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.first("cuisine")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "md-textarea",
                    {
                      attrs: {
                        type: "multiline",
                        "force-active": false,
                        maxlength: _vm.drinksMaxLength,
                        label: _vm.$translate(
                          "register.personality.taste.drinks_label"
                        )
                      },
                      model: {
                        value: _vm.payload.drinks,
                        callback: function($$v) {
                          _vm.$set(_vm.payload, "drinks", $$v)
                        },
                        expression: "payload.drinks"
                      }
                    },
                    [
                      _c("div", { staticClass: "input-helper" }, [
                        _c("span", { staticClass: "right" }, [
                          _vm._v(_vm._s(_vm.drinksLength))
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("drinks"),
                                expression: "errors.has('drinks')"
                              }
                            ],
                            staticClass: "red-text left-align"
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.first("drinks")) +
                                "\n            "
                            )
                          ]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("register-navigator-v2", {
                    attrs: {
                      "next-disabled": _vm.isCTADisabled,
                      total: _vm.total,
                      current: _vm.current
                    },
                    on: {
                      prev: function($event) {
                        $event.preventDefault()
                        return _vm.gotoPrev($event)
                      },
                      next: function($event) {
                        $event.preventDefault()
                        return _vm.submitForm($event)
                      }
                    }
                  })
                ],
                1
              )
            ])
          ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "drawer-icon --no-selected-text center-align" },
      [
        _c("img", {
          attrs: {
            src: "/img/registration/personality/taste.svg",
            alt: "girl and boy dining"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }