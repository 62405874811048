import { render, staticRenderFns } from "./not-found.vue?vue&type=template&id=2fcd2f76&scoped=true&"
import script from "./not-found.vue?vue&type=script&lang=js&"
export * from "./not-found.vue?vue&type=script&lang=js&"
import style0 from "./not-found.vue?vue&type=style&index=0&id=2fcd2f76&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fcd2f76",
  null
  
)

/* custom blocks */
import block0 from "@components/not-found/translations/not-found.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fforge%2Fama-provider8-stage.noznom.tech%2Fsrc%2Fjs%2Fcomponents%2Fnot-found%2Fnot-found.vue"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider8-stage.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2fcd2f76')) {
      api.createRecord('2fcd2f76', component.options)
    } else {
      api.reload('2fcd2f76', component.options)
    }
    module.hot.accept("./not-found.vue?vue&type=template&id=2fcd2f76&scoped=true&", function () {
      api.rerender('2fcd2f76', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/not-found/not-found.vue"
export default component.exports