import { mapGetters } from 'vuex'

import {
  Modal,
  ModalOptions,
  PRIORITY_LEVEL
} from '../models/Modal'

import { CANCELLATION_REQUEST_SENT } from '../constants/global'

const ModalManager = $h.getManager('ModalManager')

const MAX_MEETING_HR_EXTENSION = 12

export default {
  computed: {
    ...mapGetters({
      totalDuration: 'events/getActiveTotalDuration',
      checkinTime: 'events/getActiveEventcheckinTime',
      activeEvent: 'events/getActiveEvent',
      events: 'events/getSortedEvents',

      shownPendingEvent: 'events/getPendingEvent',

      dropdown: 'getDropdown',
      curTime: 'getCurrentTime'
    })
  },
  methods: {

    /**
     * Check event statuses in the vuex array.
     */
    checkEventStatuses () {
      this.checkActiveEventStatus()

      // We use for-loop instead of .forEach() for performance purposes.
      for (let i = 0, limit = this.events.length; i < limit; ++i) {
        const evt = this.events[i]

        this._oldEventCheck(evt)
        this._cancellationRequestEventCheck(evt)
        this._cancelledEventCheck(evt)
        this._pendingEventCheck(evt)
        this._reactivatedEventCheck(evt)
        this._expiredEventCheck(evt)
        this._needFeedbackCheck(evt)
        this._readyToGoCheck(evt)
        this._decrementEventSeconds(evt)
        this._runningLateEventCheck(evt)
      }
    },

    /**
     * Check active event current status.
     */
    checkActiveEventStatus () {
      if (this.activeEvent) {
        this._checkOvertime()
      }
    },

    /**
     * Check event if it's already finished or an hour old.
     *
     * @param {Object} evt - The event object.
     */
    _oldEventCheck (evt) {
      // For old events, we just remove them immediately from memory
      if (this._isEventAnHourOld(evt) && this._isFinishedEvent(evt)) {
        this.$store.commit('events/DELETE', evt.id)
      }
    },

    /**
     * Check pending event current status.
     *
     * @param {Object} evt - The event object.
     */
    _pendingEventCheck (evt) {
      if (ModalManager.hasActiveModal) return

      if (this._isPendingEvent(evt)) {
        // Avoid showing popups for pending events not yet showin in the EIArea.
        if (this.shownPendingEvent) {
          if (this.shownPendingEvent.id !== evt.id) return
        }

        // Check if there's a need to show pending event popup
        if (evt.pending_seen === 0) {
          const id = 'md-pending-event'
          const modalName = 'md-pending-event'

          const callback = () => {}
          const propsData = {
            evt: evt,
            vm: this,
            evtID: evt.id
          }
          const onOpen = () => {
            this.$store.commit('events/PENDING_EVENT_SEEN', evt.id)
          }
          const modalOpts = new ModalOptions(false, onOpen)
          const excludedRoutes = [
            '/events/details/*',
            '/events/checkin'
          ]
          const modal = new Modal(id, modalName, callback, propsData, modalOpts, excludedRoutes)

          ModalManager.addToQueue(modal)
        }

        // Check if pending event needs to expire
        else if (evt._expiration_in_seconds !== null && evt._expiration_in_seconds <= 0) {
          this.$store.dispatch('events/forceEventExpire', evt.id).then(() => {
            // Re-sync availabilities data
            this.$store.dispatch('availabilities/refreshAvailabilities')
          })
        }
      }
    },

    /**
     * Check if pending re-activated event should now expired.
     *
     * @param {Object} evt - The event object.
     */
    _reactivatedEventCheck (evt) {
      if (this._isReactivatedEvent(evt)) {
        // Check if pending event needs to expire
        if (evt._expiration_in_seconds !== null &&
            evt._expiration_in_seconds <= 0) {
          this.$store.dispatch('events/forceReactivatedEventExpire', evt.id).then(() => {
            // Re-sync availabilities data
            this.$store.dispatch('availabilities/refreshAvailabilities')
          })
        }
      }
    },

    /**
     * Check pending event if it should now expire.
     *
     * @param {Object} evt - The event object.
     */
    _expiredEventCheck (evt) {
      if (this._isUnseenExpiredReactivated(evt) ||
          this._cannotReactivateExpiredEvent(evt) ||
          this._isUnseenExpiredEvent(evt) &&
         !this._isReactivatedEvent(evt)) {
        this._showClosedEventModal(evt)
      }
    },

    /**
     * Check if there's a unseen cancelled event.
     *
     * @param {Object} evt - The event object.
     */
    _cancelledEventCheck (evt) {
      if (this._isUnseenRequestCancellation(evt)) {
        this._showCancellationRequestPage(evt)
      } else if (this._isExpiredRequestCancellation(evt)) {
        this._showExpiredRequestCancellationTimeout(evt)
      } else if (this._isUnseenCancelledEvent(evt)) {
        this._showClosedEventModal(evt)
      }
    },

    /**
     * Callback function for closedEventModal.
     */
    _onClosedExpiredEvent (evt) {
      this.$store.dispatch('availabilities/refreshAvailabilities')
      this.$store.dispatch(
        'events/expiredEventSeen',
        evt.id
      )

      // Determine the action needed to be done based on the type of expiration
      if (this._canReactivateExpiredEvent(evt)) {
        this.$store.commit('events/SET_SELECTED', evt)
        this.$router.push({
          name: 'event-details'
        })
      } else if (this._cannotReactivateExpiredEvent(evt) ||
               this._isUnseenExpiredReactivated(evt)) {
        this.$store.dispatch(
          'events/reactivatedExpiredEventSeen',
          evt.id
        )
        this.$store.commit('events/DELETE', evt.id)
      }
    },

    /**
     * Check if there's an event needs feedback from provider.
     *
     * @param {Object} evt - The event object.
     */
    _needFeedbackCheck (evt) {
      if (this._isEventNeedsFeedback(evt)) {
        if (evt._feedback_metadata.expiration_in_seconds === 0) {
          this.$router.replace({ name: 'events' })

          this.$store.commit('events/SET_SELECTED', null)
          this.$store.commit('events/DELETE', evt.id)

          this.$notifications.toast(this.$translate('events.feedback_timeout'), 5000)
        }
      }
    },

    /**
     * Check for pre-meeting event if it should now be ready-to-go.
     *
     * @param {Object} evt - The event object.
     */
    _readyToGoCheck (evt) {
      if (this._isEventReadyToGo(evt)) {
        this.$store.commit('events/UPDATE_EVENT_STATUS', {
          id: evt.id,
          newStatus: EVENT_STATUS.CONFIRMED_READY
        })
      }
    },

    /**
     * Check the active meeting for over time.
     */
    _checkOvertime () {
      // Calculate time elapsed for active event.
      if (this.curTime && this.checkinTime) {
        const elapsedHours = Math.floor(
          Math.abs(this.activeEvent._check_in_seconds) / $h.hrToSeconds(1)
        )
        const extraHr = elapsedHours - this.totalDuration

        // If meeting duration is more than an hour from the meeting total duration.
        if (extraHr > 0) {
          const totalExtendHours = this.activeEvent.extension + extraHr

          // Extend meeting time until it reach the max meeting hour extension
          if (totalExtendHours <= MAX_MEETING_HR_EXTENSION) {
            this.$store.commit('events/ADD_EXTENSION', extraHr)
          } else {
            // Do auto checkout if time extension limit is reached...
            this.$store.dispatch('events/checkout', this.activeEvent)

            if (this.$route.name === 'event-checkin') {
              this.$router.replace({ name: 'event-rate' })
            }
          }
        }
      }
    },

    /**
     * Decrement expiration, meeting-start, checkin second counter values.
     *
     * @param {Object} evt - The event object.
     */
    _decrementEventSeconds (evt) {
      this.$store.commit('events/DECREMENT_FEEDBACK_EXPIRATION_SECONDS', evt.id)
      this.$store.commit('events/DECREMENT_EVENT_CANCELLATION_SECONDS', evt.id)
      this.$store.commit('events/DECREMENT_MEETING_START_SECONDS', evt.id)
      this.$store.commit('events/DECREMENT_EXPIRATION_SECONDS', evt.id)
      this.$store.commit('events/DECREMENT_CHECKIN_SECONDS', evt.id)
    },

    /**
     * Check if meeting start is changed due to client delay
     *
     * @param {Object} evt - The event object.
     */
    _runningLateEventCheck (evt) {
      if (evt.client_delay_seen === 0 && Number(evt.client_delay) > 1) {
        if (ModalManager.hasActiveModal) return

        const id = 'md-client-late'
        const modalName = 'md-client-late'
        const callback = () => {
          this.$store.dispatch('events/clientDelaySeen', evt.id)
        }
        const propsData = {
          clientName: evt.client.name,
          minutesLate: Number(evt.client_delay),
          meetingAt: moment(evt.meeting_start_at, 'YYYY-MM-DD HH:mm').add(evt.client_delay, 'minutes').format('HH:mm')
        }
        const modalOpts = new ModalOptions()
        const excludedRoutes = []
        const modal = new Modal(
          id,
          modalName,
          callback,
          propsData,
          modalOpts,
          excludedRoutes,
          PRIORITY_LEVEL.LOW
        )

        ModalManager.addToQueue(modal)
      }
    },

    /**
     * Check if the event is already an hour old.
     *
     * @param {Object} evt - The event object.
     *
     * @returns {Boolean}
     */
    _isEventAnHourOld (evt) {
      if (evt._meeting_start_in_seconds < 0) {
        return Math.abs(evt._meeting_start_in_seconds) > $h.hrToSeconds(1)
      }

      return false
    },

    /**
     * @param {Object} evt - The event object.
     *
     * @returns {Boolean}
     */
    _isFinishedEvent (evt) {
      return evt.status === TICKET_STATUS.SUCCESS
    },

    /**
     * @param {Object} evt - The event object.
     *
     * @returns {Boolean}
     */
    _isPendingEvent (evt) {
      return evt._event_status === EVENT_STATUS.PENDING
    },

    /**
     * @param {Object} evt - The event object.
     *
     * @returns {Boolean}
     */
    _isUnseenCancelledEvent (evt) {
      return (
        evt._event_status === EVENT_STATUS.CANCELLED_CLIENT ||
        evt._event_status === EVENT_STATUS.CANCELLED_AGENT
      ) &&
        evt.is_expired_seen === 0
    },

    /**
     * @param {Object} evt - The event object.
     *
     * @returns {Boolean}
     */
    _isUnseenRequestCancellation (evt) {
      return evt.cancellation_request_seen !== 1 &&
             evt._cancellation_expiration_in_seconds > 0 &&
             this._isRequestCancellation(evt)
    },

    /**
     * @param {Object} evt - The event object.
     *
     * @returns {Boolean}
     */
    _isExpiredRequestCancellation (evt) {
      return evt.cancellation_timed_out_at !== null &&
             evt.cancellation_request_seen === 1 &&
             evt.cancellation_request_timeout_seen === 0 &&
             evt.status === TICKET_STATUS.CANCELLED &&
             evt._event_status === EVENT_STATUS.CANCELLED_PROVIDER
    },

    /**
     * @param {Object} evt - The event object.
     *
     * @returns {Boolean}
     */
    _isRequestCancellation (evt) {
      return evt.cancellation_timed_out_at !== null &&
             evt.is_cancellation_request_sent === CANCELLATION_REQUEST_SENT.PENDING &&
             (
               evt.status === TICKET_STATUS.CONFIRMED ||
               evt.status === TICKET_STATUS.PREMEETING
             )
    },

    /**
     * @param {Object} evt - The event object.
     *
     * @returns {Boolean}
     */
    _isExpired (evt) {
      return evt._event_status === EVENT_STATUS.EXPIRED
    },

    /**
     * @param {Object} evt - The event object.
     *
     * @returns {Boolean}
     */
    _canReactivateExpiredEvent (evt) {
      return this._isExpired(evt) &&
             evt.reactivation_status === REACTIVATION_STATUS.CAN_REACTIVATE &&
             evt._can_reactivate === 1
    },

    /**
     * @param {Object} evt - The event object.
     *
     * @returns {Boolean}
     */
    _cannotReactivateExpiredEvent (evt) {
      return this._isExpired(evt) &&
             evt.reactivation_status === REACTIVATION_STATUS.CANNOT_REACTIVATE &&
             evt._can_reactivate === 0
    },

    /**
     * @param {Object} evt - The event object.
     *
     * @returns {Boolean}
     */
    _isReactivatedEvent (evt) {
      return (
        this._isExpired(evt) &&
        evt.reactivation_status === REACTIVATION_STATUS.REACTIVATED
      )
    },

    /**
     * @param {Object} evt - The event object.
     *
     * @returns {Boolean}
     */
    _isUnseenExpiredReactivated (evt) {
      return (
        this._isExpired(evt) &&
        evt.reactivation_status === REACTIVATION_STATUS.REACTIVATION_EXPIRED &&
        evt.reactivation_failed_seen === 0
      )
    },

    /**
     * @param {Object} evt - The event object.
     *
     * @returns {Boolean}
     */
    _isUnseenExpiredEvent (evt) {
      return (
        this._isExpired(evt) &&
        evt.is_expired_seen === 0
      )
    },

    /**
     * @param {Object} evt - The event object.
     *
     * @returns {Boolean}
     */
    _isEventNeedsFeedback (evt) {
      return (
        evt.status === TICKET_STATUS.POST_MEETING &&
        evt._feedback_metadata.show === 1
      )
    },

    /**
     * Checks if meeting point is set to 'Room'
     *
     * @param {Object} evt - The event object.
     *
     * @returns {Boolean}
     */
    _isMeetingPointRoom (evt) {
      if (evt.meeting_point) {
        const meetingPoint = evt.meeting_point.toLowerCase()

        // eslint-disable-next-line camelcase
        return meetingPoint === this.dropdown?.meeting_point?.Room?.toLowerCase()
      }

      return false
    },

    /**
     * Check if cancellation request event should now expire.
     *
     * @param {Object} evt - The event object.
     *
     * @returns {Boolean}
     */
    _cancellationRequestEventCheck (evt) {
      if (this._isRequestCancellation(evt) && !this._isExpiredRequestCancellation(evt)) {
        if (evt._cancellation_expiration_in_seconds <= 0) {
          this.$store
            .dispatch('events/forceRequestCancellationTimeout', evt.id)
            .then(() => {
              // Re-sync availabilities data
              this.$store.dispatch('availabilities/refreshAvailabilities')
            })
        }
      }
    },

    /**
     * Checks if the meeting can now be changed to read-to-go.
     *
     * @param {Object} evt - The event object.
     *
     * @returns {Boolean}
     */
    _isEventReadyToGo (evt) {
      const is4HrsAway = evt._meeting_start_in_seconds <= $h.hrToSeconds(4)
      const isMeetingReady =
        evt.status === TICKET_STATUS.PREMEETING &&
        evt._event_status === EVENT_STATUS.CONFIRMED_PREPARING

      if (isMeetingReady) {
        if (evt.from_video_form) {
          return is4HrsAway
        }
        if (this._isMeetingPointRoom(evt)) {
          return (
            is4HrsAway &&
            evt.meeting_point !== null &&
            (evt.room_number !== null || evt.room_number_comment !== null)
          )
        }

        return (
          is4HrsAway &&
          evt.meeting_point !== null &&
          evt.client_identifier !== null &&
          (evt.room_number !== null || evt.room_number_comment !== null)
        )
      }

      return false
    },

    /**
     * Show closed event popup.
     *
     * @param {Object} evt
    */
    _showClosedEventModal (evt) {
      if (ModalManager.hasActiveModal) return

      const id = 'md-closed-event'
      const modalName = 'md-closed-event'
      const callback = () => {
        this._onClosedExpiredEvent(evt)
      }
      const propsData = {
        evt: evt,
        prevState: evt
      }
      const modalOpts = new ModalOptions()
      const excludedRoutes = [
        '/events/details/*',
        '/events/checkin'
      ]
      const modal = new Modal(
        id,
        modalName,
        callback,
        propsData,
        modalOpts,
        excludedRoutes,
        PRIORITY_LEVEL.HIGH
      )

      ModalManager.addToQueue(modal)
    },

    /**
     * Show closed event popup.
     *
     * @param {Object} evt
    */
    _showExpiredRequestCancellationTimeout (evt) {
      this.$store.commit('events/REQUEST_CANCELLATION_EXPIRED_SEEN', evt.id)

      if (ModalManager.hasActiveModal) return

      const id = 'md-modal-closed-event-timeout'
      const modalName = 'md-modal-closed-event-timeout'
      const callback = () => {
        this.$store.dispatch('events/requestCancellationTimeoutSeen', evt.id)
        this.$router.replace({ name: 'events' })
      }
      const propsData = { evt }
      const modalOpts = new ModalOptions()
      const excludedRoutes = [
        '/events/details/*',
        '/events/checkin'
      ]
      const modal = new Modal(
        id,
        modalName,
        callback,
        propsData,
        modalOpts,
        excludedRoutes,
        PRIORITY_LEVEL.HIGH
      )

      ModalManager.addToQueue(modal)
    },

    /**
     * Redirect user to cancellation request page.
     *
     * @param {Object} evt
    */
    _showCancellationRequestPage (evt) {
      if (ModalManager.hasActiveModal) return

      this.$store.commit('events/SET_SELECTED', evt)
      this.$router.push({ name: 'event-cancelled' })
    }

  }
}
