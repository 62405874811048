var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card", attrs: { id: "performance-overview" } },
    [
      _c("div", { staticClass: "section-label" }, [
        _c("img", {
          attrs: {
            src: "/img/dashboard/dashboard-performance-icon.svg",
            alt: "tips and infos icon"
          }
        }),
        _vm._v(" "),
        _c("h2", [
          _vm._v(_vm._s(_vm.$translate("provider_performance.overview.title")))
        ])
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "cta-button padding-sm waves-effect waves-dark margin-bottom-lg",
          class: {
            "feature-lock-indicator": _vm.isFeatureLocked
          },
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.gotoPerformancePage($event)
            }
          }
        },
        [
          !_vm.isFetchingData
            ? _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$translate(
                      "provider_performance.overview.all_stats_btn"
                    )
                  )
                )
              ])
            : _c("strong", [
                _vm._v(_vm._s(_vm.$translate("app.fetching_data")))
              ])
        ]
      ),
      _vm._v(" "),
      _c("performance-rating", {
        attrs: {
          rating: _vm.overallRatings.totalRating,
          rank: _vm.overallRatings.rank,
          "is-top-rating": _vm.isTopRating
        }
      }),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "sub-ratings" },
        [
          _c("reliability-rating", {
            attrs: { rating: _vm.overallRatings.reliability }
          }),
          _vm._v(" "),
          _c("client-rating", { attrs: { rating: _vm.overallRatings.client } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }