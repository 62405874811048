var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "complete" } },
    [
      [
        _c("div", { staticClass: "content__area --success" }, [
          _c("img", {
            attrs: {
              id: "k-logo",
              src: "/img/registration/k-logo.svg",
              alt: "k-logo"
            }
          }),
          _vm._v(" "),
          _c("h2", {
            staticClass: "content__title content__title--desktop",
            domProps: {
              innerHTML: _vm._s(_vm.$translate("register.complete.title_2"))
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content__area drawer max-height-mobile" }, [
          _c("div", { staticClass: "content center-align" }, [
            _c("h2", {
              staticClass: "content__title content__title--mobile",
              domProps: {
                innerHTML: _vm._s(_vm.$translate("register.complete.title_2"))
              }
            }),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("strong", { staticClass: "subtitle" }, [
              _vm._v(_vm._s(_vm.$translate("register.complete.subtitle")))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.$translate("register.complete.content_1")))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.$translate("register.complete.content_2")))
            ])
          ])
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "drawer-icon margin-bottom-x5" }, [
      _c("img", {
        attrs: {
          src: "/img/registration/success/success.svg",
          alt: "Registration Success"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }