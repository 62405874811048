<template>
  <div id="chat-bubble" class="chat-bubble" :class="{ 'me': me }">
    <div class="text">
      <div>{{ content }}</div>
    </div>
    <div v-if="me" class="status">
      <small v-if="seenAt !== null" class="seen-indicator left krypton-pink-text">
        <i class="material-icons valign-middle krypton-pink-text margin-right-sm">check</i> <strong>{{ $translate('events.where_is.seen') }}</strong>
      </small>
      <small class="time-ago right">{{ timeAgo }}</small>
    </div>
    <div v-else class="status">
      <small class="time-ago left">{{ timeAgo }}</small>
      <a v-if="!me" href="#" class="right btn-round waves-effect waves-effect waves-light" @click.prevent="doBlock"><i class="mdi mdi-account-off" /></a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    me: {
      type: Boolean,
      default: false
    },
    sentAt: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    seenAt: {
      type: [String, null],
      default: null
    }
  },
  computed: {
    ...mapGetters({
      currentTime: 'getCurrentTime'
    }),
    timeAgo () {
      if (this.sentAt) {
        return this.$moment(this.sentAt).from(this.currentTime)
      }
      return ''
    }
  },
  methods: {
    doBlock () {
      this.$emit('block')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./ChatBubble.style";
</style>
