import PrivatePlacePromoBanner from './PrivatePlacePromoBanner.vue'
import PrivatePlacePromoCard from './PrivatePlacePromoCard.vue'

Vue.component('private-place-promo-banner', PrivatePlacePromoBanner)
Vue.component('private-place-promo-card', PrivatePlacePromoCard)

const MultiLocationPromoBanner = require('@/features/multi-location/components/banners/MultiLocationPromoBanner.vue').default
const MultiLocationPromoCard = require('@/features/multi-location/components/banners/MultiLocationPromoCard.vue').default
Vue.component('multi-location-promo-banner', MultiLocationPromoBanner)
Vue.component('multi-location-promo-card', MultiLocationPromoCard)
