var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lesson-screen", attrs: { id: "lesson-screen-start" } },
    [
      _c("div", { staticClass: "wrapper" }, [
        _c(
          "div",
          { staticClass: "content__drawer content__drawer-filled" },
          [
            _c("div", { staticClass: "content lesson-screen--container" }, [
              _c("div", { staticClass: "lesson-screen--content" }, [
                _c("div", { staticClass: "cta__holder" }, [
                  _c(
                    "a",
                    {
                      staticClass: "cta__close waves-effect",
                      attrs: { "data-test": "photo-cls-btn", href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.resetActiveLesson($event)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("close")
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("h2", [
                  _vm._v(_vm._s(_vm.$t(_vm.openedLessonName + ".title")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t(_vm.openedLessonName + ".subtitle")))
                ]),
                _vm._v(" "),
                _vm.isLessonOpened(_vm.LESSONS.MONEY)
                  ? _c("img", {
                      attrs: {
                        src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-money-v2.svg"),
                        alt: "money"
                      }
                    })
                  : _vm.isLessonOpened(_vm.LESSONS.FEEDBACK)
                  ? _c("img", {
                      attrs: {
                        src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-feedback-v2.svg"),
                        alt: "feedback"
                      }
                    })
                  : _vm.isLessonOpened(_vm.LESSONS.DATES)
                  ? _c("img", {
                      attrs: {
                        src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-dates-v2.svg"),
                        alt: "dates"
                      }
                    })
                  : _vm.isLessonOpened(_vm.LESSONS.REQUESTS)
                  ? _c("img", {
                      attrs: {
                        src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-requests-v2.svg"),
                        alt: "requests"
                      }
                    })
                  : _vm.isLessonOpened(_vm.LESSONS.CLIENTS)
                  ? _c("img", {
                      attrs: {
                        src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-clients-v2.svg"),
                        alt: "clients"
                      }
                    })
                  : _vm.isLessonOpened(_vm.LESSONS.SAFETY)
                  ? _c("img", {
                      attrs: {
                        src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-safety-v2.svg"),
                        alt: "safety"
                      }
                    })
                  : _vm.isLessonOpened(_vm.LESSONS.AVAILABILITY)
                  ? _c("img", {
                      attrs: {
                        src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-availability-v2.svg"),
                        alt: "availability"
                      }
                    })
                  : _vm.isLessonOpened(_vm.LESSONS.INTERVIEW)
                  ? _c("img", {
                      attrs: {
                        src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-interview-v2.svg"),
                        alt: "interview"
                      }
                    })
                  : _c("img", {
                      attrs: {
                        "data-test": "photo-start-img",
                        src: require("@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-photos-v2.svg"),
                        alt: "photos"
                      }
                    })
              ])
            ]),
            _vm._v(" "),
            _c("navigator", {
              attrs: {
                current: 0,
                "no-prev": "",
                next: _vm.$t("messages.globals.continue"),
                "next-class": "action-btn__lilac-gradient action-btn__blocked",
                total: _vm.totalPages,
                "step-class": "--purple"
              },
              on: {
                next: function($event) {
                  $event.preventDefault()
                  return _vm.gotoNextLessonScreen($event)
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }