<template>
  <div
    id="content"
    class="container"
  >
    <bottomsheet :is-shown="isShown" @close="closeBottomSheet">
      <template v-slot:body>
        <h2 class="bottom-sheet__title" v-text="$t('feeSetup.title')" />
        <div class="bottom-sheet__description">
          <h3 v-text="$t('feeSetup.description.title')" />
          <p v-text="$t('feeSetup.description.content')" />
        </div>
        <div class="fees">
          <div v-for="(city, key) in cities" :key="key" class="fee">
            <div class="fee__city" v-text="city.name" />
            <multi-location-fee-input
              v-model="city.travel_cost"
              :city="city.name"
              :min-value="city.min_cost"
              :max-value="city.max_cost"
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <register-stepper-v2 :total="2" :current="2" />
        <div :class="ctaContainerClass">
          <a
            class="cta--edit waves-effect"
            data-test="fee-setup__edit"
            @click.prevent="edit"
            v-text="feeSetupEdit"
          />
          <button
            class="cta--save"
            :class="{ 'disabled': saving, 'waves-effect': !saving }"
            data-test="fee-setup__save"
            @click.prevent="save"
            v-text="feeSetupSave"
          />
        </div>
      </template>
    </bottomsheet>
  </div>
</template>

<i18n src="@/features/multi-location/translations/settings.json"></i18n>

<script>
import AuthService from '@/services/AuthService'
import MultiLocationService from '@/services/MultiLocationService'
import registerStepperV2 from '@components/register/utilities/stepper-v2.vue'
import MultiLocationFeeInput from '@/features/multi-location/components/common/MultiLocationFeeInput.vue'
import bottomsheet from '@components/v2/utilities/bottomsheet.vue'

export default {
  components: {
    MultiLocationFeeInput,
    registerStepperV2,
    bottomsheet
  },
  props: {
    selectedCities: {
      type: Array,
      required: true
    },
    isShown: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data: () => ({
    cities: [],
    saving: false
  }),
  computed: {
    ctaContainerClass () {
      return 'cta-container'
    },
    feeSetupEdit () {
      return this.$t('feeSetup.cta.edit')
    },
    feeSetupSave () {
      return this.$t('feeSetup.cta.save')
    }
  },
  mounted () {
    this.cities = this.selectedCities
  },
  methods: {
    closeBottomSheet () {
      this.$emit('close')
    },
    edit () {
      this.$emit('cancel')
    },
    async save () {
      if (this.saving) {
        return false
      }

      this.saving = true

      const result = await MultiLocationService.update(this.cities)

      if (!result) {
        return this.$notifications.toast('Oops! Something went wrong!')
      }

      const user = await AuthService.getUser()

      this.$store.commit('setUser', user)

      this.$emit('submit')

      this.saving = false
    }
  }
}
</script>

<style lang="scss" scoped>
#content {
  margin-bottom: 80px;
  font-family: 'Proxima Nova';

  .bottom-sheet__title {
    margin-bottom: 32px;
  }

  .bottom-sheet__description {
    margin-bottom: 24px;
  }

  .fees {
    .fee {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .fee__city {
        font-size: 18px;
        color: #2F3740;
        font-family: 'Proxima Nova Medium';
      }
    }
  }

  .cta-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;

    button {
      width: calc(50% - 8px);
      height: 44px;
      border-radius: 8px;
      line-height: 44px;
      font-family: 'Proxima Nova Semibold';
      font-size: 16px;
      color: #C51162;
      border: none;
    }
    a.cta--edit {
      color: #2F3740;
      text-decoration: underline;
      font-family: 'Proxima Nova Semibold';
      font-size: 16px;
    }
    button.cta--save {
      flex-basis: 50%;
      height: 47px;
      background: transparent linear-gradient(250deg, #FF0073 0%, #DC0064 100%);
      border-radius: 10px;
      line-height: 44px;
      font-family: 'Proxima Nova Semibold';
      font-size: 16px;
      color: #FFFFFF;
      max-width: 128px;
      text-align: center;
      border: none;

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        background: #CED6E0 !important;
        color: #FFFFFF !important;
        cursor: not-allowed;
      }
    }
    button.cta--save.disabled {
      cursor: default;
      background: #d6d7d7;
      color: #b5b5b5;
    }
  }
}
</style>
