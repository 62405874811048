import { putAffiliateSettingsSeen } from '@/features/affiliate/services/AffiliateService.js'
import { AFFILIATE_SETTINGS_SEEN_FIELD_TYPES } from '@/features/affiliate/constants.js'

export default {
  data: () => ({
    isLoading: false
  }),
  methods: {
    getMutationMethodByPopupType (popupType) {
      let mutationMethod = null

      switch (popupType) {
        case AFFILIATE_SETTINGS_SEEN_FIELD_TYPES.PROMO:
          mutationMethod = 'setUserSettingReferralPromoPopupSeen'
          break
        case AFFILIATE_SETTINGS_SEEN_FIELD_TYPES.REWARD:
          mutationMethod = 'setUserSettingReferralRewardPopupSeen'
          break
        case AFFILIATE_SETTINGS_SEEN_FIELD_TYPES.NEW_AFFILIATE:
          mutationMethod = 'setUserSettingReferralNewAffiliatePopupSeen'
          break
        default:
          break
      }

      return mutationMethod
    },
    closePopup (options = null) {
      // Spam protection
      if (this.isLoading) return false

      this.isLoading = true

      const mutationMethod = this.getMutationMethodByPopupType(options?.type)

      if (mutationMethod) {
        this.$store.commit(mutationMethod, 1)
      }

      if (options?.redirect) {
        this.$router.push(options.redirect)
      }

      return putAffiliateSettingsSeen({ field: options?.type })
        .then(() => {
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.handleErrorMessages(err)
        })
    },
    handleErrorMessages (err = ({ message: '' })) {
      this.$notifications.toast(err?.message, 5000)
    }
  }
}
