<template>
  <div id="video-date-setting">
    <md-navbar
      :go="-1"
      :brand="$translate('sidenav.settings')"
      icon="arrow_back"
    />
    <div
      id="content"
      class="container"
    >
      <h1 class="title">
        {{ $translate('video-date.settings.video_date_setting_title') }}
      </h1>
      <p class="setting-indicator margin-bottom-lg" :class="statudIndicatorClass">
        <i class="mdi valign-middle" :class="iconClass"/> {{ statudIndicatorText }}
      </p>
      <div style="position: relative;">
        <md-input
          v-model="skypeUsername"
          type="text"
          class="textfield-box-lg"
          :class="{
            'textfield-box-purple': !isActive && isEmptyInput
          }"
          autocomplete="username"
          tabindex="1"
          :label="$translate('video-date.settings.skype_name_label')"
        />
        <i
          v-if="(!isActive || hasChanges) && isValidSkypeID"
          class="mdi mdi-check krypton-green-text valign-middle"
          style="position: absolute; right: 15px; top: 25px;"
        />
      </div>
      <button
        :class="{
          'btn-krypton-grey': isActive && !hasChanges,
          'btn-krypton-purple waves-effect waves-light': !isActive || hasChanges
        }"
        class="confirm-btn btn btn-large btn-full"
        @click="doSave"
      >
        <div v-if="!isSaving">
          <span
            v-if="!isActive"
            class="valign-middle"
          >{{ $translate('video-date.settings.activate_video_date') }}</span>
          <span
            v-if="isActive && hasChanges"
            class="valign-middle"
          >{{ $translate('video-date.settings.video_date_save_changes') }}</span>
          <span
            v-else-if="isActive && !hasChanges"
            class="valign-middle"
          >{{ $translate('video-date.settings.deactivate_video_date') }}</span>
        </div>
        <div
          v-else
          class="preloader-wrapper small active"
        >
          <div class="spinner-layer spinner-green-only">
            <div class="circle-clipper left">
              <div class="circle" />
            </div>
            <div class="gap-patch">
              <div class="circle" />
            </div>
            <div class="circle-clipper right">
              <div class="circle" />
            </div>
          </div>
        </div>
      </button>
      <p v-if="!isActive" class="margin-y-lg">
        <input id="agree" v-model="agree" class="krypton-purple-checkbox" type="checkbox">
        <label for="agree">{{ $translate('video-date.settings.video_date_allow_show_username') }}</label>
      </p>
      <p
        style="font-size: 12px;"
        v-html="$translate('video-date.settings.video_date_explanation')"
      />
      <a
        href="#"
        class="krypton-purple-text"
        @click.prevent="viewInstruction"
      >{{ $translate('video-date.settings.how_to_find_skype_name') }}</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    skypeUsername: null,
    agree: false,
    isSaving: false
  }),
  computed: {
    ...mapGetters({
      registeredSkypeUsername: 'getSkypeName'
    }),
    hasChanges () {
      if (this.isActive) {
        return this.registeredSkypeUsername !== this.skypeUsername
      }
      return false
    },
    isActive () {
      return this.registeredSkypeUsername !== null
    },
    isValidSkypeID () {
      const format = /^(live:\S+|\S+)$/g

      return this.skypeUsername && format.test(this.skypeUsername)
    },
    isEmptyInput () {
      return !this.skypeUsername || this.skypeUsername.length === 0
    },
    statudIndicatorText () {
      if (this.isActive) {
        return this.$translate('video-date.settings.video_date_active')
      }
      return this.$translate('video-date.settings.video_date_inactive')
    },
    statudIndicatorClass () {
      if (this.isActive) {
        return { 'active': true }
      }
      return { 'inactive': true }
    },
    iconClass () {
      if (this.isActive) {
        return {
          'mdi-check-circle': true,
          'krypton-green-text': true
        }
      }
      return {
        'mdi-cancel': true,
        'krypton-pink-text': true
      }
    }
  },
  mounted () {
    this.skypeUsername = this.registeredSkypeUsername
  },
  methods: {
    doSave () {
      if (this.isSaving) return

      let isDeactivate = false

      if (this.isActive && !this.hasChanges) {
        isDeactivate = true
      }
      if (!this.isValidSkypeID && !isDeactivate) {
        this.$notifications.toast(
          this.$translate('video-date.settings.notifications.invalid_skype_format'),
          5000
        )
        return
      }
      if (!this.isActive && !this.agree) {
        this.$notifications.toast(
          this.$translate('video-date.settings.notifications.must_agree_conditions'),
          5000
        )
        return
      }

      // Deactivate video date setting by setting username to null
      const payload = (isDeactivate) ? null : this.skypeUsername

      this.isSaving = true
      this.$store
        .dispatch('updateSkypeName', payload)
        .then((res) => {
          if (payload === null) {
            this.skypeUsername = null
            this.agree = false
            this.$notifications.toast(
              this.$translate('video-date.settings.notifications.video_date_deactivated'),
              5000
            )
          } else {
            this.$notifications.toast(res.data.message, 5000)
          }
        })
        .catch((err) => {
          this.$notifications.toast(err.response.data.data.skype_name[0], 5000)
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    viewInstruction () {
      $h.videoDateInstruction()
    }
  }
}
</script>

<style lang="scss">
#video-date-setting {
  .setting-indicator {
    padding: 20px 45px;
    border-radius: 4px;

    &.inactive {
      border: 2px solid #C51162;
    }
    &.active {
      border: 2px solid #00D96F;
    }
  }
  .btn {
    font-size: 16px !important;
  }
  .mdi {
    font-size: 20px;
  }
  [type="checkbox"] + label {
    text-align: left;
  }
  [type="checkbox"] + label:before,
  [type="checkbox"]:not(.filled-in) + label:after {
    font: normal normal normal 24px/1 "Material Design Icons";
    border: none;
    color: #5611C5;
    content: "\F0131";
    transform: none;
  }
  [type="checkbox"]:checked + label:after {
    content: "";
  }
  [type="checkbox"]:checked + label:before {
    font: normal normal normal 24px/1 "Material Design Icons";
    border: none;
    color: #00E676;
    content: "\F0132";
    transform: none;
    top: 0;
    left: 0;
  }
}
</style>
