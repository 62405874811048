<template>
  <div class="input-page">
    <div class="input-field">
      <textarea
        ref="textarea"
        v-model="model"
        :class="textareaClass"
        :rows="rows"
        :cols="cols"
        :placeholder="placeholder"
        :tabindex="tabindex"
        :disabled="disabled"
        :maxlength="maxlength"
        @focus="onFocus()"
      />
      </textarea>
      <label :class="{ 'active': isLabelActive }">{{ label }}</label>
      <div v-if="maxlength">
        <span class="input-helper grey-text text-darken-1 right">
          {{ inputCount }}
        </span>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="overflow-modal"
      :class="{ 'active': showModal }"
    >
      <nav class="z-depth-0">
        <div class="nav-wrapper container">
          <a
            href="#"
            class="brand-logo waves-effect waves-dark"
            @click.prevent="showModal = false"
          >
            <i class="material-icons">arrow_back</i>
            <strong>{{ $translate('app.cancel') }}</strong>
          </a>
          <ul class="right">
            <li>
              <a
                id="sidenav-trigger"
                class="uppercase"
                data-activates="sidenav"
                @click.prevent="doSave()"
              >
                <strong>{{ $translate('app.save') }}</strong>
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div
        id="content"
        class="container"
      >
        <div class="input-field">
          <textarea
            ref="modaltextarea"
            v-model="modelCopy"
            :class="textareaClass"
            :rows="rows"
            :cols="cols"
            :placeholder="placeholder"
            :tabindex="tabindex"
            :disabled="disabled"
            :maxlength="maxlength"
          />
          </textarea>
          <label :class="{ 'active': isLabelActive }">{{ label }}</label>
          <div v-if="maxlength">
            <span class="input-helper grey-text text-darken-1 right">
              {{ characterCount }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      validator: (val) => {
        return typeof val === 'string' || val === null
      }
    },
    rows: { type: Number, required: false },
    cols: { type: Number, required: false },
    label: { type: String, default: '' },
    multiline: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    tabindex: { type: Number, required: false },
    maxlength: { type: Number, required: false },
    placeholder: { type: String, default: '' }
  },
  data () {
    return {
      model: null,
      showModal: false,
      modelCopy: ''
    }
  },
  computed: {
    textareaClass () {
      if (this.multiline) {
        return 'materialize-multiline'
      }
      return 'materialize-textarea'
    },
    isLabelActive () {
      if ((this.model == null || !this.model.length) && this.placeholder == null) {
        return false
      }
      return true
    },
    characterCount () {
      if (this.modelCopy) {
        return `${this.modelCopy.length}/${this.maxlength}`
      }
      return `0/${this.maxlength}`
    },
    inputCount () {
      if (this.model) { return `${this.model.length}/${this.maxlength}` }
      return `0/${this.maxlength}`
    }
  },
  watch: {
    model (newval) {
      this.$emit('input', newval)
    },
    showModal (newval) {
      if (this.showModal) {
        // Disable global scrolling of the app
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    }
  },
  mounted () {
    this.model = this.value
    this.initMultiline()
  },
  updated () {
    if (!this.showModal) {
      autosize.update(this.$refs.textarea)
      autosize.update(this.$refs.modaltextarea)
    }
  },
  methods: {
    doSave () {
      this.$emit('save')
      this.model = this.modelCopy
      this.showModal = false

      autosize.update(this.$refs.textarea)
    },
    onFocus () {
      this.$emit('focus')

      const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && screen.width < 600

      // Only show modal for mobile screens.
      if (isMobile) {
        this.showModal = true
        this.modelCopy = this.model
        this.$refs.modaltextarea.focus()

        // Add a little bit of delay for the autosize to trigger correctly.
        setTimeout(() => {
          autosize.update(this.$refs.modaltextarea)
        }, 100)
      }
    },
    initMultiline () {
      if (this.multiline) {
        autosize(this.$refs.textarea)
        autosize(this.$refs.modaltextarea)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.overflow-modal {
  position: fixed;
  top: 0;
  background: #FFF;
  z-index: 999;
  width: 100%;
  right: -100%;
  height: 100%;
  transition: all 0.2s;
  overflow: auto;

  nav {
    position: fixed;
    z-index: 9999;
  }
  &.active {
    right: 0%;
  }
  #content {
    margin-top: 56px;
  }
}
</style>
