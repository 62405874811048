// src/services/one-signal/WebPushService.js

class WebPushService {
  constructor () {
    if (!WebPushService.instance) {
      WebPushService.instance = this
    }

    return WebPushService.instance
  }

  init () {
    this.$OneSignal.init({
      appId: process.env.ONE_SIGNAL_APP_ID,
      serviceWorkerParam: { scope: '/push/onesignal/' },
      promptOptions: {
        slidedown: {
          actionMessage: 'Abonniere Benachrichtigungen und verpasse nie wieder eine neue Anfrage oder ein Update.',
          acceptButton: 'Abonnieren',
          cancelButton: 'Nein, danke',
          positiveUpdateButton: 'Abonnieren U',
          negativeUpdateButton: 'Nein, danke U'
        }
      }
    }).then(() => {
      this.$OneSignal.Notifications.addEventListener('permissionChange', (permission) => {
        console.log('3720 - permission change listener', permission)

        // If granted login the user
        if (permission) {
          console.log('3720 - one signal prompt allowed')

          this.$OneSignal.User.PushSubscription.addEventListener('change', (event) => {
            console.log('event from push subscription', event)
            // if (event.token) {
            //   // Login and optin
            //   const testExternalId = 'actual_test_julia4'
            //   this.$OneSignal.login(testExternalId).then(() => {
            //     console.log('os login success')
            //     console.log('3270 - one signal user', this.$OneSignal.User)
            //     this.$OneSignal.User.PushSubscription.optIn().then(() => {
            //       console.log('3720 - User after opt in', this.$OneSignal.User)
            //       this.getOneSignalUserInfo()
            //     }).catch(e => console.log('3720 - ERROR opt in', e))
            //   }).catch(e => console.log('3720 - os err login', e))
            // }
          })
        }
      })
    }).catch(e => console.log('3720 - os err init', e))
  }

  async promptPush () {
    try {
      await this.$OneSignal.Slidedown.promptPush()
    } catch (error) {
      console.error('Error prompting for push:', error)
    }
  }
}

const instance = new WebPushService()
Object.freeze(instance)

export default instance
