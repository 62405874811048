var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "affiliate-landing-page__q-and-a" } }, [
    _c("h2", { domProps: { textContent: _vm._s(_vm.$t("faq.title")) } }),
    _vm._v(" "),
    _c(
      "ul",
      { attrs: { id: "q-and-a" } },
      _vm._l(_vm.$t("faq.list"), function(item, key) {
        return _c("li", { key: key, class: { active: _vm.isActive(key) } }, [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.toggleAccordion(key)
                }
              }
            },
            [
              _c("i", {
                staticClass: "material-icons",
                domProps: { textContent: _vm._s(_vm.isArrowUpOrDown(key)) }
              }),
              _vm._v(" "),
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("faq.list[" + key + "].title", {
                      amount: _vm.getRewardByCountryCode
                    })
                  )
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "answer" },
            [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(
                      "faq.list[" + key + "].content",
                      _vm.getArgumentsForFAQContent
                    )
                  )
                }
              }),
              _vm._v(" "),
              _vm.isLastItem(key)
                ? _c("router-link", { attrs: { to: { name: "support-v2" } } }, [
                    _vm._v("Support")
                  ])
                : _vm._e()
            ],
            1
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }