<template>
  <div
    id="register-mobile"
    class="register-page"
  >
    <div id="start">
      <div
        id="content"
        class="container"
      >
        <img
          src="/img/icons/kr-logo-144.png"
          width="48"
          style="border-radius: 4px;"
        >
        <div
          id="header"
          style="font-size: 32px;"
        >
          <div
            class="text-darken-1"
            style="line-height: 36px"
          >
            {{ $translate('register.start.header_line_1') }}
          </div>
          <div class="text-darken-1">
            {{ $translate('register.start.header_line_2') }}
          </div>
        </div>
        <vform @submit="doSubmit">
          <div
            v-if="errorStart"
            class="grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align"
          >
            <small>{{ errorStart }}</small>
          </div>
          <md-input
            :value="username"
            type="text"
            class="textfield-box-lg"
            autocomplete="username"
            tabindex="1"
            :label="$translate('register.start.email')"
            @input="onUsernameChange"
          >
            <div
              v-show="errorEmail"
              class="left-align input-helper red-text"
            >
              {{ errorEmail }}
            </div>
          </md-input>
          <md-input
            :value="password"
            type="password"
            class="textfield-box-lg"
            autocomplete="password"
            tabindex="2"
            :label="$translate('register.start.password')"
            @input="onPasswordChange"
          >
            <div
              v-show="errorPassword"
              class="left-align input-helper red-text"
            >
              {{ errorPassword }}
            </div>
          </md-input>
          <div class="left-align margin-bottom-md">
            <span class="grey-text xsmall">{{ $translate('register.start.password_tip') }}</span>
          </div>
          <button
            type="submit"
            tabindex="3"
            class="btn btn-raised btn-large waves-effect waves-light display-block btn-krypton-pink waves-effect waves-light"
          >
            {{ $translate('register.start.register_btn') }}
          </button>
          <em><small>{{ $translate('register.start.register_sub_text') }}</small></em>
          <div
            class="margin-top-lg"
            style="font-size: 14px;"
          >
            <span style="color: #757575;">{{ $translate('register.start.already_registered') }}</span>
            <a
              href="#"
              @click.prevent="$router.push({ name: 'login' })"
            >
              {{ $translate('register.start.login_instead') }}
            </a>
          </div>
        </vform>
      </div>
    </div>
  </div>
</template>

<script>
import StartViewController from './mixins/start-view.controller'

export default {
  mixins: [StartViewController]
}
</script>
