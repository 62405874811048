import { buildParam } from '@/services/ApiService'
export const requestReactivationUrl = '/v2/register/account/request-reactivation'

export const reactivation = (payload = { contact_detail: null }) => {
  return new Promise((resolve, reject) => {
    window.api.post({
      url: buildParam(requestReactivationUrl),
      payload
    }, (success) => {
      resolve(success.data)
    }, (err) => {
      reject(err?.response?.data ?? err)
    })
  })
}

export default {
  reactivation,
  requestReactivationUrl
}
