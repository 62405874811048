var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal modal-v2 center-align apv-v2" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("p", { staticClass: "modal-text" }, [_vm._v(_vm._s(_vm.content))])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass:
            "action-btn action-btn__secondary waves-effect waves-dark",
          attrs: { "data-test": "inverted-confirm" },
          on: { click: _vm.confirm }
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.confirmText || _vm.$translate("app.ok")) +
              "\n    "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "action-btn action-btn__black waves-effect waves-dark modal-close",
          on: { click: _vm.cancel }
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.cancelText || _vm.$translate("app.cancel")) +
              "\n    "
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }