<template>
  <div id="private-place_overview" class="wrapper">
    <div id="content" class="container">
      <feature-overview
        :header-title="$translate('private-place.header.title')"
        :header-subtitle="$translate('private-place.header.subtitle')"
        :header-tagline="$translate('private-place.header.explanation')"
        :main-header-logo="'/img/private-place/private-place-header-img-v2-overview.svg'"
        :tagline-logo="'/img/private-place/private-place-subheader-img-v2.svg'"
        primary-header-color="#3AE86C"
        secondary-header-color="#46E4E9"
        header-gradient-angle="46deg"
        tagline-gradient-angle="18deg"
        :header-navbar-redirection="'dashboard'"
        :header-img-dimensions="{ height: '209px', width: '245px' }"
        :features="features"
      />
      <q-and-a
        :title="$translate('private-place.q_and_a.title')"
        :default-opened-answer="0"
        :questions="questions"
      />
      <overview-cta :redirect-to="'private-place-setting'" :label="$translate('private-place.header.cta')" :icon="'person_pin_circle'" />
    </div>
  </div>
</template>

<i18n src="@/features/private-place/translations/private-place.json"></i18n>

<script>
import FeatureOverview from '@/features/overview/FeatureOverview.vue'
import QAndA from '@/features/overview/QAndA.vue'
import OverviewCta from '@/features/overview/OverviewCTA.vue'

import values from 'lodash/values'

export default {
  components: {
    FeatureOverview,
    QAndA,
    OverviewCta
  },
  data () {
    return {
      features: values(this.$t('features')),
      questions: values(this.$t('faq'))
    }
  },
  computed: {
    REDESIGN_A2_3283 () {
      return this.$store.getters['featureflag/isEnabled']('REDESIGN_A2_3283')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./privatePlaceOverview.style";
</style>
