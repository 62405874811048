var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "super-provider-card super-provider-prereq-status" },
    [
      _c("h2", {
        staticClass: "super-provider-card__title margin-bottom-sm",
        domProps: { textContent: _vm._s(_vm.$t("title")) }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "super-provider-card__tooltip-trigger" }, [
        _c("div", {
          domProps: {
            textContent: _vm._s(
              _vm.$t("tooltip.trigger", { min: _vm.minReqSuccessfulRequests })
            )
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "ul",
        {
          staticClass: "super-provider-prereq-status__checkboxes",
          attrs: { "data-test": "sp-prereq-sr-checkbox-container" }
        },
        [
          _vm._l(_vm.actualSuccessfulRequests, function(val, key) {
            return _c(
              "li",
              {
                key: "succesful-meeting-" + key,
                staticClass:
                  "super-provider-prereq-status__checkbox successful-meeting"
              },
              [_c("i", { staticClass: "material-icons" }, [_vm._v("done")])]
            )
          }),
          _vm._v(" "),
          _vm._l(_vm.minRequiredDifference, function(val, key) {
            return _c("li", {
              key: "empty-placeholder-" + key,
              staticClass:
                "super-provider-prereq-status__checkbox empty-placeholder"
            })
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("p", {
        staticClass:
          "super-provider-prereq-status__successful-meeting-count-text",
        attrs: { "data-test": "sp-prereq-sr-count" },
        domProps: {
          textContent: _vm._s(
            _vm.$t("successfulMeetingCountText", {
              count: _vm.actualSuccessfulRequests
            })
          )
        }
      }),
      _vm._v(" "),
      _c("p", {
        staticClass:
          "super-provider-prereq-status__min-required-difference-text",
        attrs: { "data-test": "sp-prereq-sr-missing-count" },
        domProps: {
          textContent: _vm._s(
            _vm.$t("minRequiredDifferenceText", {
              count: _vm.minRequiredDifference
            })
          )
        }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "super-provider-card__box-content",
        domProps: {
          textContent: _vm._s(
            _vm.$t("boxContent", { min: _vm.minReqSuccessfulRequests })
          )
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }