var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page",
      class: {
        "bottom-space": _vm.addBottomSpace
      },
      attrs: { id: "inbox-overview" }
    },
    [
      _c(
        "div",
        { staticClass: "container", attrs: { id: "content" } },
        [
          _c("h1", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$translate("inbox.inbox_overview_page.title")))
          ]),
          _vm._v(" "),
          _c("inbox-list", {
            attrs: { filter: _vm.filter },
            on: {
              "update:filter": function($event) {
                _vm.filter = $event
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("fixed-menu"),
      _vm._v(" "),
      !_vm.showBottomStatusIndicator
        ? _c(
            "div",
            [
              _vm.shouldDisplayConnectPromo
                ? _c("krypton-connect-promo-banner")
                : _vm._e(),
              _vm._v(" "),
              _vm.shouldDisplayPrivatePlacePromoBanner
                ? _c("private-place-promo-banner")
                : _vm._e(),
              _vm._v(" "),
              _vm.shouldDisplayMultiLocationPromo_PRE_A2_1815
                ? _c("multi-location-promo-banner")
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }