<template>
  <div class="footer-buttons">
    <div>
      <button class="btn btn-secondary display-block waves-effect waves-dark" @click.prevent="onCancel">
        {{ cancel }}
      </button>
    </div>
    <div>
      <button
        class="btn btn-raised waves-effect waves-light display-block btn-krypton-pink waves-effect waves-light"
        data-test="footer-cta-confirm"
        :disabled="loading"
        @click.prevent="onConfirm">
        {{ confirm }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer-buttons {
    & {
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 12px 16px;
        background: #f6fafc;
        position: fixed;
        box-sizing: border-box;
        z-index: 990;
    }
    & > div {
        & {
            width: 50%;
            float: left;
        }
        &:first-child {
            padding-right: 4px;
        }
        &:last-child {
            padding-left: 4px;
        }
        & > button {
            width: 100%;
            height: 44px;
        }
    }
    @media (min-width: 992px) {
        & {
            right: 0;
            width: 600px;
            margin: 0 auto;
        }
    }
}
</style>

<script>
export default {
  props: [
    'cancel',
    'confirm',
    'loading'
  ],
  beforeDestroy () {
    this.$store.commit('setHasFixedFooterButtons', false)
  },
  mounted () {
    this.$store.commit('setHasFixedFooterButtons', true)
  },
  methods: {
    onCancel ($e) {
      this.$emit('cancel', $e)
    },
    onConfirm ($e) {
      this.$emit('confirm', $e)
    }
  }
}
</script>
