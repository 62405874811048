<template>
  <ul id="inbox-filter">
    <a v-for="(filter, idx) in filters"
       :id="filter.key"
       :key="idx"
       class="waves-effect waves-dark padding-y-sm padding-x-md"
       :class="{ 'active': filter.key === value }"
       @click="onClick(filter.key)"><strong>{{ filter.text }}</strong></a>
  </ul>
</template>

<script>
import { INBOX_FILTER } from '@/features/inbox/constants'

export default {
  props: {
    value: {
      type: String,
      default: INBOX_FILTER.ALL
    }
  },
  computed: {
    filters () {
      return [
        { key: INBOX_FILTER.ALL, text: this.$translate('inbox.inbox_filters.all') },
        { key: INBOX_FILTER.CONNECT, text: this.$translate('inbox.inbox_filters.connect') },
        // { key: INBOX_FILTER.LIKES, text: this.$translate('inbox.inbox_filters.likes') }, // TODO: missing API implementation for Like type query
        { key: INBOX_FILTER.REVIEWS, text: this.$translate('inbox.inbox_filters.reviews') }
      ]
    }
  },
  methods: {
    onClick (filter) {
      this.$emit('click', filter)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./InboxFilter.style";
</style>
