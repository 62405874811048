var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "krypton-connect-promo-banner max-width-center" },
    [
      _c(
        "div",
        {
          ref: "banner-container",
          staticClass: "wrapper promo-banner-container --blue",
          class: { "--expanded-height": _vm.expandedHeight },
          on: {
            click: function($event) {
              return _vm.gotoPage("krypton-connect-overview")
            }
          }
        },
        [
          _c("div", { staticClass: "promo-banner" }, [
            _c("div", { staticClass: "more-requests" }, [
              _c("i", { staticClass: "material-icons" }, [_vm._v("forum")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.getBannerTitle))])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "learn-how" }, [
              _vm._v("\n        " + _vm._s(_vm.getCTAText) + "\n      ")
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }