var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(_vm.injections.components.RequestMagicLinkController, {
    tag: "component",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var data = ref.data
          var bindings = ref.bindings
          var actions = ref.actions
          return [
            _c(
              _vm.injections.components.AuthView,
              {
                tag: "component",
                attrs: { data: data },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c("h2", [
                            _vm._v(
                              _vm._s(
                                actions.translate("requestMagicLink.title1")
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("h2", [
                            _vm._v(
                              _vm._s(
                                actions.translate("requestMagicLink.title2")
                              )
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _vm._v(" "),
                _c(
                  "apv-form",
                  {
                    attrs: {
                      "is-loading": data.isLoading,
                      novalidate: "",
                      "data-test": "forgot-password-form"
                    },
                    on: { submit: bindings.handleRequestMagicLink }
                  },
                  [
                    _c("div", { staticClass: "mobile-number-container" }, [
                      _c("div", { staticClass: "country-code-container" }, [
                        _c(
                          "div",
                          { staticClass: "country-picker-container" },
                          [
                            _c("country-picker-autocomplete", {
                              staticClass:
                                "country-picker country-picker--APV_REG_REDESIGN",
                              attrs: { caret: true },
                              on: { select: bindings.handleCountrySelected },
                              model: {
                                value: data.countryCode,
                                callback: function($$v) {
                                  _vm.$set(data, "countryCode", $$v)
                                },
                                expression: "data.countryCode"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "phone-number-container" },
                        [
                          _c("apv-input", {
                            staticClass: "APV-onbo-redesign margin-bottom-lg",
                            attrs: {
                              value: data.phoneNumber,
                              "data-test": "forgotPassword-input-email",
                              type: "text",
                              tabindex: "1",
                              label: actions.translate(
                                "requestMagicLink.mobile_number"
                              ),
                              error: actions.getFieldError("phone_number")
                            },
                            on: { input: bindings.handlePhoneNumberChange }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "explanation-text" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            actions.translate("requestMagicLink.explanation")
                          ) +
                          "\n      "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "apv-button",
                      {
                        attrs: {
                          block: "",
                          gradient: "",
                          type: "submit",
                          size: "large",
                          "waves-effect": "dark",
                          "data-test": "login-cta",
                          disabled: bindings.isEmpty(data.phoneNumber),
                          rounded: 10,
                          "is-loading": data.isLoading
                        }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(actions.translate("requestMagicLink.cta")) +
                            "\n      "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "footer-auth-nav" }, [
                  _c(
                    "div",
                    { staticClass: "login-container" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "login-link",
                          attrs: { to: data.loginRoute }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              actions.translate(
                                "requestMagicLink.login_with_password"
                              )
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }