var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "support-v2-request-new" } },
    [
      _c("md-new-navbar", { attrs: { "route-name": "support-v2" } }),
      _vm._v(" "),
      _c("div", { staticClass: "container", attrs: { id: "content" } }, [
        _c("h1", { staticClass: "support-request__title" }, [
          _vm._v(_vm._s(_vm.$translate("support.new_request")))
        ]),
        _vm._v(" "),
        _c("h2", { staticClass: "support-request__subtitle" }, [
          _vm._v(_vm._s(_vm.$translate("support.form.subtitle")))
        ]),
        _vm._v(" "),
        _c(
          "form",
          [
            _vm.categoriesSelection.length > 0
              ? _c(
                  "div",
                  [
                    _c(
                      "md-select",
                      {
                        attrs: {
                          placeholder: _vm.$translate("support.form.category"),
                          label: _vm.$translate("support.form.category"),
                          "disable-default": true,
                          disabled: _vm.isInitializedWithTicket,
                          "force-mobile": true,
                          "listen-for-reinitiate-options": true,
                          "new-design": true
                        },
                        model: {
                          value: _vm.form.category,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "category", $$v)
                          },
                          expression: "form.category"
                        }
                      },
                      _vm._l(_vm.categoriesSelection, function(opt) {
                        return _c(
                          "option",
                          {
                            key: opt.key,
                            attrs: {
                              slot: "options",
                              disabled:
                                opt.disabled && opt.key !== _vm.form.category
                            },
                            domProps: { value: opt.value },
                            slot: "options"
                          },
                          [_vm._v(_vm._s(opt.text))]
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isFetchingTickets
              ? _c("div", [
                  _vm.form.category === _vm.SUPPORT_CATEGORY.MEETING ||
                  _vm.form.category === _vm.SUPPORT_CATEGORY.INVOICE
                    ? _c(
                        "div",
                        [
                          _c("md-input", {
                            attrs: {
                              type: "text",
                              label: _vm.$translate(
                                "support.form.fetching_tickets"
                              ),
                              disabled: true
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isFetchingTickets,
                    expression: "!isFetchingTickets"
                  }
                ]
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.form.category === _vm.SUPPORT_CATEGORY.MEETING ||
                          _vm.form.category === _vm.SUPPORT_CATEGORY.INVOICE,
                        expression:
                          "form.category === SUPPORT_CATEGORY.MEETING ||\n          form.category === SUPPORT_CATEGORY.INVOICE"
                      }
                    ]
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.ticketSelection.length > 0,
                            expression: "ticketSelection.length > 0"
                          }
                        ]
                      },
                      [
                        _c(
                          "md-select",
                          {
                            attrs: {
                              "listen-for-reinitiate-options": true,
                              placeholder: _vm.$translate(
                                "support.form.subject"
                              ),
                              label: _vm.$translate("support.form.subject"),
                              disabled: _vm.isInitializedWithTicket,
                              "force-mobile": true,
                              "new-design": true
                            },
                            model: {
                              value: _vm.form.reference_id,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "reference_id", $$v)
                              },
                              expression: "form.reference_id"
                            }
                          },
                          _vm._l(_vm.ticketSelection, function(opt) {
                            return _c(
                              "option",
                              {
                                key: opt.id,
                                attrs: {
                                  slot: "options",
                                  disabled: opt.id === _vm.form.reference_id
                                },
                                domProps: { value: opt.id },
                                slot: "options"
                              },
                              [_vm._v(_vm._s(_vm.prettifyTicketTitle(opt)))]
                            )
                          }),
                          0
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.ticketSelection.length === 0
                      ? _c(
                          "div",
                          [
                            _c(
                              "md-select",
                              {
                                attrs: {
                                  placeholder: _vm.$translate(
                                    "support.form.subject"
                                  ),
                                  label: _vm.$translate("support.form.subject"),
                                  disabled: true
                                }
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: { slot: "options" },
                                    slot: "options"
                                  },
                                  [_vm._v(_vm._s(_vm.$translate("app.select")))]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _vm.form.category !== _vm.SUPPORT_CATEGORY.MEETING &&
            _vm.form.category !== _vm.SUPPORT_CATEGORY.INVOICE
              ? _c(
                  "div",
                  [
                    _c(
                      "md-input",
                      {
                        attrs: {
                          type: "text",
                          maxlength: _vm.SUBJECT_TITLE_MAX_LEN,
                          label: _vm.$translate("support.form.subject"),
                          disabled: _vm.form.category === null
                        },
                        model: {
                          value: _vm.form.subject,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "subject", $$v)
                          },
                          expression: "form.subject"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "char-count",
                            staticStyle: {
                              "margin-top": "8px",
                              "margin-bottom": "16px"
                            }
                          },
                          [_vm._v(_vm._s(_vm.subjectCharCount))]
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "md-textarea",
              {
                staticStyle: { "margin-top": "16px" },
                attrs: {
                  type: "multiline",
                  maxlength: _vm.MESSAGE_MAX_LEN,
                  label: _vm.$translate("support.form.message")
                },
                model: {
                  value: _vm.form.message,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "message", $$v)
                  },
                  expression: "form.message"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "char-count",
                    staticStyle: { "margin-right": "0" }
                  },
                  [_vm._v(_vm._s(_vm.messageCharCount))]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "support-request__files" },
              _vm._l(_vm.newFileUploads, function(file, idx) {
                return _c(
                  "div",
                  { key: idx, staticClass: "support-request__file-item" },
                  [
                    _c("img", {
                      attrs: {
                        src: "/img/support/image-icon-24x24.svg",
                        alt: "image icon"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "file-info" }, [
                      _c("span", { staticClass: "file-name" }, [
                        _vm._v(_vm._s(file.name))
                      ]),
                      _c("br")
                    ]),
                    _vm._v(" "),
                    _c(
                      "i",
                      {
                        staticClass: "material-icons clickable",
                        on: {
                          click: function($event) {
                            return _vm.removeFile(idx)
                          }
                        }
                      },
                      [_vm._v("close")]
                    )
                  ]
                )
              }),
              0
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "support-request__footer" }, [
          _c("div", { staticClass: "support-request__attach-cta" }, [
            _c("img", {
              attrs: {
                src: "/img/support/image-icon-44x44.svg",
                alt: "image icon"
              }
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "support-request__attach",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.triggerFileUpload($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$translate("support.form.upload")) +
                    "\n        "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "support-request__cta",
              attrs: { disabled: _vm.formInvalid },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.submit($event)
                }
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$translate("support.buttons.send")) +
                  "\n      "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("input", {
          ref: "fileUpload",
          staticClass: "hide",
          attrs: { type: "file", accept: "image/*,.pdf", multiple: "" },
          on: { change: _vm.onFileUploadChange }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }