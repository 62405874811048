export default {
  data () {
    return {
      openedAnswer: this.defaultOpenedAnswer
    }
  },
  methods: {
    getIcon (index) {
      return (this.openedAnswer === index) ? 'keyboard_arrow_up' : 'keyboard_arrow_right'
    },
    setOpenedAnswer (index) {
      this.openedAnswer = index
    }
  }
}
