import { getPropertyOrDefault } from '@/helpers/modelUtils';

/**
 * @type
 *
 * News model.
*/
export default function News(props) {

  this.body = getPropertyOrDefault(props, 'body', '');

};
