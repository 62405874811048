import {
  BlacklistClient,
  GetMessageHistory,
  MessageReply
} from '@/features/inbox/utils/KrptonConnectAPI'

import {
  INBOX_EVENTS,
  CLIENT_DEFAULT
} from '@/features/inbox/constants'

import {
  GROUP_ID
} from '@/constants/global'

export const MAX_CHARACTER = 320
export const MAX_SEND_MESSAGE = 3
export const MAX_LIKE_SEND_MESSAGE = 1

export default {
  data: () => ({
    MAX_CHARACTER,

    isFetching: false,
    isSending: false,
    header: {
      token_id: 0,
      client_name: '',
      client_age: 0,
      provider_name: '',
      provider_age: 0
    },
    messages: [],
    message: ''
  }),
  computed: {
    ticketTitle () {
      if (this.computedSenderName == CLIENT_DEFAULT.NAME) {
        return `${this.computedSenderName}`
      } else {
        return this.hasTokenID ? `${this.computedSenderName}, ${this.computedSenderAge}` : ''
      }
    },
    computedSenderName () {
      if (this.header.type == 3 && (this.header.client_name === null || this.header.client_name.length === 0)) {
        return CLIENT_DEFAULT.NAME
      }

      if (this.header.client_name === null || this.header.client_name.length === 0) {
        return CLIENT_DEFAULT.NAME
      }

      return this.header.client_name
    },
    computedSenderAge () {
      if (this.header.type == 3 && (this.header.client_name === null || this.header.client_name.length === 0)) {
        return CLIENT_DEFAULT.AGE
      }

      if (this.header.client_age === 0) {
        return CLIENT_DEFAULT.AGE
      }

      return this.header.client_age
    },
    isEmptyChat () {
      return this.messages.length === 0
    },
    sentMessageCount () {
      return this.messages.filter(m => this.isProvider(m.from_group_id)).length
    },
    sendMessageRemaining () {
      if (this.header.type == 3) {
        return MAX_LIKE_SEND_MESSAGE - this.sentMessageCount
      }

      return MAX_SEND_MESSAGE - this.sentMessageCount
    },
    lastSentMessage () {
      const messages = this.messages
      return (!this.isEmptyChat) ? messages[messages.length - 1] : null
    },
    cannotReply () {
      return (!this.isEmptyChat) ? this.isProvider(this.lastSentMessage.from_group_id) : false
    },
    canSendMessage () {
      return this.sendMessageRemaining > 0 && !this.cannotReply
    },
    noMessage () {
      return this.message.length === 0
    },
    noRemainingMessageLeft () {
      return this.sendMessageRemaining === 0
    },
    textPlaceholder () {
      return this.$translate('inbox.chat.message_placeholder')
    },
    hasTokenID () {
      return this.header.token_id !== 0
    },
    messageListClassName () {
      if (this.header.type == 3) {
        return 'message-list--photo-like'
      }

      return 'message-list'
    }
  },
  mounted () {
    this.fetchData()
      .then((res) => {
        this.messages = res.messages
        this.header = res.header
        this.scrollToLastMessage()
      })
      .catch(() => {
        this.$router.replace({ name: 'inbox' })
      })

    $h.EventBus.$on(INBOX_EVENTS.ON_KRYPTON_CONNECT_REPLY, this.onClientReply)
    $h.EventBus.$on(INBOX_EVENTS.ON_KRYPTON_CONNECT_MESSAGE_SEEN, this.messagesSeen)
  },
  methods: {
    send () {
      if (this.isSending) {
        this.$notifications.toast('Sending message...', 5000)
        return
      }
      if (this.noMessage) {
        this.$notifications.toast(this.$translate('inbox.chat.no_content'), 5000)
        return
      }
      if (this.cannotReply || this.noRemainingMessageLeft) {
        this.onMessageClicked()
        return
      }

      this.isSending = true

      MessageReply(this.$route.params.id, this.message)
        .then((res) => {
          this.messages.push(res.data)
          this.message = ''
        })
        .catch((err) => { console.error(err) })
        .finally(() => {
          this.isSending = false
        })
    },
    isProvider (groupID) {
      return groupID === GROUP_ID.PROVIDER
    },
    onBlockClient (clientID) {
      const content = this.$translate('inbox.block.explanation', { client_name: this.computedSenderName })

      $h.blockClientModal({ content }, () => {
        BlacklistClient(clientID)
          .then(this.blockClientSuccess)
          .catch((err) => {
            this.$notifications.toast(err.data.message, 3000)
          })
      })
    },
    blockClientSuccess () {
      $h.blockClientSuccessModal(() => {
        this.$router.replace({ name: 'inbox' })
      }, () => {
        // On support link click
        this.$router.push({ name: 'support-v2-request', params: { id: 'new' } })
      })
    },
    fetchData (showFetchIndicator = true) {
      if (this.isFetching) return
      if (showFetchIndicator) {
        this.isFetching = true
      }

      return new Promise((resolve, reject) => {
        GetMessageHistory(this.$route.params.id)
          .then((res) => { resolve(res.data) })
          .catch((err) => {
            if (err.status === 404) {
              this.$notifications.toast(err.data.message, 3000)
            }
            reject(err)
          })
          .finally(() => { this.isFetching = false })
      })
    },
    onClientReply (res) {
      if (this.header.token_id === Number(res.token_id)) {
        this.fetchData(false)
          .then((res) => {
            // we only need the last message to append on our existing list
            this.messages.push(res.messages.pop())
            this.scrollToLastMessage()
          })
      }
    },
    onMessageClicked () {
      if (this.noRemainingMessageLeft) {
        this.$notifications.toast(this.$translate('inbox.chat.no_messages_left', { client_name: this.header.client_name }), 5000)
      } else if (this.cannotReply) {
        this.$notifications.toast(this.$translate('inbox.chat.needs_client_reply', { client_name: this.header.client_name }), 5000)
      }
    },
    scrollToLastMessage (delay = 100) {
      setTimeout(() => {
        const chats = document.querySelectorAll('.chat-bubble')

        if (chats.length > 2) {
          chats[chats.length - 1].scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }, delay)
    },
    messagesSeen () {
      for (let i = 0, limit = this.messages.length; i < limit; ++i) {
        this.messages[i].seen_at = moment().format('YYYY-MM-DD HH:mm:ss')
      }
    }
  }
}
