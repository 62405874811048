import { mapGetters } from 'vuex'
const ENABLED = 1

export default {
  computed: {
    ...mapGetters({
      user: 'getUser',
      isCandidateProfile: 'isCandidateProfile'
    }),
    isConnectEnabled () {
      return this.user?.settings?.connect_enabled === ENABLED
    },
    isPrivatePlaceEnabled () {
      return this.user?.settings?.private_place_enable === ENABLED
    },
    isMultiLocationEnabled () {
      return this.user?.settings?.multi_location_enabled === ENABLED
    },
    isMultiLocationSupported () {
      return this.$store.getters['multiLocation/isMultiLocationSupported']
    },
    shouldDisplayMultiLocationPromo () {
      return this.isMultiLocationSupported &&
        this.user._has_successful_meeting &&
        !this.isMultiLocationEnabled &&
        !this.isCandidateProfile
    },
    shouldDisplayMultiLocationPromo_PRE_A2_1815 () {
      return this.isMultiLocationSupported &&
        this.user._has_successful_meeting &&
        !this.isMultiLocationEnabled &&
        !this.shouldDisplayPrivatePlacePromo &&
        !this.shouldDisplayConnectPromo &&
        !this.isCandidateProfile
    },
    shouldDisplayPrivatePlacePromo () {
      return !this.isPrivatePlaceEnabled && !this.isCandidateProfile
    },
    shouldDisplayPrivatePlacePromoBanner () {
      return !this.isPrivatePlaceEnabled &&
        !this.shouldDisplayConnectPromo &&
        !this.isCandidateProfile
    },
    shouldDisplayConnectPromo () {
      return !this.isConnectEnabled &&
      !this.isCandidateProfile
    },
    isVipPhotosEnabled () {
      return this.user?.settings?.is_vip_photos_enabled == ENABLED
    }
  }
}
