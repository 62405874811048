var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "super-provider-popup__title sp-popup__fail" }, [
      _c("h2", {
        staticClass: "greetings",
        attrs: { "data-test": "sp-popup-title" },
        domProps: { textContent: _vm._s(_vm.$t("not_super_provider.title")) }
      }),
      _vm._v(" "),
      _c("p", {
        staticClass: "subtitle",
        attrs: { "data-test": "sp-popup-subtitle" },
        domProps: { textContent: _vm._s(_vm.$t("not_super_provider.subtitle")) }
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/img/super-provider/popup-req-not-met-9167.svg",
          alt: ""
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "super-provider-popup__status",
        attrs: { "data-test": "sp-popup-status" }
      },
      [
        _c(
          "i",
          {
            staticClass:
              "material-icons super-provider-popup__icon super-provider-popup__icon--fail"
          },
          [_vm._v("cancel")]
        ),
        _vm._v(" "),
        _c("span", {
          domProps: { textContent: _vm._s(_vm.$t("not_super_provider.status")) }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }