<script>
import AuthService from '@/services/AuthService'

export default {
  name: 'ForgotPasswordStart',
  data () {
    return {
      countryCode: 'DE',
      phoneNumber: null,
      callingCode: 49,
      email: null,
      errorMsg: null,
      errorsObj: null,
      isLoading: false
    }
  },
  computed: {
    getLanguage () {
      return this.$i18n.locale === 'de' ? 'de' : 'en'
    },
    registerRoute () {
      return {
        name: 'register-start',
        ...(
          this.locale === 'en' && {
            params: {
              lang: 'en'
            }
          })
      }
    },
    loginRoute () {
      return {
        name: 'login',
        ...(
          this.getLanguage === 'en' && {
            params: {
              lang: 'en'
            }
          })
      }
    }
  },
  methods: {
    isEmpty (field = null) {
      return field === null || field === ''
    },
    handleCountrySelected (value) {
      const { alpha2Code, callingCode } = value
      this.countryCode = alpha2Code
      this.callingCode = callingCode
    },
    async handleRequestMagicLink (event) {
      this.isLoading = true
      this.errorMsg = null

      try {
        const processPhoneNumber = (callingCode, phoneNumber) => `${callingCode}*****${phoneNumber.slice(-4)}`
        this.$store.commit('magicLink/setPhoneNumber', processPhoneNumber(this.callingCode, this.phoneNumber))
        const response = await AuthService.requestMagicLink(this.countryCode, this.phoneNumber)
        if (response.data.successful === 1) {
          this.$router.replace({ name: 'request-magic-link-success', params: { lang: this.getLanguage } })
        }
      } catch (err) {
        if (err.data.successful === 1) {
          return this.$router.replace({ name: 'request-magic-link-success', params: { lang: this.getLanguage } })
        }

        this.errorsObj = ({
          country_code: err.message,
          phone_number: err?.data?.phone_number[0] ?? ''
        })
      }

      this.isLoading = false
    },
    handlePhoneNumberChange (val) {
      this.phoneNumber = val
      this.removeFieldError('phone_number')
    },
    removeFieldError (field = null) {
      if (this.errorsObj && this.errorsObj[field]) {
        delete this.errorsObj[field]
      }
    }
  },
  render () {
    return this.$scopedSlots.default({
      data: {
        countryCode: this.countryCode,
        phoneNumber: this.phoneNumber,
        email: this.email,
        errorMsg: this.errorMsg,
        errorsObj: this.errorsObj,
        isLoading: this.isLoading,
        registerRoute: this.registerRoute,
        loginRoute: this.loginRoute
      },
      actions: {
        translate: (key, opts) => { return this.$t(key, opts) },
        getFieldError: (field) => (this.errorsObj && this.errorsObj[field]) ? this.errorsObj[field] : null
      },
      bindings: {
        handleRequestMagicLink: this.handleRequestMagicLink,
        handlePhoneNumberChange: this.handlePhoneNumberChange,
        handleCountrySelected: this.handleCountrySelected,
        isEmpty: this.isEmpty
      }
    })
  }
}
</script>

<i18n src="../../translations.json"></i18n>
