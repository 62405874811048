var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "super-provider-page" } },
    [
      _c("md-new-navbar"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container", attrs: { id: "content" } },
        [
          !_vm.isLoading
            ? _c(_vm.component2show, { tag: "component" })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }