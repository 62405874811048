var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "waves-effect",
      attrs: { href: "#" },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.copy(_vm.val)
        }
      }
    },
    [_c("i", { staticClass: "material-icons" }, [_vm._v("content_copy")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }