<template>
  <div
    id="event-card"
    :class="{
      'event-card--cancelled': isCancelled,
      'event-card--rejected': isRejected,
      'soon': isSoon
    }"
  >
    <input name="meeting-date-time" type="hidden" :value="payload.meeting_start_at">
    <div
      v-if="idx === 0"
      class="card-controls grey-text clearfix"
    >
      <div class="card-date float-left">
        <small v-if="isPending || isReactivated">
          {{ $translate('apv-card.pending') }}
        </small>
        <small v-else>
          {{ month }}
        </small>
      </div>
    </div>

    <div
      class="view-wrapper"
      @click="gotoEventDetail()"
    >
      <default-card-view
        v-if="canShowDefaultView"
        :show-countdown="showCountdown"
        :payload="payload"
      />

      <pending-card-view
        v-if="isPending"
        :show-countdown="showCountdown"
        :payload="payload"
      />

      <cancelled-card-view
        v-if="canShowCancelled"
        :payload="payload"
      />

      <ready-to-go-card-view
        v-if="isReadyToGo"
        :show-countdown="showCountdown"
        :payload="payload"
      />

      <reactivate-card-view
        v-if="isReactivated"
        :show-countdown="showCountdown"
        :payload="payload"
      />
    </div>
  </div>
</template>

<script>
import EventCardMixin from './mixins/event-card'

import ReactivateCardView from './components/cards/reactivate'
import ReadyToGoCardView from './components/cards/ready-to-go'
import CancelledCardView from './components/cards/cancelled'
import DefaultCardView from './components/cards/default'
import PendingCardView from './components/cards/pending'

export default {
  name: 'EventCard',
  components: {
    ReactivateCardView,
    ReadyToGoCardView,
    CancelledCardView,
    DefaultCardView,
    PendingCardView
  },
  mixins: [
    EventCardMixin
  ],
  props: {
    idx: {
      type: Number,
      required: true
    }
  },
  computed: {
    canShowDefaultView () {
      return !this.isPending && !this.isReadyToGo &&
             !this.isCancelled && !this.isRejected &&
             !this.isReactivated
    },
    canShowCancelled () {
      return (this.isCancelled || this.isRejected) && !this.isReactivated
    },
    date () {
      if (this.isToday) { return this.$translate('apv-card.today') }
      return `${this.payload._meeting_start_at.format('dd')}\n${this.payload._meeting_start_at.format('DD')}`
    },
    month () {
      return this.payload._meeting_start_at.format('MMM YYYY').replace('.', '')
    },
    isToday () {
      const today = moment()
      const isSame = this.payload._meeting_start_at.format('YYYY-MM-DD') === today.format('YYYY-MM-DD')

      return isSame
    },
    isRoom () {
      if (this.payload && this.payload.meeting_point) {
        const meetingPoint = this.payload.meeting_point.toLowerCase()

        return meetingPoint === 'room' || meetingPoint === 'zimmer'
      }
      return false
    },
    meetingStart () {
      const meetingStartAt = this.payload._meeting_start_at.clone()

      let delay = 0

      if (this.payload.client_delay_seen &&
         (this.payload.client_delay > 0 || this.payload.provider_delay > 0)) {
        delay = this.payload.client_delay

        if (this.payload.provider_delay > delay) {
          delay = this.payload.provider_delay
        }
      }

      return meetingStartAt.add(delay, 'minutes').format('HH:mm')
    },
    acceptCountdown () {
      if (this.payload) {
        if (this.payload._expiration_in_seconds !== null &&
            this.payload._expiration_in_seconds > 0) {
          return moment().hour(0)
            .minutes(0)
            .second(this.payload._expiration_in_seconds)
            .format('HH[h] : mm[m] : ss[s]')
        }
      }
      return '00h : 00m : 00s'
    },
    feedbackConfirmationCountdown () {
      if (this.payload._feedback_metadata.expiration_in_seconds !== null &&
          this.payload._feedback_metadata.expiration_in_seconds > 0) {
        return moment().hour(0)
          .minutes(0)
          .second(this.payload._feedback_metadata.expiration_in_seconds)
          .format('HH:mm:ss')
      }
      return '00:00:00'
    },
    meetingCountdown () {
      let delay = 0

      if (this.payload.client_delay_seen &&
         (this.payload.client_delay > 0 || this.payload.provider_delay > 0)) {
        delay = this.payload.client_delay

        if (this.payload.provider_delay > delay) {
          delay = this.payload.provider_delay
        }
      }

      if ((this.payload._meeting_start_in_seconds + $h.minToSeconds(delay)) > 0) {
        return moment().hour(0)
          .minutes(0)
          .second(this.payload._meeting_start_in_seconds + $h.minToSeconds(delay))
          .format('HH:mm:ss')
      }
      return '00:00:00'
    },
    hasNotification () {
      let hasTicketUpdate = this.payload.event_seen === 0

      // Do now show unread notification if the event is still pending
      // since the user cannot see the update for meeting point and identifier fields;
      if (!this.isReadyToGo &&
           (this.payload._updates.meeting_point ||
            this.payload._updates.client_identifier ||
            // check if empty array
            this.payload._updates == false)) {
        hasTicketUpdate = false
      }

      return hasTicketUpdate || this.payload._unread_message_count > 0
    },
    needsFeedback () {
      return this.isEventNeedsFeedback(this.payload)
    }
  }
}
</script>
