<template>
  <transition name="slide">
    <div id="photo-tips" class="wrapper">
      <template>
        <div class="content__area overlay">
          <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
          <h2 class="content__title content__title--desktop">{{ $route.params.title ? $route.params.title : 'Photo tips' }}</h2>
        </div>
        <div id="isFullHeight" class="content__area max-height-mobile drawer">
          <div class="content">
            <div class="top-bar--drawer padding-y-unset" style="border-bottom: none; justify-content: space-between;">
              <a href="#" class="back" @click.prevent="closeTips">
                <i class="material-icons">close</i>
              </a>
              <span class="label">{{ $translate('register.photos.others.help') }}</span>
              <div style="width: 24px">&nbsp;</div>
            </div>
            <div v-if="$route.params.isOtherPhotos" class="photo-other-tip">
              <div class="container">
                <strong class="subtitle strong">
                  {{ $translate('photos.tips.slide_5.title') }}
                </strong>
                <p class="description">{{ $translate('photos.tips.slide_5.content') }}</p>
                <div class="carousel-img margin-top-md">
                  <img src="/img/registration/tips/photo-tips/photo-tip-other.png" alt="">
                </div>
                <div class="actions flex-grid justify-end">
                  <upload-other-photo
                    :is-register-mode="isRegisterMode"
                    :photo-trigger="PHOTO_TRIGGER_VALUES.PHOTO_TIPS_CTA"
                  />
                </div>
              </div>
            </div>
            <div v-else class="photo-primary-tip">
              <div class="container">
                <div ref="carousel" class="carousel carousel-slider center">
                  <div class="carousel-item left-align" href="#one!">
                    <div class="carousel-item-content">
                      <strong class="subtitle strong">{{ $translate('photos.tips.slide_1.title') }}</strong>
                      <p>{{ $translate('photos.tips.slide_1.content') }}</p>
                    </div>
                    <div class="carousel-img margin-top-md">
                      <img src="/img/registration/tips/photo-tips/photo-tip-1.svg" alt="">
                    </div>
                  </div>
                  <div class="carousel-item left-align" href="#two!">
                    <div class="carousel-item-content">
                      <strong class="subtitle strong">{{ $translate('photos.tips.slide_2.title') }}</strong>
                      <p>{{ $translate('photos.tips.slide_2.content') }}</p>
                    </div>
                    <div class="carousel-img margin-top-md">
                      <img src="/img/registration/tips/photo-tips/photo-tip-2.svg" alt="">
                    </div>
                  </div>
                  <div class="carousel-item left-align" href="#three!">
                    <div class="carousel-item-content">
                      <strong class="subtitle strong">{{ $translate('photos.tips.slide_3.title') }}</strong>
                      <p>{{ $translate('photos.tips.slide_3.content') }}</p>
                    </div>
                    <div class="carousel-img margin-top-md">
                      <img src="/img/registration/tips/photo-tips/photo-tip-3.png" alt="">
                    </div>
                  </div>
                </div>
                <div class="nav-control" @click.prevent="clickControl" />
                <div class="actions flex-grid justify-end">
                  <upload-main-photo
                    :is-register-mode="isRegisterMode"
                    :photo-trigger="PHOTO_TRIGGER_VALUES.PHOTO_TIPS_CTA"
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
      </template>

    </div>
  </transition>
</template>

<script>
import UploadMainPhoto from './upload-main-photo.vue'
import UploadOtherPhoto from './upload-other-photo.vue'
import { PHOTO_TRIGGER_VALUES } from '@/features/registration/constants.js'

export default {
  name: 'PhotoGuide',
  components: {
    UploadMainPhoto,
    UploadOtherPhoto
  },
  data () {
    return {
      carousel: null,
      PHOTO_TRIGGER_VALUES
    }
  },
  computed: {
    isRegisterMode () {
      return this.$route.name.indexOf('register') >= 0
    }
  },
  mounted () {
    if (!this.$route.params.inSession) {
      if (this.$route.fullPath.indexOf('registration') >= 0) {
        this.$router.replace({ name: 'register-overview' })
      } else {
        this.$router.replace({ name: 'photos' })
      }
      return
    }
    this.initializeCarousel()
  },
  methods: {
    initializeCarousel () {
      $(this.$refs.carousel).carousel({
        fullWidth: true,
        indicators: true
      })
    },
    destroyCarousel () {
      $(this.$refs.carousel).carousel('destroy')
    },
    clickControl (evt) {
      if (evt.offsetX < 100) {
        $(this.$refs.carousel).carousel('prev')
      } else {
        $(this.$refs.carousel).carousel('next')
      }
    },
    closeTips () {
      if (this.$route.params.return) {
        this.$router.push({ path: this.$route.params.return })
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

#register-new {

  .wrapper {

    #isFullHeight {

      .back {
        height: 48px;
        width: 48px;
      }

      @media screen and (max-width: 991px) {
        height: 100%;
        min-height: 98dvh;
      }
    }
  }
}

.carousel-img {
  img {
    width: 250px;
  }
}
.justify-end {
  justify-content: end;
}

.padding-y-unset {
  @media screen and (max-width: 991px) {
    padding-left: unset !important;
    padding-right: unset !important;
  }
}

.photo-primary-tip, .photo-other-tip {
  @media screen and (max-width: 991px) {
    margin-top: 28px;
  }

  .subtitle {
    font-size: 22px;
    color: #2F3740;
    font-family: 'Proxima Nova Semibold';
  }
  .description {
    font-family: 'Proxima Nova';
    color: #2F3740;
    font-size: 18px;
  }
}
</style>
