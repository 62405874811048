<template>
  <div class="cta__holder">
    <a data-test="photo-cls-btn" href="#" class="cta__close waves-effect" @click.prevent="redirect">
      <i class="material-icons">close</i>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    push: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    redirect () {
      if (this.push && 'name' in this.push) {
        this.$router.push(this.push)

        return
      }

      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.cta__holder {
  display: flex;
  justify-content: flex-start;
}

a.cta__close {
  font-size: 24px;
  border-radius: 50%;
  padding: 12px;
  position: relative;
  left: -18px;
  top: -18px;

  i.material-icons {
    display: block;
  }
}

a.cta__close,
a.cta__close:hover,
a.cta__close:visited {
  color: #78838F;
}
</style>
