<template>
  <div id="reliability-faq" class="page">
    <div class="container">
      <faq-header
        :header-title="$translate('faq.reliability_faq.title')"
        :header-tagline="$translate('faq.reliability_faq.intro')"
        main-header-logo="/img/faq/reliability-faq-header-img.svg"
        header-gradient-angle="237deg"
        tagline-gradient-angle="243deg"
        primary-header-color="#FF0073"
        secondary-header-color="#962BDA"
      />
      <div data-test="faq-content-section" class="container content-section">
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="$translate('faq.reliability_faq.explanation')" />
        <faq-cta
          route-name="feedback"
          :cta-txt="$translate('faq.performance_btn.performance_overview')"
          background-gradient-class="--pink"
        />
      </div>
    </div>
  </div>
</template>

<script>
import redirectMixin from '@/features/provider-faq/mixins/redirect'
import FAQHeader from '@/features/provider-faq/components/FAQHeader.vue'
import FAQCta from '@/features/provider-faq/components/FAQCta.vue'

export default {
  components: {
    'faq-header': FAQHeader,
    'faq-cta': FAQCta
  },
  mixins: [redirectMixin],
  data: () => ({
    fallbackRoute: {
      name: 'faq',
      title: 'sidenav.faq'
    }
  })
}
</script>

<style lang="scss" scoped>
#reliability-faq {
  .content-section {
    font-size: 18px;
    text-align: left;
    padding: 24px;
    background: #FFFFFF;
    color: #2F3740;

    .faq-cta-container {
      margin-top: 56px;
    }
  }
}
</style>

<style lang="scss">
#reliability-faq {
  .content-section {
    ul {
      padding-inline-start: 32px;
    }
    ul li::marker {
      color: #E30074;
      font-size: 1.5rem;
    }
    ul li {
      margin-bottom: 20px;
    }
  }
}
</style>
