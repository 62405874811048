var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPopup
    ? _c("div", { attrs: { id: "inactive-request-reward-popup" } }, [
        _c(
          "div",
          {
            staticClass: "popup__backdrop",
            attrs: { "data-test": "ir-popup-backdrop" }
          },
          [
            _c("div", { staticClass: "popup__modal" }, [
              _c("div", { staticClass: "popup__title" }, [
                _c(
                  "a",
                  {
                    staticClass: "popup__close-btn",
                    attrs: { href: "#", "data-test": "sp-popup-close" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.closePopup($event)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("close")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("h2", {
                  staticClass: "title",
                  attrs: { "data-test": "popup-title" },
                  domProps: { textContent: _vm._s(_vm.$t("title")) }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "popup-img-container" }, [
                _c("img", {
                  staticClass: "responsive-img",
                  attrs: {
                    src: require("@/features/inactive-request-rewards/assets/ir-header-img.svg")
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "popup__description_title",
                attrs: { "data-test": "popup-explanation-title" },
                domProps: { textContent: _vm._s(_vm.$t("description_title")) }
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "popup__description",
                attrs: { "data-test": "popup-explanation-text" },
                domProps: { textContent: _vm._s(_vm.$t("description")) }
              })
            ])
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }