<template>
  <div class="modal modal-v2 center-align apv-v2">
    <div class="modal-content">
      <h4 class="modal-title">{{ title }}</h4>
      <p class="modal-text">{{ content }}</p>
    </div>
    <div class="modal-footer">
      <button class="action-btn action-btn__secondary waves-effect waves-dark" data-test="inverted-confirm" @click="confirm">
        {{ confirmText || $translate('app.ok') }}
      </button>
      <button class="action-btn action-btn__black waves-effect waves-dark modal-close" @click="cancel">
        {{ cancelText || $translate('app.cancel') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => ''
    },
    content: {
      type: String,
      default: () => ''
    },
    cancelText: {
      type: String,
      default: () => ''
    },
    confirmText: {
      type: String,
      default: () => ''
    }
  },
  mounted () {
    if (!this.cancelText) {
      this.cancelText = this.$translate('app.cancel')
    }
    if (!this.confirmText) {
      this.confirmText = this.$translate('app.ok')
    }
  },
  methods: {
    confirm ($e) {
      this.$emit('confirm', $e)
    },
    cancel ($e) {
      this.$emit('cancel', $e)
    }
  }
}
</script>
