<template>
  <div id="verify-pending" class="id-verification">
    <div
      id="content"
      class="container"
    >
      <h5>
        {{ $translate('verify.verification_pending.title') }}
      </h5>
      <img
        src="/img/verify/id-verify-success.svg"
      >
      <p>
        {{ $translate('verify.verification_pending.explanation_text') }}
      </p>
      <br>
      <div class="cta-fixed">
        <button
          class="action-btn action-btn__krypton action-btn__blocked waves-effect waves-light"
          @click="doContinue"
        >
          <strong>{{ $translate('verify.verification_pending.cta_button') }}</strong>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      hasIDSubmitted: 'billing/hasIDSubmitted',
      hasIDApproved: 'billing/hasIDApproved'
    }),
    redirect () {
      if (this.hasIDSubmitted || this.hasIDApproved) {
        return { name: 'dashboard' }
      }
      return { name: 'verify-intro' }
    }
  },
  methods: {
    doContinue () {
      this.$router.replace({ name: 'dashboard' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './id-verification.styles';

.id-verification {
  h5 {
    margin-top: 30px;
    margin-bottom: 58px;
  }

  p {
    margin-top: 40px;
  }
}
</style>
