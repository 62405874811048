var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide" } }, [
    _c(
      "div",
      { staticClass: "wrapper", attrs: { id: "photo-tips" } },
      [
        [
          _c("div", { staticClass: "content__area overlay" }, [
            _c("img", {
              attrs: {
                id: "k-logo",
                src: "/img/registration/k-logo.svg",
                alt: "k-logo"
              }
            }),
            _vm._v(" "),
            _c(
              "h2",
              { staticClass: "content__title content__title--desktop" },
              [
                _vm._v(
                  _vm._s(
                    _vm.$route.params.title
                      ? _vm.$route.params.title
                      : "Photo tips"
                  )
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "content__area max-height-mobile drawer",
              attrs: { id: "isFullHeight" }
            },
            [
              _c("div", { staticClass: "content" }, [
                _c(
                  "div",
                  {
                    staticClass: "top-bar--drawer padding-y-unset",
                    staticStyle: {
                      "border-bottom": "none",
                      "justify-content": "space-between"
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "back",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.closeTips($event)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("close")
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(_vm.$translate("register.photos.others.help"))
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "24px" } }, [_vm._v(" ")])
                  ]
                ),
                _vm._v(" "),
                _vm.$route.params.isOtherPhotos
                  ? _c("div", { staticClass: "photo-other-tip" }, [
                      _c("div", { staticClass: "container" }, [
                        _c("strong", { staticClass: "subtitle strong" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$translate("photos.tips.slide_5.title")
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "description" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$translate("photos.tips.slide_5.content")
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "carousel-img margin-top-md" },
                          [
                            _c("img", {
                              attrs: {
                                src:
                                  "/img/registration/tips/photo-tips/photo-tip-other.png",
                                alt: ""
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "actions flex-grid justify-end" },
                          [
                            _c("upload-other-photo", {
                              attrs: {
                                "is-register-mode": _vm.isRegisterMode,
                                "photo-trigger":
                                  _vm.PHOTO_TRIGGER_VALUES.PHOTO_TIPS_CTA
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  : _c("div", { staticClass: "photo-primary-tip" }, [
                      _c("div", { staticClass: "container" }, [
                        _c(
                          "div",
                          {
                            ref: "carousel",
                            staticClass: "carousel carousel-slider center"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "carousel-item left-align",
                                attrs: { href: "#one!" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "carousel-item-content" },
                                  [
                                    _c(
                                      "strong",
                                      { staticClass: "subtitle strong" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$translate(
                                              "photos.tips.slide_1.title"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$translate(
                                            "photos.tips.slide_1.content"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "carousel-img margin-top-md" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          "/img/registration/tips/photo-tips/photo-tip-1.svg",
                                        alt: ""
                                      }
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "carousel-item left-align",
                                attrs: { href: "#two!" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "carousel-item-content" },
                                  [
                                    _c(
                                      "strong",
                                      { staticClass: "subtitle strong" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$translate(
                                              "photos.tips.slide_2.title"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$translate(
                                            "photos.tips.slide_2.content"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "carousel-img margin-top-md" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          "/img/registration/tips/photo-tips/photo-tip-2.svg",
                                        alt: ""
                                      }
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "carousel-item left-align",
                                attrs: { href: "#three!" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "carousel-item-content" },
                                  [
                                    _c(
                                      "strong",
                                      { staticClass: "subtitle strong" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$translate(
                                              "photos.tips.slide_3.title"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$translate(
                                            "photos.tips.slide_3.content"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "carousel-img margin-top-md" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          "/img/registration/tips/photo-tips/photo-tip-3.png",
                                        alt: ""
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "nav-control",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.clickControl($event)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "actions flex-grid justify-end" },
                          [
                            _c("upload-main-photo", {
                              attrs: {
                                "is-register-mode": _vm.isRegisterMode,
                                "photo-trigger":
                                  _vm.PHOTO_TRIGGER_VALUES.PHOTO_TIPS_CTA
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
              ])
            ]
          )
        ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }