import tippy from 'tippy.js/headless'
import './tooltip.scss'

const defaults = {
  closeable: true,
  useDefault: true,
  maxWidth: null,
  allowHTML: true,
  autohide: false
}

const tooltip = {
  create: (target, name, tippyOptions, customOptions = {}) => {
    const config = {
      ...defaults,
      ...customOptions
    }

    const tippyInstance = tippy(target, {
      ...tippyOptions,
      ...(config.closeable && { interactive: true, hideOnClick: false }),
      popperOptions: {
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              padding: 0
            }
          }
        ]
      },
      animation: false,
      render (instance) {
        const popper = document.createElement('div')
        const box = document.createElement('div')

        popper.appendChild(box)
        popper.className = 'tippy'

        if (!config.useDefault) {
          box.innerHTML = instance.props.content
          box.className = 'tooltip-container'

          return {
            popper,
            onUpdate
          }
        }

        if (config.maxWidth) {
          box.style.maxWidth = config.maxWidth
        }

        box.className = `tooltip-container-default ${config.closeable ? 'closeable' : ''} ${config.classes ?? ''}`

        box.innerHTML = `
            ${
              config.icon ?? ''
            }
            <span>${instance.props.content}</span>
            ${
              config.closeable ? '<i id="tooltip-close" class="material-icons">clear</i>' : ''
            }
        `

        function onUpdate (prevProps, nextProps) {
          if (prevProps.content !== nextProps.content) {
            box.textContent = nextProps.content
          }
        }

        return {
          popper,
          onUpdate
        }
      },
      onShown (instance) {
        if (config.autohide && config.autohide > 0) {
          setTimeout(() => {
            instance.hide()
          }, config.autohide)
        }

        window.addEventListener('resize', instance.destroy)
      },
      onHidden (instance) {
        window.removeEventListener('resize', instance.destroy)
      },
      onMount (instance) {
        if (config.closeable) {
          const close = document.getElementById('tooltip-close')
          close.addEventListener('click', instance.hide)
        }
      },
      onHide (instance) {
        if (config.closeable) {
          const close = document.getElementById('tooltip-close')

          close.removeEventListener('click', instance.hide)
        }
      },
      onUntrigger (instance) {
        instance.hide()
      },
      onClickOutside (instance, event) {
        instance.hide()
      }
    })

    return tippyInstance
  }
}

export default {
  install: (Vue, options) => {
    Vue.prototype.$tooltip = tooltip
  }
}
