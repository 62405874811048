var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "vip-photos-setting" } },
    [
      _c("md-navbar", {
        attrs: {
          go: -1,
          brand: _vm.$translate("sidenav.settings"),
          icon: "arrow_back"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "container", attrs: { id: "content" } }, [
        _c("h1", { staticClass: "title", attrs: { "data-test": "title" } }, [
          _vm._v(_vm._s(_vm.$translate("vip-photos.settings.title")))
        ]),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "description", attrs: { "data-test": "description" } },
          [_vm._v(_vm._s(_vm.$translate("vip-photos.settings.description")))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "switch-input margin-bottom-md" }, [
          _c("span", { attrs: { "data-test": "label" } }, [
            _vm._v(_vm._s(_vm.$translate("vip-photos.settings.title")))
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch", attrs: { "data-test": "toggle" } },
            [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.isEnabled,
                      expression: "isEnabled"
                    }
                  ],
                  attrs: {
                    id: "switch",
                    type: "checkbox",
                    disabled: _vm.isRequesting
                  },
                  domProps: {
                    checked: Array.isArray(_vm.isEnabled)
                      ? _vm._i(_vm.isEnabled, null) > -1
                      : _vm.isEnabled
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.isEnabled,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.isEnabled = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.isEnabled = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.isEnabled = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "lever" })
              ])
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }