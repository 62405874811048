<template>
  <div
    id="actions"
    class="actions flex-grid"
    :class="{
      'space-between': !noPrev && !noNext,
      'justify-start': !noPrev && noNext,
      'justify-end': noPrev && !noNext,
    }"
  >
    <register-stepper-v2 :total="total" :current="current" />

    <button
      v-if="!noPrev"
      class="btn btn-secondary"
      :disabled="prevDisabled"
      @click.prevent="onPrev"
    >
      {{ prevText }}
    </button>

    <button
      v-if="!noNext"
      class="btn btn-black"
      :disabled="nextDisabled"
      data-test="navigator-cta"
      @click.prevent="onNext"
    >
      {{ nextText }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    current: {
      type: Number,
      default: () => 0
    },
    total: {
      type: Number,
      default: () => 0
    },
    prev: {
      type: String,
      default: () => ''
    },
    next: {
      type: String,
      default: () => ''
    },
    prevDisabled: {
      type: Boolean,
      default: () => false
    },
    nextDisabled: {
      type: Boolean,
      default: () => false
    },
    noPrev: {
      type: Boolean,
      default: () => false
    },
    noNext: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    prevText () {
      return this.prev ? this.prev : this.$translate('register.navigator.prev')
    },
    nextText () {
      return this.next ? this.next : this.$translate('register.navigator.next')
    }
  },
  methods: {
    onPrev ($event) {
      this.$emit('prev', $event)
    },
    onNext ($event) {
      this.$emit('next', $event)
    }
  }
}
</script>

<style lang="scss" scoped>
.justify-start {
  justify-content: start;
}
.justify-end {
  justify-content: end;
}
</style>
