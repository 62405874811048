<script>
import AuthService from '@/services/AuthService'

export default {
  name: 'ForgotPasswordStart',
  data () {
    return {
      email: null,
      errorMsg: null,
      errorsObj: null,
      isLoading: false
    }
  },
  computed: {
    getLanguage () {
      return this.$i18n.locale === 'de' ? 'de' : 'en'
    },
    registerRoute () {
      return {
        name: 'register-start',
        ...(
          this.locale === 'en' && {
            params: {
              lang: 'en'
            }
          })
      }
    },
    loginRoute () {
      return {
        name: 'login',
        ...(
          this.locale === 'en' && {
            params: {
              lang: 'en'
            }
          })
      }
    }
  },
  methods: {
    isEmpty (field = null) {
      return field === null || field === ''
    },
    async handleForgotPassword (event) {
      this.isLoading = true
      this.errorMsg = null

      try {
        await AuthService.forgotPassword(this.email)
        
        this.$router.replace({ name: 'forgot-password-success', params: { lang: this.getLanguage } })
      } catch (err) {
        this.errorMsg = err.message

        this.errorsObj = ({
          email: err.message,
          password: err.message
        })
      }

      this.isLoading = false
    },
    handleEmailChange (val) {
      this.email = val
      this.removeFieldError('email')
    },
    removeFieldError (field = null) {
      if (this.errorsObj && this.errorsObj[field]) {
        delete this.errorsObj[field]
      }
    }
  },
  render () {
    return this.$scopedSlots.default({
      data: {
        email: this.email,
        errorMsg: this.errorMsg,
        errorsObj: this.errorsObj,
        isLoading: this.isLoading,
        registerRoute: this.registerRoute,
        loginRoute: this.loginRoute
      },
      actions: {
        translate: (key, opts) => { return this.$t(key, opts) },
        getFieldError: (field) => (this.errorsObj && this.errorsObj[field]) ? this.errorsObj[field] : null
      },
      bindings: {
        handleForgotPassword: this.handleForgotPassword,
        handleEmailChange: this.handleEmailChange,
        isEmpty: this.isEmpty
      }
    })
  }
}
</script>

<i18n src="../../translations.json"></i18n>
