<template>
  <div>
    <md-new-navbar class="navbar-support" :route-name="'support-v2'">
      <template v-slot:cta>
        <span class="support-v2-request__ticket-title">{{ ticketTitle }}</span>
      </template>
    </md-new-navbar>
    <div id="support-v2-request-chat" :class="{ 'ticket-closed': isTicketClosed }">
      <div id="content" class="container" :class="{'--with-bottom-status-indicator': showBottomStatusIndicator}">
        <infinite-loading direction="bottom" spinner="spiral" @infinite="infiniteHandler">
          <div slot="no-more" />
          <div slot="no-results" />
        </infinite-loading>

        <support-v2-chat-bubble
          v-for="(c, idx) in chatHistory" :key="idx"
          :message="c.message ? c.message : ''"
          :attachments="supportAttachments(c.attachments)"
          :created-at="c._created_at"
          :seen="false"
          :sent-by="(c.group_id === 2) ? c.user_id : 0"
        />

        <!-- Controls -->
        <form v-if="!isTicketClosed" @submit.prevent="sendReply">
          <div class="chat-controls container">
            <img src="/img/support/image-icon-44x44.svg" class="clickable attach-file" alt="image icon" @click="onClickFileUpload">
            <md-textarea
              v-model="message"
              type="multiline"
              class="chat-message"
              :placeholder="inputPlaceholder"
              :disabled="isFetching || isTicketClosed"
              :force-active="true"
              :maxlength="250"
              required
              @focus="onInputFocus"
              @blur="() => focused = false"
            />
            <button
              v-if="focused || message !== ''"
              class="btn btn-submit btn-round"
              type="submit"
              :class="{'btn--submit-disabled': submitDisabled }"
              :disabled="submitDisabled"
            >
              <i class="material-icons">send</i>
            </button>
          </div>

          <input ref="fileUpload" type="file" accept="image/*,.pdf" class="hide" @change="onFileUploadChange">
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import { mapGetters } from 'vuex'

import { tooltipPlugin } from '@/features/super-provider/plugins/vue-custom-tooltip.js'

const SupportProvider = $h.getProvider('SupportProvider')
const LocaleProvider = $h.getProvider('LocaleProvider')
const ApiProvider = $h.getProvider('ApiProvider')

export default {
  components: {
    InfiniteLoading
  },
  data () {
    return {
      SUPPORT_TICKET_STATUS,

      ticket: {},

      isFetchingTicketDetail: false,
      isFetching: false,
      isSending: false,

      message: '',

      chatHistory: [],

      page: 1,

      focused: false
    }
  },
  watch: {
    ticket () {
      if (this.isTicketClosed) {
        const message = this.$translate('support.ticket_closed') + ` <a href="/support-v2/request">${this.$translate('support.contact_us')}</a>`
        tooltipPlugin.toastV2(message, Infinity, 'bottom', ['overlay', 'unhideable'], 'info')
      }
    }
  },
  computed: {
    ...mapGetters({
      // ticket: 'support/GET_SELECTED_TICKET',
      currentTime: 'getCurrentTime',
      showBottomStatusIndicator: 'getBottomStatusIndicator'
    }),
    ticketTitle () {
      if (this.isFetchingTicketDetail) {
        return 'Fetching ticket detail...'
      } else {
        switch (this.ticket.cat_id) {
          case SUPPORT_CATEGORY.MEETING:
            // eslint-disable-next-line no-case-declarations
            const dt = moment(this.ticket._request.meeting_start_at)
              .locale(LocaleProvider.getLocale())
              .format('ddd, DD MMM, kk:mm')

            return `${this.ticket._request.provider_name} + ${this.ticket._request.client_name}, ${dt}`
          case SUPPORT_CATEGORY.INVOICE:
          default:
            if (this.ticket._invoice) {
              return `Invoice ID${this.ticket._invoice.invoice_number}`
            }
            return this.ticket.subject
        }
      }
    },
    inputPlaceholder () {
      if (this.isFetching) {
        return 'Fetching messages...'
      }
      return this.$translate('support.form.input_placeholder')
    },
    isTicketClosed () {
      return this.ticket.status === SUPPORT_TICKET_STATUS.CLOSED
    },
    submitDisabled () {
      return !this.message || this.isTicketClosed || this.isFetching || this.isSending
    }
  },
  beforeDestroy () {
    this.$store.commit('setHasFixedChatControls', false)

    tooltipPlugin.removeAll()
  },
  mounted () {
    const ticketID = this.$route.query.id

    if (ticketID) {
      this.ticket.id = ticketID
      this.fetchTicketDetail(ticketID)
    } else {
      this.$router.replace({ name: 'support-v2' })
    }

    $h.EventBus.$on('onsupportreply', this.onSupportReply)

    this.$store.commit('setHasFixedChatControls', true)
  },
  methods: {
    onSupportReply (data) {
      const routeName = this.$route.name

      if (routeName.indexOf('support-v2-request') === -1) return

      const newData = {
        ...data,

        _created_at: moment().locale(LocaleProvider.getLocale())
      }

      this.chatHistory.push(newData)
      this.scrollToLastMessage(500)

      window.api.get({ url: ApiProvider.supportTicketChatHistory(this.ticket.id, this.page) })
    },
    sendReply () {
      if (this.isSending || this.isFetching) return

      this.isSending = true

      const payload = {
        ticketID: this.ticket.id,
        message: this.message
      }

      this.$store
        .dispatch('support/sendReply', payload)
        .then((res) => {
          const data = {
            ...res,

            _created_at: moment().locale(LocaleProvider.getLocale())
          }

          this.chatHistory.push(data)
          this.message = ''
          this.scrollToLastMessage(500)
        })
        .catch((err) => {
          tooltipPlugin.toastV2(err.message, 5000, 'bottom', ['overlay', 'with-cta'], 'info')
        })
        .finally(() => {
          this.isSending = false
        })
    },
    onFileUploadChange () {
      if (this.isSending || this.isFetching) return

      this.isSending = true

      const file = this.$refs.fileUpload.files[0]
      const payload = {
        ticketID: this.ticket.id,
        file
      }

      this.$store
        .dispatch('support/sendFile', payload)
        .then((res) => {
          const data = {
            ...res,

            _created_at: moment().locale(LocaleProvider.getLocale())
          }

          this.chatHistory.push(data)
          this.scrollToLastMessage(500)
        })
        .catch((err) => {
          const message = err.data?.data?.attachments[0] ?? err.message
          tooltipPlugin.toastV2(message, 5000, 'bottom', ['overlay', 'with-cta'], 'info')
        })
        .finally(() => {
          this.isSending = false
        })
    },
    onInputFocus () {
      var isiPhoneSafari = navigator.userAgent.search(/CriOS/i) === -1 &&
                           navigator.userAgent.search(/iPhone/i) >= 0

      if (!isiPhoneSafari) {
        this.scrollToLastMessage(810)
      }

      this.focused = true
    },
    scrollToLastMessage (delay = 100) {
      setTimeout(() => {
        const chats = document.querySelectorAll('.chat-bubble')

        if (chats.length) {
          chats[chats.length - 1].scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }, delay)
    },
    appendChatHistory (chatHistory) {
      const prevHistoryLength = this.chatHistory.length
      const newData = chatHistory.map((r) => {
        const createdAt = moment(r.created_at).locale(LocaleProvider.getLocale())

        return {
          ...r,
          _created_at: createdAt
        }
      })

      this.chatHistory = this.chatHistory.concat(newData)

      if (prevHistoryLength === 0) {
        this.scrollToLastMessage(500)
      }
    },
    infiniteHandler ($state) {
      if (this.isFetching) return

      this.isFetching = true

      window.api.get({ url: ApiProvider.supportTicketChatHistory(this.ticket.id, this.page) },
        (success) => {
          const chatHistory = success.data.data.data

          if (chatHistory.length) {
            this.appendChatHistory(chatHistory)
            this.page += 1
            this.isFetching = false
            $state.loaded()
          } else {
            this.isFetching = false
            $state.complete()
          }
        },
        (err) => {
          console.error(err)
        },
        () => {
          this.isFetching = false
        })
    },
    fetchChathistory () {
      if (this.isFetching) return

      this.isFetching = true

      window.api.get({ url: ApiProvider.supportTicketChatHistory(this.ticket.id) },
        (success) => {
          const response = success.data
          const chatHistory = response.data.data.map((r) => {
            const createdAt = moment(r.created_at).locale(LocaleProvider.getLocale())

            return {
              ...r,
              _created_at: createdAt
            }
          })

          this.chatHistory = chatHistory
          this.pagination.page = response.current_page
          this.pagination.per_page = response.per_page

          this.scrollToLastMessage(500)

          this.$store.dispatch('support/checkNewUpdates')
        },
        (err) => {
          console.error(err)
        },
        () => {
          this.isFetching = false
        })
    },
    supportAttachments (attachments) {
      return attachments.map(a => {
        return {
          ...a,
          downloadProgress: -1
        }
      })
    },
    fetchTicketDetail (id) {
      this.isFetchingTicketDetail = true

      SupportProvider
        .fetchSupportTicketDetail(id)
        .then((res) => {
          this.ticket = res.data
        })
        .catch((err) => {
          tooltipPlugin.toastV2(err.response.message, 5000, 'bottom', ['overlay', 'with-cta'], 'info')
        })
        .finally(() => {
          this.isFetchingTicketDetail = false
        })
    },
    onClickFileUpload () {
      this.$refs.fileUpload.click()
    }
  }
}
</script>

<style lang="scss">
.navbar-support .new-navbar-container {
  height: 100%;
  display: flex !important;
  align-items: center;
  border-bottom: 1px solid #CED6E0;
}

.chat-message {
  position: relative;
  height: auto;
  width: auto;
  padding: 12px 44px 12px 16px !important;
  border-radius: 24px;
  z-index: 5;
  display: flex !important;
  align-items: center;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  flex: 1;

  ::-webkit-scrollbar {
    display: none;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #CED6E0;
    border-radius: 24px;
    opacity: 0.3;
    z-index: 3;
    height: 100%;
  }
}
</style>
