import ClientFAQ from './ClientFAQ.vue'
import SuperProviderFAQ from './SuperProviderFAQ.vue'
import CommunicationFAQ from './CommunicationFAQ.vue'
import DateFAQ from './DateFAQ.vue'
// import FAQ from './FAQ.vue'
import ReliabilityFAQ from './ReliabilityFAQ.vue'
import TotalRatingFAQ from './TotalRatingFAQ.vue'
import ConnectFAQ from './ConnectFAQ.vue'
import PrivatePlaceFAQ from './PrivatePlaceFAQ.vue'
import AffiliateFAQ from './AffiliateFAQ.vue'
import FAQ from './FAQ.vue'

Vue.component('client-faq-page', ClientFAQ)
Vue.component('super-provider-faq-page', SuperProviderFAQ)
Vue.component('communication-faq-page', CommunicationFAQ)
Vue.component('date-faq-page', DateFAQ)
Vue.component('faq-page', FAQ)
Vue.component('reliability-faq-page', ReliabilityFAQ)
Vue.component('total-rating-faq-page', TotalRatingFAQ)
Vue.component('connect-faq-page', ConnectFAQ)
Vue.component('private-place-faq', PrivatePlaceFAQ)
Vue.component('affiliate-faq-page', AffiliateFAQ)
