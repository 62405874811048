var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth-view",
    {
      attrs: { data: _vm.data },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c("h2", [
                _vm._v(_vm._s(_vm.$t("forgotPassword.successMainTitle")))
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c("div", { staticClass: "instructions" }, [
        _c("p", {
          staticClass: "padding-bottom-md",
          domProps: {
            textContent: _vm._s(_vm.$t("forgotPassword.successTitle"))
          }
        }),
        _vm._v(" "),
        _c("p", {
          staticClass: "padding-bottom-md",
          domProps: {
            textContent: _vm._s(_vm.$t("forgotPassword.successText1"))
          }
        }),
        _vm._v(" "),
        _c("p", {
          domProps: {
            textContent: _vm._s(_vm.$t("forgotPassword.successText2"))
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer-auth-nav" }, [
        _c(
          "div",
          { staticClass: "forgot-container" },
          [
            _c(
              "router-link",
              {
                staticClass: "forgot-link",
                attrs: { to: _vm.forgotPasswordRoute }
              },
              [_vm._v(_vm._s(_vm.$t("tryAgain.tryagain")))]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("span", { staticClass: "label" }, [
          _vm._v(_vm._s(_vm.$t("tryAgain.or")))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "register-container" },
          [
            _c(
              "router-link",
              {
                staticClass: "register-link",
                attrs: { to: _vm.registerRoute }
              },
              [_vm._v(_vm._s(_vm.$t("tryAgain.register")))]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }