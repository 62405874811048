var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "chat-bubble",
      class: { me: _vm.me },
      attrs: { id: "chat-bubble" }
    },
    [
      _c("div", { staticClass: "text" }, [
        _c("div", [_vm._v(_vm._s(_vm.content))])
      ]),
      _vm._v(" "),
      _vm.me
        ? _c("div", { staticClass: "status" }, [
            _vm.seenAt !== null
              ? _c(
                  "small",
                  { staticClass: "seen-indicator left krypton-pink-text" },
                  [
                    _c(
                      "i",
                      {
                        staticClass:
                          "material-icons valign-middle krypton-pink-text margin-right-sm"
                      },
                      [_vm._v("check")]
                    ),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$translate("events.where_is.seen")))
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("small", { staticClass: "time-ago right" }, [
              _vm._v(_vm._s(_vm.timeAgo))
            ])
          ])
        : _c("div", { staticClass: "status" }, [
            _c("small", { staticClass: "time-ago left" }, [
              _vm._v(_vm._s(_vm.timeAgo))
            ]),
            _vm._v(" "),
            !_vm.me
              ? _c(
                  "a",
                  {
                    staticClass:
                      "right btn-round waves-effect waves-effect waves-light",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.doBlock($event)
                      }
                    }
                  },
                  [_c("i", { staticClass: "mdi mdi-account-off" })]
                )
              : _vm._e()
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }