var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page", attrs: { id: "reliability-faq" } }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("faq-header", {
          attrs: {
            "header-title": _vm.$translate("faq.reliability_faq.title"),
            "header-tagline": _vm.$translate("faq.reliability_faq.intro"),
            "main-header-logo": "/img/faq/reliability-faq-header-img.svg",
            "header-gradient-angle": "237deg",
            "tagline-gradient-angle": "243deg",
            "primary-header-color": "#FF0073",
            "secondary-header-color": "#962BDA"
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container content-section",
            attrs: { "data-test": "faq-content-section" }
          },
          [
            _c("div", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$translate("faq.reliability_faq.explanation")
                )
              }
            }),
            _vm._v(" "),
            _c("faq-cta", {
              attrs: {
                "route-name": "feedback",
                "cta-txt": _vm.$translate(
                  "faq.performance_btn.performance_overview"
                ),
                "background-gradient-class": "--pink"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }