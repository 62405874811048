<template>
  <div class="input-page">
    <div
      class="interview-list clickable"
      :class="{ 'add-moded': isAddMode }"
      @click.prevent="() => $emit('item-clicked', selectedItem)"
    >
      <p class="margin-none">
        <small class="question-counter">{{ questionLabel }}</small>
      </p>
      <div v-if="isAddMode" class="add-text">
        <div class="left-align">
          <p class="question margin-bottom margin-top-none">
            {{ $translate('interview.select_a_question') }}
          </p>
        </div>
         <div class="answer left-align">
          <p class="margin-top-none">
            {{ $translate('interview.add_new_answer_cta') }}
          </p>
        </div>
        <i class="k-green material-icons">add_circle</i>
      </div>

      <div v-else>
        <div class="left-align">
          <p class="question margin-bottom margin-top-none">
            {{ $store.getters.getDropdownInterviews(interview.title) }}
          </p>
        </div>
        <div class="answer left-align">
          <p class="margin-top-none">
            {{ interview.answer }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  props: {
    interview: {
      validator: (val) => {
        return typeof val === 'object' || val === undefined
      },
      default () {
        return { title: '', answer: '' }
      }
    },
    index: { type: Number, required: true }
  },
  data () {
    return {
      interviewCopy: {}
    }
  },
  computed: {
    selectedItem () {
      return this.interview.title
    },
    isAddMode () {
      return this.interview.title === '' && this.interview.answer === ''
    },
    questionLabel () {
      return `${this.$translate('interview.question')} ${this.index + 1}`
    }
  },
  mounted () {
    this.interviewCopy = Object.assign({}, this.interview)
  },
  methods: {
    onClick () {
      const payload = {
        name: 'profile-interview-v2-edit',
        params: {
          title: this.interviewCopy.title
        }
      }

      this.$router.push(payload)
    }
  }
}
</script>

<style lang="scss" scoped>

.add-moded {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px dashed #CED6E0;
  border-radius: 12px;
  padding: 16px 22px 16px 17px;
}

.interview-list {

  .question-counter {
    color: #52575B;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
  }

  .question {
    color: #2F3740;
    font-size: 16px;
    font-family: 'Proxima Nova Semibold';
    line-height: 22px;
    margin-bottom: 6px;
    text-decoration: underline;
  }

  .answer p {
    color: #2F3740;
    font-size: 16px;
    line-height: 22px;
  }

  .k-green{ 
    color: #06d6a0;
    font-size: 24px;
  }

}

.question {
  position: relative;

  .question-trigger {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 10;
  }
  .material-icons {
    position: absolute;
    top: 0;
    right: 0;
    height: 50%;
    bottom: 0;
    margin: auto;
    color: #959DA6;
    z-index: 1;
  }
}
.question-input {
  input[type="text"][readonly="readonly"] {
    color: #2F3740;
    border-bottom: 2px solid #2F3740;
  }
}
.add-mode {
    background: #FFF;
    padding: 9px 16px 16px;
    border: 1px dashed #959DA6;
    border-radius: 4px;
}
.question-counter {
    font-size: 12px;
}
.add-text {
  position: relative;

  .material-icons {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.answer p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
}
</style>
