var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canShowStatusIndicatorAll
    ? _c(
        "div",
        {
          class: {
            "status-indicator--id-verification": _vm.canShowNeedVerification,
            "status-indicator--bottom": !_vm.hasFixedMenu,
            // for new fixed menu
            "status-indicator--bottom status-indicator--with-fixed-menu":
              _vm.hasFixedMenu,
            // for pages with navigator component
            "status-indicator--bottom status-indicator--with-fixed-navigator":
              _vm.hasFixedNavigator,
            // for pages with footer-button component
            "status-indicator--bottom status-indicator--with-fixed-footer-buttons":
              _vm.hasFixedFooterButtons,
            // for pages with chat controls
            "status-indicator--bottom status-indicator--with-fixed-chat-controls":
              _vm.hasFixedChatControls
          },
          attrs: {
            id: _vm.canShowNeedVerification
              ? "status-indicator"
              : "status-indicator-old"
          }
        },
        [
          _vm.canShowEventStatus
            ? _c("div", { staticClass: "event-status" }, [
                _c(
                  "div",
                  {
                    staticClass: "pre-nav-content",
                    class: {
                      pending: _vm.canShowPendingEvent,
                      "cancellation-request": _vm.canShowCancellationRequest
                    }
                  },
                  [
                    _c("div", { staticClass: "container" }, [
                      _vm.canShowActiveEvent
                        ? _c(
                            "div",
                            {
                              staticClass: "active-event clickable",
                              attrs: { id: "status-indicator-active-event" },
                              on: { click: _vm.gotoEventCheckin }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "display-inline-block" },
                                [
                                  _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons valign-middle"
                                    },
                                    [_vm._v("expand_more")]
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "valign-middle" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$translate(
                                            "app.status-indicator.ongoing"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "right timer valign-middle" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.timeElapsed) +
                                      "\n          "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canShowUpcomingEvent
                        ? _c(
                            "div",
                            {
                              staticClass: "active-event clickable",
                              attrs: { id: "status-indicator-upcoming-event" },
                              on: { click: _vm.gotoUpcomingEvent }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "display-inline-block valign-middle"
                                },
                                [
                                  _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons valign-middle"
                                    },
                                    [_vm._v("expand_more")]
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "valign-middle" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$translate(
                                            "app.status-indicator.upcoming"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm.unreadMessageCount > 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "unread-message margin-left-xs pulse",
                                          attrs: {
                                            id:
                                              "status-indicator-unread-msg-count"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.unreadMessageCount) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "right timer valign-middle" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.timeRemaining) +
                                      "\n          "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canShowPendingEvent
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "active-event pending-event clickable",
                              attrs: { id: "status-indicator-pending-event" },
                              on: { click: _vm.gotoPendingEvent }
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "hidden",
                                  name: "shown-pending-event"
                                },
                                domProps: {
                                  value: _vm.pendingEvent.meeting_start_at
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "display-inline-block" },
                                [
                                  _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons valign-middle"
                                    },
                                    [_vm._v("expand_more")]
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "valign-middle" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$translate(
                                            "app.status-indicator.pending"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "right timer valign-middle" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.acceptCountdown) +
                                      "\n          "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canShowCancellationRequest
                        ? _c(
                            "div",
                            {
                              staticClass: "active-event clickable",
                              attrs: { id: "cancel-event-popup" },
                              on: { click: _vm.gotoCancellationRequestPage }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "display-inline-block" },
                                [
                                  _c("span", { staticClass: "valign-middle" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$translate(
                                            "app.status-indicator.cancellation_request"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "right timer valign-middle" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.cancelRequestCountdown) +
                                      "\n          "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.canShowNeedVerification
            ? _c(
                "div",
                {
                  staticClass: "need-verification clickable",
                  on: { click: _vm.gotoVerify }
                },
                [
                  _c(
                    "div",
                    { staticClass: "nav-content yellow warning-base" },
                    [
                      _c("div", { staticClass: "container" }, [
                        _vm.isIDVerificationPending
                          ? _c("p", { staticClass: "verification-pending" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$translate(
                                      "verify.status_indicator.verification_title"
                                    )
                                  ) +
                                  "\n        "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isIDVerificationPending
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "waves-effect waves-dark darken verify-cta",
                                attrs: { href: "#" }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.$translate(
                                        "verify.status_indicator.cta_button"
                                      )
                                    ) +
                                    "\n        "
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.canShowRequestDisabled
            ? _c("div", { staticClass: "need-verification" }, [
                _c("div", { staticClass: "nav-content yellow darken-3" }, [
                  _c("div", { staticClass: "new-container" }, [
                    _c("p", { staticClass: "proper-font" }, [
                      _vm._v(_vm._s(_vm.$translate("app.new_request_disabled")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "billing-btn",
                        on: {
                          click: function($event) {
                            return _vm.gotoPage("billing-v2-unpaid")
                          }
                        }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$translate("app.new_request_disabled_btn")
                            )
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showVipPhotosBanner && _vm.VIP_PHOTOS_A2_2416
            ? _c("div", { staticClass: "pre-nav-content vip-photo-banner" }, [
                _c("div", { staticClass: "container" }, [
                  _c(
                    "div",
                    {
                      staticClass: "active-event pending-event clickable",
                      attrs: { id: "status-indicator-pending-event" }
                    },
                    [
                      _c("div", { staticClass: "display-inline-block" }, [
                        _c("span", { staticClass: "valign-middle" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$translate("app.vip_photo_banner_text")
                              ) +
                              "\n          "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "right vip-photo-banner-cta valign-middle",
                          staticStyle: { "font-size": "16px" },
                          on: { click: _vm.gotoVipPhotosSetting }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$translate("app.vip_photo_banner_cta")
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  )
                ])
              ])
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }