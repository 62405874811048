var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dropdown &&
    _vm.optsPiercing.length &&
    _vm.optsSmoking.length &&
    _vm.optsTattoo.length
    ? _c(
        "div",
        { staticClass: "wrapper", attrs: { id: "style" } },
        [
          [
            _c("div", { staticClass: "content__area" }, [
              _c("img", {
                attrs: {
                  id: "k-logo",
                  src: "/img/registration/k-logo.svg",
                  alt: "k-logo"
                }
              }),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "content__stepper content__stepper--mobile" },
                [_vm._v(" " + _vm._s(_vm.stepperLabel))]
              ),
              _vm._v(" "),
              _c(
                "h2",
                { staticClass: "content__title content__title--desktop" },
                [
                  _vm._v(
                    _vm._s(_vm.$translate("register.personality.title.style"))
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content__area drawer max-height-mobile" },
              [
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "content__stepper content__stepper--desktop"
                      },
                      [_vm._v(" " + _vm._s(_vm.stepperLabel))]
                    ),
                    _vm._v(" "),
                    _c(
                      "h2",
                      { staticClass: "content__title content__title--mobile" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$translate("register.personality.title.style")
                          )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "md-select",
                      {
                        staticClass: "textfield-box-lg",
                        attrs: {
                          "force-mobile": true,
                          "desktop-display-mode": "split",
                          label: _vm.$translate(
                            "register.personality.style.tattoo_label"
                          ),
                          placeholder: _vm.$translate("details.tattoo"),
                          default: _vm.$translate(
                            "register.personality.style.select_tattoo"
                          )
                        },
                        model: {
                          value: _vm.payload.tattoo,
                          callback: function($$v) {
                            _vm.$set(_vm.payload, "tattoo", $$v)
                          },
                          expression: "payload.tattoo"
                        }
                      },
                      [
                        _vm._l(_vm.optsTattoo, function(opt, i) {
                          return _c(
                            "option",
                            {
                              key: "opt-tattoo-" + i,
                              attrs: { slot: "options" },
                              domProps: { value: opt.key },
                              slot: "options"
                            },
                            [_vm._v(_vm._s(opt.content))]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("tattoo"),
                                expression: "errors.has('tattoo')"
                              }
                            ],
                            staticClass: "input-helper red-text left-align",
                            attrs: { slot: "errors" },
                            slot: "errors"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errors.first("tattoo")) +
                                "\n          "
                            )
                          ]
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "md-select",
                      {
                        staticClass: "textfield-box-lg",
                        attrs: {
                          "force-mobile": true,
                          "desktop-display-mode": "split",
                          label: _vm.$translate(
                            "register.personality.style.piercing_label"
                          ),
                          placeholder: _vm.$translate("details.piercing"),
                          default: _vm.$translate(
                            "register.personality.style.select_piercing"
                          )
                        },
                        model: {
                          value: _vm.payload.piercing,
                          callback: function($$v) {
                            _vm.$set(_vm.payload, "piercing", $$v)
                          },
                          expression: "payload.piercing"
                        }
                      },
                      [
                        _vm._l(_vm.optsPiercing, function(opt, i) {
                          return _c(
                            "option",
                            {
                              key: "opt-piercing-" + i,
                              attrs: { slot: "options" },
                              domProps: { value: opt.key },
                              slot: "options"
                            },
                            [_vm._v(_vm._s(opt.content))]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("piercing"),
                                expression: "errors.has('piercing')"
                              }
                            ],
                            staticClass: "input-helper red-text left-align",
                            attrs: { slot: "errors" },
                            slot: "errors"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errors.first("piercing")) +
                                "\n          "
                            )
                          ]
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "md-select",
                      {
                        staticClass: "textfield-box-lg",
                        attrs: {
                          "force-mobile": true,
                          "desktop-display-mode": "split",
                          label: _vm.$translate(
                            "register.personality.style.smoking_label"
                          ),
                          placeholder: _vm.$translate("details.smoke"),
                          default: _vm.$translate(
                            "register.personality.style.select_smoking"
                          )
                        },
                        model: {
                          value: _vm.payload.smoking,
                          callback: function($$v) {
                            _vm.$set(_vm.payload, "smoking", $$v)
                          },
                          expression: "payload.smoking"
                        }
                      },
                      [
                        _vm._l(_vm.optsSmoking, function(opt, i) {
                          return _c(
                            "option",
                            {
                              key: "opt-smoking-" + i,
                              attrs: { slot: "options" },
                              domProps: { value: opt.key },
                              slot: "options"
                            },
                            [_vm._v(_vm._s(opt.content))]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("smoking"),
                                expression: "errors.has('smoking')"
                              }
                            ],
                            staticClass: "input-helper red-text left-align",
                            attrs: { slot: "errors" },
                            slot: "errors"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errors.first("smoking")) +
                                "\n          "
                            )
                          ]
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "md-textarea",
                      {
                        attrs: {
                          type: "multiline",
                          "force-active": false,
                          maxlength: _vm.mottoMaxLength,
                          label: _vm.$translate("motto.motto_optional")
                        },
                        model: {
                          value: _vm.payload.motto,
                          callback: function($$v) {
                            _vm.$set(_vm.payload, "motto", $$v)
                          },
                          expression: "payload.motto"
                        }
                      },
                      [
                        _c("div", { staticClass: "input-helper" }, [
                          _c("span", { staticClass: "right" }, [
                            _vm._v(_vm._s(_vm.mottoLength))
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("motto"),
                                  expression: "errors.has('motto')"
                                }
                              ],
                              staticClass: "red-text left-align"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.first("motto")) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("register-navigator-v2", {
                      attrs: {
                        "next-disabled": _vm.isCTADisabled,
                        total: _vm.total,
                        current: _vm.current
                      },
                      on: {
                        prev: function($event) {
                          $event.preventDefault()
                          return _vm.gotoPrev($event)
                        },
                        next: function($event) {
                          $event.preventDefault()
                          return _vm.submitForm($event)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "drawer-icon --no-selected-text center-align" },
      [
        _c("img", {
          attrs: {
            src: "/img/registration/personality/style.svg",
            alt: "girl and boy dining"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }