var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "affiliate-page" } },
    [
      _c("md-new-navbar", { attrs: { "route-name": "affiliate-overview" } }),
      _vm._v(" "),
      _c("div", { staticClass: "container", attrs: { id: "content" } }, [
        _c("div", { staticClass: "affiliate-header" }, [
          _c("h1", {
            attrs: { "data-test": "title" },
            domProps: { textContent: _vm._s(_vm.$t("title")) }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "affiliate-header__card",
              attrs: { "data-test": "banner" }
            },
            [
              _c("div", { staticClass: "affiliate-header__card-container" }, [
                _c("div", {
                  staticClass: "affiliate-header__card-title",
                  attrs: { "data-test": "banner-title" },
                  domProps: { textContent: _vm._s(_vm.$t("card.title")) }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "affiliate-header__card-chip",
                    attrs: { "data-test": "banner-credits" }
                  },
                  [_vm._v(_vm._s(_vm.creditBalance))]
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "affiliate-tabs" },
          [
            _c("router-link", {
              staticClass: "affiliate-tabs__tab waves-effect",
              class: {
                "affiliate-tabs__tab--active": _vm.isTabActive(
                  "affiliate-received"
                )
              },
              attrs: {
                to: { name: "affiliate-received" },
                "data-test": "received-tab"
              },
              domProps: { textContent: _vm._s(_vm.$t("tabs.received.label")) }
            }),
            _vm._v(" "),
            _c("router-link", {
              staticClass: "affiliate-tabs__tab waves-effect",
              class: {
                "affiliate-tabs__tab--active": _vm.isTabActive(
                  "affiliate-pending"
                )
              },
              attrs: {
                to: { name: "affiliate-pending" },
                "data-test": "pending-tab"
              },
              domProps: { textContent: _vm._s(_vm.$t("tabs.pending.label")) }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "affiliate-content" }, [_c("router-view")], 1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "affiliate-cta" },
          [
            _c("affiliate-share-button", {
              attrs: { "data-test": "share-link-cta" }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }