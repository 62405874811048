<template>
  <div
    v-if="showVerificationBanner"
    id="onboarding-verification-banner"
    data-test="onboarding-verification-banner"
    class="mb-16"
    @click="gotoVerify"
  >
    <div class="header-container">
      <div>
        <div>
          {{ getVerificationTitle }}
        </div>
        <div
          v-if="showCountdownTimer"
          class="timer"
          data-test="verification-countdown-timer"
        >
          {{ getTimer }}
        </div>
      </div>
      <div>
        <img
          id="bottom-sheet-verification-banner-img"
          class="bottom-sheet-verification-banner-img"
          src="/img/bottom-sheet/verification-banner-img-v2.svg"
        >
      </div>
    </div>
    <div v-if="showCountdownTimer" class="timer-description">
      <span>{{ getTimerDescription }} </span>
      <a
        class="support-link underline"
        href="#"
        @click.stop="$router.push({name: 'support-v2'})"
      >
        {{ $translate('onboarding.verification.contact_support') }}
      </a>
    </div>
  </div>
</template>

<script>
import idVerificationMixin from '@/features/onboarding-bottom-sheet/mixins/id-verification.mixin.js'
import { mapGetters } from 'vuex'
import { ROUTE_NAMES } from '@/constants/global'
import { ID_VERIFICATION_COUNTDOWN } from '@/features/onboarding-bottom-sheet/constants.js'

export default {
  computed: {
    ...idVerificationMixin.computed,
    ...mapGetters({
      deadlineTimer: 'onboarding/GET_ID_DEADLINE_TIMER',
      deadlineMoment: 'onboarding/GET_ID_DEADLINE_MOMENT',
      currentTime: 'getCurrentTime',
      currentTimeTz: 'getCurrentTimeTz',
      hasIDRejected: 'billing/hasIDRejected',
      hasIDSubmitted: 'billing/hasIDSubmitted'
    }),
    showVerificationBanner () {
      return this.canShowNeedVerification
    },
    showCountdownTimer () {
      return parseInt(this.deadlineTimer) >= ID_VERIFICATION_COUNTDOWN.MIN &&
      parseInt(this.deadlineTimer) <= ID_VERIFICATION_COUNTDOWN.MAX
    },
    getTimer () {
      if (this.currentTimeTz) {
        return window.$h.calcCountdown(
          this.currentTimeTz,
          this.deadlineMoment,
          false,
          'full',
          false
        )
      }

      return '-- : -- : --'
    },
    getTimerDescription () {
      return this.$translate('onboarding.verification.timer_description')
    },
    getVerificationTitle () {
      return this.$translate('onboarding.verification.verify_account')
    }
  },
  methods: {
    gotoVerify (evt) {
      evt.preventDefault()

      if (this.hasIDRejected) {
        this.$router.replace({ name: ROUTE_NAMES.VERIFY_UPLOAD })
      } else if (this.hasIDSubmitted) {
        this.$router.push({ name: ROUTE_NAMES.VERIFY_PENDING })
      } else {
        this.$router.replace({ name: ROUTE_NAMES.VERIFY_INTRO })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./onboarding-verification-banner.style";
</style>
