<template>
  <div id="super-provider-page">
    <md-new-navbar />
    <div id="content" class="container">
      <component :is="component2show" v-if="!isLoading" />
    </div>
  </div>
</template>

<script>
import SuperProviderPrereq from '@/features/super-provider/pages/SuperProviderPrereq.vue'
import SuperProviderStatusComponent from '@/features/super-provider/pages/SuperProviderStatus.vue'
import { getSuperProviderSettings, postSuperProviderStatusCheck } from '@/features/super-provider/services/SuperProviderService.js'
import { mapGetters } from 'vuex'
import {
  DEFAULT_SUPER_PROVIDER_REQUIREMENTS,
  DEFAULT_SUPER_PROVIDER_STATUS,
  DEFAULT_SUPER_PROVIDER_USER_DETAILS
} from '@/features/super-provider/constants/super-provider-defaults.js'

export default {
  provide () {
    const superProviderData = {}

    Object.defineProperty(superProviderData,
      'superProviderRequirements', {
        enumerable: true,
        get: () => this.superProviderRequirements
      }
    )

    Object.defineProperty(superProviderData,
      'superProviderStatus', {
        enumerable: true,
        get: () => this.superProviderStatus
      }
    )

    Object.defineProperty(superProviderData,
      'superProviderUserDetails', {
        enumerable: true,
        get: () => this.superProviderUserDetails
      }
    )

    return {
      superProviderData
    }
  },
  data: () => ({
    isLoading: false,
    superProviderRequirements: DEFAULT_SUPER_PROVIDER_REQUIREMENTS,
    superProviderStatus: DEFAULT_SUPER_PROVIDER_STATUS
  }),
  computed: {
    ...mapGetters({
      user: 'getUser'
    }),
    component2show () {
      return this.isPrerequisitePassed ? SuperProviderStatusComponent : SuperProviderPrereq
    },
    user () {
      return this.$store.getters.getUser
    },
    superProviderUserDetails () {
      let spUserDetails = Object.create({})
      if (this.user) {
        spUserDetails = Object.assign(DEFAULT_SUPER_PROVIDER_USER_DETAILS, {
          is_super_provider: this.user?.settings?.is_super_provider ?? 0,
          is_super_provider_review_seen: this.user?.settings?.is_super_provider_review_seen ?? 0,
          is_super_provider_promo_seen: this.user?.settings?.is_super_provider_promo_seen ?? 0
        })
      }

      return spUserDetails
    },
    isPrerequisitePassed () {
      // Check if provider successful requests meets the minimum requirement
      const successfulRequestsRequirements = parseInt(this.superProviderRequirements?.successful_requests?.value)
      const providerSuccessfulRequests = parseInt(this.superProviderStatus?.successful_requests ?? 0)
      // By default we assumed that successful requests requirement is "min"
      // Which means successful requests should be >= requirement
      return providerSuccessfulRequests >= successfulRequestsRequirements
    }
  },
  async mounted () {
    try {
      this.isLoading = true
      await this.fetchSuperProviderSettings()
      await this.fetchSuperProviderStatus()
      this.isLoading = false
    } catch (err) {
      this.handleErrorMessages(err)
      this.isLoading = false
    }
  },
  methods: {
    handleErrorMessages (err = ({ message: '' })) {
      this.$notifications.toast(err?.message, 5000)
    },
    fetchSuperProviderSettings () {
      return getSuperProviderSettings()
        .then(data => {
          if (data && data.data) this.superProviderRequirements = Object.assign(DEFAULT_SUPER_PROVIDER_REQUIREMENTS, data.data)
        })
        .catch(err => this.handleErrorMessages(err))
    },
    fetchSuperProviderStatus () {
      return postSuperProviderStatusCheck()
        .then(data => {
          if (data && data.data) this.superProviderStatus = Object.assign(DEFAULT_SUPER_PROVIDER_STATUS, data.data)
        })
        .catch(err => this.handleErrorMessages(err))
    }
  }
}
</script>
