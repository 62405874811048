var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(_vm.injections.components.ForgotPasswordController, {
    tag: "component",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var data = ref.data
          var bindings = ref.bindings
          var actions = ref.actions
          return [
            _c(
              _vm.injections.components.AuthView,
              {
                tag: "component",
                attrs: { data: data },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c("h2", [
                            _vm._v(
                              _vm._s(actions.translate("forgotPassword.title1"))
                            )
                          ]),
                          _vm._v(" "),
                          _c("h2", [
                            _vm._v(
                              _vm._s(actions.translate("forgotPassword.title2"))
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _vm._v(" "),
                _c(
                  "apv-form",
                  {
                    attrs: {
                      "is-loading": data.isLoading,
                      novalidate: "",
                      "data-test": "forgot-password-form"
                    },
                    on: { submit: bindings.handleForgotPassword }
                  },
                  [
                    _c("apv-input", {
                      staticClass: "APV-onbo-redesign margin-bottom-lg",
                      attrs: {
                        value: data.email,
                        "data-test": "forgotPassword-input-email",
                        type: "email",
                        tabindex: "1",
                        label: actions.translate("forgotPassword.email"),
                        error: actions.getFieldError("email")
                      },
                      on: { input: bindings.handleEmailChange }
                    }),
                    _vm._v(" "),
                    _c(
                      "apv-button",
                      {
                        attrs: {
                          block: "",
                          gradient: "",
                          type: "submit",
                          size: "large",
                          "waves-effect": "dark",
                          "data-test": "login-cta",
                          disabled: bindings.isEmpty(data.email),
                          rounded: 10,
                          "is-loading": data.isLoading
                        }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              actions.translate("messages.globals.continue")
                            ) +
                            "\n      "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "footer-auth-nav" }, [
                  _c(
                    "div",
                    { staticClass: "login-container" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "login-link",
                          attrs: { to: data.loginRoute }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              actions.translate("register.link2loginText2")
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "register-container" },
                    [
                      _c("span", { staticClass: "login-label" }, [
                        _vm._v(
                          _vm._s(actions.translate("register.register4free"))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "register-link",
                          attrs: { to: data.registerRoute }
                        },
                        [_vm._v(_vm._s(actions.translate("login.signUp")))]
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }