<template>
  <div id="affiliate-landing-page__q-and-a">
    <h2 v-text="$t('faq.title')" />
    <ul id="q-and-a">
      <li v-for="(item, key) in $t('faq.list')" :key="key" :class="{ 'active': isActive(key) }">
        <a href="#" @click.prevent="toggleAccordion(key)">
          <i class="material-icons" v-text="isArrowUpOrDown(key)" />
          <span v-text="$t(`faq.list[${key}].title`, {
            amount: getRewardByCountryCode
          })" />
        </a>
        <div class="answer">
          <!-- trusted source (translations) -->
          <span v-html="$t(`faq.list[${key}].content`, getArgumentsForFAQContent)" />
          <router-link v-if="isLastItem(key)" :to="{ name: 'support-v2' }">Support</router-link>
        </div>
      </li>
    </ul>
  </div>
</template>

<i18n src="@/features/affiliate/translations/landing-page.json"></i18n>

<script>
import faqMixin from '@/features/affiliate/mixins/faq.mixin'

export default {
  mixins: [faqMixin],
  data: () => ({
    active: 0
  }),
  methods: {
    toggleAccordion (key) {
      this.active = parseInt(key)
    }
  }
}
</script>

<style lang="scss" scoped>
#affiliate-landing-page__q-and-a {
  padding: 40px 24px 8px;
  background-color: #ffffff;

  h2 {
    margin: 0 0 22px;
    font-family: 'Proxima Nova Semibold';
    font-size: 26px;
    text-align: center;
    color: #2F3740;
  }

  ul#q-and-a {
    margin: 0;
    text-align: left;

    li {
      margin-bottom: 8px;
      border-bottom: 1px solid #CAD1D9;

      & > a {
        display: flex;
        align-items: flex-start;
        margin-bottom: 4px;

        span {
          font-family: 'Proxima Nova Semibold';
          font-size: 18px;
          color: #2F3740;
        }
      }
      div.answer {
        display: none;
        margin: 0 0 8px;
        font-size: 16px;
        color: #737B85;

        ::v-deep .highlighted {
          font-weight: 600;
          color: #FF0000;
        }
      }
    }
    li.active {
      div.answer {
        display: block;

        ::v-deep .highlighted {
          font-weight: 600;
          color: #FF0000;
        }
      }
    }
  }
}
</style>
