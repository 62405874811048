import { render, staticRenderFns } from "./ClientRating.vue?vue&type=template&id=05ca7876&scoped=true&"
import script from "./ClientRating.vue?vue&type=script&lang=js&"
export * from "./ClientRating.vue?vue&type=script&lang=js&"
import style0 from "./ClientRating.vue?vue&type=style&index=0&id=05ca7876&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05ca7876",
  null
  
)

/* custom blocks */
import block0 from "@/features/super-provider/translations/super-provider-card.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fforge%2Fama-provider8-stage.noznom.tech%2Fsrc%2Fjs%2Ffeatures%2Fsuper-provider%2Fcomponents%2Frequirements%2FClientRating.vue"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider8-stage.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('05ca7876')) {
      api.createRecord('05ca7876', component.options)
    } else {
      api.reload('05ca7876', component.options)
    }
    module.hot.accept("./ClientRating.vue?vue&type=template&id=05ca7876&scoped=true&", function () {
      api.rerender('05ca7876', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/features/super-provider/components/requirements/ClientRating.vue"
export default component.exports