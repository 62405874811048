<template>
  <div>
    <div v-if="photoTrigger === PHOTO_TRIGGER_VALUES.PRIMARY_PHOTO" class="upload-container">
      <a href="#" class="primary-upload" @click.prevent="showPhotoTipsForFirstTimeUser">
        <div class="primary-upload-content">
          <img src="/img/registration/photos/img-placeholder-new.svg" alt="image placeholder">
          <div><strong>{{ $translate('photos.add_photo') }}</strong></div>
        </div>
      </a>
    </div>
    <button
      v-if="photoTrigger === PHOTO_TRIGGER_VALUES.PHOTO_TIPS_CTA"
      class="btn btn-black btn-photo-tip-upload"
      data-test="upload-cta-navigator"
      @click.prevent="showFilePicker"
    >
      <img src="/img/registration/tips/photo-tips/cta-upload-photo-icon.svg" alt="">
      <span>{{ $translate('photos.add_photo2') }}</span>
    </button>

    <vform
      ref="uploadMainForm"
      @submit="uploadMainPhoto"
    >
      <input
        ref="uploadMainFormFile"
        type="file"
        accept="image/*"
        class="hide"
        @change="submitMainForm"
      >
    </vform>
  </div>
</template>

<script>
import { PHOTO_TRIGGER_VALUES } from '@/features/registration/constants.js'
const PhotosProvider = $h.getProvider('PhotosProvider')

export default {
  props: {
    isRegisterMode: {
      type: Boolean,
      default: false
    },
    photoTrigger: {
      type: String,
      default: PHOTO_TRIGGER_VALUES.PRIMARY_PHOTO
    }
  },
  data: () => ({
    PHOTO_TRIGGER_VALUES,
    hasSeenPrimaryPhotoTips: true
  }),
  mounted () {
    this.detectIfUserHasSeenPhotoTips()
  },
  methods: {
    detectIfUserHasSeenPhotoTips () {
      // Check if user has seen the tips
      window.localforage.getItem('hasSeenPrimaryPhotoTips', (err, hasSeenPrimaryPhotoTips) => {
        if (!err) {
          if (!hasSeenPrimaryPhotoTips) {
            this.hasSeenPrimaryPhotoTips = false
          }
        }
      })
    },
    showPhotoTipsForFirstTimeUser () {
      // Perform certain actions if user has seen photo tips
      if (this.hasSeenPrimaryPhotoTips) {
        this.showFilePicker()
      } else {
        window.localforage.setItem('hasSeenPrimaryPhotoTips', true)
        this.$router.push({
          name: 'register-photo-tips',
          params: {
            inSession: true,
            isOtherPhotos: false,
            return: this.$route.path
          }
        })
      }
    },
    showFilePicker () {
      this.$refs.uploadMainFormFile.click()
    },
    submitMainForm () {
      this.$refs.uploadMainForm.submit()
    },
    uploadMainPhoto (next) {
      var file = this.$refs.uploadMainFormFile.files[0]
      PhotosProvider.uploadMainPhoto(file, this.isRegisterMode)
        .then((res) => {
          const otherPhotosRoute = {
            name: 'register-photos',
            params: { type: 'others' }
          }

          this.$router.push({
            name: this.isRegisterMode ? 'register-photos-crop' : 'photos-anonimize',
            params: {
              photo: res.data,
              return: this.isRegisterMode
                ? otherPhotosRoute
                : { name: 'photos' },
              cancel: this.isRegisterMode
                ? { name: 'register-photos', params: { type: 'primary' } }
                : { name: 'photos' }
            }
          })
        })
        .catch((err) => {
          if (_.isEmpty(err.data)) {
            $h.photoErrorModal(err.message)
          } else {
            $h.photoErrorModal(err.data)
          }
        })
        .finally(() => {
          if (this.$refs.uploadMainFormFile) {
            this.$refs.uploadMainFormFile.value = ''
          }
          next()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-photo-tip-upload {
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 16px;
  font-family: 'Proxima Nova Semibold';

  & > span {
    padding-left: 8px;
  }
}
</style>
