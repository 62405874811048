var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "account-setting" } },
    [
      _c("bottomsheet", {
        attrs: { "is-shown": _vm.isShown },
        on: { close: _vm.reset },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("h2", { staticClass: "bottom-sheet__title" }, [
                  _vm._v(_vm._s(_vm.$translate("setting.email_setting_title")))
                ]),
                _vm._v(" "),
                _c(
                  "vform",
                  {
                    ref: "form",
                    staticStyle: { "margin-bottom": "24px" },
                    on: { submit: _vm.submitForm }
                  },
                  [
                    _c(
                      "md-input",
                      {
                        staticClass: "setting-input",
                        class: { "with-error": _vm.errors.has("email") },
                        attrs: {
                          type: "email",
                          label: _vm.$translate("setting.new_email_label"),
                          required: ""
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      },
                      [
                        _c("div", { staticClass: "text-error" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.first("email")) +
                              "\n          "
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    staticClass:
                      "bottom-sheet__cta bottom-sheet__cta--confirm full-width",
                    attrs: {
                      disabled: _vm.email === "",
                      "data-test": "footer-cta-confirm"
                    },
                    on: { click: _vm.submit }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$translate("app.save")) +
                        "\n      "
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }