<template>
    <div id="reactivation-success-modal" class="modal left-align" style="max-height: 80%;">
        <div class="modal-content" style="padding: 32px 12px 2px;">
            <h4 class="modal-title center" style="font-size: 20px;">
              {{ $translate('events.reactivated_event_popup.title') }}
            </h4>

            <div class="center">
              <img class="responsive-img"
                    src="/img/icons/ic-check-circle.svg"
                    alt="review-pending"
                    width="59"
              />
            </div>

            <p class="center grey-text text-darken-1 center" style="font-size: 14px;">
              {{
                $translate('events.reactivated_event_popup.explanation', {
                  client_name: clientName,
                })
              }}
            </p>
        </div>

        <div class="modal-footer" style="border-top: 1px solid #E1E6EB;">
            <a href="#" class="btn-flat waves-effect waves-dark krypton-pink-text" @click="confirm">
                <strong>
                  {{ this.$translate('events.buttons.ok_understand') }}
                </strong>
            </a>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    clientName: {
      type: String,
      required: true
    },
  },
  methods: {
    confirm() {
        this.$emit('confirm');
    }
  },
  computed: {
    isToday() {
      const today = moment();
      const isSame = this.evt._meeting_start_at.format('YYYY-MM-DD') === today.format('YYYY-MM-DD');

      return isSame;
    },
  },
}
</script>

<style scoped>
.alarm-icon .material-icons {
    font-size: 72px;
    color: #BFB38F;
}
.modal-content {
    padding-bottom: 0;
}
.modal-footer {
    text-align: center;
}
</style>
