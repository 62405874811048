<template>
  <div id="photos-gallery">
    <div v-if="(hasPrimaryPhoto && !isFetchingLivePhotos) || isFetchingLivePhotos">
      <h2 class="photos-gallery__title">{{ $translate('photos.v2.tabs.live_photos') }}</h2>
      <div class="content">
        <div id="live-photos">
          <div class="lesson-shortcut--container">
            <lesson-shortcut v-model="LESSONS.PHOTOS" :translation-key="'photos'" />
          </div>
          <live-photos />
        </div>
      </div>
    </div>
    <div v-else>
      <add-primary-photo />
    </div>
  </div>
</template>

<script>
import LivePhotos from './tabs/live-photos'
import AddPrimaryPhoto from './slots/add-primary-photo'
import LessonShortcut from '@/features/onboarding-lesson/components/LessonShortcut.vue'
import { LESSONS } from '@/features/onboarding-lesson/constants'

export default {
  components: { LivePhotos, AddPrimaryPhoto, LessonShortcut },
  data () {
    return {
      LESSONS
    }
  },
  computed: {
    photos () {
      return this.$store.getters.getLivePhotos
    },
    hasPrimaryPhoto () {
      return this.photos.findIndex(p => p.primary === 1) !== -1
    },
    isFetchingLivePhotos () {
      return this.$store.getters.isFetchingLivePhotos
    }
  },
  mounted () {
    $(document).ready(() => {
      $('.tabs').tabs()
    })
  }
}
</script>

<style lang="scss" scoped>
.lesson-shortcut--container {
  padding: 0 30px;
}
</style>
