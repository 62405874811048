var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide" } }, [
    _c(
      "div",
      { attrs: { id: "event-cancelled" } },
      [
        _c("md-navbar", {
          attrs: {
            push: { name: "events" },
            brand: _vm.$translate("events.nav_title"),
            icon: "arrow_back"
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "container", attrs: { id: "content" } }, [
          _vm.currentStep === _vm.PAGE_STEPS.STEP_1
            ? _c(
                "div",
                { staticClass: "step-1" },
                [
                  _c("h5", { staticClass: "margin-top-none" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$translate("events.event_cancelled.heading_1")
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "subheading",
                      attrs: { id: "event-cancelled-client-name" }
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$translate(
                                "events.event_cancelled.subheading_1",
                                { client_name: _vm.clientName }
                              )
                            ) +
                            "\n          "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("event-card", {
                    attrs: {
                      idx: -1,
                      payload: _vm.selectedEvent,
                      "is-clickable": false,
                      "show-countdown": false
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "margin-bottom-lg margin-top-lg",
                      attrs: { id: "event-cancelled-amount-payable" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$translate(
                              "events.event_cancelled.cancellation_note",
                              {
                                amount_payable: _vm.amountPayable
                              }
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "navigator row margin-top-lg" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-large btn-secondary waves-effect waves-dark",
                        attrs: { id: "event-cancelled-abort-step-1" },
                        on: { click: _vm.doAbort }
                      },
                      [
                        !_vm.isAborting
                          ? _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$translate(
                                      "events.event_cancelled.cta_button.revoke"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ])
                          : _c(
                              "div",
                              { staticClass: "preloader-wrapper small active" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "spinner-layer spinner-green-only"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "circle-clipper left" },
                                      [_c("div", { staticClass: "circle" })]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "gap-patch" }, [
                                      _c("div", { staticClass: "circle" })
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "circle-clipper right" },
                                      [_c("div", { staticClass: "circle" })]
                                    )
                                  ]
                                )
                              ]
                            )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-large btn-krypton-pink waves-effect waves-light",
                        on: { click: _vm.nextStep }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$translate(
                                "events.event_cancelled.cta_button.continue"
                              )
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "small",
                    {
                      staticClass: "footer-note",
                      attrs: { id: "event-cancelled-cancellation-countdown" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$translate(
                              "events.event_cancelled.footer_note",
                              { time_remaining: _vm.cancellationCountdown }
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentStep === _vm.PAGE_STEPS.STEP_2
            ? _c(
                "div",
                { staticClass: "step-2" },
                [
                  _c("h5", { staticClass: "margin-top-none" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$translate("events.event_cancelled.heading_2")
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$translate(
                            "events.event_cancelled.subheading_2",
                            { client_name: _vm.clientName }
                          )
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.error
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align"
                        },
                        [_c("small", [_vm._v(_vm._s(_vm.error))])]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "md-textarea",
                    {
                      attrs: {
                        type: "multiline",
                        maxlength: _vm.answerMaxChar,
                        "force-active": true,
                        label: _vm.$translate(
                          "events.event_cancelled.form.textarea_placeholder"
                        )
                      },
                      model: {
                        value: _vm.message,
                        callback: function($$v) {
                          _vm.message = $$v
                        },
                        expression: "message"
                      }
                    },
                    [
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "input-helper grey-text text-darken-1 right"
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.getMaxChar(_vm.message)) +
                                "\n            "
                            )
                          ]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row margin-top-md" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-large btn-full btn-secondary waves-effect waves-dark margin-bottom-lg",
                        attrs: { id: "event-cancelled-abort-step-2" },
                        on: { click: _vm.doAbort }
                      },
                      [
                        !_vm.isAborting
                          ? _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$translate(
                                      "events.event_cancelled.cta_button.revoke_cancellation"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ])
                          : _c(
                              "div",
                              { staticClass: "preloader-wrapper small active" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "spinner-layer spinner-green-only"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "circle-clipper left" },
                                      [_c("div", { staticClass: "circle" })]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "gap-patch" }, [
                                      _c("div", { staticClass: "circle" })
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "circle-clipper right" },
                                      [_c("div", { staticClass: "circle" })]
                                    )
                                  ]
                                )
                              ]
                            )
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-large btn-full btn-krypton-pink waves-effect waves-light",
                        attrs: { id: "event-cancelled-confirm-step-2" },
                        on: { click: _vm.doConfirmCancellation }
                      },
                      [
                        !_vm.isConfirming
                          ? _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$translate(
                                      "events.event_cancelled.cta_button.send_cancellation"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ])
                          : _c(
                              "div",
                              { staticClass: "preloader-wrapper small active" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "spinner-layer spinner-green-only"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "circle-clipper left" },
                                      [_c("div", { staticClass: "circle" })]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "gap-patch" }, [
                                      _c("div", { staticClass: "circle" })
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "circle-clipper right" },
                                      [_c("div", { staticClass: "circle" })]
                                    )
                                  ]
                                )
                              ]
                            )
                      ]
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }