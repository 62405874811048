var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "lesson-screen",
      attrs: { id: "lesson-screen-thumbs-down" }
    },
    [
      _c("div", { staticClass: "wrapper" }, [
        _c(
          "div",
          { staticClass: "content__drawer content__drawer-filled" },
          [
            _c(
              "div",
              { staticClass: "content content__tip lesson-screen--container" },
              [
                _c("div", { staticClass: "lesson-screen--content" }, [
                  _c("div", { staticClass: "lesson-screen--story" }, [
                    _c("div", [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("h2", [
                        _vm._v(_vm._s(_vm.$t(_vm.openedLessonName + ".title")))
                      ])
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c("navigator", {
              attrs: {
                current: _vm.currentPage,
                "no-prev": "",
                next: _vm.$t("messages.globals.continue"),
                "next-class": "action-btn__black",
                total: _vm.totalPages,
                "step-class": "--purple"
              },
              on: {
                next: function($event) {
                  $event.preventDefault()
                  return _vm.gotoNextLessonScreen($event)
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-container" }, [
      _c("i", { staticClass: "material-icons" }, [_vm._v("thumb_down")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }