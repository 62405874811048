<template>
  <div
    class="availability-card no-select"
    :class="{ 'is-card-active': isToggled }"
  >
    <div
      v-if="isMonday && !isFirstDayMonth && $props.pkey !== 0"
      class="col s12"
    >
      <br>
    </div>
    <div class="col s12">
      <div
        class="card-panel no-select"
        :class="{
          'inactive': !isToggled,
          'active': isToggled,
          'today': isToday
        }"
      >
        <div
          id="availability-card-no-select-toggle"
          class="card-panel--header no-select"
          :class="{ 'today': isToday, 'monday': isMonday }"
          @click="toggle"
        >
          <div
            :id="availabilityID"
            class="availability-link"
          />
          <div
            class="availabilities--date"
            :class="{ 'black-text': isMonday || isToday }"
          >
            <pre>{{ date }}</pre>
          </div>
          <div
            v-if="formData.id && (!formData.not_available || shouldConfirm) || isToggled"
            class="availabilities--summary"
          >
            <div class="meeting-indicators">
              <div
                v-for="(status, idx) in meetings"
                :key="idx"
                class="indicator"
                :class="{ 'active': status }"
              />
            </div>
            <div class="time">
              <span v-if="isAvailableAndNotLateNight">{{ formData.availability_start }} - {{ formData.availability_end }}</span>
              <span v-if="notAvailableOrIsLateNight">-:- - -:-</span>
            </div>
            <div class="spacer" />
          </div>
          <div
            v-if="formData.id && !isToggled && (formData.not_available && !shouldConfirm)"
            class="availabilities--summary"
            :class="{ 'not-available': formData.not_available && !shouldConfirm }"
          >
            <div class="meeting-indicators">
              <div
                v-for="(status, idx) in meetings"
                :key="idx"
                class="indicator"
                :class="{ 'active': status }"
              />
            </div>
            <div class="time">
              <span>{{ $translate('availability.not_available') }}</span>
            </div>
            <div class="spacer" />
          </div>
          <div
            v-if="!formData.id && !isToggled"
            class="availabilities--summary"
          >
            <p>{{ $translate('availability.set_now') }}</p>
          </div>
          <div
            class="availability-status"
            :class="{
              'unavailable': notAvailableOrIsLateNight,
              'unfilled': !formData.id,
              'filled': formData.id,
            }"
          />

        </div>
        <div class="card-panel--body">
          <div
            ref="rangeinput"
            class="availability-schedule--input"
          />

          <div
            class="availability-schedule--indicator"
            :class="{
              'disabled': notAvailableOrIsLateNight,
            }"
          >
            <div
              v-for="(status, idx) in schedule"
              :key="idx"
              class="indicator"
              :class="{
                'half-available-meeting-2': status === MEETING_INDICATOR_STATUS.HALF_MEETING_AVAILABLE_2,
                'half-available-meeting-1': status === MEETING_INDICATOR_STATUS.HALF_MEETING_AVAILABLE_1,
                'half-available-2': status === MEETING_INDICATOR_STATUS.HALF_AVAILABLE_2,
                'half-available-1': status === MEETING_INDICATOR_STATUS.HALF_AVAILABLE_1,
                'half-meeting-1': status === MEETING_INDICATOR_STATUS.HALF_MEETING_1,
                'half-meeting-2': status === MEETING_INDICATOR_STATUS.HALF_MEETING_2,
                'unavailable': status === MEETING_INDICATOR_STATUS.UNAVAILABLE,
                'available': status === MEETING_INDICATOR_STATUS.AVAILABLE,
                'meeting': status === MEETING_INDICATOR_STATUS.MEETING,
                'prime-time': getIndicatorPrimeTimeStartIndex(idx)
              }"
            />
          </div>

          <!-- Controls/Save Indicator -->
          <div class="input-group">
            <div class="left-btn">
              <div
                class="btn-click waves-effect waves-dark"
                :class="{ 'disabled': meetings.indexOf(true) >= 0 }"
                @click="confirm"
              >
                <span
                  v-if="!formData.not_available || shouldConfirm"
                  id="avail-away-btn"
                >
                  <i class="material-icons">block</i> <small>{{ $translate('availability.away_btn') }}</small>
                </span>
                <span
                  v-if="formData.not_available && !shouldConfirm"
                  id="avail-make-btn"
                  class="pink-text make-available"
                  :class="{ 'disabled': isTimeWithinLateNight }"
                >
                  <i class="material-icons">refresh</i> <small>{{ $translate('availability.make_available_btn') }}</small>
                </span>
              </div>
            </div>
            <div class="right-btn">
              <button
                v-if="(!formData.id || shouldConfirm) && !isSaving"
                id="save-avail-btn"
                class="confirm-btn btn waves-effect waves-light"
                type="button"
                :disabled="isTimeWithinLateNight"
                @click="save"
              >
                {{ $translate('availability.confirm_btn') }}
              </button>
              <div
                v-if="(formData.id && !shouldConfirm) || isSaving"
                class="save-indicator green-text"
              >
                <div
                  v-if="isSaving"
                  class="saving"
                >
                  <div
                    class="preloader-wrapper"
                    small
                    active
                  >
                    <div class="spinner-layer spinner-green-only">
                      <div class="circle-clipper left">
                        <div class="circle" />
                      </div>
                      <div class="gap-patch">
                        <div class="circle" />
                      </div>
                      <div class="circle-clipper right">
                        <div class="circle" />
                      </div>
                    </div>
                  </div>
                  <strong>{{ $translate('availability.saving') }}</strong>
                </div>
                <div
                  v-if="!isSaving"
                  class="saved"
                >
                  <i class="material-icons">check</i> <strong>{{ $translate('availability.saved') }}</strong>
                </div>
              </div>
            </div>
          </div>

          <!-- Explanation Text -->
          <div
            v-if="!isInPrimeTime && !isTimeWithinLateNight"
            class="explanation-text"
          >
            <i class="material-icons">flash_on</i>
            <p>{{ $translate('availability.prime_time_explanation') }}</p>
          </div>

          <div
            v-if="isTimeWithinLateNight"
            class="explanation-text"
          >
            <p>{{ $translate('availability.late_night') }}</p>
          </div>
        </div>
        <!-- </transition> -->
      </div>
    </div>
  </div>
</template>

<script>
import AvailabilityCardMixin from './mixins/availability-card-mixin'

export default {
  mixins: [AvailabilityCardMixin]
}
</script>

<style lang="scss" scoped>
#avail-make-btn.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
