var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "referral" }, [
    _c(
      "div",
      { staticClass: "referral__container" },
      [
        _c("section", [
          _c("img", {
            staticClass: "referral__icon",
            attrs: {
              width: "48",
              height: "48",
              src: "/img/icons/k-logo-new.svg",
              alt: "krypton icon"
            }
          }),
          _vm._v(" "),
          _c("h1", { staticClass: "referral__title" }, [
            _vm._v(_vm._s(_vm.$t("referral.title")))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "referral__text" }, [
            _vm._v(_vm._s(_vm.$t("referral.subtitle_1")))
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "referral__cta",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goToRegistration($event)
                }
              }
            },
            [
              _vm._v("\n        " + _vm._s(_vm.$t("referral.cta")) + " "),
              _c("span", { staticStyle: { "margin-left": "12px" } }, [
                _vm._v("➜")
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "referral__info" }, [
          _c("section", [
            _c("h2", { staticClass: "referral__info-title" }, [
              _vm._v(_vm._s(_vm.$t("referral.info.title")))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "referral__info-subtitle" }, [
              _vm._v(_vm._s(_vm.$t("referral.info.subtitle")))
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "referral__info-list" }, [
              _c("li", [
                _c("p", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("referral.info.item_1_title")))
                  ])
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.$t("referral.info.item_1_text")))])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("p", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("referral.info.item_2_title")))
                  ])
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.$t("referral.info.item_2_text")))])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("p", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("referral.info.item_3_title")))
                  ])
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.$t("referral.info.item_3_text")))])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "referral__link" }, [
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.goToRegistration($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("referral.cta")) +
                      "\n          "
                  )
                ]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "margin-left": "15px",
                    "text-decoration": "none"
                  }
                },
                [_vm._v("➜")]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("LanguageSwitcherAuthView")
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("picture", [
      _c("source", {
        attrs: {
          media: "(min-width: 600px)",
          srcset: "/img/affiliate/referral-landing-page.png"
        }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "referral__banner",
        attrs: {
          src: "/img/affiliate/referral-landing-page-mobile.png",
          alt: "referral banner"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }