// type Availability = {
//   _city_name: string;
//   _requests_end: string;
//   _requests_start: string;
//   accept_overnight: boolean;
//   availability_end: string;
//   availability_start: string;
//   city_id: number;
//   date: string;
//   id: number | null;
//   not_available: number;
//   out_of_town: number;
//   requests: any;
// };

// type AvailabilityPayload = {
//   id: number;
//   date: string;
//   availability_start: any;
//   availability_end: any;
//   city_id: number;
//   not_available: number;
//   accept_overnight: number;
//   out_of_town: number;
//   requests: [];
//   _city_name: null;
//   _requests_start: null;
//   _requests_end: null;
//   availability_start_full: null;
//   availability_end_full: null;
// };

const isOutOfTownAndNoCityID = (out_of_town, city_id) => {
  return out_of_town == 1 && city_id === null;
};

const isChangingSelectedAvailability = (newDate, oldDate, isToggled) => {
  return newDate !== oldDate && isToggled;
}

const isClosingSelectedAvailability = (parentIdx, localIdx) => {
  return parentIdx === localIdx;
}

const isAwaySelectedAvailability = (not_available) => {
  return not_available == 1;
}

const verifySave = (availability, shouldConfirm) => {
  let isCityError = isOutOfTownAndNoCityID(availability.out_of_town, availability.city_id);
  let props = {
    ...availability,
    shouldConfirm,
    isCityError,
    isSaving: false
  };

  if (shouldConfirm) {
      props.not_available = props.not_available === 0 ? 1 : 0;
      props.shouldConfirm = false;

      // @todo separate implementation
      // setTimeout(() => {
      //     this.$refs.rangeinput.removeAttribute('disabled');
      // }, 100);

      props.isSaving = false;
  }

  return props;

}
