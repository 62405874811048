import Store from '../stores/index.js'
import LocalizationService from '@/services/LocalizationService.js'
import RegisterSteps from '@/constants/registration/steps'

export default function redirectNotAuthenticated (to, from, next) {
  var accessDenied = (next) => {
    window.NotificationPlugin.toast('You are not allowed to access the module.', 5000)
    next({ name: 'dashboard' })
  }
  const getUTMSource = Object.fromEntries(new URLSearchParams(window.location.search).entries())?.utm_source ?? null

  const redirectByNavigatorLanguage = () => {
    // If language detected contains German related languages
    const languages = ['de', 'de-AT', 'de-BE', 'de-CH', 'de-DE', 'de-IT', 'de-LI', 'de-LU']
    const navigatorLanguage = navigator?.language || 'en'

    let loginRoute = { name: 'login' }

    if (!languages.includes(navigatorLanguage)) {
      loginRoute = { ...loginRoute, params: { lang: 'en' } }
    }

    if (getUTMSource === 'homescreen') {
      loginRoute = { ...loginRoute, query: { utm_source: getUTMSource } }
    }

    next(loginRoute)
  }

  const onTokenChecked = async () => {
    if (window.api.authorization === null || window.api.authorization === undefined) {
      redirectByNavigatorLanguage()
    } else {
      const appLocale = await LocalizationService.getSavedLocale()

      if (Store.state.user.app_lang !== null) {
        LocalizationService.setLocale(Store.state.user.app_lang.toLowerCase())
      } else if (appLocale !== null) {
        LocalizationService.setLocale(appLocale)
      } else {
        LocalizationService.setLocale(LocalizationService.defaultLocale())
      }

      if (Store.state.user.register_progress === 0) {
        // Route guard check on specific modules
        switch (to.name) {
          case 'events':
            if (Store.state.user.events_enabled === 1) {
              next()
            } else {
              accessDenied(next)
            }
            break
          case 'availability':
            if (Store.state.user.availability_enabled === 1) {
              next()
            } else {
              accessDenied(next)
            }
            break
          default:
            next()
        }

        // Check if user has agreed to terms and conditions
        // agree_terms === 2 - If enabled
        if (Store.state.user.agree_terms !== AGREEMENT_TERM.ENABLED) {
          next()
        } else {
          if (to.name !== 'legal-terms-conditions') {
            next({ name: 'legal-terms-conditions' })
          } else {
            next()
          }
        }
      } else {
        // Redirect to registration page where the user left off
        if (to.name === RegisterSteps[Store.state.user.register_progress]?.name) {
          next()
        } else {
          next(RegisterSteps[Store.state.user.register_progress])
        }
      }
    }
  }

  if (window.api.headers.hasOwnProperty('Authorization')) {
    onTokenChecked()
  }

  document.addEventListener('onTokenChecked', onTokenChecked)
}
