var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal left-align",
      staticStyle: { "max-height": "80%" },
      attrs: { id: "reactivation-success-modal" }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-content",
          staticStyle: { padding: "32px 12px 2px" }
        },
        [
          _c(
            "h4",
            {
              staticClass: "modal-title center",
              staticStyle: { "font-size": "20px" }
            },
            [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$translate("events.reactivated_event_popup.title")
                  ) +
                  "\n        "
              )
            ]
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "center grey-text text-darken-1 center",
              staticStyle: { "font-size": "14px" }
            },
            [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$translate(
                      "events.reactivated_event_popup.explanation",
                      {
                        client_name: _vm.clientName
                      }
                    )
                  ) +
                  "\n        "
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-footer",
          staticStyle: { "border-top": "1px solid #E1E6EB" }
        },
        [
          _c(
            "a",
            {
              staticClass: "btn-flat waves-effect waves-dark krypton-pink-text",
              attrs: { href: "#" },
              on: { click: _vm.confirm }
            },
            [
              _c("strong", [
                _vm._v(
                  "\n              " +
                    _vm._s(this.$translate("events.buttons.ok_understand")) +
                    "\n            "
                )
              ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "center" }, [
      _c("img", {
        staticClass: "responsive-img",
        attrs: {
          src: "/img/icons/ic-check-circle.svg",
          alt: "review-pending",
          width: "59"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }