<template>
  <div>
    <div id="agreement" class="wrapper">
      <div class="content__area">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="girl with heart graphics"></img>
        <h2 class="content__title content__title--desktop">{{ $translate('register.agreement.title') }}</h2>
      </div>
      <div class="content__area">
        <div class="content">
          <h2 class="content__title content__title--mobile">{{ $translate('register.agreement.title') }}</h2>
          <div class="center-align content__image">
            <img src="/img/registration/agreement-avatar.svg" alt="girl with heart graphics"></img>
          </div>
          <p>
            <span>{{ $translate('register.agreement.p_1_1') }}</span>
            <a href="#" @click.prevent="$router.push({ name: 'legal-philosophy' })">
              {{ $translate('register.agreement.a_1_1') }}
            </a>
            <span>{{ $translate('register.agreement.p_1_2') }}</span>
            <a href="#" @click.prevent="$router.push({ name: 'legal-privacy' })">
              {{ $translate('register.agreement.a_1_2') }}
            </a>
          </p>
          <p>{{ $translate('register.agreement.p_2_1') }}</p>
          <div v-if="monthOpts.length && yearOpts.length" class="flex-grid space-between">
            <div class="grid-item f-sm-6">
              <div>
                <md-select
                  v-model="payload.month"
                  desktop-display-mode="split"
                  :force-mobile="true"
                  class="textfield-box-lg"
                  :label="$translate('register.agreement.month')"
                  :half-width="true"
                  :placeholder="$translate('register.agreement.birth_month')"
                  :default="$translate('register.agreement.select_month')"
                ><option
                  v-for="(month, i) in monthOpts"
                  :key="`month-option-${i}`"
                  slot="options"
                  :value="month.key"
                  :disabled="month.disabled"
                >{{ month.content }}</option>
                </md-select>
              </div>
            </div>
            <div class="grid-item f-sm-6">
              <md-select
                v-model="payload.year"
                desktop-display-mode="split"
                :force-mobile="true"
                class="textfield-box-lg"
                :label="$translate('register.agreement.year')"
                :half-width="true"
                :placeholder="$translate('register.agreement.birth_year')"
                :default="$translate('register.agreement.select_year')"
              ><option
                v-for="(year, i) in yearOpts"
                :key="`year-option-${i}`"
                slot="options"
                :value="year.key"
                :disabled="year.disabled"
              >{{ year.content }}</option>
              </md-select>
            </div>
          </div>
        </div>
        <div class="actions flex-grid space-between">
          <button
            class="btn btn-secondary"
            @click.prevent="declineTerms"
          >{{ $translate('register.agreement.decline') }}</button>
          <button
            :disabled="isCTADisabled"
            class="btn btn-primary"
            :class="{ 'disabled': isCTADisabled }"
            data-test="agree-cta"
            @click.prevent="submit"
          >{{ $translate('register.agreement.accept') }}</button>
        </div>
      </div>
    </div>

    <decline-popup :value="showPopup" @change="(val) => showPopup = val" />
  </div>
</template>

<script>
import DeclinePopup from './decline-popup.vue'
const LocaleProvider = $h.getProvider('LocaleProvider')
const RegisterProvider = $h.getProvider('RegisterProvider')

export default {
  components: {
    DeclinePopup
  },
  data () {
    return {
      showPopup: false,
      monthOpts: [],
      yearOpts: [],
      payload: {
        month: null,
        year: null
      }
    }
  },
  computed: {
    getMaxYearMonth () {
      const date = new Date()
      const eighteenYearsFromNow = date.getFullYear() - 18
      let currentMonth = date.getMonth() + 1 // since getMonth method is index based
      // Format the month to always be 2 digits
      currentMonth = currentMonth.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })

      return `${eighteenYearsFromNow}-${currentMonth}`
    },
    isCTADisabled () {
      return this.isEmpty(this.payload.year) || this.isEmpty(this.payload.month)
    },
    birthday () {
      return this.$store.getters.getBirthday
    }
  },
  watch: {
    'payload.month': function (newval, oldval) {
      this.disableInvalidYears()
      this.$store.commit('setBirthMonth', newval)
    },
    'payload.year': function (newval, oldval) {
      this.disableInvalidMonths()
      this.$store.commit('setBirthYear', newval)
    },
    birthday: function (newval, oldval) {
      this.initializePayload()
    }
  },
  mounted () {
    this.initializeMonthOpts()
    this.initializeYearOpts()
    this.initializePayload()
  },
  methods: {
    declineTerms () {
      // Show popup
      this.showPopup = true
    },
    isEmpty (value = null) {
      return value === null || value === ''
    },
    submit () {
      if (!this.payload.month || !this.payload.year) {
        this.$notifications.toast(this.$translate('register.agreement.invalid_input'), 5000)
        return
      }

      RegisterProvider.setAge(this.payload)
        .then((res) => {
          this.$store.commit('setUser', res.data)
          this.$router.push({
            name: 'register-overview',
            params: { step: 1 }
          })
        })
    },
    decline () {
      this.$notifications.toast(this.$translate('register.agreement.decline_error'), 5000)
    },
    enableAllMonths () {
      for (var i = 0; i < this.monthOpts.length; i++) this.monthOpts[i].disabled = false
    },
    enableAllYears () {
      for (var i = 0; i < this.yearOpts.length; i++) this.yearOpts[i].disabled = false
    },
    disableInvalidMonths () {
      if (this.payload.year != _.last(this.yearOpts).key) {
        this.enableAllMonths()
        return false
      }

      var maxMonth = moment().format('M')

      if (this.payload.month > maxMonth) this.payload.month = null

      for (var i = 0; i < this.monthOpts.length; i++) {
        this.monthOpts[i].disabled = this.monthOpts[i].key > maxMonth
      }
    },
    disableInvalidYears () {
      var maxMonth = moment().format('M')

      if (this.payload.month <= maxMonth) {
        this.enableAllYears()
        return false
      }

      if (this.payload.year == _.last(this.yearOpts).key) this.payload.year = null

      var lastIndex = _.findLastIndex(this.yearOpts)

      this.yearOpts[lastIndex].disabled = true
    },
    initializeMonthOpts () {
      var locale = LocaleProvider.isLocaleDe() ? 'de' : 'en'

      for (var i = 1; i <= 12; i++) {
        this.monthOpts.push({
          key: i,
          content: moment(i, 'M').locale(locale).format('MMMM'),
          disabled: false
        })
      }
    },
    initializeYearOpts () {
      var maxYear = moment().subtract(18, 'year').format('YYYY')

      for (var i = 1970; i <= maxYear; i++) {
        this.yearOpts.push({
          key: i,
          content: i,
          disabled: false
        })
      }
    },
    initializePayload () {
      if (!this.birthday) return false

      this.payload.month = this.birthday('month')
      this.payload.year = this.birthday('year')
    }
  }
}
</script>
