<template>
  <div>
    <div id="onboarding-progress-greeting">
      <div class="greeting-container">
        <div class="greeting-text">
          {{ $translate('onboarding.greeting') }}
        </div>
        <div class="greeting-name">
          {{ providerName }}
        </div>
      </div>
      <circular-progress-bar
        id="onboarding-progress-bar"
        :percentage="onBoardingProgress"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      onBoardingProgress: 'onboarding/GET_ONBOARDING_PROGRESS'
    }),
    providerName () {
      return this.user?.profiles?.name ?? ''
    },
    user () {
      return this.$store.getters.getUser
    }
  }
}
</script>

<style lang="scss" scoped>
#onboarding-progress-greeting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;

  .greeting-container {
    font-size: 18px;

    .greeting-text {
      color: #78838F;
    }
    .greeting-name {
      color: #2E353D;
      font-weight: 600;
    }
  }
}
</style>
