<template>
  <button
    data-test="affiliate-share-btn"
    class="btn display-block waves-effect"
    :disabled="loading"
    @click.prevent="share"><span>{{ $t('cta') }}</span><i
      v-if="!isNavigatorShareApiSupported"
      data-test="affiliate-copy-icon"
      class="material-icons">content_copy</i>
  </button>
</template>

<i18n src="@/features/affiliate/translations/affiliate.json"></i18n>

<script>
import { postAffiliateShareLink } from '@/features/affiliate/services/AffiliateService.js'

export default {
  data: () => ({
    loading: false
  }),
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    affiliateReferralUrl () {
      return this.$store.getters['affiliate/getAffiliateReferralUrl']
    },
    profileUrl () {
      return this.user?.profiles?._profile_url ?? null
    },
    profileName () {
      return this.user?.profiles?.name ?? null
    },
    isNavigatorShareApiSupported () {
      return navigator.canShare
    }
  },
  methods: {
    copyToClipboard (url) {
      navigator.clipboard.writeText(url)
      this.$notifications.toast('Link copied to clipboard!')
    },
    shareUsingNavigatorShareApi (shareData) {
      navigator.share(shareData)
    },
    generateAffiliateUrl () {
      this.loading = true

      return new Promise((resolve, reject) => {
        const locale = this.$i18n?.locale ?? 'de'
        postAffiliateShareLink(locale)
          .then(res => {
            this.loading = false
            resolve(res)
          })
          .catch(err => {
            this.loading = false
            reject(err)
            this.handleErrorMessages(err)
          })
      })
    },
    copyOrShare (url) {
      const shareData = {
        url,
        title: this.profileName
      }

      if (!this.isNavigatorShareApiSupported) {
        this.copyToClipboard(url)
      } else if (this.isNavigatorShareApiSupported) {
        this.shareUsingNavigatorShareApi(shareData)
      }
    },
    share () {
      if (this.affiliateReferralUrl) {
        this.copyOrShare(this.affiliateReferralUrl)
      } else {
        this.generateAffiliateUrl()
          .then(res => {
            if (res?.data) {
              this.$store.commit('affiliate/setAffiliateReferralUrl', res.data)
              this.copyOrShare(res.data)
            }
          })
      }
    },
    handleErrorMessages (err = ({ message: '' })) {
      this.$notifications.toast(err?.message, 5000)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  height: 44px;
  border-radius: 8px;
  background: transparent linear-gradient(270deg, #E61472 0%, #B30F59 100%) 0% 0% no-repeat padding-box;
  line-height: 44px;
  font-size: 16px;
  display: flex;
  justify-content: center;

  .material-icons {
    padding-left: 8px;
  }
}
</style>
