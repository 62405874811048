var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "rates" } },
    [
      [
        _c("div", { staticClass: "content__area" }, [
          _c("img", {
            attrs: {
              id: "k-logo",
              src: "/img/registration/k-logo.svg",
              alt: "k-logo"
            }
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "content__title content__title--desktop" }, [
            _vm._v(_vm._s(_vm.$translate("register.rates.title")))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content__area" }, [
          _c("div", { staticClass: "content" }, [
            _c("h2", { staticClass: "content__title content__title--mobile" }, [
              _vm._v(_vm._s(_vm.$translate("register.rates.title")))
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "tiers" },
              _vm._l(_vm.getAvailableTiers, function(availableTier, i) {
                return _c(
                  "li",
                  {
                    key: i,
                    ref: "tiers",
                    refInFor: true,
                    class: [
                      "select-tier-pill",
                      {
                        "active selected-pill":
                          _vm.selectedTier == availableTier.tier
                      }
                    ],
                    style: { order: i + 1 },
                    on: {
                      click: function() {
                        _vm.selectTier(availableTier)
                        _vm.arrangeTier(i)
                      }
                    }
                  },
                  [
                    _vm.getCurrencySymbol.length === 1
                      ? _c("strong", [
                          _vm._v(
                            _vm._s(
                              "" + _vm.getCurrencySymbol + availableTier.value
                            )
                          )
                        ])
                      : _c("strong", [
                          _vm._v(_vm._s("" + availableTier.value)),
                          _c("span", { staticClass: "smaller" }, [
                            _vm._v(_vm._s("" + _vm.getCurrencySymbol))
                          ])
                        ]),
                    _vm._v(" "),
                    _c("span", [_vm._v("2h")])
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "rates-conditions" }, [
              _c(
                "ul",
                _vm._l(_vm.checklistModels, function(checklistModel, i) {
                  return _c(
                    "li",
                    {
                      key: i,
                      staticClass:
                        "checkedbox flex-justify-left-align-center padding-bottom-24",
                      attrs: { "data-checkbox-container": checklistModel }
                    },
                    [
                      _c(
                        "div",
                        {
                          class: [
                            "checkbox-label",
                            {
                              unchecked: !_vm.getCheckboxStatus(checklistModel)
                            }
                          ]
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "material-icons",
                              on: {
                                click: function($event) {
                                  return _vm.onSelectInfo(checklistModel)
                                }
                              }
                            },
                            [_vm._v("info")]
                          ),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$translate(
                                  "register.rates.checkbox_label_" +
                                    checklistModel
                                )
                              )
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "checkbox-container" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedChecklistModels,
                              expression: "selectedChecklistModels"
                            }
                          ],
                          attrs: { id: checklistModel, type: "checkbox" },
                          domProps: {
                            value: checklistModel,
                            checked: Array.isArray(_vm.selectedChecklistModels)
                              ? _vm._i(
                                  _vm.selectedChecklistModels,
                                  checklistModel
                                ) > -1
                              : _vm.selectedChecklistModels
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.selectedChecklistModels,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = checklistModel,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.selectedChecklistModels = $$a.concat(
                                        [$$v]
                                      ))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.selectedChecklistModels = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.selectedChecklistModels = $$c
                                }
                              },
                              function($event) {
                                return _vm.onCheckboxChange(checklistModel)
                              }
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: checklistModel } })
                      ])
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    "information-container",
                    {
                      unchecked: _vm.getSelectedInfoCheckStatus === "unchecked"
                    }
                  ]
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$translate(
                          "register.rates.explanation_" +
                            _vm.selectedInfoModel +
                            "_" +
                            _vm.getSelectedInfoCheckStatus
                        )
                      ) +
                      "\n          "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "actions flex-grid space-between" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-verify",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submit($event)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$translate("register.rates.cta")))]
              )
            ])
          ])
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }