var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", {
    class: _vm.getCityPillClass,
    domProps: { textContent: _vm._s(_vm.cityName) },
    on: { click: _vm.selectCity }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }