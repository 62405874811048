var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "id-verification", attrs: { id: "verify-upload-id" } },
    [
      _c("div", { staticClass: "container", attrs: { id: "content" } }, [
        _c("h5", [_vm._v("\n      " + _vm._s(_vm.pageTitle) + "\n    ")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$translate("verify.upload.explanation_text")) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-grid-column margin-bottom-x5" },
          [
            _c("upload-slot", {
              attrs: {
                "image-src": _vm.frontID,
                "is-replaceable": _vm.isFrontIDReplaceable,
                placeholder: _vm.frontIDPlaceholder
              },
              on: {
                onclick: function($event) {
                  return _vm.onClick(_vm.SIDES.FRONT)
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "margin-y-sm" }),
            _vm._v(" "),
            _c("upload-slot", {
              attrs: {
                "image-src": _vm.backID,
                "is-replaceable": _vm.isBackIDReplaceable,
                placeholder: _vm.backIDPlaceholder,
                "is-front": false
              },
              on: {
                onclick: function($event) {
                  return _vm.onClick(_vm.SIDES.BACK)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "cta-fixed" }, [
          _c(
            "button",
            {
              staticClass:
                "action-btn action-btn__krypton action-btn__blocked waves-effect waves-light",
              attrs: { disabled: !_vm.isComplete },
              on: { click: _vm.doContinue }
            },
            [
              !_vm.isSubmitting
                ? _c("strong", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$translate("verify.upload.cta_button")) +
                        "\n        "
                    )
                  ])
                : _c("div", { staticClass: "preloader-wrapper small active" }, [
                    _vm._m(0)
                  ])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("input", {
        ref: "uploadFrontID",
        staticClass: "hide",
        attrs: { id: "front-id-input", type: "file", accept: "image/*" },
        on: {
          change: function($event) {
            return _vm.onChange(_vm.SIDES.FRONT)
          }
        }
      }),
      _vm._v(" "),
      _c("input", {
        ref: "uploadBackID",
        staticClass: "hide",
        attrs: { id: "back-id-input", type: "file", accept: "image/*" },
        on: {
          change: function($event) {
            return _vm.onChange(_vm.SIDES.BACK)
          }
        }
      })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner-layer spinner-green-only" }, [
      _c("div", { staticClass: "circle-clipper left" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gap-patch" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "circle-clipper right" }, [
        _c("div", { staticClass: "circle" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }