<template>
  <div class="feature-overview__container" :style="cssVars">
    <div class="feature-overview__banner">
      <div class="feature-overview__banner-container">
        <md-faq-navbar :route-name="headerNavbarRedirection" />
        <h1 class="feature-overview__banner-title">{{ headerTitle }}</h1>
        <p class="feature-overview__banner-subtitle">{{ headerSubtitle }}</p>
        <img
          data-test="faq-main-header-logo"
          :src="mainHeaderLogo"
        >
      </div>
    </div>
    <div v-if="hasTagline" class="feature-overview__body">
      <div class="feature-overview__tagline">
        <p
          class="feature-overview__tagline-text"
          data-test="faq-tagline-text"
          v-html="headerTagline"
        />
        <img
          v-if="taglineLogo"
          class="feature-overview__tagline-logo"
          data-test="faq-tagline-logo"
          :src="taglineLogo"
        >
      </div>

      <card-panels
        :features="features"
      />
    </div>
  </div>
</template>

<script>
import CardPanels from './CardPanels.vue'

export default {
  components: {
    CardPanels
  },
  props: {
    headerTitle: {
      type: String,
      required: true,
      default: ''
    },
    headerSubtitle: {
      type: String,
      required: true,
      default: ''
    },
    headerTagline: {
      type: String,
      required: true,
      default: ''
    },
    mainHeaderLogo: {
      type: String,
      required: true,
      default: ''
    },
    taglineLogo: {
      type: String,
      required: false,
      default: null
    },
    primaryHeaderColor: {
      type: String,
      required: false,
      default: '#F5187C'
    },
    secondaryHeaderColor: {
      type: String,
      required: false,
      default: '#C51162'
    },
    headerGradientAngle: {
      type: String,
      required: false,
      default: '244deg'
    },
    taglineGradientAngle: {
      type: String,
      required: false,
      default: '245deg'
    },
    headerImgDimensions: {
      type: Object,
      required: false,
      default: () => ({
        height: '107px',
        width: '125px'
      })
    },
    headerNavbarRedirection: {
      type: String,
      default: 'faq'
    },
    hasTagline: {
      type: Boolean,
      required: false,
      default: true
    },
    features: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  computed: {
    cssVars () {
      // Declaration of dynamic css variables
      return {
        '--primary-header-color': this.primaryHeaderColor,
        '--secondary-header-color': this.secondaryHeaderColor,
        '--header-gradient-angle': this.headerGradientAngle,
        '--tagline-gradient-angle': this.taglineGradientAngle,
        '--header-img-height': this.headerImgDimensions?.height,
        '--header-img-width': this.headerImgDimensions?.width
      }
    }
  },
  methods: {
    getCardPanelVariant (incentive) {
      return !!(incentive % 2) ? 'card-panel--left' : 'card-panel--right'
    }
  }
}
</script>

<style lang="scss" scoped>
.feature-overview__container {
  .feature-overview__banner {
    width: 100%;
    position: relative;
    background: transparent linear-gradient(var(--header-gradient-angle), var(--primary-header-color) 0%,var(--secondary-header-color) 100%) 0% 0% no-repeat padding-box;
    color: #ffffff;
    text-align: center;
    height: 336px;
    z-index: 2;

    .feature-overview__banner-container {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      top: 0;

      h1, p {
        line-height: 1;
      }

      h1.feature-overview__banner-title {
        margin: 0;
        font-family: 'Proxima Nova Semibold';
        font-size: 26px;
        padding-top: 5px;
      }

      p.feature-overview__banner-subtitle {
        font-size: 18px;
        margin-top: 12px;
        margin-bottom: 0;
        line-height: 28px;
      }

      img {
        height: var(--header-img-height);
        width: var(--header-img-width);
        margin-top: 32px;
      }
    }
  }

  .feature-overview__body {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 18px;
    color: #2F3740;
    padding: 87px 24px 32px;
    z-index: 1;

    &::before {
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background: transparent linear-gradient(var(--tagline-gradient-angle), var(--primary-header-color) 0%,var(--secondary-header-color) 100%) 0% 0% no-repeat padding-box;
      opacity: 0.1;
      z-index: 0;
    }

    @media screen and (min-width: 600px) {
      padding: 87px 32px 32px;
    }

    .feature-overview__tagline {
      padding-bottom: 32px;
    }

    p.feature-overview__tagline-text {
      margin: 0;
      line-height: 26px;
    }

    img.feature-overview__tagline-logo {
      padding-top: 24px;
    }
  }
}
</style>
