<template>
  <div id="photos">
    <template>
      <register-photos-primary
        v-if="stepper.current == 1"
        :total="stepper.total"
        :current="stepper.current"
        :stepper-label="stepperLabel"
      />
      <register-photos-others
        v-if="stepper.current == 2"
        :total="stepper.total"
        :current="stepper.current"
        :stepper-label="stepperLabel"
      />
    </template>
  </div>
</template>

<script>
const TOTAL_STEPS = 2
export default {
  data () {
    return {
      stepper: {
        total: TOTAL_STEPS,
        current: null
      }
    }
  },
  computed: {
    stepperLabel () {
      return this.$translate('register.photos.stepper') + ' ' + this.stepper.current + '/' + this.stepper.total
    }
  },
  watch: {
    $route: function (to, from) {
      this.getCurrentStep()
    }
  },
  mounted () {
    this.getCurrentStep()
  },
  methods: {
    getCurrentStep () {
      switch (this.$route.params.type) {
        case 'primary':
          this.stepper.current = 1
          break
        case 'others':
          this.stepper.current = 2
          break
      }
    }
  }
}
</script>
