var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page max-max-width-center", attrs: { id: "date-faq" } },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("faq-header", {
            attrs: {
              "header-title": _vm.$translate("faq.date_faq.title"),
              "main-header-logo": "/img/faq/date-faq-header-img.svg",
              "primary-header-color": "#FF0073",
              "secondary-header-color": "#962BDA",
              "header-gradient-angle": "236deg",
              "header-navbar-redirection": "faq",
              "has-tagline": false
            }
          }),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "checklist-ul" },
            _vm._l(11, function(i) {
              return _c(
                "li",
                { key: i },
                [
                  _c("faq-item", {
                    attrs: {
                      "item-type": _vm.$translate(
                        "faq.date_faq.item_" + i + ".type"
                      ),
                      content: _vm.$translate(
                        "faq.date_faq.item_" + i + ".content"
                      ),
                      img: _vm.$translate("faq.date_faq.item_" + i + ".img")
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }