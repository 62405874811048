var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "onboarding-lesson" } },
    [
      _vm.isLessonScreenOpened(_vm.SCREENS.START)
        ? _c("screen-start", { attrs: { "data-test": "onboarding-start-cpt" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLessonScreenOpened(_vm.SCREENS.THUMBS_UP)
        ? _c("screen-thumbs-up", {
            attrs: { "data-test": "onboarding-thumbsup-cpt" }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLessonScreenOpened(_vm.SCREENS.THUMBS_DOWN)
        ? _c("screen-thumbs-down", {
            attrs: { "data-test": "onboarding-thumbsdown-cpt" }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLessonScreenOpened(_vm.SCREENS.PHOTOS_GOOD)
        ? _c("screen-photos-good", {
            attrs: { "data-test": "onboarding-photosgood-cpt" }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLessonScreenOpened(_vm.SCREENS.PHOTOS_BAD)
        ? _c("screen-photos-bad", {
            attrs: { "data-test": "onboarding-photosbad-cpt" }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLessonScreenOpened(_vm.SCREENS.VIDEO)
        ? _c("screen-video", { attrs: { "data-test": "onboarding-video-cpt" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLessonScreenOpened(_vm.SCREENS.FEEDBACK)
        ? _c("screen-feedback", {
            attrs: { "data-test": "onboarding-feedback-cpt" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }