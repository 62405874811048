<template>
  <div id="interview-v2-edit">
    <bottomsheet :is-shown="isShown" @close="reset">

      <template v-slot:body>
        <div class="edit-interview-content">
          <h2>{{ questionLabel }}</h2>
          <div class="left-align m-bottom">
            <div class="question clickable">
              <md-input
                class="question-input question-trigger-text"
                type="text"
                :value="selectedQuestion"
                :label="questionLabel"
                readonly
              />
              <div class="question-trigger" @click="triggerSelect" />
              <i
                class="material-icons material-icon-question-trigger"
              >expand_more</i>
            </div>
            <md-select
              v-if="interviewOpts.length > 0"
              ref="selectModal"
              v-model="interview.title"
              class="hide"
              :label="questionNewPlaceholder"
              :placeholder="questionNewPlaceholder"
              :force-mobile="true"
              :new-design="true"
            >
              <option
                v-for="i in interviewOpts"
                slot="options"
                :value="i.key"
                :disabled="i.disabled"
              >{{ i.content }}</option>
            </md-select>
          </div>
          <div class="input-field">
            <md-textarea
              v-model="interview.answer"
              type="multiline"
              :maxlength="answerMaxChar"
              :force-active="true"
              :label="textareaPlaceholder"
              :placeholder="textareaPlaceholder">
              <div>
                <span class="input-helper right">
                  {{ getMaxChar(interview.answer) }}
                </span>
              </div>
            </md-textarea>
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <ul class="save-trigger">
          <li>
            <button
              class="interview_cta"
              data-activates="sidenav"
              :disabled="isDisabled"
              @click.prevent="doSave"
            >
              {{ $translate('app.save') }}
            </button>
          </li>
        </ul>
      </template>

    </bottomsheet>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import bottomsheet from '@components/v2/utilities/bottomsheet.vue'

const UserProvider = $h.getProvider('UserProvider')

const ANSWER_MAX_CHAR = 500

export default {
  components: {
    bottomsheet
  },
  props: {
    isShown: {
      type: Boolean,
      default: false
    },
    selectedItem: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      answerMaxChar: ANSWER_MAX_CHAR,
      selectedTitle: null,
      maxlength: ANSWER_MAX_CHAR,
      interview: {
        title: '',
        question: '',
        answer: '',
        index: -1
      },
      isSending: false
    }
  },
  watch: {
    selectedItem (newVal) {
      this.selectedTitle = newVal
      this.setQuestion()

      if (this.selectedItem === '') {
        this.interview.title = ''
        this.interview.answer = ''
      }
    }
  },
  mounted () {
    const title = this.selectedItem

    this.selectedTitle = title
    this.setQuestion()
  },
  methods: {
    reset () {
      if (this.selectedItem === '') {
        this.interview.title = ''
        this.interview.answer = ''
      } else {
        const originalstate = this.interviews.find((interview) => {
          return interview.title === this.selectedTitle
        })
        this.interview.title = originalstate.title
        this.interview.answer = originalstate.answer
      }

      this.$emit('close')
    },
    doSave () {
      if (this.isSending) return

      this.isSending = true

      this.$store.commit('interview/UPDATE_INTERVIEW', this.interview)

      const payload = { interview: this.interviews }

      UserProvider
        .updateInterview(payload)
        .then((res) => {
          this.$notifications.toast(this.$translate('profile.profile_updated'), 5000)

          this.$store.commit('setInterview', res.data.interview)
          this.$store.commit('setInterviewDe', res.data.interview_de)

          this.$emit('close')
        })
        .catch((err) => {
          this.$notifications.toast(err.message, 5000)
        })
        .finally(() => {
          this.isSending = false
        })
    },
    goback () {
      this.$router.replace({ name: 'profile-interview-v2' })
    },
    createSelectRef (index) {
      return 'select' + index
    },
    triggerSelect () {
      var $select = this.$refs.selectModal

      $($select.$el).find('input.select-dropdown').click()
    },
    setQuestion () {
      if (this.interviews) {
        const idx = this.interviews.findIndex(i => i.title === this.selectedTitle)

        if (idx !== -1) {
          this.interview = {
            ...this.interviews[idx],
            index: idx
          }
        } else {
          this.interview.index = this.interviews.length
        }
      } else {
        this.interview.index = 0
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      interviewsSelection: 'getInterviewsSelection',
      interviews: 'interview/GET_INTERVIEWS'
    }),
    isDisabled () {
      if (this.interview.title.length === 0 || this.interview.answer.length === 0) {
        return true
      }
      return false
    },
    isInterviewEditReady () {
      return this.interview.index >= 0
    },
    interviewOpts () {
      let interviewOpts = $h.obj2arr(this.interviewsSelection)

      if (this.interviews) {
        interviewOpts = interviewOpts.map((s) => {
          return {
            ...s,
            disabled: this.interviews
              .findIndex(i => i.title === s.key) !== -1
          }
        })
      } else {
        interviewOpts = interviewOpts.map((s) => {
          return {
            ...s,
            disabled: false
          }
        })
      }

      return interviewOpts
    },
    getMaxChar () {
      return (answer) => {
        if (answer) {
          return `${answer.length}/${ANSWER_MAX_CHAR}`
        }
        return `0/${ANSWER_MAX_CHAR}`
      }
    },
    textareaPlaceholder () {
      return `${this.$translate('interview.answer')} ${this.interview.index + 1}`
    },
    questionPlaceholder () {
      return `${this.$translate('interview.select_question')} ${this.interview.index + 1}`
    },
    questionNewPlaceholder () {
      return `${this.$translate('interview.new_select_question')}`
    },
    questionLabel () {
      return `${this.$translate('interview.question')} ${this.interview.index + 1}`
    },
    isLabelActive () {
      if (this.interview.answer) {
        if (this.interview.answer.length) {
          return false
        }
      }
      return true
    },
    selectedQuestion () {
      const selected = this.interviewOpts.find(i => i.key === this.interview.title)

      this.interview.question = (selected) ? selected.content : ''

      return (selected) ? selected.content : ''
    },
    characterCount () {
      if (this.interview.answer) {
        return `${this.interview.answer.length}/${this.maxlength}`
      }
      return `0/${this.maxlength}`
    }
  }
}
</script>

<style lang="scss" scoped>
#interview-v2-edit {

  .edit-interview-content {

    h2 {
      color: #2F3740;
      font-size: 22px;
      line-height: 26px;
      margin: 0 0 36px 0;
      padding: 0;
      text-align: left;
    }

    .input-helper {
      color: #52575B;
    }
  }

  .save-trigger {
    text-align: right;
    width: 100%;
    float: none;

    button {
      background: #2F3740 0% 0% no-repeat padding-box;
      border: none;
      border-radius: 10px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 800;
      line-height: 14px;
      padding: 16px 44px 17px;
      text-transform: uppercase;
    }

    button[disabled] {
      background: #CED6E0 0% 0% no-repeat padding-box !important;
    }

  }

  .m-bottom {
    margin-bottom: 20px;
  }

  .question {
    position: relative;

    .question-trigger {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 10;
    }
    .material-icons {
      position: absolute;
      top: 0;
      right: 0;
      height: 50%;
      bottom: 0;
      margin: auto;
      color: #959DA6;
      z-index: 1;

      &.material-icon-question-trigger {
        display: flex;
        align-items: center;
        color: #78838F;
        margin-right: 16px;
      }
    }
  }
  .question-input {

    input[type="text"][readonly="readonly"] {
      color: #2F3740;
      border-bottom: 2px solid #2F3740;
      width: 100%;
    }
  }
  .add-mode {
      background: #FFF;
      padding: 9px 16px 16px;
      border: 1px dashed #959DA6;
      border-radius: 4px;
  }
  .question-counter {
      font-size: 12px;
  }
}
</style>
