export const DEFAULT_SUPER_PROVIDER_REQUIREMENTS = ({
  successful_requests: {
    rule: 'min',
    value: 5
  },
  primetime_availability_hours: {
    rule: 'min',
    value: 120,
    subsettings: {
      successful_meetings_exemption: {
        rule: 'min',
        value: 50
      }
    }
  },
  client_rating: {
    rule: 'min',
    value: 4.9
  },
  reliability_rating: {
    rule: 'min',
    value: 4.5
  },
  unpaid_invoices: {
    rule: 'max',
    value: 0,
    subsettings: {
      unpaid_invoices_overdue: {
        rule: 'max',
        value: 14
      }
    }
  },
  provider_cancellations: {
    rule: 'max',
    value: 0
  }
})

export const DEFAULT_SUPER_PROVIDER_STATUS = ({
  successful_requests: 0,
  primetime_availability_hours: 0,
  client_rating: '0',
  reliability_rating: '0',
  provider_cancellations: 0,
  _oldest_invoice: {
    invoice_number: '',
    invoice_age: 0
  },
  _next_review_date: ''
})

export const DEFAULT_SUPER_PROVIDER_USER_DETAILS = ({
  is_super_provider: 0,
  is_super_provider_review_seen: 0,
  is_super_provider_promo_seen: 0
})

export const SP_SETTINGS_SEEN_FIELD_TYPES = {
  REVIEW: 'review',
  PROMO: 'promo',
  PROMO_POPUP: 'promo_popup'
}
