<template>
  <div v-if="isViewReady" id="event-rate">
    <div class="content">
      <p class="header">
        <span class="please-rate">{{ $translate('events.rate.please_rate') }}</span><br>
        <span class="grey-text">
          {{
            $translate('events.rate.please_rate_client', { client_name: selectedEvent.client.name }) }}
        </span>
      </p>
      <div style="background: #000; color: #FFF; padding: 5px; font-size: 14px; width: 135px; border-radius: 4px;">
        <strong class="center-countdown countdown">
          {{ meetingCountdown }}
        </strong>
      </div><br>
      <div class="rate-btns">
        <a href="#" class="rate"
           @click.prevent="selectedRate(1)">
          <i class="material-icons">thumb_up</i>
        </a>
        <a href="#" class="rate"
           @click.prevent="selectedRate(0)">
          <i class="material-icons">thumb_down</i>
        </a>
      </div><br>
      <div class="note">
        <h5 class="black-text">
          {{ $translate('events.rate.be_honest_and_fair') }}
        </h5>
        <p class="grey-text">
          {{ $translate('events.rate.be_honest_and_fair_explanation') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import NullSelectCheck from './mixins/nullSelectCheck.js'

export default {
  mixins: [
    NullSelectCheck
  ],
  computed: {
    meetingCountdown () {
      let countdown = 0

      if (this.selectedEvent) {
        countdown = moment().hour(0)
          .minutes(0)
          .second(this.selectedEvent._feedback_metadata.expiration_in_seconds)
          .format('mm[m]:ss[s]')
      }

      return `${this.$translate('events.confirm_popup.time_left')}: ${countdown}`
    }
  },
  mounted () {
    this.$store.commit('events/UPDATE_TICKET_STATUS', {
      id: this.selectedEvent.id,
      newStatus: TICKET_STATUS.POST_MEETING
    })
    this.$store.commit('events/SET_ACTIVE', null)
  },
  methods: {
    selectedRate (rate) {
      this.$router
        .replace({
          name: 'event-rate-feedback',
          params: { rate }
        })
    }
  }
}
</script>
