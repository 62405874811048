var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "autocomplete",
      class: { "autocomplete--visible": _vm.isShown },
      attrs: { id: _vm.component_id }
    },
    [
      _c("div", { staticClass: "autocomplete__container" }, [
        _c("div", { staticClass: "autocomplete__header" }, [
          _c(
            "div",
            {
              staticClass: "autocomplete__close-cta",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return (function() {
                    return _vm.$emit("close")
                  })($event)
                }
              }
            },
            [
              _c("img", {
                attrs: { src: "/img/icons/close-icon-48.png", alt: "" }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "autocomplete__input" }, [
          _c(
            "div",
            { staticClass: "input-field" },
            [
              _c("md-input", {
                ref: "search",
                class: _vm.hotjarWhitelist ? "data-hj-whitelist" : "",
                attrs: {
                  value: _vm.query,
                  type: "text",
                  label: _vm.label,
                  placeholder: _vm.computedPlacholder,
                  "data-test": "material-input",
                  clearable: true
                },
                on: {
                  input: _vm.delayedSearch,
                  focus: _vm.onSearchFocus,
                  blur: _vm.onSearchBlur
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm.hasResults
          ? _c(
              "div",
              {
                ref: "suggestions",
                staticClass: "autocomplete__suggestions",
                class: {
                  "has-bottom-status-indicator": _vm.hasBottomStatusIndicator
                }
              },
              _vm._l(_vm.suggestions, function(suggestion, key) {
                return _c(
                  "div",
                  {
                    key: key,
                    on: {
                      mousedown: function($event) {
                        $event.preventDefault()
                        return _vm.select(suggestion)
                      }
                    }
                  },
                  [
                    _vm._t(
                      "default",
                      [
                        _c("div", { staticClass: "suggestion-item" }, [
                          _vm._v(_vm._s(suggestion[_vm.searchKey]))
                        ])
                      ],
                      { value: suggestion }
                    )
                  ],
                  2
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.noResults
          ? _c(
              "div",
              { staticClass: "autocomplete__suggestions" },
              [_vm._t("no-results", null, { value: _vm.query })],
              2
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }