<template>
  <div
    class="billing-item"
    :class="{
      'billing-item--overdue': isOverdue,
      'billing-item--late': isUnpaid,
      'billing-item--cancelled': isCancelled,
      'billing-item--paid': isPaid,
      'billing-item--expanded': isExpanded
    }"
    data-test="billing-item"
    @click="handleItemClick(index)"
  >
    <div class="billing-item__header">
      <div class="billing-item__header-icon">
        <i class="material-icons">description</i>
        <div class="billing-item__header-invoice-id" data-test="invoice-id">{{ invoice.invoice_number }}</div>
      </div>
      <div class="billing-item__header-amount" data-test="amount">{{ amount }}</div>
      <div class="billing-item__header-status" data-test="status">{{ statusText }}</div>
    </div>
    <div v-if="isExpanded" class="billing-item__body">
      <div v-if="hasCreditUsed" class="billing-item__body-row" data-test="credit-used">
        <div class="billing-item__body-row-label">{{ $t('credit_used') }}</div>
        <div class="billing-item__body-row-value">{{ creditUsed }}</div>
      </div>
      <div class="billing-item__body-row" data-test="invoice-number">
        <div class="billing-item__body-row-label">{{ $t('invoice_number') }}</div>
        <div class="billing-item__body-row-value">{{ invoice.invoice_number }}</div>
      </div>
      <div class="billing-item__body-row" data-test="invoice-date">
        <div class="billing-item__body-row-label">{{ $t('invoice_date') }}</div>
        <div class="billing-item__body-row-value">{{ invoiceDate }}</div>
      </div>
      <div v-if="isPaid" class="billing-item__body-row" data-test="paid-date">
        <div class="billing-item__body-row-label">{{ $t('paid.date') }}</div>
        <div class="billing-item__body-row-value">{{ paidDate }}</div>
      </div>
    </div>
    <div v-if="isExpanded && (isOverdue || isUnpaid)" class="billing-item__footer">
      <img src="/img/billing/billing-bank-icon.svg" alt="">
      <a href="#" data-test="view-bank-cta" @click.stop="handleShowModal(index)">{{ $t('bank_details.cta') }}</a>
    </div>
  </div>
</template>

<i18n src="@/features/billing/translations/billing.json"></i18n>

<script>
import {
  DEFAULT_BILLING_OVERVIEW
} from '@/features/billing/constants/billing-overview-defaults'

export default {
  inject: {
    billingData: {
      default: () => ({
        billingOverview: DEFAULT_BILLING_OVERVIEW,
        isEUR: false
      })
    }
  },
  props: {
    invoice: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: null
    }
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    currency () {
      return this.user?._currency ?? '€'
    },
    amount () {
      const amount = this.billingData.isEUR
        ? `${this.invoice?.amount?.toFixed(2)} ${this.currency}`
        : `${this.currency} ${this.invoice?.amount?.toFixed(2)}`

      return amount
    },
    creditUsed () {
      const credit = this.billingData.isEUR
        ? `${this.invoice?._invoice_credits?.toFixed(2)} ${this.billingData?.billingOverview?.currency_symbol}`
        : `${this.billingData?.billingOverview?.currency_symbol} ${this.invoice?._invoice_credits?.toFixed(2)}`

      return credit
    },
    hasCreditUsed () {
      return parseInt(this.invoice._invoice_credits) > 0
    },
    statusText () {
      if (this.isUnpaid) {
        return this.$t('unpaid.invoice_status', { days: (14 - this.invoice._invoice_age ) })
      }

      if (this.isOverdue) {
        return this.$t('unpaid.overdue_status')
      }

      if (this.isPaid) {
        return this.$t('paid.invoice_status')
      }

      if (this.isCancelled) {
        return this.$t('cancelled')
      }

      return ''
    },
    invoiceDate () {
      return moment(this.invoice.created_at)
        .locale(this.$i18n.locale)
        .format('ddd, DD MMM')
        .replaceAll('.', '')
    },
    paidDate () {
      if (!this.isPaid) return ''

      return moment(this.invoice.paid_at)
        .locale(this.$i18n.locale)
        .format('ddd, DD MMM')
        .replaceAll('.', '')
    },
    isUnpaid () {
      return this.invoice.status === 0 && parseInt(this.invoice._invoice_age) < 14
    },
    isOverdue () {
      return this.invoice.status === 0 && parseInt(this.invoice._invoice_age) >= 14
    },
    isPaid () {
      return this.invoice.status === 1 && this.invoice.paid_at
    },
    isCancelled () {
      return this.invoice.status === 2
    },
    isExpanded () {
      return this.invoice._expanded
    }
  },
  methods: {
    handleShowModal (index) {
      this.$emit('show-bank-details', index)
    },
    handleItemClick (index) {
      this.$emit('item-collapse', index)
    }
  }
}
</script>

<style>

</style>
