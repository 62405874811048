<template>
  <div id="affiliate-landing-page">
    <md-new-navbar />
    <div id="main-container" class="container">
      <div class="affiliate-title" data-test="affiliate-title">
        <div class="affiliate-title--first-line" v-text="$t('title.sub')" />
        <div
          class="affiliate-title--second-line"
          v-text="$t('title.main', { amount: getRewardByCountryCode })"
        />
      </div>
      <div class="affiliate-jumbotron">
        <img data-test="affiliate-banner-image" :src="getAffiliateImg" alt="">
      </div>
      <div class="sharing-description">
        <div class="sharing-information-text">
          {{ $t('copy_text') }}
        </div>
        <div data-test="affiliate-copy-to-clipboard" class="copy-to-clipboard-container">
          <div class="referral-url-preview">{{ affiliateReferralUrl }}</div>
          <button
            class="copy-cta btn display-block waves-effect"
            @click.prevent="copyToClipboard"
          >{{ $t('copy_cta') }}</button>
        </div>
        <div class="separator-text">
          {{ $t('or_txt') }}
        </div>
        <div class="social-icons-container" data-test="affiliate-social-platforms">
          <a
            v-for="(socialIcon, i) in socialIconsFiltered"
            :key="i"
            class="social-icon"
            :class="socialIcon.class"
            :href="getLink(socialIcon)"
            v-bind="getProps(socialIcon)"
          >
            <img :src="socialIcon.icon" :alt="socialIcon.platform">
          </a>
        </div>
        <div class="potential-text" data-test="affiliate-potential-text" v-html="$t('potential_text')" />
      </div>
      <div class="referral-overview-shortcut" data-test="affiliate-referral-overview-cta" @click="goToReferralOverview">
        <div>
          <img class="paid-icon" src="/img/affiliate/affiliate-paid-icon.svg" alt="$">
          <span class="shortcut-txt">{{ $t('referral_overview_shortcut') }}</span>
        </div>
        <img class="forward-icon" src="/img/affiliate/affiliate-arrow-forward-icon.svg" alt=">">
      </div>

      <div class="affiliate-content" data-test="affiliate-explanation-text">
        <ul class="affiliate-content__list">
          <li
            v-for="(item, key) in $t('list')"
            :key="key"
            class="affiliate-content__list-item">
            <div class="affiliate-content__list-item-icon">
              <img :src="item.icon" alt="">
            </div>
            <span class="affiliate-content__list-item-text">
              <span>{{ $t(`list[${key}].text`, { amount: getRewardByCountryCode }) }}
              </span>
              <i
                v-if="translationHasTooltip(item)"
                data-test="affiliate-tooltip-trigger"
                class="material-icons affiliate-tooltip-trigger"
                @click="showTooltip(item.tooltip)">help_outline</i>
            </span>
          </li>
        </ul>
      </div>
      <affiliate-landing-page-qa class="affiliate-landing-qa" data-test="affiliate-q-and-a-section" />
      <div class="affiliate-cta fixed">
        <affiliate-share-button data-test="affiliate-share-cta" />
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/affiliate/translations/landing-page.json"></i18n>

<script>
import AffiliateShareButton from '@/features/affiliate/components/common/AffiliateShareButton.vue'
import AffiliateLandingPageQA from '@/features/affiliate/components/landing-page/AffiliateLandingPageQA.vue'
import { tooltipPlugin } from '@/features/super-provider/plugins/vue-custom-tooltip.js'
import { postAffiliateShareLink } from '@/features/affiliate/services/AffiliateService.js'

const SOCIAL_PLATFORMS = Object.freeze({
  WHATSAPP: 'Whatsapp',
  TELEGRAM: 'Telegram',
  MESSENGER: 'Messenger',
  SNAPCHAT: 'Snapchat'
})

export default {
  components: {
    AffiliateShareButton,
    'affiliate-landing-page-qa': AffiliateLandingPageQA
  },
  data: () => ({
    loading: false,
    socialIcons: [
      {
        platform: SOCIAL_PLATFORMS.WHATSAPP,
        icon: '/img/affiliate/social-icon-whatsapp.svg',
        link: 'https://api.whatsapp.com/send?text=',
        class: '',
        props: {
          'data-action': 'share/whatsapp/share'
        }
      },
      {
        platform: SOCIAL_PLATFORMS.TELEGRAM,
        icon: '/img/affiliate/social-icon-telegram.svg',
        link: 'https://telegram.me/share/url?url=',
        class: '',
        props: {
        }
      },
      {
        platform: SOCIAL_PLATFORMS.MESSENGER,
        icon: '/img/affiliate/social-icon-messenger.svg',
        link: '#',
        class: 'fb-share-button',
        props: {
          'data-href': ''
        }
      },
      {
        platform: SOCIAL_PLATFORMS.SNAPCHAT,
        icon: '/img/affiliate/social-icon-snapchat.svg',
        link: '#',
        class: 'snapchat-share-button',
        props: {
          'data-share-url': ''
        }
      }
    ]
  }),
  computed: {
    socialIconsFiltered () {
      // If not on mobile show everything except messenger and snapchat
      if (!this.isMobile) {
        return this.socialIcons.filter(s => s.platform === SOCIAL_PLATFORMS.WHATSAPP || s.platform === SOCIAL_PLATFORMS.TELEGRAM)
      }

      return this.socialIcons
    },
    isMobile () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },
    encodedAffiliateReferralUrl () {
      return encodeURI(this.affiliateReferralUrl)
    },
    affiliateReferralUrl () {
      return this.user?.settings?._referral_link || this.$store.getters['affiliate/getAffiliateReferralUrl']
    },
    getAffiliateImg () {
      return '/img/affiliate/gruppe-13903.svg'
    },
    affiliateSettings () {
      return this.$store.getters['affiliate/getAffiliateSettings']
    },
    user () {
      return this.$store.getters.getUser
    },
    currency () {
      return this.user?._currency ?? '€'
    },
    isFromGB () {
      return (this.user?.profiles?.country_code ?? '').toLowerCase() === 'gb'
    },
    getCurrencySymbolByCountryCode () {
      return this.isFromGB ? this.affiliateSettings?.currency_symbols?.gbp : this.affiliateSettings?.currency_symbols?.eur
    },
    getRewardByCountryCode () {
      return this.isFromGB
        ? `${this.currency} ${this.affiliateSettings?.rewards?.gbp}`
        : `${this.affiliateSettings?.rewards?.eur} ${this.currency}`
    },
    profileUrl () {
      return this.user?.profiles?._profile_url ?? null
    },
    profileName () {
      return this.user?.profiles?.name ?? null
    }
  },
  mounted () {
    this.checkOrGenerateReferralLink()
    // Only run the social platform with SDK on mobile
    if (this.isMobile) {
      this.initiateFacebookSDK()
      this.initiateSnapChatSDK()
    }
  },
  methods: {
    checkOrGenerateReferralLink () {
      if (!this.user?.settings?._referral_link) {
        this.generateAffiliateUrl()
          .then(res => {
            if (res?.data) {
              this.$store.commit('affiliate/setAffiliateReferralUrl', res.data)
            }
          })
      }
    },
    getLink (socialIcon) {
      if (socialIcon.platform === SOCIAL_PLATFORMS.MESSENGER) {
        return `fb-messenger://share?link=${this.encodedAffiliateReferralUrl}&app_id=${process.env.FE_FB_APP_ID}`
      }

      if (socialIcon.platform === SOCIAL_PLATFORMS.WHATSAPP) {
        return `${socialIcon.link}${this.$t('share_on_social_media_text')}${this.encodedAffiliateReferralUrl}`
      }

      if (socialIcon.platform === SOCIAL_PLATFORMS.TELEGRAM) {
        return `${socialIcon.link}${this.encodedAffiliateReferralUrl}`
      }

      return '#'
    },
    getProps (socialIcon) {
      const socialPlatform = socialIcon

      if (socialPlatform.platform === SOCIAL_PLATFORMS.SNAPCHAT) {
        socialPlatform.props['data-share-url'] = this.affiliateReferralUrl
      }

      return socialPlatform.props
    },
    generateAffiliateUrl () {
      this.loading = true

      return new Promise((resolve, reject) => {
        const locale = this.$i18n?.locale ?? 'de'
        postAffiliateShareLink(locale)
          .then(res => {
            this.loading = false
            resolve(res)
          })
          .catch(err => {
            this.loading = false
            reject(err)
            this.handleErrorMessages(err)
          })
      })
    },
    initiateSnapChatSDK () {
      (function (d, s, id) {
        var js
        var sjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src = 'https://sdk.snapkit.com/js/v1/create.js'
        sjs.parentNode.insertBefore(js, sjs)
      })(document, 'script', 'snapkit-creative-kit-sdk')

      window.snapKitInit = function () {
        window.snap.creativekit.initalizeShareButtons(
          document.getElementsByClassName('snapchat-share-button')
        )
      }
    },
    initiateFacebookSDK () {
      (function (d, s, id) {
        var js; var fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s); js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0'
        fjs.parentNode.insertBefore(js, fjs)
      }(document, 'script', 'facebook-jssdk'))
    },
    async copyToClipboard () {
      try {
        await navigator.clipboard.writeText(this.affiliateReferralUrl)
        this.$notifications.toast('COPIED!', 3000)
      } catch (err) {
        console.error('Failed to copy: ', err)
      }
    },
    goToReferralOverview () {
      this.$router.push({ name: 'affiliate-pending' })
    },
    translationHasTooltip (item) {
      return item?.tooltip?.length > 0
    },
    share () {
      navigator.share({
        url: this.profileUrl,
        title: this.profileName
      })
    },
    showTooltip (tooltipExplanation) {
      tooltipPlugin.toast(tooltipExplanation)
    }
  }
}
</script>

<style lang="scss" scoped>
#main-container {
  background-color: #FFFFFF;
  padding-bottom: 169px;
}
.affiliate-title,
.affiliate-jumbotron
.affiliate-content {
  padding: 0 24px;
  text-align: center;
}
.affiliate-title {
  padding-top: 8px;
  padding-bottom: 32px;
  font-family: 'Proxima Nova Semibold';
  color: #2E353D;
}
.affiliate-title--first-line {
  font-size: 20px;
}
.affiliate-title--second-line {
  font-size: 26px;
}
.affiliate-jumbotron {
  margin-bottom: 24px;
  text-align: center;
}

.affiliate-content__list {
  margin: 0;
  padding: 14px 16px;
  border-radius: 8px;
  border: 1px solid #CAD1D9;
  background-color: #F5F7FA;
}
.affiliate-content__list-item {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 16px;
  font-size: 16px;
  font-family: 'Proxima Nova';
  text-align: left;
}
.affiliate-content__list-item::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 21px;
  height: 100%;
  border-left: 2px dashed #C51162;
}
.affiliate-content__list-item:last-child::before {
  display: none;
}
.affiliate-content__list-item:last-child {
  padding-bottom: 0;
}
.affiliate-content__list-item-icon {
  position: relative;
  margin-left: -8px;
}
.affiliate-content__list-item-icon img {
  display: block;
}
.affiliate-cta {
  padding: 24px;

  &.fixed {
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    background: #FFFFFF;
    left: 0;
    right: 0;
    max-width: 600px;
    text-align: center;
    margin: auto;
  }
}
.affiliate-tooltip-trigger {
  color: #78838f;
  font-size: 1.2rem;
  vertical-align: text-bottom;
}

.sharing-description {
  text-align: center;
  font-family: 'Proxima Nova';

  .sharing-information-text, .separator-text {
    color: #2F3740;
    font-family: 'Proxima Nova Medium';
    font-size: 18px;
  }

  .separator-text {
    margin-top: 16px;
  }

  .copy-to-clipboard-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    .referral-url-preview {
      border-radius: 10px;
      border: 1px solid #CED6E0;
      background: #FFFFFF;
      color: #2F3740;
      font-family: 'Proxima Nova Semibold';
      font-size: 16px;
      min-width: 227px;
      height: 47px;
      line-height: 47px;
      text-align: center;
      display: block;
      padding-left: 16px;
      padding-right: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }

    .copy-cta {
      margin-left: 8px;
      height: 47px;
      width: max-content;
      border-radius: 10px;
      padding-left: 24px;
      padding-right: 24px;
      background: transparent linear-gradient(64deg, #DC0064 0%, #FF0073 100%) 0% 0% no-repeat padding-box;
      box-shadow: none;
      font-family: 'Proxima Nova Semibold';
      font-size: 16px;
    }
  }

  .social-icons-container {
    margin-top: 14px;
    display: flex;
    justify-content: center;

    .social-icon {
      display: block;
      height: 55px;
      width: 55px;
      border-radius: 50%;
      padding: 12px;
      box-shadow: 0px 3px 6px #00000029;

      &:not(:first-child) {
        margin-left: 16px;
      }
    }
  }

  .potential-text {
    margin-top: 40px;
    font-family: 'Proxima Nova';
    font-size: 18px;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
}

.referral-overview-shortcut {
  margin-top: 12px;
  margin-bottom: 20px;
  cursor: pointer;
  height: 48px;
  line-height: 48px;
  padding-left: 12px;
  font-family: 'Proxima Nova Semibold';
  font-size: 18px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;

  .paid-icon {
    height: 24px;
    width: 24px;
    line-height: 48px;
    vertical-align: middle;
  }

  .shortcut-txt {
    padding-left: 12px;
    line-height: 48px;
    vertical-align: middle;
  }
}
</style>
