<template>
  <div class="modal" data-test="onboarding-lesson-reminder-modal">
    <div class="modal-content">
      <h4 class="modal-title margin-bottom-sm" style="font-size: 20px;">{{ $t('title') }}</h4>
      <p class="center grey-text margin-bottom-none margin-top-none text-darken-1" style="font-size: 14px;">{{ $t('body') }}</p>
    </div>
    <div class="modal-footer" style="text-align: center; text-align: center;">
      <a
        href="#"
        data-test="onboarding-lesson-reminder-modal-confirm"
        class="btn-flat waves-effect waves-dark krypton-pink-text"
        style="font-size: 16px;"
        @click.prevent="confirm"
      >
        {{ $t('cta') }}
      </a>
    </div>
  </div>
</template>

<i18n src="../translations.json"></i18n>

<script>
export default {
  methods: {
    confirm ($e) {
      this.$emit('confirm', $e)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-content {
    h4, p {
      text-align: left;
    }
  }
}
</style>
