var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isViewReady
    ? _c(
        "div",
        { attrs: { id: "event-whereis" } },
        [
          _c("md-navbar", {
            attrs: {
              push: { name: "event-details", params: { event: {} } },
              brand: _vm.$translate("events.nav_title"),
              "menu-trigger": false,
              icon: "arrow_back"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "content container" }, [
            _c(
              "div",
              { staticClass: "chat-container" },
              [
                _c("div", { staticClass: "warning" }, [
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v("warning")
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    attrs: { id: "event-whereis-meeting-point" },
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$translate("events.where_is.warning", {
                          meeting_point: _vm.meetingPoint,
                          client_name: _vm.selectedEvent.client.name
                        })
                      )
                    }
                  })
                ]),
                _vm._v(" "),
                _vm._l(_vm.chat, function(c, idx) {
                  return _c("chat-bubble", {
                    key: idx,
                    attrs: {
                      message: c.message,
                      "created-at": c._message_sent_at_in_seconds,
                      seen: c.group_id === 2 ? c.seen !== null : false,
                      "sent-by": c.group_id === 2 ? c.sent_by : 0
                    }
                  })
                }),
                _vm._v(" "),
                _vm.isFetching
                  ? _c("div", { staticClass: "margin-top-md padding center" }, [
                      _vm._v("Fetching messages...")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.remaining > 0 && !_vm.isFetching
                  ? _c("div", { staticClass: "message-left" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$translate("events.where_is.message_left", {
                              count: _vm.remaining
                            })
                          )
                        )
                      ])
                    ])
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            !_vm.isFetching
              ? _c("div", [
                  _vm.remaining > 0
                    ? _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.send($event)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "chat-controls container" },
                            [
                              _c("md-textarea", {
                                attrs: {
                                  type: "multiline",
                                  "data-test": "whereis",
                                  placeholder: _vm.textPlaceholder,
                                  disabled:
                                    !_vm.canSendMessage ||
                                    _vm.chat.length === 0,
                                  "force-active": true,
                                  maxlength: 250,
                                  required: ""
                                },
                                on: { focus: _vm.onInputFocus },
                                model: {
                                  value: _vm.message,
                                  callback: function($$v) {
                                    _vm.message = $$v
                                  },
                                  expression: "message"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn-floating btn-large btn-round waves-effect btn-krypton-pink waves-effect waves-light",
                                  class: {
                                    "animated infinite heartbeat":
                                      _vm.chat.length === 0
                                  },
                                  attrs: {
                                    id: "event-whereis-send-btn",
                                    type: "submit",
                                    disabled: !_vm.canSendMessage
                                  }
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("send")
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "placeholder-text krypton-pink-text center margin-top-sm"
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$translate(
                                  "events.where_is.no_message_left"
                                )
                              ) +
                              " "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "underline clickable",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  _vm.$router.push({
                                    name: "support-v2",
                                    params: {
                                      navTitle: _vm.$translate(
                                        "events.buttons.where_is_he"
                                      )
                                    }
                                  })
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "events.where_is.contact_support"
                                  )
                                )
                              )
                            ]
                          )
                        ]
                      )
                ])
              : _vm._e()
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }