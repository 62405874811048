var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "input-field col s12 autocomplete-parent",
      class: {
        "autocomplete-parent--APV_REG_REDESIGN": true,
        disabled: _vm.shouldDisableAutocomplete
      },
      on: { click: _vm.onClickHandler }
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.model,
            expression: "model"
          }
        ],
        staticClass: "autocomplete",
        class: {
          "has-value": _vm.model
        },
        attrs: {
          id: "autocomplete-input",
          type: "text",
          autocomplete: "test",
          disabled: _vm.shouldDisableAutocomplete
        },
        domProps: { value: _vm.model },
        on: {
          focus: _vm.onInputFocus,
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.model = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          class: {
            active: _vm.model
          },
          attrs: { for: "autocomplete-input" }
        },
        [_vm._v(_vm._s(_vm.label))]
      ),
      _vm._v(" "),
      _vm._t("errors"),
      _vm._v(" "),
      _c("i", { staticClass: "material-icons" }, [_vm._v("expand_more")])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }