const LocaleProvider = window?.$h?.getProvider('LocaleProvider')

export function buildParam (url, obj) {
  if (url[0] === '/') {
    url = process.env.KRYPTON_PROVIDER_API + url
  }

  var round = 0
  var param = null

  if (obj == null) obj = {}

  obj.locale = LocaleProvider.getLocale()

  for (var key in obj) {
    param = (!round) ? '?' : param + '&'
    param = param + key + '=' + obj[key]
    round++
  }

  return url + param
}
