var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "onboarding-progress-greeting" } },
      [
        _c("div", { staticClass: "greeting-container" }, [
          _c("div", { staticClass: "greeting-text" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$translate("onboarding.greeting")) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "greeting-name" }, [
            _vm._v("\n        " + _vm._s(_vm.providerName) + "\n      ")
          ])
        ]),
        _vm._v(" "),
        _c("circular-progress-bar", {
          attrs: {
            id: "onboarding-progress-bar",
            percentage: _vm.onBoardingProgress
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }