var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPopup
    ? _c("div", { attrs: { id: "provider-preferences-popup" } }, [
        _c(
          "div",
          {
            staticClass: "provider-preferences-popup__backdrop",
            attrs: { "data-test": "pp-popup-backdrop" }
          },
          [
            _c("div", { staticClass: "provider-preferences-popup__modal" }, [
              _c(
                "a",
                {
                  staticClass: "provider-preferences-popup__close-btn",
                  attrs: { href: "#", "data-test": "sp-popup-close" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.closePopup($event)
                    }
                  }
                },
                [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "provider-preferences-popup__title" }, [
                _c("h2", [_vm._v(_vm._s(_vm.$t("title")))]),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: require("@/features/provider-preferences/images/provider-preferences-popup.svg"),
                    alt: "provider-preferences-icon"
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "provider-preferences-popup__description",
                  attrs: { "data-test": "sp-popup-explanation-text" }
                },
                [_vm._v(" " + _vm._s(_vm.$t("description")))]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "provider-preferences-popup__cta btn waves-effect waves-dark",
                  attrs: { "data-test": "sp-popup-cta" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.closePopup({
                        redirect: {
                          name: "profile",
                          params: { showPPBottomSheet: true }
                        }
                      })
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("cta")))]
              )
            ])
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }