var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "inbox-list" } },
    [
      _c("inbox-filter", {
        attrs: { value: _vm.filter },
        on: { click: _vm.onClick }
      }),
      _vm._v(" "),
      _vm.isReviewFilter
        ? _c(
            "button",
            {
              staticClass:
                "feedback-cta-btn btn btn-secondary btn-large display-block waves-effect waves-dark margin-bottom-md capitalize",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$router.replace({ name: "feedback" })
                }
              }
            },
            [
              _vm._v(
                _vm._s(_vm.$translate("inbox.cta_buttons.see_performance"))
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.messages, function(message, idx) {
        return _c(
          "inbox-list-item",
          _vm._b(
            {
              key: idx,
              attrs: {
                "has-notification": message.hasNotification,
                "updated-at": message.lastReceivedAt
                  ? message.lastReceivedAt
                  : message.updatedAt
              }
            },
            "inbox-list-item",
            message,
            false
          )
        )
      }),
      _vm._v(" "),
      _vm.showLoadMore
        ? _c(
            "button",
            { staticClass: "load-more", on: { click: _vm.loadMore } },
            [_c("span", [_vm._v("LOAD MORE")])]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "infinite-loading",
        {
          ref: "infiniteLoader",
          attrs: {
            direction: "top",
            spinner: "spiral",
            identifier: _vm.filter
          },
          on: { infinite: _vm.infiniteHandler }
        },
        [
          _c("div", { attrs: { slot: "no-more" }, slot: "no-more" }),
          _vm._v(" "),
          _c("div", { attrs: { slot: "no-results" }, slot: "no-results" })
        ]
      ),
      _vm._v(" "),
      _vm.isEmpty && !_vm.isFetching && !_vm.isReviewFilter
        ? _c(
            "button",
            {
              staticClass:
                "btn btn-secondary btn-large display-block waves-effect waves-dark btn-inbox-connect-faq",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$router.replace({ name: "connect-faq" })
                }
              }
            },
            [
              _c("i", { staticClass: "mdi mdi-information valign-middle" }),
              _vm._v("  "),
              _c("strong", { staticClass: "valign-middle" }, [
                _vm._v(_vm._s(_vm.$translate("inbox.cta_buttons.goto_faq")))
              ])
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }