<template>
  <div class="new-navbar-wrapper" :class="{'--with-top-status-indicator': hasTopStatusIndicator}">
    <div class="new-navbar --fixed">
      <div class="new-navbar-container">
        <a class="back-btn" href="#" style="height: 100%;" @click.prevent="redirect">
          <img class="responsive-img" src="/img/back-arrow.svg" alt="back-btn">
        </a>
        <slot name="cta" />
      </div>
    </div>
    <div class="new-navbar__placeholder" />
  </div>
</template>
<script>
export default {
  props: {
    routeName: {
      type: String,
      default: null
    }
  },
  computed: {
    hasTopStatusIndicator () {
      return this.$store.getters.getTopStatusIndicator
    }
  },
  methods: {
    redirect () {
      if (this.routeName) {
        this.$router.push({ name: this.routeName })
      } else {
        this.$router.push({ name: 'menu' })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#new-navbar {
    box-shadow: none;
    display: block;
    height: 48px;
    margin: 0 auto;
    width: 100%;
    background-color: white;
}

.new-navbar-wrapper {
  position: relative;
  display: block;

  .new-navbar {
    box-shadow: none;
    display: block;
    height: 48px;
    margin: 0 auto;
    width: 100%;
    background-color: white;

    &.--fixed {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99;
      max-width: 600px;
      width: 100%;

      @media screen and (min-width: 600px) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .new-navbar-container {
    padding: 0 12px;
  }

  // necessary as the navbar is fixed now, so that contents
  // would be pushed to their original position
  .new-navbar__placeholder {
    height: 48px;
    background: transparent;
  }

  &.--with-top-status-indicator {
    .new-navbar {
      top: 61px;
    }
  }
}
</style>
