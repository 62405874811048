<template>
  <div id="support-v2" class="page wrapper">
    <md-new-navbar :route-name="'help'" />

    <div id="content">
      <div class="header center-align">
        <h1 class="support-v2__title">{{ $translate('support.support_title') }}</h1>

        <div>
          <img class="support-v2__img" src="/img/support/support-overview.svg" alt="support overview">
        </div>

        <p class="support-v2__description">{{ $translate('support.support_text') }}</p>
      </div>

      <div>
        <p v-if="tickets.length > 0" class="support-v2__ticket-list__header">{{ $translate('support.ticket_list_header') }}</p>
        <support-item
          v-for="(t, i) in tickets"
          :key="i"
          :ticket="t" />

        <infinite-loading spinner="spiral" @infinite="infiniteHandler">
          <div slot="no-more" />
          <div slot="no-results" />
        </infinite-loading>
      </div>

      <div class="support-v2__footer">
        <button class="support-v2__cta" @click.prevent="goToNewRequest">
          {{ $translate('support.buttons.new_ticket') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import SupportItem from './components/support-item.vue'

const LocaleProvider = $h.getProvider('LocaleProvider')

export default {
  components: {
    SupportItem,
    InfiniteLoading
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (to.params.navTitle) {
        vm.prevNav.title = to.params.navTitle
        vm.prevNav.name = from.name
      }
    })
  },
  data () {
    return {
      prevNav: {
        title: this.$translate('dashboard.title'),
        name: 'dashboard'
      },
      tickets: [],
      isFetching: false,
      page: 1
    }
  },
  beforeDestroy () {
    this.$store.commit('setHasFixedFooterButtons', false)
  },
  mounted () {
    $h.EventBus.$on('onsupportreply', this.onSupportReply)

    this.$store.commit('setHasFixedFooterButtons', true)
  },
  methods: {
    appendTickets (tickets) {
      const newData = tickets.map((t) => {
        let _last_message_received_at = moment(t.created_at).locale(LocaleProvider.getLocale())

        if (t.last_message_received_at !== null) {
          const lastUpdate = t.last_message_received_at
          _last_message_received_at = moment(lastUpdate, 'YYYY-MM-DD HH:mm:ss').locale(LocaleProvider.getLocale())
        }

        return {
          ...t,

          // Wrap created_at into moment.js object
          _created_at: moment(t.created_at, 'YYYY-MM-DD HH:mm:ss').locale(LocaleProvider.getLocale()),
          _last_message_received_at: _last_message_received_at
        }
      })

      this.tickets = this.tickets.concat(newData)
    },
    infiniteHandler ($state) {
      if (this.isFetching) return

      this.isFetching = true

      this.$store
        .dispatch('support/fetchSupportTickets', this.page)
        .then((res) => {
          const tickets = res.data

          if (tickets.length) {
            this.appendTickets(tickets)
            this.page += 1
            this.isFetching = false
            $state.loaded()
          } else {
            this.isFetching = false
            $state.complete()
          }
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    onSupportReply (ticket) {
      this.updateSeenTicket(ticket.support_id)
    },
    updateSeenTicket (ticketID) {
      const idx = this.tickets.findIndex(t => t.id === ticketID)

      if (idx >= 0) {
        this.tickets[idx].is_read = 0
        this.tickets[idx].last_message_received_at = moment().locale(LocaleProvider.getLocale())
      }
    },
    goToNewRequest () {
      this.$router.push({
        name: 'support-v2-request',
        params: {
          id: 'new'
        }
      })
    }
  }
}
</script>
