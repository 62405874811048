<template>
  <div class="affiliate-reward-modal" data-test="affiliate-popup">
    <div class="affiliate-reward-modal__backdrop" data-test="affilate-popup-backdrop">
      <div class="affiliate-reward-modal__card">
        <a href="#" class="affiliate-reward-modal__close-btn">
          <i
            data-test="affiliate-close-btn"
            class="material-icons"
            @click.prevent="closePopup({ type: affiliatePopupType })">close</i>
        </a>
        <img data-test="affiliate-image" src="/img/affiliate/affiliate-reward-modal.svg" alt="">
        <h2 data-test="affiliate-title-main" class="title-main">{{ $t('reward.title_main') }}</h2>
        <h2 data-test="affiliate-title">{{ $t('reward.title', {
          amount: getRewardByCountryCode
        }) }}</h2>
        <p data-test="affiliate-description">{{ $t('reward.description') }}</p>
        <a
          data-test="affiliate-cta"
          href="#"
          class="btn waves-effect"
          @click.prevent="closePopup({ redirect: { name: 'affiliate-received' }, type: affiliatePopupType })"
        >{{ $t('reward.cta') }}</a>
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/affiliate/translations/popup.json"></i18n>

<script>
import affiliatePopupsMixin from '@/features/affiliate/mixins/affiliate-popups.mixin.js'
import { AFFILIATE_SETTINGS_SEEN_FIELD_TYPES } from '@/features/affiliate/constants.js'

export default {
  data: () => ({
    affiliatePopupType: AFFILIATE_SETTINGS_SEEN_FIELD_TYPES.REWARD,
    ...affiliatePopupsMixin.data
  }),
  methods: {
    ...affiliatePopupsMixin.methods
  },
  computed: {
    affiliateSettings () {
      return this.$store.getters['affiliate/getAffiliateSettings']
    },
    user () {
      return this.$store.getters.getUser
    },
    isFromGB () {
      return (this.user?.profiles?.country_code ?? '').toLowerCase() === 'gb'
    },
    getRewardByCountryCode () {
      const currency = this.user?._currency ?? this.affiliateSettings?.currency_symbols?.eur

      return this.isFromGB
        ? `${currency}${this.affiliateSettings?.rewards?.gbp}`
        : `${currency}${this.affiliateSettings?.rewards?.eur}`
    }
  }
}
</script>

<style lang="scss" scoped>
.affiliate-reward-modal {
  .affiliate-reward-modal__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(#2E353D, 0.3);
    z-index: 999;

    .affiliate-reward-modal__card {
      padding: 41px 24px 48px;
      background-color: #FFFFFF;
      border-radius: 16px;
      position: relative;
      max-width: 568px;
      top: 50% !important;
      transform: scalex(1) translate(0, -50%) !important;
      transition: top 0.2s, opacity 0.2s;
      width: 100%;
      margin: 0 auto;
      text-align: center;

      @media screen and (max-width: 600px) {
        margin: 0 16px;
        width: 94%;
        }
      @media screen and (max-width: 420px) {
        width: 92%;
      }

      .affiliate-reward-modal__close-btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 44px;
        height: 44px;
        text-align: center;
        color: #737B85;

        i.material-icons {
          display: block;
          line-height: 44px;
        }
      }

      img {
        display: block;
        max-width: 100%;
        margin: 0 auto 8px;
      }

      h2 {
        &.title-main {
          margin: 0
        }

        margin: 0 0 24px;
        font-size: 22px;
        font-family: 'Proxima Nova Bold', sans-serif;
        color: #2F3740;
        line-height: 32px;
      }

      p {
        font-size: 16px;
        color: #737B85;
      }

      a.btn {
        padding: 0 35px;
        height: 44px;
        line-height: 44px;
        background: transparent linear-gradient(270deg, #E61472 0%, #B30F59 100%) 0% 0% no-repeat padding-box;
        border-radius: 8px;
        font-size: 16px;
        min-width: 186px;
      }
    }
  }
}
</style>
