<template>
  <div
    class="multi-location-promo-banner max-width-center"
    data-test="multi-location-promo-banner"
    @click="gotoPage('multi-location-overview')"
  >
    <div
      ref="banner-container"
      class="wrapper promo-banner-container --orange"
      :class="{ '--expanded-height': expandedHeight }"
    >
      <div class="promo-banner">
        <div class="more-requests">
          <img src="/img/multi-location/commute_black_24dp.svg">
          <span data-test="pp-bottom-banner-title" v-text="$t('banner.title')" />
        </div>
        <div class="learn-how" data-test="pp-bottom-banner-cta" v-text="$t('banner.cta')" />
      </div>
    </div>
  </div>
  </div>
</template>

<i18n src="@/features/multi-location/translations/banners.json"></i18n>

<script>
import goToPageMixin from '@/features/private-place/mixins/goToPage'
import fixedMenuMixin from '@components/v2/utilities/fixedMenu'

export default {
  mixins: [goToPageMixin, fixedMenuMixin],
  props: {
    expandedHeight: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    setTimeout(() => {
      this.adjustBannerBottomPosition()
    }, 300)
  }
}
</script>

<style lang="scss" scoped>
@import "@/features/promo-banner/shared/PromoBanner.style";
.multi-location-promo-banner {
  .multi-location-promo-banner-container {
    background: transparent linear-gradient(76deg, #FF665B 0%, #FFD04D 100%) 0% 0% no-repeat padding-box;
    justify-content: center;
    display: flex;
    position: fixed;
    bottom: 0;
    color: #FFFFFF;
    z-index: 3;
    width: 100%;
    height: 96px;
    align-items: flex-start;
    width: 100%;
    font-weight: 600;
  }

  .promo-banner {
    justify-content: space-between;
    display: flex;
    font-size: 16px;
    padding: 0 32px;
    width: 100%;

    @media (min-width: 992px) {
      width: 600px;
    }

    .more-requests {
      display: flex;

      span {
        margin-left: 8px;
      }
    }

    .learn-how {
      text-transform: uppercase;
    }
  }
}
</style>
