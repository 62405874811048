var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-feedback-success-modal-container" }, [
    _c("div", { staticClass: "app-feedback-success-popup" }, [
      _c("h2", [_vm._v(_vm._s(_vm.$t("title")))]),
      _vm._v(" "),
      _c("p", { domProps: { innerHTML: _vm._s(_vm.$t("contact_support")) } }),
      _vm._v(" "),
      _c("div", { staticClass: "app-feedback-success-popup__footer" }, [
        _c(
          "a",
          {
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.$emit("close")
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("cta")))]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }