<template>
  <div v-if="dropdown && optsLanguages.length" id="intellect" class="wrapper">
    <template>
      <div class="content__area">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <p class="content__stepper content__stepper--mobile"> {{ stepperLabel }}</p>
        <h2 class="content__title content__title--desktop">{{ $translate('register.personality.title.intellect') }}</h2>
      </div>

      <div class="content__area drawer --with-long-content --with-both-bars max-height-mobile">
        <div class="content">
          <p class="content__stepper content__stepper--desktop"> {{ stepperLabel }}</p>
          <h2 class="content__title content__title--mobile">{{ $translate('register.personality.title.intellect') }}</h2>

          <div class="drawer-icon --no-selected-text center-align">
            <img src="/img/registration/personality/intellect.svg" alt="girl with a note">
          </div>

          <!-- Lang 1 -->
          <md-select
            v-model="payload.lang_1"
            :force-mobile="true"
            desktop-display-mode="split"
            class="textfield-box-lg"
            :class="{'remove-bottom-space': !showSecondLang}"
            :label="$translate('register.personality.intellect.first_lang_label')"
            :placeholder="$translate('details.lang_1')"
            :default="$translate('register.personality.intellect.select_language')"
            :listen-for-reinitiate-options="true"
            @change="reInitiateOptions"
          >
            <option
              v-for="opt in optsLanguages"
              slot="options"
              :key="opt.key"
              :value="opt.key"
              :disabled="opt.key == payload.lang_1 || opt.key == payload.lang_2 || opt.key == payload.lang_3"
            >{{ opt.content }}</option>
            <div v-show="errors.has('lang_1')" slot="errors" class="input-helper red-text left-align">
              {{ errors.first('lang_1') }}
            </div>
          </md-select>
          <!-- Lang 2 -->
          <div v-if="showSecondLangDefault || showSecondLang">
            <md-select
              v-model="payload.lang_2"
              :force-mobile="true"
              desktop-display-mode="split"
              :listen-for-reinitiate-options="true"
              class="textfield-box-lg remove-bottom-space"
              :label="$translate('register.personality.intellect.second_lang_label')"
              :placeholder="$translate('details.lang_2')"
              :default="$translate('register.personality.intellect.select_language')"
              @change="reInitiateOptions"
            >
              <option v-for="opt in optsLanguages"
                      slot="options"
                      :key="opt.key"
                      :value="opt.key"
                      :disabled="opt.key == payload.lang_2 || opt.key == payload.lang_1 || opt.key == payload.lang_3">{{ opt.content }}</option>
              <div v-show="errors.has('lang_2')" slot="errors" class="input-helper red-text left-align">
                {{ errors.first('lang_2') }}
              </div>
            </md-select>

            <div class="btn-holder">
              <strong class="remove-lang uppercase" @click.prevent="removeLang(2)">
                {{ $translate('app.remove') }}
              </strong>
            </div>

          </div>
          <!-- Lang 3 -->
          <div v-if="showThirdLang">
            <md-select
              v-model="payload.lang_3"
              :force-mobile="true"
              desktop-display-mode="split"
              :listen-for-reinitiate-options="true"
              class="textfield-box-lg remove-bottom-space"
              :label="$translate('register.personality.intellect.third_lang_label')"
              :placeholder="$translate('details.lang_3')"
              :default="$translate('register.personality.intellect.select_language')"
              @change="reInitiateOptions"
            >
              <option v-for="opt in optsLanguages"
                      slot="options"
                      :key="opt.key"
                      :value="opt.key"
                      :disabled="opt.key == payload.lang_3 || opt.key == payload.lang_2 || opt.key == payload.lang_1">{{ opt.content }}</option>
              <div v-show="errors.has('lang_3')" slot="errors" class="input-helper red-text left-align">
                {{ errors.first('lang_3') }}
              </div>
            </md-select>

            <div class="btn-holder">
              <strong class="remove-lang uppercase" @click.prevent="removeLang(3)">
                {{ $translate('app.remove') }}
              </strong>
            </div>

          </div>
          <!-- Add Lang -->
          <div class="btn-holder">
            <strong
              v-if="showAddLang"
              class="add-lang right-align"
              @click.prevent="incrementLang2show"
            >+ {{ $translate('details.add_language') }}
            </strong>
          </div>

          <!-- Interests -->
          <md-textarea
            v-model="payload.interests"
            type="multiline"
            class="textfield-box-lg"
            :maxlength="interestsMaxLength"
            :label="$translate('profile.interests')"
            data-test="personality-interests"
          >
            <div class="input-helper">
              <span class="right">{{ interestsLength }}</span>
              <div
                v-if="errors.has('interests')"
                data-test="personality-interests-error"
                class="red-text left-align"
              >
                {{ errors.first('interests') }}
              </div>
            </div>
          </md-textarea>
          <!-- Profession -->
          <md-select
            v-model="payload.profession"
            :force-mobile="true"
            desktop-display-mode="split"
            class="textfield-box-lg"
            :label="$translate('register.personality.intellect.profession_label')"
            :placeholder="$translate('details.job')"
            :default="$translate('register.personality.intellect.select_profession')">
            <option v-for="(opt, i) in optsProfession" :key="`opts-profession-${i}`" slot="options" :value="opt.key">{{ opt.content }}</option>
            <div v-show="errors.has('profession')" slot="errors" class="input-helper red-text left-align">
              {{ errors.first('profession') }}
            </div>
          </md-select>

          <div class="padding-bottom-xl hide-on-small-only" />

          <register-navigator-v2
            :next-disabled="isCTADisabled"
            :total="total"
            :current="current"
            @prev.prevent="gotoPrev"
            @next.prevent="submitForm"
          />

        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { isEmpty } from '@/constants/helpers.js'
const { submitField } = require('@/services/RegisterService').default
const { getLocale } = require('@/services/LocalizationService').default
const { isValidTextInput } = require('@/features/core/validators/inputValidator.js').default

const INTERESTS_MAX_LENGTH = 60

export default {
  name: 'RegisterPersonalityIntellect',
  props: ['total', 'current', 'stepperLabel'],
  data () {
    return {
      interestsMaxLength: INTERESTS_MAX_LENGTH,
      optsProfession: [],
      optsLanguages: [],
      lang2show: 2,
      payload: {
        lang_1: '',
        lang_2: '',
        lang_3: '',
        interests: '',
        profession: ''
      },
      showSecondLangDefault: true
    }
  },
  computed: {
    isCTADisabled () {
      return isEmpty(this.payload.lang_1) || isEmpty(this.payload.interests) || isEmpty(this.payload.profession)
    },
    dropdown () {
      return this.$store.getters.getDropdown
    },
    showSecondLang () {
      return this.lang2show >= 2
    },
    showThirdLang () {
      return this.lang2show >= 3
    },
    showAddLang () {
      return this.lang2show <= 2
    },
    interestsLength () {
      if (!this.payload.interests) {
        return `0/${this.interestsMaxLength}`
      } else {
        return `${this.payload.interests.length}/${this.interestsMaxLength}`
      }
    },
    user () {
      return this.$store.getters.getUser
    },
    isValidForm () {
      // if (this.showSecondLang && !this.payload.lang_2) return false;
      if (this.showThirdLang && !this.payload.lang_3) return false

      return this.payload.lang_1 && isValidTextInput(this.payload.interests) && this.payload.profession
    }
  },
  watch: {
    dropdown (newval, oldval) {
      this.initDropdown()
    },
    user (newval) {
      if (newval.profiles.lang_2) {
        this.lang2show = 2
      }
      if (newval.profiles.lang_3) {
        this.lang2show = 3
      }
    }
  },
  mounted () {
    this.initDropdown()
    this.initUser()
  },
  methods: {
    reInitiateOptions () {
      this.$root.$emit('parent-reinitiate-options')
    },
    gotoPrev () {
      this.$router.push({ name: 'register-overview' })
    },
    scrollDown () {
      if (this.FEATURE_APV_REG_REDESIGN) {
        return
      }

      /** We can deprecate this method as it causes problem on mobile for the new design */
      setTimeout(() => {
        window.scrollTo({ top: 1000, left: 0 })
      }, 300)
    },
    submitForm () {
      this.errors.clear()

      if (this.getErrors()) {
        return false
      }

      var payload = {
        languages: [
          (this.payload.lang_1) ? this.payload.lang_1 : null,
          (this.payload.lang_2) ? this.payload.lang_2 : null,
          (this.payload.lang_3) ? this.payload.lang_3 : null
        ],
        interests: this.payload.interests,
        profession: this.payload.profession
      }

      submitField('personality/intellect', payload)
        .then((res) => {
          this.$store.commit('setUser', res.data)
          this.$router.push({ name: 'register-personality', params: { type: 'taste' } })
        })
    },
    getErrors () {
      if (this.isValidForm) {
        return false
      }

      if (!this.payload.lang_1) {
        this.errors.add({
          field: 'lang_1',
          msg: this.$translate('errors.select', {
            field: this.$translate('register.personality.intellect.first_language')
          })
        })
      }

      // if (this.showSecondLang && !this.payload.lang_2) {
      //     this.errors.add('lang_2', this.$translate('errors.select', {
      //         field: this.$translate('register.personality.intellect.second_language')
      //     }));
      // }

      if (this.showThirdLang && !this.payload.lang_3) {
        this.errors.add({
          field: 'lang_3',
          msg: this.$translate('errors.select', {
            field: this.$translate('register.personality.intellect.third_language')
          })
        })
      }

      if (!isValidTextInput(this.payload.interests)) {
        const fieldErrorMsg = this.$translate('errors.input2', {
          field: this.$translate('register.personality.intellect.interests')
        })

        this.errors.add({
          field: 'interests',
          msg: fieldErrorMsg
        })
      }

      if (!this.payload.profession) {
        this.errors.add({
          field: 'profession',
          msg: this.$translate('errors.select', {
            field: this.$translate('register.personality.intellect.profession')
          })
        })
      }

      return true
    },
    initUser () {
      if (!this.user) return

      this.payload.lang_1 = this.user.profiles.lang_1 ? this.user.profiles.lang_1.short : null
      this.payload.lang_2 = this.user.profiles.lang_2 ? this.user.profiles.lang_2.short : null
      this.payload.lang_3 = this.user.profiles.lang_3 ? this.user.profiles.lang_3.short : null
      this.payload.interests = this.parseInterests()
      this.payload.profession = this.user.profiles.profession

      if (this.user.profiles.lang_2) {
        this.lang2show = 2
      }
      if (this.user.profiles.lang_3) {
        this.lang2show = 3
      }
    },
    parseInterests () {
      const isLocaleDe = getLocale('legacy') === 'de'

      if (isLocaleDe && this.user.profiles.interests_de) return this.user.profiles.interests_de
      return this.user.profiles.interests
    },
    initDropdown () {
      if (!this.dropdown) return
      this.optsLanguages = $h.obj2arr(this.dropdown.language)
      this.optsProfession = $h.obj2arr(this.dropdown.profession)
    },
    setPayloadProp (key, val) {
      if (val === undefined) val = null
      this.payload[key] = val
    },
    incrementLang2show () {
      this.reInitiateOptions()
      if (this.lang2show < 3) this.lang2show++
    },
    decrementLang2show () {
      if (this.lang2show > 1) this.lang2show--
    },
    removeLang (index) {
      if (index === 2) {
        this.showSecondLangDefault = false
      }
      if (index === 2 && this.payload.lang_3) {
        this.payload.lang_2 = this.payload.lang_3
        index = 3
      }

      var key = 'lang_' + index

      this.setPayloadProp(key)
      this.decrementLang2show()
      this.reInitiateOptions()
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn-holder {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .remove-lang, .add-lang {
    font-size: 12px;
    cursor: pointer;
    padding: 16px 0;

    @media screen and (min-width: 992px) {
      font-size: 14px;
    }
  }

  .add-lang {
    color: #DC0064;
  }

  .remove-lang {
    color: #52575B;
  }

  .remove-bottom-space {
    margin-bottom: 0;
    padding-bottom: 0;
  }
</style>
