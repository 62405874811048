<template>
  <div id="private-place-setting" class="wrapper">
    <md-new-navbar :route-name="getRedirectBackRouteName" />
    <div id="content" class="container">
      <h1 class="title">{{ $translate('private-place.settings.private_place_setting_title') }}</h1>
      <p ref="description" class="description" v-html="$translate('private-place.settings.link_to_faq')" />
      <div class="switch-input margin-bottom-md">
        <span>{{ $translate('private-place.settings.label') }}</span>
        <div class="switch switch-solid">
          <label>
            <input
              id="switch"
              v-model="isEnabled"
              type="checkbox"
              :disabled="isRequesting"
            >
            <span class="lever" />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { UpdateKryptonPrivatePlaceSetting } from '@/features/inbox/utils/KrptonConnectAPI'

export default {
  data: () => ({
    isRequesting: false,
    isEnabledCopy: false,
    isEnabled: false,
    prevRoute: null
  }),
  computed: {
    ...mapGetters({
      privatePlaceEnabled: 'getPrivatePlaceEnabled'
    }),
    getRedirectBackRouteName () {
      return this.prevRoute && this.prevRoute.name === 'faq.private-place' ? 'faq.private-place' : 'setting'
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  watch: {
    isEnabled () {
      if (this.isEnabled === this.isEnabledCopy) return

      if (!this.isEnabled) {
        const mTitle = this.$translate('private-place.settings.deactivation_popup_title')
        const mContent = this.$translate('private-place.settings.deactivation_popup_explanation')
        const confirmText = this.$translate('private-place.settings.deactivation_cta_confirm')
        const onConfirm = this.doDeactivate

        $h.invertedCustomModalConfirm({
          title: mTitle,
          content: mContent,
          cancelText: this.$translate('private-place.settings.cta_cancel'),
          confirmText: confirmText
        }, onConfirm, this.onConfirmationDecline)

        return
      }

      this.doActivate()
    }
  },
  mounted () {
    this.isEnabledCopy = this.isEnabled = this.privatePlaceEnabled

    const vm = this

    if (this.$refs.description) {
      this.$refs.description.querySelector('a')?.addEventListener('click', function (e) {
        e.preventDefault()
        vm.$router.push({ name: 'faq.private-place' })
      })
    }
  },
  methods: {
    onConfirmationDecline () {
      this.isEnabled = !this.isEnabled
    },
    doActivate () {
      this.isRequesting = true
      this.isEnabledCopy = true

      this.$store.commit('setPrivatePlaceEnabled', 1)

      UpdateKryptonPrivatePlaceSetting(1)
        .finally(() => {
          this.isRequesting = false
        })
    },
    doDeactivate () {
      this.isRequesting = true
      this.isEnabledCopy = false

      this.$store.commit('setPrivatePlaceEnabled', 0)

      UpdateKryptonPrivatePlaceSetting(0)
        .finally(() => {
          this.isRequesting = false
        })
    }
  }
}
</script>

<style lang="scss">
@import "./private-place-setting.style";
</style>
