var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "content" } },
    [
      _c("bottomsheet", {
        attrs: { "is-shown": _vm.isShown },
        on: { close: _vm.closeBottomSheet },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("h2", {
                  staticClass: "bottom-sheet__title",
                  domProps: { textContent: _vm._s(_vm.$t("feeSetup.title")) }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "bottom-sheet__description" }, [
                  _c("h3", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("feeSetup.description.title"))
                    }
                  }),
                  _vm._v(" "),
                  _c("p", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("feeSetup.description.content")
                      )
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "fees" },
                  _vm._l(_vm.cities, function(city, key) {
                    return _c(
                      "div",
                      { key: key, staticClass: "fee" },
                      [
                        _c("div", {
                          staticClass: "fee__city",
                          domProps: { textContent: _vm._s(city.name) }
                        }),
                        _vm._v(" "),
                        _c("multi-location-fee-input", {
                          attrs: {
                            city: city.name,
                            "min-value": city.min_cost,
                            "max-value": city.max_cost
                          },
                          model: {
                            value: city.travel_cost,
                            callback: function($$v) {
                              _vm.$set(city, "travel_cost", $$v)
                            },
                            expression: "city.travel_cost"
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c("register-stepper-v2", { attrs: { total: 2, current: 2 } }),
                _vm._v(" "),
                _c("div", { class: _vm.ctaContainerClass }, [
                  _c("a", {
                    staticClass: "cta--edit waves-effect",
                    attrs: { "data-test": "fee-setup__edit" },
                    domProps: { textContent: _vm._s(_vm.feeSetupEdit) },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.edit($event)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("button", {
                    staticClass: "cta--save",
                    class: {
                      disabled: _vm.saving,
                      "waves-effect": !_vm.saving
                    },
                    attrs: { "data-test": "fee-setup__save" },
                    domProps: { textContent: _vm._s(_vm.feeSetupSave) },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.save($event)
                      }
                    }
                  })
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }