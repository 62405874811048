
import { mapGetters } from 'vuex'

import { INBOX_TYPE, INBOX_LIKE_TYPE } from '@/features/inbox/constants'

export default {
  data: () => ({
    MAX_RATING: 5
  }),
  computed: {
    ...mapGetters({
      currentTime: 'getCurrentTime'
    }),
    isKryptonConnectType () {
      return this.type === INBOX_TYPE.KRYPTON_CONNECT
    },
    isReviewType () {
      return this.type === INBOX_TYPE.REVIEW
    },
    isLikeType () {
      return this.type === INBOX_TYPE.LIKE
    },
    isPhotoLikeType () {
      return this.type === INBOX_TYPE.LIKE && this.likeType == INBOX_LIKE_TYPE.PHOTO
    },
    isFavoriteLikeType () {
      return this.type === INBOX_TYPE.LIKE && this.likeType == INBOX_LIKE_TYPE.FAVORITE
    },
    lastUpdate () {
      return this.updatedAt ? this.$moment(this.updatedAt).from(this.currentTime) : ''
    }
  },
  methods: {
    goToType() {
      if (this.isKryptonConnectType || this.isLikeType) {
        return this.onClick()
      }

      if (this.isReviewType && !this.showReview) {
        return this.showHint()
      }

      return this.onClick()
    },
    ratingIconClass (count, compare) {
      const diff = compare - count

      if (diff >= 0) {
        return { 'mdi-circle': true }
      } else if (diff > -1 && diff < 0) {
        return { 'mdi-circle-half-full': true }
      } else {
        return { 'mdi-circle-outline': true }
      }
    },
    onClick () {
      if (this.isKryptonConnectType || this.isLikeType) {
        this.$router.push({
          name: 'krypton-connect',
          params: { id: this.tokenID }
        })
      } else {
        this.$emit('click', this.tokenID)
      }
    },
    showHint () {
      $h.confirmOnlyModal(this.$translate('feedback.cannot_see_comment_explanation'))
    }
  }
}
