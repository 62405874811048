var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { attrs: { id: "inbox-filter" } },
    _vm._l(_vm.filters, function(filter, idx) {
      return _c(
        "a",
        {
          key: idx,
          staticClass: "waves-effect waves-dark padding-y-sm padding-x-md",
          class: { active: filter.key === _vm.value },
          attrs: { id: filter.key },
          on: {
            click: function($event) {
              return _vm.onClick(filter.key)
            }
          }
        },
        [_c("strong", [_vm._v(_vm._s(filter.text))])]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }