<template>
  <div>
    <forgot-password-desktop
      :email.sync="email"
      :error="error"
      @submit="submit"
    />
    <forgot-password-mobile
      :email.sync="email"
      :error="error"
      @submit="submit"
    />
  </div>
</template>

<script>
import ForgotPasswordDesktop from './forgotPassword-desktop'
import ForgotPasswordMobile from './forgotPassword-mobile'
import ForgotPasswordController from './mixins/forgotpass.controller'

export default {
  components: {
    ForgotPasswordDesktop,
    ForgotPasswordMobile
  },
  mixins: [ForgotPasswordController]
}
</script>
