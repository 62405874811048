import { getAffiliateOverview } from '@/features/affiliate/services/AffiliateService.js'
import { AFFILIATE_OVERVIEW } from '@/features/affiliate/constants.js'

const state = {
  settings: AFFILIATE_OVERVIEW.settings,
  total_rewards: AFFILIATE_OVERVIEW.total_rewards,
  pending_referrals: AFFILIATE_OVERVIEW.pending_referrals,
  successful_referrals: AFFILIATE_OVERVIEW.successful_referrals,
  referral_url: null
}

const getters = {
  getAffiliateSettings (state) {
    return state.settings
  },
  getAffiliateTotalRewards (state) {
    return state.total_rewards
  },
  getAffiliatePendingReferrals (state) {
    return state.pending_referrals
  },
  getAffiliateSuccessfulReferrals (state) {
    return state.successful_referrals
  },
  getAffiliateReferralUrl (state) {
    return state.referral_url
  }
}

const mutations = {
  setAffiliateOverviewValues (state, obj) {
    state.settings = obj?.settings ?? AFFILIATE_OVERVIEW.settings
    state.total_rewards = obj?.total_rewards ?? AFFILIATE_OVERVIEW.total_rewards
    state.pending_referrals = obj?.pending_referrals ?? AFFILIATE_OVERVIEW.pending_referrals
    state.successful_referrals = obj?.successful_referrals ?? AFFILIATE_OVERVIEW.successful_referrals
    state.referral_url = obj?.referral_url ?? null
  },
  setAffiliateReferralUrl (state, value) {
    state.referral_url = value
  }
}

const actions = {
  async fetchAffiliateOverview ({ commit }) {
    try {
      const affiliateOverviewResponse = await getAffiliateOverview()
      commit('setAffiliateOverviewValues', affiliateOverviewResponse?.data)
    } catch (err) {
      console.error(err)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
