<template>
  <div id="news-list-item" :data-test="news.dataTestName">
    <div class="image-wrapper" :class="{'multilocation': isMultiLocationFeature(news) }">
      <img
        :src="news.image"
        data-test="news-item-image"
      >
    </div>
    <p class="left-align margin-bottom-sm">
      <strong class="title" data-test="news-item-title">{{ getTitle(news.item) }}</strong>
    </p>
    <p
      class="content left-align margin-top-none margin-bottom-none"
      data-test="news-item-description"
    >
      {{ getDescription(news.item) }}<br>
    </p>
    <p class="right-align margin-top-none">
      <a
        class="router-link waves-effect waves-dark padding-sm"
        :class="{
          'feature-lock-indicator': news.disabled,
        }"
        data-test="news-item-cta"
        @click.prevent="news.onclick"
      >
        <strong>{{ getCta(news) }}</strong>
      </a>
    </p>
  </div>
</template>

<script>
import NewsListItemController from './NewsListItem.controller'

export default {
  mixins: [NewsListItemController],
  props: {
    news: {
      /**
       * @property {model/News} news
      */
      type: Object, required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
#news-list-item {
  position: relative;

  .image-wrapper {
    height: 152px;
    overflow: hidden;
    position: relative;
    border-radius: 4px;

    img {
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .image-wrapper.multilocation {
    height: auto;

    img {
      position: inherit;
    }
  }
  .title {
    font-size: 18px;
  }
  .content {
    color: #2E353D;
    font-size: 16px
  }
  a {
    color: #CC1467;
  }

  // class name can be deleted during fflag removal
  a.new-link {
    color: #E30074;
  }
}

#news-list-item a.feature-lock-indicator {
  color: #E30074;
}
</style>
