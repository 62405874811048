<template>
  <div id="login">
    <div id="content" class="container">
      <div v-if="validationError" id="resetpassword-error">
        <div class="padding-top-lg">
          <div class="grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align">
            <small>{{ validationError }}</small>
          </div>
        </div>
        <a href="#" @click.prevent="$router.push({ name: 'forgot-password' })">{{ $translate('login.forgot_password') }}</a>
      </div>
      <div v-if="validationError != null && !validationError" id="resetpassword-form">
        <img src="/img/icons/kr-logo-144.png" width="48" style="border-radius: 4px;">
        <div id="header">
          <div class="text-darken-1" style="line-height: 36px">{{ $translate('forgotPassword.reset_header_line_1') }}</div>
          <div class="text-darken-1">{{ $translate('forgotPassword.reset_header_line_2') }}</div>
        </div>
        <vform @submit="submit">
          <div v-if="error" id="resetpassword-error-msg" class="grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align">
            <small>{{ error }}</small>
          </div>
          <md-input
            id="resetpassword-password-new"
            v-model="password"
            type="password"
            class="textfield-box-lg"
            autocomplete="password"
            tabindex="2"
            :label="$translate('forgotPassword.password')" />
          <md-input
            id="resetpassword-password-new-confirmation"
            v-model="passwordConfirmation"
            type="password"
            class="textfield-box-lg"
            autocomplete="password"
            tabindex="2"
            :label="$translate('forgotPassword.passwordConfirmation')" />
          <button
            id="resetpassword-submit-btn"
            type="submit"
            tabindex="3"
            class="btn btn-raised btn-large waves-effect waves-light display-block">
            {{ $translate('forgotPassword.continue') }}
          </button>
        </vform>
      </div>
    </div>
  </div>
</template>

<script>
const AuthProvider = $h.getProvider('AuthProvider')

export default {
  data () {
    return {
      next: null,
      error: null,
      password: null,
      passwordConfirmation: null,
      validationError: null
    }
  },
  mounted () {
    AuthProvider.forgotPasswordValidate({
      email: this.$route.query.email,
      token: this.$route.query.token
    })
      .then((data) => {
        this.validationError = false
      })
      .catch((err) => {
        this.validationError = err.message
      })
  },
  methods: {
    submit (next) {
      this.next = next
      this.error = null

      AuthProvider.forgotPasswordReset({
        email: this.$route.query.email,
        token: this.$route.query.token,
        password: this.password,
        password_confirmation: this.passwordConfirmation
      })
        .then((data) => {
          this.$router.replace({ name: 'login', query: { 'reset-password': true } })
        })
        .catch((err) => {
          if (err.data.password) {
            this.error = `${this.error ? `${this.error}, ` : ''}${err.data.password[0]}`
          }
          if (err.data.password_confirmation) {
            this.error = `${this.error ? `${this.error}, ` : ''}${err.data.password_confirmation[0]}`
          }
          if (err.message) {
            this.error = `${this.error ? `${this.error}, ` : ''}${err.message}`
          }
        })
        .finally(() => {
          next()
        })

      setTimeout(() => {
        next()
      }, 15000)
    }
  }
}
</script>
