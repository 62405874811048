var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lesson-screen", attrs: { id: "lesson-photos-bad" } },
    [
      _c("div", { staticClass: "wrapper" }, [
        _c(
          "div",
          { staticClass: "content__drawer content__drawer-filled" },
          [
            _c("div", { staticClass: "content lesson-screen--container" }, [
              _c(
                "div",
                { staticClass: "lesson-screen--content" },
                [
                  _c("div", { staticClass: "lesson-screen--header" }, [
                    _c("div", { staticClass: "photos-remark--container" }, [
                      _c("div", { staticClass: "photos-remark--content" }, [
                        _c("i", { staticClass: "material-icons bad-photo" }, [
                          _vm._v("thumb_down")
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("bad_photos")))])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "examples-text--container" }, [
                      _vm._v(_vm._s(_vm.$t("examples")))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("photo-samples", { attrs: { photos: _vm.BAD_PHOTOS } })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("navigator", {
              attrs: {
                current: _vm.currentPage,
                "no-prev": "",
                next: _vm.$t("messages.globals.continue"),
                "next-class": "action-btn__black",
                total: _vm.totalPages,
                "step-class": "--purple"
              },
              on: {
                next: function($event) {
                  $event.preventDefault()
                  return _vm.gotoNextLessonScreen($event)
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }