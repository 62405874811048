<template>
  <div id="register-new">
    <div id="registration-complete-mobile" class="wrapper">
      <div class="content__area" :class="getSuccessClass">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <h2
          class="content__title content__title--desktop"
          v-html="$translate('register.new_complete.title')"
        />
      </div>

      <div class="content__area drawer max-height-mobile">
        <div class="content center-align">
          <h2
            class="content__title content__title--mobile"
            v-html="getTitle"
          />

          <div class="drawer-icon">
            <img src="/img/registration/success/apv-preview-new.png" style="max-width: 205px;" alt="Registration Success">
          </div>

          <p class="text">{{ $translate('register.new_complete.content') }}</p>

          <span v-if="A2_3980" href="#" class="missing-link" @click="redirectToManualDownloadPage">{{ $t('existsLine4') }}</span>

          <div class="actions flex-grid space-between">
            <!-- registration success CTA here -->
            <button
              v-if="beforeInstallPromptEvt"
              :disabled="isDownloadCTAClicked"
              class="btn btn-primary btn-verify"
              data-id="registration-complete-cta"
              @click="doGetTheApp">
              {{ $translate('register.new_complete.get_the_app') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/pwa-animation/translations/android.json"></i18n>

<script>
import { mapGetters } from 'vuex'
const logEvent = (evtName, message) => {
  console.log(message)
  const evt = new CustomEvent(evtName, {
    detail: {
      message
    }
  })
  document.getRootNode().dispatchEvent(evt)
}

const AuthProvider = $h.getProvider('AuthProvider')

export default {
  props: {
    data: {
      type: Object,
      default () {
        return {
          isRegistrationComplete: false
        }
      }
    }
  },
  data: () => ({
    isDownloadCTAClicked: false
  }),
  computed: {
    ...mapGetters({
      beforeInstallPromptEvt: 'pwaAnimation/beforeInstallPromptEvt'
    }),
    A2_3980 () {
      return this.$store.getters['featureflag/isEnabled']('A2_3980')
    },
    FEATURE_APV_REDESIGN_A2_2624 () {
      return this.$store.getters['featureflag/isEnabled']('APV_REDESIGN_A2_2624')
    },
    getSuccessClass () {
      return { '--success': this.data?.isRegistrationComplete }
    },
    getTitle () {
      return this.data?.isRegistrationComplete
        ? this.$translate('register.new_complete.title_success')
        : this.$translate('register.new_complete.title')
    },
    isAndroidChrome () {
      return navigator.userAgent.search(/Chrome/i) >= 0 &&
         navigator.userAgent.search(/Android/i) >= 0 &&
         navigator.userAgent.search(/SamsungBrowser/i) === -1
    },
    isIPhoneNonSafari () {
      return navigator.userAgent.search(/iPhone|iPad/i) >= 0 && navigator.userAgent.search(/CriOS/i) >= 0
    }
  },
  methods: {
    setForcePWAAnimation () {
      this.$store.commit('pwaAnimation/setForceShowAndroidAnimation', true)
      this.$store.commit('setShowPWADownloadSuccessScreen', false)
      // this.$store.commit('pwaAnimation/setForceShowAnimation', false)
    },
    redirectToManualDownloadPage () {
      this.$router.push({ name: 'manual-download' })
    },
    async triggerDefferedInstallPrompt () {
      this.$store.commit('pwaAnimation/setForceShowAnimation', false)
      logEvent('pwa-download-screen', 'cta clicked - (trigger install prompt)')
      this.isDownloadCTAClicked = true
      this.beforeInstallPromptEvt?.prompt()

      const { outcome } = await this.beforeInstallPromptEvt.userChoice

      if (outcome === 'accepted') {
        logEvent('pwa-download-screen', 'user accepted from install prompt')
        this.$store.commit('setShowPWADownloadScreen', false)
        this.$store.commit('pwaAnimation/setForceShowAnimation', true)
        this.$store.commit('setShowPWADownloadSuccessScreen', true)
        this.doLogout()
        this.isDownloadCTAClicked = false
      } else {
        logEvent('pwa-download-screen', 'user dismissed install prompt')
        this.isDownloadCTAClicked = false
      }
    },
    doGetTheApp () {
      this.$store.commit('pwaAnimation/setForceShowAnimation', true)
      if (this.beforeInstallPromptEvt && this.beforeInstallPromptEvt.prompt) {
        this.triggerDefferedInstallPrompt()
      } else if (this.isIPhoneNonSafari) {
        this.$store.commit('pwaAnimation/setForceShowSafariBanner', true)
        this.doLogout()
      } else {
        const InstallChromeBanner = require('@/install-chrome-banner.js')

        InstallChromeBanner.init({
          instructionText: this.$translate('app.install_chrome_screen.content'),
          linkText: this.$translate('app.install_chrome_screen.button')
        })

        this.doLogout()
      }
    },
    doLogout () {
      AuthProvider.logout(() => {
        AuthProvider.setAuthorizationHeader()
        this.$store.commit('reset')
        this.$store.commit('billing/reset')
        this.$store.commit('events/RESET')
      })
    }
  }
}
</script>

<style lang="scss" scoped>

#register-new .wrapper  {
  .content__title.content__title--mobile {
    font-size: 38px;
  }

  .content__area:first-of-type.--success {
    background: linear-gradient(123deg, #06D6A0, #FFD600);
  }

  .content__area .text {
    font-size: 18px;
  }

  .missing-link {
    cursor: pointer;
    text-decoration: underline;
    color: #C51162;
  }
}
</style>
