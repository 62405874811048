import { buildParam } from '@/services/ApiService'
export const contactWarningPopupSeenPath = '/v2/contact-warning-popup-seen'

export const putContactWarningPopupSeen = () => {
  const url = buildParam(`${process.env.KRYPTON_PROVIDER_API}${contactWarningPopupSeenPath}`)
  return new Promise((resolve, reject) => {
    window.api.put({ url },
      (success) => { resolve(success.data) },
      (err) => { reject(err.response.data) })
  })
}
