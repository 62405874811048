module.exports = {
  title: 'FAQ',
  events_btn: {
    title: 'Events',
    date_faq: 'Date',
    communication_faq: 'Communication',
    private_place_faq: 'Private Place',
    multi_location_faq: 'Multi City',
    video_date_faq: 'Video Date'
  },
  inbox_btn: {
    title: 'Inbox',
    connect_faq: 'Connect'
  },
  performance_btn: {
    title: 'Performance',
    total_rating: 'Overall Feedback',
    reliability_rating: 'Reliability Scoring',
    client_rating: 'Client Rating',
    super_provider: 'Super Advertiser',
    performance_overview: 'To your overview',
    super_provider_overview: 'To Super Advertiser'
  },
  bonus_program_btn: {
    title: 'Referral bonus',
    affiliate: 'Invite friends'
  },
  communication_faq: {
    title: 'Communication checklist',
    item_1: {
      type: 'check',
      content: 'Speed',
      img: '/img/onboarding-communication/event_details.png'
    },
    item_2: {
      type: 'flash',
      content: 'Quickly respond to requests of clients in your app'
    },
    item_3: {
      type: 'flash',
      content: 'Available means: “I want a meeting. When I receive a request, I can answer it in a short time. ” In a short time nowadays means often less than 30 minutes. If it takes longer, you will often lose the request. For requests that are not for the same day, you will of course have more time.'
    },
    item_4: {
      type: 'check',
      content: 'Always keep your availability up-to-date',
      img: '/img/onboarding-communication/availability_overview.png'
    },
    item_5: {
      type: 'flash',
      content: 'The app gives you maximum flexibility and freedom. Take advantage of this and always enter changes in your availability immediately.'
    },
    item_6: {
      type: 'flash',
      content: 'Keeping your availabilities up-to-date will take you less than two minutes a day and will have a major impact on the number of your requests and your revenue.'
    }
  },
  date_faq: {
    title: 'Checklist for a date',
    item_1: {
      type: 'check',
      content: 'You can find details of the meeting (date, time, location, outfit requests, client information, etc.) in the app.',
      img: '/img/onboarding-date/event_navbar.png'
    },
    item_2: {
      type: 'check',
      content: 'As soon as you see “ready to go” in the app, the client has entered all necessary information. This sometimes happens only one hour before the meeting after the client has arrived. It is recommended that you don’t leave home before you see the green screen.',
      img: '/img/onboarding-date/ready_to_go.png'
    },
    item_3: {
      type: 'check',
      content: 'Receive your fee at the start of the meeting and check for completeness.',
      img: '/img/onboarding-date/accept_request.png'
    },
    item_4: {
      type: 'flash',
      content: 'Some ladies apologize briefly to the toilet in order to check their fee discreetly and not in the presence of the client.',
      img: ''
    },
    item_5: {
      type: 'flash',
      content: 'If your client doesn\'t proactively hand you your fee in the first few minutes, many ladies say something like: "Do you mind if we take care of the formalities right away?"',
      img: ''
    },
    item_6: {
      type: 'flash',
      content: 'Your fee is your responsibility',
      img: ''
    },
    item_7: {
      type: 'check',
      content: 'At the beginning of the meeting, check in using the swipe in the app. Please do not check in before you have met the client.',
      img: '/img/onboarding-date/checkin_slider.png'
    },
    item_8: {
      type: 'check',
      content: 'If you can\'t find your client directly, click on "Where is He?"',
      img: ''
    },
    item_9: {
      type: 'check',
      content: 'After the end of the meeting, check out using again the swipe in the app',
      img: '/img/onboarding-date/checkout_slider.png'
    },
    item_10: {
      type: 'check',
      content: 'If there is an extension, enter it in the app.',
      img: '/img/onboarding-date/hour_extension.png'
    },
    item_11: {
      type: 'check',
      content: 'If you have any questions, contact support by phone or via the contact option in the app.',
      img: '/img/onboarding-date/support_btn.png'
    }
  },
  total_rating_faq: {
    title: 'Overall Feedback',
    intro: 'The Overall Feedback consists of your Reliability Scoring and your Client Feedback and is visible on your profile.',
    tagline: 'The Overall Feedback consists of your Reliability Scoring and your Client Feedback and is visible on your profile.',
    explanation: 'You can improve your Overall Feedback by either improving your Reliability Scoring or getting even more positive feedback from clients.'
  },
  reliability_faq: {
    title: 'Reliability Scoring',
    intro: '<p>The Reliability Scoring indicates whether you respond to requests, how correct your availabilities are and whether you actually attend confirmed meetings.</p><br/><p>The reliability index is a <b>help</b> - advertisers who are reliable have a higher scoring and therefore more requests. Even if the reliability index may annoy you sometimes, please keep in mind that it can help you to stand out positively through an above-average scoring. Furthermore, with a high score you can also become a super advertiser and pay lower fees. Therefore, consider the reliability index as a friendly nuisance.</p>',
    explanation: `
      <p>Do you answer requests from clients before they expire? Are your availabilities correct? Do you not only confirm meetings, but actually meet your clients then?</p>
      <p>If you can answer yes to all three questions, you have a high Reliability Scoring.</p>
      <p>On the other hand, if your Reliability Scoring is low, it is because you often leave requests unanswered, your availabilities are not up to date, or you cancel regularly confirmed meetings.</p>
      <p>As a result, you will receive fewer requests. Most clients prefer to request profiles with a high index, because otherwise the risk of a cancellation seems too high to them.</p>
      <p>You can do the following to improve your Reliability Scoring:</p>
      <ul class="browser-default">
        <li>Answer requests before they expire. You will be notified of new requests via SMS, WhatsApp and email. So make sure that you can be reached on these channels, especially during your availability.</li>
        <li>Keep your availabilities up to date. Using the app, you can update your availability in a few seconds if your planning changes. This is especially important for the same day and the next day.</li>
        <li>Only cancel confirmed meetings in real exceptional cases and with as much lead time as possible.</li>
      </ul>
      <p>Smaller deductions in the scoring from time to time are normal. Optimally, you should always stay above 4.5.</p>
    `
  },
  client_faq: {
    title: 'Client Rating',
    intro: 'The Client Rating indicates how your clients felt about the meetings with you.',
    explanation: `
      <p>Three tips for more and better Client Rating:</p>
      <ul class="browser-default">
        <li>Leave the client a feedback in your app after the meeting. You have 30 minutes for this after each meeting. A short nice comment is usually sufficient and significantly increases the chances that the client will also write you feedback in return.</li>
        <li>Really bad reviews are rare on the platform. They are often the result of very different expectations. Therefore, if you feel at the beginning of the meeting that it just doesn't fit, it might be better to cancel the meeting at the beginning rather than to continue it at any cost.</li>
        <li>Asking helps: If it fits your mood and chemistry you can also tell your client during the meeting that you would be very happy if he wrote you a feedback after the meeting.</li>
      </ul>
    `
  },
  connect_faq: {
    title: 'Krypton Connect',
    intro: 'With Krypton Connect you can exchange short messages with qualified clients and receive more requests 💶',
    explanation1: `
      <p>There is a number of very good clients who wish a short correspondence before the meeting to make sure that it is a good match. The same is true for many advertisers 🥂</p>
      <p>This is what Krypton Connect is used for. Connect can only be used by clients who have previously had successful meetings and have received positive feedback from other advertisers 👍</p>
      <p>If a client is interested in meeting you but wants to clarify a detail in advance, he can send you a message 💬 via the app. You can exchange up to three messages in total.</p>
      <p>Krypton Connect increases your chances of more requests and also gives you the opportunity to ask your client a question in advance 🙋</p>
      <p>The function is free of charge 🆓and can be activated and deactivated at any time. So you can simply test the function first.</p>
    `,
    explanation2_pre: 'Activate Krypton Connect ',
    explanation2_post: 'here',
    explanation3: 'Please note that the use of the function can be restricted if you leave messages from clients unanswered.',
    cta: 'To your settings'
  },
  private_place_faq: {
    title: 'Private Place',
    intro: 'With the Private Place function, you can also receive private requests, i.e. meet clients at their home 🏘️',
    support_cta: 'Support',
    q_and_a: {
      item_1: {
        question: 'What are my advantages when I activate Private Place?',
        answer: 'There are few business trips currently. In addition, many clients have no opportunity to book a hotel. For these clients, Private Place is the only way to organize a meeting at home. It is also the chance for you to receive more requests.'
      },
      item_2: {
        question: 'Which clients can send Private Place requests?',
        answer: 'In order for a client to be able to make a private request, they must either have had successful meetings with other advertisers in the past and have received positive feedback; new clients must first go through additional verification. Otherwise they cannot use the feature. Although this restricts the group of users, it is for your safety.'
      },
      item_3: {
        question: 'How exactly does it work?',
        answer: 'Private Place requests work in the same way as hotel requests. The only difference is that the client gives his private address instead of a hotel when sending the request and the meeting then takes place there. Check-in and check-out work the same as always. You can reach support as usual.'
      },
      item_4: {
        question: 'Can I also reject a Private Place request?',
        answer: 'Of course. As before, you can reject a single request or block the client if you think that it is generally not a good match.'
      },
      item_5: {
        question: 'What do I have to do if I do not want to receive private requests?',
        answer: 'The function is deactivated before you activate it yourself. So you don\'t need to do anything if you don\'t want to receive Private Place requests. '
      },
      item_6: {
        question: 'Can I test the function for some time?',
        answer: 'Of course, you can activate the function for a while to see how you like it and then keep it activated or deactivate it again. There are no costs involved. If you have any further questions, please contact '
      }
    }
  },
  affiliate: {
    title: 'Krypton Referral',
    intro: 'Recommend the platform to friends and earn money with your recommendations.',
    cta: 'To the referral bonus'
  }
}
