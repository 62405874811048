import { ticketsFixture } from '../fixtures/support.js';

const LocaleProvider = $h.getProvider('LocaleProvider');
const SupportProvider = $h.getProvider('SupportProvider');

export const MAX_FILE_COUNT_UPLOAD = 6;

const newSupportForm = {
  category: null,
  subject: null,
  reference_id: null,
  message: '',
  attachments: [],
};

/**
 * Vuex State
*/
const state = {
  tickets: [],
  selectedTicket: null,
  newFileUpload: [],
  newSupportForm: newSupportForm,
  hasUpdates: false,
};

/**
 * State getters
*/
const getters = {
  GET_TICKETS(state) {
    return state.tickets;
  },
  GET_SELECTED_TICKET(state) {
    return state.selectedTicket;
  },
  GET_NEW_FILE_UPLOAD(state) {
    return state.newFileUpload;
  },
  GET_SUPPORT_FORM(state) {
    return state.newSupportForm;
  },
  hasUpdates(state) {
    return state.hasUpdates;
  },
};

/**
 * State mutations
*/
const mutations = {
  /**
   * Set tickets store using fixture data.
   *
   * @param {Object} state
   */
  SET_FIXTURE(state) {
    const newData = ticketsFixture.map((t) => {
      return {
        ...t,

        // Wrap created_at into moment.js object
        _created_at: moment(t.created_at, 'YYYY-MM-DD HH:mm:ss').locale(LocaleProvider.getLocale()),
      };
    });

    state.tickets = newData;
  },

  /**
   * Set tickets store.
   *
   * @param {Object} state
   * @param {Array} tickets
   */
  SET_TICKETS(state, tickets) {
    // const momentOpts = {
    //   relativeTime: {
    //     future: "in %s",
    //     past:   "%s ago",
    //     s  : 'a few seconds',
    //     ss : '%d seconds',
    //     m:  "a minute",
    //     mm: "%d minutes",
    //     h:  "an hour",
    //     hh: "%d hours",
    //     d:  "a day",
    //     dd: "%d days",
    //     M:  "a month",
    //     MM: "%d months",
    //     y:  "a year",
    //     yy: "%d years"
    //   },
    // };

    const newData = tickets.map((t) => {
      return {
        ...t,

        // Wrap created_at into moment.js object
        _created_at: moment(t.created_at, 'YYYY-MM-DD HH:mm:ss').locale(LocaleProvider.getLocale()),
      };
    });

    state.tickets = newData;
  },

  /**
   * Set tickets store.
   *
   * @param {Object} state
   * @param {Array} tickets
   */
  APPEND_TICKETS(state, tickets) {
    // const momentOpts = {
    //   relativeTime: {
    //     future: "in %s",
    //     past:   "%s ago",
    //     s  : 'a few seconds',
    //     ss : '%d seconds',
    //     m:  "a minute",
    //     mm: "%d minutes",
    //     h:  "an hour",
    //     hh: "%d hours",
    //     d:  "a day",
    //     dd: "%d days",
    //     M:  "a month",
    //     MM: "%d months",
    //     y:  "a year",
    //     yy: "%d years"
    //   },
    // };

    const newData = tickets.map((t) => {
      return {
        ...t,

        // Wrap created_at into moment.js object
        _created_at: moment(t.created_at, 'YYYY-MM-DD HH:mm:ss').locale(LocaleProvider.getLocale()),
      };
    });

    state.tickets = state.tickets.concat(newData);
  },
  SET_SELECTED_TICKET(state, ticketID) {
    const idx = state.tickets.findIndex(t => t.id === ticketID);

    state.selectedTicket = state.tickets[idx];
  },
  SET_NEW_FILE_UPLOAD(state, newFileUpload) {
    state.newFileUpload = newFileUpload;
  },
  PUSH_NEW_FILE_UPLOAD(state, newFileUpload) {
    const filteredList = newFileUpload
                            .filter((f) => {
                              const file = state.newFileUpload.find(nf => nf.name === f.name);

                              // Check if file existed on the list
                              return file === undefined;
                            });

    state.newFileUpload = state.newFileUpload.concat(filteredList);

    if (state.newFileUpload.length > MAX_FILE_COUNT_UPLOAD) {
      state.newFileUpload = state.newFileUpload.slice(0, MAX_FILE_COUNT_UPLOAD);
    }
  },
  REMOVE_NEW_FILE_UPLOAD(state, index) {
    state.newFileUpload.splice(index, 1);
  },
  SET_FORM_FIELD(state, form) {
    for (const key in form) {
      if (form.hasOwnProperty(key)) {
        state.form[key] = form[key];
      }
    }
  },
  RESET_FORM_TICKET(state) {
    state.newSupportForm.subject = null;
    state.newSupportForm.reference_id = null;
  },
  RESET_FORM_FIELD(state) {
    state.newSupportForm = Object.assign({}, newSupportForm);
  },
  RESET_TICKETS(state) {
    state.tickets = [];
  },
  SET_HAS_UPDATES(state, value=true) {
    state.hasUpdates = value;
  },
};

/**
 * State actions
*/
const actions = {
  fetchSupportTickets({ commit }, page=1) {
    return new Promise((resolve, reject) => {
      SupportProvider
        .fetchSupportTickets(page)
        .then((res) => {
          // commit('APPEND_TICKETS', res.data.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sendSupportTicket({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SupportProvider
        .sendSupportTicket(payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sendReply({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SupportProvider
        .sendReply(payload.ticketID, payload.message)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sendFile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SupportProvider
        .sendFile(payload.ticketID, payload.file)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  checkNewUpdates({ commit }, payload) {
    return new Promise((resolve, reject) => {
      SupportProvider
        .checkNewUpdates()
        .then((res) => {
          if (res.data.total > 0) {
            commit('SET_HAS_UPDATES');
          } else {
            commit('SET_HAS_UPDATES', false);
          }

          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

///////////////////////////
// Nothing to do here...
///////////////////////////

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
