var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal id-verify-rejected",
      attrs: { id: "verify-id-success-modal" }
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$translate("verify.verification_rejected.title")) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$translate("verify.verification_rejected.explanation_text")
              ) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-grid-column" }, [
          _vm.isFrontIDRejected
            ? _c("div", { staticClass: "flex-1 margin-bottom-sm" }, [
                _c("div", { staticClass: "flex-grid" }, [
                  _c("img", {
                    attrs: { src: "/img/verify/front-id-error.svg" }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "reject-reason flex-2 margin-left-md" },
                    [
                      _c("strong", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$translate(
                                "verify.verification_rejected.reason_front"
                              )
                            ) +
                            "\n            "
                        )
                      ]),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.file_id_front_error) +
                            "\n            "
                        )
                      ])
                    ]
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isBackIDRejected
            ? _c("div", { staticClass: "flex-1" }, [
                _c("div", { staticClass: "flex-grid" }, [
                  _c("img", {
                    attrs: { src: "/img/verify/back-id-error.svg" }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "reject-reason flex-2 margin-left-md" },
                    [
                      _c("strong", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$translate(
                                "verify.verification_rejected.reason_back"
                              )
                            ) +
                            "\n            "
                        )
                      ]),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.file_id_back_error) +
                            "\n            "
                        )
                      ])
                    ]
                  )
                ])
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer" }, [
        _c("button", { staticClass: "new-btn", on: { click: _vm.confirm } }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$translate("verify.verification_rejected.cta_button")
              ) +
              "\n    "
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }