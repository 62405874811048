var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: {
        id: "multi-location-list-item",
        "data-test": "multi-location-promo-card"
      },
      on: { click: _vm.gotoPrivatePlacePromoPage }
    },
    [
      _c("div", { staticClass: "left-panel" }, [
        _c("div", { staticClass: "promo-title" }, [
          _vm._v(_vm._s(_vm.$t("card.title")))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "promo-subtitle" }, [
          _vm._v(_vm._s(_vm.$t("card.subtitle")))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "promo-cta" }, [
          _vm._v(_vm._s(_vm.$t("card.cta")))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "right-panel" }, [
        _c("img", { attrs: { src: _vm.item.image } })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }