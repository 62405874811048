const AuthProvider = $h.getProvider('AuthProvider')

export default {
  methods: {
    submit (next) {
      this.next = next
      this.error = null

      AuthProvider.forgotPassword({
        email: this.email
      })
        .then((data) => {
          this.$router.replace({ name: 'forgot-password-success' })
        })
        .catch((err) => {
          this.error = null
          if (err.data.email) {
            this.error = `${this.error ? `${this.error}, ` : ''}${err.data.email[0]}`
          }
          if (err.message) {
            this.error = `${this.error ? `${this.error}, ` : ''}${err.message}`
          }
        })
        .finally(() => {
          next()
        })

      setTimeout(() => {
        next()
      }, 15000)
    }
  },
  data () {
    return {
      next: null,
      error: null,
      email: null
    }
  }
}
