var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "register" } }, [
    _c("div", { attrs: { id: "complete" } }, [
      _c("img", {
        staticStyle: { "border-radius": "4px" },
        attrs: { src: "/img/icons/kr-logo-144.png", width: "48" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "explanation-title" }, [
        _vm._v(
          _vm._s(
            _vm.$translate(
              "forgotPassword.success_email_sent.explanation_title"
            )
          )
        )
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "explanation-container",
        domProps: {
          innerHTML: _vm._s(
            _vm.$translate("forgotPassword.success_email_sent.explanation_text")
          )
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }