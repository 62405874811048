import { timezoneByCountryCode } from '@/services/TimezoneService'
import { mapGetters } from 'vuex'

// const MINIMUM_PRIME_TIME_START = 17 // in hours
const MINIMUM_PRIME_TIME_START = 16 // in hours - Prime time starts at 4PM
const MINIMUM_PRIME_TIME_DURATION = 3 // in hours
const AVAILABILITY_INDICATOR_PRIME_TIME_START_INDEX = 7 // Index 7 is the 4PM
const SAVE_DELAY = 800 // In milliseconds. Default 800ms
const WEEKDAY_DEFAULT_TIME = [16, 26] // 1600 ~ 0200
const WEEKEND_DEFAULT_TIME = [12, 26] // 1200 ~ 0200
const SLIDER_SETTINGS = {
  animate: false,
  step: 0.5,
  behaviour: 'tap-drag',
  connect: true,
  snap: true,
  range: {
    min: 9, // 9AM
    max: 26 // 2AM
  }
}

const ALLOWANCE_START_TIME = 90
const ALLOWANCE_END_TIME = 210; // 90 mins + 120mins

// Calculate maximum bar count for slider
(function () {
  const maxBars = SLIDER_SETTINGS.range.max - SLIDER_SETTINGS.range.min

  for (let i = 0, s = SLIDER_SETTINGS.range.min; i < maxBars; i += 0.5, s += 0.5) {
    if (i > 0) {
      const percent = `${Number(i / 17 * 100).toFixed(2)}%`
      SLIDER_SETTINGS.range[percent] = s
    }
  }
})()

export default {
  name: 'availability-card',
  props: ['payload', 'idx', 'pkey'],
  data () {
    return {
      MEETING_INDICATOR_STATUS: {
        UNFILLED: 0,
        UNAVAILABLE: 1,
        AVAILABLE: 2,
        HALF_AVAILABLE_1: 3,
        HALF_AVAILABLE_2: 4,
        MEETING: 5,
        HALF_MEETING_1: 6,
        HALF_MEETING_2: 7,
        HALF_MEETING_AVAILABLE_1: 8,
        HALF_MEETING_AVAILABLE_2: 9
      },
      sliderVal: [],
      timeoutID: null,
      prevPayload: '',
      formData: {
        availability_start: '',
        _requests_start: '',
        accept_overnight: 0,
        availability_end: '',
        _requests_end: '',
        not_available: 0,
        out_of_town: 0,
        _city_name: '',
        requests: [],
        city_id: 0,
        date: '',
        id: null
      },
      shouldConfirm: false,
      isSaving: false,
      isToggled: false,
      initial: true,
      lastHandle: null
    }
  },
  methods: {
    getIndicatorPrimeTimeStartIndex (idx) {
      return idx >= AVAILABILITY_INDICATOR_PRIME_TIME_START_INDEX
    },
    modalConfirmCallback () {
      if (this.$parent.currentCard.idx === this.idx) {
        this.setNeedToSave(false)
        this.shouldConfirm = false
        this.revert()
      } else {
        this.$parent.currentCard.shouldConfirm = false
        this.$parent.currentCard.isToggled = false
        this.$parent.currentCard.revert()
      }

      if (this.formData.id || (this.formData.id && !this.formData.not_available)) {
        this.setNeedToSave(false)
      }

      this.$store.commit('availabilities/setLastAvailability', {
        id: this.availabilityID,
        date: this.date
      })

      this.$parent.currentCard = this
      this.isToggled = !this.isToggled

      $('html,body').animate({
        scrollTop: ($(`#${this.availabilityID}`).offset().top - 150)
      }, 300, 'swing')
    },
    animateToTop () {
      $('html,body').animate({
        scrollTop: ($(`#${this.lastAvailability.id}`).offset().top - 150)
      }, 300, 'swing')
    },
    toggle () {
      if (this.needSave) {
        const title = this.$translate('availability.save_confirm_title', {
          date: this.lastAvailability.date
        })
        const content = this.$translate('availability.save_confirm_content', {
          date: this.lastAvailability.date
        })

        this.animateToTop()

        $h.newModalConfirm(title, content, this.modalConfirmCallback)
      } else {
        if (this.$parent.currentCard && this.$parent.currentCard !== null) {
          if (this.$parent.currentCard.idx === this.idx) {
            this.shouldConfirm = false
          } else {
            this.$parent.currentCard.shouldConfirm = false
            this.$parent.currentCard.isToggled = false
          }
        }

        if (!this.payload.id) {
          this.setNeedToSave(true)
        }

        this.$store.commit('availabilities/setLastAvailability', {
          id: this.availabilityID,
          date: this.date
        })
        this.$parent.currentCard = this
        this.isToggled = !this.isToggled
      }
    },
    revert () {
      this.formData = Object.assign({}, JSON.parse(this.prevPayload))

      const opt = {
        start: [
          this.timeStrToNum(this.formData.availability_start),
          this.timeStrToNum(this.formData.availability_end)
        ]
      }

      this.$refs.rangeinput.noUiSlider.updateOptions(opt)
      this.sliderVal = opt.start
    },
    setNeedToSave (val) {
      this.$store.commit('availabilities/setNeedSave', val)
    },
    confirm () {
      if (this.meetings.indexOf(true) >= 0) return
      if (this.isTimeWithinLateNight && this.formData.not_available == 1) return
      if (this.formData.id) {
        if (this.formData.not_available == 1) {
          this.shouldConfirm = true
          this.setNeedToSave(true)
        } else {
          this.shouldConfirm = false
        }
      }
      this.formData.not_available = this.formData.not_available == 0 ? 1 : 0

      let opt = {}
      if (this.isToday && this.formData.not_available === 0) {
        const { start, end } = this.timeRangeCheck({
          start: this.timeStrToNum(this.formData.availability_start),
          end: this.timeStrToNum(this.formData.availability_end)
        }, SLIDER_SETTINGS.range)

        opt = {
          start: this.getAvailableAgainMinAllowedTime({ start, end })
        }

        this.sliderVal = opt.start
        this.formData.availability_start = this.timeParse(opt.start[0].toFixed(2).toString())
        this.formData.availability_end = this.timeParse(opt.start[1].toFixed(2).toString())

        this.$refs.rangeinput.noUiSlider.set(opt.start)

        if (this.isToday && this.isTimeWithinLateNight) {
          setTimeout(() => {
            this.$refs.rangeinput.setAttribute('disabled', true)
          }, 100)
        }
      }

      if (this.isToday && this.formData.not_available === 1) {
        const { start, end } = this.timeRangeCheck({
          start: this.timeStrToNum(this.formData.availability_start),
          end: this.timeStrToNum(this.formData.availability_end)
        }, SLIDER_SETTINGS.range)

        const currentTime = this.getMinAllowedTime()

        if (start <= currentTime && currentTime <= end) {
          opt = {
            start: this.getAwayEndTimeRelativeToCurrentTime({ start, end })
          }

          this.sliderVal = opt.start
          this.formData.availability_start = this.timeParse(opt.start[0].toFixed(2).toString())
          this.formData.availability_end = this.timeParse(opt.start[1].toFixed(2).toString())

          this.$refs.rangeinput.noUiSlider.set(opt.start)
        }
      }
    },
    timeStrToNum (timeStr) {
      if (!timeStr) return

      const val = timeStr.split(':')
      const hr = Number(val[0]) < 8 ? Number(val[0]) + 24 : Number(val[0])
      const min = Number(val[1]) ? 0.5 : 0.0

      return hr + min
    },
    timeParse (timeStr) {
      if (!timeStr) return

      const val = timeStr.split('.')
      const hr = Number(val[0])
      const min = Number(`.${val[1]}`)

      const timeString = this.convertSliderToTimestring(hr, min)
      return this.formatToHHmm(timeString.hour, timeString.minute)
    },
    formatToHHmm (hour = 0, second = 0) {
      const formattedHour = hour.toLocaleString('en-US', {
        minimumIntegerDigits: 2
      })
      const formattedSecond = second.toLocaleString('en-US', {
        minimumIntegerDigits: 2
      })

      return `${formattedHour}:${formattedSecond}`
    },
    convertSliderToTimestring (hr, min) {
      return { hour: hr % 24, minute: (min >= 0.5) ? 30 : 0 }
    },
    onSliderSet (values, handle) {
      if (this.$refs.rangeinput.getAttribute('disabled')) return
      const newTime = this.timeRangeCheck({ start: values[0], end: values[1] }, SLIDER_SETTINGS.range)
      let newVal = [newTime.start.toString(), newTime.end.toString()]
      let minAllowedStartTime = this.getMinAllowedTime(new Date(), ALLOWANCE_START_TIME)
      const minAllowedEndTime = this.getMinAllowedTime(new Date(), ALLOWANCE_END_TIME)

      if (this.isToday &&
        handle === 0 &&
        newTime.start < minAllowedStartTime
      ) {
        if (minAllowedStartTime > SLIDER_SETTINGS.range.max - 2) {
          minAllowedStartTime = 24
        }

        newVal = [minAllowedStartTime.toString(), values[1]]

        this.sliderVal = newVal
        this.formData.availability_start = this.timeParse(newVal[0])
        this.formData.availability_end = this.timeParse(newVal[1])

        // needed to reset the update callback, to avoid maximum call stack
        this.$refs.rangeinput.noUiSlider.off('slide')
        this.$refs.rangeinput.noUiSlider.set([minAllowedStartTime, newTime.end])
        this.$refs.rangeinput.noUiSlider.on('slide', this.onSliderSet)

        return
      }

      if (this.isToday &&
        handle === 1 &&
        newTime.end < minAllowedEndTime
      ) {
        newVal = [values[0], minAllowedEndTime.toString()]

        this.sliderVal = newVal
        this.formData.availability_start = this.timeParse(newVal[0])
        this.formData.availability_end = this.timeParse(newVal[1])

        // needed to reset the update callback, to avoid maximum call stack
        this.$refs.rangeinput.noUiSlider.off('slide')
        this.$refs.rangeinput.noUiSlider.set([newTime.start, minAllowedEndTime])
        this.$refs.rangeinput.noUiSlider.on('slide', this.onSliderSet)
      }
    },
    onSliderChange (values, handle) {
      if (this.$refs.rangeinput.getAttribute('disabled')) return

      const newTime = this.timeRangeCheck({ start: values[0], end: values[1] }, SLIDER_SETTINGS.range)
      let newVal = [newTime.start.toString(), newTime.end.toString()]
      const minAllowedStartTime = this.getMinAllowedTime(new Date(), ALLOWANCE_START_TIME)
      const minAllowedEndTime = this.getMinAllowedTime(new Date(), ALLOWANCE_END_TIME)

      if (this.isToday && handle === 0 && newTime.start < minAllowedStartTime) {
        return
      }

      if (this.isToday && handle === 1 && newTime.end < minAllowedEndTime) {
        return
      }

      if (!this.isTimeDiffMoreThanMinHr(newTime, 2)) {
        if (handle === 0) {
          const correctVal = Number(values[0]) - 0.5

          if (correctVal < SLIDER_SETTINGS.range.min) {
            this.lastHandle = 1
            return
          }

          this.lastHandle = 0
          this.$refs.rangeinput.noUiSlider.set(correctVal)
        } else if (handle === 1) {
          const correctVal = Number(values[1]) + 0.5

          if (correctVal > SLIDER_SETTINGS.range.max) {
            this.lastHandle = 0
            return
          }

          this.lastHandle = 1
          this.$refs.rangeinput.noUiSlider.set([null, correctVal])
        }
      } else {
        if (handle === 0) {
          const reqStart = this.timeStrToNum(this.payload._requests_start)
          if (this.isOverlappingMeetingTime({ start: Number(values[handle]) }, {
            start: reqStart
          })) {
            newVal = [reqStart.toFixed(2).toString(), values[1]]
            this.$refs.rangeinput.noUiSlider.set(reqStart)
          }
        } else if (handle === 1) {
          const reqEnd = this.timeStrToNum(this.payload._requests_end)

          if (this.isOverlappingMeetingTime({ end: Number(values[handle]) }, {
            end: reqEnd
          })) {
            newVal = [values[0], reqEnd.toFixed(2).toString()]
            this.$refs.rangeinput.noUiSlider.set([null, reqEnd])
          }
        }

        this.sliderVal = newVal
        this.formData.availability_start = this.timeParse(newVal[0])
        this.formData.availability_end = this.timeParse(newVal[1])
      }
    },
    initialize () {
      // set timezone
      if (this.isToday) {
        this.$moment.tz.setDefault(timezoneByCountryCode(this.homeBaseCountry))
      }

      let opt = {}

      this.formData = Object.assign({}, this.payload)
      this.formData.city_id = null

      if (this.formData.id === null) {
        const day = this.$moment(this.$props.idx).day()

        opt = this.setDefaultTime(day)

        this.formData.id = 0
        this.formData.accept_overnight = 0
        this.formData.not_available = 0
        this.formData.availability_start = opt.start[0].toFixed(2).toString().replace('.', ':')
        this.formData.availability_end = opt.start[1].toFixed(2).toString().replace('.', ':')

        if (this.isToday && this.isTimeWithinLateNight) {
          this.$refs.rangeinput.setAttribute('disabled', true)
        }
      } else {
        const { start, end } = this.timeRangeCheck({
          start: this.timeStrToNum(this.formData.availability_start),
          end: this.timeStrToNum(this.formData.availability_end)
        }, SLIDER_SETTINGS.range)

        if (this.isToggled && !this.isSaving &&
                  !this.shouldConfirm && this.formData.id > 0 &&
                  (this.$parent.currentCard && this.$parent.currentCard.payload.id !== this.formData.id)) {
          this.isToggled = false
          this.setNeedToSave(false)
        }
        opt = { start: [start, end] }

        this.formData.city_id = this.payload.city_id

        if (this.formData.city_id === this.user.profiles.city_id) {
          this.formData.city_id = null
        }
      }

      // For the Slider
      if (this.$refs.rangeinput && this.$refs.rangeinput.noUiSlider) {
        this.$refs.rangeinput.noUiSlider.updateOptions(Object.assign({}, SLIDER_SETTINGS, opt))
      } else {
        noUiSlider.create(
          this.$refs.rangeinput,
          Object.assign({}, SLIDER_SETTINGS, opt)
        )
      }

      if (this.formData.id && this.formData.not_available && !this.shouldConfirm) {
        this.$refs.rangeinput.setAttribute('disabled', true)
      }

      // disable slider if current time is late night (curent time + 90mins = 00:00 up to 02:00)
      if (this.isToday && this.isTimeWithinLateNight) {
        this.$refs.rangeinput.setAttribute('disabled', true)
      }

      this.prevPayload = JSON.stringify(this.formData)
      this.$refs.rangeinput.noUiSlider.on('update', this.onSliderChange)
      this.$refs.rangeinput.noUiSlider.on('slide', this.onSliderSet)
      this.sliderVal = opt.start
      this.initial = false
    },
    isMeetingSet (time) {
      return this.requestTimeTable
        .some(request => time >= request.start && time < request.end)
    },
    getMeetingSet (time) {
      return this.requestTimeTable.find(request => time >= request.start && time < request.end)
    },
    update () {
      const payload = {
        ...this.formData,
        out_of_town: 0,
        not_available: Number(this.formData.not_available)
      }

      this.isSaving = true

      this.$store
        .dispatch('availabilities/updateAvailability', payload)
        .then((res) => {
          this.formData.id = res.id
          this.payload.id = res.id
          this.prevPayload = JSON.stringify(res)
        })
        .catch((err) => {
          console.log(err)
          this.$notifications.toast(this.$translate('availability.error'))
        })
        .finally(() => {
          this.isSaving = false
          this.shouldConfirm = false
          this.setNeedToSave(false)
          clearTimeout(this.timeoutID)
          this.timeoutID = null
        })
    },
    save () {
      if (this.formData.id) {
        this.update()
        return
      }
      const payload = {
        ...this.formData,
        out_of_town: 0,
        not_available: Number(this.formData.not_available)
      }

      this.isSaving = true
      this.$store
        .dispatch('availabilities/saveAvailability', payload)
        .then((res) => {
          this.formData.id = res.id
          this.payload.id = res.id
          this.prevPayload = JSON.stringify(res)

          this.$store.dispatch('availabilities/refreshAvailabilities', false)
        })
        .catch((err) => {
          console.log(err)
          this.$notifications.toast(this.$translate('availability.error'))
        })
        .finally(() => {
          this.shouldConfirm = false
          this.isSaving = false
          this.setNeedToSave(false)
          clearTimeout(this.timeoutID)
          this.timeoutID = null
        })
    },
    timeRangeCheck (time, rangeLimit) {
      let { start, end } = time
      start = parseFloat(start)
      end = parseFloat(end)

      // Prevent end time to be lesser than the start time
      if (end < start) {
        end = rangeLimit.max
      }
      if (start < rangeLimit.min) {
        start = rangeLimit.min
      }
      if (end > rangeLimit.max) {
        end = rangeLimit.max
      }

      return { start, end }
    },
    checkWithinPrimeTime (timeArray, primetimeOpts) {
      if (timeArray.length === 0) return false

      const startTime = Number(timeArray[0])
      const endTime = Number(timeArray[1])

      let timeDiff = 0

      if (startTime >= primetimeOpts.startTime) {
        timeDiff = endTime - startTime
      } else if (startTime < primetimeOpts.startTime && endTime >= primetimeOpts.startTime) {
        timeDiff = endTime - primetimeOpts.startTime
      }

      return ((startTime >= primetimeOpts.startTime || endTime >= primetimeOpts.startTime) && timeDiff >= primetimeOpts.duration)
    },
    isOverlappingMeetingTime (time, requestStartEnd) {
      if (time.start) {
        return time.start > requestStartEnd.start
      }
      if (time.end) {
        return time.end < requestStartEnd.end
      }
    },
    isTimeDiffMoreThanMinHr (time, minHr) {
      return (time.end - time.start) >= minHr
    },
    setDefaultTime (day) {
      let weekendStartTime = WEEKEND_DEFAULT_TIME
      let weekdayStartTime = WEEKDAY_DEFAULT_TIME

      if (this.isToday) {
        const minAllowedStartTime = this.getMinAllowedTime(new Date(), ALLOWANCE_START_TIME)
        weekendStartTime = [minAllowedStartTime, WEEKEND_DEFAULT_TIME[1]]
        weekdayStartTime = [minAllowedStartTime, WEEKDAY_DEFAULT_TIME[1]]

        if (minAllowedStartTime < SLIDER_SETTINGS.range.min) {
          weekendStartTime = [SLIDER_SETTINGS.range.min, WEEKEND_DEFAULT_TIME[1]]
          weekdayStartTime = [SLIDER_SETTINGS.range.min, WEEKDAY_DEFAULT_TIME[1]]
        }

        if (minAllowedStartTime > SLIDER_SETTINGS.range.max - 2) {
          weekendStartTime = [24, WEEKEND_DEFAULT_TIME[1]]
          weekdayStartTime = [24, WEEKDAY_DEFAULT_TIME[1]]
        }
      }

      switch (day) {
        case DAYS.Sunday: // Sunday
        case DAYS.Saturday: // Saturday
          return { start: weekendStartTime }
        default:
          return { start: weekdayStartTime }
      }
    },
    getAvailableAgainMinAllowedTime ({ start, end }) {
      const currentTime = this.getMinAllowedTime()
      const minAllowedStartTime = this.getMinAllowedTime(new Date(), ALLOWANCE_START_TIME)
      let allowedStart, allowedEnd

      if (this.isToday && this.withinRange(currentTime)) {
        if (minAllowedStartTime < 24) {
          allowedStart = minAllowedStartTime <= SLIDER_SETTINGS.range.min
            ? start
            : minAllowedStartTime
          allowedEnd = SLIDER_SETTINGS.range.max
        } else {
          allowedStart = SLIDER_SETTINGS.range.max - 2
          allowedEnd = SLIDER_SETTINGS.range.max
        }
      }

      return [allowedStart, allowedEnd]
    },
    getAwayEndTimeRelativeToCurrentTime ({ start, end }) {
      const minAllowedEndTime = this.getMinAllowedTime()
      const offset = 2
      let allowedEnd

      if (minAllowedEndTime < start + offset) {
        allowedEnd = start + offset
      } else {
        allowedEnd = minAllowedEndTime > SLIDER_SETTINGS.range.max - offset ? end : minAllowedEndTime
      }

      return [start, allowedEnd]
    },
    getMinAllowedTime (date = new Date(), addMin = 0) {
      const start = this.$moment(date)
      const minTime = start.add({ minutes: addMin })
      const remainder = 30 - (minTime.minutes() % 30)
      const minAllowedTime = remainder === 30
        ? this.$moment(minTime)
        : this.$moment(minTime).add({ minutes: remainder })

      const hours = this.$moment.duration({
        hours: minAllowedTime.hours(),
        minutes: minAllowedTime.minutes()
      }).asHours()

      const allowedTime = hours <= 2 ? hours + 24 : hours

      return allowedTime
    },
    withinRange (value) {
      return value >= SLIDER_SETTINGS.range.min &&
        value <= SLIDER_SETTINGS.range.max
    }
  },
  watch: {
    'formData.not_available': function (newVal, oldVal) {
      if (newVal == 1) {
        this.$refs.rangeinput.setAttribute('disabled', true)
      } else {
        this.$refs.rangeinput.removeAttribute('disabled')
      }
    },
    shouldConfirm: function (newVal, oldVal) {
      if (newVal) {
        this.$refs.rangeinput.removeAttribute('disabled')
      } else if (!oldVal && newVal) {
        this.$refs.rangeinput.setAttribute('disabled', true)
      }
    },
    formData: {
      handler: function (newVal, oldVal) {
        if (this.timeoutID) {
          clearTimeout(this.timeoutID)
        }

        if (!this.isToggled || JSON.stringify(newVal) === this.prevPayload || this.shouldConfirm) return

        // Trigger auto-save on every change...
        if (this.formData.id) {
          this.timeoutID = setTimeout(() => {
            this.update()
          }, SAVE_DELAY)
        }
      },
      deep: true
    },
    payload: function (newVal, oldVal) {
      if (newVal.date !== oldVal.date && this.isToggled) {
        this.shouldConfirm = false
        this.setNeedToSave(false)
        this.isToggled = false
      }
      if (newVal.id !== oldVal.id) {
        this.initialize()
      }
    }
  },
  mounted () {
    this.initialize()
  },
  computed: {
    ...mapGetters({
      homeBaseCountry: 'getHomeBaseCountry'
    }),
    isInPrimeTime () {
      const primeTimeStart = MINIMUM_PRIME_TIME_START
      return this.checkWithinPrimeTime(this.sliderVal, {
        startTime: primeTimeStart,
        duration: MINIMUM_PRIME_TIME_DURATION
      })
    },
    availabilityID () {
      return `availability-${this.$props.idx}`
    },
    date () {
      const today = this.$moment(this.$moment(new Date()))
      const payloadDate = this.$moment(this.$props.idx)
      const day = this.$translate(`availability.day.${payloadDate.day()}`)

      return (this.isToday) ? this.$translate('availability.today') : `${day}\n${payloadDate.date()}`
    },
    needSave () {
      return this.$store.getters['availabilities/getNeedSave']
    },
    isToday () {
      const today = this.$moment(this.$moment(new Date()))
      const payloadDate = this.$moment(this.$props.idx)

      return payloadDate.format('YYYY-MM-DD') === today.format('YYYY-MM-DD')
    },
    cities () {
      return this.$store.getters.getCities
    },
    user () {
      return this.$store.getters.getUser
    },
    requestTimeTable () {
      return this.formData.requests.map((request) => {
        const start = this.timeStrToNum(request._time_start)
        const end = this.timeStrToNum(request._time_end)

        return { start, end }
      })
    },
    requestStart () {
      return this.timeStrToNum(this.formData.availabilities_start)
    },
    requestEnd () {
      return this.timeStrToNum(this.formData.availabilities_end)
    },
    schedule () {
      const start = Number(this.sliderVal[0])
      const end = Number(this.sliderVal[1])

      if (!start && !end) return

      const maxBars = SLIDER_SETTINGS.range.max - SLIDER_SETTINGS.range.min
      const reqEnd = this.timeStrToNum(this.formData.availabilities_end)

      const result = []

      for (let i = 0, s = SLIDER_SETTINGS.range.min; i < maxBars; i++, s++) {
        if (this.isMeetingSet(s)) {
          result.push(this.MEETING_INDICATOR_STATUS.MEETING)
        } else {
          if (s >= start && s < end) {
            result.push(this.MEETING_INDICATOR_STATUS.AVAILABLE)
          } else {
            result.push(this.MEETING_INDICATOR_STATUS.UNFILLED)
          }
        }
      }

      // For .5 or :30 ish values, we need to re-evalute in order to display
      // the correct half-box presentation.
      for (let i = 0, s = SLIDER_SETTINGS.range.min; i < maxBars; i += 0.5, s += 0.5) {
        const lastStatus = result[Math.floor(i)]
        const conditions = {
          isEnd: false,
          isStart: false,
          hasMeeting: false,
          isAvailable: false
        }
        let newstatus = lastStatus

        if (s === start) {
          conditions.isStart = true
        } else if (s === end) {
          conditions.isEnd = true
        }
        if (this.isMeetingSet(s)) {
          conditions.hasMeeting = true
        }
        if (s >= start && s <= end) {
          conditions.isAvailable = true
        }

        switch (lastStatus) {
          case this.MEETING_INDICATOR_STATUS.MEETING:
            if (!conditions.hasMeeting && conditions.isAvailable) {
              if (conditions.isEnd) {
                newstatus = this.MEETING_INDICATOR_STATUS.HALF_MEETING_2
              } else {
                newstatus = this.MEETING_INDICATOR_STATUS.HALF_MEETING_AVAILABLE_2
              }
            }
            break
          case this.MEETING_INDICATOR_STATUS.AVAILABLE:
            if (conditions.isEnd) {
              if (conditions.hasMeeting) {
                newstatus = this.MEETING_INDICATOR_STATUS.HALF_MEETING_2
              } else if (conditions.isAvailable) {
                newstatus = this.MEETING_INDICATOR_STATUS.HALF_AVAILABLE_2
              }
            } else {
              if (conditions.hasMeeting && conditions.isAvailable) {
                newstatus = this.MEETING_INDICATOR_STATUS.HALF_MEETING_AVAILABLE_1
              }
            }
            break
          case this.MEETING_INDICATOR_STATUS.UNFILLED:
            if (conditions.isStart) {
              if (conditions.hasMeeting) {
                newstatus = this.MEETING_INDICATOR_STATUS.HALF_MEETING_1
              } else if (conditions.isAvailable) {
                newstatus = this.MEETING_INDICATOR_STATUS.HALF_AVAILABLE_1
              }
            }
            break
        }

        result[Math.floor(i)] = newstatus
      }

      return result
    },
    meetings () {
      const meetingCount = this.formData.requests.length
      const result = []

      for (let c = 1; c <= 3; ++c) {
        if (c <= meetingCount) {
          result.push(true)
        } else {
          result.push(false)
        }
      }

      return result
    },
    isFirstDayMonth () {
      return this.$moment(this.$props.idx).date() === 1
    },
    isMonday () {
      return this.$moment(this.$props.idx).day() === 1
    },
    month () {
      const d = this.$moment(this.$props.idx)
      const m = this.$translate(`availability.month.${d.month()}`)

      return `${m} ${d.format('YYYY')}`
    },
    isTimeWithinLateNight () {
      // if current time is between 22:30 and 00:00
      return this.isToday && this.getMinAllowedTime() > 22.5 && this.getMinAllowedTime() <= 24
    },
    isAvailableAndNotLateNight () {
      // if current time is between 22:30 and 00:00
      return (!this.formData.not_available || this.shouldConfirm) && !this.isTimeWithinLateNight
    },
    notAvailableOrIsLateNight  () {
      // if current time is between 22:30 and 00:00
      return (this.formData.not_available && !this.shouldConfirm) || this.isTimeWithinLateNight
    },
    lastAvailability () {
      return this.$store.getters['availabilities/getLastAvailability']
    },
    filteredCities () {
      return this.cities.filter(c => c.id !== this.user.profiles.city_id)
    }
  }
}
