var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.componentView,
    { tag: "component", attrs: { data: _vm.computedData } },
    [
      !_vm.isLoading
        ? _c("div", [_vm._t("default")], 2)
        : _c("apv-preloader-circular", { staticClass: "btn-preloader" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }