import { render, staticRenderFns } from "./confirm.vue?vue&type=template&id=06177613&"
import script from "./confirm.vue?vue&type=script&lang=js&"
export * from "./confirm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider8-stage.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06177613')) {
      api.createRecord('06177613', component.options)
    } else {
      api.reload('06177613', component.options)
    }
    module.hot.accept("./confirm.vue?vue&type=template&id=06177613&", function () {
      api.rerender('06177613', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/md/modals/confirm.vue"
export default component.exports