<template>
  <div class="overview-cta__container">
    <a class="overview-cta__btn" @click="gotoPage(redirectTo)">
      <img v-if="img" :src="img" class="overview-cta__img">
      <i v-else class="material-icons">{{ icon }}</i><span>{{ label }}</span>
    </a>
  </div>
</template>
<script>
import goToPageMixin from '@/features/krypton-connect/mixins/goToPage'

export default {
  mixins: [goToPageMixin],
  props: {
    icon: {
      type: String,
      default: 'forum'
    },
    img: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    redirectTo: {
      type: String,
      default: ''
    }
  },
  beforeDestroy () {
    this.$store.commit('setHasFixedFooterButtons', false)
  },
  mounted () {
    this.$store.commit('setHasFixedFooterButtons', true)
  }
}
</script>

<style lang="scss" scoped>
.overview-cta__container {
  width: 100%;
  max-width: 600px;
  height: 96px;
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 24px 31px;
  background-color: white;
  z-index: 10;

  .overview-cta__btn {
    width: 100%;
    height: 47px;
    padding: 15px 24px 14px;
    border-radius: 10px;
    font-size: 16px;
    outline: none;
    box-shadow: none;
    border: unset;
    text-transform: uppercase;
    font-family: 'Proxima Nova Semibold';
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent linear-gradient(250deg, #FF0073 0%, #DC0064 100%) 0% 0% no-repeat padding-box;
    color: white;
    cursor: pointer;

    .overview-cta__img, .material-icons {
      margin-right: 10px
    }
  }
}
</style>
