<template>
    <div class="modal left-align" style="max-height: 80%;">
        <div class="modal-content" style="padding: 32px 24px 2px;">
          <h4 class="modal-title center"
              style="font-size: 20px;"
              v-html="$translate('events.event_cancelled.modal.success_cancellation.title', {
                client_name: evt.client.name
              })">
          </h4>

          <div class="alarm-icon center">
              <i class="material-icons krypton-green-text">event_busy</i>
          </div>

          <p class="grey-text text-darken-1 center" style="font-size: 14px;">
            {{ $translate('events.event_cancelled.modal.success_cancellation.explanation') }}
          </p>
        </div>
        <div class="modal-footer" style="border-top: 1px solid #E1E6EB;">
          <a href="#" class="btn-flat waves-effect waves-dark krypton-pink-text center" @click.prevent="confirm">
            <strong>{{ $translate('events.event_cancelled.cta_button.ok_understand') }}</strong>
          </a>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    evt: { type: Object, required: true },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
  },
}
</script>

<style scoped>
.alarm-icon .material-icons {
  font-size: 72px;
}
.modal-content {
  padding-bottom: 0;
}
.modal-footer {
  text-align: center;
}
.modal-title {
  margin-bottom: 22px;
}
</style>
