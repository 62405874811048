<template>
  <transition name="slide">
    <div id="legal-new">
      <div id="privacy" class="wrapper">
        <div class="content__area overlay">
          <img id="k-logo" src="/img/registration/k-logo.svg" alt="girl with heart graphics">
          <h2 class="content__title content__title--desktop">{{ $translate('register.agreement.title') }}</h2>
        </div>
        <div ref="scrollArea" class="content__area">
          <div class="content">
            <div class="top-bar">
              <a href="#" class="back grey-text text-darken-1" @click.prevent="$router.go(-1)">
                <i class="material-icons">chevron_left</i>
              </a>
            </div>
            <h1 class="title strong margin-top-none">{{ $translate('privacyNew.header') }}</h1>
            <h2 class="subtitle strong">{{ $translate('privacyNew.section_1.title') }}</h2>
            <div v-html="$translate('privacyNew.section_1.content')" />
            <h2 class="subtitle strong">{{ $translate('privacyNew.section_2.title') }}</h2>
            <div v-html="$translate('privacyNew.section_2.content')" />
            <h2 class="subtitle strong">{{ $translate('privacyNew.section_3.title') }}</h2>
            <div v-html="$translate('privacyNew.section_3.content')" />
            <h2 class="subtitle strong">{{ $translate('privacyNew.section_4.title') }}</h2>
            <div v-html="$translate('privacyNew.section_4.content')" />
            <h2 class="subtitle strong">{{ $translate('privacyNew.section_5.title') }}</h2>
            <div v-html="$translate('privacyNew.section_5.content')" />
            <h2 class="subtitle strong">{{ $translate('privacyNew.section_6.title') }}</h2>
            <div v-html="$translate('privacyNew.section_6.content')" />
            <h2 class="subtitle strong">{{ $translate('privacyNew.section_7.title') }}</h2>
            <div v-html="$translate('privacyNew.section_7.content')" />
            <h2 class="subtitle strong">{{ $translate('privacyNew.section_8.title') }}</h2>
            <div v-html="$translate('privacyNew.section_8.content')" />
            <div v-if="$translate('privacyNew.section_9.content').length" v-html="$translate('privacyNew.section_9.content')" />

            <div class="actions flex-grid">
              <slideToTopBtn :text="$translate('register.navigator.go_top')" classes="btn btn-black" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import slideToTopBtn from '@components/helpers/slide-to-top-btn.vue'

export default {
  components: {
    slideToTopBtn
  }
}
</script>

<style lang="scss" scoped>
#privacy {
    & {
        background: #FFF;
        display: block;
        overflow: auto;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 999;
    }
    .close {
        top: 16px;
        right: 16px;
        position: absolute;
    }
    .title {
        font-size: 1.375rem;
    }
    .subtitle {
        font-size: 1.125rem;
    }
}

.actions {
  justify-content: end;

  @media screen and (max-width: 991px) {
    justify-content: center;
    background: transparent !important;
    border-top: unset !important;
  }
}
</style>
