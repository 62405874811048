var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reactivation" }, [
    _c("div", { staticClass: "container", attrs: { id: "content" } }, [
      _c(
        "h2",
        { staticClass: "title", attrs: { "data-test": "reactivation-title" } },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _vm._v(" "),
      _c("img", {
        staticClass: "icon",
        attrs: {
          "data-test": "reactivation-image",
          src: "/img/reactivation/lock.svg",
          alt: "lock icon"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "description-box",
          attrs: { "data-test": "reactivation-explanation-text" }
        },
        [
          _c("strong", [_vm._v(_vm._s(_vm.subtitle))]),
          _vm._v(" "),
          _c("p", [_vm._v("\n        " + _vm._s(_vm.text) + "\n      ")]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "cta btn btn-full waves-effect waves-dark",
              attrs: {
                "data-test": "reactivation-cta",
                disabled: _vm.isFormSubmitting
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.reactivateAccount($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.cta))]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }