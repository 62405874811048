Vue.component('register-stepper', require('./utilities/stepper.vue').default)
Vue.component('register-stepper-v2', require('./utilities/stepper-v2.vue').default)
Vue.component('register-navigator', require('./utilities/navigator.vue').default)
Vue.component('register-navigator-v2', require('./utilities/navigator-v2.vue').default)
Vue.component('register', require('./register/register.vue').default)
Vue.component('register-agreement', require('./agreement/agreement.vue').default)
Vue.component('register-overview', require('./overview/overview.vue').default)
Vue.component('register-basic', require('./basic/basic.vue').default)
Vue.component('register-basic-box', require('./basic/box.vue').default)
Vue.component('register-basic-city', require('./basic/city.vue').default)
Vue.component('register-personality', require('./personality/personality.vue').default)
Vue.component('register-personality-intellect', require('./personality/intellect.vue').default)
Vue.component('register-personality-taste', require('./personality/taste.vue').default)
Vue.component('register-personality-style', require('./personality/style.vue').default)
Vue.component('register-personality-name', require('./personality/name.vue').default)
Vue.component('register-basic-eye-color', require('./basic/eye-color.vue').default)
Vue.component('register-basic-hair-color', require('./basic/hair-color.vue').default)
Vue.component('register-basic-origin', require('./basic/origin.vue').default)
Vue.component('register-basic-height', require('./basic/height.vue').default)
Vue.component('register-basic-weight', require('./basic/weight.vue').default)
Vue.component('register-basic-bra-cup', require('./basic/bra-cup.vue').default)
Vue.component('register-basic-dress-size', require('./basic/dress-size.vue').default)
Vue.component('register-photos', require('./photos/photos.vue').default)
Vue.component('register-photos-crop', require('./photos/crop.vue').default)
Vue.component('register-photos-preview', require('./photos/preview.vue').default)
Vue.component('register-photos-anonimize', require('./photos/anonimize.vue').default)
Vue.component('register-photos-primary', require('./photos/primary.vue').default)
Vue.component('register-photos-others', require('./photos/others.vue').default)
Vue.component('register-verify', require('./final/verify.vue').default)
Vue.component('register-photo-tips', require('../account/photos/photo-tips.vue').default)
