<template>
  <div>
    <div id="super-provider-landing-page__header">
      <div id="super-provider-landing-page__header-wrapper">
        <md-faq-navbar
          :route-name="'faq'"
        />
        <h1 v-text="headerTitle" />
        <img src="/img/super-provider/gruppe-4570.svg">
      </div>
    </div>
    <div
      class="super-provider-landing-page__content-section super-provider-landing-page__content-section--tagline"
    >
      <p v-text="headerTagline" />
      <img src="/img/super-provider/tagline-header-img.svg">
    </div>
  </div>
</template>

<script>

export default {
  props: {
    headerTitle: {
      type: String,
      required: true,
      default: ''
    },
    headerTagline: {
      type: String,
      required: true,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
#super-provider-landing-page__header {
  width: 100%;
  position: relative;
  color: #ffffff;
  text-align: center;
  background: transparent linear-gradient(54deg, #4B38FA 0%, #D300C5 100%) 0% 0% no-repeat padding-box;
  height: 270px;

  #super-provider-landing-page__header-wrapper {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    top: 0;

     h1, p {
      line-height: 1;
    }

    h1 {
      margin: 0;
      font-family: 'Proxima Nova Semibold';
      font-size: 24px;
      padding-top: 5px;
    }

    img {
      width: 130px;
      padding-top: 19px;
    }
  }
}

.super-provider-landing-page__content-section {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  &::before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.1;
    z-index: 0;
    background: transparent linear-gradient(52deg, #4B38FA 0%, #D300C5 100%) 0% 0% no-repeat padding-box;
  }
}

.super-provider-landing-page__content-section--tagline {
  font-size: 18px;
  color: #2F3740;
  padding: 40px 32px;

  p {
    margin: 0;
  }

  img {
    padding-top: 24px;
  }
}
</style>
