<script>
/**
 * Forms
 *
 * @param boolean novalidate - Disables html5 client side validation.
 * @param boolean disabled - Force the form to be disabled.
 * @param boolean isLoading - Disables the entire form.
 */
export default {
  props: {
    novalidate: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDisabled () {
      return this.disabled || this.isLoading
    }
  },
  methods: {
    handleSubmit (event) {
      this.$emit('submit', event)
    }
  }
}
</script>

<template>
  <form :novalidate="novalidate" @submit.prevent="handleSubmit">
    <fieldset :disabled="isDisabled">
      <slot />
    </fieldset>
  </form>
</template>

<style lang="scss" scoped>
form {
  fieldset:disabled {
    ::v-deep input[type="text"].apv-input-field--input,
    ::v-deep input[type="email"].apv-input-field--input,
    ::v-deep input[type="password"].apv-input-field--input,
    ::v-deep input[type="tel"].apv-input-field--input {
      border-bottom: 1px dotted #2E353D;
    }
  }
}
</style>
