<template>
  <div id="multi-location-landing-page" class="wrapper">
    <div id="content" class="container">
      <feature-overview
        :header-title="$t('header.title')"
        :header-subtitle="$t('header.subtitle')"
        :header-tagline="$t('content.tagline')"
        :main-header-logo="'/img/multi-location/multiloc-overview.svg'"
        :tagline-logo="'/img/multi-location/multiloc-tagline-logo-v2.svg'"
        primary-header-color="#FF665B"
        secondary-header-color="#FFD04D"
        header-gradient-angle="46deg"
        tagline-gradient-angle="18deg"
        :header-navbar-redirection="'dashboard'"
        :header-img-dimensions="{ height: '209px', width: '245px' }"
        :features="features"
      />
      <q-and-a
        :title="$t('faq.title')"
        :default-opened-answer="0"
        :questions="questions"
      />
      <overview-cta :redirect-to="'multi-location-setting'" :label="$t('cta')" :img="'/img/multi-location/commute_black_24dp.svg'" />
    </div>
  </div>
</template>

<i18n src="@/features/multi-location/translations/landing-page.json"></i18n>

<script>
import FeatureOverview from '@/features/overview/FeatureOverview.vue'
import QAndA from '@/features/overview/QAndA.vue'
import OverviewCta from '@/features/overview/OverviewCTA.vue'

import values from 'lodash/values'

export default {
  components: {
    FeatureOverview,
    QAndA,
    OverviewCta
  },
  data () {
    return {
      features: values(this.$t('content.features')),
      questions: values(this.$t('faq.list2'))
    }
  }
}
</script>

<style lang="scss" scoped>
#multi-location-landing-page {
  font-family: 'Proxima Nova';

  #content {
    padding: 0;
  }
}
</style>
