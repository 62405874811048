<template>
  <div v-if="options.length" id="height" class="wrapper">
    <template>
      <div class="content__area">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <p class="content__stepper content__stepper--mobile"> {{ stepperLabel }}</p>
        <h2 class="content__title content__title--desktop">{{ $t('title') }}</h2>
      </div>
      <div class="content__area drawer">
        <div class="content">
          <p class="content__stepper content__stepper--desktop"> {{ stepperLabel }}</p>
          <h2 class="content__title content__title--mobile">{{ $t('title') }}</h2>

          <div class="drawer-icon center-align">
            <img src="/img/registration/basic/height.svg" alt="locator icon">
          </div>

          <div class="selected-text-container">
            <p v-if="selectedText" class="center-align selected-text">
              {{ selectedText }}
            </p>

            <p v-else> &nbsp; </p>
          </div>

          <md-select
            v-model="payload.height"
            class="textfield-box-lg"
            :scroll-to-value="160"
            :label="$t('input_label')"
            :default="$t('input_default')"
            :placeholder="$t('title')"
            :force-mobile="true"
            desktop-display-mode="split"
          >
            <option
              v-for="(option, index) in options"
              slot="options"
              :key="index"
              :value="option.key"
            >{{ option.content }}</option>
            <div v-if="errors.has('height')" slot="errors" class="input-helper red-text">
              {{ errors.first('height') }}
            </div>
          </md-select>

          <register-navigator-v2
            :next-disabled="isCTADisabled"
            :total="total"
            :current="current"
            @prev.prevent="gotoPrev"
            @next.prevent="submit"
          />
        </div>
      </div>
    </template>

  </div>
</template>

<i18n src="@components/register/translations/height.json"></i18n>

<script>
import { getV2ProviderDropdownService } from '@/services/DropdownService.js'
import LocalizationService from '@/services/LocalizationService.js'
import { isEmpty } from '@/constants/helpers.js'

const REG_HEIGHT_CONST = Object.freeze({
  ERR_DISP_DELAY: 5000
})

export default {
  props: ['total', 'current', 'stepperLabel'],
  data () {
    return {
      options: [],
      payload: {
        height: null
      }
    }
  },
  computed: {
    isCTADisabled () {
      return isEmpty(this.payload.height)
    },
    homeBaseCountry () {
      return this.$store.getters.getHomeBaseCountry
    },
    user () {
      return this.$store.getters.getUser
    },
    userPayloadHeight () {
      return this.user?.profiles?.height ?? null
    },
    payloadHeight () {
      return this.payload?.height ?? null
    },
    selectedText () {
      return this.payloadHeight ? this.getContentFromDropdown(this.payloadHeight) : ''
    },
    isFormValid () {
      return this.payloadHeight ?? false
    },
    isLocaleEn () {
      return LocalizationService.getLocale()?.toLowerCase() === 'en'
    }
  },
  mounted () {
    this.initializePayload()
    this.fetchDropdownOptions()
  },
  methods: {
    getContentFromDropdown (payloadHeight) {
      const optionItem = this.options.find(o => o.key === payloadHeight)
      let optionItemContent = optionItem?.content ?? null

      if (this.isLocaleEn && optionItemContent) {
        optionItemContent = `${optionItemContent} tall`
      }

      return optionItemContent ?? ''
    },
    extractHeightOptions (res) {
      const heightOptions = res?.data?.height

      this.options = Object.entries(heightOptions).map(option => ({
        key: parseInt(option[0]),
        content: option[1]
      }))
    },
    fetchDropdownOptions () {
      getV2ProviderDropdownService()
        .then(res => this.extractHeightOptions(res))
        .catch(err => console.log('err', err))
    },
    submit () {
      this.errors.clear()

      if (!this.isFormValid) {
        var errorMsg = this.$t('error_select', {
          field: this.$t('name')
        })

        this.errors.add({
          field: 'height',
          msg: errorMsg
        })

        return false
      }

      this.$store.dispatch('updateBasicRegistration', { type: 'height', payload: this.payload })
        .then(() => this.$router.push(this.resolveBasicRoute('weight')))
        .catch(err => this.displayErrorMessages(err))
    },
    displayErrorMessages (err) {
      this.$notifications.toast(err.message, REG_HEIGHT_CONST.ERR_DISP_DELAY)
    },
    gotoPrev () {
      this.$router.push(this.resolveBasicRoute('origin'))
    },
    resolveBasicRoute (type) {
      return {
        name: 'register-basic',
        params: {
          type: type
        }
      }
    },
    initializePayload () {
      if (this.userPayloadHeight) {
        this.setPayloadHeight(this.userPayloadHeight)
      }
    },
    setPayloadHeight (payloadHeight) {
      this.payload.height = payloadHeight
    }
  }
}
</script>
