var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "incentives" },
    _vm._l(_vm.features, function(feature, i) {
      return _c(
        "div",
        {
          key: "feature-item-" + i,
          class: ["card-panel", _vm.getCardPanelVariant(i)]
        },
        [
          _c("div", { staticClass: "card-header" }, [
            _c("div", { staticClass: "card-header-forum-container" }, [
              _c("img", { attrs: { src: feature.icon, alt: "" } })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "incentive-title" }, [
              _vm._v("\n        " + _vm._s(feature.title) + "\n      ")
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "incentive-description",
              domProps: { innerHTML: _vm._s(feature.content) }
            })
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }