<template>
  <form @submit.prevent="submit">
    <fieldset :disabled="onTransit">
      <slot />
    </fieldset>
    <div v-if="onTransit" class="vform-preloader-wrapper">
      <md-preloader color="krypton-pink" />
    </div>
  </form>
</template>

<style lang="scss" scoped>
.vform-preloader-wrapper {
  & {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    position: fixed;
    line-height: 100vh;
    background-color: rgba(#000, 0.5);
    text-align: center;
  }
}
</style>

<script>
export default {
  props: [
    'params'
  ],
  data () {
    return {
      data: null,
      onTransit: false
    }
  },
  created () {
    this.data = this.setData(this.params)
  },
  methods: {
    setData (data) {
      this.data = data
    },
    setTransit (mode) {
      this.onTransit = mode
    },
    setTransitFalse () {
      this.setTransit(false)
    },
    submit () {
      this.setTransit(true)
      this.$emit('submit', this.setTransitFalse, this.data)
    }
  }
}
</script>
