var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "start-screen-root" } },
    [
      [
        _c("div", { staticClass: "wrapper" }, [
          _c("div", {
            staticClass: "content__backdrop content__backdrop--lilac"
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content__drawer" },
            [
              _c(
                "div",
                { staticClass: "content", attrs: { id: "start-screen" } },
                [
                  _c("div", { staticClass: "start-screen-step" }, [
                    _c("img", {
                      style: [
                        _vm.userStartScreenProgress === 2
                          ? { transform: "translate(-20%)" }
                          : null
                      ],
                      attrs: {
                        src: require("@/features/start-screen/assets/" +
                          _vm.getCurrentImage),
                        alt: _vm.getCurrentImage,
                        "data-test": "start-screen-image"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "start-screen-content" }, [
                      _c(
                        "h2",
                        { attrs: { "data-test": "start-screen-title" } },
                        [_vm._v(_vm._s(_vm.title))]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        { attrs: { "data-test": "start-screen-description" } },
                        [_vm._v(_vm._s(_vm.content))]
                      )
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("navigator", {
                attrs: {
                  total: 3,
                  current: _vm.userStartScreenProgress,
                  "no-prev": "",
                  "primary-at-last": "",
                  next: _vm.cta,
                  "next-class": {
                    "action-btn__black": _vm.userStartScreenProgress < 3,
                    "action-btn__primary action-btn__blocked":
                      _vm.userStartScreenProgress === 3
                  }
                },
                on: {
                  next: function($event) {
                    $event.preventDefault()
                    return _vm.gotoNextStep($event)
                  }
                }
              })
            ],
            1
          )
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }