var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "faq-link-item" }, [
    _c("div", { staticClass: "question" }, [
      _c("span", { staticClass: "question-number" }, [
        _vm._v(_vm._s(_vm.getItemNumber) + ".")
      ]),
      _vm._v(" "),
      _c("span", {
        domProps: { textContent: _vm._s(_vm.$t("faq.linkItem.title")) }
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "answer" },
      [
        _c("span", {
          domProps: { textContent: _vm._s(_vm.$t("faq.linkItem.section_1")) }
        }),
        _vm._v(" "),
        _c("router-link", { attrs: { to: { name: "reliability-faq" } } }, [
          _vm._v(_vm._s(_vm.$t("faq.linkItem.section_2")))
        ]),
        _vm._v(" "),
        _c("span", {
          domProps: { textContent: _vm._s(_vm.$t("faq.linkItem.section_3")) }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }