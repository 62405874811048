class LocaleProvider {
    getLocale() {
        return Lang.locale() === 'en' ? 'en' : 'de';
    }

    isLocaleDe() {
        return this.getLocale() === 'de';
    }

    isLocaleEn() {
        return this.getLocale() === 'en';
    }
}

export default new LocaleProvider;
