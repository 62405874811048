var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "input-field",
      class: {
        active: _vm.forceActive,
        "has-placeholder": _vm.placeholder,
        "input-field--APV_REG_REDESIGN": true,
        "has-value": !_vm.isEmpty(_vm.model),
        disabled: _vm.disabled
      }
    },
    [
      _vm.checkingPasswordType === "checkbox" && _vm.type === "password"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model"
              }
            ],
            ref: "textinput",
            attrs: {
              placeholder: _vm.placeholder,
              autocomplete: _vm.autocomplete,
              tabindex: _vm.tabindex,
              maxlength: _vm.maxlength,
              disabled: _vm.disabled,
              type: "checkbox"
            },
            domProps: {
              checked: Array.isArray(_vm.model)
                ? _vm._i(_vm.model, null) > -1
                : _vm.model
            },
            on: {
              focus: _vm.onFocus,
              blur: _vm.onBlur,
              change: function($event) {
                var $$a = _vm.model,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.model = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.model = $$c
                }
              }
            }
          })
        : _vm.checkingPasswordType === "radio" && _vm.type === "password"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model"
              }
            ],
            ref: "textinput",
            attrs: {
              placeholder: _vm.placeholder,
              autocomplete: _vm.autocomplete,
              tabindex: _vm.tabindex,
              maxlength: _vm.maxlength,
              disabled: _vm.disabled,
              type: "radio"
            },
            domProps: { checked: _vm._q(_vm.model, null) },
            on: {
              focus: _vm.onFocus,
              blur: _vm.onBlur,
              change: function($event) {
                _vm.model = null
              }
            }
          })
        : _vm.type === "password"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model"
              }
            ],
            ref: "textinput",
            attrs: {
              placeholder: _vm.placeholder,
              autocomplete: _vm.autocomplete,
              tabindex: _vm.tabindex,
              maxlength: _vm.maxlength,
              disabled: _vm.disabled,
              type: _vm.checkingPasswordType
            },
            domProps: { value: _vm.model },
            on: {
              focus: _vm.onFocus,
              blur: _vm.onBlur,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.model = $event.target.value
              }
            }
          })
        : _vm.type === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model"
              }
            ],
            ref: "textinput",
            attrs: {
              placeholder: _vm.placeholder,
              autocomplete: _vm.autocomplete,
              tabindex: _vm.tabindex,
              maxlength: _vm.maxlength,
              disabled: _vm.disabled,
              type: "checkbox"
            },
            domProps: {
              checked: Array.isArray(_vm.model)
                ? _vm._i(_vm.model, null) > -1
                : _vm.model
            },
            on: {
              focus: _vm.onFocus,
              blur: _vm.onBlur,
              input: _vm.onInput,
              change: function($event) {
                var $$a = _vm.model,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.model = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.model = $$c
                }
              }
            }
          })
        : _vm.type === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model"
              }
            ],
            ref: "textinput",
            attrs: {
              placeholder: _vm.placeholder,
              autocomplete: _vm.autocomplete,
              tabindex: _vm.tabindex,
              maxlength: _vm.maxlength,
              disabled: _vm.disabled,
              type: "radio"
            },
            domProps: { checked: _vm._q(_vm.model, null) },
            on: {
              focus: _vm.onFocus,
              blur: _vm.onBlur,
              input: _vm.onInput,
              change: function($event) {
                _vm.model = null
              }
            }
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model"
              }
            ],
            ref: "textinput",
            attrs: {
              placeholder: _vm.placeholder,
              autocomplete: _vm.autocomplete,
              tabindex: _vm.tabindex,
              maxlength: _vm.maxlength,
              disabled: _vm.disabled,
              type: _vm.type
            },
            domProps: { value: _vm.model },
            on: {
              focus: _vm.onFocus,
              blur: _vm.onBlur,
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.model = $event.target.value
                },
                _vm.onInput
              ]
            }
          }),
      _vm._v(" "),
      _c("label", { class: { active: _vm.isLabelActive } }, [
        _vm._v(_vm._s(_vm.label))
      ]),
      _vm._v(" "),
      _vm.type === "password"
        ? _c("img", {
            staticClass: "show-hide-cta",
            attrs: { src: _vm.showPasswordImgSrc },
            on: { click: _vm.showPassword }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type !== "password" && _vm.clearable
        ? _c("img", {
            ref: "clearcta",
            staticClass: "clear-cta",
            attrs: { src: "/img/registration/input/clearcta.svg", alt: "X" },
            on: { click: _vm.clearAndFocusInput }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }