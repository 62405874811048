<template>
  <div id="lesson-screen-feedback" class="lesson-screen">
    <div class="wrapper">
      <div class="content__drawer content__drawer-filled">
        <div v-show="hasLoaded" class="content lesson-screen--container">
          <div class="lesson-screen--content">
            <img
              v-if="isLessonOpened(LESSONS.MONEY)"
              class="cover-photo"
              src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-money-v2.svg"
            >
            <img
              v-else-if="isLessonOpened(LESSONS.FEEDBACK)"
              class="cover-photo"
              src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-feedback-v2.svg"
            >
            <img
              v-else-if="isLessonOpened(LESSONS.DATES)"
              class="cover-photo"
              src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-dates-v2.svg"
            >
            <img
              v-else-if="isLessonOpened(LESSONS.REQUESTS)"
              class="cover-photo"
              src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-requests-v2.svg"
            >
            <img
              v-else-if="isLessonOpened(LESSONS.CLIENTS)"
              class="cover-photo"
              src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-clients-v2.svg"
            >
            <img
              v-else-if="isLessonOpened(LESSONS.SAFETY)"
              class="cover-photo"
              src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-safety-v2.svg"
            >
            <img
              v-else-if="isLessonOpened(LESSONS.AVAILABILITY)"
              class="cover-photo"
              src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-availability-v2.svg"
            >
            <img
              v-else-if="isLessonOpened(LESSONS.INTERVIEW)"
              class="cover-photo"
              src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-interview-v2.svg"
            >
            <img
              v-else class="cover-photo" src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-start-photos-v2.svg">
            <h2>{{ $t(`${openedLessonName}.title`) }}</h2>
            <p>{{ $t(`${openedLessonName}.subtitle`) }}</p>
            <a
              href="#"
              data-test="photo-faq-link"
              class="btn btn-outline"
              @click.prevent="redirectToFAQ">
              <img src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-info-24px-baseline-v2.svg" alt="info icon">
              <span>{{ $t(`${openedLessonName}.faq`) }}</span>
            </a>
            <a
              href="#"
              data-test="photo-support-link"
              class="btn btn-link"
              @click.prevent="redirectToSupport">{{ $t('contact_support') }}</a>
            <h3 class="feedback-question">{{ $t('h3') }}</h3>
            <ul>
              <li>
                <a href="#" data-test="photo-smiley-rate" @click.prevent="setFeedback(1)">
                  <img
                    :class="{ 'inactive': !isFeedbackActive(1) }"
                    src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-smile-v2.svg"
                    alt="smile emoji"
                  >
                </a>
              </li>
              <li>
                <a href="#" data-test="photo-smiley-rate" @click.prevent="setFeedback(2)">
                  <img
                    :class="{ 'inactive': !isFeedbackActive(2) }"
                    src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-speechless-v2.svg"
                    alt="speechless emoji"
                  >
                </a>
              </li>
              <li>
                <a href="#" data-test="photo-smiley-rate" @click.prevent="setFeedback(3)">
                  <img
                    :class="{ 'inactive': !isFeedbackActive(3) }"
                    src="@/features/onboarding-lesson/assets/v2/onboarding-lesson-angry-face-v2.svg"
                    alt="smile emoji"
                  >
                </a>
              </li>
            </ul>
          </div>
        </div>

        <navigator
          :current="currentPage"
          no-prev
          :next="$t('finish')"
          :next-class="'action-btn__lilac-gradient action-btn__blocked'"
          :total="totalPages"
          :step-class="'--purple'"
          @next.prevent="completeLesson"
        />
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/onboarding-lesson/translations/feedback.json"></i18n>

<script>
import OnboardingProvider from '@/providers/OnboardingProvider'
import navigator from '@components/v2/utilities/navigator.vue'
import { OnboardingLessons } from '@/mixins/onboarding'
import { mapGetters } from 'vuex'

export default {
  components: {
    navigator
  },
  mixins: [
    OnboardingLessons
  ],
  data () {
    return {
      feedback: 0,
      hasLoaded: false,
      isCompleted: false,
      isFeedbackSent: false
    }
  },
  computed: {
    ...mapGetters({
      activeLesson: 'onboarding/GET_ACTIVE_LESSON'
    }),
    faqRoute () {
      return this.$t(`${this.openedLessonName}.faqLink`)
    },
    isFeedbackActive () {
      return (feedback) => {
        if (this.feedback === 0) {
          return true
        }

        return this.feedback === feedback
      }
    }
  },
  async mounted () {
    await window.localforage.removeItem('openedLesson')
    await this.fetchLessonProgress()

    if (this.isCompleted === false) {
      await OnboardingProvider.completeLesson(this.openedLesson.type)

      if (this.openedLesson.type === this.activeLesson) {
        this.$store.dispatch('onboarding/UPDATE_IS_ACTIVE_LESSON_COMPLETED', 1)
      }
    }

    this.hasLoaded = true
  },
  methods: {
    redirectToSupport () {
      this.$router.push({ name: 'support-v2' })
      this.resetActiveLesson()
    },
    redirectToFAQ () {
      this.$router.push({ name: this.faqRoute })
      this.resetActiveLesson()
    },
    setFeedback (feedback) {
      if (this.isFeedbackSent === true) {
        return
      }

      this.feedback = feedback
    },
    closeLesson () {
      this.resetActiveLesson()
    },
    async fetchLessonProgress () {
      try {
        const progress = await OnboardingProvider.fetchLessonProgress(this.openedLesson.type)

        this.feedback = progress.data.feedback
        this.isCompleted = progress.data.is_completed !== 0
        this.isFeedbackSent = progress.data.feedback !== 0
      } catch (e) {
        this.feedback = 0
        this.isCompleted = false
        this.isFeedbackSent = false
      }
    },
    async submitLessonFeedback () {
      try {
        await OnboardingProvider.submitLessonFeedback(this.openedLesson.type, this.feedback)
      } catch (e) {
        // @todo send slack notification
      }
    },
    async completeLesson () {
      try {
        if (this.feedback !== 0 && this.isFeedbackSent === false) {
          await this.submitLessonFeedback()
        }
      } finally {
        this.closeLesson()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/features/onboarding-lesson/sass/styles";

#lesson-screen-feedback {
  text-align: center;

  .lesson-screen--container {
    .lesson-screen--content {
      margin-top: 30px;

      img.cover-photo {
        width: 156px;
        margin-bottom: 55px;
      }
      h2, h3 {
        font-size: 18px;
        color: #2F3740;
      }
      h2 {
        margin: 0 0 8px;
      }
      p {
        font-size: 16px;
        color: #2F3740;
        margin: 0 0 45px;
      }
      .cta {
        width: 152px;
      }
      ul {
        display: block;
        width: 100%;
        margin: 0 0 40px;

        li {
          display: inline-block;
          margin: 0 8px;

          a {
            img.inactive {
              height: 34px;
              margin: 5px 0;
              filter: grayscale(1);
            }
          }
        }
      }

      h3.feedback-question {
        margin-top: 41px;
        margin-bottom: 24px;
      }
    }
  }

  @media (min-width: 768px) {
    .lesson-screen--container {
      display: flex;
      align-items: center;
      justify-content: center;

      .lesson-screen--content {
        margin-top: 0;
      }
    }
  }
}
</style>
