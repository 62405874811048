<template>
  <div id="registration-complete-desktop" class="wrapper">
    <template>
      <div class="content__area --success">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <h2
          class="content__title content__title--desktop"
          v-html="$translate('register.new_complete.title')"
        />
      </div>

      <div class="content__area drawer --with-long-content --with-both-bars">
        <div class="content">
          <h2
            class="content__title content__title--mobile"
            v-html="$translate('register.new_complete.title')"
          />

          <div class="drawer-icon center-align">
            <img src="/img/registration/success/apv-preview-new.png" alt="Registration Success">
          </div>

          <p class="text">{{ $translate('register.new_complete.content') }}</p>

          <div ref="qrcode" class="qrcode" />
          <p
            class="qr-code-text"
          >{{ $translate('register.new_complete.qr_code_explainer') }}</p>

          <div class="actions flex-grid space-between">
            <!-- Registration success CTA here -->
            <button
              class="btn btn-primary btn-verify"
              data-id="registration-complete-cta"
              @click="doSendLinkToMobile">
              <span v-if="isSending">Sending link...</span>
              <span v-else>{{ $translate('register.new_complete.send_link_to_mobile') }}</span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import qrcode from 'qrcode-generator'

import { sendSMSLink } from '@/features/registration/utils/RegisterAPI'

export default {
  data: () => ({
    isSending: false,
    cantSend: false
  }),
  mounted () {
    this.generateQRCode()
  },
  methods: {
    generateQRCode () {
      const typeNumber = 4
      const errorCorrectionLevel = 'L'
      const qr = qrcode(typeNumber, errorCorrectionLevel)

      qr.addData(window.location.origin)
      qr.make()

      this.$refs.qrcode.innerHTML = qr.createSvgTag()
    },
    doSendLinkToMobile () {
      if (this.isSending || this.cantSend) return

      this.isSending = true

      sendSMSLink()
        .then(() => {
          this.$notifications.toast(this.$translate('register.new_complete.sms_sent'), 20000)
        })
        .catch((err) => {
          if (err.response.data.message === 'Retries exceeded') {
            this.cantSend = true
          }
        })
        .finally(() => {
          this.isSending = false
        })
    }
  }
}
</script>
