import { buildParam } from '@/services/ApiService'

export const getTierData = () => {
  return new Promise((resolve, reject) => {
    window.api.get({
      url: buildParam('/v2/tiers'),
      config: {
        params: {
          locale: window.i18n.locale
        }
      }
    }, (res) => {
      resolve(res.data)
    }, (err) => {
      reject(err?.response?.data ?? err)
    })
  })
}

export const getTiers = () => {
  return new Promise((resolve, reject) => {
    window.api.get({
      url: buildParam('/v2/tiers/all'),
      config: {
        params: {
          locale: window.i18n.locale
        }
      }
    }, (res) => {
      resolve(res.data)
    }, (err) => {
      reject(err?.response?.data ?? err)
    })
  })
}

export default {
  getTierData,
  getTiers
}
