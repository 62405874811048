var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "multi-location-landing-page" } },
    [
      _c(
        "div",
        { staticClass: "container", attrs: { id: "content" } },
        [
          _c("feature-overview", {
            attrs: {
              "header-title": _vm.$t("header.title"),
              "header-subtitle": _vm.$t("header.subtitle"),
              "header-tagline": _vm.$t("content.tagline"),
              "main-header-logo": "/img/multi-location/multiloc-overview.svg",
              "tagline-logo":
                "/img/multi-location/multiloc-tagline-logo-v2.svg",
              "primary-header-color": "#FF665B",
              "secondary-header-color": "#FFD04D",
              "header-gradient-angle": "46deg",
              "tagline-gradient-angle": "18deg",
              "header-navbar-redirection": "dashboard",
              "header-img-dimensions": { height: "209px", width: "245px" },
              features: _vm.features
            }
          }),
          _vm._v(" "),
          _c("q-and-a", {
            attrs: {
              title: _vm.$t("faq.title"),
              "default-opened-answer": 0,
              questions: _vm.questions
            }
          }),
          _vm._v(" "),
          _c("overview-cta", {
            attrs: {
              "redirect-to": "multi-location-setting",
              label: _vm.$t("cta"),
              img: "/img/multi-location/commute_black_24dp.svg"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }