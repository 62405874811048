export default {
  data: () => ({
    fallbackRoute: {
      name: 'dashboard',
      title: 'sidenav.dashboard'
    },
    previousLink: '',
    previousTitle: ''
  }),
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.previousLink = { name: from.name }
      vm.previousTitle = from.name
    })
  }
}
