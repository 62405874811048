<template>
  <div>
    <div
      ref="photo-upload"
      class="photo-gallery-v2-photo"
      :style="bgImage"
      :class="{
        'is-draggable': $props.photo.primary === 0 && isEditMode,
        'not-published': $props.photo.status === 0
      }"
    >
      <div class="preview-btn" @click="previewPhoto" />
      <div v-if="$props.photo.primary !== 0" class="primary-photo-indicator" />
      <div v-if="isEditMode && $props.photo.primary !== 0" class="edit-locked" />
      <div v-if="showPhotoInsights" class="photo-insights" data-test="photo-insights">
        <div class="photo-insights__like">
          <i class="material-icons">favorite</i> <span data-test="photo-likes-count">{{ photo._likes }}</span>
        </div>
      </div>
      <div v-if="!isEditMode" class="photo-delete waves-effect waves-dark" @click="replacePhoto" />
    </div>
  </div>
</template>

<script>
import PhotoHelper from '@/helpers/PhotoHelper'

export default {
  props: ['photo', 'index'],
  computed: {
    PHOTO_FALLBACK_A2_2644 () {
      return this.$store.getters['featureflag/isEnabled']('PHOTO_FALLBACK_A2_2644')
    },
    isEditMode () {
      return this.$store.getters.getPhotoEditMode
    },
    hasReplaceConfirm () {
      return this.$store.getters.hasReplaceConfirm !== 0
    },
    bgImage () {
      let photoUrl
      if (this.PHOTO_FALLBACK_A2_2644) {
        photoUrl = this.photo._fallback_url.replace('resize', '375x500')
      } else {
        photoUrl = this.photo._resp_url
      }

      if (!photoUrl) {
        return ''
      }

      if (photoUrl?.indexOf('data:image') !== -1) {
        return `background-image: url(${photoUrl})`
      }

      const overlay = PhotoHelper.transform(photoUrl.transformations, 'overlay')

      const size = PhotoHelper.size('results')

      const crop = PhotoHelper.transform(photoUrl.transformations, 'crop')

      const imgUrl = PhotoHelper.link(photoUrl, crop, overlay, size)

      return `background-image: url(${imgUrl})`
    },
    showPhotoInsights () {
      if (!this.bgImage) {
        return false
      }

      if (this.photo.status !== 1 || this.photo.published_at === null) {
        return false
      }

      return true
    }
  },
  methods: {
    triggerUpload () {
      if (this.$props.photo.status === 0) {
        this.$emit('stop-replace', this.$refs['photo-upload'], this.index)
        return
      }

      if (this.$props.photo.primary === 0) {
        this.$parent.replacePhoto(this.$props.photo.id, false)
      } else {
        this.$parent.replacePhoto(this.$props.photo.id, true)
      }
    },
    replacePhoto () {
      this.triggerUpload()
    },
    previewPhoto () {
      if (this.PHOTO_FALLBACK_A2_2644) {
        this.$router.push({ name: 'photos-preview', query: { url: this.photo._fallback_url.replace('resize', '600x800') } })
      } else {
        this.$router.push({ name: 'photos-preview', query: { url: this.$props.photo._url } })
      }
    }
  }
}
</script>
