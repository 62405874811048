<template>
    <div class="modal modal-dropdown modal-fixed-footer left-align">
        <div class="modal-header" ref="header">{{ header }}</div>
        <div class="modal-content" ref="content">
            <div class="input-radio" v-for="option in options">
                <input class="filled-in multiple" type="checkbox" v-model="data" :value="option.val" :disabled="option.disabled || limitReached" v-if="multiple" />
                <input class="with-gap" type="radio" v-model="data" :value="option.val" :disabled="option.disabled" v-else />
                <label @click.prevent="select(option)">{{ option.text }}</label>
            </div>
        </div>
        <div class="modal-footer" ref="footer">
            <a href="#!" class="btn-flat waves-effect waves-dark" @click.prevent="close">{{ $translate('app.cancel') }}</a>
            <a href="#!" class="btn-flat waves-effect waves-dark krypton-pink-text" @click.prevent="confirm">{{ $translate('app.ok') }}</a>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'input',
        'header',
        'options',
        'multiple',
        'limit',
    ],
    methods: {
        select(option) {
            if (option.disabled) return false;
            if (this.multiple) {
                this.push2data(option.val);
            } else {
                this.setData(option.val);
            }
        },
        setData(val) {
            this.data = val;
        },
        push2data(val) {
            var index = _.indexOf(this.data, val);

            if (index >= 0) {
                this.data.splice(index, 1);
                this.data = _.filter(this.data, (e) => {
                    return e !== null;
                });
            } else {
                if (!this.limitReached) {
                    this.data.push(val);
                }
            }
        },
        confirm($e) {
            this.$emit('confirm', this.data);
        },
        close() {
            this.$emit('close', this.data);
        }
    },
    data() {
        return {
            data: null,
            optionSelectedCount: 0
        }
    },
    created() {
        this.data = this.multiple ? [] : null;
    },
    watch: {
        data(newVal, oldVal) {
            if (newVal.hasOwnProperty('length')) {
                this.optionSelectedCount = newVal.length;
            } else {
                this.optionSelectedCount = 1;
            }
        }
    },
    computed: {
        limitReached () {
            if ((this.multiple && this.limit) && this.optionSelectedCount == this.limit) {
                return true;
            }
            return false;
        }
    },
    mounted() {
        this.data = _.clone(this.input);
    }
}
</script>
