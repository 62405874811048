var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page", attrs: { id: "feedback" } },
    [
      _c("md-new-navbar"),
      _vm._v(" "),
      _c("div", { staticClass: "header-content center" }, [
        _c("h1", { staticClass: "title center" }, [
          _vm._v(_vm._s(_vm.$translate("feedback.title")))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "review-rating" },
          _vm._l(_vm.MAX_RATING, function(n) {
            return _c("i", {
              key: n,
              staticClass: "mdi",
              class: _vm.ratingIconClass(n, _vm.totalRating)
            })
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "rate valign-wrapper" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.totalRating) +
              "/" +
              _vm._s(_vm.MAX_RATING) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "reviews-counter margin-left-sm krypton-pink-text" },
          [_vm._v("\n      " + _vm._s(_vm.totalReviews) + "\n    ")]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container", attrs: { id: "content" } },
        [
          _vm._l(_vm.reviews, function(review, idx) {
            return !_vm.isFetching
              ? _c("div", { key: idx, staticClass: "card-panel left-align" }, [
                  _c("div", { staticClass: "card-header flex-grid" }, [
                    _c("div", { staticClass: "flex-1" }, [
                      _c(
                        "strong",
                        { staticStyle: { "vertical-align": "middle" } },
                        [_vm._v(_vm._s(review.client_name))]
                      ),
                      _vm._v(" "),
                      review.private_review
                        ? _c(
                            "span",
                            {
                              staticClass: "is-private",
                              staticStyle: { "vertical-align": "middle" }
                            },
                            [_vm._v("(Private)")]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-1 right-align date" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.displayDateCreated(review.created_at)) +
                          "\n        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-content" }, [
                    _c(
                      "div",
                      { staticClass: "review-rating" },
                      _vm._l(_vm.MAX_RATING, function(n) {
                        return _c("i", {
                          key: n,
                          staticClass: "mdi",
                          class: _vm.ratingIconClass(n, review.client_rating)
                        })
                      }),
                      0
                    ),
                    _vm._v(" "),
                    review.show_review === 1
                      ? _c("div", { staticClass: "message" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(review.client_review) +
                              "\n        "
                          )
                        ])
                      : _c("div", { staticClass: "message" }, [
                          _c("span", { staticClass: "blur-content" }, [
                            _vm._v(
                              "\n            Curabitur eleifend felis a hendrerit ultricies. Donec at semper justo, et hendrerit metus. Fusce et tellus sapien. Duis nulla enim, egestas ac imperdiet ut, vulputate pellentesque turpis.\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.showHint($event)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$translate(
                                      "feedback.cannot_see_comment_text"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ])
                  ])
                ])
              : _vm._e()
          }),
          _vm._v(" "),
          _vm.reviews.length === 0 && !_vm.isFetching
            ? _c("div", { staticClass: "card-panel" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$translate("feedback.empty_feedback")) +
                    "\n    "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.reviews.length === 0 && _vm.isFetching
            ? _c("div", { staticClass: "card-panel" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$translate("feedback.fetch_feedback")) +
                    "\n    "
                )
              ])
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }