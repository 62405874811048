
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      hasSuccessfulMeetings: 'getHasSuccessfulMeetings'
    })
  },
  watch: {
    hasSuccessfulMeetings () {
      this.checkHasSuccessfulMeetings()
    }
  },
  mounted () {
    this.checkHasSuccessfulMeetings()
  },
  methods: {
    checkHasSuccessfulMeetings () {
      if (this.hasSuccessfulMeetings) {
        this.$router.replace({ name: 'faq' })
      }
    }
  }
}
