<template>
  <div id="account" class="apv-v2" :class="{ 'has-bottom-status-indicator': hasBottomStatusIndicator }">
    <status-indicator />
    <router-view />
    <onboarding-lesson />
    <div>
      <!-- popups / overlays to be renderred accordingly -->
      <component :is="getPopupComponent" v-if="getPopupComponent" />
    </div>

  </div>
</template>

<script>
import Intercom from '@intercom/messenger-js-sdk'
import OnboardingLesson from '@/features/onboarding-lesson/components/OnboardingLesson.vue'
import SuperProviderPopup from '@/features/super-provider/components/SuperProviderPopup.vue'
import ProviderPreferencesPopup from '@/features/provider-preferences/ProviderPreferencesPopup.vue'
import ContactWarningPopup from '@/features/contact-warning/components/ContactWarningPopup.vue'
import LongTermBookingPopup from '@/features/availability/popup/LongTermBookingPopup.vue'
import IDVerificationMixin from '@/features/onboarding-bottom-sheet/mixins/id-verification.mixin.js'
import { mapGetters } from 'vuex'
import { isCandidateProfile } from '@/constants/helpers'
import { VISIBILITY_STATES } from '@/constants/global'
import { ID_VERIFICATION_COUNTDOWN, PROVIDER_PLATFORM_AGE } from '@/features/onboarding-bottom-sheet/constants.js'
import { updateUserLanguage } from '@/services/UserService.js'
import { allowedWebPushNotifications } from '@/features/web-push/WebPushAPIService.js'

import AffiliateReferralPopup from '@/features/affiliate/components/modals/AffiliateReferralPopup.vue'
import AffiliateRewardPopup from '@/features/affiliate/components/modals/AffiliateRewardPopup.vue'
import AffiliatePromoPopup from '@/features/affiliate/components/modals/AffiliatePromoPopup.vue'
import InactiveRequestRewardPopup from '@/features/inactive-request-rewards/popup/InactiveRequestRewardPopup.vue'

import LocalizationService from '@/services/LocalizationService.js'

const ModalManager = $h.getManager('ModalManager')

const EventStatusCheckingMixin = $h.getMixin('eventStatusChecker')
const NetworkCheckMixin = $h.getMixin('networkCheck')
const PusherMixin = $h.getMixin('pusher')
const TimerMixin = $h.getMixin('timer')

const DropdownProvider = $h.getProvider('DropdownProvider')
const CitiesProvider = $h.getProvider('CitiesProvider')
const UserProvider = $h.getProvider('UserProvider')
const VerificationProvider = $h.getProvider('VerificationProvider')

const InstallChromeBanner = require('../../install-chrome-banner.js')

export default {
  components: {
    OnboardingLesson,
    SuperProviderPopup,
    ContactWarningPopup,
    AffiliateReferralPopup,
    AffiliateRewardPopup,
    AffiliatePromoPopup,
    LongTermBookingPopup,
    ProviderPreferencesPopup,
    InactiveRequestRewardPopup
  },
  mixins: [
    EventStatusCheckingMixin,
    NetworkCheckMixin,
    PusherMixin,
    TimerMixin
  ],
  data: () => ({
    isBillingModalShown: false,
    canCheckModalQueue: false,
    hasCheckedModals: false,
    hasActiveModal: false,
    isProviderTwoWeeksOld: false
  }),
  methods: {
    ...IDVerificationMixin.methods,
    /**
     * @method fetchDropdown
     *
     * Preload dropdown data in the api.
    */
    fetchDropdown () {
      DropdownProvider.get()
        .then(res => {
          this.$store.commit('setDropdown', res.data)
        })
        .catch(err => {
          /** @todo prompt error to notify provider **/
          console.error(err)
        })
    },

    /**
     * @method checkPWA
     *
     * Checks if the user is using PWA or not.
    */

    checkPWA () {
      const isPWA =
        window.matchMedia('(display-mode: standalone)').matches ||
        window.matchMedia('(display-mode: fullscreen)').matches ||
        window.navigator.standalone === true ||
        window.location.search.indexOf('utm_source=homescreen') >= 0

      const isAndroidChrome = navigator.userAgent.search(/Chrome/i) >= 0 &&
        navigator.userAgent.search(/Android/i) >= 0 &&
        navigator.userAgent.search(/SamsungBrowser/i) === -1
      const isiPhoneSafari = navigator.userAgent.search(/iPhone|iPad/i) >= 0 &&
        navigator.userAgent.search(/CriOS/i) === -1
      const isMobile = /Android|SamsungBrowser|webOS|iPhone|iPad|Opera Mini/i.test(navigator.userAgent)
      const isDesktop = !isMobile || (!isAndroidChrome && !isiPhoneSafari)

      this.$store.commit('setIsPWA', isPWA)

      const isPWAModeAndNotDesktop = isPWA && !isDesktop

      UserProvider.updateUsage(this.user.id, isPWAModeAndNotDesktop)

      InstallChromeBanner.init({
        instructionText: this.$translate('app.install_chrome_screen.content'),
        linkText: this.$translate('app.install_chrome_screen.button')
      })
    },

    /**
     * @method fetchAvailabilities
     *
     * Preload availabilities data in the API.
    */
    fetchAvailabilities () {
      if (this.user.availability_enabled) {
        this.$store.dispatch('availabilities/fetchInitialAvailabilities')
      }
    },

    fetchActiveLessons () {
      this.$store.dispatch('onboarding/FETCH_ACTIVE_LESSONS', isCandidateProfile(this.user))
    },

    fetchEvents () {
      if (this.isEventsEnabled) {
        this.$store
          .dispatch('events/fetchEvents')
          .then(() => {
            // Set delay before we start modal check to allow some time to re-sort events
            const FETCH_EVENTS_TIMEOUT_DELAY = 2000
            const fetchEventsTimeout = setTimeout(() => {
              this.canCheckModalQueue = true
              clearTimeout(fetchEventsTimeout)
            }, FETCH_EVENTS_TIMEOUT_DELAY)
          })
      }
    },

    initInternalTimer () {
      this.startTimer(() => {
        this.updateCurrentTime()

        if (this.isEventsEnabled) {
          this.checkEventStatuses()
        }

        // Constant check for modals
        if (this.canCheckModalQueue) {
          ModalManager.showNextModal()
          this.hasActiveModal = ModalManager?.hasActiveModal ?? false
          this.hasCheckedModals = true
        }
      })
    },

    fetchPhotos () {
      this.$store.dispatch('fetchLivePhotos').then(() => {
        this.$store.commit('sortOtherPhotos')
      })
    },

    fetchCities () {
      CitiesProvider.get()
        .then(res => {
          this.$store.commit('setCities', res.data)
        })
        .catch(err => {
          console.error('Failed to fetch list of cities', err)
        })
    },

    fetchFeedbacks () {
      this.$store.dispatch('feedbacks/fetch')
      this.$store.dispatch('feedbacks/checkUnseenFeedback')
    },

    fetchBillingDetails () {
      this.$store
        .dispatch('billing/fetchBilling')
        .then(() => {
          this.shouldShowBilling()
          if (isCandidateProfile(this.user)) {
            this.checkForIdVerificationUpdates()
          }
        })
    },

    fetchIdDeadline () {
      this.$store.dispatch('onboarding/FETCH_ID_DEADLINE')
    },

    fetchAppNotifications () {
      this.$store.dispatch('notification/FETCH')
    },

    syncData () {
      this.$store.dispatch('getUserDetails')
        .then(() => {
          this.syncLanguage()
        })
      this.$store.dispatch('billing/fetchBilling')
      this.$store.dispatch('events/fetchEvents')
      this.fetchAvailabilities()
    },
    /**
     * Initialize server sync with data when the app visibility changes.
     */
    initializeSyncCheck () {
      if ($h.isPWAMode) {
        // Re-sync events data with server when user focuses on the PWA app.
        if (document.visibilityState === VISIBILITY_STATES.VISIBLE) {
          const excludedPaths = [
            '/verify/upload'
          ]
          const isExcludedPath = excludedPaths.indexOf(window.location.pathname) >= 0

          if (!isExcludedPath) {
            this.$notifications.toast(this.$translate('app.re_sync'), 5000)

            this.syncData()
            this.startNetworkCheck()
            this.initializePingCheck()
          }
        } else {
          // Pause web workers when PWA is idle.
          this.stopNetworkCheck()
        }
      } else {
        // pause/resume network check for desktop
        if (document.visibilityState === VISIBILITY_STATES.VISIBLE) {
          this.startNetworkCheck()
        } else {
          // Pause web workers when PWA is idle.
          this.stopNetworkCheck()
        }
      }
    },

    fetchAffiliateOverview () {
      // Dispatch action to do an API call for affiliate overview
      // Then set the mutate the vuex values on state
      this.$store.dispatch('affiliate/fetchAffiliateOverview')
    },

    syncLanguage () {
      // The purpose of this method is when there's a discrepancy between the user's language
      // From the API and from local storage. We will sync the user's language from the API

      const userAppLang = this.user.app_lang?.toLowerCase()
      const localeAppLang = LocalizationService?.getLocale()?.toLowerCase()

      if (localeAppLang !== userAppLang) {
        updateUserLanguage({ app_lang: userAppLang })
        this.$store.dispatch('getUserDetails')
      }
    },

    fetchAPIData () {
      this.fetchActiveLessons()
      this.fetchDropdown()
      this.fetchAvailabilities()
      this.fetchEvents()
      this.fetchPhotos()
      this.fetchCities()
      this.fetchFeedbacks()
      this.fetchAppNotifications()

      if (isCandidateProfile(this.user)) {
        this.fetchIdDeadline()
        this.fetchBillingDetails()
      }

      // API calls that should only be available for Providers (non-candidate)
      // @TODO: In the future we should exclude calling these APIs on APV Lite
      // The blocker for now is that the getUserDetails vuex action is called from time to time
      // And appending these APIs after that one is finished
      // Would cause a lot of network calls when the user tabs in and out of the PWA
      this.fetchAffiliateOverview()
    },

    initializePingCheck () {
      this.startNetworkCheck()
      this.pingCheck()
    },

    // App visibility methods
    onAppOnline () {
      this.initializeSyncCheck()
    },

    onAppOffline () {
      this.pingCheck()
    },

    onAppVisibilityChange () {
      this.initializeSyncCheck()
    },

    shouldShowBilling () {
      if (this.user.profiles.show_billing_details === 1 &&
        this.hasIDApproved &&
        !this.hasBillingSubmitted &&
        this.$route.name.indexOf('billing') === -1) {
        if (!this.isBillingModalShown) {
          this.isBillingModalShown = true
          $h.billingRequiredModal(() => {
            this.$router.push({ name: 'billing-form' })
          })
        }
      }
    },

    showFirstTimeLoginPopupCheck () {
      if (isCandidateProfile(this.user) && this.user?.profiles?.show_first_login_popup === 1) {
        const userID = this.user?.id
        this.$store.dispatch('onboarding/CHECK_START_SCREEN_PROGRESS', userID)
      }
    },

    checkForIdVerificationUpdates () {
      this.checkForSuccessVerification()
      this.checkForRejectedVerification()
    },

    checkForSuccessVerification () {
      if (this.shouldShowSuccessVerification) {
        $h.verifyIDSuccessModal(async () => {
          try {
            await this.$store
              .dispatch('billing/popupSeen', { approved_id_seen: true })
          } catch (err) {
            console.log('err', err)
          }
        })
      }
    },

    checkForRejectedVerification () {
      if (this.shouldShowRejectedVerification) {
        $h.verifyIDRejectedModal({ ...this.rejectedIDs },
          async () => {
            try {
              await this.$store
                .dispatch('billing/popupSeen', {
                  rejected_id_front_seen: this.rejectedIDs.file_id_front_error !== null,
                  rejected_id_back_seen: this.rejectedIDs.file_id_back_error !== null
                })

              return false
            } catch (err) {
              return false
            }
          })
      }
    },

    checkProviderPlatformAge (currTime) {
      // Only perform this check if the provider is not yet marked 2 weeks old
      if (!this.isProviderTwoWeeksOld) {
        // If the provider first_time_online_at is null, then we will assume that the provider is old
        if (this.user?.profiles?.first_time_online_at === null && !isCandidateProfile(this.user)) {
          this.isProviderTwoWeeksOld = true
        }

        // Check if provider is 2 weeks old
        const momentInstanceFirstTimeOnlineAt = this.user?.profiles?.first_time_online_at ? moment(this.user.profiles.first_time_online_at) : null
        if (momentInstanceFirstTimeOnlineAt && !isCandidateProfile(this.user)) {
          const numOfDays = $h.calcTimeDiff(momentInstanceFirstTimeOnlineAt, currTime, 'days')
          this.isProviderTwoWeeksOld = momentInstanceFirstTimeOnlineAt &&
            numOfDays >= PROVIDER_PLATFORM_AGE.MIN_DAYS_FOR_LONG_TERM_BOOKING_POPUP
        }
      }
    },

    mountIntercom () {
      const intercomAppId = process.env.INTERCOM_APP_ID || 'iqg5yr2f'

      if (isCandidateProfile(this.user) &&
        (this.user.agree_terms == 1 || this.user.agree_terms == 3)
      ) {
        Intercom({
          app_id: intercomAppId,
          user_id: this.user.id,
          email: this.user.email,
          name: this.user.profiles.name,
          created_at: this.user.created_at,
          vertical_padding: 90
        })
      }
    },

    initializeWebPushService () {
      // Browser language detection
      const languages = ['de', 'de-AT', 'de-BE', 'de-CH', 'de-DE', 'de-IT', 'de-LI', 'de-LU']
      const navigatorLanguage = navigator?.language || 'en'
      const isEn = !languages.includes(navigatorLanguage)
      // Browser language detection
      if (process.env.ONE_SIGNAL_APP_ID) {
        this.$OneSignal.init({
          appId: process.env.ONE_SIGNAL_APP_ID,
          serviceWorkerParam: { scope: '/push/onesignal/' },
          promptOptions: {
            slidedown: {
              autoPrompt: false,
              actionMessage: isEn ? 'Would you like to be notified of new requests?' : 'Möchtest du über neue Anfragen benachrichtigt werden?',
              acceptButton: isEn ? 'Yes' : 'Ja',
              cancelButton: isEn ? 'No' : 'Nein',
              positiveUpdateButton: isEn ? 'Yes' : 'Ja',
              negativeUpdateButton: isEn ? 'No' : 'Nein'
            }
          },
          welcomeNotification: {
            title: '',
            message: isEn ? 'Notifications enabled!' : 'Benachrichtigungen aktiviert!'
          }
        }).then(() => {
          this.$OneSignal.Slidedown.addEventListener('slidedownCancelClick', () => {
            console.log('3720 - prompt cancel click')
            // User denied permission
            const deniedPromptProviderIdValue = 'xxxxxxxx'
            allowedWebPushNotifications({ provider_driver_id: deniedPromptProviderIdValue })
          })
          this.$OneSignal.Notifications.addEventListener('permissionChange', (permission) => {
            console.log('3720 - permission change listener', permission)

            // If granted login the user
            if (permission) {
              console.log('3720 - one signal prompt allowed')

              this.$OneSignal.User.PushSubscription.addEventListener('change', (event) => {
                console.log('event from push subscription', event)
                // Check if current event has token and subscription id
                if (event.current.token && event.current.id) {
                  const subscriptionId = event.current.id
                  console.log('one signal id', subscriptionId) // Cannot get the one signal id, probably because the user is not

                  // Login and optin
                  if (this.user.id) {
                    // Login with the user id
                    const testExternalId = `${this.user.id}`
                    console.log('test external id', testExternalId)
                    this.$OneSignal.login(testExternalId).then(() => {
                      console.log('os login success')
                      allowedWebPushNotifications({ provider_driver_id: subscriptionId })
                    }).catch(e => console.log('3720 - os err login', e))
                  }
                }
              })
            }
          })
        }).catch(e => console.log('3720 - os err init', e))
      }
    }
  },
  computed: {
    ...mapGetters({
      curTimeTz: 'getCurrentTimeTz',
      hasBillingSubmitted: 'billing/hasBillingSubmitted',
      hasIDApproved: 'billing/hasIDApproved',
      hasRejectedID: 'billing/hasRejectedID',
      shouldShowSuccessVerification: 'billing/shouldShowSuccessVerification',
      shouldShowRejectedVerification: 'billing/shouldShowRejectedVerification',
      isAppOnline: 'getIsOnline',
      isRequestEnabled: 'getIsRequestEnabled',
      rejectedIDs: 'billing/getRejectedIDs',
      deadlineTimer: 'onboarding/GET_ID_DEADLINE_TIMER',
      deadlineMoment: 'onboarding/GET_ID_DEADLINE_MOMENT',
      startScreenProgress: 'onboarding/GET_START_SCREEN_PROGRESS',
      user: 'getUser',
      userRegisterProgress: 'getUserRegisterProgress',
      showBottomStatusIndicator: 'getBottomStatusIndicator',
      hasFixedMenu: 'hasFixedMenu',
      homeBaseCountry: 'getHomeBaseCountry'
    }),

    FEATURE_INACTIVE_REQUESTS_NOTIFICATIONS () {
      return this.$store.getters['featureflag/isEnabled']('INACTIVE_REQUESTS_NOTIFICATIONS')
    },

    TD_A2_3660 () {
      return this.$store.getters['featureflag/isEnabled']('TD_A2_3660')
    },

    shouldShowContactWarningPopup () {
      const DEFAULT_SHOW_STATUS = 0

      return this.hasCheckedModals &&
        this.canCheckModalQueue &&
        !this.hasActiveModal &&
        !isCandidateProfile(this.user) &&
        Boolean(this.user?.show_contact_warning_popup ?? DEFAULT_SHOW_STATUS) &&
        this.isCWPReadyForReveal
    },

    isCWPReadyForReveal () {
      return this.$store.getters.isCWPReadyForReveal
    },

    getPopupComponent () {
      let componentToRender = null

      if (this.shouldShowStartScreen) {
        componentToRender = 'start-screen'
      } else if (this.shouldShowContactWarningPopup) {
        componentToRender = 'contact-warning-popup'
      } else if (this.shouldShowLongTermBookingPopup) {
        componentToRender = 'long-term-booking-popup'
      } else if (this.shouldShowSuperProviderPopup) {
        componentToRender = 'super-provider-popup'
      } else if (this.shouldShowAffiliatePromoPopup) {
        componentToRender = 'affiliate-promo-popup'
      } else if (this.shouldShowReferralPopup) {
        componentToRender = 'affiliate-referral-popup'
      } else if (this.shouldShowRewardPopup) {
        componentToRender = 'affiliate-reward-popup'
      } else if (this.shouldShowProviderPreferencesPopup) {
        componentToRender = 'provider-preferences-popup'
      } else if (this.shouldShowInactiveRequestRewardPopup) {
        componentToRender = 'inactive-request-reward-popup'
      }

      return componentToRender
    },

    shouldShowRewardPopup () {
      return this.hasCheckedModals &&
        this.canCheckModalQueue &&
        !this.hasActiveModal &&
        !isCandidateProfile(this.user) &&
        !this.shouldShowReferralPopup &&
        !this.isAffiliateRewardSeen
    },

    shouldShowReferralPopup () {
      return this.hasCheckedModals &&
        this.canCheckModalQueue &&
        !this.hasActiveModal &&
        !isCandidateProfile(this.user) &&
        !this.shouldShowAffiliatePromoPopup &&
        !this.isAffiliateReferralSeen
    },

    shouldShowAffiliatePromoPopup () {
      return this.hasCheckedModals &&
        this.canCheckModalQueue &&
        !this.hasActiveModal &&
        !isCandidateProfile(this.user) &&
        !this.shouldShowSuperProviderPopup &&
        !this.isAffiliatePromoSeen
    },

    shouldShowSuperProviderPopup () {
      // @TODO: Work on chaining the conditions for template level components to avoid overlapping
      // - even better, use a dynamic component to render which template level components to showup
      return this.hasCheckedModals &&
        this.canCheckModalQueue &&
        !this.hasActiveModal &&
        !this.isSuperProviderReviewSeen &&
        !isCandidateProfile(this.user)
    },

    isAffiliatePromoSeen () {
      const DEFAULT_SEEN_STATUS = 1
      return Boolean(this.user?.settings?.referral_promo_popup_seen ?? DEFAULT_SEEN_STATUS)
    },

    isAffiliateRewardSeen () {
      const DEFAULT_SEEN_STATUS = 1
      return Boolean(this.user?.settings?.referral_reward_popup_seen ?? DEFAULT_SEEN_STATUS)
    },

    isAffiliateReferralSeen () {
      const DEFAULT_SEEN_STATUS = 1
      return Boolean(this.user?.settings?.referral_new_affiliate_popup_seen ?? DEFAULT_SEEN_STATUS)
    },

    isSuperProviderReviewSeen () {
      const DEFAULT_SEEN_STATUS = 1
      return Boolean(this.user?.settings?.is_super_provider_review_seen ?? DEFAULT_SEEN_STATUS)
    },

    shouldShowStartScreen () {
      const minProgress = 0
      const maxProgress = 3
      const progress = this.startScreenProgress?.progress

      return parseInt(progress) > minProgress &&
        parseInt(progress) <= maxProgress
    },

    shouldShowLongTermBookingPopup () {
      const ukCode = 'gb'
      const isUKProvider = this.homeBaseCountry?.toLowerCase() === ukCode

      return this.isProviderTwoWeeksOld &&
        !isUKProvider &&
        this.user?.settings?.is_long_term_booking_promo_popup_seen === 0
    },

    shouldShowProviderPreferencesPopup () {
      return !this.shouldShowLongTermBookingPopup && Boolean(this.user?._has_2_successful_meetings) && !Boolean(this.user?.settings?.nsfw_preferences_promo_popup_seen)
    },

    shouldShowInactiveRequestRewardPopup () {
      return this.FEATURE_INACTIVE_REQUESTS_NOTIFICATIONS && !this.shouldShowProviderPreferencesPopup && Boolean(this.user?._show_inactive_request_reward_popup)
    },

    isEventsEnabled () {
      if (this.user) {
        return !!this.user.events_enabled
      }
      return false
    },

    isDeactivated () {
      if (this.user) {
        return !!this.user.deactivated
      }
      return false
    },

    hasBottomStatusIndicator () {
      return this.showBottomStatusIndicator && !this.hasFixedMenu
    }
  },
  watch: {
    isAppOnline: {
      handler: function (online) {
        if (online) {
        } else {
          this.$notifications.toast(this.$translate('app.offline'))
        }
      },
      immediate: true
    },
    deadlineTimer (newVal) {
      if (newVal === null) {
        return false
      }

      const deadline = this.curTimeTz.clone().add(newVal, 'seconds')

      this.$store.commit('onboarding/SET_ID_DEADLINE_MOMENT', deadline)
    },
    curTimeTz (newVal) {
      // Check Provider Platform Age and set necessary variables to be used for conditions later
      this.checkProviderPlatformAge(newVal)

      const isCandidateAndCountdownEnding = this.deadlineMoment !== null &&
        $h.calcTimeDiff(newVal, this.deadlineMoment, 'seconds') <= ID_VERIFICATION_COUNTDOWN.MIN &&
        isCandidateProfile(this.user)

      if (this.TD_A2_3660) {
        let isProcessIdDeadlineRequested = false
        if (isCandidateAndCountdownEnding && !isProcessIdDeadlineRequested) {
          isProcessIdDeadlineRequested = true
          // Ensure that id deadline only gets processed once
          VerificationProvider.processIdDeadline()
            .then(() => {
              this.logoutAndRedirectToLogin()
            })
            .catch(err => {
              console.log('Failed to process id deadline verification', err)
            })
        } else {
          return false
        }
      } else {
        if (isCandidateAndCountdownEnding) {
          VerificationProvider.processIdDeadline()
            .then(() => {
              this.logoutAndRedirectToLogin()
            })
            .catch(err => {
              console.log('Failed to process id deadline verification', err)
            })
        } else {
          return false
        }
      }
    },
    userRegisterProgress (newVal) {
      this.mountIntercom()
    }
  },
  mounted () {
    if (!parseInt(process.env.TEST_MODE)) {
      this.checkPWA()
    }
    this.fetchAPIData()
    this.initializePingCheck()
    this.mountIntercom()
    this.initializeWebPushService()

    if (window.location.pathname.search('registration') === -1) {
      this.showFirstTimeLoginPopupCheck()
      this.initInternalTimer()
    }

    window.addEventListener('online', this.onAppOnline)
    window.addEventListener('offline', this.onAppOffline)
    document.addEventListener(
      'visibilitychange',
      this.onAppVisibilityChange,
      false
    )

    this.$store.dispatch('multiLocation/fetch')
  }
}
</script>

<style lang="scss">
@import "../../../sass/materialize/custom/v2/wrapper";
@import "../../../sass/materialize/custom/v2/btn";
@import "../../../sass/materialize/custom/v2/maxwidthcenter";

.has-bottom-status-indicator {
  padding-bottom: 100px;
}
</style>
