var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isViewReady
    ? _c(
        "div",
        { attrs: { id: "event-late" } },
        [
          _c("md-navbar", {
            attrs: {
              push: {
                name: "event-details",
                params: { event: _vm.selectedEvent }
              },
              brand: _vm.$translate("events.nav_title"),
              menuTrigger: false,
              icon: "arrow_back"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "content container" }, [
            !_vm.isUpdateSent
              ? _c(
                  "div",
                  { staticClass: "send-notification" },
                  [
                    _c("h1", { staticClass: "margin-top-md header" }, [
                      _vm._v(_vm._s(_vm.$translate("events.late.header")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "warning padding-md" }, [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("warning")
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$translate("events.late.warning", {
                              meeting_start_at: _vm.timeStart
                            })
                          )
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "md-select",
                      {
                        attrs: {
                          label: _vm.$translate("events.late.input_label"),
                          placeholder: _vm.$translate("events.late.input_label")
                        },
                        model: {
                          value: _vm.selectedVal,
                          callback: function($$v) {
                            _vm.selectedVal = $$v
                          },
                          expression: "selectedVal"
                        }
                      },
                      _vm._l(_vm.filteredLateOps, function(i, idx) {
                        return _c(
                          "option",
                          {
                            key: idx,
                            attrs: { slot: "options" },
                            domProps: { value: i },
                            slot: "options"
                          },
                          [
                            _vm._v(
                              "+" +
                                _vm._s(
                                  _vm.$translate("events.late.value_prefix", {
                                    val: i
                                  })
                                )
                            )
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.newTime !== _vm.timeStart
                      ? _c("div", { staticClass: "row center-align" }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                color: "#757575"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "events.late.new_meeting_start"
                                  )
                                )
                              )
                            ]
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "strong",
                            {
                              staticStyle: {
                                "font-size": "22px",
                                color: "#212121"
                              }
                            },
                            [_vm._v(_vm._s(_vm.newTime))]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "navigator row margin-top-lg" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-large no-padding btn-secondary",
                          on: {
                            click: function($event) {
                              return _vm.$router.go(-1)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$translate("events.buttons.cancel")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-large no-padding btn-krypton-pink waves-effect waves-light",
                          attrs: { disabled: !_vm.canSend },
                          on: {
                            click: function($event) {
                              return _vm.send()
                            }
                          }
                        },
                        [
                          !_vm.isSending
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$translate("events.buttons.send"))
                                )
                              ])
                            : _c(
                                "div",
                                {
                                  staticClass: "preloader-wrapper small active"
                                },
                                [_vm._m(0)]
                              )
                        ]
                      )
                    ])
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "success-notification center-align" },
                  [
                    _c("h1", { staticClass: "success margin-top-md" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$translate("events.late.success_header")) +
                          "\n      "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "sub-title" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$translate("events.late.sub_title", {
                              client_name: _vm.selectedEvent.client.name
                            })
                          ) +
                          "\n      "
                      )
                    ]),
                    _vm._v(" "),
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("alarm")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "border" }, [
                      _c("strong", { staticClass: "be-on-time" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$translate("events.late.be_on_time")) +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "notes",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$translate("events.late.notes", {
                              client_name: _vm.selectedEvent.client.name,
                              time: _vm.newTime
                            })
                          )
                        }
                      })
                    ])
                  ]
                )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner-layer spinner-green-only" }, [
      _c("div", { staticClass: "circle-clipper left" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _c("div", { staticClass: "gap-patch" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _c("div", { staticClass: "circle-clipper right" }, [
        _c("div", { staticClass: "circle" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }