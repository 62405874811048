var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(_vm.injections.components.LanguageSwitcherController, {
    tag: "component",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var data = ref.data
          var bindings = ref.bindings
          return [
            _c(
              "div",
              {
                staticClass: "lang-switcher",
                attrs: { "data-test": "lang-switch" }
              },
              [
                _c(
                  "a",
                  {
                    class: { active: data.isLocale("en") },
                    attrs: {
                      href: data.linkToEN,
                      "data-test": "lang-switch-en"
                    },
                    on: {
                      click: function($event) {
                        return bindings.setLocale("en")
                      }
                    }
                  },
                  [_vm._v("EN")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: { active: data.isLocale("de") },
                    attrs: {
                      href: data.linkToDE,
                      "data-test": "lang-switch-de"
                    },
                    on: {
                      click: function($event) {
                        return bindings.setLocale("de")
                      }
                    }
                  },
                  [_vm._v("DE")]
                )
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }