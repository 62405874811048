<template>
  <div id="primary" class="wrapper register-photos-others">
    <template>
      <div class="content__area">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <div class="content__stepper content__stepper--mobile">
          <p> {{ stepperLabel }}</p>
          <div class="helper__btn" @click.prevent="openTips">
            <span> {{ $translate('register.photos.others.help') }}</span>
          </div>
        </div>
        <h2 class="content__title content__title--desktop">{{ $translate('register.photos.others.title') }}</h2>
      </div>
      <div class="content__area drawer --with-long-content --with-both-bars">
        <div class="content">
          <div class="content__stepper content__stepper--desktop">
            <p> {{ stepperLabel }}</p>
            <div class="helper__btn" @click.prevent="openTips">
              <span>{{ $translate('register.photos.others.help') }}</span>
            </div>
          </div>

          <h2 class="content__title content__title--mobile">{{ $translate('register.photos.others.title') }}</h2>

          <p class="protip" v-html="$translate('register.photos.others.protip')" />

          <upload-other-photo
            v-if="!isPhotosReachedLimit"
            :is-register-mode="isRegisterMode"
            :photo-trigger="PHOTO_TRIGGER_VALUES.OTHER_PHOTO_PILL"
          />

          <photos-gallery />

          <register-navigator-v2
            :total="total"
            :current="current"
            :next="nextText"
            @prev.prevent="goToRegisterOverview"
            @next.prevent="publish"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import UploadOtherPhoto from '@/components/account/photos/upload-other-photo.vue'
import { PHOTO_TRIGGER_VALUES } from '@/features/registration/constants.js'
const RegisterProvider = $h.getProvider('RegisterProvider')

export default {
  components: {
    UploadOtherPhoto
  },
  props: ['total', 'current', 'stepperLabel'],
  data: () => ({
    PHOTO_TRIGGER_VALUES
  }),
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    isPhotosReachedLimit () {
      let photos = this.$store.getters.getPhotos

      if (photos.length === 0) return false

      photos = photos.filter(photo => {
        return photo.id !== 0
      })

      if (photos.length === 6) return true

      return false
    },
    isRegisterMode () {
      return this.$route.name.indexOf('register') >= 0
    },
    nextText () {
      if (!this.user) return null
      return this.user.profiles.photos.others.length
        ? this.$translate('register.photos.others.continue')
        : this.$translate('register.photos.others.skip')
    }
  },
  mounted () {
    this.initializeTips()

    if (this.$route.params.showTipOnMount) {
      this.openTips()
    }
  },
  methods: {
    goToRegisterOverview () {
      this.$router.push({ name: 'register-overview' })
    },
    publish () {
      RegisterProvider.publishPhotos()
        .then((res) => {
          this.$store.commit('setProgress', 14)
          this.$router.push({ name: 'register-overview' })
        })
        .catch((err) => {
          this.$notifications.toast(err.message, 5000)
        })
    },
    openTips () {
      this.$router.push({
        name: 'register-photo-tips',
        params: {
          title: this.$translate('register.photos.others.title'),
          inSession: true,
          isOtherPhotos: true,
          return: this.$route.path
        }
      })
    },
    closeTips () {
      $(this.$refs.modal).modal('close')
      this.destroyCarousel()
    },
    initializeTips () {
      $(this.$refs.modal).modal()
    },
    initializeCarousel () {
      $(this.$refs.carousel).carousel({
        fullWidth: true,
        indicators: true
      })
    },
    destroyCarousel () {
      $(this.$refs.carousel).carousel('destroy')
    }
  }
}
</script>
