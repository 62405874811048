<template>
  <div
    id="content"
    class="container"
    data-test="providerpreferences__city-selection">
    <bottomsheet
      :is-shown="isShown"
      @close="closeBottomSheet"
    >
      <template v-slot:body>
        <h2 class="bottom-sheet__title" v-text="$t('title')" />
        <div class="providerPreferences">
          <!-- selection pill -->
          <div
            v-for="(pp, key) in sPPCopy"
            :key="key"
            :class="['option-item', { selected: pp.selected }]"
            @click="selectPP(key)"
          >{{ pp.title }}</div>
        </div>
      </template>
      <template v-slot:footer>
        <register-stepper-v2 :total="1" :current="0" />
        <div :class="ctaContainerClass">
          <div
            class="select-count"
            data-test="pp-multi-selection-cta"
            @click="onClickMutliSelect"
            v-text="multiSelectCta"
          />
          <button
            class="cta waves-effect"
            data-test="city-selection-cta"
            @click="submit"
            v-text="saveCta"
          />
        </div>
      </template>
    </bottomsheet>
  </div>
</template>

<i18n src="@/features/provider-preferences/translations/profile-section.json"></i18n>

<script>
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'
import bottomsheet from '@components/v2/utilities/bottomsheet.vue'
import registerStepperV2 from '@components/register/utilities/stepper-v2.vue'

export default {
  components: {
    bottomsheet,
    registerStepperV2
  },
  props: {
    /**
     * If array is not empty it means user is coming from fee setup step
     */
    isShown: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data: () => ({
    hasLoaded: false,
    sPPCopy: [],
    isSelectAllClicked: false
  }),
  computed: {
    ...mapGetters({
      user: 'getUser',
      providerPreferences: 'providerPreferences/getProviderPreferences'
    }),
    noproviderPreferencesSelected () {
      return this.selectCount === 0
    },
    selectedProviderPreferences () {
      return this.user?.nsfw_preferences || []
    },
    hasUser () {
      return !isEmpty(this.user)
    },
    selectCount () {
      return this.sPPCopy.filter(i => i.selected === true).length
    },
    ctaContainerClass () {
      return 'cta-container'
    },
    saveCta () {
      return this.$t('cta_save')
    },
    multiSelectCta () {
      return this.isSelectAllClicked ? this.$t('cta_unselect_all') : this.$t('cta_select_all')
    }
  },
  watch: {
    providerPreferences: {
      deep: true,
      handler (__) {
        this.mapProviderPreferences()
      }
    }
  },
  methods: {
    closeBottomSheet () {
      this.$emit('close')
      this.mapProviderPreferences()
    },
    selectPP (key) {
      this.sPPCopy[key].selected = !this.sPPCopy[key].selected
    },
    mapProviderPreferences () {
      const selectedPPIds = this.selectedProviderPreferences.length > 0 ? this.selectedProviderPreferences.map(s => s.id) : []
      this.sPPCopy = this.providerPreferences.map(pp => {
        return {
          id: pp.id,
          title: pp.title,
          selected: selectedPPIds.includes(pp.id)
        }
      })
    },
    onClickMutliSelect () {
      // Mark selection clicked
      this.isSelectAllClicked = !this.isSelectAllClicked
      // Select/Deselect all selections
      this.providerPreferences.forEach((__, key) => {
        this.sPPCopy[key].selected = this.isSelectAllClicked
      })
    },
    submit () {
      const filteredproviderPreferencesOnlySelected = this.sPPCopy.filter(s => s.selected === true)
      this.$emit('submit', filteredproviderPreferencesOnlySelected)
    }
  }
}
</script>

<style lang="scss" scoped>
#content {
  margin-bottom: 80px;
  font-family: 'Proxima Nova';

  .bottom-sheet__title {
    margin-bottom: 32px;
  }

  .providerPreferences {
    text-align: left;
      height: calc(100% - 70px);
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      justify-content: center;
      align-self: center;
      max-width: 400px;
      color: #2F3740;
      text-align: center;
      margin: auto;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      & > .option-item {
        flex: 1 1 120px;
        height: 100px;
        width: 150px;
        border-radius: 12px;
        border: 1px solid #CED6E0;
        /* text-align: center; */
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        font-size: 18px;
        font-family: 'Proxima Nova Medium';
        padding-left: 7px;
        padding-left: 7px;

        @media (min-width: 400px) {
          flex: 1 1 150px;
        }

        &.selected {
          border: 2px solid #2F3740;
          background-color: #F7FAFF;
        }

        &.disabled {
          opacity: 0.5;
        }
      }
  }

  .cta-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    z-index: 1;

    .select-count {
      font-size: 16px;
      color: #2F3740;
      font-family: 'Proxima Nova Semibold';
      text-decoration: underline;
      cursor: pointer;
    }
    button {
      flex-basis: 50%;
      height: 47px;
      background: #2F3740;
      border-radius: 10px;
      line-height: 44px;
      font-family: 'Proxima Nova Semibold';
      font-size: 16px;
      color: #FFFFFF;
      max-width: 128px;
      text-align: center;
      border: none;

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        background: #CED6E0 !important;
        color: #FFFFFF !important;
        cursor: not-allowed;
      }
    }
  }
}
</style>
