var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "input-month-container desktop-container" }, [
      _vm._v("\n    desktop month container\n  ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "input-month-container mobile-container" }, [
      _c("label", { staticClass: "active", attrs: { for: "input-month" } }, [
        _vm._v(_vm._s(_vm.label))
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.model,
            expression: "model"
          }
        ],
        attrs: { type: "month", min: _vm.min, max: _vm.max },
        domProps: { value: _vm.model },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.model = $event.target.value
          }
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }