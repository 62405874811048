var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page wrapper", attrs: { id: "faq" } },
    [
      _c("md-new-navbar", { attrs: { "route-name": "help" } }),
      _vm._v(" "),
      _c("div", { staticClass: "menu-container" }, [
        _c(
          "div",
          { staticClass: "menu-content" },
          [
            _c("h2", [_vm._v(_vm._s(_vm.$translate("faq.title")))]),
            _vm._v(" "),
            _vm._l(_vm.filteredMenuItems, function(section, idx) {
              return _c(
                "div",
                { key: "faq-section-" + idx, staticClass: "menu-section" },
                [
                  _vm.hasChildItems(section)
                    ? _c(
                        "div",
                        { attrs: { "data-category-container": section.title } },
                        [
                          _c("h3", { staticClass: "menu-section__title" }, [
                            _vm._v(_vm._s(_vm.$translate(section.title)))
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "menus" },
                            _vm._l(section.children, function(child, idChild) {
                              return _c(
                                "li",
                                {
                                  key: "faq-section-child-" + idChild,
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.$router.push({
                                        name: child.routeName
                                      })
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "#",
                                        "data-id": child.routeName
                                      }
                                    },
                                    [
                                      _c("div", [
                                        _c("img", {
                                          attrs: {
                                            src: "/img/faq/" + child.icon,
                                            alt: "video date icon"
                                          }
                                        })
                                      ]),
                                      _vm._v(" "),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.$translate(child.title))
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("img", {
                                    attrs: {
                                      src: require("@/features/other-menu/assets/menu-icon.svg"),
                                      alt: "chevron right icon"
                                    }
                                  })
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            })
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }