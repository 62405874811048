var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "bottom-sheet-menu-backdrop": _vm.newDesign,
        "bottom-sheet-menu-backdrop_pre_existing": !_vm.newDesign,
        "--desktop-split": _vm.isDesktopDisplayModeSplit
      },
      attrs: { id: "bottom-sheet-menu" }
    },
    [
      _c(
        "div",
        {
          class: {
            "menu-container": _vm.newDesign,
            "menu-container_pre_existing": !_vm.newDesign
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "close-cta",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.onClose($event)
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  src: "/img/registration/bottom-sheet-close.svg",
                  alt: ""
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { ref: "header", staticClass: "title" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _vm._v(" "),
          _c(
            "div",
            { ref: "content", staticClass: "option-menu" },
            _vm._l(_vm.options, function(option, i) {
              return _c(
                "div",
                {
                  key: i,
                  staticClass: "option-item-container",
                  class: { disabled: option.disabled }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "option-item",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.select(option)
                        }
                      }
                    },
                    [_vm._v(_vm._s(option.text))]
                  )
                ]
              )
            }),
            0
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }