<template>
  <div id="setting" class="wrapper">
    <md-new-navbar />
    <div class="menu-container">
      <div class="menu-content">
        <h2>{{ $translate('setting.setting_title') }}</h2>
        <ul class="menus">
          <li @click.prevent="gotoPage('account-setting')">
            <a href="#">
              <div>
                <img src="./assets/account-setting.svg" alt="manage profile icon">
              </div>
              <strong>{{ $translate('setting.account_btn') }}</strong>
            </a>
            <img src="@/features/other-menu/assets/menu-icon.svg" alt="chevron right icon">
          </li>

          <li
            v-if="isVideoDateEnabled"
            :class="{'locked': isFeatureLocked}"
            @click.prevent="gotoPage('video-date-setting', true)"
          >
            <a href="#">
              <div>
                <img v-if="isFeatureLocked" src="./assets/video-date-locked-setting.svg" alt="manage profile icon">
                <img v-else src="./assets/video-date-setting.svg" alt="manage profile icon">
              </div>
              <strong>{{ $translate('video-date.settings.video_date_btn') }}</strong>
            </a>
            <img src="@/features/other-menu/assets/menu-icon.svg" alt="chevron right icon">
          </li>

          <li
            :class="{'locked': isFeatureLocked}"
            @click.prevent="gotoPage('private-place-setting', true)"
          >
            <a href="#">
              <div>
                <img v-if="isFeatureLocked" src="./assets/private-place-locked-setting.svg" alt="manage profile icon">
                <img v-else src="./assets/private-place-setting.svg" alt="manage profile icon">
              </div>
              <strong>{{ $translate('private-place.settings.private_place_btn') }}</strong>
            </a>
            <img src="@/features/other-menu/assets/menu-icon.svg" alt="chevron right icon">
          </li>

          <li
            v-if="isMultiLocationSupported"
            data-test="multiLocationSettingBtn"
            :class="{'locked': !isMultiLocationSupported}"
            @click.prevent="gotoMultilocation"
          >
            <a href="#">
              <div>
                <img v-if="!isMultiLocationSupported" src="./assets/multi-city-locked-setting.svg" alt="manage profile icon">
                <img v-else src="./assets/multi-city-setting.svg" alt="manage profile icon">
              </div>
              <strong>{{ $translate('setting.multi_location_btn') }}</strong>
            </a>
            <img src="@/features/other-menu/assets/menu-icon.svg" alt="chevron right icon">
          </li>

          <li id="krypton-connect-btn" :class="{'locked': isFeatureLocked}" @click.prevent="gotoPage('krypton-connect-setting', true)">
            <a href="#">
              <div>
                <img v-if="isFeatureLocked" src="./assets/krypton-connect-locked-setting.svg" alt="manage profile icon">
                <img v-else src="./assets/krypton-connect-setting.svg" alt="manage profile icon">
              </div>
              <strong>{{ $translate('inbox.settings.krypton_connect_setting_btn') }}</strong>
            </a>
            <img src="@/features/other-menu/assets/menu-icon.svg" alt="chevron right icon">
          </li>

          <li id="language-setting-btn" :class="{'locked': isFeatureLocked}" @click.prevent="gotoPage('language-setting')">
            <a href="#">
              <div>
                <img src="./assets/language-setting.svg" alt="language setting icon">
              </div>
              <strong>{{ $translate('setting.language_setting_btn') }}</strong>
            </a>
            <img src="@/features/other-menu/assets/menu-icon.svg" alt="chevron right icon">
          </li>

          <li
            v-if="canShowVipPhotos"
            data-test="vipPhotosSettingBtn"
            :class="{'locked': isFeatureLocked}"
            @click.prevent="gotoPage('vip-photos-setting', true)"
          >
            <a href="#">
              <div>
                <img v-if="isFeatureLocked" src="./assets/vip-photos-locked-setting.svg" alt="manage profile icon">
                <img v-else src="./assets/vip-photos-setting.svg" alt="manage profile icon">
              </div>
              <strong>VIP Photos</strong>
            </a>
            <img src="@/features/other-menu/assets/menu-icon.svg" alt="chevron right icon">
          </li>

          <li
            v-if="canShowLegalTerms"
            @click.prevent="gotoPage('legal-terms-conditions', false)"
          >
            <a href="#">
              <div>
                <img src="./assets/legal-terms-setting.svg" alt="manage profile icon">
              </div>
              <strong>{{ $translate('setting.legal_setting_title') }}</strong>
            </a>
            <img src="@/features/other-menu/assets/menu-icon.svg" alt="chevron right icon">
          </li>
        </ul>

        <span class="build-version">Build version: {{ APP_VERSION.replace(/\"/gi, '') }}</span>
        <a href="#" class="logout-link" @click.prevent="logout">
          {{ $translate('app.logout.link') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import FEATURES_LIST from '@/features'
import { LITE_EVENTS } from '@/features/lite/constants'

const AuthProvider = window?.$h?.getProvider('AuthProvider')

export default {
  name: 'Setting',
  data: () => ({
    FEATURES_LIST,
    APP_VERSION: JSON.stringify(require('../../../../../package.json').version)
  }),
  computed: {
    ...mapGetters({
      isVideoRequestEnabled: 'getIsVideoRequestEnabled',
      isCandidateProfile: 'isCandidateProfile',
      user: 'getUser',
      homeBaseCountry: 'getHomeBaseCountry'
    }),
    FEATURE_VIDEO_DATE () {
      return this.$store.getters['featureflag/isEnabled']('VIDEO_DATE')
    },
    isVideoDateEnabled () {
      return this.isVideoRequestEnabled && this.FEATURE_VIDEO_DATE
    },
    canShowLegalTerms () {
      return this.user.agree_terms === AGREEMENT_TERM.AGREED
    },
    isFeatureLocked () {
      return this.isCandidateProfile
    },
    isMultiLocationSupported () {
      if (!this.user._has_successful_meeting || this.isCandidateProfile) {
        return false
      }

      return this.$store.getters['multiLocation/isMultiLocationSupported']
    },
    canShowVipPhotos () {
      return this.FEATURE_VIP_PHOTOS_A2_2414 && this.homeBaseCountry === 'DE'
    },
    FEATURE_VIP_PHOTOS_A2_2414 () {
      return this.$store.getters['featureflag/isEnabled']('VIP_PHOTOS_A2_2414')
    }
  },
  methods: {
    gotoMultilocation () {
      if (!this.isMultiLocationSupported) {
        return $h.EventBus.$emit(LITE_EVENTS.ONFEATURELOCK)
      }

      this.$router.push({ name: 'multi-location-setting' })
    },
    gotoPage (name, restrictCandidateProfile = false) {
      if (this.isCandidateProfile && restrictCandidateProfile) {
        $h.EventBus.$emit(LITE_EVENTS.ONFEATURELOCK)
      } else {
        this.$router.push({ name })
      }
    },
    logout () {
      const options = {
        title: this.$translate('app.logout.modal_title'),
        content: this.$translate('app.logout.modal_content'),
        confirmText: this.$translate('app.logout.confirm_btn'),
        cancelText: this.$translate('app.logout.cancel_btn')
      }

      $h.invertedCustomModalConfirm(options, ($e) => {
        AuthProvider.logout(() => {
          AuthProvider.setAuthorizationHeader()
          this.$store.commit('availabilities/reset')
          this.$store.commit('billing/reset')
          this.$store.commit('events/RESET')

          this.$router.push({
            name: 'login',
            ...(
              this.$i18n.locale === 'en' && {
                params: {
                  lang: 'en'
                }
              })
          })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../features/other-menu/assets/scss/menu.scss';

#setting {
  .button {
      height: 56px;
  }
  .feature-lock-indicator {
    strong,
    .mdi,
    .material-icons {
      color: #cad1d9 !important;
    }
    .icon {
      opacity: 0.4;
    }
    &::before {
      position: absolute;
      bottom: 28px;
      z-index: 10;
      left: 28px;
    }
  }
  .mdi-video-account {
    font-size: 29px;
  }
  .flex-align-center {
    display: flex;
    align-items: center;
  }
  .krypton-connect {
    .mdi-forum {
      font-size: 24px;
      margin-right: 4px;
      vertical-align: text-top;
    }
    strong {
      vertical-align: sub;
    }
  }
}

.build-version {
  font-size: 14px;
  color: #737B85;
  margin: 40px 0 0;
}

.logout-link {
  font-size: 16px;
  color: #2F3740;
  padding: 11px 0 15px;
  margin-top: 8px;
}
</style>
