var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page wrapper", attrs: { id: "support-v2-request" } },
    [
      _vm.isNewRequest
        ? _c("support-v2-request-new", { attrs: { "ticket-id": _vm.ticketID } })
        : _c("support-v2-request-chat")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }