<template>
  <div v-if="options.length" id="bra-cup" class="wrapper">
    <template>
      <div class="content__area">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <p class="content__stepper content__stepper--mobile"> {{ stepperLabel }}</p>
        <h2 class="content__title content__title--desktop">{{ $translate('register.basic.title.bra_cup') }}</h2>
      </div>
      <div class="content__area drawer">
        <div class="content">
          <p class="content__stepper content__stepper--desktop"> {{ stepperLabel }}</p>
          <h2 class="content__title content__title--mobile">{{ $translate('register.basic.title.bra_cup') }}</h2>

          <div class="drawer-icon center-align">
            <img src="/img/registration/basic/bra-cup.svg" alt="locator icon">
          </div>

          <div class="selected-text-container">
            <p v-if="selectedText" class="selected-text center-align strong">
              {{ selectedText }}
            </p>

            <p v-else> &nbsp; </p>
          </div>

          <md-select
            v-model="bra_cup"
            class="textfield-box-lg"
            :label="$translate('register.basic.bra_cup.input_label')"
            :default="$translate('register.basic.bra_cup.input_default')"
            :placeholder="$translate('register.basic.title.bra_cup')"
            :force-mobile="true"
            desktop-display-mode="split"
          >
            <option v-for="(option, i) in options" :key="`bracup-option-${i}`" slot="options" :value="option.key">{{ option.content }}</option>
            <div v-if="errors.has('bra_cup')" slot="errors" class="input-helper red-text">
              {{ errors.first('bra_cup') }}
            </div>
          </md-select>

          <div v-if="showNatural" class="switch-container">
            <div class="natural-label">{{ $translate('register.basic.bra_cup.natural') }}</div>
            <div class=" right" :class="{
              'switch-pill--APV_REG_REDESIGN': true
            }">
              <span :class="{ 'active': natural === false }" @click.prevent="setNatural(false)">
                {{ $translate('register.basic.bra_cup.natural_no') }}
              </span>
              <span :class="{ 'active': natural }" @click.prevent="setNatural(true)">
                {{ $translate('register.basic.bra_cup.natural_yes') }}
              </span>
            </div>
          </div>
          <div v-if="errors.has('natural')" slot="errors" class="margin-top-sm input-helper red-text">
            {{ errors.first('natural') }}
          </div>

          <register-navigator-v2 :next-disabled="isCTADisabled" :total="total" :current="current" @prev.prevent="gotoPrev" @next.prevent="submit" />

        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { isEmpty } from '@/constants/helpers.js'
const LocaleProvider = $h.getProvider('LocaleProvider')
const RegisterProvider = $h.getProvider('RegisterProvider')

export default {
  props: ['total', 'current', 'stepperLabel'],
  data () {
    return {
      options: [],
      bra_cup: null,
      natural: null,
      payload: {
        bra_cup: null
      }
    }
  },
  computed: {
    isCTADisabled () {
      return isEmpty(this.payload.bra_cup)
    },
    user () {
      return this.$store.getters.getUser
    },
    selectedText () {
      if (!this.payload.bra_cup) return null

      var result = this.$store.getters.getDropdownBraCup(this.payload.bra_cup)

      if (LocaleProvider.isLocaleEn() && result.length === 1) {
        result = result + ' Cup'
      } else if (LocaleProvider.isLocaleEn() && result.length > 1) {
        result = result.slice(0, 1) + ' Cup' + result.slice(1)
      }

      return result
    },
    showNatural () {
      return this.bra_cup
    }
  },
  watch: {
    bra_cup (newval, oldval) {
      this.analyzeBraCup()
    },
    natural (newval, oldval) {
      this.analyzeBraCup()
    }
  },
  mounted () {
    this.initializePayload()
    this.initializeOptions()
  },
  methods: {
    submit () {
      this.errors.clear()

      if (this.bra_cup === null) {
        var errorMsg = this.$translate('errors.select', {
          field: this.$translate('register.basic.bra_cup.name')
        })

        this.errors.add({
          field: 'bra_cup',
          msg: errorMsg
        })

        return false
      } else if (this.natural === null) {
        this.errors.add({
          field: 'natural',
          msg: this.$translate('errors.is_natural')
        })
        return false
      }

      RegisterProvider.updateBasic('bra-cup', this.payload)
        .then((res) => {
          this.$store.commit('setUser', res.data)
          this.$router.push(this.resolveBasicRoute('dress-size'))
        })
        .catch((err) => {
          this.$notifications.toast(err.message, 5000)
        })
    },
    gotoPrev () {
      this.$router.push(this.resolveBasicRoute('weight'))
    },
    analyzeBraCup () {
      if (this.bra_cup == null || this.natural == null) { return this.payload.bra_cup = null }

      if (this.bra_cup && this.natural) { return this.payload.bra_cup = this.bra_cup + 'n' } else { return this.payload.bra_cup = this.bra_cup }
    },
    setNatural (val) {
      this.natural = val
    },
    resolveBasicRoute (type) {
      return {
        name: 'register-basic',
        params: {
          type: type
        }
      }
    },
    initializePayload () {
      this.payload.bra_cup = this.user.profiles.bra_cup

      if (!this.payload.bra_cup) return

      if (this.payload.bra_cup.length == 1) {
        this.bra_cup = this.payload.bra_cup[0]
        this.natural = false
      } else {
        this.bra_cup = this.payload.bra_cup[0]
        this.natural = true
      }
    },
    initializeOptions () {
      var cups = ['A', 'B', 'C', 'D', 'E', 'F', 'G']

      for (var i = 0; i < cups.length; i++) {
        this.options.push({
          key: cups[i],
          content: cups[i]
        })
      }
    }
  }
}
</script>
