const ApiProvider = $h.getProvider('ApiProvider')

class DropdownProvider {
  get () {
    return new Promise((resolve, reject) => {
      window.api.get({ url: ApiProvider.dropdown() },
        (success) => { resolve(success.data) },
        (err) => { reject(err.response.data) })
    })
  }
}

export default new DropdownProvider()
