import VeeValidate from 'vee-validate'

import VueRouter from 'vue-router'

import Vuex from 'vuex'

import VueCroppie from 'vue-croppie'
import 'croppie/croppie.css'

import VueTranslate from './vue-translate.js'

import VueTouch from 'vue-touch'

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VueCustomNotifications from './vue-custom-notifications.js'
import tippy from './tippy/tooltip'

import OneSignalVue from 'onesignal-vue'
import WebPushPlugin from './web-push-plugin.js'

Vue.use(VeeValidate)
Vue.use(VueRouter)
Vue.use(Vuex) // import the croppie css manually
Vue.use(VueCroppie)
Vue.use(VueTranslate)
Vue.use(VueTouch)
Vue.use(VueMoment, { moment })
Vue.use(VueCustomNotifications)
Vue.use(tippy)
Vue.use(OneSignalVue)
Vue.use(WebPushPlugin)
