<template>
  <div id="verify-upload-id" class="id-verification">
    <div id="content" class="container">
      <navbar-close :push="{ name: 'dashboard' }" />
      <h5>
        {{ $translate('verify.intro.title') }}
      </h5>
      <img src="/img/verify/id-front-placeholder.svg">
      &nbsp;&nbsp;
      <img src="/img/verify/id-back-placeholder.svg">
      <p>
        {{ $translate('verify.intro.explanation_text') }}
      </p>
      <div class="cta-fixed">
        <button class="action-btn action-btn__krypton action-btn__blocked waves-effect waves-light" @click="gotoUpload">
          <strong>
            {{ $translate('verify.intro.cta_button') }}
          </strong>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarClose from '@components/md/navbar-close.vue'

export default {
  components: {
    NavbarClose
  },
  methods: {
    gotoUpload() {
      this.$router.push({ name: 'verify-upload' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './id-verification.styles';

.id-verification {
  h5 {
    margin-top: 5px;
    margin-bottom: 40px;
  }

  p {
    margin-top: 40px;
  }
}
</style>
