<template>
  <header>
    <h2 class="title">{{ `${$translate('onboarding.greeting')} ${name}` }}!</h2>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      user: 'getUser'
    }),
    name () {
      return this.user.profiles?.name
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  height: 187px;
  color: white;
  background: transparent linear-gradient(113deg, #7638FA 0%, #D300C5 46%, #FF0073 100%) 0% 0% no-repeat padding-box;
  padding: 54px 24px 0;

  h2.title {
    margin-top: 14px;
  }
}

</style>
