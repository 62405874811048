<template>
  <div>
    <div>
      <div id="overview" class="wrapper">
        <div class="content__area">
          <img id="k-logo" src="/img/registration/k-logo.svg" alt="girl with heart graphics">
          <h2 class="content__title content__title--desktop">{{ `${headerLine1} ${headerLine2}` }}</h2>
        </div>
        <div class="content__area no-actions drawer max-height-mobile">
          <div class="content">
            <h2 class="content__title content__title--mobile content__title--steppers">{{ headerLine1 }} <br> {{ headerLine2 }}</h2>
            <div class="step left-align margin-bottom-lg" :class="getStepClass(REGISTRATION_STEPS.STEP_1)">
              <div v-if="isStepDone(REGISTRATION_STEPS.STEP_1)" class="right" style="line-height: 95px">
                <img src="/img/registration/ic-check-circle-dark.svg" class="step-check" width="35">
              </div>
              <div class="step__label">
                {{ $translate('register.overview.step_1.label') }}
              </div>
              <div class="step__title">{{ $translate('register.overview.step_1.title') }}</div>
              <div v-if="! isStepDone(REGISTRATION_STEPS.STEP_1)" class="step__subtitle">{{ $translate('register.overview.step_1.subtitle') }}</div>
              <a v-if="isStepDone(REGISTRATION_STEPS.STEP_1)"
                 class="edit-link"
                 href="#"
                 @click.prevent="pushToStep('register-basic', { type: 'city' })">
                {{ $translate('register.overview.edit') }}
              </a>
              <div class="margin-top-md">
                <button class="btn btn__overview btn-primary btn-blocked"
                        @click.prevent="pushToStep('register-basic', { type: 'city' })">
                  {{ $translate('register.overview.continue') }}
                </button>
              </div>
            </div>
            <div class="step left-align margin-bottom-lg" :class="getStepClass(REGISTRATION_STEPS.STEP_2)">
              <div v-if="isStepDone(REGISTRATION_STEPS.STEP_2)" class="right" style="line-height: 95px">
                <img src="/img/registration/ic-check-circle-dark.svg" class="step-check" width="35">
              </div>
              <div class="step__label">
                {{ $translate('register.overview.step_2.label') }}
              </div>
              <div class="step__title">{{ $translate('register.overview.step_2.title') }}</div>
              <div v-if="! isStepDone(REGISTRATION_STEPS.STEP_2)" class="step__subtitle">{{ $translate('register.overview.step_2.subtitle') }}</div>
              <a v-if="isStepDone(REGISTRATION_STEPS.STEP_2)" class="edit-link" href="#" @click.prevent="pushToPhotos">
                {{ $translate('register.overview.edit') }}
              </a>
              <div class="margin-top-md">
                <button class="btn btn__overview btn-primary btn-blocked"
                        @click.prevent="pushToPhotos">
                  {{ $translate('register.overview.continue') }}
                </button>
              </div>
            </div>
            <div class="step left-align margin-bottom-lg" :class="getStepClass(REGISTRATION_STEPS.STEP_3)">
              <div v-if="isStepDone(REGISTRATION_STEPS.STEP_3)" class="right" style="line-height: 95px">
                <img src="/img/registration/ic-check-circle-dark.svg" class="step-check" width="35">
              </div>
              <div class="step__label">
                {{ $translate('register.overview.step_3.label') }}
              </div>
              <div class="step__title">{{ $translate('register.overview.step_3.title') }}</div>
              <div v-if="! isStepDone(REGISTRATION_STEPS.STEP_3)" class="step__subtitle">{{ $translate('register.overview.step_3.subtitle') }}</div>
              <a v-if="isStepDone(REGISTRATION_STEPS.STEP_3)"
                 class="edit-link"
                 href="#"
                 @click.prevent="pushToStep('register-personality', { type: 'intellect' })">
                {{ $translate('register.overview.edit') }}
              </a>
              <div class="margin-top-md">
                <button class="btn btn__overview btn-primary btn-blocked"
                        @click.prevent="pushToStep('register-personality', { type: 'intellect' })">
                  {{ $translate('register.overview.continue') }}
                </button>
              </div>
            </div>
            <button
              v-if="isStepDone(REGISTRATION_STEPS.STEP_3)"
              class="btn btn-btn__overview btn-primary btn-blocked"
              @click.prevent="redirectToLastStep">
              {{ $translate('register.overview.last_step') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
const REGISTRATION_STEPS = {
  STEP_1: 1,
  STEP_2: 2,
  STEP_3: 3
}

export default {
  data: () => ({
    REGISTRATION_STEPS
  }),
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    getStepClass () {
      return (step) => {
        switch (step) {
          case 1:
            if (this.isStepActive(REGISTRATION_STEPS.STEP_1)) {
              return 'active'
            } else if (this.isStepDone(REGISTRATION_STEPS.STEP_1)) {
              return 'done'
            }
            break
          case 2:
            if (this.isStepActive(REGISTRATION_STEPS.STEP_2)) {
              return 'active'
            } else if (this.isStepDone(REGISTRATION_STEPS.STEP_2)) {
              return 'done'
            }
            break
          case 3:
            if (this.isStepActive(REGISTRATION_STEPS.STEP_3)) {
              return 'active'
            } else if (this.isStepDone(REGISTRATION_STEPS.STEP_3)) {
              return 'done'
            }
            break
        }
      }
    },
    isStepDone () {
      return (step) => {
        if (step === REGISTRATION_STEPS.STEP_1 && this.user.register_progress > 11) return true
        if (step === REGISTRATION_STEPS.STEP_2 && this.user.register_progress > 13) return true
        if (step === REGISTRATION_STEPS.STEP_3 && this.user.register_progress > 18) return true
        return false
      }
    },
    isStepActive () {
      return (step) => {
        if (this.user.register_progress <= 11) return step === 1
        if (this.user.register_progress <= 13) return step === 2
        if (this.user.register_progress <= 18) return step === 3
      }
    },
    headerLine1 () {
      if (this.isStepActive(REGISTRATION_STEPS.STEP_1)) {
        return this.$translate('register.overview.header_line_1')
      } else if (this.isStepActive(REGISTRATION_STEPS.STEP_2)) {
        return this.$translate('register.overview.header_line_2_1')
      } else if (this.isStepActive(REGISTRATION_STEPS.STEP_3)) {
        return this.$translate('register.overview.header_line_3_1')
      } else {
        return this.$translate('register.overview.header_line_4_1')
      }
    },
    headerLine2 () {
      if (this.isStepActive(REGISTRATION_STEPS.STEP_1)) {
        return this.$translate('register.overview.header_line_2')
      } else if (this.isStepActive(REGISTRATION_STEPS.STEP_2)) {
        return this.$translate('register.overview.header_line_2_2')
      } else if (this.isStepActive(REGISTRATION_STEPS.STEP_3)) {
        return this.$translate('register.overview.header_line_3_2')
      } else {
        return this.$translate('register.overview.header_line_4_2')
      }
    }
  },
  methods: {
    redirectToLastStep () {
      this.$router.push({ name: 'register-potential' })

      return false
    },
    pushToPhotos () {
      const primaryNotExists = this.user.profiles.photos.primary == null &&
        this.user.profiles.photos.other_primary == null

      if (!this.user || primaryNotExists) {
        this.pushToStep('register-photos', { type: 'primary', showTipOnMount: false })
      } else {
        this.pushToStep('register-photos', { type: 'others', showTipOnMount: false })
      }
    },
    pushToStep (route, params) {
      this.$router.push({
        name: route,
        params: params
      })
    }
  }
}
</script>
