var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "event-card--cancelled": _vm.isCancelled,
        "event-card--rejected": _vm.isRejected,
        soon: _vm.isSoon
      },
      attrs: { id: "event-card" }
    },
    [
      _c("input", {
        attrs: { name: "meeting-date-time", type: "hidden" },
        domProps: { value: _vm.payload.meeting_start_at }
      }),
      _vm._v(" "),
      _vm.idx === 0
        ? _c("div", { staticClass: "card-controls grey-text clearfix" }, [
            _c("div", { staticClass: "card-date float-left" }, [
              _vm.isPending || _vm.isReactivated
                ? _c("small", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$translate("apv-card.pending")) +
                        "\n      "
                    )
                  ])
                : _c("small", [
                    _vm._v("\n        " + _vm._s(_vm.month) + "\n      ")
                  ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "view-wrapper",
          on: {
            click: function($event) {
              return _vm.gotoEventDetail()
            }
          }
        },
        [
          _vm.canShowDefaultView
            ? _c("default-card-view", {
                attrs: {
                  "show-countdown": _vm.showCountdown,
                  payload: _vm.payload
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isPending
            ? _c("pending-card-view", {
                attrs: {
                  "show-countdown": _vm.showCountdown,
                  payload: _vm.payload
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.canShowCancelled
            ? _c("cancelled-card-view", { attrs: { payload: _vm.payload } })
            : _vm._e(),
          _vm._v(" "),
          _vm.isReadyToGo
            ? _c("ready-to-go-card-view", {
                attrs: {
                  "show-countdown": _vm.showCountdown,
                  payload: _vm.payload
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isReactivated
            ? _c("reactivate-card-view", {
                attrs: {
                  "show-countdown": _vm.showCountdown,
                  payload: _vm.payload
                }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }