<template>
  <transition name="slide">
    <div id="event-cancelled">
      <md-navbar
        :push="{ name: 'events' }"
        :brand="$translate('events.nav_title')"
        icon="arrow_back"
      />
      <div
        id="content"
        class="container"
      >
        <!-- Step 1 -->
        <div
          v-if="currentStep === PAGE_STEPS.STEP_1"
          class="step-1"
        >
          <h5 class="margin-top-none">
            {{ $translate('events.event_cancelled.heading_1') }}
          </h5>
          <p id="event-cancelled-client-name" class="subheading">
            <strong>
              {{ $translate('events.event_cancelled.subheading_1', { client_name: clientName }) }}
            </strong>
          </p>
          <event-card
            :idx="-1"
            :payload="selectedEvent"
            :is-clickable="false"
            :show-countdown="false"
          />
          <p id="event-cancelled-amount-payable" class="margin-bottom-lg margin-top-lg">
            {{ $translate('events.event_cancelled.cancellation_note', {
              amount_payable: amountPayable
            }) }}
          </p>
          <div class="navigator row margin-top-lg">
            <button
              id="event-cancelled-abort-step-1" class="btn btn-large btn-secondary waves-effect waves-dark"
              @click="doAbort"
            >
              <span v-if="!isAborting">
                {{ $translate('events.event_cancelled.cta_button.revoke') }}
              </span>
              <div
                v-else
                class="preloader-wrapper small active"
              >
                <div class="spinner-layer spinner-green-only">
                  <div class="circle-clipper left">
                    <div class="circle" />
                  </div>
                  <div class="gap-patch">
                    <div class="circle" />
                  </div>
                  <div class="circle-clipper right">
                    <div class="circle" />
                  </div>
                </div>
              </div>
            </button>
            <button
              class="btn btn-large btn-krypton-pink waves-effect waves-light"
              @click="nextStep"
            >
              {{ $translate('events.event_cancelled.cta_button.continue') }}
            </button>
          </div>
          <small id="event-cancelled-cancellation-countdown" class="footer-note">
            {{ $translate('events.event_cancelled.footer_note', { time_remaining: cancellationCountdown }) }}
          </small>
        </div>
        <!-- Step 2 -->
        <div
          v-if="currentStep === PAGE_STEPS.STEP_2"
          class="step-2"
        >
          <h5 class="margin-top-none">
            {{ $translate('events.event_cancelled.heading_2') }}
          </h5>
          <p>
            {{ $translate('events.event_cancelled.subheading_2', { client_name: clientName }) }}
          </p>
          <div
            v-if="error"
            class="grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align"
          >
            <small>{{ error }}</small>
          </div>
          <md-textarea
            v-model="message"
            type="multiline"
            :maxlength="answerMaxChar"
            :force-active="true"
            :label="$translate('events.event_cancelled.form.textarea_placeholder')"
          >
            <div>
              <span class="input-helper grey-text text-darken-1 right">
                {{ getMaxChar(message) }}
              </span>
            </div>
          </md-textarea>
          <div class="row margin-top-md">
            <button
              id="event-cancelled-abort-step-2" class="btn btn-large btn-full btn-secondary waves-effect waves-dark margin-bottom-lg"
              @click="doAbort"
            >
              <span v-if="!isAborting">
                {{ $translate('events.event_cancelled.cta_button.revoke_cancellation') }}
              </span>
              <div
                v-else
                class="preloader-wrapper small active"
              >
                <div class="spinner-layer spinner-green-only">
                  <div class="circle-clipper left">
                    <div class="circle" />
                  </div>
                  <div class="gap-patch">
                    <div class="circle" />
                  </div>
                  <div class="circle-clipper right">
                    <div class="circle" />
                  </div>
                </div>
              </div>
            </button>
            <br>
            <button
              id="event-cancelled-confirm-step-2"
              class="btn btn-large btn-full btn-krypton-pink waves-effect waves-light"
              @click="doConfirmCancellation"
            >
              <span v-if="!isConfirming">
                {{ $translate('events.event_cancelled.cta_button.send_cancellation') }}
              </span>
              <div
                v-else
                class="preloader-wrapper small active"
              >
                <div class="spinner-layer spinner-green-only">
                  <div class="circle-clipper left">
                    <div class="circle" />
                  </div>
                  <div class="gap-patch">
                    <div class="circle" />
                  </div>
                  <div class="circle-clipper right">
                    <div class="circle" />
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'

import NullSelectCheck from '../mixins/nullSelectCheck.js'

import EventCard from '../event-card'

export const PAGE_STEPS = {
  STEP_1: 1,
  STEP_2: 2
}

export default {
  beforeRouteLeave (to, from, next) {
    if (this.selectedEvent) {
      if (this.selectedEvent._cancellation_expiration_in_seconds > 0) {
        const modalContent = {
          title: this.$translate('events.event_cancelled.modal.leave_confirmation.title'),
          content: this.$translate('events.event_cancelled.modal.leave_confirmation.content'),
          cancelText: this.$translate('events.event_cancelled.cta_button.stay'),
          confirmText: this.$translate('events.event_cancelled.cta_button.cancel'),
          reverse: true
        }

        $h.newCustomModalConfirm(modalContent, () => {
          next()
        })
      } else {
        next()
      }
    } else {
      next()
    }
  },
  components: {
    EventCard
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    }),
    getCharCount (answer) {
      return (answer) => {
        if (!answer) return 0
        return answer.length
      }
    },
    getMaxChar () {
      return (answer) => {
        return this.getCharCount(answer) + '/' + this.answerMaxChar
      }
    },
    cancellationCountdown () {
      if (this.isViewReady) {
        if (this.selectedEvent._cancellation_expiration_in_seconds > 0) {
          if (this.selectedEvent._cancellation_expiration_in_seconds >= 3600) {
            return moment().hour(0)
              .minutes(0)
              .second(this.selectedEvent._cancellation_expiration_in_seconds)
              .format('HH[h] : mm[m] : ss[s]')
          } else {
            return moment().hour(0)
              .minutes(0)
              .second(this.selectedEvent._cancellation_expiration_in_seconds)
              .format('mm[m] : ss[s]')
          }
        }
      }
      return '00h : 00m : 00s'
    },
    amountPayable () {
      if (this.isViewReady) {
        return this.selectedEvent.amount_payable
      }
      return 0
    },
    clientName () {
      if (this.isViewReady) {
        return this.selectedEvent.client.name
      }
      return ''
    },
    isRequesting () {
      return this.isAborting || this.isConfirming
    }
  },
  mixins: [NullSelectCheck],
  data: () => ({
    PAGE_STEPS,

    answerMaxChar: 160,
    message: '',
    error: '',
    currentStep: PAGE_STEPS.STEP_1,
    isAborting: false,
    isConfirming: false
  }),
  mounted () {
    this.$store.dispatch('events/requestCancellationSeen', this.selectedEvent.id)
  },
  methods: {
    gotoEventsOverview () {
      this.$router.replace({ name: 'events' })
    },
    nextStep () {
      this.currentStep++
    },
    doAbort () {
      if (this.isRequesting) return

      this.isAborting = true

      this.$store
        .dispatch('events/abortRequestCancellation', this.selectedEvent.id)
        .then(() => {
          if (this.user.availability_enabled) {
            this.$store.dispatch(
              'availabilities/refreshAvailabilities',
              false
            )
          }
          $h.closedEventTimeoutModal(
            this.selectedEvent,
            $h.CLOSED_EVENT_MODAL_TYPE.ABORT,
            this.gotoEventsOverview
          )
        })
        .catch((err) => {
          const message = err?.response?.data?.data?.message || err?.response?.data?.message || err?.message

          this.$notifications.toast(message, 5000)
        })
        .finally(() => {
          this.isAborting = false
        })
    },
    doConfirmCancellation () {
      if (this.isRequesting) return
      if (this.message.length < 50) {
        this.error = this.$translate('events.event_cancelled.input_required', {
          client_name: this.clientName
        })
        return
      }

      this.error = ''
      this.isConfirming = true

      const params = {
        eventID: this.selectedEvent.id,
        cancellation_message: this.message
      }

      this.$store
        .dispatch('events/confirmRequestCancellation', params)
        .then(() => {
          if (this.user.availability_enabled) {
            this.$store.dispatch(
              'availabilities/refreshAvailabilities',
              false
            )
          }
          $h.closedEventTimeoutModal(
            this.selectedEvent,
            $h.CLOSED_EVENT_MODAL_TYPE.SUCCESS,
            this.gotoEventsOverview
          )
        })
        .catch((err) => {
          const message = err?.response?.data?.data?.message || err?.response?.data?.message || err?.message

          this.$notifications.toast(message, 5000)
        })
        .finally(() => {
          this.isConfirming = false
        })
    }
  }
}
</script>
