var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "android-pwa-animation" }, [
    _c("div", { staticClass: "contain" }, [
      _c("div", { staticClass: "content" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("installKrypton")))]),
        _vm._v(" "),
        _c("p", [
          _c("span", [_vm._v(_vm._s(_vm.$t("appDescription_new")))]),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "contentline-desc" }, [
            _vm._v(_vm._s(_vm.$t("contentline_description")))
          ])
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "intro-img",
          attrs: {
            src: "/img/pwa/screen/android/android-download-instructions.jpg"
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "steps" }, [
          _c("ul", [
            _c("li", [
              _c("img", {
                staticClass: "forward-img one",
                attrs: { src: "/img/pwa/icon/android/three-dots.svg" }
              }),
              _vm._v(" "),
              _c("p", [_vm._v("1.) " + _vm._s(_vm.$t("contentLine1_new")))])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("img", {
                staticClass: "forward-img two",
                attrs: { src: "/img/pwa/icon/android/download-pwa-icon.svg" }
              }),
              _vm._v(" "),
              _c("p", [_vm._v("2.) " + _vm._s(_vm.$t("contentLine2_new")))])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }