import { buildParam } from '@/services/ApiService'
export const updateUserLangEndpoint = '/v2/user/language'

export const updateUserLanguage = (params) => {
  return new Promise((resolve, reject) => {
    window.api.put({ url: buildParam(updateUserLangEndpoint), payload: params },
      (success) => { resolve(success.data) },
      (err) => { reject(err.response.data) })
  })
}
