<template>
  <div id="block-client" class="modal left-align">
    <div class="modal-content center-align">
      <h4 class="modal-title" v-html="$translate('inbox.block.title')" />
      <i class="mdi mdi-account-off krypton-pink-text valign-middle" style="font-size: 72px;" /><br>
      <p class="content grey-text text-darken-1" v-html="content" />
    </div>
    <div class="modal-footer">
      <button class="btn-flat waves-effect waves-dark modal-close" @click="cancel">
        {{ $translate('inbox.cta_buttons.cancel') }}
      </button>
      <button class="btn-flat waves-effect waves-dark krypton-pink-text" @click="confirm">
        <div v-if="isLoading" class="preloader-wrapper small active">
          <div class="spinner-layer spinner-green-only">
            <div class="circle-clipper left">
              <div class="circle" />
            </div><div class="gap-patch">
              <div class="circle" />
            </div><div class="circle-clipper right">
              <div class="circle" />
            </div>
          </div>
        </div>
        <span v-else>{{ $translate('inbox.cta_buttons.confirm') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    isLoading: false
  }),
  methods: {
    confirm ($e) {
      if (this.isLoading) return

      this.$emit('confirm', $e)

      // Fake out loading for API
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    cancel () {
      if (this.isLoading) return

      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss">
#block-client {
  @media screen and (max-width: 320px) {
    .content {
      margin: 0 !important;
    }
    .modal-footer .btn-flat {
      font-size: 14px !important;
    }
  }
}
</style>
