var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "dashboard" } },
    [
      _c("gradient-header"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "container",
          class: {
            "bottom-space": _vm.addBottomSpace
          },
          attrs: { id: "content" }
        },
        [
          _vm.shouldShowTipsAndInfos ? _c("tips-and-infos") : _vm._e(),
          _vm._v(" "),
          _c("performance-overview"),
          _vm._v(" "),
          _vm.isAvailabilityEnabled
            ? _c("div", { staticClass: "margin-y-xl" }, [
                _c("div", { staticClass: "section-label" }, [
                  _c("img", {
                    attrs: {
                      src: "/img/dashboard/dashboard-availability-icon.svg",
                      alt: "tips and infos icon"
                    }
                  }),
                  _vm._v(" "),
                  _c("h2", [
                    _vm._v(_vm._s(_vm.$translate("availability.title")))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col s12" }, [
                    _c("div", { staticClass: "overview-table" }, [
                      _c("div", { staticClass: "overview-table--title" }, [
                        _c("div", { staticClass: "overview-table--block" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$translate("availability.day_short.monday")
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "overview-table--block" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$translate("availability.day_short.tuesday")
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "overview-table--block" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$translate(
                                  "availability.day_short.wednesday"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "overview-table--block" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$translate(
                                  "availability.day_short.thursday"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "overview-table--block" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$translate("availability.day_short.friday")
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "overview-table--block" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$translate(
                                  "availability.day_short.saturday"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "overview-table--block" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$translate("availability.day_short.sunday")
                              ) +
                              "\n              "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "overview-table--schedule summary",
                          staticStyle: { "margin-bottom": "14px" }
                        },
                        _vm._l(_vm.availabilitiesOverview, function(
                          status,
                          idx
                        ) {
                          return _c("div", {
                            key: idx,
                            staticClass: "overview-table--block",
                            class: _vm.getClassAccordingToStatus(status)
                          })
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "left margin-top-sm" }, [
                        _c("div", {
                          staticClass: "overview-indicator",
                          class: _vm.getClassAccordingToEmotion(
                            _vm.filledAvailabilityCount
                          )
                        }),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.filledAvailabilityCount))
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$translate("availability.of")) + " "
                        ),
                        _c("strong", [_vm._v("14")])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "right",
                          staticStyle: { "margin-right": "-6px" }
                        },
                        [
                          _c(
                            "vlink",
                            {
                              staticClass: "waves-effect waves-dark padding-sm",
                              staticStyle: {
                                "vertical-align": "middle",
                                "margin-top": "1px"
                              },
                              attrs: { to: { name: "availability" } }
                            },
                            [
                              _c("small", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$translate("availability.set_more")
                                    )
                                  )
                                ])
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("news-list")
        ],
        1
      ),
      _vm._v(" "),
      _c("fixed-menu"),
      _vm._v(" "),
      !_vm.showBottomStatusIndicator
        ? _c(
            "div",
            [
              _vm.shouldDisplayConnectPromo
                ? _c("krypton-connect-promo-banner")
                : _vm._e(),
              _vm._v(" "),
              _vm.shouldDisplayPrivatePlacePromoBanner
                ? _c("private-place-promo-banner")
                : _vm._e(),
              _vm._v(" "),
              _vm.shouldDisplayMultiLocationPromo_PRE_A2_1815
                ? _c("multi-location-promo-banner")
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }