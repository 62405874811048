<template>
  <div id="video-date-faq" class="page">
    <md-navbar
      :push="{ name: 'faq' }"
      :brand="$translate('faq.title')"
      icon="arrow_back"
    />
    <div>
      <div class="container">
        <!-- Video Date FAQ Header -->
        <faq-header
          :header-title="$translate('video-date.landing_page.q_and_a.faq_title')"
          :header-tagline="getVDateTagline"
          main-header-logo="/img/video-date/videodatefaqheadericon.svg"
          primary-header-color="#5611C5"
          secondary-header-color="#7C15D6"
          header-gradient-angle="90deg"
          tagline-gradient-angle="90deg"
          :header-img-dimensions="{ height: '107px', width: '180px' }"
        />
        <!-- Video Date FAQ Items -->
        <div id="content" data-test="faq-q-and-a-section">
          <div class="q-and-a">
            <ul>
              <li
                v-for="i in 9"
                :key="i"
                class="item"
              >
                <div class="question">
                  <span class="question-number">{{ `${i}.` }}</span> <span>{{ $translate(`video-date.landing_page.q_and_a.items.item_${i}.question`) }}</span>
                </div>
                <div class="answer">
                  <p>
                    {{ $translate(`video-date.landing_page.q_and_a.items.item_${i}.answer`) }} <a
                      v-if="i === 9"
                      href="#"
                      @click.prevent="$router.replace({ name: 'support-v2' })"
                    ><strong>{{ $translate('video-date.cta_button.support') }}</strong>.</a>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FAQHeader from '@/features/provider-faq/components/FAQHeader.vue'

export default {
  components: {
    'faq-header': FAQHeader
  },
  computed: {
    getVDateTagline () {
      return `${this.$translate('video-date.landing_page.explanation.headline')}<br/><br/>${this.$translate('video-date.landing_page.explanation.subtitle')}`
    }
  }
}
</script>

<style lang="scss" scoped>
  #video-date-faq {
    #content {
      text-align: left;
      font-size: 14px;
      padding-left: 32px;
      padding-right: 32px;
      color: #2F3740;

      .title {
        font-size: 22px;
      }
      .faq-img-container {
        padding: 30px 8px;
        background-color: #F5F7FA;
        border: 1px solid #E1E6EB;
        border-radius: 8px;

        img {
          margin: 0 auto;
          display: block
        };
      }
      .q-and-a {
      li {
        margin: 0;
        text-align: left;
      }

      li:not(:last-child) {
        border-bottom: 1px solid #CAD1D9;
      }

      li:not(:first-child) {
        padding-top: 18px;
      }

      .question {
        display: flex;
        font-size: 20px;
        font-family: 'Proxima Nova Semibold';
        font-weight: 600;
        color: #2E353D;
        margin: 0;

        .question-number {
          color: #C51162;
          margin-right: 8px;
        }
      }

      .answer {
        color: #2F3740;
        font-size: 18px;
        margin: 0;
        margin-left: 18px;
        padding-top: 18px;
        padding-bottom: 18px;

        p {
          margin: 0;
        }
      }
    }
    }
  }
  </style>
