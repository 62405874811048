<template>
  <div v-if="showPopup" id="inactive-request-reward-popup">
    <div class="popup__backdrop" data-test="ir-popup-backdrop">
      <div class="popup__modal">
        <div class="popup__title">
          <a href="#" class="popup__close-btn" data-test="sp-popup-close" @click.prevent="closePopup">
            <i class="material-icons">close</i>
          </a>
          <h2
            class="title"
            data-test="popup-title"
            v-text="$t('title')"
          />
        </div>
        <div class="popup-img-container">
          <img class="responsive-img" :src="require('@/features/inactive-request-rewards/assets/ir-header-img.svg')">
        </div>
        <div
          class="popup__description_title"
          data-test="popup-explanation-title"
          v-text="$t('description_title')"
        />
        <div
          class="popup__description"
          data-test="popup-explanation-text"
          v-text="$t('description')"
        />
      </div>
    </div>
  </div>
</template>

  <i18n src="@/features/inactive-request-rewards/translations/popup.json"></i18n>

<script>
import { putInactiveRequestRewardSeen } from '@/features/inactive-request-rewards/services/InactiveRequestRewardService.js'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    showPopup () {
      // Value should be false by default - should only depend on the value of show inactive request popup
      const DEFAULT_STATUS = 0
      return Boolean(this.user?._show_inactive_request_reward_popup ?? DEFAULT_STATUS)
    }
  },
  methods: {
    updateStoreForInactiveRequestRewardPopupSeen () {
      this.$store.commit('setUserInactiveRequestRewardPopupSeen', 0)
    },
    closePopup (actions = null) {
      // Spam protection
      if (this.isLoading) return false

      this.isLoading = true
      return putInactiveRequestRewardSeen()
        .then(() => {
          this.loading = false
          this.updateStoreForInactiveRequestRewardPopupSeen()
        })
        .catch(err => {
          this.loading = false
          this.handleErrorMessages(err)
        })
    },
	  handleErrorMessages (err = ({ message: '' })) {
      this.$notifications.toast(err?.message, 5000)
    }
  }
}
</script>

  <style lang="scss" scoped>
  #inactive-request-reward-popup {
	font-family: 'Proxima Nova';

	.popup__backdrop {
	  position: fixed;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background: rgba(#000000, 0.7);
	  z-index: 1000;
	  overflow: hidden;
	  display: flex;
	  justify-content: center;
	  align-items: center;

	.popup__close-btn {
		position: absolute;
		top: 6px;
		right: 6px;
		font-size: 24px;
		color: #8995A3;
		height: 48px;
		width: 48px;
		display: flex;
		justify-content: center;
		align-items: center;

    }

	  .popup__modal {
		position: relative;
		width: 100%;
		background-color: #FFFFFF;
		box-shadow: 0px 3px 6px #00000029;
		font-family: 'Proxima Nova Semibold';
		border-radius: 12px;
		max-width: 568px;
		margin: 0 auto;

	  	@media screen and (max-width: 600px) {
			margin: 0 16px;
			width: 94%;
        }

		@media screen and (max-width: 420px) {
			width: 92%;
		}

		.popup__title {
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  font-size: 24px;
		  padding-top: 50px;
		  padding-left: 24px;
		  padding-right: 24px;
		  font-family: 'Proxima Nova Semibold';

		  h2 {
			margin: 0;
			font-size: 24px;
			font-family: 'Proxima Nova Semibold', sans-serif;
			color: #2F3740;
		  }
		}

		.popup-img-container {
			text-align: center;
			padding: 24px;
		}

		.popup__description_title {
		  font-size: 20px;
		  color: #2F3740;
		  text-align: center;
		  margin: 0;
		  font-family: 'Proxima Nova Semibold', sans-serif;
		  padding-left: 24px;
		  padding-right: 24px;
		}

		.popup__description {
		  font-size: 18px;
		  color: #2F3740;
		  padding-top: 24px;
		  padding-left: 24px;
		  padding-right: 24px;
		  text-align: center;
		  margin: 0;
		  padding-bottom: 50px;
		  font-family: 'Proxima Nova', sans-serif;
		}

		.popup__actions {
		  border-top: 1px solid #CAD1D9;
		  display: flex;
		  padding: 24px 32px;
		  justify-content: space-between;
		}

	  }
	}
  }
  </style>
