var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("LoginController", {
    tag: "component",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var data = ref.data
          var bindings = ref.bindings
          var actions = ref.actions
          return [
            _c(
              "AuthView",
              {
                tag: "component",
                attrs: { data: data },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c("h2", [
                            _vm._v(_vm._s(actions.translate("login.title1")))
                          ]),
                          _vm._v(" "),
                          _c("h2", [
                            _vm._v(_vm._s(actions.translate("login.title2")))
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _vm._v(" "),
                _c(
                  "apv-form",
                  {
                    attrs: {
                      "is-loading": data.isLoading,
                      novalidate: "",
                      "data-test": "login-form"
                    },
                    on: { submit: bindings.handleLogin }
                  },
                  [
                    _c("apv-input", {
                      staticClass: "margin-bottom-md",
                      attrs: {
                        value: data.username,
                        "data-test": "input-email",
                        type: "email",
                        autocomplete: "username",
                        tabindex: "1",
                        label: actions.translate("login.email"),
                        error: actions.getFieldError("email")
                      },
                      on: { input: bindings.handleUsernameChange }
                    }),
                    _vm._v(" "),
                    _c("apv-input", {
                      staticClass: "margin-bottom-lg",
                      attrs: {
                        value: data.password,
                        "data-test": "input-password",
                        type: "password",
                        autocomplete: "password",
                        tabindex: "2",
                        label: actions.translate("login.password"),
                        error: actions.getFieldError("password")
                      },
                      on: { input: bindings.handlePasswordChange }
                    }),
                    _vm._v(" "),
                    _c(
                      "apv-button",
                      {
                        staticClass: "margin-bottom-lg",
                        attrs: {
                          rounded: 10,
                          "is-loading": data.isLoading,
                          block: "",
                          gradient: "",
                          type: "submit",
                          size: "large",
                          "waves-effect": "dark",
                          disabled:
                            bindings.isEmpty(data.username) ||
                            bindings.isEmpty(data.password),
                          "data-test": "login-cta"
                        }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              actions.translate("messages.globals.continue")
                            ) +
                            "\n      "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "footer-auth-nav" }, [
                  _c(
                    "div",
                    { staticClass: "forgot-pass-container" },
                    [
                      bindings.PROVIDER_MAGIC_LINKS
                        ? _c(
                            "router-link",
                            {
                              staticClass: "forgot-pass",
                              attrs: { to: data.requestMagicLinkRoute }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  actions.translate("login.forgotPassword")
                                )
                              )
                            ]
                          )
                        : _c(
                            "router-link",
                            {
                              staticClass: "forgot-pass",
                              attrs: { to: data.forgotPasswordRoute }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  actions.translate("login.forgotPassword")
                                )
                              )
                            ]
                          )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "signup-container" },
                    [
                      _c("span", { staticClass: "signup-label" }, [
                        _vm._v(_vm._s(actions.translate("login.noAccountYet")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "signup-link",
                          attrs: { to: data.registerRoute }
                        },
                        [_vm._v(_vm._s(actions.translate("login.signUp")))]
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }