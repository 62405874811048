<template>
  <div id="mobile-setting">
    <bottomsheet :is-shown="isShown" :class="{'max-z-index': countryPickerShown}" @close="reset">
      <template v-slot:body>
        <h2 class="bottom-sheet__title">{{ $translate('setting.mobile_setting_title') }}</h2>
        <vform ref="form" style="margin-bottom: 24px;" @submit="requestSMSCode">
          <div>
            <div class="verification-field-container">
              <div class="country-picker-container">
                <country-picker-autocomplete
                  v-model="payload.input_code"
                  class="country-picker textfield-box-lg verify-dropdown"
                  :class="{
                    'has-status-indicator': hasTopStatusIndicator,
                    'country-picker--APV_REG_REDESIGN': true
                  }"
                  :caret="true"
                  @select="countrySelected"
                  @shown="countryPickerShown = true"
                  @closed="countryPickerShown = false"
                />
              </div>
              <div class="mobile-field-container">
                <md-input
                  v-model="payload.phone_number"
                  type="text"
                  :label="$translate('setting.new_mobile_label')"
                  required
                  :class="{'with-error': errors.has('mobile')}"
                >
                  <div class="text-error">
                    {{ errors.first('mobile') }}
                  </div>
                </md-input>
              </div>
            </div>
            <md-input
              v-if="isRequestCode"
              v-model="payload.smsCode"
              type="tel"
              :label="$translate('setting.sms_code_label')"
              style="background: #FFFFFF; margin-top: 16px;"
              :class="{'with-error': errors.has('verification_code')}"
            >
              <div class="text-error">
                {{ errors.first('verification_code') }}
              </div>
            </md-input>
          </div>
        </vform>
        <p v-if="!isRequestCode" class="form-description">{{ $translate('setting.sms_code_notice') }}</p>
        <p v-if="isRequestCode" class="resend">
          {{ $translate('setting.havent_receive') }}
          <strong v-if="!resentCountdown" @click="() => {
            isRequestCode = false
            submit()
          }">
            {{ $translate('setting.resend') }}
          </strong>
          <span v-if="resentCountdown">
            {{ $translate('setting.resend') }} in {{ resentCountdown }}s
          </span>
        </p>
      </template>

      <template v-slot:footer>
        <div>&nbsp;</div>

        <button v-if="!isRequestCode" class="bottom-sheet__cta bottom-sheet__cta--confirm" :disabled="!isFormComplete" @click="submit">
          {{ $translate('app.save') }}
        </button>

        <button v-else class="bottom-sheet__cta bottom-sheet__cta--confirm full-width" :disabled="!isFormComplete || payload.smsCode === ''" @click="submit">
          Verify
        </button>
      </template>
    </bottomsheet>
  </div>
</template>

<script>
import { DIAL_CODES } from '@/components/register/final/constants'
import bottomsheet from '@components/v2/utilities/bottomsheet.vue'
import { tooltipPlugin } from '@/features/super-provider/plugins/vue-custom-tooltip.js'
const SettingsProvider = $h.getProvider('SettingsProvider')

export default {
  name: 'AccountSetting',
  components: {
    bottomsheet
  },
  props: {
    isShown: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isRequestCode: false,
      isFetching: false,
      phoneVerificationRes: null,
      payload: {
        input_code: 'DE',
        country: 'DE',
        country_code: 49,
        smsCode: '',
        phone_number: ''
      },
      resentCountdown: 30,
      interval: null,
      countryPickerShown: false
    }
  },
  computed: {
    homebaseCountry () {
      return this.$store.getters.getHomeBaseCountry
    },
    hasStatusIndicator () {
      return this.$store.getters.getStatusIndicator
    },
    hasTopStatusIndicator () {
      return this.$store.getters.getTopStatusIndicator
    },
    hasBottomStatusIndicator () {
      return this.$store.getters.getBottomStatusIndicator
    },
    isFormComplete () {
      return this.payload.input_code && this.payload.phone_number
    }
  },
  mounted () {
    const country = this.homebaseCountry

    this.payload.country_code = DIAL_CODES[country]

    this.payload.input_code = country

    this.payload.country = country
  },
  methods: {
    requestSMSCode (next) {
      if (!this.isRequestCode) {
        this.errors.clear()

        SettingsProvider
          .requestSMSCode(
            this.payload.phone_number,
            this.payload.country_code,
            this.payload.country
          )
          .then((res) => {
            this.phoneVerificationRes = res
            this.isRequestCode = true

            this.resentCountdown = 30
            this.initializeResendCountdown()
          })
          .catch((err) => {
            let errorMsg = 'An error has occured.'

            if (err.data.data.country_code) {
              errorMsg = err.data.data.country_code[0]
            } else if (err.data.data.phone_number) {
              errorMsg = err.data.data.phone_number[0]
            } else {
              errorMsg = err.data.message
            }

            this.errors.add({
              field: 'mobile',
              msg: errorMsg
            })
          })
          .finally(() => {
            next()
          })
      } else {
        this.errors.clear()

        SettingsProvider.verifySMSCode(
          this.phoneVerificationRes.data.phone_number,
          this.phoneVerificationRes.data.country_code,
          this.payload.smsCode,
          this.payload.country
        )
          .then((res) => {
            this.$store.commit('setPhone', this.phoneVerificationRes.data.phone_number)
            this.reset()

            tooltipPlugin.toastV2(res.message, 5000, 'bottom')
          })
          .catch((err) => {
            if (err.data.data) {
              if (err.data.data.error_code === '60022') { // error code from authy for invalid code
                this.errors.add({
                  field: 'verification_code',
                  msg: err.data.message
                })

                return
              }

              for (const [key, value] of Object.entries(err.data.data)) {
                if (value.length > 0) {
                  this.errors.add({
                    field: key,
                    msg: value[0]
                  })
                }
              }
            }
          })
          .finally(() => {
            next()
          })
      }
    },
    countrySelected (value) {
      this.payload.country_code = value.callingCode

      this.payload.country = value.alpha2Code
    },
    submit () {
      this.$refs.form.submit()
    },
    reset () {
      this.isRequestCode = false
      this.isFetching = false
      this.phoneVerificationRes = null
      this.payload = {
        input_code: 'DE',
        country: 'DE',
        country_code: 49,
        smsCode: '',
        phone_number: ''
      }
      this.errors.clear()
      this.$emit('close')
    },
    initializeResendCountdown () {
      this.interval = setInterval(() => {
        this.resentCountdown--

        if (this.resentCountdown == 0) {
          clearInterval(this.interval)
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss">

#mobile-setting {
  .country-picker {
    height: 59px !important;

    &.country-picker--APV_REG_REDESIGN {
      height: 68px !important;
      margin-bottom: unset;

      @media screen and (max-width: 475px) {
        width: 111px;
      }
    }

    @media screen and (max-width: 1366px) {
      .fullscreen .material-autocomplete-wrapper {
        margin-top: 56px;
      }

      &.has-status-indicator .fullscreen .material-autocomplete-wrapper {
        margin-top: 112px;
      }
    }
  }

  .verification-field-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .country-picker-container {
      flex-grow: 1;

      .input-field--NEW_APV_REG_REDESIGN {
        width: 100%;
      }
    }

    .mobile-field-container {
      flex-grow: 2;

      .input-field label {
        top: .6em;
      }
    }
  }

  .form-description {
    font-size: 14px;
    color: #2F3740;
  }

  .resend {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #2F3740;
    font-size: 14px;

    strong {
      font-size: 16px;
      text-decoration: underline;
      cursor: pointer;
    }

    span {
      font-size: 16px;
    }
  }
}

.max-z-index {
  z-index: 999 !important;
}
</style>
