<template>
  <div
    class="private-place-promo-banner max-width-center"
    @click="gotoPage('private-place-overview')"
  >
    <div
      ref="banner-container"
      class="wrapper promo-banner-container  --green"
      :class="{ '--expanded-height': expandedHeight }"
    >
      <div class="promo-banner">
        <div class="more-requests">
          <i class="material-icons" data-test="pp-bottom-banner-icon">person_pin_circle</i>
          <span data-test="pp-bottom-banner-title">{{ getBannerTitle }}</span>
        </div>
        <div class="learn-how" data-test="pp-bottom-banner-cta">
          {{ getCTAText }}
        </div>
      </div>
    </div>
  </div>
</template>

<i18n src="./../translations/private-place.json"></i18n>

<script>
import goToPageMixin from '@/features/private-place/mixins/goToPage'
import fixedMenuMixin from '@components/v2/utilities/fixedMenu'

export default {
  mixins: [goToPageMixin, fixedMenuMixin],
  props: {
    expandedHeight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getCTAText () {
      return this.$t('promo_cta')
    },
    getBannerTitle () {
      return this.$t('promo_more_requests')
    }
  },
  mounted () {
    const vm = this
    setTimeout(() => {
      vm.adjustBannerBottomPosition()
    }, 300)
  }
}
</script>
<style lang="scss" scoped>
@import "@/features/promo-banner/shared/PromoBanner.style";
@import "./PrivatePlacePromoBanner.style";
</style>
