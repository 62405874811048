<template>
  <div id="video-date-landing-page">
    <md-navbar
      :push="{ name: 'dashboard' }"
      :brand="$translate('dashboard.title')"
      icon="arrow_back"
    />
    <div class="explanation center-align">
      <div class="padding-xl content">
        <h1
          class="heading"
          style="margin-bottom: 12px;"
        >
          {{ $translate('video-date.landing_page.explanation.headline') }}
        </h1>
        <p class="description margin-top-none">
          {{ $translate('video-date.landing_page.explanation.subtitle') }}
        </p>
        <button
          class="confirm-btn btn btn-large waves-effect waves-light btn-full btn-krypton-purple"
          @click="$router.push({ name: 'video-date-setting' })"
        >
          <span class="valign-middle">{{ $translate('video-date.cta_button.activate') }}</span>&nbsp;&nbsp;<i
            class="mdi mdi-arrow-right-circle valign-middle"
            style="font-size: 29px;"
          />
        </button>
        <p>
          <span class="how-it-works-text">{{ $translate('video-date.landing_page.explanation.how_it_works') }}</span> <a
            class="krypton-purple-text clickable"
            @click="scrollTo('how-it-works')"
          ><strong>{{ $translate('video-date.cta_button.find_out_how') }}</strong></a>
        </p>
      </div>
    </div>
    <div class="incentives center-align">
      <div class="padding-x-xl padding-y-x3">
        <div
          v-for="i in 3"
          :key="i"
          class="item"
        >
          <h5 class="title">
            {{ $translate(`video-date.landing_page.incentives.item_${i}.title`) }}
          </h5>
          <p class="description">
            {{ $translate(`video-date.landing_page.incentives.item_${i}.description`) }}
          </p>
        </div>
      </div>
    </div>
    <div class="how-it-works">
      <div class="padding-x-xl padding-top-x3 padding-bottom-lg">
        <h1
          id="how-it-works"
          class="heading center-align margin-top-none"
        >
          {{ $translate('video-date.landing_page.how_it_works.title') }}<br>
          <div class="img-wrapper">
            <img
              class="margin-top-lg"
              src="img/video-date/how_it_works.png"
              width="100%"
            >
          </div>
        </h1>
        <p class="description left-align margin-bottom-none">
          {{ $translate('video-date.landing_page.how_it_works.description') }}
        </p>
      </div>
    </div>
    <div class="q-and-a">
      <div class="padding-x-lg padding-top-x3 padding-bottom-xl">
        <h1 class="heading center-align margin-top-none">
          {{ $translate('video-date.landing_page.q_and_a.heading') }}
        </h1>
        <ul
          ref="collapsible"
          class="collapsible apv-collapsible margin-bottom-none"
        >
          <li
            v-for="i in 9"
            :key="i"
            class="item"
          >
            <div class="collapsible-header padding-left-xl">
              <h5 class="title">
                {{ $translate(`video-date.landing_page.q_and_a.items.item_${i}.question`) }}
              </h5>
            </div>
            <div class="collapsible-body">
              <p class="description left-align padding-bottom-md">
                {{ $translate(`video-date.landing_page.q_and_a.items.item_${i}.answer`) }} <a
                  v-if="i === 9"
                  href="#"
                  @click.prevent="$router.replace({ name: 'support-v2' })"
                ><strong>{{ $translate('video-date.cta_button.support') }}</strong>.</a>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="activate-section padding-x-xl padding-y-lg center-align">
      <button
        class="confirm-btn btn btn-large waves-effect waves-light btn-full btn-krypton-purple"
        @click="$router.push({ name: 'video-date-setting' })"
      >
        <span class="valign-middle">{{ $translate('video-date.cta_button.activate') }}</span>&nbsp;&nbsp;<i
          class="mdi mdi-arrow-right-circle valign-middle"
          style="font-size: 29px;"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    $(this.$refs.collapsible).collapsible({
      onOpen: (el) => {
        setTimeout(() => {
          const top = el[0].offsetTop - 100

          $('html,body').animate({
            scrollTop: top
          }, 300, 'swing')
        }, 300)
      }
    })
  },
  methods: {
    scrollTo (link) {
      document.getElementById(link).scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>
