export const INBOX_FILTER = {
  ALL: 'all',
  CONNECT: 'connect',
  LIKES: 'likes',
  REVIEWS: 'reviews'
}

export const ITEM_TYPE = {
  KRYPTON_CONNECT: 'krypton_connect',
  REVIEW: 'review',
  PROFILE_ACTIVITY: 'profile_activity',
  LIKE: 'like'
}

export const INBOX_TYPE = {
  KRYPTON_CONNECT: 1,
  REVIEW: 2,
  LIKE: 3
}

export const INBOX_LIKE_TYPE = {
  PHOTO: 1,
  FAVORITE: 2
}

export const CLIENT_DEFAULT = {
  NAME: 'New',
  AGE: 45
}

export const INBOX_FILTER_VALUE = {
  ALL: -1,
  connect: INBOX_TYPE.KRYPTON_CONNECT,
  reviews: INBOX_TYPE.REVIEW,
  likes: INBOX_TYPE.LIKE
}

export const INBOX_EVENTS = {
  ON_KRYPTON_INBOX: 'on-krypton-inbox',
  ON_KRYPTON_CONNECT_MESSAGE_SEEN: 'on-krypton-connect-message-seen',
  ON_KRYPTON_CONNECT_REPLY: 'on-krypton-connect-reply'
}
