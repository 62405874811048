export const responseCodes = Object.freeze({
  OK: 200,
  SEE_OTHER: 303,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  RATE_LIMIT: 429,
  BLOCKED: 444,
  SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503
})
