<template>
  <div
    id="inbox-overview"
    class="page"
    :class="{
      'bottom-space': addBottomSpace
    }"
  >
    <div id="content" class="container">
      <h1 class="title">{{ $translate('inbox.inbox_overview_page.title') }}</h1>
      <inbox-list :filter.sync="filter" />
    </div>
    <fixed-menu />
    <div v-if="!showBottomStatusIndicator">
      <krypton-connect-promo-banner v-if="shouldDisplayConnectPromo" />
      <private-place-promo-banner v-if="shouldDisplayPrivatePlacePromoBanner" />
      <multi-location-promo-banner v-if="shouldDisplayMultiLocationPromo_PRE_A2_1815" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#inbox-overview {
  margin-bottom: 100px;

  &.bottom-space {
    margin-bottom: 200px !important;
  }
}

</style>

<script>
import { mapGetters } from 'vuex'
import ControllerMixin from './InboxOverviewPage.controller'

export default {
  components: {
    FixedMenu: () => import('@components/v2/utilities/fixed-menu.vue')
  },
  mixins: [ControllerMixin],
  computed: {
    ...mapGetters({
      user: 'getUser',
      showBottomSheet: 'onboarding/GET_SHOW_BOTTOM_SHEET',
      displayBottomSheetInMaxView: 'onboarding/GET_DISPLAY_BOTTOM_SHEET_IN_MAX_VIEW',
      filledAvailabilityCount: 'availabilities/getFilledAvailabilityCount',
      showBottomStatusIndicator: 'getBottomStatusIndicator'
    }),
    addBottomSpace  () {
      return this.shouldDisplayConnectPromo ||
        this.shouldDisplayPrivatePlacePromoBanner ||
        this.shouldDisplayMultiLocationPromo_PRE_A2_1815 ||
        this.showBottomStatusIndicator
    }
  }
}
</script>
