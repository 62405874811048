<template>
  <md-input
    :value="email"
    type="text"
    class="textfield-box-lg"
    tabindex="1"
    :label="$translate('forgotPassword.email')"
    @input="onInput"
  />
</template>

<script>
export default {
  props: {
    email: {
      type: String,
      default: ''
    }
  },
  methods: {
    onInput (val) {
      this.$emit('onInput', val)
    }
  }
}
</script>
