var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page", attrs: { id: "video-date-faq" } },
    [
      _c("md-navbar", {
        attrs: {
          push: { name: "faq" },
          brand: _vm.$translate("faq.title"),
          icon: "arrow_back"
        }
      }),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("faq-header", {
              attrs: {
                "header-title": _vm.$translate(
                  "video-date.landing_page.q_and_a.faq_title"
                ),
                "header-tagline": _vm.getVDateTagline,
                "main-header-logo":
                  "/img/video-date/videodatefaqheadericon.svg",
                "primary-header-color": "#5611C5",
                "secondary-header-color": "#7C15D6",
                "header-gradient-angle": "90deg",
                "tagline-gradient-angle": "90deg",
                "header-img-dimensions": { height: "107px", width: "180px" }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { id: "content", "data-test": "faq-q-and-a-section" } },
              [
                _c("div", { staticClass: "q-and-a" }, [
                  _c(
                    "ul",
                    _vm._l(9, function(i) {
                      return _c("li", { key: i, staticClass: "item" }, [
                        _c("div", { staticClass: "question" }, [
                          _c("span", { staticClass: "question-number" }, [
                            _vm._v(_vm._s(i + "."))
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$translate(
                                  "video-date.landing_page.q_and_a.items.item_" +
                                    i +
                                    ".question"
                                )
                              )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "answer" }, [
                          _c("p", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.$translate(
                                    "video-date.landing_page.q_and_a.items.item_" +
                                      i +
                                      ".answer"
                                  )
                                ) +
                                " "
                            ),
                            i === 9
                              ? _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.$router.replace({
                                          name: "support-v2"
                                        })
                                      }
                                    }
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$translate(
                                            "video-date.cta_button.support"
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(".")
                                  ]
                                )
                              : _vm._e()
                          ])
                        ])
                      ])
                    }),
                    0
                  )
                ])
              ]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }