import { UnleashClient, EVENTS } from 'unleash-proxy-client'

const DEFAULT_REFRESH_INTERVAL = 30
const DEFAULT_BOOTSTRAP_OVERRIDE = true

/**
 * Feature Flag service responsible for communicating with the feature flag server
 */
export default class FeatureFlagService {
  /**
   * @param {Object} config - { url:string, clientKey:string, appName:string, environment:string, bootstrap:object, refreshInterval?:int }
   * @param {Object} listeners - {error?:[Func(obj)], init?:[Func], ready?:[Func], update?:[Func], impression?:[Func(obj)]}
   */
  constructor (config = {}, listeners = {}) {
    this.unleash = new UnleashClient({
      url: config.url,
      clientKey: config.clientKey,
      appName: config.appName,
      environment: config.environment,
      refreshInterval: config.refreshInterval ?? DEFAULT_REFRESH_INTERVAL,
      bootstrapOverride: DEFAULT_BOOTSTRAP_OVERRIDE,
      bootstrap: config.bootstrap?.features ?? null,
      disableMetrics: false,
      metricsInterval: 30
    })

    listeners.error && listeners.error.forEach(listener => {
      this.unleash.on(EVENTS.ERROR, listener) // param is exception object
    })
    listeners.init && listeners.init.forEach(listener => {
      this.unleash.on(EVENTS.INIT, () => {
        listener(this)
      })
    })
    listeners.ready && listeners.ready.forEach(listener => {
      this.unleash.on(EVENTS.READY, () => {
        listener(this)
      })
    })
    listeners.update && listeners.update.forEach(listener => {
      this.unleash.on(EVENTS.UPDATE, () => {
        listener(this)
      })
    })
    listeners.impression && listeners.impression.forEach(listener => {
      /**
       {
        eventType: 'getVariant',
        eventId: '84b41a43-5ba0-47d8-b21f-a60a319607b0',
        context: {
          sessionId: 54085233,
          appName: 'my-webapp',
          environment: 'default'
        },
        enabled: true,
        featureName: 'my-feature-toggle',
        variant: 'variantA'
      }
       */
      this.unleash.on(EVENTS.IMPRESSION, listener) // custom evt object
    })

    this.initialize()
  }

  /**
   *
   * @param {string} key
   * @param {string} value
   */
  addContext (key, value) {
    this.unleash.setContextField(key, value)
  }

  getContext () {
    return this.unleash.getContext()
  }

  /**
   *
   * @param {string} value
   */
  setSessionId (value) {
    this.addContext('sessionId', value)
    this.forceFetchToggles()
  }

  setUserId (value) {
    this.addContext('userId', value)
    this.forceFetchToggles()
  }

  /**
   * for internal class use only.
   * Starts the background polling
   */
  async initialize () {
    try {
      await this.unleash.start()
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *
   * @param {string} flag - feature flag name as written in the server
   * @returns {bool} the feature flag status
   */
  isEnabled (flag) {
    return this.unleash.isEnabled(flag)
  }

  /**
   * @returns {array} array of feature flag names that are enabled
   */
  getAllEnabledFlags () {
    return this.unleash.getAllToggles()
      .filter(flag => flag.enabled)
      .map(flag => flag.name)
  }

  /**
   *
   * @param {string} flag
   * @returns {Object} - { isEnabled: string, payload?: { type: string, value: string, } }
   */
  getVariant (flag) {
    const result = this.unleash.getVariant(flag)
    return {
      isEnabled: result.enabled,
      payload: this.formatPayload(result.payload)
    }
  }

  // eslint-disable-next-line class-methods-use-this
  formatPayload (payload) {
    if (payload) {
      const value = payload.value
      try {
        return JSON.parse(value)
      } catch (e) {
        return { value }
      }
    } else {
      return {}
    }
  }

  // only use this when absolutely necessary
  forceFetchToggles () {
    this.unleash.fetchToggles()
  }
}
