<template>
  <div class="overview__q-and-a">
    <div class="title">{{ $translate('krypton_connect.q_and_a.title') }}</div>
    <div class="faq">
      <ul class="q_and_a">
        <li v-for="(question, i) in questions" :key="`q-and-a-item-${i}`">
          <slot name="questionItem" :question="question" :index="i">
            <div class="question" @click="setOpenedAnswer(i)">
              <i class="material-icons">{{ getIcon(i) }}</i>
              <span class="label">{{ question.title }}</span>
            </div>
            <div v-if="openedAnswer === i" class="answer" v-html="question.content" />
          </slot>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import QAndAMixin from '@/features/overview/mixins/q-and-a.mixin'
export default {
  mixins: [QAndAMixin],
  props: {
    title: {
      type: String,
      default: ''
    },
    defaultOpenedAnswer: {
      type: Number,
      default: 0
    },
    questions: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.overview__q-and-a {
  background: #FFFFFF;
  padding: 48px 24px;

  .title {
    font-size: 2.5rem;
    font-weight: 600;
    color: #2F3740;
  }

  .q_and_a {
    margin: 0;
    padding-bottom: 96px;

    .question {
      display: flex;
      cursor: pointer;

      .material-icons {
        color: #E30074;
      }

      .label {
        color: #2F3740;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .answer {
      color: #2F3740;
      font-size: 16px;
      padding: 8px;
    }

    li {
      text-align: left;
      padding: 8px 0;

      &:not(:last-of-type) {
        border-bottom: 1px solid #CAD1D9;
      }
    }
  }
}
</style>
