<template>
  <div class="incentives">
    <div v-for="(feature, i) in features" :key="`feature-item-${i}`" :class="['card-panel', getCardPanelVariant(i)]">
      <div class="card-header">
        <div class="card-header-forum-container">
          <img :src="feature.icon" alt="">
        </div>
      </div>
      <div class="card-body">
        <div class="incentive-title">
          {{ feature.title }}
        </div>
        <div class="incentive-description" v-html="feature.content" />
      </div>
    </div>
  </div></template>

<script>
export default {
  props: {
    features: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    getCardPanelVariant (index) {
      return !!(index % 2) ? 'card-panel--right' : 'card-panel--left'
    }

  }
}
</script>

<style lang="scss" scoped>
@mixin variant($modifier) {
  &--#{$modifier} {
    @content;
  }
}

.incentives {
  padding-top: 24px;
  margin: 0 auto;
  max-width: 327px;
  z-index: 2;
  position: relative;
}

.card-panel {
  max-width: 291px;
  border-radius: 12px;
  display: flex;
  padding: 0;
  box-shadow: unset;
  background-color: white;

  &:not(:last-of-type) {
    margin-bottom: 37px;
  }

  @include variant(left) {
    flex-direction: row;
    margin-left: auto;

    .card-header {
      .card-header-forum-container {
        left: -30px;

        @media screen and (max-width: 320px) {
          left: -18px;
        }
      }
    }

    .card-body {
      padding-right: 24px;
    }
  }

  @include variant(right) {
    flex-direction: row-reverse;

    .card-header {
      .card-header-forum-container {
        right: -30px;

        @media screen and (max-width: 320px) {
          right: -18px;
        }
      }
    }

    .card-body {
      padding-left: 24px;
    }
  }

  .card-header {
    .card-header-forum-container {
      border-radius: 8px;
      height: 60px;
      width: 60px;
      position: relative;
      top: -13px;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(45deg, var(--primary-header-color), var(--secondary-header-color));
        border-radius: 8px;
        transform: scale(1.2);
      }

      img {
        z-index: 1;
      }
    }
  }

  .card-body {
    order: 2;
    display: flex;
    flex-direction: column;
    padding-top: 26px;
    padding-bottom: 26px;

    .incentive-title {
      color: #2F3740;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 16px;
      text-align: left;
    }

    .incentive-description {
      color: #2F3740;
      font-size: 16px;
      text-align: left;
    }

    @media screen and (max-width: 320px) {
      .incentive-title {
        font-size: 1rem;
      }
      .modal-footer .btn-flat {
        font-size: 0.750rem;
      }
    }
  }
}
</style>
