var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("login-mobile-view", {
        attrs: {
          username: _vm.email,
          password: _vm.password,
          error: _vm.error
        },
        on: {
          "update:username": function($event) {
            _vm.email = $event
          },
          "update:password": function($event) {
            _vm.password = $event
          },
          submit: _vm.submit
        }
      }),
      _vm._v(" "),
      _c("login-desktop-view", {
        attrs: {
          username: _vm.email,
          password: _vm.password,
          error: _vm.error
        },
        on: {
          "update:username": function($event) {
            _vm.email = $event
          },
          "update:password": function($event) {
            _vm.password = $event
          },
          submit: _vm.submit
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }