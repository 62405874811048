<template>
  <div id="promo-card" class="super-provider-prerequire-card margin-bottom-lg">
    <div class="super-provider-prerequire-card__body">
      <h2 class="super-provider-prerequire-card__body-title" data-test="title">
        <img src="/img/super-provider/super-provider-diamond.svg" data-test="icon">
        <span v-text="$t('body.title.super')" />
        <span v-text="$t('body.title.provider')" />
      </h2>
      <div class="super-provider-prerequire-card__body-status" data-test="status">
        <i class="material-icons" :class="{ 'material-icons--super-provider': isSuperProvider }">{{ statusIcons }}</i>
        <span v-text="statusText" />
      </div>
      <p class="super-provider-prerequire-card__body-text" data-test="description" v-text="contentText" />
      <div class="super-provider-prerequire-card__body-next-review" data-test="next-review" v-html="nextReviewText" />
    </div>
    <div class="super-provider-prerequire-card__footer" data-test="collapse">
      <div class="super-provider-prerequire-card__footer-header">
        <span v-text="$t('benefits.title')" />
        <a href="#" @click.prevent="expandBenefits">
          <i class="material-icons">{{ benefitsIcons }}</i>
        </a>
      </div>
      <ul class="super-provider-prerequire-card__footer-list" :class="{ 'expanded': benefitsListExpanded }">
        <li v-for="(item, key) in $t('benefits.items')" :key="key">
          <h3><i class="material-icons">check_circle</i><span v-text="item.title" /></h3>
          <p v-text="item.content" />
        </li>
      </ul>
    </div>
  </div>
</template>

<i18n src="@/features/super-provider/translations/super-provider-card.json"></i18n>
<i18n src="@/features/super-provider/translations/popup.json"></i18n>

<script>
export default {
  inject: {
    superProviderData: {
      default: () => ({
        superProviderUserDetails: {},
        superProviderStatus: {}
      })
    }
  },
  data () {
    return {
      benefitsListExpanded: false,
      nextReviewDate: null,
      nextReviewInDays: null
    }
  },
  computed: {
    isSuperProvider () {
      return this.superProviderData?.superProviderUserDetails?.is_super_provider
    },
    statusIcons () {
      return this.isSuperProvider ? 'check_circle' : 'cancel'
    },
    statusText () {
      return this.isSuperProvider ? this.$t('super_provider.status') : this.$t('not_super_provider.status')
    },
    contentText () {
      return this.isSuperProvider ? this.$t('body.text.superProvider') : this.$t('body.text.nonSuperProvider')
    },
    nextReviewText () {
      return `${this.$t('body.nextReview')} <strong>${this.nextReviewDate}</strong> - <strong>${this.nextReviewInDays}d</strong>`
    },
    benefitsIcons () {
      return this.benefitsListExpanded ? 'expand_less' : 'expand_more'
    }
  },
  mounted () {
    this.initDates()
  },
  methods: {
    expandBenefits () {
      this.benefitsListExpanded = !this.benefitsListExpanded
    },
    initDates () {
      const nextReviewData = moment(this.superProviderData?.superProviderStatus?._next_review_date).locale(Lang.locale())
      this.nextReviewDate = nextReviewData.format('MMM DD')
      this.nextReviewInDays = nextReviewData.diff(moment(new Date()), 'days')
    }
  }
}
</script>

<style lang="scss" scoped>
.super-provider-prerequire-card {
  .super-provider-prerequire-card__body {
    padding: 24px 32px 16px;
    background: transparent linear-gradient(57deg, #845BE7 0%, #608AFF 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0px 0px;

    .super-provider-prerequire-card__body-title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0;
      margin-bottom: 16px;
      font-size: 22px;
      font-family: 'Proxima Nova Semibold', sans-serif;
      color: #FFFFFF;

      span,
      img {
        margin: 0 4px;
      }
    }
    .super-provider-prerequire-card__body-status {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0 4px;
      background-color: #FFFFFF;
      border-radius: 12px;

      i.material-icons {
        display: block;
        margin-right: 8px;
        color: #FF6584;
      }
      i.material-icons.material-icons--super-provider {
        color: #00D96F;
      }
      span {
        font-size: 16px;
        font-family: 'Proxima Nova Semibold', sans-serif;
        color: #7969EE;
      }
    }
    .super-provider-prerequire-card__body-text {
      margin: 16px 0;
      font-size: 12px;
      font-family: 'Proxima Nova', sans-serif;
      color: #FFFFFF;
    }
    .super-provider-prerequire-card__body-next-review {
      padding: 4px;
      background-color: rgba(#2E353D, 0.3);
      border-radius: 12px;
      font-size: 12px;
      font-family: 'Proxima Nova', sans-serif;
      color: #FFFFFF;

      strong {
        font-family: 'Proxima Nova Semibold';
        font-weight: normal;
      }
    }
  }
  .super-provider-prerequire-card__footer {
    background: #776CF0 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 8px 8px;

    .super-provider-prerequire-card__footer-header {
      display: flex;
      justify-content: space-between;
      padding: 13px 16px 13px 24px;
      font-size: 16px;
      font-family: 'Proxima Nova Semibold';
      color: #FFFFFF;

      a {
        color: #FFFFFF;
        width: 44px;
        display: flex;
        justify-content: center;

        i.material-icons {
          display: block;
        }
      }
    }
    .super-provider-prerequire-card__footer-list {
      display: none;
      margin: 0;
      padding: 24px 13px 24px 24px;
      border-top: 1px solid rgba(#FFFFFF, 0.7);

      li {
        margin-bottom: 24px;
        color: #FFFFFF;

        h3 {
          display: flex;
          align-items: center;
          width: 100%;
          margin: 0 0 8px;
          font-size: 14px;
          font-family: 'Proxima Nova Semibold';

          i.material-icons {
            display: block;
            margin-right: 8px;
            color: #FFFFFF;
          }
        }
        p {
          margin: 0;
          font-size: 12px;
          font-family: 'Proxima Nova';
          color: rgba(#FFFFFF, 0.7);
          text-align: left;
        }
      }
      li:last-child {
        margin-bottom: 0;
      }
    }
    .super-provider-prerequire-card__footer-list.expanded {
      display: block;
    }
  }
}
</style>
