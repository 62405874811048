var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "wrapper", attrs: { id: "agreement" } }, [
        _c("div", { staticClass: "content__area" }, [
          _c("img", {
            attrs: {
              id: "k-logo",
              src: "/img/registration/k-logo.svg",
              alt: "girl with heart graphics"
            }
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "content__title content__title--desktop" }, [
            _vm._v(_vm._s(_vm.$translate("register.agreement.title")))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content__area" }, [
          _c("div", { staticClass: "content" }, [
            _c("h2", { staticClass: "content__title content__title--mobile" }, [
              _vm._v(_vm._s(_vm.$translate("register.agreement.title")))
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(_vm._s(_vm.$translate("register.agreement.p_1_1")))
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$router.push({ name: "legal-philosophy" })
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$translate("register.agreement.a_1_1")) +
                      "\n          "
                  )
                ]
              ),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$translate("register.agreement.p_1_2")))
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$router.push({ name: "legal-privacy" })
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$translate("register.agreement.a_1_2")) +
                      "\n          "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$translate("register.agreement.p_2_1")))
            ]),
            _vm._v(" "),
            _vm.monthOpts.length && _vm.yearOpts.length
              ? _c("div", { staticClass: "flex-grid space-between" }, [
                  _c("div", { staticClass: "grid-item f-sm-6" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "md-select",
                          {
                            staticClass: "textfield-box-lg",
                            attrs: {
                              "desktop-display-mode": "split",
                              "force-mobile": true,
                              label: _vm.$translate("register.agreement.month"),
                              "half-width": true,
                              placeholder: _vm.$translate(
                                "register.agreement.birth_month"
                              ),
                              default: _vm.$translate(
                                "register.agreement.select_month"
                              )
                            },
                            model: {
                              value: _vm.payload.month,
                              callback: function($$v) {
                                _vm.$set(_vm.payload, "month", $$v)
                              },
                              expression: "payload.month"
                            }
                          },
                          _vm._l(_vm.monthOpts, function(month, i) {
                            return _c(
                              "option",
                              {
                                key: "month-option-" + i,
                                attrs: {
                                  slot: "options",
                                  disabled: month.disabled
                                },
                                domProps: { value: month.key },
                                slot: "options"
                              },
                              [_vm._v(_vm._s(month.content))]
                            )
                          }),
                          0
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "grid-item f-sm-6" },
                    [
                      _c(
                        "md-select",
                        {
                          staticClass: "textfield-box-lg",
                          attrs: {
                            "desktop-display-mode": "split",
                            "force-mobile": true,
                            label: _vm.$translate("register.agreement.year"),
                            "half-width": true,
                            placeholder: _vm.$translate(
                              "register.agreement.birth_year"
                            ),
                            default: _vm.$translate(
                              "register.agreement.select_year"
                            )
                          },
                          model: {
                            value: _vm.payload.year,
                            callback: function($$v) {
                              _vm.$set(_vm.payload, "year", $$v)
                            },
                            expression: "payload.year"
                          }
                        },
                        _vm._l(_vm.yearOpts, function(year, i) {
                          return _c(
                            "option",
                            {
                              key: "year-option-" + i,
                              attrs: {
                                slot: "options",
                                disabled: year.disabled
                              },
                              domProps: { value: year.key },
                              slot: "options"
                            },
                            [_vm._v(_vm._s(year.content))]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "actions flex-grid space-between" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.declineTerms($event)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$translate("register.agreement.decline")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                class: { disabled: _vm.isCTADisabled },
                attrs: {
                  disabled: _vm.isCTADisabled,
                  "data-test": "agree-cta"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.submit($event)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$translate("register.agreement.accept")))]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("decline-popup", {
        attrs: { value: _vm.showPopup },
        on: {
          change: function(val) {
            return (_vm.showPopup = val)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "center-align content__image" }, [
      _c("img", {
        attrs: {
          src: "/img/registration/agreement-avatar.svg",
          alt: "girl with heart graphics"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }