<script>
import { private_place_faq as PrivatePlaceFaq } from '@lang/en/faq.js'
import FAQHeader from '@/features/provider-faq/components/FAQHeader.vue'
import FAQCta from '@/features/provider-faq/components/FAQCta.vue'
const PRIVATE_PLACE_FAQ = Object.freeze({
  LAST_ITEM: 6,
  DEFAULT_QNA: {},
  DEFAULT_NUMBER_OF_ITEMS: 0
})

export default {
  components: {
    'faq-header': FAQHeader,
    'faq-cta': FAQCta
  },
  data: () => ({
    QnALength: (Object.keys(
      PrivatePlaceFaq?.q_and_a ?? PRIVATE_PLACE_FAQ.DEFAULT_QNA
    ).length ?? PRIVATE_PLACE_FAQ.DEFAULT_NUMBER_OF_ITEMS),
    PRIVATE_PLACE_FAQ,
    prevRoute: null

  }),
  computed: {
    isLastItem () {
      return (key) => {
        return parseInt(key) === this.QnALength
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  }
}
</script>

<template>
  <div id="private-place-faq" class="page">
    <div id="faq-pplace">
      <div class="container">
        <faq-header
          :header-title="$translate('faq.private_place_faq.title')"
          :header-tagline="$translate('faq.private_place_faq.intro')"
          main-header-logo="/img/private-place/private-place-header-img-v2.svg"
          tagline-logo="/img/private-place/private-place-subheader-img-v2.svg"
          primary-header-color="#3AE86C"
          secondary-header-color="#46E4E9"
          header-gradient-angle="63deg"
          tagline-gradient-angle="50deg"
          header-navbar-redirection="faq"
        />
        <div
          id="content"
          class="question-answer-section"
          data-test="faq-q-and-a-section"
        >
          <div class="q-n-a-container">
            <ul class="q-and-a">
              <li v-for="i in QnALength" :key="i">
                <p class="question">
                  <span class="question-number">{{ `${i}.` }}</span> <span>{{ `${$translate(`faq.private_place_faq.q_and_a.item_${i}.question`)}` }}</span>
                </p>
                <p class="answer">
                  {{ $translate(`faq.private_place_faq.q_and_a.item_${i}.answer`) }}
                  <span v-if="isLastItem(i)"><a href="#" class="support" @click.prevent="$router.push({name: 'support-v2'})">
                    {{ $translate('krypton_connect.support') }}
                  </a>.</span>
                </p>
              </li>
            </ul>
          </div>
          <faq-cta
            route-name="private-place-setting"
            :cta-txt="$translate('private-place.header.cta')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/features/provider-faq/scss/q-and-a.scss';

#private-place-faq #content {
  text-align: left;
  font-size: 14px;
  padding-left: 32px;
  padding-right: 32px;
  color: #2F3740;

  & {
    background: #FFFFFF;
  }

  .title {
    font-size: 22px;
  }

  .question-answer-section {
    .question {
      color: #2E353D;
      font-size: 18px;
      font-weight: 600;
    }

    .answer {
      color: #2F3740;
      font-size: 14px;

      a {
        text-decoration: underline;
      }
    }
  }
}

#faq-pplace {
  #content {
    text-align: left;
    font-size: 14px;
    padding-left: 32px;
    padding-right: 32px;
    color: #2F3740;

    .title {
      font-size: 22px;
    }

    .question-answer-section {
      padding-top: 36px;
    }

    .q-and-a {
      & {
        margin: 0;
        text-align: left;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #CAD1D9;
      }

      &:not(:first-child) {
        padding-top: 18px;
      }

      .question {
        display: flex;
        font-size: 20px;
        font-family: 'Proxima Nova Semibold';
        font-weight: 600;
        color: #2F3740;
        margin: 0;

        .question-number {
          color: #E30074;
          margin-right: 8px;
          font-size: 18px;
        }
      }

      .answer {
        color: #2F3740;
        font-size: 18px;
        margin: 0;
        margin-left: 18px;
        padding-top: 18px;
        padding-bottom: 18px;

        a {
          text-decoration: underline;
        }
      }
    }

    .faq-cta-container {
      margin-top: 50px;
    }
  }
}
</style>
