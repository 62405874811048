<template>
  <div :id="component_id" class="autocomplete" :class="{'autocomplete--visible': isShown }">
    <div class="autocomplete__container">
      <div class="autocomplete__header">
        <div class="autocomplete__close-cta" @click.prevent="() => $emit('close')"><img src="/img/icons/close-icon-48.png" alt=""></div>
      </div>
      <div class="autocomplete__input">
        <div class="input-field">
          <md-input
            ref="search"
            :value="query"
            type="text"
            :label="label"
            :placeholder="computedPlacholder"
            :class="hotjarWhitelist ? 'data-hj-whitelist' : ''"
            data-test="material-input"
            :clearable="true"
            @input="delayedSearch"
            @focus="onSearchFocus"
            @blur="onSearchBlur"
          />
        </div>
      </div>
      <div v-if="hasResults" ref="suggestions" class="autocomplete__suggestions" :class="{
        'has-bottom-status-indicator': hasBottomStatusIndicator
      }">
        <div v-for="(suggestion, key) in suggestions" :key="key" @mousedown.prevent="select(suggestion)">
          <slot :value="suggestion">
            <div class="suggestion-item">{{ suggestion[searchKey] }}</div>
          </slot>
        </div>
      </div>
      <div v-if="noResults"
           class="autocomplete__suggestions">
        <slot name="no-results" :value="query" />
      </div>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js'
import { random, debounce, clone } from 'lodash'

export default {
  props: {
    label: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: null
    },
    valueKey: {
      type: String,
      default: null
    },
    searchKey: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: () => ([])
    },
    optionsCallback: {
      type: Function,
      default: () => false
    },
    position: {
      type: String,
      default: null
    },
    delay: {
      type: Number,
      default: null
    },
    trigger: {
      type: Object,
      default: null
    },
    defaultQuery: {
      type: String,
      default: null
    },
    showOnEmpty: {
      type: Boolean,
      default: false
    },
    hotjarWhitelist: {
      type: Boolean,
      default: false
    },
    isShown: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      query: null,
      component_id: null,
      timeout: 700,
      selected: {},
      suggestions: [],
      debounceStore: null,
      isFocused: false,
      isSearching: false,
      fuseOptions: {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
          'name'
        ]
      }
    }
  },
  computed: {
    computedPlacholder: function () {
      if (this.placeholder !== null) return this.placeholder

      return null
    },
    isMobile: function () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      }

      return false
    },
    hasResults () {
      return this.suggestions.length > 0
    },
    noResults () {
      return (this.query !== null ? this.query.length > 0 : false) && this.suggestions.length == 0 && this.isFocused && !this.isSearching
    },
    hasBottomStatusIndicator () {
      return this.$store.getters.getBottomStatusIndicator
    }
  },
  watch: {
    query: function (newval, oldval) {
      const id = '#' + this.component_id + ' .input-field input'

      const input = document.querySelector(id)

      if (input) input.classList.remove('valid')
    },
    isShown: function (newval, oldval) {
      if (newval) {
        this.$nextTick(() => {
          const input = this.$refs.search.$el?.querySelector('input')

          input.focus()

          this.scrollToTopMenuContainer()
        })
      }
    }
  },
  created () {
    if (this.delay !== undefined && this.delay !== null) {
      this.timeout = this.delay
    }

    if (this.defaultQuery !== undefined && this.defaultQuery !== null) {
      this.query = this.defaultQuery
    }
  },
  mounted () {
    while (this.component_id === null || document.getElementById(this.component_id) !== null) {
      this.component_id = 'autocomplete-' + random(0, 999999)
    }

    this.scrollToTop()
    this.scrollToTopMenuContainer()
  },
  methods: {
    broadcast: function (val) {
      this.$emit('input', val[this.valueKey])
      this.$emit('select', val[this.valueKey])
    },
    onSearchFocus: function ($event) {
      this.isFocused = true
      this.scrollToTopMenuContainer()
      this.$emit('focus')
      this.search($event.target.value)
    },
    onSearchBlur: function ($event) {
      this.isFocused = false
      this.$emit('blur')
      this.hideSuggestions($event.target.value)
    },
    search: function (value = '') {
      var vm = this

      if (vm.optionsCallback) {
        vm.optionsCallback(value)
          .then(function (res) {
            vm.setSuggestions(res)
          }).catch(function (err) {
            /**
             * @todo prompt error
             */
            console.error(err)
          })
      } else {
        var fuse = new Fuse(vm.options, vm.fuseOptions)
        var result = fuse.search(value)

        vm.setSuggestions(result)
      }
    },
    delayedSearch: function (value) {
      var vm = this
      vm.isSearching = true

      vm.resetSuggestions()

      if (vm.debounceStore && vm.debounceStore.hasOwnProperty('cancel')) {
        vm.debounceStore.cancel()
      }

      vm.debounceStore = debounce(function () {
        vm.search(value)
        vm.isSearching = false
      }, vm.timeout)

      vm.debounceStore()
    },
    select: function (value) {
      this.selected = clone(value)
      this.resetSuggestions()
      this.broadcast(this.selected)
      this.scrollToTop()
      this.$emit('close')
    },
    setSuggestions: function (value) {
      this.suggestions = value
    },
    resetSuggestions: function () {
      if (this.debounceStore && this.debounceStore.hasOwnProperty('cancel')) {
        this.debounceStore.cancel()
      }

      this.suggestions = []
    },
    hideSuggestions: function () {
      if (window.innerWidth < 992) {
        return false
      }

      this.resetSuggestions()
    },
    clearSearch: function () {
      this.query = null
      document.querySelector('#' + this.component_id + ' input[type="text"]').focus()
      this.resetSuggestions()
    },
    async scrollToTopMenuContainer () {
      await this.$nextTick()

      setTimeout(() => {
        document.documentElement.scrollTop = 0
      }, 600)
    },
    async scrollToTop () {
      await this.$nextTick()

      if (this.$refs.suggestions) {
        this.$refs.suggestions.scrollTop = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-up) {
  0% { transform: translateY(90%); }
  100% { transform: translateY(0); }
}

@include keyframes(slide-down) {
  0% { transform: translateY(0); }
  100% { transform: translateY(90%); }
}
.autocomplete {
  position: fixed;
  width: 100%;
  max-width: 600px;
  height: 100dvh;
  margin: 0 auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  z-index: 199;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    opacity: 0.7;
    left: 0;
  }

  .autocomplete__container {
    width: 100%;
    height: 90dvh;
    background-color: #fff;
    border-radius: 12px 12px 0px 0px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
    padding: 0 24px;
    display: none;
  }

  .automplete__header {
    margin-top: 7px;
    height: 48px;
    display: flex;
    align-items: center;
    margin-bottom: 28px;
  }

  .autocomplete__close-cta {
    padding-top: 20px;
    margin-left: -16px;
    width: 48px;
    height: 100%;
    cursor: pointer;
  }

  &.autocomplete--visible {
    display: block;

    .autocomplete__container {
      display: block;
      @include animation('slide-up 0.3s ease');
    }
  }

  .autocomplete__suggestions {
      max-height: 37em;
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      padding-bottom: 100px;

      &.has-bottom-status-indicator {
        padding-bottom: 200px;
      }

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .suggestion-item {

        &::first-letter {
          text-transform: uppercase;
        }

        border-bottom: 1px solid #CED6E0;
        padding-top: 20px;
        padding-bottom: 20px;
        font-family: 'Proxima Nova Medium';
        color: #2F3740;
        font-size: 18px;
      }
    }
}

.autocomplete.autocomplete--registration {
  &::before {
    background-color: #000000;
  }
}

/* .iphone .autocomplete.autocomplete--visible {
  position: fixed;
  bottom: 0;
  z-index: 9999;
} */

@media screen and (min-width: 992px) {
  .autocomplete.autocomplete--registration {
    max-width: 50dvw;
    left: unset;
    transform: unset;
    right: 0;

    .autocomplete__container {
        height: 100dvh;
        border-radius: unset;
    }

    .autocomplete__input {
      max-width: 464px;
      margin-top: 28px;
      margin-left: auto;
      margin-right: auto;
    }

    .autocomplete__suggestions {
      max-width: 464px;
      margin-left: auto;
      margin-right: auto;
    }

    .autocomplete__close-cta {
      margin-left: 48px;
      margin-top: 24px;
    }
  }
}

</style>
