var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "interview-v2-edit" } },
    [
      _c("bottomsheet", {
        attrs: { "is-shown": _vm.isShown },
        on: { close: _vm.reset },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("div", { staticClass: "edit-interview-content" }, [
                  _c("h2", [_vm._v(_vm._s(_vm.questionLabel))]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "left-align m-bottom" },
                    [
                      _c(
                        "div",
                        { staticClass: "question clickable" },
                        [
                          _c("md-input", {
                            staticClass: "question-input question-trigger-text",
                            attrs: {
                              type: "text",
                              value: _vm.selectedQuestion,
                              label: _vm.questionLabel,
                              readonly: ""
                            }
                          }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "question-trigger",
                            on: { click: _vm.triggerSelect }
                          }),
                          _vm._v(" "),
                          _c(
                            "i",
                            {
                              staticClass:
                                "material-icons material-icon-question-trigger"
                            },
                            [_vm._v("expand_more")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.interviewOpts.length > 0
                        ? _c(
                            "md-select",
                            {
                              ref: "selectModal",
                              staticClass: "hide",
                              attrs: {
                                label: _vm.questionNewPlaceholder,
                                placeholder: _vm.questionNewPlaceholder,
                                "force-mobile": true,
                                "new-design": true
                              },
                              model: {
                                value: _vm.interview.title,
                                callback: function($$v) {
                                  _vm.$set(_vm.interview, "title", $$v)
                                },
                                expression: "interview.title"
                              }
                            },
                            _vm._l(_vm.interviewOpts, function(i) {
                              return _c(
                                "option",
                                {
                                  attrs: {
                                    slot: "options",
                                    disabled: i.disabled
                                  },
                                  domProps: { value: i.key },
                                  slot: "options"
                                },
                                [_vm._v(_vm._s(i.content))]
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-field" },
                    [
                      _c(
                        "md-textarea",
                        {
                          attrs: {
                            type: "multiline",
                            maxlength: _vm.answerMaxChar,
                            "force-active": true,
                            label: _vm.textareaPlaceholder,
                            placeholder: _vm.textareaPlaceholder
                          },
                          model: {
                            value: _vm.interview.answer,
                            callback: function($$v) {
                              _vm.$set(_vm.interview, "answer", $$v)
                            },
                            expression: "interview.answer"
                          }
                        },
                        [
                          _c("div", [
                            _c("span", { staticClass: "input-helper right" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.getMaxChar(_vm.interview.answer)) +
                                  "\n              "
                              )
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c("ul", { staticClass: "save-trigger" }, [
                  _c("li", [
                    _c(
                      "button",
                      {
                        staticClass: "interview_cta",
                        attrs: {
                          "data-activates": "sidenav",
                          disabled: _vm.isDisabled
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.doSave($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$translate("app.save")) +
                            "\n          "
                        )
                      ]
                    )
                  ])
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }