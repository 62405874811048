<template>
  <div class="faq-cta-container">
    <a
      class="router-link waves-effect waves-dark"
      :class="[backgroundGradientClass]"
      @click="gotoPage(routeName)"
    >
      <span>{{ ctaTxt }}</span>
    </a>
  </div>
</template>
<script>

import goToPageMixin from '@/features/krypton-connect/mixins/goToPage'

export default {
  mixins: [goToPageMixin],
  props: {
    routeName: {
      type: String,
      default: ''
    },
    ctaTxt: {
      type: String,
      default: ''
    },
    backgroundGradientClass: {
      type: String,
      default: '--green'
    }
  }
}
</script>

<style lang="scss" scoped>
  .faq-cta-container {
  .router-link {
    height: 48px;
    border-radius: 10px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-family: 'Proxima Nova Semibold';
    font-size: 16px;
    text-transform: uppercase;

    &.--green {
      background: transparent linear-gradient(262deg, #46E4E9 0%, #3AE86C 100%) 0% 0% no-repeat padding-box;
    }

    &.--orange {
      background: transparent linear-gradient(262deg, #FFD04D 0%, #FF665B 100%) 0% 0% no-repeat padding-box;
    }

    &.--blue {
      background: transparent linear-gradient(262deg, #46E4E9 0%, #004EE8 100%) 0% 0% no-repeat padding-box;
    }

    &.--pink {
      background: transparent linear-gradient(262deg, #FF0073 0%, #DC0064 100%) 0% 0% no-repeat padding-box;
    }

    &.--purple {
      background: transparent linear-gradient(262deg, #D300C5 0%, #4B38FA 100%) 0% 0% no-repeat padding-box;
    }
  }
}
</style>
