<template>
  <div id="reliability-rating" class="super-provider-card super-provider-card__req margin-bottom-md" :class="cardStatusClass">
    <h2 class="super-provider-card__title margin-bottom-lg" data-test="sp-rr-title" v-text="title" />
    <div class="super-provider-card__value margin-bottom-md">
      <i class="material-icons margin-right-md" data-test="sp-rr-icon">star</i>
      <span data-test="sp-rr-value">{{ actualReliabilityRating }}</span>
    </div>
    <div class="super-provider-card__tooltip-trigger" data-test="sp-rr-tooltip" @click="showTooltip">
      <span class="super-provider-card__tooltip-trigger-text" v-text="tooltipText" />
      <i class="material-icons">help_outline</i>
    </div>
    <div class="super-provider-card__req-status" data-test="sp-rr-status">
      <span v-text="cardStatusText" />
      <i class="material-icons">{{ cardStatusIcon }}</i>
    </div>
  </div>
</template>

<i18n src="@/features/super-provider/translations/super-provider-card.json"></i18n>

<script>
import { tooltipPlugin } from '@/features/super-provider/plugins/vue-custom-tooltip.js'
import {
  DEFAULT_SUPER_PROVIDER_REQUIREMENTS,
  DEFAULT_SUPER_PROVIDER_STATUS
} from '@/features/super-provider/constants/super-provider-defaults.js'

export default {
  inject: {
    superProviderData: {
      default: () => ({
        superProviderRequirements: DEFAULT_SUPER_PROVIDER_REQUIREMENTS,
        superProviderStatus: DEFAULT_SUPER_PROVIDER_STATUS
      })
    }
  },
  computed: {
    minReqReliabilityRating () {
      const { reliability_rating } = DEFAULT_SUPER_PROVIDER_REQUIREMENTS
      return parseFloat(this.superProviderData?.superProviderRequirements?.reliability_rating?.value ?? reliability_rating.value)
    },
    actualReliabilityRating () {
      return this.superProviderData?.superProviderStatus?.reliability_rating ?? 0
    },
    parsedActualReliabilityRating () {
      return parseFloat(this.actualReliabilityRating)
    },
    title () {
      return this.$t('statusChecks.reliabilityRating.title')
    },
    tooltipText () {
      return this.$t('statusChecks.reliabilityRating.tooltipText', { min: this.minReqReliabilityRating })
    },
    tooltipExplanation () {
      return this.$t('statusChecks.reliabilityRating.tooltipExplanation')
    },
    onTrack () {
      // check value against condition here
      return this.parsedActualReliabilityRating >= this.minReqReliabilityRating
    },
    cardStatusIcon () {
      return this.onTrack
        ? 'check'
        : 'clear'
    },
    cardStatusText () {
      return this.onTrack
        ? this.$t('statusText.onTrack')
        : this.$t('statusText.notMet')
    },
    cardStatusClass () {
      return this.onTrack ? '--success' : '--warning'
    }
  },
  methods: {
    showTooltip () {
      tooltipPlugin.toast(this.tooltipExplanation)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/features/super-provider/sass/super-provider-card";

#prime-time-availability {
  .prime-time {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    i {
      color: #F9A825;
    }

    span {
      line-height: 12px;
    }

    strong {
      font-family: 'Proxima Nova Semibold', sans-serif;
    }
  }
}
</style>
