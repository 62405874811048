<template>
  <div
    class="modal modal-dropdown left-align md-autocomplete-dropdown"
    style="top:0!important;height:100%!important;width:100%;margin:0;max-height:100%"
  >
    <div
      ref="header"
      class="modal-header row"
      style="padding:0"
    >
      <div class="row">
        <div
          class="col s1"
          style="padding-top: 20px"
        >
          <i
            class="material-icons"
            @click="close"
          >arrow_back</i>
        </div>
        <div class="input-field col s10">
          <input
            id="autocomplete-dropdown-input"
            type="text"
            autocomplete="test"
            @keydown="search"
          >
          <label for="autocomplete-dropdown-input">{{ header }}</label>
        </div>
      </div>
    </div>
    <div
      ref="content"
      class="modal-content"
    >
      <div
        v-for="option in clonedOptions"
        class="autocomplete-option"
      >
        <label
          class="autocomplete-option-label"
          :data-option-value="strip(option)"
          @click.prevent="select(option)"
          v-html="option"
        />
      </div>
    </div>
  </div>
</template>

<script>
import latinize from 'latinize'

export default {
  props: [
    'input',
    'header',
    'options',
    'multiple',
    'limit'
  ],
  data () {
    return {
      clonedOptions: [],
      searchKey: null
    }
  },
  mounted () {
    this.setClonedOptions(Object.values(this.options).slice(0, 20))
  },
  methods: {
    strip (html) {
      var tmp = document.createElement('DIV')
      tmp.innerHTML = html

      return tmp.textContent || tmp.innerText || ''
    },
    setClonedOptions (val) {
      this.clonedOptions = val
    },
    confirm ($e) {
      this.$emit('confirm', this.data)
    },
    close () {
      this.$emit('close', this.data)
    },
    search ($event) {
      this.debounceStore = _.debounce(() => {
        this.searchKey = $event.target.value
        if (($event.target.value == null || $event.target.value.length == 0)) {
          this.setClonedOptions(this.options)
          return false
        }

        this.clonedOptions = Object.values(this.options)
          .filter(opt => this.testMatch(opt, $event.target.value))
          .map(opt => opt.replace(new RegExp($event.target.value.toLowerCase(), 'gi'), `<b>${$event.target.value.toLowerCase()}</b>`))
      }, 100)

      this.debounceStore()
    },
    testMatch (str1, str2) {
      return (new RegExp(`^.*${latinize(str2)}.*$`, 'i')).test(latinize(str1))
    }
  }
}
</script>
