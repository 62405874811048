<template>
  <div id="krypton-connect-list-item" data-test="krypton-connect-promo-card" @click="gotoKryptonConnectPromoPage">
    <div class="left-panel">
      <div class="promo-title">{{ $translate('krypton_connect.news_promo_banner.title') }}</div>
      <div class="promo-subtitle">{{ $translate('krypton_connect.news_promo_banner.subtitle') }}</div>
      <div class="promo-cta">{{ $translate('krypton_connect.news_promo_banner.cta') }}</div>
    </div>
    <div class="right-panel">
      <img :src="item.image">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object, required: true
    }
  },
  methods: {
    gotoKryptonConnectPromoPage () {
      this.$router.push({ name: 'krypton-connect-overview' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './KryptonConnectPromoCard.style';
</style>
