var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "news-list-item", "data-test": _vm.news.dataTestName } },
    [
      _c(
        "div",
        {
          staticClass: "image-wrapper",
          class: { multilocation: _vm.isMultiLocationFeature(_vm.news) }
        },
        [
          _c("img", {
            attrs: { src: _vm.news.image, "data-test": "news-item-image" }
          })
        ]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "left-align margin-bottom-sm" }, [
        _c(
          "strong",
          { staticClass: "title", attrs: { "data-test": "news-item-title" } },
          [_vm._v(_vm._s(_vm.getTitle(_vm.news.item)))]
        )
      ]),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass: "content left-align margin-top-none margin-bottom-none",
          attrs: { "data-test": "news-item-description" }
        },
        [_vm._v("\n    " + _vm._s(_vm.getDescription(_vm.news.item))), _c("br")]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "right-align margin-top-none" }, [
        _c(
          "a",
          {
            staticClass: "router-link waves-effect waves-dark padding-sm",
            class: {
              "feature-lock-indicator": _vm.news.disabled
            },
            attrs: { "data-test": "news-item-cta" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.news.onclick($event)
              }
            }
          },
          [_c("strong", [_vm._v(_vm._s(_vm.getCta(_vm.news)))])]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }