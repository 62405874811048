var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal center-align billing-required",
      attrs: { id: "billing-required-modal" }
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c("h4", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$translate("billing.popup.title")) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "margin-y-lg",
          attrs: { src: "/img/verify/billing-required-checked.svg" }
        }),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$translate("billing.popup.explanation_text")) +
              "\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "new-modal-footer" }, [
        _c("a", { attrs: { href: "#" }, on: { click: _vm.confirm } }, [
          _c("strong", [
            _vm._v(_vm._s(this.$translate("billing.popup.cta_button")))
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }