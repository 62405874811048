var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "apv-card", attrs: { id: "pending-event-card" } },
    [
      _c("div", { staticClass: "card-header" }, [
        _c("div", { staticClass: "card-header__flex" }, [
          _c("pre", { staticClass: "date uppercase" }, [
            _vm._v(_vm._s(_vm.date))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grey-text card-header__flex card-header__flex--mid" },
          [
            _c("span", { staticClass: "schedule" }, [
              _c(
                "strong",
                {
                  staticClass: "schedule__countdown lilac",
                  class: {
                    "lilac--red": _vm.hasNotification
                  }
                },
                [
                  _c("span", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.meetingStart) +
                        "\n          "
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("small", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$translate("events.labels.for")) +
                    " " +
                    _vm._s(_vm.payload.meeting_duration) +
                    "h\n        "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "location" }, [
              _vm.isVideoDateMeeting
                ? _c("p", { staticClass: "margin-none krypton-purple-text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$translate("events.video_date.title")) +
                        "\n        "
                    )
                  ])
                : _vm.isPrivatePlaceOrMultiLocationMeeting
                ? _c(
                    "div",
                    { staticClass: "event-details-hotel-name" },
                    [
                      _vm.isPrivatePlaceMeeting
                        ? _c(
                            "i",
                            {
                              staticClass: "material-icons",
                              attrs: { "data-test": "event-details-pp-icon" }
                            },
                            [
                              _vm._v(
                                "\n            person_pin_circle\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isMultiLocationMeeting
                        ? _c("multi-location-event-icon")
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isMultiLocationMeeting
                        ? _c("span", [_vm._v(_vm._s(_vm.payload._city_name))])
                        : _c("span", [_vm._v(_vm._s(_vm.payload._hotel_name))])
                    ],
                    1
                  )
                : _c("p", { staticClass: "margin-none" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.payload._hotel_name) +
                        "\n        "
                    )
                  ])
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-header__flex" }, [
          _c(
            "strong",
            { staticClass: "amount" },
            [
              _c("currency"),
              _vm._v(_vm._s(_vm.payload.amount_payable) + "\n        "),
              _vm.isMultiLocationMeeting
                ? _c(
                    "div",
                    [
                      _vm._v("+ "),
                      _c("currency"),
                      _vm._v(_vm._s(_vm.payload.travel_cost))
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _vm.isMultiLocationMeeting
        ? _c("multi-location-event-address", { attrs: { event: _vm.payload } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "card-content" }, [
        _c("div", { staticClass: "row controls" }, [
          _c("div", { staticClass: "expiration-time" }, [
            _c("span", [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$translate("events.labels.expires")) +
                  " " +
                  _vm._s(_vm.acceptCountdown) +
                  "\n        "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "respond-btn" }, [
            _c(
              "button",
              {
                staticClass: "btn waves-effect waves-light",
                class: {
                  "btn-krypton-pink": !_vm.isVideoDateMeeting,
                  "btn-krypton-purple": _vm.isVideoDateMeeting
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$translate("events.buttons.respond_now")) +
                    "\n        "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("label", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$translate("events.labels.acceptance_note")) +
                "\n      "
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }