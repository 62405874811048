window.Materialize = require('materialize-css')
const DEFAULT_MESSAGE = ''
const DEFAULT_DURATION = 1000
const notificationPlugin = {
  toast: (message = DEFAULT_MESSAGE, duration = DEFAULT_DURATION) => {
    window.Materialize.Toast.removeAll()
    window.Materialize.toast(message, duration)
  }
}

export default {
  install: (Vue, options) => {
    Vue.prototype.$notifications = notificationPlugin
    window.NotificationPlugin = notificationPlugin
  }
}
