var render, staticRenderFns
import script from "./ResetPasswordController.vue?vue&type=script&lang=js&"
export * from "./ResetPasswordController.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../../translations.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fforge%2Fama-provider8-stage.noznom.tech%2Fsrc%2Fjs%2Ffeatures%2Fauth%2Fcomponents%2FResetPassword%2FResetPasswordController.vue"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider8-stage.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7d85330c')) {
      api.createRecord('7d85330c', component.options)
    } else {
      api.reload('7d85330c', component.options)
    }
    
  }
}
component.options.__file = "src/js/features/auth/components/ResetPassword/ResetPasswordController.vue"
export default component.exports