<template>
  <div id="verify-id-success-modal" class="modal id-verify-rejected">
    <div class="modal-content">
      <h4 class="modal-title">
        {{ $translate('verify.verification_rejected.title') }}
      </h4>

      <p>
        {{ $translate('verify.verification_rejected.explanation_text') }}
      </p>

      <div class="flex-grid-column">
        <div v-if="isFrontIDRejected" class="flex-1 margin-bottom-sm">
          <div class="flex-grid">
            <img src="/img/verify/front-id-error.svg">
            <div class="reject-reason flex-2 margin-left-md">
              <strong>
                {{ $translate('verify.verification_rejected.reason_front') }}
              </strong><br>
              <span>
                {{ file_id_front_error }}
              </span>
            </div>
          </div>
        </div>
        <div v-if="isBackIDRejected" class="flex-1">
          <div class="flex-grid">
            <img src="/img/verify/back-id-error.svg">
            <div class="reject-reason flex-2 margin-left-md">
              <strong>
                {{ $translate('verify.verification_rejected.reason_back') }}
              </strong><br>
              <span>
                {{ file_id_back_error }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button class="new-btn" @click="confirm">
        {{ $translate('verify.verification_rejected.cta_button') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file_id_front_error: {
      required: true
    },
    file_id_back_error: {
      required: true
    }
  },
  computed: {
    isFrontIDRejected () {
      return typeof this.file_id_front_error === 'string'
    },
    isBackIDRejected () {
      return typeof this.file_id_back_error === 'string'
    }
  },
  methods: {
    confirm ($e) {
      this.$emit('confirm', $e)
    }
  }
}
</script>

<style lang="scss" scoped>

.id-verify-rejected {
    background: #FFFFFF;
    border-radius: 12px;
    top: 20%;

    @media screen and (max-width: 600px) {
        margin: 0 16px;
        width: 94%;
    }
    @media screen and (max-width: 420px) {
        width: 92%;
    }

    .modal-content {
        padding: 32px 24px;

        .modal-title {
            color: #2F3740;
            font-size: 20px;
            line-height: 32px;
            margin: 0 auto;
            text-align: center;
        }

        p {
            color: #2F3740;
            font-size: 18px;
            line-height: 28px;
            margin: 32px auto 24px auto;
            text-align: center;
        }

        .reject-reason {
            color: #2F3740 !important;
            line-height: 19px;
        }

    }

    .modal-footer {
        background: #FFFFFF;
        border-top: 1px solid #CAD1D9;
        height: 95px;
        margin: 0 auto;
        padding: 17px 24px 31px;
        text-align: center;

        .new-btn {
            background: transparent linear-gradient(261deg, #FF0073 0%, #DC0064 100%) 0% 0% no-repeat padding-box;
            border: none;
            border-radius: 10px;
            box-shadow: none;
            color: #fff;
            display: inline-block;
            font-size: 16px;
            font-weight: 700;
            height: 47px;
            line-height: 14px;
            padding: 15px 0 14px;
            width: 100%;
        }
    }

}

#verify-id-success-modal {
  .reject-reason {
    color: #757575;
  }
}
</style>
