import { ROUTE_NAMES } from '@/constants/global'

const AccountComponent = require('@/features/pwa-animation/tpl/PwaCheckpoint.vue').default

const redirectNotAuthenticated = require('@/middleware/redirectNotAuthenticated').default

const routes = [
  {
    path: '',
    name: 'dashboard',
    component: Vue.component('dashboard')
  },
  // TODO: deprecated, can be removed or be repurposed to use the v2 component
  {
    path: 'support',
    name: 'support',
    component: Vue.component('support')
  },
  {
    path: 'setting',
    name: 'setting',
    component: Vue.component('setting')
  },
  {
    path: 'setting/account',
    name: 'account-setting',
    component: Vue.component('account-setting')
  },
  {
    path: 'verify-email',
    name: 'verify-email',
    component: Vue.component('verify-email')
  },
  {
    path: 'setting/video-date',
    name: 'video-date-setting',
    component: Vue.component('video-date-setting')
  },
  {
    path: 'setting/vip-photos',
    name: 'vip-photos-setting',
    component: Vue.component('vip-photos-setting')
  },
  {
    path: 'profile',
    name: 'profile',
    params: { showPPBottomSheet: false },
    component: Vue.component('profile')
  },
  {
    path: '/profile/motto',
    name: 'profile-motto',
    component: Vue.component('profile-motto')
  },
  {
    path: '/profile/details',
    name: 'profile-details',
    component: Vue.component('profile-details')
  },
  {
    path: '/profile/basic-details',
    name: 'profile-basic-details',
    component: Vue.component('profile-basic-details')
  },
  {
    path: '/profile/character-details',
    name: 'profile-character-details',
    component: Vue.component('profile-character-details')
  },
  // TODO: deprecated, can be removed or be repurposed to use the v2 component
  {
    path: '/profile/interview',
    name: 'profile-interview',
    component: Vue.component('profile-interview')
  },
  {
    path: '/profile/interview-v2',
    name: 'profile-interview-v2',
    component: Vue.component('profile-interview-v2')
  },
  {
    path: '/profile/interview-v2/edit',
    name: 'profile-interview-v2-edit',
    component: Vue.component('profile-interview-v2-edit')
  },
  // Photos Module
  // TODO: deprecated and duplicate, can be removed or be repurposed to use the v2 component
  {
    path: '/profile/photos',
    name: 'photos',
    component: Vue.component('photos-v2')
  },
  {
    path: '/profile/photos/tips',
    name: 'photo-tips',
    component: Vue.component('photo-tips')
  },
  {
    path: '/profile/photos-v2',
    name: 'photos-v2',
    component: Vue.component('photos-v2')
  },
  {
    path: '/profile/photos/preview',
    name: 'photos-preview',
    component: Vue.component('photos-preview')
  },
  {
    path: '/profile/photos/upload/crop',
    name: 'photos-crop',
    component: Vue.component('photos-crop'),
    params: { photo: null, return: null, cancel: null }
  },
  {
    path: '/profile/photos/upload/anonimize',
    name: 'photos-anonimize',
    component: Vue.component('photos-anonimize'),
    params: { photo: null, return: null, cancel: null }
  },
  {
    path: '/profile/photos/guide',
    name: 'photo-guide',
    component: Vue.component('photo-guide'),
    params: { return: 'photos' }
  },
  // Events
  {
    path: 'events',
    name: 'events',
    component: Vue.component('events')
  },
  {
    path: 'events/details',
    name: 'event-details',
    component: Vue.component('event-details'),
    params: { event: null }
  },
  {
    path: 'events/checkin',
    name: 'event-checkin',
    component: Vue.component('event-checkin'),
    params: { event: null }
  },
  {
    path: '/events/details/confirmed',
    name: 'event-confirmed',
    component: Vue.component('event-confirmed')
  },
  {
    path: '/events/details/cancelled',
    name: 'event-cancelled',
    component: Vue.component('event-cancelled')
  },
  {
    path: '/events/details/rate',
    name: 'event-rate',
    component: Vue.component('event-rate')
  },
  {
    path: '/events/details/rate/feedback',
    name: 'event-rate-feedback',
    component: Vue.component('event-rate-feedback'),
    params: { rate: null }
  },
  {
    path: '/events/details/late',
    name: 'event-late',
    component: Vue.component('event-late')
  },
  {
    path: '/events/details/where-is-he',
    name: 'event-whereis',
    component: Vue.component('event-whereis')
  },
  // Availabilities Module
  {
    path: '/availability',
    name: 'availability',
    component: Vue.component('availability')
  },
  {
    path: '/availability-block',
    name: 'availability-block',
    component: Vue.component('availability-block')
  },
  // Billing Module
  {
    path: '/billing',
    component: Vue.component('billing'),
    children: [
      {
        path: '',
        name: 'billing-form',
        component: Vue.component('billing-form')
      }
    ]
  },
  // Support V2 Module
  {
    path: '/support-v2',
    name: 'support-v2',
    component: Vue.component('support-v2')
  },
  {
    path: '/support-v2/request',
    name: 'support-v2-request',
    component: Vue.component('support-v2-request')
  },
  // Feedback Module
  {
    path: '/reviews',
    name: 'reviews',
    component: Vue.component('feedback')
  },
  // Verification module
  {
    path: '/verify',
    component: Vue.component('verify'),
    beforeEnter: redirectNotAuthenticated,
    children: [
      {
        path: '',
        name: ROUTE_NAMES.VERIFY_INTRO,
        component: Vue.component('verify-intro')
      },
      {
        path: 'upload',
        name: ROUTE_NAMES.VERIFY_UPLOAD,
        component: Vue.component('verify-upload')
      },
      {
        path: 'pending',
        name: ROUTE_NAMES.VERIFY_PENDING,
        component: Vue.component('verify-pending')
      }
    ]
  },
  // Video Date module
  {
    path: '/video-date',
    name: 'video-date',
    component: Vue.component('video-date-landing-page'),
    beforeEnter: redirectNotAuthenticated
  },
  // FAQ
  {
    path: '/faq',
    name: 'faq',
    component: Vue.component('faq-page')
  },
  {
    path: '/faq/date',
    name: 'date-faq',
    component: Vue.component('date-faq-page')
  },
  {
    path: '/faq/communication',
    name: 'communication-faq',
    component: Vue.component('communication-faq-page')
  },
  {
    path: '/faq/performance/total-rating',
    name: 'total-rating-faq',
    component: Vue.component('total-rating-faq-page')
  },
  {
    path: '/faq/performance/reliability',
    name: 'reliability-faq',
    component: Vue.component('reliability-faq-page')
  },
  {
    path: '/faq/performance/client',
    name: 'client-faq',
    component: Vue.component('client-faq-page')
  },
  {
    path: '/faq/performance/super-advertiser',
    name: 'super-provider-faq',
    component: Vue.component('super-provider-faq-page')
  },
  {
    path: '/faq/affiliate',
    name: 'affiliate-faq',
    component: Vue.component('affiliate-faq-page')
  },
  {
    path: '/events/details/declined',
    name: 'event-declined-v2',
    component: Vue.component('event-declined-v2')
  },
  {
    path: 'feedback',
    name: 'feedback',
    component: Vue.component('provider-performance-page')
  },
  // Private Place
  {
    path: 'private-place',
    name: 'private-place-overview',
    component: Vue.component('private-place-overview')
  },
  {
    path: '/faq/private-place',
    name: 'faq.private-place',
    component: Vue.component('private-place-faq')
  },
  {
    path: 'setting/private-place',
    name: 'private-place-setting',
    component: Vue.component('private-place-setting')
  },
  {
    path: 'krypton-connect',
    name: 'krypton-connect-overview',
    component: Vue.component('krypton-connect-overview')
  },
  {
    path: '/inbox/:filter?',
    name: 'inbox',
    component: Vue.component('inbox-overview-page')
  },
  {
    path: '/krypton-connect/:id',
    name: 'krypton-connect',
    component: Vue.component('krypton-connect-page')
  },
  {
    path: 'setting/krypton-connect',
    name: 'krypton-connect-setting',
    component: Vue.component('krypton-connect-setting-page')
  },
  {
    path: 'setting/language',
    name: 'language-setting',
    component: Vue.component('language-setting-page')
  },
  {
    path: '/faq/inbox/connect',
    name: 'connect-faq',
    component: Vue.component('connect-faq-page')
  },
  {
    path: '/onboarding-lessons',
    name: 'onboarding-lessons',
    component: require('@/features/onboarding-lesson-overview/components/OnboardingLessonOverview.vue').default
  },
  {
    path: 'help',
    name: 'help',
    component: require('@/components/account/help/help.vue').default
  },
  // Super provider
  {
    path: '/super-advertiser',
    name: 'super-provider',
    component: require('@/features/super-provider/pages/SuperProvider.vue').default
  },
  // Super Provider Landing Page
  {
    path: '/super-advertiser-overview',
    name: 'super-provider-overview',
    component: require('@/features/super-provider/pages/SuperProviderLandingPage.vue').default
  },
  // Affiliate
  {
    path: '/affiliate-overview',
    name: 'affiliate-overview',
    component: require('@/features/affiliate/pages/AffiliateLandingPage.vue').default
  },
  {
    path: '/affiliate',
    name: 'affiliate',
    component: require('@/features/affiliate/pages/Affiliate.vue').default,
    children: [
      {
        path: 'received',
        name: 'affiliate-received',
        component: require('@/features/affiliate/pages/AffiliateReceived.vue').default
      },
      {
        path: 'pending',
        name: 'affiliate-pending',
        component: require('@/features/affiliate/pages/AffiliatePending.vue').default
      }
    ]
  },
  {
    path: '/billing-v2',
    name: 'billing-v2',
    component: require('@/features/billing/pages/Billing.vue').default,
    children: [
      {
        path: 'unpaid',
        name: 'billing-v2-unpaid',
        component: require('@/features/billing/pages/BillingUnpaid.vue').default
      },
      {
        path: 'paid',
        name: 'billing-v2-paid',
        component: require('@/features/billing/pages/BillingPaid.vue').default
      }
    ]
  },
  {
    path: '/menu',
    name: 'menu',
    component: require('@/features/other-menu/pages/OtherMenu.vue').default
  },
  {
    path: '/app-feedback',
    name: 'app-feedback',
    component: require('@/features/app-feedback/pages/AppFeedback.vue').default
  }
]

const MultiLocationLandingPage = require('@/features/multi-location/components/landing-page/MultiLocationLandingPage.vue').default

const routesA21595 = [
  {
    path: 'multi-location',
    name: 'multi-location-overview',
    component: MultiLocationLandingPage
  }
]

for (const route of routesA21595) {
  routes.push(route)
}

const MultiLocationSettings = require('@/features/multi-location/components/settings/MultiLocationSettings.vue').default

routes.push({
  path: 'setting/multi-location',
  name: 'multi-location-setting',
  component: MultiLocationSettings
})

const MultiLocationFaq = require('@/features/multi-location/components/faq/MultiLocationFaq.vue').default

routes.push({
  path: '/faq/multi-location',
  name: 'faq.multi-location',
  component: MultiLocationFaq
})

const VideoDateFaq = require('@/components/account/video-date/video-date-faq.vue').default

routes.push({
  path: '/faq/video-date',
  name: 'faq.video-date',
  component: VideoDateFaq
})

export default {
  path: '/',
  component: AccountComponent,
  meta: {
    isAccountRoute: true
  },
  beforeEnter: redirectNotAuthenticated,
  children: routes
}
