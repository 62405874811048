<template>
  <a href="#" class="waves-effect" @click.prevent="copy(val)"><i class="material-icons">content_copy</i></a>
</template>

<script>
export default {
  props: ['val'],
  methods: {
    async copy (val) {
      try {
        await navigator.clipboard.writeText(val)
      } catch (err) {
        console.error('Failed to copy: ', err)
      }
    }
  }
}
</script>
