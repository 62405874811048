// Import vue specialized components
import ApvButton from '@/features/apv/form/buttons/button.vue'
import ApvForm from '@/features/apv/form/form.vue'
import ApvInput from '@/features/apv/form/textfields/input.vue'
import ApvPreloaderCircular from '@/features/apv/preloaders/circular.vue'
import Vue from 'vue'
import availabilityBlock from './account/availability/availability-block.vue'
import availabilityCard from './account/availability/availability-card.vue'
// Availability
import availability from './account/availability/availability.vue'
import billingForm from './account/billing/form.vue'
// ====== Billing ======
import billing from './account/billing/index.vue'
import billingPaid from './account/billing/tabs/billing-paid.vue'
import billingUnpaid from './account/billing/tabs/billing-unpaid.vue'
// Import route components
import dashboard from './account/dashboard.vue'
import eventChatBubble from './account/events/components/chat-bubble.vue'
import emptyEvent from './account/events/components/empty-event.vue'
import eventCheckin from './account/events/event-checkin.vue'
import eventDetails from './account/events/event-details.vue'
import eventLate from './account/events/event-late.vue'
import eventRateFeedback from './account/events/event-rate-feedback.vue'
import eventRate from './account/events/event-rate.vue'
import eventWhereIs from './account/events/event-whereis.vue'
import events from './account/events/events.vue'
import eventCancelled from './account/events/popups/event-cancelled.vue'
import eventConfirmed from './account/events/popups/event-confirmed.vue'
import eventDeclined from './account/events/popups/event-declined.vue'
// Feedback
import feedback from './account/feedback/feedback.vue'
import logout from './account/logout.vue'
import photosAnonimize from './account/photos/anonimize.vue'
import photosCrop from './account/photos/crop.vue'
import photosGalleryV2 from './account/photos/gallery-v2/gallery.vue'
// ====== V2 Photos Components ======
import photosV2 from './account/photos/gallery-v2/photos.vue'
import photosGalleryV2AddPrimaryPhoto from './account/photos/gallery-v2/slots/add-primary-photo.vue'
// Slots
import photosGalleryV2PhotoEmpty from './account/photos/gallery-v2/slots/photo-empty.vue'
import photosGalleryV2Photo from './account/photos/gallery-v2/slots/photo.vue'
// Tab
import photosGalleryV2LivePhotos from './account/photos/gallery-v2/tabs/live-photos.vue'
import photosGalleryV2PrivatePhotos from './account/photos/gallery-v2/tabs/private-photos.vue'
// ====== end of V2 Photos Components ======
// Photos components
import photosGallery from './account/photos/gallery.vue'
import photoGuide from './account/photos/photo-guide.vue'
import photoTips from './account/photos/photo-tips.vue'
import photosPreview from './account/photos/preview.vue'
import profileDetails from './account/profile/details.vue'
import profileBasicDetails from './account/profile/basic-details.vue'
import profileCharacterDetails from './account/profile/character-details.vue'
import profileInterviewV2Edit from './account/profile/interview-v2/interview-edit.vue'
// ====== V2 Interview ======
import profileInterviewV2 from './account/profile/interview-v2/interview.vue'
import profileInterview from './account/profile/interview.vue'
import profileMotto from './account/profile/motto.vue'
import profile from './account/profile/profile.vue'
import settingAccount from './account/settings/account-setting.vue'
import settingEmail from './account/settings/email-setting.vue'
import settingMobile from './account/settings/mobile-setting.vue'
import settingPassword from './account/settings/password-setting.vue'
import privatePlaceSetting from './account/settings/private-place-setting.vue'
import vipPhotosSetting from './account/settings/vip-photos-setting.vue'
// ====== end of V2 Photos Components ======
// Settings components
import setting from './account/settings/setting.vue'
import settingEmailVerify from './account/settings/verify-email.vue'
import videoDateSetting from './account/settings/video-date-setting.vue'
import supportV2ChatBubble from './account/support-v2/components/chat-bubble.vue'
import supportV2RequestChat from './account/support-v2/components/support-request-chat.vue'
import supportV2RequestNew from './account/support-v2/components/support-request-new.vue'
import supportV2Request from './account/support-v2/support-request.vue'
// ====== V2 Support ======
import supportV2 from './account/support-v2/support.vue'
import support from './account/support.vue'
// ====== End of Billing components ======
// ====== Verify ID ======
import verify from './account/verify/index.vue'
import verifyIntro from './account/verify/intro.vue'
import verifyPending from './account/verify/pending.vue'
import verifyUploadSlot from './account/verify/slots/upload-slot.vue'
import verifyUpload from './account/verify/upload.vue'
// Video Date
import VideoDateLandingPage from './account/video-date/landing-page.vue'
// Import helper components
import helperEditBtn from './helpers/edit-btn.vue'
import helperFooterBtn from './helpers/footer-buttons.vue'
import helperFooterBtnApvRedesign from './helpers/footer-buttons-apv-redesign.vue'
import statusIndicator from './helpers/status-indicator.vue'
import legalPhilosophy from './legal/philosophy.vue'
// ====== end of V2 Support ======
// Legal components
import legalPrivacy from './legal/privacy.vue'
// News List
import NewsList from './list/news/NewsList.vue'
import mdInput from './md/input.vue'
import mdKryptonInterviewInput from './md/krypton-interview-input.vue'
import mdKryptonTextarea from './md/krypton-textarea.vue'
import mdModalAutocompleteDropdown from './md/modals/autocomplete-dropdown.vue'
import mdBottomSheetAutocomplete from './md/modals/bottom-sheet-autocomplete.vue'
import mdBillingRequired from './md/modals/billing-required.vue'
import mdClientLate from './md/modals/client-late.vue'
import mdClosedEventAbortModal from './md/modals/closed-event-abort.vue'
import mdClosedEventSuccessModal from './md/modals/closed-event-success.vue'
import mdClosedEventTimeoutModal from './md/modals/closed-event-timeout.vue'
import mdClosedEvent from './md/modals/closed-event.vue'
import mdConfirmOnlyModal from './md/modals/confirm-only.vue'
import mdModalConfirm from './md/modals/confirm.vue'
import mdModalDropdown from './md/modals/dropdown.vue'
import mdBottomSheetMenu from './md/modals/bottomsheetmenu.vue'
import mdBottomSheetMultiSelect from './md/modals/bottomsheetmultiselect.vue'
import mdInvertedModalConfirm from './md/modals/inverted-confirm.vue'
// import mdPendingEvent from './md/modals/pending-event.vue'
import mdPhotoErrorModal from './md/modals/photo-error.vue'
import mdPhotoReplaceModal from './md/modals/photo-replace.vue'
import mdRateLeaveModal from './md/modals/rate-leave.vue'
import mdReactivationSuccessEvent from './md/modals/reactivation-success-event.vue'
import mdRejectedPhotosModal from './md/modals/rejected-photos.vue'
import mdVerifyIDRejected from './md/modals/verify-id-rejected.vue'
import mdVerifyIDSuccess from './md/modals/verify-id-success.vue'
import VideoDateInstruction from './md/modals/video-date-instruction.vue'
// Event components
import mdNavbarEvent from './md/navbar-event.vue'
// Import materialize components
import mdNewNavbar from './md/new-navbar.vue'
import mdFaqNavbar from './md/faq-navbar.vue'
import mdNavbar from './md/navbar.vue'
import mdPreloader from './md/preloader.vue'
import mdScreenPreloader from './md/screen-preloader.vue'
import mdSelect from './md/select.vue'
import customInputMonth from './custom-inputs/input-month.vue'
import mdSidenav from './md/sidenav.vue'
import mdSlideToUnlock from './md/slide-to-unlock.vue'
import mdTextarea from './md/textarea.vue'
import account from './tpl/account.vue'
import forgotPassword from './tpl/forgotPassword.vue'
import forgotPasswordSuccess from './tpl/forgotPasswordSuccess.vue'
// Import template components
import login from './tpl/login.vue'
import resetPassword from './tpl/resetPassword.vue'
import vform from './vue/vform.vue'
import vlink from './vue/vlink.vue'
import countryPickerAutocomplete from '@/features/country-picker/components/CountryPickerAutocomplete.vue'

// Create vue specialized components
Vue.component('vform', vform)
Vue.component('vlink', vlink)

// Create materialize components
Vue.component('md-new-navbar', mdNewNavbar)
Vue.component('md-faq-navbar', mdFaqNavbar)
Vue.component('md-navbar', mdNavbar)
Vue.component('md-sidenav', mdSidenav)
Vue.component('md-preloader', mdPreloader)
Vue.component('md-screen-preloader', mdScreenPreloader)
Vue.component('md-input', mdInput)
Vue.component('md-textarea', mdTextarea)
Vue.component('md-select', mdSelect)
Vue.component('input-month', customInputMonth)

const mdAutocomplete = require('./md/autocomplete.vue').default

Vue.component('md-autocomplete', mdAutocomplete)
Vue.component('md-modal-dropdown', mdModalDropdown)
Vue.component('md-bottom-sheet-menu', mdBottomSheetMenu)
Vue.component('md-bottom-sheet-multiselect', mdBottomSheetMultiSelect)
Vue.component('md-modal-autocomplete-dropdown', mdModalAutocompleteDropdown)
Vue.component('md-bottomsheet-autocomplete', mdBottomSheetAutocomplete)
Vue.component('md-modal-confirm', mdModalConfirm)
Vue.component('md-inverted-modal-confirm', mdInvertedModalConfirm)
Vue.component('md-photo-error-modal', mdPhotoErrorModal)
Vue.component('md-photo-replace-modal', mdPhotoReplaceModal)
Vue.component('md-slide-to-unlock', mdSlideToUnlock)
Vue.component('md-modal-rate-leave', mdRateLeaveModal)
Vue.component('md-modal-closed-event-timeout', mdClosedEventTimeoutModal)
Vue.component('md-modal-closed-event-success', mdClosedEventSuccessModal)
Vue.component('md-modal-closed-event-abort', mdClosedEventAbortModal)
Vue.component('md-client-late', mdClientLate)

Vue.component('md-pending-event', require('./md/modals/pending-event.vue').default)

Vue.component('md-closed-event', mdClosedEvent)
Vue.component('md-krypton-textarea', mdKryptonTextarea)
Vue.component('md-krypton-interview-input', mdKryptonInterviewInput)
Vue.component('md-confirm-only', mdConfirmOnlyModal)
Vue.component('md-verify-id-success', mdVerifyIDSuccess)
Vue.component('md-verify-id-rejected', mdVerifyIDRejected)
Vue.component('md-billing-required', mdBillingRequired)
Vue.component('md-reactivation-success-event', mdReactivationSuccessEvent)

// Create helper components
Vue.component('h-edit-btn', helperEditBtn)
Vue.component('h-footer-btns', helperFooterBtn)
// TODO: will eventually replace all other footer btns
Vue.component('h-footer-btns-apv-redesign', helperFooterBtnApvRedesign)
Vue.component('status-indicator', statusIndicator)

// Create template components
Vue.component('login', login)
Vue.component('forgot-password', forgotPassword)
Vue.component('forgot-password-success', forgotPasswordSuccess)
Vue.component('reset-password', resetPassword)
Vue.component('account', account)

// Create route components
Vue.component('dashboard', dashboard)
Vue.component('logout', logout)
Vue.component('profile', profile)
Vue.component('profile-motto', profileMotto)
Vue.component('profile-details', profileDetails)
Vue.component('profile-basic-details', profileBasicDetails)
Vue.component('profile-character-details', profileCharacterDetails)
Vue.component('profile-interview', profileInterview)
Vue.component('photos-preview', photosPreview)
Vue.component('photos-gallery', photosGallery)
Vue.component('photos-crop', photosCrop)
Vue.component('photos-anonimize', photosAnonimize)
Vue.component('photo-guide', photoGuide)
Vue.component('photo-tips', photoTips)

// Video Date
Vue.component('video-date-landing-page', VideoDateLandingPage)
Vue.component('video-date-instruction', VideoDateInstruction)

// Dashboard News
Vue.component('news-list', NewsList)

// Interview V2
Vue.component('profile-interview-v2', profileInterviewV2)
Vue.component('profile-interview-v2-edit', profileInterviewV2Edit)

// Manage Photos V2
Vue.component('photos-v2', photosV2)
Vue.component('photos-gallery-v2', photosGalleryV2)
Vue.component('photos-gallery-v2-photo', photosGalleryV2Photo)
Vue.component('photos-gallery-v2-photo-empty', photosGalleryV2PhotoEmpty)
Vue.component('photos-gallery-v2-live-photos', photosGalleryV2LivePhotos)
Vue.component('photos-gallery-v2-private-photos', photosGalleryV2PrivatePhotos)
Vue.component('photos-gallery-v2-add-primary-photo', photosGalleryV2AddPrimaryPhoto)
Vue.component('photos-gallery-v2-rejected-photos', mdRejectedPhotosModal)

// Setting components
Vue.component('setting', setting)
Vue.component('account-setting', settingAccount)
Vue.component('password-setting', settingPassword)
Vue.component('mobile-setting', settingMobile)
Vue.component('email-setting', settingEmail)
Vue.component('verify-email', settingEmailVerify)
Vue.component('support', support)
Vue.component('video-date-setting', videoDateSetting)
Vue.component('private-place-setting', privatePlaceSetting)
Vue.component('vip-photos-setting', vipPhotosSetting)

// Availability
Vue.component('availability', availability)
Vue.component('availability-card', availabilityCard)
Vue.component('availability-block', availabilityBlock)

// Legal components
Vue.component('legal-privacy', legalPrivacy)
Vue.component('legal-philosophy', legalPhilosophy)

const legalTermsConditions = require('./legal/terms-conditions.vue').default

Vue.component('legal-terms-conditions', legalTermsConditions)

// Event components
Vue.component('events', events)
Vue.component('empty-event', emptyEvent)
Vue.component('event-checkin', eventCheckin)
Vue.component('event-details', eventDetails)
Vue.component('event-declined', eventDeclined)
Vue.component('event-confirmed', eventConfirmed)
Vue.component('event-cancelled', eventCancelled)
Vue.component('event-rate', eventRate)
Vue.component('event-rate-feedback', eventRateFeedback)
Vue.component('event-late', eventLate)
Vue.component('event-whereis', eventWhereIs)
Vue.component('chat-bubble', eventChatBubble)
Vue.component('md-navbar-event', mdNavbarEvent)

// Billing
Vue.component('billing', billing)
Vue.component('billing-form', billingForm)
Vue.component('billing-unpaid', billingUnpaid)
Vue.component('billing-paid', billingPaid)

// Support V2
Vue.component('support-v2', supportV2)
Vue.component('support-v2-request', supportV2Request)
Vue.component('support-v2-request-new', supportV2RequestNew)
Vue.component('support-v2-request-chat', supportV2RequestChat)
Vue.component('support-v2-chat-bubble', supportV2ChatBubble)

// Feedback
Vue.component('feedback', feedback)

Vue.component('country-picker-autocomplete', countryPickerAutocomplete)

// Verify
Vue.component('verify', verify)
Vue.component('verify-intro', verifyIntro)
Vue.component('verify-upload', verifyUpload)
Vue.component('verify-pending', verifyPending)
Vue.component('verify-upload-slot', verifyUploadSlot)

Vue.component('apv-form', ApvForm)
Vue.component('apv-input', ApvInput)
Vue.component('apv-button', ApvButton)
Vue.component('apv-preloader-circular', ApvPreloaderCircular)

require('./register/_register.js')

Vue.component('currency', require('@/features/units-of-measures/components/Currency.vue').default)

require('@/features/onboarding-lesson-reminder')

const MultiLocationEventIcon = require('@/features/multi-location/components/events/MultiLocationEventIcon.vue').default
const MultiLocationEventAddress = require('@/features/multi-location/components/events/MultiLocationEventAddress.vue').default
Vue.component('multi-location-event-icon', MultiLocationEventIcon)
Vue.component('multi-location-event-address', MultiLocationEventAddress)
