<template>
  <div class="manual-download-page">
    <android-pwa-animation />
  </div>
</template>

<script>
import AndroidPwaAnimation from '@/features/pwa-animation/components/androidPwaAnimation.vue'

export default {
  name: 'ManualDownload',
  components: {
    AndroidPwaAnimation
  }
}
</script>

<style lang="scss" scoped>
.manual-download-page {
  height: 100vh;
  width: 100%;
}
</style>
