<template>
  <div
    id="date-faq"
    class="page max-max-width-center"
  >
    <div class="container">
      <faq-header
        :header-title="$translate('faq.date_faq.title')"
        main-header-logo="/img/faq/date-faq-header-img.svg"
        primary-header-color="#FF0073"
        secondary-header-color="#962BDA"
        header-gradient-angle="236deg"
        header-navbar-redirection="faq"
        :has-tagline="false"
      />
      <ul class="checklist-ul">
        <li v-for="i in 11" :key="i">
          <faq-item
            :item-type="$translate(`faq.date_faq.item_${i}.type`)"
            :content="$translate(`faq.date_faq.item_${i}.content`)"
            :img="$translate(`faq.date_faq.item_${i}.img`)"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import successMeetingCheckMixin from './successMeetingCheck.mixin'
import FAQHeader from '@/features/provider-faq/components/FAQHeader.vue'

export default {
  components: {
    'faq-header': FAQHeader
  },
  mixins: [successMeetingCheckMixin]
}
</script>

<style lang="scss" scoped>
@import '@/features/provider-faq/scss/faq-checklist.scss';
</style>
