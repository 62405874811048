<script>
import AuthService from '@/services/AuthService'

export default {
  name: 'RegisterStart',
  data () {
    return {
      username: null,
      password: null,
      errorMsg: null,
      errorsObj: null,
      isLoading: false
    }
  },
  computed: {
    locale () {
      return this.$i18n.locale
    },
    loginRoute () {
      return {
        name: 'login',
        ...(
          this.locale === 'en' && {
            params: {
              lang: 'en'
            }
          })
      }
    }
  },
  mounted () {
    this.setReferralCode()
    this.checkForTrackingCode()
  },
  methods: {
    isEmpty (field = null) {
      return field === null || field === ''
    },
    async redirectToReactivationRequest () {
      await window.localforage.setItem('contact_detail', this.username)
      this.$router.push({ name: 'reactivate' })
    },
    async handleRegister () {
      this.errorMsg = null
      this.isLoading = true

      try {
        let registerResponse

        const payload = {
          email: this.username,
          password: this.password
        }

        const refcode = this.checkForReferralCode()

        if (refcode) {
          payload.refcode = refcode
        }

        const trackingcode = this.checkForTrackingCode()

        if (trackingcode) {
          payload.trackingcode = trackingcode
        }

        registerResponse = await AuthService.register(payload)

        // Check if deactivated provider trying to register
        const isDeactivatedUser = registerResponse?.data?.deactivated === 1
        if (isDeactivatedUser) {
          this.redirectToReactivationRequest()
        } else {
          const loginResponse = await AuthService.login(this.username, this.password, 1)
          const authorizationToken = loginResponse.authorization

          window.api.authorization = authorizationToken

          await window.localforage.setItem('authToken', btoa(authorizationToken))

          const user = await AuthService.getUser()

          const refcodeFromStorage = localStorage.getItem('refcode')

          if (refcodeFromStorage) {
            localStorage.removeItem('refcode')
          }

          const trackingcodeFromStorage = localStorage.getItem('trackingcode')

          if (trackingcodeFromStorage) {
            localStorage.removeItem('trackingcode')
          }

          this.$store.commit('setUser', user)
          // if deactivated then redirect to reactivation
          this.$router.push({ name: 'register-agreement' })
        }
      } catch (err) {
        this.errorMsg = err?.message ?? 'Unexpected error'

        if (err.data) {
          this.errorsObj = err?.data
          if (err.data.email) {
            // Get the first error
            this.errorMsg = err.data.email[0]
          } else if (err.data.password) {
            // - I will extract password related errors and display it directly on the top (the data field for this is errMsg).
            this.errorMsg = err.data.password[0]
          }
        }
      }

      this.isLoading = false
    },
    handleUsernameChange (val) {
      this.username = val
      this.removeFieldError('email')
    },
    handlePasswordChange (val) {
      this.password = val
      this.removeFieldError('password')
    },
    removeFieldError (field = null) {
      if (this.errorsObj && this.errorsObj[field]) {
        delete this.errorsObj[field]
      }
    },
    setReferralCode () {
      const refcodeFromUrl = this.checkForReferralCode()

      if (refcodeFromUrl) {
        localStorage.setItem('refcode', refcodeFromUrl)

        return
      }

      try {
        const refcodeFromStorage = localStorage.getItem('refcode')
        const trackingcodeFromStorage = localStorage.getItem('trackingcode')

        if (!refcodeFromUrl && refcodeFromStorage) {
          this.$router.push({
            query: {
              refcode: refcodeFromStorage,
              ...(trackingcodeFromStorage && { trackingcode: trackingcodeFromStorage })
            },
            name: 'register-start',
            ...(
              this.locale === 'en' && {
                params: {
                  lang: 'en'
                }
              })
          })
        }
      } catch (err) {
        console.log('err')
      }
    },
    checkForReferralCode () {
      // Checks the query parameter if there's a referral code
      let refCode = this.$route?.query?.refcode ?? null

      if (refCode && (refCode.includes('?') || refCode.includes('&'))) {
        refCode = refCode.substring(0, refCode.indexOf('?'))
        localStorage.setItem('refcode', refCode)
      }

      return refCode
    },
    checkForTrackingCode () {
      // Checks the query parameter if there's a referral code
      let trackingCode = this.$route?.query?.trackingcode ?? null
      let refCode = this.$route?.query?.refcode ?? null

      if (!trackingCode && refCode && (refCode.includes('?') || refCode.includes('&'))) {
        trackingCode = refCode.slice(refCode.indexOf('=') + 1)
        localStorage.setItem('trackingcode', trackingCode)
      }

      return trackingCode
    }
  },
  render () {
    return this.$scopedSlots.default({
      data: {
        username: this.username,
        password: this.password,
        errorMsg: this.errorMsg,
        errorsObj: this.errorsObj,
        isLoading: this.isLoading,
        loginRoute: this.loginRoute
      },
      actions: {
        translate: (key, opts) => { return this.$t(key, opts) },
        getFieldError: (field) => (this.errorsObj && this.errorsObj[field]) ? this.errorsObj[field][0] : null
      },
      bindings: {
        handleRegister: this.handleRegister,
        handleUsernameChange: this.handleUsernameChange,
        handlePasswordChange: this.handlePasswordChange,
        isEmpty: this.isEmpty
      }
    })
  }
}
</script>

<i18n src="../translations.json"></i18n>
