<template>
  <div v-if="showPopup" id="super-provider-popup">
    <div class="super-provider-popup__backdrop" data-test="sp-popup-backdrop">
      <div class="super-provider-popup__modal">
        <a href="#" class="super-provider-popup__close-btn" data-test="sp-popup-close" @click.prevent="closePopup">
          <i class="material-icons">close</i>
        </a>
        <!-- Render super provider popup Success or Fail -->
        <component :is="renderHeaderComponent" />
        <div class="super-provider-popup__description" data-test="sp-popup-explanation-text" v-text="description" />
        <button
          data-test="sp-popup-cta"
          class="super-provider-popup__cta btn waves-effect waves-dark"
          @click.prevent="closePopup({ redirect: { name: 'super-provider' } })">{{ ctaText }}</button>
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/super-provider/translations/popup.json"></i18n>

<script>
import { putSuperProviderSettingsSeen } from '@/features/super-provider/services/SuperProviderService.js'
import PopupHeaderFail from '@/features/super-provider/components/popup-components/header-fail.vue'
import PopupHeaderSuccess from '@/features/super-provider/components/popup-components/header-success.vue'

export default {
  components: {
    PopupHeaderFail,
    PopupHeaderSuccess
  },
  data: () => ({
    isLoading: false
  }),
  computed: {
    renderHeaderComponent () {
      return this.isSuperProvider ? 'popup-header-success' : 'popup-header-fail'
    },
    showPopup () {
      // Value should NOT be seen in order to show the popup
      const DEFAULT_SEEN_STATUS = 1
      return !Boolean(this.user?.settings?.is_super_provider_review_seen ?? DEFAULT_SEEN_STATUS)
    },
    user () {
      return this.$store.getters.getUser
    },
    isSuperProvider () {
      return Boolean(this.user?.settings?.is_super_provider ?? 0)
    },
    title () {
      return this.isSuperProvider ? this.$t('super_provider.title') : this.$t('not_super_provider.title')
    },
    status () {
      return this.isSuperProvider ? this.$t('super_provider.status') : this.$t('not_super_provider.status')
    },
    statusClass () {
      return this.isSuperProvider ? 'super-provider-popup__icon--success' : 'super-provider-popup__icon--fail'
    },
    statusIcon () {
      return this.isSuperProvider ? 'check_circle' : 'cancel'
    },
    description () {
      return this.isSuperProvider ? this.$t('super_provider.description') : this.$t('not_super_provider.description')
    },
    ctaText () {
      return this.isSuperProvider ? this.$t('super_provider.cta') : this.$t('not_super_provider.cta')
    }
  },
  methods: {
    closePopup (actions = null) {
      // Spam protection
      if (this.isLoading) return false

      this.isLoading = true
      return putSuperProviderSettingsSeen()
        .then(() => {
          this.loading = false
          this.updateStoreForReviewPopupSeen()
          if (actions?.redirect) {
            this.$router.push(actions.redirect)
          }
        })
        .catch(err => {
          this.loading = false
          this.handleErrorMessages(err)
        })
    },
    handleErrorMessages (err = ({ message: '' })) {
      this.$notifications.toast(err?.message, 5000)
    },
    updateStoreForReviewPopupSeen (val = 1) {
      /**
       * Instead of doing a full API call for /user endpoint
       * just to make sure that the popup is seen
       * we will instead mutate the vuex,
       * since it putSuperProviderSettingsSeen is already successful at this point
      */
      this.$store.commit('setUserSettingReviewPopupSeen', val)
    }
  }
}
</script>

<style lang="scss" scoped>
#super-provider-popup {
  font-family: 'Proxima Nova';

  .super-provider-popup__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#2E353D, 0.3);
    z-index: 998;

    .super-provider-popup__modal {
      position: relative;
      width: 100%;
      max-width: 568px;
      padding: 46px 28px;
      background-color: #FFFFFF;
      border-radius: 16px;
      box-shadow: 0px 3px 6px #00000029;
      top: 50% !important;
      transform: scalex(1) translate(0, -50%) !important;
      margin: 0 auto;

      @media screen and (max-width: 600px) {
        margin: 0 16px;
        width: 94%;
      }
      @media screen and (max-width: 420px) {
          width: 92%;
      }

      .super-provider-popup__close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 24px;
        color: #8995A3;
      }
      .super-provider-popup__title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;

        img {
          display: block;
          width: 48px;
          margin-right: 8px;
        }
        h2 {
          margin: 0;
          font-size: 22px;
          font-family: 'Proxima Nova Semibold', sans-serif;
          color: #2F3740;
        }
      }
      .super-provider-popup__status {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;
        padding: 4px;
        background-color: #FFFFFF;
        border-radius: 12px;

        .super-provider-popup__icon {
          margin-right: 8px;
          font-size: 18px;
        }
        .super-provider-popup__icon.super-provider-popup__icon--success {
          color: #00D96F;
        }
        .super-provider-popup__icon.super-provider-popup__icon--fail {
          color: #FF6584;
        }
        span {
          display: block;
          font-size: 16px;
          font-family: 'Proxima Nova Semibold', sans-serif;
          color: #7969EE;
        }
      }
      .super-provider-popup__description {
        margin-bottom: 24px;
        font-size: 16px;
        font-family: 'Proxima Nova', sans-serif;
        color: #737B85;
        text-align: center;
      }
      .super-provider-popup__cta {
        display: block;
        margin: 0 auto;
        width: fit-content;
        height: 44px;
        line-height: 44px;
        border-radius: 8px;
        font-size: 16px;
        padding: 0px 32px;
        background: transparent linear-gradient(270deg, #E61472 0%, #B30F59 100%) 0% 0% no-repeat padding-box;
      }
    }
  }
}
</style>
