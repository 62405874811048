var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "slide-to-unlock",
      class: { "is-sending": _vm.isSending, reversed: _vm.isReversed }
    },
    [
      !_vm.isSending
        ? _c("label", [_vm._v(_vm._s(_vm.$props.label))])
        : _c("div", { staticClass: "preloader-wrapper small active" }, [
            _vm._m(0)
          ]),
      _vm._v(" "),
      _c(
        "input",
        _vm._g(
          _vm._b(
            {
              staticClass: "pullee",
              class: { reversed: _vm.isReversed },
              attrs: {
                type: "range",
                min: _vm.min,
                max: _vm.max,
                disabled: _vm.disabled
              },
              domProps: { value: _vm.value }
            },
            "input",
            _vm.$attrs,
            false
          ),
          _vm.inputListeners
        )
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner-layer spinner-green-only" }, [
      _c("div", { staticClass: "circle-clipper left" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _c("div", { staticClass: "gap-patch" }, [
        _c("div", { staticClass: "circle" })
      ]),
      _c("div", { staticClass: "circle-clipper right" }, [
        _c("div", { staticClass: "circle" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }