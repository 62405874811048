var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "apv-card", attrs: { id: "ready-to-go-event-card" } },
    [
      _c("div", { staticClass: "card-header" }, [
        _c("div", { staticClass: "card-header__flex" }, [
          _c("pre", { staticClass: "date uppercase" }, [
            _vm._v(_vm._s(_vm.date))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grey-text card-header__flex card-header__flex--mid" },
          [
            _c("span", { staticClass: "schedule" }, [
              _c(
                "strong",
                {
                  staticClass: "schedule__countdown lilac",
                  class: {
                    "lilac--red": _vm.hasNotification,
                    "lilac--green": _vm.payload.event_seen === 1
                  }
                },
                [
                  !_vm.isToday && !_vm.needsFeedback
                    ? _c("span", [_vm._v(_vm._s(_vm.meetingStart))])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isToday && !_vm.needsFeedback
                    ? _c("span", [_vm._v(_vm._s(_vm.meetingCountdown))])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isPending && !_vm.isCancelled && _vm.needsFeedback
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.feedbackConfirmationCountdown))
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c("small", [
                _vm.isToday && !_vm.isPending && !_vm.isCancelled
                  ? _c("span", [_vm._v(_vm._s(_vm.meetingStart))])
                  : _vm._e(),
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$translate("events.labels.for")) +
                    " " +
                    _vm._s(_vm.payload.meeting_duration) +
                    "h\n        "
                )
              ])
            ]),
            _vm._v(" "),
            !_vm.isCancelled && !_vm.isRejected && _vm.needsFeedback
              ? _c("div", { staticClass: "location" }, [
                  _c("p", { staticClass: "margin-none" }, [
                    _vm._v(_vm._s(_vm.$translate("events.feedback_required")))
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.isCancelled && !_vm.isRejected && !_vm.needsFeedback
              ? _c("div", { staticClass: "location" }, [
                  _vm.isVideoDateMeeting
                    ? _c(
                        "p",
                        { staticClass: "margin-none krypton-purple-text" },
                        [
                          _vm._v(
                            _vm._s(_vm.$translate("events.video_date.title"))
                          )
                        ]
                      )
                    : _vm.isPrivatePlaceOrMultiLocationMeeting
                    ? _c(
                        "div",
                        { staticClass: "event-details-hotel-name" },
                        [
                          _vm.isPrivatePlaceMeeting
                            ? _c(
                                "i",
                                {
                                  staticClass: "material-icons",
                                  attrs: {
                                    "data-test": "event-details-pp-icon"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            person_pin_circle\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isMultiLocationMeeting
                            ? _c("multi-location-event-icon")
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isMultiLocationMeeting
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.payload._city_name))
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(_vm.payload._hotel_name))
                              ])
                        ],
                        1
                      )
                    : _c("p", { staticClass: "margin-none" }, [
                        _vm._v(_vm._s(_vm.payload._hotel_name))
                      ])
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-header__flex" }, [
          _c(
            "strong",
            { staticClass: "amount" },
            [
              _c("currency"),
              _vm._v(_vm._s(_vm.payload.amount_payable) + "\n        "),
              _vm.isMultiLocationMeeting
                ? _c(
                    "div",
                    [
                      _vm._v("+ "),
                      _c("currency"),
                      _vm._v(_vm._s(_vm.payload.travel_cost))
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }