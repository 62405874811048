var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal verti-centered" }, [
    _c("div", { staticClass: "new-modal-content" }, [
      _c("h4", { staticClass: "modal-title" }, [
        _vm._v(_vm._s(_vm.$translate("app.feature_lock_popup.title")))
      ]),
      _vm._v(" "),
      _c("i", { staticClass: "mdi mdi-lock" }),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          _vm._s(_vm.$translate("app.feature_lock_popup.explanation_text"))
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "new-modal-footer" }, [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.confirm($event)
            }
          }
        },
        [_c("strong", [_vm._v(_vm._s(_vm.$translate("app.ok_understand")))])]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }