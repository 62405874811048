<template>
  <div id="faq" class="page wrapper">
    <md-new-navbar :route-name="'help'" />

    <div class="menu-container">
      <div class="menu-content">
        <h2>{{ $translate('faq.title') }}</h2>
        <div v-for="(section, idx) in filteredMenuItems" :key="`faq-section-${idx}`" class="menu-section">
          <div v-if="hasChildItems(section)" :data-category-container="section.title">
            <h3 class="menu-section__title">{{ $translate(section.title) }}</h3>
            <ul class="menus">
              <li v-for="(child, idChild) in section.children" :key="`faq-section-child-${idChild}`" @click.prevent="$router.push({ name: child.routeName })">
                <a href="#" :data-id="child.routeName">
                  <div>
                    <img :src="`/img/faq/${child.icon}`" alt="video date icon">
                  </div>
                  <strong>{{ $translate(child.title) }}</strong>
                </a>
                <img src="@/features/other-menu/assets/menu-icon.svg" alt="chevron right icon">
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import redirectMixin from '@/features/provider-faq/mixins/redirect'

const menuItems = [
  {
    title: 'faq.events_btn.title',
    children: [
      {
        title: 'faq.events_btn.date_faq',
        showCondition: 'hasNoSuccessfulMeetings',
        routeName: 'date-faq',
        icon: 'date.svg'
      },
      {
        title: 'faq.events_btn.communication_faq',
        showCondition: 'hasNoSuccessfulMeetings',
        routeName: 'communication-faq',
        icon: 'communication.svg'
      },
      {
        title: 'faq.events_btn.private_place_faq',
        routeName: 'faq.private-place',
        icon: 'private-place.svg'
      },
      {
        title: 'faq.events_btn.multi_location_faq',
        routeName: 'faq.multi-location',
        icon: 'multi-city.svg'
      }
    ]
  },
  {
    title: 'faq.performance_btn.title',
    open: false,
    children: [
      {
        title: 'faq.performance_btn.total_rating',
        routeName: 'total-rating-faq',
        icon: 'overall-rating.svg'
      },
      {
        title: 'faq.performance_btn.reliability_rating',
        routeName: 'reliability-faq',
        icon: 'reliability-rating.svg'
      },
      {
        title: 'faq.performance_btn.client_rating',
        routeName: 'client-faq',
        icon: 'client-rating.svg'
      },
      {
        title: 'faq.performance_btn.super_provider',
        routeName: 'super-provider-faq',
        icon: 'super-provider.svg'
      }
    ]
  },
  {
    title: 'faq.inbox_btn.title',
    open: false,
    children: [
      {
        title: 'faq.inbox_btn.connect_faq',
        routeName: 'connect-faq',
        icon: 'krypton-connect.svg'
      }
    ]
  },
  {
    title: 'faq.bonus_program_btn.title',
    open: false,
    children: [
      {
        title: 'faq.bonus_program_btn.affiliate',
        routeName: 'affiliate-faq',
        icon: 'referral.svg'
      }
    ]
  }
]

export default {
  mixins: [redirectMixin],
  data: () => ({
    isPerformanceExpanded: false,
    menuItems
  }),
  computed: {
    ...mapGetters({
      hasSuccessfulMeetings: 'getHasSuccessfulMeetings',
      candidateProfile: 'isCandidateProfile'
    }),
    filteredMenuItems () {
      const items = this.menuItems.map(item => {
        // eslint-disable-next-line no-param-reassign
        item.children = item.children.filter(subItem => {
          switch (subItem.showCondition) {
            case 'hasNoSuccessfulMeetings':
              return !this.hasSuccessfulMeetings
            default:
              return true
          }
        })

        return item
      })

      if (this.candidateProfile) {
        items.shift()
      }

      return items
    }
  },
  methods: {
    hasChildItems (menuItem) {
      return menuItem.children !== undefined && menuItem.children.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../other-menu/assets/scss/menu.scss';

#faq {
  .border {
    border-color: #CED7E0;
  }
  .title {
    font-size: 22px;
  }
  a, h5, .title {
    color: #2E353D;
  }
  .button {
    height: 56px;
  }
  .group-button,
  .button {
    padding: 12px 16px;
  }
  hr {
    border: 1px solid #CED6E0;
    border-bottom: 0;
  }
}
</style>
