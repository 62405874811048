var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal", attrs: { id: "registration-review-modal" } },
    [
      _c("div", { staticClass: "modal-content center-align" }, [
        _c("h4", {
          staticClass: "modal-title margin-bottom-none",
          staticStyle: { "font-size": "20px" },
          domProps: {
            innerHTML: _vm._s(
              _vm.$translate("app.registration_review_popup.title")
            )
          }
        }),
        _vm._v(" "),
        _c("i", {
          staticClass: "mdi mdi-lock-open-outline krypton-green-text",
          staticStyle: { "font-size": "72px" }
        }),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "margin-bottom-md margin-top-none",
            staticStyle: { "margin-top": "-8px" }
          },
          [
            _c("strong", [
              _vm._v(
                _vm._s(_vm.$translate("app.registration_review_popup.subtitle"))
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass:
              "center grey-text margin-bottom-none margin-top-none text-darken-1",
            staticStyle: { "font-size": "14px" }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$translate(
                    "app.registration_review_popup.explanation_text_1"
                  )
                ) +
                "\n    "
            )
          ]
        ),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass:
              "center grey-text margin-bottom-none margin-top-none text-darken-1",
            staticStyle: { "font-size": "14px" }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$translate(
                    "app.registration_review_popup.explanation_text_2"
                  )
                ) +
                "\n    "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-footer",
          staticStyle: {
            "text-align": "center",
            "border-top": "1px solid #E1E6EB"
          }
        },
        [
          _c(
            "a",
            {
              staticClass: "btn-flat waves-effect waves-dark krypton-pink-text",
              staticStyle: { "font-size": "16px" },
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.confirm($event)
                }
              }
            },
            [
              _c("strong", [
                _vm._v(_vm._s(_vm.$translate("app.ok_understand")))
              ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "support-number margin-bottom-md margin-top-lg" },
      [_c("strong", [_vm._v("01573582190")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }