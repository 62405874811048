<template>
  <svg id="animated" xmlns="http://www.w3.org/2000/svg" width="65.569" height="65.569" viewBox="0 0 100 100">
    <circle cx="50" cy="50" r="45" fill="none" />
    <path fill="none" stroke-linecap="round" stroke-width="5" stroke="#f5f7fa"
          stroke-dasharray="251.2,0"
          d="M50 10
           a 40 40 0 0 1 0 80
           a 40 40 0 0 1 0 -80" />
    <path fill="none" stroke-linecap="round" stroke-width="5" stroke="#00d96f"
          :stroke-dasharray="getPercentageBarValue"
          d="M50 10
           a 40 40 0 0 1 0 80
           a 40 40 0 0 1 0 -80">
          <!-- <animate attributeName="stroke-dasharray" from="0,251.2" to="251.2,0" dur="5s" /> -->
    </path>
    <text
      id="count"
      class="count-class"
      x="50"
      y="50"
      text-anchor="middle"
      dy="7"
      font-size="20"
      fill="#78838F"
      font-family="Proxima Nova, Helvetica, sans-serif"
    >
      {{ `${percentage}%` }}
    </text>
  </svg>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {
    getPercentageBarValue () {
      const originalValue = 251.2
      const percentageValue = this.percentage / 100
      const calculateValueByPercentage = percentageValue * originalValue
      const remainingValue = originalValue - calculateValueByPercentage
      return `${calculateValueByPercentage}, ${remainingValue}`
    }
  }
}
</script>

<style lang="scss" scoped>
  .count-class {
    font-family: 'Proxima Nova';
    color: #78838F;
  }
</style>
