var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "registration-complete-desktop" } },
    [
      [
        _c("div", { staticClass: "content__area --success" }, [
          _c("img", {
            attrs: {
              id: "k-logo",
              src: "/img/registration/k-logo.svg",
              alt: "k-logo"
            }
          }),
          _vm._v(" "),
          _c("h2", {
            staticClass: "content__title content__title--desktop",
            domProps: {
              innerHTML: _vm._s(_vm.$translate("register.new_complete.title"))
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "content__area drawer --with-long-content --with-both-bars"
          },
          [
            _c("div", { staticClass: "content" }, [
              _c("h2", {
                staticClass: "content__title content__title--mobile",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$translate("register.new_complete.title")
                  )
                }
              }),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("p", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.$translate("register.new_complete.content")))
              ]),
              _vm._v(" "),
              _c("div", { ref: "qrcode", staticClass: "qrcode" }),
              _vm._v(" "),
              _c("p", { staticClass: "qr-code-text" }, [
                _vm._v(
                  _vm._s(
                    _vm.$translate("register.new_complete.qr_code_explainer")
                  )
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "actions flex-grid space-between" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-verify",
                    attrs: { "data-id": "registration-complete-cta" },
                    on: { click: _vm.doSendLinkToMobile }
                  },
                  [
                    _vm.isSending
                      ? _c("span", [_vm._v("Sending link...")])
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$translate(
                                "register.new_complete.send_link_to_mobile"
                              )
                            )
                          )
                        ])
                  ]
                )
              ])
            ])
          ]
        )
      ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "drawer-icon center-align" }, [
      _c("img", {
        attrs: {
          src: "/img/registration/success/apv-preview-new.png",
          alt: "Registration Success"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }