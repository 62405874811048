var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page wrapper", attrs: { id: "support-v2" } },
    [
      _c("md-new-navbar", { attrs: { "route-name": "help" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "content" } }, [
        _c("div", { staticClass: "header center-align" }, [
          _c("h1", { staticClass: "support-v2__title" }, [
            _vm._v(_vm._s(_vm.$translate("support.support_title")))
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("p", { staticClass: "support-v2__description" }, [
            _vm._v(_vm._s(_vm.$translate("support.support_text")))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _vm.tickets.length > 0
              ? _c("p", { staticClass: "support-v2__ticket-list__header" }, [
                  _vm._v(_vm._s(_vm.$translate("support.ticket_list_header")))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.tickets, function(t, i) {
              return _c("support-item", { key: i, attrs: { ticket: t } })
            }),
            _vm._v(" "),
            _c(
              "infinite-loading",
              {
                attrs: { spinner: "spiral" },
                on: { infinite: _vm.infiniteHandler }
              },
              [
                _c("div", { attrs: { slot: "no-more" }, slot: "no-more" }),
                _vm._v(" "),
                _c("div", { attrs: { slot: "no-results" }, slot: "no-results" })
              ]
            )
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "support-v2__footer" }, [
          _c(
            "button",
            {
              staticClass: "support-v2__cta",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goToNewRequest($event)
                }
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$translate("support.buttons.new_ticket")) +
                  "\n      "
              )
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "support-v2__img",
        attrs: {
          src: "/img/support/support-overview.svg",
          alt: "support overview"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }