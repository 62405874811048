<template functional>
  <component :is="injections.components.ResetPasswordController" v-slot="{ data, bindings, actions }">
    <component
      :is="injections.components.AuthView"
      :data="data"
      :positionfixed="data.pFixedFalse"
    >
    <template v-slot:title>
      <h2>{{ actions.translate('resetPassword.title1') }}</h2>
      <h2>{{ actions.translate('resetPassword.title2') }}</h2>
    </template>

    <apv-form
      :is-loading="data.isLoading"
      :disabled="data.isDisabled"
      novalidate
      data-test="reset-password-form"
      @submit="bindings.handlePasswordReset"
    >
      <apv-input
        :value="data.password"
        data-test="reset-password-input-password"
        type="password"
        tabindex="1"
        class="APV-onbo-redesign margin-bottom-md"
        :label="actions.translate('resetPassword.password')"
        :error="actions.getFieldError('password')"
        @input="bindings.handlePasswordChange"
      />
      <apv-input
        :value="data.confirmPassword"
        data-test="reset-password-input-confirm-password"
        type="password"
        tabindex="2"
        class="APV-onbo-redesign margin-bottom-lg"
        :label="actions.translate('resetPassword.confirmPassword')"
        :error="actions.getFieldError('confirmPassword')"
        @input="bindings.handleConfirmPasswordChange"
      />
      <div class="reset-text-description">
        <p>
          <img src="/img/asterisk.svg">
          {{ actions.translate('resetPassword.textDescription') }}
        </p>
      </div>
      <apv-button
        :rounded="8"
        :is-loading="data.isLoading"
        :disabled="bindings.isEmpty(data.password) || bindings.isEmpty(data.confirmPassword)"
        block
        gradient
        type="submit"
        size="large"
        waves-effect="dark"
      >
      {{ actions.translate('messages.globals.continue') }}
      </apv-button>
    </apv-form>

    <div class="footer-auth-nav">
      <div class="login-container">
        <router-link class="login-link" :to="data.loginRoute">{{ actions.translate('register.link2loginText2') }}</router-link>
      </div>
      <div class="register-container">
        <span class="login-label">{{ actions.translate('register.register4free') }}</span>
        <router-link class="register-link" :to="data.registerRoute">{{ actions.translate('login.signUp') }}</router-link>
      </div>
    </div>

    </component>
  </component>
</template>
<script>
import ResetPasswordController from './ResetPasswordController.vue'
import AuthView from '../AuthView.vue'

export default {
  inject: {
    components: {
      default: {
        ResetPasswordController,
        AuthView
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.footer-auth-nav {
  font-size: 14px;
  line-height: 18px;
  margin: 46px 0 38px 0;

  .login-label { color: #2F3740; }

  .login-container { margin-bottom: 28px; }

  .login-link, .register-link {
      color: #DC0064;
      text-decoration: underline;
  }
}

.reset-text-description {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CED6E0;
  border-radius: 12px;
  margin-bottom: 24px;
  padding: 16px;

  p {
    display: inline-flex;
    width: 100%;
    font-size: 12px;
    line-height: 12px;
    margin: 0;

    img {
      margin-right: 8px;
    }
  }
}

.forgot-pass-auth-nav {
  font-size: 14px;
  line-height: 18px;
  margin: 32px 0 60px 0;

  .login-label { color: #2F3740; }

  .login-container { margin-bottom: 28px; }

  .login-link, .register-link {
    color: #DC0064;
    text-decoration: underline;
  }
}

.auth-background {
  position: relative;
  width: 100vw;
  height: 100dvh;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  background-color: white;

  @media (min-width: 769px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
