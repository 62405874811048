import Worker from '../workers/setInterval.worker.js';

const WORKER_INTERVAL = $h.secToMilliseconds(1); // In milliseconds (default: 1 sec)

export default {
  data: () => ({

    worker: null,

  }),
  methods: {

    /**
     * @method startTimer
     *
     * Starts the timer worker and execute the callback function passed.
     *
     * @param {function} cb - Callback function
     */
    startTimer(cb) {

      if (this.worker === null) {
        this.worker = new Worker();
        this.worker.postMessage(WORKER_INTERVAL);
        this.worker.onmessage = cb;
      }

    },

    /**
     * @method stopTimer
     *
     * Stop the timer worker.
     */
    stopTimer() {

      if (this.worker) {
        this.worker.terminate();
        this.worker = null;
      }

    },

    /**
     * @method updateCurrentTime
     *
     * Update time in Vuex.
     */
    updateCurrentTime() {

      this.$store.commit('setCurrentTime', moment());
      this.$store.commit('setCurrentTimeTz', moment.tz());

    },

  },
  beforeRouteLeave(to, from, next) {

    this.stopTimer();

    next();

  },
};
