var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "photos" } }, [
    _c(
      "div",
      { staticClass: "wrapper wrapper_fixed", attrs: { id: "crop" } },
      [
        _c("div", { staticClass: "content__area overlay" }, [
          _c("img", {
            attrs: {
              id: "k-logo",
              src: "/img/registration/k-logo.svg",
              alt: "k-logo"
            }
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "content__title content__title--desktop" }, [
            _vm._v(_vm._s(_vm.$translate("photos.pinch_zoom_to_crop")))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "content__area drawer no-y-padding --with-photo-content --with-both-bars-crop content__drawer",
            attrs: { id: "photo-drawer" }
          },
          [
            _c(
              "div",
              { staticClass: "content", staticStyle: { "max-width": "unset" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "top-bar--drawer",
                    staticStyle: {
                      "border-bottom": "none",
                      "justify-content": "space-between"
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.triggerCancelUpload($event)
                          }
                        }
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons",
                            staticStyle: { "margin-right": "8px" }
                          },
                          [_vm._v("close")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "cancel-upload-label" }, [
                          _vm._v(_vm._s(_vm.$translate("photos.cancel_upload")))
                        ])
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "hide", attrs: { id: "content" } },
                  [_c("vform", { ref: "cropForm", on: { submit: _vm.crop } })],
                  1
                ),
                _vm._v(" "),
                _vm.isCroppieViewable
                  ? _c(
                      "div",
                      { staticClass: "crop-container" },
                      [
                        _c("vue-croppie", {
                          ref: "croppie",
                          attrs: {
                            "show-zoomer": false,
                            "enable-resize": false,
                            "enable-exif": true,
                            "enable-orientation": true,
                            boundary: _vm.croppieBoundary,
                            viewport: _vm.croppieViewport
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "crop-controls hide-on-med-and-down" },
                          [
                            _c("button", {
                              staticClass: "crop-controls-btn__zoom-in",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.adjustZoom(true)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("button", {
                              staticClass: "crop-controls-btn__zoom-out",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.adjustZoom(false)
                                }
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "photo-text" }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$translate("photos.pinch_zoom_to_crop")))
                  ])
                ]),
                _vm._v(" "),
                _c("register-navigator-v2", {
                  attrs: { "no-prev": true },
                  on: {
                    next: function($event) {
                      $event.preventDefault()
                      return _vm.getCropData($event)
                    }
                  }
                })
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _vm.loading ? _c("md-screen-preloader") : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }