var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "register-preview" } },
    [
      _c(
        "div",
        {
          class: { "no-ui": _vm.noUi },
          style: _vm.background,
          attrs: {
            id: "" + (_vm.canShowStatusIndicator ? "preview" : "preview_pre")
          },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.toggleNoUi($event)
            }
          }
        },
        [
          _c("div", { staticClass: "gradient-effect top" }),
          _vm._v(" "),
          _c("div", { staticClass: "gradient-effect bottom" }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "close white-text",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.closePhoto($event)
                }
              }
            },
            [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }