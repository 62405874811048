<template>
  <div id="super-provider-faq" class="page">
    <div id="content" class="container">
      <super-provider-faq-header
        :header-title="$t('faq.faq_page_title')"
        :header-tagline="$t('faq.faq_page_description')"
      />
      <div class="question-answer-section">
        <div>
          <ul class="q-and-a">
            <li
              v-for="(__, key) in faqList"
              :key="key">
              <component
                :is="renderListItem(key)"
                v-bind="{ itemKey: key }"
              />
            </li>
          </ul>
        </div>

        <faq-cta
          route-name="super-provider"
          :cta-txt="$translate('faq.performance_btn.super_provider_overview')"
          background-gradient-class="--purple"
        />
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/super-provider/translations/landing-page.json"></i18n>

<script>
import TextItem from '@/features/super-provider/components/faq-list-item/text-item.vue'
import VideoItem from '@/features/super-provider/components/faq-list-item/video-item.vue'
import LinkItem from '@/features/super-provider/components/faq-list-item/link-item.vue'
import SuperProviderFaqHeader from '@/features/super-provider/components/SuperProviderFAQHeader.vue'
import FAQCta from '@/features/provider-faq/components/FAQCta.vue'

export default {
  components: {
    TextItem,
    VideoItem,
    LinkItem,
    SuperProviderFaqHeader,
    'faq-cta': FAQCta
  },
  computed: {
    faqList () {
      return Object.keys(this.$t('faq.list')).map((key) => this.$t('faq.list')[key])
    },
    getPageTitle () {
      return this.$t('faq.faq_page_title')
    },
    getPageDescription () {
      return this.$t('faq.faq_page_description')
    }
  },
  methods: {
    renderListItem (key) {
      const VIDEO_ITEM_KEY = 0
      const LINK_ITEM_KEY = 3
      let component = 'text-item'

      switch (key) {
        case VIDEO_ITEM_KEY:
          component = 'video-item'
          break
        case LINK_ITEM_KEY:
          component = 'link-item'
          break
        default:
          component = 'text-item'
          break
      }

      return component
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/features/provider-faq/scss/q-and-a.scss';

#super-provider-faq #content {
  text-align: left;
  font-size: 14px;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  color: #2F3740;
  font-family: 'Proxima Nova';

  .title {
    font-size: 22px;
  }

  .question-answer-section {
    padding: 30px 24px 72px;
    background-color: #ffffff;

    h2 {
      margin: 0 0 48px;
      font-family: 'Proxima Nova Semibold';
      font-size: 38px;
      color: #2F3740;
    }

    ul#q-and-a {
      margin: 0 0 48px;
      text-align: left;

      & > li:not(:last-child) {
        border-bottom: 1px solid #CAD1D9;
      }
    }
  }
}
</style>
