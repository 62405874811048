<template>
  <div :class="{
    'md-select--NEW_APV_REG_REDESIGN': true,
    'input-field--NEW_APV_REG_REDESIGN': true,
    'half-width': halfWidth,
    'has-value': !isEmpty(model),
    '--disabled': disabled
  }" class="padding-bottom-xs" @click="onClickHandler">
    <select
      v-if="multiple"
      ref="select"
      v-model="model"
      multiple><option
        v-if="!isSelectedExists"
        :disabled="disableDefault"
      >{{ defaultOpt }}</option><slot name="options" /></select>
    <select
      v-else
      ref="select"
      v-model="model"
    ><option
      v-if="!isSelectedExists"
      :disabled="disableDefault"
      value="null">{{ defaultOpt }}</option><slot name="options" />
    </select>
    <!-- Mock the material_select appended elements because we did material_select('destroy') -->
    <div
      v-if="isMobile() || forceMobile"
      id="mocked-select-wrapper-for-mobile"
      class="select-wrapper initialized"
    >
      <input
        type="text"
        class="select-dropdown"
        :value="selectedValueText"
        readonly="true"
      >
    </div>
    <div
      ref="labelcontainer"
      class="label-and-addons-container"
      @click="labelContainerHandler">
      <label :class="{'active': !isEmpty(model)}">{{ label }}</label>
      <i class="material-icons">expand_more</i>
    </div>
    <slot name="errors" />
  </div>
</template>

<script>
import { isEmpty } from '@/constants/helpers.js'

export default {
  props: {
    value: {
      type: [String, Number, Array],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    multiple: {
      type: [String, Boolean],
      default: null
    },
    limit: {
      type: [Number, Boolean],
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    default: {
      type: String,
      default: null
    },
    modalRoot: {
      type: String,
      default: null
    },
    forceMobile: {
      type: [String, Boolean],
      default: null
    },
    desktopDisplayMode: {
      type: String,
      default: null
    },
    scrollToValue: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disableDefault: {
      type: Boolean,
      default: true
    },
    halfWidth: {
      type: Boolean,
      default: false
    },
    listenForReinitiateOptions: {
      type: Boolean,
      default: false
    },
    newDesign: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      model: null,
      isSelectedExists: false,
      bottomSheetInstance: null,
      selectedValueText: null
    }
  },
  computed: {
    defaultOpt () {
      // We don't want to display anything for the on the input
      return ''
    }
  },
  watch: {
    value: {
      handler (newval) {
        if (newval === this.model) return false
        this.setModel(newval)
      },
      immediate: true
    },
    model (newval) {
      var value = $.isNumeric(newval) ? parseInt(newval) : newval
      this.$emit('input', value)
      this.$emit('change', value)
    },
    disabled (isDisabled) {
      if (isDisabled) {
        this.disableInput()
      } else {
        this.enableInput()
      }
    },
    label () {
      if (this.bottomSheetInstance && this.label) {
        this.bottomSheetInstance.title = this.label
      }
    }
  },
  created () {
    this.model = this.multiple ? [] : null
  },
  mounted () {
    this.setModel(this.value)
    this.initializeSelect()
    window.addEventListener('resize', this.initializeSelect)
  },
  updated () {
    this.initializeSelect()
  },
  methods: {
    setScrollingBehaviour ({ disableVerticalScroll = true }) {
      const contentArea = document.getElementsByClassName('content__area')
      const app = document.getElementById('app')

      /* Get the content-area index 1 which is the content container, not the title container */
      if (contentArea[1]) {
        if (disableVerticalScroll) {
          app.classList.add('disable-vertical-scroll')
          contentArea[1].classList.add('disable-vertical-scroll')
        }

        if (!disableVerticalScroll) {
          app.classList.remove('disable-vertical-scroll')
          contentArea[1].classList.remove('disable-vertical-scroll')
        }
      }
    },
    onClickHandler () {
      if (!this.disabled && (this.multiple || this.isMobile() || this.forceMobile)) {
        this.openBottomSheet()
      }
    },
    openBottomSheet () {
      if (this.bottomSheetInstance) {
        this.setScrollingBehaviour({ disableVerticalScroll: true })
        this.setBottomSheetVisibility('flex')
      }
    },
    isEmpty (val) {
      return isEmpty(val) || (!isEmpty(val) && val.length === 0)
    },
    labelContainerHandler () {
      if (!this.isMobile() && !this.forceMobile) {
        $(this.$refs.select).prevAll('input.select-dropdown').trigger('open')
      }
    },
    setModel (val) {
      if (val === undefined) val = null
      this.model = val

      this.setSelectedValueText()
    },
    setSelectedValueText () {
      if (this.bottomSheetInstance?.options?.length > 0) {
        // IF bottom sheet options has value
        this.selectedValueText = this.getSelectedValueText({
          customOptions: this.bottomSheetInstance.options
        })
      } else {
        this.selectedValueText = this.getSelectedValueText()
      }
    },
    setBottomSheetTitle () {
      this.bottomSheetInstance.title = this.label
    },
    getSelectedValueText (param = { customOptions: [] }) {
      let text = null
      const options = param.customOptions.length > 0 ? param.customOptions : this.parseSlotToOptions()

      if (this.multiple) {
        // filter options and join as one String
        // Filter the options and concatenate the text
        text = (options.length > 0 && this.model)
          ? options.filter(o => this.model.includes(o.val)).map(o => o.text).join(', ')
          : null
      } else {
        // find option and return text
        text = (options.length > 0 && this.model !== null)
          ? (options.find(o => o?.val?.toString() === this.model?.toString())?.text ?? null)
          : null
      }

      return text
    },
    initializeSelect () {
      if (!this.isMobile() && !this.forceMobile) {
        // @TODO: Question: Do we still need to initiate material select knowing that on desktop we don't show dropdown anymore?
        $(this.$refs.select).material_select()
      }

      $(this.$refs.select).on('change', ($e) => {
        $e.stopImmediatePropagation()
        this.model = $($e.target).val()
      })

      // Multiple Select must have bottom sheet instance as well
      // Force mobile is responsible for showing it in previous implementation

      if (!this.isMobile() && !this.forceMobile) return

      // Destroy the material select initialization
      // Display the mocked select div instead
      if (this.multiple) {
        this.createBottomSheetMultiSelect()
      } else {
        this.createBottomSheetMenu()
      }

      if (this.disabled) {
        this.disableInput()
      }
    },
    createDropdownModal () {
      // Set default modal root if none is specified
      if (this.modalRoot == null) { var root = 'body' } else { var root = this.modalRoot }
      // Hide the select options to disable them
      var $selectDropdown = $(this.$el).find('input.select-dropdown')
      var $labelContainer = $(this.$el).find('.label-and-addons-container')
      var $dropdownContent = $(this.$el).find('ul.dropdown-content')
      $dropdownContent.addClass('hide')
      // Listen to click event(s) on the select input

      const onDropdownClick = ($e) => {
        // Create new instance of the component
        var DropdownModal = Vue.component('md-modal-dropdown')
        var ExDropdownModal = Vue.extend(DropdownModal)
        var NewDropdownModal = new ExDropdownModal({
          propsData: {
            input: this.model,
            header: this.placeholder,
            options: this.parseSlotToOptions(),
            multiple: this.multiple,
            limit: this.limit
          }
        })
        // Mount the component
        NewDropdownModal.$mount()
        // Append component to given container and save it to the flyModal var
        var flyModal = $(NewDropdownModal.$el).appendTo(root)
        // Initialize component as a modal
        flyModal.modal({
          ready: () => {
            var headerHeight = $(flyModal).find('.modal-header').outerHeight()
            var footerHeight = $(flyModal).find('.modal-footer').outerHeight()
            // Add headerHeight & footerHeight to get amount to subtract from content height
            $(flyModal).find('.modal-content').css({
              height: 'calc(100% - %var%px)'.replace('%var%', headerHeight + footerHeight)
            })
          },
          complete: () => {
            flyModal.remove()
          }
        })
        // Create event handler on confirm
        NewDropdownModal.$on('confirm', (val) => {
          this.model = val; flyModal.modal('close')
        })
        // Create event handler on close
        NewDropdownModal.$on('close', (val) => {
          flyModal.modal('close')
        })
        // Open the modal on click
        flyModal.modal('open')

        if (!this.model && this.scrollToValue) {
          document.querySelector(`.modal input[value="${this.scrollToValue}"]`)
            .scrollIntoView({ block: 'start', behavior: 'smooth' })
        } else if (typeof this.model !== 'object' && this.model) {
          document.querySelector(`.modal input[value="${this.model}"]`)
            .scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }

      $selectDropdown.on('click', ($e) => {
        onDropdownClick($e)
      })

      $labelContainer.on('click', ($e) => {
        $selectDropdown.trigger('click')
      })
    },
    hideDesktopSelectOptions () {
      var $dropdownContent = $(this.$el).find('ul.dropdown-content')
      $dropdownContent.addClass('hide')
    },
    createBottomSheetMultiSelect () {
      this.hideDesktopSelectOptions()
      if (!this.bottomSheetInstance) {
        this.mountBottomSheetMultiSelect()
      }
    },
    async updateBottomSheetOptions () {
      // Challenge: Parse slot options every time the other menu's model are updated
      await this.$nextTick()
      this.bottomSheetInstance.options = this.parseSlotToOptions()
      this.setBottomSheetTitle()
      this.setSelectedValueText()
    },
    mountBottomSheetMultiSelect () {
      const BottomSheetMultiSelect = Vue.component('md-bottom-sheet-multiselect')
      const ExtendedBottomSheetMultiSelect = Vue.extend(BottomSheetMultiSelect)
      var BottomSheetMultiSelectWithProps = new ExtendedBottomSheetMultiSelect({
        propsData: {
          title: this.label,
          options: this.parseSlotToOptions(),
          limit: this.limit,
          input: this.model
        }
      })
      // Mount the component
      BottomSheetMultiSelectWithProps.$mount()
      document.getElementById('app').appendChild(BottomSheetMultiSelectWithProps.$el)

      this.bottomSheetInstance = BottomSheetMultiSelectWithProps

      this.setBottomSheetVisibility('none')

      BottomSheetMultiSelectWithProps.$on('onConfirm', data => {
        this.setModel(data)
        const options = this.parseSlotToOptions()
        // Filter the options and concatenate the text
        this.selectedValueText = options.filter(o => data.includes(o.val)).map(o => o.text).join(', ')
        this.setBottomSheetVisibility('none')
      })

      BottomSheetMultiSelectWithProps.$on('onClose', data => {
        this.setBottomSheetVisibility('none')
      })
    },
    createBottomSheetMenu () {
      this.hideDesktopSelectOptions()
      if (!this.bottomSheetInstance) {
        this.mountBottomSheetMenu()
      }
    },
    mountBottomSheetMenu () {
      var BottomSheetMenu = Vue.component('md-bottom-sheet-menu')
      var ExtendedBottomSheetMenu = Vue.extend(BottomSheetMenu)
      var BottomSheetMenuWithProps = new ExtendedBottomSheetMenu({
        propsData: {
          title: this.label,
          options: this.parseSlotToOptions(),
          desktopDisplayMode: this.desktopDisplayMode,
          newDesign: this.newDesign
        }
      })
      // Mount the component
      BottomSheetMenuWithProps.$mount()
      document.getElementById('app').appendChild(BottomSheetMenuWithProps.$el)

      this.bottomSheetInstance = BottomSheetMenuWithProps

      this.setBottomSheetVisibility('none')

      BottomSheetMenuWithProps.$on('onSelect', data => {
        this.setModel(data)
        const options = this.parseSlotToOptions()
        this.selectedValueText = options.find(o => o.val === this.model).text
        this.setBottomSheetVisibility('none')
      })

      BottomSheetMenuWithProps.$on('onClose', () => {
        this.setBottomSheetVisibility('none')
      })

      // Set parent event listener
      if (this.listenForReinitiateOptions) {
        this.$root.$on('parent-reinitiate-options', this.updateBottomSheetOptions)
      }
    },
    setBottomSheetVisibility (display) {
      if (display === 'none') {
        this.bottomSheetInstance.$el.classList.remove('element--visible')
        // Re-enable scrolling on content__area
        this.setScrollingBehaviour({ disableVerticalScroll: false })
      } else {
        this.bottomSheetInstance.$el.classList.add('element--visible')
      }
    },
    parseSlotToOptions () {
      var options = []
      if (this.$slots && this.$slots.options) {
        // Iterate through the slots (options) and create an array of objects from it
        for (var i = 0; i < this.$slots.options.length; i++) {
          options.push({
            val: this.$slots.options[i].elm?._value ?? null,
            text: this.$slots.options[i].elm?.text ?? null,
            disabled: this.$slots.options[i].elm?.disabled
          })
        }
      }
      // Return the array to be used as options for the modal
      return options
    },
    isMobile () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },
    checkIfSelectedExists () {
      if (this.$slots && this.$slots.options) {
        if (!this.$slots.options._rendered) return false
        return !!_.find(this.$slots.options, (o) => { return o.elm._value === this.model })
      }
      return false
    },
    disableInput () {
      if (!this.isMobile() && !this.forceMobile) {
        $(this.$refs.select).prop('disabled', true)
        $(this.$refs.select).material_select()
      }
    },
    enableInput () {
      if (!this.isMobile() && !this.forceMobile) {
        $(this.$refs.select).prop('disabled', false)
        $(this.$refs.select).material_select()
      }
    }
  }
}
</script>

<style lang="scss">
.md-select--NEW_APV_REG_REDESIGN {
  position: relative;
  margin-bottom: 16px;

  @media (min-width: 992px) {
    & {
      width: 464px;
    }
    &.half-width {
      // Parent container should handle the width
      width: 100%;
    }
  }

  .select-wrapper input.select-dropdown {
    font-size: 16px;
    font-family: 'Proxima Nova Semibold';
    color: #52575B;
    border: 1px solid #CED6E0;
    border-radius: 12px;
    padding-top: 0;
    height: 68px;
    box-sizing: border-box;
    margin: 0;

    &:disabled,
    &[readonly="readonly"] {
      border-bottom: 1px solid #CED6E0 !important;
    }
  }

  .label-and-addons-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    left: 16px;
    padding-right: 32px;
    cursor: pointer;
    color: #52575B;
    position: absolute;
    top: 0;

    label {
      position: relative !important;
      color: #52575B;
      font-size: 16px;
      font-family: 'Proxima Nova Semibold';
      top: 24px;
    }

    label.active {
      top: -10px;
      transform: translate(0px, 0px) scale(1);
      color: #2F3740;
      background: #FFFFFF;
      padding-left: 5px;
      padding-right: 5px;
      font-family: 'Proxima Nova Medium';
      font-size: 12px;
  }

  i.material-icons {
    color: #78838F;
    pointer-events: none;
    position: absolute;
    top: 24px;
    right: 32px;
  }

  }

  &.has-value {
    .select-wrapper input.select-dropdown {
      border: 1px solid #2F3740 !important;
      color: #2F3740;
    }

    label.active {
      color: #52575B
    }
  }
}
</style>
