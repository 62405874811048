var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edit-button" }, [
    _c(
      "a",
      {
        staticClass: "valign-middle waves-effect waves-dark",
        class: {
          "padding-sm": !_vm.redesign,
          "padding-md": _vm.redesign
        },
        attrs: { href: "#" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.fire($event)
          }
        }
      },
      [
        !_vm.redesign
          ? _c("i", { staticClass: "material-icons" }, [_vm._v("edit")])
          : _vm._e(),
        _vm._v(" "),
        _c("strong", [_vm._v(_vm._s(_vm.btnLabel))])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }