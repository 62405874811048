<template>
  <div id="start-screen-root">
    <template>
      <div class="wrapper">
        <div class="content__backdrop content__backdrop--lilac" />
        <div class="content__drawer">
          <div id="start-screen" class="content">
            <div class="start-screen-step">
              <img
                :src="require(`@/features/start-screen/assets/${getCurrentImage}`)"
                :alt="getCurrentImage"
                data-test="start-screen-image"
                :style="[userStartScreenProgress === 2 ? {transform: 'translate(-20%)'} : null]"
              >
              <div class="start-screen-content">
                <h2 data-test="start-screen-title">{{ title }}</h2>
                <p data-test="start-screen-description">{{ content }}</p>
              </div>
            </div>
          </div>

          <navigator
            :total="3"
            :current="userStartScreenProgress"
            no-prev
            primary-at-last
            :next="cta"
            :next-class="{
              'action-btn__black': userStartScreenProgress < 3,
              'action-btn__primary action-btn__blocked': userStartScreenProgress === 3
            }"
            @next.prevent="gotoNextStep"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<i18n src="@/features/start-screen/translations/start-screen.json"></i18n>

<script>
import { START_SCREEN_IMAGES } from '@/features/start-screen/constants.js'
import { mapGetters } from 'vuex'

import navigator from '@components/v2/utilities/navigator.vue'

export default {
  components: {
    navigator
  },
  data: () => ({
    currentPage: 1,
    START_SCREEN_IMAGES
  }),
  computed: {
    ...mapGetters({
      startScreenProgress: 'onboarding/GET_START_SCREEN_PROGRESS',
      user: 'getUser'
    }),
    userStartScreenProgress () {
      return this.startScreenProgress?.progress
    },
    mappedCurrentPageDetails () {
      return [
        {
          page: 1,
          image: START_SCREEN_IMAGES.WELCOME,
          title: this.$t('page_01.title', {
            name: this.user?.profiles?.name ?? ''
          }),
          content: this.$t('page_01.content', {
            name: this.user?.profiles?.name ?? ''
          }),
          cta: this.$t('page_01.cta'),
          stepper: 1
        },
        {
          page: 2,
          image: START_SCREEN_IMAGES.PROMOTE,
          title: this.$t('page_02.title'),
          content: this.$t('page_02.content'),
          cta: this.$t('page_02.cta'),
          stepper: 2
        },
        {
          page: 3,
          image: START_SCREEN_IMAGES.MONETIZE,
          title: this.$t('page_03.title'),
          content: this.$t('page_03.content'),
          cta: this.$t('page_03.cta'),
          stepper: 3
        }
      ]
    },
    getCurrentImage () {
      return this.mappedCurrentPageDetails.find(m => m.page === this.userStartScreenProgress)?.image ?? START_SCREEN_IMAGES.WELCOME
    },
    title () {
      return this.mappedCurrentPageDetails.find(m => m.page === this.userStartScreenProgress)?.title ?? ''
    },
    content () {
      return this.mappedCurrentPageDetails.find(m => m.page === this.userStartScreenProgress)?.content ?? ''
    },
    cta () {
      return this.mappedCurrentPageDetails.find(m => m.page === this.userStartScreenProgress)?.cta ?? ''
    },
    getCurrentActiveStepper () {
      return (idx) => ({ active: this.userStartScreenProgress === idx })
    }
  },
  methods: {
    gotoNextStep () {
      let currentPage = this.userStartScreenProgress
      if (currentPage <= this.mappedCurrentPageDetails.length) {
        currentPage += 1
        this.$store.dispatch('onboarding/UPDATE_START_SCREEN_PROGRESS', currentPage)
          .then(() => {
            if (currentPage > 3) {
            // UPDATE LEGAL DETAILS
              this.$store
                .dispatch('updateAgreeTerms', { agree_terms: AGREEMENT_TERM.ENABLED })
                .finally(() => {
                  this.$router.push({ name: 'legal-terms-conditions' })
                })
            }
          }).catch(err => console.log(err))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./start-screen.style";
</style>
