var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "photos" } },
    [
      [
        _vm.stepper.current == 1
          ? _c("register-photos-primary", {
              attrs: {
                total: _vm.stepper.total,
                current: _vm.stepper.current,
                "stepper-label": _vm.stepperLabel
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.stepper.current == 2
          ? _c("register-photos-others", {
              attrs: {
                total: _vm.stepper.total,
                current: _vm.stepper.current,
                "stepper-label": _vm.stepperLabel
              }
            })
          : _vm._e()
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }