<template>
  <div v-if="options.length" id="origin" class="wrapper">
    <template>
      <div class="content__area">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <p class="content__stepper content__stepper--mobile"> {{ stepperLabel }}</p>
        <h2 class="content__title content__title--desktop">{{ $translate('register.basic.title.origin') }}</h2>
      </div>
      <div class="content__area drawer">
        <div class="content">
          <p class="content__stepper content__stepper--desktop"> {{ stepperLabel }}</p>
          <h2 class="content__title content__title--mobile">{{ $translate('register.basic.title.origin') }}</h2>

          <div class="drawer-icon center-align">
            <img src="/img/registration/basic/origin.svg" alt="globe icon">
          </div>

          <div class="selected-text-container">
            <p v-if="selectedText" class="center-align selected-text">
              {{ selectedText }}
            </p>

            <p v-else> &nbsp; </p>
          </div>

          <md-select
            v-model="payload.origin"
            :force-mobile="true"
            desktop-display-mode="split"
            class="textfield-box-lg"
            :label="$translate('register.basic.origin.input_label')"
            :default="$translate('register.basic.origin.input_default')"
            :placeholder="$translate('register.basic.title.origin')"
          >
            <option v-for="(option, i) in options" :key="`origin-option-${i}`" slot="options" :value="option.key">{{ option.content }}</option>
            <div v-if="errors.has('origin')" slot="errors" class="input-helper red-text">
              {{ errors.first('origin') }}
            </div>
          </md-select>

          <register-navigator-v2 :next-disabled="isCTADisabled" :total="total" :current="current" @prev.prevent="gotoPrev" @next.prevent="submit" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { isEmpty } from '@/constants/helpers.js'
const RegisterProvider = $h.getProvider('RegisterProvider')

export default {
  props: ['total', 'current', 'stepperLabel'],
  data () {
    return {
      payload: {
        origin: null
      }
    }
  },
  computed: {
    isCTADisabled () {
      return isEmpty(this.payload.origin)
    },
    user () {
      return this.$store.getters.getUser
    },
    homeBaseCountry () {
      return this.$store.getters.getHomeBaseCountry
    },
    options () {
      let origins = $h.obj2arr(this.$store.getters.getDropdownOrigin())
      if (origins.length === 0) {
        return []
      }

      origins = origins.filter((origin) => {
        let excludeOrigin = []
        if (this.homeBaseCountry?.toLowerCase() === 'de') {
          excludeOrigin = ['uk', 'ch', 'at']
        } else if (this.homeBaseCountry?.toLowerCase() === 'gb') {
          excludeOrigin = ['de', 'ch', 'at']
        } else if (this.homeBaseCountry?.toLowerCase() === 'ch') {
          excludeOrigin = ['de', 'uk', 'at']
        }

        return !excludeOrigin.includes(origin.key)
      })

      return origins
    },
    selectedText () {
      if (!this.payload.origin) return null
      return this.$store.getters.getDropdownOrigin(this.payload.origin)
    },
    isFormValid () {
      return this.payload.origin
    }
  },
  mounted () {
    this.initializePayload()
  },
  methods: {
    submit () {
      this.errors.clear()

      if (!this.isFormValid) {
        var errorMsg = this.$translate('errors.select', {
          field: this.$translate('register.basic.origin.name')
        })

        this.errors.add({
          field: 'origin',
          msg: errorMsg
        })

        return false
      }

      RegisterProvider.updateBasic('origin', this.payload)
        .then((res) => {
          this.$store.commit('setUser', res.data)
          this.$router.push(this.resolveBasicRoute('height'))
        })
        .catch((err) => {
          this.$notifications.toast(err.message, 5000)
        })
    },
    gotoPrev () {
      this.$router.push(this.resolveBasicRoute('hair-color'))
    },
    resolveBasicRoute (type) {
      return {
        name: 'register-basic',
        params: {
          type: type
        }
      }
    },
    initializePayload () {
      this.payload.origin = this.user.profiles.origin
    }
  }
}
</script>
