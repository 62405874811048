var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal left-align", attrs: { id: "block-client-success" } },
    [
      _c("div", { staticClass: "modal-content center-align" }, [
        _c("h4", {
          staticClass: "modal-title",
          domProps: {
            innerHTML: _vm._s(_vm.$translate("inbox.block.success.title"))
          }
        }),
        _vm._v(" "),
        _c("i", {
          staticClass: "mdi mdi-check-circle krypton-green-text valign-middle",
          staticStyle: { "font-size": "72px" }
        }),
        _c("br"),
        _vm._v(" "),
        _c("p", { staticClass: "content grey-text text-darken-1" }, [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$translate("inbox.block.success.explanation_1")
              )
            }
          }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "krypton-pink-text",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.onSupportLink($event)
                }
              }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$translate("inbox.block.success.explanation_support")
                )
              )
            ]
          ),
          _vm._v(
            _vm._s(_vm.$translate("inbox.block.success.explanation_2")) +
              "\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-footer",
          staticStyle: {
            "border-top": "1px solid #E1E6EB",
            "text-align": "center"
          }
        },
        [
          _c(
            "a",
            {
              staticClass: "btn-flat waves-effect waves-dark krypton-pink-text",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.confirm($event)
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$translate("inbox.cta_buttons.ok_understand")) +
                  "\n    "
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }