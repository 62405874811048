<template>
  <div class="super-provider-card super-provider-prereq-status">
    <h2 class="super-provider-card__title margin-bottom-sm" v-text="$t('title')" />
    <div class="super-provider-card__tooltip-trigger">
      <div v-text="$t('tooltip.trigger', { min: minReqSuccessfulRequests })" />
    </div>
    <ul data-test="sp-prereq-sr-checkbox-container" class="super-provider-prereq-status__checkboxes">
      <li v-for="(val, key) in actualSuccessfulRequests" :key="`succesful-meeting-${key}`" class="super-provider-prereq-status__checkbox successful-meeting">
        <i class="material-icons">done</i>
      </li>
      <li v-for="(val, key) in minRequiredDifference" :key="`empty-placeholder-${key}`" class="super-provider-prereq-status__checkbox empty-placeholder" />
    </ul>
    <p
      data-test="sp-prereq-sr-count"
      class="super-provider-prereq-status__successful-meeting-count-text"
      v-text="$t('successfulMeetingCountText', { count: actualSuccessfulRequests })"
    />
    <p
      data-test="sp-prereq-sr-missing-count"
      class="super-provider-prereq-status__min-required-difference-text"
      v-text="$t('minRequiredDifferenceText', { count: minRequiredDifference })"
    />
    <div class="super-provider-card__box-content" v-text="$t('boxContent', { min: minReqSuccessfulRequests })" />
  </div>
</template>

<i18n src="@/features/super-provider/translations/super-provider-prereq-status.json"></i18n>

<script>
import {
  DEFAULT_SUPER_PROVIDER_REQUIREMENTS,
  DEFAULT_SUPER_PROVIDER_STATUS
} from '@/features/super-provider/constants/super-provider-defaults.js'

export default {
  inject: {
    superProviderData: {
      default: () => ({
        superProviderRequirements: DEFAULT_SUPER_PROVIDER_REQUIREMENTS,
        superProviderStatus: DEFAULT_SUPER_PROVIDER_STATUS
      })
    }
  },
  computed: {
    minReqSuccessfulRequests () {
      return parseInt(this.superProviderData?.superProviderRequirements?.successful_requests?.value ?? 0)
    },
    actualSuccessfulRequests () {
      return parseInt(this.superProviderData?.superProviderStatus?.successful_requests ?? 0)
    },
    minRequiredDifference () {
      return this.minReqSuccessfulRequests - this.actualSuccessfulRequests
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/features/super-provider/sass/super-provider-card";

.super-provider-prereq-status__checkboxes {
  display: flex;
  justify-content: center;
  align-items: center;

  .super-provider-prereq-status__checkbox {
    width: 32px;
    height: 32px;
    margin: 0 4px;

    &.successful-meeting {
      background-color: #00D96F;
      border-radius: 4px;

      i.material-icons {
        font-size: 32px;
        color: #FFFFFF;
      }
    }
    &.empty-placeholder {
      border: 1px dashed #FF6584;
      border-radius: 8px;
    }
  }
}
.super-provider-prereq-status__successful-meeting-count-text,
.super-provider-prereq-status__min-required-difference-text {
  margin: 0;
  font-size: 12px;
  font-family: 'Proxima Nova Semibold', sans-serif;
}
.super-provider-prereq-status__successful-meeting-count-text {
  margin-bottom: 8px;
  color: #00D96F;
}
.super-provider-prereq-status__min-required-difference-text {
  color: #FF6584;
}
</style>
