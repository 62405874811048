<template>
  <div
    class="navigator flex-grid"
    :class="{
      'space-between': !noPrev && !noNext,
      'justify-start': !noPrev && noNext,
      'justify-end': noPrev && !noNext,
    }"
  >
    <steps :total="total" :current="current" :classes="stepClass" />

    <button
      v-if="!noPrev"
      class="action-btn action-btn__secondary"
      :disabled="prevDisabled"
      data-test="navigator-prev"
      @click.prevent="onPrev"
    >
      {{ prevText }}
    </button>

    <button
      v-if="!noNext"
      class="action-btn"
      :class="nextClass"
      :disabled="nextDisabled"
      data-test="navigator-next"
      @click.prevent="onNext"
    >
      {{ nextText }}
    </button>
  </div>
</template>

<script>
import steps from '@components/v2/utilities/steps'

export default {
  components: {
    steps
  },
  props: {
    current: {
      type: Number,
      default: () => 0
    },
    next: {
      type: [String],
      default: () => ''
    },
    nextClass: {
      type: [String, Object],
      default: () => ''
    },
    nextDisabled: {
      type: Boolean,
      default: () => false
    },
    noPrev: {
      type: Boolean,
      default: () => false
    },
    noNext: {
      type: Boolean,
      default: () => false
    },
    prev: {
      type: String,
      default: () => ''
    },
    prevClass: {
      type: String,
      default: () => ''
    },
    prevDisabled: {
      type: Boolean,
      default: () => false
    },
    stepClass: {
      type: String,
      default: () => ''
    },
    total: {
      type: Number,
      default: () => 0
    }
  },
  computed: {
    prevText () {
      return this.prev ? this.prev : this.$translate('register.navigator.prev')
    },
    nextText () {
      return this.next ? this.next : this.$translate('register.navigator.next')
    },
    showFullWidthBtn () {
      return this.primaryAtLast && (this.current === this.total)
    }
  },
  beforeDestroy () {
    this.$store.commit('setHasFixedNavigator', false)
  },
  mounted () {
    this.$store.dispatch('support/checkNewUpdates')

    this.$store.commit('setHasFixedNavigator', true)
  },
  methods: {
    onPrev ($event) {
      this.$emit('prev', $event)
    },
    onNext ($event) {
      this.$emit('next', $event)
    }
  }
}
</script>

<style lang="scss" scoped>
.navigator {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100%;
  max-width: 600px;
  height: 95px;
  border-top: 2px solid #CAD1D9;
  padding: 0 24px;
  background-color: white;
  z-index: 20;
  transform: translate(-50%, 0);

  @media screen and (min-width: 992px) {
    padding: 0 48px;
  }
}

.justify-start {
  justify-content: start;
}
.justify-end {
  justify-content: end;
}
</style>
