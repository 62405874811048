<template>
  <div class="reactivation">
    <div id="content" class="container">
      <h2 data-test="reactivation-success-title" class="title">{{ title }}</h2>
      <img data-test="reactivation-success-icon" class="icon" src="/img/reactivation/success.svg" alt="lock icon">

      <div data-test="reactivation-success-explanation-text" class="description-box">
        <strong>{{ subtitle }}</strong>
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/registration/pages/reactivation/translations/reactivation.json"></i18n>

<script>
const REDIRECT_COUNTDOWN = 5000
export default {
  computed: {
    title () {
      return this.$t('success.title')
    },
    subtitle () {
      return this.$t('success.subtitle')
    }
  },
  mounted () {
    this.redirectToLoginOnCountdown()
  },
  methods: {
    redirectToLogin () {
      this.$router.replace({
        name: 'login',
        ...(
          this.$i18n.locale === 'en' && {
            params: {
              lang: 'en'
            }
          })
      })
    },
    redirectToLoginOnCountdown () {
      const timeOut = setTimeout(() => {
        this.redirectToLogin()
        clearTimeout(timeOut)
      }, REDIRECT_COUNTDOWN)
    }
  }
}
</script>

<style lang="scss" style="scoped">
.reactivation {
  height: 100vh;
  background-color: white;

  > #content {
    background-color: white !important;
    max-width: 376px;
    padding: 32px;
  }

  h2.title {
    font-size: 26px;
    padding: 0 2rem;
  }

  .description-box {
    width: 100%;
    border: 1px solid #CED6E0;
    border-radius: 8px;
    padding: 24px;

    strong {
      font-size: 18px;
      padding: 0 1rem;
    }
  }

  .icon {
    margin-top: 18px;
    margin-bottom: 32px;
  }

  .cta {
    font-family: 'Proxima Nova Semibold';
    background: transparent linear-gradient(270deg, #E61472 0%, #B30F59 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 2px #0000003D;
    border-radius: 8px;
    height: 44px;
  }
}
</style>
