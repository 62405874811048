var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "faq-text-item" },
    [
      _c("div", { staticClass: "question" }, [
        _c("span", { staticClass: "question-number" }, [
          _vm._v(_vm._s(_vm.getItemNumber) + ".")
        ]),
        _vm._v(" "),
        _c("span", {
          domProps: {
            textContent: _vm._s(_vm.$t("faq.list[" + _vm.itemKey + "].title"))
          }
        })
      ]),
      _vm._v(" "),
      _vm._l(_vm.$t("faq.list[" + _vm.itemKey + "].content"), function(
        item2,
        subItemKey
      ) {
        return _c("div", {
          key: subItemKey,
          staticClass: "answer",
          domProps: {
            innerHTML: _vm._s(
              _vm.$t(
                "faq.list[" + _vm.itemKey + "].content[" + subItemKey + "]"
              )
            )
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }