var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer-buttons" }, [
    _c("div", [
      _c(
        "button",
        {
          staticClass:
            "btn btn-secondary display-block waves-effect waves-dark",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.onCancel($event)
            }
          }
        },
        [_vm._v("\n      " + _vm._s(_vm.cancel) + "\n    ")]
      )
    ]),
    _vm._v(" "),
    _c("div", [
      _c(
        "button",
        {
          staticClass:
            "btn btn-raised waves-effect waves-light display-block btn-krypton-pink waves-effect waves-light",
          attrs: { "data-test": "footer-cta-confirm", disabled: _vm.loading },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.onConfirm($event)
            }
          }
        },
        [_vm._v("\n      " + _vm._s(_vm.confirm) + "\n    ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }