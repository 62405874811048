<template>
  <div class="krypton-connect-promo-banner max-width-center">
    <div
      ref="banner-container"
      class="wrapper promo-banner-container --blue"
      :class="{ '--expanded-height': expandedHeight }"
      @click="gotoPage('krypton-connect-overview')">
      <div class="promo-banner">
        <div class="more-requests">
          <i class="material-icons">forum</i>
          <span>{{ getBannerTitle }}</span>
        </div>
        <div class="learn-how">
          {{ getCTAText }}
        </div>
      </div>
    </div>
  </div>
</template>

<i18n src="./../translations/connect.json"></i18n>

<script>
import goToPageMixin from '@/features/krypton-connect/mixins/goToPage'
import fixedMenuMixin from '@components/v2/utilities/fixedMenu'

export default {
  mixins: [goToPageMixin, fixedMenuMixin],
  props: {
    expandedHeight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getCTAText () {
      return this.$t('promo_more_requests')
    },
    getBannerTitle () {
      return this.$t('promo_cta')
    }
  },
  mounted () {
    setTimeout(() => {
      this.adjustBannerBottomPosition()
    }, 300)
  }
}
</script>
<style lang="scss" scoped>
@import "@/features/promo-banner/shared/PromoBanner.style";
@import "./KryptonConnectPromoBanner.style";
</style>
