<template>
  <div id="primary" class="wrapper"
  >
    <template>
      <div class="content__area">
        <img id="k-logo" src="/img/registration/k-logo.svg" alt="k-logo">
        <div class="content__stepper content__stepper--mobile">
          <p> {{ stepperLabel }}</p>
          <!-- TODO help btn functionality -->
          <div class="helper__btn" @click.prevent="openTips">
            <span> {{ $translate('register.photos.others.help') }}</span>
          </div>
        </div>
        <h2 class="content__title content__title--desktop">{{ $translate('register.photos.primary.title') }}</h2>
      </div>
      <div class="content__area drawer --with-long-content --with-both-bars">
        <div class="content">
          <div class="content__stepper content__stepper--desktop">
            <p> {{ stepperLabel }}</p>
            <!-- TODO help btn functionality -->
            <div class="helper__btn" @click.prevent="openTips">
              <span> {{ $translate('register.photos.others.help') }}</span>
            </div>
          </div>

          <h2 class="content__title content__title--mobile">{{ $translate('register.photos.primary.title') }}</h2>

          <div class="drawer-icon center-align">
            <img src="/img/registration/photos/primary.svg" alt="girl in frame">
          </div>

          <photos-gallery />

          <register-navigator-v2
            :total="total"
            :current="current"
            :no-next="true"
            @prev.prevent="$router.push({ name: 'register-overview' })"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ['total', 'current', 'stepperLabel'],
  mounted () {
    this.initializeTips()

    if (this.$route.params.showTipOnMount) {
      this.openTips()
    }
  },
  methods: {
    openTips () {
      this.$router.push({
        name: 'register-photo-tips',
        params: {
          title: this.$translate('register.photos.primary.title'),
          inSession: true,
          isOtherPhotos: false,
          return: this.$route.path
        }
      })
    },
    closeTips () {
      $(this.$refs.modal).modal('close')
      this.destroyCarousel()
    },
    initializeTips () {
      $(this.$refs.modal).modal()
    },
    initializeCarousel () {
      $(this.$refs.carousel).carousel({
        fullWidth: true,
        indicators: true
      })
    },
    destroyCarousel () {
      $(this.$refs.carousel).carousel('destroy')
    },
    clickControl (evt) {
      if (evt.offsetX < 100) {
        $(this.$refs.carousel).carousel('prev')
      } else {
        $(this.$refs.carousel).carousel('next')
      }
    }
  }
}
</script>
