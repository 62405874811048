import PerformanceRankingModel from '@/features/provider-performance/models/PerformanceRanking'

import { buildParam } from '@/helpers/api'

export function fetchOverallRating () {
  return new Promise((resolve, reject) => {
    window.api
      .get({ url: buildParam('/v2/rating') },
        (success) => {
          resolve({
            totalRating: success.data.data.total_rating,
            reliability: success.data.data.reliability_rating,
            client: success.data.data.client_rating,
            rank: success.data.data.rank
          })
        },
        (err) => {
          window.NotificationPlugin.toast(err.message)
          reject(err)
        })
  })
}

function remapRatingSummaryData (data) {
  return {
    totalRating: new PerformanceRankingModel({
      lastMonth: data.last_month.total_rating,
      thisMonth: data.current_month.total_rating,
      others: data.others.total_rating
    }),
    reliability: new PerformanceRankingModel({
      lastMonth: data.last_month.reliability_rating,
      thisMonth: data.current_month.reliability_rating,
      others: data.others.reliability_rating
    }),
    client: new PerformanceRankingModel({
      lastMonth: data.last_month.client_rating,
      thisMonth: data.current_month.client_rating,
      others: data.others.client_rating
    })
  }
}

export function fetchRatingSummary () {
  return new Promise((resolve, reject) => {
    window.api
      .get({ url: buildParam('/v2/rating-summary') },
        (success) => { resolve(remapRatingSummaryData(success.data.data)) },
        (err) => {
          this.$notifications.toast(err.message)
          reject(err)
        })
  })
}
