var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "overview__q-and-a" }, [
    _c("div", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.$translate("krypton_connect.q_and_a.title")))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "faq" }, [
      _c(
        "ul",
        { staticClass: "q_and_a" },
        _vm._l(_vm.questions, function(question, i) {
          return _c(
            "li",
            { key: "q-and-a-item-" + i },
            [
              _vm._t(
                "questionItem",
                [
                  _c(
                    "div",
                    {
                      staticClass: "question",
                      on: {
                        click: function($event) {
                          return _vm.setOpenedAnswer(i)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v(_vm._s(_vm.getIcon(i)))
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "label" }, [
                        _vm._v(_vm._s(question.title))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.openedAnswer === i
                    ? _c("div", {
                        staticClass: "answer",
                        domProps: { innerHTML: _vm._s(question.content) }
                      })
                    : _vm._e()
                ],
                { question: question, index: i }
              )
            ],
            2
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }