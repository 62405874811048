<template>
  <div
    class="onboarding-active-lesson-container"
    data-test="onboarding-active-lesson"
  >
    <div
      v-if="hasActiveLesson"
      id="onboarding-active-lesson"
    >
      <onboarding-lesson-card
        class="onboarding-lesson-card--bottom-sheet"
        :lesson-card="getActiveLesson"
        @click="onLessonClicked"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { LESSON } from '@/features/onboarding-bottom-sheet/constants'
import OnboardingLessonCard from '@/features/onboarding-lesson-card/components/OnboardingLessonCard.vue'
import { LESSON_CARDS } from '@/features/onboarding-lesson-card/constants'
import { OnboardingLessons } from '@/mixins/onboarding'

export default {
  components: {
    OnboardingLessonCard
  },
  mixins: [
    OnboardingLessons
  ],
  computed: {
    ...mapGetters({
      activeLesson: 'onboarding/GET_ACTIVE_LESSON'
    }),
    getKeyAccordingToActiveLesson () {
      const key = LESSON ? Object.keys(LESSON).find(key => LESSON[key] === this.activeLesson) : null
      return key ?? null
    },
    getActiveLessonTitle () {
      return this.getKeyAccordingToActiveLesson
        ? this.$translate(`onboarding.bottom_sheet_title.${this.getKeyAccordingToActiveLesson.toLowerCase()}`)
        : ''
    },
    hasActiveLesson () {
      return parseInt(this.activeLesson) > 0
    },
    getActiveLesson () {
      return this.getKeyAccordingToActiveLesson
        ? LESSON_CARDS[this.getKeyAccordingToActiveLesson.toUpperCase()]
        : ''
    }
  },
  methods: {
    onLessonClicked () {
      const cardID = this.getActiveLesson?.id

      if (cardID) {
        switch (cardID) {
          case this.LESSONS.PHOTOS:
            this.openPhotosLesson()
            break
          case this.LESSONS.INTERVIEW:
            this.openInterviewLesson()
            break
          case this.LESSONS.AVAILABILITY:
            this.openAvailabilityLesson()
            break
          case this.LESSONS.SAFETY:
            this.openSafetyLesson()
            break
          case this.LESSONS.CLIENTS:
            this.openClientsLesson()
            break
          case this.LESSONS.REQUESTS:
            this.openRequestsLesson()
            break
          case this.LESSONS.DATES:
            this.openDatesLesson()
            break
          case this.LESSONS.FEEDBACK:
            this.openFeedbackLesson()
            break
          case this.LESSONS.MONEY:
            this.openMoneyLesson()
            break
          default:
            this.resetActiveLesson()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.onboarding-active-lesson-container {
  margin-bottom: 16px;
}

#onboarding-active-lesson {
  border-radius: 16px;
  background: #EDEEFB;
  display: flex;
  align-items: center;

  .onboarding-lesson-card {
    max-width: none;
  }

  .card-title {
    font-size: 22px;
    font-weight: 600;
    color: #2E353D;
  }

  .card-subtitle {
    font-size: 16px;
    color: #78838F;
  }

  .card-video-cta-container {
    display: flex;
    align-items: center;

    .card-video-cta-txt {
      font-size: 14px;
      color: #6C63FF;
      padding-left: 8px;
    }
  }

  .card-image > img {
    max-width: 140px;
  }

  @media (max-width: 360px) {
    .card-image > img {
      max-width: 120px;
    }
  }

  @media (max-width: 320px) {
    .card-image > img {
      max-width: 100px;
    }
  }

  .pt-16 {
    margin-top: 16px;
  }
}
</style>
