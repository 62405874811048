var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("md-input", {
    staticClass: "textfield-box-lg",
    attrs: {
      value: _vm.email,
      type: "text",
      tabindex: "1",
      label: _vm.$translate("forgotPassword.email")
    },
    on: { input: _vm.onInput }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }