var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "registration-complete" } },
    [
      [
        _vm.showSuccessScreenWithCta
          ? [
              _c("complete-desktop-view"),
              _vm._v(" "),
              _c("complete-mobile-view")
            ]
          : _c("complete-default-view")
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }