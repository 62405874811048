var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "affiliate-landing-page" } },
    [
      _c("md-new-navbar"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container", attrs: { id: "main-container" } },
        [
          _c(
            "div",
            {
              staticClass: "affiliate-title",
              attrs: { "data-test": "affiliate-title" }
            },
            [
              _c("div", {
                staticClass: "affiliate-title--first-line",
                domProps: { textContent: _vm._s(_vm.$t("title.sub")) }
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "affiliate-title--second-line",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("title.main", { amount: _vm.getRewardByCountryCode })
                  )
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "affiliate-jumbotron" }, [
            _c("img", {
              attrs: {
                "data-test": "affiliate-banner-image",
                src: _vm.getAffiliateImg,
                alt: ""
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sharing-description" }, [
            _c("div", { staticClass: "sharing-information-text" }, [
              _vm._v("\n        " + _vm._s(_vm.$t("copy_text")) + "\n      ")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "copy-to-clipboard-container",
                attrs: { "data-test": "affiliate-copy-to-clipboard" }
              },
              [
                _c("div", { staticClass: "referral-url-preview" }, [
                  _vm._v(_vm._s(_vm.affiliateReferralUrl))
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "copy-cta btn display-block waves-effect",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.copyToClipboard($event)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("copy_cta")))]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "separator-text" }, [
              _vm._v("\n        " + _vm._s(_vm.$t("or_txt")) + "\n      ")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "social-icons-container",
                attrs: { "data-test": "affiliate-social-platforms" }
              },
              _vm._l(_vm.socialIconsFiltered, function(socialIcon, i) {
                return _c(
                  "a",
                  _vm._b(
                    {
                      key: i,
                      staticClass: "social-icon",
                      class: socialIcon.class,
                      attrs: { href: _vm.getLink(socialIcon) }
                    },
                    "a",
                    _vm.getProps(socialIcon),
                    false
                  ),
                  [
                    _c("img", {
                      attrs: { src: socialIcon.icon, alt: socialIcon.platform }
                    })
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", {
              staticClass: "potential-text",
              attrs: { "data-test": "affiliate-potential-text" },
              domProps: { innerHTML: _vm._s(_vm.$t("potential_text")) }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "referral-overview-shortcut",
              attrs: { "data-test": "affiliate-referral-overview-cta" },
              on: { click: _vm.goToReferralOverview }
            },
            [
              _c("div", [
                _c("img", {
                  staticClass: "paid-icon",
                  attrs: {
                    src: "/img/affiliate/affiliate-paid-icon.svg",
                    alt: "$"
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "shortcut-txt" }, [
                  _vm._v(_vm._s(_vm.$t("referral_overview_shortcut")))
                ])
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "forward-icon",
                attrs: {
                  src: "/img/affiliate/affiliate-arrow-forward-icon.svg",
                  alt: ">"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "affiliate-content",
              attrs: { "data-test": "affiliate-explanation-text" }
            },
            [
              _c(
                "ul",
                { staticClass: "affiliate-content__list" },
                _vm._l(_vm.$t("list"), function(item, key) {
                  return _c(
                    "li",
                    { key: key, staticClass: "affiliate-content__list-item" },
                    [
                      _c(
                        "div",
                        { staticClass: "affiliate-content__list-item-icon" },
                        [_c("img", { attrs: { src: item.icon, alt: "" } })]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "affiliate-content__list-item-text" },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("list[" + key + "].text", {
                                  amount: _vm.getRewardByCountryCode
                                })
                              ) + "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _vm.translationHasTooltip(item)
                            ? _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons affiliate-tooltip-trigger",
                                  attrs: {
                                    "data-test": "affiliate-tooltip-trigger"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.showTooltip(item.tooltip)
                                    }
                                  }
                                },
                                [_vm._v("help_outline")]
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ]
          ),
          _vm._v(" "),
          _c("affiliate-landing-page-qa", {
            staticClass: "affiliate-landing-qa",
            attrs: { "data-test": "affiliate-q-and-a-section" }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "affiliate-cta fixed" },
            [
              _c("affiliate-share-button", {
                attrs: { "data-test": "affiliate-share-cta" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }