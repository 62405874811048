var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal photo-error-modal" }, [
    _c("div", { staticClass: "modal-content" }, [
      _vm.errorType === _vm.ERROR_TYPES.REVIEW_PENDING
        ? _c("div", [
            _c("p", { staticClass: "status-label" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$translate("photos.error_modal.REVIEW_PENDING.label")
                  ) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "status-text" }, [
              _vm._v("\n        " + _vm._s(_vm.errorMessage) + "\n      ")
            ]),
            _vm._v(" "),
            _vm._m(0)
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.errorType === _vm.ERROR_TYPES.NO_FACE
        ? _c("div", [
            _c("p", { staticClass: "status-label" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$translate("photos.error_modal.NO_FACE.label")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "status-text" }, [
              _vm._v("\n        " + _vm._s(_vm.errorMessage) + "\n      ")
            ]),
            _vm._v(" "),
            _vm._m(1)
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.errorType === _vm.ERROR_TYPES.LOW_RESOLUTION
        ? _c("div", [
            _c("p", { staticClass: "status-label" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$translate("photos.error_modal.LOW_RESOLUTION.label")
                  ) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "status-text" }, [
              _vm._v("\n        " + _vm._s(_vm.errorMessage) + "\n      ")
            ]),
            _vm._v(" "),
            _vm._m(2)
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.errorType === _vm.ERROR_TYPES.NEED_FULL_BODY
        ? _c("div", [
            _c("p", { staticClass: "status-label" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$translate("photos.error_modal.NEED_FULL_BODY.label")
                  ) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "status-text" }, [
              _vm._v("\n        " + _vm._s(_vm.errorMessage) + "\n      ")
            ]),
            _vm._v(" "),
            _vm._m(3)
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.errorType === _vm.ERROR_TYPES.TOO_CLOSE
        ? _c("div", [
            _c("p", { staticClass: "status-label" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$translate("photos.error_modal.TOO_CLOSE.label")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "status-text" }, [
              _vm._v("\n        " + _vm._s(_vm.errorMessage) + "\n      ")
            ]),
            _vm._v(" "),
            _vm._m(4)
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.errorType === _vm.ERROR_TYPES.TOO_CLOSE_EDGE
        ? _c("div", [
            _c("p", { staticClass: "status-label" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$translate("photos.error_modal.TOO_CLOSE_EDGE.label")
                  ) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "status-text" }, [
              _vm._v("\n        " + _vm._s(_vm.errorMessage) + "\n      ")
            ]),
            _vm._v(" "),
            _vm._m(5)
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.errorType > 6
        ? _c("div", [
            _c("p", { staticClass: "status-label" }, [
              _vm._v("\n        " + _vm._s(_vm.error.error_title) + "\n      ")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "status-text" }, [
              _vm._v("\n        " + _vm._s(_vm.errorMessage) + "\n      ")
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-footer" }, [
      _c(
        "a",
        { staticClass: "btn display-block modal-close", attrs: { href: "#" } },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$translate("photos.error_modal.ok_button")) +
              "\n    "
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "error-img center" }, [
      _c("img", {
        staticClass: "responsive-img",
        attrs: {
          src: "/img/photos/edit-error/error-5.png",
          alt: "review-pending"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "error-img center" }, [
      _c("img", {
        staticClass: "responsive-img",
        attrs: {
          src: "/img/photos/edit-error/error-1-new.png",
          alt: "review-pending"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "error-img center" }, [
      _c("img", {
        staticClass: "responsive-img",
        attrs: {
          src: "/img/photos/edit-error/error-4-new.png",
          alt: "review-pending"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "error-img center" }, [
      _c("img", {
        staticClass: "responsive-img",
        attrs: {
          src: "/img/photos/edit-error/error-3.png",
          alt: "review-pending"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "error-img center" }, [
      _c("img", {
        staticClass: "responsive-img",
        attrs: {
          src: "/img/photos/edit-error/error-2.png",
          alt: "review-pending"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "error-img center" }, [
      _c("img", {
        staticClass: "responsive-img error-6-photo",
        attrs: {
          src: "/img/photos/edit-error/error-6.png",
          alt: "review-pending"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }