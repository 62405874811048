const LocaleProvider = $h.getProvider('LocaleProvider')

class ApiProvider {
  constructor (baseurl = '') {
    this._baseurl = baseurl
    this.noBaseurl = baseurl === null || baseurl === undefined
  }

  buildParam (url, obj) {
    var round = 0
    var param = null

    if (obj == null) obj = {}

    obj.locale = LocaleProvider.getLocale()

    for (var key in obj) {
      param = !round ? '?' : param + '&'
      param = param + key + '=' + obj[key]
      round++
    }

    return url + param
  }

  set baseURL (url) {
    this._baseurl = url
  }

  get baseURL () {
    if (this.noBaseurl) {
      return ''
    } else {
      return process.env.KRYPTON_PROVIDER_API ?? this._baseurl
    }
  }

  stripBaseUrl () {
    return new ApiProvider(null)
  }

  login () {
    return this.buildParam(`${this.baseURL}/login`, {})
  }

  getUser () {
    return this.buildParam(`${this.baseURL}/user`, {})
  }

  generateName () {
    return this.buildParam(
      `${this.baseURL}/v2/register/generate-name`,
      {}
    )
  }

  submitSupportTicket () {
    return this.buildParam(`${this.baseURL}/v2/support`, {})
  }

  requestSMSVerification () {
    return this.buildParam(
      `${this.baseURL}/v2/user/send-code`,
      {}
    )
  }

  requestEmailChange () {
    return this.buildParam(
      `${this.baseURL}/v2/user/email/send-code`,
      {}
    )
  }

  verifyEmailChange () {
    return this.buildParam(
      `${this.baseURL}/v2/user/email/verify-code`,
      {}
    )
  }

  changePassword () {
    return this.buildParam(
      `${this.baseURL}/user/settings/password`,
      {}
    )
  }

  verifySMSVerification () {
    return this.buildParam(
      `${this.baseURL}/v2/user/verify-code`,
      {}
    )
  }

  updateUser () {
    return this.buildParam(`${this.baseURL}/v2/user`, {})
  }

  updateUserLanguage () {
    return this.buildParam(`${this.baseURL}/v2/user/language`, {})
  }

  updateProfileBasicDetails () {
    return this.buildParam(`${this.baseURL}/v2/user/vital-stats`, {})
  }

  updateProfileCharacterDetails () {
    return this.buildParam(`${this.baseURL}/v2/user/character`, {})
  }

  updateMotto () {
    return this.buildParam(`${this.baseURL}/v2/user/motto`, {})
  }

  updateInterview () {
    return this.buildParam(`${this.baseURL}/v2/user/interview`, {})
  }

  uploadMainPhoto () {
    return this.buildParam(
      `${this.baseURL}/user/photos/primary`,
      {}
    )
  }

  uploadPhoto () {
    return this.buildParam(
      `${this.baseURL}/user/photos/others`,
      {}
    )
  }

  cropPhoto (id) {
    return this.buildParam(
      `${this.baseURL}/user/photos/${id}/crop`,
      {}
    )
  }

  photoOverlayDefaultWidth (id, isRegister) {
    return this.buildParam(
      `${this.baseURL}/user/photos/${id}/overlay-width`,
      { is_register: isRegister }
    )
  }

  photoOverlayDefaultHeight (id, isRegister) {
    return this.buildParam(
      `${this.baseURL}/user/photos/${id}/overlay-height`,
      { is_register: isRegister }
    )
  }

  anonimizePhoto (id, isRegister) {
    return this.buildParam(
      `${this.baseURL}/user/photos/${id}/overlay`,
      {
        is_register: isRegister
      }
    )
  }

  publishPhoto (id) {
    return this.buildParam(
      `${this.baseURL}/v2/photos/${id}/publish`,
      {}
    )
  }

  deletePhoto (id) {
    return this.buildParam(
      `${this.baseURL}/user/photos/${id}`,
      {}
    )
  }

  register () {
    return this.buildParam(`${this.baseURL}/v2/register/account`, {})
  }

  countries () {
    return this.buildParam(`${process.env.KRYPTON_CLIENT_API}/countries`, {
      apv: 1
    })
  }

  dropdown () {
    return this.buildParam(`${process.env.KRYPTON_CLIENT_API}/dropdown`, {})
  }

  availability (date = '', range = 1) {
    return this.buildParam(`${this.baseURL}/v2/availabilities`, {
      date,
      range
    })
  }

  updateAvailability (availabilityID) {
    return this.buildParam(
      `${this.baseURL}/v2/availabilities/${availabilityID}`,
      {}
    )
  }

  appUsage (userID, isPWA) {
    if (isPWA) {
      return this.buildParam(
        `${this.baseURL}/v2/usage/${userID}/pwa`
      )
    } else {
      return this.buildParam(
        `${this.baseURL}/v2/usage/${userID}/browser`
      )
    }
  }

  cities () {
    return this.buildParam(`${process.env.KRYPTON_CLIENT_API}/cities`, {
      paginate: 0
    })
  }

  onlineAt () {
    return this.buildParam(
      `${this.baseURL}/v2/user/email/verify-code`,
      {}
    )
  }

  sortPhotos () {
    return this.buildParam(`${this.baseURL}/v2/photos/sort`)
  }

  rejectdPhotos () {
    return this.buildParam(`${this.baseURL}/user/photos/rejected`)
  }

  replaceOtherPhoto (photoID) {
    return this.buildParam(
      `${this.baseURL}/user/photos/${photoID}/replace-other`
    )
  }

  replacePrimaryPhoto (photoID) {
    return this.buildParam(
      `${this.baseURL}/user/photos/${photoID}/replace-primary`
    )
  }

  fetchLivePhotos () {
    return this.buildParam(`${this.baseURL}/user/photos`)
  }

  updateReplaceConfirm () {
    return this.buildParam(`${this.baseURL}/v2/photos/confirm`)
  }

  cancelUpload (photoID) {
    return this.buildParam(
      `${this.baseURL}/user/photos/${photoID}/cancel-upload`
    )
  }

  agreeTerms () {
    return this.buildParam(`${this.baseURL}/v2/user/agree-terms`)
  }

  buildEventsPath (eventID = null, postPath = '') {
    let path = `${this.baseURL}/v2/events`

    if (eventID) path = `${path}/${eventID}`
    if (postPath) path = `${path}/${postPath}`

    return this.buildParam(path)
  }

  sendRateFeedback (eventID) {
    return this.buildEventsPath(eventID, 'feedback')
  }

  events (eventID) {
    return this.buildEventsPath(eventID)
  }

  approveEvent (eventID) {
    return this.buildEventsPath(eventID, 'accept')
  }

  declineEvent (eventID) {
    return this.buildEventsPath(eventID, 'decline')
  }

  reactivateEvent (eventID) {
    return this.buildEventsPath(eventID, 'reactivate')
  }

  declineReactivation (eventID) {
    return this.buildEventsPath(eventID, 'reactivate-decline')
  }

  fetchMessageHistory (eventID) {
    return this.buildEventsPath(eventID, 'where-is-he')
  }

  ping () {
    return this.buildParam(`${this.baseURL}/ping`, {})
  }

  fetchFeedbacks (read = null) {
    if (read !== null) {
      return this.buildParam(
        `${this.baseURL}/v2/events-feedbacks`,
        { read }
      )
    }
    return this.buildParam(`${this.baseURL}/v2/events-feedbacks`)
  }

  fetchFeedbacksA22250 (read = null, query) {
    if (read !== null) {
      return this.buildParam(
        `${this.baseURL}/v2/events-feedbacks`,
        { read }
      )
    }
    return this.buildParam(`${this.baseURL}/v2/events-feedbacks`, query)
  }

  checkUnseenFeedback () {
    return this.buildParam(`${this.baseURL}/v2/unseen-feedbacks`)
  }

  photoTipSeen () {
    return this.buildParam(
      `${this.baseURL}/v2/photo-tip-seen`,
      {}
    )
  }

  eventTooltipSeen (eventID) {
    return this.buildEventsPath(eventID, 'tooltip-seen')
  }

  eventNotificationSeen (eventID) {
    return this.buildParam(
      `${this.baseURL}/v2/events-notification/${eventID}/seen`
    )
  }

  expireEvent (eventID) {
    return this.buildEventsPath(eventID, 'expired')
  }

  billingDetails () {
    return this.buildParam(`${this.baseURL}/v2/id-verification/billing-details`)
  }

  idDeadline () {
    return this.buildParam(`${this.baseURL}/v2/id-deadline`)
  }

  idDeadlineProcess () {
    return this.buildParam(`${this.baseURL}/v2/id-deadline-process`)
  }

  uploadIDs (isReupload = false) {
    if (!isReupload) { return this.buildParam(`${this.baseURL}/v2/id-verification/upload`) }
    return this.buildParam(`${this.baseURL}/v2/id-verification/reupload`)
  }

  billingDetailsSeen () {
    return this.buildParam(`${this.baseURL}/v2/id-verification/seen`)
  }

  checkSupportUpdates () {
    return this.buildParam(`${this.baseURL}/v2/supports`, { query: 'is_read:0' })
  }

  supportCategories (ticketID) {
    if (ticketID) { return this.buildParam(`${this.baseURL}/v2/support-categories/${ticketID}`) }
    return this.buildParam(`${this.baseURL}/v2/support-categories`)
  }

  supportTicket (page = 1) {
    const payload = {
      page,
      order_by: 'created_at:desc'
    }

    return this.buildParam(`${this.baseURL}/v2/supports`, payload)
  }

  supportTicketReply (supportID) {
    return this.buildParam(`${this.baseURL}/v2/supports/${supportID}/reply`, {})
  }

  supportTicketChatHistory (supportID, page = 1) {
    return this.buildParam(`${this.baseURL}/v2/supports/${supportID}/history`, { page })
  }

  supportTicketDetail (id) {
    return this.buildParam(`${this.baseURL}/v2/supports/${id}`)
  }

  abortRequestCancellation (eventID) {
    return this.buildParam(`${this.baseURL}/v2/cancellation-request/${eventID}/decline`)
  }

  confirmRequestCancellation (eventID) {
    return this.buildParam(`${this.baseURL}/v2/cancellation-request/${eventID}/confirm`)
  }

  requestCancellationSeen (eventID) {
    return this.buildParam(`${this.baseURL}/v2/cancellation-request/${eventID}/seen`)
  }

  requestCancellationTimeout (eventID) {
    return this.buildParam(`${this.baseURL}/v2/cancellation-request/${eventID}/timeout`)
  }

  skypeSetting () {
    return this.buildParam(`${this.baseURL}/user/settings/skype`)
  }

  sendSMSLink () {
    return this.buildParam(`${this.baseURL}/v2/register/sms-link-apv`)
  }

  kryptonConnectSetting () {
    return this.buildParam(`${this.baseURL}/v2/k-connect`)
  }

  kryptonPrivatePlaceSetting () {
    return this.buildParam(`${this.baseURL}/user/settings/private-place`)
  }

  kryptonConnectInbox (queryFilters = {}) {
    return this.buildParam(`${this.baseURL}/v2/k-connect/inbox`, queryFilters)
  }

  kryptonConnectMessages (tokenID) {
    return this.buildParam(`${this.baseURL}/v2/k-connect/messages/${tokenID}`)
  }

  kryptonConnectMessageReply (tokenID) {
    return this.buildParam(`${this.baseURL}/v2/k-connect/messages/${tokenID}/reply`)
  }

  kryptonConnectBlacklistClient (clientID) {
    return this.buildParam(`${this.baseURL}/v2/k-connect/blacklist/${clientID}`)
  }

  appNotifications () {
    return this.buildParam(`${this.baseURL}/v2/notifications`)
  }

  notificationSeen () {
    return this.buildParam(`${this.baseURL}/v2/notifications-seen`)
  }

  fetchActiveLesson () {
    return this.buildParam(`${this.baseURL}/v2/active-lesson`)
  }

  fetchLessonProgress (lessonId) {
    return this.buildParam(`${this.baseURL}/v2/lesson-progress/${lessonId}`)
  }

  completeLesson (lessonId) {
    return this.buildParam(`${this.baseURL}/v2/lesson-progress/${lessonId}/completed`)
  }

  submitLessonFeedback (lessonId) {
    return this.buildParam(`${this.baseURL}/v2/lesson-progress/${lessonId}/feedback`)
  }

  viewLesson (lessonId) {
    return this.buildParam(`${this.baseURL}/v2/lesson-progress/${lessonId}/view-module`)
  }
}

export default new ApiProvider()
