import OnboardingProvider from '@/providers/OnboardingProvider'
import VerificationProvider from '@/providers/VerificationProvider'
import { firstLoginSeen } from '@/features/lite/utils/LiteAPI'
import { LESSONS, SCREENS } from '@/features/onboarding-lesson/constants'

const DEFAULT_START_SCREEN_PROGRESS = { id: null, progress: 0 }

/**
 * Vuex State
*/
// TODO: change to new constant value
const state = {
  activeLesson: 0,
  onboardingProgress: 0,
  idDeadlineTimer: null,
  idDeadlineMoment: null,
  openedLesson: {
    type: LESSONS.NONE,
    screen: SCREENS.NONE
  },
  startScreenProgress: DEFAULT_START_SCREEN_PROGRESS,
  showBottomSheet: 0,
  showDashboardIntro: 0,
  displayBottomSheetInMaxView: 0,
  isActiveLessonCompleted: 1
}

/**
 * State getters
*/
const getters = {
  GET_ACTIVE_LESSON (state) {
    return state.activeLesson
  },
  GET_ONBOARDING_PROGRESS (state) {
    return state.onboardingProgress
  },
  GET_ID_DEADLINE_TIMER (state) {
    return state.idDeadlineTimer
  },
  GET_ID_DEADLINE_MOMENT (state) {
    return state.idDeadlineMoment
  },
  GET_OPENED_LESSON (state) {
    return state.openedLesson
  },
  GET_START_SCREEN_PROGRESS (state) {
    return state.startScreenProgress
  },
  GET_SHOW_BOTTOM_SHEET (state) {
    return state.showBottomSheet
  },
  GET_SHOW_DASHBOARD_INTRO (state) {
    return state.showDashboardIntro
  },
  GET_DISPLAY_BOTTOM_SHEET_IN_MAX_VIEW (state) {
    return state.displayBottomSheetInMaxView
  },
  GET_IS_ACTIVE_LESSON_COMPLETED (state) {
    return state.isActiveLessonCompleted
  }
}

/**
 * State mutations
*/
const mutations = {
  SET_ACTIVE_LESSON (state, activeLesson = 0) {
    state.activeLesson = activeLesson
  },

  SET_ONBOARDING_PROGRESS (state, onboardingProgress = 0) {
    state.onboardingProgress = onboardingProgress
  },

  SET_ID_DEADLINE_TIMER (state, idDeadlineTimer = null) {
    state.idDeadlineTimer = idDeadlineTimer
  },

  SET_ID_DEADLINE_MOMENT (state, idDeadlineMoment = null) {
    state.idDeadlineMoment = idDeadlineMoment
  },

  SET_OPENED_LESSON (state, openedLesson) {
    state.openedLesson = openedLesson
  },

  SET_START_SCREEN_PROGRESS (state, startScreenProgress) {
    state.startScreenProgress = startScreenProgress
  },

  SET_SHOW_BOTTOM_SHEET (state, showBottomSheet) {
    state.showBottomSheet = showBottomSheet
  },

  SET_SHOW_DASHBOARD_INTRO (state, showDashboardIntro = false) {
    state.showDashboardIntro = showDashboardIntro
  },

  SET_DISPLAY_BOTTOM_SHEET_IN_MAX_VIEW (state, displayBottomSheetInMaxView) {
    state.displayBottomSheetInMaxView = displayBottomSheetInMaxView
  },

  SET_IS_ACTIVE_LESSON_COMPLETED (state, isCompleted) {
    state.isActiveLessonCompleted = isCompleted
  }
}

/**
 * State actions
*/
const actions = {
  FETCH_ACTIVE_LESSONS ({ commit }, isCandidateProfile = false) {
    OnboardingProvider.fetchActiveLesson()
      .then((res) => {
        if (isCandidateProfile && (res?.data?.show_bottom_sheet ?? false)) {
          commit('SET_DISPLAY_BOTTOM_SHEET_IN_MAX_VIEW', 1)
        }

        commit('SET_ACTIVE_LESSON', res?.data?.active_lesson ?? null)
        commit('SET_ONBOARDING_PROGRESS', res?.data?.progress ?? null)
        commit('SET_SHOW_BOTTOM_SHEET', res?.data?.show_bottom_sheet ?? 0)
        commit('SET_SHOW_DASHBOARD_INTRO', res?.data?.show_dashboard_intro ?? false)
      })
  },
  FETCH_ID_DEADLINE ({ commit }) {
    VerificationProvider.fetchIdDeadline()
      .then((res) => {
        commit('SET_ID_DEADLINE_TIMER', res?.data?.deadline ?? null)
      })
  },
  UPDATE_ACTIVE_LESSON ({ commit }, activeLesson) {
    commit('SET_OPENED_LESSON', activeLesson)
  },
  OPEN_PHOTOS_LESSON ({ commit }) {
    commit('SET_OPENED_LESSON', {
      type: LESSONS.PHOTOS,
      screen: SCREENS.START
    })
  },
  OPEN_INTERVIEW_LESSON ({ commit }) {
    commit('SET_OPENED_LESSON', {
      type: LESSONS.INTERVIEW,
      screen: SCREENS.START
    })
  },
  OPEN_AVAILABILITY_LESSON ({ commit }) {
    commit('SET_OPENED_LESSON', {
      type: LESSONS.AVAILABILITY,
      screen: SCREENS.START
    })
  },
  OPEN_SAFETY_LESSON ({ commit }) {
    commit('SET_OPENED_LESSON', {
      type: LESSONS.SAFETY,
      screen: SCREENS.START
    })
  },
  OPEN_CLIENTS_LESSON ({ commit }) {
    commit('SET_OPENED_LESSON', {
      type: LESSONS.CLIENTS,
      screen: SCREENS.START
    })
  },
  OPEN_REQUESTS_LESSON ({ commit }) {
    commit('SET_OPENED_LESSON', {
      type: LESSONS.REQUESTS,
      screen: SCREENS.START
    })
  },
  OPEN_DATES_LESSON ({ commit }) {
    commit('SET_OPENED_LESSON', {
      type: LESSONS.DATES,
      screen: SCREENS.START
    })
  },
  OPEN_FEEDBACK_LESSON ({ commit }) {
    commit('SET_OPENED_LESSON', {
      type: LESSONS.FEEDBACK,
      screen: SCREENS.START
    })
  },
  OPEN_MONEY_LESSON ({ commit }) {
    commit('SET_OPENED_LESSON', {
      type: LESSONS.MONEY,
      screen: SCREENS.START
    })
  },
  OPEN_START_SCREEN ({ commit, state }) {
    commit('SET_OPENED_LESSON', {
      type: state.openedLesson.type,
      screen: SCREENS.START
    })
  },
  OPEN_THUMBS_UP_SCREEN ({ commit, state }) {
    commit('SET_OPENED_LESSON', {
      type: state.openedLesson.type,
      screen: SCREENS.THUMBS_UP
    })
  },
  OPEN_THUMBS_DOWN_SCREEN ({ commit, state }) {
    commit('SET_OPENED_LESSON', {
      type: state.openedLesson.type,
      screen: SCREENS.THUMBS_DOWN
    })
  },
  OPEN_PHOTOS_GOOD_SCREEN ({ commit, state }) {
    commit('SET_OPENED_LESSON', {
      type: state.openedLesson.type,
      screen: SCREENS.PHOTOS_GOOD
    })
  },
  OPEN_PHOTOS_BAD_SCREEN ({ commit, state }) {
    commit('SET_OPENED_LESSON', {
      type: state.openedLesson.type,
      screen: SCREENS.PHOTOS_BAD
    })
  },
  OPEN_VIDEO_SCREEN ({ commit, state }) {
    commit('SET_OPENED_LESSON', {
      type: state.openedLesson.type,
      screen: SCREENS.VIDEO
    })
  },
  OPEN_FEEDBACK_SCREEN ({ commit, state }) {
    commit('SET_OPENED_LESSON', {
      type: state.openedLesson.type,
      screen: SCREENS.FEEDBACK
    })
  },
  RESET_ACTIVE_LESSON ({ commit }) {
    commit('SET_OPENED_LESSON', {
      type: 0,
      screen: 0
    })
  },
  CHECK_START_SCREEN_PROGRESS ({ commit }, userID = null) {
    const startScreenPayload = {
      id: userID,
      progress: 1
    }

    window.localforage.getItem('startScreenProgress', (err, startScreenProgress) => {
      if (!err) {
        const userHaveStartScreenProgress = startScreenProgress && startScreenProgress?.id === userID && startScreenProgress?.progress
        const userDoesNotHaveStartScreenProgress = !startScreenProgress || startScreenProgress?.id !== userID

        if (userHaveStartScreenProgress) {
          startScreenPayload.progress = parseInt(startScreenProgress.progress)
        }

        if (userDoesNotHaveStartScreenProgress) {
          window.localforage.setItem('startScreenProgress', startScreenPayload)
        }
      }

      commit('SET_START_SCREEN_PROGRESS', startScreenPayload)
    })
  },
  UPDATE_START_SCREEN_PROGRESS ({ commit, state }, startScreenProgress) {
    return new Promise((resolve, reject) => {
      const startScreenPayload = state.startScreenProgress
      startScreenPayload.progress = startScreenProgress

      window.localforage.setItem('startScreenProgress', startScreenPayload)
        .then(() => {
          commit('SET_START_SCREEN_PROGRESS', startScreenPayload)
          if (startScreenPayload.progress > 3) {
            firstLoginSeen()
              .then(() => {
                window.localforage.removeItem('startScreenProgress')
                  .then(() => {
                    resolve()
                  })
                  .catch(err => reject(err))
              })
              .catch(err => reject(err))
          } else {
            resolve()
          }
        }).catch(err => reject(err))
    })
  },
  UPDATE_IS_ACTIVE_LESSON_COMPLETED ({commit}, isCompleted) {
    commit('SET_IS_ACTIVE_LESSON_COMPLETED', isCompleted)
  }
}

/// ////////////////////////
// Nothing to do here...
/// ////////////////////////

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
