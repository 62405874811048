class API {
  constructor () {
    this.axios = require('axios').default

    // Adds finally method to axios class
    require('promise.prototype.finally').shim()

    // Setup axios headers
    this.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    const DEFAULT_TIMEOUT = 10000
    this.axios.defaults.timeout = parseInt(process.env.AXIOS_TIMEOUT) || DEFAULT_TIMEOUT // Limit all request to maximum of 10 seconds before timeout
    this.axios.defaults.withCredentials = true // include cookies on all requests
  }

  async get (params = { url: '', config: undefined }, success = (p) => {}, fail = (p) => {}, cleanup = () => {}) {
    try {
      const request = { url: '', config: undefined, ...params }
      success(await this.axios.get(request.url, request.config))
    } catch (err) {
      fail(err)
    } finally {
      cleanup()
    }
  }

  async post (params = { url: '', payload: undefined, config: undefined }, success = (p) => {}, fail = (p) => {}, cleanup = () => {}) {
    try {
      const request = { url: '', payload: undefined, config: undefined, ...params }
      success(await this.axios.post(request.url, request.payload, request.config))
    } catch (err) {
      fail(err)
    } finally {
      cleanup()
    }
  }

  async put (params = { url: '', payload: undefined, config: undefined }, success = (p) => {}, fail = (p) => {}, cleanup = () => {}) {
    try {
      const request = { url: '', payload: undefined, config: undefined, ...params }
      success(await this.axios.put(request.url, request.payload, request.config))
    } catch (err) {
      fail(err)
    } finally {
      cleanup()
    }
  }

  async delete (params = { url: '', config: undefined }, success = (p) => {}, fail = (p) => {}, cleanup = () => {}) {
    try {
      const request = { url: '', config: undefined, ...params }
      success(await this.axios.delete(request.url, request.config))
    } catch (err) {
      fail(err)
    } finally {
      cleanup()
    }
  }

  get headers () {
    return this.axios.defaults.headers.common
  }

  set baseUrl (path) {
    this.axios.defaults.baseURL = path
  }

  set adapter (instance) {
    this.axios.defaults.adapter = instance
  }

  set authorization (token) {
    this.axios.defaults.headers.common.Authorization = token
  }

  get authorization () {
    return this.axios.defaults.headers.common.Authorization
  }

  set requestInterceptor (handlers) {
    const _handlers = { success: (p) => p, failed: (p) => p, ...handlers }
    this.axios.interceptors.request.use(
      function (config) {
        return _handlers.success(config)
      },
      function (error) {
        return Promise.reject(_handlers.failed(error))
      }
    )
  }

  set responseInterceptor (handlers) {
    const _handlers = { success: (p) => p, failed: (p) => p, ...handlers }
    this.axios.interceptors.response.use(
      function (config) {
        return _handlers.success(config)
      },
      function (error) {
        return Promise.reject(_handlers.failed(error))
      }
    )
  }
}

export default API
