var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper register-photos-others", attrs: { id: "primary" } },
    [
      [
        _c("div", { staticClass: "content__area" }, [
          _c("img", {
            attrs: {
              id: "k-logo",
              src: "/img/registration/k-logo.svg",
              alt: "k-logo"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content__stepper content__stepper--mobile" },
            [
              _c("p", [_vm._v(" " + _vm._s(_vm.stepperLabel))]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "helper__btn",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.openTips($event)
                    }
                  }
                },
                [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$translate("register.photos.others.help"))
                    )
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("h2", { staticClass: "content__title content__title--desktop" }, [
            _vm._v(_vm._s(_vm.$translate("register.photos.others.title")))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "content__area drawer --with-long-content --with-both-bars"
          },
          [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "div",
                  { staticClass: "content__stepper content__stepper--desktop" },
                  [
                    _c("p", [_vm._v(" " + _vm._s(_vm.stepperLabel))]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "helper__btn",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.openTips($event)
                          }
                        }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$translate("register.photos.others.help")
                            )
                          )
                        ])
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h2",
                  { staticClass: "content__title content__title--mobile" },
                  [
                    _vm._v(
                      _vm._s(_vm.$translate("register.photos.others.title"))
                    )
                  ]
                ),
                _vm._v(" "),
                _c("p", {
                  staticClass: "protip",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$translate("register.photos.others.protip")
                    )
                  }
                }),
                _vm._v(" "),
                !_vm.isPhotosReachedLimit
                  ? _c("upload-other-photo", {
                      attrs: {
                        "is-register-mode": _vm.isRegisterMode,
                        "photo-trigger":
                          _vm.PHOTO_TRIGGER_VALUES.OTHER_PHOTO_PILL
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("photos-gallery"),
                _vm._v(" "),
                _c("register-navigator-v2", {
                  attrs: {
                    total: _vm.total,
                    current: _vm.current,
                    next: _vm.nextText
                  },
                  on: {
                    prev: function($event) {
                      $event.preventDefault()
                      return _vm.goToRegisterOverview($event)
                    },
                    next: function($event) {
                      $event.preventDefault()
                      return _vm.publish($event)
                    }
                  }
                })
              ],
              1
            )
          ]
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }