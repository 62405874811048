import Modals from './modals'
import * as HelperFunctions from '../constants/helpers'

window.$h = {}

$h.getProvider = HelperFunctions.getProvider
$h.getManager = HelperFunctions.getManager
$h.getModel = HelperFunctions.getModel
$h.getMixin = HelperFunctions.getMixin
$h.getFixture = HelperFunctions.getFixture
$h.getConstant = HelperFunctions.getConstant
$h.processErrors = HelperFunctions.processErrors
$h.obj2arr = HelperFunctions.obj2arr
$h.string2json = HelperFunctions.string2json
$h.groupBy = HelperFunctions.groupBy
$h.hrToSeconds = HelperFunctions.hrToSeconds
$h.minutesToSeconds = HelperFunctions.minutesToSeconds
$h.calcCountdown = HelperFunctions.calcCountdown
$h.calcTimeDiff = HelperFunctions.calcTimeDiff
$h.minToSeconds = HelperFunctions.minToSeconds
$h.secToMilliseconds = HelperFunctions.secToMilliseconds
$h.byteToKilobyte = HelperFunctions.byteToKilobyte
$h.byteToMegabyte = HelperFunctions.byteToMegabyte
$h.prettifySecondsDisplay = HelperFunctions.prettifySecondsDisplay
$h.isPWAMode = window.matchMedia('(display-mode: standalone)').matches ||
               window.matchMedia('(display-mode: fullscreen)').matches ||
               window.navigator.standalone === true ||
               window.location.search.indexOf('utm_source=homescreen') >= 0

require('./modals.js')
require('./api.js')
require('./eventbus.js')

/**
 * register modals
 */
for (const [key, value] of Object.entries(Modals)) {
  $h[key] = value
}
