<script>
/**
 * Buttons
 *
 * @param string size - Set to "large" or "small" to modify size of button. Leave empty to use default size.
 * @param string type - The type of button. Same value as a normal html button. Not applicable for links.
 * @param string wavesEffect - Set to "light" or "dark". Creates a ripple effect on button click.
 * @param number rounded - Sets the button to have rounded corners. Value will be set as size of border radius.
 * @param boolean link - Sets the button to use an anchor tag instead of button.
 * @param boolean block - Sets the button to have a block display and 100% width.
 * @param boolean disabled - Forces the state of the button to be disabled.
 * @param boolean isLoading - Shows a preloader instead of the passed text.
 * @param boolean gradient - Shows a gradient background than standard flat color.
 */

import DefaultView from './button-view-default.vue'
import AnchorView from './button-view-anchor.vue'
import ApvPreloaderCircular from '@/features/apv/preloaders/circular.vue'

export default {
  components: {
    ApvPreloaderCircular
  },
  props: {
    size: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    wavesEffect: {
      type: String,
      default: null
    },
    rounded: {
      type: Number,
      default: 4
    },
    link: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    gradient: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    componentView () {
      return this.link === true ? AnchorView : DefaultView
    },
    computedStyle () {
      return {
        'border-radius': `${this.rounded}px`
      }
    },
    computedClass () {
      // @TODO: On fflag removal the `.btn-redesign` merges with the .btn class
      // So please remove its own instance
      return {
        disabled: this.computedDisabled,
        'btn-block': this.block,
        'waves-effect waves-light': this.wavesEffect === 'light',
        'waves-effect': this.wavesEffect === 'dark',
        'btn-large': this.size === 'large',
        'btn-small': this.size === 'small',
        'btn-gradient': this.gradient,
        'btn-redesign': true
      }
    },
    computedData () {
      return {
        size: this.size,
        type: this.type,
        wavesEffect: this.wavesEffect,
        link: this.link,
        isLoading: this.isLoading,
        disabled: this.computedDisabled,
        styles: this.computedStyle,
        classes: this.computedClass,
        onClick: this.handleClickEvent
      }
    },
    computedDisabled () {
      return this.disabled || this.isLoading
    }
  },
  methods: {
    handleClickEvent (event) {
      if (this.computedDisabled) {
        return false
      }

      this.$emit('click', event)
    }
  }
}
</script>

<template>
  <component :is="componentView" :data="computedData">
    <div v-if="!isLoading">
      <slot />
    </div>
    <apv-preloader-circular v-else class="btn-preloader" />
  </component>
</template>

<style lang="scss" scoped>
.btn {
  font-size: 16px;
  font-family: 'Proxima Nova Semibold';

  .btn-preloader {
    width: 25px;
    height: 25px;
    margin-top: 5px;
  }
  &.btn-block {
    display: block;
    width: 100%;
  }
  &.btn-large {
      height: 44px;
      line-height: 44px;

      .btn-preloader {
        width: 30px;
        height: 30px;
        margin-top: 7px;
      }
  }
  &.btn-gradient:not(:disabled) {
    background: transparent linear-gradient(270deg, #E61472 0%, #B30F59 100%) 0% 0% no-repeat padding-box;
  }

  &.btn-redesign {
    height: 47px;
  }

  &.btn-redesign:not(:disabled) {
    background: transparent linear-gradient(270deg, #FF0073 0%, #DC0064 100%) 0% 0% no-repeat padding-box;
    color: #FFFFFF;
  }

  &.btn-redesign:disabled {
    background: #CED6E0 !important;
    color: #FFFFFF !important;
  }
}

</style>
