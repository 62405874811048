<template>
  <div v-if="isCWPReadyForReveal" id="modal-contact-warning-popup" style="border-radius: 4px;">
    <div class="modal-contact-warning-popup__backdrop" data-test="mcw-popup-backdrop">
      <div class="modal-contact-warning-popup__modal">
        <div class="modal-content">
          <h2 class="contact-warning-modal-title center-align">
            {{ $t('title') }}
          </h2>
          <p class="contact-warning-modal-content center-align">
            {{ $t('content') }}
          </p>
        </div>
        <figure style="border-top: solid 1px #E1E6EB; margin: 0;" />
        <div class="modal-footer">
          <div class="terms-agree-checkbox">
            <input id="terms-agree"
                   v-model="termsAgree"
                   type="checkbox"
                   class="filled-in"
                   :true-value="1"
                   :false-value="0">
            <label for="terms-agree">
              {{ $t('checkbox_text') }}
            </label>
          </div>
          <div class="center-align">
            <button
              type="submit"
              form="room-number-form"
              class="waves-effect waves-dark btn-flat  krypton-pink-text cta-btn"
              :class="{'disabled': !termsAgree, 'active': termsAgree}"
              @click.prevent="closePopup"
            >
              <span>
                {{ $t('cta') }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n src="@/features/contact-warning/translations/popup.json"></i18n>

<script>
import { putContactWarningPopupSeen } from '@/features/contact-warning/services/ContactWarningService'

export default {
  data: () => ({
    isLoading: false,
    termsAgree: false
  }),
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    isCWPReadyForReveal () {
      return this.$store.getters.isCWPReadyForReveal
    }
  },
  methods: {
    closePopup (actions = null) {
      // Spam protection
      if (this.isLoading) return false

      this.isLoading = true
      return putContactWarningPopupSeen()
        .then(() => {
          this.loading = false
          this.updateStoreForPopupStatus()
          if (actions?.redirect) {
            this.$router.push(actions.redirect)
          }
        })
        .catch(err => {
          this.loading = false
          this.handleErrorMessages(err)
        })
    },
    handleErrorMessages (err = ({ message: '' })) {
      this.$notifications.toast(err?.message, 5000)
    },
    updateStoreForPopupStatus (val = 0) {
      /**
       * Instead of doing a full API call for /user endpoint
       * just to make sure that the popup is seen
       * we will instead mutate the vuex,
       * since it putSuperProviderSettingsSeen is already successful at this point
      */
      this.$store.commit('setUserContactWarningPopupStatus', val)

      // Set the CWP Ready for reveal to false
      this.$store.commit('setIsCWPReadyForReveal', false)
    }
  }
}
</script>

<style lang="scss" scoped>
#modal-contact-warning-popup {
  & {
      max-height: 100% !important;
      min-height: 420px;
      transition: opacity 0.3s !important;
      margin: auto;
      width: calc(100% - 60px) !important;
      background: #FFFFFF !important;
      min-width: 375px;
      max-width: 375px;
      font-family: 'Proxima Nova';
  }
  .modal-content {
      & {
          padding: 0 24px;
          font-family: 'Proxima Nova';
          color: #2F3740;
      }
      .contact-warning-modal-title {
          font-size: 20px;
          font-weight: 600;
          margin: 0;
      }
      .contact-warning-modal-content {
          padding-top: 24px;
          color: #2F3740;
          font-size: 18px;
          margin: 0;
          padding-bottom: 17px;
          letter-spacing: 0.18px;
          font-weight: normal;
      }
  }
  .modal-footer {

    .terms-agree-checkbox {
      padding: 22px 24px 12px 24px;

      label {
        padding-left: 32px !important;
      }

      [type="checkbox"].filled-in:checked + label:after {
        border: 2px solid #06D6A0;
        background-color: #06D6A0;
      }

      [type="checkbox"] + label {
        color: #2F3740;
        font-size: 14px;
        padding-left: 11px;
      }

      [type="checkbox"].filled-in:not(:checked) + label:after {
        border: 1px solid #5a5a5a;
        border-radius: 3px;
      }
    }
    font-size: 14px;
    color: #2F3740 !important;
    font-weight: normal;
    margin: 0;
    padding: 0;
    text-align: left;
  }
  .cta-btn {
      font-family: 'Proxima Nova Semibold';
      font-size: 16px;
      border-radius: 10px;
      height: 47px;
      min-width: 295px;

      & > span {
        color: #FFFFFF;
      }

      &.active {
        background: transparent linear-gradient(263deg, #FF0073 0%, #DC0064 100%) 0% 0% no-repeat padding-box;
      }

      &.disabled {
        background: #CED6E0 !important;
      }
  }
  .modal-contact-warning-popup__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#2E353D, 0.3);
    z-index: 998;

    .modal-contact-warning-popup__modal {
      position: relative;
      width: 100%;
      max-width: 568px;
      padding: 32px 0 26px 0 !important;
      background-color: #FFFFFF;
      border-radius: 16px;
      box-shadow: 0px 3px 6px #00000029;
      top: 50% !important;
      transform: scalex(1) translate(0, -50%) !important;
      margin: 0 auto;

      @media screen and (max-width: 600px) {
        margin: 0 16px;
        width: 94%;
      }
      @media screen and (max-width: 420px) {
          width: 92%;
      }

    }
  }

}
</style>
