var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lesson-screen--photos" },
    _vm._l(_vm.photos, function(photo, key) {
      return _c("div", { key: key, staticClass: "lesson-screen--photo" }, [
        _vm.PHOTO_FALLBACK_A2_2720
          ? _c("div", {
              staticClass: "lesson-screen--img",
              style: {
                "background-image": "url(" + photo.photo_fall_back_A2_2720 + ")"
              },
              attrs: { "data-test": "photo-img-thumb" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.focusOnKey(key)
                }
              }
            })
          : _c("div", {
              staticClass: "lesson-screen--img",
              style: { "background-image": "url(" + photo.thumbnail + ")" },
              attrs: { "data-test": "photo-img-thumb" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.focusOnKey(key)
                }
              }
            }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "lesson-screen--fullscreen-backdrop",
            class: { focus: _vm.isFullscreen(key) },
            attrs: { "data-test": "photo-img-container" }
          },
          [
            _c("div", { staticClass: "lesson-screen--fullscreen-content" }, [
              _c("div", { staticClass: "lesson-screen--img-full" }, [
                _c(
                  "div",
                  { staticClass: "lesson-screen--img-navigation-container" },
                  [
                    _c(
                      "div",
                      { staticClass: "lesson-screen--img-navigation" },
                      [
                        _c(
                          "a",
                          {
                            attrs: { "data-test": "prev-button", href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.navigatePrev($event)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("chevron_left")
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { "data-test": "next-button", href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.navigateNext($event)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("chevron_right")
                            ])
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.PHOTO_FALLBACK_A2_2720
                  ? _c("img", {
                      attrs: {
                        "data-test": "photo-img-full",
                        src: photo.photo_fall_back_A2_2720
                      }
                    })
                  : _c("img", {
                      attrs: { "data-test": "photo-img-full", src: photo.full }
                    })
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "lesson-screen--fullscreen-controller" },
                [
                  _c(
                    "div",
                    { staticClass: "lesson-screen--controller-progress" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("progress", {
                            current: _vm.currentFocus,
                            total: _vm.photosArraySize
                          })
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.photoCaption(key)
                    ? _c(
                        "div",
                        {
                          staticClass: "lesson-screen--controller-caption",
                          attrs: { "data-test": "photo-img-caption" }
                        },
                        [_vm._v(_vm._s(_vm.photoCaption(key)))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "lesson-screen--controller-action" },
                    [
                      _c(
                        "a",
                        {
                          attrs: { "data-test": "photo-img-close", href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.closeFullscreen($event)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("close")
                          ])
                        ]
                      )
                    ]
                  )
                ]
              )
            ])
          ]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }