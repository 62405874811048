import { mapGetters, mapActions } from 'vuex'

import { LESSONS, SCREENS } from '@/features/onboarding-lesson/constants'

const TOTAL_PAGES_PHOTOS = 5
const TOTAL_PAGES_OTHERS = 4

export const OnboardingLessons = {
  data () {
    return {
      LESSONS,
      SCREENS
    }
  },
  computed: {
    ...mapGetters({
      openedLesson: 'onboarding/GET_OPENED_LESSON'
    }),
    isLessonOpened () {
      return (type) => {
        return this.openedLesson?.type === type
      }
    },
    isLessonScreenOpened () {
      return (screen) => {
        return this.openedLesson?.screen === screen
      }
    },
    openedLessonName () {
      switch (this.openedLesson?.type) {
        case LESSONS.MONEY:
          return 'money'
        case LESSONS.FEEDBACK:
          return 'feedback'
        case LESSONS.DATES:
          return 'dates'
        case LESSONS.REQUESTS:
          return 'requests'
        case LESSONS.CLIENTS:
          return 'clients'
        case LESSONS.SAFETY:
          return 'safety'
        case LESSONS.AVAILABILITY:
          return 'availability'
        case LESSONS.INTERVIEW:
          return 'interview'
        case LESSONS.PHOTOS:
        default:
          return 'photos'
      }
    },
    totalPages () {
      if (this.openedLesson.type === this.LESSONS.PHOTOS) {
        return TOTAL_PAGES_PHOTOS
      }

      return TOTAL_PAGES_OTHERS
    },
    currentPage () {
      if (this.openedLesson.type === this.LESSONS.PHOTOS) {
        return this.currentPageForPhotoLesson
      }

      return this.currentPageForOtherLesson
    },
    currentPageForPhotoLesson () {
      switch (this.openedLesson?.screen) {
        case this.SCREENS.FEEDBACK:
          return 5
        case this.SCREENS.PHOTOS_BAD:
          return 4
        case this.SCREENS.THUMBS_DOWN:
          return 3
        case this.SCREENS.PHOTOS_GOOD:
          return 2
        case this.SCREENS.THUMBS_UP:
          return 1
        case this.SCREENS.START:
        default:
          return 0
      }
    },
    currentPageForOtherLesson () {
      switch (this.openedLesson?.screen) {
        case this.SCREENS.FEEDBACK:
          return 4
        case this.SCREENS.THUMBS_DOWN:
          return 3
        case this.SCREENS.THUMBS_UP:
          return 2
        case this.SCREENS.VIDEO:
          return 1
        case this.SCREENS.START:
        default:
          return 0
      }
    }
  },
  methods: {
    ...mapActions('onboarding', {
      updateActiveLesson: 'UPDATE_ACTIVE_LESSON',
      resetActiveLesson: 'RESET_ACTIVE_LESSON',
      openPhotosLesson: 'OPEN_PHOTOS_LESSON',
      openInterviewLesson: 'OPEN_INTERVIEW_LESSON',
      openAvailabilityLesson: 'OPEN_AVAILABILITY_LESSON',
      openSafetyLesson: 'OPEN_SAFETY_LESSON',
      openClientsLesson: 'OPEN_CLIENTS_LESSON',
      openRequestsLesson: 'OPEN_REQUESTS_LESSON',
      openDatesLesson: 'OPEN_DATES_LESSON',
      openFeedbackLesson: 'OPEN_FEEDBACK_LESSON',
      openMoneyLesson: 'OPEN_MONEY_LESSON',
      openStartScreen: 'OPEN_START_SCREEN',
      openThumbsUpScreen: 'OPEN_THUMBS_UP_SCREEN',
      openThumbsDownScreen: 'OPEN_THUMBS_DOWN_SCREEN',
      openPhotosGoodScreen: 'OPEN_PHOTOS_GOOD_SCREEN',
      openPhotosBadScreen: 'OPEN_PHOTOS_BAD_SCREEN',
      openVideoScreen: 'OPEN_VIDEO_SCREEN',
      openFeedbackScreen: 'OPEN_FEEDBACK_SCREEN'
    }),
    gotoNextLessonScreen () {
      if (this.openedLesson?.type === LESSONS.PHOTOS) {
        return this.findNextScreenForPhotoLessons()
      }

      return this.findNextScreenForOtherLessons()
    },
    async findNextScreenForPhotoLessons () {
      switch (this.openedLesson?.screen) {
        case SCREENS.PHOTOS_BAD:
          await this.saveLessonState(SCREENS.FEEDBACK)
          this.openFeedbackScreen()
          break
        case SCREENS.THUMBS_DOWN:
          await this.saveLessonState(SCREENS.PHOTOS_BAD)
          this.openPhotosBadScreen()
          break
        case SCREENS.PHOTOS_GOOD:
          await this.saveLessonState(SCREENS.THUMBS_DOWN)
          this.openThumbsDownScreen()
          break
        case SCREENS.THUMBS_UP:
          await this.saveLessonState(SCREENS.PHOTOS_GOOD)
          this.openPhotosGoodScreen()
          break
        case SCREENS.START:
        default:
          await this.saveLessonState(SCREENS.THUMBS_UP)
          this.openThumbsUpScreen()
      }
    },
    async findNextScreenForOtherLessons () {
      switch (this.openedLesson?.screen) {
        case SCREENS.THUMBS_DOWN:
          await this.saveLessonState(SCREENS.FEEDBACK)
          this.openFeedbackScreen()
          break
        case SCREENS.THUMBS_UP:
          await this.saveLessonState(SCREENS.THUMBS_DOWN)
          this.openThumbsDownScreen()
          break
        case SCREENS.VIDEO:
          await this.saveLessonState(SCREENS.THUMBS_UP)
          this.openThumbsUpScreen()
          break
        case SCREENS.START:
        default:
          await this.saveLessonState(SCREENS.VIDEO)
          this.openVideoScreen()
      }
    },
    async saveLessonState (screen) {
      await window.localforage.setItem('openedLesson', {
        type: this.openedLesson?.type,
        screen: screen
      })
    }
  }
}
