import { render, staticRenderFns } from "./ForgotPasswordSuccess.vue?vue&type=template&id=96b0762a&scoped=true&"
import script from "./ForgotPasswordSuccess.vue?vue&type=script&lang=js&"
export * from "./ForgotPasswordSuccess.vue?vue&type=script&lang=js&"
import style0 from "./ForgotPasswordSuccess.vue?vue&type=style&index=0&id=96b0762a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96b0762a",
  null
  
)

/* custom blocks */
import block0 from "../../translations.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fforge%2Fama-provider8-stage.noznom.tech%2Fsrc%2Fjs%2Ffeatures%2Fauth%2Fcomponents%2FForgotPassword%2FForgotPasswordSuccess.vue"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider8-stage.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('96b0762a')) {
      api.createRecord('96b0762a', component.options)
    } else {
      api.reload('96b0762a', component.options)
    }
    module.hot.accept("./ForgotPasswordSuccess.vue?vue&type=template&id=96b0762a&scoped=true&", function () {
      api.rerender('96b0762a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/features/auth/components/ForgotPassword/ForgotPasswordSuccess.vue"
export default component.exports