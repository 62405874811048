import { buildParam } from '@/services/ApiService'
const longTermBookingPromoPath = '/v2/popup/long-term-booking-promo/seen'

export const putAvailabilityLongTermBookingSeen = (payload = { }) => {
  const url = buildParam(`${process.env.KRYPTON_PROVIDER_API}${longTermBookingPromoPath}`)
  return new Promise((resolve, reject) => {
    window.api.put({ url, payload: payload },
      (success) => { resolve(success.data) },
      (err) => { reject(err.response.data) })
  })
}
