var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      [
        _vm.photos.length
          ? _c(
              "div",
              {
                staticClass: "photos-register",
                class: {
                  "bottom-spacer": _vm.photos.length >= 2
                }
              },
              [
                _vm._l(_vm.photos, function(photo, i) {
                  return _c(
                    "div",
                    {
                      key: "photo-item-" + i,
                      staticClass: "photo margin-bottom-sm",
                      style: _vm.createBG(photo._url),
                      on: {
                        click: function($event) {
                          if ($event.target !== $event.currentTarget) {
                            return null
                          }
                          return _vm.previewPhoto(photo)
                        }
                      }
                    },
                    [
                      _vm.mainPhotoTextDisplay(photo)
                        ? _c("div", { staticClass: "primary-photo-indicator" })
                        : _vm._e()
                    ]
                  )
                }),
                _vm._v(" "),
                !_vm.isOtherPhotosMaxed
                  ? _c(
                      "div",
                      { staticClass: "photo margin-bottom-sm empty" },
                      [
                        _c("upload-other-photo", {
                          attrs: {
                            "is-register-mode": _vm.isRegisterMode,
                            "is-other-photo-empty": _vm.isOtherPhotoEmpty
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              2
            )
          : [
              _c("div", { staticClass: "description" }, [
                _c("strong", [
                  _vm._v(_vm._s(_vm.$translate("photos.primary_tip")))
                ])
              ]),
              _vm._v(" "),
              _c("upload-main-photo", {
                staticClass: "margin-bottom-lg",
                attrs: { "is-register-mode": _vm.isRegisterMode }
              })
            ],
        _vm._v(" "),
        _c("vform", { ref: "deleteForm", on: { submit: _vm.deletePhoto } })
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }